import React, { useContext } from "react"

import { SignUpEmailContext } from "@contexts/SignUpEmailContext"

import { InputEl } from "../../common"
import { CodeMessage } from "../atoms"
import { REGISTER_STEPS } from "../constants"
import { FormCode, InputWrapper } from "../newSignUpEmailStyles"

import { ResendCode } from "./index"

export const PrivateInfoForm = () => {
  const {
    email,
    setEmail,
    code,
    setCode,
    password,
    setPassword,
    allPasswordErrors,
    step,
    deviceId,
    isCodeVerifying,
    isCodeVerified,
    validationErrors,
    apiErrors,
    touchField,
  } = useContext(SignUpEmailContext)

  return (
    <InputWrapper>
      <InputEl
        label="What is your email address?"
        placeholder="Email address"
        name="email"
        errorMessage={validationErrors?.email || apiErrors?.email}
        disabled={step !== REGISTER_STEPS.EMAIL}
        value={email}
        onChange={newVal => {
          touchField("email", newVal)
          setEmail(newVal)
        }}
      />

      {step !== REGISTER_STEPS.EMAIL && (
        <FormCode>
          {step === REGISTER_STEPS.SIGN_UP_CODE && <CodeMessage />}

          <InputEl
            isLoading={isCodeVerifying}
            isValid={isCodeVerified}
            label="Sign-up code"
            placeholder="Sign-up code"
            name="code"
            errorMessage={apiErrors?.code || null}
            disabled={step !== REGISTER_STEPS.SIGN_UP_CODE}
            value={code}
            onChange={newVal => {
              touchField("code", newVal)
              setCode(newVal)
            }}>
            {step === REGISTER_STEPS.SIGN_UP_CODE && (
              <ResendCode email={email} deviceId={deviceId} setCode={setCode} />
            )}
          </InputEl>
        </FormCode>
      )}

      {step === REGISTER_STEPS.PASSWORD && (
        <InputEl
          label="Set a password"
          placeholder="New password"
          name="password"
          type="password"
          value={password}
          allPasswordErrors={allPasswordErrors}
          onChange={newVal => {
            touchField("password", newVal)
            setPassword(newVal)
          }}
        />
      )}
    </InputWrapper>
  )
}
