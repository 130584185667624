import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Wrapper = styled.section`
  width: 100%;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;
  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    padding: 40px 130px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding: 40px 10px;
  }
`

export const Block = styled.div`
  ${({ isOdd }) =>
    isOdd
      ? css`
          width: 70%;
          @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
            width: 90%;
          }
          @media (max-width: ${MEDIA_SIZES.MOBILE}) {
            width: 80%;
            flex-direction: column;
          }
          padding: 30px 0 30px 25px;
        `
      : css`
          width: calc(70% - 25px);
          @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
            width: calc(90% - 25px);
          }
          @media (max-width: ${MEDIA_SIZES.MOBILE}) {
            width: calc(80% - 25px);
            flex-direction: column;
          }
          padding: 30px 25px;
        `};
  background-color: var(--lightGray);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isFirst }) =>
    !isFirst &&
    css`
      margin-top: 20px;
    `};
`

export const BlockLeft = styled.div`
  width: 40%;
  ${({ isOdd }) =>
    !isOdd &&
    css`
      order: 1;
      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        order: 0;
      }
    `};
  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
  }
`

export const BlockImg = styled.img`
  width: 50%;
  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
    ${({ isOdd }) =>
      !isOdd &&
      css`
        margin-top: 20px;
      `};
  }
`

export const AppStores = styled.div`
  width: 100%;
  background-color: var(--radicalRed2);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0;
  margin-top: 50px;
`

export const StoresLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  margin-top: 20px;
`
