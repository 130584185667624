import React from "react"

import FooterButtonsWrapper from "./FooterButtonsWrapper"

import useSuggest from "../../Hooks/useSuggest"
import useOnlineTabState from "../../Hooks/useOnlineTabState"

import { images } from "@utils/imageUtils"
// import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import "./index.scss"
import { useSelector } from "react-redux"

import { selectIsFromCreateTeeUp } from "@selectors/common"

const FooterButtons = ({ suggestInfo, close, onSuccess, onError }) => {
  const { activeButtonId, initComment, setActiveButtonId } = useOnlineTabState()
  const { onSubmit } = useSuggest({
    suggestInfo: suggestInfo,
    suggestType: "online",
  })
  const isFromCreateTeeUp = useSelector(selectIsFromCreateTeeUp)

  return (
    <>
      {activeButtonId === 0 && (
        <div className="suggest-online__suggest inactive">Suggest</div>
      )}
      {activeButtonId === 1 && (
        <div
          className="suggest-online__button"
          onClick={() => setActiveButtonId(2)}>
          <img
            src={images.ndSuggestSkipDetails}
            alt="skip"
            style={{ marginRight: 4 }}
          />
          Skip meeting details
        </div>
      )}
      {activeButtonId === 2 && (
        <>
          {isFromCreateTeeUp ? (
            <>
              <div
                data-testid="decideOnThisButton"
                className="suggest-online__button"
                onClick={async () => {
                  await onSubmit(true)
                  close()
                  // TODO: remove it later (Calendar removing)
                  // syncCalendar()
                  // store.dispatch(setIsCalendarSynchronized(false))
                }}>
                Decide on this
              </div>
              <div
                data-testid="suggestButton"
                className="suggest-online__suggest"
                onClick={async () => {
                  await onSubmit(false)
                  close()
                }}>
                Suggest
              </div>
            </>
          ) : (
            <FooterButtonsWrapper
              suggestInfo={suggestInfo}
              initComment={initComment}
              onSuccess={onSuccess}
              onError={onError}
            />
          )}
        </>
      )}
    </>
  )
}

export default FooterButtons
