import React, { useState, createContext, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { setIsTeeupOverviewSettings } from "@actions/commonActions"
import { selectActiveTeeupState } from "@selectors/activeTeeup"
import { selectIsTeeupOverviewSettings } from "@selectors/common"
import { selectUserId } from "@selectors/user"
import { isOrganiser } from "@utils/teeupUtils"

export const NewTeeupPageContext = createContext({})

export const NewTeeupPageState = ({ children }) => {
  const dispatch = useDispatch()
  const isSettings = useSelector(selectIsTeeupOverviewSettings)
  const [settingsType, setSettingsType] = useState(null)
  const [isShareTeeUpLinkModalOpen, setIsShareTeeUpLinkModalOpen] =
    useState(false)
  const [localOrganisers, setLocalOrganisers] = useState([])
  const [isGameplanOpen, setIsGameplanOpen] = useState(false)
  const authedUserId = useSelector(selectUserId)
  const [isInvitePeopleOpen, setIsInvitePeopleOpen] = useState(false)
  const teeup = useSelector(selectActiveTeeupState)
  const activeUserId = useSelector(selectUserId)
  const isTeeupOrganiser = isOrganiser(activeUserId, teeup?.organisers)

  useEffect(() => {
    setLocalOrganisers(teeup.organisers)

    return () => {
      setIsSettings(false)
    }
  }, [teeup])

  const disableOrganiserActions = person => {
    return (
      authedUserId === person.id &&
      isTeeupOrganiser &&
      localOrganisers.length < 2
    )
  }

  const setIsSettings = val => {
    dispatch(setIsTeeupOverviewSettings(val))
  }

  return (
    <NewTeeupPageContext.Provider
      value={{
        isTeeupLoading: !teeup?.id,
        teeup,
        isSettings,
        settingsType,
        isTeeupOrganiser,
        setIsSettings,
        setSettingsType,
        localOrganisers,
        isShareTeeUpLinkModalOpen,
        setIsShareTeeUpLinkModalOpen,
        isInvitePeopleOpen,
        setIsInvitePeopleOpen,
        setLocalOrganisers,
        disableOrganiserActions,
        isGameplanOpen,
        setIsGameplanOpen,
      }}>
      {children}
    </NewTeeupPageContext.Provider>
  )
}
