import { useState } from "react"

export const useDelay = () => {
  const [hookTimeout, setHookTimeout] = useState(0)

  const setDelay = (callback, ms) => {
    const timeout = setTimeout(() => {
      callback()
    }, ms)

    setHookTimeout(timeout)
  }

  const clearDelay = () => {
    clearTimeout(hookTimeout)
  }

  return [setDelay, clearDelay]
}
