import React from "react"

import {
  FontWeight,
  Subtitle,
  Caption,
} from "../../../assets/styles/typographyStyles"
import { UserAvatar } from "../atoms"
import { InviteWrapper, InviteDescription } from "../newSignUpEmailStyles"

export const InviteInfo = ({
  info: { inviterAvatar, inviterName, email, phone, type, message },
}) => (
  <InviteWrapper>
    <UserAvatar isSmall picture={inviterAvatar} />

    <InviteDescription>
      <Subtitle
        style={{ fontWeight: FontWeight.bold, wordBreak: "break-word" }}>
        {inviterName} sent you an invite at: {email || phone}
      </Subtitle>

      <Caption>
        We just sent you an {type} so we can verify your invitation. Please
        check your {message}
      </Caption>
    </InviteDescription>
  </InviteWrapper>
)
