import ct from "countries-and-timezones"
import moment from "moment-timezone"
import mtz from "moment-timezone"
import * as RNLocalize from "react-native-localize"

import dayjs from "utils/dayjs"

const fullDayDuration = 1440
const getTimezone = () => RNLocalize.getTimeZone()
const defaultTimezone = getTimezone()
let currentTimezone = ""

export const setCurrentTimezone = newTimezone => (currentTimezone = newTimezone)
const getCurrentTimezone = () => currentTimezone
export const getDefaultTimezone = () => defaultTimezone

//  const calendarFormat = isIOS
//     ? 'YYYY-MM-DDTHH:mm:ss.SSSZ'
//     : 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

const convertToMilliseconds = (value, type) =>
  moment.duration(+value, type).valueOf()

const getDateWithTimezone = (date = new Date()) => dayjs(date).tz()

export const getTimeWithTimezone = (date = new Date(), timezone) =>
  mtz.tz(getDateWithTimezone(date), timezone).milliseconds()

export const addDayTimestampString = day => {
  // add days and returns timestamp like Thu Oct 17 2019 09:15:08 GMT+0300 (Eastern European Summer Time)
  return getDateWithTimezone().add(day, "days").toString()
}

export const getHour = (date, timezone) => {
  return getDateWithTimezone(date, timezone).hour()
}

export const getMinutes = (date, timezone) => {
  return getDateWithTimezone(date, timezone).minutes()
}

export const getEndOfTheDay = date =>
  setSecondsToZero(getDateWithTimezone(date).endOf("day"))

const composeDatetime = (date, time) => {
  let momentDate = moment.utc(date)
  let momentTime = getDateWithTimezone(time)

  const composedTime = getDateWithTimezone()
    .date(momentDate.date())
    .month(momentDate.month())
    .year(momentDate.year())
    .hour(momentTime.hour())
    .minute(momentTime.minute())
    .format()

  return composedTime
}

const addMinutes = (date, minutes) => {
  let momentDate = getDateWithTimezone(date)
  momentDate.add(minutes, "minutes")
  return momentDate.format()
}

const addHours = (date, hours) => {
  let momentDate = getDateWithTimezone(date)
  momentDate.add(hours, "hours")
  return momentDate.format()
}

//  const getDuration = (startDate, endDate) => {
//      const end = getDateWithTimezone(endDate)
//      const start = getDateWithTimezone(startDate)
//     return end.diff(start, 'minutes')
// }

const getTimezoneCode = timezone => {
  return mtz.tz(getDateWithTimezone(), timezone).format("z")
}

const getTimezoneOffset = timezone => {
  return mtz.tz.zone(timezone).utcOffset(getDateWithTimezone())
}

const getTimezoneToUtc = timezone => {
  return mtz.tz(timezone).format("ZZ")
}

const getTimezoneAbbreviation = timezone => {
  return dayjs().tz(timezone).format("z")
}

const minutesToHourMinutes = total => {
  const hours = Number.parseInt(total / 60)
  const minutes = total - hours * 60
  return (
    (hours > 0 ? hours + " hours" : "") +
    (minutes > 0 && hours > 0 ? ", " : "") +
    (minutes > 0 ? minutes + " minutes" : "")
  )
}

const getTimestampString = () => {
  const newTimestampDate = new Date()
  return getDateWithTimezone(newTimestampDate).format("YYYY-MM-DD HH:mm:ss")
}

export const isToday = (date, selectedDate = new Date()) => {
  return getDateWithTimezone(date).isSame(
    getDateWithTimezone(selectedDate),
    "day"
  )
}

export const getTimeDifferenceStrForActivity = (date, fromDate) => {
  if (!date) {
    return ""
  }

  const now = fromDate || getDateWithTimezone()
  const then = getDateWithTimezone(date)

  let daysDiff = Math.abs(then.diff(now, "days"))
  let hoursDiff = Math.abs(then.diff(now, "hours") % 24)
  let minsDiff = Math.abs(then.diff(now, "minutes") % 60)
  let secsDiff = Math.abs(then.diff(now, "seconds") % 60)

  if (daysDiff > 0) {
    return ` ${daysDiff}d ago`
  }
  if (hoursDiff > 0) {
    return ` ${hoursDiff}${hoursDiff > 1 ? "hrs" : "h"} ago`
  }
  if (minsDiff > 0) {
    return ` ${minsDiff}min ago`
  }

  if (secsDiff > 0) {
    return ` ${secsDiff}sec ago`
  }

  return ""
}

const isTodayWithDeviceTimezone = date => {
  return moment(date).isSame(moment(new Date()), "day")
}

const isTodayTimestamp = timestamp => {
  return momentUniversal(timestamp).isSame(getDateWithTimezone(), "day")
}

const formatCalendarEventDate = string => {
  return getDateWithTimezone(string).utc().format(calendarFormat)
}

const isYesterday = timestamp => {
  return momentUniversal(timestamp).isSame(
    getDateWithTimezone().subtract(1, "days"),
    "day"
  )
}

const isThisWeek = (date, selectedDate = new Date()) => {
  return getDateWithTimezone(date).isSame(
    getDateWithTimezone(selectedDate),
    "week"
  )
}

const isThisMonth = (date, selectedDate = new Date()) => {
  return getDateWithTimezone(date).isSame(
    getDateWithTimezone(selectedDate),
    "month"
  )
}

const isEqualDates = (date1, date2) =>
  momentUniversal(date1).isSame(momentUniversal(date2), "second")

export const isTomorrow = timestamp => {
  return momentUniversal(timestamp).isSame(
    getDateWithTimezone().add(1, "days"),
    "day"
  )
}

const areDatesEqual = (timestamp1, timestamp2) => {
  return moment.unix(timestamp1).isSame(moment.unix(timestamp2), "day")
}

const momentUniversal = timestamp => {
  try {
    return getDateWithTimezone(timestamp) // Parse timestamp as UTC
  } catch {
    // Handle potential errors during parsing
    console.error("Invalid timestamp:", timestamp)
    return null
  }
}

export const timeFromNow = timestamp => {
  return momentUniversal(timestamp).fromNow(true)
}

const getUnixTimestamp = timestamp => {
  return getDateWithTimezone(timestamp).unix()
}

const unixSecondsFromNow = unixTimestamp => {
  let date = getDateWithTimezone(unixTimestamp * 1000)
  let now = getDateWithTimezone()
  return date.diff(now, "seconds")
}

const adjustMinuteRange = (date, difference = 15) => {
  // 15 minutes minimum different
  date = getDateWithTimezone(date)
  let minutes = Math.ceil(date.minute() / difference) * difference
  // TODO: check if few minutes in the past
  date.minute(minutes)
  return date
}

const timeDifferenceSeconds = (dateStart, dateEnd) => {
  let start = getDateWithTimezone(dateStart)
  let end = getDateWithTimezone(dateEnd)
  return end.diff(start, "seconds")
}

const timeDifferenceMinutes = (dateStart, dateEnd) => {
  let start = getDateWithTimezone(dateStart)
  let end = getDateWithTimezone(dateEnd)
  return end.diff(start, "minutes")
}

// compare only time not dates
const timeDifferenceSecondsTime = (date1, date2) => {
  const firstDate = getDateWithTimezone(date1).format("h:mm:ss")
  const secondDate = getDateWithTimezone(date2).format("h:mm:ss")
  return getDateWithTimezone(date1).isAfter(date2)
    ? getDateWithTimezone(firstDate, "h:mm:ss").diff(
        getDateWithTimezone(secondDate, "h:mm:ss"),
        "seconds"
      )
    : getDateWithTimezone(secondDate, "h:mm:ss").diff(
        getDateWithTimezone(firstDate, "h:mm:ss"),
        "seconds"
      )
}

function getCalendarFormatDayjs(date) {
  if (!date) return null

  const now = dayjs()
  const diffInDays = date.diff(now, "day")

  let formatted
  switch (diffInDays) {
    case 0:
      formatted = date.format("h:mm A")
      break
    case 1:
      formatted = "[Tomorrow]"
      break
    case diffInDays >= 2 && diffInDays <= 6:
      formatted = date.format("dddd")
      break
    case diffInDays < 0:
      formatted =
        Math.abs(diffInDays) === 1
          ? "[Yesterday]"
          : `[Last] ${date.format("dddd")}`
      break
    default:
      formatted = date.format("DD/MM/YYYY")
  }

  return formatted
}

const getCalendarFormat = timestamp => {
  let date = momentUniversal(timestamp)
  if (!date) return null

  return getCalendarFormatDayjs(date)
}

const getTimeFormat = (timestamp, format = "h:mm A") => {
  let date = momentUniversal(timestamp)
  let formatted
  if (date) {
    formatted = date.format(format)
  }
  return formatted ?? null
}

const getDatesArray = selectedDate => {
  const startOfWeek = getDateWithTimezone(selectedDate).startOf("week")
  let days = []
  for (let i = 0; i < 7; i++) {
    days.push(getDateWithTimezone(startOfWeek).add(i, "d"))
  }
  return days
}

const getDiffInDays = date =>
  getDateWithTimezone().diff(getDateWithTimezone(date), "days")

export const normalizeTimeFromToday = time => {
  const momentTime = getDateWithTimezone(time)
  const momentDate = getDateWithTimezone()

  return getDateWithTimezone(
    momentDate.hour(momentTime.hour()).minute(momentTime.minute()).toDate()
  ).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
}

const addDayTimestamp = (date, day, patternFormat = "dddd MMM DD") => {
  return getDateWithTimezone(date).add(day, "days").format(patternFormat)
}

const getLocaleTime = string => {
  // const localeFormat = moment.localeData().longDateFormat("LT")
  // const a = dayjs.tz.guess()
  // const date = dayjs.tz(string, a)
  const date = dayjs(string).tz()
  return date.format("h:mm A")
}

const isDateBeforeNow = (date, timezone, compareType = "minutes") => {
  const nowDate = getDateWithTimezone(undefined, timezone)
  const dateObj = getDateWithTimezone(date, timezone)
  return dateObj.isBefore(nowDate, compareType)
}

const setSecondsToZero = date => {
  if (!date) {
    return null
  }

  return getDateWithTimezone(date).set({ second: 0 }).format()
}

const stringToDateFormat = (string, format) => {
  // const customTimezone = defaultTimezone ? null : getCustomTimezone()

  const date = getDateWithTimezone(string)
  return dayjs(date).format(format)
}

const getMonthDay = date => {
  return getDateWithTimezone(date).date()
}

const getWeekdayName = date => {
  return getDateWithTimezone(date).format("ddd")
}

const addDayAtStartOfDay = (date, day) => {
  return getDateWithTimezone(date).startOf("day").add(day, "days").format()
}

const dayDifferenceBetweenDates = (dateString1, dateString2) => {
  return getDateWithTimezone(dateString1)
    .startOf("day")
    .diff(getDateWithTimezone(dateString2).startOf("day"), "days")
}

const formatComingUpHeader = date => {
  if (moment(date).year() !== moment().year())
    return getDateWithTimezone(date).format("dddd, MMMM D YYYY")
  return getDateWithTimezone(date).format("dddd, MMMM D")
}

const addTimeCalendarEventDate = (time, amount, timeType) => {
  return getDateWithTimezone(time)
    .add(amount, timeType)
    .utc()
    .format(calendarFormat)
}

const generateDaysOfTheWeek = () => {
  const startOfWeek = getDateWithTimezone().startOf("week")
  let days = []
  for (let i = 0; i < 7; i++) {
    days.push(getDateWithTimezone(startOfWeek).add(i, "d").format("dddd"))
  }
  return days
}

// const calendarFormat = isIOS
//     ? 'YYYY-MM-DDTHH:mm:ss.SSSZ'
//     : 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
const calendarFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ"

const generateDaysArray = () => {
  const date = new Date()
  let days = [...Array.from({ length: 60 }).keys()].map((item, index) => {
    return addDayTimestamp(date, index + 1)
  })
  days.unshift("Today")
  return days
}

const getDuration = (startDate, endDate) => {
  const end = getDateWithTimezone(endDate)
  const start = getDateWithTimezone(startDate)
  return end.diff(start, "minutes")
}

const isSameDate = (date1, date2) => {
  const d1 = getDateWithTimezone(date1)
  const d2 = getDateWithTimezone(date2)
  return d1.isSame(d2, "minutes")
}

// for sorting by date
const dateComparator = (dateString1, dateString2, checkOnUndefined = false) => {
  if (checkOnUndefined) {
    if (!dateString2 && dateString1) {
      return 1
    }

    if (dateString2 && !dateString1) {
      return -1
    }

    if (!dateString2 && !dateString1) {
      return 0
    }
  }

  const date1 = getDateWithTimezone(dateString1)
  const date2 = getDateWithTimezone(dateString2)
  if (date1.isBefore(date2)) {
    return -1
  }
  if (date1.isAfter(date2)) {
    return 1
  }
  return 0
}
const formatStatusBarTime = timeString => {
  return getDateWithTimezone(timeString).format("h.mm a")
}

const getTimezonesForCountry = country => {
  return ct.getTimezonesForCountry(country)
}

const formatDaybreakDate = (timestamp, format = "ddd MMM D") => {
  if (isTodayWithDeviceTimezone(moment.unix(timestamp))) {
    return { daybreak: "TODAY" }
  }
  if (isYesterday(moment.unix(timestamp))) {
    return { daybreak: "YESTERDAY" }
  }
  return {
    daybreak: moment.unix(timestamp).format(format).toUpperCase(),
  }
}

const getMonthFormat = date => {
  //format like Jun 26
  return getDateWithTimezone(date).format("MMM D")
}

const compareTimeStrings = (timeString1, timeString2) => {
  return getDateWithTimezone(timeString1).isBefore(
    getDateWithTimezone(timeString2)
  )
}

const dateToFormatTimezone = (date, timezone, format = "h:mm A") => {
  return mtz(date).tz(timezone).format(format)
}

const daysPeriodByTimezone = ({ startDate, endDate, timezone }) => {
  const format = "dddd MMM DD"
  const start = mtz(startDate).tz(timezone).format(format)
  const end = mtz(endDate).tz(timezone).format(format)

  return start === end ? start : `${start} - ${end}`
}

const getCountryForTimezone = timezone => {
  return ct.getCountryForTimezone(timezone)?.id
}

const generateHoursArray = ({ maxHour }) => {
  const length = maxHour ? maxHour : 24
  const hoursArray = [...new Array(length).keys()].map(item => item.toString())
  return hoursArray
}

const generateMinutesArray = (interval = 1) => {
  const minutesArray = [...Array.from({ length: 60 / interval }).keys()].map(
    i => `00${i * interval}`.slice(-2)
  )
  return minutesArray
}

const formatDateTh = date => {
  return `${date.format("dddd")}, ${date.format("Do")}`
}

const getPMFormat = date => {
  return dayjs(date).format("h:mm A")
}

const getTimeRange = (start, end) => {
  return `${getPMFormat(start)} - ${getPMFormat(end)}`
}

export {
  addDayTimestamp,
  getLocaleTime,
  getTimezoneToUtc,
  isDateBeforeNow,
  setSecondsToZero,
  stringToDateFormat,
  getTimeFormat,
  getDatesArray,
  getDiffInDays,
  getMonthDay,
  getWeekdayName,
  addDayAtStartOfDay,
  dayDifferenceBetweenDates,
  formatComingUpHeader,
  addTimeCalendarEventDate,
  generateDaysOfTheWeek,
  getDateWithTimezone,
  generateDaysArray,
  getDuration,
  isSameDate,
  dateComparator,
  momentUniversal,
  formatStatusBarTime,
  getCalendarFormat,
  getUnixTimestamp,
  areDatesEqual,
  formatDaybreakDate,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  unixSecondsFromNow,
  getTimestampString,
  getMonthFormat,
  compareTimeStrings,
  addMinutes,
  composeDatetime,
  getTimezonesForCountry,
  adjustMinuteRange,
  dateToFormatTimezone,
  daysPeriodByTimezone,
  fullDayDuration,
  getCountryForTimezone,
  getTimezoneOffset,
  timeDifferenceSeconds,
  timeDifferenceMinutes,
  addHours,
  generateHoursArray,
  generateMinutesArray,
  formatCalendarEventDate,
  isEqualDates,
  formatDateTh,
  getPMFormat,
  getTimeRange,
}
