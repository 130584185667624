import React from "react"

import { StepperHeader, SubmitChoicesForm } from "../molecules"

import { Wrapper, Content } from "./choicesSubmitScreenStyles"

export const ChoicesSubmitScreen = () => (
  <Wrapper>
    <StepperHeader
      goBackText="Verify your identity"
      subtitle="To submit your reactions/suggestions and receive plan updates, please verify your identity."
    />

    <Content>
      {/* <Title>Submit your Choices</Title>
      <Description>
        To submit your reactions/suggestions and receive plan updates, please
        verify your identity. Your name and email will be visible to other TeeUp
        participants.
      </Description> */}

      <SubmitChoicesForm />
    </Content>
  </Wrapper>
)
