import React from "react"

import PropTypes from "prop-types"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"

import {
  ReactionsListHeader,
  Reaction,
  ReactionTimezone,
  EmptyList,
} from "../atoms"
import { useReactionsList } from "../hooks"

import {
  Wrapper,
  List,
  ListItem,
  LeftSide,
  Info,
  Name,
} from "./reactionsListStyles"

export const ReactionsList = ({ reactions, previewInfo }) => {
  const reactionsList = useReactionsList(reactions, previewInfo.participants)

  return (
    <Wrapper>
      <ReactionsListHeader />

      {reactionsList.length > 0 ? (
        <List>
          {reactionsList.map((reaction, reactionIndex) => (
            <ListItem key={reactionIndex}>
              <LeftSide>
                <ParticipantIcon
                  avatar={reaction.avatar}
                  defaultAvatar={reaction?.key?.[0] || reaction?.name?.[0]}
                  isLarge
                />

                <Info>
                  <Name>{reaction.name}</Name>

                  {reaction?.timezone && (
                    <ReactionTimezone reaction={reaction} />
                  )}
                </Info>
              </LeftSide>

              <Reaction reaction={reaction} />
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyList />
      )}
    </Wrapper>
  )
}

ReactionsList.propTypes = {
  reactions: PropTypes.array,
}
