import moment from "moment-timezone"

export const getTileDisabled = ({ date, view }, thisWeekDisabledDays) => {
  if (view === "month") {
    const isDisabled = thisWeekDisabledDays.some(disabledDate =>
      moment(disabledDate).isSame(moment(date), "day")
    )

    if (isDisabled) {
      return {
        classes: "custom-disabled-day",
        style: {
          display: "none",
        },
      }
    }
  }
}
