import React, { Fragment } from "react"

import propTypes from "prop-types"
import { ScrollView, View, Text, Image, TouchableOpacity } from "react-native"

import { teeupUserStatusKeys } from "@configs/enums"
import { AppColors } from "@theme/"
import Avatar from "@ui/avatar"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import { getInitials } from "@utils/dataUtils"
import { getHour, getMinutes, getTimeFormat } from "@utils/dateUtils"
import { images } from "@utils/imageUtils"

import { ButtonProps } from "../teeupStyles"

import { styles } from "./StartsWhenCarouselStyles"

const TIMELINE_TICKS = 96
const HOURS_IN_DAY = 24
const TIME_SCALE = TIMELINE_TICKS / HOURS_IN_DAY
const timeline = [...Array.from({ length: TIMELINE_TICKS }).keys()]
const TICK_MINUTES = 60 / TIME_SCALE

const TimeLabelsLine = () => (
  <View style={styles.timeLabels}>
    {timeline.map((hour, index) => {
      const isOdd = Math.floor(hour / TIME_SCALE) % 2 !== 0
      const currentHour = Math.floor(hour / TIME_SCALE)
      const timeLabel =
        currentHour === 0
          ? "12 a.m."
          : currentHour === 12
          ? "12 p.m."
          : currentHour > 12
          ? `${currentHour - 12} p.m`
          : `${currentHour} a.m`
      const isStartOfOddHour =
        isOdd &&
        Math.floor(hour / TIME_SCALE) !== Math.floor((hour - 1) / TIME_SCALE)
      if (isStartOfOddHour) {
        return (
          <Text key={`timeLabel-${hour}`} style={styles.hourText(index)}>
            {timeLabel}
          </Text>
        )
      }
    })}
  </View>
)

const UsersTimeLabels = ({ user, isCurrentUser, onSetAvailability }) => {
  const startLabel = getTimeFormat(user.startTime)
  const start = {
    hours: getHour(user.startTime),
    minutes: getMinutes(user.startTime),
  }

  return timeline.map(tick => {
    const currentMinutes = tick * TICK_MINUTES
    const startMins = start.hours * 60 + start.minutes
    const isFirst =
      currentMinutes >= startMins && currentMinutes < startMins + TICK_MINUTES
    if (isFirst) {
      return (
        <TouchableOpacity
          disabled={!isCurrentUser}
          onPress={onSetAvailability}
          key={`UsersTimeLabels-${tick}`}
          style={[
            styles.usersTimeLabels(tick),
            user.status === teeupUserStatusKeys.going && styles.goingTimeLabel,
            user.status === teeupUserStatusKeys.mightgo &&
              styles.mightGoTimeLabel,
          ]}>
          <Text style={styles.userStartText}>{startLabel}</Text>
          {isCurrentUser && (
            <Image source={images.edit} style={styles.editImage} />
          )}
        </TouchableOpacity>
      )
    }
  })
}

const HeaderBlocks = () =>
  timeline.map((hour, index) => {
    const isOdd = Math.floor(hour / TIME_SCALE) % 2 !== 0
    return (
      <View
        key={`tiemTitle-${hour} + ${index}`}
        style={[styles.hours({ isOdd }), styles.block()]}
      />
    )
  })

const UsersAvailabilityLines = ({
  people,
  userId,
  onSetAvailability,
  startsWhenGameplan,
}) => (
  <View pointerEvents="box-none">
    <View style={styles.dayRowContainer}>
      {people.map((user, index) => (
        <View key={index.toString()} style={styles.userRow}>
          <Fragment key={index.toString()}>
            {timeline.map(tick => {
              const isOdd = Math.floor(tick / TIME_SCALE) % 2 !== 0
              return (
                <>
                  <View
                    key={`availabilityLine-${user.id}-${tick}`}
                    style={[
                      styles.block(),
                      styles.hours({
                        isOdd,
                        withOpacity: true,
                      }),
                    ]}
                  />
                  <View style={styles.addtionalRowStyle} />
                </>
              )
            })}
          </Fragment>
        </View>
      ))}
    </View>

    <View style={styles.firstUserRowStyle} />
    <StartsWhenFlag startsWhenGameplan={startsWhenGameplan} />

    {people.map((user, index) => {
      const isCurrentUser = userId === user.id
      const decided =
        user.startTime &&
        user.endTime &&
        (user.status === teeupUserStatusKeys.going ||
          user.status === teeupUserStatusKeys.mightgo ||
          user.status === teeupUserStatusKeys.onmyway ||
          user.status === teeupUserStatusKeys.arrived)

      const eventStart = {
        hours: getHour(startsWhenGameplan.conditionTime),
        minutes: getMinutes(startsWhenGameplan.conditionTime),
      }

      const start = {
        hours: getHour(user.startTime),
        minutes: getMinutes(user.startTime),
      }

      const startMinutes = start.hours * 60 + start.minutes
      const eventStartMinutes = eventStart.hours * 60 + eventStart.minutes

      const show = eventStartMinutes > startMinutes

      let timeCapsule

      if (decided) {
        for (const tick of timeline) {
          const currentMinutes = tick * TICK_MINUTES
          const start = {
            hours: getHour(user.startTime),
            minutes: getMinutes(user.startTime),
          }
          const end = {
            hours: getHour(user.endTime),
            minutes: getMinutes(user.endTime),
          }
          const startMins = start.hours * 60 + start.minutes
          const endMins = end.hours * 60 + end.minutes
          const width = (endMins - startMins) / TICK_MINUTES

          const fillColor =
            user.status === teeupUserStatusKeys.mightgo
              ? ButtonProps.mightgo.color
              : ButtonProps.going.color
          if (currentMinutes >= startMins && currentMinutes <= endMins) {
            timeCapsule = (
              <TouchableOpacity
                activeOpacity={1}
                disabled={!isCurrentUser}
                onPress={onSetAvailability}
                key={`filledLine-${tick}`}
                style={styles.fillLine({
                  tick,
                  width,
                  fillColor,
                })}
                pointerEvents="box-none"
              />
            )
            break
          }
        }
      }

      return (
        <View
          pointerEvents="box-none"
          key={`${user} + ${index}`}
          style={styles.userRow}>
          {timeline.map(tick => {
            return (
              <View
                key={`availabilityLine-${user.id}-${tick}`}
                style={styles.block()}
              />
            )
          })}
          {decided && (
            <>
              {timeCapsule && timeCapsule}
              <UsersTimeLabels
                onSetAvailability={onSetAvailability}
                isCurrentUser={isCurrentUser}
                user={user}
              />

              {!!startsWhenGameplan.conditionsMet && show && (
                <View
                  style={styles.overlayLine(eventStartMinutes / TICK_MINUTES)}
                />
              )}
            </>
          )}
        </View>
      )
    })}
  </View>
)
UsersAvailabilityLines.propTypes = {
  people: propTypes.arrayOf(propTypes.shape({})).isRequired,
  userId: propTypes.number.isRequired,
  onSetAvailability: propTypes.func.isRequired,
  startsWhenGameplan: propTypes.string.isRequired,
}

const isDecided = user => user.startTime && user.endTime && isGoing(user)

const isGoing = user =>
  user.status === teeupUserStatusKeys.going ||
  user.status === teeupUserStatusKeys.onmyway ||
  user.status === teeupUserStatusKeys.arrived ||
  user.status === teeupUserStatusKeys.mightgo

const isInvited = user => user.status === teeupUserStatusKeys.invited
// const isJoined = user => user.status === teeupUserStatusKeys.joined
const isNotgoing = user => user.status === teeupUserStatusKeys.notgoing

const UserAvatarsLayer = ({ people, isDragged, userId, onSetAvailability }) => (
  <View style={styles.userAvatarsLayer} pointerEvents="box-none">
    {people.map((user, index) => {
      const isCurrentUser = userId === user.id
      const decided = isDecided(user)
      const going = isGoing(user)
      const invited = isInvited(user)
      const notgoing = isNotgoing(user)

      return (
        <View
          key={`users-${user.id}-${index}`}
          style={[styles.block(true), styles.avatarWrapper]}
          pointerEvents="box-none">
          <Avatar
            size={40}
            imageUrl={user.avatar}
            initials={getInitials(user.name || user.username)}
            contentStyle={styles.avatarColor({
              isCurrentUser,
              decided,
              going,
              notgoing,
            })}
            style={styles.avatarStyle({ isDragged, invited })}
            accesory={
              <UserStatusAccesory
                additionalCount={user.additionalCount}
                userStatus={user.status}
                accesoryStyle={styles.avatarAccesory}
              />
            }
          />
          {isCurrentUser && !decided ? (
            <TouchableOpacity
              onPress={onSetAvailability}
              style={[
                styles.absoluteLine,
                styles.setAvailabilityLine,
                going && {
                  backgroundColor: AppColors.brand.green,
                },
                user.status === teeupUserStatusKeys.mightgo && {
                  backgroundColor: AppColors.brand.orange,
                },
                notgoing && {
                  backgroundColor: AppColors.brand.red,
                },
              ]}>
              <Text style={styles.availableText}>
                {user.status === teeupUserStatusKeys.notgoing
                  ? "Not available"
                  : "Set your availability"}
              </Text>
              <Image style={styles.pencilImage} source={images.pencil} />
            </TouchableOpacity>
          ) : (
            notgoing && (
              <View
                style={[styles.absoluteLine, styles.notGoingLine]}
                pointerEvents="none">
                <Text style={styles.availableText}>Not going</Text>
              </View>
            )
          )}
        </View>
      )
    })}
  </View>
)
UserAvatarsLayer.propTypes = {
  people: propTypes.arrayOf(propTypes.shape({})).isRequired,
  isDragged: propTypes.bool.isRequired,
  userId: propTypes.number.isRequired,
  onSetAvailability: propTypes.func.isRequired,
}

const StartsWhenFlag = ({ startsWhenGameplan }) => {
  if (!startsWhenGameplan.conditionsMet) {
    return null
  }
  let flag = null
  timeline.forEach(tick => {
    const currentMinutes = tick * TICK_MINUTES
    const start = {
      hours: getHour(startsWhenGameplan.conditionTime),
      minutes: getMinutes(startsWhenGameplan.conditionTime),
    }
    const startMinutes = start.hours * 60 + start.minutes
    const additionalPosition = startMinutes % 15 ? 12 : 0
    if (
      startMinutes >= currentMinutes &&
      startMinutes < currentMinutes + TICK_MINUTES
    ) {
      flag = (
        <Image
          style={[styles.flagImage(tick, additionalPosition), styles.flagLayer]}
          source={images.startsWhenGoal}
        />
      )
    }
  })
  return flag
}
StartsWhenFlag.propTypes = {
  startDate: propTypes.string.isRequired,
}

const StartsWhenCarouselComponent = ({
  scrollRef,
  startDate,
  isDragged,
  people,
  userId,
  onContentSizeChange,
  onSetAvailability,
  onScrollBeginDrag,
  onScrollEndDrag,
  startsWhenGameplan,
}) => {
  return (
    <>
      <ScrollView
        ref={scrollRef}
        onContentSizeChange={onContentSizeChange}
        showsHorizontalScrollIndicator={false}
        horizontal
        onScrollBeginDrag={onScrollBeginDrag}
        onScrollEndDrag={onScrollEndDrag}>
        <View>
          <View style={styles.headerLine}>
            <HeaderBlocks />
            <TimeLabelsLine />
          </View>

          <UsersAvailabilityLines
            {...{
              onSetAvailability,
              userId,
              people,
              startDate,
              startsWhenGameplan,
            }}
          />
        </View>
      </ScrollView>

      <UserAvatarsLayer
        isDragged={isDragged}
        people={people}
        userId={userId}
        onSetAvailability={onSetAvailability}
      />
    </>
  )
}
StartsWhenCarouselComponent.propTypes = {
  scrollRef: propTypes.shape({}),
  startDate: propTypes.string,
  isDragged: propTypes.bool,
  people: propTypes.arrayOf(propTypes.shape({})).isRequired,
  userId: propTypes.number,

  onContentSizeChange: propTypes.func,
  onSetAvailability: propTypes.func,
  onScrollBeginDrag: propTypes.func,
  onScrollEndDrag: propTypes.func,
  startsWhenGameplan: propTypes.object,
}

export default StartsWhenCarouselComponent
