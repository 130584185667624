import React, { createContext, useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import ApiCalendar from "react-google-calendar-api"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  linkGoogleCalendar,
  newOutlookLink,
  resetSingleCalendar,
  unlinkExternalCalendar,
} from "@actions/calendarActions"
import { loginProviders } from "@config/enums"
import {
  selectPrimaryGoogleCalendar,
  selectPrimaryOutlookCalendar,
  selectSyncEmail,
  selectDisplayCalendar,
} from "@selectors/calendar"
import { selectCalendarSyncSettings, selectUserInfo } from "@selectors/user"
import {
  changeSyncCalendar,
  fetchCalendarEvents,
  undisplayRemovedCalendars,
  unlinkCalendarEvents,
} from "@utils/calendarSyncUtils"
import { CALENDAR_TYPES } from "@utils/calendarUtils"
import { getExternalCalendars } from "@utils/getExternalCalendars"

import {
  useLinkingSuccessMessage,
  useLinkingModal,
  useCalendarControl,
  useSyncLinkedCalendar,
  useLinkingError,
} from "./hooks"

export const CalendarSettingsContext = createContext({})

const CalendarSettingsState = ({
  children,
  userInfo,
  googlePrimaryCalendar,
  outlookPrimaryCalendar,
  resetSingleCalendar,
  syncEmail,
  calendarSyncSettings,
  calendarDisplay,
}) => {
  console.log("googlePrimaryCalendar", googlePrimaryCalendar)
  const { getAccessTokenWithPopup } = useAuth0()
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [isOutlookLoading, setIsOutlookLoading] = useState(false)
  const [linkingSuccessType, setLinkingSuccessType] = useState(null)
  const [unlinkLoadingType, setUnlinkLoadingType] = useState(null)

  useEffect(() => {
    // Wait for the user to be authenticated before fetching the calendar events
    setTimeout(() => {
      fetchCalendarEvents({})
    }, 0)
  }, [])

  const getAccessToken = ({ connection, connection_scope }) => {
    return getAccessTokenWithPopup({
      connection,
      connection_scope,
      prompt: "consent",
      access_type: "offline",
    })
      .then(res => res)
      .catch(() => {
        switch (connection) {
          case loginProviders.google:
            setIsGoogleLoading(false)
            break
          case loginProviders.windowslive:
            setIsOutlookLoading(false)
            break
          default:
            break
        }

        setLinkingError("User canceled the operation.")
      })
  }

  const { setSyncingType } = useSyncLinkedCalendar({
    syncEmail,
    googlePrimaryCalendar,
    outlookPrimaryCalendar,
    selectedCalendars: calendarSyncSettings?.selectedCalendars,
  })

  const { catchLinkingErrorMessage } = useLinkingError()

  const handleAddGoogleCalendar = async event => {
    event.preventDefault()
    setIsGoogleLoading(true)

    const accessToken = await getAccessToken({
      connection: loginProviders.google,
      connection_scope: "https://www.googleapis.com/auth/calendar",
    })
    if (!accessToken) return

    try {
      await linkGoogleCalendar(accessToken)

      setSyncingType(loginProviders.google)
      fetchCalendarEvents({
        isGoogleLinking: true,
      })
      setIsLinkingModal(false)
      setIsGoogleLoading(false)
      setLinkingSuccessType(loginProviders.google)
    } catch (error) {
      const errorMessage = catchLinkingErrorMessage(error)

      setLinkingError(errorMessage)
      setIsLinkingModal(false)
      setIsGoogleLoading(false)
    }
  }

  const handleAddMSCalendar = async event => {
    event.preventDefault()
    setIsOutlookLoading(true)

    const accessToken = await getAccessToken({
      connection: loginProviders.windowslive,
      connection_scope: "https://graph.microsoft.com/Calendars.ReadWrite",
    })

    if (!accessToken) return

    try {
      await newOutlookLink(accessToken)

      setSyncingType(loginProviders.windowslive)
      setIsLinkingModal(false)
      setIsOutlookLoading(false)
      setLinkingSuccessType(loginProviders.windowslive)
    } catch (error) {
      const errorMessage = catchLinkingErrorMessage(error)

      setLinkingError(errorMessage)
      setIsLinkingModal(false)
      setIsOutlookLoading(false)
    }
  }

  const unsyncCalendar = isSynced => {
    if (isSynced) {
      changeSyncCalendar(null)
    }
  }

  const handleRemoveCalendar = async () => {
    const { calendars } = userInfo || {}
    const { googleCalendar, outlookCalendar } = getExternalCalendars(calendars)
    const { calendarId, calendarType } = unlinkCalendar
    await undisplayRemovedCalendars(calendarType)
    const isSynced =
      syncEmail?.calendarEmailId === unlinkCalendar.calendarId &&
      syncEmail?.calendarType === unlinkCalendar.calendarType

    setUnlinkLoadingType(calendarType)
    unsyncCalendar(isSynced)

    switch (calendarType) {
      case CALENDAR_TYPES.google:
        await unlinkExternalCalendar(googleCalendar.id)
        ApiCalendar.handleSignoutClick()
        resetSingleCalendar({ calendarId, calendarType: CALENDAR_TYPES.google })
        break
      case CALENDAR_TYPES.outlook:
        await unlinkExternalCalendar(outlookCalendar.id)
        await unlinkCalendarEvents(calendarId)

        resetSingleCalendar({
          calendarId,
          calendarType: CALENDAR_TYPES.outlook,
        })
        break
    }

    setUnlinkLoadingType(null)
  }

  const { linkingSuccessMessage, setLinkingSuccessMessage } =
    useLinkingSuccessMessage({
      linkingSuccessType,
      googlePrimaryCalendar,
      outlookPrimaryCalendar,
    })

  const addCalendarControl = useCalendarControl({
    googlePrimaryCalendar,
    outlookPrimaryCalendar,
  })

  const closeModals = () => {
    setIsLinkingModal(false)
    setLinkingError("")
    setLinkingSuccessMessage("")
    setLinkingSuccessType(null)
    setUnlinkCalendar(null)
  }

  const {
    setIsLinkingModal,
    linkingModalData,
    linkingError,
    setLinkingError,
    unlinkCalendar,
    setUnlinkCalendar,
  } = useLinkingModal({
    linkingSuccessMessage,
    closeModals,
    handleRemoveCalendar,
  })

  return (
    <CalendarSettingsContext.Provider
      value={{
        setIsLinkingModal,
        isGoogleLoading,
        isOutlookLoading,
        linkingError,
        linkingSuccessMessage,
        linkingModalData,
        googlePrimaryCalendar,
        outlookPrimaryCalendar,
        unlinkCalendar,
        unlinkLoadingType,
        addCalendarControl,
        setLinkingError,
        handleAddGoogleCalendar,
        handleAddMSCalendar,
        closeModals,
        setUnlinkCalendar,
      }}>
      {children}
    </CalendarSettingsContext.Provider>
  )
}

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state),
  googlePrimaryCalendar: selectPrimaryGoogleCalendar(state),
  outlookPrimaryCalendar: selectPrimaryOutlookCalendar(state),
  syncEmail: selectSyncEmail(state),
  calendarSyncSettings: selectCalendarSyncSettings(state),
  calendarDisplay: selectDisplayCalendar(state),
})

const mapDispatchToProps = dispatch => ({
  resetSingleCalendar: bindActionCreators(resetSingleCalendar, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSettingsState)
