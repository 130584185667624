import React from "react"

import { Wrapper, BackIcon, Text } from "./headerStyles"

export const Header = ({ title, onClose }) => (
  <Wrapper onClick={onClose}>
    <BackIcon name="arrow-2" color="var(--gray6)" />

    <Text>{title}</Text>
  </Wrapper>
)
