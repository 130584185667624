import { useEffect } from "react"

export const useResetLoginLoader = ({
  isAuthenticated,
  isLoading,
  isLoginInProgress,
  isRegistrationInProgress,
  setIsLoginInProgress,
  setIsRegistrationInProgress,
}) => {
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      if (isLoginInProgress) {
        setIsLoginInProgress(false)
      }
      if (isRegistrationInProgress) {
        setIsRegistrationInProgress(false)
      }
    }
  }, [isAuthenticated, isLoading, isLoginInProgress, isRegistrationInProgress])
}
