import React from "react"

import PropTypes from "prop-types"

import { List, ListItem, ItemImage } from "./photosListStyles"

export const PhotosList = ({ selectedPhotoId, previewList, onSelect }) => {
  return (
    <List>
      {previewList.map((item, index) => (
        <ListItem
          $isSelected={selectedPhotoId === item.id}
          onClick={() => onSelect(item)}
          key={index}>
          <ItemImage src={item.urls.thumb} alt={item.alt_description} />
        </ListItem>
      ))}
    </List>
  )
}

PhotosList.propTypes = {
  selectedPhotoId: PropTypes.string,
  previewList: PropTypes.array,
  onSelect: PropTypes.func,
}
