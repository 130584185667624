import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"

export const ImageBackground = styled.div`
  ${({ $isCreateTeeup }) => css`
    width: 80px;
    height: 80px;
    background-color: var(--white);
    border: 2px solid var(--white);
    border-radius: 8px;
    box-shadow: var(--grayShadow);

    overflow: hidden;
    z-index: 9;

    ${$isCreateTeeup &&
    css`
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    `};
  `};
`

export const ImageWrapper = styled.div`
  ${({ $image }) => css`
    width: 100%;
    height: 100%;
    ${$image
      ? css`
          background: url(${$image}) center/cover no-repeat;
        `
      : css`
          background-color: var(--white);
        `};

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`

export const ImageTemplate = styled(SvgIcon)`
  width: 36px;
  height: 36px;
`
