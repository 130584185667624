import React from "react"

import { Caption } from "../../../assets/styles/typographyStyles"

export const Description = () => (
  <Caption
    style={{
      color: "#fff",
      textAlign: "center",
      marginTop: 20,
      marginBottom: 40,
    }}>
    Spend less time coordinating, and more time meeting.
  </Caption>
)
