import { SvgIcon } from "@components/common"

import "./index.scss"
import { Platform as IPlatform } from "types/suggestions"

interface Props {
  platform: IPlatform
  onClick: (platform: IPlatform) => void
}

const Platform = ({ platform, onClick }: Props) => {
  return (
    <li onClick={() => onClick(platform)} className="suggestion-where-platform">
      <div>
        <SvgIcon name={platform.iconName} />
      </div>
      <span>{platform.label}</span>
    </li>
  )
}

export default Platform
