import React, { Component } from "react"

import _ from "lodash"
import moment from "moment-timezone"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { View, Text } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setTeeupActive } from "@actions/activeTeeupActions"
import { dispatchSections } from "@actions/calendarActions"
import CalendarListItem from "@components/calendar/calendarListItem"
import TeeupItem from "@components/teeupList/TeeupItem"
import { teeupStatusKeys } from "@configs/enums"
import {
  selectLatestTeeupEventDate,
  selectTeeupEventsByDay,
  selectTeeupEvents,
  selectDisplayCalendar,
  selectCalendarEmails,
} from "@selectors/calendar"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
  selectActiveTeeups,
  selectArchivedTeeups,
  selectRemovedTeeups,
} from "@selectors/teeups"
import { selectTeeupsStateTeeups } from "@selectors/teeupsState"
import {
  selectUserInfo,
  selectUserId,
  selectCalendarSyncSettings,
} from "@selectors/user"
import { Units, AppSizes } from "@theme/"
import {
  addDayAtStartOfDay,
  dateComparator,
  dayDifferenceBetweenDates,
  formatComingUpHeader,
  isToday,
  isTomorrow,
} from "@utils/dateUtils"
// import teeupActions from '@actions/teeupActions'
// import { routeTeeup } from '@nav'

import { CalendarStyles } from "./calendarCardStyles"
import CalendarCardView from "./calendarCardView"

// TODO: here was "moment" if everything is okay - remove this comment

import "./index.scss"
import { EVENTS, logEvent } from "analytics-tracking"
import {
  updateTeeupArchiveStatus,
  moveTeeupToTrash,
  archiveTeeup,
} from "@actions/teeupActions"
import CalendarState from "reducers/calendarStorage"

const sizes = {
  dragger: Units.responsiveValue(22),
  midContainer: Units.responsiveValue(81),
}

const generateHeaderDays = latestDayEvent => {
  let days = []
  const daysFromToday = dayDifferenceBetweenDates(latestDayEvent)
  let dayCount = daysFromToday > 6 ? daysFromToday : 6
  for (let i = 0; i <= dayCount; i++) {
    days.push(addDayAtStartOfDay(undefined, i))
  }
  return days
}

const formatSections = (
  headerDays,
  teeupEventsByDay,
  teeups,
  teeupEvents,
  calendarSyncSettings,
  calendarDisplay,
  emails,
  archivedTeeups,
  removedTeeups
) => {
  let sections = []
  const showOnlyAllSet = calendarSyncSettings.syncOnlyAllSet
  sections = headerDays
    .map(day => {
      let allset = false
      let planning = false
      let happening = false
      let data = []
      const { calendarEventsByDay, calendarEvents } = CalendarState
      const t = teeupEventsByDay[day]

      if (teeupEventsByDay[day]) {
        var isArchived = archivedTeeups.filter(archivedTeeup => {
          return teeupEventsByDay[day].includes(archivedTeeup.id)
        })

        var isRemoved = removedTeeups.filter(removedTeeup => {
          return teeupEventsByDay[day].includes(removedTeeup.id)
        })

        if (isRemoved.length === 0 && isArchived.length === 0) {
          data = teeupEventsByDay[day].map(teeupEventId => {
            const itemTeeup = teeups.find(
              singleTeeup => singleTeeup.id === teeupEventId
            )
            const itemTeeupCalendar = teeupEvents[teeupEventId]

            if (itemTeeup) {
              switch (itemTeeup?.status) {
                case teeupStatusKeys.planning: {
                  planning = true

                  break
                }
                case teeupStatusKeys.allset: {
                  allset = true

                  break
                }
                case teeupStatusKeys.happening: {
                  happening = true

                  break
                }
                // No default
              }
              return { ...itemTeeupCalendar, ...itemTeeup }
            }
          })
          if (showOnlyAllSet && data.length > 0) {
            data = data.filter(item => item.status === teeupStatusKeys.allset)
          }
        }
      }
      let shouldRenderItem = false
      if (calendarEventsByDay[day]) {
        calendarEventsByDay[day].forEach(calendarEventKey => {
          const calendarEvent = calendarEvents[calendarEventKey]
          shouldRenderItem =
            calendarEvent.calendarId === "primary"
              ? emails.some(email => {
                  return (
                    email.primary &&
                    email.calendarType === calendarEvent.calendarType &&
                    calendarDisplay[email.calendarEmailId]?.selected
                  )
                })
              : emails.some(email => {
                  return (
                    email.calendarType === calendarEvent.calendarType &&
                    calendarDisplay[email.calendarEmailId]?.selected
                  )
                })

          const isExistingTeeupEvent = data.some(dataEvent => {
            return (
              dataEvent.name === calendarEvent.name &&
              dataEvent.startDate.isSame(calendarEvent.startDate)
            )
          })
          if (isExistingTeeupEvent) return

          if (
            !shouldRenderItem ||
            (showOnlyAllSet && calendarEvent.name.startsWith("[Planning]"))
          )
            return

          calendarEvent.startDate = moment(calendarEvent.startDate)
          calendarEvent.endDate = moment(calendarEvent.endDate)
          data.push(calendarEvent)
        })
      }
      data = data.sort(
        (calendarItem1, calendarItem2) =>
          calendarItem1.startDate - calendarItem2.startDate
      )

      return {
        id: day,
        title: formatTitle(day),
        data,
        status: {
          allset,
          planning,
          happening,
        },
      }
    })
    .filter(section => section.data.length)
  return sections
}

const formatTitle = day => {
  if (isToday(day)) {
    return "Today"
  } else if (isTomorrow(day)) {
    return "Tomorrow"
  } else {
    return formatComingUpHeader(day)
  }
}

class CalendarCardContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inviteContent: null,
    }
    const headerDays = generateHeaderDays(props.latestEventDate)
    this.TOGGLE_THRESHOLD = 500 / 11
    const topPositionThreshold =
      AppSizes.statusBarHeight + Units.responsiveValue(17)
    this.comingUpHeaderRef = React.createRef()

    this.BOTTOM_POSITION = {
      x: 0,
      y: props.contentHeight,
    }

    this.UP_POSITION = {
      x: 0,
      y: topPositionThreshold,
    }

    this.MIDDLE_POSITION = {
      x: 0,
      y: props.contentHeight - sizes.dragger - sizes.midContainer,
    }

    this.state = {
      headerDays,
      currentPosition: props?.newSuggestionProps
        ? this.BOTTOM_POSITION
        : this.MIDDLE_POSITION,
      sections: [
        {
          title: "",
          data: [],
          status: {},
        },
      ],
      // activeDayHeader: "",
      teeupEvents: {},
      teeups: props.teeups,
    }
  }

  setCurrentPosition = position => {
    const { onCloseCalendarCard } = this.props
    this.setState({ currentPosition: position })

    if (position === this.UP_POSITION) {
      this.toggleNavBar("top")
    } else {
      this.toggleNavBar()
    }
    if (position === this.BOTTOM_POSITION && onCloseCalendarCard) {
      onCloseCalendarCard()
    }
  }

  componentDidUpdate(prevProps) {
    const { isCalendarShown } = this.props
    if (prevProps.isCalendarShown !== isCalendarShown) {
      this.setCurrentPosition(
        isCalendarShown ? this.UP_POSITION : this.BOTTOM_POSITION
      )
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = null
    if (
      nextProps.teeupEvents !== state.teeupEvents ||
      nextProps.teeups !== state.teeups
    ) {
      const sections = formatSections(
        state.headerDays,
        nextProps.teeupEventsByDay,
        nextProps.teeups,
        nextProps.teeupEvents,
        nextProps.calendarSyncSettings,
        nextProps.calendarDisplay,
        nextProps.emails,
        nextProps.archivedTeeups,
        nextProps.removedTeeups
      )
      updates = {
        teeupEvents: nextProps.teeupEvents,
        sections,
        // activeDayHeader: sections[0].id,
      }
    }
    return updates
  }

  renderSectionHeader = ({ section: { title, data } }) => {
    return (
      <div className="comingUpListHeaderContainer">
        <Text
          style={[
            CalendarStyles.comingUpListHeaderText,
            data.length === 0 && CalendarStyles.comingUpListEmptyHeaderText,
          ]}>
          {title}{" "}
        </Text>
        <View style={CalendarStyles.comingUpListHeaderSeparator} />
      </div>
    )
  }

  onArchivePressed = teeup => {
    updateTeeupArchiveStatus(teeup.id)
    this.props.archiveTeeup(teeup.id)
    logEvent(EVENTS.MOVE_TEEUP_TO_ARCHIVE, { teeupId: teeup?.id })
  }

  setInviteesContent = inviteContent => {
    this.setState({ inviteContent })
  }

  renderItem = ({ item, index }) => {
    const {
      teeupsState,
      teeupParticipants,
      teeupPeople,
      teeupGameplans,
      userId,
      teeups,
    } = this.props

    if (!item) {
      return null
    }
    const { id, type } = item

    return (
      <View
        key={`calendar-teeupItem-${id}`}
        style={CalendarStyles.teeupContainer}>
        {type === "calendar" ? (
          <CalendarListItem itemData={item} />
        ) : (
          <TeeupItem
            key={`teeupItem-${id}`}
            currentUserId={userId}
            teeup={item}
            teeupState={teeupsState[id]}
            participants={_.get(teeupParticipants, id, [])}
            people={_.get(teeupPeople, id, {})}
            gameplans={_.get(teeupGameplans, id, [])}
            onPress={() => this.onTeeupPressed(item)}
            testID={`teeup-${id}`}
            isLast={index + 1 === teeups.length}
            isComingUp
            setInviteesContent={this.setInviteesContent}
            onArchivePressed={() => this.onArchivePressed(item)}
          />
        )}
      </View>
    )
  }

  onTeeupPressed = (item = {}) => {
    // const { teeupPeople, userInfo } = this.props

    // const { id } = item
    // const people = _.get(teeupPeople, id, {})
    // const user = _.get(people, userInfo.id, {})
    // if (
    //     user?.status !== teeupUserStatusKeys.droppedout &&
    //     user?.status !== teeupUserStatusKeys.skipped
    // ) {
    //     this.props.setTeeupActive(item)
    //     routeTeeup()
    // }
    setTeeupActive(item)
  }

  onViewableItemsChanged = ({ viewableItems }) => {
    if (!viewableItems[0]) {
      return
    }

    // this.setState({
    //   activeDayHeader: viewableItems[0].section.id,
    // })
  }

  scrollToDay = day => {
    let sectionIndex = this.state.sections.findIndex(
      section => section.id === day
    )
    const { current: refCurrent } = this.comingUpHeaderRef || {}
    if (refCurrent && sectionIndex > -1) {
      refCurrent.scrollToLocation({
        sectionIndex,
        itemIndex: 0,
        viewOffset: -Units.responsiveValue(16),
      })
    }
  }

  handleAndroidBackPress = () => {
    const { newSuggestionProps } = this.props
    if (this.state.currentPosition === this.UP_POSITION) {
      this.setCurrentPosition(
        newSuggestionProps ? this.BOTTOM_POSITION : this.MIDDLE_POSITION
      )
      return true
    } else {
      return false
    }
  }

  toggleNavBar = position => {
    // if (position === 'top') {
    //     Navigation.mergeOptions(this.props.parentComponentId, {
    //         statusBar: {
    //             backgroundColor: AppColors.brand.black40,
    //         },
    //     })
    // } else {
    //     Navigation.mergeOptions(this.props.parentComponentId, {
    //         statusBar: {
    //             backgroundColor: AppColors.brand.white,
    //         },
    //     })
    // }
  }

  togglePosition = () => {
    const { newSuggestionProps, onCloseCalendarCard } = this.props
    const { currentPosition } = this.state
    let newPosition = newSuggestionProps
      ? this.BOTTOM_POSITION
      : this.MIDDLE_POSITION
    if (currentPosition === this.UP_POSITION) {
      newPosition = newSuggestionProps
        ? this.BOTTOM_POSITION
        : this.MIDDLE_POSITION
    } else if (currentPosition === this.MIDDLE_POSITION) {
      newPosition = this.UP_POSITION
    }
    this.setCurrentPosition(newPosition, () => {
      if (newPosition === this.BOTTOM_POSITION && onCloseCalendarCard) {
        onCloseCalendarCard()
      }
    })

    return newPosition
  }

  toggleUp = () => {
    const { onCloseCalendarCard } = this.props
    const newPosition = this.UP_POSITION

    this.setCurrentPosition(newPosition, () => {
      if (newPosition === this.BOTTOM_POSITION && onCloseCalendarCard) {
        onCloseCalendarCard()
      }
    })

    return newPosition
  }

  setNewHeaderDays = () => {
    const { calendarDisplay, latestEventDate } = this.props
    if (!_.isEmpty(CalendarState.calendarEvents)) {
      let indexHelper = null
      const lastCalendarDayIndex = CalendarState.calendarDays.findIndex(
        CalendarDay => {
          indexHelper = dateComparator(
            this.state.headerDays.at(-1),
            CalendarDay
          )
          return indexHelper === 0 || indexHelper === -1
        }
      )
      const nextDateString =
        CalendarState.calendarDays[lastCalendarDayIndex + 10]
      if (lastCalendarDayIndex > 0 && nextDateString) {
        this.setState({
          headerDays: generateHeaderDays(nextDateString),
        })
      }
    } else if (_.isEmpty(calendarDisplay)) {
      this.setState({
        headerDays: generateHeaderDays(latestEventDate),
      })
    }
  }

  render() {
    const { headerDays, currentPosition, sections } = this.state

    const {
      contentHeight,
      newSuggestionProps,
      onCloseCalendarCard,
      activeScreen,
      bottomMargin = 0,
    } = this.props
    const { inviteContent } = this.state
    const isFromSugggestionDetails =
      !!newSuggestionProps?.isFromSugggestionDetails
    return (
      <>
        <CalendarCardView
          headerDays={headerDays}
          setHeaderDays={this.setNewHeaderDays}
          bottomMargin={bottomMargin}
          setCurrentPosition={this.setCurrentPosition}
          currentPosition={currentPosition}
          upPosition={this.UP_POSITION}
          middlePosition={this.MIDDLE_POSITION}
          bottomPosition={this.BOTTOM_POSITION}
          toggleThreshold={this.TOGGLE_THRESHOLD}
          sections={sections}
          renderSectionHeader={this.renderSectionHeader}
          renderItem={this.renderItem}
          onViewableItemsChanged={this.onViewableItemsChanged}
          // activeDayHeader={activeDayHeader}
          activeScreen={activeScreen}
          contentHeight={contentHeight}
          scrollToDay={this.scrollToDay}
          // comingUpHeaderRef={this.comingUpHeaderRef}
          togglePosition={this.togglePosition}
          toggleUp={this.toggleUp}
          newSuggestionProps={newSuggestionProps}
          onCloseCalendarCard={onCloseCalendarCard}
          isFromSugggestionDetails={isFromSugggestionDetails}
          openSettings={this.props.openSettings}
        />
        {inviteContent &&
          ReactDOM.createPortal(
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 3,
                  display: "flex",
                }}
                onClick={() => this.setInviteesContent(null)}>
                <div
                  style={{
                    width: "660px",
                    backgroundColor: "white",
                    margin: "auto",
                    borderRadius: "8px",
                  }}
                  onClick={e => e.stopPropagation()}>
                  {inviteContent}
                </div>
              </div>
            </>,
            document.querySelector("#modal-root")
          )}
      </>
    )
  }
}

CalendarCardContainer.propTypes = {
  // parentComponentId: PropTypes.string.isRequired,
  // setTeeupActive: PropTypes.func.isRequired,
  onCloseCalendarCard: PropTypes.func,
  userInfo: PropTypes.object.isRequired,
  // contentHeight: PropTypes.number.isRequired,
  isCalendarShown: PropTypes.bool,
  bottomMargin: PropTypes.number,

  teeupsState: PropTypes.object,
  teeupParticipants: PropTypes.object,
  teeupPeople: PropTypes.object,
  teeupGameplans: PropTypes.object,
  teeups: PropTypes.array,
  teeupList: PropTypes.array,
  latestEventDate: PropTypes.string,
  teeupEvents: PropTypes.object,
  teeupEventsByDay: PropTypes.object,
  activeScreen: PropTypes.string,
  archivedTeeups: PropTypes.object,
  removedTeeups: PropTypes.object,
  // newSuggestionProps: PropTypes.oneOfType([
  //     PropTypes.string,
  //     PropTypes.shape({
  //         duration: PropTypes.number,
  //         passedEvent: PropTypes.object,
  //         isFromCreateTeeup: PropTypes.bool,
  //         selectedDate: PropTypes.oneOfType([
  //             PropTypes.string,
  //             PropTypes.object,
  //         ]),
  //         onDatesSelected: PropTypes.func,
  //         onPickTimerangeFromCalendar: PropTypes.func,
  //     }),
  // ]),
}

const mapStateToProps = state => {
  const latestEventDate = selectLatestTeeupEventDate(state)

  const teeupEvents = selectTeeupEvents(state)
  const teeupEventsByDay = selectTeeupEventsByDay(state)
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const calendarDisplay = selectDisplayCalendar(state)
  const emails = selectCalendarEmails(state)

  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const teeupsState = selectTeeupsStateTeeups(state)
  const userInfo = selectUserInfo(state)
  const teeups = selectActiveTeeups(state)
  const archivedTeeups = selectArchivedTeeups(state)
  const removedTeeups = selectRemovedTeeups(state)
  const userId = selectUserId(state)

  return {
    teeupParticipants,
    teeupPeople,
    teeupGameplans,
    teeupsState,
    userInfo,
    teeups: [...teeups, ...archivedTeeups],
    latestEventDate,
    teeupEvents,
    teeupEventsByDay,
    userId,
    calendarSyncSettings,
    calendarDisplay,
    emails,
    archivedTeeups,
    removedTeeups,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchSections: bindActionCreators(dispatchSections, dispatch),
  archiveTeeup: bindActionCreators(archiveTeeup, dispatch),
  moveTeeupToTrash: bindActionCreators(moveTeeupToTrash, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarCardContainer)
