import TabContext from "@mui/lab/TabContext"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"

import { Tab, TabList } from "@components/ui/Tabs"
import {
  selectSuggestionsWhen,
  selectSuggestionsWhere,
} from "@selectors/activeTeeup"
import { selectTeeupReactionsByUserId } from "@selectors/teeups"
import { DETAILS_MODAL_CONTENT } from "@utils/peopleUtils"
import { Participant } from "types/participant"

import TabItem from "./TabItem"

import "./index.scss"

interface Props {
  currentTab: string
  onTabChange: (event: React.SyntheticEvent, newValue: string) => void
  currentParticipant: Participant
}

const suggestionActions = [
  {
    value: true,
    iconName: "clock-2",
    isSelected: true,
    text: "When",
  },
  {
    value: false,
    iconName: "location-2",
    isSelected: false,
    text: "Where",
  },
]

const reactionActions = [
  {
    value: true,
    iconName: "thumb-up",
    isSelected: true,
    text: "Liked",
  },
  {
    value: false,
    iconName: "thumb-down",
    isSelected: false,
    text: "Disliked",
  },
]

const suggestionDivider = {
  iconName: "location-2",
  text: "Where",
}

const reactionDivider = {
  iconName: "thumb-down",
  text: "Disliked",
}

const Tabs = ({ currentTab, onTabChange, currentParticipant }: Props) => {
  const suggestionsWhen = useSelector(selectSuggestionsWhen)
  const suggestionsWhere = useSelector(selectSuggestionsWhere)
  const { liked, disliked } = useSelector(
    selectTeeupReactionsByUserId(
      currentParticipant.id,
      suggestionsWhen,
      suggestionsWhere
    )
  )

  return (
    <div className="people-details-modal-tabs">
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={onTabChange}>
            <Tab
              label="Suggestions"
              value={DETAILS_MODAL_CONTENT.SUGGESTIONS}
            />
            <Tab label="Reactions" value={DETAILS_MODAL_CONTENT.REACTIONS} />
          </TabList>
        </Box>

        <TabPanel value={DETAILS_MODAL_CONTENT.SUGGESTIONS}>
          <TabItem
            actions={suggestionActions}
            firstContent={suggestionsWhen}
            divider={suggestionDivider}
            secondContent={suggestionsWhere}
          />
        </TabPanel>
        <TabPanel value={DETAILS_MODAL_CONTENT.REACTIONS}>
          <TabItem
            actions={reactionActions}
            firstContent={liked}
            divider={reactionDivider}
            secondContent={disliked}
          />
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default Tabs
