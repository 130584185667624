import React, { useEffect, useState } from "react"

import { AiOutlineLeft } from "react-icons/ai"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
  loginEmail,
  cleanLoginError,
  setAuth0Error,
} from "@actions/loginActions"
import { Caption } from "@assets/styles/typographyStyles"
import { setItem } from "@utils/localStorage"

import { logScreen, SCREENS } from "../../analytics-tracking"
import { AuthLayout } from "../../hoc"
import { useFormValidation } from "../../hooks"
import {
  InputEl,
  CheckboxEl,
  FormErrorMessage,
  ForgotPasswordLink,
} from "../common"

import { Auth0ErrorLogin } from "./atoms"
import { LOGIN_ERRORS } from "./constants"
import { getLoginErrorsMessages } from "./helpers"
import {
  SignInFormWrapper,
  SignInForm,
  FormFooter,
  Form,
  BackWrapper,
  SubmitButtons,
} from "./molecules"
import { newSignInSchema } from "./validationSchema"

const NewSignInForm = ({
  loginEmail,
  cleanLoginError,
  loginErrorCode,
  isLoginLoading,
  auth0Type,
  auth0Error,
  setAuth0Error,
}) => {
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [isRememberMe, setIsRememberMe] = useState(true)
  const [password, setPassword] = useState("")
  const { validationErrors } = useFormValidation(newSignInSchema, {
    email,
    password,
  })

  const { ACCOUNT_DOES_NOT_EXIST, INCORRECT_PASSWORD } = LOGIN_ERRORS

  useEffect(() => {
    if (loginErrorCode) {
      cleanLoginError()
    }
  }, [email, password])

  const handleSubmit = e => {
    e.preventDefault()

    loginEmail(email, password)
    setItem("rememberMe", isRememberMe)
  }

  useEffect(() => {
    if (auth0Error?.errorEmail) {
      setEmail(auth0Error?.errorEmail)
    }
  }, [auth0Error])

  const getTitle = () => {
    return auth0Error ? auth0Error?.errorTitle : "Log in with Email"
  }

  useEffect(() => {
    logScreen(SCREENS.SIGN_IN, { type: "email" })
  }, [])

  const onRememberHandle = () => {
    setIsRememberMe(prevState => !prevState)
  }

  const isSubmitDisabled = () => {
    return validationErrors || isLoginLoading || loginErrorCode
  }

  useEffect(() => {
    return () => {
      setAuth0Error(null)
    }
  }, [])

  return (
    <SignInFormWrapper>
      <Form onSubmit={handleSubmit}>
        <AuthLayout isEmail={false} title={getTitle()}>
          <SignInForm>
            <InputEl
              isHidden={!!auth0Error}
              label="Email Address"
              name="email"
              value={email}
              placeholder="email@email.com"
              onChange={setEmail}
              isError={loginErrorCode === ACCOUNT_DOES_NOT_EXIST}
            />

            <InputEl
              type="password"
              label="Password"
              name="password"
              value={password}
              placeholder="******"
              onChange={setPassword}
              isError={loginErrorCode === INCORRECT_PASSWORD}
            />

            <FormFooter>
              <CheckboxEl
                label="Remember me"
                name="isRememberMe"
                value={isRememberMe}
                onChange={onRememberHandle}
              />

              <ForgotPasswordLink />
            </FormFooter>
            {loginErrorCode && (
              <FormErrorMessage
                errorMessage={getLoginErrorsMessages(loginErrorCode, auth0Type)}
              />
            )}

            {!!auth0Error && (
              <Auth0ErrorLogin email={auth0Error?.errorEmail || ""} />
            )}
          </SignInForm>
          <SubmitButtons
            isSsoError={!!auth0Error}
            isLoginLoading={isLoginLoading}
            loginErrorCode={loginErrorCode}
            isSubmitDisabled={isSubmitDisabled()}
          />
        </AuthLayout>
        <BackWrapper onClick={() => history.push("/sign-in")}>
          <AiOutlineLeft />
          <Caption>All Login Options</Caption>
        </BackWrapper>
      </Form>
    </SignInFormWrapper>
  )
}

const mapStateToProps = ({ user }) => ({
  loginErrorCode: user.loginErrorCode,
  isLoginLoading: user.isEmailLoginLoading,
  auth0Type: user.auth0Type,
  auth0Error: user.auth0Error,
})

const mapDispatchToProps = dispatch => ({
  loginEmail: bindActionCreators(loginEmail, dispatch),
  cleanLoginError: bindActionCreators(cleanLoginError, dispatch),
  setAuth0Error: bindActionCreators(setAuth0Error, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewSignInForm)
