export const REACTION_TYPE = {
  LOVE: 1,
  UPVOTE: 2,
  DOWNVOTE: 3,
  NEUTRAL: 4,
  NOT_VOTE: 5,
  X: 6,
  QUESTION: 7,
  CHECK_MARK: 8,
}
