import styled from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.div`
  background-color: var(--lightGray4);
  padding: 0 20px 80px 20px;
  margin-top: 20px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding: 0 5px 80px 5px;
  }

  .rbc-time-header {
    display: none;
  }
  .rbc-time-view,
  .rbc-time-content,
  .rbc-time-gutter .rbc-timeslot-group {
    border: none;
  }
  .rbc-time-gutter .rbc-time-slot .rbc-label {
    color: var(--gray);
  }
  .rbc-day-slot {
    border-top: 1px solid var(--lightGray5);
  }
  .rbc-events-container,
  .rbc-timeslot-group {
    border-color: var(--lightGray5);
    margin-right: 0;
  }
  .rbc-timeslot-group {
    min-height: 160px;
  }
  .rbc-day-slot .rbc-timeslot-group {
    background-color: var(--white);
  }
  .rbc-day-slot .rbc-event-content {
    display: flex;
    align-items: center;
    & > div {
      width: 100%;
    }
  }
  .rbc-addons-dnd-resizable {
    width: 100%;
    height: 100%;
  }
  .rbc-ev-conflict {
    width: 50% !important;
  }
  .rbc-ev-clear {
    width: 100% !important;
    left: 0 !important;
  }
  .rbc-ev-left-pos {
    left: 0 !important;
  }
  .rbc-ev-right-pos {
    left: 50% !important;
  }

  // Resizing anchors styles
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    overflow: unset !important;
  }
  .rbc-addons-dnd-resize-ns-icon {
    display: block !important;
    background-color: var(--white);
    width: 26px !important;
    height: 6px;
    border-radius: 8px;
    border: 1px solid var(--darkBlue) !important;

    @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
      width: 50px !important;
      height: 30px;
    }
  }
  .rbc-addons-dnd-resize-ns-anchor:first-child {
    top: -6px;
  }
  .rbc-addons-dnd-resize-ns-anchor:last-child {
    bottom: -6px;
  }
  .rbc-ev-no-anchor .rbc-addons-dnd-resize-ns-icon,
  .rbc-ev-no-anchor:hover .rbc-addons-dnd-resize-ns-icon {
    display: none !important;
  }
`
