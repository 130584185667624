import React from "react"

import { connect } from "react-redux"

import { selectAllContacts } from "@selectors/contacts"
import { selectTeeupsPeople } from "@selectors/teeups"

import Contacts from "../../components/me/Contacts"

const CooEVerse = ({
  teeupPeople,
  handleOnInviteesDone,
  setContent,
  close,
  fromContact,
  cooEVerse,
  contacts,
  fromCreateTeeup,
  invitees,
  setInvitees,
  inviteToNewTeeup,
  closeOptionWithAnimations,
  fromTeeupTooltip,
  isTeeupInvite,
}) => {
  const getUniquePeopleFromUsersTeeups = () => {
    const peopleFromAllTeeups = []
    Object.values(teeupPeople).map(teeup =>
      peopleFromAllTeeups.push(...Object.values(teeup))
    )

    let map = new Map()
    for (let person of peopleFromAllTeeups) {
      map.set(person.id, person)
    }

    const uniquePeople = []
    map.forEach(person => uniquePeople.push(person))

    return uniquePeople
  }

  const combineContactsAndConnections = () => {
    const combinedPeople = getUniquePeopleFromUsersTeeups()

    for (let i = 0; i < combinedPeople.length; i++) {
      for (const contact of contacts) {
        if (combinedPeople[i].id === contact.id) combinedPeople[i] = contact
      }
    }

    return combinedPeople
  }

  return (
    <div style={{ margin: "auto" }}>
      <Contacts
        combinedPeople={combineContactsAndConnections()}
        handleOnInviteesDone={handleOnInviteesDone}
        setContent={setContent}
        close={close}
        cooEVerse={cooEVerse}
        fromContact={fromContact}
        fromCreateTeeup={fromCreateTeeup}
        setInvitees={setInvitees}
        inviteToNewTeeup={inviteToNewTeeup}
        closeOptionWithAnimations={closeOptionWithAnimations}
        invitees={invitees}
        fromTeeupTooltip={fromTeeupTooltip}
        isTeeupInvite={isTeeupInvite}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const teeupPeople = selectTeeupsPeople(state)
  const contacts = selectAllContacts(state)
  return {
    teeupPeople,
    contacts,
  }
}

export default connect(mapStateToProps, null)(CooEVerse)
