import { useMemo } from "react"

import { teeupUserGenericStatus } from "@config/enums"

export const useStatusesList = gameplanInfo => {
  return useMemo(
    () => [
      {
        title: "I'm Going",
        imgName: "going",
        index: teeupUserGenericStatus.reverse[teeupUserGenericStatus.going],
        isShown: !!gameplanInfo?.when.decidedSuggestion,
      },
      {
        title: teeupUserGenericStatus.mightgo,
        imgName: "might-go",
        index: teeupUserGenericStatus.reverse[teeupUserGenericStatus.mightgo],
        isShown: !!gameplanInfo?.when.decidedSuggestion,
      },
      {
        title: teeupUserGenericStatus.notgoing,
        imgName: "not-going",
        index: teeupUserGenericStatus.reverse[teeupUserGenericStatus.notgoing],
        isShown: !!gameplanInfo?.when.decidedSuggestion,
      },
      {
        title: teeupUserGenericStatus.interested,
        imgName: "new-interested",
        index:
          teeupUserGenericStatus.reverse[teeupUserGenericStatus.interested],
        isShown: !gameplanInfo?.when.decidedSuggestion,
      },
      {
        title: teeupUserGenericStatus.notInterested,
        imgName: "not-interested",
        index:
          teeupUserGenericStatus.reverse[teeupUserGenericStatus.notInterested],
        isShown: !gameplanInfo?.when.decidedSuggestion,
      },
    ],
    [gameplanInfo]
  )
}
