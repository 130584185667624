import React from "react"

import PropTypes from "prop-types"

export const ErrorMessage = ({ errorMessage }) => (
  <div
    className="auth0-errors__messages"
    dangerouslySetInnerHTML={{ __html: errorMessage }}
  />
)

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}
