import React, { useContext } from "react"

import { MODAL_STATUSES } from "@constants/modal"
import { useScreenWidth } from "@hooks"
import { NewModal } from "components"
import { Auth0ErrorContext } from "contexts/Auth0ErrorContext"

export const ErrorOauthModal = () => {
  const { errorInfo, clearErrors, isErrorModalOpen, setIsErrorModalOpen } =
    useContext(Auth0ErrorContext)
  const {
    status,
    title,
    message,
    closeAction,
    confirmAction,
    closeText,
    confirmText,
  } = errorInfo

  const { isMobile } = useScreenWidth()

  const handleClose = (event, isClear = true) => {
    if (isClear) {
      clearErrors()
    }
    setIsErrorModalOpen(false)
    // onClose()
  }

  const onCancel = () => {
    if (closeAction) {
      closeAction(isClear => handleClose(null, isClear))
    } else {
      handleClose()
    }
  }

  const onConfirm = () => {
    if (confirmAction) {
      confirmAction(isClear => handleClose(null, isClear))
    } else {
      handleClose()
    }
  }

  return (
    <NewModal
      isOpen={isErrorModalOpen}
      isBottom={isMobile}
      isHeader={false}
      status={status || MODAL_STATUSES.WARNING}
      title={title}
      description={message}
      onClose={handleClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText={closeText}
      confirmText={confirmText}
    />
  )
}
