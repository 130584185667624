import { getBoxInfo } from "utils/suggestionUtils"

const CalendarBox = ({ data, defaultBoxStyles, disabledBoxStyles }) => {
  const currentStyles = data.isDisabled
    ? {
        ...defaultBoxStyles,
        ...disabledBoxStyles,
        marginTop: 20,
      }
    : {
        ...defaultBoxStyles,
        ...getBoxInfo("10am").style,
        marginTop: 20,
      }

  return <div style={currentStyles}>{data.event?.title}</div>
}

export default CalendarBox
