import React from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, Image } from "react-native"
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { Units } from "@theme/"
import { images } from "@utils/imageUtils"

import teeUp from "../../../assets/images/teeUps.svg"

import EmailInput from "./emailInput"
import PhoneInput from "./phoneInput"
import styles from "./styles"

const AddContacts = ({
  isPhoneContact,
  handleDone,
  disableDoneButton,
  onClose,
  contactList,
  removeTextInputRow,
  handleTextChange,
  addTextInputRow,
  defaultCountry,
  getInitPhoneNumber,
}) => (
  <View style={styles.container}>
    <div className="create__top addinvitees__header">
      <span className="invitees__title">
        {isPhoneContact ? "Add Phone Numbers" : "Add Email"}
      </span>
      <img
        src={images.cross}
        onClick={onClose}
        style={{ height: 25, width: 25, cursor: "pointer" }}
        alt="close icon"
      />
    </div>
    <View
      style={[styles.body, { maxHeight: "70vh", overflowY: "auto" }]}
      extraScrollHeight={Units.rem(1)}
      keyboardShouldPersistTaps="handled">
      {contactList.map(({ value, id, existedUser, countryCode }, i) => {
        const isLastRow = i === contactList.length - 1
        return (
          <View
            style={[styles.marginBottom, styles.buttonStyle]}
            key={`input-${id}`}>
            <View style={styles.rowItem}>
              {isPhoneContact ? (
                <PhoneInput
                  value={value}
                  index={id}
                  handleTextChange={handleTextChange}
                  isLastRow={isLastRow}
                  defaultCountry={defaultCountry}
                  getInitPhoneNumber={getInitPhoneNumber}
                  countryCode={countryCode}
                />
              ) : (
                <EmailInput
                  value={value}
                  index={id}
                  handleTextChange={handleTextChange}
                />
              )}
              <TouchableOpacity onPress={removeTextInputRow(id)}>
                <Image source={images.iconTrash} style={styles.icon} />
              </TouchableOpacity>
            </View>
            {existedUser ? (
              <AlreadyExistedUser
                {...existedUser}
                isPhoneContact={isPhoneContact}
              />
            ) : null}
          </View>
        )
      })}
      <TouchableOpacity
        style={[
          styles.rowItem,
          styles.buttonStyle,
          { borderBottom: "1px solid #eeeeee" },
        ]}
        onPress={addTextInputRow}>
        <img
          src={images.addCircle}
          style={{ marginRight: 6, width: 16, height: 16 }}
          alt="add icon"
        />
        <Text style={styles.text}>
          {isPhoneContact
            ? "Add another phone number"
            : "Add another email address"}
        </Text>
      </TouchableOpacity>
      <div
        className="create__bottom"
        style={{ left: "50%", transform: "translateX(-50%)", border: "none" }}>
        <button
          type="button"
          style={{ background: "white" }}
          className="create__button"
          onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          className="create__button"
          onClick={handleDone}
          disabled={disableDoneButton}
          style={{ opacity: disableDoneButton ? 0.5 : 1 }}>
          Done
        </button>
      </div>
    </View>
  </View>
)
AddContacts.propTypes = {
  isPhoneContact: PropTypes.bool,
  onBlur: PropTypes.func,
  handleDone: PropTypes.func.isRequired,
  disableDoneButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  contactList: PropTypes.array,
  removeTextInputRow: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  onChooseCountry: PropTypes.func.isRequired,
  inputRefFunc: PropTypes.func,
  addTextInputRow: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
  getInitPhoneNumber: PropTypes.func,
}

const AlreadyExistedUser = ({ username, isCooe, isPhoneContact }) => (
  <View style={styles.userWrapper}>
    <Text style={styles.existedUser}>{username}</Text>
    {isCooe && <Image style={styles.cooeIcon} source={teeUp} />}
  </View>
)
AlreadyExistedUser.propTypes = {
  username: PropTypes.string.isRequired,
  isCooe: PropTypes.bool,
  isPhoneContact: PropTypes.bool,
}
export default AddContacts
