import React, { useMemo } from "react"

import PropTypes from "prop-types"

import AppleIcon from "@assets/images/apple.svg"
import FacebookIcon from "@assets/images/facebook.svg"
import GoogleIcon from "@assets/images/google.svg"
import OutlookIcon from "@assets/images/outlook-2.svg"
import TwitterIcon from "@assets/images/twitter.svg"
import { AUTH0_TYPES } from "@constants/auth0Types"

import {
  SSOButton,
  SSOButtonIcon,
  SSOButtonText,
} from "../../../assets/styles/authButtonsStyles"

export const AuthButton = ({
  text,
  dataTestId = "data-testid",
  iconType,
  onClick,
}) => {
  const iconSrc = useMemo(() => {
    switch (iconType) {
      case AUTH0_TYPES.GOOGLE:
        return GoogleIcon
      case AUTH0_TYPES.APPLE:
        return AppleIcon
      case AUTH0_TYPES.TWITTER:
        return TwitterIcon
      case AUTH0_TYPES.FACEBOOK:
        return FacebookIcon
      case AUTH0_TYPES.OUTLOOK:
        return OutlookIcon
      default:
        return ""
    }
  }, [])

  return (
    <SSOButton data-testid={dataTestId} onClick={onClick}>
      <SSOButtonIcon src={iconSrc} alt={text} />

      <SSOButtonText>{text}</SSOButtonText>
    </SSOButton>
  )
}

AuthButton.propTypes = {
  text: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
}
