import React, { PureComponent } from "react"

import find from "lodash/find"
import PropTypes from "prop-types"

import {
  teeupUserGenericStatus,
  teeupStatus,
  teeupNotifications,
} from "@configs/enums"
import {
  statusTypeIds,
  reactionsTranslations,
  reactionsConductNaming,
  teeupStatusTypes,
  teeupNotificationsTypes,
  userStatusTypes,
  conversationTypeIds,
} from "@configs/mappings"
import { getSuggestionValue } from "@utils/gamePlanUtils"
import { isWhenWorksReaction } from "@utils/messagesUtils"
import {
  getCritmassGoingCount,
  isShowAdditionalPeople,
  getSuffixForAdditionalPeople,
  userStatusSystemMessageImage,
} from "@utils/teeupUtils"

import InitialMessage from "./initialMessage"
import ReactionMessage from "./reactionMessage"
import TitleMessage from "./titleMessage"

const handlePrefixUse = ({ sendersLength, userStatusTrans }) => {
  const isPrefixUsed =
    teeupUserGenericStatus.going === userStatusTrans ||
    teeupUserGenericStatus.notgoing === userStatusTrans ||
    teeupUserGenericStatus.onmyway === userStatusTrans

  return isPrefixUsed ? (sendersLength === 1 ? "is" : "are") : ""
}

export default class StatusMessage extends PureComponent {
  onExpandPress = () => {
    const { item, index, onStatusMessagePress } = this.props
    onStatusMessagePress(item.id, index)
  }

  getParticipants = (ids, item) => {
    const { people } = this.props

    let senders = []
    let sender = people[ids]
    if (sender) {
      senders.push(sender)
    } else if (Array.isArray(ids)) {
      ids.forEach((senderId, index) =>
        people[senderId]
          ? senders.push({
              ...people[senderId],
              timestamp: item.timestamps[index],
            })
          : null
      )
    }
    return senders.reverse()
  }

  render = () => {
    const {
      people,
      suggestions,
      standAlone,
      handleOnLongPress,
      item,
      teeupTitle,
      permissions,
      expandedStatusMessagesArray,
      isTeeupCancelled,
      settings,
      isMyMessage,
      newDesign,
    } = this.props
    const { userId, senderId, isSystem, image } = item

    const senders = this.getParticipants(senderId, item)
    const invites = this.getParticipants(userId, item)
    const isStatusMessage =
      item.conversationTypeId === conversationTypeIds.status

    const isImageChange =
      item.conversationTypeId === conversationTypeIds.imageChange

    const isInitial = item.conversationTypeId === conversationTypeIds.initial
    let creatorUsername = ""
    if (senders && senders.length > 0) {
      creatorUsername = senders[0].username || senders[0].name
    }
    let text = ""
    let userStatusText = ""
    let icon = null

    let expanded = find(
      expandedStatusMessagesArray,
      expandedId => expandedId === item.id
    )

    if (
      item.conversationTypeId !== conversationTypeIds.initial &&
      item.typeId === statusTypeIds.teeup
    ) {
      if (senders[0]) {
        text += creatorUsername
      }
    } else if (
      item.conversationTypeId !== conversationTypeIds.initial &&
      item.typeId === statusTypeIds.invite &&
      people[senderId]
    ) {
      let senderName = people[senderId].username || people[senderId].name

      text += `${senderName} invited `

      invites.map((invite, index) => {
        let inviteName = invite.username || invite.name

        text += inviteName + (index === invites.length - 1 ? " " : ", ")
      })
    } else if (isImageChange) {
      let senderName = people[senderId].username || people[senderId].name
      text += `${senderName} changed the background picture `
    } else {
      senders.forEach((participant, index) => {
        let participantName = participant.username || participant.name

        text += participantName + (index === senders.length - 1 ? " " : ", ")
      })
    }

    if (isInitial) {
      return (
        <InitialMessage
          teeupTitle={teeupTitle}
          item={item}
          senders={senders}
          people={people}
          suggestions={suggestions}
          handleOnLongPress={handleOnLongPress}
          permissions={permissions}
          expanded={expanded}
          onPress={this.onExpandPress}
          isTeeupCancelled={isTeeupCancelled}
          isMyMessage={isMyMessage}
          newDesign={newDesign}
        />
      )
    } else if (item.typeId === statusTypeIds.teeup) {
      let newStatus =
        teeupStatusTypes[item.statusId] ||
        teeupNotificationsTypes[item.statusId]
      let teeupStatusTrans =
        teeupStatus[newStatus] || teeupNotifications[newStatus]

      let userStatusesChange
      if (isSystem) {
        switch (newStatus) {
          case "allset":
            userStatusesChange = teeupUserGenericStatus["going"]

            text += `Enough people are now ${userStatusesChange}! TeeUp state set to ${teeupStatusTrans}`
            break
          case "happeningSoon": {
            text += `${item.text}. Get ready!`
            break
          }
          case "happening":
          case "ended":
            text += `TeeUp state set to ${teeupStatusTrans}`
            break
          default:
            return null
        }

        // let expanded = find(
        //   expandedStatusMessagesArray,
        //   (expandedId) => expandedId === item.id
        // )

        if (
          item.conversationTypeId !== conversationTypeIds.initial &&
          item.typeId === statusTypeIds.teeup
        ) {
          if (senders[0]) {
            text += creatorUsername
          }
        } else if (
          item.conversationTypeId !== conversationTypeIds.initial &&
          item.typeId === statusTypeIds.invite &&
          people[senderId]
        ) {
          let senderName = people[senderId].username || people[senderId].name

          text += `${senderName} invited `

          invites.map((invite, index) => {
            let inviteName = invite.username || invite.name

            text += inviteName + (index === invites.length - 1 ? " " : ", ")
          })
        } else if (isImageChange) {
          let senderName = people[senderId].username || people[senderId].name
          text += `${senderName} changed the background picture `
        } else {
          senders.forEach((participant, index) => {
            let participantName = participant.username || participant.name

            text +=
              participantName + (index === senders.length - 1 ? " " : ", ")
          })
        }
      }
      if (!isSystem && newStatus) {
        switch (newStatus) {
          case "planning":
            text += ` set the TeeUp status to Planning`
            break
          case "allset":
            text += ` set the TeeUp status to All Set`
            break
          default:
            return null
        }
      }
    } else if (item.typeId === statusTypeIds.user) {
      let userStatus = userStatusTypes[item.statusId]
      let userStatusTrans = teeupUserGenericStatus[userStatus]
      icon = userStatusSystemMessageImage(userStatus)
      const usePrefix = handlePrefixUse({
        sendersLength: senders.length,
        userStatusTrans,
      })

      const withAdditionalPeople = isShowAdditionalPeople(
        userStatus,
        item.additionalCount
      )
        ? ` ${getSuffixForAdditionalPeople(userStatus)} ${
            item.additionalCount
          } others`
        : ""

      text += usePrefix
      userStatusText = userStatusTrans + withAdditionalPeople

      switch (userStatus) {
        case "going":
        case "arrived":
        case "onmyway":
          if (settings && settings.allowAutoScheduled) {
            const goingCount = getCritmassGoingCount(
              Object.keys(people),
              people
            )
            const peopleNeeded = settings.criticalMassAmount - goingCount
            if (peopleNeeded > 0) {
              userStatusText += `. ${peopleNeeded} more needed!`
            }
          }
          break
        case "joined":
          text += "joined"
          break
        default:
      }
    } else if (isWhenWorksReaction(item)) {
      text += "responded to the WhenWorks poll"
    } else
      switch (item.typeId) {
        case statusTypeIds.reaction: {
          let suggestion = suggestions[item.suggestionId]

          let suggestionValue = suggestion
            ? getSuggestionValue(suggestion)?.full
            : "unknown"

          text += `${reactionsConductNaming[item.statusId]} ${suggestionValue}`

          return (
            <ReactionMessage
              text={text}
              standAlone={standAlone}
              item={item}
              senders={senders}
              isInitial={isInitial}
              expanded={expanded}
              onPress={this.onExpandPress}
              isStatusMessage={isStatusMessage}
              isTeeupCancelled={isTeeupCancelled}
              image={image}
              isImageChange={isImageChange}
            />
          )
        }
        case statusTypeIds.retract: {
          let suggestion = suggestions[item.suggestionId]

          let suggestionValue = suggestion
            ? getSuggestionValue(suggestion).full
            : "unknown"

          text += `retracted a ${
            reactionsTranslations[item.statusId]
          } from ${suggestionValue}`

          break
        }
        case statusTypeIds.organizer: {
          text = item.text

          break
        }
        // No default
      }

    return (
      <TitleMessage
        text={text}
        item={item}
        senders={senders}
        isInitial={isInitial}
        expanded={expanded}
        onPress={this.onExpandPress}
        isStatusMessage={isStatusMessage}
        isTeeupCancelled={isTeeupCancelled}
        image={image}
        isImageChange={isImageChange}
        icon={icon}
        userStatusText={userStatusText}
      />
    )
  }
}

StatusMessage.propTypes = {
  index: PropTypes.number,
  people: PropTypes.object,
  standAlone: PropTypes.bool,
  suggestions: PropTypes.object,
  item: PropTypes.object,
  teeupTitle: PropTypes.string,
  permissions: PropTypes.object,
  onStatusMessagePress: PropTypes.func,
  handleOnLongPress: PropTypes.func,
  expandedStatusMessagesArray: PropTypes.array,
  isTeeupCancelled: PropTypes.bool,
  settings: PropTypes.object,
}
