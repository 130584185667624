import { Dayjs } from "dayjs"
import { create } from "zustand"

import { TimeWheelSuggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

interface SuggestionTimeWheelStore {
  currentDate: dayjs.Dayjs | null
  setCurrentDate: (currentDate: dayjs.Dayjs) => void
  suggestions: TimeWheelSuggestion[]
  addSuggestion: (suggestion: TimeWheelSuggestion) => void
  removeSuggestion: (index: number) => void
  updateSuggestion: (suggestion: TimeWheelSuggestion, index: number) => void
  currentActiveSuggestionIndex: number
  setCurrentActiveSuggestionIndex: (index: number) => void
  triggerDatesRangeChange: number
  updateDatesRangeTrigger: () => void
  triggerGenerateDatesChange: number
  updateGenerateDatesTrigger: () => void
  triggerCurrentSuggestionChange: number
  updateCurrentSuggestionTrigger: () => void

  triggerOnAddAnotherClick: number
  updateOnAddAnotherClickTrigger: () => void

  clearState: () => void
}

export const useSuggestionTimeWheelStore = create<SuggestionTimeWheelStore>()(
  (set, get) => ({
    currentDate: null,
    triggerDatesRangeChange: 0,
    updateDatesRangeTrigger: () =>
      set({ triggerDatesRangeChange: get().triggerDatesRangeChange + 1 }),
    triggerGenerateDatesChange: 0,
    updateGenerateDatesTrigger: () =>
      set({ triggerGenerateDatesChange: get().triggerGenerateDatesChange + 1 }),
    setCurrentDate: currentDate => set({ currentDate }),
    suggestions: [],
    addSuggestion: suggestion =>
      set({ suggestions: [...get().suggestions, suggestion] }),
    removeSuggestion: (indexToRemove: number) =>
      set({
        suggestions: get().suggestions.filter(
          (_, index) => index !== indexToRemove
        ),
        currentActiveSuggestionIndex: 0,
      }),
    updateSuggestion: (suggestion, index) =>
      set({
        suggestions: get().suggestions.map((item, itemIndex) => {
          if (index === itemIndex) return suggestion
          return item
        }),
      }),
    currentActiveSuggestionIndex: -1,
    setCurrentActiveSuggestionIndex: index =>
      set({ currentActiveSuggestionIndex: index }),
    triggerCurrentSuggestionChange: 0,
    updateCurrentSuggestionTrigger: () =>
      set({
        triggerCurrentSuggestionChange:
          get().triggerCurrentSuggestionChange + 1,
      }),

    triggerOnAddAnotherClick: 0,
    updateOnAddAnotherClickTrigger: () =>
      set({ triggerOnAddAnotherClick: get().triggerOnAddAnotherClick + 1 }),

    clearState: () =>
      set({
        currentDate: null,
        suggestions: [],
        currentActiveSuggestionIndex: -1,
        triggerDatesRangeChange: 0,
        triggerGenerateDatesChange: 0,
        triggerCurrentSuggestionChange: 0,
      }),
  })
)
