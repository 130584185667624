import { useMemo } from "react"

export const usePostingReactions = guestReactions => {
  return useMemo(() => {
    return Object.entries(guestReactions).map(([suggestionId, reactionId]) => ({
      suggestionId: Number(suggestionId),
      reactionId,
    }))
  }, [guestReactions])
}
