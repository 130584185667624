import { useEffect, useState } from "react"

import localStorage from "redux-persist/es/storage"

import { changeUserTimeZone, postUserSettings } from "@actions/settingsActions"

const storageKeyName = "device_tz"
const ignoredStatus = "ignored"

export const useDeviceTimezone = ({
  isAuthenticated,
  customTimezone,
  calendarSyncSettings,
}) => {
  const [isTimezoneModal, setIsTimezoneModal] = useState(false)
  const currentDeviceTimezone =
    Intl.DateTimeFormat().resolvedOptions()?.timeZone

  const catchDeviceTimezone = async () => {
    const savedTimezone = await localStorage.getItem(storageKeyName)
    const isAnotherTimezone =
      savedTimezone !== currentDeviceTimezone &&
      savedTimezone !== `${currentDeviceTimezone}_${ignoredStatus}`

    if (!isAuthenticated) return

    // saving timezone by default if it's correct from the beginning
    if (
      !savedTimezone &&
      (!customTimezone || currentDeviceTimezone === customTimezone)
    ) {
      return localStorage.setItem(storageKeyName, currentDeviceTimezone)
    }

    // if timezone doesn't match then show a modal window
    if (
      (!savedTimezone || isAnotherTimezone) &&
      customTimezone !== currentDeviceTimezone
    ) {
      setIsTimezoneModal(true)
    }
  }

  useEffect(() => {
    catchDeviceTimezone().then()
  }, [customTimezone, isAuthenticated])

  const applyDeviceTimezone = () => {
    postUserSettings({
      calendarSync: {
        ...calendarSyncSettings.calendarSync,
        // customTimezone: currentDeviceTimezone,
      },
    }).then()
    changeUserTimeZone(currentDeviceTimezone)
    localStorage.setItem(storageKeyName, currentDeviceTimezone)
    setIsTimezoneModal(false)
  }

  const ignoreChanges = () => {
    localStorage.setItem(
      storageKeyName,
      `${currentDeviceTimezone}_${ignoredStatus}`
    )
  }

  return {
    isTimezoneModal,
    currentDeviceTimezone,
    applyDeviceTimezone,
    ignoreChanges,
    closeTimezoneModal: () => setIsTimezoneModal(false),
  }
}
