import styled from "styled-components"

import { SvgIcon } from "@components/common"

export const Wrapper = styled.section`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 100px;
  margin: 0 auto;
`

export const ImageTemplate = styled(SvgIcon)`
  width: 200px;
  height: 140px;
`
