import { useCallback, useEffect, useState } from "react"

import { combineValidationErrors } from "@utils/combineValidationErrors"

export const useFormValidation = (
  validationSchema,
  formValues,
  isValidateOnClick,
  isPasswordInForm
) => {
  const [isValidating, setIsValidating] = useState(false)
  const [validationErrors, setValidationErrors] = useState(null)
  const [allPasswordErrors, setAllPasswordErrors] = useState([])

  const validateForm = useCallback(async () => {
    setIsValidating(true)

    try {
      await validationSchema.validate(formValues, {
        abortEarly: false,
      })
      setValidationErrors(null)

      setAllPasswordErrors([])
      return null
    } catch (error) {
      if (isPasswordInForm) {
        const passwordErrors = error.inner.filter(
          err => err.path === "password"
        )
        const messages = passwordErrors.map(err => err.message)
        setAllPasswordErrors(messages)
      }

      const localErrors = combineValidationErrors(error)
      if (localErrors) {
        setValidationErrors(localErrors)

        return localErrors
      }
    }
  }, [...Object.values(formValues), validationSchema])

  useEffect(() => {
    if (isValidateOnClick && !isValidating) return

    validateForm().then()
  }, Object.values(formValues))

  return { validationErrors, allPasswordErrors, validateForm, setIsValidating }
}
