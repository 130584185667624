import _ from "lodash"
import { createSelector } from "reselect"

import { notificationLevels } from "@configs/enums"
import { getCountryCodeFromPhoneNumber } from "@utils/contactUtils"

import { initialState } from "../reducers/userReducer"

export const selectUser = state => state.user || initialState

export const selectUserInfo = createSelector(
  selectUser,
  user => user.userInfo || {}
)

export const selectToken = createSelector(
  selectUser,
  user => user.userInfo.accessToken
)

export const selectUserId = createSelector(selectUser, user => user.userInfo.id)

export const selectUserCustomDates = createSelector(
  selectUser,
  user => user.customDates || []
)

export const selectUserCustomTimes = createSelector(
  selectUser,
  user => user.customTimes || []
)

export const selectContactMechanisms = createSelector(
  selectUser,
  user => user.contactMechanisms || {}
)

export const selectMechanismsLocations = createSelector(
  selectContactMechanisms,
  mechanisms => mechanisms.location || []
)

export const selectUserHomeLocation = createSelector(
  selectMechanismsLocations,
  locations =>
    _.find(locations, location =>
      location ? "Home".match(new RegExp(location.title, "i")) && location : {}
    )
)

export const selectUserTimezone = createSelector(
  selectUserInfo,
  userInfo => userInfo.timezone || null
)

export const selectUserSettings = createSelector(
  selectUser,
  user => user.settings || {}
)

export const selectConnections = createSelector(
  selectUser,
  user => user.connections || {}
)

export const selectCalendarSyncSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.calendarSync || {}
)

export const selectUserInterests = createSelector(
  selectUser,
  user => user.interests || []
)

export const selectContactsSyncSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.contacts || {}
)

export const selectMechanismsEmail = createSelector(
  selectContactMechanisms,
  mechanisms => mechanisms.email || []
)

export const selectMechanismsPhone = createSelector(
  selectContactMechanisms,
  mechanisms => mechanisms.phone || []
)

export const selectPublicContactMechanismsValues = createSelector(
  selectContactMechanisms,
  contactMechanisms => {
    const url = contactMechanisms.url.find(item => item.isPublic)
    // const location = contactMechanisms.location.find((item) => item.isPublic)
    const location = contactMechanisms.location.find(item => item.isPublic)
    const email = contactMechanisms.email.find(item => item.isPublic)
    const phone = contactMechanisms.phone.find(item => item.isPublic)

    return {
      url,
      location,
      email,
      phone,
    }
  }
)

export const selectUserVerifiedCountryCode = createSelector(
  selectMechanismsPhone,
  phones => {
    const verifiedPhone = phones.find(phone => phone.isVerified)
    if (!verifiedPhone) return null

    const verifiedPhoneNumber = verifiedPhone.value
    return (
      verifiedPhoneNumber && getCountryCodeFromPhoneNumber(verifiedPhoneNumber)
    )
  }
)

export const selectNotificationLevel = createSelector(
  selectUserSettings,
  userSettings =>
    userSettings.notificationLevel || notificationLevels.conversation
)

export const selectSoundSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.applicationSoundEnabled
)

export const selectUserNotificationSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.notifications || {}
)

export const selectIsDetectedTimezone = createSelector(
  selectUserSettings,
  userSettings => userSettings.detectTimezone
)

export const selectAllUserNotificationSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings || {}
)

export const selectUserEmailNotification = createSelector(
  selectUserSettings,
  userSettings => userSettings.emailNotifications
)

export const selectContactsSyncedAt = createSelector(
  selectUserInfo,
  userInfo => userInfo.contactsSyncedAt || null
)

export const selectIsUserLoading = createSelector(
  selectUser,
  user => user.isLoading
)

export const selectLoginErrorMessage = createSelector(
  selectUser,
  user => user.loginErrorMessage
)

export const selectIdentities = createSelector(
  selectUserInfo,
  userInfo => userInfo?.identities
)

export const selectLoginType = createSelector(
  selectUserInfo,
  userInfo => userInfo.loginType
)
