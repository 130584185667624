import styled, { css } from "styled-components"

export const List = styled.ul`
  display: flex;
  column-gap: 10px;

  ${({ $isCollapsed, $isFullScreen }) =>
    $isCollapsed
      ? css`
          padding-top: 15px;
          overflow-x: scroll;
          padding-right: 15px;
          &::-webkit-scrollbar {
            display: none;
          }
        `
      : css`
          ${$isFullScreen
            ? css`
                padding: 0 15%;
                margin: 15px 0;
              `
            : css`
                max-height: 300px;
                padding-right: 7px;
              `};
          flex-direction: column;
          row-gap: 25px;
          overflow-y: scroll;
          padding-top: 20px;
        `}
`
