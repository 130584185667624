import styled, { css } from "styled-components"

export const List = styled.ul`
  min-width: 150px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--blackShadow);
  list-style: none;
  padding: 5px 10px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
`

export const CalendarColor = styled.div`
  ${({ $color }) => css`
    width: 10px;
    height: 10px;
    background-color: ${$color};
    border-radius: 50%;
    margin-left: 5px;
  `};
`

export const CalendarIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 5px;
`
