import styled from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  box-shadow: var(--grayShadow2);
  box-sizing: border-box;
  padding: 15px;
  position: absolute;
  bottom: 30px;
  left: 0;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    bottom: 30px;
  }
`
