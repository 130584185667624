import { createSelector } from "reselect"

export const selectTeeupCard = state => state.teeupCard

export const selectActiveMessage = createSelector(
  selectTeeupCard,
  teeupCard => teeupCard.activeTextMessage
)

export const selectActiveHighlightedMessage = createSelector(
  selectTeeupCard,
  teeupCard => {
    return teeupCard.activeHighlightedMessage
  }
)
