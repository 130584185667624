import moment from "moment-timezone"

export const useTimezoneDateLabel = ({ timezoneName, startDate, endDate }) => {
  let momentStartDate = moment(startDate)
  let momentEndDate = moment(endDate)
  if (timezoneName) {
    momentStartDate = momentStartDate.tz(timezoneName)
    momentEndDate = momentEndDate.tz(timezoneName)
  }
  momentStartDate = momentStartDate.format("dddd MMM DD")
  momentEndDate = momentEndDate.format("dddd MMM DD")

  return momentStartDate === momentEndDate
    ? momentStartDate
    : `${momentStartDate} - ${momentEndDate}`
}
