import React, { useState } from "react"

import cn from "classnames"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"

import teeUp from "../../../assets/images/teeUps.svg"

import PhoneInputNd from "./PhoneInputNd"
import styles from "./styles"

const AddContacts = ({
  handleDone,
  isPhoneContact,
  disableDoneButton,
  contactList,
  handleTextChange,
  defaultCountry,
  getInitPhoneNumber,
  setCurrentCheckbox,
  currentCheckbox,
  error,
  setError,
  setContactType,
}) => {
  const [emailInput, setEmailInput] = useState("")
  const [phoneVal, setPhoneVal] = useState("")
  const [defCountry, setDefCountry] = useState({ countryCode: "us" })

  const handleEmailAddress = (e, contactId) => {
    e.preventDefault()
    setError(false)
    const trimedEmail = e.target.value.trim()
    setEmailInput(trimedEmail)
    handleTextChange(contactId)(trimedEmail)
  }

  const addContactForInvite = () => {
    if (disableDoneButton) {
      setError(true)
    } else {
      currentCheckbox === 1 ? handleDone("phone") : handleDone("email")
      setEmailInput("")
      setPhoneVal(defCountry.dialCode || "")
    }
  }

  return (
    <div
      className={cn("inviteNd-by", {
        "inviteNd-by--error": error,
      })}>
      <h3 className="inviteNd-by__title">Invite by phone or email</h3>
      {contactList.map(({ value, id, existedUser, countryCode }, i) => {
        const isLastRow = i === contactList.length - 1

        return (
          <div>
            {isPhoneContact ? (
              <PhoneInputNd
                value={value}
                inputVal={phoneVal}
                setInputVal={setPhoneVal}
                index={id}
                handleTextChange={handleTextChange}
                isLastRow={isLastRow}
                defaultCountry={defaultCountry}
                getInitPhoneNumber={getInitPhoneNumber}
                countryCode={countryCode}
                setError={setError}
                error={error}
                defCountry={defCountry}
                setDefCountry={setDefCountry}
              />
            ) : (
              <input
                type="text"
                className={cn("inviteNd-by__input", {
                  "inviteNd-by__input--error": error,
                })}
                placeholder="Add email address"
                value={emailInput}
                onChange={e => handleEmailAddress(e, id)}
              />
            )}
          </div>
        )
      })}
      <div className="inviteNd-by__choice--container">
        <div
          className="inviteNd-by__choice"
          onClick={() => {
            setContactType("phone")
            setError(false)
            setCurrentCheckbox(1)
          }}>
          <div data-testid="inviteByPhone" className="inviteNd-by__checkbox">
            {currentCheckbox === 1 && (
              <div className="inviteNd-by__checkbox--active"></div>
            )}
          </div>
          Phone
        </div>

        <div
          className="inviteNd-by__choice"
          onClick={() => {
            setContactType("email")
            setError(false)
            setCurrentCheckbox(2)
          }}>
          <div data-testid="inviteByEmail" className="inviteNd-by__checkbox">
            {currentCheckbox === 2 && (
              <div className="inviteNd-by__checkbox--active"></div>
            )}
          </div>
          Email
        </div>

        {error && (
          <div className="inviteNd-by__error">
            {currentCheckbox === 1
              ? "Please add correct phone number"
              : "Please add correct email address"}
          </div>
        )}
      </div>
      <button
        className={cn("inviteNd-by__button", {
          "inviteNd-by__button--error": error,
        })}
        onClick={addContactForInvite}
        // disabled={disableDoneButton}
        data-testid={
          currentCheckbox === 1 ? "addPhoneButton" : "addEmailButton"
        }>
        {currentCheckbox === 1 ? "Add Phone" : "Add Email"}
      </button>
    </div>
  )
}

const AlreadyExistedUser = ({ username, isCooe, isPhoneContact }) => (
  <View style={styles.userWrapper}>
    <Text style={styles.existedUser}>{username}</Text>
    {isCooe && <Image style={styles.cooeIcon} source={teeUp} />}
  </View>
)
AlreadyExistedUser.propTypes = {
  username: PropTypes.string.isRequired,
  isCooe: PropTypes.bool,
  isPhoneContact: PropTypes.bool,
}
export default AddContacts
