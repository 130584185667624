import styled from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  column-gap: 10px;
  padding: 20px 20px 0 20px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    height: 78px;
  }
`

export const UnlinkButtonWrapper = styled.div`
  margin-left: 7px;
`
