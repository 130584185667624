import { useRef, useState } from "react"

import { SuggestionItem } from "@components/SuggestionItem"
import ActionButton from "@components/TeeupPeople/ActionButton"
import { SvgIcon } from "@components/common"

import "./index.scss"

interface Props {
  actions: {
    value: boolean
    iconName: string
    isSelected: boolean
    text: string
  }[]
  firstContent: any[]
  divider: {
    iconName: string
    text: string
  }
  secondContent: any[]
}

const suggestionItemDefaultProps = {
  isCollapsed: false,
  isPreview: false,
  isCreateTeeup: false,
  isReacted: true,
  isDisableOnClick: true,
  contentClassName: "people-details-suggestion-item",
  wrapperClassName: "people-details-suggestion-item-wrapper",
}

const TabItem = ({ actions, firstContent, secondContent, divider }: Props) => {
  const [isFirstActionSelected, setIsFirstActionSelected] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const dividerRef = useRef<HTMLDivElement>(null)

  const firstContentLength = firstContent.length
  const secondContentLength = secondContent.length

  const handleActionChange = (value: boolean) => {
    setIsFirstActionSelected(value)

    if (value && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    } else if (!value && dividerRef.current) {
      dividerRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <>
      <div className="people-details-modal-tabs-actions">
        {actions.map(action => (
          <ActionButton
            key={action.text}
            onClick={() => handleActionChange(action.value)}
            variant="primary"
            isSelected={isFirstActionSelected === action.value}>
            <SvgIcon name={action.iconName} />
            {action.text}
          </ActionButton>
        ))}
      </div>

      <div className="people-details-tab-wrapper">
        <div className="people-details-tab-content" ref={contentRef}>
          {firstContent.map(item => (
            <SuggestionItem
              {...suggestionItemDefaultProps}
              key={item.id}
              suggestion={item}
              suggestionsLength={firstContentLength}
            />
          ))}

          {secondContent?.length > 0 && (
            <div
              ref={dividerRef}
              className="people-details-tab-content-divider">
              <SvgIcon name={divider.iconName} />
              <span>{divider.text}</span>
            </div>
          )}

          {secondContent.map(item => (
            <SuggestionItem
              {...suggestionItemDefaultProps}
              key={item.id}
              suggestion={item}
              suggestionsLength={secondContentLength}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default TabItem
