import React, { createContext, useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { v4 as uuidv4 } from "uuid"

import {
  setUserInfo as setUserInfoAction,
  setInviteSSORegisterToken,
} from "@actions/userActions"
import { isProdEnv } from "@utils/checkEnv"
import { useFormValidation, useApiErrors } from "hooks"

import {
  getTokenOnVerifiedInvitation,
  setIsRegisterSsoNames,
  setNewRegisteredUser,
  setNewRegisteredUserError,
  setIsCreateUserAnyway,
} from "../../actions/loginActions"
import { REGISTER_STEPS } from "../../components/NewSignUpEmail/constants"
import {
  useContinueDisabled,
  useRegisterEmail,
  useVerifyCode,
  useRegisterNames,
  useUserRegistration,
  useIsInvite,
} from "../../components/NewSignUpEmail/hooks"
import { getValidationSchema } from "../../components/NewSignUpEmail/validationSchema"

const deviceId = uuidv4()

export const SignUpEmailContext = createContext({})

const SignUpEmailState = ({
  children,
  isRegisterSsoNames,
  isCreateUserAnyway,
  teeupInviteInfo,
  accessToken,
  inviteSSORegisterToken,
  getTokenOnVerifiedInvitation,
  setUserInfoAction,
  setIsRegisterSsoNames,
  setNewRegisteredUser,
  setNewRegisteredUserError,
  setIsCreateUserAnyway,
  setInviteSSORegisterToken,
}) => {
  const { user } = useAuth0()
  const initialAvatar = user?.picture ? user.picture : null

  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [inviteVerificationCode, setInviteVerificationCode] = useState("")
  const [step, setStep] = useState(REGISTER_STEPS.EMAIL)
  const [userInfo, setUserInfo] = useState()
  const [localSSOUser, setLocalSSOUser] = useState()
  const [contactMechanismVerificationId, setContactMechanismVerificationId] =
    useState()
  const [password, setPassword] = useState("")
  const [fullName, setFullName] = useState("")
  const [captcha, setCaptcha] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [avatar, setAvatar] = useState(initialAvatar)
  const [contactMechanismId, setContactMechanismId] = useState(null)
  const [contactVerificationId, setContactVerificationId] = useState(null)
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    code: false,
    password: false,
    fullName: false,
    displayName: false,
  })
  const { validationErrors, allPasswordErrors, validateForm, setIsValidating } =
    useFormValidation(
      getValidationSchema(step),
      {
        email,
        code,
        password,
        fullName,
        displayName,
        captcha,
        inviteVerificationCode,
      },
      true,
      true
    )
  const { apiErrors, addApiError, removeApiError, removeAllApiErrors } =
    useApiErrors()

  const isInvite = useIsInvite({
    teeupInviteInfo,
    email,
    user,
    isRegisterSsoNames,
  })

  useEffect(() => {
    if (isRegisterSsoNames) {
      setStep(REGISTER_STEPS.NAMES)
    }
  }, [isRegisterSsoNames])

  useEffect(() => {
    removeAllApiErrors()
  }, [email, code, password, fullName, displayName, inviteVerificationCode])

  const { isRegisterEmailLoading, registerEmail } = useRegisterEmail({
    deviceId,
    setUserInfo,
    setContactMechanismVerificationId,
    setStep,
    addApiError,
    removeApiError,
  })

  const { isCodeVerifying, isCodeVerified, verifyCode } = useVerifyCode({
    addApiError,
    getTokenOnVerifiedInvitation,
    setStep,
  })

  const {
    isCodeVerifying: isInviteCodeVerifying,
    isCodeVerified: isInviteCodeVerified,
    verifyCode: verifyInviteCode,
  } = useVerifyCode({
    isInvite,
    addApiError,
    step,
    teeupInviteInfo,
    token: userInfo?.accessToken || inviteSSORegisterToken,
    isRegisterSsoNames,
    localSSOUser,
    setContactMechanismId,
    setContactVerificationId,
    setUserInfoAction,
    setNewRegisteredUser,
    setIsRegisterSsoNames,
  })

  const { isRegisterNamesLoading, setIsRegisterNamesLoading, registerNames } =
    useRegisterNames({
      setUserInfo,
      deviceId,
      accessToken,
      setUserInfoAction,
      addApiError,
      setStep,
    })

  const { registerUser } = useUserRegistration({
    setNewRegisteredUser,
    setIsRegisterNamesLoading,
    setIsRegisterSsoNames,
    setNewRegisteredUserError,
    setIsCreateUserAnyway,
    addApiError,
    setStep,
    setLocalSSOUser,
    setInviteSSORegisterToken,
  })

  useEffect(() => {
    if (step === REGISTER_STEPS.PASSWORD) {
      validateForm()
    }
    if (step === REGISTER_STEPS.NAMES) {
      setIsValidating(false)
    }
  }, [step])

  const continueHandle = async event => {
    event.preventDefault()

    const errors = await validateForm()

    if (errors) return
    switch (step) {
      case REGISTER_STEPS.EMAIL:
        registerEmail(email)
        break
      case REGISTER_STEPS.SIGN_UP_CODE:
        verifyCode(contactMechanismVerificationId, code)
        break
      case REGISTER_STEPS.PASSWORD:
        setUserInfo(prevState => ({
          ...prevState,
          password,
        }))
        setStep(REGISTER_STEPS.NAMES)
        break
      case REGISTER_STEPS.NAMES:
        const isProd = isProdEnv()
        if (isRegisterSsoNames) {
          setIsRegisterNamesLoading(true)

          const values = {
            isInvite,
            user,
            avatar,
            fullName,
            displayName,
            createAnyways: isCreateUserAnyway,
            setIsRegisterNamesLoading,
          }
          if (isProd) {
            values.captcha = captcha
          }

          registerUser(values)
        } else {
          const values = {
            userInfo,
            avatar,
            fullName,
            displayName,
            isInvite,
          }

          if (isProd) {
            values.captcha = captcha
          }
          registerNames(values)
        }
        break
      case REGISTER_STEPS.INVITE_SENDER:
        verifyInviteCode({
          contactVerificationId,
          code: inviteVerificationCode,
          userInfo,
        })
        break
      default:
        return
    }
  }

  const touchField = (fieldName, newVal) => {
    setTouchedFields(prevState => ({
      ...prevState,
      [fieldName]: !!newVal,
    }))
  }

  const isContinueDisabled = useContinueDisabled({
    validationErrors,
    isRegisterEmailLoading,
    apiErrors,
    isCodeVerifying,
    isInviteCodeVerifying,
    isRegisterNamesLoading,
    step,
    touchedFields,
    fullName,
    displayName,
    captcha,
    email,
  })

  return (
    <SignUpEmailContext.Provider
      value={{
        touchedFields,
        email,
        setEmail,
        code,
        inviteVerificationCode,
        setInviteVerificationCode,
        setCode,
        step,
        setStep,
        password,
        setPassword,
        fullName,
        setFullName,
        displayName,
        setDisplayName,
        setAvatar,
        avatar,
        deviceId,
        captcha,
        setCaptcha,
        isCodeVerifying,
        isCodeVerified,
        isInviteCodeVerifying,
        isInviteCodeVerified,
        validationErrors,
        allPasswordErrors,
        apiErrors,
        isRegisterEmailLoading,
        isContinueDisabled,
        isRegisterNamesLoading,
        continueHandle,
        touchField,
        contactMechanismId,
        setContactMechanismId,
        contactVerificationId,
        setContactVerificationId,
        setTouchedFields,
      }}>
      {children}
    </SignUpEmailContext.Provider>
  )
}

const mapStateToProps = ({ user, signUpWithInvitation }) => ({
  isRegisterSsoNames: user.isRegisterSsoNames,
  isCreateUserAnyway: user.isCreateUserAnyway,
  teeupInviteInfo: user.teeupInviteInfo,
  inviteSSORegisterToken: user.inviteSSORegisterToken,
  accessToken: signUpWithInvitation.token,
})

const mapDispatchToProps = dispatch => ({
  getTokenOnVerifiedInvitation: bindActionCreators(
    getTokenOnVerifiedInvitation,
    dispatch
  ),
  setUserInfoAction: bindActionCreators(setUserInfoAction, dispatch),
  setIsRegisterSsoNames: bindActionCreators(setIsRegisterSsoNames, dispatch),
  setNewRegisteredUser: bindActionCreators(setNewRegisteredUser, dispatch),
  setNewRegisteredUserError: bindActionCreators(
    setNewRegisteredUserError,
    dispatch
  ),
  setIsCreateUserAnyway: bindActionCreators(setIsCreateUserAnyway, dispatch),
  setInviteSSORegisterToken: bindActionCreators(
    setInviteSSORegisterToken,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpEmailState)
