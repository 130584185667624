import { InviteMainInfo } from "./InviteMainInfo"
import { Wrapper, ContentWrapper } from "./stepperWrapperStyles"

export const StepperWrapper = ({ children }) => (
  <Wrapper>
    {/* <InviteMainInfo isStepper /> */}

    <ContentWrapper>{children}</ContentWrapper>
  </Wrapper>
)
