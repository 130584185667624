import { useEffect, useMemo, useState } from "react"

import actionTypes from "../../actions/actionTypes"
import { store } from "../../index"

import { passwordSchema } from "./validationSchema"

// TODO: it's a NEW password validation hook. in the future we must change old usePassword hook by this
export const usePasswordValidation = () => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordErrors, setPasswordErrors] = useState(null)
  const [failedRequirements, setFailedRequirements] = useState([])

  const validatePassword = () => {
    const values = { password, confirmPassword }
    passwordSchema
      .validate(values, { strict: true, abortEarly: false })
      .then(() => {
        setPasswordErrors(null)
        setFailedRequirements([])
      })
      .catch(error => {
        const errors = {}

        error.inner.forEach(err => {
          errors[err.path] = err.message
        })
        setFailedRequirements(error.errors)
        setPasswordErrors(errors)
      })
  }

  useEffect(() => {
    if (password || confirmPassword) {
      validatePassword()
      store.dispatch({
        type: actionTypes.SET_RESET_PASS_ERROR,
        payload: null,
      })
    }
  }, [password, confirmPassword])

  const isValid = useMemo(() => {
    return !passwordErrors && password && confirmPassword
  }, [passwordErrors, password, confirmPassword])

  return {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validatePassword,
    passwordErrors,
    failedRequirements,
    isValid,
  }
}
