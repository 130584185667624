import React, { useMemo, useState } from "react"

import { SvgIcon } from "@components/common"
import Pill from "@components/ui/Pill"
import { TextField } from "@components/ui/TextField"
import { ToggleButton } from "@components/ui/settingsClickableRow/actionButtons"
import {
  getSuggestionOnlineOptions,
  platformLinkPatterns,
} from "@utils/suggestionUtils"
import { useSnackbarStore } from "stores/snackbarStore"
import { useSuggestionOnlineStore } from "stores/suggestions/suggestionOnlineStore"
import {
  MEETING_PLATFORMS,
  OnlineSuggestion,
  URL_TYPES,
} from "types/suggestions"

import "./index.scss"

const MeetingDetails = () => {
  const [isUrlError, setIsUrlError] = useState(false)
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false)

  const selectedPlatform = useSuggestionOnlineStore(
    state => state.currentPlatform
  )
  const currentUrlAddress = useSuggestionOnlineStore(
    state => state.currentUrlAddress
  )
  const currentAdditionalInformation = useSuggestionOnlineStore(
    state => state.currentAdditionalInformation
  )
  const currentUrlType = useSuggestionOnlineStore(state => state.currentUrlType)
  const setCurrentPlatform = useSuggestionOnlineStore(
    state => state.setCurrentPlatform
  )
  const setCurrentUrlAddress = useSuggestionOnlineStore(
    state => state.setCurrentUrlAddress
  )
  const setCurrentUrlType = useSuggestionOnlineStore(
    state => state.setCurrentUrlType
  )
  const updateSuggestion = useSuggestionOnlineStore(
    state => state.updateSuggestion
  )
  const setCurrentAdditionalInformation = useSuggestionOnlineStore(
    state => state.setCurrentAdditionalInformation
  )
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)
  const currentActiveSuggestionIndex = useSuggestionOnlineStore(
    state => state.currentActiveSuggestionIndex
  )
  const suggestions = useSuggestionOnlineStore(state => state.suggestions)
  const isSuggestionsExist = suggestions.length > 0

  const meetingPlatforms = useMemo(() => getSuggestionOnlineOptions(), [])
  const platformLinkPatternsEntries = useMemo(
    () => Object.entries(platformLinkPatterns),
    []
  )

  const selectAddressOptions = useMemo(
    () => [
      {
        label: "Enter Url/Address",
        value: URL_TYPES.URL,
        onClick: () => {
          setCurrentUrlType(URL_TYPES.URL)
          updateSuggestionWithCurrentValues()
        },
      },
      {
        label: "Share link later",
        value: URL_TYPES.LINK_LATER,
        onClick: () => {
          if (isSuggestionsExist) {
            updateSuggestionWithCurrentValues({
              urlAddress: "",
              urlType: URL_TYPES.LINK_LATER,
            })
          }
          setCurrentUrlType(URL_TYPES.LINK_LATER)
          setCurrentUrlAddress("")
        },
      },
      {
        label: "Need someone to provide link",
        value: URL_TYPES.SOMEONE_PROVIDE,
        onClick: () => {
          if (isSuggestionsExist) {
            updateSuggestionWithCurrentValues({
              urlAddress: "",
              urlType: URL_TYPES.SOMEONE_PROVIDE,
            })
          }
          setCurrentUrlType(URL_TYPES.SOMEONE_PROVIDE)
          setCurrentUrlAddress("")
        },
      },
    ],
    [suggestions]
  )

  const handleBackClick = () => {
    setCurrentPlatform(null)
    setCurrentUrlAddress("")
    setCurrentUrlType(URL_TYPES.URL)
    setCurrentAdditionalInformation("")
    // addSuggestion(suggestions)
    // removeSuggestion()
  }

  const updateSuggestionWithCurrentValues = (
    values?: Partial<OnlineSuggestion>
  ) => {
    updateSuggestion(currentActiveSuggestionIndex, {
      urlType: currentUrlType,
      urlAddress: currentUrlAddress,
      additionalInformation: currentAdditionalInformation,
      ...values,
    })
  }

  const handleLinkChange = (value: string) => {
    if (
      selectedPlatform?.id !== undefined &&
      selectedPlatform?.id !== MEETING_PLATFORMS.CALL &&
      !platformLinkPatternsEntries.some(([key, pattern]) =>
        pattern?.test(value)
      )
    ) {
      setSnackbarConfig({
        message: "Invalid URL, please check the link of the platform",
        open: true,
      })
      setIsUrlError(true)
    } else {
      const platformPattern = platformLinkPatternsEntries.find(
        ([key, pattern]) => pattern?.test(value)
      )
      const platformId = platformPattern?.[0]
      const platform = meetingPlatforms.find(
        option => option.id?.toString() === platformId
      )

      if (platform) {
        updateSuggestionWithCurrentValues({ ...platform })
        setCurrentPlatform(platform)
      }

      setIsUrlError(false)
    }

    setCurrentUrlAddress(value)
  }

  return (
    <div className="suggestion-where-meeting-details">
      <div className="meeting-details-title">
        <h4>
          <SvgIcon name="arrow-2" onClick={handleBackClick} /> Add Meeting
          Details (Optional)
        </h4>
      </div>

      <div className="select-url-address">
        <h5>Add URL/Address</h5>

        <ul className="select-url-address-container">
          {selectAddressOptions.map(option => (
            <Pill
              key={option.label}
              isSelected={option.value === currentUrlType}
              onClick={option.onClick}>
              {option.label}
            </Pill>
          ))}
        </ul>

        {currentUrlType === URL_TYPES.URL && (
          <TextField
            error={isUrlError}
            value={currentUrlAddress}
            placeholder="Enter URL or address here"
            onChange={e => handleLinkChange(e.target.value)}
            onBlur={() => updateSuggestionWithCurrentValues()}
          />
        )}
      </div>

      <div>
        <div className="additional-information">
          <h5>Provide Additional Information</h5>
          <ToggleButton
            onToggle={() => setIsAdditionalInfo(prevState => !prevState)}
            value={isAdditionalInfo}
          />
        </div>

        {isAdditionalInfo && (
          <textarea
            className="custom-textarea"
            rows={5}
            cols={50}
            placeholder="Add anything you think others need to know in order to successfully reach this meeting."
            value={currentAdditionalInformation}
            onChange={e => setCurrentAdditionalInformation(e.target.value)}
          />
        )}
      </div>
    </div>
  )
}

export default MeetingDetails
