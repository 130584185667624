import React, { useContext, useState } from "react"

import _ from "lodash"

import { MenuTabs, SuggestionsList, SuggestionItem } from "@components"
import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"

import { useGameplans } from "../NewTeeupGameplan/hooks"
import { SuggestionsActions } from "../NewTeeupGameplan/molecules"

import { EmptyBlock } from "./molecules"
import { Wrapper } from "./openedGameplanStyles"

const TABS_KEYS = {
  [GAMEPLAN_TYPES.WHEN]: GAMEPLAN_TYPES.WHEN,
  [GAMEPLAN_TYPES.WHERE]: GAMEPLAN_TYPES.WHERE,
}

const TABS_TEXT = {
  [GAMEPLAN_TYPES.WHEN]: "When",
  [GAMEPLAN_TYPES.WHERE]: "Where",
}

export const OpenedGameplan = ({ isFullScreen }) => {
  const tabs = Object.keys(TABS_KEYS)
  const [activeTab, setActiveTab] = React.useState(tabs[0])
  const type = _.lowerCase(activeTab)
  const [isNewest, setIsNewest] = useState(true)

  const { teeup } = useContext(NewTeeupPageContext)
  const { suggestions } = useGameplans({
    teeup,
    type,
    isNewest,
  })

  return (
    <Wrapper $isFullScreen={isFullScreen}>
      <MenuTabs
        tabs={tabs}
        tabsText={TABS_TEXT}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {suggestions.length > 0 ? (
        <>
          <SuggestionsActions
            type={type}
            isNewest={isNewest}
            setIsNewest={setIsNewest}
          />

          <SuggestionsList isCollapsed={false} isFullScreen={isFullScreen}>
            {suggestions.map(suggestion => (
              <SuggestionItem
                isCollapsed={false}
                suggestion={suggestion}
                suggestionsLength={suggestions.length}
                key={suggestion.id}
              />
            ))}
          </SuggestionsList>
        </>
      ) : (
        <EmptyBlock type={type} />
      )}
    </Wrapper>
  )
}
