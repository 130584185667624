import { useEffect } from "react"

import { v4 as uuidv4 } from "uuid"

import { GAMEPLAN_TYPES } from "constants/gameplan"
import dayjs from "utils/dayjs"

// import { createLastMoment } from "../helpers"

export const useLocalSuggestions = ({
  newGuestSuggestion,
  gameplanInfo,
  previewInfo,
  setGuestSuggestions,
  setNewGuestSuggestion,
  setMyGuestTeeupReactions,
  currentSelectedDay,
}) => {
  const getParticipants = reactedBy => {
    return reactedBy.map(id => {
      const currentParticipant = previewInfo?.participants.find(
        participant => participant.id === id
      )

      return currentParticipant ? currentParticipant : id
    })
  }

  const updateReactedBy = originalSuggestions => {
    return originalSuggestions?.map(suggestion => ({
      ...suggestion,
      reactions: suggestion?.reactions.map(reaction => ({
        ...reaction,
        reactedBy: getParticipants(reaction.reactedBy),
      })),
    }))
  }

  const findMyTeeupReactedSuggestions = originalSuggestions => {
    const result = originalSuggestions
      .filter(({ reactions }) => {
        return reactions.find(({ reactedBy }) =>
          reactedBy.find(
            participantId => participantId === previewInfo?.invitee.id
          )
        )
      })
      .map(({ id }) => id)

    setMyGuestTeeupReactions(result)
  }

  const setDefaultSuggestions = () => {
    const originalSuggestions = gameplanInfo?.when.suggestions
    const updatedSuggestions = updateReactedBy(originalSuggestions)

    if (originalSuggestions) {
      setGuestSuggestions(updatedSuggestions)
      findMyTeeupReactedSuggestions(originalSuggestions)
    }
  }

  useEffect(() => {
    setDefaultSuggestions()
  }, [gameplanInfo, previewInfo])

  // useEffect(() => {
  //   if (!newGuestSuggestion) return

  //   const { isNextDay, lastMoment } = createLastMoment(newGuestSuggestion)

  //   if (isNextDay) {
  //     setNewGuestSuggestion({
  //       end: lastMoment,
  //     })
  //   }
  // }, [newGuestSuggestion])

  const createSuggestion = () => {
    setNewGuestSuggestion({
      id: uuidv4(),
      isHidden: true,
    })
    const { start, end, ...rest } = newGuestSuggestion
    setGuestSuggestions([
      {
        ...rest,
        isCreated: true,
        creator: { name: "You" },
        startDate: currentSelectedDay
          .set("hour", start.hour())
          .set("minute", start.minute())
          .format(),
        endDate: currentSelectedDay
          .set("hour", end.hour())
          .set("minute", end.minute())
          .format(),
        type: GAMEPLAN_TYPES.when,
      },
    ])
  }

  useEffect(() => {
    if (!newGuestSuggestion) return
    const startOfDate = dayjs().startOf("date")

    if (newGuestSuggestion.start.isBefore(startOfDate)) {
      setNewGuestSuggestion({
        isHidden: true,
      })
    }
  }, [newGuestSuggestion?.start])

  return { createSuggestion }
}
