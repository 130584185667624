import { stringToDateFormat } from "../dateUtils"

const getSuggestionWhenTitle = (suggestion: any, format: string): string => {
  const startFormattedDate = stringToDateFormat(suggestion.startDate, format)
  const endFormattedDate = stringToDateFormat(suggestion.endDate, format)

  if (suggestion.endDate && suggestion.startDate && suggestion.isCustomDate) {
    return endFormattedDate
  }

  return startFormattedDate === endFormattedDate
    ? startFormattedDate
    : startFormattedDate + "-" + endFormattedDate
}

export default getSuggestionWhenTitle
