import React, { useState, useMemo } from "react"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { setSelectedCalendarDay } from "@actions/commonActions"
import Modal from "@components/Modal"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import { SuggestModalHeader, SuggestModalWrapper } from "../components"

import { GeneralTimeProvider } from "./GeneralTimeTab/GeneralTimeContext/GeneralTimeContext"
import GeneralTimeTab from "./GeneralTimeTab/GeneralTimeTab"
import TimeSlotsTab from "./TimeSlotsTab/TimeSlotsTab"
import TimeWheelTab from "./TimeWheelTab/TimeWheelTab"

const SUGGEST_WHEN_TABS = [
  { id: "timeWheel", name: "Time Wheel" },
  { id: "generalTime", name: "General Time" },
  // TODO implement Time Slots - uncomment this
  // { id: "timeSlots", name: "Time Slots" },
]

const SuggestWhenModal = ({ onSave, closeModal }) => {
  const [activeTab, setActiveTab] = useState(SUGGEST_WHEN_TABS[0].id)
  const { closeSuggestWhenModal } = useTeeUpPage()
  const dispatch = useDispatch()
  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  const handleClose = () => {
    if (closeModal) {
      closeModal()
    } else closeSuggestWhenModal()
    dispatch(setSelectedCalendarDay(null))
  }

  const content = useMemo(() => {
    switch (activeTab) {
      case "timeWheel":
        return <TimeWheelTab onSave={onSave} handleClose={handleClose} />
      case "timeSlots":
        return <TimeSlotsTab onSave={onSave} handleClose={handleClose} />
      case "generalTime":
      default:
        return (
          <GeneralTimeProvider>
            <GeneralTimeTab onSave={onSave} handleClose={handleClose} />
          </GeneralTimeProvider>
        )
    }
  }, [activeTab])

  return (
    <Modal>
      <SuggestModalWrapper>
        <SuggestModalHeader
          title="Suggest When"
          tabs={SUGGEST_WHEN_TABS}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          handleClose={handleClose}
        />
        {content}
      </SuggestModalWrapper>
    </Modal>
  )
}
SuggestWhenModal.propTypes = {
  onSave: PropTypes.func,
}

export default SuggestWhenModal
