import React, { useCallback, useMemo } from "react"

import PropTypes from "prop-types"

import "./passwordRules.scss"
import CheckedMarkWait from "@assets/images/checked-mark-wait.svg"
import CheckedMark from "@assets/images/checked-mark.svg"

import { CHANGE_PASSWORD_REQUIREMENTS } from "../../../../constants/password"

export const PasswordRules = ({ allPasswordErrors }) => {
  const rulesList = useMemo(() => {
    return CHANGE_PASSWORD_REQUIREMENTS.map(rule => rule.text)
  }, [])

  const getRuleIcon = useCallback(
    rule => {
      return allPasswordErrors.includes(rule) ? CheckedMarkWait : CheckedMark
    },
    [allPasswordErrors]
  )

  return (
    <div className="password-rules">
      {rulesList.map((rule, ruleIndex) => (
        <div className="password-rules__item" key={ruleIndex}>
          <img
            className="password-rules__item-icon"
            src={getRuleIcon(rule)}
            alt="rule"
          />

          <span className="password-rules__item-text">{rule}</span>
        </div>
      ))}
    </div>
  )
}

PasswordRules.propTypes = {
  allPasswordErrors: PropTypes.arrayOf(PropTypes.string),
}
