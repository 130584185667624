import endpoints from "@config/endpoints"
import { teeupUserStatusPriorities } from "@config/enums"

export const getSettingStatusEndpoint = ({ isSharedLink, status, slug }) => {
  const statusVal = teeupUserStatusPriorities[status]

  if (status) {
    return isSharedLink
      ? endpoints.teeup.previewSetSharedLinkStatus(slug, statusVal)
      : endpoints.teeup.previewSetGuestStatus(slug, statusVal)
  }

  return endpoints.teeup.previewPostGuestData(slug)
}
