import api from "../api"
import endpoints from "../config/endpoints"
import { store } from "../index"

import actionTypes from "./actionTypes"

export const setIsTeeupOverviewSettings = payload => ({
  type: actionTypes.SET_IS_TEEUP_OVERVIEW_SETTINGS,
  payload,
})

export const setShowSideBar = payload => ({
  type: actionTypes.SET_SHOW_SIDE_BAR,
  payload,
})

export const setReplyMessage = payload => {
  return {
    type: actionTypes.SET_REPLY_MESSAGE,
    payload,
  }
}

export const setFromCommentsInput = payload => {
  return {
    type: actionTypes.SET_FROM_COMMENTS_INPUT,
    payload,
  }
}

export const setTitle = title => ({
  type: actionTypes.SET_TITLE,
  title,
})

export const setNudgePeopleOpen = payload => ({
  type: actionTypes.SET_NUDGE_PEOPLE_OPEN,
  payload,
})

export const setTeeupFilter = filter => ({
  type: actionTypes.SET_TEEUP_FILTER,
  filter,
})

export const setPeopleSideBar = payload => ({
  type: actionTypes.SET_SHOW_PEOPLE_SIDE_BAR,
  payload,
})

export const setSideBarContent = payload => ({
  type: actionTypes.SET_SIDE_BAR_CONTENT,
  payload,
})

export const setMentionedMessages = payload => ({
  type: actionTypes.SET_MENTIONED_MESSAGES,
  payload,
})

export const setActiveTab = payload => ({
  type: actionTypes.SET_ACTIVE_TAB,
  payload,
})

export const setActiveInviteesTab = payload => ({
  type: actionTypes.SET_ACTIVE_INVITEES_TAB,
  payload,
})

export const setNotificationTeeups = () => ({
  type: actionTypes.SET_ACTIVE_NOTIFICATIONS_TEEUPS,
})

export const setHelpPopup = payload => ({
  type: actionTypes.SET_HELP_POPUP,
  payload,
})

export const setModalPeopleNudge = (value, id, typeId) => {
  return {
    type: actionTypes.SET_PEOPLE_NUDGE,
    value,
    id,
    typeId,
  }
}

export const setModalSuccess = payload => {
  return {
    type: actionTypes.SET_MODAL_SUCCESS,
    payload,
  }
}

export const readTeeup = teeupId => {
  const url = endpoints.teeup.teeupStateSeen(teeupId)

  return api.client
    .put(url)
    .then(
      store.dispatch({
        type: actionTypes.READ_TEEUP_STATE,
        payload: { teeupId: teeupId },
      })
    )
    .catch(() => {
      console.log("teeUpRead error ")
    })
}

export const hideSuggestionListFooter = () => {
  return {
    type: actionTypes.SET_HIDE_SUGGESTION_LIST_FOOTER,
  }
}

export const showSuggestionListFooter = () => {
  return {
    type: actionTypes.SET_SHOW_SUGGESTION_LIST_FOOTER,
  }
}

export const toggleHelpPhonePopup = showHelpPopup => {
  return {
    type: actionTypes.TOGGLE_HELP_PHONE_POPUP,
    payload: showHelpPopup,
  }
}

export const isOnlineTabOpened = isOpened => {
  return {
    type: actionTypes.IS_ONLINE_TAB_OPENED,
    payload: isOpened,
  }
}

export const handleOpenSuggestion = () => {
  return {
    type: actionTypes.OPEN_SUGGESTION,
  }
}

export const handleCloseSuggestion = () => {
  return {
    type: actionTypes.CLOSE_SUGGESTION,
  }
}

export const handleSetTeeupsMode = teeupsMode => {
  return {
    type: actionTypes.SET_TEEUPS_MODE,
    payload: teeupsMode,
  }
}

export const getStyledMessages = styledMessages => {
  return {
    type: actionTypes.GET_STYLED_MESSAGES,
    payload: styledMessages,
  }
}

export const getCurrentWindowParams = params => {
  return {
    type: actionTypes.GET_CURRENT_WINDOW_PARAMS,
    payload: params,
  }
}

export const setPeopleToNudge = peopleToNudge => {
  return {
    type: actionTypes.SET_PEOPLE_TO_NUDGE,
    payload: peopleToNudge,
  }
}

export const setSelectedCalendarDay = day => {
  return {
    type: actionTypes.SET_SELECTED_CALENDAR_DAY,
    payload: day,
  }
}

export const setIsFromCreateTeeUp = value => {
  return {
    type: actionTypes.SET_IS_FROM_CREATE_TEEUP,
    payload: value,
  }
}

export const setModalInfo = payload => ({
  type: actionTypes.SET_MODALINFO,
  payload,
})
export const resetModalInfo = () => ({
  type: actionTypes.RESET_MODALINFO,
})
