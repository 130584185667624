export const combineValidationErrors = errorInfo => {
  const result = {}

  if (errorInfo.inner) {
    errorInfo.inner.forEach(err => {
      result[err.path] = err.message
    })
  }

  return result
}
