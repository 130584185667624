import React, { useContext } from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { Wrapper } from "./reactionsSubmitStyles"

export const ReactionsSubmit = ({ reactionsAmount }) => {
  const {
    stepsManager: { goNextStep },
  } = useContext(TeeupPreviewContext)

  return (
    <Wrapper>
      <ButtonEl
        fullWidth
        isBlack
        variant={BUTTON_VARIANTS.ROUNDED}
        text={`Continue with (${reactionsAmount}) reaction${
          reactionsAmount > 1 ? "s" : ""
        }`}
        onClick={goNextStep}
      />
    </Wrapper>
  )
}

ReactionsSubmit.propTypes = {
  reactionsAmount: PropTypes.number,
}
