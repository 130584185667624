import React from "react"

import PropTypes from "prop-types"

import { CloseButton } from "@components/common"

import { Wrapper, LeftSide, Title, Description } from "./newModalHeaderStyles"

export const NewModalHeader = ({ title, description, close }) => (
  <Wrapper>
    <LeftSide>
      {!!title && <Title>{title}</Title>}

      {!!description && <Description>{description}</Description>}
    </LeftSide>

    <CloseButton onClick={close} />
  </Wrapper>
)

NewModalHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  close: PropTypes.func,
}
