import TeeupInvitee, { USER_STATUS } from "./participant"
import { Suggestion } from "./suggestions"

export enum TEEUP_STATUS {
  PLANNING = 1,
  ALL_SET = 2,
  HAPPENING = 3,
  HAPPENING_SOON = 6,
  ENDED = 4,
  CANCELLED = 5,
}

export interface ISuggestion {
  type: string
  isCustomDate: boolean
  isCustomTime: boolean
  isTimeZoneEnabled: boolean
  selected: boolean
  decided: boolean
  value: string | null
  value2: string | null
  index: number
  teeupId: number | null
  optionId: number | null
  userId: number
  startDate: string
  endDate: string
}

export interface IContact {
  avatar: string
  cooeId: number
  count: number
  createdAt: string
  emails: { email: string }[]
  id: number
  isConnected: boolean
  locations: string[]
  name: string
  phoneNumbers: string[]
  tags: string[]
  timezone: string
  type: string
  updatedAt: string
  urls: string[]
  userType: number
  username: string
}

export interface Stage {
  label: string
  position: number
  isSelected: boolean
  color: string
  backgroundColor: string
  labelToDisplay: string
  isDisabled?: boolean
  isHidden?: boolean
  statusId: TEEUP_STATUS
}

export interface TeeUpCardStage {
  label: string
  className: string
}

export interface TeeupSettings {
  allowAutoEnded: boolean
  allowAutoScheduled: boolean
  allowChangeGameplanOptions: boolean
  allowChangeState: boolean
  allowChangeTitle: boolean
  allowDecideGameplan: boolean
  allowInviteOthers: boolean
  allowNotifyBeforeHappening: boolean
  allowPromoteToAllSetOrPlanning: boolean
  allowPromoteToEnded: boolean
  allowSelectGameplan: boolean
  criticalMassAmount: number
  enableSharedLink: boolean
  timeBeforeAutoEnded: number
  whenToNotifyBeforeHappening: number
}
export interface GameplanItem {
  id: number
  icon?: string
  name: string
  isPrimary: boolean
  optionId: number
  sourceType?: string
  statusId?: string
  title: string
  type: string
  data?: number[]
  suggestions: Suggestion[]
}

export interface TeeupItem {
  archived: boolean
  categoryId: number
  createdat: string
  filter?: string
  createdby: number
  events: []
  id: number
  gameplanOptions: GameplanItem[]
  gameplans: GameplanItem[]
  groupId: null | number
  hasInvitations: boolean
  invitationMessage: string
  invited: TeeupInvitee[]
  isArchivePromptShow: boolean
  isArchived: boolean
  isGameplanUpdated: boolean
  isInactive: boolean
  isMute: boolean
  isPrivate: boolean | null
  lastActedAt: string
  locationid: null | number
  name: string
  new: boolean
  newMessages: number | null
  newStatus: number | null
  newTitle: string | null
  newWhen: string | null
  newWhere: string | null
  nudgeIssuer: string | null
  nudgeType: string | null
  photo: string
  seen: boolean
  settings: TeeupSettings
  slackDomain: boolean | null
  slug: string | null
  status: TEEUP_STATUS
  statusChangedAt: string
  teeupId: number
  type: string
  updatedAt: string
  updatedat: string
  formattedStartDate?: string
  nearestStartDateHour?: string
  formattedStartDateWithTime?: string
  formattedEndDateWithTime?: string
  userKickedOutBy: number | null
  userPrevStatus: null
  userStatus: USER_STATUS | null
  userstatus: USER_STATUS | null
  valid: boolean
  when?: string
}
