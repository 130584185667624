import React from "react"

import PropTypes from "prop-types"

import { Headline, SuggestionChip } from "./eventsBlockHeadlineStyles"

export const EventsBlockHeadline = ({ text, amount }) => (
  <Headline>
    {text}:&nbsp;
    <SuggestionChip>
      {amount} Suggestion{amount > 1 ? "s" : ""}
    </SuggestionChip>
  </Headline>
)

EventsBlockHeadline.propTypes = {
  amount: PropTypes.number,
  text: PropTypes.string,
}
