import { useCallback } from "react"

import moment from "moment-timezone"

export const useSameHour = currentHour => {
  return useCallback(
    ({ startDate }) => moment(currentHour).isSame(moment(startDate), "hour"),
    [currentHour]
  )
}
