import React, { createContext, useEffect, useState } from "react"

import { useMediaQuery } from "@mui/material"
import { useHistory } from "react-router-dom"

import { SIDEBAR_MENU } from "@constants/sidebar"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

const isSidebarOpenKey = "is_sidebar_open"

export const SideBarContext = createContext({})

export const SideBarState = ({ children }) => {
  const { location } = useHistory()
  const [focusCounter, setFocusCounter] = useState(1)
  const [isSideBarOpen, setIsSideBarOpen] = useState(() => {
    const storageVal = localStorage.getItem(isSidebarOpenKey)
    return storageVal ? storageVal === "true" : true
  })
  const [openNumber, setOpenNumber] = useState(0)

  const handleToggleSidebar = value => {
    setIsSideBarOpen(value)
    localStorage.setItem(isSidebarOpenKey, value)
  }

  const activateSidebar = () => {
    const { pathname } = location

    Object.values(SIDEBAR_MENU).forEach(menuItem => {
      const isTeeupsTab = menuItem.hasOwnProperty("TEEUPS_MODE")

      if (pathname.includes(menuItem.PATHNAME) && !isTeeupsTab) {
        setFocusCounter(menuItem.INDEX)
      }
    })
  }

  const isTablet = useMediaQuery(`(max-width: ${MEDIA_SIZES.TABLET_L})`)

  useEffect(() => {
    if (!isTablet) return
    handleToggleSidebar(false)
  }, [isTablet])

  useEffect(() => {
    activateSidebar()
  }, [activateSidebar, location])

  return (
    <SideBarContext.Provider
      value={{
        focusCounter,
        setFocusCounter,
        isSideBarOpen,
        handleToggleSidebar,
        openNumber,
        setOpenNumber,
      }}>
      {children}
    </SideBarContext.Provider>
  )
}
