import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Timezone = styled(NSubtitleS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.regular};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
