import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { fetchTeeups } from "@actions/teeupActions"
import {
  selectCalendarDays,
  selectCalendarEmails,
  selectDisplayCalendar,
} from "@selectors/calendar"
import { fetchCalendarEvents } from "@utils/calendarSyncUtils"
import {
  SCREENS,
  getDatesArrayForSwiping,
  findPositionInExistingDatesArray,
} from "@utils/calendarUtils"
import {
  getDateWithTimezone,
  isDateBeforeNow,
  generateDaysArray,
  getDuration,
  isSameDate,
} from "@utils/dateUtils"
import { DATE_SELECT_TYPES } from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import { store } from "index"
import dayjs from "utils/dayjs"

import CalendarView from "./calendarView"

class CalendarContainer extends React.Component {
  constructor(props) {
    super(props)
    const { newSuggestionProps, fromProfile, calendarWidth, comingUpWidth } =
      props
    const selectedDate = props.selectedDate
      ? getDateWithTimezone(props.selectedDate)
      : getDateWithTimezone()
    const activeScreen = props.activeScreen
      ? props.activeScreen
      : props.useComingUpScreen
      ? SCREENS.comingUp
      : SCREENS.month
    this.state = {
      isEventsLoading: false,
      selectedDate,
      activeScreen,
      hasPickerBackground: false,
      duration:
        newSuggestionProps?.duration || newSuggestionProps?.duration >= 0
          ? newSuggestionProps.duration
          : 60,
      // isLoaded: false,
      isLoaded: true,
      verticalScrollPosition: null,
      ITEM_WIDTH: fromProfile ? calendarWidth : comingUpWidth,
      ...getDatesArrayForSwiping(
        selectedDate,
        activeScreen,
        props.allowGoToPast
      ),
    }

    this.datesArray = generateDaysArray()
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 20,
      waitForInteraction: false,
    }
  }

  fetchCalendarEventsHandle = async () => {
    this.setState({ isEventsLoading: true })
    const { calendars } = store.getState().user.userInfo

    const outlookCalendar = calendars?.find(c => c.provider === "windowslive")
    const googleCalendar = calendars?.find(c => c.provider === "google-oauth2")

    if (outlookCalendar || googleCalendar) {
      const fetchAllTeeups = fetchTeeups()

      fetchAllTeeups(store.dispatch, store.getState)

      // Awaiting for selected calendar actions
      setTimeout(() => {
        fetchCalendarEvents({})
      }, 0)
      this.setState({ isEventsLoading: false })
    }
  }

  componentDidMount() {
    this.fetchCalendarEventsHandle()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentPosition: prevCurrentPosition,
      selectedDate: prevPropsDate,
      newSuggestionProps: prevNewSuggestionProps,
      calendarWidth: prevCalendarWidth,
      comingUpWidth: prevComingUpWidth,
      calendarEmails: prevCalendarEmails,
    } = prevProps
    const {
      currentPosition,
      middlePosition,
      selectedDate,
      useComingUpScreen,
      newSuggestionProps = {},
      calendarWidth,
      fromProfile,
      comingUpWidth,
      calendarEmails,
    } = this.props

    const prevDateObj =
      prevPropsDate && prevPropsDate !== this.state.selectedDate
        ? getDateWithTimezone(prevPropsDate)
        : dayjs()
    const selectedDateObj = getDateWithTimezone(selectedDate)
    if (selectedDateObj && !prevDateObj.isSame(selectedDateObj, "minute")) {
      this.setState({ selectedDate: selectedDateObj })
    }
    // if (fromProfile && prevState.activeScreen !== this.props.activeScreenFN) {
    //   this.setState({ activeScreen: this.props.activeScreenFN, datesArray: [] })
    // }
    if (fromProfile && prevCalendarWidth !== calendarWidth) {
      this.setState({ ITEM_WIDTH: calendarWidth })
    }

    if (!fromProfile && prevComingUpWidth !== comingUpWidth) {
      this.setState({ ITEM_WIDTH: comingUpWidth + 50 })
    }
    if (
      (newSuggestionProps.duration || newSuggestionProps.duration >= 0) &&
      prevNewSuggestionProps?.duration !== newSuggestionProps?.duration
    ) {
      this.setState({ duration: newSuggestionProps.duration })
    }
    if (
      prevCurrentPosition !== currentPosition &&
      currentPosition === middlePosition &&
      useComingUpScreen
    ) {
      this.setState({
        activeScreen: SCREENS.comingUp,
        datesArray: [],
        verticalScrollPosition: null,
      })
    }

    if (prevCalendarEmails !== calendarEmails && calendarEmails.length > 0) {
      this.fetchCalendarEventsHandle()
    }
  }

  getDateChangeProps = () => {
    const { selectedDate, activeScreen } = this.state
    const date = getDateWithTimezone(selectedDate)
    let interval
    if (activeScreen === SCREENS.day) {
      interval = "d"
    }
    if (activeScreen === SCREENS.week) {
      interval = "w"
    }
    if (activeScreen === SCREENS.month) {
      interval = "M"
    }
    return { date, interval }
  }

  moveCalendarForward = (isDouble, isIgnore) => {
    // TODO: temporary return for testing. If everything is okay - remove this method with its calls
    return
    // const { date, interval } = this.getDateChangeProps()
    // if (interval && !isIgnore) {
    //   if (isDouble) date.add(2, interval)
    //   else date.add(1, interval)
    // }
    // const newIndex = this.getNewHorizontalScrollIndex(
    //   this.state.selectedDate,
    //   date
    // )
    //
    // // if (newIndex !== this.state.currentDayIndex) {
    // if (newIndex >= 0) {
    //   this.setState({
    //     currentDayIndex: newIndex,
    //     selectedDate: date,
    //   })
    //
    //   if (this._flatList) {
    //     this._flatList.scrollToIndex({
    //       animated: true,
    //       index: newIndex,
    //     })
    //   }
    // } else {
    //   this.reloadDatesArray(
    //     date,
    //     this.state.activeScreen,
    //     this.props.allowGoToPast
    //   )
    // }
    // }
  }

  newSelectToday = () => {
    return new Promise(resolve => {
      const date = dayjs()
      const newIndex = this.getNewHorizontalScrollIndex(
        this.state.selectedDate,
        date
      )
      this.setState({
        currentDayIndex: newIndex,
        selectedDate: date,
      })
      this.reloadDatesArray(
        date,
        this.state.activeScreen,
        this.props.allowGoToPast
      )
      if (this._flatList) {
        this._flatList.scrollToIndex({
          animated: true,
          index: newIndex,
        })
      }
      resolve()
    })
  }

  newMoveBack = () => {
    return new Promise((resolve, reject) => {
      const { date, interval } = this.getDateChangeProps()
      if (interval) {
        date.subtract(1, interval)
      }
      const newIndex = this.getNewHorizontalScrollIndex(
        this.state.selectedDate,
        date
      )
      if (newIndex !== this.state.currentDayIndex) {
        if (newIndex > 2) {
          this.setState({
            currentDayIndex: newIndex,
            selectedDate: date,
          })

          if (this._flatList) {
            this._flatList.scrollToIndex({
              animated: true,
              index: newIndex,
            })
          }
          resolve()
        } else {
          this.reloadDatesArray(
            date,
            this.state.activeScreen,
            this.props.allowGoToPast
          )
          reject()
        }
      }
    })
  }

  newMoveForward = () => {
    return new Promise((resolve, reject) => {
      const { date, interval } = this.getDateChangeProps()
      if (interval) {
        date.add(1, interval)
      }
      const newIndex = this.getNewHorizontalScrollIndex(
        this.state.selectedDate,
        date
      )
      if (newIndex > 2) {
        this.setState({
          currentDayIndex: newIndex,
          selectedDate: date,
        })

        if (this._flatList) {
          this._flatList.scrollToIndex({
            animated: true,
            index: newIndex,
          })
        }
        resolve()
      } else {
        this.reloadDatesArray(
          date,
          this.state.activeScreen,
          this.props.allowGoToPast
        )
        reject()
      }
    })
  }

  moveCalendarBackward = () => {
    // TODO: temporary commented for testing. If everything is okay - remove this method with its calls
    // const { date, interval } = this.getDateChangeProps()
    // if (interval) {
    //   date.subtract(1, interval)
    // }
    // const newIndex = this.getNewHorizontalScrollIndex(
    //   this.state.selectedDate,
    //   date
    // )
    // if (newIndex !== this.state.currentDayIndex) {
    //   if (newIndex >= 0) {
    //     this.setState({
    //       currentDayIndex: newIndex,
    //       selectedDate: date,
    //     })
    //
    //     if (this._flatList) {
    //       this._flatList.scrollToIndex({
    //         animated: true,
    //         index: newIndex,
    //       })
    //     }
    //   } else {
    //     this.reloadDatesArray(
    //       date,
    //       this.state.activeScreen,
    //       this.props.allowGoToPast
    //     )
    //   }
    // }
  }

  getNewDate = date => {
    const { newSuggestionProps } = this.props
    let newSelectedDate = getDateWithTimezone(date)
    if (newSuggestionProps && isDateBeforeNow(newSelectedDate)) {
      newSelectedDate = getDateWithTimezone()
    }
    return newSelectedDate
  }

  getNewHorizontalScrollIndex = (prevDate, newDate) => {
    const prevDateObj = getDateWithTimezone(prevDate)
    const selectedDateObj = getDateWithTimezone(newDate)
    // if (selectedDateObj && !prevDateObj.isSame(selectedDateObj, "day")) {
    const newIndex = findPositionInExistingDatesArray(
      this.state.datesArray,
      selectedDateObj,
      this.state.activeScreen
    )
    return newIndex
    // }
    // return this.state.currentDayIndex
  }

  changeDate = date => {
    this.setState({
      selectedDate: this.getNewDate(date),
    })
  }

  handleChangeDuration = duration => this.setState({ duration })

  handleSelectDateForSuggestion = () => {
    const { selectedDate, duration: stateDuration } = this.state
    const {
      newSuggestionProps: { onDatesSelected, duration, passedEvent },
      onCloseCalendarCard,
    } = this.props
    const newDate = isDateBeforeNow(selectedDate)
      ? getDateWithTimezone()
      : getDateWithTimezone(selectedDate)

    onCloseCalendarCard()

    onDatesSelected({
      ...passedEvent,
      duration: stateDuration || stateDuration === 0 ? stateDuration : duration,
      startDate: newDate,
      startTime: newDate,
      type: DATE_SELECT_TYPES.date,
    })
  }

  onPickTimerangeFromCalendar = () => {
    const { selectedDate, duration } = this.state
    // showGameplanTimePicker({
    //     onDone: this.setDateAndDurationFromPicker,
    //     listComponentId: 'calendarContainer',
    //     isEndEnabled: true,
    //     startDate: selectedDate,
    //     endDate: getDateWithTimezone(selectedDate).add(duration, 'minutes'),
    //     duration,
    //     type: DATE_SELECT_TYPES.timeRange,
    //     checkForTodaysDate: () => {},
    //     startDateIndex: 0,
    //     timezone: getCurrentTimezone(),
    // })
  }

  setDateAndDurationFromPicker = ({ startDate, endDate }) => {
    const start = isDateBeforeNow(startDate)
      ? getDateWithTimezone()
      : getDateWithTimezone(startDate)
    const end = getDateWithTimezone(endDate)
    const duration = getDuration(start, end)
    this.changeDate(start)
    this.handleChangeDuration(duration)
  }

  changeViewType = (view, date) => {
    // this.setState({ isLoaded: false })
    let updates = {
      activeScreen: view,
      verticalScrollPosition: null,
    }
    if (date) {
      updates.selectedDate = this.getNewDate(date)
    }
    if (view === SCREENS.comingUp) {
      updates = { ...updates, datesArray: [] }
    } else {
      const selectedDateObj = updates.selectedDate || this.state.selectedDate
      const { datesArray, currentDayIndex } = getDatesArrayForSwiping(
        selectedDateObj,
        view,
        this.props.allowGoToPast
      )
      updates = { ...updates, datesArray, currentDayIndex: currentDayIndex + 1 }
    }

    this.setState(updates)
    // this.changePickerBackground(false)
    // this.props.changePosition &&
    //     this.props.changePosition({ stopInTopPosition: true })
    // if (updates.datesArray && !isNaN(updates.currentDayIndex)) {
    //     setTimeout(() => {
    //         if (this._flatList) {
    //             this._flatList.scrollToIndex({
    //                 animated: false,
    //                 index: updates.currentDayIndex,
    //             })
    //         }
    // this.setState({ isLoaded: true })
    // }, 2500)
    // }
  }

  changePickerBackground = hasBackground => {
    this.setState({ hasPickerBackground: hasBackground })
  }

  openViewTypes = () => {
    const { toggleUp } = this.props
    if (toggleUp) {
      toggleUp()
    }

    this.setState(state => ({
      isMenuButtonModalOpen: !state.isMenuButtonModalOpen,
    }))
  }

  getButtons = () => {
    const { activeScreen, selectedDate } = this.state
    const { useComingUpScreen } = this.props
    // this.changePickerBackground(true)

    const buttons = [
      {
        title: SCREENS.day,
        image: images.calendarDay,
        callback: () => {
          this.setState({ selectedDate: dayjs() })
          this.changeViewType(SCREENS.day, dayjs())
        },
        isSelected: activeScreen === SCREENS.day,
      },
      {
        title: SCREENS.week,
        image: images.calendarWeek,
        callback: () => {
          this.changeViewType(SCREENS.week, selectedDate, dayjs())
        },
        isSelected: activeScreen === SCREENS.week,
      },
      {
        title: SCREENS.month,
        image: images.calendarMonth,
        callback: () => this.changeViewType(SCREENS.month, dayjs()),
        isSelected: activeScreen === SCREENS.month,
      },
    ]

    if (useComingUpScreen) {
      buttons.unshift({
        title: SCREENS.comingUp,
        image: images.calendarComingUp,
        callback: () => this.changeViewType(SCREENS.comingUp, dayjs()),
        isSelected: activeScreen === SCREENS.comingUp,
      })
    }
    return buttons
  }

  onMomentumScrollEnd = ({ nativeEvent }) => {
    const {
      selectedDate,
      activeScreen,
      currentDayIndex,
      datesArray,
      ITEM_WIDTH,
    } = this.state

    const { fromProfile } = this.props
    const { allowGoToPast } = this.props

    const indexToAdd = fromProfile ? -1 : 0
    const newIndex =
      Math.round(nativeEvent.contentOffset.x / ITEM_WIDTH) + indexToAdd

    const newDateIntervalStart = datesArray[newIndex]

    if (newIndex !== currentDayIndex) {
      const selectedDateObj = getDateWithTimezone(selectedDate)
      const newDate = getDateWithTimezone(datesArray[newIndex])
      if (activeScreen === SCREENS.month) {
        newDate.date(selectedDateObj.date())
      }
      if (activeScreen === SCREENS.week) {
        newDate.day(selectedDateObj.day())
      }
      newDate.hour(selectedDateObj.hour())
      newDate.minute(selectedDateObj.minute())

      const newSelectedDate = this.getNewDate(newDate)

      this.setState({
        selectedDate: newSelectedDate,
        currentDayIndex: newIndex,
      })
    }

    // if (newIndex === 0 && currentDayIndex === 0) {
    //     const comparingType = COMPARING_TYPES[activeScreen]
    //     const todayIntervalStart = getDateWithTimezone().startOf(
    //         comparingType
    //     )

    //     if (
    //         !allowGoToPast &&
    //         getDateWithTimezone(newDateIntervalStart).isSame(
    //             todayIntervalStart,
    //             'day'
    //         )
    //     ) {
    //         return
    //     }
    //     this.reloadDatesArray(
    //         newDateIntervalStart,
    //         activeScreen,
    //         allowGoToPast
    //     )
    // }
    // if (
    //     newIndex === datesArray.length &&
    //     currentDayIndex === datesArray.length
    // ) {
    //     this.reloadDatesArray(
    //         newDateIntervalStart,
    //         activeScreen,
    //         allowGoToPast
    //     )
    // }
  }

  setVerticalScrollPosition = position => {
    this.setState({ verticalScrollPosition: position })
  }

  reloadDatesArray = (selectedDate, activeScreen, allowGoToPast) => {
    const newSelectedDate = this.getNewDate(selectedDate)
    const { datesArray, currentDayIndex } = getDatesArrayForSwiping(
      newSelectedDate,
      activeScreen,
      allowGoToPast
    )
    this.setState({
      selectedDate: newSelectedDate,
      datesArray,
      currentDayIndex,
      // isLoaded: false,
    })
    // if (datesArray && !isNaN(currentDayIndex)) {
    //     setTimeout(() => {
    //         if (this._flatList) {
    //             this._flatList.scrollToIndex({
    //                 animated: false,
    //                 index: currentDayIndex,
    //             })
    //         }
    //         // this.setState({ isLoaded: true })
    //     }, 2500)
    // }
  }

  render() {
    const {
      selectedDate,
      activeScreen,
      hasPickerBackground,
      duration,
      verticalScrollPosition,
      datesArray,
      currentDayIndex,
      isMenuButtonModalOpen,
      ITEM_WIDTH,
      isEventsLoading,
    } = this.state
    const {
      showSettingsIcon,
      comingUpComponent,
      calendarCardStyles,
      newSuggestionProps,
      allowGoToPast,
      isFromSugggestionDetails,
      fromProfile,
      calendarWidth,
      comingUpWidth,
      refVal,
    } = this.props

    const isDateDifferentFromInitial =
      newSuggestionProps &&
      (!isSameDate(newSuggestionProps.selectedDate, selectedDate) ||
        duration !== newSuggestionProps.duration)

    return (
      <>
        <CalendarView
          isEventsLoading={isEventsLoading}
          flatListRef={ref => (this._flatList = ref)}
          isLoaded={true}
          verticalScrollPosition={verticalScrollPosition}
          datesArray={datesArray}
          currentDayIndex={currentDayIndex}
          allowGoToPast={allowGoToPast}
          newSuggestionProps={newSuggestionProps}
          onPickTimerangeFromCalendar={this.onPickTimerangeFromCalendar}
          selectedDate={selectedDate}
          changeSelectedDate={selectedDate => this.setState({ selectedDate })}
          newMoveBack={this.newMoveBack}
          newMoveForward={this.newMoveForward}
          newSelectToday={this.newSelectToday}
          duration={duration}
          activeScreen={activeScreen}
          showSettingsIcon={showSettingsIcon}
          hasPickerBackground={hasPickerBackground}
          comingUpComponent={comingUpComponent}
          calendarCardStyles={calendarCardStyles}
          isDateDifferentFromInitial={isDateDifferentFromInitial}
          moveCalendarForward={this.moveCalendarForward}
          moveCalendarBackward={this.moveCalendarBackward}
          changeViewType={this.changeViewType}
          changeDate={this.changeDate}
          openViewTypes={this.openViewTypes}
          openSettings={this.props.openSettings}
          onSelectDateForSuggestion={this.handleSelectDateForSuggestion}
          onDurationChange={this.handleChangeDuration}
          setVerticalScrollPosition={this.setVerticalScrollPosition}
          onMomentumScrollEnd={this.onMomentumScrollEnd}
          isFromSugggestionDetails={isFromSugggestionDetails}
          ITEM_WIDTH={ITEM_WIDTH}
          fromProfile={fromProfile}
          isMenuButtonModalOpen={isMenuButtonModalOpen}
          buttons={this.getButtons()}
          calendarWidth={calendarWidth}
          comingUpWidth={comingUpWidth}
          refVal={refVal}
          getDateChangeProps={this.getDateChangeProps}
          getNewHorizontalScrollIndex={this.getNewHorizontalScrollIndex}
        />
      </>
    )
  }
}

CalendarContainer.propTypes = {
  allowGoToPast: PropTypes.bool,
  useComingUpScreen: PropTypes.bool,
  showSettingsIcon: PropTypes.bool,
  changePosition: PropTypes.func,
  comingUpComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  calendarCardStyles: PropTypes.object,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activeScreen: PropTypes.string,
  calendarDays: PropTypes.array,
  onCloseCalendarCard: PropTypes.func,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      passedEvent: PropTypes.object,
      isFromCreateTeeup: PropTypes.bool,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  // currentPosition: PropTypes.object.isRequired,
  // middlePosition: PropTypes.object.isRequired,
  isFromSugggestionDetails: PropTypes.bool,
}

CalendarContainer.defaultProps = {
  allowGoToPast: true,
  useComingUpScreen: false,
  showSettingsIcon: false,
}

const mapStateToProps = (state, ownProps) => {
  const calendarDays = selectCalendarDays(state)
  const { newSuggestionProps, selectedDate } = ownProps

  return {
    calendarDays,
    calendarEmails: selectCalendarEmails(state),
    selectedDate: selectedDate || newSuggestionProps?.selectedDate,
    displayCalendar: selectDisplayCalendar(state),
  }
}

export default connect(mapStateToProps)(CalendarContainer)
