import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  position: relative;
`

export const Crown = styled(SvgIcon)`
  position: absolute;
  top: -5px;
  left: -4px;
`

export const StatusBackground = styled.div`
  ${({ $color }) => css`
    width: 16px;
    height: 16px;
    background-color: ${$color};
    border: 1px solid var(--white);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    right: 0;
  `};
`

export const StatusIcon = styled.img`
  width: 8px;
  height: 8px;
`
