// TODO: here was "moment" if everything is okay - remove this comment

import { gapi } from "gapi-script"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import moment from "moment-timezone"
import ApiCalendar from "react-google-calendar-api"
import { Platform } from "react-native"

import actionTypes from "@actions/actionTypes"
import {
  calendarTeeupRemoved,
  calendarTeeupAdd,
  calendarAddTeeupEvents,
  setCalendarEmailData,
  setDisplayedData,
  resetSingleCalendar,
  createCalendarEvent,
  deleteCalendarEvent,
  setIsCalendarSynchronized,
} from "@actions/calendarActions"
import { postUserSettings } from "@actions/settingsActions"
import { fetchTeeups } from "@actions/teeupActions"
import endpoints from "@config/endpoints"
import {
  loginProviders,
  loginProvidersText,
  teeupStatusKeys,
} from "@configs/enums"
import strings from "@i18n"
import {
  selectCalendarDays,
  selectSelectedCalendar,
  selectAdditionalCalendars,
  selectCalendarEmails,
  selectDisplayCalendar,
  selectSyncEmail,
  selectShouldSyncOnlyAllSet,
  selectPrimaryOutlookCalendar,
  selectCalendarById,
  selectPrimaryCalendarIds,
} from "@selectors/calendar"
import {
  selectTeeups,
  selectArchivedTeeups,
  selectTeeupsGameplans,
  selectAllTeeups,
} from "@selectors/teeups"
import {
  selectUserId,
  selectUserSettings,
  selectCalendarSyncSettings,
} from "@selectors/user"
import Toast from "@ui/toast"
import {
  DEFAULT_SYNCED_DURATION_MINS,
  DEFAULT_EVENT_DURATION_MINS,
  getEndDate,
  CALENDAR_TYPES,
} from "@utils/calendarUtils"
import {
  formatCalendarEventDate,
  getDateWithTimezone,
  isEqualDates,
  getCurrentTimezone,
  addHours,
} from "@utils/dateUtils"
import { getSelectedGameplan } from "@utils/gamePlanUtils"
import { getExternalCalendars } from "@utils/getExternalCalendars"
import { logCalendarReading } from "@utils/logCalendarReading"
import {
  getDecidedWhen,
  isTeeupDecided,
  isTeeupDisabled,
} from "@utils/teeupUtils"
import api, { customCalInstance } from "api"

import { store } from "../index"
import CalendarState from "../reducers/calendarStorage"

const SKIP_CALENDAR_PERMISSION = "SKIP_CALENDAR_PERMISSION"

export const isAuthorized = () => {
  // return RNCalendarEvents.checkPermissions()
  //     .then((status) => {
  //         if (status === 'authorized') {
  //             return true
  //         } else if (status === 'undetermined' || status === 'denied') {
  //             // think its temp solution. Was a crash when we ask calendar permission when other we already have permission prompt
  //             return SKIP_CALENDAR_PERMISSION
  //         }
  //         return false
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.authorizationStatus: error', {
  //             error,
  //         })
  //         return false
  //     })
}

export const authorize = () => {
  // return RNCalendarEvents.requestPermissions()
  //     .then((status) => {
  //         if (status === 'authorized') {
  //             return true
  //         }
  //         return false
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.authorizeEventStore: error', {
  //             error,
  //         })
  //         return false
  //     })
}

export const hasCalendarPermissions = async supressToasts => {
  // const permission = await handleCheckCalendarPermissions(supressToasts)
  // switch (permission) {
  //     // case RESULTS.UNAVAILABLE:
  //     //   This feature is not available (on this device / in this context)
  //     //   break;
  //     case RESULTS.DENIED:
  //         // The permission has not been requested / is denied but requestable
  //         if (!supressToasts) {
  //             Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //         }
  //         return false
  //     case RESULTS.BLOCKED:
  //         // The permission is denied and not requestable anymore
  //         return false
  //     case RESULTS.GRANTED:
  //         break
  // }
  let isAllowed = await isAuthorized()
  if (!isAllowed || (isAllowed && isAllowed !== SKIP_CALENDAR_PERMISSION)) {
    isAllowed = await authorize()
  }

  if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
    if (!supressToasts) {
      Toast.show(strings.calendar.toastMsg.authorizeManually)
    }
    return false
  }

  let calendar = await getSpecificCalendarById(
    selectSelectedCalendar(store.getState())
  )

  if (!calendar) {
    if (!supressToasts) {
      Toast.show(strings.calendar.toastMsg.notFound)
    }
    return false
  }

  return true
}

export const handleCheckCalendarPermissions = async (supressToasts = false) => {
  const permissionChecks = []
  // if (Platform.OS === 'ios') {
  //     permissionChecks.push(check(PERMISSIONS.IOS.CALENDARS))
  // } else {
  //     permissionChecks.push(check(PERMISSIONS.ANDROID.READ_CALENDAR))
  //     permissionChecks.push(check(PERMISSIONS.ANDROID.WRITE_CALENDAR))
  // }

  // const checkResult = await Promise.all(permissionChecks)
  // if (checkResult.every((result) => result === RESULTS.GRANTED)) {
  //     return RESULTS.GRANTED
  // }
  // if (checkResult.some((result) => result === RESULTS.UNAVAILABLE)) {
  //     return RESULTS.UNAVAILABLE
  // }
  // if (checkResult.some((result) => result === RESULTS.BLOCKED)) {
  //     if (!supressToasts) {
  //         Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //     }
  //     return RESULTS.BLOCKED
  // }
  // if (checkResult.some((result) => result === RESULTS.DENIED)) {
  //     if (!supressToasts) {
  //         Toast.show(I18n.t('calendar.toastMsg.noPermissions'))
  //     }
  //     return RESULTS.DENIED
  // }
}

export const handleAskCalendarPermissions = async (
  callback,
  supressToasts = false
) => {
  let permissionsCheck = await handleCheckCalendarPermissions(supressToasts)
  // switch (permissionsCheck) {
  //     // case RESULTS.DENIED:
  //     case RESULTS.UNAVAILABLE:
  //         return permissionsCheck
  //     case RESULTS.GRANTED:
  //         callback()
  //         return permissionsCheck
  // }
  // const requestMessage = {
  //   title: strings.calendar.alerts.permissionTitle,
  //   message: strings.calendar.alerts.permissionMsg,
  //   buttonNeutral: strings.systemButtons.askMeLater,
  //   buttonNegative: strings.systemButtons.cancel,
  //   buttonPositive: strings.systemButtons.ok,
  // }
  // if (Platform.OS === 'ios') {
  //     const requestResult = await request(
  //         PERMISSIONS.IOS.CALENDARS,
  //         requestMessage
  //     )
  //     requestResults.push(requestResult)
  // } else {
  //     if (permissionsCheck !== RESULTS.BLOCKED)
  //         requestResults = await requestMultiple([
  //             PERMISSIONS.ANDROID.READ_CALENDAR,
  //             PERMISSIONS.ANDROID.WRITE_CALENDAR,
  //         ])
  // }

  permissionsCheck = await handleCheckCalendarPermissions(supressToasts)

  // if (permissionsCheck === RESULTS.GRANTED) {
  //     callback()
  // }
  return permissionsCheck
}

let calendars
export const getCalendars = async () => {
  if (calendars) {
    return calendars
  }

  let isAllowed = await isAuthorized()
  if (!isAllowed || (isAllowed && isAllowed !== SKIP_CALENDAR_PERMISSION)) {
    isAllowed = await authorize()
  }

  // calendars = await RNCalendarEvents.findCalendars()
  return calendars
}

export const getSpecificCalendarById = async selectedCalendar => {
  let calendars = await getCalendars()
  return getSpecificCalendarByIdWithCalendars(selectedCalendar, calendars)
}

export const getSpecificCalendarByIdWithCalendars = (
  selectedCalendar,
  calendars
) => {
  let calendar = null
  if (CalendarState && calendars.length > 0) {
    // Should be efficient enough for this screen
    calendars.forEach(cal => {
      if (cal.id === selectedCalendar) {
        calendar = cal
      }
    })
  }
  return calendar
}

export const getCalendarEvents = async ({
  syncSelectedInterval,
  start,
  shouldSupressPermissionToast = true,
}) => {
  let isAllowed = await hasCalendarPermissions(shouldSupressPermissionToast)

  if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
    return
  }

  const state = store.store()
  // const selectedCalendarId = selectSelectedCalendar(state)
  // const selectedAdditionalCalendarIds = selectAdditionalCalendars(state)
  const calendarDays = selectCalendarDays(state)

  let startDate
  let endDate
  if (syncSelectedInterval) {
    startDate = getDateWithTimezone(start).startOf("month")
    endDate = getDateWithTimezone(start).endOf("month")
  } else {
    startDate = getDateWithTimezone().startOf("year")
    endDate = getDateWithTimezone().endOf("year")
    let calendarStartDate
    let calendarEndDate
    if (calendarDays.length > 0) {
      calendarStartDate = getDateWithTimezone(calendarDays[0]).startOf("month")
      calendarEndDate = getDateWithTimezone(calendarDays.at(-1)).endOf("month")
    }
    if (calendarStartDate && calendarEndDate) {
      if (startDate && startDate.isAfter(calendarStartDate)) {
        startDate = calendarStartDate
      }
      if (endDate && endDate.isBefore(calendarEndDate)) {
        endDate = calendarEndDate
      }
    }
  }

  startDate = startDate?.toISOString()
  endDate = endDate?.toISOString()
  if (!startDate) {
    return
  }
  let events = []
  try {
    // events = await RNCalendarEvents.fetchAllEvents(startDate, endDate, [
    //     selectedCalendarId,
    //     ...selectedAdditionalCalendarIds,
    // ])
  } catch (error) {
    console.log("RNCalendarEvents.fetchAllEvents error:", error)
    return
  }

  const teeups = selectTeeups(store.getState())
  const archivedTeeups = selectArchivedTeeups(store.getState())
  const teeupEvents = {}
  const teeupsWithEvents = [...teeups, ...archivedTeeups]
  teeupsWithEvents.forEach(teeup => {
    if (teeup?.events && teeup?.events?.length > 0) {
      teeup.events.forEach(event => {
        if (event.eventId) {
          teeupEvents[event.eventId] = true
        }
      })
    }
  })

  // Remove teeup events
  const filteredEvents = []
  events.forEach(event => {
    if (teeupEvents[event.id]) {
      // Teeup event found
      return
    }
    // Transformation of events
    let formattedEvent = { ...event, name: event.title }
    if (event.allDay) {
      const momentDate = moment.utc(event.startDate)
      const eventDay = getDateWithTimezone()
        .year(momentDate.year())
        .month(momentDate.month())
        .date(momentDate.date())

      formattedEvent.startDate = formatCalendarEventDate(
        eventDay.startOf("day")
      )
      formattedEvent.endDate = formatCalendarEventDate(eventDay.endOf("day"))
    } else if (!event.endDate || isEqualDates(event.startDate, event.endDate)) {
      formattedEvent.endDate = formatCalendarEventDate(
        getEndDate({
          startDate: event.startDate,
          endDate: event.endDate,
          overrideDuration: true,
          defaultDurationMins: DEFAULT_EVENT_DURATION_MINS,
        })
      )
    }
    filteredEvents.push(formattedEvent)
  })

  store.dispatch({
    type: actionTypes.GOT_CALENDAR,
    payload: filteredEvents,
  })
}

const checkExistingCalendarEvent = async event => {
  if (!event || !event.eventId) {
    return null
  }

  let eventId = event.eventId
  if (Platform.OS === "android") {
    // In Android calendar event ids need to be int
    eventId = Number.parseInt(eventId)
  }
  if (eventId) {
    // try {
    //     let existingEvent = await RNCalendarEvents.findEventById(
    //         '' + eventId
    //     )
    //     if (existingEvent) {
    //         return {
    //             ...event,
    //             ...existingEvent,
    //             eventIdBE: event.id,
    //         }
    //     }
    // } catch (error) {
    //     console.log('RNCalendarEvents.findEventById error: ', error)
    // }
    // The event is not found. Maybe user deleted it manually or on a different device eventId is not shared
    calendarTeeupRemoved(event.teeupId, event.id)
    return null
  }
  return null
}

const unscheduleCalendarEvent = async (event, teeupId) => {
  if (event && event.id && !event.external) {
    // can delete only the events we've auto-synced
    await removeTeeupEvent(event.eventId)
    calendarTeeupRemoved(teeupId, event.eventIdBE)
  }
}

export const syncCalendarTeeups = async (
  userId,
  shouldSync,
  shouldSyncAllSetOnly,
  selectedCalendar,
  teeups = [],
  teeupGameplans,
  teeupsState = {}, // dynamic, usually unseen updates for teeups,
  shouldCheckSelectedCalendar = true
) => {
  if (shouldCheckSelectedCalendar && (!shouldSync || !selectedCalendar)) {
    // Only allow to even try syncing if a specific calendar added
    return
  }

  if (shouldCheckSelectedCalendar) {
    let isAllowed = await hasCalendarPermissions(true)
    if (!isAllowed || (isAllowed && isAllowed === SKIP_CALENDAR_PERMISSION)) {
      return
    }
  }
  let newlySyncedTeeups = {}

  for (let i = 0, len = teeups.length; i < len; i++) {
    const teeup = teeups[i]
    const teeupId = teeup.id
    const teeupState = teeupsState[teeupId]
    const gameplans = teeupGameplans[teeupId]
    const teeupTitle =
      teeupState && teeupState.newTitle ? teeupState.newTitle : teeup.name
    const teeupStatus =
      teeupState && teeupState.newStatus ? teeupState.newStatus : teeup.status

    let existingEvents = []
    let existingEventIds = []

    // Should try to sync this teeup to calendar if:
    // 1) not there yet
    // 2) has a date/time specific when gameplan

    // TODO: also handle startsWhen and whenWorks

    // Use dynamically gotten teeup updates if any. Otherwise use downloaded teeup gameplan
    // TODO: why teeupState has newWhen.when? should immediately be the correct structure in newWhen?
    const newWhen = get(teeupState, ["newWhen", "when"], null)
    let startDate
    let endDate
    let isDesided = false
    if (newWhen) {
      if (newWhen.type === "Day" && teeupState.newWhen.type === "WHEN") {
        // Avoiding startsWhen/whenWorks for now
        startDate = newWhen.value
        isDesided = newWhen.isDecided
      }
    } else {
      if (gameplans) {
        let whenGameplan = getSelectedGameplan(gameplans, "when")

        if (whenGameplan) {
          if (!whenGameplan.isCustomDate && !whenGameplan.isCustomTime) {
            // Time/date specific when gameplan
            startDate = whenGameplan.startDate
            endDate = getEndDate(whenGameplan)
          }
          isDesided = whenGameplan.decided
        } else {
          // Maybe it's startsWhen or whenWorks
          gameplans.forEach(gameplan => {
            if (gameplan.type === "startsWhen") {
              if (gameplan.conditionTime) {
                // conditionTime is the optimal predicted time that fits the best
                startDate = gameplan.conditionTime
              }
            } else if (gameplan.type === "worksWhen") {
              // TODO: in progress
            }
          })
        }
      }
    }
    let promises = []
    if (teeup?.events && teeup?.events?.length > 0) {
      promises = teeup.events.map(
        async event => await checkExistingCalendarEvent(event)
      )
    }
    existingEvents = await Promise.all(promises)
    existingEvents = existingEvents.filter(event => !!event)
    existingEventIds = existingEvents.map(event => event.id)
    if (
      !startDate ||
      isTeeupDisabled(teeup) ||
      (shouldSyncAllSetOnly &&
        !(
          teeupStatus === teeupStatusKeys.allset ||
          teeupStatus === teeupStatusKeys.happening
        ))
    ) {
      // Might need to unschedule this as probably it was 'allset' teeup and becase 'planning' or something
      let promises = []
      if (existingEvents && existingEvents.length > 0) {
        const newlySynced = []
        promises = existingEvents.map(async event => {
          if (event.external) {
            newlySynced.push(event)
            return null
          }
          await unscheduleCalendarEvent(event, teeupId)
        })
        newlySyncedTeeups[teeupId] = {
          ...teeup,
          teeupId,
          userId,
          events: newlySynced,
        }
      }
      await Promise.all(promises)
    } else if (
      !shouldSyncAllSetOnly ||
      teeupStatus === teeupStatusKeys.allset ||
      teeupStatus === teeupStatusKeys.happening
    ) {
      // date/time specific when gameplan found
      const whereGameplan = get(
        teeupState,
        ["newWhere", "where"],
        getSelectedGameplan(gameplans, "where")
      )
      let hasAutoSyncedEvent = false
      const newlySynced = []
      if (existingEvents.length > 0) {
        const promises = existingEvents.map(async event => {
          if (event.external) {
            newlySynced.push(event)
            return null
          }
          if (!hasAutoSyncedEvent && event.calendar.id === selectedCalendar) {
            hasAutoSyncedEvent = true
            newlySynced.push(event)
            // update data if necessary
            try {
              await addTeeupEvent({
                teeupTitle,
                isPlanning: teeupHasPlanningMark({
                  teeupStatus,
                  isDesided,
                }),
                startDate,
                endDate,
                whereGameplan,
                existingEvent: event,
                selectedCalendar,
              })
            } catch (error) {
              console.log("addTeeupEvent error:", error)
            }
          } else {
            // it's duplicate in the same calendar
            // or the calendar was changed
            await unscheduleCalendarEvent(event, teeupId)
          }
        })
        existingEvents = await Promise.all(promises)
      }
      if (existingEvents.length === 0 || !hasAutoSyncedEvent) {
        try {
          const eventId = await addTeeupEvent({
            teeupTitle,
            isPlanning: teeupHasPlanningMark({
              teeupStatus,
              isDesided,
            }),
            startDate,
            endDate,
            whereGameplan,
            selectedCalendar,
          })
          if (
            eventId &&
            (existingEvents.length === 0 || !existingEventIds.includes(eventId))
          ) {
            // Only when new event is saved, not an existing update
            newlySynced.push({
              ...teeup,
              teeupId,
              userId,
              eventId,
              id: 1_000_000,
              external: false,
            })
            // What to do upon error? if BE doesn't receive this calendar sync...
            calendarTeeupAdd({
              teeupId,
              userId,
              eventId,
            })
          } else {
            // Probably some error occured OR same event got updated. Or maybe not even updated, just verified to be the same
          }
        } catch (error) {
          console.log("addTeeupEvent error:", error)
        }
      }
      newlySyncedTeeups[teeupId] = {
        ...teeup,
        teeupId,
        userId,
        events: newlySynced,
      }
    }
  }

  if (!isEmpty(newlySyncedTeeups)) {
    // Some new teeups synced to calendar
    calendarAddTeeupEvents(newlySyncedTeeups)
  }
}

export const removeTeeupEvent = async eventId => {
  // return RNCalendarEvents.removeEvent(eventId)
  //     .then((id) => {
  //         return id
  //     })
  //     .catch((error) => {
  //         console.log('error removeTeeupEvent', error)
  //         return null
  //     })
}

const teeupHasPlanningMark = ({ teeupStatus, isDesided }) =>
  teeupStatus === teeupStatusKeys.planning && !isDesided

export const addTeeupEvent = async ({
  teeupTitle,
  isPlanning,
  startDate,
  endDate,
  whereGameplan,
  existingEvent,
  selectedCalendar,
}) => {
  const noDuration = !endDate || isEqualDates(startDate, endDate)
  const start = formatCalendarEventDate(startDate)
  const end = formatCalendarEventDate(
    getEndDate({
      startDate,
      endDate,
      overrideDuration: noDuration,
      defaultDurationMins: DEFAULT_SYNCED_DURATION_MINS,
    })
  )

  const location =
    whereGameplan && whereGameplan.value ? whereGameplan.value : ""
  const title = isPlanning ? `[Planning] ${teeupTitle}` : teeupTitle

  let eventConfig = {
    title,
    startDate: start,
    endDate: end,
    location,
  }

  if (existingEvent) {
    if (
      existingEvent.title === eventConfig.title &&
      getDateWithTimezone(eventConfig.startDate).isSame(
        getDateWithTimezone(existingEvent.startDate)
      ) &&
      getDateWithTimezone(eventConfig.endDate).isSame(
        getDateWithTimezone(existingEvent.endDate)
      ) &&
      existingEvent.location === eventConfig.location
    ) {
      // Events are equal
      return existingEvent.id
    }

    eventConfig.id = existingEvent.id
  }
  if (selectedCalendar) {
    eventConfig.calendarId = selectedCalendar
  }

  return saveEvent(eventConfig)
}

// Returns whether there's a conflict with another event
// export const isEventConflict = startDate => { }
// export const isEventConflict = () => {}

// export const saveEvent = (title, startDate, endDate, location, existingId) => {
export const saveEvent = ({
  calendarId,
  title,
  startDate,
  endDate,
  location,
  id,
}) => {
  // /** The start date of the calendar event in ISO format */
  // startDate: ISODateString;
  // /** The end date of the calendar event in ISO format. */
  // endDate: ISODateString;
  // /** Unique id for the calendar where the event will be saved. Defaults to the device's default  calendar. */
  // calendarId?: string;
  // /** Indicates whether the event is an all-day event. */
  // allDay?: boolean;
  // /** The simple recurrence frequency of the calendar event. */
  // recurrence?: RecurrenceFrequency;
  // /** The location associated with the calendar event. */
  // location?: string;
  // /** iOS ONLY - Indicates whether an event is a detached instance of a repeating event. */
  // isDetached?: boolean;
  // /** iOS ONLY - The url associated with the calendar event. */
  // url?: string;
  // /** iOS ONLY - The notes associated with the calendar event. */
  // notes?: string;
  // /** ANDROID ONLY - The description associated with the calendar event. */
  // description?: string;
  // id?: string;
  //   /** The event's recurrence settings */
  //   recurrenceRule?: RecurrenceRule;
  //   /** The alarms associated with the calendar event, as an array of alarm objects. */
  //   alarms?: Array<Alarm<ISODateString | number>>;

  let details = {
    startDate,
    endDate,
    location,
  }

  if (id) {
    details.id = id
  }
  if (calendarId) {
    details.calendarId = calendarId
  }

  // return RNCalendarEvents.saveEvent(title, details, options)
  //     .then((id) => {
  //         return id
  //     })
  //     .catch((error) => {
  //         console.log('RNCalendarEvents.saveEvent error: ', error)
  //         return null
  //     })
}

export const formatSingleCalendarEvent = event => {
  try {
    const formattedEvent = {
      ...event,
      name: event.summary ?? event.subject,
      description: event.description ? event.description : event.body ?? null,
    }
    // if event start has only date then it's all day (for google cal.)
    if (
      (event.start?.date && event?.calendarType === CALENDAR_TYPES.google) ||
      (event.calendarType === CALENDAR_TYPES.outlook && event.isAllDay)
    ) {
      const dayjsDate = getDateWithTimezone(
        event.start?.date || event.start?.dateTime
      )
      const eventDay = getDateWithTimezone()
        .year(dayjsDate.year())
        .month(dayjsDate.month())
        .date(dayjsDate.date())

      formattedEvent.startDate = formatCalendarEventDate(
        eventDay.startOf("day")
      )
      formattedEvent.endDate = formatCalendarEventDate(eventDay.endOf("day"))
    } else {
      formattedEvent.startDate = getDateWithTimezone(
        event.start?.dateTime
      ).format()
      formattedEvent.endDate = getDateWithTimezone(event.end?.dateTime).format()
    }

    return formattedEvent
  } catch (error) {
    console.log("formatSingleCalendarEvent error:", error)
    return null
  }
}

export const formatFetchedEvents = events => {
  try {
    return events.map(event => formatSingleCalendarEvent(event))
  } catch (error) {
    console.log("formatFetchedEvents error:", error)
    return []
  }
}

const MAX_EVENTS = 2500 // max number allowed by the api

export const fetchCalendarEventsData = async (calendarId, sync, limits) => {
  try {
    let params = {
      showDeleted: false,
      singleEvents: true,
      maxResults: MAX_EVENTS,
      orderBy: "startTime",
    }
    if (limits) {
      params = { ...params, ...limits }
    } else {
      params.timeMin = sync
        ? moment().format()
        : moment().startOf("month").subtract(3, "months").format()
    }

    return await customCalInstance({
      url: `${
        endpoints.googleApi
      }${endpoints.googleCalendar.getInsertEventsList(calendarId)}`,
      params,
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`,
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    console.error(error)
  }
}

// TODO: it's a very bad way with this flags - we will fix it when calendars WS will be ready
let isGoogleCalendarRead = false
let isOutlookCalendarRead = false

const displayNewCalendars = (calendarType, calendars) => {
  const state = store.getState()
  const calendarSyncSettings = selectCalendarSyncSettings(state)
  const newCalendars = calendars.reduce((acc, current) => {
    acc.push({ id: current.id, provider: calendarType })

    return acc
  }, [])

  if (newCalendars.length > 0) {
    postUserSettings({
      calendarSync: {
        ...calendarSyncSettings,
        selectedCalendars:
          calendarSyncSettings.selectedCalendars.concat(newCalendars),
      },
    }).then()
  }
}

export const undisplayRemovedCalendars = calendarType => {
  const state = store.getState()
  const calendarSyncSettings = selectCalendarSyncSettings(state)

  return postUserSettings({
    calendarSync: {
      ...calendarSyncSettings,
      selectedCalendars: calendarSyncSettings.selectedCalendars.filter(
        calendar => calendar.provider !== calendarType
      ),
    },
  })
}

const getGoogleCalendarsList = async (
  calendarEmails,
  calendarDisplayObj,
  isLinking = false
) => {
  gapi.client?.calendar?.events?.list({
    calendarId: "primary", // Change to specific calendar ID if needed
  })
  customCalInstance({
    url: `${endpoints.googleApi}${endpoints.googleCalendar.getCalendarsList()}`,
    headers: {
      Authorization: `Bearer ${gapi.client.getToken().access_token}`,
      "Content-Type": "application/json",
    },
  })
    .then(({ data }) => {
      if (isLinking) {
        displayNewCalendars(CALENDAR_TYPES.google, data.items)
      }
      if (calendarEmails.length > 0) {
        const emailsExist = data.items.every(item =>
          calendarEmails.find(email => email.calendarEmailId === item.id)
        )
        if (emailsExist) return
      }

      const primaryEmail = data.items.find(email => email.primary)
      if (!isGoogleCalendarRead) {
        logCalendarReading({
          calendarName: loginProvidersText.google,
          ownerEmail: primaryEmail.summary,
        })
        isGoogleCalendarRead = true
      }

      store.dispatch(
        setCalendarEmailData({
          data: data.items,
          calendarType: CALENDAR_TYPES.google,
          relatedTo: primaryEmail.id,
        })
      )

      data.items.forEach(item => {
        calendarDisplayObj[item.id] = {
          selected: false,
          type: CALENDAR_TYPES.google,
          relatedTo: primaryEmail.id,
        }
      })
      store.dispatch(setDisplayedData(calendarDisplayObj))
    })
    .catch(error => console.log("Google calendar API error", error))
}

// Workaround for fetching past events from Google Calendar
const listAllEvents = async (displayCalendar, calendarEmails) => {
  return Promise.allSettled(
    calendarEmails.map(async emailItem => {
      if (
        displayCalendar[emailItem.calendarEmailId]?.selected &&
        emailItem.calendarType === CALENDAR_TYPES.google &&
        ApiCalendar.sign
      ) {
        return fetchCalendarEventsData(emailItem.calendarEmailId)
          .then(res => {
            if (!res) return
            const data = res?.data
            if (data?.items?.length > 0) {
              return data.items.map(event => ({
                ...event,
                calendarId: emailItem.calendarEmailId,
                calendarType: CALENDAR_TYPES.google,
              }))
            }

            return null
          })
          .catch(error => {
            console.log("List events calendar error", error)
            return {}
          })
      } else if (
        displayCalendar[emailItem.calendarEmailId]?.selected &&
        emailItem.calendarType === CALENDAR_TYPES.outlook
      ) {
        return fetchMSCalendarEventsData({
          calendarId: emailItem.calendarEmailId,
          accEmail: emailItem?.accEmail,
        })
          .then(res => {
            if (res.value.length > 0) {
              return res.value.map(event => ({
                ...event,
                calendarId: emailItem.calendarEmailId,
                calendarType: CALENDAR_TYPES.outlook,
              }))
            }
            return {}
          })
          .catch(error => {
            return {}
          })
      }
      return null
    })
  )
    .then(data => data.filter(item => item !== null))
    .then(data => data.flat())
    .catch(error =>
      console.log("Error while getting events data from calendar", error)
    )
}

// DESCRIPTION: Fetches events from calendars and dispatches them to the store
export const fetchCalendarEvents = async ({
  newOutlookCalendar = null,
  isGoogleLinking = false,
  isOutlookLinking = false,
}) => {
  const state = store.getState()
  const calendarEmails = selectCalendarEmails(state)
  const displayCalendar = selectDisplayCalendar(state)
  const calendarsList = store.getState().user?.userInfo?.calendars
  const { outlookCalendar, googleCalendar } =
    getExternalCalendars(calendarsList)
  if (googleCalendar) {
    gapi.client.setToken({
      access_token: googleCalendar.accessToken,
    })
    ApiCalendar.updateSigninStatus(true)
  }

  if (googleCalendar || ApiCalendar.sign) {
    await getGoogleCalendarsList(
      calendarEmails,
      displayCalendar,
      isGoogleLinking
    )
  }

  if (outlookCalendar || newOutlookCalendar) {
    await getMSCalendarsList(
      outlookCalendar || newOutlookCalendar,
      calendarEmails,
      displayCalendar,
      isOutlookLinking
    )
  }

  const isDisplayedCalendars = Object.values(displayCalendar)
    .map(calendar => calendar.selected)
    .some(Boolean)

  if (!isDisplayedCalendars) {
    store.dispatch({
      type: actionTypes.GOT_CALENDAR,
      payload: {
        events: [],
      },
    })

    return isDisplayedCalendars
  }

  return new Promise((resolve, reject) => {
    listAllEvents(displayCalendar, calendarEmails)
      .then(res => {
        res.forEach(item => {
          const events = item?.value

          if (isEmpty(events)) return
          const filteredEvents = formatFetchedEvents(events)

          store.dispatch({
            type: actionTypes.GOT_CALENDAR,
            payload: {
              events: filteredEvents,
            },
          })
        })
        resolve("success")
      })
      .catch(error => {
        console.error("fetchCalendarEvents error", error)
        reject("failed")
      })
  })
}

const fetchSyncCalendarEvents = async calendarId => {
  const { calendars } = store.getState().user.userInfo

  const outlookCalendar = calendars?.find(c => c.provider === "windowslive")
  const googleCalendar = calendars?.find(c => c.provider === "google-oauth2")

  if (ApiCalendar.sign || googleCalendar) {
    return fetchCalendarEventsData(calendarId, true)
      .then(({ data }) => {
        return formatFetchedEvents(data.items)
      })
      .catch(error => {
        console.log(error)
        console.log("Error syncing calendar data")
      })
  }

  if (outlookCalendar) {
    return fetchMSCalendarEventsData({ calendarId, sync: true })
      .then(({ value }) => formatFetchedEvents(value))
      .catch(() => console.log("Error syncing calendar data"))
  }
}

// sync logic is not done yet
export const syncCalendar = async () => {
  if (store.getState().calendar.isCalendarSynchronized) return

  const fetchAllTeeups = await fetchTeeups()

  await fetchAllTeeups(store.dispatch, store.getState)

  await fetchCalendarEvents({})

  const state = store.getState()
  const syncEmail = selectSyncEmail(state)

  const onlyAllSet = selectShouldSyncOnlyAllSet(state)
  const timeZone = getCurrentTimezone()

  const fetchedEvents = await fetchSyncCalendarEvents(
    syncEmail?.calendarEmailId
  )

  const allTeeups = [
    ...state.teeups.teeups,
    ...state.teeups.archivedTeeups,
    ...state.teeups.removedTeeups,
  ]

  allTeeups.forEach(async teeup => {
    let associatedEventWithTeeup

    const decidedSuggestion = getDecidedWhen(teeup)

    if (teeup?.events?.length > 0) {
      associatedEventWithTeeup = fetchedEvents?.find(calendarEvent => {
        return teeup.events.some(
          teeupEvent => teeupEvent.eventId === calendarEvent?.id
        )
      })
    }

    if (teeup?.events?.length > 1) {
      teeup.events.forEach(async (event, i) => {
        if (i === 0) return

        await deleteEvent(syncEmail, teeup, event.eventId)
      })
    }

    if (associatedEventWithTeeup && !isTeeupDecided(teeup)) {
      await deleteEvent(syncEmail, teeup, associatedEventWithTeeup?.id)
    }

    if (
      decidedSuggestion &&
      getDateWithTimezone(decidedSuggestion.value).isAfter(new Date())
    ) {
      let { value, endDate } = decidedSuggestion
      if (!endDate) endDate = addHours(value, 1)
      // if event start is greater than latest fetched event start
      // if (dateComparator(value, fetchedEvents?.at(-1)?.startDate) !== -1)
      //   return store.dispatch(setCalendarSyncInProgress(false))
      const isTeeupPlanning = teeup.status === teeupStatusKeys.planning
      const openTeeupString = `Open teeup: ${window.origin}/teeUps/${teeup.id}`
      const description = `<br>_____________________________<br><span>${openTeeupString}</span>`

      const event =
        syncEmail.calendarType === CALENDAR_TYPES.google
          ? {
              start: { dateTime: value, timeZone },
              end: { dateTime: endDate, timeZone },
              summary: teeup.name,
              description,
            }
          : {
              start: { dateTime: value, timeZone },
              end: { dateTime: endDate, timeZone },
              subject: teeup.name,
              body: {
                contentType: "html",
                content: description,
              },
            }

      if (teeup.events.length === 0 && !associatedEventWithTeeup) {
        await createEvent(syncEmail, event, teeup)
      }

      if (onlyAllSet && associatedEventWithTeeup && isTeeupPlanning) {
        await deleteEvent(syncEmail, teeup, associatedEventWithTeeup.id)
      }

      if (
        (syncEmail.calendarType === CALENDAR_TYPES.google &&
          ((associatedEventWithTeeup &&
            associatedEventWithTeeup.name !== event.summary) ||
            associatedEventWithTeeup?.description !== event.description)) ||
        (syncEmail.calendarType === CALENDAR_TYPES.outlook &&
          ((associatedEventWithTeeup &&
            associatedEventWithTeeup.name !== event.subject) ||
            !associatedEventWithTeeup?.description.content.includes(
              `${openTeeupString}`
            )))
      ) {
        await updateEvent(syncEmail, event, associatedEventWithTeeup?.id)
      }
    }
  })

  store.dispatch(setIsCalendarSynchronized(true))
}

const updateEvent = async (
  { calendarType, summary, calendarEmailId },
  event,
  eventId
) => {
  if (calendarType === CALENDAR_TYPES.google) {
    return customCalInstance({
      url: `${endpoints.googleApi}${endpoints.googleCalendar.updateDeleteEvent(
        calendarEmailId,
        eventId
      )}`,
      method: "put",
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`,
        "Content-Type": "application/json",
      },
      data: event,
    }).then(({ data }) =>
      store.dispatch(
        createCalendarEvent({
          ...data,
          calendarId: calendarEmailId,
          calendarType,
        })
      )
    )
  } else {
    const { calendars } = store.getState().user.userInfo

    const outlookCalendar = calendars.find(c => c.provider === "windowslive")

    const { accessToken, username } = outlookCalendar

    return msGraphRequest({
      account: { username, accessToken },
      path: endpoints.msCalendar.updateDeleteCalendarEvent(
        summary,
        calendarEmailId,
        eventId
      ),
      method: "patch",
      body: event,
    }).then(res =>
      store.dispatch(
        createCalendarEvent({
          ...res,
          calendarId: calendarEmailId,
          calendarType,
        })
      )
    )
  }
}

const createEvent = async (
  { calendarType, calendarEmailId, summary },
  event,
  teeup
) => {
  console.log(calendarType)

  const state = store.getState()
  const userId = selectUserId(state)
  if (calendarType === CALENDAR_TYPES.google)
    return customCalInstance({
      url: `${
        endpoints.googleApi
      }${endpoints.googleCalendar.getInsertEventsList(calendarEmailId)}`,
      method: "post",
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`,
        "Content-Type": "application/json",
      },
      data: event,
    })
      .then(async ({ data }) => {
        const { id } = data
        const url = endpoints.calendarEvent(teeup.id)

        return api.client
          .post(url, { eventId: id, external: false, userId })
          .catch(error => {
            console.log("createCalendarEvent error", error)
          })
          .then(() =>
            store.dispatch(
              createCalendarEvent({
                ...data,
                calendarId: calendarEmailId,
                calendarType,
              })
            )
          )
      })
      .catch(error => {
        console.log("ApiCalendar createGoogleEvent", error)
      })
  else {
    const { calendars } = store.getState().user.userInfo

    const outlookCalendar = calendars.find(c => c.provider === "windowslive")

    const { accessToken, username } = outlookCalendar

    return msGraphRequest({
      account: { username, accessToken },
      path: endpoints.msCalendar.createCalendarEvent(summary, calendarEmailId),
      method: "post",
      body: event,
    })
      .then(async res => {
        const { id } = res
        const url = endpoints.calendarEvent(teeup.id)

        return api.client
          .post(url, { eventId: id, external: false, userId })
          .catch(error => {
            console.log("createCalendarEvent error", error)
          })
          .then(() =>
            store.dispatch(
              createCalendarEvent({
                ...res,
                calendarId: calendarEmailId,
                calendarType,
              })
            )
          )
      })
      .catch(error => console.log("CreateEvent MS error", error))
  }
}

const deleteEvent = async (
  { calendarType, summary, calendarEmailId },
  teeup,
  eventId
) => {
  const deleteEventFromBackendCallback = async () => {
    const url = endpoints.calendarEvent(teeup.id)

    const deleteEventId = teeup.events.find(event => event.eventId === eventId)

    return api.client
      .delete(url + `/${deleteEventId.id}`)
      .catch(error => {
        console.log("deleteCalendarEvent error", error)
      })
      .then(() => store.dispatch(deleteCalendarEvent(eventId)))
  }

  if (calendarType === CALENDAR_TYPES.google)
    return customCalInstance({
      url: `${endpoints.googleApi}${endpoints.googleCalendar.updateDeleteEvent(
        calendarEmailId,
        eventId
      )}`,
      method: "delete",
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(deleteEventFromBackendCallback)
      .catch(error => console.log("deleteEventGoogle error", error))
  else {
    const { calendars } = store.getState().user.userInfo

    const outlookCalendar = calendars.find(c => c.provider === "windowslive")

    const { accessToken, username } = outlookCalendar

    return msGraphRequest({
      account: { accessToken, username },
      path: endpoints.msCalendar.updateDeleteCalendarEvent(
        summary,
        calendarEmailId,
        eventId
      ),
      method: "delete",
    })
      .then(deleteEventFromBackendCallback)
      .catch(error => console.log("deleteEvent error", error))
  }
}

export const changeSyncCalendar = async calendarId => {
  const state = store.getState()
  const calendarSyncSettings = selectUserSettings(state)
  let additionalCalendars = selectAdditionalCalendars(state)
  const primaryCalIds = selectPrimaryCalendarIds(state)
  // the ones that exist in primary cals and in additional
  const primarysInAdditional = primaryCalIds.filter(el =>
    additionalCalendars.includes(el)
  )
  if (!calendarId) {
    for (const calId of primarysInAdditional) {
      await unlinkCalendarEvents(calId)
      additionalCalendars = additionalCalendars.filter(
        additionalId => additionalId !== calId
      )
    }

    return await postUserSettings({
      calendarSync: {
        ...calendarSyncSettings.calendarSync,
        calendar: calendarId,
        additionalCalendars,
      },
    })
  }
  const calendarsToUnlink = primaryCalIds.filter(id => id !== calendarId)
  for (const calId of calendarsToUnlink) {
    await unlinkCalendarEvents(calId)
    additionalCalendars = additionalCalendars.filter(
      additionalId => additionalId !== calId
    )
  }

  const exists = additionalCalendars.find(
    additionalCal => additionalCal === calendarId
  )

  if (!exists && calendarId) additionalCalendars.push(calendarId)

  await postUserSettings({
    calendarSync: {
      ...calendarSyncSettings.calendarSync,
      calendar: calendarId,
      additionalCalendars,
    },
  })
  // call only if sync mail has been changed
  // TODO: remove it later (Calendar removing)
  // store.dispatch(setIsCalendarSynchronized(false))
  // syncCalendar()
}
// after unlinking, delete calendar from additionalcalendars
export const unlinkCalendarEvents = async calendarId => {
  const state = store.getState()
  const calendar = selectCalendarById(calendarId)(state)
  if (!calendar) return

  const allTeeups = selectAllTeeups(state)
  const teeupGameplans = selectTeeupsGameplans(state)

  const fetchedEvents = await fetchSyncCalendarEvents(calendar)
  if (!fetchedEvents) return

  return Promise.all(
    allTeeups.map(async teeup => {
      const gameplans = get(teeupGameplans, teeup.id, [])
      const selectedWhen = getSelectedGameplan(gameplans, "when")

      if (
        selectedWhen &&
        getDateWithTimezone(selectedWhen.startDate).isAfter(new Date())
      ) {
        const existingEvent =
          teeup?.events &&
          fetchedEvents.find(calendarEvent => {
            return teeup.events.some(
              teeupEvent => teeupEvent.eventId === calendarEvent.id
            )
          })
        if (existingEvent) return deleteEvent(calendar, teeup, existingEvent.id)
      }
    })
  )
}

const isMSAuth = instance => instance.getAllAccounts().length > 0

const msGraphRequest = async ({ account, path, method, body }) =>
  customCalInstance({
    method: method ?? "get",
    url: `${endpoints.MSGraphApi}${path}`,
    data: body ?? null,
    headers: {
      Authorization: `Bearer ${account.accessToken}`,
      "Content-Type": "application/json",
    },
  }).then(res => res.data)

export const getGuestOutlookCalendars = (accessToken, uid) => {
  return msGraphRequest({
    account: { accessToken },
    path: endpoints.msCalendar.getCalendarsList(uid),
  }).then(res => res)
}

const getMSCalendarsList = async (
  outlookCalendar,
  calendarEmails,
  calendarDisplayObj,
  isLinking = false
) => {
  msGraphRequest({
    account: outlookCalendar,
    path: endpoints.msCalendar.getCalendarsList(outlookCalendar.uid),
  })
    .then(({ value }) => {
      if (isLinking) {
        displayNewCalendars(CALENDAR_TYPES.outlook, value)
      }
      if (calendarEmails.length > 0) {
        const emailsExist = value.every(item =>
          calendarEmails.find(email => email.calendarEmailId === item.id)
        )
        if (emailsExist) return
      }

      const primaryEmail = value.find(email => email.isDefaultCalendar)
      if (!isOutlookCalendarRead) {
        logCalendarReading({
          calendarName: loginProvidersText.outlook,
          ownerEmail: primaryEmail.owner.address,
        })
        isOutlookCalendarRead = true
      }

      store.dispatch(
        setCalendarEmailData({
          data: value,
          calendarType: CALENDAR_TYPES.outlook,
          relatedTo: primaryEmail.id,
          accountId: outlookCalendar.uid,
        })
      )
      value.forEach(item => {
        calendarDisplayObj[item.id] = {
          selected: false,
          type: CALENDAR_TYPES.outlook,
          relatedTo: primaryEmail.id,
          accountId: outlookCalendar.uid,
        }
      })
      store.dispatch(setDisplayedData(calendarDisplayObj))
    })
    .catch(error => console.log("MSCalendarList fetch error"))
}

export const fetchGuestOutlookCalendarEvents = ({
  accessToken,
  accEmail,
  calendarId,
  startDate,
  endDate,
}) => {
  return msGraphRequest({
    account: { accessToken },
    path: endpoints.msCalendar.getCalendarEvents(
      accEmail,
      calendarId,
      startDate,
      endDate
    ),
  })
}

export const fetchMSCalendarEventsData = async ({
  calendarId,
  sync = false,
  accEmail = "",
}) => {
  const { calendars } = store.getState().user.userInfo

  const outlookCalendar = calendars.find(
    c => c.provider === loginProviders.windowslive
  )

  const { accessToken } = outlookCalendar

  return msGraphRequest({
    account: { accessToken },
    path: endpoints.msCalendar.getCalendarEvents(
      accEmail,
      calendarId,
      sync ? moment().toISOString() : moment().startOf("month").toISOString(),
      moment().endOf("month").toISOString()
    ),
  })
    .then(res => res)
    .catch(error => console.log(error))
}

export const resetOutlookCalendar = () => {
  const state = store.getState()
  const outlookPrimary = selectPrimaryOutlookCalendar(state)
  if (!outlookPrimary) return
  store.dispatch(
    resetSingleCalendar({
      calendarId: outlookPrimary.calendarEmailId,
      calendarType: CALENDAR_TYPES.outlook,
    })
  )
}
