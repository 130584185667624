import React from "react"

import PropTypes from "prop-types"

import { SvgIcon } from "@components/common"

import { ClearWrapper } from "../inputElStyles"

export const InputClear = ({ onClick }) => (
  <ClearWrapper>
    <SvgIcon name="cross-2" color="var(--gray4)" onClick={onClick} />
  </ClearWrapper>
)

InputClear.propTypes = {
  onClick: PropTypes.func,
}
