import TabListMui from "@mui/lab/TabList"
import TabMui from "@mui/material/Tab"

export const TabList: typeof TabListMui = props => (
  <TabListMui
    sx={{
      "& .MuiTabs-indicator": {
        backgroundColor: "#000",
        color: "#000",
      },
    }}
    {...props}
  />
)

export const Tab: typeof TabMui = props => (
  <TabMui
    sx={{
      textTransform: "none",
      fontSize: "13px",
      fontWeight: "500",
      "&.Mui-selected": {
        color: "#000",
      },
    }}
    disableRipple
    {...props}
  />
)
