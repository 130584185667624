import React from "react"

import Linkify from "linkify-react"
import _ from "lodash"
import truncate from "lodash/truncate"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { IconContext } from "react-icons"
import {
  PanResponder,
  View,
  Text,
  TouchableOpacity,
  Image,
  Animated,
} from "react-native"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { bindActionCreators } from "redux"

import { removeSuggestionNewStatus } from "@actions/teeupActions"
import strings from "@i18n"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import {
  selectNewSuggestions,
  selectRemovedSuggestions,
} from "@selectors/localData"
import { selectTeeupPeople } from "@selectors/teeups"
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import { hasConflictingEvents } from '@selectors/calendar'
// import { selectSuggestionsWithDetails } from '@selectors/localData'
import { AppColors, Units } from "@theme/"
import { capitalizeFirstLetter } from "@utils/dataUtils"
// import { openMap } from '@utils/linkUtils'
import { getCurrentTimezone, getTimezoneAbbreviation } from "@utils/dateUtils"
import {
  getSuggestionValue,
  getGameplanIcon,
  GAMEPLAN_OPTIONS,
  locationTypeIds,
  isPastSuggestion,
} from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"

import threeDotsIcon from "../../assets/images/three-dots.svg"

import {
  GameplansRowStyles,
  draggableContainerInner,
  messageTypesStyles,
  rowReorderImage,
} from "./gameplansStyles"
import ReactionButton from "./reactionButton"

import "./svgStyles.scss"
import SuggestionPopup from "./SuggestionPopup"

import { selectBeforeCalendarDate } from "@selectors/calendar"

import { ChatSuggestionLabel } from "./ChatSuggestionLabel"
import {
  ChatSuggestionValueWrapper,
  ChatSuggestionValue,
  WhereTitleWrapper,
  AppIcon,
  MapLocationIcon,
} from "./newDraggableSuggestionRowStyles"

import { apps } from "constants/apps"
import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { SuggestionItem } from "components"
import { MAX_NAME_LENGHT } from "contexts/TeeupPreviewContext/constants"

// Util functions used to measure component overflowness
function measureHeightAsync(component) {
  return new Promise(resolve => {
    component.measure((x, y, w, h) => {
      resolve(h)
    })
  })
}

function nextFrameAsync() {
  return new Promise(resolve => requestAnimationFrame(() => resolve()))
}

const onSuggestionChat = props => {
  // showSuggestionChat({ suggestionParams: props })
}

const TypeIcon = ({
  type,
  selected,
  decided,
  isNew,
  isTeeupCancelled,
  isMyMessage,
  inactive,
}) => {
  let iconColor = AppColors.brand.warmGrey2
  if (isNew) {
    iconColor = AppColors.brand.pink
  } else if (isTeeupCancelled) {
    iconColor = AppColors.brand.warmGrey2
  } else if (selected) {
    // iconColor = AppColors.brand.blue2
    iconColor = "rgb(157,157,157)"
    if (decided) {
      // iconColor = AppColors.brand.green
      iconColor = "#767676"
    }
  }

  if (isMyMessage && !inactive) {
    // iconColor = AppColors.brand.darkBlue
    iconColor = "rgb(116,169,198)"
  }

  return (
    <IconContext.Provider value={{ color: iconColor }}>
      {getGameplanIcon({ type })}
    </IconContext.Provider>
  )
}

TypeIcon.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool,
  decided: PropTypes.bool,
  isNew: PropTypes.bool,
  isTeeupCancelled: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  inactive: PropTypes.bool,
}

export class DraggableSuggestionRowContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this._rippleOpacity = new Animated.Value(props.isDragged ? 1 : 0)
    this._rippleScale = new Animated.Value(1)
    this._draggingOverlayOpacity = new Animated.Value(1)
    this._rowOpacity = new Animated.Value(1)
    this._rowScale = new Animated.Value(1)
    this._rippleAnimation = null
    this.markSeen(props)
    this.markRemovedSeen(props)

    this._panResponder = PanResponder.create({
      onShouldBlockNativeResponder: () => true,
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        const { dx, dy } = gestureState
        return dx > 2 || dx < -2 || dy > 2 || dy < -2
      },
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => {
        const { dx, dy } = gestureState
        return dx > 2 || dx < -2 || dy > 2 || dy < -2
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderGrant: props.onTouchStart,
      onPanResponderMove: props.onTouchMove,
      onPanResponderEnd: props.onTouchEnd,
    })
    this.state = {
      shouldShowDetails: false,
      measured: true,
      threeDotsSuggestions: false,
      displayNew: true,
    }
    this.messageRef = React.createRef()
    this.decidedRef = React.createRef()
  }

  async componentDidMount() {
    const { shouldMeasureOverflow } = this.props
    this._isMounted = true

    if (shouldMeasureOverflow) {
      await this.measureOverflow()
    }
  }

  measureOverflow = async () => {
    this.setState({ measured: false })
    await nextFrameAsync()

    if (!this._isMounted) {
      return
    }

    // Get the height of the text with no restriction on number of lines
    const fullHeight = await measureHeightAsync(this._text)
    this.setState({ measured: true })
    await nextFrameAsync()

    if (!this._isMounted) {
      return
    }

    // Get the height of the text now that number of lines has been set
    const limitedHeight = await measureHeightAsync(this._text)

    if (fullHeight > limitedHeight) {
      this.setState({ shouldShowDetails: true })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setTextRef = text => {
    this._text = text
  }

  componentDidUpdate(prevProps, prevState) {
    this.markSeen(this.props)
    this.markRemovedSeen(this.props)

    if (prevProps.isDragging && !this.props.isDragging) {
      this._draggingOverlayOpacity.setValue(1)
    }
  }

  showRipple(show = true) {
    if (this._rippleAnimation) {
      this._rippleAnimation.stop()
    }
    if (show) {
      this._rippleOpacity.setValue(0)
      this._rippleScale.setValue(0)
      this._rippleAnimation = Animated.parallel([
        Animated.timing(this._rippleOpacity, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(this._rippleScale, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
      ])
      this._rippleAnimation.start()
    } else {
      this._rippleAnimation = Animated.timing(this._rippleOpacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      })
      this._rippleAnimation.start()
    }
  }

  setOnDropPoint() {
    this._rowOpacity.setValue(1)
  }

  animateSelectSuggestion() {
    Animated.timing(this._draggingOverlayOpacity, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }

  animateDismiss() {
    Animated.parallel([
      Animated.timing(this._rowOpacity, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }),
      Animated.timing(this._rowScale, {
        toValue: 0,
        duration: 150,
        useNativeDriver: true,
      }),
    ]).start()
  }

  isPast = () => isPastSuggestion(this.props.suggestion, "day")

  isWithdrawn = () => {
    const { suggestion } = this.props
    return suggestion ? suggestion.isWithdrawn : false
  }

  markSeen = props => {}

  markRemovedSeen = props => {}

  showDayCalendar = () => {}

  renderStatusIndicator() {
    const {
      suggestion: { decided, selected },
      suggestion,
      isTeeupCancelled,
      newSuggestions,
      removedSuggestions,
      activeTeeup,
      removeSuggestionNewStatus,
    } = this.props

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const inactive = isPast || isWithdrawn

    let isNew
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true && newSuggestions[key]?.isNew) {
        setTimeout(() => {
          this.setState({ displayNew: false })
          removeSuggestionNewStatus(suggestion.id, activeTeeup.id)
        }, 15_000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    if (inactive) {
      return <></>
    } else if (selected) {
      if (decided) {
        return (
          <View
            style={[
              GameplansRowStyles.markContainer,
              !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
            ]}>
            <Text style={GameplansRowStyles.selectedMarkGameplan}>
              {strings.gameplan}
            </Text>
          </View>
        )
      }
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.selectedMarkGameplan}>
            {strings.gameplan}
          </Text>
        </View>
      )
    } else if (isRemoved) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.isRemovedMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.removedMark}>Removed</Text>
        </View>
      )
    } else if (isNew && this.state.displayNew) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.isNewMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.newMarkGameplan}>
            {/* {selected ? 'New Game Plan' : 'New'} */}
            New
          </Text>
        </View>
      )
    } else if (
      (selected && isNew && this.state.displayNew) ||
      (isNew && this.state.displayNew)
    ) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.newMarkGameplan}>New</Text>
        </View>
      )
    } else {
      return <></>
    }
  }

  renderStatusIndicatorForList() {
    const {
      suggestion: { decided, selected },
      suggestion,
      isTeeupCancelled,
      newSuggestions,
      removedSuggestions,
      activeTeeup,
      removeSuggestionNewStatus,
    } = this.props

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const inactive = isPast || isWithdrawn

    let isNew = false
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true && newSuggestions[key]?.isNew) {
        setTimeout(() => {
          this.setState({ displayNew: false })
          removeSuggestionNewStatus(suggestion.id, activeTeeup.id)
        }, 15_000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    if (inactive) {
      return <></>
    } else if (selected) {
      if (decided) {
        return (
          <View
            style={[
              GameplansRowStyles.markContainer,
              !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
            ]}>
            <Text style={GameplansRowStyles.selectedMarkGameplan}>
              {strings.gameplan}
            </Text>
          </View>
        )
      }
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.selectedMarkGameplan}>
            {strings.gameplan}
          </Text>
        </View>
      )
    } else if (isRemoved) {
      return (
        <Text
          style={[
            GameplansRowStyles.removedMark,
            GameplansRowStyles.newMarkGameplanList,
          ]}>
          Removed
        </Text>
      )
    } else if (isNew && this.state.displayNew) {
      return (
        <Text
          style={[
            GameplansRowStyles.newMarkGameplan,
            GameplansRowStyles.newMarkGameplanList,
          ]}>
          New
        </Text>
      )
    } else if (
      (selected && isNew && this.state.displayNew) ||
      (isNew && this.state.displayNew)
    ) {
      return (
        <View
          style={[
            GameplansRowStyles.markContainer,
            !isTeeupCancelled && GameplansRowStyles.selectedMarkContainer,
          ]}>
          <Text style={GameplansRowStyles.newMarkGameplan}>New</Text>
        </View>
      )
    } else {
      return <></>
    }
  }

  renderSuggestionLines() {
    const {
      showHints,
      isTimeZoneEnabled,
      suggestion,
      isMyMessage,
      hasConflict,
      currentUserTimezone,
      fromSuggestionChat,
      suggestionsWithDetails,
      createSuggestion,
      fromConversation,
      fromInitialGameplans,
      isFromSuggestionListHeader,
      fromSuggestionPopup,
      newDesign,
    } = this.props
    const { measured } = this.state

    const {
      id,
      locationType,
      isCustomDate,
      isCustomTime,
      isFreestyleValues = false,
    } = suggestion
    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()

    const isShowSuggestionDetails = suggestionsWithDetails.find(
      suggestion => suggestion === id
    )

    const type = this.props.type ? this.props.type : suggestion.type
    const suggestionValues = getSuggestionValue(suggestion, type)
    const {
      startTimeFormatted = "",
      endTimeFormatted = "",
      isOnlyStartTime = false,
    } = suggestionValues
    const isSuggestionWithoutTime =
      !startTimeFormatted && !endTimeFormatted && !isTimeZoneEnabled

    const inactive = isPast || isWithdrawn

    const suggestionTextSyle = [
      GameplansRowStyles.suggestionText,
      !inactive &&
        (isMyMessage
          ? GameplansRowStyles.suggestionTextActiveMy
          : GameplansRowStyles.suggestionTextActive),
      newDesign && {
        fontSize: "15px",
        fontWeight: 600,
        letterSpacing: "-0.35px",
        color: "#212121",
      },
    ]

    const isOnline = locationType === locationTypeIds?.online

    const isCollapseSuggestionDetails =
      suggestion &&
      (type === GAMEPLAN_OPTIONS.when ||
        (type === GAMEPLAN_OPTIONS.where && isOnline)) &&
      isShowSuggestionDetails &&
      fromSuggestionChat

    const linesRestrictForLine1 = {
      numberOfLines: isCollapseSuggestionDetails ? 2 : undefined,
    }
    let linesRestrictForLine2 = {
      numberOfLines: createSuggestion ? 4 : 2,
    }
    const linkOptions = {
      className: "message-link",
    }

    let line1 = null
    if (suggestionValues.line1) {
      let appIcon
      let isMapIcon = false

      if (suggestion.type === GAMEPLAN_TYPES.where) {
        appIcon = apps.find(app => app.title === suggestion.value)?.image
        isMapIcon = !appIcon
      }
      line1 = (
        <WhereTitleWrapper>
          {appIcon && <AppIcon src={appIcon} alt={suggestion.value} />}
          {isMapIcon && <MapLocationIcon name="google-location" />}

          <Linkify options={linkOptions}>
            <ChatSuggestionValue $isBold>
              {truncate(suggestionValues.line1, {
                length: 35,
              })}
            </ChatSuggestionValue>
          </Linkify>
        </WhereTitleWrapper>
      )
    } else if (showHints) {
      line1 = (
        <Text
          style={GameplansRowStyles.suggestionPlaceholder}
          numberOfLines={1}>
          {suggestionValues.placeholder1}
        </Text>
      )
    } else if (!suggestionValues.line2) {
      line1 = (
        <Text
          style={GameplansRowStyles.suggestionPlaceholder}
          numberOfLines={1}>
          {suggestionValues.placeholder}
        </Text>
      )
    }

    if (isCollapseSuggestionDetails) {
      linesRestrictForLine2 = {
        numberOfLines: 2,
      }
    }

    let line2 = suggestionValues.line2 ? (
      <ChatSuggestionValueWrapper
        $isThreeDots
        $isDecided={suggestion.selected}
        $isWithdrawn={suggestion.isWithdrawn}>
        <Linkify options={linkOptions}>
          <ChatSuggestionValue>{suggestionValues.line2}</ChatSuggestionValue>
        </Linkify>
      </ChatSuggestionValueWrapper>
    ) : showHints ? (
      <Text style={GameplansRowStyles.suggestionPlaceholder} numberOfLines={1}>
        {suggestionValues.placeholder2}
      </Text>
    ) : null

    if (suggestion && type === GAMEPLAN_OPTIONS.when) {
      // Add timezone abbr to exact time
      let timezoneView
      if (
        !suggestion.isCustomTime &&
        !suggestion.isCustomDate &&
        !isFreestyleValues &&
        (!isSuggestionWithoutTime || isOnlyStartTime)
      ) {
        const timezoneName = currentUserTimezone || getCurrentTimezone()
        let timezoneAbbr
        if (timezoneName) timezoneAbbr = getTimezoneAbbreviation(timezoneName)

        timezoneView = timezoneAbbr && (
          <Text
            style={[suggestionTextSyle, GameplansRowStyles.timezoneText]}
            numberOfLines={1}>
            {timezoneAbbr}
          </Text>
        )
      }

      // Add globe or conflict icon
      let multizoneView
      if (hasConflict) {
        multizoneView = (
          <TouchableOpacity
            onPress={this.showDayCalendar}
            style={GameplansRowStyles.multizoneIcon}
            hitSlop={Units.hitSlop()}>
            <Image source={images.calendarAlert} />
          </TouchableOpacity>
        )
      } else if (
        (suggestion.isTimeZoneEnabled || isTimeZoneEnabled) &&
        !suggestion.isCustomTime &&
        !suggestion.isCustomDate &&
        !isFreestyleValues
      ) {
        multizoneView = (
          <View style={GameplansRowStyles.multizoneIcon}>
            <Image
              source={images.globe}
              style={[
                GameplansRowStyles.iconGray,
                GameplansRowStyles.iconSmall,
              ]}
            />
          </View>
        )
      }

      let inActiveIcon = isPast
        ? images.clockIcon
        : isWithdrawn
        ? images.delete
        : null

      let inActiveView = inActiveIcon && (
        <View style={GameplansRowStyles.inActiveIcon}>
          <Image
            source={inActiveIcon}
            style={[
              GameplansRowStyles.iconInactive,
              GameplansRowStyles.iconSmall,
            ]}
            resizeMode="contain"
          />
        </View>
      )

      line2 =
        line2 && startTimeFormatted && endTimeFormatted ? (
          <ChatSuggestionValueWrapper
            $isThreeDots
            $isDecided={suggestion.selected}
            $isWithdrawn={suggestion.isWithdrawn}>
            <ChatSuggestionValue>
              {`${startTimeFormatted} - ${endTimeFormatted}`}
            </ChatSuggestionValue>
          </ChatSuggestionValueWrapper>
        ) : (
          line2
        )

      let baseView = line2 || line1
      baseView = (
        <View style={GameplansRowStyles.multizoneIconContainer}>
          {baseView}
          {startTimeFormatted && endTimeFormatted ? null : (
            <>
              {timezoneView}
              {multizoneView}
            </>
          )}
        </View>
      )

      if (line2) {
        line2 = baseView
      } else {
        line1 = baseView
      }
    }

    return (
      <>
        {fromSuggestionPopup ? (
          <div style={{ width: "230px", flexWrap: "wrap" }}>
            {line1}
            {line2}
          </div>
        ) : (
          <View
            style={{
              maxWidth: 290,
              flexDirection: "column",
              columnGap: 5,
              paddingRight: "4px",
            }}>
            {line1}
            {line2}
          </View>
        )}
      </>
    )
  }

  handleOpenDirection = () => {
    // const { suggestedPlace } = this.props
    // if (!suggestedPlace) {
    //     return
    // }
    // const { location, details } = suggestedPlace
    // if (!location) {
    //     return
    // }
    // const { lat, lng } = location
    // openMap(`@${lat},${lng}`, details)
  }

  handleSuggestionPress = () => {
    const { onPress, goToSuggestion, suggestion } = this.props
    return onPress
      ? onPress()
      : goToSuggestion
      ? goToSuggestion(suggestion)
      : onSuggestionChat(this.props)
  }

  render() {
    const {
      name,
      reactions,
      onPress,
      customStyle,
      customContentStyle,
      createSuggestion,
      suggestedItem,
      customReactionId,
      disableReaction,
      handleOnLongPress,
      suggestion,
      recommendation,
      previewMode,
      draggable,
      testID,
      isTeeupCancelled,
      suggestionIndex,
      isDragging,
      isDragged,
      isMyMessage,
      // isNew,
      // isRemoved,
      messageType,
      forChat,
      fullContainer,
      contentRef,
      fromSuggestionChat,
      renderLockIcon,
      showShadow,
      renderPlaceholder,
      suggestedPlace,
      newSuggestions,
      removedSuggestions,
      suggestionFromList,
      isFromSuggestionListHeader,
      people,
      activeTeeup,
      fromSuggestionPopup,
      fromGamePlanSuggestion,
      newDesign,
      isTemporalCustom,
    } = this.props

    let sections = []

    // reactions.map(({ reactionId, reactedBy }) => {
    //   if (reactedBy) {
    //     let reaction = reactionId
    //     let data = reactedBy.map((userId) => {
    //       return { name: people[userId].name, avatar: people[userId].avatar }
    //     })
    //     if (!data.length) {
    //       reaction = ""
    //     }
    //     sections.push({ reaction, data })
    //   }
    // })

    const isPast = this.isPast()
    const isWithdrawn = this.isWithdrawn()
    const { id, selected, decided } = suggestion
    const type = this.props.type ? this.props.type : suggestion.type
    const isDraggable = draggable && !forChat && !isPast && !isWithdrawn
    const showActionSheet =
      !isTeeupCancelled && !fromSuggestionChat && !previewMode
    const noSuggestion = _.isEmpty(suggestion)
    const disableLinkingToSuggestionChat =
      (noSuggestion || suggestedItem) && !onPress

    let isNew = false
    const isNewArr = Object.keys(newSuggestions).map((key, index) => {
      if (key.includes(suggestion.id) === true && newSuggestions[key]?.isNew) {
        setTimeout(() => {
          this.setState({ displayNew: false })
          removeSuggestionNewStatus(suggestion.id, activeTeeup.id)
        }, 15_000)
        isNew = true
      } else {
        isNew = false
      }
    })

    let isRemoved
    const isRemovedArr = Object.keys(removedSuggestions).map(function (
      key,
      index
    ) {
      key.includes(suggestion.id) ? (isRemoved = true) : (isRemoved = false)
    })
    const bordered = selected || decided || isRemoved

    const inactive = isPast || isWithdrawn
    const isSuggestionPressDisabled =
      isTeeupCancelled || disableLinkingToSuggestionChat || isDragging
    const isShowReactionButtons =
      ((!createSuggestion && !noSuggestion) || previewMode) && !isWithdrawn

    return (
      <>
        {fromSuggestionPopup ? (
          <>
            <TouchableOpacity
              activeOpacity={1}
              disabled={isSuggestionPressDisabled}
              onPress={this.props.toggleSuggestionPopup}
              // onPress={this.handleSuggestionPress}
            >
              {this.renderSuggestionLines()}
            </TouchableOpacity>

            <ReactionButton
              suggestionId={id}
              isMyMessage={isMyMessage}
              // reactions={reactions}
              previewMode={previewMode}
              customReactionId={customReactionId}
              disableReaction={disableReaction}
              inactive={inactive}
              sections={sections}
            />
          </>
        ) : (
          <TouchableOpacity
            testID={testID}
            activeOpacity={1}
            disabled={isSuggestionPressDisabled}
            onPress={this.handleSuggestionPress}
            ref={isDraggable && contentRef}
            onMouseOver={() => {
              !isFromSuggestionListHeader &&
                this.setState({ ...this.state, threeDotsSuggestions: true })
            }}
            onMouseLeave={() => {
              // if(!shouldShowMessageAction) {
              //     return;
              // }
              ReactTooltip.hide(this.messageRef.current)
              this.setState({ ...this.state, threeDotsSuggestions: false })
            }}>
            {this.state.threeDotsSuggestions &&
              (suggestionFromList || isFromSuggestionListHeader) && (
                <img
                  src={threeDotsIcon}
                  ref={this.messageRef}
                  data-tip={id}
                  data-for="message-tooltip"
                  className="three-dots-list-suggestions"
                  onClick={() => ReactTooltip.show(this.messageRef.current)}
                  alt="suggestion"
                />
              )}
            <Animated.View
              style={[
                suggestionIndex === 0 || forChat
                  ? GameplansRowStyles.background
                  : GameplansRowStyles.backgroundWithPaddingTop,
                {
                  opacity: this._rowOpacity,
                  transform: [
                    {
                      scale: this._rowScale,
                    },
                  ],
                },
                fromSuggestionChat && { paddingTop: 0 },
              ]}>
              <div className="box-shadow-hover">
                <TouchableOpacity
                  onPress={this.props.toggleSuggestionPopup}
                  style={[
                    { paddingRight: "4px" },
                    GameplansRowStyles.draggableContainer,

                    (forChat || fromGamePlanSuggestion) && {
                      marginHorizontal: 0,
                    },
                    !forChat &&
                      !fromGamePlanSuggestion &&
                      !isDragging &&
                      showShadow &&
                      GameplansRowStyles.draggableContainerShadow,
                    forChat &&
                      (isMyMessage
                        ? GameplansRowStyles.forChatContainerMy
                        : GameplansRowStyles.forChatContainer),
                    forChat &&
                      newDesign && {
                        borderColor: isMyMessage ? "transparent" : "#e3e5e8",
                      },
                    fullContainer && GameplansRowStyles.fullContainer,
                    customStyle,
                    inactive && GameplansRowStyles.draggableContainerGrey,
                    !fromGamePlanSuggestion &&
                      selected && {
                        borderColor: newDesign
                          ? isMyMessage
                            ? "transparent"
                            : "#e3e5e8"
                          : "rgb(0,188,246)",
                        borderWidth: "1pt",
                        ...(newDesign && {
                          backgroundColor: isMyMessage ? "#e0e8ee" : "white",
                        }),
                      },
                    newDesign &&
                      !selected && {
                        backgroundColor: isMyMessage ? "#e0e8ee" : "white",
                      },
                    isTemporalCustom && { maxWidth: "345px" },
                    fromGamePlanSuggestion && {
                      borderWidth: "1pt",
                      borderStyle: "solid",
                      borderColor: "#e3e5e8",
                      boxShadow: "0 1px 0 0 #e3e5e8",
                    },
                    {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      border: "none",
                    },
                  ]}>
                  <View
                    style={[
                      forChat &&
                        messageType &&
                        (isMyMessage
                          ? messageTypesStyles[`${messageType}My`]
                          : messageTypesStyles[messageType]),
                      draggableContainerInner({
                        bordered,
                        decided,
                        isDraggable,
                        isTeeupCancelled,
                        isNew,
                        isRemoved,
                        selected,
                        forChat,
                        fullContainer,
                      }),
                      customContentStyle,
                    ]}>
                    {(isDraggable || isWithdrawn) && (
                      <View
                        style={GameplansRowStyles.rowReorder}
                        {...(isDraggable && this._panResponder.panHandlers)}>
                        <Image
                          source={images.reorderDots}
                          style={rowReorderImage(bordered)}
                        />
                      </View>
                    )}
                    {!isDraggable && !forChat && !isWithdrawn && (
                      <View style={GameplansRowStyles.rowReorder} />
                    )}

                    <View
                      style={[
                        GameplansRowStyles.draggableContentContainer,
                        isWithdrawn &&
                          GameplansRowStyles.withdrawnContentContainer,
                      ]}>
                      <ChatSuggestionLabel suggestion={suggestion} />
                      {suggestion.creator?.name && (
                        <span
                          style={{
                            position: "absolute",
                            top: -20,
                            color: "var(--gray4)",
                            fontSize: 13,
                          }}>
                          @
                          {truncate(suggestion.creator?.name, {
                            length: MAX_NAME_LENGHT,
                          })}
                        </span>
                      )}

                      <View style={GameplansRowStyles.contentContainerAlignTop}>
                        {(suggestionFromList || isFromSuggestionListHeader) && (
                          <Image
                            source={images.dots}
                            style={{
                              width: 6,
                              heigth: "100%",
                              marginRight: 10,
                            }}
                          />
                        )}
                        {isFromSuggestionListHeader ||
                          (suggestionFromList &&
                            this.renderStatusIndicatorForList())}
                        <View
                          style={GameplansRowStyles.titleContainer}
                          testID={`${testID}-name`}
                          {...(isDraggable && this._panResponder.panHandlers)}>
                          {(isFromSuggestionListHeader ||
                            suggestionFromList) && (
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "1px",
                              }}>
                              <Text
                                style={[
                                  isMyMessage && !inactive
                                    ? GameplansRowStyles.myUsername
                                    : GameplansRowStyles.username,
                                  isFromSuggestionListHeader &&
                                    suggestionFromList && { marginLeft: "5px" },
                                ]}>
                                @{capitalizeFirstLetter(name)}
                              </Text>
                            </View>
                          )}
                          <TouchableOpacity
                            activeOpacity={1}
                            disabled={isSuggestionPressDisabled}
                            onPress={this.props.toggleSuggestionPopup}
                            // onPress={this.handleSuggestionPress}
                          >
                            {this.renderSuggestionLines()}
                          </TouchableOpacity>
                        </View>
                        {suggestedPlace && suggestedPlace.location && (
                          <TouchableOpacity
                            onPress={this.handleOpenDirection}
                            style={GameplansRowStyles.mapButton}>
                            <Image source={images.directions} />
                          </TouchableOpacity>
                        )}
                      </View>

                      {isShowReactionButtons && (
                        <ReactionButton
                          suggestionId={id}
                          isMyMessage={isMyMessage}
                          // reactions={reactions}
                          previewMode={previewMode}
                          customReactionId={customReactionId}
                          disableReaction={disableReaction}
                          inactive={inactive}
                          sections={sections}
                          newDesign={newDesign}
                        />
                      )}
                    </View>
                    {isDraggable && (
                      <Animated.View
                        pointerEvents="none"
                        style={[
                          GameplansRowStyles.rippleOverlay,
                          {
                            opacity: this._rippleOpacity,
                            transform: [
                              {
                                scaleX: this._rippleScale,
                              },
                            ],
                          },
                        ]}
                      />
                    )}
                  </View>
                  {isDragging && isDraggable && (
                    <Animated.View
                      style={[
                        GameplansRowStyles.draggingOverlay({
                          selected,
                        }),
                        {
                          opacity: this._draggingOverlayOpacity,
                        },
                      ]}
                      pointerEvents="none">
                      {renderPlaceholder && renderPlaceholder()}
                    </Animated.View>
                  )}
                  {isDragged && (
                    <View style={GameplansRowStyles.draggedIndicator}>
                      <View
                        style={[
                          GameplansRowStyles.draggedIndicatorInner,
                          // forChat &&
                          //     messageType &&
                          //     GameplansRowStyles[messageType](
                          //         isMyMessage
                          //     ),
                          fullContainer && GameplansRowStyles.fullContainer,
                        ]}
                      />
                    </View>
                  )}
                </TouchableOpacity>

                {/* {recommendation && (
                        <View
                            style={
                                GameplansRowStyles.suggestionRecommendationsContainer
                            }
                        >
                            <Text
                                style={
                                    GameplansRowStyles.suggestionRecommendationsHeader
                                }
                            >
                                DEAL FOR YOUR TEEUP:
                            </Text>
                            <RecommendedItem item={recommendation} />
                        </View>
                    )} */}
              </div>
            </Animated.View>
          </TouchableOpacity>
        )}
      </>
    )
  }
}

DraggableSuggestionRowContent.defaultProps = {
  suggestion: {},
  initiateStatusUpdate: false,
  previewMode: false,
  draggable: true,
  forChat: false,
  showShadow: true,
  fromSuggestionChat: false,
}

DraggableSuggestionRowContent.propTypes = {
  name: PropTypes.string,
  reactions: PropTypes.array,
  showHints: PropTypes.bool,
  onPress: PropTypes.func,
  renderPlaceholder: PropTypes.func,
  // customStyle: PropTypes.object,
  customStyle: PropTypes.number,
  // customContentStyle: PropTypes.object,
  createSuggestion: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  suggestedItem: PropTypes.bool,
  disableReaction: PropTypes.bool,
  handleOnLongPress: PropTypes.func,
  suggestion: PropTypes.object,
  people: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participants: PropTypes.array,
  type: PropTypes.string,
  customReactionId: PropTypes.number,
  isNew: PropTypes.bool,
  isRemoved: PropTypes.bool,
  isPast: PropTypes.bool,
  isWithdrawn: PropTypes.bool,
  isTimeZoneEnabled: PropTypes.bool,
  recommendation: PropTypes.object,
  decidedGameplanId: PropTypes.number,
  testID: PropTypes.string,
  isTeeupCancelled: PropTypes.bool,
  activeTeeupId: PropTypes.number,
  previewMode: PropTypes.bool,
  draggable: PropTypes.bool,
  isRowExpanded: PropTypes.bool,
  initiateStatusUpdate: PropTypes.bool,
  suggestionIndex: PropTypes.number,
  isDragging: PropTypes.bool,
  isDragged: PropTypes.bool,
  forChat: PropTypes.bool,
  fromSuggestionChat: PropTypes.bool,
  shouldMeasureOverflow: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  messageType: PropTypes.string,
  fullContainer: PropTypes.bool,
  contentRef: PropTypes.func,
  onTouchStart: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchEnd: PropTypes.func,
  hasConflict: PropTypes.bool,
  locationType: PropTypes.number,
  renderLockIcon: PropTypes.func,
  goToSuggestion: PropTypes.func,
  showShadow: PropTypes.bool,
  teeupName: PropTypes.string,
  currentUserTimezone: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  suggestionsWithDetails: PropTypes.array,
  suggestedPlace: PropTypes.object,
  isBeforeCalendarDate: PropTypes.bool,
}

class DraggableSuggestionRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSuggestionPopupOpened: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showPopup !== this.props.showPopup && this.props.showPopup)
      this.toggleSuggestionPopup()
  }
  getOwnProps() {
    const ownProps = { ...this.props }
    delete ownProps.reactions
    delete ownProps.people
    delete ownProps.participants
    delete ownProps.decidedGameplanId
    delete ownProps.activeTeeupId
    delete ownProps.rowRef

    return ownProps
  }

  toggleSuggestionPopup = () => {
    this.setState({
      isSuggestionPopupOpened: !this.state.isSuggestionPopupOpened,
    })
    if (this.state.isSuggestionPopupOpened && this.props.setShowPopup) {
      this.props.setShowPopup(false)
    }
  }

  render() {
    const {
      rowRef,
      onAddSuggestion,
      isExclusive,
      popupOnly, // to open popup without displaying whole suggestion component
      showPopup, // flag on which popup will be displayed
      setShowPopup, // reference to the setState of the showPopup flag. If being used, test if it exists first
      ...otherProps
    } = this.props
    const { isSuggestionPopupOpened } = this.state

    return (
      <>
        {!popupOnly && (
          <div onClick={() => this.toggleSuggestionPopup()}>
            <DraggableSuggestionRowContent
              ref={ref => {
                if (rowRef) {
                  rowRef(ref)
                }
                this._row = ref
              }}
              contentRef={ref => (this._rowContent = ref)}
              {...otherProps}
              toggleSuggestionPopup={this.toggleSuggestionPopup}
            />
          </div>
        )}
        {isSuggestionPopupOpened &&
          ReactDOM.createPortal(
            <SuggestionPopup
              {...otherProps}
              draggableSuggestionRow={
                <SuggestionItem
                  isPopup
                  isCollapsed={false}
                  suggestion={this.props.suggestion}
                  suggestionsLength={0}
                />
              }
              currentSuggestion={otherProps.suggestion}
              currentSuggestionData={otherProps.suggestion}
              toggleSuggestionPopup={this.toggleSuggestionPopup}
              onAddSuggestion={onAddSuggestion}
            />,
            document.querySelector("#modal-root")
          )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const activeTeeup = selectActiveTeeup(state)
  const teeupName = activeTeeup.name
  const decidedGameplan = {}
  const decidedGameplanId = decidedGameplan.id || -1
  const currentUserTimezone = getCurrentTimezone()
  const suggestionsWithDetails = []
  const newSuggestions = selectNewSuggestions(state)
  const removedSuggestions = selectRemovedSuggestions(state)
  let hasConflict = null

  const people = selectTeeupPeople(state)
  const isBeforeCalendarDate = selectBeforeCalendarDate(state)

  return {
    // reactions,
    decidedGameplanId,
    activeTeeup,
    teeupName,
    hasConflict,
    currentUserTimezone,
    suggestionsWithDetails,
    newSuggestions,
    removedSuggestions,
    people,
    isBeforeCalendarDate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeSuggestionNewStatus: bindActionCreators(
      removeSuggestionNewStatus,
      dispatch
    ),
  }
}

DraggableSuggestionRow.defaultProps = {
  isActionSheetActive: false,
}

DraggableSuggestionRow.propTypes = {
  rowRef: PropTypes.func,
  currentUserTimezone: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object,
  ]),
  suggestionsWithDetails: PropTypes.array,
  isActionSheetActive: PropTypes.bool,
  removedSuggestions: PropTypes.array,
  isExclusive: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraggableSuggestionRow)
