import React from "react"

import { List, ListItem, ImageWrapper, Image, Text } from "./pointsListStyles"

const points = [
  "Get notified about attendance and plan status",
  "Users can chat about the plan",
]

export const PointsList = () => (
  <List>
    {points.map((point, pointIndex) => (
      <ListItem key={pointIndex}>
        <ImageWrapper>
          <Image name="new-checkmark" />
        </ImageWrapper>

        <Text>{point}</Text>
      </ListItem>
    ))}
  </List>
)
