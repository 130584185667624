export const useLinkingError = () => {
  const catchLinkingErrorMessage = error => {
    const { status, data } = error

    if (status === 409) {
      return data.message
    }

    return "Something went wrong. Try again later."
  }

  return { catchLinkingErrorMessage }
}
