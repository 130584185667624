import React from "react"

import PropTypes from "prop-types"

import NonUser from "@assets/images/non-user.svg"

import { Wrapper, Avatar } from "./creatorAvatarStyles"

export const CreatorAvatar = ({ isCollapsed, creatorAvatar }) => (
  <Wrapper $isCollapsed={isCollapsed}>
    <Avatar src={creatorAvatar || NonUser} alt="creator-avatar" />
  </Wrapper>
)

CreatorAvatar.propTypes = {
  isCollapsed: PropTypes.bool,
  creatorAvatar: PropTypes.string,
}
