import React, { useState, useEffect, useRef } from "react"

import { connect } from "react-redux"
import { ReactSVG } from "react-svg"
import { bindActionCreators } from "redux"
import "./index.scss"

import { setIsCalendarSynchronized } from "@actions/calendarActions"
import { setTitle, setActiveInviteesTab } from "@actions/commonActions"
import { inviteToTeeup } from "@middlewares/backendMiddleware"
import { selectActiveInviteesTab } from "@selectors/common"
import { getDraftTeeup } from "@selectors/createTeeUp"
import { selectDraftInvitees } from "@selectors/invitees"
import { formatInvitees } from "@utils/contactUtils"
import { getBase64, getRandomBase64Image, images } from "@utils/imageUtils"

import { closeCreateTeeup, createTeeup } from "../../actions/createTeeupActions"
import camera from "../../assets/images/camera.svg"
import chevron from "../../assets/images/chevron.svg"
import clock from "../../assets/images/clock.svg"
import location from "../../assets/images/location.svg"
import tick from "../../assets/images/tick.svg"
import CooEVerse from "../../components/CooEVerse"
import ViewByTeeups from "../../components/ViewByTeeups"
import { titles } from "../../config/enums"
import { selectActiveTeeup } from "../../selectors/activeTeeup"
import { selectTeeupGameplans } from "../../selectors/teeups"
import { selectUserId } from "../../selectors/user"
import { GAMEPLAN_OPTIONS } from "../../utils/gamePlanUtils"
import AddInvitees from "../AddInvitees"

import TabInvitees from "./addInviteesTabs"
import GameplanContainer from "./gameplanContainer"
import GameplanRow from "./gameplanRow"

const ItemSeparatorComponent = () => (
  <div style={{ height: 1, backgroundColor: "#E3E5E8" }} />
) //backgroundColor: '#E4E4E4'
const renderButton = (text, icon) => {
  const suggestions = "no suggestions yet"

  return (
    <div className="plan">
      <div className="plan__wrapper">
        <img src={tick} alt="tick" />
        <div className="plan__content">
          <span className="plan__title">
            <img src={icon} alt="suggestion" /> {text} &middot; {suggestions}
          </span>
          <span className="create__suggest">Suggest {text}</span>
        </div>
      </div>
      <ReactSVG src={chevron} />
    </div>
  )
}

const CreateTeeUp = ({
  close,
  userId,
  createTeeUp,
  draftInvitees,
  setTitle,
  activeTeeUp,
  draftTeeup,
  activeInviteesTab,
  setActiveInviteesTab,
  setIsCalendarSynchronized,
}) => {
  const [image, setImage] = useState(null)
  const [invitees, setInvitees] = useState(draftInvitees)
  const [input, setInput] = useState({ name: "", message: "" })
  const [content, setContent] = useState(null)
  const [modalContent, setModalContent] = useState(null)
  const [animation, setAnimation] = useState("")
  const [isOptionOpened, setIsOptionOpened] = useState(false)
  const [isGameplanOpened, setIsGameplanOpened] = useState(false)
  const [gameplanData, setGameplanData] = useState([
    {
      icon: clock,
      isPrimary: true,
      suggestions: [],
      title: "When",
      type: "when",
    },
    {
      icon: location,
      isPrimary: true,
      suggestions: [],
      title: "Where",
      type: "where",
    },
  ])
  const [isLoading, setIsLoading] = useState(false)

  const [initialGameplans, setInitialGameplans] = useState([])

  useEffect(() => {
    if (draftTeeup) {
      const { name, message, whenSuggestions, whereSuggestions } = draftTeeup

      if (name && message) setInput({ name, message })
      setGameplanData([
        {
          icon: clock,
          isPrimary: true,
          suggestions: whenSuggestions,
          title: "When",
          type: "when",
        },
        {
          icon: location,
          isPrimary: true,
          suggestions: whereSuggestions,
          title: "Where",
          type: "where",
        },
      ])
    }
  }, [])

  const optionRef = useRef()
  const isValidated = invitees.length > 0 && input.name.length > 0
  const openOption = () => setIsOptionOpened(true)
  const closeOption = () => setIsOptionOpened(false)

  useEffect(() => {
    setTitle(titles.createTitle)

    return () => {
      setTitle(activeTeeUp.name)
    }
  }, [])

  useEffect(() => {
    if (activeInviteesTab === 1) {
      setContent(inviteesContent)
    } else if (activeInviteesTab === 2) {
      setContent(inviteesContent)
    } else {
      setContent(inviteesContent)
    }
  }, [activeInviteesTab])

  const closeWithAnimations = () => {
    document.body.style.overflow = "auto"
    setAnimation("right-slide")
    setTimeout(() => {
      close()
      closeOption()
    }, 250)
  }

  const closeOptionWithAnimations = () => {
    optionRef.current.style.animationName = "right-slide"

    setTimeout(() => {
      closeOption()
    }, 250)
  }

  const onGameplanRowClick = (gameplan, index) => {
    if (
      gameplan.type === GAMEPLAN_OPTIONS.startsWhen ||
      gameplan.type === GAMEPLAN_OPTIONS.whenWorks
    ) {
      const props = {
        initialGameplan: gameplan,
        callback: ({ startDate, peopleCount, dates }) => {
          let updatedGameplans = this.props.teeup.gameplans.map(
            (singleGameplan, singleGameplanIndex) => {
              if (index === singleGameplanIndex) {
                return {
                  ...singleGameplan,
                  startDate,
                  peopleCount: Number.parseInt(peopleCount),
                  dates,
                  suggestions: dates,
                  availability: [],
                }
              }
              return singleGameplan
            }
          )
          this.props.updateGameplanOptions({
            gameplans: updatedGameplans,
          })
        },
      }
    } else {
      openOption()
      setIsGameplanOpened(true)
      setContent(gameplanContent(gameplan, index))
    }
  }

  const renderGameplanDefaults = () => {
    return (
      <>
        {gameplanData.map((gameplan, index, gpData) => (
          <React.Fragment key={index}>
            <GameplanRow
              gameplan={gameplan}
              onGameplanRowClick={onGameplanRowClick}
              onDecidePress={handleDecidePress(gameplan)}
              index={index}
            />
            {index !== gpData.length - 1 && <ItemSeparatorComponent />}
          </React.Fragment>
        ))}
      </>
    )
  }

  const handleDecidePress = newGameplan => () => {
    const {
      updateGameplanOptions,
      teeup: { gameplans },
    } = this.props
    const selectedSuggestion = newGameplan?.suggestions[0]
    if (!selectedSuggestion) return null

    const updatedGameplans = gameplans.map(gameplan => {
      if (newGameplan.type === gameplan.type) {
        const updatedSuggestions = gameplan.suggestions.map(suggestion => ({
          ...suggestion,
          decided: suggestion.selected && !suggestion.decided,
        }))
        return {
          ...gameplan,
          suggestions: updatedSuggestions,
        }
      } else return gameplan
    })

    updateGameplanOptions({
      gameplans: updatedGameplans,
    })
  }

  const gameplanContent = (gameplan, index) => {
    return (
      <>
        <GameplanContainer
          fromCreate={true}
          closeCreate={() => {
            closeOption()
            setIsGameplanOpened(false)
          }}
          suggestions={gameplan["suggestions"]}
          index={index}
          gameplanData={gameplanData}
          setGameplanData={setGameplanData}
          {...gameplan}
          allowPutOnGameplan={true}
          isFromCreateTeeup={true}
          initialGameplans={initialGameplans}
          setInitialGameplans={setInitialGameplans}
        />
      </>
    )
  }

  const inviteesContent = () => {
    return (
      <>
        <div
          style={{ paddingLeft: 12, paddingRight: 8 }}
          className="create__top next-fullHeight">
          <span className="invitees__title">Invite to TeeUp</span>
          <img
            src={images.cross}
            onClick={closeOptionWithAnimations}
            style={{ height: 25, width: 25, cursor: "pointer" }}
            alt="close"
          />
        </div>
        <div className="tabInvitees_wrapper">
          <TabInvitees
            index={1}
            title={"Contacts"}
            action={setActiveInviteesTab}
          />
          <TabInvitees
            index={2}
            title={"CooWe-verse"}
            action={setActiveInviteesTab}
          />
          <TabInvitees
            index={3}
            title={"Teeups"}
            action={setActiveInviteesTab}
          />
        </div>
        {activeInviteesTab === 1 && (
          <AddInvitees
            setInvitees={setInvitees}
            invitees={invitees}
            setModalContent={setModalContent}
            fromCreateTeeup={true}
            closeOptionWithAnimations={closeOptionWithAnimations}
            inviteToNewTeeup
          />
        )}
        {activeInviteesTab === 2 && (
          <CooEVerse
            setInvitees={setInvitees}
            invitees={invitees}
            setModalContent={setModalContent}
            fromCreateTeeup={true}
            closeOptionWithAnimations={closeOptionWithAnimations}
            inviteToNewTeeup
          />
        )}
        {activeInviteesTab === 3 && (
          <ViewByTeeups
            setInviteesFromCreateTeeup={setInvitees}
            fromCreateTeeup={true}
            noHover
            inviteToNewTeeup
            closeOptionWithAnimations={closeOptionWithAnimations}
          />
        )}
        {/* <div className="create__bottom">
          <div className="create__button" onClick={closeOptionWithAnimations}>Cancel</div>
          <div className="create__button" onClick={closeOptionWithAnimations}>Done</div>
        </div> */}
      </>
    )
  }

  const handleInput = async ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
  }

  const create = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    const otherData = {}

    const emails = []
    const phones = []
    const manualInvitees = invitees.filter(
      invitee => invitee.userType === "manual"
    )
    manualInvitees.forEach(element => {
      if (element.contactType === "phone") phones.push(element.value)
      if (element.contactType === "email") emails.push(element.value)
    })
    const dataForManualInvitees = { phones, emails }
    const formattedInvites = formatInvitees(invitees)
    otherData.photo = image || (await getRandomBase64Image("randomSmaller"))

    await createTeeUp(
      userId,
      input.name.trim(),
      input.message.trim() || " ",
      invitees,
      gameplanData,
      dataForManualInvitees,
      otherData
    )

    // TODO: remove it later (Calendar removing)
    // setIsCalendarSynchronized(false)
    // syncCalendar()
    // Timeout to wait until socket connection is established
    await new Promise(resolve =>
      setTimeout(() => resolve(inviteToTeeup(formattedInvites)), 5000)
    )
    close()
  }

  return (
    <div className="settings create" onClick={closeWithAnimations}>
      {isOptionOpened && (
        <div
          ref={optionRef}
          className="create__modal--window settings__modal settings__modal-content"
          style={{
            animationName: animation,
            overflow: "initial",
            width: isGameplanOpened ? "380px" : "610px",
            height: "90vh",
            padding: 0,
            backgroundColor: "#ffffff",
          }}
          onClick={event => event.stopPropagation()}>
          {content}
        </div>
      )}
      {modalContent && (
        <div
          className="addinvitees__overlay"
          onClick={() => setModalContent(null)}>
          <div
            className="addinvitees__modal"
            onClick={event => event.stopPropagation()}>
            {modalContent}
          </div>
        </div>
      )}

      {!isOptionOpened && (
        <div
          style={{ animationName: animation, width: "872px" }}
          className="create__modal--window settings__modal"
          onClick={event => event.stopPropagation()}>
          <div
            style={{
              display: "flex",
              height: "calc(100% - 32px)",
              justifyContent: "space-between",
              flexDirection: "column",
            }}>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                  <div className="create__input--wrapper">
                    {image ? (
                      <div
                        className="create__image"
                        style={{ backgroundImage: `url(${image})` }}>
                        <img src={camera} alt="camera" />
                        <input
                          className="upload-input"
                          type="file"
                          onChange={e => {
                            const file = e.target.files[0]
                            getBase64(file).then(base64 => {
                              setImage(base64)
                            })
                          }}
                        />
                      </div>
                    ) : (
                      <div className="create__image">
                        <img src={camera} alt="camera" />
                        <input
                          className="upload-input"
                          type="file"
                          onChange={e => {
                            const file = e.target.files[0]
                            getBase64(file).then(base64 => {
                              setImage(base64)
                            })
                          }}
                        />
                      </div>
                    )}
                    <input
                      className="create__input"
                      type="text"
                      autoComplete="off"
                      name="name"
                      placeholder="TeeUp Name"
                      style={{ paddingRight: image ? 80 : 50 }}
                      onChange={handleInput}
                      value={input.name}
                    />
                  </div>
                  <div className="people__invite create__block">
                    <div className="people__invite--header">
                      <div style={{ display: "flex" }}>
                        <div
                          className="settings__title"
                          style={{ border: "none", width: "fit-content" }}>
                          People
                        </div>
                        <div
                          className="create__align-items"
                          style={{ marginLeft: 6, paddingTop: 2 }}>
                          <img src={images.user} alt="user" />
                          <span className="people__invite--count">
                            {invitees.length > 0 && invitees.length}
                          </span>
                        </div>
                      </div>
                      {/* <div onClick={() => {openOption(); setContent(inviteesContent)}}>
                      <img src={images.invite} alt=""/>
                    </div> */}
                    </div>
                    <div
                      className="create__align-items"
                      style={{
                        flexDirection: invitees.length > 0 && "column",
                      }}>
                      <div className="create__invited">
                        {invitees.map(
                          ({ avatar, firstName, name, userType, label }) => (
                            <div
                              className="create__user"
                              key={avatar + firstName}>
                              {avatar ? (
                                <div
                                  className="create__user-avatar"
                                  style={{
                                    backgroundImage: `url(${avatar})`,
                                  }}></div>
                              ) : (
                                <div className="create__user-avatar create__user-manual">
                                  {name?.[0] || label?.[0] || firstName?.[0]}
                                </div>
                              )}
                              {/* <span className="create__user-name">{firstName ? firstName : userType === 'manual' ? label : name}</span> */}
                            </div>
                          )
                        )}
                      </div>
                      <div
                        onClick={() => {
                          openOption()
                          setContent(inviteesContent)
                        }}
                        className={`people__invite--button ${
                          invitees.length > 0 && "people__invite--overlap"
                        }`}>
                        <div className="create__align-items">
                          <img
                            src={images.pinkInvite}
                            alt="invite"
                            style={{ marginRight: "6px" }}
                          />
                          <span>Add Invitees</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="create__gameplan--wrapper create__block">
                    <div className="settings__title">
                      <span>Game Plan</span>
                    </div>
                    {renderGameplanDefaults()}
                  </div>
                  {/* <div className="block block__settings create__block">
                  <ReactSVG src={images.settings} />
                  <span>TeeUp Settings</span>
                </div> */}
                </div>
                <div className="create__invitation create__block">
                  <div className="create__invitation--title">
                    Invitation message
                  </div>
                  <textarea
                    className="create__invitation--message"
                    type="text"
                    name="message"
                    placeholder="Optional message to describe the TeeUp a bit more for your invitees"
                    value={input.message}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>
            <div
              className="create__bottom"
              style={{
                position: "relative",
                marginTop: 5,
                marginBottom: 15,
                zIndex: 1,
              }}>
              <button
                type="button"
                className="create__button"
                onClick={closeWithAnimations}>
                Cancel
              </button>
              <button
                type="button"
                className="create__button"
                onClick={create}
                disabled={!isValidated || isLoading}
                style={{ opacity: isValidated || isLoading ? 1 : 0.5 }}>
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  close: bindActionCreators(closeCreateTeeup, dispatch),
  createTeeUp: bindActionCreators(createTeeup, dispatch),
  setTitle: bindActionCreators(setTitle, dispatch),
  setActiveInviteesTab: bindActionCreators(setActiveInviteesTab, dispatch),
  setIsCalendarSynchronized: bindActionCreators(
    setIsCalendarSynchronized,
    dispatch
  ),
})

const mapStateToProps = state => ({
  userId: selectUserId(state),
  draftGameplans: selectTeeupGameplans(state),
  draftInvitees: selectDraftInvitees(state),
  activeTeeUp: selectActiveTeeup(state),
  draftTeeup: getDraftTeeup(state),
  activeInviteesTab: selectActiveInviteesTab(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeeUp)
