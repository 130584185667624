import { useEffect } from "react"

import { useSelector } from "react-redux"

import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { Suggestion, SuggestionStatus } from "types/suggestions"
import dayjs from "utils/dayjs"

import "./index.scss"

interface Props {
  suggestion: Suggestion
  status: SuggestionStatus
  setStatus: (status: SuggestionStatus) => void
}

const statuses = {
  error: {
    className: "suggestion-item__header__status__error",
  },
  success: {
    className: "suggestion-item__header__status__success",
  },
  default: {
    className: "suggestion-item__header__status",
  },
}

const Status = ({ suggestion, status, setStatus }: Props) => {
  const linkedCalendar = useSelector(selectGuestLinkedCalendar)
  const isDecided = suggestion?.isDecided || suggestion?.decided
  const isWithdrawn = suggestion?.isWithdrawn
  const isPast =
    dayjs().isAfter(dayjs(suggestion.startDate)) && !isWithdrawn && !isDecided
  const isCalendarConflict =
    !!suggestion?.isCalendarConflict && !isDecided && !isWithdrawn && !isPast

  const getStatusLabel = () => {
    if (isPast) {
      setStatus({
        label: "Past",
        className: statuses.default.className,
      })
    } else if (isWithdrawn) {
      setStatus({
        label: "Withdrawn",
        className: statuses.default.className,
      })
    } else if (isDecided) {
      setStatus({
        label: "Decided",
        className: statuses.success.className,
      })
    } else if (isCalendarConflict && !!linkedCalendar) {
      setStatus({
        label: "Calendar conflict",
        className: statuses.error.className,
      })
    } else if (!isCalendarConflict && linkedCalendar) {
      setStatus({
        label: "Available",
        className: statuses.success.className,
      })
    }
  }

  useEffect(() => {
    getStatusLabel()
  }, [isPast, isWithdrawn, isDecided, isCalendarConflict, linkedCalendar])

  return (
    <>
      <div className={status.className}>{status.label}</div>
    </>
  )
}

export default Status
