import _ from "lodash"

import { timeFromNow } from "@utils/dateUtils"
import {
  getGameplanValue,
  getSuggestionValue,
  GAMEPLAN_OPTIONS,
} from "@utils/gamePlanUtils"
import { getGoingCount, formatUserStatusText } from "@utils/teeupUtils"

import { teeupUserStatusKeys, teeupStatusKeys } from "../../../config/enums"
import { teeupStatusTypes } from "../../../config/mappings"

// import {
//     selectAdditionalPeopleByTeeupId,
//     selectAdditionalGoingPeopleByTeeupId,
// } from '@selectors/teeup'

const propsMapper = (state, props) => {
  let {
    teeup = {},
    participants,
    people,
    gameplans,
    ignoreHorizontalMargin,
    fromTeeupsSection,
    onPress,
    whenFromInterest,
    whereFromInterest,
    invitation,
    onRespondToInvitation,
    onArchivePressed,
    onKeepInTheListPress,
    currentUserId,
  } = props

  const {
    seen,
    newStatus,
    newMessages,
    newTitle,
    newWhen,
    newWhere,
    updatedAt,
    updatedat,
    statusChangedAt,
    isArchived,
    isArchivePromptShow: showArchivePrompt,
    status,
    userStatus,
  } = teeup

  let nudgeType
  let nudgeIssuer

  Object.keys(state.teeupsState.teeups).map(function (key, index) {
    if (key == teeup.id) {
      nudgeType = state.teeupsState.teeups[key].nudgeType
      nudgeIssuer = state.teeupsState.teeups[key].nudgeIssuer
    }
  })

  let nudgeInfo = null
  if (nudgeType) {
    nudgeInfo = { nudgeType }
    if (nudgeIssuer) {
      // nudgeInfo.nudgeIssuer = _.find(people, (p) => p.id === nudgeIssuer, {})
      nudgeInfo.nudgeIssuer = nudgeIssuer
    }
  }

  const statusID = teeup?.teeupStatus?.id || null
  const hasUpdate = seen === false

  const newWhenValue = newWhen
    ? getSuggestionValue(newWhen, GAMEPLAN_OPTIONS.when).full
    : null

  const newWhereValue = newWhere
    ? getSuggestionValue(newWhere, GAMEPLAN_OPTIONS.where).full
    : null

  const ownerId = teeup.createdby || teeup.createdBy
  let additionalPeopleInTeeup = 0
  let additionalGoingPeopleInTeeup = 0
  // if (teeup.id) {
  //     additionalPeopleInTeeup = selectAdditionalPeopleByTeeupId(teeup.id)(
  //         state
  //     )
  //     additionalGoingPeopleInTeeup = selectAdditionalGoingPeopleByTeeupId(
  //         teeup.id
  //     )(state)
  // }
  if (!participants) {
    participants = []
  }

  let when = whenFromInterest
  let where = whereFromInterest

  let teeupStatus =
    typeof newStatus === "number"
      ? teeupStatusTypes[newStatus]
      : statusID
      ? teeupStatusTypes[statusID]
      : status

  const isOrganizer = currentUserId === ownerId

  const userStatusValue = formatUserStatusText(userStatus)

  let isUndecidedWhenInGameplan = false

  if (gameplans && gameplans.length > 0 && gameplans.length > 0) {
    gameplans.forEach(gameplan => {
      const { suggestions, title } = gameplan

      if (title === GAMEPLAN_OPTIONS.where) {
        let value = getGameplanValue(suggestions, GAMEPLAN_OPTIONS.where)
        if (value && value.full) {
          where = value.full
        }
      }

      if (title === GAMEPLAN_OPTIONS.when) {
        let value = getGameplanValue(suggestions, GAMEPLAN_OPTIONS.when)
        const undecidedInGameplan = suggestions.find(
          s => s.selected && !s.decided && isOrganizer
        )
        if (
          undecidedInGameplan &&
          teeupStatus !== teeupStatusKeys.ended &&
          !isArchived
        )
          isUndecidedWhenInGameplan = true

        if (value && value.full) {
          when = value.full
        }
      }
    })
  }

  const owner = _.get(people, ownerId, {})

  const participantCount = participants.length + additionalPeopleInTeeup
  const goingCount = getGoingCount(participants, people)

  const fromNow = timeFromNow(updatedAt || updatedat)
  const fromNowString = fromNow === "1d" ? "Yesterday" : `${fromNow} ago`

  let isArchivePromptShow = false
  if (
    !isArchived &&
    teeupStatus === teeupStatusKeys.ended &&
    showArchivePrompt
    // && getDiffInDays(statusChangedAt) >= 1
  ) {
    isArchivePromptShow = true
  }
  // } else if (
  //   !isArchived &&
  //   teeupStatus === teeupStatusKeys.cancelled &&
  //   showArchivePrompt
  // ) {
  //   isArchivePromptShow = true
  // }

  // TODO it may break something because removing !!invitation
  // let isInitiallyInvited =
  //     !!invitation && userStatus === teeupUserStatusKeys.invited

  let isInitiallyInvited = userStatus === teeupUserStatusKeys.invited
  const isDroppedOut = userStatus === teeupUserStatusKeys.droppedout
  const isSkippedDeclined = userStatus === teeupUserStatusKeys.skipped

  const isGreyOverlay =
    isDroppedOut ||
    isSkippedDeclined ||
    teeupStatus === teeupStatusKeys.droppedout ||
    teeupStatus === teeupStatusKeys.ended

  const showTimestampInHeader = isOrganizer

  const ownerName = _.get(owner, "username", null)
  const ownerAvatar = _.get(owner, "avatar", null)

  return {
    isOrganizer,
    ownerId,
    name: newTitle || teeup.name,
    status: teeupStatus || teeup.status,
    teeupStatusStyling: isGreyOverlay
      ? teeupUserStatusKeys.droppedout
      : teeupStatus || status,
    userStatus,
    isGreyOverlay,
    fromNowString,
    invitation,
    isPrivate: teeup.isPrivate,
    when: newWhenValue || when,
    where: newWhereValue || where,
    ownerName,
    ownerAvatar,
    participantCount,
    goingCount,
    ignoreHorizontalMargin,
    fromTeeupsSection,
    onPress,
    onArchivePressed,
    onKeepInTheListPress,
    isArchivePromptShow,
    isInitiallyInvited,
    isDroppedOut,
    isSkippedDeclined,
    onDeclineInvitation: () => onRespondToInvitation(false),
    onAcceptInvitation: () => onRespondToInvitation(true),
    isNewTitle: hasUpdate && !!newTitle,
    isNewWhen: hasUpdate && !!newWhen,
    isNewWhere: hasUpdate && !!newWhere,
    newMessages: hasUpdate && newMessages,
    photo: teeup.photo,
    showTimestampInHeader,
    isUndecidedWhenInGameplan,
    nudgeInfo,
  }
}

export default propsMapper
