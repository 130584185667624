import React, { Component } from "react"

import PropTypes from "prop-types"
import {
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
  KeyboardAvoidingView,
  FlatList,
  Platform,
  StyleSheet,
} from "react-native"

import strings from "@i18n"
import { AppColors, Units, AppFonts } from "@theme/"
import ViewWithHeader from "@ui/viewWithHeader"
import { caselessContains } from "@utils/dataUtils"
import { images } from "@utils/imageUtils"

// Components

const isAndroid = Platform.OS === "android"

const MAX_LENGTH_SEARCH = 250
const MAX_CHAR_SHOWS = 30
class SearchList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      search: "",
    }
  }

  onRowPresssed = (inputValue, isNew) => {
    const { data = [] } = this.state
    const value = inputValue.replaceAll(/\s+/g, " ").trim()

    if (value) {
      const isAlreadyExist = data.find(
        i => i?.value.toLowerCase() === value.toLowerCase()
      )
      this.props.callback(value, isNew && !isAlreadyExist)
      this.props.onClose()
    }
  }

  onFilter = search => {
    let { data } = this.props
    if (search && search.length > 0) {
      data = data.filter(item => caselessContains(item.value, search))
    }

    this.setState({
      search,
      data,
    })
  }

  renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.row}
      onPress={() => this.onRowPresssed(item.value)}>
      {item.isHistory && (
        <Image style={styles.historyIcon} source={images.clockHistory} />
      )}
      <Text style={styles.text}>{item.value}</Text>
    </TouchableOpacity>
  )

  renderEmptyComponent = () => {
    const { label } = this.props
    const { search } = this.state

    const cutTxt = txt => {
      if (txt.length > MAX_CHAR_SHOWS)
        return `${txt.slice(0, MAX_CHAR_SHOWS)}...`
      return txt
    }

    return (
      <TouchableOpacity
        style={styles.emptyContainer}
        onPress={() => this.onRowPresssed(search, true)}>
        <Text style={styles.emptyText}>
          Use “<Text style={styles.emptyBoldText}>{cutTxt(search)}</Text>
          {`” for ${label}`}
        </Text>
        <Image style={styles.emptyCheckmark} source={images.roundCheckmark} />
      </TouchableOpacity>
    )
  }

  keyExtractor = (_, index) => "" + index

  handleRenderItemSeparator = () => <View style={styles.separator} />

  handleGoToSelectSpecific = () =>
    this.props.selectSpecific(this.props.componentId)

  handleSubmitEditing = event => {
    const { data = [] } = this.state
    const { text } = event.nativeEvent
    const textValue = text.replaceAll(/\s+/g, " ").trim()
    if (
      data.length === 1 &&
      data[0].value.toLowerCase() === textValue.toLowerCase()
    ) {
      this.onRowPresssed(textValue)
    } else this.onRowPresssed(textValue, true)
  }

  getSpecificTextAndIcon = () => {
    const { type } = this.props
    let specificTextLabel
    let specificIcon
    switch (type) {
      case "date":
        specificTextLabel = strings.specificDate
        specificIcon = images.calendar
        break
      case "time":
        specificTextLabel = strings.specificTime
        specificIcon = images.clock
        break
      default:
        specificTextLabel = strings.specificDate
        specificIcon = images.calendar
        break
    }
    return { specificTextLabel, specificIcon }
  }

  render = () => {
    const { data, search } = this.state
    const { label, type } = this.props

    const { specificTextLabel, specificIcon } = this.getSpecificTextAndIcon()

    const showSpecificField = type === "date" || type === "time" ? false : true
    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={isAndroid ? undefined : "padding"}>
        <ViewWithHeader
          title={label}
          onClose={this.props.onClose}
          rightButton={<View style={styles.stubRightButton}></View>}>
          <View style={styles.searchContainer}>
            <TextInput
              style={styles.fieldContainer}
              key={label}
              placeholder="Type anything"
              placeholderTextColor={AppColors.brand.warmGrey2}
              selectionColor={AppColors.brand.pink}
              underlineColorAndroid="transparent"
              value={search}
              onChangeText={this.onFilter}
              onSubmitEditing={this.handleSubmitEditing}
              blurOnSubmit={false}
              returnKeyType="go"
              autoFocus
              maxLength={MAX_LENGTH_SEARCH}
            />
            <TouchableOpacity
              style={styles.fieldAction}
              onPress={() => this.onFilter("")}>
              <Image style={styles.fieldCancelIcon} source={images.cancel} />
            </TouchableOpacity>
          </View>
          {search ? this.renderEmptyComponent() : null}
          <FlatList
            style={styles.listContainer}
            keyboardShouldPersistTaps="handled"
            data={data}
            initialListSize={4}
            renderItem={this.renderItem}
            keyExtractor={this.keyExtractor}
            ItemSeparatorComponent={this.handleRenderItemSeparator}
          />
          {showSpecificField && (
            <TouchableOpacity
              onPress={this.handleGoToSelectSpecific}
              style={styles.specificContainer}>
              <Image style={styles.specificIcon} source={specificIcon} />
              <Text style={[styles.text, styles.specificText]}>
                {specificTextLabel}
              </Text>
              <Image source={images.chevron} />
            </TouchableOpacity>
          )}
        </ViewWithHeader>
      </KeyboardAvoidingView>
    )
  }
}

SearchList.propTypes = {
  data: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
}

SearchList.defaultProps = {
  data: [],
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.white,
    flex: 1,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    height: Units.rem(3),
    marginHorizontal: Units.rem(1),
    marginBottom: Units.rem(1),
    borderWidth: 1,
    borderColor: AppColors.brand.grey2,
    borderRadius: Units.responsiveValue(4),
  },
  listContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    maxHeight: "143px",
  },
  specificContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: AppColors.brand.whiteGrey,
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(1.25),
    paddingBottom: Units.rem(1.375),
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
  },
  specificIcon: {
    tintColor: AppColors.brand.pink,
  },
  row: {
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.6875),
    paddingBottom: Units.rem(0.9375),
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    flex: 1,
    ...AppFonts.biggerDemibold,
    color: "black",
    letterSpacing: -Units.rem(0.025),
  },
  historyIcon: {
    marginRight: Units.rem(0.5),
  },
  specificText: {
    flex: 1,
    marginHorizontal: Units.rem(0.75),
  },
  separator: {
    height: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  fieldContainer: {
    flex: 1,
    paddingLeft: Units.rem(1),
    ...AppFonts.biggerMedium,
  },
  fieldAction: {
    width: Units.rem(3),
    justifyContent: "center",
    alignItems: "center",
  },
  fieldCancelIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    tintColor: AppColors.brand.grey165,
  },
  emptyContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: Units.responsiveValue(56),
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.75),
    paddingBottom: Units.rem(1),
    backgroundColor: AppColors.brand.grey247,
  },
  emptyText: {
    flex: 1,
    marginRight: Units.rem(1),
    ...AppFonts.biggerDemibold,
  },
  emptyBoldText: {
    ...AppFonts.biggerBold,
  },
  emptyCheckmark: {
    tintColor: AppColors.brand.blackTwo,
  },
  stubRightButton: {
    width: Units.responsiveValue(85),
  },
})

SearchList.propTypes = {
  // componentId: PropTypes.string.isRequired,
  label: PropTypes.string,
  selectSpecific: PropTypes.func,
  type: PropTypes.string,
}

export default SearchList
