import { StyleSheet } from "react-native"

import { AppColors, Units, AppFonts } from "@theme"

const radius = Units.responsiveValue(4)
const suggestionRadius = Units.responsiveValue(8)

const calculatePaddings = ({
  isImage,
  isFile,
  isReply,
  onSuggestionCommentScreen,
}) =>
  isImage
    ? { padding: 0 }
    : isFile
    ? {
        paddingTop: 0,
        paddingBottom: 0,
      }
    : {
        paddingTop: Units.responsiveValue(onSuggestionCommentScreen ? 11 : 8),
        paddingBottom: Units.responsiveValue(
          onSuggestionCommentScreen ? 12 : 9
        ),
        paddingHorizontal: isReply
          ? Units.responsiveValue(7)
          : Units.responsiveValue(14),
      }

export default StyleSheet.create({
  messageContainer: {
    flexDirection: "row",
    backgroundColor: "red",
  },
  head: myMessage => ({
    [myMessage ? "borderBottomRightRadius" : "borderBottomLeftRadius"]: 0,
  }),
  default: () => ({}),
  tail: myMessage => ({
    [myMessage ? "borderTopRightRadius" : "borderTopLeftRadius"]: 0,
  }),
  middle: myMessage => ({
    [myMessage ? "borderBottomRightRadius" : "borderBottomLeftRadius"]: 0,
    [myMessage ? "borderTopRightRadius" : "borderTopLeftRadius"]: 0,
  }),
  mentionHead: myMessage => ({
    [myMessage ? "borderBottomRightRadius" : "borderBottomLeftRadius"]: 0,
    backgroundColor: AppColors.brand.lightYellow2,
  }),
  suggestionMessage: {
    backgroundColor: "transparent",
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderWidth: 0,
    flexDirection: "row",
    borderColor: "transparent",
  },
  selected: {
    borderColor: AppColors.brand.blue2,
  },
  selectableText: {
    userSelect: "text",
    cursor: "text",
  },
  decided: {
    borderColor: AppColors.brand.green,
  },
  new: {
    borderColor: AppColors.brand.red,
  },
  usual: {
    borderColor: AppColors.brand.message,
  },
  statusMessage: {
    backgroundColor: "transparent",
    borderWidth: 0,
    margin: 0,
    borderRadius: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pinIcon: isMyMessage => {
    const pinIconMargin = isMyMessage
      ? { marginLeft: Units.responsiveValue(2) }
      : { marginRight: Units.responsiveValue(2) }
    return {
      tintColor: AppColors.brand.coolGrey,
      transform: [{ rotateY: isMyMessage ? "180deg" : "0deg" }],
      height: Units.responsiveValue(10),
      width: Units.responsiveValue(10),
      ...pinIconMargin,
    }
  },
  rowWithPinIcon: isMyMessage => ({
    flexGrow: 1,
    flexDirection: isMyMessage ? "row" : "row-reverse",
    alignSelf: "flex-start",
    alignItems: "center",
    paddingBottom: Units.responsiveValue(4),
    backgroundColor: "red",
  }),
  pinnedUser: {
    ...AppFonts.smallDemibold,
    fontSize: Units.responsiveValue(11),
    color: AppColors.brand.coolGrey,
    flexWrap: "wrap",
  },
  overlay: {
    zIndex: 10_000,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
})

export const messageStyles = ({
  isMyMessage,
  isTeeupCancelled,
  isImage,
  isFile,
  isReply,
  isSuggestion,
  onSuggestionCommentScreen,
  isSuggestionOpen,
  newDesign = false,
  isInitialMessage,
  isTimestampsVisible,
  isStatusMessage,
}) => ({
  zIndex: 1,
  flexGrow: 0,
  maxWidth:
    isSuggestionOpen && document.documentElement.clientWidth <= 1366
      ? 280
      : 400,
  fontSize: newDesign ? "15px" : "17px",
  color: isMyMessage && !newDesign ? "#085f8e" : "#222222",
  backgroundColor: isMyMessage
    ? isTeeupCancelled
      ? AppColors.brand.warmGrey2
      : newDesign
      ? "#e0e8ee"
      : AppColors.brand.lightBlue4
    : onSuggestionCommentScreen
    ? "green"
    : newDesign
    ? "var(--lightGray2)"
    : AppColors.brand.lightGrey,
  ...(newDesign &&
    !isInitialMessage &&
    !isSuggestion &&
    !isMyMessage &&
    !isTimestampsVisible &&
    ((isInitialMessage && !isMyMessage) ||
      (!isMyMessage && !isStatusMessage)) && { border: "solid 1px #e3e5e8" }),
  ...calculatePaddings({
    isImage,
    isFile,
    isReply,
    onSuggestionCommentScreen,
  }),
  borderRadius: isSuggestion ? "0px" : radius,
  flexDirection: "column",
})
