export const AVATARS_SIZES = {
  MINI: "MINI",
  SMALL_S: "SMALL_S",
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
}

export const RSVPBadgeType = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
}

export const BUTTONS_ACTIONS = {
  MAKE_SUGGESTION: "MAKE_SUGGESTION",
  CALENDAR_ACTION: "CALENDAR_ACTION",
}
