import React, { useContext, useEffect } from "react"

import { isValidNumber } from "libphonenumber-js"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { batchVerifyAndGetUserInfo } from "@actions/batchVerifyAndGetUserInfo"
import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { removeSelectedContact } from "@actions/inviteesActions"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { selectSyncContactsConnectors } from "@selectors/contacts"
import { selectSelectedContacts } from "@selectors/invitees"
import {
  contactTypes,
  formatContact,
  getEmailLabel,
  getPhoneLabel,
  userTypes,
} from "@utils/contactUtils"
import { fetchSyncContactsData } from "@utils/contacts"
import { getIsEmail } from "@utils/getIsEmail"
import { isContainsOnlyNumbers } from "@utils/isContainsOnlyNumbers"
import { InviteSearch } from "components"

import { useCreateTeeUp } from "../../pages/CreateTeeUpNd/CreateTeeUpContext/useCreateTeeUp"
import { ContactsList } from "../ContactsList"

import { Wrapper, SubmitWrapper } from "./createTeeupInviteStyles"

export const CreateTeeupInviteScreen = ({
  setInvitees: setInviteesProp,
  setInviteToTeeUpById,
}) => {
  const dispatch = useDispatch()
  const { setInvitees } = useCreateTeeUp()
  const selectedContacts = useSelector(selectSelectedContacts)
  const selectedContactsLength = selectedContacts.length
  const contactsConnectors = useSelector(selectSyncContactsConnectors)
  const { setNotFoundEmails } = useContext(InvitePeopleContext)

  useEffect(() => {
    fetchSyncContactsData(contactsConnectors)
  }, [contactsConnectors])

  const formatRest = data => {
    return data.map(contact => {
      const id = uuidv4()
      const userObj = {
        id,
        value: contact,
        lastName: "",
        userType: userTypes.MANUAL,
      }
      if (isContainsOnlyNumbers(contact)) {
        userObj.firstName = getPhoneLabel(contact)
        userObj.contactType = contactTypes.PHONE
      } else {
        userObj.firstName = getEmailLabel(contact)
        userObj.contactType = contactTypes.EMAIL
      }

      return userObj
    })
  }

  const handleSendInvitations = async () => {
    const values = []
    const notValidContacts = []
    const notValidContactIds = []

    selectedContacts.forEach(contact => {
      const value =
        contact.matchingContact ||
        contact.emails[0].email ||
        contact.phoneNumbers[0].number
      values.push(value)

      if (
        !isContainsOnlyNumbers(value) &&
        !getIsEmail(value) &&
        !isValidNumber(value)
      ) {
        notValidContacts.push(contact)
        notValidContactIds.push(contact.id)
      }
    })

    if (notValidContacts.length > 0) {
      setNotFoundEmails(
        notValidContacts.map(({ matchingContact }) => matchingContact)
      )
      dispatch(removeSelectedContact(notValidContactIds))
      return
    }

    const { users, contacts, rest } = await batchVerifyAndGetUserInfo(values)
    const invitees = [
      ...users,
      ...contacts.map(contact => formatContact(contact)),
      ...formatRest(rest),
    ]

    if (setInviteesProp) {
      setInviteesProp(invitees)
      setInviteToTeeUpById(true)
      dispatch(removeSelectedContact(selectedContacts.map(c => c.id)))
      setNotFoundEmails([])
      return
    }

    setInvitees(invitees)
    dispatch(setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.MAIN))
    setNotFoundEmails([])
  }

  return (
    <>
      <Wrapper $isSelectedContacts={selectedContactsLength > 0}>
        <InviteSearch />

        <ContactsList />
      </Wrapper>

      {selectedContactsLength > 0 && (
        <SubmitWrapper>
          <ButtonEl
            isBlack
            fullWidth
            testID="send-invitations-button"
            variant={BUTTON_VARIANTS.ROUNDED}
            text={`Send ${selectedContactsLength} invitations`}
            onClick={handleSendInvitations}
          />
        </SubmitWrapper>
      )}
    </>
  )
}
