const reverseMapping = (keys, mapping) => {
  let reverse = {}
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i]
    reverse[mapping[key]] = key
  }
  return reverse
}

const prepareMappings = mapping => {
  const keys = Object.keys(mapping)
  const reverse = reverseMapping(keys, mapping)
  const values = Object.keys(reverse)

  const structured = {
    mapping,
    keys,
    values,
    reverse,
    ...mapping,
  }
  return structured
}

const userStatusTypes = prepareMappings({
  1: "invited",
  2: "going",
  3: "mightgo",
  4: "notgoing",
  5: "joined",
  6: "onmyway",
  7: "arrived",
  8: "attended",
  9: "droppedout",
  10: "skipped", // removed status,
  11: "approvalWaiting",
  12: "approvalDeclined",
  13: "coolIdea",
  17: "runningLate",
  18: "backedOut",
  19: "kickedOut",
  20: "interested",
  21: "notInterested",
})

const gameplanTypes = prepareMappings({
  when: 1,
  where: 2,
  what: 3,
  startsWhen: 4,
  worksWhen: 5,
})

const teeupStatusTypes = prepareMappings({
  1: "planning",
  2: "allset",
  3: "happening",
  4: "ended",
  5: "cancelled",
  6: "happeningSoon",
})

const reactionTypes = prepareMappings({
  love: 1,
  upvote: 2,
  downvote: 3,
  neutral: 4,
  notVote: 5,
  XMark: 6,
  question: 7,
  checkMark: 8,
})

const gameplanOptionStatusIds = prepareMappings({
  POLLING: 1,
  POLL_COMPLETE: 2,
  POLL_CLOSED: 3,
  SCHEDULED: 4,
})

export {
  userStatusTypes,
  gameplanTypes,
  teeupStatusTypes,
  reactionTypes,
  gameplanOptionStatusIds,
}
