import React from "react"

import { useSelector } from "react-redux"

import SuccessIcon from "@assets/images/success.svg"
import { Subtitle } from "@assets/styles/typographyStyles"
import { NewModal } from "@components"
import { selectUserInfo } from "@selectors/user"

import { MessageWrapper } from "./molecules/forgotPasswordStyles"

export const ForgotPasswordModal = ({ onClose, isOpen = true }) => {
  const { email } = useSelector(selectUserInfo)

  const message = `We've sent an email to ${email} with password reset instructions.  If the email does not arrive soon, check your spam folder.`

  return (
    <NewModal
      isOpen={isOpen}
      title="Reset password"
      closeText={"Close"}
      onClose={onClose}>
      <MessageWrapper>
        <img src={SuccessIcon} alt="error" />
        <Subtitle
          style={{ color: "#767676", wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </MessageWrapper>
    </NewModal>
  )
}
