import styled, { css } from "styled-components"

import { FontWeight, SubtitleXS } from "assets/styles/typographyStyles"

export const Wrapper = styled.div`
  ${({ $isDecided, $isPast }) => css`
    width: max-content;
    background-color: ${$isDecided
      ? "var(--lightMint)"
      : $isPast
      ? "var(--lightGray4)"
      : "var(--lightBlue3)"};
    border: 1px solid var(--lightGray5);
    border-radius: 25px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 6px 10px;
    position: absolute;
    top: -20px;
    right: 5px;
  `};
`

export const Text = styled(SubtitleXS)`
  ${({ $isDecided, $isPast }) => css`
    color: ${$isDecided
      ? "var(--green3)"
      : $isPast
      ? "var(#000)"
      : "var(--darkBlue)"};
    font-weight: ${FontWeight.bold};
    text-transform: uppercase;
  `};
`
