import React, { memo } from "react"

import truncate from "lodash/truncate"
import PropTypes from "prop-types"
import { View, Text } from "react-native"

import { MAX_NAME_LENGHT } from "contexts/TeeupPreviewContext/constants"

import styles from "./messageSenderStyles"

const MessageSender = ({ name, isAttachment }) => (
  <View style={isAttachment && styles.attachmentSender}>
    <Text style={styles.messageSenderText}>
      @{truncate(name, { length: MAX_NAME_LENGHT })}
    </Text>
  </View>
)

MessageSender.propTypes = {
  name: PropTypes.string,
  isAttachment: PropTypes.bool.isRequired,
}

export default memo(MessageSender)
