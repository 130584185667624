import { StyleSheet } from "react-native"

import { Units } from "@theme"

export const styles = StyleSheet.create({
  row: { flexDirection: "row", alignItems: "center" },
  rightBottomImage: {
    flexGrow: 0,
    flexShrink: 1,
    height: Units.responsiveValue(15),
    width: Units.responsiveValue(15),
  },
  userAvatarStyle: (isFirst, zIndex) => ({
    marginLeft: -Units.responsiveValue(isFirst ? 0 : 8),
    overflow: isFirst ? undefined : "hidden",
    zIndex,
  }),
  timeFromNow: {
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 12,
    lineHeight: "13px",
    letterSpacing: -0.24,
    color: "#222222",
    paddingLeft: Units.responsiveValue(10),
  },
  grayout: {
    opacity: 0.5,
    filter: "grayscale(1)",
  },
})
