import React from "react"

import Item from "./Item"

const TimePickerItem = ({
  small,
  average,
  pickHour,
  noMargin,
  startTimeHourNumbers,
  selectStartHour,
  startTimeMinuteNumbers,
  selectStartMinute,
  durationHourNumbers,
  durationMinuteNumbers,
  selectDurationHour,
  selectDurationMinute,
}) => {
  return (
    <div
      className="time-picker-item__container"
      style={{ width: small ? "136px" : average ? "226px" : "316px" }}>
      <p className="time-picker-item__title">
        {pickHour ? "Hours" : "Minutes"}
      </p>
      {!!startTimeHourNumbers && (
        <div className="time-picker-item__elements">
          {startTimeHourNumbers.map(item => (
            <Item item={item} selectStartHour={selectStartHour} />
          ))}
        </div>
      )}
      {!!startTimeMinuteNumbers && (
        <div className="time-picker-item__elements">
          {startTimeMinuteNumbers.map(item => (
            <Item
              item={item}
              noMargin={noMargin}
              selectStartMinute={selectStartMinute}
            />
          ))}
        </div>
      )}
      {!!durationHourNumbers && (
        <div className="time-picker-item__elements">
          <div
            className="time-picker-item__zero"
            style={{
              backgroundColor: durationHourNumbers[0]?.selected && "#ffffff",
            }}
            onClick={() => selectDurationHour(durationHourNumbers[0]?.value)}>
            {durationHourNumbers[0]?.value}
          </div>
          {durationHourNumbers.slice(1).map(item => (
            <Item
              item={item}
              noMargin={noMargin}
              selectDurationHour={selectDurationHour}
            />
          ))}
        </div>
      )}
      {!!durationMinuteNumbers && (
        <div className="time-picker-item__elements">
          {durationMinuteNumbers.map(item => (
            <Item
              item={item}
              noMargin={noMargin}
              selectDurationMinute={selectDurationMinute}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default TimePickerItem
