import React from "react"

import { Caption, FontWeight } from "@assets/styles/typographyStyles"

export const InnerEventsLabel = ({ event, textColor }) => (
  <Caption
    style={{
      color: textColor,
      fontWeight: FontWeight.medium,
      marginTop: 5,
    }}>
    +{event.innerEvents.length - 1}
  </Caption>
)
