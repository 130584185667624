import { useEffect, useState } from "react"

import DefaultModal from "@ui/DefaultModal"
import { getAttendanceItems } from "@utils/peopleUtils"
import { useUserStatusChange } from "hooks"

import ModalHeader from "../../../ui/DefaultModal/Header"
import ActionButton from "../../ActionButton"
import StatusIcon from "../../Content/Participants/ParticipantItem/StatusIcon"

import Options from "./Options"

import "./index.scss"

const AttendanceModal = ({ user }) => {
  const [open, setOpen] = useState(false)
  const [whereAreYouOptions, setWhereAreYouOptions] = useState([])
  const [areYouGoingOptions, setAreYouGoingOptions] = useState([])

  const { handleUserStatusChange, statusExtended } = useUserStatusChange(user)

  useEffect(() => {
    const wherePick = new Set(["runningLate", "arrived", "onmyway"])
    const goingPick = new Set(["going", "mightgo", "notgoing"])

    const attendance = getAttendanceItems()
    const whereAreYouOptions = attendance.filter(item =>
      wherePick.has(item.status)
    )
    const areYouGoingOptions = attendance.filter(item =>
      goingPick.has(item.status)
    )

    setAreYouGoingOptions(areYouGoingOptions)
    setWhereAreYouOptions(whereAreYouOptions)
  }, [])

  const handleStatusClick = item => {
    handleUserStatusChange(item.status)
    setOpen(false)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <ActionButton
        onClick={handleOpen}
        className="people-attendance-button"
        variant="primary">
        {statusExtended && (
          <>
            <StatusIcon status={statusExtended} isBlock isLarge />
            {statusExtended.text}
          </>
        )}

        {!statusExtended && "RSVP"}
      </ActionButton>
      <DefaultModal open={open} onClose={handleClose}>
        <div className="people-attendance-content">
          <ModalHeader
            title="My Attendance"
            subtitle="Let others know your status."
            onClose={handleClose}
          />

          <div className="people-attendance-options-container">
            <Options
              title="Are you going?"
              options={areYouGoingOptions}
              onClick={handleStatusClick}
            />
            <Options
              title="Where are you?"
              options={whereAreYouOptions}
              onClick={handleStatusClick}
            />
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default AttendanceModal
