import React from "react"

import { apps } from "@constants/apps"

import useOnlineTabState from "../../Hooks/useOnlineTabState"

import "./index.scss"

const MeetingApp = () => {
  const { activeApp, setActiveApp, setActiveButtonId } = useOnlineTabState()

  const handleSelectApp = selectedItem => {
    if (activeApp && activeApp.id === selectedItem.id) {
      setActiveApp(null)
      setActiveButtonId(0)
    } else {
      setActiveApp(selectedItem)
      setActiveButtonId(1)
    }
  }

  return (
    <div className="suggest-online__app">
      <h3 className="suggest-online__title">Meeting app</h3>
      <div className="suggest-online-apps">
        {apps.map(item => {
          const isActiveApp = activeApp && item.id === activeApp.id
          const isDefault = !activeApp

          return (
            <div
              data-testid={item.title}
              className="suggest-online-app"
              key={item.id}
              onClick={() => {
                handleSelectApp(item)
                item.title === "Other" && setActiveButtonId(0)
              }}>
              <img
                src={isDefault || isActiveApp ? item.image : item.imageInactive}
                alt={item.title}
              />
              <h5
                className="suggest-online-app__title"
                style={{
                  color: isActiveApp
                    ? "#222"
                    : isDefault
                    ? "#989898"
                    : "#e3e5e8",
                }}>
                {item.title}
              </h5>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MeetingApp
