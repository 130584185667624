import React from "react"

import PropTypes from "prop-types"
import { View, TouchableOpacity, Text, ActivityIndicator } from "react-native"

import { AppColors } from "@theme"

import { styles, invitationButton, invitationButtonText } from "./styles"

const ActionButton = ({
  isGreyOverlay,
  onPress,
  alreadyAnswered,
  buttonText,
  isPrimary,
  noMargin,
  customStyle,
}) => {
  return (
    <div data-testid={buttonText}>
      <TouchableOpacity
        style={[
          invitationButton({
            buttonStatus: isPrimary,
            isGreyOverlay,
          }),
          noMargin && { marginRight: 0 },
        ]}
        onPress={onPress}>
        {alreadyAnswered ? (
          <ActivityIndicator size="small" color={AppColors.brand.white} />
        ) : (
          <Text
            style={[
              invitationButtonText({
                buttonStatus: isPrimary,
                isGreyOverlay,
              }),
              customStyle && customStyle,
            ]}>
            {buttonText}
          </Text>
        )}
      </TouchableOpacity>
    </div>
  )
}

ActionButton.propTypes = {
  isGreyOverlay: PropTypes.bool,
  onPress: PropTypes.func,
  alreadyAnswered: PropTypes.bool,
  buttonText: PropTypes.string,
  isPrimary: PropTypes.bool,
}

const ActionButtonsView = ({
  isGreyOverlay,
  onMainButtonPress,
  onSecondaryButtonPress,
  alreadyAnswered,
  mainButtonText,
  secondaryButtonText,
  noMargin,
  isFromOverviewPage,
}) => {
  return (
    <View style={isFromOverviewPage && styles.buttonsWrapper}>
      <View style={styles.invitationButtonContainer}>
        {!!secondaryButtonText && (
          <ActionButton
            isGreyOverlay={isGreyOverlay}
            onPress={onSecondaryButtonPress}
            alreadyAnswered={alreadyAnswered}
            buttonText={secondaryButtonText}
            customStyle={{ fontSize: "12px" }}
          />
        )}

        {!!mainButtonText && (
          <ActionButton
            isPrimary
            isGreyOverlay={isGreyOverlay}
            onPress={onMainButtonPress}
            alreadyAnswered={alreadyAnswered}
            buttonText={mainButtonText}
            customStyle={{ fontSize: "12px" }}
          />
        )}
      </View>
    </View>
  )
}

ActionButtonsView.propTypes = {
  isGreyOverlay: PropTypes.bool,
  onMainButtonPress: PropTypes.func,
  onSecondaryButtonPress: PropTypes.func,
  alreadyAnswered: PropTypes.bool,
  mainButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
}

export default ActionButtonsView
