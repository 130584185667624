import cn from "classnames"

import ActionButton from "@components/TeeupPeople/ActionButton"
import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { ActionButton as IActionButton, StatusIcon } from "types/participant"

import "./index.scss"

interface Props {
  title: string
  subtitle?: string
  avatar: string
  status: StatusIcon
  defaultAvatar: string
  actionButtons?: IActionButton[]
  isOptionsShown?: boolean
}

const ModalHead = ({
  title,
  subtitle,
  avatar,
  status,
  actionButtons,
  defaultAvatar,
  isOptionsShown,
}: Props) => {
  return (
    <div
      className={cn("modal-participant-head", {
        "modal-participant-head-rounded": isOptionsShown,
      })}>
      <ParticipantIcon
        isXL
        status={status}
        avatar={avatar}
        defaultAvatar={defaultAvatar}
      />

      <div className="modal-participant-info">
        <div>{title}</div>

        {subtitle && <div>{subtitle}</div>}
      </div>

      <div className="modal-action-buttons">
        {actionButtons?.map((button, index) => (
          <ActionButton key={index} onClick={button.action} variant="primary">
            {button.text}
          </ActionButton>
        ))}
      </div>
    </div>
  )
}

export default ModalHead
