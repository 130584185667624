import React, { useEffect } from "react"

import { connect } from "react-redux"

import { loginProviders } from "@config/enums"
import { AUTH0_TYPES } from "@constants/auth0Types"
import { BUTTON_VARIANTS } from "@constants/button"
import { useSignUpActions } from "@hooks"
import { selectGuestRegistrationData } from "@selectors/guest"

import {
  AuthButtons,
  AuthWrapper,
  OrLine,
} from "../../assets/styles/authButtonsStyles"
import { TEMPLATE_TYPES } from "../../pages/components/CustomTemplateSign/constants"
import { AuthTabs } from "../AuthTabs"
import { AuthButton, ButtonEl } from "../common"

const SignUpButtons = ({ isHiddenTabs, isPreview, guestRegistrationData }) => {
  const {
    handleTwitterLogin,
    handleGoogleLogin,
    handleFbLogin,
    handleOutlookLogin,
    handleAppleLogin,
    handleSignUp,
  } = useSignUpActions({ isPreview })

  const triggerSSOMethod = () => {
    switch (guestRegistrationData?.type) {
      case loginProviders.google:
        handleGoogleLogin()
        break
      case loginProviders.apple:
        handleAppleLogin()
        break
      case loginProviders.twitter:
        handleTwitterLogin()
        break
      case loginProviders.fb:
        handleFbLogin()
        break
      case loginProviders.outlook:
        handleOutlookLogin()
        break
      default:
        break
    }
  }

  useEffect(() => {
    triggerSSOMethod()
  }, [guestRegistrationData])

  return (
    <AuthWrapper>
      {!isHiddenTabs && <AuthTabs type={TEMPLATE_TYPES.signup} />}

      <AuthButtons>
        <AuthButton
          text="Sign up with Google"
          iconType={AUTH0_TYPES.GOOGLE}
          onClick={handleGoogleLogin}
        />

        <AuthButton
          text="Sign up with Apple"
          iconType={AUTH0_TYPES.APPLE}
          onClick={handleAppleLogin}
        />

        <AuthButton
          text="Sign up with Twitter"
          iconType={AUTH0_TYPES.TWITTER}
          onClick={handleTwitterLogin}
        />

        <AuthButton
          text="Sign up with Facebook"
          iconType={AUTH0_TYPES.FACEBOOK}
          onClick={handleFbLogin}
        />

        <AuthButton
          text="Sign up with Outlook"
          iconType={AUTH0_TYPES.OUTLOOK}
          onClick={handleOutlookLogin}
        />

        <OrLine />

        <ButtonEl
          testID="singUpWithEmail"
          text="Sign up with email"
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={handleSignUp}
        />
      </AuthButtons>
    </AuthWrapper>
  )
}

const mapStateToProps = state => ({
  guestRegistrationData: selectGuestRegistrationData(state),
})

export default connect(mapStateToProps)(SignUpButtons)
