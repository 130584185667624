// import moment from "moment-timezone"

import dayjs from "utils/dayjs"

import { roundMinutesToFifteen } from "./roundMinutesToFifteen"

export const getDisabledDate = ({ isOnScroll = false, hour, nowDate }) => {
  return isOnScroll
    ? dayjs(hour).diff(
        dayjs(roundMinutesToFifteen(nowDate)).add(15, "minutes"),
        "minutes"
      ) < -16
    : dayjs(hour).minute(0).diff(dayjs(nowDate), "hours") < 0
}
