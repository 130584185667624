import React, { useEffect, Fragment, useContext, useState } from "react"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Paper from "@mui/material/Paper"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"

import { setIsCreateTeeupOpen } from "@actions/createTeeupActions"
import CreateTeeupAiModal from "@components/CreateTeeupAiModal/CreateTeeupAiModal"
import CreateTeeupConfirmPopup from "@components/CreateTeeupConfirmPopup"
import {
  BOTTOM_SIDEBAR_MENU,
  NEW_SIDEBAR_MENU,
  SIDEBAR_MENU_NAMES,
} from "@constants/sidebar"
import { useScreenWidth } from "@hooks"
import { SvgIcon } from "@components/common"
import closeIcon from "@assets/images/newDesign/ndInviteesCross.svg"
import { selectIsCreateTeeupOpen } from "@selectors/createTeeUp"
import { selectUserId } from "@selectors/user"
import { showAiFeature } from "@utils/showAiFeature"
import { SideBarContext } from "@utils/sideBarContext"
import { CreateTeeupModal } from "components"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"
import { useCreateTeeupStore } from "stores/createTeeupStore"

import { ToggleButton } from "./atoms"
import UserInfoPopover from "./UserInfoPopover"
import { NavItem } from "./molecules"
import {
  Wrapper,
  Logo,
  CrossWrapper,
  Navigation,
  FirstPartList,
  ListItem,
  Name,
  LogoWrapper,
  BottomPart,
  Title,
} from "./sidebarStyles"

const accordionStyles = {
  ".MuiAccordion-root::before": {
    content: "none",
  },
  ".MuiAccordionSummary-root": {
    borderBottomWidth: 0,
  },
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "52px",
  },
}

const summuryStyles = {
  ".MuiAccordionSummary-content": {
    margin: "0px !important",
  },
  ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    maxWidth: 235,
    minWidth: 235,
    borderRadius: 8,
    fontSize: theme.typography.pxToRem(12),
  },
}))

export const NewSidebar = () => {
  const { pathname, search } = useLocation()

  const route = pathname?.slice(1)
  const menu = NEW_SIDEBAR_MENU[route] || NEW_SIDEBAR_MENU.common
  const bottomMenu = BOTTOM_SIDEBAR_MENU[route] || BOTTOM_SIDEBAR_MENU.common
  const defaultRoute = menu?.[0]?.items?.[0]

  const [activeRoute, setActiveRoute] = useState(defaultRoute)
  const [isProfileMenuOpen, toggleProfileMenuOpen] = useState(false)

  const [activeExpandedMenus, setActiveExpandedMenus] = useState([])
  const userId = useSelector(selectUserId)
  const [isAiCreateOpen, setIsAiCreateOpen] = useState(false)
  const isCreateOpen = useSelector(selectIsCreateTeeupOpen)
  const dispatch = useDispatch()
  const history = useHistory()
  const { isSideBarOpen, focusCounter, handleToggleSidebar, setFocusCounter } =
    useContext(SideBarContext)
  const logoName = isSideBarOpen ? "CooWePinkLogo" : "coowe-small-icon"
  const { isSmallSize } = useScreenWidth()
  const isCreateConfirmOpen = useCreateTeeupStore(
    state => state.isConfirmCreateTeeupPopupOpen
  )

  useEffect(() => {
    if (pathname) {
      const items = menu.flatMap(item => item.items)
      const index = items.findIndex(item => item.pathname === pathname)

      setActiveExpandedMenus(
        [...activeExpandedMenus, items?.[index]?.group].filter(Boolean)
      )
    }
  }, [pathname])

  useEffect(() => {
    const option = new URLSearchParams(search).get("option")

    if (option) {
      const items = menu.flatMap(item => item.items)
      const index = items.findIndex(item => item.option === option)

      setActiveRoute(items[index])
    }
  }, [search])

  const setIsCreateOpen = value => {
    dispatch(setIsCreateTeeupOpen(value))
  }

  const toggleAiModal = () => {
    setIsAiCreateOpen(prev => !prev)
  }

  const handleSetFocus = item => {
    if (document.body.offsetWidth <= Number.parseInt(MEDIA_SIZES.MOBILE)) {
      handleToggleSidebar(false)
    }

    const { name, itemIndex } = item
    switch (name) {
      case SIDEBAR_MENU_NAMES.AI_CREATE:
        history.push("/inbox")
        toggleAiModal()
        break
      case SIDEBAR_MENU_NAMES.CREATE:
        history.push("/inbox")
        setIsCreateOpen(true)
        break
      default:
        setActiveRoute(item)
        setFocusCounter(itemIndex)
        break
    }
  }

  const renderMenuItems = menuItems => {
    return menuItems.map(item => {
      const isActive = item.option
        ? activeRoute.option === item.option
        : focusCounter === item.index || activeRoute.name === item.name
      const isShouldShow = showAiFeature(userId)

      if (item.name === SIDEBAR_MENU_NAMES.AI_CREATE && !isShouldShow) {
        return null
      }

      return (
        <ListItem
          $isActive={isActive}
          $isClickable
          testId={item.testId}
          $isWrapped={!isSideBarOpen}
          $isCreate={
            item.name === SIDEBAR_MENU_NAMES.CREATE ||
            item.name === SIDEBAR_MENU_NAMES.AI_CREATE
          }
          onClick={() => handleSetFocus(item)}
          key={`${item.name}-${item.index}`}>
          <NavItem isActive={isActive} item={item} />
        </ListItem>
      )
    })
  }

  const toggleMenuItems = groupName => {
    if (activeExpandedMenus.includes(groupName)) {
      setActiveExpandedMenus(
        activeExpandedMenus.filter(item => item !== groupName)
      )
    } else {
      setActiveExpandedMenus([...activeExpandedMenus, groupName])
    }
  }

  const goBack = () => {
    history.push("/inbox")
  }

  return (
    <>
      {isCreateOpen && (
        <CreateTeeupModal onClose={() => setIsCreateOpen(false)} />
      )}

      {isAiCreateOpen && (
        <CreateTeeupAiModal type="create" onClose={toggleAiModal} />
      )}

      {isCreateConfirmOpen && <CreateTeeupConfirmPopup />}

      <Wrapper $isWrapped={!isSideBarOpen} $isOpen={isSideBarOpen}>
        {!isSmallSize && <ToggleButton />}
        <LogoWrapper>
          <Link to="/inbox">
            <Logo $isWrapped={!isSideBarOpen} name={logoName} />
          </Link>
          <CrossWrapper
            $isOpen={isSideBarOpen}
            onClick={() => handleToggleSidebar(false)}>
            <img src={closeIcon} alt="close" />
          </CrossWrapper>
        </LogoWrapper>

        <Navigation>
          <FirstPartList>
            {menu?.[0]?.title && (
              <Title onClick={goBack}>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="Media / Icon/Unfilled/cheveron-down">
                    <path
                      id="Icon"
                      d="M12 15.1521L7 10.1521L12 5.15213"
                      stroke="#404040"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
                <p>{menu[0].title}</p>
              </Title>
            )}
            {menu.map((group, groupIndex) => (
              <Fragment key={`${group.name}-${groupIndex}`}>
                {isSideBarOpen && group.name ? (
                  <Accordion
                    expanded={activeExpandedMenus.includes(group.name)}
                    onChange={() => toggleMenuItems(group.name)}
                    className="!m-0 !shadow-none [&:before]:!opacity-0"
                    sx={accordionStyles}>
                    <AccordionSummary
                      expandIcon={<SvgIcon name="arrow" />}
                      sx={summuryStyles}>
                      <ListItem $isGroup={group.name}>
                        <Name $isGroup>{group.name}</Name>
                      </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className="!p-0">
                      {renderMenuItems(group.items)}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  renderMenuItems(group.items)
                )}
              </Fragment>
            ))}
          </FirstPartList>

          <BottomPart>
            {bottomMenu.map(item => {
              const isActive = focusCounter === item.index

              return (
                <ListItem
                  $isActive={isActive}
                  $isClickable
                  $isWrapped={!isSideBarOpen}
                  $isProfileLink={item.name === SIDEBAR_MENU_NAMES.PROFILE}
                  onClick={() => handleSetFocus(item)}
                  key={`${item.name}-${item.index}`}>
                  <NavItem isActive={isActive} item={item} />
                </ListItem>
              )
            })}

            <ClickAwayListener onClickAway={() => toggleProfileMenuOpen(false)}>
              <div>
                <HtmlTooltip
                  onClose={() => toggleProfileMenuOpen(false)}
                  open={isProfileMenuOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Paper elevation={3}>
                      <UserInfoPopover
                        onPopoverClose={() => toggleProfileMenuOpen(false)}
                      />
                    </Paper>
                  }>
                  <ListItem
                    $isActive={false}
                    $isClickable
                    $isWrapped={!isSideBarOpen}
                    $isProfileLink
                    onClick={event => {
                      event.stopPropagation()
                      toggleProfileMenuOpen(!isProfileMenuOpen)
                    }}>
                    <NavItem
                      isActive={false}
                      disabled
                      profile
                      item={{
                        index: 13,
                        name: SIDEBAR_MENU_NAMES.PROFILE,
                        pathname: "/my-profile",
                      }}
                    />
                  </ListItem>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </BottomPart>
        </Navigation>
      </Wrapper>
    </>
  )
}
