import styled from "styled-components"

import { SubtitleXS, FontWeight } from "@assets/styles/typographyStyles"

export const List = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`

export const Calendar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  top: 114px;
  left: 7px;
  z-index: 999;
`

export const CalendarIcon = styled.img`
  width: 10px;
  height: 10px;
`

export const CalendarText = styled(SubtitleXS)`
  color: var(--gray);
  font-weight: ${FontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.45px;
  margin-left: 5px;
`

export const CalendarMore = styled(SubtitleXS)`
  background-color: var(--radicalRed);
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  font-weight: ${FontWeight.bold};
  font-size: 12px;
  display: block;
  padding: 0 5px;
  margin-top: -1px;
  margin-left: 5px;
`
