import styled from "styled-components"

import {
  NSubtitle,
  NSubtitleS,
  NFontWeight,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray3);
  }
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`

export const NameInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const NameFirstLine = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;
`

export const Name = styled(NSubtitle)`
  color: var(--black2);
  font-weight: ${NFontWeight.semiBold};
`

export const Username = styled(NSubtitleS)`
  color: var(--gray);
`
