import { useHistory } from "react-router-dom"

import { teeupUserStatusKeys } from "@config/enums"
import { useScreenWidth } from "@hooks/useScreenWidth"

import { useAppLink } from "@hooks"

export const useSubmitButtonInfo = (confirmingStatus, closeHandle) => {
  const { appLink } = useAppLink()
  const { isSmallSize } = useScreenWidth()
  const history = useHistory()

  const confirmHandle = () => {
    if (isSmallSize) {
      window.location.href = appLink
    } else {
      history.push("/sign-up")
    }
  }

  const getSubmitButtonInfo = () => {
    switch (confirmingStatus) {
      case teeupUserStatusKeys.notInterested:
        return {
          confirmText: "Change Response",
          confirmAction: closeHandle,
        }
      case teeupUserStatusKeys.notgoing:
        return {
          confirmText: "Change Status",
          confirmAction: closeHandle,
        }
      default:
        return {
          confirmText: isSmallSize ? "Download the app" : "Sign up",
          confirmAction: confirmHandle,
        }
    }
  }

  return getSubmitButtonInfo()
}
