import React from "react"

import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import useInPersonTabState from "../../Hooks/useInPersonTabState"
import useSuggest from "../../Hooks/useSuggest"

import "./index.scss"

const FooterButtonsWrapper = ({ onSave, handleClose }) => {
  const { activePlace } = useInPersonTabState()
  const { onSubmit } = useSuggest({
    suggestInfo: activePlace,
    suggestType: "in-person",
  })
  const { closeSuggestWhereModal } = useTeeUpPage()

  const makeSuggestion = () => {
    onSubmit(false)
    if (handleClose) {
      handleClose()
    } else closeSuggestWhereModal()
  }

  return (
    <div className="suggest-buttons">
      <div
        className="suggest-buttons__gameplan"
        onClick={() => {
          onSubmit(true)
          closeSuggestWhereModal()
          // TODO: remove it later (Calendar removing)
          // store.dispatch(setIsCalendarSynchronized(false))
          // syncCalendar()
        }}>
        Decide on this
      </div>
      <div className="suggest-buttons__suggest" onClick={makeSuggestion}>
        Suggest
      </div>
    </div>
  )
}

export default FooterButtonsWrapper
