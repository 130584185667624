import React, { useContext } from "react"

import Button from "@mui/material/Button"
import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { Wrapper } from "./confirmationScreenButtonsStyles"

export const ConfirmationScreenButtons = ({ isSubmitDisabled }) => {
  const {
    stepsManager: { goPrevStep, goNextStep },
  } = useContext(TeeupPreviewContext)

  return (
    <Wrapper $isShown>
      <ButtonEl
        fullWidth
        isBlack
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Confirm"
        disabled={isSubmitDisabled}
        onClick={goNextStep}
      />

      <Button
        variant="text"
        onClick={goPrevStep}
        sx={{ color: "var(--gray4)" }}>
        Keep suggesting and reacting
      </Button>
    </Wrapper>
  )
}

ConfirmationScreenButtons.propTypes = {
  isSubmitDisabled: PropTypes.bool,
}
