import moment from "moment-timezone"

import { PART_OF_THE_DAY } from "../constants"

export const setDayPart = date => {
  const hour = moment(date).hour()

  if (hour > 8 && hour < 17) {
    return PART_OF_THE_DAY.daytime
  } else if (hour > 20 || hour < 6) {
    return PART_OF_THE_DAY.night
  } else {
    return PART_OF_THE_DAY.inBetween
  }
}
