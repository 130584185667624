import React from "react"

import styled, { css } from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"
import { SubtitleS } from "@assets/styles/typographyStyles"
import { SvgIcon } from "@components/common"

export const ChatSuggestionValueWrapper = styled.div`
  ${({ $isThreeDots, $isDecided, $isWithdrawn }) => css`
    color: var(--darkBlue);

    ${$isThreeDots &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};

    ${$isDecided &&
    css`
      color: var(--green);
    `};
    ${$isWithdrawn &&
    css`
      color: var(--gray4);
    `};
  `}};
`

export const ChatSuggestionValue = styled(SubtitleS)`
  color: var(--black4);

  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: ${NFontWeight.bold};
    `};
`

export const WhereTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`

export const AppIcon = styled.img`
  width: 20px;
  height: 20px;
`

const MapLocationWrapper = styled.div`
  display: flex;
  background-color: var(--white);
  border-radius: 50%;
  border: 0.5px solid var(--lightGray5);
  padding: 3px;
`

export const MapLocationIcon = styled(({ ...props }) => (
  <MapLocationWrapper>
    <SvgIcon {...props} />
  </MapLocationWrapper>
))``
