import Units from "./units"

const family = {
  extrabold: "greycliffcf-extrabold-salt",
  bold: "greycliffcf-bold-salt",
  demibold: "greycliffcf-demibold-salt",
  medium: "greycliffcf-medium-salt",
  regular: "greycliffcf-regular-salt",
}

const sizes = {
  bigger: Units.responsiveValue(17),
  big: Units.responsiveValue(16),
  big18: Units.responsiveValue(18),
  big20: Units.responsiveValue(18),
  huge: Units.responsiveValue(40),
  biggest: Units.responsiveValue(48),
  medium: Units.responsiveValue(15),
  header: Units.responsiveValue(21),
  header22: Units.responsiveValue(22),
  xl: Units.responsiveValue(24),
  headerSmall: Units.responsiveValue(20),
  headerBig: Units.responsiveValue(28),
  middle: Units.responsiveValue(14),
  smallish: Units.responsiveValue(13),
  small: Units.responsiveValue(12),
  smallest: Units.responsiveValue(11),
  extraSmall: Units.responsiveValue(10),
  small8: Units.responsiveValue(8),
}

const base = {
  dynamic: (sizeType, familyType) => ({
    fontSize: sizes[sizeType],
    fontFamily: family[familyType],
  }),
  cardTitle: {
    fontSize: sizes.medium,
    fontFamily: family.demibold,
    letterSpacing: -Units.rem(0.018_75),
  },
  teeupCardTitle: {
    fontSize: sizes.bigger,
    fontFamily: family.extrabold,
    letterSpacing: -Units.rem(0.021_25),
  },
  date: {
    fontSize: sizes.biggest,
    fontFamily: family.medium,
  },
  navbar: {
    fontSize: Units.rem(1.375),
    fontFamily: family.demibold,
  },
  headerDemibold: {
    fontSize: sizes.header,
    fontFamily: family.demibold,
  },
  hugeDemibold: {
    fontSize: sizes.huge,
    fontFamily: family.demibold,
  },
  middleDemibold: {
    fontSize: sizes.middle,
    fontFamily: family.demibold,
  },
  xlBold: {
    fontSize: sizes.xl,
    fontFamily: family.bold,
  },
  xlDemibold: {
    fontSize: sizes.xl,
    fontFamily: family.demibold,
  },
  xlMedium: {
    fontSize: sizes.xl,
    fontFamily: family.medium,
  },
  middle: {
    fontSize: sizes.middle,
    fontFamily: family.bold,
  },
  middleMedium: {
    fontSize: sizes.middle,
    fontFamily: family.medium,
  },
  middleExtraBold: {
    fontSize: sizes.middle,
    fontFamily: family.extrabold,
  },
  header: {
    fontSize: sizes.header,
    fontFamily: family.bold,
  },
  header22: {
    fontSize: sizes.header,
    fontFamily: family.bold,
  },
  header20: {
    fontSize: sizes.headerSmall,
    fontFamily: family.bold,
  },
  headerBig: {
    fontSize: sizes.headerBig,
    fontFamily: family.demibold,
  },
  headerBigBold: {
    fontSize: sizes.headerBig,
    fontFamily: family.bold,
  },
  headerMedium: {
    fontSize: sizes.header,
    fontFamily: family.medium,
  },
  bigBold: {
    fontSize: sizes.big,
    fontFamily: family.bold,
  },
  big18Bold: {
    fontSize: sizes.big18,
    fontFamily: family.bold,
  },
  big20Demibold: {
    fontSize: sizes.big20,
    fontFamily: family.demibold,
  },
  bigDemibold: {
    fontSize: sizes.big,
    fontFamily: family.demibold,
  },
  bigMedium: {
    fontSize: sizes.big,
    fontFamily: family.medium,
  },
  big: {
    fontSize: sizes.big,
    fontFamily: family.regular,
  },
  mediumBig: {
    fontSize: sizes.big,
    fontFamily: family.medium,
  },
  mediumExtraBold: {
    fontSize: sizes.medium,
    fontFamily: family.extrabold,
  },
  mediumBold: {
    fontSize: sizes.medium,
    fontFamily: family.bold,
  },
  mediumDemibold: {
    fontSize: sizes.medium,
    fontFamily: family.demibold,
  },
  mediumMedium: {
    fontSize: sizes.medium,
    fontFamily: family.medium,
  },
  medium: {
    fontSize: sizes.medium,
    fontFamily: family.regular,
  },
  small: {
    fontSize: sizes.small,
    fontFamily: family.regular,
  },
  smallMedium: {
    fontSize: sizes.small,
    fontFamily: family.medium,
  },
  smallish: {
    fontSize: sizes.smallish,
    fontFamily: family.regular,
  },
  smallishMedium: {
    fontSize: sizes.smallish,
    fontFamily: family.medium,
  },
  smallishDemibold: {
    fontSize: sizes.smallish,
    fontFamily: family.demibold,
  },
  smallishBold: {
    fontSize: sizes.smallish,
    fontFamily: family.bold,
  },
  bold: {
    fontSize: sizes.smallish,
    fontFamily: family.bold,
  },
  smallBold: {
    fontSize: sizes.small,
    fontFamily: family.bold,
  },
  smallExtraBold: {
    fontSize: sizes.small,
    fontFamily: family.extrabold,
  },
  smallDemibold: {
    fontSize: sizes.small,
    fontFamily: family.demibold,
  },
  smallestMedium: {
    fontSize: sizes.smallest,
    fontFamily: family.medium,
  },
  smallestDemibold: {
    fontSize: sizes.smallest,
    fontFamily: family.demibold,
  },
  smallestBold: {
    fontSize: sizes.smallest,
    fontFamily: family.bold,
  },
  smallestExtrabold: {
    fontSize: sizes.smallest,
    fontFamily: family.extrabold,
  },
  bigger: {
    fontSize: sizes.bigger,
    fontFamily: family.regular,
  },
  biggerBold: {
    fontSize: sizes.bigger,
    fontFamily: family.bold,
  },
  biggerDemibold: {
    fontSize: sizes.bigger,
    fontFamily: family.demibold,
  },
  biggerMedium: {
    fontSize: sizes.bigger,
    fontFamily: family.medium,
  },
  header22Medium: {
    fontSize: sizes.header22,
    fontFamily: family.medium,
  },
  header22Bold: {
    fontSize: sizes.header22,
    fontFamily: family.bold,
  },
  header22Demibold: {
    fontSize: sizes.header22,
    fontFamily: family.demibold,
  },
  headerSmallBold: {
    fontSize: sizes.headerSmall,
    fontFamily: family.bold,
  },
  headerSmallDemibold: {
    fontSize: sizes.headerSmall,
    fontFamily: family.demibold,
  },
  extraSmallExtrabold: {
    fontSize: sizes.extraSmall,
    fontFamily: family.extrabold,
  },
  small8Extrabold: {
    fontSize: sizes.small8,
    fontFamily: family.extrabold,
  },
  extraSmallDemibold: {
    fontSize: sizes.extraSmall,
    fontFamily: family.demibold,
  },
  extraSmallMedium: {
    fontSize: sizes.extraSmall,
    fontFamily: family.medium,
  },
}

export default {
  family,
  sizes,
  ...base,
}
