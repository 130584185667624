import React, { useMemo, useState } from "react"

import OutsideClickHandler from "react-outside-click-handler"
import { connect } from "react-redux"

import { selectCalendarEmails } from "@selectors/calendar"
import { images } from "@utils/imageUtils"

import { CalendarsList } from "../molecules"

import {
  Calendar,
  CalendarIcon,
  CalendarMore,
  CalendarText,
} from "./participantsListStyles"

const CalendarLabel = ({ zones, calendars }) => {
  const [isOpenedList, setIsOpenedList] = useState(false)
  const calendarLabel =
    calendars.length > 0 ? calendars[0].summary : "My calendar"
  const isFewCalendars = calendars.length > 1

  const myTimezone = useMemo(() => {
    return zones.find(zone => zone.isMyTZ)
  }, [zones])

  return (
    <>
      {myTimezone && (
        <OutsideClickHandler onOutsideClick={() => setIsOpenedList(false)}>
          <Calendar>
            <CalendarIcon src={images.calendarDay} alt="calendar" />

            <CalendarText>{calendarLabel}</CalendarText>

            {isFewCalendars && (
              <CalendarMore onClick={() => setIsOpenedList(!isOpenedList)}>
                +{calendars.length - 1}
              </CalendarMore>
            )}

            {isOpenedList && <CalendarsList calendars={calendars} />}
          </Calendar>
        </OutsideClickHandler>
      )}
    </>
  )
}

const mapStateToProps = state => {
  const calendarEmails = selectCalendarEmails(state)

  return {
    calendars: calendarEmails.filter(calendar => calendar.primary),
  }
}

export default connect(mapStateToProps)(CalendarLabel)
