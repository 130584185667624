export const CONTENT_TYPES = {
  DEFAULT: "DEFAULT",
  COMMENTS: "COMMENTS",
  REACTIONS: "REACTIONS",
  REPLIES: "REPLIES",
  TIMEZONES: "TIMEZONES",
  SUGGESTIONS: "SUGGESTIONS",
}

export const contentActionsList = [
  { text: "Comments", type: CONTENT_TYPES.COMMENTS },
  { text: "Reactions", type: CONTENT_TYPES.REACTIONS },
  { text: "Timezones", type: CONTENT_TYPES.TIMEZONES },
]
