import React from "react"

const TimePickerFooter = ({
  setIsTimePickerOpened,
  handleTimePick,
  chosenStartHour,
  chosenStartDayPart,
  chosenStartMinute,
  chosenDurationHour,
  chosenDurationMinute,
}) => {
  const formattedChosenStartHour =
    chosenStartDayPart === "AM"
      ? chosenStartHour == 12
        ? 0
        : +chosenStartHour
      : chosenStartHour == 12
      ? 12
      : +chosenStartHour + 12
  const formattedDuration = +(chosenDurationHour * 60 + chosenDurationMinute)

  const handlePick = e => {
    handleTimePick(
      e,
      formattedChosenStartHour,
      +chosenStartMinute,
      formattedDuration
    )
    setIsTimePickerOpened(false)
  }

  return (
    <div className="time-picker-footer__container">
      <button
        className="time-picker-footer__button"
        style={{ color: "#f42862" }}
        onClick={() => setIsTimePickerOpened(false)}>
        Cancel
      </button>
      <button
        className="time-picker-footer__button pink--button"
        onClick={e => handlePick(e)}>
        Apply
      </button>
    </div>
  )
}

export default TimePickerFooter
