import {
  formatDateTh,
  getLocaleTime,
  stringToDateFormat,
  getDateWithTimezone,
} from "@utils/dateUtils"
import { apps } from "constants/apps"
import dayjs from "utils/dayjs"

const formatDate = (date, isDecided, isTeeup) => {
  const dateFormat = isTeeup ? "ddd, MMM D" : "MMM. D"

  return isDecided ? formatDateTh(date) : date.format(dateFormat)
}

export const getWhenSuggestionInfo = (suggestion, isTeeup = false) => {
  const { startDate, endDate, isDecided } = suggestion
  const format = isTeeup ? "ddd, MMM D" : "MMM. D"
  const startDateFormatted = stringToDateFormat(startDate, format)
  const endDateFormatted = stringToDateFormat(endDate, format)
  const isSameDate = dayjs(startDateFormatted).isSame(endDateFormatted, "date")

  const date = isSameDate
    ? startDateFormatted
    : `${startDateFormatted} - ${endDateFormatted}`

  const startFormatted = getLocaleTime(startDate)
  const endFormatted = getLocaleTime(endDate)
  const time = `${startFormatted} - ${endFormatted}`

  return {
    img: apps.find(app => app.title === "when")?.image,
    value: date,
    details: isDecided || isTeeup ? time : `(${time})`,
  }
}
