import { useEffect, useRef } from "react"

export const useTeeupRedirect = (userInfo, history) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const teeupIdPath = sessionStorage.getItem("teeupIdPath")
      const locationPath = window.location.pathname

      if (userInfo.user && teeupIdPath) {
        history.push(teeupIdPath)
        if (locationPath === teeupIdPath) {
          sessionStorage.removeItem("teeupIdPath")
        }
      } else {
        if (!userInfo.user && locationPath.includes("/teeUps/")) {
          sessionStorage.setItem("teeupIdPath", locationPath)
        }
      }
    }
  })
}
