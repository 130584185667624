import React from "react"

import { useDispatch, useSelector } from "react-redux"

import { removeSelectedContact } from "@actions/inviteesActions"
import { ContactAvatar } from "@components/common"
import { selectSelectedContacts } from "@selectors/invitees"
import { getIsEmail } from "@utils/getIsEmail"

import { List, ListItem, Label, Remove } from "./selectedContactsListStyles"

export const SelectedContactsList = () => {
  const dispatch = useDispatch()
  const selectedContacts = useSelector(selectSelectedContacts)

  const getContactLabel = contact => {
    return (
      (!getIsEmail(contact.matchingContact) && contact.matchingContact) ||
      contact.name ||
      contact.matchingContact
    )
  }

  const handleRemove = id => {
    dispatch(removeSelectedContact(id))
  }

  return (
    <List>
      {selectedContacts.map(contact => (
        <ListItem key={contact.id}>
          <ContactAvatar
            src={contact.avatar}
            text={contact.name || contact.matchingContact}
            avatar={contact.avatar}
            size={24}
          />

          <Label>{getContactLabel(contact)}</Label>

          <Remove
            name="cross"
            color="var(--gray4)"
            onClick={() => handleRemove(contact.id)}
          />
        </ListItem>
      ))}
    </List>
  )
}
