import { createSelector } from "reselect"

export const selectPromptsState = state => state.prompts

export const selectIgnoredPrompts = createSelector(
  selectPromptsState,
  prompts => prompts.ignored
)
export const selectRespondedPrompts = createSelector(
  selectPromptsState,
  prompts => prompts.responded
)

export const selectMentionsPrompts = createSelector(
  selectPromptsState,
  prompts => prompts.mentions
)
