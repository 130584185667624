import { StyleSheet } from "react-native"

import { AppColors, AppFonts, AppSizes, Units } from "@theme"

export const AvatarStyles = StyleSheet.create({
  container: {},
  roundedContainer: {
    overflow: "hidden",
    borderRadius: AppSizes.borderRadius,
    width: AppSizes.borderRadius * 2,
    height: AppSizes.borderRadius * 2,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  imageContainer: {
    borderRadius: AppSizes.borderRadius,
    width: AppSizes.borderRadius * 2,
    height: AppSizes.borderRadius * 2,
    overflow: "hidden",
  },
  hiddenCountContainer: {
    position: "absolute",
    borderRadius: AppSizes.borderRadius,
    width: AppSizes.borderRadius * 2,
    height: AppSizes.borderRadius * 2,
    overflow: "hidden",
    backgroundColor: AppColors.brand.black40,
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenCount: {
    fontFamily: AppFonts.family.bold,
    fontSize: AppFonts.sizes.bigger,
    color: AppColors.brand.white,
  },
  imageContainerText: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.lightBlue,
  },
  imageContainerTextDisabled: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.warmGrey2,
  },
  avatar: {
    width: null,
    height: null,
    flex: 1,
    // backgroundColor: AppColors.brand.warmGrey2,
  },
  initials: {
    ...AppFonts.smallDemibold,
    color: "black",
  },
  absolute: {
    position: "absolute",
  },
  youRibbon: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    paddingBottom: 2,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: AppColors.brand.pink70,
  },
  youRibbonDisabled: {
    backgroundColor: AppColors.brand.darkGrey2_75,
  },
  youRibbonText: {
    color: AppColors.brand.white,
    ...AppFonts.smallDemibold,
    fontSize: 10,
  },
  followingRibbon: {
    left: -4,
    bottom: 0,
  },
})

export const UserStatusAccesoryStyles = {
  accesory: () => ({
    position: "absolute",
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderColor: AppColors.brand.white,
    borderWidth: 2,
  }),
  disabledAccesory: {
    backgroundColor: AppColors.brand.darkGrey2,
  },
  additionalCount: {
    color: AppColors.brand.white,
  },
  sizes: {
    small: {
      accesory: useAdditionalCount => ({
        height: Units.responsiveValue(20),
        width: useAdditionalCount
          ? Units.responsiveValue(32)
          : Units.responsiveValue(20),
        borderRadius: Units.responsiveValue(10),
      }),
      accesoryImage: useAdditionalCount => ({
        height: useAdditionalCount
          ? Units.responsiveValue(11)
          : Units.responsiveValue(16),
        width: useAdditionalCount
          ? Units.responsiveValue(11)
          : Units.responsiveValue(16),
      }),
      additionalCountContainer: {
        minWidth: Units.responsiveValue(11),
      },
      additionalCount: {
        ...AppFonts.extraSmallBold,
      },
    },
    medium: {
      accesory: useAdditionalCount => ({
        height: Units.responsiveValue(32),
        width: useAdditionalCount
          ? Units.responsiveValue(56)
          : Units.responsiveValue(32),
        borderRadius: Units.responsiveValue(16),
      }),
      accesoryImage: () => ({
        height: Units.responsiveValue(18),
        width: Units.responsiveValue(18),
      }),
      additionalCountContainer: {
        minWidth: Units.responsiveValue(18),
        marginRight: 1,
      },
      additionalCount: {
        ...AppFonts.biggerBold,
      },
    },
  },
}

export const PollingAccesoryStyles = StyleSheet.create({
  accesory: {
    position: "absolute",
    right: 0,
  },
})

export const CustomAccesoryStyles = StyleSheet.create({
  accesory: {
    position: "absolute",
    right: 0,
  },
})
