import React, { useContext } from "react"

import { Link } from "react-router-dom"

import PinkCooweLogo from "@assets/images/CooWePinkLogo.svg"
import WhiteCooweLogo from "@assets/images/CooWeWhiteLogo.svg"
import NonUser from "@assets/images/non-user.svg"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import {
  Header,
  HeaderLogo,
  HeaderText,
  HeaderAvatar,
} from "../teeupPreviewStyles"

export const PreviewHeader = () => {
  const { isAuthenticated, userInfo } = useContext(TeeupPreviewContext)

  return (
    <Header>
      <Link to="/">
        <HeaderLogo $pinkLogo={PinkCooweLogo} $whiteLogo={WhiteCooweLogo} />
      </Link>

      {isAuthenticated ? (
        <HeaderAvatar src={userInfo.avatar || NonUser} alt="avatar" />
      ) : (
        <HeaderText>Make Meetings Happen</HeaderText>
      )}
    </Header>
  )
}
