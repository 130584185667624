import { useMemo } from "react"

import moment from "moment"

export const useSuggestionValues = ({
  dateLabel,
  zones,
  startDate,
  endDate,
}) => {
  const getFormattedDate = date => moment(date).format("hh:mm A")

  return useMemo(() => {
    if (dateLabel === "Invalid date") return
    const line1 = dateLabel
    const line2 = zones[0]?.time

    return {
      line1,
      line2,
      full: `${line1}, ${line2}`,
      empty: false,
      placeholder1: "Date",
      placeholder2: "Time",
      placeholder: "Suggest When",
      isOnlyStartTime: true,
      startTimeFormatted: getFormattedDate(startDate),
      endTimeFormatted: getFormattedDate(endDate),
    }
  }, [dateLabel, zones])
}
