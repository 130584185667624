import { linkSyncContacts } from "@actions/contactActions"
import { loginProviders, loginProvidersText } from "@config/enums"

import { SYNC_CONTACTS_MODAL } from "../constants"

export const useSyncGoogle = ({
  setIsGoogleLoading,
  getAccessToken,
  setConnectorEmail,
  setModalType,
  setConnectorType,
  showSyncingError,
}) => {
  return async () => {
    setIsGoogleLoading(true)

    const accessToken = await getAccessToken({
      connection: loginProviders.google,
      connection_scope: "https://www.googleapis.com/auth/contacts",
    })

    if (!accessToken) return

    try {
      const linkedConnector = await linkSyncContacts(
        accessToken,
        loginProviders.google
      )
      setConnectorEmail(linkedConnector.email)
      if (linkedConnector.isTryAnother) {
        setModalType(SYNC_CONTACTS_MODAL.ALREADY_ADDED)
      } else {
        setModalType(SYNC_CONTACTS_MODAL.SUCCESSFULLY_ADDED)
        setConnectorType(loginProvidersText.google)
      }
      setIsGoogleLoading(false)
    } catch ({ data }) {
      showSyncingError(data.code)
    }
  }
}
