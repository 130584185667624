import styled from "styled-components"

export const ClearWrapper = styled.div`
  background-color: var(--lightGray);
  border: 1px solid var(--gray3);
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`
