import React from "react"

import { ParticipantsInfo } from "../atoms"

import { List } from "./participantsListStyles"

export const ParticipantsList = ({ zones }) => (
  <List>
    {zones.map((zone, zoneIndex) => (
      <ParticipantsInfo
        zone={zone}
        key={`info-${zone.timezoneName}_${zoneIndex}`}
      />
    ))}
  </List>
)
