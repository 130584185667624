import React from "react"

import { connect } from "react-redux"

import { SvgIcon } from "@components/common"
import { selectGuestSuggestions, selectGuestReactions } from "@selectors/guest"

import {
  StepperHeader,
  SuggestionsListActions,
  SuggestionsFullList,
  ReactionsSubmit,
} from "../molecules"
import { InvitesSuggest } from "components"

import {
  Wrapper,
  ContentWrapper,
  MakeSuggestionButton,
} from "./suggestionsScreenStyles"

const SuggestionsScreen = ({
  guestSuggestions,
  guestReactions,
  setIsMakeSuggestion,
}) => {
  const reactionsAmount = Object.keys(guestReactions).length

  const renderContent = () => {
    return guestSuggestions.length === 0 ? (
      <InvitesSuggest isFullWidth isOnlySuggest />
    ) : (
      <SuggestionsFullList />
    )
  }

  return (
    <Wrapper>
      <StepperHeader
        goBackText="Share your meeting preference"
        subtitle="React to suggestions, or make your own!"
      />

      {/* <Title>Share When Works for You</Title>

      <Description>
        React to suggestions, or make your own suggestion!
      </Description> */}

      <SuggestionsListActions isFullList />

      <ContentWrapper>
        <MakeSuggestionButton onClick={() => setIsMakeSuggestion(true)}>
          <SvgIcon name="plus-symbol" color="var(--gray5)" />
          Make a Suggestion
        </MakeSuggestionButton>

        {renderContent()}

        {reactionsAmount > 0 && (
          <ReactionsSubmit reactionsAmount={reactionsAmount} />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  guestSuggestions: selectGuestSuggestions(state),
  guestReactions: selectGuestReactions(state),
})

export default connect(mapStateToProps)(SuggestionsScreen)
