import React from "react"

import ReactTooltip from "react-tooltip"

export const NewTooltip = () => (
  <ReactTooltip
    border
    effect="solid"
    borderColor="var(--gray2)"
    backgroundColor="#fff"
    textColor="var(--black)"
  />
)
