import React from "react"

import classnames from "classnames"

import "./quickSelect.scss"
import moment from "moment-timezone"

import useGeneralTimeTab from "../GeneralTimeContext/useGeneralTimeTab"

// TODO find out does this exist somewhere, or is it being fetched. in the mean time for development leave it as is.
const currentDate = new Date()

const getOneDayPeriod = date => {
  const result = []
  for (let i = 0; i < 2; i++) {
    result.push(date)
  }

  return result
}

const getDatesPeriod = (date, frame) => {
  const start = date.clone().startOf(frame)
  const end = date.clone().endOf(frame)

  return [start.toDate(), end.toDate()]
}

const getWeekends = date => {
  const start = date.clone().weekday(5)
  const end = date.clone().weekday(6)

  return [start.toDate(), end.toDate()]
}

const QUICK_SELECT_OPTIONS = [
  {
    id: "today",
    title: "Today",
    date: getOneDayPeriod(moment(currentDate).toDate()),
  },
  {
    id: "tomorrow",
    title: "Tomorrow",
    date: getOneDayPeriod(moment(currentDate).add(1, "day").toDate()),
  },
  {
    id: "this-weekend",
    title: "This Weekend",
    date: getWeekends(moment(currentDate)),
  },
  {
    id: "next-weekend",
    title: "Next Weekend",
    date: getWeekends(moment(currentDate).add(1, "week")),
  },
  {
    id: "this-week",
    title: "This Week",
    date: getDatesPeriod(moment(currentDate), "week"),
  },
  {
    id: "next-week",
    title: "Next Week",
    date: getDatesPeriod(moment(currentDate).add(1, "week"), "week"),
  },
  {
    id: "this-month",
    title: "This Month",
    date: getDatesPeriod(moment(currentDate), "month"),
  },
  {
    id: "next-month",
    title: "Next Month",
    date: getDatesPeriod(moment(currentDate).add(1, "month"), "month"),
  },
]

const QuickSelect = () => {
  const { quickSelect, setQuickSelect } = useGeneralTimeTab()

  return (
    <div className="quick-select">
      {QUICK_SELECT_OPTIONS.map(option => (
        <div
          key={option.id}
          className={classnames("quick-select__option", {
            selected: quickSelect?.id === option.id,
          })}
          onClick={() => setQuickSelect(option)}>
          {option.title}
        </div>
      ))}
    </div>
  )
}

export default QuickSelect
