import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

const PARAM_KEY = "isDone"

export const useDoneApproved = isStatusConfirmedModal => {
  const [isDoneModal, setIsDoneModal] = useState(false)
  const history = useHistory()

  const urlSearch = new URLSearchParams(window.location.search)

  const deleteUrlParam = () => {
    urlSearch.delete(PARAM_KEY)
    history.replace({
      search: urlSearch.toString(),
    })
  }

  useEffect(() => {
    setIsDoneModal(urlSearch.has(PARAM_KEY) && !isStatusConfirmedModal)
    deleteUrlParam()
  }, [isStatusConfirmedModal])

  return {
    isDoneModal,
    closeDoneModal: () => setIsDoneModal(false),
  }
}
