import PropTypes from "prop-types"

import { HeaderTitle, DecidedLabel } from "../createTeeupGameplanStyles"

export const Header = ({ title, isDecided, suggestionsLength }) => {
  return (
    <HeaderTitle>
      {title} {isDecided && <DecidedLabel>· Decided</DecidedLabel>}
      {!isDecided && suggestionsLength > 0 && (
        <>
          ·{" "}
          <HeaderTitle $color="var(--darkBlue)">
            {suggestionsLength}{" "}
            {suggestionsLength > 1 ? "suggestions" : "suggestion"}
          </HeaderTitle>
        </>
      )}
    </HeaderTitle>
  )
}

Header.propTypes = {
  gameplanData: PropTypes.object,
}
