import { useContext } from "react"

import { useSelector } from "react-redux"

import { selectActiveTeeup } from "@selectors/activeTeeup"
import { showAiFeature } from "@utils/showAiFeature"
import { isOrganiser as getIsOrganiser } from "@utils/teeupUtils"
import { NewTeeupPageContext } from "contexts/NewTeeupPageContext"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import { logScreen, SCREENS } from "../../../analytics-tracking"
import { useTeeUpPage } from "../../../pages/TeeUpPage/TeeUpPageContext"

export const useActions = ({ activeTeeupSettings, userId, activeTeeup }) => {
  const { openSuggestWhenAiModal } = useTeeUpPage()
  // const { openSuggestWhenModal, openSuggestWhereModal } = useTeeUpPage()

  const isOrganiser = getIsOrganiser(userId, activeTeeup.organisers)
  const { setIsShareTeeUpLinkModalOpen, setIsInvitePeopleOpen } =
    useContext(NewTeeupPageContext)
  const settings = useSelector(selectActiveTeeup)?.settings
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )

  const aiFeature = showAiFeature(userId)
    ? [
        {
          id: "ai_suggest",
          title: "AI suggest when",
          iconName: "clock-2",
          testId: "aiSuggestWhenQuickActions",
          isDisabled: !settings?.allowChangeGameplanOptions,
          onClick: () => {
            openSuggestWhenAiModal()
            logScreen(SCREENS.SUGGEST_WHEN)
          },
        },
      ]
    : []

  return [
    ...aiFeature,
    {
      id: "suggest_when",
      title: "Suggest when",
      iconName: "clock-2",
      testId: "suggestWhenQuickActions",
      isDisabled: !settings?.allowChangeGameplanOptions,
      onClick: () => {
        setIsWhenModalOpen(true)
        logScreen(SCREENS.SUGGEST_WHERE)
      },
    },
    {
      id: "suggest_where",
      title: "Suggest where",
      iconName: "location-2",
      testId: "suggestWhereQuickActions",
      isDisabled: !settings?.allowChangeGameplanOptions,
      onClick: () => {
        setIsWhereModalOpen(true)
        logScreen(SCREENS.SUGGEST_WHEN)
      },
    },
    // {
    //   id: "view_timezones",
    //   title: "View timezones",
    //   iconName: "planet",
    //   testId: "viewTimezones",
    //   isDisabled: true,
    //   onClick: () => {},
    // },
    {
      id: "add_people",
      title: "Invite people",
      iconName: "people-2",
      testId: "addPeopleQuickActions",
      isDisabled: !activeTeeupSettings?.allowInviteOthers && !isOrganiser,
      tooltip: "Only organizer is able to invite people",
      onClick: () => setIsInvitePeopleOpen(true),
    },
    {
      id: "share-link",
      title: "Share link",
      iconName: "share",
      testId: "shareLink",
      onClick: () => setIsShareTeeUpLinkModalOpen(true),
    },
  ]
}
