import React from "react"

import { Title6, Caption } from "@assets/styles/typographyStyles"

export const SectionDescription = () => (
  <>
    <Title6>Linked Contacts</Title6>

    <Caption style={{ color: "#767676", paddingLeft: 17, marginTop: 10 }}>
      To make it easier to invite people to TeeUp you can connect your Google
      Contacts.
    </Caption>
  </>
)
