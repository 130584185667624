import React from "react"

import PropTypes from "prop-types"

import { Timezone } from "./reactionTimezoneStyles"

export const ReactionTimezone = ({ reaction }) => (
  <Timezone>
    {reaction.timezone} · {reaction.timezoneUTC}
  </Timezone>
)

ReactionTimezone.propTypes = {
  reaction: PropTypes.object,
}
