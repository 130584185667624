import styled, { css } from "styled-components"

import { SvgIcon } from "../SvgIcon"

export const Button = styled.button`
  ${({ $disabled }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    ${$disabled &&
    css`
      cursor: default;
      opacity: 0.4;
    `}
  `}
`

export const SvgEl = styled(SvgIcon)`
  ${({ $size }) => css`
    &:active {
      opacity: 0.4;
    }

    ${$size &&
    css`
      width: ${$size}px;
      height: ${$size}px;
    `};
  `};
`
