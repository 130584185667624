import React from "react"

import { useSelector } from "react-redux"

import { selectUserTimezone } from "@selectors/user"
import { getTimezoneAbbreviation } from "@utils/dateUtils"
import { getTimeListOptions } from "@utils/suggestionUtils"
import { Suggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  let formattedDuration = ""

  if (hours > 0) {
    formattedDuration += hours === 1 ? "1 hour" : hours + " hours"
    if (minutes > 0) {
      formattedDuration += " "
    }
  }

  if (minutes > 0) {
    formattedDuration += minutes === 1 ? "1 minute" : minutes + " minutes"
  }

  return formattedDuration.includes("24") ? "All day" : formattedDuration
}

interface Props {
  suggestion: Suggestion
  title: string
  subtitle: string
}

const DefaultDetails = ({ suggestion, title, subtitle }: Props) => {
  const isTimeWheel = !suggestion.isCustomDate && suggestion.type === "when"
  const isGeneralSuggestion =
    suggestion.isCustomDate && suggestion.type === "when"
  const userTimezone = useSelector(selectUserTimezone)

  const formattedDateStart = dayjs(suggestion.startDate)
    .tz(userTimezone)
    .format("dddd, MMMM Do, YYYY")
  const formattedDateEnd = dayjs(suggestion.endDate)
    .tz(userTimezone)
    .format("dddd, MMMM Do, YYYY")
  const isSame = dayjs(suggestion.startDate).isSame(
    dayjs(suggestion.endDate),
    "day"
  )
  const formattedTimeStart = dayjs(suggestion.startDate).format("h:mma")
  const formattedTimeEnd = dayjs(suggestion.endDate).format("h:mma")

  const defaultTime = getTimeListOptions().find(item => item.label === subtitle)
  const timeToDisplay = defaultTime?.timeRange
    ? `${defaultTime.timeRange}`
    : `${formattedTimeStart} - ${formattedTimeEnd}`

  const timezoneAbr = getTimezoneAbbreviation(userTimezone)

  const isAsap = suggestion.value === "ASAP"

  return (
    <div className="meeting-details">
      <div className="meeting-details__block">
        <h5>Date</h5>
        {isTimeWheel && <p>{formattedDateStart}</p>}
        {isGeneralSuggestion && (
          <p>
            {isSame
              ? formattedDateStart
              : formattedDateStart + " - " + formattedDateEnd}
          </p>
        )}
        {!isTimeWheel && !isGeneralSuggestion && (
          <p>
            {title
              ? `${title} (${formattedDateStart} - ${formattedDateEnd})`
              : `${formattedDateStart} - ${formattedDateEnd}`}
          </p>
        )}
      </div>

      <div className="meeting-details__block">
        <h5>Time</h5>
        {isTimeWheel && (
          <p>
            {subtitle} ({timezoneAbr})
          </p>
        )}
        {!isTimeWheel && !isAsap && (
          <p>
            {subtitle
              ? `${subtitle} (${timeToDisplay} ${timezoneAbr})`
              : `${timeToDisplay} (${timezoneAbr})`}
          </p>
        )}
        {!isTimeWheel && isAsap && (
          <p>
            {subtitle ? (
              `${subtitle} (${timezoneAbr})`
            ) : (
              <span className="secondary_text">No General Time Specified</span>
            )}
          </p>
        )}
      </div>

      {suggestion?.duration && (
        <div className="meeting-details__block">
          <h5>Duration</h5>
          <p>{formatDuration(suggestion?.duration)}</p>
        </div>
      )}
    </div>
  )
}

export default DefaultDetails
