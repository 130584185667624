import styled from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 10px;
`

export const Text = styled(NSubtitle)`
  color: var(--gray5);
  font-weight: ${NFontWeight.medium};
`

export const Icon = styled(SvgIcon)`
  width: 44px;
  height: 44px;
`
