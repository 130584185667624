import React from "react"

import { getIsOddIndex } from "@utils/getIsOddIndex"

import { Title6, CaptionS } from "../../../assets/styles/typographyStyles"
import { INFO_LIST } from "../constants"
import { Block, BlockLeft, BlockImg } from "../whiteCooweOverviewStyles"

export const InfoBlock = () => (
  <>
    {INFO_LIST.map((item, itemIndex) => {
      const isOdd = getIsOddIndex(itemIndex)

      return (
        <Block isOdd={isOdd} isFirst={itemIndex === 0} key={item.name}>
          <BlockLeft isOdd={isOdd}>
            <Title6 style={{ color: "#F42862", marginBottom: 10 }}>
              {item.title}
            </Title6>

            <CaptionS style={{ paddingRight: 10 }}>{item.description}</CaptionS>
          </BlockLeft>

          <BlockImg isOdd={isOdd} src={item.image} alt={item.name} />
        </Block>
      )
    })}
  </>
)
