import { create } from "zustand"

interface CreateTeeupStore {
  isConfirmCreateTeeupPopupOpen: boolean
  setIsConfirmCreateTeeupPopupOpen: (flag: boolean) => void
  isInviteesError: boolean
  setIsInviteesError: (flag: boolean) => void
}

export const useCreateTeeupStore = create<CreateTeeupStore>()(set => ({
  isConfirmCreateTeeupPopupOpen: false,
  setIsConfirmCreateTeeupPopupOpen: flag =>
    set({ isConfirmCreateTeeupPopupOpen: flag }),

  isInviteesError: false,
  setIsInviteesError: flag => set({ isInviteesError: flag }),
}))
