import styled from "styled-components"

export const Title = styled.h4`
  font-family: "greycliffcf-bold-salt";
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #222;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const CrossButton = styled.div`
  cursor: pointer;
  margin-top: 6px;

  &:hover svg {
    color: #222 !important;
  }
`
