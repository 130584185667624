import React from "react"

import PropTypes from "prop-types"

import WarningIcon from "../../../assets/images/warning-icon.svg"
import { Subtitle } from "../../../assets/styles/typographyStyles"
import { Warning, WarningImg } from "../teeupPreviewContentStyles"

export const ContactWarning = ({ message }) => (
  <Warning>
    <WarningImg src={WarningIcon} alt="warning" />
    <Subtitle style={{ color: "#F59E0B" }}>{message}</Subtitle>
  </Warning>
)

ContactWarning.propTypes = {
  message: PropTypes.string,
}
