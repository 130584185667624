import React from "react"

import PropTypes from "prop-types"

import { NewModal } from "@components"

export const UnlinkCalendarPopup = ({
  closeUnlinkPopup,
  unlinkCalendarHandle,
}) => (
  <NewModal
    isOpen
    isBlackBtn
    isHeader={false}
    iconName="shadow-calendar"
    title="Disconnect Calendar"
    description="Are you sure to disconnect your account from the calendar?"
    cancelText="Close"
    confirmText="Disconnect"
    onClose={closeUnlinkPopup}
    onCancel={closeUnlinkPopup}
    onConfirm={unlinkCalendarHandle}
  />
)

UnlinkCalendarPopup.propTypes = {
  closeUnlinkPopup: PropTypes.func,
  unlinkCalendarHandle: PropTypes.func,
}
