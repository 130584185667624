import React, { useContext } from "react"

import { connect } from "react-redux"

import { SuggestionsHorizontalList } from "@components/common"
import { selectGuestReactions, selectGuestSuggestions } from "@selectors/guest"

import { EventsBlockHeadline } from "../atoms"
import { useReactedSuggestions, useCreatedSuggestions } from "../hooks"
import { StepperHeader, ConfirmationScreenButtons } from "../molecules"

import { Wrapper, Content, EventsBlock } from "./confirmationScreenStyles"

const ConfirmationScreen = ({
  guestSuggestions,
  guestReactions,
  statusModalTrigger,
}) => {
  const reactedSuggestions = useReactedSuggestions({
    guestReactions,
    guestSuggestions,
  })
  const createdSuggestions = useCreatedSuggestions(guestSuggestions)
  const isSubmitDisabled =
    reactedSuggestions.length === 0 &&
    createdSuggestions.length === 0 &&
    !statusModalTrigger

  return (
    <Wrapper>
      <StepperHeader
        goBackText="Confirm your choices"
        subtitle="This is a summary of your reactions and/or suggestions."
      />

      <Content>
        <EventsBlock $isBorderTop>
          <EventsBlockHeadline
            text="You made"
            amount={createdSuggestions.length}
          />

          {createdSuggestions?.length > 0 && (
            <SuggestionsHorizontalList list={createdSuggestions} />
          )}
        </EventsBlock>

        <EventsBlock>
          <EventsBlockHeadline
            text="You responded to"
            amount={reactedSuggestions.length}
          />

          {reactedSuggestions.length > 0 && (
            <SuggestionsHorizontalList isReacted list={reactedSuggestions} />
          )}
        </EventsBlock>

        <ConfirmationScreenButtons isSubmitDisabled={isSubmitDisabled} />
      </Content>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  guestSuggestions: selectGuestSuggestions(state),
  guestReactions: selectGuestReactions(state),
})

export default connect(mapStateToProps)(ConfirmationScreen)
