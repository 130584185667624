import React, { useContext, useMemo } from "react"

import { Caption } from "@assets/styles/typographyStyles"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { CalendarSettingsContext } from "@contexts/CalendarSettingsContext"

import { ButtonsList, ButtonsListItem } from "./linkingListStyles"

export const LinkingList = () => {
  const {
    isGoogleLoading,
    isOutlookLoading,
    googlePrimaryCalendar,
    outlookPrimaryCalendar,
    handleAddGoogleCalendar,
    handleAddMSCalendar,
  } = useContext(CalendarSettingsContext)

  const buttonsParams = useMemo(() => {
    return [
      {
        text: "Link Google calendar",
        iconName: "google",
        isLoading: isGoogleLoading,
        isDisabled: isGoogleLoading || !!googlePrimaryCalendar,
        action: e => handleAddGoogleCalendar(e),
      },
      {
        text: "Link Office 365 calendar",
        iconName: "outlook",
        isLoading: isOutlookLoading,
        isDisabled: isOutlookLoading || !!outlookPrimaryCalendar,
        action: e => handleAddMSCalendar(e),
      },
    ]
  }, [
    isGoogleLoading,
    isOutlookLoading,
    googlePrimaryCalendar,
    outlookPrimaryCalendar,
  ])

  return (
    <>
      <Caption>
        Link a calendar to enable TeeUps to be added as events and updated
        automatically as changes are made.
      </Caption>

      <ButtonsList>
        {buttonsParams.map((button, buttonIndex) => (
          <ButtonsListItem key={buttonIndex}>
            <ButtonEl
              fullWidth
              backgroundColor="var(--lightGray4)"
              border="1px solid var(--gray3)"
              textColor="var(--gray)"
              loadingColor="var(--gray)"
              variant={BUTTON_VARIANTS.ROUNDED}
              text={button.text}
              iconName={button.iconName}
              isLoading={button.isLoading}
              disabled={button.isDisabled}
              onClick={button.action}
            />
          </ButtonsListItem>
        ))}
      </ButtonsList>
    </>
  )
}
