import dayjs, { Dayjs } from "dayjs"

import {
  IDateRange,
  MEETING_PLATFORMS,
  Platform,
  SuggestionDuration,
  TimeListOption,
} from "types/suggestions"

// Suggestion when time wheel constants
export const BOX_SIZE = 64.5
export const DAYS_TO_DISPLAY = 3
export const HOURS_PER_DAY = 24
export const BOX_COUNT = HOURS_PER_DAY * DAYS_TO_DISPLAY
export const TIME_WHEEL_WIDTH = 840
export const TIME_WHEEL_HEIGHT = 200
export const ROW_HEIGHT = 200
export const START_OF_DAY_HOUR = 0
export const HOURS_BEFORE_CURRENT_HOUR = 6
export const HOUR_IN_MINUTES = 60
export const DURATION_OFFSET = 15
export const PROPORTION_BOX_SIZE = BOX_SIZE / HOUR_IN_MINUTES
export const SCROLL_DEBOUNCE_DELAY = 300

export const MAX_CREATOR_NAME_LENGTH = 30

export const URL_PATTERN = new RegExp(
  String.raw`^(https?:\/\/)?` +
    String.raw`((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|` +
    String.raw`((\d{1,3}\.){3}\d{1,3}))` +
    String.raw`(\:\d+)?(\/[-a-z\d%_.~+]*)*` +
    String.raw`(\?[;&a-z\d%_.~+=-]*)?` +
    String.raw`(\#[-a-z\d_]*)?$`,
  "i"
)

const today = dayjs()

export const roundToFuture15Minutes = (minute: number) =>
  Math.ceil(minute / 15) * 15

const getWeekends = (date: Dayjs) => {
  const dayOfWeek = date.day()

  // 3. Calculate the offset from Saturday (positive offset for weekdays)
  const offset = dayOfWeek === 0 ? 6 : dayOfWeek - 6 // 0 for Saturday, positive for weekdays

  // 4. Get this Saturday's date
  const saturday = date.subtract(offset, "day")

  // 5. Get this Sunday's date (add 1 day to Saturday)
  const sunday = saturday.add(1, "day")
  return [saturday, sunday]
}

export const getSuggestionOnlineOptions = (): Platform[] => [
  {
    id: MEETING_PLATFORMS.DISCORD,
    label: "Discord",
    iconName: "discord",
  },
  {
    id: MEETING_PLATFORMS.ZOOM,
    label: "Zoom",
    iconName: "zoom",
  },
  {
    id: MEETING_PLATFORMS.GOOGLE_MEET,
    label: "Google Meet",
    iconName: "google-meet",
  },
  {
    id: MEETING_PLATFORMS.TEAMS,
    label: "Teams",
    iconName: "teams",
  },
  {
    id: MEETING_PLATFORMS.SKYPE,
    label: "Skype",
    iconName: "skype",
  },
  {
    id: MEETING_PLATFORMS.SLACK,
    label: "Slack",
    iconName: "slack",
  },
  {
    id: MEETING_PLATFORMS.WEBEX,
    label: "WebEx",
    iconName: "webex",
  },
  {
    id: MEETING_PLATFORMS.CALL,
    label: "Call",
    iconName: "call",
  },
]

const boxConfig = {
  moon: {
    icon: "moon",
    className: "people-timezone-box-night",
    style: {
      backgroundColor: "#124CAE",
      color: "#FFFFFF",
      border: "1px solid #124CAE",
    },
  },
  sunrise: {
    icon: "sunrise",
    className: "people-timezone-box-sunrise",
    style: {
      backgroundColor: "#EDF3FD",
    },
  },
  sun: {
    icon: "sun-03",
    className: "people-timezone-box-sun",
    style: {
      backgroundColor: "#FFFFFF",
    },
  },
  sunset: {
    icon: "sunset",
    className: "people-timezone-box-sunset",
    style: {
      backgroundColor: "#EDF3FD",
    },
  },
}

export const getBoxInfo = (timeString: string) => {
  const isAm = timeString?.includes("am")
  const time = +timeString?.replace("am", "")?.replace("pm", "")

  if (
    (time >= 1 && time <= 5 && isAm) ||
    (time >= 10 && time <= 11 && !isAm) ||
    (time === 12 && isAm)
  ) {
    return boxConfig.moon
  } else if (time >= 6 && time <= 9 && isAm) {
    return boxConfig.sunrise
  } else if (
    (time >= 10 && time <= 12 && !isAm) ||
    (time >= 1 && time <= 5 && !isAm) ||
    (time === 12 && !isAm)
  ) {
    return boxConfig.sun
  } else if (time >= 6 && time <= 9 && !isAm) {
    return boxConfig.sunset
  } else {
    return boxConfig.sun
  }
}

export const getDefaultDateRanges = (): IDateRange[] => [
  {
    startDate: today.startOf("day").format(),
    endDate: today.endOf("day").format(),
    label: "ASAP",
  },
  {
    startDate: today.startOf("day").format(),
    endDate: today.endOf("day").format(),
    label: "Today",
  },
  {
    startDate: today.add(1, "day").startOf("day").format(),
    endDate: today.add(1, "day").endOf("day").format(),
    label: "Tomorrow",
  },
  {
    startDate: getWeekends(today)[0].format(),
    endDate: getWeekends(today)[1].format(),
    label: "This Weekend",
  },
  {
    startDate: getWeekends(today.add(1, "week"))[0].format(),
    endDate: getWeekends(today.add(1, "week"))[1].format(),
    label: "Next Weekend",
  },
  {
    startDate: today.startOf("day").format(),
    endDate: today.endOf("week").format(),
    label: "This Week",
  },
  {
    startDate: today.add(1, "week").startOf("week").format(),
    endDate: today.add(1, "week").endOf("week").format(),
    label: "Next Week",
  },
  {
    startDate: today.format(),
    endDate: today.endOf("month").format(),
    label: "This Month",
  },
  {
    startDate: today.add(1, "month").startOf("month").format(),
    endDate: today.add(1, "month").endOf("month").format(),
    label: "Next Month",
  },
]

export const getTimeListOptions = (): TimeListOption[] => [
  {
    label: "Sometime",
    iconName: "",
    from: 0,
    to: 23,
    timeRange: "12:00am - 11:59pm",
  },
  {
    label: "Morning",
    iconName: "sunrise",
    from: 6,
    to: 9,
    isDisabled: today.hour() >= 9,
    timeRange: "6:00am - 9:59am",
  },
  {
    label: "Afternoon",
    iconName: "sun-03",
    from: 10,
    to: 17,
    isDisabled: today.hour() >= 17,
    timeRange: "10:00am - 5:59pm",
  },
  {
    label: "Evening",
    iconName: "sunset",
    from: 18,
    to: 21,
    isDisabled: today.hour() >= 21,
    timeRange: "6:00pm - 9:59pm",
  },
  {
    label: "Night",
    iconName: "moon",
    from: 22,
    to: 5,
    isDisabled: today.hour() >= 22 && today.hour() <= 5,
    timeRange: "10:00pm - 5:59am",
  },
]

export const platformLinkPatterns = {
  [MEETING_PLATFORMS.DISCORD]: /((h|H)ttps?:\/\/)?(d|D)(iscord.com\/)+\S+/,
  [MEETING_PLATFORMS.ZOOM]:
    /((h|H)ttps?:\/\/)?(?:\w+.)?zoom.us\/(j|u)\/(\w+)(\?+\S+)?/,
  [MEETING_PLATFORMS.GOOGLE_MEET]:
    /((h|H)ttps?:\/\/)?(m|M)(eet.google.com\/)+\S+/,
  [MEETING_PLATFORMS.TEAMS]:
    /((h|H)ttps?:\/\/)?(t|T)(eams.microsoft.com\/l\/meetup-join\/)+\S+/,
  [MEETING_PLATFORMS.SKYPE]: /((h|H)ttps?:\/\/)?(j|J)(oin.skype.com\/)+\S+/,
  [MEETING_PLATFORMS.SLACK]:
    /((h|H)ttps?:\/\/)?((j|J)oin\.slack\.com\/\S+|(app\.slack\.com\/\S+))/,
  [MEETING_PLATFORMS.WEBEX]: /((h|H)ttps?:\/\/)?\w+(.webex.com\/)+\S+/,
}

// const suggestionOnlineLinksRegExp = {
//   zoom: /((h|H)ttps?:\/\/)?(?:\w+.)?(?:zoom.us\/(j|u)\/)(\w+)(\?+\S+)?/,
//   slack: /((h|H)ttps?:\/\/)?(j|J)(oin.slack.com\/)+\S+/,
//   slackApp: /((h|H)ttps?:\/\/)?(app.slack.com\/)+\S+/,
//   webEx: /((h|H)ttps?:\/\/)?(?:\w+)(.webex.com\/)+\S+/,
//   skype: /((h|H)ttps?:\/\/)?(j|J)(oin.skype.com\/)+\S+/,
//   microsoftTeams:
//     /((h|H)ttps?:\/\/)?(t|T)(eams.microsoft.com\/l\/meetup-join\/)+\S+/,
//   googleMeet: /((h|H)ttps?:\/\/)?(m|M)(eet.google.com\/)+\S+/,
//   discord: /((h|H)ttps?:\/\/)?(d|D)(iscord.gg\/)+\S+/,
//   discordCom: /((h|H)ttps?:\/\/)?(d|D)(iscord.com\/)+\S+/,
// }

export const getHoursData = () => {
  const data: SuggestionDuration[] = [
    {
      id: "1440",
      value: "All day",
    },
  ]

  for (let i = 15; i <= 8 * 60; i += 15) {
    const hours = Math.floor(i / 60)
    const minutes = i % 60
    const timeString =
      hours > 0
        ? `${hours}${minutes > 0 ? `.${minutes}` : ""} hour${
            hours > 1 ? "s" : ""
          }`
        : `${minutes} min`

    data.push({
      id: i.toString(),
      value: timeString,
    })
  }
  return data
}

export const getCoords = async (
  geocoder: any,
  placeId?: string | null,
  lat?: number,
  lng?: number
) => {
  try {
    const searchBy = placeId ? { placeId } : ({ location: { lat, lng } } as any)
    const { results } = await geocoder.geocode(searchBy)

    if (!results?.[0]) return

    const location = results[0].geometry.location
    const locLat = location.lat()
    const locLng = location.lng()

    return {
      address: results[0].formatted_address,
      lat: locLat,
      lng: locLng,
      placeId: placeId || results[0].place_id || "",
    }
  } catch (error) {
    console.error("Error getting coordinates", error)
  }
}
