import { useMemo } from "react"

export const useButtons = ({
  isGoogleLoading,
  isAppleLoading,
  handleSyncGoogleContacts,
  handleSyncAppleContacts,
}) => {
  return useMemo(
    () => [
      {
        text: "Continue with Google",
        iconName: "google",
        isLoading: isGoogleLoading,
        isDisabled: isGoogleLoading,
        action: handleSyncGoogleContacts,
      },
    ],
    [isGoogleLoading, isAppleLoading]
  )
}
