import _ from "lodash"

import actionTypes from "@actions/actionTypes"

export const initialState = {
  notifications: [], // notifications
  loading: false,
  openedFromNotification: false,
  lastSeenTimestamp: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      return _.assign({}, initialState)
    }

    case actionTypes.GET_NOTIFICATIONS: {
      let updates = { loading: true }
      return _.assign({}, state, updates)
    }

    case actionTypes.GOT_NOTIFICATIONS: {
      let notifications = action.payload

      let updates = { notifications, loading: false }

      return _.assign({}, state, updates)
    }

    case actionTypes.GET_NOTIFICATIONS_ERROR: {
      let updates = { loading: false }
      return _.assign({}, state, updates)
    }

    case actionTypes.READ_NOTIFICATION: {
      const newNotification = {
        ...action.payload,
        read: true,
      }
      const notifications = state.notifications.map(n =>
        n.id === newNotification.id ? newNotification : n
      )

      let updates = { notifications }

      return _.assign({}, state, updates)
    }

    case actionTypes.REMOVE_NOTIFICATION: {
      const notifications = state.notifications.filter(
        n => n.id !== action.payload.id
      )

      let updates = { notifications }

      return _.assign({}, state, updates)
    }

    case actionTypes.UPDATE_NOTIFICATION_LAST_SEEN: {
      let updates = {}
      if (state.notifications.length > 0) {
        updates.lastSeenTimestamp = state.notifications[0].createdat
      }

      return _.assign({}, state, updates)
    }

    default:
      return state
  }
}
