import React, { useEffect, useState } from "react"

import { useSelector } from "react-redux"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { selectMessages } from "@selectors/activeTeeup"
import { selectTeeupActivePeople } from "@selectors/teeups"
import dayjs from "utils/dayjs"

import "./index.scss"
import EmptyConversation from "@components/TeeUpsOverview/Chat/discussionCard/EmptyConversation"

const Replies = () => {
  const [replies, setReplies] = useState<any[]>([])
  const messages = useSelector(selectMessages)
  const people = useSelector(selectTeeupActivePeople)

  useEffect(() => {
    const replies = messages?.filter(
      message =>
        message.messageTypeId === 1 &&
        message.conversationTypeId === 1 &&
        !!message.suggestionId &&
        !!message.text
    )
    setReplies(replies)
  }, [])

  if (replies.length === 0)
    return (
      <EmptyConversation
        iconName="chat-icon-red"
        title="No Replies"
        description="No one has replied to this suggestion yet"
      />
    )

  return (
    <div>
      {replies.map(reply => {
        const user = people[reply.senderId]
        const time = dayjs(reply.updatedAt).tz().format("h:mm a")
        return (
          <div key={reply.id} className="reply-item">
            <div>
              <ParticipantIcon
                avatar={user.avatar}
                defaultAvatar={user.name[0]}
              />
            </div>

            <div className="reply-item-content-container">
              <div className="reply-item-info">
                <h5>{user.name}</h5>
                <p>{time}</p>
              </div>
              <div className="reply-item-content">
                <p>{reply.text}</p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Replies
