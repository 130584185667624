import React, { useContext } from "react"

import { CalendarSettingsContext } from "../../contexts/CalendarSettingsContext"

import { LinkingStatus, LinkingList, LINKING_STATUSES } from "./molecules"

const getUnlinkWarningMessage = calendar => `
  Are you sure you want to disconnect the <b>${calendar}</b> calendar? 
  If you disconnect this <b>${calendar}</b> and TeeUp events 
  in this calendar will no longer be updated.
`

export const LinkCalendarPopup = () => {
  const { linkingError, linkingSuccessMessage, unlinkCalendar } = useContext(
    CalendarSettingsContext
  )

  const renderContent = () => {
    const unlinkWarningMessage = getUnlinkWarningMessage(
      unlinkCalendar?.calendarTypeText || ""
    )

    switch (true) {
      case !!linkingError:
        return (
          <LinkingStatus type={LINKING_STATUSES.ERROR} message={linkingError} />
        )
      case !!linkingSuccessMessage:
        return (
          <LinkingStatus
            type={LINKING_STATUSES.SUCCESS}
            message={linkingSuccessMessage}
          />
        )
      case !!unlinkCalendar:
        return (
          <LinkingStatus
            type={LINKING_STATUSES.WARNING}
            message={unlinkWarningMessage}
          />
        )
      case !linkingError:
      case !linkingSuccessMessage:
      case !unlinkCalendar:
        return <LinkingList />
      default:
        return false
    }
  }

  return <div>{renderContent()}</div>
}
