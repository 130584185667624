import React, { useContext } from "react"

import { InvitePeopleContext } from "@contexts/InvitePeopleContext"

import { Wrapper, Text } from "./notFoundEmailsStyles"

export const NotFoundEmails = () => {
  const { notFoundEmails } = useContext(InvitePeopleContext)

  const emailsString = notFoundEmails.map(email => `"${email}"`).join(", ")

  return (
    <Wrapper>
      <Text>{emailsString} couldn't be added</Text>
    </Wrapper>
  )
}
