import React, { useState } from "react"

import { connect } from "react-redux"

import { IS_SHARE_POPUP_KEY } from "@constants/global"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { CustomCalendarView, ShareSuggestionsPopup } from "components"

import { BUTTONS_ACTIONS } from "../constants"
import {
  StepperHeader,
  SuggestionsListActions,
  SuggestBottom,
} from "../molecules"

import { Wrapper } from "./makeSuggestionScreenStyles"

const MakeSuggestionScreen = () => {
  const isSharePopupClosed = sessionStorage.getItem(IS_SHARE_POPUP_KEY)
  const [isSharePopup, setIsSharePopup] = useState(!isSharePopupClosed)

  return (
    <>
      {isSharePopup && (
        <ShareSuggestionsPopup setIsSharePopup={setIsSharePopup} />
      )}

      <Wrapper>
        <StepperHeader goBackText="Make a Suggestion" />

        <SuggestionsListActions actions={[BUTTONS_ACTIONS.CALENDAR_ACTION]} />

        <CustomCalendarView />

        <SuggestBottom />
      </Wrapper>
    </>
  )
}

const mapStateToProps = state => ({
  linkedCalendar: selectGuestLinkedCalendar(state),
})

export default connect(mapStateToProps)(MakeSuggestionScreen)
