import React from "react"

import PropTypes from "prop-types"

import { getFirstLetters } from "@utils/getFirstLetters"

import { AvatarImg, NameAvatar, Text } from "./contactAvatarStyles"

export const ContactAvatar = ({ src, text, size }) => {
  const renderAvatarImg = () => (
    <AvatarImg $size={size} src={src} alt="avatar" />
  )

  const renderNameAvatar = () => (
    <NameAvatar $size={size}>
      <Text>{getFirstLetters(text || "")}</Text>
    </NameAvatar>
  )

  return src ? renderAvatarImg() : renderNameAvatar()
}

ContactAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.number,
}
