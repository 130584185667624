import endpoints from "@config/endpoints"
import api from "api"
import { useEffect } from "react"
import { useInboxStore } from "stores/inboxStore"

import type { Participant } from "types/participant"

const useFetchUsers = () => {
  const setUsers = useInboxStore(state => state.setUsers)

  const prepareUserById = (allUsers: Participant[]) => {
    return Object.values(allUsers)
      .flat()
      .reduce((acc: {}, user: any) => {
        if (user?.id) {
          acc[user.id] = user
        }
        return acc
      }, {})
  }

  const fetchUsers = async () => {
    if (!api?.client) return

    try {
      const url = endpoints.user.teeupsUsers

      const response = await api.client.get(url)
      const allUsers = response?.data?.users
      if (allUsers) {
        const userById = prepareUserById(allUsers)

        setUsers(userById)
      }
    } catch (e) {
      console.log("error", e)
    } finally {
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])
}

export default useFetchUsers
