/**
 * App Theme - Colors
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */
import { Dimensions } from "react-native"

const { width } = Dimensions.get("window")

const iPhoneSEWidth = 320
const hitSlopDimentions = { top: 12, bottom: 12, left: 12, right: 12 }

export const rem = value => responsiveValue(value * 16)

export const responsiveValue = value => {
  const DEFAULT_REM = width > iPhoneSEWidth ? 1 : 0.875
  return value * DEFAULT_REM
}

// TODO: add percentage?

export const hitSlop = customSlop => {
  if (customSlop) {
    return { ...hitSlopDimentions, ...customSlop }
  }
  return hitSlopDimentions
}

export default {
  rem,
  responsiveValue,
  hitSlop,
}
