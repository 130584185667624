import React from "react"

import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { NewModal } from "components"

import { Description } from "./guestDoneModalStyles"
import { useScreenWidth } from "@hooks"

export const GuestDoneModal = ({ onClose, isStatusModalOpen }) => {
  const { isSmallSize } = useScreenWidth()
  const history = useHistory()

  const redirect = path => history.push(path)

  const goToSignIn = () => {
    onClose()
    redirect("/sign-in")
  }

  const goToSignUp = () => {
    onClose()
    redirect("/sign-up")
  }

  return (
    <NewModal
      isOpen
      isBlackBtn
      isCloseBtn
      isBottom={isSmallSize}
      isHeader={false}
      iconName="success-shadow"
      title="Done"
      confirmText="Sign Up"
      cancelText="Already have an account? Login"
      onCancel={goToSignIn}
      onClose={onClose}
      onConfirm={goToSignUp}>
      <Description>
        Your suggestions/reactions have been added to the TeeUp
        <br />
        You wil receive updates about this TeeUp via email.
      </Description>
    </NewModal>
  )
}

GuestDoneModal.propTypes = {
  onClose: PropTypes.func,
}
