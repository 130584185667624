import { StyleSheet } from "react-native"

import { AppFonts, AppColors } from "@theme"

export default StyleSheet.create({
  container: {
    width: "100%",
  },
  label: {
    ...AppFonts.bigDemibold,
    textAlign: "center",
    color: AppColors.brand.charcoalGrey,
    lineHeight: 19,
    paddingVertical: 8,
  },
  errorLabel: {
    color: AppColors.brand.pink,
  },
  inputContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputBox: {
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    boxShadow: "0 0 6px 0 rgba(82, 63, 63, 0.25)",
    elevation: 3,
    flex: 1,
    marginRight: 5,
    maxWidth: 50,
    borderWidth: 1,
    borderColor: "transparent",
    borderRadius: 6,
    backgroundColor: AppColors.brand.white,
  },
  errorInput: {
    borderColor: AppColors.brand.pink,
  },
  lastInputBox: {
    marginRight: 0,
  },
  inputText: {
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 30,
    lineHeight: 36,
    color: AppColors.brand.black,
  },
})
