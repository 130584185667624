import styled from "styled-components"

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ErrorMessageIcon = styled.img`
  margin-right: 15px;
`
