import { useEffect, useState } from "react"

import Avatar from "@mui/material/Avatar"
import AvatarGroup from "@mui/material/AvatarGroup"

import { getTeeupStatusOption } from "@utils/teeupUtils"

import "./index.scss"
import cn from "classnames"

interface Props {
  teeup: any
  teeupPeople?: any[]
  onClick?: (id: number) => void
  isFromOutside?: boolean
}

interface Status {
  label: string
  backgroundColor: string
  value: string
}

const TeeupItem = ({ teeup, teeupPeople, onClick, isFromOutside }: Props) => {
  const [status, setStatus] = useState<Status | null>(null)
  const { gameplans } = teeup
  const [when, where] = gameplans
  const suggestionsCount =
    when?.suggestions?.length + where?.suggestions?.length || 0
  const participantsCount =
    teeup?.participants?.length || teeupPeople?.length || 0
  const participants = teeup?.participants || teeupPeople || []

  useEffect(() => {
    const status = getTeeupStatusOption(teeup.status)
    if (!status) return

    setStatus(status)
  }, [teeup])

  return (
    <div
      className="my-profile-suggestion"
      onClick={() => onClick && onClick(teeup.id)}>
      <div className="my-profile-suggestion-info-container">
        <div>
          <img src={teeup.photo} alt="" />
        </div>

        <div className="my-profile-suggestion-info">
          <h2>{teeup.name}</h2>
          <p>{suggestionsCount} Suggestions</p>
          <p>{participantsCount} Participants</p>
          <div
            className={cn({
              "my-profile-suggestion-from-outside": isFromOutside,
            })}>
            <AvatarGroup max={5} className="my-profile-avatar-group">
              {participants?.map((participant: any) => (
                <Avatar
                  alt="user-avatar"
                  src={participant.avatar}
                  sx={{ width: 24, height: 24 }}
                />
              ))}
            </AvatarGroup>
          </div>
        </div>
      </div>

      {status && (
        <div
          className="my-profile-suggestion-status"
          style={{
            backgroundColor: status?.backgroundColor || "",
            color: status?.value || "",
          }}>
          {status.label}
        </div>
      )}
    </div>
  )
}

export default TeeupItem
