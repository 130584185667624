import { useState } from "react"

import { useMediaQuery } from "@mui/material"
import cn from "classnames"
import PropTypes from "prop-types"

import { SvgIcon } from "@components/common"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

import {
  Wrapper,
  Header,
  Title,
  GoIcon,
} from "./teeupOverviewBlockWrapperStyles"

import "./index.scss"

export const TeeupOverviewBlockWrapper = ({
  title,
  onClick,
  children,
  isWide,
  shouldExpand,
  isGameplanOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery(`(max-width: ${MEDIA_SIZES.MOBILE})`)
  const isArrowShown = (!isOpen && isMobile) || !!onClick

  const handleClick = e => {
    if (!isMobile || !shouldExpand) return
    e.stopPropagation()
    setIsOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <Wrapper $isWide={isWide} $isOpen={isOpen} onClick={handleClick}>
      <Header $isClick={!!onClick} onClick={onClick}>
        <Title>
          {isOpen && isMobile && (
            <SvgIcon
              name="arrow-2"
              color="var(--gray4)"
              className=""
              onClick={handleClose}
            />
          )}
          {title}
        </Title>

        {isArrowShown && (
          <SvgIcon
            name="arrow-2"
            className={cn("gameplan-arrow", { "arrow-open": isGameplanOpen })}
            color="var(--gray4)"
          />
        )}
      </Header>

      {children}
    </Wrapper>
  )
}

TeeupOverviewBlockWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  shouldExpand: PropTypes.bool,
}
