export const throttle = (func, delay) => {
  let inProgress = false
  return (...args) => {
    if (inProgress) {
      return
    }
    inProgress = true
    // func(...args) // if function needs to be invoked on first run
    setTimeout(() => {
      func(...args)
      inProgress = false
    }, delay)
  }
}
