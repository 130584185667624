import { useEffect } from "react"

import { useHistory } from "react-router-dom"

import { SIDEBAR_MENU } from "@constants/sidebar"

export const useTeeupsReloading = ({ teeupsPaginationParams, fetchTeeups }) => {
  const history = useHistory()

  const fetchNextTeeups = () => {
    fetchTeeups(teeupsPaginationParams, true)
  }

  useEffect(() => {
    const infiniteScrollTarget = document.querySelector(
      "#infinite-scroll-target"
    )

    if (infiniteScrollTarget) {
      const isScroll =
        infiniteScrollTarget.scrollHeight > infiniteScrollTarget.clientHeight
      const pathname = history.location.pathname

      if (!isScroll && pathname === SIDEBAR_MENU.INBOX.PATHNAME) {
        fetchNextTeeups()
      }
    }
  }, [teeupsPaginationParams.nextPage])

  return { fetchNextTeeups }
}
