import axios, { AxiosInstance } from "axios"
import axiosRetry from "axios-retry"
import jwtDecode from "jwt-decode"

import actionTypes from "@actions/actionTypes"
import { fetchUserInfo } from "@actions/userActions"
import endpoints from "@config/endpoints"
import { loginProviders } from "@configs/enums"
import { unixSecondsFromNow } from "@utils/dateUtils"
import { setItem, getItem, deleteAllCookies } from "@utils/localStorage"
import { reloadPage } from "@utils/reloadPage"

import { store } from "./index"

interface ApiServiceInterface {
  init(): void
  attachHeaders(headers: any): void
  createSession(userInfo: any): void
  checkSession(savedUserInfo: any): void
  updateToken(refreshToken: string): Promise<any> | void
  fallback(): void
}

class ApiService implements ApiServiceInterface {
  client: AxiosInstance | null = null
  private _retryArr = {}
  private whiteListedUrls = [endpoints.verifyPassword, endpoints.resetPassword]

  init() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })

    this.client.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config
        if (
          !this.whiteListedUrls.includes(originalRequest.url) &&
          !originalRequest.url.includes("users/passwords/verify-token") &&
          (error.response.status === 401 || error.response.status === 403) &&
          this._retryArr[originalRequest.url] !== true
        ) {
          this._retryArr[originalRequest.url] = true
          const access_token = await this.updateToken()

          if (!access_token) {
            this.fallback()
            throw error.response
          }

          axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token
          originalRequest.headers["Authorization"] = "Bearer " + access_token

          return this?.client?.request(originalRequest)
        }
        this._retryArr[originalRequest.url] = false

        throw error.response
      }
    )

    const userInfo = getItem("userInfo")
    if (userInfo) {
      this.attachHeaders({
        Authorization: `Bearer ${userInfo.accessToken}`,
      })

      this.checkSession(userInfo)
    }
  }

  attachHeaders = headers => {
    if (!this.client) return

    Object.assign(this.client.defaults.headers, headers)
  }

  createSession = userInfo => {
    setItem("userInfo", userInfo)

    this.attachHeaders({
      Authorization: `Bearer ${userInfo.accessToken}`,
    })
  }

  async checkSession(savedUserInfo) {
    if (!this.client) return

    const { accessToken, refreshToken } = savedUserInfo
    const decoded = jwtDecode(accessToken)

    const minRefreshSeconds = 60 * 60 * 10
    let expiresInSeconds = unixSecondsFromNow(decoded.exp)
    let userInfo = savedUserInfo

    const { loginType } = savedUserInfo

    if (
      loginType === loginProviders.email &&
      expiresInSeconds < minRefreshSeconds
    ) {
      // less than 10 hours

      const body = {
        refreshToken,
      }

      const response = await this.client.put(endpoints.token, body)

      userInfo = response.data
      userInfo.loginType = savedUserInfo.loginType
      this.createSession(userInfo)
    }

    store.dispatch({
      type: actionTypes.SET_USER_INFO,
      payload: userInfo,
    })

    fetchUserInfo()
  }

  updateToken = async () => {
    try {
      const userInfo = getItem("userInfo")
      if (!this?.client || !userInfo?.accessToken || !userInfo?.refreshToken)
        return

      const refreshToken = userInfo?.refreshToken
      const accessToken = userInfo?.accessToken

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip",
        Authorization: `Bearer ${accessToken}`,
      }

      const response = await this.client.put(
        endpoints.token,
        {
          refreshToken,
        },
        {
          headers,
        }
      )
      const tokens = response.data

      this.attachHeaders({
        Authorization: `Bearer ${tokens.accessToken}`,
      })
      userInfo.accessToken = tokens.accessToken
      userInfo.refreshToken = tokens.refreshToken
      setItem("userInfo", userInfo)
      return tokens.accessToken
    } catch {
      this.fallback()
    }
  }

  fallback = () => {
    localStorage.clear()
    deleteAllCookies()
    reloadPage()
  }
}

// Instance for exponential backoff
export const customCalInstance = axios.create()

axiosRetry(customCalInstance, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    error?.response?.status === 429 || error?.response?.status === 403,
})

const api = new ApiService()

export default api
