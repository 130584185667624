import React, { memo, useState } from "react"

import { Calendar, dayjsLocalizer } from "react-big-calendar"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import "react-big-calendar/lib/sass/styles.scss"
import "react-big-calendar/lib/addons/dragAndDrop/styles.css"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setNewGuestSuggestion } from "@actions/guestActions"
import { selectGuestCalendarEvents } from "@selectors/calendar"
import {
  selectGuestSuggestions,
  selectNewGuestSuggestion,
} from "@selectors/guest"
import { checkIfEventsConflict, EVENT_TYPES } from "@utils/calendarUtils"
import { usePreviewScreenStore } from "stores/previewScreen"
import dayjs from "utils/dayjs"

import { FORMATS } from "./constants"
import { Wrapper } from "./customCalendarViewStyles"
import {
  useFilteredEvents,
  useEventPropGetter,
  useCheckExistingEvents,
} from "./hooks"
import { CustomEventWrapper, CustomToolbar, CustomEvent } from "./molecules"
import { useScreenWidth } from "hooks"

import "./styles.scss"

const isSameDateTime = (time1, time2) =>
  time1.isSame(time2, "date") &&
  time1.isSame(time2, "hour") &&
  time1.isSame(time2, "minute")

const hasConflict = (suggestionTime: any, event: any) =>
  isSameDateTime(suggestionTime.start, event.start) &&
  isSameDateTime(suggestionTime.end, event.end)

const localizer = dayjsLocalizer(dayjs)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const CustomCalendarView = ({
  externalEvents,
  newGuestSuggestion,
  guestSuggestions,
  setNewGuestSuggestion,
}) => {
  const [eventsArr, setEventsArr] = useState([])
  const { isDesktop } = useScreenWidth()
  const calendarHeightStyled = isDesktop ? {} : { height: 600 }
  const currentSelectedDay = usePreviewScreenStore(
    state => state.currentSelectedDay
  )
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0

  useFilteredEvents({
    newGuestSuggestion,
    setEventsArr,
    guestSuggestions,
    externalEvents,
    currentSelectedDay,
  })

  useCheckExistingEvents({
    eventsArr,
    newGuestSuggestion,
    setNewGuestSuggestion,
  })

  const getEventPropGetter = useEventPropGetter()

  const setMinimumDurationTime = newSuggestionTime => {
    const { start: newStart, end: newEnd } = newSuggestionTime
    const newDuration = newEnd.diff(newStart, "minutes")
    if (newDuration < 15) {
      return (newSuggestionTime = {
        ...newSuggestionTime,
        end: dayjs(newStart).add(15, "minutes"),
      })
    }
  }

  const moveAndResizeEvent = ({ event, start, end }) => {
    if (!event.key) return

    const notAvailableZone = eventsArr.find(
      ({ eventType }) => eventType === EVENT_TYPES.not_avaliable
    )
    let newSuggestionTime = {
      start: dayjs(start),
      end: dayjs(end),
    }

    newSuggestionTime =
      setMinimumDurationTime(newSuggestionTime) || newSuggestionTime

    const isConflictNotAvaliable = checkIfEventsConflict(
      notAvailableZone,
      newSuggestionTime
    )

    const isConflict = (eventsArr as any[]).some(
      event =>
        hasConflict(newSuggestionTime, event) ||
        (event?.innerEvents &&
          event.innerEvents.some(innerEvent =>
            hasConflict(newSuggestionTime, innerEvent)
          ))
    )

    setNewGuestSuggestion({
      ...newSuggestionTime,
      isConflict: isConflict || isConflictNotAvaliable,
    })
  }

  const selectSlotHandle = ({ start }) => {
    // if (newGuestSuggestion.isHidden) {
    setNewGuestSuggestion({
      isHidden: false,
      start: dayjs(start),
      end: dayjs(start).add(1, "hour"),
      isConflict: false,
    })
    // }
  }

  return (
    <Wrapper>
      <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
        <DragAndDropCalendar
          selectable
          resizable
          eventPropGetter={getEventPropGetter}
          onEventDrop={moveAndResizeEvent}
          onEventResize={moveAndResizeEvent}
          localizer={localizer}
          style={calendarHeightStyled}
          // @ts-ignore
          startAccessor={event => event.start}
          // @ts-ignore
          endAccessor={event => event.end}
          events={eventsArr}
          views={["day"]}
          formats={FORMATS}
          onSelectSlot={selectSlotHandle}
          longPressThreshold={50}
          className="custom-calendar-view"
          components={{
            toolbar: CustomToolbar,
            // @ts-ignore
            eventWrapper: CustomEventWrapper,
            // @ts-ignore
            event: CustomEvent,
          }}
          defaultView="day"
          timeslots={4}
          step={15}
          date={currentSelectedDay.toDate()}
        />
      </DndProvider>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  externalEvents: selectGuestCalendarEvents(state),
  newGuestSuggestion: selectNewGuestSuggestion(state),
  guestSuggestions: selectGuestSuggestions(state),
})

const mapDispatchToProps = dispatch => ({
  setNewGuestSuggestion: bindActionCreators(setNewGuestSuggestion, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CustomCalendarView))
