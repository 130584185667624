import cn from "classnames"

import { SvgIcon } from "@components/common"

import "./index.scss"

interface Props {
  isWithIndent?: boolean
  hideBorder?: boolean
  title: string
  subtitle?: string
  onClose?: () => void
  onBackClick?: () => void
  className?: string
}

const Header = ({
  title,
  subtitle,
  onClose,
  onBackClick,
  isWithIndent,
  hideBorder,
  className,
}: Props) => {
  return (
    <header
      className={cn("modal-header", className, {
        "modal-bottom-indent": isWithIndent,
        "hide-border": hideBorder,
      })}>
      <div className="modal-header-title">
        {onBackClick && (
          <div onClick={onBackClick}>
            <SvgIcon
              name="arrow-2"
              color="var(--gray4)"
              className="people-modal-back"
            />
          </div>
        )}

        <div>
          <h4>{title}</h4>
          {subtitle && <span>{subtitle}</span>}
        </div>
      </div>
      {onClose && (
        <button onClick={onClose}>
          <SvgIcon name="cross" />
        </button>
      )}
    </header>
  )
}

export default Header
