import React, { useMemo, useRef, useState } from "react"

import cn from "classnames"
import PropTypes from "prop-types"

import "./inputEl.scss"
import BlackSearch from "@assets/images/black-search.svg"
import ValidCheckmark from "@assets/images/valid-checkmark.svg"
import { useScreenWidth } from "@hooks"

import { CircleLoader } from "../CircleLoader"

import { InputClear, ErrorMessage, MentionInputEl, Label } from "./atoms"
import { PasswordToggler, PasswordRules } from "./molecules"

export const InputEl = ({
  children,
  isHidden = false,
  isNoClear = false,
  isRounded = false,
  isLoading = false,
  isValid = false,
  isSearch = false,
  disabled = false,
  isMention = false,
  maxLength = null,
  error = false,
  label,
  name,
  errorMessage,
  isError = false,
  type = "text",
  value,
  placeholder = "",
  testId,
  allPasswordErrors,
  onChange,
  onPaste,
}) => {
  const { isMobile } = useScreenWidth()
  const inputRef = useRef(null)
  const [isShownPassword, setIsShownPassword] = useState(false)

  const handlePasswordToggle = () => {
    setIsShownPassword(prevState => !prevState)
  }

  const handleInputClear = () => {
    onChange("")
    inputRef.current.focus()
  }

  const inputType = useMemo(() => {
    return type === "password" ? (isShownPassword ? "text" : "password") : type
  }, [type, isShownPassword])

  return (
    <div
      className={cn("input-el--content", {
        "input-el--hidden": isHidden,
      })}>
      {label && <Label name={name} label={label} />}

      <div className="input-el--wrapper">
        {isLoading && !isValid && (
          <div className="input-el__loader">
            <CircleLoader />
          </div>
        )}

        {!isLoading && isValid && (
          <img className="input-el__valid" src={ValidCheckmark} alt="valid" />
        )}

        {isSearch && (
          <img className="input-el__search" src={BlackSearch} alt="search" />
        )}

        {isMention && <MentionInputEl value={value} />}

        <input
          ref={inputRef}
          id={name}
          className={cn("input-el", {
            "input-el--error": errorMessage || isError,
            "input-el--icon": isLoading || isValid || isSearch,
            "input-el--mention": isMention,
            "input-el--disabled": disabled,
            "input-el--rounded": isRounded,
            "input-el--mobile": isMobile,
          })}
          type={inputType}
          name={name}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          onChange={e => onChange(e.target.value)}
          onPaste={e => onPaste && onPaste(e.clipboardData.getData("text"))}
          placeholder={placeholder}
          data-testid={testId}
        />

        {!disabled && type !== "password" && value && !isNoClear && (
          <InputClear onClick={handleInputClear} />
        )}

        {type === "password" && value && (
          <PasswordToggler
            isShownPassword={isShownPassword}
            onToggle={handlePasswordToggle}
          />
        )}
      </div>

      <div
        className={cn("input-el__footer", {
          "input-el__footer--error": errorMessage,
        })}>
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {children && children}
      </div>

      {type === "password" && allPasswordErrors && (
        <PasswordRules allPasswordErrors={allPasswordErrors} />
      )}
    </div>
  )
}

InputEl.propTypes = {
  maxLength: PropTypes.string | PropTypes.number,
  isHidden: PropTypes.bool,
  isNoClear: PropTypes.bool,
  isRounded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool,
  isSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  isMention: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string | PropTypes.number,
  placeholder: PropTypes.string,
  allPasswordErrors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  testId: PropTypes.string,
}
