import React, { useContext } from "react"

import { SideBarContext } from "@utils/sideBarContext"

import { Button, ArrowIcon } from "./toggleButtonStyles"

export const ToggleButton = () => {
  const { isSideBarOpen, handleToggleSidebar } = useContext(SideBarContext)

  return (
    <Button onClick={() => handleToggleSidebar(!isSideBarOpen)}>
      <ArrowIcon
        $isWrapped={!isSideBarOpen}
        name="arrow-2-black"
        color="var(--gray6)"
      />
    </Button>
  )
}
