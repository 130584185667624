import { ReactSVG } from "react-svg"

import Modal from "@components/Modal"
import { useOpenAI } from "@hooks/useOpenAi/useOpenAi"
import { images } from "@utils/imageUtils"
import { TitleInput } from "pages/CreateTeeUpNd/TopBar/topBarStyles"
import Chat, {
  TMessage,
} from "pages/TeeUpPage/TeeUpPageModals/SuggestWhenAiModal/components/Chat"
import ContactItem from "pages/TeeUpPage/TeeUpPageModals/SuggestWhenAiModal/components/ContactItem/ContactItem"
import { SuggestModalWrapper } from "pages/TeeUpPage/TeeUpPageModals/components"
import { IContact } from "types/teeup"

import { useCreateTeeup } from "./useCreateTeeup"

import "./createTeeupAiModal.scss"

interface ICreateTeeupAiModalProps {
  onClose: () => void
}

const CreateTeeupAiModal = ({ onClose }: ICreateTeeupAiModalProps) => {
  const {
    chatMessages,
    isGenerating,
    participantsNames,
    assistantCall,
    setChatMessages,
    title: meetingTitle,
    time,
  } = useOpenAI({ type: "create" })

  const {
    title,
    isLoading,
    selectedContacts,
    suggestedContacts,
    handleTeeupCreate,
    handleTitleChange,
    handleContactSelect,
  } = useCreateTeeup({ onClose, time, participantsNames, meetingTitle })

  const handleMessageSend = (chatMessage: TMessage) => {
    setChatMessages(prev => [...prev, chatMessage])
    assistantCall(chatMessage.message)
  }

  // const isShouldShowSuggestButtons = !selectedSuggestions.some(
  //   innerSuggestion =>
  //     innerSuggestion.index === suggestion?.index ||
  //     innerSuggestion.startDate === suggestion?.startDate
  // )
  const isSubmitDisabled = isLoading || !title

  return (
    <Modal>
      <SuggestModalWrapper>
        <div className="createTeeupAiModal">
          <div className="createTeeupAiModal__headWrap">
            <h3>Create Teeup</h3>
            <button className="createTeeupAiModal__closeBtn" onClick={onClose}>
              <ReactSVG src={images.ndCloseXLIcon} />
            </button>
          </div>
          <TitleInput
            value={title}
            onChange={handleTitleChange}
            placeholder="Add Title"
          />
          <Chat
            isLoading={isGenerating}
            messages={chatMessages}
            sendMessage={handleMessageSend}
          />
          <div className="createTeeupAiModal__suggestionWrapper">
            {/* <div>
              {suggestion && (
                <div className="createTeeupAiModal__suggestWrap">
                  <SuggestionPreview suggestion={suggestion} />
                  {isShouldShowSuggestButtons && (
                    <>
                      <button
                        className={classNames([
                          "createTeeupAiModal__btn",
                          "createTeeupAiModal__btn_secondary",
                        ])}
                        onClick={() => handleSuggestionSave(true)}>
                        Decide on this
                      </button>
                      <button
                        className="createTeeupAiModal__btn"
                        onClick={() => handleSuggestionSave(false)}>
                        Suggest
                      </button>
                    </>
                  )}
                </div>
              )}
            </div> */}
            <div>
              {suggestedContacts.length > 0 && (
                <>
                  <div>Select participants</div>
                  {suggestedContacts.map((contact: IContact) => {
                    const isChecked = selectedContacts.some(
                      selectedContact => selectedContact.id === contact.id
                    )

                    return (
                      <ContactItem
                        key={contact.id}
                        onChange={handleContactSelect}
                        contact={contact}
                        checked={isChecked}
                      />
                    )
                  })}
                </>
              )}
            </div>
            <div>
              <div className="createTeeupAiModal__title">
                Selected participants
              </div>
              {selectedContacts.map(contact => {
                const isChecked = selectedContacts.some(
                  selectedContact => selectedContact.id === contact.id
                )
                return (
                  <ContactItem
                    key={contact.id}
                    onChange={handleContactSelect}
                    contact={contact}
                    checked={isChecked}
                  />
                )
              })}
            </div>
          </div>
          <div>
            <div className="createTeeupAiModal__suggestionWrapper">
              {/* <div>
                <div className="createTeeupAiModal__title">
                  Selected suggestions
                </div>
                {selectedSuggestions.map(innerSuggestion => {
                  return (
                    <div className="createTeeupAiModal__suggestWrap">
                      <SuggestionPreview
                        suggestion={innerSuggestion}
                        key={innerSuggestion.index}
                      />
                      <button
                        className={classNames([
                          "createTeeupAiModal__btn",
                          "createTeeupAiModal__btn_secondary",
                        ])}
                        onClick={() => removeSuggestion(innerSuggestion)}>
                        Remove
                      </button>
                    </div>
                  )
                })}
              </div> */}
            </div>
            <button
              disabled={isSubmitDisabled}
              className="createTeeupAiModal__createBtn"
              onClick={handleTeeupCreate}>
              Create Teeup
            </button>
          </div>
        </div>
      </SuggestModalWrapper>
    </Modal>
  )
}

export default CreateTeeupAiModal
