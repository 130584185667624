import { useEffect, useMemo, useState } from "react"

import { GoogleApiWrapper } from "google-maps-react"

import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import { getCoords } from "@utils/suggestionUtils"
import Map from "pages/TeeUpPage/TeeUpPageModals/SuggestWhereModalNd/InPerson/Map"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"
import { Suggestion } from "types/suggestions"

interface Props {
  suggestion: Suggestion
}

const MapDetails = ({ suggestion }: Props) => {
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
    address: "",
  })
  const placeId = suggestion?.googlePlaceId
  // @TODO remove this shit
  const additionalInformation =
    suggestion?.fullDetails === "undefined"
      ? undefined
      : suggestion?.fullDetails

  const clearInPersonState = useSuggestionInPersonStore(
    state => state.clearState
  )

  const geocoder = useMemo(() => new window.google.maps.Geocoder(), [])

  useEffect(() => {
    getCoordinates()

    return () => {
      clearInPersonState()
    }
  }, [placeId, geocoder])

  const getCoordinates = async () => {
    if (!placeId || !geocoder) return

    const response = await getCoords(geocoder, placeId)
    if (!response) return

    setCoordinates({
      lat: response.lat,
      lng: response.lng,
      address: suggestion.value,
    })
  }
  const getGoogleMapsUrl = (placeId?: string, lat?: number, lng?: number) => {
    if (lat && lng) {
      return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    } else if (placeId) {
      return `https://www.google.com/maps/place/?q=place_id:${placeId}`
    } else {
      return null
    }
  }

  const handleDirectionsClick = () => {
    let url = getGoogleMapsUrl(placeId, coordinates.lat, coordinates.lng)

    if (url) {
      window.open(url, "_blank") // Opens the URL in a new tab
    }
  }

  return (
    <div className="meeting-details">
      <div className="meeting-details__block">
        <h5>Address</h5>
        {!placeId && <p>No address posted</p>}
        {placeId && <Map externalCoordinates={coordinates} />}
        <Button fullWidth variant="contained" onClick={handleDirectionsClick}>
          Get directions <SvgIcon name="link-square" />
        </Button>
      </div>

      <div className="meeting-details__block">
        <h5>Additional Information</h5>
        <p>{additionalInformation || "No additional information posted"}</p>
      </div>
    </div>
  )
}

const WrapperMapDetails = GoogleApiWrapper({
  apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
  // @ts-ignore
})(MapDetails)

export default WrapperMapDetails
