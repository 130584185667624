import { Fragment, useEffect, useState } from "react"

import { useSelector } from "react-redux"

import { selectUserInfo, selectUserTimezone } from "@selectors/user"
import { groupByTimezone } from "@utils/peopleUtils"
import { getBoxInfo } from "@utils/suggestionUtils"

import Block from "../Block"

import TimezoneItem from "./TimezoneItem"

import "./index.scss"

const Timezone = ({ people }) => {
  const [peopleByTimezone, setPeopleByTimezone] = useState([])
  const user = useSelector(selectUserInfo)
  const timezone = useSelector(selectUserTimezone)

  useEffect(() => {
    const peopleByTimezone = groupByTimezone(people)

    setPeopleByTimezone(Object.values(peopleByTimezone))
  }, [people, timezone])

  return (
    <Block>
      <div className="people-timezone-label">
        <h4>Timezone </h4>
        <span>· {peopleByTimezone.length}</span>
      </div>
      <Block className="people-timezone-block">
        <div className="people-timezone-middle-line">
          <div></div>
          <div></div>
        </div>

        <div className="people-timezone-items-container">
          {peopleByTimezone.map(people => (
            <Fragment key={people[0].id}>
              <TimezoneItem
                peopleByTimezone={people}
                getBoxInfo={getBoxInfo}
                user={user}
              />
            </Fragment>
          ))}
        </div>
      </Block>
    </Block>
  )
}

export default Timezone
