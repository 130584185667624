import React, { useContext, useEffect, useState } from "react"

import OutsideClickHandler from "react-outside-click-handler"
import { useSelector } from "react-redux"

import { SEARCH_TYPES } from "@constants/search"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { selectSelectedContacts } from "@selectors/invitees"
import { isContainsOnlyNumbers } from "@utils/isContainsOnlyNumbers"

import { SwitchSearchType, NotFoundEmails } from "./atoms"
import { useTypingSearchResults } from "./hooks"
import { Wrapper } from "./inviteSearchStyles"
import {
  EmailSearch,
  PhoneSearch,
  SearchResults,
  SelectedContactsList,
} from "./molecules"

export const InviteSearch = () => {
  const selectedContacts = useSelector(selectSelectedContacts)
  const { notFoundEmails, searchValue, setSearchValue, setNotFoundEmails } =
    useContext(InvitePeopleContext)
  const [searchType, setSearchType] = useState(SEARCH_TYPES.EMAIL)
  const [isResultsShown, setIsResultsShown] = useState(false)
  const isEmailSearch = searchType === SEARCH_TYPES.EMAIL
  const isOnlyNumbers = isContainsOnlyNumbers(searchValue)
  const switchSearchTypeText = isEmailSearch
    ? "Invite by phone number"
    : "Invite by name or email address"

  const searchResults = useTypingSearchResults({
    isOnlyNumbers,
    selectedContacts,
  })

  const handleSearchValueChanges = newVal => {
    setNotFoundEmails([])
    setSearchValue(newVal)
  }

  useEffect(() => {
    setIsResultsShown(searchValue.length > 0)
  }, [searchValue])

  const handleSwitchType = () => {
    isEmailSearch
      ? setSearchType(SEARCH_TYPES.PHONE)
      : setSearchType(SEARCH_TYPES.EMAIL)
  }

  return (
    <Wrapper>
      {isEmailSearch ? (
        <EmailSearch onChange={handleSearchValueChanges} />
      ) : (
        <PhoneSearch onChange={handleSearchValueChanges} />
      )}

      {notFoundEmails.length > 0 && <NotFoundEmails />}

      {selectedContacts.length > 0 && <SelectedContactsList />}

      <SwitchSearchType
        text={switchSearchTypeText}
        onClick={handleSwitchType}
      />

      {isResultsShown && (
        <OutsideClickHandler onOutsideClick={() => setIsResultsShown(false)}>
          <SearchResults
            isTrySwitch={isEmailSearch && isOnlyNumbers}
            onSwitchType={setSearchType}
            searchResults={searchResults}
          />
        </OutsideClickHandler>
      )}
    </Wrapper>
  )
}
