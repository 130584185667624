import { useCallback, useEffect } from "react"

import { getScrollLeftOfCurrentDate } from "../helpers"

export const useInitScrollLeft = params => {
  const initScrollLeft = useCallback(
    ({
      startDate,
      nowDate,
      parsedCurrentPeriod,
      dragScrollRef,
      pickerWidth,
    }) => {
      if (startDate) return
      if (
        nowDate &&
        parsedCurrentPeriod &&
        parsedCurrentPeriod.length > 0 &&
        dragScrollRef.current
      ) {
        const nowDateIndex = parsedCurrentPeriod.indexOf(nowDate)

        dragScrollRef.current.scrollLeft = getScrollLeftOfCurrentDate(
          nowDateIndex,
          pickerWidth
        )
      }
    },
    []
  )

  const { nowDate, parsedCurrentPeriod } = params
  useEffect(() => {
    initScrollLeft(params)
  }, [nowDate, parsedCurrentPeriod])

  return { reinitScrollLeft: initScrollLeft }
}
