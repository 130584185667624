import React, { memo } from "react"

import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"

import Button from "@ui/Button"
import { images } from "@utils/imageUtils"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"

import "./generalTimeTab.scss"
import CalendarSelect from "./CalendarSelect/CalendarSelect"
import useGeneralTimeTab from "./GeneralTimeContext/useGeneralTimeTab"
import Pinboard from "./Pinboard"
import QuickSelect from "./QuickSelect/QuickSelect"
import SelectTime from "./SelectTime/SelectTime"

const GeneralTimeTab = ({ onSave, handleClose }) => {
  const {
    canSuggest,
    onSuggest,
    daysSelected,
    quickSelect,
    time,
    initialGamePlanWhen,
  } = useGeneralTimeTab()

  const makeSuggestion = selected => {
    if (!canSuggest) return

    onSuggest(selected)

    if (onSave) {
      onSave()
      // TODO: remove it later (Calendar removing)
      // store.dispatch(setIsCalendarSynchronized(false))
      // syncCalendar()
    }
    if (handleClose) {
      handleClose()
    }
  }

  const slideLeft = () => {
    const slider = document.querySelector("#slider")
    // 369 is the width of the card
    slider.scrollLeft = slider.scrollLeft - 369
  }

  const slideRight = () => {
    const slider = document.querySelector("#slider")
    // 369 is the width of the card
    slider.scrollLeft = slider.scrollLeft + 369
  }

  const canSubmitGamePlan = canSuggest === 1 || canSuggest === 2

  return (
    <>
      <div className="general-time-tab">
        {daysSelected.length >= 3 && (
          <div className="general-time-tab__arrows">
            <div
              className="general-time-tab__arrow general-time-tab__arrow--left"
              onClick={slideLeft}>
              <ReactSVG src={images.ndArrowLeftIcon} />
            </div>
            <div
              className="general-time-tab__arrow general-time-tab__arrow--right"
              onClick={slideRight}>
              <ReactSVG src={images.ndArrowRightIcon} />
            </div>
          </div>
        )}
        <div id="slider" className="general-time-tab__content">
          {daysSelected.length > 0 ? (
            daysSelected.map((day, index) => (
              <SuggestModalSubHeader key={index}>
                <Pinboard gamePlans={{ day, quickSelect, time }} />
              </SuggestModalSubHeader>
            ))
          ) : (
            <SuggestModalSubHeader>
              <Pinboard
                gamePlans={{ day: daysSelected[0], quickSelect, time }}
              />
            </SuggestModalSubHeader>
          )}
        </div>
      </div>

      <SuggestModalContent type="when">
        <div className="general-time__date">
          <div className="general-time__calendar">
            <div className="general-time__sub-title">
              Select one or more days
            </div>
            <CalendarSelect />
          </div>
          <div className="general-time__date-divider" />
          <div className="general-time__quick-select">
            <div className="general-time__sub-title">Quick select</div>
            <QuickSelect />
          </div>
        </div>
        <div className="general-time__time">
          <div className="general-time__sub-title">
            Select time<span>optional</span>
          </div>
          <SelectTime />
        </div>
        {/* <SuggestModalTimeline
          selectTime={time}
          daySelected={daysSelected.slice(-1)[0] || quickSelect}
        /> */}
      </SuggestModalContent>
      <SuggestModalFooter>
        <button
          className={
            canSubmitGamePlan
              ? "footer-buttons__place"
              : "footer-buttons__place footer-button__place-disabled"
          }
          disabled={!canSubmitGamePlan}
          onClick={() => makeSuggestion(true)}>
          Decide on this
        </button>
        <Button
          type={canSuggest ? "primary" : "disabled"}
          as="div"
          style={{ height: "40px", width: "144px", fontSize: "17px" }}
          onClick={() => makeSuggestion(false)}>
          <span>Suggest</span>
        </Button>
      </SuggestModalFooter>
    </>
  )
}
GeneralTimeTab.propTypes = {
  onSave: PropTypes.func,
}

export default memo(GeneralTimeTab)
