import { useDispatch, useSelector } from "react-redux"

import actionTypes from "@actions/actionTypes"
import {
  selectMapCenter,
  selectActivePlace,
  selectIsSearchNearby,
  selectNearbyPlaces,
} from "@selectors/suggest"

const useInPersonTabState = () => {
  const dispatch = useDispatch()
  const mapCenter = useSelector(selectMapCenter)
  const activePlace = useSelector(selectActivePlace)
  const isSearchNearby = useSelector(selectIsSearchNearby)
  const nearbyPlaces = useSelector(selectNearbyPlaces)

  const setMapCenter = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_MAP_CENTER, payload })
  }

  const setActivePlace = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_ACTIVE_PLACE, payload })
  }

  const setIsSearchNearby = payload => {
    dispatch({
      type: actionTypes.SET_SUGGEST_IN_PERSON_IS_SEARCH_NEARBY,
      payload,
    })
  }

  const setNearbyPlaces = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_NEARBY_PLACES, payload })
  }

  const clearNearbyPlaces = () => {
    dispatch({ type: actionTypes.SET_SUGGEST_IN_PERSON_NEARBY_CLEAR_PLACES })
  }

  return {
    mapCenter,
    activePlace,
    isSearchNearby,
    nearbyPlaces,
    setMapCenter,
    setActivePlace,
    setIsSearchNearby,
    setNearbyPlaces,
    clearNearbyPlaces,
  }
}

export default useInPersonTabState
