import { CUSTOM_ACTION_KEY } from "@constants/global"
import { REACTION_TYPE } from "@constants/reactions"
import {
  checkIfCurrentReactionType,
  pushReactionType,
  removeAnotherReaction,
} from "@utils/guestHelpers"

const CUSTON_ACTION_OBJECT = { key: CUSTOM_ACTION_KEY }

export const updateSuggestionReaction = ({
  originSuggestions,
  suggestionId,
  reactionId,
  isActive,
  inviteeId,
}) => {
  let isNotVote = false
  return originSuggestions.map(suggestion => {
    if (suggestion.id === suggestionId) {
      if (!checkIfCurrentReactionType(suggestion, reactionId)) {
        return pushReactionType({
          suggestion,
          reactionId,
          inviteeId,
        })
      }

      let newReactions = suggestion.reactions.map(reaction => {
        const updatedReaction = { ...reaction }
        removeAnotherReaction(updatedReaction, inviteeId)
        if (
          reaction.reactedBy.some(participant => participant.id === inviteeId)
        ) {
          isNotVote = true

          return updatedReaction
        }
        if (reaction.reactionId === reactionId && !isActive) {
          updatedReaction.reactedBy.push(CUSTON_ACTION_OBJECT)
        }

        return updatedReaction
      })

      if (isNotVote) {
        newReactions.push({
          reactedBy: [CUSTON_ACTION_OBJECT],
          reactionId: REACTION_TYPE.NOT_VOTE,
        })
      }

      return {
        ...suggestion,
        reactions: newReactions,
      }
    }

    return suggestion
  })
}
