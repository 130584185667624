import React from "react"

import { connect } from "react-redux"

import { NewModal } from "@components"
import { MODAL_STATUSES } from "@constants/modal"
import { useDeviceTimezone } from "@hooks"
import {
  selectCalendarSyncSettings,
  selectToken,
  selectUserSettings,
} from "@selectors/user"

const TimezoneChange = ({
  isAuthenticated,
  customTimezone,
  calendarSyncSettings,
}) => {
  const {
    isTimezoneModal,
    currentDeviceTimezone,
    applyDeviceTimezone,
    ignoreChanges,
    closeTimezoneModal,
  } = useDeviceTimezone({
    isAuthenticated,
    customTimezone,
    calendarSyncSettings,
  })

  const getDescription = () => `
    It seems you have changed your location, and a new time zone has been detected: [${currentDeviceTimezone}].
    <br>
    If correct, press Apply.
  `

  return (
    <>
      <NewModal
        isOpen={isTimezoneModal}
        isHeader={false}
        status={MODAL_STATUSES.WARNING}
        title="Time Zone Change"
        description={getDescription()}
        cancelText="Ignore"
        confirmText="Apply"
        onCancel={() => {
          closeTimezoneModal()
          ignoreChanges()
        }}
        onConfirm={applyDeviceTimezone}
        onClose={closeTimezoneModal}
      />
    </>
  )
}

const mapStateToProps = state => {
  const calendarSyncSettings = selectCalendarSyncSettings(state)

  return {
    isAuthenticated: selectToken(state),
    customTimezone: calendarSyncSettings?.customTimezone,
    calendarSyncSettings: selectUserSettings(state),
  }
}

export default connect(mapStateToProps)(TimezoneChange)
