import React, { memo, useEffect, useState } from "react"

import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"

import { selectUserTimezone } from "@selectors/user"
import { getConflictsForMonthCalendar } from "@utils/calendarUtils"
import { getDateWithTimezone } from "@utils/dateUtils"

import { selectCalendarForMonth } from "../../../selectors/calendar"

import MonthCalendarTimetableView from "./MonthCalendarTimetableView"

const generateDaysOfTheMonth = (selectedDate, timezone) => {
  if (!timezone) return []
  const startOfMonth = getDateWithTimezone(selectedDate, timezone).startOf(
    "month"
  )
  const startOfTimeframe = getDateWithTimezone(startOfMonth, timezone).startOf(
    "week"
  )
  let weeks = []
  for (let i = 0; i < 6; i++) {
    let days = []

    for (let j = 0; j < 7; j++) {
      days.push(
        getDateWithTimezone(startOfTimeframe, timezone).add(7 * i + j, "d")
      )
    }
    weeks.push(days)
  }

  return weeks
}

const MonthCalendarTimetableContainer = props => {
  const {
    selectedDate,
    eventsList,
    selectDay,
    hasNewSuggestionProps,
    monthCellWidth,
  } = props
  const timezone = useSelector(selectUserTimezone)
  const daysOfTheMonth = generateDaysOfTheMonth(selectedDate, timezone)
  const [totalWidth, setTotalWidth] = useState(0)
  const [conflictsDetected, setConflictsDetected] = useState({})
  useEffect(() => {
    setConflictsDetected(detectConflicts())
    // eslint-disable-next-line
  }, [])

  const detectConflicts = () => {
    let newConflictsDetected = {}
    Object.keys(eventsList).forEach(day => {
      let conflicts = getConflictsForMonthCalendar(eventsList[day])
      if (conflicts.length > 0) {
        newConflictsDetected[day] = conflicts
      }
    })

    return newConflictsDetected
  }

  const calculateBlocksWidth = ({ nativeEvent }) => {
    const { width } = nativeEvent.layout
    setTotalWidth(width)
  }

  return (
    <MonthCalendarTimetableView
      monthCellWidth={monthCellWidth}
      hasNewSuggestionProps={hasNewSuggestionProps}
      selectedDate={selectedDate}
      totalWidth={totalWidth}
      calculateBlocksWidth={calculateBlocksWidth}
      eventsList={eventsList}
      conflictsDetected={conflictsDetected}
      daysOfTheMonth={daysOfTheMonth}
      selectDay={selectDay}
    />
  )
}

MonthCalendarTimetableContainer.propTypes = {
  eventsList: PropTypes.object,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectDay: PropTypes.func,
  hasNewSuggestionProps: PropTypes.bool,
  loadEvents: PropTypes.bool,
}

MonthCalendarTimetableContainer.defaultProps = {
  allowCreateTeeupFromEvent: true,
}

const mapStateToProps = (state, ownProps) => ({
  eventsList: selectCalendarForMonth(state)(ownProps.selectedDate),
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(MonthCalendarTimetableContainer))
