import styled from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.section`
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
`

export const Title = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
`

export const TextareaWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 15px;
`

export const Counter = styled(NSubtitleS)`
  color: var(--gray4);
  position: absolute;
  top: 7px;
  right: 8px;
`

export const TextareaEl = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 8px 75px 42px 8px;
  object-fit: contain;
  border-radius: 8px;
  border: solid 1px var(--lightGray5);
  resize: none;
  box-sizing: border-box;
  overflow: hidden;

  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: -0.3px;

  &::placeholder {
    color: #a9b0b9;
  }
`
