import React from "react"

import PropTypes from "prop-types"
import {
  TouchableWithoutFeedback,
  Text,
  StyleSheet,
  Animated,
  Easing,
  View,
} from "react-native"
// import RootSiblings from 'react-native-root-siblings'
// import { isIphoneX } from 'react-native-iphone-x-helper'

import { AppColors, Units, AppFonts } from "@theme"

// const defaultBottomOffset = isIphoneX() ? 48 : 8
const defaultBottomOffset = 8

const styles = StyleSheet.create({
  container: (bottomOffset = 0) => ({
    position: "absolute",
    bottom: Units.responsiveValue(defaultBottomOffset) + bottomOffset,
    right: Units.responsiveValue(8),
    left: Units.responsiveValue(8),
    display: "flex",
  }),
  content: {
    alignItems: "center",
    backgroundColor: AppColors.brand.blackTwo,
    flex: 1,
    borderRadius: 8,
    display: "flex",
    elevation: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: Units.responsiveValue(48),
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(14),
    shadowColor: AppColors.brand.black,
    shadowOffset: { height: 2 },
    shadowOpacity: 0.12,
    shadowRadius: 4,
  },
  textContainer: {
    flex: 1,
    flexShrink: 2,
  },
  text: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.white,
  },
  buttonText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.pink,
  },
})

export const ToastText = ({ numberOfLines, children, textAlign }) => (
  <Text style={[styles.text, { textAlign }]} numberOfLines={numberOfLines}>
    {children}
  </Text>
)

ToastText.propTypes = {
  children: PropTypes.string.isRequired,
  numberOfLines: PropTypes.number,
  textAlign: PropTypes.string,
}

export const ToastButton = ({ label, onPress }) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <Text style={styles.buttonText}>{label}</Text>
  </TouchableWithoutFeedback>
)

ToastButton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
}

class ToastContent extends React.Component {
  state = {
    opacity: new Animated.Value(0),
    isAnimating: false,
  }

  hideTimer = null

  handlePress = () => {
    this.hide()
  }

  hide = byButtonPress => {
    const { isAnimating } = this.state
    if (!isAnimating) {
      return this.animateOpacity(
        {
          toValue: 0,
          easing: Easing.out(Easing.ease),
        },
        () => {
          this.props.onDismiss(byButtonPress)
          Toast.hide()
        }
      )
    }

    this.props.onDismiss(byButtonPress)
    return Toast.hide()
  }

  animateOpacity = (options, onFinished = () => {}) =>
    this.setState(
      () => ({ isAnimating: true }),
      () =>
        Animated.timing(this.state.opacity, {
          useNativeDriver: true,
          timing: 500,
          ...options,
        }).start(({ finished }) => {
          if (finished) {
            this.setState(() => ({ isAnimating: false }))
            onFinished()
          }
        })
    )

  handleButtonPress = () => {
    const { buttonOnPress } = this.props
    buttonOnPress()
    this.hide(true)
  }

  componentDidMount = () => {
    const { duration } = this.props
    this.animateOpacity(
      {
        toValue: 1,
        easing: Easing.out(Easing.ease),
      },
      () => {
        if (duration) {
          this.hideTimer = setTimeout(this.hide, duration)
        }
      }
    )
  }

  componentWillUnmount = () => {
    clearTimeout(this.hideTimer)
  }

  render = () => {
    const { children, bottomOffset, buttonLabel, numberOfLines, textAlign } =
      this.props
    const { opacity } = this.state
    return (
      <TouchableWithoutFeedback onPress={this.handlePress}>
        <Animated.View
          style={{
            opacity,
            ...styles.container(bottomOffset),
          }}>
          <View style={styles.content}>
            <View style={styles.textContainer}>
              <ToastText numberOfLines={numberOfLines} textAlign={textAlign}>
                {children}
              </ToastText>
            </View>
            {buttonLabel ? (
              <ToastButton
                label={buttonLabel}
                onPress={this.handleButtonPress}
              />
            ) : null}
          </View>
        </Animated.View>
      </TouchableWithoutFeedback>
    )
  }
}

ToastContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bottomOffset: PropTypes.number,
  buttonLabel: PropTypes.string,
  buttonOnPress: PropTypes.func,
  onDismiss: PropTypes.func,
  duration: PropTypes.number,
  numberOfLines: PropTypes.number,
  textAlign: PropTypes.string,
}

ToastContent.defaultProps = {
  bottomOffset: 0,
  buttonLabel: "",
  buttonOnPress: () => {},
  onDismiss: () => {},
  duration: 3500,
  numberOfLines: null,
}

class Toast extends React.Component {
  toast = null

  static show = (content, options = { bottomOffset: 58 }) => {
    Toast.hide()

    // this.toast = new RootSiblings(
    //     <ToastContent {...options}>{content}</ToastContent>
    // )

    // return this.toast
  }

  static hide = () => {
    // if (this.toast instanceof RootSiblings) {
    //     this.toast.destroy()
    //     this.toast = null
    // }
  }

  componentDidMount() {
    // this.toast = new RootSiblings(<ToastContent {...this.props} />)
  }

  render = () => null
}

export default Toast
