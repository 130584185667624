import { create } from "zustand"

import { SuggestionDuration } from "types/suggestions"

interface SuggestionDurationStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  duration: SuggestionDuration
  setDuration: (duration: SuggestionDuration) => void
  clearState: () => void
}

export const useSuggestionDurationStore = create<SuggestionDurationStore>()(
  (set, get) => ({
    isOpen: false,
    setIsOpen: isOpen => set({ isOpen }),

    duration: { id: "60", value: "1 hour" },
    setDuration: duration => set({ duration }),

    clearState: () =>
      set({ duration: { id: "60", value: "1 hour" }, isOpen: false }),
  })
)
