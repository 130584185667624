import { Fragment, useContext } from "react"

import cn from "classnames"
import Skeleton from "react-loading-skeleton"
import { useSelector } from "react-redux"

import { SvgIcon } from "@components/common"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { selectActiveTeeupSettings, selectUserId } from "@selectors/activeTeeup"
import { getParticipantStatus } from "@utils/peopleUtils"
import { TEEUP_SETTINGS_TYPES } from "constants/teeups"

import ActionButton from "../ActionButton"
import ParticipantIcon from "../Content/Participants/ParticipantItem/ParticipantIcon"

import "./index.scss"

import AskPeopleModal from "../Modals/AskPeople"
import AttendanceModal from "../Modals/Attendance"
import NudgeModal from "../Modals/Nudge"

const maxPeopleCount = 7

const SubTitleContent = ({
  people,
  isCollapsed,
  isParticipants,
  isOrganizer,
}) => {
  const settings = useSelector(selectActiveTeeupSettings)
  const userId = useSelector(selectUserId)
  const isPeopleExist = people.length > 0

  const { setIsInvitePeopleOpen, setSettingsType, setIsSettings } =
    useContext(NewTeeupPageContext)

  const handleInviteClick = () => {
    setIsInvitePeopleOpen(true)
  }

  const handleManageParticipantsClick = () => {
    setIsSettings(true)
    setSettingsType(TEEUP_SETTINGS_TYPES.PARTICIPANTS)
  }

  if (people.length === 0)
    return (
      <div className="people-sup-skeleton">
        <Skeleton width="100%" borderRadius={50} />
      </div>
    )

  if (isParticipants && isCollapsed) return null

  return (
    <div className="people-sub-container">
      {isCollapsed && !isParticipants && (
        <div className="people-actions">
          <NudgeModal />
          <AskPeopleModal people={people} />

          <ActionButton
            onClick={handleInviteClick}
            isDisabled={!isOrganizer && !settings?.allowInviteOthers}
            className="people-attendance-button">
            <SvgIcon name="people-2" color="var(--gray4)" />
            Invite
          </ActionButton>

          {isOrganizer && (
            <ActionButton
              onClick={handleManageParticipantsClick}
              className="people-attendance-button">
              <SvgIcon name="people-manage" color="var(--gray4)" />
              Manage Participants
            </ActionButton>
          )}
        </div>
      )}

      {!isCollapsed && (
        <>
          <div
            className={cn("people-participants-container", {
              "people-participants-extended": isParticipants,
            })}>
            {isPeopleExist &&
              people
                .slice(0, maxPeopleCount)
                .map(participant => (
                  <ParticipantIcon
                    key={participant.id}
                    avatar={participant.avatar}
                    status={getParticipantStatus(participant.status)}
                    isLarge={isParticipants}
                    isOrganizer={participant.isOrganiser}
                    defaultAvatar={participant?.name || participant?.username}
                  />
                ))}

            {people.length > maxPeopleCount && (
              <div className="people-participants-more">
                <p>+{people.length - maxPeopleCount}</p>
              </div>
            )}
          </div>

          {!isParticipants && (
            <AttendanceModal user={people.find(i => i.id === userId)} />
          )}
        </>
      )}
    </div>
  )
}

export default SubTitleContent
