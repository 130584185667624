import React from "react"

import PropTypes from "prop-types"

import { Wrapper, Text } from "./decidedMarkStyles"

export const SuggestionMark = ({ isDecided = false }) => (
  <Wrapper $isDecided={isDecided}>
    <Text>{isDecided ? "Decided" : "Withdrawn"}</Text>
  </Wrapper>
)

SuggestionMark.propTypes = {
  isDecided: PropTypes.bool,
}
