import React, { useEffect, useRef } from "react"

import cn from "classnames"
import { useDispatch } from "react-redux"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import NonUser from "@assets/images/non-user.svg"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import { images } from "@utils/imageUtils"

import { PlusIcon } from "../../../components/CreateTeeupGameplan/createTeeupGameplanStyles"
import { useCreateTeeUp } from "../CreateTeeUpContext/useCreateTeeUp"

import { Wrapper, Left, Title, LeftText } from "./peopleStyles"

import "../index.scss"

const CreateTeeUpPeople = ({
  settings,
  isHover,
  setIsHover,
  openInviteesModal,
  isInviteesError,
  inviteesAreaRef,
}) => {
  const dispatch = useDispatch()
  const {
    name: teeupName,
    userInfo,
    isInviteesOpen,
    invitees,
    setInvitees,
  } = useCreateTeeUp()
  const isDisabled = !teeupName

  const deleteInviteUser = id => {
    setInvitees(invitees.filter(user => user.id !== id))
  }

  const openInvitesScreen = () => {
    dispatch(setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.ADD_PEOPLE))
  }

  return (
    <section className="create-teeup__people" ref={inviteesAreaRef}>
      <Wrapper $isInviteesLength={invitees.length > 0}>
        <Left $isActive={invitees.length > 0} $isDisabled={isDisabled}>
          <Title>People</Title>
          <div
            className={cn({
              "create-teeup__invitees": invitees.length > 0,
            })}>
            {invitees.length > 0 ? (
              <>
                <div style={{ display: "flex" }}>
                  <div
                    className={cn("create-teeup__me", {
                      "create-teeup__me--open": invitees.length > 0,
                    })}
                    style={{
                      backgroundImage: `url(${
                        userInfo?.avatar || userInfo.user?.avatar
                      })`,
                    }}>
                    <img src={images.ndCrown} className="create-teeup__crown" />
                  </div>
                </div>

                {invitees.map(
                  ({
                    avatar,
                    firstName,
                    name,
                    userType,
                    label,
                    emails,
                    id,
                    contactMechanism,
                  }) => (
                    <div className="create-teeup__user" key={avatar + name}>
                      {avatar ? (
                        <div
                          className="create-teeup__user-avatar"
                          style={{
                            backgroundImage: `url(${avatar || NonUser})`,
                          }}></div>
                      ) : (
                        <div className="create-teeup__user-avatar create-teeup__user-manual">
                          {name ? name[0] : firstName[0]}
                        </div>
                      )}
                      {!isInviteesOpen && (
                        <div
                          className="create-teeup__user-avatar--cross"
                          onClick={() => {
                            deleteInviteUser(id)
                          }}>
                          <img
                            src={images.ndInviteesCrossActive}
                            style={{
                              width: 8,
                              height: 8,
                            }}
                            className="create-teeup__user-avatar--icon"
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
              </>
            ) : (
              <LeftText>Invite People</LeftText>
            )}
          </div>
        </Left>

        {invitees.length > 0 ? (
          <ButtonEl
            autoWidth
            isNoPadding
            text=""
            disabled={isDisabled}
            onClick={openInvitesScreen}>
            <PlusIcon name="circle-plus" />
          </ButtonEl>
        ) : (
          <ButtonEl
            autoWidth
            isNoPadding
            variant={BUTTON_VARIANTS.TRANSPARENT}
            text="Add"
            disabled={isDisabled}
            onClick={openInvitesScreen}
          />
        )}
      </Wrapper>

      {isInviteesError && (
        <p data-testid="inviteError" className="create-teeup__invite--error">
          You need to add at least one user
        </p>
      )}
    </section>
  )
}

export default CreateTeeUpPeople
