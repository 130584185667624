import React, { CSSProperties } from "react"

import "./index.scss"
import { BOX_SIZE, ROW_HEIGHT } from "@utils/suggestionUtils"

interface Props {
  styles: CSSProperties
}

const DaySeparator = ({ styles }: Props) => {
  //   @ts-ignore
  const topOffset = styles.top + ROW_HEIGHT - BOX_SIZE - 38
  // @ts-ignore
  const leftOffset = styles.left - 5

  return (
    <div
      className="days-separator-container"
      style={{ top: topOffset, left: leftOffset }}>
      <div className="horizontal-line"></div>
      <div className="vertical-line"></div>
      <div className="horizontal-line"></div>
    </div>
  )
}

export default DaySeparator
