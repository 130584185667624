import { useMemo } from "react"

export const useCurrentSuggestion = ({
  guestSuggestions,
  suggestionPreviewId,
  whereSuggestions,
}) => {
  return useMemo(() => {
    const suggestion = guestSuggestions.find(
      suggestion => suggestion.id === suggestionPreviewId
    )

    if (!suggestion)
      return whereSuggestions.find(
        suggestion => suggestion.id === suggestionPreviewId
      )

    return suggestion
  }, [guestSuggestions, suggestionPreviewId])
}
