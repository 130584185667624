import React, { useEffect, useMemo, useState } from "react"

import { AiOutlineLeft } from "react-icons/ai"
import { useRouteMatch } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { v4 as uuidv4 } from "uuid"

import { recoveryRequest } from "@actions/loginActions"
import SuccessIcon from "@assets/images/passwordChangeSuccess.svg"
import { Caption } from "@assets/styles/typographyStyles"
import { AuthLayout } from "hoc"

import {
  ForgotPasswordStep,
  CheckYourEmailStep,
  ResetPasswordStep,
  PasswordUpdatedStep,
} from "./atoms"
import { Page, BackWrapper, ForgotPasswordWrapper } from "./molecules"

const deviceId = uuidv4()

export const ForgotPassword = () => {
  const data = useRouteMatch()
  const history = useHistory()
  const { path } = data

  const [currentlyEmail, setCurrentlyEmail] = useState(null)
  const [step, setStep] = useState(1)

  const statusIcon = (step === 2 || step === 4) && SuccessIcon

  const handleClickSendRecoveryLink = (email, callback, setError) => {
    recoveryRequest(email || currentlyEmail, deviceId)
      .then(() => callback())
      .catch(() => {
        setError(`We couldn’t find a CooWe account associated with ${email}`)
      })
  }

  const useTitle = useMemo(() => {
    switch (true) {
      case step === 2:
        return "Check your email"

      case step === 3:
        return "Reset Password"

      case step === 4:
        return "Password Updated"

      default:
        return "Forgot Password"
    }
  }, [step])

  useEffect(() => {
    if (path === "/forgot-password/confirm/:token") {
      setStep(3)
    }
  }, [])

  return (
    <Page>
      <ForgotPasswordWrapper>
        <AuthLayout statusIcon={statusIcon} title={useTitle} center>
          {step === 1 && (
            <ForgotPasswordStep
              handleClickSendRecoveryLink={handleClickSendRecoveryLink}
              setStep={setStep}
              setCurrentlyEmail={setCurrentlyEmail}
            />
          )}
          {step === 2 && (
            <CheckYourEmailStep
              handleClickSendRecoveryLink={handleClickSendRecoveryLink}
              currentlyEmail={currentlyEmail}
            />
          )}
          {step === 3 && <ResetPasswordStep setStep={setStep} />}
          {step === 4 && <PasswordUpdatedStep />}
        </AuthLayout>
        {!(step === 3 || step === 4) && (
          <BackWrapper
            data-testid="backToLoginButton"
            onClick={() => history.push("/new-sign-in-email")}>
            <AiOutlineLeft />
            <Caption>Back to Login</Caption>
          </BackWrapper>
        )}
      </ForgotPasswordWrapper>
    </Page>
  )
}
