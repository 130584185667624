import { useEffect, useState } from "react"

import cn from "classnames"
import { useSelector } from "react-redux"

import { addTeeupInvitees } from "@actions/teeupActions"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserInfo } from "@selectors/user"
import { DETAILS_MODAL_CONTENT, getParticipantStatus } from "@utils/peopleUtils"
import DefaultModal from "components/ui/DefaultModal"
import { history } from "configureStore"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"
import { ActionButton } from "types/participant"

import ModalHeader from "../../../ui/DefaultModal/Header"

import Content from "./Content"
import ModalHead from "./ModalHead"

import "./index.scss"

const ParticipantDetails = ({ organizerId }) => {
  const [actionButtons, setActionButtons] = useState<ActionButton[]>([])
  const currentParticipant = useTeeupPeopleStore(
    state => state.currentParticipant
  )

  const isParticipantDetailsModalOpen = useTeeupPeopleStore(
    state => state.isParticipantDetailsModalOpen
  )
  const setIsParticipantDetailsModalOpen = useTeeupPeopleStore(
    state => state.setIsParticipantDetailsModalOpen
  )
  const detailsModalContent = useTeeupPeopleStore(
    state => state.detailsModalContent
  )
  const setDetailsModalContent = useTeeupPeopleStore(
    state => state.setDetailsModalContent
  )

  const teeUpId = useSelector(selectActiveTeeupId)
  const user = useSelector(selectUserInfo)

  const isInvited = currentParticipant?.status === "invited"
  const isMe = user.id === currentParticipant?.id
  const isOptionsShown = !isInvited && !isMe
  const isTabsSelected =
    detailsModalContent === DETAILS_MODAL_CONTENT.REACTIONS ||
    detailsModalContent === DETAILS_MODAL_CONTENT.SUGGESTIONS
  const isContentShown = (!isMe || isTabsSelected) && !isInvited
  const modalTitle = currentParticipant?.username
    ? `@${currentParticipant?.username}`
    : currentParticipant?.contactList[0]?.value

  const handleClose = () => setIsParticipantDetailsModalOpen(false)

  const handleResendInvite = () => {
    addTeeupInvitees(teeUpId, [{ id: currentParticipant?.id }])
  }

  const handleViewProfile = () => {
    if (!currentParticipant) return

    const url = isMe
      ? `/my-profile`
      : `/profile-2/${currentParticipant.username}`
    history.push(url)
  }

  useEffect(() => {
    return () => {
      setTimeout(
        () => setDetailsModalContent(DETAILS_MODAL_CONTENT.OPTIONS),
        200
      )
    }
  }, [isParticipantDetailsModalOpen])

  useEffect(() => {
    if (!currentParticipant || !organizerId) return

    const isOrganizerClicked = user.id === organizerId

    const buttons: ActionButton[] = [
      {
        text: "Suggestions",
        action: () => setDetailsModalContent(DETAILS_MODAL_CONTENT.SUGGESTIONS),
        isShown: !isInvited,
      },
      {
        text: "Reactions",
        action: () => setDetailsModalContent(DETAILS_MODAL_CONTENT.REACTIONS),
        isShown: !isInvited,
      },
      {
        text: "Resend Invite",
        action: handleResendInvite,
        isShown: isInvited && isOrganizerClicked && !isMe,
      },
      {
        text: "View User Profile",
        action: handleViewProfile,
        isShown: !!currentParticipant?.username,
      },
    ].filter(button => button.isShown)

    setActionButtons(buttons)
  }, [currentParticipant, organizerId])

  if (!currentParticipant) return false

  return (
    <DefaultModal open={isParticipantDetailsModalOpen} onClose={handleClose}>
      <ModalHeader
        onClose={handleClose}
        title={modalTitle || ""}
        isWithIndent
      />
      <div
        className={cn("modal-participant-details", {
          "modal-participant-tabs": isTabsSelected,
        })}>
        <ModalHead
          defaultAvatar={currentParticipant.name || currentParticipant.username}
          title={currentParticipant.name}
          subtitle={
            currentParticipant?.lastTeeupActivity || isInvited
              ? `Invited by ${currentParticipant?.invitedByUsername}`
              : ""
          }
          avatar={currentParticipant.avatar}
          status={getParticipantStatus(currentParticipant.status)}
          actionButtons={actionButtons}
          isOptionsShown={!isOptionsShown}
        />

        {isContentShown && <Content onClose={handleClose} />}
      </div>
    </DefaultModal>
  )
}

export default ParticipantDetails
