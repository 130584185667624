import GoogleMapReact from "google-map-react"

import { images } from "@utils/imageUtils"

import useInPersonTabState from "../../Hooks/useInPersonTabState"

import "./index.scss"

const Marker = ({ text, icon }) => (
  <div className="suggest-in-person-map__marker">
    <img src={icon} alt={text} />
    {text}
  </div>
)

const MapContainer = () => {
  const {
    mapCenter,
    setMapCenter,
    activePlace,
    setActivePlace,
    isSearchNearby,
    nearbyPlaces,
  } = useInPersonTabState()

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }
  }

  const mapClicked = mapProps => {
    const latLng = new window.google.maps.LatLng(mapProps.lat, mapProps.lng)
    const geocoder = new window.google.maps.Geocoder()

    geocoder.geocode({ latLng: latLng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        alert(status)
      }
      if (status == window.google.maps.GeocoderStatus.OK) {
        const formattedAddress = results[0].formatted_address.split(", ")

        setActivePlace({
          firstRow: formattedAddress[0],
          secondRow: formattedAddress.slice(1).join(", "),
        })
      }
    })

    setMapCenter({
      lat: mapProps.lat,
      lng: mapProps.lng,
    })
  }

  return (
    <div className="suggest-in-person-map">
      <GoogleMapReact
        center={mapCenter}
        zoom={13}
        options={{
          styles: [
            {
              featureType: "poi.business",
              elementType: "labels",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
          ],
          zoomControl: false,
          fullscreenControl: false,
        }}
        yesIWantToUseGoogleMapApiInternals
        onClick={mapClicked}>
        <Marker
          lat={mapCenter.lat}
          lng={mapCenter.lng}
          icon={
            activePlace
              ? images.ndSuggestMarkerSelected
              : images.ndSuggestMarker
          }
        />
        {isSearchNearby &&
          nearbyPlaces.length > 0 &&
          !activePlace &&
          nearbyPlaces.map((place, index) => (
            <Marker
              lat={place.lat}
              lng={place.lng}
              icon={images.ndSuggestSmallMarker}
              key={index}
            />
          ))}
      </GoogleMapReact>
      <div
        className="suggest-in-person-map__button"
        onClick={() => getCurrentLocation()}>
        Center on Current Location
      </div>
    </div>
  )
}

export default MapContainer
