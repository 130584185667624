import { useSelector } from "react-redux"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { SvgIcon } from "@components/common"
import { selectUserId } from "@selectors/activeTeeup"
import {
  selectTeeupPeople,
  selectTeeupReactionsBySuggestionId,
} from "@selectors/teeups"

import "./index.scss"

const Reactions = ({ suggestion }) => {
  const reactions = useSelector(
    selectTeeupReactionsBySuggestionId(suggestion.id)
  )
  const people = useSelector(selectTeeupPeople)
  const userId = useSelector(selectUserId)

  return (
    <div>
      {reactions.map(reaction =>
        reaction.reactedBy.map(user => {
          const userInfo = people[user]
          if (!userInfo) return null
          return (
            <div key={user} className="reaction-item">
              <div className="reaction-item-content">
                <div>
                  <ParticipantIcon
                    avatar={userInfo?.avatar}
                    defaultAvatar={userInfo?.name?.[0]}
                  />
                </div>
                <div className="reaction-item-info">
                  <h5>{userId === userInfo?.id ? "You" : userInfo?.name}</h5>
                  <p>{userInfo?.timezone}</p>
                </div>
              </div>

              <div>
                <SvgIcon
                  name={reaction.reactionId === 2 ? "thumb-up" : "thumb-down"}
                />
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

export default Reactions
