import { useCallback } from "react"

import { catchIsUserValid } from "@utils/catchIsUserValid"

import { checkLoginIdentity } from "../../actions/loginActions"
import { AUTH0_ACTION_TYPES } from "../../pages/Auth0ContentPage/constants"

export const useHandleAuth0Registration = ({
  user,
  isLoginInProgress,
  isRegistrationInProgress,
  socialRegistrationType,
  isRegisterCheckIdentity,
  setIsRegisterCheckIdentity,
  setIsRegisterSsoNames,
  setIsRegistrationInProgress,
  loginWithRedirect,
  handleAuthError,
  setIsIdentityVerified,
}) => {
  return useCallback(() => {
    if (
      !isLoginInProgress &&
      user &&
      isRegistrationInProgress &&
      socialRegistrationType &&
      isRegisterCheckIdentity
    ) {
      const provider = user.sub.split("|")[0]
      const uid = user.sub.split("|")[1]

      checkLoginIdentity({
        email: user.email,
        provider,
        uid,
        isLogin: false,
      })
        .then(() => {
          const isUserValid = catchIsUserValid(user)

          setIsRegistrationInProgress(false)
          setIsRegisterCheckIdentity(false)

          if (isUserValid) {
            setIsIdentityVerified(true)
            return
          }

          setIsRegisterSsoNames(true)

          setIsRegistrationInProgress(false)
          setIsRegisterCheckIdentity(false)
        })
        .catch(error => {
          handleAuthError(error, AUTH0_ACTION_TYPES.SIGN_UP)
          setIsRegistrationInProgress(false)
          setIsRegisterCheckIdentity(false)
        })
    }
  }, [
    user,
    isRegistrationInProgress,
    loginWithRedirect,
    socialRegistrationType,
    isLoginInProgress,
    isRegisterCheckIdentity,
  ])
}
