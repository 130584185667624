import styled from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  column-gap: 15px;
  margin-top: 15px;
  flex-grow: 1;
  overflow-y: auto;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    row-gap: 10px;
    margin-top: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    // overflow-y: clip;
  }
`
