import styled from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle5,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled(NTitle5)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NCaptionS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  text-align: center;
  margin: 10px 0 40px 0;
`
