import styled, { css } from "styled-components"

import MockUser from "@assets/images/mock-user.svg"
import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { SubtitleL, FontWeight } from "@assets/styles/typographyStyles"

export const InviteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`

export const InviteDescription = styled.div`
  width: calc(95% - 70px);
`

export const SignUpWrapper = styled.section`
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: var(--radicalRed);
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    .input-el--wrapper > input {
      border-radius: 67px;
    }
  }
`

export const SignUpForm = styled.form`
  width: 500px;
`

export const FormCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const FormCodeMessage = styled.p`
  font-size: 15px;
  font-family: greycliffcf-regular-salt;
  margin-bottom: 22px;
  margin-top: 8px;
`

export const SignUpButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    position: absolute;
    bottom: 44px;
    width: calc(100% - 34px);
  }
`

export const BackTextButton = styled(SubtitleL)`
  color: var(--radicalRed);
  cursor: pointer;
  padding: 12px 0px;
  font-weight: ${FontWeight.medium};
`

export const InputWrapper = styled.div`
  > div {
    margin-top: 8px;
  }
`

export const InputAvatar = styled.div`
  background: var(--gray7);
  width: 100px;
  border: 4px solid var(--white);
  box-shadow: var(--shadowBox1);
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url(${MockUser});

  ${({ picture }) =>
    picture &&
    css`
      background-image: url(${picture});
      background-size: cover;
    `};
`

export const InputAvatarEditIcon = styled.input`
  text-indent: -999em;
  position: relative;
  width: 32px;
  height: 32px;
  bottom: 45px;
  left: 52%;

  ${({ picture }) =>
    picture &&
    css`
      background-image: url(${picture});
      background-size: cover;
    `};
`
