import type { CSSProperties } from "react"

import { BOX_SIZE, roundToFuture15Minutes } from "@utils/suggestionUtils"

const disabledBoxStyles: CSSProperties = {
  backgroundColor: "rgb(246 246 246 / 71%)",
  zIndex: 10,
  top: 0,
  bottom: 0,
  position: "absolute",
  left: 0,
}

const DisabledBoxPart = ({ isCurrentTimePinShown }) => {
  const currentMinute = new Date().getMinutes()
  const DISABLED_BOX_WIDTH =
    BOX_SIZE - roundToFuture15Minutes(currentMinute) - 5

  if (!isCurrentTimePinShown) return false

  return (
    <div
      style={{
        ...disabledBoxStyles,
        right: DISABLED_BOX_WIDTH,
      }}
    />
  )
}

export default DisabledBoxPart
