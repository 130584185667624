import { EVENT_TYPES } from "@utils/calendarUtils"

export const getEventsColors = ({ eventType, isDecided }) => {
  if (isDecided) {
    return {
      backgroundColor: "var(--lightMint)",
      borderColor: "var(--lightMint4)",
      color: "var(--green2)",
    }
  }

  switch (eventType) {
    case EVENT_TYPES.teeup:
      return {
        backgroundColor: "var(--lightBlue3)",
        borderColor: "var(--lightBlue4)",
        color: "var(--darkBlue)",
      }
    case EVENT_TYPES.calendar:
      return {
        backgroundColor: "var(--primary-50)",
        borderColor: "var(--pink2)",
        color: "var(--red3)",
      }
    case EVENT_TYPES.not_avaliable:
      return {
        backgroundColor: "var(--white)",
        borderColor: "var(--lightGray5)",
        color: "var(--gray)",
        padding: 0,
      }
    default:
      return {
        backgroundColor: "var(--darkBlue2)",
        border: "2px dashed var(--darkBlue)",
        zIndex: 99,
      }
  }
}
