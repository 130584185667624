import { useMemo } from "react"

export const useNavigationLabel = daysList => {
  const getLabelDateFormat = date => {
    return date.format("MMM, Do")
  }

  return useMemo(() => {
    const start = getLabelDateFormat(daysList[0].date)
    const end = getLabelDateFormat(daysList.at(-1).date)

    return `${start} - ${end}`
  }, [daysList])
}
