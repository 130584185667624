import { useMemo } from "react"

export const useParsePeriod = ({
  currentPeriod,
  setParsedCurrentPeriod,
  reinitScrollLeft,
  startDate,
  dragScrollRef,
  pickerWidth,
}) => {
  useMemo(() => {
    let dateInd = 0

    const newPeriod = currentPeriod.reduce((acc, current) => {
      current.forEach(hour => {
        acc[dateInd] = hour
        dateInd++
      })

      return acc
    }, [])

    if (newPeriod?.length) {
      setParsedCurrentPeriod(newPeriod)

      reinitScrollLeft({
        startDate: null,
        nowDate: startDate,
        parsedCurrentPeriod: newPeriod,
        dragScrollRef,
        pickerWidth,
      })
      return
    }
  }, [currentPeriod])
}
