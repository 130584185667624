import styled, { css } from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NSubtitle,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  ${({ $isToggle, $isDescription }) => css`
    background-color: var(--white);
    border-top: 1px solid var(--lightGray5);
    border-bottom: 1px solid var(--lightGray5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    ${!$isToggle &&
    css`
      cursor: pointer;
    `};
    ${$isDescription &&
    css`
      align-items: flex-start;
    `};
  `};
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const Title = styled(NSubtitle)`
  font-weight: ${NFontWeight.semiBold};
`

export const Description = styled(NCaptionS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
`

export const ArrowIcon = styled(SvgIcon)`
  transform: rotate(-90deg);
`
