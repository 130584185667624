import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  ${({ $isInline, $isInner }) =>
    !$isInline &&
    css`
      background-color: var(--lightBlue4);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      & > button {
        padding: 4px;

        & > div > div {
          background: var(--darkBlue) !important;
        }
      }

      ${$isInner &&
      css`
        right: 0;
      `}
    `};
`

export const IconWrapper = styled.div`
  background-color: var(--lightBlue4);
  border-radius: 50%;
  display: flex;
  padding: 5px;
`

export const Icon = styled(SvgIcon)`
  width: 11px;
  height: 11px;
`
