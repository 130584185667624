import { SvgIcon } from "@components/common"

import "./index.scss"

interface Props {
  iconName: string
  color?: string
  title: string
  description: string
}

const EmptyConversation = ({ iconName, title, description, color }: Props) => {
  return (
    <div className="empty-conversation-container">
      <SvgIcon name={iconName} color={color} css={{ width: 98, height: 98 }} />

      <h4>{title}</h4>

      <p>{description}</p>
    </div>
  )
}

export default EmptyConversation
