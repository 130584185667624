import React from "react"

import _ from "lodash"
import { FaClock, FaMapMarkedAlt, FaCube } from "react-icons/fa"

import { gameplanType } from "@config/enums"
import strings from "@i18n"
import { selectUserId } from "@selectors/user"
import { AppColors } from "@theme"
import {
  markAsDecided,
  promoteToGameplan as promoteToGameplanBackendMiddleware,
} from "middlewares/backendMiddleware"

import { phoneRegExp } from "../constants/phone"
import { store } from "../index"

import {
  getLocaleTime,
  stringToDateFormat,
  isToday,
  isTomorrow,
  addDayTimestamp,
  normalizeTimeFromToday,
  isDateBeforeNow,
  getTimezoneToUtc,
  setSecondsToZero,
  getEndOfTheDay,
} from "./dateUtils"
import { gameplanTypes } from "./mappings"

const initialValue = {
  line1: null,
  line2: null,
  full: null,
  empty: true,
}

const GAMEPLAN_SOURCE_TYPE = {
  whenWorks: "WORKS_WHEN",
}

const defaultCustomDates = [
  "Next Week",
  "Today",
  "Tomorrow",
  "Next Friday",
  "Next Monday",
  "Next Saturday",
  "Next Year",
  "Next Month",
  "Next Weekend",
]

let defaultCustomTimes = [
  "Morning",
  "Afternoon",
  "Evening",
  "Night",
  "Midnight",
  "Dawn",
  "Sundown",
]

const permissionsText = {
  orgPermission: "Only Organizers can change this Game Plan",
  decidedPermission: "The Game Plan is Decided make it undecided to change",
}

const getGameplanValue = (suggestions, type, isTeeupInCreation) => {
  let value = { ...initialValue }

  if (suggestions && suggestions.length > 0) {
    let suggestion = isTeeupInCreation
      ? suggestions[0]
      : suggestions.find(suggestion => suggestion.isSelected === true)
    if (suggestion) {
      value = getSuggestionValue(suggestion, type)
    }
  }

  return value
}

const ALL_DAY = "All day"

const isAllDay = ({ startDate, endDate }) => {
  const endOfTheDay = getEndOfTheDay(startDate)
  return endOfTheDay === endDate
}

const getSuggestionValue = (suggestion, type, gameplan, title) => {
  // Maybe this should be part of suggestion formatting after receiving from backend? So it's only done once
  if (!suggestion) return {}
  if (!type) {
    type = suggestion.type
  }
  let processedValue = { ...initialValue }

  switch (type) {
    case GAMEPLAN_OPTIONS.when: {
      processedValue.placeholder1 = "Date"
      processedValue.placeholder2 = "Time"
      processedValue.placeholder = title ? `Suggest ${title}` : "Suggest When"

      if (!suggestion) {
        break
      }

      const {
        value,
        value2,
        startDate,
        endDate,
        isCustomDate,
        isCustomTime,

        showTime = true,
      } = suggestion

      if (!value && !value2 && !startDate && !endDate) {
        break
      }

      let time = null

      processedValue.isOnlyStartTime = true
      if (isCustomTime) {
        time = value2
        processedValue.isOnlyStartTime = false
      } else if (startDate && endDate) {
        const startFormatted = getLocaleTime(startDate)
        const endFormatted = getLocaleTime(endDate)
        if (startFormatted === endFormatted) {
          time = startFormatted
        } else {
          time = startFormatted + "-" + endFormatted
          processedValue.startTimeFormatted = startFormatted
          processedValue.endTimeFormatted = endFormatted
        }
      } else if (startDate) {
        time = getLocaleTime(startDate)
      }

      if (!showTime) {
        time = null
        processedValue.isOnlyStartTime = false
      }

      if (isCustomTime) {
        time = !isCustomDate && startDate ? getLocaleTime(startDate) : value2
      } else if (startDate && endDate) {
        const startFormatted = getLocaleTime(startDate)
        const endFormatted = getLocaleTime(endDate)
        if (isAllDay(suggestion)) {
          time = ALL_DAY
        } else if (startFormatted === endFormatted) {
          time = startFormatted
          processedValue.startTimeFormatted = startFormatted
        } else {
          time = startFormatted + "-" + endFormatted
          processedValue.startTimeFormatted = startFormatted
          processedValue.endTimeFormatted = endFormatted
        }
      } else if (startDate) {
        time = getLocaleTime(startDate)
      }

      let date = null
      if (isCustomDate && value) {
        date = value
        processedValue.isOnlyStartTime = false
      } else if (startDate && endDate) {
        const format = "ddd, MMM D"
        const startFormatted = stringToDateFormat(startDate, format)
        const endFormatted = stringToDateFormat(endDate, format)
        date =
          startFormatted === endFormatted
            ? startFormatted
            : startFormatted + "-" + endFormatted
      } else if (startDate) {
        date = stringToDateFormat(startDate, "dddd MMM D")
      }
      // processedValue = stringToDateFormat(suggestion.value, 'dddd MMM D, h:mm A')
      processedValue.line1 = date
      processedValue.line2 = time

      processedValue.full =
        (date ? date : "") + (date && time ? ", " : "") + (time ? time : "")
      processedValue.empty = false

      break
    }
    case GAMEPLAN_OPTIONS.where: {
      const { locationType } = suggestion
      const isOnline = locationType === locationTypeIds.online
      processedValue.placeholder1 = "Place name"
      processedValue.placeholder2 =
        locationType === locationTypeIds.online ? "URL" : "Location"
      processedValue.placeholder = title ? `Suggest ${title}` : "Suggest Where"
      processedValue.isOnline = isOnline
      // processedValue.placeholder2 = 'Location'

      if (!suggestion) {
        break
      }
      if (suggestion.value && suggestion.value.length > 0 && isOnline) {
        // need to also use this part for now, untill we will have old suggestion format
        const suggestionOnlineValues = getSuggestionOnlineValuesFromLink(
          // suggestion.details,
          suggestion.value
        )
        processedValue.type = suggestionOnlineValues?.type
          ? suggestionOnlineValues?.type
          : suggestion.value
        processedValue.line1 = suggestionOnlineValues?.link
          ? suggestionOnlineValues.link
          : suggestion.value
        processedValue.line2 =
          suggestionOnlineValues?.type ||
          suggestion.onlineTypeOfInviteLink?.link ||
          ""
        processedValue.full = suggestion.fullDetails
          ? suggestion.fullDetails
          : suggestion.details || suggestion.value
        processedValue.empty = false
      } else if (suggestion.value && suggestion.value.length > 0 && !isOnline) {
        processedValue.type = suggestion.type
        processedValue.line1 = suggestion?.details
          ? suggestion.details
          : suggestion.value

        processedValue.line2 = suggestion?.details ? suggestion.value : "Other"
        processedValue.full = suggestion.fullDetails
          ? suggestion.fullDetails
          : suggestion.details || suggestion.value

        processedValue.empty = false
      }

      // if (suggestion.placeName && suggestion.placeName.length > 0) {
      //     processedValue.line1 = suggestion.placeName
      //     processedValue.full = `${processedValue.line1}`
      //     processedValue.empty = false
      // }
      // if (suggestion.details && suggestion.details.length > 0) {
      //   const suggestionOnlineValues = getSuggestionOnlineValuesFromLink(
      //     suggestion.details,
      //     suggestion.value
      //   )

      //   if (suggestionOnlineValues) {
      //     // freestyle suggestion contain link
      //     processedValue.type = suggestionOnlineValues.type
      //     processedValue.line1 =
      //       suggestionOnlineValues.otherName || suggestionOnlineValues.type
      //     processedValue.line2 = getSuggestionDetailValue(
      //       suggestion.details,
      //       suggestionOnlineValues.type,
      //       suggestionOnlineValues.link ||
      //         suggestionOnlineValues?.onlineTypeOfInviteLink?.link
      //     )
      //     processedValue.full = `${processedValue.line1}: ${processedValue.line2}`
      //     processedValue.empty = false
      //   } else {
      //     processedValue.line1 = suggestion.fullDetails?.split(",")[0]
      //     processedValue.line2 = suggestion.details.replace(/(\r)/gm, "")
      //     processedValue.full = suggestion.fullDetails
      //       ? suggestion.fullDetails
      //       : suggestion.details
      //   }
      // }

      if (suggestion.fullDetails && suggestion.fullDetails.length > 0) {
        processedValue.fullDetails = suggestion.fullDetails
      }
      break
    }
    case GAMEPLAN_OPTIONS.what: {
      processedValue.placeholder = title ? `Suggest ${title}` : "Suggest What"
      processedValue.placeholder1 = "Suggest What"
      processedValue.line1 = suggestion.value
      processedValue.full = suggestion.value
      processedValue.empty = !suggestion.value
      break
    }
    case "startsWhen": {
      processedValue.placeholder1 = "Starts when"
      processedValue.placeholder2 = "Starts when"
      processedValue.placeholder = "Starts when"
      // processedValue.placeholder2 = 'Location'

      if (!suggestion) {
        break
      }

      const { startDate, peopleCount } = suggestion
      // const { dateNum, dateType } = getTimeDifference(startDate)
      // const time = gameplan?.conditionTime
      //     ? getTimeFormat(gameplan.conditionTime)
      //     : null
      const time = null
      let date
      if (isToday(startDate)) {
        date = "Today"
      } else if (isTomorrow(startDate)) {
        date = "Tomorrow"
      } else {
        date = addDayTimestamp(startDate)
      }
      const isConditionTimeSet = time && gameplan?.conditionsMet ? true : false

      if (isConditionTimeSet) {
        processedValue.line1 = `Starts ${date} at`
        processedValue.line2 = `${time} ${peopleCount}${
          peopleCount < gameplan?.goingPeopleCount ? "+" : ""
        } with people`
      } else {
        processedValue.line1 = `Starts ${date} when`
        processedValue.line2 = `${peopleCount} people can come`
      }

      processedValue.full += `${processedValue.line1}, ${processedValue.line2}`
      break
    }
    case "whenWorks": {
      processedValue.placeholder1 = "When works"
      processedValue.placeholder2 = "When works"
      processedValue.placeholder = "When works"

      if (!suggestion) {
        break
      }

      processedValue.line1 = "Determining WhenWorks"
      processedValue.full += `${processedValue.line1}, ${processedValue.line2}`
      break
    }
  }

  return processedValue || {}
}

const suggestionDetailValueRegExp = {
  zoom: {
    topic: /Topic:(.*?)(\n|\r)/,
    meeting: /Meeting ID:(.*?)(\n|\r)/,
  },
  goToMeeting: {
    topic: /(^.*$)/m,
  },
  webEx: {
    topic: /Meeting Topic: (.*)/g,
    meeting: /Meeting number: (\d*)/g,
  },
}

const getSuggestionDetailValue = (details, type, link) => {
  switch (type) {
    case suggestionOnlineTypeOfLinks.zoom: {
      if (suggestionDetailValueRegExp.zoom.topic.test(details))
        return suggestionDetailValueRegExp.zoom.topic.exec(details)[1]
      else if (suggestionDetailValueRegExp.zoom.meeting.test(details))
        return (
          "Meeting ID: " +
          suggestionDetailValueRegExp.zoom.meeting.exec(details)[1]
        )
      else return link
    }
    case suggestionOnlineTypeOfLinks.googleMeet: {
      return link
    }
    case suggestionOnlineTypeOfLinks.goToMeeting: {
      return suggestionDetailValueRegExp.goToMeeting.topic.test(details)
        ? suggestionDetailValueRegExp.goToMeeting.topic.exec(details)[1]
        : link
    }
    case suggestionOnlineTypeOfLinks.webEx: {
      if (suggestionDetailValueRegExp.webEx.topic.test(details))
        return suggestionDetailValueRegExp.webEx.topic.exec(details)[1]
      else if (suggestionDetailValueRegExp.webEx.meeting.test(details))
        return (
          "Meeting ID: " +
          suggestionDetailValueRegExp.webEx.meeting.exec(details)[1]
        )
      else return link
    }
    case suggestionOnlineTypeOfLinks.microsoftTeams: {
      return link
    }
    case suggestionOnlineTypeOfLinks.slack: {
      return link
    }
    case suggestionOnlineTypeOfLinks.discord: {
      return link
    }
    case suggestionOnlineTypeOfLinks.whatsApp: {
      return link
    }
    case suggestionOnlineTypeOfLinks.phone: {
      return link
    }
    case suggestionOnlineTypeOfLinks.other: {
      return link
    }
    // No default
  }
  return ""
}

const GAMEPLAN_OPTIONS = {
  whenWorks: "worksWhen",
  startsWhen: "startsWhen",
  when: "when",
  what: "what",
  where: "where",
}

const locationTypeIds = {
  realWorld: 1,
  online: 2,
}

const isMultiTimezone = (people, participants) => {
  let usersTimeZonesUTC = []
  let timezone
  for (let i = 0, len = participants.length; i < len; i++) {
    let participant = people[participants[i]]
    const participantTimezoneUTC = getTimezoneToUtc(participant.timezone)

    if (!participant.timezone) {
      continue
    }

    if (!timezone) {
      timezone = participantTimezoneUTC
      usersTimeZonesUTC.push(participantTimezoneUTC)
      continue
    }
    if (
      participantTimezoneUTC !== timezone ||
      !usersTimeZonesUTC.includes(participantTimezoneUTC)
    ) {
      usersTimeZonesUTC.push(participantTimezoneUTC)
      if (usersTimeZonesUTC.length > 1) break
    }
  }

  return usersTimeZonesUTC.length > 1
}

const formatTeeupGameplanOptions = (
  userId,
  teeup,
  gameplanOptions,
  teeupPeople
) => {
  let suggestionsReactions = {} // suggestionId : reactions
  let gameplans = []
  let suggestions = {}
  let isWhereDecided = false
  let isStartsWhenConditionMet = false

  gameplanOptions.forEach(gameplanOption => {
    const { icon, id, title, type, isPrimary, statusId, sourceType } =
      gameplanOption

    let gameplan = {
      type,
      title,
      icon,
      optionId: id,
      suggestions: [],
      isPrimary,
      statusId,
      sourceType,
    }

    if (
      gameplan.type === GAMEPLAN_OPTIONS.startsWhen ||
      gameplan.type === GAMEPLAN_OPTIONS.whenWorks
    ) {
      const firstSuggestion = gameplanOption.suggestions[0] || {}
      gameplan.availability = firstSuggestion.availability
      gameplan.peopleCount = firstSuggestion.peopleCount
      gameplan.startDate = firstSuggestion.startDate

      let filteredAvailabilities =
        gameplan.availability?.filter(availability => {
          const person = teeupPeople[availability.userId]
          return person && person.status === "going" ? true : false
        }) || []
      filteredAvailabilities = _.uniqBy(filteredAvailabilities, "userId")
      const availabilitiesSortedByStartTime = _.sortBy(
        filteredAvailabilities,
        "startTime"
      )
      const availabilitiesSortedByEndTime = _.sortBy(
        filteredAvailabilities,
        "endTime"
      )
      let curLoad = 0
      let maxLoad = 0
      let startIndex = 0
      let endIndex = 0
      let conditionTime

      while (
        startIndex < availabilitiesSortedByStartTime.length &&
        endIndex < availabilitiesSortedByEndTime.length
      ) {
        if (
          normalizeTimeFromToday(
            availabilitiesSortedByStartTime[startIndex].startTime
          ) <=
          normalizeTimeFromToday(
            availabilitiesSortedByEndTime[endIndex].endTime
          )
        ) {
          curLoad +=
            teeupPeople[availabilitiesSortedByStartTime[startIndex].userId]
              .additionalCount + 1
          if (curLoad > maxLoad) {
            maxLoad = Math.max(maxLoad, curLoad)
            conditionTime =
              availabilitiesSortedByStartTime[startIndex].startTime
          }
          startIndex++
        } else {
          curLoad -=
            teeupPeople[availabilitiesSortedByEndTime[endIndex].userId]
              .additionalCount + 1
          endIndex++
        }
      }
      if (maxLoad >= gameplan.peopleCount) {
        gameplan.conditionsMet = true
        isStartsWhenConditionMet = true
      } else {
        gameplan.conditionsMet = false
      }
      gameplan.currentPeopleCount = maxLoad
      gameplan.conditionTime = conditionTime
    }
    gameplans.push(gameplan)
    gameplanOption.suggestions.forEach(backSuggestion => {
      const { reactions, id, time } = backSuggestion
      let suggestion = formatSuggestion(backSuggestion, gameplan)
      let suggestionId = id
      if (!suggestionId) {
        suggestionId = time && time.length > 0 ? time[0].id : null
        suggestion.id = suggestionId
      }
      suggestions[suggestionId] = suggestion
      suggestionsReactions[id] = reactions
      gameplan.suggestions.push(suggestion)
      if (gameplanOption.typeId === gameplanTypes.worksWhen) {
        gameplan.suggestions = gameplanOption.suggestions
      } else if (
        suggestion.type === gameplanTypes.reverse[2] &&
        suggestion.decided
      ) {
        isWhereDecided = true
      }
    })
  })
  //
  // if (
  //     isWhereDecided &&
  //     isStartsWhenConditionMet &&
  //     teeup.status === teeupStatusKeys.planning
  // ) {
  //     globalDispatch({
  //         type: actionTypes.TEEUP_UPDATE_STATUS,
  //         payload: {
  //             status: teeupStatusTypes[2],
  //             teeupId: teeup.id,
  //             userId,
  //         },
  //     })
  // }

  return { teeupId: teeup.id, gameplans, suggestionsReactions, suggestions }
}

const getSelectedGameplan = (gameplans, type) => {
  let selectedSuggestion = null

  gameplans.forEach(gameplan => {
    if (selectedSuggestion) {
      return
    }

    gameplan.type === type &&
      gameplan.suggestions.forEach(suggestion => {
        if (selectedSuggestion) {
          return
        }

        if (suggestion && (suggestion.selected || suggestion.decided)) {
          selectedSuggestion = suggestion
        }
      })
  })
  return selectedSuggestion
}

const validateSuggestion = (createdSuggestions, suggestion, type) => {
  const currentSuggestions = Object.values(createdSuggestions).filter(
    s => s.optionId === suggestion.optionId
  )
  let returnValue = true
  const parsedSuggestion = getSuggestionValue(suggestion, type)

  if (type === GAMEPLAN_OPTIONS.where) {
    if (parsedSuggestion?.type) {
      for (let current of currentSuggestions) {
        const parsedCurrentSuggestion = getSuggestionValue(current, type)

        // comparing type and the url
        if (
          parsedCurrentSuggestion.full === parsedSuggestion.full &&
          parsedCurrentSuggestion.line2 === parsedSuggestion.line2
        ) {
          returnValue = false
          break
        }
      }
    } else {
      returnValue = false
    }
  } else if (type === GAMEPLAN_OPTIONS.when) {
    for (let current of currentSuggestions) {
      const parsedCurrentSuggestion = getSuggestionValue(current, type)

      if (parsedCurrentSuggestion.full === parsedSuggestion.full) {
        returnValue = false
        break
      }
    }
  }
  return returnValue
}

const formatSuggestion = (
  {
    id,
    typeId,
    optionId,
    type,
    selected,
    isSelected = selected || false,
    isDecided = false,
    value,
    details,
    fullDetails,
    latitude,
    longitude,
    googlePlaceId,
    isCustomDate = false,
    isCustomTime = false,
    isTimeZoneEnabled,
    customDate,
    customTime,
    startDate,
    endDate,
    createdBy,
    creator,
    teeupUserSuggestions,
    locationType,
    date,
    comment,
    time = [],
    isWithdrawn,
    when = {},
    where = {},
  },
  gameplan
) => {
  if (_.isString(createdBy)) {
    createdBy = Number.parseInt(createdBy)
  }

  if (_.isString(id)) {
    id = id === "" ? undefined : Number.parseInt(id)
  }
  const state = store.getState()
  const userId = selectUserId(state)
  const seenInfo =
    teeupUserSuggestions &&
    teeupUserSuggestions.find(suggestion => suggestion.userId === userId)
  const fullDetailsDecoded = decodeURIComponent(fullDetails)
  const commentDecoded = decodeURIComponent(comment)

  let suggestion = {
    id,
    optionId,
    selected: isSelected, // first one is selected
    decided: isDecided,
    locationType,
    value: customDate ? customDate : value,
    value2: customTime,
    details,
    fullDetails: fullDetailsDecoded,
    latitude,
    longitude,
    googlePlaceId,
    isCustomDate,
    isCustomTime,
    isTimeZoneEnabled,
    startDate,
    endDate,
    createdBy,
    creator,
    seenInfo,
    date,
    comment: commentDecoded,
    time,
    isWithdrawn,
    customDate: customDate ? customDate : value,
    customTime,
    duration: when?.duration || null,
  }

  if (typeId === 4 && gameplan) {
    // if(isToday(startDate)){
    //     suggestion.startDate = 'Today'
    // }else if(isTomorrow(startDate)){
    //     suggestion.startDate = 'Tomorrow'
    // }else{
    //     suggestion.startDate = addDayTimestamp(startDate)
    // }
    suggestion.peopleCount = gameplan.peopleCount
    suggestion.startDate = gameplan.startDate
    suggestion.availability = []
  }
  if (type) {
    suggestion.type = type
  }

  if (typeId) {
    suggestion.type = gameplanTypes.reverse[typeId]
  }

  return suggestion
}

const DATE_SELECT_TYPES = {
  date: "date",
  time: "time",
  duration: "duration",
  timeDuration: "timeDuration",
  timeRange: "timeRange",
}

const whenTypeIds = {
  specificTime: 1,
  freestyleText: 2,
}

const getGameplanIcon = gameplan => {
  if (gameplan.icon) {
    return gameplan.icon
  }
  if (gameplan.type === "when") {
    return <FaClock style={{ transform: "scale(0.8)" }} />
  } else if (gameplan.type === "where") {
    return (
      <FaMapMarkedAlt style={{ transform: "scale(0.8)", color: "#767676" }} />
    )
  } else {
    return <FaCube />
  }
}

const getGameplanTitle = gameplan => {
  if (gameplan.type === GAMEPLAN_OPTIONS.when) {
    return GAMEPLAN_OPTIONS.when
  } else if (gameplan.type === GAMEPLAN_OPTIONS.where) {
    return GAMEPLAN_OPTIONS.where
  } else {
    return gameplan.title
  }
}

const getNotAllowedIconTintColor = allowed => ({
  tintColor: AppColors.brand[allowed ? "darkGrey2" : "warmGrey2"],
})

const getNotAllowedTextColor = allowed =>
  allowed ? {} : { color: AppColors.brand.warmGrey2 }

const getSuggestionStatus = (newSuggestions, suggestion, userId) => {
  const { decided, selected } = suggestion
  const isNew = isNewSuggestion(newSuggestions, suggestion, userId)

  let suggestionStatus = "usual"

  if (decided) {
    suggestionStatus = "decided"
  }
  if (selected) {
    suggestionStatus = "selected"
  }
  if (isNew) {
    suggestionStatus = "new"
  }

  return suggestionStatus
}

const isNewSuggestion = (newSuggestions, suggestion, userId) => {
  const isNew = newSuggestions && suggestion && newSuggestions[suggestion.id]

  return isNew && (suggestion.createdBy !== userId || !userId)
}

const isPastSuggestion = (suggestion, compareType) => {
  if (!suggestion) {
    return false
  }

  const { startDate, endDate, type } = suggestion

  if (type === GAMEPLAN_OPTIONS.where) {
    return false
  }

  return isDateBeforeNow(
    endDate ? endDate : startDate,
    undefined,
    !endDate && compareType ? compareType : undefined
  )
}

const promoteToGameplan = ({
  suggestion,
  decidedGameplanId,
  permissions,
  activeTeeupId,
  isRemove = false,
}) => {
  const { id: suggestionId, type, optionId } = suggestion
  const { allowSelectGameplan, allowDecideGameplan } = permissions
  if (decidedGameplanId > -1) {
    const typeTitle = gameplanType[type] || "Gameplan"
    // if (allowSelectGameplan && allowDecideGameplan) {
    //     showOverlayModal({
    //         title: `${typeTitle} is already Decided`,
    //         description:
    //             'Do you still want to change the suggestion on the Game Plan? The row will be reset to Not-Decided.',
    //         rightButton: {
    //             text: 'Change',
    //             onPress: () => {
    //                 // mark existing "decided" gameplan and undecided
    //                 globalDispatch({
    //                     type: actionTypes.MARK_AS_DECIDED,
    //                     payload: {
    //                         suggestionId: decidedGameplanId,
    //                         isDecided: false,
    //                         callbackAfterDecided: () =>
    //                             globalDispatch({
    //                                 type: isRemove
    //                                     ? actionTypes.REMOVE_FROM_GAMEPLAN
    //                                     : actionTypes.PROMOTE_TO_GAMEPLAN,
    //                                 payload: {
    //                                     suggestionId,
    //                                     type,
    //                                     teeupId: activeTeeupId,
    //                                     gameplanOptionId: optionId,
    //                                 },
    //                             }),
    //                     },
    //                 })
    //             },
    //         },
    //     })
    // } else {
    //     Toast.show(`${typeTitle} is already Decided!`, {
    //         bottomOffset: 8,
    //     })
    // }
    // } else {
    //     if (allowSelectGameplan) {
    //         globalDispatch({
    //             type: isRemove
    //                 ? actionTypes.REMOVE_FROM_GAMEPLAN
    //                 : actionTypes.PROMOTE_TO_GAMEPLAN,
    //             payload: {
    //                 suggestionId,
    //                 type,
    //                 teeupId: activeTeeupId,
    //                 gameplanOptionId: optionId,
    //             },
    //         })
    //     } else {
    //         Toast.show(permissionsText.orgPermission, {
    //             bottomOffset: 8,
    //         })
    //     }
  }
}

const getDropTargetText = ({ permissions, decidedGameplanId }) => {
  let dropText = ""
  const { allowSelectGameplan, allowDecideGameplan } = permissions
  if (allowSelectGameplan) {
    if (decidedGameplanId > -1 && allowDecideGameplan) {
      dropText = permissions.decidedPermission
    }
  } else {
    dropText = permissions.orgPermission
  }

  return dropText
}

const isRemovedSuggestion = (removedSuggestions, suggestion, userId) => {
  const isRemoved =
    removedSuggestions &&
    suggestion &&
    removedSuggestions[suggestion.id] &&
    removedSuggestions[suggestion.id].isRemoved
  return isRemoved && (suggestion.createdBy !== userId || !userId)
}

const getSuggestionsToDisplay = (suggestions = []) => {
  const result = []
  const suggest = Array.isArray(suggestions)
    ? suggestions
    : Object.values(suggestions)
  suggest.forEach(suggestion => {
    if (
      suggestion &&
      (Object.keys(suggestion).includes("id") ||
        (suggestion.date && suggestion.date.length > 0))
    ) {
      result.push(suggestion)
    }
  })
  return result
}

const getUserReactions = (suggestions = [], userId) => {
  // const userReactions = []
  // const suggest = Array.isArray(suggestions)
  //   ? suggestions
  //   : Object.values(suggestions)
  // suggest.forEach(s => {
  //   if (s.time && s.time.length > 0) {
  //     s.time.forEach(t => {
  //       if (t.reactions && t.reactions.length > 0) {
  //         t.reactions.forEach(r => {
  //           if (r.reactedBy.includes(userId)) {
  //             userReactions.push({
  //               type: r.reactionId,
  //               values: [t.id],
  //             })
  //           }
  //         })
  //       }
  //     })
  //   }
  // })
  // sugg
  // return userReactions
}

const formatSuggestionOut = (suggestion, type, isRemakeOrDuplicate = false) => {
  const {
    startDate,
    endDate,
    value = null,
    value2,
    isTimeZoneEnabled,
    isCustomDate,
    isCustomTime,
    details,
    fullDetails,
    lat,
    lng,
    googlePlaceId,
    optionId,
    locationType,
    isDecided,
    selected = false,
    decided = false,
  } = suggestion

  type = type || suggestion.type

  const formatted = {}

  formatted.isSelected = selected
  formatted.isDecided = isDecided || decided

  if (!isRemakeOrDuplicate) {
    formatted.gameplanOptionId = optionId
  }

  switch (type) {
    case GAMEPLAN_OPTIONS.when: {
      const withRemovedSecondsStartDate = setSecondsToZero(startDate)
      const withRemovedSecondsEndDate = setSecondsToZero(endDate)
      formatted.customDate = value || ""
      formatted.customTime = value2 || ""
      formatted.startDate = withRemovedSecondsStartDate
      formatted.endDate = withRemovedSecondsEndDate
      formatted.isTimeZoneEnabled = isTimeZoneEnabled
      formatted.isCustomDate = isCustomDate
      formatted.isCustomTime = isCustomTime

      break
    }
    case GAMEPLAN_OPTIONS.where: {
      formatted.details = details
      formatted.googlePlaceId = googlePlaceId
      formatted.latitude = lat
      formatted.longitude = lng
      formatted.locationType = locationType
      formatted.fullDetails = fullDetails
      if (value) {
        formatted.value = value
      }

      break
    }
    case GAMEPLAN_OPTIONS.what: {
      formatted.value = value || ""

      break
    }
    // No default
  }

  return formatted
}

const suggestionOnlineTypeOfLinks = {
  zoom: "Zoom",
  slack: "Slack",
  webEx: "WebEx",
  skype: "Skype",
  microsoftTeams: "Microsoft Teams",
  facebookMessengerRoom: "Facebook Messenger Room",
  googleHangouts: "Google Hangouts",
  discord: "Discord",
  goToMeeting: "GoToMeeting",
  googleMeet: "Meet",
  whatsApp: "WhatsApp",
  phone: "Phone",
  other: "Other",
}

const suggestionOnlineLinksRegExp = {
  zoom: /((h|H)ttps?:\/\/)?(?:\w+.)?zoom.us\/(j|u)\/(\w+)(\?+\S+)?/,
  slack: /((h|H)ttps?:\/\/)?(j|J)(oin.slack.com\/)+\S+/,
  slackApp: /((h|H)ttps?:\/\/)?(app.slack.com\/)+\S+/,
  webEx: /((h|H)ttps?:\/\/)?\w+(.webex.com\/)+\S+/,
  skype: /((h|H)ttps?:\/\/)?(j|J)(oin.skype.com\/)+\S+/,
  microsoftTeams:
    /((h|H)ttps?:\/\/)?(t|T)(eams.microsoft.com\/l\/meetup-join\/)+\S+/,
  facebookMessengerRoom: /((h|H)ttps?:\/\/)?(m|M)(sngr.com\/)+\S+/,
  googleHangouts: /((h|H)ttps?:\/\/)?(h|H)(angouts.google.com\/)+\S+/,
  googleMeet: /((h|H)ttps?:\/\/)?(m|M)(eet.google.com\/)+\S+/,
  discord: /((h|H)ttps?:\/\/)?(d|D)(iscord.gg\/)+\S+/,
  discordCom: /((h|H)ttps?:\/\/)?(d|D)(iscord.com\/)+\S+/,
  goToMeeting: /((h|H)ttps?:\/\/)?((g|G)lobal.gotomeeting.com\/join\/)+\d+/,
  whatsApp: /((h|H)ttps?:\/\/)?(wa.me\/)+\S+/,
  phone: phoneRegExp,
}

const getSuggestionOnlineTypeOfLink = (linkWithMetadata, suggestionValue) => {
  if (suggestionOnlineLinksRegExp.googleMeet.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.googleMeet,
    }
  } else if (suggestionOnlineLinksRegExp.zoom.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.zoom,
    }
  } else if (suggestionOnlineLinksRegExp.slack.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.slack,
    }
  } else if (suggestionOnlineLinksRegExp.slackApp.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.slack,
    }
  } else if (suggestionOnlineLinksRegExp.webEx.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.webEx,
    }
  } else if (suggestionOnlineLinksRegExp.skype.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.skype,
    }
  } else if (
    suggestionOnlineLinksRegExp.microsoftTeams.test(linkWithMetadata)
  ) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.microsoftTeams,
    }
  } else if (
    suggestionOnlineLinksRegExp.facebookMessengerRoom.test(linkWithMetadata)
  ) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.facebookMessengerRoom,
    }
  } else if (
    suggestionOnlineLinksRegExp.googleHangouts.test(linkWithMetadata)
  ) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.googleHangouts,
    }
  } else if (suggestionOnlineLinksRegExp.discord.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.discord,
    }
  } else if (suggestionOnlineLinksRegExp.discordCom.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.discord,
    }
  } else if (suggestionOnlineLinksRegExp.goToMeeting.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.goToMeeting,
    }
  } else if (suggestionOnlineLinksRegExp.whatsApp.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.whatsApp,
    }
  } else if (suggestionOnlineLinksRegExp.phone.test(linkWithMetadata)) {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.phone,
    }
  } else {
    return {
      link: linkWithMetadata,
      type: suggestionOnlineTypeOfLinks.other,
      otherName: suggestionValue,
    }
  }
}

const getSuggestionOnlineMetadata = (link, type) => {
  const metadata = []

  switch (type) {
    case suggestionOnlineTypeOfLinks.zoom: {
      const roomOwnerRegExp =
        /(.*) is inviting you to a scheduled Zoom meeting./g
      const topicRegExp = /Topic: ((.*) Join Zoom|.*)/g
      const meetingIdRegExp = /Meeting ID: ([\d ]*)/g
      const passwordRegExp = /Password: (\d*)/g
      const passcodeRegExp = /Passcode: (.*)/g

      const roomOwner = roomOwnerRegExp.exec(link)
      const topic = topicRegExp.exec(link)
      const meetingId = meetingIdRegExp.exec(link)
      const password = passwordRegExp.exec(link)
      const passcode = passcodeRegExp.exec(link)

      if (roomOwner && roomOwner[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.roomOwner,
          value: roomOwner[1],
        })
      if (topic && topic[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.topic,
          value: topic[1].split(" Join Zoom")[0],
        })
      if (meetingId && meetingId[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.meetingId,
          value: meetingId[1],
        })
      if (password && password[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.password,
          value: password[1],
        })

      if (passcode && passcode[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.passcode,
          value: passcode[1],
        })

      if (!meetingId && !password) {
        const meetingAndPasswordRegExp =
          /https?:\/\/\w+.zoom.us\/j\/(\d+)\?+pwd=+(\S+)/g
        const meetingAndPassword = meetingAndPasswordRegExp.exec(link)
        if (meetingAndPassword && meetingAndPassword[1])
          metadata.push({
            label: strings.suggestionOnlineLinks.meetingId,
            value: meetingAndPassword[1],
          })
      }

      break
    }
    case suggestionOnlineTypeOfLinks.webEx: {
      const topicRegExp = /Meeting Topic: (.*)/g
      const meetingIdRegExp = /Meeting number: (\d*)/g

      const topic = topicRegExp.exec(link)
      const meetingId = meetingIdRegExp.exec(link)

      if (topic && topic[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.topic,
          value: topic[1],
        })
      if (meetingId && meetingId[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.meetingId,
          value: meetingId[1],
        })

      break
    }
    case suggestionOnlineTypeOfLinks.goToMeeting: {
      const topicRegExp = /(^.*$)/m
      const numberRegExp =
        /For supported devices, tap a one-touch number below to join instantly.[^:]+: (.+)/g
      const oneTouchNumberRegExp = /- One-touch: tel:([\d#+,\\]+)/g
      const accessCodeRegExp = /Access Code: (\d\d\d-\d\d\d-\d\d\d)/g

      const topic = topicRegExp.exec(link)
      const number = numberRegExp.exec(link)
      const oneTouchNumber = oneTouchNumberRegExp.exec(link)
      const accessCode = accessCodeRegExp.exec(link)

      if (topic && topic[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.topic,
          value: topic[1],
        })
      if (number && number[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.number,
          value: number[1],
        })
      if (oneTouchNumber && oneTouchNumber[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.oneTouchNumber,
          value: oneTouchNumber[1],
        })
      if (accessCode && accessCode[1])
        metadata.push({
          label: strings.suggestionOnlineLinks.accessCode,
          value: accessCode[1],
        })

      break
    }
    // No default
  }

  return metadata
}

const getSuggestionOnlineValuesFromLink = (
  linkWithMetadata,
  suggestionValue
) => {
  const linkObject = getSuggestionOnlineTypeOfLink(
    linkWithMetadata,
    suggestionValue
  )

  if (linkObject) {
    const metadata = getSuggestionOnlineMetadata(
      linkWithMetadata,
      linkObject.type
    )

    return {
      ...linkObject,
      metadata,
      linkWithMetadata,
    }
  } else {
    return null
  }
}

const promoteOrDecide = (suggestion, type) => {
  if (type === GAMEPLAN_OPTIONS.when) {
    if (!suggestion.startDate) {
      promoteToGameplanBackendMiddleware({
        suggestionId: suggestion.id,
        type,
      })
      return
    }
    if (suggestion.startDate && suggestion.endDate)
      markAsDecided(suggestion?.id, true)

    return
  }

  if (type === GAMEPLAN_OPTIONS.where) {
    const suggestionValues = getSuggestionValue(suggestion)
    if (suggestionValues?.line1) {
      if (suggestionValues?.line2) {
        markAsDecided(suggestion?.id, true)
        return
      }
      promoteToGameplanBackendMiddleware({
        suggestionId: suggestion.id,
        type,
      })
    }
  }
}

export {
  getSuggestionValue,
  getGameplanValue,
  GAMEPLAN_OPTIONS,
  DATE_SELECT_TYPES,
  GAMEPLAN_SOURCE_TYPE,
  formatTeeupGameplanOptions,
  getSuggestionOnlineTypeOfLink,
  getGameplanIcon,
  getGameplanTitle,
  getNotAllowedIconTintColor,
  getNotAllowedTextColor,
  getSuggestionStatus,
  isNewSuggestion,
  isPastSuggestion,
  formatSuggestion,
  locationTypeIds,
  promoteToGameplan,
  getDropTargetText,
  permissionsText,
  isRemovedSuggestion,
  getSuggestionsToDisplay,
  getUserReactions,
  defaultCustomDates,
  defaultCustomTimes,
  isMultiTimezone,
  validateSuggestion,
  whenTypeIds,
  formatSuggestionOut,
  getSelectedGameplan,
  getSuggestionOnlineValuesFromLink,
  suggestionOnlineTypeOfLinks,
  promoteOrDecide,
}
