import React from "react"

import useDeleteAccount from "../../components/SingleSignIn/useDeleteAccount"
import { TeeUpsPage } from "../../pages"

export const InboxPage = () => {
  const modal = useDeleteAccount()

  return (
    <>
      {modal}
      <TeeUpsPage />
    </>
  )
}
