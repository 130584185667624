import React, { memo } from "react"

import { CreateTeeUpProvider } from "./CreateTeeUpContext/CreatePageContext"
import CreateTeeUpPage from "./CreateTeeUpPage"

const CreateTeeUpWithContext = props => (
  <CreateTeeUpProvider>
    <CreateTeeUpPage {...props} />
  </CreateTeeUpProvider>
)

export default memo(CreateTeeUpWithContext)
