import { loginProviders } from "@config/enums"

export const useSyncApple = ({ setIsAppleLoading, getAccessToken }) => {
  return async () => {
    setIsAppleLoading(true)

    const accessToken = await getAccessToken({
      connection: loginProviders.apple,
    })

    // TODO: make a logic
    // if (!accessToken) return

    // try {
    //   const linkedConnector = await linkSyncContacts(accessToken, loginProviders.apple)
    // } catch (e) {
    // }
  }
}
