import { Fragment, useMemo, useState } from "react"

import ParticipantDetails from "@components/TeeupPeople/Modals/ParticipantDetails"
import Collapse from "@ui/Collapse"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import SubTitleContent from "../../SubTitleContent"

import ParticipantItem from "./ParticipantItem"

import "./index.scss"

const Participants = ({ people }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const setCurrentParticipant = useTeeupPeopleStore(
    state => state.setCurrentParticipant
  )
  const setIsParticipantDetailsModalOpen = useTeeupPeopleStore(
    state => state.setIsParticipantDetailsModalOpen
  )
  const organizerId = useMemo(
    () => people.find(p => p.isOrganiser)?.id,
    [people]
  )

  const TitleContent = isCollapsed && (
    <div className="people-participant-view-less">View less</div>
  )

  const handleParticipantDetails = participant => {
    setCurrentParticipant(participant)
    setIsParticipantDetailsModalOpen(true)
  }

  return (
    <>
      <Collapse
        title="Participants"
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        contentClassName="people-participants-content"
        titleContent={TitleContent}
        subTitleContent={
          <SubTitleContent
            people={people}
            isCollapsed={isCollapsed}
            isParticipants
          />
        }>
        <ul className="people-participant-block">
          {people.map(participant => (
            <ParticipantItem
              key={participant.id}
              participant={participant}
              onClick={handleParticipantDetails}
            />
          ))}
        </ul>
      </Collapse>

      <ParticipantDetails organizerId={organizerId} />
    </>
  )
}

export default Participants
