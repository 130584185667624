import styled from "styled-components"

export const PreviewErrorWrapper = styled.section`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`

export const PreviewError = styled.div`
  width: 500px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 744px) {
    width: 80%;
  }

  @media (max-width: 450px) {
    width: 100%;
    top: unset;
    bottom: 0;
    left: 0;
    border-radius: 24px 24px 0 0;
    transform: unset;
  }
`

export const PreviewErrorIcon = styled.img`
  width: 52px;
  height: 56px;
`

export const PreviewErrorTitle = styled.h2`
  font-family: "greycliffcf-bold-salt";
  font-size: 32px;
  text-align: center;
  margin-top: 8px;
`

export const PreviewErrorMessage = styled.p`
  color: #737373;
  font-family: "greycliffcf-regular-salt";
  font-size: 18px;
  margin-top: 8px;
  text-align: center;
`

export const PreviewErrorButtons = styled.div`
  width: 100%;
  margin-top: 40px;
`
