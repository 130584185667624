import styled, { css } from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.section`
  ${({ $isSuggestionsLength }) => css`
    width: 100%;
    background-color: var(--white);
    border-top: 1px solid var(--lightGray5);
    border-bottom: 1px solid var(--lightGray5);
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: ${$isSuggestionsLength ? "flex-start" : "center"};

    padding: 16px;
  `};
`

export const Left = styled.div`
  width: 92%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.2;
    `};
`

export const HeaderTitle = styled(NSubtitle)`
  ${({ $color }) => css`
    font-weight: ${NFontWeight.bold};

    ${$color &&
    css`
      color: ${$color};
    `}
  `};
`

export const LeftText = styled(NSubtitleS)`
  color: var(--gray5);
`
export const PlatformIcon = styled(SvgIcon)`
  align-self: center;
  width: 20px;
  height: 20px;
  padding: 6px;
  border: 1px solid var(--lightGray5);
  border-radius: 5px;
`

export const PlusIcon = styled(SvgIcon)`
  width: 36px;
  height: 36px;
`

export const DecidedLabel = styled.span`
  color: var(--green2);
  font-size: 14px;
`
