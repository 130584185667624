import React from "react"

import PropTypes from "prop-types"

import { UploaderFileInput } from "@components"
import { ButtonEl } from "@components/common"
import { BUTTON_SIZES, BUTTON_VARIANTS } from "@constants/button"

import { Wrapper, ImageTemplate } from "./uploadFromDeviceStyles"

export const UploadFromDevice = ({ onUpload }) => (
  <Wrapper>
    <UploaderFileInput onUpload={onUpload} />
    <ImageTemplate name="upload-image" />

    <ButtonEl
      isIconFirst
      border="1px solid var(--lightGray5)"
      backgroundColor="var(--lightGray4)"
      text="Upload from Device"
      textColor="var(--gray6)"
      iconName="upload-icon"
      size={BUTTON_SIZES.SMALL}
      variant={BUTTON_VARIANTS.ROUNDED}
      onClick={() => {}}
    />
  </Wrapper>
)

UploadFromDevice.propTypes = {
  onUpload: PropTypes.func,
}
