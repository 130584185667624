import cn from "classnames"

const StageLabel = ({ status }) => {
  if (!status) return false

  return (
    <div
      className={cn(
        "text-xs font-semibold leading-tight rounded px-1.5 py-0.5 border",
        status?.className
      )}>
      {status?.label}
    </div>
  )
}

export default StageLabel
