import styled, { css } from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle5,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  background-color: var(--lightGray7);
`

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-top: 0;
  overflow-y: auto;
  max-height: 100%;
  padding-bottom: 220px;
`

export const Title = styled(NTitle5)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NCaptionS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  margin: 10px 0 40px 0;
`

export const EventsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 40px 0;
  row-gap: 15px;
  border-top: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  padding: 16px;
  background-color: var(--white);
  margin-top: 8px;
`
