import React, { useContext, useEffect, useState } from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Caption } from "@assets/styles/typographyStyles"

import { requestVerifyContactMechanism } from "../../../actions/contactMechanismsActions"
import { sendBetaCode } from "../../../actions/loginActions"
import { SignUpEmailContext } from "../../../contexts/SignUpEmailContext"

import "./publicInfoForm.scss"

const timerDuration = 60

const ResendCode = ({
  isInvite,
  email,
  deviceId,
  inviteSSORegisterToken,
  setCode,
}) => {
  const [timer, setTimer] = useState(timerDuration)
  const { contactMechanismId, setContactVerificationId } =
    useContext(SignUpEmailContext)

  // counting of timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer === 0) {
        clearInterval(intervalId)
      } else {
        setTimer(timer - 1)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timer])

  const resendHandle = () => {
    setTimer(timerDuration)
    setCode("")

    if (isInvite) {
      return requestVerifyContactMechanism(
        contactMechanismId,
        inviteSSORegisterToken
      ).then(res => {
        setContactVerificationId(res.contactMechanismVerificationId)
      })
    }

    sendBetaCode({ email1: email }, deviceId)
  }

  return (
    <Caption onClick={timer === 0 && resendHandle} className="resend">
      resend code {!(timer === 0) && `(${timer})`}
    </Caption>
  )
}

ResendCode.propTypes = {
  isInvite: PropTypes.bool,
  email: PropTypes.string,
  deviceId: PropTypes.string,
  setCode: PropTypes.func,
  inviteSSORegisterToken: PropTypes.string | PropTypes.any,
}

const mapStateToProps = ({ user }) => ({
  inviteSSORegisterToken: user.inviteSSORegisterToken,
})

export default connect(mapStateToProps)(ResendCode)
