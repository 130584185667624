import React from "react"

import { ReactSVG } from "react-svg"
import ReactTooltip from "react-tooltip"

import { images } from "@utils/imageUtils"
import { DEFAULT_SORT, SORT_KEYS } from "@utils/teeupUtils"

import "./index.scss"

const sortButtons = {
  default: DEFAULT_SORT,
  createdatDesc: {
    id: "createdatDesc",
    sort: SORT_KEYS.createdat,
    desc: true,
    title: "Newest first",
  },
  createdatAsc: {
    id: "createdatAsc",
    sort: SORT_KEYS.createdat,
    desc: false,
    title: "Oldest first",
  },
  updatedat: {
    id: "updatedat",
    sort: SORT_KEYS.startsWhen,
    desc: false,
    title: "Happening Soonest",
  },
  unscheduled: {
    id: "unscheduled",
    sort: SORT_KEYS.noExactWhen,
    desc: false,
    title: "Unscheduled",
  },
}

const SortTooltip = ({
  changeSortType,
  sorting,
  onClose,
  isSortTooltip,
  toggleIsOpen,
}) => {
  const DEFAULT_TITLE = "Recent activity"

  const handleOptionSelect = sortOption => {
    changeSortType(sortOption)
  }

  return (
    <ReactTooltip
      id="sort"
      className="sortTooltip"
      place="bottom"
      effect="solid"
      clickable={true}
      afterHide={toggleIsOpen}
      afterShow={toggleIsOpen}>
      {Object.values(sortButtons).map(sortButton => (
        <div
          className="tooltip-list-option"
          key={`${sortButton.id}`}
          onClick={() => {
            handleOptionSelect(sortButton)
          }}>
          <div className="tooltip-list-option-checkmark">
            {sortButton.title === sorting.title && (
              <ReactSVG
                src={images.ndCheckmarkCircleIcon}
                className="checkmark"
              />
            )}
          </div>
          <span className="tooltip-list-option-title">
            {sortButton.title || DEFAULT_TITLE}
          </span>
        </div>
      ))}
    </ReactTooltip>
  )
}

export default SortTooltip
