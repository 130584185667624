import styled from "styled-components"

import { NCaptionS } from "@assets/styles/newTypographyStyles"

export const Text = styled(NCaptionS)`
  color: var(--gray5);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
`
