import React from "react"

import find from "lodash/find"
import get from "lodash/get"
import PropTypes from "prop-types"
import { FlatList } from "react-native"

import TeeupItem from "@components/teeupList/TeeupItem/smallItem"

const UserProfileSharedTeeups = props => {
  const {
    teeups,
    teeupParticipants,
    teeupPeople,
    teeupGameplans,
    teeupInvitations,
    userId,
    onTeeupPress,
    divHeight,
  } = props
  // eslint-disable-next-line
  const renderItem = ({ item }) => {
    // eslint-disable-next-line
    const id = item.id

    return (
      <TeeupItem
        key={`teeupItem-${id}`}
        teeup={item}
        currentUserId={userId}
        invitation={find(teeupInvitations, ["teeupId", id]) || null}
        participants={get(teeupParticipants, id, [])}
        people={get(teeupPeople, id, {})}
        gameplans={get(teeupGameplans, id, [])}
        onPress={() => onTeeupPress(item)}
        testID={`teeup-${id}`}
        isNotOrganizer
        disablePrompts
      />
    )
  }

  return (
    <FlatList
      style={{ height: `calc(100vh - ${divHeight}px - 96px - 220px)` }}
      data={teeups}
      renderItem={renderItem}
    />
  )
}

UserProfileSharedTeeups.propTypes = {
  teeups: PropTypes.array,
  // teeupParticipants: PropTypes.array,
  // teeupPeople: PropTypes.array,
  // teeupGameplans: PropTypes.array,
  teeupInvitations: PropTypes.array,
  userId: PropTypes.number,
  onTeeupPress: PropTypes.func,
}

export default UserProfileSharedTeeups
