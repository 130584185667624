import PropTypes from "prop-types"

import { EVENT_TYPES } from "@utils/calendarUtils"

import {
  TeeupCustomEventContent,
  CalendarCustomEventContent,
  NotAvailableZone,
} from "../molecules"

const CustomEvent = ({ isInner, event }) => {
  const renderContent = () => {
    switch (event.eventType) {
      case EVENT_TYPES.teeup:
        return <TeeupCustomEventContent isInner={isInner} event={event} />
      case EVENT_TYPES.calendar:
        return <CalendarCustomEventContent isInner={isInner} event={event} />
      case EVENT_TYPES.not_avaliable:
        return <NotAvailableZone />
      default:
        return null
    }
  }

  return renderContent()
}

export default CustomEvent

CustomEvent.propType = {
  isInner: PropTypes.bool,
  event: PropTypes.event,
}
