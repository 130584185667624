export const REGISTER_STEPS = {
  EMAIL: "EMAIL",
  SIGN_UP_CODE: "SIGN_UP_CODE",
  PASSWORD: "PASSWORD",
  NAMES: "NAMES",
  INVITE_SENDER: "INVITE_SENDER",
}

export const API_ERRORS_MESSAGES = {
  EMAIL_IS_USING: `
    <p>Another Coowe account is using this email address.</p>
    <p>You cannot create a CooWe account with an email address used in another account.</p>
  `,
  SOMETHING_WENT_WRONG: `<p>Something went wrong! Try again later.</p>`,
  INCORRECT_CODE: "<p>This is not the correct code</p>",
  TOO_MANY_REQUESTS: "<p>Too many requests, please try again</p>",
  INVALID_DISPLAY_NAME: "<p>Invalid display name</p>",
}
