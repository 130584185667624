import React, { useState, useEffect } from "react"

import get from "lodash/get"
import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

import styles from "./statusMessageStyles"
import TitleMessage from "./titleMessage"

const ExpandableMessage = props => {
  const { text } = props
  const [expandable, setExpandable] = useState(null)
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (expandable) {
      setExpanded(false)
    }
  }, [expandable])

  const onLayout = event => {
    const height = get(event, "nativeEvent.layout.height")
    if (!height || expandable !== null) return

    setExpandable(height > 30)
  }

  const toggleExpansion = () => setExpanded(!expanded)

  return (
    <View>
      <Text
        onLayout={onLayout}
        {...(!expanded && { numberOfLines: 1 })}
        style={[styles.text, { maxWidth: "400px" }]}>
        {text}
      </Text>
      {expandable && (
        <TouchableOpacity onPress={toggleExpansion}>
          <Text
            style={[
              styles.expansionText,
              expanded && styles.expansionTextHighlight,
            ]}>
            {expanded ? "Hide Update" : "Show Full Update"}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

ExpandableMessage.propTypes = {
  text: PropTypes.string,
}

const ReactionMessage = props => {
  const { standAlone, ...otherProps } = props

  if (standAlone) {
    return <ExpandableMessage {...otherProps} />
  }

  return <TitleMessage {...otherProps} />
}

ReactionMessage.propTypes = {
  standAlone: PropTypes.bool,
}

export default ReactionMessage
