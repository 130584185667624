import React, { useEffect, useState } from "react"

import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import { setShowSideBar } from "@actions/commonActions"
import { getUserContactMechanisms } from "@actions/userActions"
import { selectContactMechanisms, selectUserId } from "@selectors/user"
import { images } from "@utils/imageUtils"

import Error from "../components/Error"
import Success from "../components/Success"

import AddContactMethod from "./AddContactMethod"
import LearnMoreModal from "./LearnMoreModal"
import List from "./List"
import VerifyContactMethod from "./VerifyContactMethod"

const STEPS = {
  LIST: "LIST",
  ADD_CONTACT: "ADD_CONTACT",
  VERIFY_CONTACT: "VERIFY_CONTACT",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
}

let propsForVerify = {}

const ContactMethods = ({
  contactMechanisms,
  selectUserId,
  setShowSideBar,
}) => {
  const [activeStep, setActiveStep] = useState(STEPS.LIST)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [createdCM, setCreatedCM] = useState(null)

  useEffect(() => {
    setShowSideBar(false)
    return function () {
      setShowSideBar(true)
    }
  }, [])

  useEffect(() => {
    if (activeStep === STEPS.LIST) {
      getUserContactMechanisms()
    }
  }, [activeStep])

  switch (activeStep) {
    case STEPS.ADD_CONTACT:
      return (
        <AddContactMethod
          showFail={() => setActiveStep(STEPS.FAIL)}
          showVerifyCode={(
            contactMechanismId,
            contactMechanismVerificationId,
            value
          ) => {
            propsForVerify = {
              contactMechanismId,
              defaultVerificationId: contactMechanismVerificationId,
              value,
            }
            setActiveStep(STEPS.VERIFY_CONTACT)
          }}
          cancel={() => setActiveStep(STEPS.LIST)}
          setCreatedCM={setCreatedCM}
        />
      )
    case STEPS.VERIFY_CONTACT:
      return (
        <VerifyContactMethod
          {...propsForVerify}
          userId={selectUserId}
          showSucces={() => setActiveStep(STEPS.SUCCESS)}
          cancel={setActiveStep}
          stepSuccess={STEPS.LIST}
          createdCM={createdCM}
        />
      )
    case STEPS.FAIL:
      return (
        <>
          <LearnMoreModal
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
          />
          <Error
            image={images.failed}
            title="Contact Method Linked to Another Account"
            subtitle="The contact method you provided is already linked and verified to another Coo•e account."
            onClickLearnMore={() => setIsModalOpen(true)}
            onClickOkay={() => setActiveStep(STEPS.LIST)}
          />
        </>
      )
    case STEPS.SUCCESS:
      return (
        <Success
          image={images.successCreate}
          title="Contact Method Verified!"
          subtitle="This contact method has been verified. You can now merge this with your current account."
          buttonText="Merge Account"
          onClick={() => setActiveStep(STEPS.LIST)}
        />
      )
    default:
      return (
        <List
          showAddContact={() => setActiveStep(STEPS.ADD_CONTACT)}
          contactMechanisms={contactMechanisms}
        />
      )
  }
}

const mapStateToProps = state => {
  const contacts = selectContactMechanisms(state)
  const userId = selectUserId(state)

  let contactMechanisms = [contacts["email"], contacts["phone"]].flat()

  return {
    contactMechanisms,
    userId,
  }
}

const mapDispatchToProps = dispatch => ({
  setShowSideBar: bindActionCreators(setShowSideBar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactMethods)
