import React from "react"

import PropTypes from "prop-types"

import { Loader } from "./arrowLoaderStyles"

export const ArrowLoader = ({ isCentered }) => <Loader name="loader" />

ArrowLoader.propTypes = {
  isCentered: PropTypes.bool,
}
