import { CSSProperties, useMemo } from "react"

import dayjs from "dayjs"

import { ROW_HEIGHT, getBoxInfo } from "@utils/suggestionUtils"
import { HourBox as IHourBox } from "types/suggestions"

import CurrentTimePin from "../CurrentTimePin"
import DaySeparator from "../DaysSeparator"

import CalendarBox from "./CalendarBox"
import DisabledBoxPart from "./DisabledBoxPart"

interface ItemProps {
  styles: CSSProperties
  data: IHourBox
  rowIndex: number
  columnIndex: number
}

const defaultContainerStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  height: ROW_HEIGHT,
  userSelect: "none",
}

const defaultBoxStyles: CSSProperties = {
  position: "relative",
  border: "1px solid #E5E5E5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 40,
  color: "#737373",
  fontSize: 14,
  fontWeight: 600,
  boxSizing: "border-box",
  marginTop: "auto",
  width: "100%",
  userSelect: "none",
}

const unknownTimezoneStyles = {
  backgroundColor: "var(--lightGray5)",
  border: "1px solid var(--lightGray5)",
}

const defaultDisabledBoxStyles = {
  opacity: 0.3,
}

const hour = new Date().getHours()

const HourBox = ({ styles, data, rowIndex }: ItemProps) => {
  const currentHour = useMemo(
    () => dayjs(data.dateString).hour(),
    [data.dateString]
  )
  const isFirstRow = rowIndex === 0
  const isCurrentTimePinShown =
    // @ts-ignore
    styles?.left > 0 &&
    // @ts-ignore
    styles?.left < 1000 &&
    isFirstRow &&
    data.isToday &&
    currentHour === hour

  const currentStyles =
    data.isDisabled && !isCurrentTimePinShown
      ? {
          ...defaultBoxStyles,
          ...defaultDisabledBoxStyles,
          marginTop: isFirstRow ? "auto" : "initial",
        }
      : {
          ...defaultBoxStyles,
          ...getBoxInfo(data.label).style,
          marginTop: isFirstRow ? "auto" : "initial",
        }

  if (data?.isUnknownTimezone)
    return (
      <div
        style={{
          ...styles,
          ...defaultContainerStyles,
          justifyContent: isFirstRow ? "flex-end" : "center",
        }}>
        <div
          style={{
            ...defaultBoxStyles,
            ...unknownTimezoneStyles,
            marginTop: isFirstRow ? "auto" : "initial",
          }}></div>
      </div>
    )

  return (
    <>
      {isCurrentTimePinShown && <CurrentTimePin />}
      {data.isDaySeparator && isFirstRow && <DaySeparator styles={styles} />}
      <div
        style={{
          ...styles,
          ...defaultContainerStyles,
          justifyContent: isFirstRow ? "flex-end" : "center",
        }}>
        <div style={currentStyles}>
          <DisabledBoxPart isCurrentTimePinShown={isCurrentTimePinShown} />
          {data.label}
        </div>

        {isFirstRow && (
          <CalendarBox
            data={data}
            defaultBoxStyles={defaultBoxStyles}
            disabledBoxStyles={defaultDisabledBoxStyles}
          />
        )}
      </div>
    </>
  )
}

export default HourBox
