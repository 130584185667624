import { createSelector } from "reselect"

import { initialState } from "../reducers/guestReducer"

export const selectGuest = state => state.guest || initialState

export const selectGuestRegistrationData = createSelector(
  selectGuest,
  guest => guest.guestRegistrationData
)

export const selectNewGuestSuggestion = createSelector(
  selectGuest,
  guest => guest.newSuggestion
)

export const selectGuestSuggestions = createSelector(
  selectGuest,
  guest => guest.suggestions
)

export const selectGuestReactions = createSelector(
  selectGuest,
  guest => guest.reactions
)
