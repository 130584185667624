import React from "react"

import cn from "classnames"
import { connect } from "react-redux"

import "./index.scss"
import { selectAllContacts } from "@selectors/contacts"
import { selectTeeupsPeople } from "@selectors/teeups"
import { selectUserId, selectConnections } from "@selectors/user"

import AddInviteesContainerNd from "../AddInvitees/AddInviteesContainerNd"

const TABS_DATA = [
  { index: 0, title: "All" },
  { index: 1, title: "CONTACTS" },
  { index: 2, title: "COOWE-VERSE" },
]

const ContactBookContainer = ({
  isNoAll,
  activeInviteesTab,
  setActiveInviteesTab,
  setInvitees,
  invitees,
  fromContactBook,
  allContacts,
  teeupPeople,
  fromContactsBook,
  openInviteToTeeupModal,
  handleOnInviteesDone,
  tagUser,
  openTagPopUp,
  activePopUpOpen,
  userId,
  userConnections,
}) => {
  const renderTabs = () =>
    TABS_DATA.filter(tab => !isNoAll || tab.title !== "All").map(tab => (
      <h4
        className={cn("inviteNd__item", {
          "inviteNd__item--active": activeInviteesTab === tab.index,
        })}
        onClick={() => {
          setActiveInviteesTab(tab.index)
        }}>
        {tab.title}
      </h4>
    ))

  return (
    <div data-testid="contacts">
      <div className="inviteNd__header">{renderTabs()}</div>

      <AddInviteesContainerNd
        key={activeInviteesTab}
        activeTabIndex={activeInviteesTab}
        setInvitees={setInvitees}
        invitees={invitees}
        fromNewCreateTeeup={true}
        myContacts={activeInviteesTab === 2}
        fromContactsBook={fromContactsBook}
        openInviteToTeeupModal={openInviteToTeeupModal}
        handleOnInviteesDone={handleOnInviteesDone}
        tagUser={tagUser}
        openTagPopUp={openTagPopUp}
        activePopUpOpen={activePopUpOpen}
      />
    </div>
  )
}

const mapStateToProps = (state, passedProps) => {
  const teeupPeople = selectTeeupsPeople(state)
  const allContacts = selectAllContacts(state)
  const userId = selectUserId(state)
  const userConnections = selectConnections(state)
  return {
    allContacts,
    teeupPeople,
    userId,
    userConnections,
  }
}

export default connect(mapStateToProps)(ContactBookContainer)
