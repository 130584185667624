export const GAMEPLAN_TYPES = {
  WHEN: "WHEN",
  WHERE: "WHERE",
  when: "when",
  where: "where",
}

export const GAMEPLAN_TYPES_LABELS = {
  WHEN: "When",
  WHERE: "Where",
}
