import styled, { css } from "styled-components"

import { NFontWeight, NSubtitle } from "assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  column-gap: 5px;
  margin-bottom: 10px;
  ${({ $isNonUserFlow }) => css`
    ${$isNonUserFlow &&
    css`
      justify-content: space-between;
    `};
  `};

  ${({ $isDecided }) => css`
    ${$isDecided &&
    css`
      justify-content: space-between;
    `};
  `};
`

export const Text = styled(NSubtitle)`
  ${({ $color, $isNonUserFlow }) => css`
    font-weight: ${NFontWeight.bold};
    ${$color &&
    css`
      color: ${$color};
      font-weight: ${NFontWeight.semibold};
    `};
    ${$isNonUserFlow &&
    css`
      border: 1px solid var(--lightBlue6);
      background-color: var(--lightBlue3);
      color: var(--darkBlue);
      padding: 2px 6px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
    `}
  `};
`
