import React, { useEffect, useState } from "react"

import { IconContext } from "react-icons"
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa"
import { useRouteMatch, useHistory } from "react-router-dom"

import {
  verifyPassword,
  NewPasswordChange,
  newSendPasswordRecoveryLink,
  newPasswordRecovery,
} from "@actions/userActions"
import { CaptionS } from "@assets/styles/typographyStyles"
import { NewModal } from "@components"
import { ForgotPasswordModal } from "@components/ForgotPasswordModal"
import { PasswordChangedModal } from "@components/PasswordChangedModal"
import { ERRORS_STATUSES } from "@config/enums"
import { usePasswordValidation } from "@hooks"

import api from "../../api"
import { CHANGE_PASSWORD_REQUIREMENTS } from "../../constants/password"
import { InputEl } from "../common"

import {
  ActionWrapper,
  InputLable,
  ForgotPasswordAction,
  ModalDescription,
  Input,
  ActionFooter,
  PasswordRequirementsContainer,
  PasswordRequirementWrapper,
  PasswordRequirement,
} from "./molecules"

const getRuleIcon = failedRule => {
  return failedRule ? (
    <IconContext.Provider value={{ color: "#A9B0B9" }}>
      <FaRegCheckCircle />
    </IconContext.Provider>
  ) : (
    <IconContext.Provider value={{ color: "#00C5AE" }}>
      <FaCheckCircle />
    </IconContext.Provider>
  )
}

export const ChangePasswordModal = ({ handleClose }) => {
  const { params } = useRouteMatch()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [modal, setModal] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [passwordValue, setPasswordValue] = useState("")

  const { password, setPassword, failedRequirements } = usePasswordValidation()

  const handleVerifyPassword = () => {
    verifyPassword(passwordValue)
      .then(({ data }) => {
        setPasswordValue(null)
        setToken(data.accessToken)
      })
      .catch(error => {
        if (
          error.status === ERRORS_STATUSES.UNAUTHORIZED ||
          error.status === ERRORS_STATUSES.INCORECT
        ) {
          setErrorMessage("Incorrect password")

          return
        }

        setErrorMessage("Something went wrong")
      })
  }

  const handleChangePassword = () => {
    NewPasswordChange(password, token)
      .then(({ data }) => {
        setModal(<PasswordChangedModal onClose={onClose} />)
        api.createSession(data)
      })
      .catch(error => setErrorMessage({ payload: error.data.message }))
  }

  const handleResetPassword = () => {
    newPasswordRecovery(password, token)
      .then(({ data }) => {
        setModal(<PasswordChangedModal onClose={onClose} />)
        api.createSession(data)
      })
      .catch(({ data }) => setErrorMessage({ payload: data.message }))
  }

  const onForgotPassword = () => {
    newSendPasswordRecoveryLink()
      .then(() => {
        setModal(<ForgotPasswordModal onClose={() => setModal(null)} />)
      })
      .catch(error => {})
  }

  const onClose = () => {
    if (params.token) {
      history.push("/settings")
      return
    }

    handleClose()
  }

  const handleInput = value => {
    if (errorMessage) setErrorMessage(null)

    setPasswordValue(value)
  }

  const handleInputNewPassword = event => {
    const value = event.target.value

    errorMessage && setErrorMessage(null)

    setPassword(value)
  }

  const handleContinue = () => {
    if (token) {
      params.token ? handleResetPassword() : handleChangePassword()
    } else {
      handleVerifyPassword()
    }
  }

  useEffect(() => {
    const { token } = params

    if (token) {
      setToken(token)
    }
  }, [params])

  return modal ? (
    modal
  ) : (
    <NewModal
      isOpen
      isDisabledConfirm={
        token
          ? failedRequirements.length > 1 || failedRequirements.length === 0
          : !passwordValue
      }
      title="Change my password"
      confirmText={token ? "Change password" : "Next"}
      onConfirm={handleContinue}
      onClose={onClose}
      onCancel={onClose}
      cancelText="Cancel">
      {token ? (
        <>
          <ActionWrapper token={token}>
            <InputLable>New password</InputLable>
            <Input
              value={password}
              onChange={handleInputNewPassword}
              placeholder="Password"
              type="text"
            />
          </ActionWrapper>
          <ModalDescription>Your new password must:</ModalDescription>
          <PasswordRequirementsContainer>
            {CHANGE_PASSWORD_REQUIREMENTS.map(req => {
              const failedRule =
                password === "" || failedRequirements.includes(req.description)

              return (
                <PasswordRequirementWrapper>
                  {getRuleIcon(failedRule)}
                  <PasswordRequirement>{req.text}</PasswordRequirement>
                </PasswordRequirementWrapper>
              )
            })}
          </PasswordRequirementsContainer>
          {errorMessage && "payload" in errorMessage && (
            <CaptionS style={{ marginTop: "9px" }} className="error">
              {errorMessage?.payload}
            </CaptionS>
          )}
        </>
      ) : (
        <>
          <ModalDescription>
            To continue, please enter your current password below.
          </ModalDescription>
          <ActionWrapper>
            <InputLable>Current password</InputLable>
            <InputEl
              type="password"
              name="password"
              value={passwordValue}
              onChange={handleInput}
            />
            <ActionFooter>
              {errorMessage && (
                <CaptionS className="error">{errorMessage}</CaptionS>
              )}
              <ForgotPasswordAction onClick={onForgotPassword}>
                forgot password
              </ForgotPasswordAction>
            </ActionFooter>
          </ActionWrapper>
        </>
      )}
    </NewModal>
  )
}
