import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { SvgIcon } from "@components/common"
import { loginProviders } from "@config/enums"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { getTimeRange } from "@utils/dateUtils"

import { InnerEvents } from "../molecules"

import { Wrapper, Info, Text } from "./calendarCustomEventContentStyles"

const CalendarCustomEventContent = ({ isInner, event, linkedCalendar }) => {
  const { summary, start, end, isConflict, innerEvents } = event

  const getCalendarIconName = () => {
    if (!linkedCalendar?.provider) return

    return linkedCalendar.provider === loginProviders.google
      ? "google"
      : "outlook-2"
  }

  return (
    <Wrapper $isInner={isInner}>
      {innerEvents?.length > 1 ? (
        <InnerEvents event={event} />
      ) : (
        <>
          <Info $isConflict={isConflict}>
            <Text>{summary}</Text>
            <Text>{getTimeRange(start, end)}</Text>
          </Info>

          <SvgIcon name={getCalendarIconName()} />
        </>
      )}
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  linkedCalendar: selectGuestLinkedCalendar(state),
})

export default connect(mapStateToProps)(CalendarCustomEventContent)

CalendarCustomEventContent.propTypes = {
  isInner: PropTypes.bool,
  event: PropTypes.object,
}
