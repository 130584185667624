import actionTypes from "./actionTypes"

export const setMentionPrompt = payload => ({
  type: actionTypes.SET_MENTION_PROMPT,
  payload,
})

export const removeMentionPrompt = payload => ({
  type: actionTypes.REMOVE_MENTION_PROMPT,
  payload,
})
