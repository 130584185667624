import { useContext } from "react"

import PropTypes from "prop-types"

import { ProgressLine } from "@components/common"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { GoBack } from "../atoms"

import { Wrapper, Navigation, CountText, Subtitle } from "./stepperHeaderStyles"

export const StepperHeader = ({ goBackText, subtitle }) => {
  const {
    gameplanInfo,
    stepsManager: {
      totalSteps,
      currentStep,
      suggestionPreviewId,
      isMakeSuggestion,
      goPrevStep,
      jumpToStep,
      setIsMakeSuggestion,
    },
  } = useContext(TeeupPreviewContext)
  const decidedSuggestion = gameplanInfo?.when.decidedSuggestion

  const progressPercent = (currentStep / totalSteps) * 100

  const goBackHandle = () => {
    if (isMakeSuggestion && !!decidedSuggestion) {
      setIsMakeSuggestion(false)
      jumpToStep(0)
    } else {
      goPrevStep()
    }
  }

  return (
    <Wrapper>
      <Navigation>
        <GoBack text={goBackText} onClick={goBackHandle} />

        {!suggestionPreviewId && (
          <CountText>
            {currentStep}/{totalSteps}
          </CountText>
        )}
      </Navigation>

      {!suggestionPreviewId && <ProgressLine percent={progressPercent} />}

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

StepperHeader.propTypes = {
  goBackText: PropTypes.string,
}
