import { Radio } from "@mui/material"

import { SortOption } from "types/inbox"

interface Props {
  option: SortOption
  onClick: (option: SortOption) => void
  isChecked: boolean
}

const Option = ({ option, onClick, isChecked }: Props) => {
  return (
    <div
      className="flex h-14 w-full cursor-pointer items-center justify-between gap-2 bg-stone-50 px-6"
      key={option.id}
      onClick={() => onClick(option)}>
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex h-[17px] items-center justify-start gap-2">
          <div className="select-none font-['Inter'] text-sm text-neutral-700">
            {option.title}
          </div>
        </div>
      </div>

      <Radio
        className="!text-[#303030]"
        checked={isChecked}
        onChange={() => onClick(option)}
        value="b"
        name="radio-buttons"
        inputProps={{ "aria-label": "B" }}
      />
    </div>
  )
}

export default Option
