import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  dateMessageContainer: {
    width: "100%",
    height: Units.rem(1.5625),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Units.rem(1),
    // transform: [{ scaleY: -1 }],
    marginVertical: Units.rem(0.5),
  },
  dateMessageLine: {
    flexGrow: 1,
    height: 1,
    backgroundColor: AppColors.brand.lightGrey2,
  },
  dateMessageDate: {
    paddingHorizontal: Units.rem(0.75),
    color: AppColors.brand.lightGrey3,
    ...AppFonts.smallBold,
    lineHeight: Units.rem(1),
    letterSpacing: Units.rem(-0.0125),
  },
})
