import React, { useMemo } from "react"

import PropTypes from "prop-types"

import ErrorIcon from "@assets/images/shadow-error.svg"
import SuccessIcon from "@assets/images/success.svg"
import WarningIcon from "@assets/images/warning-shadow.svg"
import { Subtitle } from "@assets/styles/typographyStyles"

import {
  ErrorMessageWrapper,
  ErrorMessageIcon,
} from "../linkCalendarPopupStyles"

export const LINKING_STATUSES = {
  WARNING: "WARNING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
}

export const LinkingStatus = ({ type, message }) => {
  const icon = useMemo(() => {
    switch (type) {
      case LINKING_STATUSES.ERROR:
        return ErrorIcon
      case LINKING_STATUSES.WARNING:
        return WarningIcon
      default:
        return SuccessIcon
    }
  }, [type])

  return (
    <ErrorMessageWrapper>
      <ErrorMessageIcon src={icon} alt="error" />

      <Subtitle
        style={{ color: "#767676", wordBreak: "break-word" }}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </ErrorMessageWrapper>
  )
}

LinkingStatus.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
}
