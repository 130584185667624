import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const PopupContentWrapper = styled.div`
  width: 840px;
  min-height: 90vh;
  background-color: var(--white);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  & > header {
    margin: 1px;
    margin-top: 16px;
    border-bottom: 0;
  }

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 90%;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 95%;
  }
`

export const SuggestionContentWrapper = styled.div`
  max-height: 650px;
  // background-color: var(--lightGray4);
  padding-top: 24px;
  overflow-y: auto;

  ${({ $isDefault }) =>
    !$isDefault &&
    css`
      padding: 16px;
    `};

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    max-height: 70vh;
  }
`

export const SuggestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 40px;
`
