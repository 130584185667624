// Directly from state: present, maxId. From update: updates
// Warning: updates might be modified here

import _ from "lodash"

const caselessCompare = (one, two) => {
  return one.toUpperCase() === two.toUpperCase()
}

const firstLetter = name => {
  return (name.match(/[A-Za-z]/) || [""]).pop()
}

const firstLetterOrNumber = name => {
  return (name.match(/[\dA-Za-z]/) || [""]).pop()
}

const capitalizeFirstLetter = string => {
  if (typeof string !== "string") return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const isNumber = string => {
  let isNum = false
  if ((string.match(/\d/) || []).pop()) {
    isNum = true
  }
  return isNum
}

const truncateString = (str = "", num = 40) => {
  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + "..."
}

const getInitials = fullName => {
  let initials = ""

  if (_.isString(fullName)) {
    let nameSplit = fullName.split(" ")
    for (let i = 0, len = nameSplit.length; i < len; i++) {
      initials += nameSplit[i].charAt(0)

      if (i === 1) {
        // Show max 2 letters in initials
        break
      }
    }
  }

  initials = initials.toUpperCase()

  return initials
}

const getContactInitials = ({ firstName, lastName, name, username, value }) => {
  if (firstName && lastName) {
    return `${firstName.toUpperCase().charAt(0)}${lastName
      .toUpperCase()
      .charAt(0)}`
  }
  if (username) {
    return getInitials(username)
  }

  const initial = firstName || lastName || name || username || value
  if (initial) {
    return initial.toUpperCase().charAt(0)
  }
  return "NA"
}

const getContactName = ({
  firstName,
  lastName,
  name,
  value,
  username,
  emails,
  phoneNumbers,
}) => {
  if (firstName || lastName) return `${firstName || ""} ${lastName || ""}`
  if (name) return name
  if (value) return value
  if (username) return username
  if (emails && emails.length > 0) return emails[0].email
  if (phoneNumbers && phoneNumbers.length > 0) return phoneNumbers[0].number
  return ""
}

const getFirstName = user => {
  if (user.firstName) return user.firstName
  if (user.firstname) return user.firstname
  if (user.name) return user.name.split(" ")[0]

  return ""
}

const caselessContains = (string, substring) => {
  return string.toUpperCase().includes(substring.toUpperCase())
}

const validateEmailPhone = emailPhone => {
  // either email or phone
  return validateEmail(emailPhone) || validatePhone(emailPhone)
}

const validatePhone = phone => {
  // TODO: add proper validation
  const regex = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
  return phone && phone.length > 0 && regex.test(phone)
}

const validateEmail = email => {
  // eslint-disable-next-line
  var regex =
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
  return regex.test(email)
}

const validatePassword = password => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[\w !"#$%&'()*+,-./:;<=>?@[\]^`{|}~]{8,}$/
  return isString(password) && regex.test(password)
}

const simplePasswordValidate = password => {
  return isString(password) && password.length > 7
}

const validateVerificationCode = code => {
  return isString(code) && code.length === 4
}

// const matchPasswords = (repeatedPassword) => {
//     const state = globalGetState()
//     const { password } = state.user.userInfo
//     return isString(repeatedPassword) && password === repeatedPassword
// }

const isString = test => {
  return typeof test === "string" || test instanceof String
}

const isValidImageUrl = url => url.match(/\.(jpeg|jpg|gif|png)$/) !== null

const immutableSplice = (arr, index) => {
  return _.concat(_.slice(arr, 0, index), _.slice(arr, index + 1))
}

const checkIfObject = val => typeof val === "object" && val !== null

const checkIfEqual = (val1, val2) => {
  if (checkIfObject(val1) && checkIfObject(val2)) {
    return _.isEqual(Object.values(val1), Object.values(val2))
  }
  return _.isEqual(val1, val2)
}

const stringAlphabeticalComparator = (string1 = "", string2 = "") => {
  if (string1 < string2) {
    return -1
  }
  if (string1 > string2) {
    return 1
  }
  return 0
}

const escapeRegExp = string => {
  return string.replaceAll(/[$()*+.?[\\\]^{}]/g, String.raw`\$&`) // $& means the whole matched string
}

const ExpandedMenuTypes = {
  images: "images",
  photo: "photo",
  files: "files",
  mentions: "mentions",
}

export {
  caselessCompare,
  caselessContains,
  validateEmail,
  validatePhone,
  validateEmailPhone,
  isString,
  validatePassword,
  validateVerificationCode,
  // matchPasswords,
  firstLetter,
  firstLetterOrNumber,
  getFirstName,
  getInitials,
  getContactInitials,
  getContactName,
  isNumber,
  truncateString,
  isValidImageUrl,
  immutableSplice,
  capitalizeFirstLetter,
  checkIfEqual,
  stringAlphabeticalComparator,
  escapeRegExp,
  simplePasswordValidate,
  ExpandedMenuTypes,
}
