import { create } from "zustand"

import dayjs from "utils/dayjs"

import type { Dayjs } from "dayjs"

interface PreviewScreenStore {
  isParticipantsModalOpen: boolean
  setIsParticipantsModalOpen: (flag: boolean) => void
  makeSuggestionInnerEventsOpen: string[]
  setMakeSuggestionInnerEventsOpen: (key: string) => void
  isCalendarConnected: boolean
  setIsCalendarConnected: (flag: boolean) => void
  currentSelectedDay: Dayjs
  setCurrentSelectedDay: (day: Dayjs) => void
}

export const usePreviewScreenStore = create<PreviewScreenStore>()(
  (set, get) => ({
    isParticipantsModalOpen: false,
    setIsParticipantsModalOpen: flag => set({ isParticipantsModalOpen: flag }),

    makeSuggestionInnerEventsOpen: [],
    setMakeSuggestionInnerEventsOpen: key => {
      const openEvents = [...get().makeSuggestionInnerEventsOpen]
      if (openEvents.includes(key)) {
        openEvents.splice(openEvents.indexOf(key), 1)
      } else {
        openEvents.push(key)
      }
      set({ makeSuggestionInnerEventsOpen: openEvents })
    },
    isCalendarConnected: false,
    setIsCalendarConnected: flag => set({ isCalendarConnected: flag }),

    currentSelectedDay: dayjs(),
    setCurrentSelectedDay: day => set({ currentSelectedDay: day }),
  })
)
