import React from "react"

import PropTypes from "prop-types"

import { getBase64 } from "@utils/imageUtils"

import { Input } from "./uploaderFileInputStyles"

const imageMimeTypes = new Set([
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
])

export const UploaderFileInput = ({ onUpload }) => {
  const onChange = e => {
    const file = e.target.files[0]
    if (!imageMimeTypes.has(file.type)) return

    getBase64(file).then(base64 => {
      onUpload(base64)
    })
  }

  return (
    <Input
      data-testid="bannerFileInput"
      type="file"
      accept="image/png, image/jpeg, image/jpg, image/webp"
      onChange={onChange}
    />
  )
}

UploaderFileInput.propTypes = {
  onUpload: PropTypes.func,
}
