import React from "react"

import { Wrapper, Line } from "./orBlockStyles"

export const OrBlock = () => (
  <Wrapper>
    <Line />
    or
    <Line />
  </Wrapper>
)
