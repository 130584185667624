import actionTypes from "../actions/actionTypes"

const initialState = {
  mentions: {},
  ignored: [],
  responded: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENTION_PROMPT: {
      return {
        ...state,
        mentions: {
          ...state.mentions,
          [action.payload.teeupId]: action.payload.senderId,
        },
      }
    }
    case actionTypes.REMOVE_MENTION_PROMPT: {
      const mentionsCopy = state.mentions
      delete mentionsCopy[action.payload]

      return {
        ...state,
        mentions: mentionsCopy,
      }
    }
    case actionTypes.IGNORE_MESSAGE_PROMPT: {
      if (
        state.ignored.length > 0 &&
        state.ignored.find(im => im === action.payload)
      ) {
        return state
      }
      return {
        ...state,
        ignored: [...state.ignored, action.payload],
      }
    }
    case actionTypes.RESPOND_MESSAGE_PROPMT: {
      if (
        state.responded.length > 0 &&
        state.responded.find(rm => rm === action.payload)
      ) {
        return state
      }
      return {
        ...state,
        responded: [...state.responded, action.payload],
      }
    }
    default:
      return state
  }
}
