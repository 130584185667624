import React, { useMemo, useState } from "react"

import { images } from "@utils/imageUtils"

import Button from "../../ui/Button"
import InputWithIcon from "../../ui/InputWithIcon"
import TeeupListFilter from "../TeeupListFilter"
import SortTooltip from "../sortTooltip"

import "./index.scss"

const TeeupListHeader = ({
  mode,
  notification,
  setTeeupFilter,
  filtering,
  setFiltering,
  notifications,
  sorting,
  addTeeupsSorting,
  sortingType,
  unreadNotifications,
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [showSort, setShowSort] = useState(false)

  const headerTitle = useMemo(() => {
    if (mode) {
      return mode.toLowerCase()
    }

    if (notification) {
      return "Unread"
    }

    return "Inbox"
  }, [mode, notification])

  const handleChange = event => {
    setTeeupFilter(event.target.value)
  }

  const handleToggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleToggleSort = () => {
    setShowSort(!showSort)
  }

  const numberOfActiveFilters = useMemo(() => {
    return (
      filtering["other"].length +
      filtering["status"].length +
      filtering["type"].length +
      filtering["userStatus"].length
    )
  }, [filtering])

  return (
    <div className="list-header">
      <div className="list-header-filter-bar">
        <span className="list-header-title">{headerTitle}</span>
        <InputWithIcon
          icon={images.search}
          placeholder={`Search ${headerTitle}`}
          type="text"
          handleChange={handleChange}
        />
        {notifications.length > 0 && (
          <Button
            icon={images.ndCardIcon}
            notification={notifications.length}
            notificationStyle="primary"
            onClick={unreadNotifications}
            active={notification}>
            Unread
          </Button>
        )}
        <Button
          data-testid="filterButton"
          icon={images.ndFilterIcon}
          onClick={handleToggleFilter}
          notification={numberOfActiveFilters}
          active={showFilter}>
          Filter
        </Button>
        <Button
          icon={images.ndSortIcon}
          active={showSort}
          onClick={handleToggleSort}
          data-tooltip-content="sort"
          data-event="click"
          data-for="sort">
          {sorting.title}
        </Button>
        <SortTooltip
          changeSortType={addTeeupsSorting}
          sorting={sortingType}
          toggleIsOpen={handleToggleSort}
        />
      </div>
      <div className="list-header-filter-options">
        {showFilter && (
          <TeeupListFilter
            toggleFilter={handleToggleFilter}
            // TODO prop drilling is not a good solution. consider moving stuff to contexts
            filtering={filtering}
            setFiltering={setFiltering}
          />
        )}
      </div>
    </div>
  )
}

export default TeeupListHeader
