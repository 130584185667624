import styled, { css } from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  ${({ $isInviteesLength }) => css`
    width: 100%;
    border-top: solid 1px var(--lightGray5);
    border-bottom: solid 1px var(--lightGray5);
    background-color: var(--white);
    box-sizing: border-box;
    display: flex;
    align-items: ${$isInviteesLength ? "flex-start" : "center"};
    justify-content: space-between;
    padding: 16px;
  `};
`

export const Left = styled.div`
  ${({ $isActive, $isDisabled }) => css`
    width: 92%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    ${$isActive &&
    css`
      height: max-content;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.5s;
    `}
    ${$isDisabled &&
    css`
      opacity: 0.2;
    `}
  `};
`

export const Title = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
`

export const LeftText = styled(NSubtitleS)`
  color: var(--gray5);
`
