//  eslint-disable-next-line
import { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

import { AUTH0_ERRORS } from "../../../pages/Auth0ContentPage/constants"

export const useErrorContent = ({
  auth0Type,
  auth0Error,
  isRegisterSsoNames,
  setIsRegisterSsoNames,
  setIsCreateUserAnyway,
}) => {
  const history = useHistory()

  useEffect(() => {
    if (!auth0Error && !isRegisterSsoNames) {
      history.push("/")
    }
  }, [auth0Error, isRegisterSsoNames])

  const getUnknownErrorMessage = () => {
    const isSignUp = history.location.pathname.includes("sign-up")
    return isSignUp
      ? `
          <p>There was a problem with ${auth0Type || "..."}.</p>
          <p>Please go back to the sign up selection and try again.</p>
        `
      : `<p>Log in failed with ${auth0Type}. Please try again.</p>`
  }

  useEffect(() => {
    getUnknownErrorMessage()
  }, [])

  return useMemo(() => {
    const notConnectedTitle = `${auth0Type} is not connected`

    switch (auth0Error?.code) {
      case AUTH0_ERRORS.NOT_CONNECTED:
        return {
          title: notConnectedTitle,
          message: "<p>Please try a different login method.</p>",
          confirmText: "Close",
        }
      case AUTH0_ERRORS.TRY_EMAIL:
        return {
          title: notConnectedTitle,
          message: `<p>Looks like you already have an account with ${
            auth0Error?.errorEmail || "..."
          }.</p>
                    <p>Enter the password to log in.</p>`,
          confirmText: "Log in with email",
          confirmAction: callback => {
            history.push("/new-sign-in-email")
            callback()
          },
          closeText: "Close",
        }
      case AUTH0_ERRORS.ANOTHER_SSO:
        return {
          title: `Continue and Sign Up with ${auth0Type}?`,
          message: `<p>You do not have a ${auth0Type} login for a CooWe account.</p>
                    <p>However, ${
                      auth0Error?.errorEmail || "..."
                    } proved by ${auth0Type} is in use by another CooWe account, 
                    Please log into that account. </p>`,
          closeAction: () => history.push("/sign-up"),
        }
      case AUTH0_ERRORS.EXISTING_USER:
        return {
          title: `Continue and Sign Up with ${auth0Type}?`,
          message: `<p>Looks like you already have an account with ${
            auth0Error?.errorEmail || "..."
          }.</p>`,
          confirmText: "Back to login",
          confirmAction: callback => {
            history.push("/new-sign-in-email")
            callback()
          },
          closeText: "Continue to create a new account",
          closeAction: callback => {
            setIsRegisterSsoNames(true)
            setIsCreateUserAnyway(true)
            callback()
          },
          status: auth0Error?.status,
        }
      default:
        return {
          title: "There was a problem",
          message: getUnknownErrorMessage(),
          confirmText: "Close",
        }
    }
  }, [])
}
