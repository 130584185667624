export const TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS =
  "TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS"
export const TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE =
  "TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE"

export const TOP_LEVEL_SET_IS_LOGIN_IN_PROGRESS =
  "TOP_LEVEL_SET_IS_LOGIN_IN_PROGRESS"

export const TOP_LEVEL_SET_IS_REGISTER_CHECK_IDENTITY =
  "TOP_LEVEL_SET_IS_REGISTER_CHECK_IDENTITY"
