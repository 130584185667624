import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.div`
  ${({ $isWrapped, $isOpen }) => css`
    width: 192px;
    min-width: 192px;
    min-height: 100vh;
    background-color: var(--white);
    border-right: 1px solid var(--gray3);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 10;

    &::-webkit-scrollbar {
      display: none;
    }

    ${$isWrapped &&
    css`
      width: 100px;
      min-width: 100px;
    `};

    ${$isOpen &&
    css`
      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
        width: 100%;
      }
    `};

    ${!$isOpen &&
    css`
      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        width: 0;
        left: -300px;
        position: absolute;
      }
    `};
  `};
`

export const CrossWrapper = styled.div`
  ${({ $isOpen }) => css`
    @media (min-width: ${MEDIA_SIZES.MOBILE}) {
      display: none;
    }

    ${$isOpen &&
    css`
      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        position: absolute;
        right: 16px;
        top: 30px;
      }
    `};

    ${!$isOpen &&
    css`
      display: none;
    `};
  `};
`

export const LogoWrapper = styled.div`
  padding: 24px 16px;
  justify-content: center;
  display: flex;
`

export const Title = styled.div`
  cursor: pointer;
  padding: 16px;
  color: var(--gray4);
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--gray6);
  }
`

export const Logo = styled(SvgIcon)`
  ${({ $isWrapped }) => css`
    width: 99px;
    height: 19px;
    display: block;
    transition: all 0.2s ease-in-out;

    ${$isWrapped &&
    css`
      width: 34px;
      height: 31px;
      margin: 24px auto 20px auto;
    `}
  `}}
`

export const Navigation = styled.nav`
  width: 100%;
  border-top: 1px solid var(--gray3);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const FirstPartList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`

export const ListItem = styled.li`
  ${({
    $isClickable,
    $isGroup,
    $isCreate,
    $isActive,
    $isWrapped,
    $isProfileLink,
  }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    border-left: 4px solid transparent;
    transition: all 0.2s ease-in-out;

    ${($isCreate || !$isClickable) &&
    css`
      padding: 16px 16px 16px 12px;
    `};
    ${$isCreate &&
    css`
      &:active {
        opacity: 0.4;
      }
    `};
    ${$isActive &&
    !$isProfileLink &&
    !$isCreate &&
    css`
      background-color: #fdf6f7;
      border-left: 4px solid #f42862;
    `}
    ${$isWrapped &&
    css`
      justify-content: center;
    `};

    ${$isGroup &&
    css`
      padding-left: 0px;
      border-left: 0;
    `};

    ${$isProfileLink &&
    css`
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--lightGray5);

        position: absolute;
        top: -8px;
        left: 0;
      }
    `};
  `};
`

export const Name = styled(NSubtitleS)`
  ${({ $isGroup, $isActive, $color }) => css`
    ${$isGroup
      ? css`
          color: ${$color || "var(--gray4)"};
          font-weight: 500;
          font-size: 10px;
          line-height: 20px;
          text-transform: uppercase;
        `
      : css`
          color: ${$color || $isActive ? "#F42862" : "var(--gray6)"};
          font-weight: ${NFontWeight.medium};
          line-height: 20px;
        `}
  `};
`

export const BottomPart = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
