import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { updateGuestReaction } from "@actions/guestActions"
import { useReactionsButtons } from "@components/PreviewSuggestionReactions/hooks"
import { SvgIcon } from "@components/common"
import { Preview, Suggestion } from "types/suggestions"

import "./index.scss"

interface Props {
  suggestion: Suggestion
  updateGuestReaction: (data: any) => void
  preview: Preview
}

const PreviewReactionButtons = ({
  suggestion,
  updateGuestReaction,
  preview,
}: Props) => {
  const reactionButtons = useReactionsButtons({
    reactions: suggestion?.reactions,
    suggestionId: suggestion?.id,
    updateGuestReaction,
  })

  return (
    <div className="preview-reaction-buttons">
      {reactionButtons.map(button => (
        <div
          className="preview-reaction-buttons__button"
          onClick={button.onClick}>
          <SvgIcon
            name={button.iconName}
            css={{
              filter:
                "invert(0%) sepia(0%) saturate(0%) hue-rotate(100deg) brightness(112%) contrast(103%)",
            }}
          />
          {preview.shortReactions ? (
            <span>{button.amount}</span>
          ) : (
            <span>{button.label}</span>
          )}
        </div>
      ))}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  updateGuestReaction: bindActionCreators(updateGuestReaction, dispatch),
})

export default connect(null, mapDispatchToProps)(PreviewReactionButtons)
