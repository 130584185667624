const reverseMapping = (keys, mapping) => {
  let reverse = {}
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i]
    reverse[mapping[key]] = key
  }
  return reverse
}

const prepareMappings = mapping => {
  const keys = Object.keys(mapping)
  const reverse = reverseMapping(keys, mapping)
  const values = Object.keys(reverse)

  const structured = {
    mapping,
    keys,
    values,
    reverse,
    ...mapping,
  }
  return structured
}

const gameplanTypes = prepareMappings({
  when: 1,
  where: 2,
  what: 3,
  startsWhen: 4,
  worksWhen: 5,
})

const gameplanOptionStatusIds = prepareMappings({
  POLLING: 1,
  POLL_COMPLETE: 2,
  POLL_CLOSED: 3,
  SCHEDULED: 4,
})

const reactionTypes = prepareMappings({
  love: 1,
  upvote: 2,
  downvote: 3,
  neutral: 4,
  notVote: 5,
  XMark: 6,
  question: 7,
  checkMark: 8,
})

const reactionsTranslations = {
  2: "👍",
  3: "👎",
  5: "🚫",
  6: "❌",
  7: "❓",
  8: "✔",
}

const reactionsTranslationsNaming = {
  2: "Likes",
  3: "Dislikes",
  5: "Can’t do",
}

const reactionsConductNaming = {
  2: "liked",
  3: "disliked",
  5: "retracted",
}

const teeupStatusTypes = prepareMappings({
  1: "planning",
  2: "allset",
  3: "happening",
  4: "ended",
  5: "cancelled",
  6: "happeningSoon",
})

const statusText = {
  planning: "PLANNING",
  allset: "ALL SET",
  happening: "HAPPENING NOW",
  ended: "ENDED",
  cancelled: "CANCELLED",
  happeningSoon: "HAPPENING SOON",
}

const teeupNotificationsTypes = prepareMappings({
  criticalMassChallenge: "criticalMassEnabled",
  happeningSoon: "happeningSoon",
})

const userStatusTypes = prepareMappings({
  1: "invited",
  2: "going",
  3: "mightgo",
  4: "notgoing",
  5: "joined",
  6: "onmyway",
  7: "arrived",
  8: "attended",
  9: "droppedout",
  10: "skipped", // removed status,
  11: "approvalWaiting",
  12: "approvalDeclined",
  13: "coolIdea",
  17: "runningLate",
  18: "backedOut",
  19: "kickedOut",
  20: "interested",
  21: "notInterested",
})

const conversationTypeIds = {
  message: 1,
  suggestion: 2,
  status: 3,
  initial: 4,
  reply: 5,
  gameplanUpdate: 6,
  durationUpdate: 7,

  imageChange: 9,
  nudge: 10,

  // Custom messages, not from backend
  suggestionMessage: 101,
  image: 102,
  file: 103,
  video: 104,
  template: 105,
  gameplanSelection: 106,
}

const messageTypeIds = {
  message: 1,
  image: 2,
  video: 3,
  file: 4,
  nudge: 5,
}

const statusTypeIds = {
  teeup: 1,
  user: 2,
  reaction: 3,
  invite: 4,
  retract: 5,
  pollClose: 6,
  pollSchedule: 7,
  organizer: 8,
}

const reasonsMessage = {
  teeup: "Because you follow",
  group: "Because you follow",
  person: "Also interested in",
}

const userLoginTypes = {
  phone: "phone",
  email: "email",
  tellUs: "tellUs",
}

export {
  reactionTypes,
  messageTypeIds,
  reactionsTranslations,
  gameplanTypes,
  gameplanOptionStatusIds,
  userStatusTypes,
  teeupStatusTypes,
  teeupNotificationsTypes,
  conversationTypeIds,
  statusTypeIds,
  reactionsTranslationsNaming,
  reactionsConductNaming,
  reasonsMessage,
  userLoginTypes,
  statusText,
}
