import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { SubtitleL, CaptionL, CaptionS } from "@assets/styles/typographyStyles"

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: var(--radicalRed);
  text-align: center;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    text-align: start;

    .input-el--wrapper > input {
      border-radius: 67px;
    }

    .forgot_password-button {
      position: absolute;
      bottom: 44px;
      width: calc(100% - 34px);
    }
  }
`

export const ForgotPasswordWrapper = styled.div`
  width: 500px;
`

export const Description = styled(SubtitleL)`
  color: var(--gray4);
`

export const ResetInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: start;
  margin: 44px 0px 32px 0px;
`

export const CheckEmailFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 44px;
`
export const ResendButton = styled(CaptionL)`
  font-weight: 600;
  color: var(--radicalRed);
  cursor: pointer;

  ${({ second }) =>
    second &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`

export const PasswordRequirementsWrapper = styled.div`
  margin-bottom: 32px;

  > div {
    background: var(--white);
  }
`

export const PasswordUpdateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 44px;
`

export const PasswordResetWrapper = styled.div`
  text-align: start;
`

export const Error = styled(CaptionS)`
  color: var(--error);
`

export const BackWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  color: var(--white);
  gap: 8px;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    display: none;
  }
`
