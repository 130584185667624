import { useEffect, useState } from "react"

import { SvgIcon } from "@components/common"
import DefaultModal from "@ui/DefaultModal"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import ModalHeader from "../../../ui/DefaultModal/Header"
import ActionButton from "../../ActionButton"

import Option from "./Option"

import "./index.scss"

const NudgeModal = () => {
  const isNudgeModalOpen = useTeeupPeopleStore(state => state.isNudgeModalOpen)
  const setIsNudgeModalOpen = useTeeupPeopleStore(
    state => state.setIsNudgeModalOpen
  )
  const setIsAskPeopleModalOpen = useTeeupPeopleStore(
    state => state.setIsAskPeopleModalOpen
  )
  const setIsOnGoingSelected = useTeeupPeopleStore(
    state => state.setIsOnGoingSelected
  )

  const handleOpen = () => setIsNudgeModalOpen(true)
  const handleClose = () => setIsNudgeModalOpen(false)

  const handleGoingClick = () => {
    setIsAskPeopleModalOpen(true)
    setIsOnGoingSelected(true)
    handleClose()
  }

  const handleWhereClick = () => {
    setIsAskPeopleModalOpen(true)
    setIsOnGoingSelected(false)
    handleClose()
  }

  return (
    <div>
      <ActionButton onClick={handleOpen} className="people-attendance-button">
        <SvgIcon name="nudgePeopleNew" color="var(--gray4)" />
        Nudge
      </ActionButton>
      <DefaultModal open={isNudgeModalOpen} onClose={handleClose}>
        <div className="people-nudge-content">
          <ModalHeader title="Nudge Options" onClose={handleClose} />

          <div className="people-nudge-option-container">
            <Option
              title="Ask People if they are going"
              onClick={handleGoingClick}
            />
            <Option
              title="Ask People where they are"
              onClick={handleWhereClick}
            />
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default NudgeModal
