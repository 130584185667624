import React from "react"

import { SvgIcon } from "@components/common"

import { Wrapper, Text } from "./statusLabelStyles"

export const StatusLabel = ({ isDecided, text, type }) => {
  const typeIconName = type === "when" ? "clock-2" : "location-2"
  const textColor = isDecided ? "var(--green3)" : "var(--darkBlue)"

  return (
    <Wrapper>
      <SvgIcon name={typeIconName} color={textColor} />

      <Text $textColor={textColor}>{text}</Text>
    </Wrapper>
  )
}
