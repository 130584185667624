import React from "react"

import CooWeLogo from "../../../assets/images/CooWeWhiteLogo.svg"
import { Subtitle } from "../../../assets/styles/typographyStyles"
import { FooterSocialNetworks } from "../../common"
import { Footer } from "../teeupPreviewStyles"

export const FooterBlock = () => (
  <Footer>
    <img src={CooWeLogo} alt="CooWe" />

    <Subtitle style={{ color: "#fff", margin: "30px 0" }}>
      © {new Date().getFullYear()} CooWe. All rights reserved.
    </Subtitle>

    <FooterSocialNetworks />
  </Footer>
)
