import React, { useState } from "react"

import { PreviewMessageGroupPopup } from "components"

import { Wrapper, Text, Icon } from "./messageTheGroupStyles"

export const MessageTheGroup = () => {
  const [isPopup, setIsPopup] = useState(false)

  return (
    <>
      <Wrapper onClick={() => setIsPopup(true)}>
        <Text>Message The Group</Text>

        <Icon name="chat-icon" />
      </Wrapper>

      {isPopup && <PreviewMessageGroupPopup close={() => setIsPopup(false)} />}
    </>
  )
}
