export const AUTH0_ACTION_TYPES = {
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
}

export const AUTH0_ERRORS = {
  EXISTING_USER: "EXISTING_USER",
  WAS_A_PROBLEM: "WAS_A_PROBLEM",
  NOT_CONNECTED: 6,
  TRY_EMAIL: 7,
  LINKED_ALREADY: 9,
  ANOTHER_SSO: 62,
}
