import styled, { css } from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"
import { BUTTON_SIZES, BUTTON_VARIANTS } from "@constants/button"

import { SvgIcon } from "../SvgIcon"

export const Button = styled.button`
  border: none;
  background-color: transparent;
  border-radius: 36px;
  cursor: pointer;
  font-weight: ${NFontWeight.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 46px;
  outline: none;
  min-width: 110px;
  white-space: nowrap;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:active {
    opacity: 0.4;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 0 35px;
    `};

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `};

  ${({ $isAutoWidth }) =>
    $isAutoWidth &&
    css`
      min-width: unset;
      padding: 6px;
    `};

  ${({ variant }) =>
    !!variant &&
    css`
      ${variant === BUTTON_VARIANTS.BORDERED &&
      css`
        border: 2px solid var(--radicalRed);
        color: var(--radicalRed);
        font-family: greycliffcf-bold-salt;
        padding: 6px 12px;
      `};

      ${variant === BUTTON_VARIANTS.ROUNDED &&
      css`
        background-color: var(--radicalRed);
        color: var(--white);
        font-family: greycliffcf-medium-salt;
        font-size: 18px;
      `};

      ${variant === BUTTON_VARIANTS.TRANSPARENT &&
      css`
        color: var(--radicalRed);
        font-family: greycliffcf-bold-salt;
      `};

      ${variant === BUTTON_VARIANTS.UNDERLINE &&
      css`
        border-bottom: 1px solid #3c446f;
        border-radius: 0;
        color: #3c446f;
        font-family: greycliffcf-regular-salt;
        font-size: 15px;
        padding: 0;
        &:disabled {
          border-color: transparent;
        }
      `};

      ${({ $size }) =>
        $size &&
        css`
          ${$size === BUTTON_SIZES.SMALL &&
          css`
            font-size: 13px;
            padding: 6px 15px;
          `};

          ${$size === BUTTON_SIZES.MEDIUM &&
          css`
            font-size: 14px;
            padding: 6px 30px;
          `};
        `};
    `};

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};

  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `};

  ${({ $textColor }) =>
    $textColor &&
    css`
      color: ${$textColor};
      ${({ variant }) =>
        variant === BUTTON_VARIANTS.UNDERLINE &&
        css`
          border-color: ${$textColor};
        `}
    `};

  ${({ variant, $isBlack }) =>
    $isBlack &&
    variant === BUTTON_VARIANTS.ROUNDED &&
    css`
      background-color: var(--black2);
      color: var(--white);
    `};

  ${({ variant, $isBlack }) =>
    $isBlack &&
    variant === BUTTON_VARIANTS.TRANSPARENT &&
    css`
      color: var(--black2);
    `};
  ${({ $isReverse }) =>
    $isReverse &&
    css`
      flex-direction: row-reverse;
    `};
  ${({ $isNoPadding }) =>
    $isNoPadding &&
    css`
      padding: 0;
    `};
  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `};
  ${({ $fontSize }) =>
    $fontSize &&
    css`
      font-size: ${$fontSize}px;
    `};
`

export const ButtonIcon = styled(SvgIcon)`
  ${({ $isIconFirst }) =>
    $isIconFirst
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `};
`
