import React from "react"

import filter from "lodash/filter"
import PropTypes from "prop-types"
import {
  View,
  Text,
  FlatList,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from "react-native"
import { connect } from "react-redux"
// import Icon from 'react-native-vector-icons/AntDesign'
import { bindActionCreators } from "redux"

// import { showRespondToPoll, showClosePollScreen } from '@nav'
import { reactionTypes, gameplanOptionStatusIds } from "@configs/mappings"
import I18n from "@i18n"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectTeeupReactions, selectTeeupSuggestions } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { AppColors, Units } from "@theme"
import Avatar from "@ui/avatar"
import Toast from "@ui/toast"
import { getDateWithTimezone } from "@utils/dateUtils"
import { getUserReactions, getSuggestionsToDisplay } from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"

import { styles } from "./styles"

class WhenWorksSection extends React.Component {
  constructor(props) {
    super(props)

    const { suggestions } = props
    this.state = {
      suggestions,
      timeReactionItemSize: Units.responsiveValue(56),
      timeHeight: Units.responsiveValue(140),
    }
  }

  componentDidMount() {
    const { getParticipants, people, teeupId } = this.props
    getParticipants(teeupId)
    if (this.props.people && Object.keys(people).length > 0) {
      this.setTimeHeight()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.people).length !==
      Object.keys(this.props.people).length
    ) {
      this.setTimeHeight()
    }
  }

  setTimeHeight = () => {
    this.setState({
      timeHeight: Units.responsiveValue(
        140 +
          Object.keys(this.props.people).length *
            this.state.timeReactionItemSize
      ),
    })
  }

  updateVipStatus = (userId, newStatus) => () => {
    const { isCurrentUserOrganiser, changeVipStatus } = this.props
    if (isCurrentUserOrganiser) {
      changeVipStatus(userId, newStatus)
    } else {
      Toast.show(I18n.t("changeVIPstatus"))
    }
  }

  renderTimeItem = ({ item, index }) => {
    if (item.time?.length === 0 && item.usersReaction?.length === 0) {
      return <View style={styles.emptyColumn} />
    }

    const time = getDateWithTimezone(item.time)
    const approvedTime =
      item.usersReaction.filter(
        r => r.reactionId === reactionTypes.checkMark
      ) || []
    return (
      <View
        key={time.valueOf()}
        style={[
          styles.timeSuggestions,
          index > 2 && index % 2 !== 0 ? styles.darkColumn : styles.lightColumn,
          { height: this.state.timeHeight },
        ]}>
        <Text style={styles.month}>{time.format("MMM")}</Text>
        <Text style={styles.dayNumber}>{time.format("DD")}</Text>
        <Text style={styles.day}>{time.format("ddd")}</Text>
        <Text style={styles.time}>{time.format("h:mma").slice(0, -1)}</Text>
        <View style={styles.total}>
          <View
            style={[
              styles.totalIcon,
              approvedTime.length > 0
                ? styles.activeTotalIcon
                : styles.zeroTotalIcon,
            ]}>
            {/* <Icon
                            name="check"
                            size={10}
                            color={AppColors.brand.white}
                        /> */}
          </View>
          <Text style={styles.totalCount}>{approvedTime.length}</Text>
        </View>
        {this.renderReactions(item.usersReaction)}
      </View>
    )
  }

  calculateReactionPosition = timeReaction => {
    return (
      Units.responsiveValue(140) +
      timeReaction.position * Units.responsiveValue(56) +
      Units.responsiveValue(16)
    )
  }

  getImageSource = reactionId => {
    switch (reactionId) {
      case reactionTypes.XMark:
        return images.redCross
      case reactionTypes.question:
        return images.orangeQuestion
      case reactionTypes.checkMark:
        return images.greenCheck
    }
  }

  renderReactions = (reactions = []) => {
    return reactions.map((r, i) => (
      <Image
        style={[
          styles.reactionImage,
          { top: this.calculateReactionPosition(r) },
        ]}
        key={r.userId + "_" + r.time.valueOf() + "_" + i}
        source={this.getImageSource(r.reactionId)}
      />
    ))
  }

  updateReactions = reactions => {
    reactions.forEach(r => {
      r.values.forEach(suggestionId => {
        if (suggestionId) {
          this.props.addReaction({
            reactionId: r.type,
            suggestionId,
            teeupId: this.props.teeupId,
            userId: this.props.userId,
          })
        }
      })
    })
  }

  showRespondToPollScreen(item) {
    const { gameplan, suggestions, userId } = this.props
    if (
      item.isCurrentUser &&
      gameplan.statusId !== gameplanOptionStatusIds.POLL_COMPLETE &&
      gameplan.statusId !== gameplanOptionStatusIds.POLL_CLOSED
    ) {
      /* eslint-disable no-undef */
      // showRespondToPoll({
      //     ...item,
      //     suggestions: getWhenWorksSuggestions(
      //         getTimeSortedSuggestions(
      //             getSuggestionsToDisplay(suggestions)
      //         )
      //     ),
      //     addReaction: this.updateReactions,
      //     reactions: getUserReactions(suggestions, userId),
      // })
    }
  }

  renderContactItem = ({ item, index }) => {
    let contactLineStyle = [styles.statusLine]
    let contactRowStyle = [
      styles.contactRow,
      item.isCurrentUser ? styles.userContactRow : null,
    ]
    if (!item.hasReactions) {
      contactLineStyle = [
        ...contactLineStyle,
        styles.statusLineColor,
        item.isCurrentUser ? styles.redLine : null,
      ]
      contactRowStyle = [...contactRowStyle, styles.userContactOpacity]
    }
    const hasAnswers = this.isAnswered(item.id)
    return (
      <TouchableWithoutFeedback
        onPress={() => this.showRespondToPollScreen(item)}>
        <View
          style={[
            contactRowStyle,
            index % 2 === 0 ? styles.lightRow : styles.darkRow,
          ]}>
          <View style={contactLineStyle}>
            <TouchableOpacity
              onPress={this.updateVipStatus(item.id, !item.vip)}>
              <Image
                style={styles.vip}
                source={
                  item.vip
                    ? item.isCurrentUser
                      ? hasAnswers
                        ? images.yellowStar
                        : images.whiteStar
                      : images.yellowStar
                    : images.greyStar
                }
              />
            </TouchableOpacity>
            <View style={[styles.userAvatar]}>
              <Avatar
                imageUrl={item.avatar}
                size={40}
                borderWidth={item.vip || hasAnswers ? 2 : 0}
                borderColor={
                  item.isCurrentUser
                    ? hasAnswers
                      ? AppColors.brand.yellowBorder
                      : AppColors.brand.white
                    : item.vip
                    ? AppColors.brand.yellowBorder
                    : hasAnswers
                    ? AppColors.brand.blue2
                    : null
                }
              />
            </View>
            {!item.hasReactions && (
              <Text style={styles.statusLabel}>
                {I18n.t(item.isCurrentUser ? "respondToPoll" : "noResponseYet")}
              </Text>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  isAnswered = userId => {
    return getUserReactions(this.props.suggestions, userId).length > 0
  }

  freeTime = () => ({ time: [], usersReaction: [] })

  getSortedTime = (suggestions, timeReactions) => {
    let sortedTime = []
    suggestions.forEach(s => {
      s.time.forEach(t => {
        const reactions = timeReactions.filter(
          tr =>
            getDateWithTimezone(tr.time).valueOf() ===
            getDateWithTimezone(t.time).valueOf()
        )
        sortedTime.push({ ...t, usersReaction: reactions || [] })
      })
    })

    const sortedTimeList = sortedTime.sort(
      (a, b) =>
        getDateWithTimezone(a.time).valueOf() -
        getDateWithTimezone(b.time).valueOf()
    )
    return [this.freeTime(), this.freeTime(), this.freeTime()].concat(
      sortedTimeList
    )
  }

  getSortedContacts = (people, userId) => {
    let participants = Object.values(people)
    const mappedParticipants = participants.map(p => {
      const user = {
        id: p.id,
        avatar: p.avatar,
        vip: p.isVip,
      }
      if (p.id === userId) {
        user.isCurrentUser = true
      }
      return user
    })
    return mappedParticipants.sort((a, b) => b.vip - a.vip)
  }

  updateParticipantsAction = (participants, timeReactions) => {
    const updatedParticipants = []
    participants.forEach(p => {
      const user = { ...p }
      if (timeReactions.some(t => t.userId === p.id)) {
        user.hasReactions = true
      }
      updatedParticipants.push(user)
    })
    return updatedParticipants
  }

  getTimeReactions = (suggestions = [], people = []) => {
    let timeReactions = []
    suggestions.forEach(s => {
      if (s?.time && s.time?.length > 0) {
        s.time.forEach(t => {
          if (t.reactions && t.reactions.length > 0) {
            t.reactions.forEach(r => {
              r.reactedBy.forEach(userId => {
                const posIndex = people.findIndex(p => p.id === userId)
                if (posIndex !== -1) {
                  timeReactions.push({
                    time: t.time,
                    userId,
                    position: posIndex,
                    reactionId: r.reactionId,
                  })
                }
              })
            })
          }
        })
      }
    })
    return timeReactions
  }

  render() {
    const {
      suggestions,
      people,
      userId,
      isShowClosePollButton,
      isCurrentUserOrganiser,
    } = this.props
    const showCloseButton = isShowClosePollButton && isCurrentUserOrganiser
    let participants = this.getSortedContacts(people, userId)
    const dispSuggestions = getSuggestionsToDisplay(suggestions)
    const timeReactions = this.getTimeReactions(dispSuggestions, participants)

    const time = this.getSortedTime(dispSuggestions, timeReactions)
    const blockHeight = {
      height:
        this.state.timeHeight +
        (showCloseButton ? Units.responsiveValue(64) : 0),
    }
    participants = this.updateParticipantsAction(participants, timeReactions)
    return (
      <View style={[styles.container, blockHeight]}>
        <View style={[styles.strictContactsBackground, blockHeight]} />
        {showCloseButton && (
          <View style={styles.closePollBlock}>
            <TouchableOpacity
              style={styles.closePollBtn}
              // onPress={showClosePollScreen}
            >
              <Text style={styles.closePollText}>{I18n.t("closePoll")}</Text>
            </TouchableOpacity>
          </View>
        )}
        <View
          style={[
            styles.timeList,
            {
              marginTop: Units.responsiveValue(showCloseButton ? 64 : 0),
            },
          ]}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal
            data={time}
            renderItem={this.renderTimeItem}
            keyExtractor={(item, index) => `group-${item.id}-${index}`}
          />
        </View>
        <View
          style={[
            styles.contactsBlock,
            showCloseButton ? { marginTop: Units.responsiveValue(200) } : null,
          ]}>
          <View style={[styles.backBlock, blockHeight]} />
          <FlatList
            nestedScrollEnabled={true}
            renderScrollComponent={false}
            data={participants}
            renderItem={this.renderContactItem}
            keyExtractor={(item, index) => `group-${item.id}-${index}`}
          />
        </View>
      </View>
    )
  }
}

WhenWorksSection.defaultProps = {
  isShowClosePollButton: true,
}

WhenWorksSection.propTypes = {
  suggestions: PropTypes.array.isRequired,
  people: PropTypes.object.isRequired,
  reactions: PropTypes.object,
  gameplan: PropTypes.object,
  userId: PropTypes.number,
  teeupId: PropTypes.number,
  addReaction: PropTypes.func,
  changeVipStatus: PropTypes.func,
  getParticipants: PropTypes.func,
  isShowClosePollButton: PropTypes.bool,
  isCurrentUserOrganiser: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  const userId = selectUserId(state)
  const teeup = selectActiveTeeup(state)
  const reactions = selectTeeupReactions(state)
  const suggestions = selectTeeupSuggestions(state)
  const organisers = teeup.organisers || []
  const isCurrentUserOrganiser =
    teeup.createdby === userId ||
    filter(organisers, ({ id }) => id === userId).length > 0
  return {
    userId,
    isCurrentUserOrganiser,
    teeupId: teeup.id || null,
    reactions,
    suggestions,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // addReaction: teeupActions.addReaction,
      // changeVipStatus: teeupActions.changeVipStatus,
      // getParticipants: teeupActions.getParticipants,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WhenWorksSection)
