import React from "react"

import { SubtitleXS } from "@assets/styles/typographyStyles"

import { TextWrapper, DescriptionTitle } from "../getAppBadgeStyles"

export const Text = () => (
  <TextWrapper>
    <DescriptionTitle>CooWe - Group Coordination</DescriptionTitle>

    <SubtitleXS style={{ color: "#8d8d8d" }}>Productivity</SubtitleXS>
  </TextWrapper>
)
