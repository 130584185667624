import React, { useEffect } from "react"

import TimeWheel from "pages/TeeUpPage/TeeUpPageModals/SuggestWhenModalNd/TimeWheelTab/TimeWheel"
import GroupInfo from "pages/TeeUpPage/TeeUpPageModals/SuggestWhenModalNd/TimeWheelTab/TimeWheel/GroupInfo"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import dayjs from "utils/dayjs"

import "./index.scss"

const Timezones = () => {
  const currentDate = useSuggestionTimeWheelStore(state => state.currentDate)
  const setCurrentDate = useSuggestionTimeWheelStore(
    state => state.setCurrentDate
  )

  useEffect(() => {
    setCurrentDate(dayjs())
  }, [])

  if (!currentDate) return false

  return (
    <div className="suggestion-popup__timezones">
      <GroupInfo />
      <TimeWheel isStatic />
    </div>
  )
}

export default Timezones
