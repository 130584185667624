import React, { useMemo } from "react"

import PropTypes from "prop-types"

import { isPastSuggestion } from "@utils/gamePlanUtils"

import ReactionButton from "../../gamePlansCard/reactionButton"

export const Reactions = ({ suggestion }) => {
  const isInactive = useMemo(() => {
    const isPast = isPastSuggestion(suggestion, "day")
    const isWithdrawn = suggestion.isWithdrawn

    return isPast || isWithdrawn
  }, [suggestion])

  return (
    <ReactionButton
      suggestionId={suggestion.id}
      inactive={isInactive}
      inSuggestion
    />
  )
}

Reactions.propTypes = {
  suggestion: PropTypes.object,
}
