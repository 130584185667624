import React from "react"

import PropTypes from "prop-types"
import { Image } from "react-native"
import Switch from "react-switch"

import { images } from "@utils/imageUtils"

import styles, { selectedButtonImage } from "./styles"

export const ToggleButton = ({
  onToggle,
  value,
  disabled,
  isBlack = false,
}) => {
  return (
    <Switch
      data-testid={value}
      onChange={onToggle}
      checked={value}
      disabled={disabled}
      uncheckedIcon={false}
      checkedIcon={false}
      width={44}
      height={24}
      offColor="#9D9D9D"
      onColor={isBlack ? "#303030" : "#F42862"}
    />
  )
}

ToggleButton.propTypes = {
  onToggle: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

export const ArrowButton = () => {
  return (
    <Image
      style={styles.arrowButtonImage}
      source={images.chevron}
      resizeMode="contain"
    />
  )
}

export const AddButton = () => {
  return (
    <Image
      style={styles.plusButtonImage}
      source={images.pinkAddPlusWithBg}
      resizeMode="contain"
    />
  )
}

export const SelectedButton = ({ color }) => {
  return (
    <Image
      style={selectedButtonImage(color)}
      source={images.cmVerified}
      resizeMode="contain"
    />
  )
}

SelectedButton.propTypes = {
  color: PropTypes.string,
}

// export const DeleteButton = () => {
//     return (
//         <Image
//             style={styles.arrowButtonImage}
//             source={images.chevron}
//             resizeMode="contain"
//         />
//     )
// }
