import React, { useState, useEffect } from "react"

import { CaptionS } from "@assets/styles/typographyStyles"

import { Description, ResendButton, CheckEmailFooter } from "../molecules"

export const CheckYourEmailStep = ({
  currentlyEmail,
  handleClickSendRecoveryLink,
}) => {
  const timer = 60
  const [seconds, setSeconds] = useState(timer)

  const handleResendEmail = () => {
    handleClickSendRecoveryLink()
    setSeconds(timer)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1)
    }, 1000)

    if (seconds === 0) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  return (
    <div>
      <Description>
        An email has been sent to {currentlyEmail} with a link to reset your
        password.
      </Description>
      <CheckEmailFooter>
        <CaptionS>Didn't receive an email?</CaptionS>
        <ResendButton onClick={handleResendEmail} second={seconds !== 0}>
          Resend Email {seconds !== 0 && `(${seconds}sec)`}
        </ResendButton>
      </CheckEmailFooter>
    </div>
  )
}
