import { useEffect, useState } from "react"

import { useParams } from "react-router-dom"

import endpoints from "@config/endpoints"
import { teeupUserStatusPriorities } from "@config/enums"

import api from "../../../api"
import { TEEUP_PREVIEW_ERRORS } from "../constants"

export const usePreviewInfo = ({
  isInbox,
  isSharedLink,
  teeupSlug,
  closePreviewError,
}) => {
  const [isPreviewLoading, setIsPreviewLoading] = useState(true)
  const [previewInfo, setPreviewInfo] = useState(null)
  const [previewError, setPreviewError] = useState(null)
  const { slug } = useParams()
  const previewUrl = endpoints.teeup.preview(slug)
  const previewBySlugUrl = endpoints.teeup.previewBySlug(
    isSharedLink ? slug : teeupSlug
  )

  const catchResponseError = error => {
    if (error) {
      setPreviewError(TEEUP_PREVIEW_ERRORS[error.code])
    }
  }

  // getting of preview info
  useEffect(() => {
    if (isInbox || isSharedLink) {
      api.client
        .get(previewBySlugUrl)
        .then(res => {
          const error = res.data.error
          catchResponseError(error)

          setPreviewInfo(res.data.preview)
          setIsPreviewLoading(false)
        })
        .catch(error => {
          setPreviewError(error.status)
        })
        .finally(() => {
          setIsPreviewLoading(false)
        })

      return
    }

    api.client
      .get(previewUrl)
      .then(res => {
        const error = res.data.error
        catchResponseError(error)

        setPreviewInfo(res.data.preview)
        setIsPreviewLoading(false)
      })
      .catch(error => {
        if (error.status === TEEUP_PREVIEW_ERRORS.NOT_FOUND) {
          closePreviewError()
        } else {
          setPreviewError(error.status)
        }
      })
      .finally(() => {
        setIsPreviewLoading(false)
      })
  }, [teeupSlug])

  const updatePreviewInfo = data => {
    setPreviewInfo(prevState => ({
      ...prevState,
      invitee: {
        ...prevState.invitee,
        status: teeupUserStatusPriorities[data.status],
        name: data.fullName,
        timezone: data.timezone,
      },
    }))
  }

  return {
    isPreviewLoading,
    previewInfo,
    previewError,
    setPreviewError,
    updatePreviewInfo,
  }
}
