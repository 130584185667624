import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  z-index: 2;
`

export const StatusesList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  column-gap: 15px;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    column-gap: 0;
    flex-direction: column;
    row-gap: 7px;
  }
`

export const StatusItem = styled.li`
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  border-radius: 84px;
  cursor: pointer;
  column-gap: 7px;
  display: flex;
  align-items: center;
  padding: 8px 10px;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 45%;
    justify-content: center;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 90%;
  }

  ${({ $isActive }) =>
    $isActive &&
    `
    border: 1px solid var(--darkGray2);
  `}
`

export const StatusIcon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
`

export const Description = styled(NSubtitleS)`
  color: var(--gray5);
`

export const StatusTitle = styled(NSubtitleS)`
  color: var(--darkGray2);
  font-weight: 500;
  white-space: nowrap;
`
