import { Fragment, useEffect, useState } from "react"

import cn from "classnames"

import { SvgIcon } from "@components/common"
import Separator from "@components/ui/DefaultModal/Separator"
import Pill from "@components/ui/Pill"
import { getTimeListOptions } from "@utils/suggestionUtils"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"
import { TimeListOption } from "types/suggestions"

import "../DateRange/index.scss"
import dayjs from "utils/dayjs"

const today = dayjs()

const TimeList = () => {
  const currentDate = useSuggestionGeneralDateStore(state => state.currentDate)
  const setIsCustomTimeOpen = useSuggestionGeneralDateStore(
    state => state.setIsCustomTimeOpen
  )
  const triggerCustomTimeUpdate = useSuggestionGeneralDateStore(
    state => state.triggerCustomTimeUpdate
  )
  const currentTime = useSuggestionGeneralDateStore(state => state.currentTime)
  const setCurrentTime = useSuggestionGeneralDateStore(
    state => state.setCurrentTime
  )

  const [timeList, setTimeList] = useState<TimeListOption[]>([])
  const currentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.currentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionGeneralDateStore(
    state => state.updateSuggestion
  )
  const isCurrentDateToday = currentDate?.[0]?.isSame(today, "day")

  const isBlockDisabled = !currentDate

  useEffect(() => {
    getTimeList()
  }, [triggerCustomTimeUpdate])

  const getTimeList = () => {
    const timeListOptions = getTimeListOptions()

    const customTime = JSON.parse(
      localStorage.getItem("customTime") || "[]"
    ) as TimeListOption[]

    if (customTime.length > 0) {
      timeListOptions.push(...customTime)
    }

    setTimeList(timeListOptions)
  }

  const handleClick = (timeListOption: TimeListOption) => {
    setCurrentTime(timeListOption)
    if (currentActiveSuggestionIndex !== -1) {
      updateSuggestion(currentActiveSuggestionIndex, {
        time: timeListOption,
      })
    }
  }

  const handleCustomTimeClick = () => {
    setIsCustomTimeOpen(true)
  }

  return (
    <div
      className={cn("date-range-container time-list", {
        "time-list__disabled": isBlockDisabled,
      })}>
      <Separator />

      <h4>Select time</h4>
      <ul className="date-range">
        {timeList.map(option => (
          <Pill
            key={option.label}
            onClick={() => handleClick(option)}
            isDisabled={isCurrentDateToday && option?.isDisabled}
            isSelected={
              option.label === currentTime?.label && !isBlockDisabled
            }>
            {option.iconName && <SvgIcon name={option.iconName} />}
            <span>{option.label}</span>
          </Pill>
        ))}
      </ul>

      <div className="add-date-range" onClick={handleCustomTimeClick}>
        + Custom time
      </div>
    </div>
  )
}

export default TimeList
