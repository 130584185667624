import { useEffect, useState } from "react"

import { useDebouncedCallback } from "use-debounce"

import { useInboxStore } from "stores/inboxStore"
import { FilterOption, FilterTypes } from "types/inbox"

import Option from "./Option"

interface Props {
  filterType: FilterTypes
  options: FilterOption[]
}

const DELAY_TIME = 200

const Content = ({ filterType, options }: Props) => {
  const [localSelectedOptions, setLocalSelectedOptions] = useState<
    FilterOption[]
  >([])
  const selectedFilters = useInboxStore(state => state.selectedFilters)
  const setSelectedFilters = useInboxStore(state => state.setSelectedFilters)

  useEffect(() => {
    setLocalSelectedOptions(selectedFilters[filterType])
  }, [selectedFilters])

  const updateFiltersState = useDebouncedCallback(
    filters => setSelectedFilters(filters, filterType),
    DELAY_TIME
  )

  const handleOptionToggle = (option: FilterOption) => {
    const isAlreadySelected = localSelectedOptions.some(
      selectedOption => selectedOption.id === option.id
    )

    if (isAlreadySelected) {
      const selectedOptions = localSelectedOptions.filter(
        selectedOption => selectedOption.id !== option.id
      )

      setLocalSelectedOptions(selectedOptions)
      updateFiltersState(selectedOptions)
      return
    }

    const newFilters = [...localSelectedOptions, option]
    setLocalSelectedOptions(newFilters)
    updateFiltersState(newFilters)
  }

  return (
    <div>
      {options.map(option => (
        <Option
          option={option}
          onClick={handleOptionToggle}
          key={option.id}
          localSelectedOptions={localSelectedOptions}
        />
      ))}
    </div>
  )
}

export default Content
