import React from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { IconWrapper, Icon } from "./circleButtonStyles"

export const CircleButton = ({ iconName, isNoPadding, onClick }) => (
  <ButtonEl
    autoWidth
    isNoPadding={isNoPadding}
    variant={BUTTON_VARIANTS.TRANSPARENT}
    onClick={onClick}>
    <IconWrapper>
      <Icon name={iconName} color="var(--darkGray2)" />
    </IconWrapper>
  </ButtonEl>
)

CircleButton.propTypes = {
  iconName: PropTypes.string,
  isNoPadding: PropTypes.bool,
  onClick: PropTypes.func,
}
