import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { getParticipantStatus } from "@utils/peopleUtils"
import { Participant } from "types/participant"

interface Props {
  participant?: Participant
}

const InviteHeadInfo = ({ participant }: Props) => {
  if (!participant) return null

  const inviteeUsername = participant?.username || ""

  return (
    <div className="flex items-center gap-1">
      <div>
        <ParticipantIcon
          avatar={participant.avatar}
          status={getParticipantStatus(participant.status)}
          isOrganizer={participant.isOrganiser}
          defaultAvatar={"A"}
          className="!size-6"
          statusClassName="!size-3"
        />
      </div>

      <div className="flex items-center gap-1">
        <span className="text-sm font-bold leading-tight text-neutral-700">
          {inviteeUsername}
        </span>
        <span className="text-sm font-medium leading-tight text-neutral-700">
          invited you to a
        </span>
        <span className="text-sm font-bold leading-tight text-neutral-700">
          TeeUp
        </span>
      </div>
    </div>
  )
}

export default InviteHeadInfo
