import { useSuggestionData } from "@components/NewTeeupGameplan/hooks"
import { Image } from "@components/SuggestionItem/suggestionItemStyles"

import "./index.scss"

const DecidedSuggestion = ({ suggestion }) => {
  const { img, value, details } = useSuggestionData(suggestion)

  return (
    <div className="suggestion-item__decided">
      <div>
        <h4>{value}</h4>
        <p>{details}</p>
      </div>

      <div>
        <div>{img && <img src={img} alt={value} />}</div>
      </div>
    </div>
  )
}

export default DecidedSuggestion
