import styled from "styled-components"

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const Checkbox = styled.input`
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid var(--gray3);
  accent-color: var(--radicalRed);
  clip: auto !important;
  clip-path: none !important;
  overflow: visible !important;
  position: static !important;
  white-space: normal !important;
`
