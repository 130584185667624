import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { createStructuredSelector } from "reselect"

import { selectToken } from "../selectors/user"

export function PublicRoute({
  component: Component,
  isDisabledRedirect = false,
  isSharedLink = false,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && !isDisabledRedirect ? (
          <Redirect to="/inbox" />
        ) : (
          <Component {...props} isSharedLink={isSharedLink} />
        )
      }
    />
  )
}

PublicRoute.propTypes = {
  isDisabledRedirect: PropTypes.bool,
  isAuthenticated: PropTypes.string,
  isSharedLink: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectToken,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(PublicRoute)
