import styled, { css } from "styled-components"

export const List = styled.ul`
  ${({ $listLength, $isReacted, $isDecided }) => css`
    display: flex;
    column-gap: 10px;
    overflow-x: scroll;
    padding-top: 15px;
    ${$isDecided &&
    css`
      overflow-x: visible;
    `};

    user-select: none;

    ${$listLength > 1 &&
    css`
      cursor: move;
    `};

    ${$isReacted &&
    css`
      padding-bottom: 25px;
    `}

    &::-webkit-scrollbar {
      display: none;
    }
  `};
`
