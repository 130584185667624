import React from "react"

import "./index.scss"

/**
 *
 * @param icon should be the value from imageUtils.js -> images
 * @param handleChange accepts function that is trigered on React's onChange
 * @param props is all the parameters that input natively accepts
 *
 */
const InputWithIcon = ({ icon, handleChange, ...props }) => {
  return (
    <div className="input-with-icon">
      <img className="icon" src={icon} alt="icon" />
      <input {...props} className="input" onChange={handleChange} />
    </div>
  )
}

export default InputWithIcon
