import styled from "styled-components"

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
`

export const Content = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
`
