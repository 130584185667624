import { useEffect } from "react"

import { BOX_SIZE } from "@utils/suggestionUtils"
import debounce from "lodash/debounce"

const useTimeWheelMouseMove = (scrollRef: any, innerRef: any) => {
  useEffect(() => {
    const gridElement = scrollRef.current
    if (!gridElement) return

    const handleMouseDown = event => {
      const initialX = event.clientX

      // @ts-ignore
      const initialScrollLeft = gridElement.state.scrollLeft

      const handleMouseMove = debounce(moveEvent => {
        const deltaX = initialX - moveEvent.clientX
        if (gridElement.state.scrollLeft < BOX_SIZE + 5 && deltaX < 0) return

        const newScrollLeft = initialScrollLeft + deltaX

        const newState = {
          scrollLeft: newScrollLeft,
          isScrolling: true,
          horizontalScrollDirection: deltaX > 0 ? "forward" : "backward",
        }
        gridElement.scrollToItem({
          columnIndex: Math.round(newState.scrollLeft / BOX_SIZE),
          align: "center",
        })
        // gridElement.scrollTo({
        //   scrollLeft: newState.scrollLeft,
        // })

        gridElement.setState(newState)
      }, 0)

      const handleMouseUp = () => {
        window.removeEventListener("mousemove", handleMouseMove)
        window.removeEventListener("mouseup", handleMouseUp)
      }

      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }

    innerRef.current?.addEventListener("mousedown", handleMouseDown)

    return () => {
      innerRef.current?.removeEventListener("mousedown", handleMouseDown)
    }
  }, [scrollRef])
}

export default useTimeWheelMouseMove
