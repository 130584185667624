import _ from "lodash"
import { createSelector } from "reselect"

import { selectTeeupsStateTeeups } from "@selectors/teeupsState"

import { teeupUserStatusKeys } from "../config/enums"

import { selectActiveTeeupId, selectActiveTeeupState } from "./activeTeeup"

const initialFiltersValues = {
  status: [], // one of teeupStatusKeys
  userStatus: [], // one of teeupUserStatusKeys
  type: [], // one of teeupTypes
  other: [],
}

const selectUserInfo = state => state.user.userInfo || {}

const selectUserId = state => state.user.userInfo.id

export const selectTeeups = state => state.teeups

export const selectIsTeeupsPaginationLoading = createSelector(
  selectTeeups,
  teeupState => teeupState.isPaginationLoading
)

export const selectTeeupsPaginationError = createSelector(
  selectTeeups,
  teeupState => teeupState.paginationError
)

export const selectIsTeeupsPagination = createSelector(
  selectTeeups,
  teeupState => teeupState.isPagination
)

export const selectTeeupsPaginationParams = createSelector(
  selectTeeups,
  teeupState => teeupState.paginationParams
)

export const selectTeeupsUsersInfo = createSelector(
  selectTeeups,
  teeupState => teeupState.teeups || {}
)
export const selectTeeupsState = createSelector(
  selectTeeups,
  selectTeeupsStateTeeups,
  (teeup, teeupsState) => {
    if (!teeup.teeups) return []
    return teeup.teeups.map(teeup => {
      if (teeup && teeupsState[teeup.id]) {
        return { ...teeup, ...teeupsState[teeup.id] }
      }
      return teeup
    })
  }
)

export const selectActiveTeeups = createSelector(
  selectTeeups,
  teeups => teeups.teeups || []
)

export const selectArchivedTeeups = createSelector(
  selectTeeups,
  teeups => teeups.archivedTeeups || []
)

export const selectRemovedTeeups = createSelector(
  selectTeeups,
  teeups => teeups.removedTeeups || []
)

export const selectTeeupsGameplans = createSelector(
  selectTeeups,
  teeups => teeups.teeupGameplans
)

export const selectTeeupsParticipants = createSelector(
  selectTeeups,
  teeups => teeups.teeupParticipants || {}
)

export const selectTeeupsPeople = createSelector(
  selectTeeups,
  teeups => teeups.teeupPeople
)

export const selectTeeupsSuggestionMessages = createSelector(
  selectTeeups,
  teeup => teeup.teeupsSuggestionMessages
)

export const selectTeeupsRecommendationsIds = createSelector(
  selectTeeups,
  teeup => teeup.teeupRecommendationsIds || {}
)

export const selectTeeupsRecommendations = createSelector(
  selectTeeups,
  teeup => teeup.teeupRecommendations || {}
)

export const selectAnyTeeupById = teeupId =>
  createSelector(
    selectActiveTeeups,
    selectArchivedTeeups,
    selectRemovedTeeups,
    (teeups, archivedTeeups, selectRemovedTeeups) => {
      return [...teeups, ...archivedTeeups, ...selectRemovedTeeups].find(
        singleTeeup => singleTeeup.id === teeupId
      )
    }
  )

export const selectAllTeeups = createSelector(
  selectActiveTeeups,
  selectArchivedTeeups,
  selectRemovedTeeups,
  (teeups, archivedTeeups, removedTeeups) => {
    return [...teeups, ...archivedTeeups, ...removedTeeups]
  }
)

export const selectTeeupPeopleByTeeupId = id =>
  createSelector(selectTeeupsPeople, teeupsPeople => teeupsPeople[id] || {})

export const selectTeeupPeople = createSelector(
  selectTeeupsPeople,
  selectActiveTeeupId,
  (teeupsPeople, activeTeeupId) => teeupsPeople[activeTeeupId] || {}
)

export const selectUserStatus = createSelector(
  selectTeeupPeople,
  selectUserId,
  (teeupPeople, userId) => teeupPeople[userId]
)

export const selectTeeupParticipant = id =>
  createSelector(selectTeeupPeople, people => people[id])

export const selectTeeupsReactions = createSelector(
  selectTeeups,
  teeup => teeup.teeupReactions || {}
)

export const selectTeeupReactions = createSelector(
  selectTeeupsReactions,
  selectActiveTeeupId,
  (teeupsReactions, activeTeeupId) => teeupsReactions[activeTeeupId] || []
)

export const selectTeeupsSuggestions = createSelector(
  selectTeeups,
  teeup => teeup.teeupSuggestions || {}
)

export const selectTeeupSuggestions = createSelector(
  selectTeeupsSuggestions,
  selectActiveTeeupId,
  (teeupsSuggestions, activeTeeupId) => teeupsSuggestions[activeTeeupId] || {}
)

export const selectAdditionalPeopleInTeeup = createSelector(
  selectTeeupPeople,
  teeupPeople => {
    const formatted = _.filter(
      teeupPeople,
      i => i.additionalCount && i.status !== teeupUserStatusKeys.notgoing
    ).map(user => user.additionalCount)
    return _.reduce(formatted, (sum, current) => sum + current) || 0
  }
)

export const selectTeeupParticipants = createSelector(
  selectTeeupsParticipants,
  selectActiveTeeupId,
  (teeupsParticipants, activeTeeupId) => teeupsParticipants[activeTeeupId] || []
)

export const selectTeeupReactionsBySuggestionId = id =>
  createSelector(
    selectTeeupReactions,
    teeupsreactions => teeupsreactions[id] || []
  )

export const selectTeeupReactionsByUserId = (
  id,
  suggestionsWhen,
  suggestionsWhere
) =>
  createSelector(selectTeeupReactions, teeupReactions => {
    const allSuggestions = [...suggestionsWhen, ...suggestionsWhere]

    const userReactions = Object.entries(teeupReactions).reduce(
      (acc, current) => {
        const [key, value] = current
        const reactions = value.filter(reaction =>
          reaction.reactedBy.includes(id)
        )

        if (reactions.length > 0) {
          acc.push({ id: +key, ...reactions?.[0] })
        }
        return acc
      },
      []
    )

    const suggestionsByReaction = allSuggestions.reduce(
      (acc, current) => {
        const reaction = userReactions.find(item => item.id === current?.id)

        if (!reaction) return acc

        if (reaction.reactionId === 2) {
          acc.liked.push(current)
        }
        if (reaction.reactionId === 3) {
          acc.disliked.push(current)
        }

        return acc
      },
      { liked: [], disliked: [] }
    )
    return suggestionsByReaction
  })

export const selectDecidedGameplanByType = type =>
  createSelector(
    selectTeeupSuggestions,
    teeupSuggestions =>
      Object.values(teeupSuggestions).find(
        suggestion => suggestion.type === type && suggestion.decided
      ) || {}
  )

export const selectChosenGameplanByType = type =>
  createSelector(
    selectTeeupSuggestions,
    teeupSuggestions =>
      Object.values(teeupSuggestions).find(
        suggestion => suggestion.type === type && suggestion.selected
      ) || {}
  )
export const selectDecidedGameplans = createSelector(
  selectTeeupSuggestions,
  teeupSuggestions => {
    let decidedGameplans = {}
    Object.values(teeupSuggestions).forEach(suggestion => {
      if (suggestion.decided) {
        decidedGameplans[suggestion.type] = suggestion
      }
    })
    return decidedGameplans
  }
)

export const selectPermissions = createSelector(
  selectActiveTeeupState,
  selectUserInfo,
  (teeup, userInfo) => {
    const defaultSettings = {
      allowInviteOthers: false,
      allowSelectGameplan: false,
      allowDecideGameplan: false,
      allowChangeState: false,
      allowChangeGameplanOptions: false,
    }
    const { organisers = [], settings = defaultSettings } = teeup
    const { id: userId } = userInfo
    const isOrganiser =
      teeup.createdby === userId ||
      _.some(organisers, ({ id }) => id === userId)
    if (isOrganiser) {
      return {
        allowInviteOthers: true,
        allowSelectGameplan: true,
        allowDecideGameplan: true,
        allowChangeState: true,
        allowChangeGameplanOptions: true,
      }
    }

    return settings
  }
)

export const selectSuggestionMessages = createSelector(
  selectTeeupsSuggestionMessages,
  selectActiveTeeupId,
  (suggestionsMessages, activeTeeupId) =>
    suggestionsMessages[activeTeeupId] || {}
)

export const selectTeeupGameplans = createSelector(
  selectTeeupsGameplans,
  selectActiveTeeupId,
  (teeupsGameplans, activeTeeupId) => teeupsGameplans[activeTeeupId] || []
)

export const selectTeeupRecommendations = createSelector(
  selectTeeupsRecommendations,
  selectActiveTeeupId,
  (recomendations, activeTeeupId) => recomendations[activeTeeupId] || {}
)

export const selectTeeupRecommendationsIds = createSelector(
  selectTeeupsRecommendationsIds,
  selectActiveTeeupId,
  (recomendationsIds, activeTeeupId) => recomendationsIds[activeTeeupId] || []
)

export const selectTeeupGoingPeopleCount = createSelector(
  selectTeeupPeople,
  teeupPeople => {
    const formatted = _.filter(
      teeupPeople,
      i => i.status === teeupUserStatusKeys.going
    ).map(user => 1 + user.additionalCount)
    return _.reduce(formatted, (sum, current) => sum + current) || 0
  }
)

export const selectTeeupGoingPeopleIds = createSelector(
  selectTeeupPeople,
  teeupPeople =>
    Object.keys(teeupPeople).filter(
      userId => teeupPeople[userId].status === teeupUserStatusKeys.going
    )
)

export const selectTeeupMightGoPeopleCount = createSelector(
  selectTeeupPeople,
  teeupPeople => {
    const formatted = _.filter(
      teeupPeople,
      i => i.status === teeupUserStatusKeys.mightgo
    ).map(user => 1 + user.additionalCount)
    return _.reduce(formatted, (sum, current) => sum + current) || 0
  }
)

export const selectTeeupMightgoPeopleIds = createSelector(
  selectTeeupPeople,
  teeupPeople =>
    Object.keys(teeupPeople).filter(
      userId => teeupPeople[userId].status === teeupUserStatusKeys.mightgo
    )
)

export const selectTeeupActivePeople = createSelector(
  selectTeeupPeople,
  selectTeeupParticipants,
  (teeupsPeople, participantsArray) => {
    const people = {}
    participantsArray.forEach(participant => {
      if (
        teeupsPeople[participant] &&
        teeupsPeople[participant].status !== teeupUserStatusKeys.invited &&
        teeupsPeople[participant].status !== teeupUserStatusKeys.droppedout
      ) {
        people[participant] = teeupsPeople[participant]
      }
    })
    return people
  }
)
export const selectIsTeeupListVisible = createSelector(
  selectTeeups,
  ({ isTeeupListVisible }) => isTeeupListVisible
)

export const selectTeeupsByUserId = userId =>
  createSelector(
    selectTeeups,
    selectTeeupsStateTeeups,
    selectTeeupsPeople,
    (teeup, teeupsState, teeupPeople) => {
      if (!teeup.teeups) return []
      const filteredTeeupsByUserId = teeup.teeups.filter(
        teeup => teeupPeople[teeup.id] && teeupPeople[teeup.id][userId]
      )
      return filteredTeeupsByUserId.map(teeup => {
        if (teeup && teeupsState[teeup.id]) {
          return { ...teeup, ...teeupsState[teeup.id] }
        }
        return teeup
      })
    }
  )

export const selectTeeupUserById = id =>
  createSelector(selectTeeupPeople, people => people[id] || {})

export const selectTeeupFiltering = createSelector(
  selectTeeups,
  teeup => teeup.teeupFiltering || initialFiltersValues
)

export const selectUnseenTeeups = createSelector(selectTeeupsState, teeups =>
  _.filter(teeups, teeup => {
    const { seen, newTitle, newWhen, newWhere, newMessages } = teeup
    const hasUpdate = seen === false
    const isNewTitle = hasUpdate && !!newTitle
    const isNewWhen = hasUpdate && !!newWhen
    const isNewWhere = hasUpdate && !!newWhere
    return (
      isNewTitle || isNewWhen || isNewWhere || (hasUpdate && newMessages > 0)
    )
  })
)
