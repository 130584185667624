import React, { useContext } from "react"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import PropTypes from "prop-types"

import "./phoneInputStyles.scss"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"

export const PhoneSearch = ({ onChange }) => {
  const { searchValue } = useContext(InvitePeopleContext)

  return (
    <PhoneInput
      country="us"
      containerClass="invite-phone-search"
      buttonClass="invite-phone-search__button"
      value={searchValue}
      onChange={(phone, country) => {
        onChange(phone)
      }}
    />
  )
}

PhoneSearch.propTypes = {
  onChange: PropTypes.func,
}
