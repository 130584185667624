import React from "react"

import "./suggestModalContent.scss"
import "./index.scss"

const SuggestModalContent = ({ type = "where", children, className = "" }) => {
  return (
    <div
      className={`${
        type === "where"
          ? "suggest-modal__content suggest-modal__content-where"
          : "suggest-modal__content-when"
      } ${className}`}>
      {children}
    </div>
  )
}

export default SuggestModalContent
