import { loginProviders } from "@config/enums"
import strings from "@i18n"
import { images } from "@utils/imageUtils"

export const getIdentityTitleByProvider = (identities, provider) => {
  const identityExists = identities?.find(
    identity => identity.provider === provider
  )
  if (!identityExists) return strings.loginDetails.accNotLinked
  return identityExists.title ?? "@null"
}

export const getSignInMethodTitle = loginType => {
  switch (loginType) {
    case loginProviders.fb: {
      return "Facebook"
    }
    case loginProviders.google: {
      return "Google"
    }
    case loginProviders.twitter: {
      return "Twitter"
    }
    case loginProviders.email: {
      return "Email"
    }
    case loginProviders.apple: {
      return "Apple"
    }
    default: {
      return "Email"
    }
  }
}
export const getLoginMethodTitle = loginType => {
  switch (loginType) {
    case loginProviders.fb: {
      return "Facebook"
    }
    case loginProviders.google: {
      return "Google"
    }
    case loginProviders.twitter: {
      return "Twitter"
    }
    case loginProviders.apple: {
      return "Apple"
    }
  }
}

export const getLoginMethodIcon = loginType => {
  switch (loginType) {
    case loginProviders.fb: {
      return images.facebookSettings
    }
    case loginProviders.google: {
      return images.googleSettings
    }
    case loginProviders.twitter: {
      return images.twitterSettings
    }
    case loginProviders.apple: {
      return images.appleSettings
    }
  }
}
