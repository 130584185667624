import { useEffect, useState } from "react"

import { loginProviders, loginProvidersText } from "@config/enums"

export const useLinkingSuccessMessage = ({
  linkingSuccessType,
  googlePrimaryCalendar,
  outlookPrimaryCalendar,
}) => {
  const [linkingSuccessMessage, setLinkingSuccessMessage] = useState("")

  useEffect(() => {
    const primaryCalendar =
      linkingSuccessType === loginProviders.windowslive
        ? outlookPrimaryCalendar
        : googlePrimaryCalendar

    if (linkingSuccessType && primaryCalendar) {
      const message = `
        <b>${loginProvidersText[linkingSuccessType]}</b> calendar <b>${primaryCalendar?.summary}</b> 
        connected successfully. You can now set up your newly linked calendars.
      `

      setLinkingSuccessMessage(message)
    }
  }, [linkingSuccessType, googlePrimaryCalendar, outlookPrimaryCalendar])

  return { linkingSuccessMessage, setLinkingSuccessMessage }
}
