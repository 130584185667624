import Dates from "./Dates"
import TimeWheel from "./TimeWheel"
import GroupInfo from "./TimeWheel/GroupInfo"
import YourSuggestionsContainer from "./YourSuggestionContainer"
import "./index.scss"

const TimeWheelTab = ({ onSave, isFromCreateTeeUp }) => {
  return (
    <div className="time-wheel-tab">
      <Dates />
      <GroupInfo isFromCreateTeeUp={isFromCreateTeeUp} />
      <TimeWheel isFromCreateTeeUp={isFromCreateTeeUp} />
      <YourSuggestionsContainer
        onSave={onSave}
        isFromCreateTeeUp={isFromCreateTeeUp}
      />
    </div>
  )
}

export default TimeWheelTab
