import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.responsiveValue(16),
  },
  chatItempart: {
    transform: [{ rotateX: "180deg" }],
    width: "fit-content",
  },
  emptyComponent: {
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
    marginTop: Units.responsiveValue(4),
    height: "80%",
  },
  placeholderImage: {
    width: Units.rem(10),
    height: Units.rem(10),
  },
  placeholderTitle: {
    color: AppColors.brand.darkGrey3,
    fontSize: Units.responsiveValue(24),
    fontFamily: AppFonts.family.medium,
  },
  placeholderText: {
    ...AppFonts.bigMedium,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.responsiveValue(7),
    paddingHorizontal: Units.responsiveValue(33),
    textAlign: "center",
    letterSpacing: -Units.responsiveValue(0.78),
  },
  listPadding: {
    paddingBottom: 50,
  },
})
