import React from "react"

import { NewModal } from "@components"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { ModalTitle, ModalDescription } from "./manageModalStyles"

export const ManageModal = ({ actionsList, onClose }) => (
  <NewModal isOpen isBottom isCloseBtn isHeader={false} onClose={onClose}>
    <ModalTitle>Manage Participant</ModalTitle>

    <ModalDescription>Select an option below</ModalDescription>

    {actionsList.map(
      (item, index) =>
        !item.isHidden && (
          <ButtonEl
            fullWidth
            isBlack
            text={item.text}
            variant={
              index === 0
                ? BUTTON_VARIANTS.ROUNDED
                : BUTTON_VARIANTS.TRANSPARENT
            }
            onClick={() => {
              item.action()
              onClose()
            }}
            key={`btn-${index}`}
          />
        )
    )}
  </NewModal>
)
