import React from "react"

import ArrowIcon from "../../../assets/images/white-arrow.svg"
import { SubtitleL } from "../../../assets/styles/typographyStyles"
import { BackLink, BackLinkIcon } from "../teeupPreviewStyles"

export const Back = () => (
  <BackLink to="/">
    <BackLinkIcon src={ArrowIcon} alt="back" />

    <SubtitleL data-testid="backButton">Back</SubtitleL>
  </BackLink>
)
