import { useEffect, useState } from "react"

import { useHistory, useParams } from "react-router-dom"

import endpoints from "@config/endpoints"
import {
  teeupUserStatusPriorities,
  teeupUserStatusMessage,
} from "@config/enums"
import { getSettingStatusEndpoint } from "@utils/guestHelpers"
import { userStatusTypes } from "@utils/mappings"

import api from "../../../api"

export const useGuestStatus = ({
  isRegisteredUser,
  teeupTitle,
  isSharedLink,
  previewInfo,
  setStatusModalTrigger,
  updatePreviewInfo,
  setIsStatusConfirmedModal,
  setConfirmingStatus,
}) => {
  const urlSearch = new URLSearchParams(window.location.search)
  const isStatusParam = urlSearch.has("status")
  const history = useHistory()
  const { slug } = useParams()

  const guestStatus = previewInfo?.invitee.status
  const [guestStatusMessage, setGuestStatusMessage] = useState("")
  const [isRSVPStatusBadge, setIsRSVPStatusBadge] = useState(false)
  const [RSVPWarningBadgeVal, setRSVPWarningBadgeVal] = useState(null)

  const getReadyStatus = () => {
    const status = Object.keys(teeupUserStatusPriorities).find(
      key => teeupUserStatusPriorities[key] === guestStatus
    )
    setIsRSVPStatusBadge(true)
    setGuestStatusMessage(teeupUserStatusMessage[status])
  }

  const removeUrlParam = param => {
    urlSearch.delete(param)
    history.replace({
      search: urlSearch.toString(),
    })
  }

  const setGuestStatus = async data => {
    const { status, fullName, timezone, email } = data
    const isValidStatus = Object.keys(teeupUserStatusPriorities).includes(
      status
    )

    if (isValidStatus && slug) {
      const setStatusEndpoint = getSettingStatusEndpoint({
        isSharedLink,
        status,
        slug,
      })

      try {
        await api.client.post(setStatusEndpoint, {
          timezone,
          username: fullName,
          email,
        })
        setIsRSVPStatusBadge(true)
        updatePreviewInfo(data)
        setGuestStatusMessage(teeupUserStatusMessage[status])
      } catch {
        return {
          isErrors: true,
          data: {
            email: "This email is used in CooWe system",
          },
        }
      }
    }

    removeUrlParam("status")

    return { data }
  }

  const confirmStatusHandle = async () => {
    const confirmStatus = urlSearch.get("confirmStatus")
    const confirmStatusEndpoint = endpoints.teeup.confirmStatus(slug)

    if (confirmStatus && previewInfo) {
      const { name, timezone } = previewInfo.invitee

      removeUrlParam("confirmStatus")
      await setGuestStatus({
        status: confirmStatus,
        fullName: name,
        timezone,
      })
      await api.client.post(confirmStatusEndpoint)
      setConfirmingStatus(userStatusTypes[confirmStatus])
      if (confirmStatus != teeupUserStatusPriorities.invited) {
        setIsStatusConfirmedModal(true)
      }
    }
  }

  useEffect(() => {
    confirmStatusHandle()
  }, [previewInfo])

  const catchGuestStatus = () => {
    const urlGuestStatus = urlSearch.get("status")
    const isValidStatus = Object.keys(teeupUserStatusPriorities).includes(
      urlGuestStatus
    )

    if (urlGuestStatus && isValidStatus && slug) {
      setStatusModalTrigger(urlGuestStatus)
    }
  }

  useEffect(() => {
    if (!isRegisteredUser) {
      if (guestStatus && guestStatus !== teeupUserStatusPriorities.invited) {
        getReadyStatus()
      }

      catchGuestStatus()
    }
  }, [isRegisteredUser, guestStatus])

  const catchRSVPWarning = () => {
    const planChangedVal = urlSearch.get("planChanged")
    const titleChangedVal = urlSearch.get("titleChanged")

    if (planChangedVal === "1") {
      setRSVPWarningBadgeVal("<b>Game plan changed!</b>")
    }

    if (titleChangedVal === "1" && teeupTitle) {
      setRSVPWarningBadgeVal(
        `<b>Title has changed!</b> Now it's <b>${teeupTitle}</b>`
      )
    }
  }

  useEffect(() => {
    catchRSVPWarning()
  }, [teeupTitle])

  return {
    isStatusParam,
    isRSVPStatusBadge,
    guestStatusMessage,
    RSVPWarningBadgeVal,
    setGuestStatus,
  }
}
