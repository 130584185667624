import React, { useEffect, useState, useRef } from "react"

import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import {
  setReplyMessage,
  setPeopleSideBar,
  setActiveTab,
  readTeeup,
} from "@actions/commonActions"
import { messageTypeIds } from "@config/mappings"
import { selectActiveTeeup, selectActiveTeeupId } from "@selectors/activeTeeup"
import {
  selectFromCommentsInput,
  selectReplyMessage,
  selectActiveTab,
  selectModalPeopleNudge,
} from "@selectors/common"
import { selectTeeupPeopleByTeeupId } from "@selectors/teeups"
import { selectUserInfo, selectUserId } from "@selectors/user"
import InformationTooltip from "@ui/informationTooltip"
import { getMentionedUsersIds } from "@utils/teeupUtils"

import { sendMessage } from "../../../middlewares/backendMiddleware"
import MessageTooltip from "../Chat/MessageTooltip/index"
import DiscussionCard from "../Chat/discussionCard/discussionCardContainer"
import SelectPeopleToNudge from "../Chat/selectPeopleToNudge/index"
import ConversationBottom from "../ConversationBottom"

import ReplyOverview from "./ReplyOverview"

import { differenceBy } from "lodash"
import classNames from "classnames"

const OverviewConversation = ({
  replyMessage,
  setReplyMessage,
  userInfo,
  people,
  activeTab,
  setPeopleSideBar,
  getUserId,
  modalPeopleNudge,
  setActiveTab,
  handleOpenSuggestion,
  fromSuggestionContainer,
  fromCommentsInput,
  teeup,
  isWhereSuggestion,
  newDesign,
}) => {
  const [invitees, setInvitees] = useState([])
  const inputRef = useRef()

  useEffect(() => {
    if (teeup.id) {
      readTeeup(teeup.id)
    }
  }, [teeup.id])

  const handleToggleContact =
    ({ data }) =>
    () => {
      setInvitees(invitees => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length
        return isInvited ? diffInvitees : [...invitees, { ...data }]
      })
    }

  const createMessage = text => {
    const message = text.replaceAll(/  +/g, " ").trim()
    if (message.length === 0) return

    const mentionedUserIds = getMentionedUsersIds(message, people, userInfo?.id)
    if (replyMessage) {
      sendMessage(message, messageTypeIds.message, replyMessage, [])
      setReplyMessage(null)
    } else {
      sendMessage(message, messageTypeIds.message, null, mentionedUserIds)
    }
  }

  return (
    <>
      {modalPeopleNudge &&
        ReactDOM.createPortal(
          <SelectPeopleToNudge
            invitees={invitees}
            setInvitees={setInvitees}
            people={Object.values(people).filter(
              person => person.id !== getUserId
            )}
            handleToggleContact={handleToggleContact}
          />,
          document.querySelector("#modal-root")
        )}
      <div
        className={classNames("conversation", { "nd-conversation": newDesign })}
        style={
          fromSuggestionContainer && {
            minWidth:
              isWhereSuggestion && document.documentElement.clientWidth <= 1450
                ? "350px"
                : isWhereSuggestion &&
                  document.documentElement.clientWidth <= 1505
                ? "420px"
                : "470px",
            maxWidth: "500px",
          }
        }>
        <DiscussionCard index={activeTab} newDesign={newDesign} />
        <InformationTooltip />
        <MessageTooltip inputRef={inputRef} />
        {replyMessage && !fromCommentsInput && <ReplyOverview />}
        {activeTab === 0 && (
          <ConversationBottom
            createMessage={createMessage}
            setPeopleSideBar={setPeopleSideBar}
            inputRef={inputRef}
            handleOpenSuggestion={handleOpenSuggestion}
            newDesign={newDesign}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    replyMessage: selectReplyMessage(state),
    userInfo: selectUserInfo(state),
    people: selectTeeupPeopleByTeeupId(selectActiveTeeupId(state))(state),
    activeTab: selectActiveTab(state),
    getUserId: selectUserId(state),
    modalPeopleNudge: selectModalPeopleNudge(state),
    fromCommentsInput: selectFromCommentsInput(state),
    teeup: selectActiveTeeup(state),
  }
}

const mapDispatchToProps = dispatch => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
  setPeopleSideBar: bindActionCreators(setPeopleSideBar, dispatch),
  setActiveTab: bindActionCreators(setActiveTab, dispatch),
  readTeeup: bindActionCreators(readTeeup, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewConversation)
