import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: var(--dashed);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
`

export const Text = styled(NSubtitleS)`
  color: var(--gray);
  font-weight: ${NFontWeight.semiBold};
`
