import endpoints from "@config/endpoints"
import { KIBANA_LOGS } from "@constants/kibanaLogs"

import api from "../api"

export const logCalendarReading = ({ calendarName, ownerEmail }) => {
  api.client.post(endpoints.log, {
    eventType: KIBANA_LOGS.EXTERNAL_CALENDAR_READING,
    data: `Reading of ${calendarName} [${ownerEmail}] external calendar.`,
  })
}
