import styled from "styled-components"

import { NFontWeight, NTitle2 } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  width: 60%;
  height: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const Arrow = styled(SvgIcon)`
  transform: rotate(90deg);
`

export const TeeupName = styled(NTitle2)`
  max-width: 715px;
  color: var(--white);
  font-weight: ${NFontWeight.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
