import React from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, Image } from "react-native"
// import Icon from 'react-native-vector-icons/FontAwesome5'

import { RecommendationStyles } from "./gameplansStyles"

const RecommendedItem = ({ item, onSelectRecommendation, numberOfPeople }) => {
  const enoughPeople = item.minPeople <= numberOfPeople

  return (
    <TouchableOpacity
      onPress={() => onSelectRecommendation && onSelectRecommendation(item)}
      style={RecommendationStyles.container}>
      <View style={RecommendationStyles.imageContainer}>
        <Image
          resizeMode={"cover"}
          style={RecommendationStyles.image}
          source={{
            uri: "https://www.sweetestmenu.com/wp-content/uploads/2018/08/oreoicecream8.jpg",
          }}
        />
      </View>
      <View style={RecommendationStyles.contentContainer}>
        <View>
          <Text style={RecommendationStyles.headerText}>{item.header}</Text>
          <Text style={RecommendationStyles.contentText}>
            {item.contentText}
          </Text>
        </View>
        <View style={RecommendationStyles.bottomContainer}>
          <View style={RecommendationStyles.flexRow}>
            <Text
              style={[
                RecommendationStyles.peopleText,
                enoughPeople && RecommendationStyles.peopleTextActive,
              ]}>
              MIN {item.minPeople} PEOPLE
            </Text>
            {/* {enoughPeople && (
                            <Icon
                                name="check-circle"
                                size={12}
                                color={AppColors.brand.green}
                                solid
                            />
                        )} */}
          </View>
          <View style={RecommendationStyles.flexRow}>
            {/* <Icon
                            name="map-marker-alt"
                            size={10}
                            color={AppColors.brand.warmGrey2}
                        /> */}
            <Text style={RecommendationStyles.locationText}>2,3 mil</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default RecommendedItem

RecommendedItem.propTypes = {
  onSelectRecommendation: PropTypes.func,
  item: PropTypes.object,
  numberOfPeople: PropTypes.number,
}
