import styled from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`

export const Title = styled(NSubtitle)`
  display: block;
  font-weight: ${NFontWeight.bold};
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const OrganiserName = styled(NSubtitleS)`
  color: var(--gray5);
  font-weight: ${NFontWeight.bold};
`
