import { SvgIcon } from "@components/common"
import { FilterOption } from "types/inbox"

interface Props {
  option: FilterOption
  onRemove: (option: FilterOption) => void
}

const Option = ({ option, onRemove }: Props) => {
  return (
    <div className="flex h-7 animate-fade select-none items-center justify-center gap-1 rounded border border-neutral-200 bg-white px-1.5 py-1">
      <div className="text-center font-['Inter'] text-xs font-medium leading-tight text-neutral-700">
        {option.title}
      </div>
      <div className="flex size-3 items-center justify-center">
        <SvgIcon
          name="cross-nd"
          className="!size-[9px] cursor-pointer"
          onClick={() => onRemove(option)}
        />
      </div>
    </div>
  )
}

export default Option
