import styled from "styled-components"

export const Wrapper = styled.div`
  width: 94%;
  background-color: var(--white);
  border: 1px solid var(--gray3);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: var(--shadowBox1);
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 10;
`

export const List = styled.ul`
  max-height: 213px;
  list-style: none;
  overflow-y: auto;
`

export const ButtonsWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
`
