import { useMemo } from "react"

import { useSelector } from "react-redux"

import TimezoneItem from "@components/TeeupPeople/Content/Timezone/TimezoneItem"
import { selectSelectedContacts } from "@selectors/invitees"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import { groupByTimezone } from "@utils/peopleUtils"
import {
  HOURS_PER_DAY,
  HOUR_IN_MINUTES,
  getBoxInfo,
} from "@utils/suggestionUtils"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import { Participant } from "types/participant"

import "./index.scss"

interface Props {
  isFromCreateTeeUp?: boolean
}

const GroupInfo = ({ isFromCreateTeeUp }: Props) => {
  const currentDate = useSuggestionTimeWheelStore(state => state.currentDate)
  const people = useSelector(selectTeeupPeople)
  const user = useSelector(selectUserInfo)
  const duration = useSuggestionDurationStore(state => state.duration)
  const isAllDay = +duration.id / HOUR_IN_MINUTES === HOURS_PER_DAY
  const selectedContacts = useSelector(selectSelectedContacts)

  const peopleByTimezone = useMemo(() => {
    const peopleArr: Participant[] = Object.values(people)
    const peopleContainer: Participant[] = isFromCreateTeeUp
      ? [...selectedContacts]
      : [...peopleArr]

    if (isFromCreateTeeUp) peopleContainer.unshift(user)

    return Object.values(groupByTimezone(peopleContainer))
  }, [people, selectedContacts])

  if (!peopleByTimezone) return false

  return (
    <div className="group-info-container">
      <div className="group-info-container-2">
        {peopleByTimezone.map((timezone, index) => (
          <div className="group-info" key={index}>
            <TimezoneItem
              isBoxesHidden
              key={index}
              peopleByTimezone={timezone}
              user={user}
              currentDate={currentDate}
              getBoxInfo={getBoxInfo}
              isAllDay={isAllDay}
              duration={duration}
            />
            <div className="clean" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default GroupInfo
