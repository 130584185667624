import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { FontWeight } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LogoImg = styled.img`
  width: 150px;
  heigth: 29px;
  margin: 32px auto;
`

export const Content = styled.section`
  width: 100%;
  box-shadow: var(--shadowBox1);
  ${({ isEmail }) =>
    !isEmail &&
    css`
      background-color: var(--white);
      border: 1px solid var(--lightGray5);
      border-radius: 8px;
      padding: 44px;
      box-sizing: border-box;
    `};

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    height: calc(100vh - 97px);
    border-radius: 0px;
    padding: 24px 16px;
  }
`

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 32px;
  font-weight: ${FontWeight.bold};
  font-size: 32px;
  ${({ isEmail }) =>
    isEmail &&
    css`
      background-color: var(--lightGray4);
      border-top: 1px solid var(--lightGray5);
      border-right: 1px solid var(--lightGray5);
      border-left: 1px solid var(--lightGray5);
      border-radius: 8px 8px 0 0;
      padding-top: 20px;
      padding-bottom: 32px;
      margin-bottom: 0;
    `};

  ${({ statusIcon }) =>
    statusIcon &&
    css`
      margin-top: 24px;
    `};

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    text-align: start;
    font-size: 28px;
  }
`

export const Icon = styled.img`
  width: 56px;
  heigth: 56px;
  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `};
`
