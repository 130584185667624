import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { FontWeight, Subtitle } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;

  ${({ $isPreview }) =>
    $isPreview &&
    css`
      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        position: absolute;
        // 90px is header hide on mobile responsive
        top: 90px;
        left: 0;
        z-index: 3;
      }
    `};
`

export const BadgeLeft = styled.div`
  display: flex;
  align-items: center;
`

export const LogoWrapper = styled.div`
  width: 54px;
  height: 54px;
  background-color: #f42862;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DescriptionTitle = styled(Subtitle)`
  width: 205px;
  font-weight: ${FontWeight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
