import React, { useState } from "react"

import noop from "lodash/noop"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import ActionButtonsView from "./ActionButtonsView"

const ActionButtonsBar = ({
  isInvited,
  isDroppedOut,
  isSkippedDeclined,
  isArchivePromptShow,
  isOneDayAfterEnded,
  isCancelled,
  isArchived,
  isKickedOut,
  onIgnorePress,
  onRespondPress,
  hasMultipleNudges,
  onViewMentionsPress,
  onViewNudgeResponse,
  isNudgeResponseReceived,
  onDeclineInvitation,
  nudgeInfo,
  isMentioned,
  onKeepInTheListPress,
  onArchivePressed,
  isFromOverviewPage,
  onAcceptInvitation,
  onMoveToActivePress,
  teeupId,
}) => {
  const history = useHistory()
  const [alreadyAnswered, setIsAlreadyAnswered] = useState(false)

  const handleDecline = e => {
    e.preventDefault()
    onDeclineInvitation()
    setIsAlreadyAnswered(true)
  }
  const handleAccept = e => {
    e.preventDefault()
    onAcceptInvitation(true)
    setIsAlreadyAnswered(true)
    onMoveToActivePress()
    history.push(`/teeUps/${teeupId}`)
  }

  const handleArchivePressed = e => {
    e.preventDefault()
    onArchivePressed()
    setIsAlreadyAnswered(true)
  }

  const handleKeepInTheListPress = e => {
    e.preventDefault()
    onKeepInTheListPress()
    setIsAlreadyAnswered(true)
  }

  const handleIgnore = e => {
    e.preventDefault()
    onIgnorePress()
  }

  const handleNudgeRespond = e => {
    e.preventDefault()
    onRespondPress()
  }
  const handleViewMentions = e => {
    e.preventDefault()
    onViewMentionsPress()
  }

  const viewNudgeResponse = e => {
    e.preventDefault()
    onViewNudgeResponse()
  }

  if (
    !isInvited &&
    !isDroppedOut &&
    !isSkippedDeclined &&
    !isArchivePromptShow &&
    !isCancelled &&
    !isKickedOut &&
    // !isOneDayAfterEnded
    !nudgeInfo &&
    !isMentioned
    // !isUndecidedWhenInGameplan
  )
    return null

  if (isMentioned) {
    return (
      <ActionButtonsView
        mainButtonText={"View"}
        onMainButtonPress={alreadyAnswered ? noop : handleViewMentions}
        secondaryButtonText={"Ignore"}
        onSecondaryButtonPress={alreadyAnswered ? noop : handleIgnore}
        isFromOverviewPage={isFromOverviewPage}
      />
    )
  }
  if (isInvited && !isArchived) {
    return (
      <ActionButtonsView
        alreadyAnswered={alreadyAnswered}
        onSecondaryButtonPress={alreadyAnswered ? noop : handleDecline}
        secondaryButtonText={"Decline and Archive"}
        onMainButtonPress={alreadyAnswered ? noop : handleAccept}
        mainButtonText={!isCancelled && "Join"}
        isFromOverviewPage={isFromOverviewPage}
      />
    )
  }

  if (
    (isOneDayAfterEnded || isCancelled || isKickedOut) &&
    !isInvited &&
    isArchivePromptShow
  ) {
    return (
      <ActionButtonsView
        isGreyOverlay={isDroppedOut || isSkippedDeclined}
        alreadyAnswered={alreadyAnswered}
        onSecondaryButtonPress={handleKeepInTheListPress}
        secondaryButtonText={"Keep in the list"}
        onMainButtonPress={alreadyAnswered ? noop : handleArchivePressed}
        noMargin={true}
        mainButtonText={"Archive"}
        isFromOverviewPage={isFromOverviewPage}
      />
    )
  }

  if (isInvited && isArchived) {
    return (
      <ActionButtonsView
        alreadyAnswered={alreadyAnswered}
        onMainButtonPress={alreadyAnswered ? noop : handleAccept}
        mainButtonText={"Join"}
      />
    )
  }

  if (isNudgeResponseReceived && !hasMultipleNudges) {
    return (
      <ActionButtonsView
        mainButtonText={"View"}
        onMainButtonPress={alreadyAnswered ? noop : viewNudgeResponse}
        secondaryButtonText={"Ignore"}
        onSecondaryButtonPress={alreadyAnswered ? noop : handleIgnore}
      />
    )
  }

  return (
    <ActionButtonsView
      mainButtonText={hasMultipleNudges ? "View All" : "Respond"}
      onMainButtonPress={alreadyAnswered ? noop : handleNudgeRespond}
      secondaryButtonText={"Ignore"}
      onSecondaryButtonPress={alreadyAnswered ? noop : handleIgnore}
    />
  )
}
ActionButtonsBar.propTypes = {
  hasMultipleNudges: PropTypes.bool,
  onDeclineInvitation: PropTypes.func.isRequired,
  onKeepInTheListPress: PropTypes.func,
  onIgnoreUndecidedInGameplan: PropTypes.func,
  onViewTeeup: PropTypes.func,
  onAcceptInvitation: PropTypes.func.isRequired,
  onArchivePressed: PropTypes.func,
  onRespondPress: PropTypes.func,
  onViewMentionsPress: PropTypes.func,
  onIgnore: PropTypes.func,
  isArchivePromptShow: PropTypes.bool,
  invitation: PropTypes.object,
  nudgeInfo: PropTypes.object,
  userStatus: PropTypes.string,
  isInvited: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isSkippedDeclined: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
  onViewNudgeResponse: PropTypes.func,
  userId: PropTypes.number,
  onIgnorePress: PropTypes.func,
}

export default ActionButtonsBar
