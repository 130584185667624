import React, { useContext } from "react"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import { setSelectedContact } from "@actions/inviteesActions"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { contactTypes } from "@utils/contactUtils"

import { InviteButton, Text, PlusIcon } from "./inviteNewContactStyles"
import { parsePhoneNumber } from "libphonenumber-js/mobile"
import { useSnackbarStore } from "stores/snackbarStore"

export const InviteNewContact = ({ newInviteContact, setNewInviteContact }) => {
  const dispatch = useDispatch()
  const { setSearchValue } = useContext(InvitePeopleContext)
  const isPhoneInvite = newInviteContact.type === contactTypes.PHONE
  const isPlusSymbol =
    isPhoneInvite && !newInviteContact.matchingContact.includes("+")
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)

  const handleInviteClick = () => {
    try {
      const matchingContact = isPlusSymbol
        ? `+${newInviteContact.matchingContact}`
        : newInviteContact.matchingContact

      if (isPhoneInvite) {
        const phoneNumber = parsePhoneNumber(matchingContact)
        if (!phoneNumber.isValid()) {
          throw new Error("Invalid phone number")
        }
      }

      dispatch(
        setSelectedContact({
          id: uuidv4(),
          matchingContact,
        })
      )
      setSearchValue("")
      setNewInviteContact(null)
    } catch (e) {
      setSnackbarConfig({
        open: true,
        type: "error",
        message: "Invalid phone number",
        duration: 3000,
      })
    }
  }

  return (
    <InviteButton onClick={handleInviteClick} data-testid="invite-button">
      <Text>
        Invite by {newInviteContact.type}:{" "}
        <b>
          "{isPlusSymbol && "+"}
          {newInviteContact.matchingContact}"
        </b>
      </Text>

      <PlusIcon name="circle-plus-2" />
    </InviteButton>
  )
}

InviteNewContact.propTypes = {
  newInviteContact: PropTypes.string,
  setNewInviteContact: PropTypes.func,
}
