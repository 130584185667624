import styled, { css } from "styled-components"

import {
  NFontWeight,
  NSubtitleS,
  NTitle5,
} from "assets/styles/newTypographyStyles"

export const InviterTitle = styled(NSubtitleS)`
  ${({ $isStepper }) => css`
    color: ${$isStepper ? "var(--white)" : "var(--gray5)"};
    font-weight: ${NFontWeight.regular};
  `};
`

export const TeeupTitle = styled(NTitle5)`
  font-weight: ${NFontWeight.bold};
  text-align: center;
  margin-top: 10px;

  ${({ $isStepper }) =>
    $isStepper &&
    css`
      color: var(--white);
      margin-top: 0;
    `};
`
