import styled, { css } from "styled-components"

import { FontWeight, SubtitleS } from "@assets/styles/typographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  background-color: var(--pink);
  border: 1px solid var(--pink2);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  margin: 10px 0;

  ${({ $isSideMargins }) =>
    $isSideMargins &&
    css`
      margin: 10px 12px;
    `};
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled(SvgIcon)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`

export const Text = styled(SubtitleS)`
  color: var(--red2);
  font-weight: ${FontWeight.medium};
`
