import moment from "moment"

import dayjs from "utils/dayjs"

const getTzSymbol = timezone => {
  if (timezone === 0) return ""

  return timezone < 0 ? "-" : "+"
}

export const getSpecialTzCurrentHour = (currentHour, zone, creatorZone) => {
  const tzDate = dayjs(currentHour).tz(zone.timezoneName).format()
  // const creatorTzSymbol = getTzSymbol(creatorZone.timezone)
  // const tzSymbol = getTzSymbol(zone.timezone)
  // const tzSymbolIndex = tzDate.lastIndexOf(tzSymbol)
  return tzDate.replace("Z", "")
  // return (
  //   tzDate.slice(0, tzSymbolIndex) +
  //   creatorTzSymbol +
  //   currentHour?.slice(tzSymbolIndex + 1)
  // )
}
