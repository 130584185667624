import { lazy, object, string } from "yup"

import { EMAIL_VALIDATION_REGEX } from "@constants/global"

export const getSubmitChoicesValidationSchema = isEmailField => {
  return lazy(values => {
    const schemaObject = {
      username: string()
        .min(3, "Minimum 3 characters")
        .max(40, "Maximum 40 characters"),
    }

    if (isEmailField) {
      schemaObject.email = string()
        .matches(EMAIL_VALIDATION_REGEX, "Please enter a valid email address")
        .required("Email is required field")
    }

    return object().shape(schemaObject)
  })
}
