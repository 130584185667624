import React, { useContext } from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { SuggestionsHorizontalList } from "@components/common"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"
import { selectGuestSuggestions } from "@selectors/guest"
import { GAMEPLAN_TYPES } from "constants/gameplan"

import { Wrapper } from "./previewCollapsedSuggestionsStyles"
import { useFilteredGuestSuggestions } from "@hooks"

const PreviewCollapsedSuggestions = ({
  type,
  guestSuggestions,
  isInvitees,
}) => {
  const { gameplanInfo } = useContext(TeeupPreviewContext)
  const filteredSuggestions = useFilteredGuestSuggestions(guestSuggestions)
  const list =
    type === GAMEPLAN_TYPES.when
      ? filteredSuggestions
      : gameplanInfo?.where.suggestions || []

  return (
    <Wrapper $isMarginBottom={list.length}>
      {isInvitees && (
        <div className="mb-4 flex items-center justify-between">
          <div class="font-['Inter'] text-base font-bold text-neutral-800">
            When
          </div>

          <div class="inline-flex items-center justify-center gap-1 rounded border border-blue-200 bg-blue-50 px-1.5 py-0.5">
            <div class="font-['Inter'] text-xs font-semibold leading-tight text-blue-600">
              {list.length} Suggestion{list.length > 1 && "s"}
            </div>
          </div>
        </div>
      )}
      <SuggestionsHorizontalList
        list={list}
        isWhere={type === GAMEPLAN_TYPES.where}
      />
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  guestSuggestions: selectGuestSuggestions(state),
})

export default connect(mapStateToProps)(PreviewCollapsedSuggestions)

PreviewCollapsedSuggestions.propTypes = {
  type: PropTypes.string,
}
