import { useMemo } from "react"

import { teeupStatusKeys } from "@config/enums"
import { getStageOptions } from "@utils/teeupStateUtils"
import { Stage } from "types/teeup"

import Item from "./Item"

import "./index.scss"

interface TimeLineProps {
  currentStage: Stage | null
  changeStatus: (stage: Stage) => void
  isOnlyGeneralWhenSuggestion: boolean
}

const TimeLine = ({
  currentStage,
  changeStatus,
  isOnlyGeneralWhenSuggestion,
}: TimeLineProps) => {
  const stageOptions = useMemo(
    () => getStageOptions().filter(stage => !stage.isHidden),
    []
  )

  return (
    <div className="time-line">
      {stageOptions.map((stage, index) => (
        <Item
          stage={stage}
          key={index}
          isFirstItem={index === 0}
          isLastItem={index === stageOptions.length - 1}
          currentStage={currentStage}
          isHappeningNowDisabled={
            isOnlyGeneralWhenSuggestion &&
            stage.label === teeupStatusKeys.happening
          }
          onClick={() => changeStatus(stage)}
        />
      ))}
    </div>
  )
}

export default TimeLine
