import { useEffect, useState } from "react"

import dayjs from "@utils/dayjs"

import "./index.scss"

const TimeWheel = ({ middleDate, getBoxInfo, datesMultiplier = 1 }) => {
  const [timeWheelData, setTimeWheelData] = useState([])

  useEffect(() => {
    if (!middleDate) return

    const fromDate = middleDate.subtract(4 * datesMultiplier, "hours")
    const toDate = middleDate.add(5 * datesMultiplier, "hours")
    const dateDiff = toDate.diff(fromDate, "hours")

    const timeWheelData = Array.from({ length: dateDiff }, (_, index) =>
      dayjs(fromDate).add(index, "hours").format("ha")
    )

    setTimeWheelData(timeWheelData)
  }, [middleDate])

  return (
    <div className="time-wheel-container">
      {timeWheelData.map(time => (
        <div key={time} className={getBoxInfo(time).className}>
          {time}
        </div>
      ))}
    </div>
  )
}

export default TimeWheel
