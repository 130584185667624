import { useContext } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { setAuth0Type } from "@actions/loginActions"
import { loginProviders } from "@config/enums"
import { AUTH0_TYPES } from "constants/auth0Types"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"
import { useTopLevelContext } from "contexts/TopLevelContext"

export const useSignUpActions = ({ isPreview }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    setIsRegistrationInProgress,
    setSocialRegistrationType,
    setIsRegisterCheckIdentity,
  } = useTopLevelContext()
  const { loginWithPopup } = useAuth0()
  const { saveInviteInfo } = useContext(TeeupPreviewContext)

  const handleTwitterLogin = event => {
    event && event.preventDefault()
    setIsRegistrationInProgress(true)
    setSocialRegistrationType(loginProviders.twitter)
    dispatch(setAuth0Type(AUTH0_TYPES.TWITTER))

    if (isPreview) {
      saveInviteInfo()
    }

    loginWithPopup({
      screen_hint: "signup",
      connection: loginProviders.twitter,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.twitter,
      },
    }).then(() => {
      setIsRegisterCheckIdentity(true)
    })
  }

  const handleGoogleLogin = event => {
    event && event.preventDefault()
    setIsRegistrationInProgress(true)
    setSocialRegistrationType(loginProviders.google)
    dispatch(setAuth0Type(AUTH0_TYPES.GOOGLE))

    if (isPreview) {
      saveInviteInfo()
    }

    loginWithPopup({
      screen_hint: "signup",
      connection: loginProviders.google,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.google,
      },
    }).then(() => {
      setIsRegisterCheckIdentity(true)
    })
  }

  const handleFbLogin = event => {
    event && event.preventDefault()
    setIsRegistrationInProgress(true)
    setSocialRegistrationType(loginProviders.fb)
    dispatch(setAuth0Type(AUTH0_TYPES.FACEBOOK))

    if (isPreview) {
      saveInviteInfo()
    }

    loginWithPopup({
      screen_hint: "signup",
      connection: loginProviders.fb,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.fb,
      },
    }).then(() => {
      setIsRegisterCheckIdentity(true)
    })
  }

  const handleOutlookLogin = event => {
    event && event.preventDefault()
    setIsRegistrationInProgress(true)
    setSocialRegistrationType(loginProviders.outlook)
    dispatch(setAuth0Type(AUTH0_TYPES.OUTLOOK))

    if (isPreview) {
      saveInviteInfo()
    }

    loginWithPopup({
      screen_hint: "signup",
      connection: loginProviders.outlook,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.outlook,
      },
    }).then(() => {
      setIsRegisterCheckIdentity(true)
    })
  }

  const handleAppleLogin = event => {
    event && event.preventDefault()
    setIsRegistrationInProgress(true)
    setSocialRegistrationType(loginProviders.apple)
    dispatch(setAuth0Type(AUTH0_TYPES.APPLE))

    if (isPreview) {
      saveInviteInfo()
    }

    loginWithPopup({
      screen_hint: "signup",
      connection: loginProviders.apple,
      appState: {
        isRegistrationInProgress: true,
        socialRegistrationType: loginProviders.apple,
      },
    }).then(() => {
      setIsRegisterCheckIdentity(true)
    })
  }

  const handleSignUp = () => {
    if (isPreview) {
      saveInviteInfo()
    }
    history.push("/sign-up-email")
  }

  return {
    handleTwitterLogin,
    handleGoogleLogin,
    handleFbLogin,
    handleOutlookLogin,
    handleAppleLogin,
    handleSignUp,
  }
}
