import _ from "lodash"

import actionTypes from "@actions/actionTypes"
import { mergeArrays } from "@utils/mergeArrays"

import ContactState from "./contactStorage"

export const initialState = {
  isContactsSynced: false,
  syncInProgress: false,
  isGoogleFetched: false,
  isAppleFetched: false,
  syncConnectors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return _.assign({}, initialState)

    case actionTypes.GOT_BOOK_CONTACTS: {
      const { contacts } = action.payload
      ContactState.bookContacts = mergeArrays(
        ContactState.bookContacts,
        contacts
      )

      return _.assign({}, state)
    }

    case actionTypes.DELETE_SYNC_CONTACTS: {
      ContactState.bookContacts = ContactState.bookContacts.filter(
        contact => contact.connectorEmail !== action.payload
      )

      return _.assign({}, state)
    }

    case actionTypes.GOT_CONTACTS: {
      let { allContacts, tags, recent, frequent, byTags } = action.payload

      // let isContactsSynced = false

      // if (state.isContactsSynced) {
      //     isContactsSynced = true
      // } else if (bookContacts.length > 0) {
      //     isContactsSynced = true
      // }

      // if (!isContactsSynced) {
      //     allContacts = []
      // }

      ContactState.allContacts = allContacts
      ContactState.tags = tags
      ContactState.recent = recent
      ContactState.frequent = frequent
      ContactState.byTags = byTags

      return _.assign({}, state)
    }

    case actionTypes.CONTACT_CHANGE_DEFAULT_TYPE: {
      // Temporarily update default contact mechanism for a contact
      const { updatedContact } = action.payload

      // let updates = {}

      const updateContact = array => {
        for (let i = 0, len = array.length; i < len; i++) {
          const contact = array[i]
          if (contact.id === updatedContact.id) {
            array = [...array]
            array[i] = updatedContact
            break
          }
        }
        return array
      }

      ContactState.allContacts = updateContact(state.allContacts)

      const tagKeys = Object.keys(state.byTags)
      let updatedByTags = state.byTags
      for (let i = 0, len = tagKeys.length; i < len; i++) {
        const tagKey = tagKeys[i]
        const updatedTagArray = updateContact(updatedByTags[tagKey])
        if (updatedTagArray !== updatedByTags[tagKey]) {
          // Contact got updated under this tag
          updatedByTags = { ...updatedByTags }
          updatedByTags[tagKey] = updatedTagArray
        }
      }

      if (updatedByTags !== ContactState.byTags) {
        ContactState.byTags = updatedByTags
      }

      return state
    }

    case actionTypes.SET_USER_CONTACTS_SYNCED: {
      return _.assign({}, state, { isContactsSynced: true })
    }

    case actionTypes.SET_USER_CONTACTS_SYNCING_PROGRESS: {
      return _.assign({}, state, { syncInProgress: action.payload })
    }

    case actionTypes.SET_NEW_SYNC_CONTACT: {
      return _.assign({}, state, {
        syncConnectors: [...state.syncConnectors, action.payload],
      })
    }

    case actionTypes.SET_SYNC_CONTACTS_LIST: {
      return _.assign({}, state, {
        syncConnectors: action.payload,
      })
    }

    case actionTypes.DELETE_CONTACTS_CONNECTOR: {
      const syncConnectors = state.syncConnectors.filter(
        connector => connector.id !== action.payload
      )

      return _.assign({}, state, { syncConnectors })
    }

    default:
      return state
  }
}
