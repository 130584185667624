import { createSelector } from "reselect"

// export const selectInvitees = createSelector((state) => state.invitees.invitees)
export const selectInvitees = state => state.invitees.invitees
export const selectDraftInvitees = state => state.invitees.draftInvitees

export const selectSelectedContacts = state => state.invitees.selectedContacts

export const selectMostTeeupedWithUsersInvitees = createSelector(
  selectInvitees,
  invitees => invitees.mostTeeupedWithUsers || []
)

export const selectPreviousTeeupsInvitees = createSelector(
  selectInvitees,
  invitees => invitees.previousTeeups || []
)

export const selectRecommendedInvitees = createSelector(
  selectInvitees,
  invitees => invitees.recommended || []
)
