import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  setModalPeopleNudge,
  hideSuggestionListFooter,
} from "@actions/commonActions"
import {
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
} from "@actions/teeupActions"
import { SvgIcon, NewTooltip } from "@components/common"
import Pill from "@components/ui/Pill"
import { selectMessageById, selectActiveTeeup } from "@selectors/activeTeeup"
import { selectUserId } from "@selectors/user"

import { store } from "../../../../index"
import { usePopupActions } from "../hooks"

import {
  Wrapper,
  ContentActions,
  ActionItem,
  Text,
  ActionsContainer,
} from "./popupActionStyles"

// TODO: almost all content of this component is copied from SuggestionPopup component - refactor this component.
// TODO: from here - src/components/gamePlansCard/SuggestionPopup/index.js

// TODO: also think about Context for SuggestionPopup component for less amount of props

const PopupActions = ({
  currentSuggestion,
  toggleSuggestionPopup,
  setCurrentSuggestion,
  handleOpenSuggestion,
  setPopupContentType,
  initialSuggestions,
  activeTeeup,
  userId,
  hideSuggestionListFooter,
  setModalPeopleNudge,
  setSuggestionRemovedStatus,
  isGeneralSuggestion,
}) => {
  const completeCurrentSuggestion =
    selectMessageById(currentSuggestion.id)(store.getState()) ||
    initialSuggestions?.find(
      suggestion => suggestion.id === currentSuggestion.id
    )

  const closeSuggestionPopup = () => {
    toggleSuggestionPopup(false)
    setCurrentSuggestion && setCurrentSuggestion({})
  }

  const actionsList = usePopupActions({
    currentSuggestion,
    completeCurrentSuggestion,
    setModalPeopleNudge,
    activeTeeup,
    userId,
    setSuggestionRemovedStatus,
    removeSuggestionRemovedStatus,
    closeSuggestionPopup,
    setPopupContentType,
    isGeneralSuggestion,
  })

  return (
    <Wrapper onClick={e => e.stopPropagation()}>
      <ActionsContainer>
        {actionsList.map((action, actionIndex) => (
          <>
            {action.isDisabled && action.tooltip && (
              <NewTooltip key={`tip_${actionIndex}`} />
            )}

            <Pill
              data-testid={action.testID}
              data-tip={action.tooltip}
              isDisabled={action.isDisabled}
              onClick={action.method}
              key={actionIndex}>
              <SvgIcon name={action.iconName} color="var(--gray6)" />

              <span>{action.text}</span>
            </Pill>
          </>
        ))}
      </ActionsContainer>

      {/* <ContentActions>
        {contentActions.map((action, actionIndex) => (
          <ButtonEl
            autoWidth
            text={action.text}
            variant={BUTTON_VARIANTS.TRANSPARENT}
            onClick={() => setPopupContentType(action.type)}
            key={actionIndex}
          />
        ))}
      </ContentActions> */}
    </Wrapper>
  )
}

PopupActions.propTypes = {
  currentSuggestion: PropTypes.object,
  toggleSuggestionPopup: PropTypes.func,
  setCurrentSuggestion: PropTypes.func,
  handleOpenSuggestion: PropTypes.func,
  setPopupContentType: PropTypes.func,
}

const mapStateToProps = state => ({
  initialSuggestions: state.activeTeeup.messages.find(
    message => message.suggestionIds
  )?.gameplans,
  activeTeeup: selectActiveTeeup(state),
  userId: selectUserId(state),
})

const mapDispatchToProps = dispatch => ({
  hideSuggestionListFooter: () => dispatch(hideSuggestionListFooter()),
  setModalPeopleNudge: (value, id, typeId) =>
    dispatch(setModalPeopleNudge(value, id, typeId)),
  setSuggestionRemovedStatus: (id, activeTeeupId) =>
    dispatch(setSuggestionRemovedStatus(id, activeTeeupId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupActions)
