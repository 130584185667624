import styled, { css } from "styled-components"

import { INNER_EVENTS_HEIGHT } from "@contexts/TimeWheelContext"

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const EventItem = styled.div`
  ${({ width, startColumn, marginLeft, $colors }) => css`
    width: ${width}px;
    height: 50px;
    background-color: ${$colors.secondary};
    border-left: 3px solid ${$colors.main};
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: ${startColumn > 1 ? `${startColumn * 40 - 40}px` : "0"};
    margin-left: ${marginLeft ? `${marginLeft}px` : 0};
    padding: 2px 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  `};
`

export const InnerEventsContainer = styled.div`
  width: 200px;
  max-height: ${INNER_EVENTS_HEIGHT}px;
  overflow-y: auto;
  background-color: var(--white);
  padding: 10px 10px 10px 25px;
  position: absolute;
  top: 100%;
  left: -3px;
  z-index: 9;
`

export const InnerEvent = styled.div`
  position: relative;
  margin-top: 10px;
`

export const EventPoint = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: -16px;
`
