import { useAuth0 } from "@auth0/auth0-react"

import { registerWithSocial } from "../../../actions/loginActions"
import { API_ERRORS_MESSAGES, REGISTER_STEPS } from "../constants"

export const useUserRegistration = ({
  setNewRegisteredUser,
  setIsRegisterNamesLoading,
  setIsRegisterSsoNames,
  setNewRegisteredUserError,
  setIsCreateUserAnyway,
  addApiError,
  setStep,
  setLocalSSOUser,
  setInviteSSORegisterToken,
}) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const registerUser = ({
    isInvite,
    user,
    fullName,
    displayName,
    createAnyways,
    captcha,
  }) => {
    registerWithSocial({
      ...user,
      name: fullName,
      captcha,
      nickname: displayName.replace("@", ""),
      createAnyways,
    })
      .then(async res => {
        const registeredUser = {
          ...user,
          "https://coo-e.com/userId": res.data.id,
          "https://coo-e.com/userType": res.data.userType,
          updatedName: fullName,
          updatedNickname: displayName,
        }
        setLocalSSOUser(registeredUser)

        if (isInvite) {
          setStep(REGISTER_STEPS.INVITE_SENDER)
          await getAccessTokenSilently({ ignoreCache: true })
          const idToken = await getIdTokenClaims()
          setInviteSSORegisterToken(idToken.__raw)
        } else {
          setNewRegisteredUser(registeredUser)
          setIsRegisterSsoNames(false)
        }

        setIsRegisterNamesLoading(false)
        setIsCreateUserAnyway(false)
      })
      .catch(error => {
        if (error.status === 422) {
          addApiError("displayName", API_ERRORS_MESSAGES.INVALID_DISPLAY_NAME)
        } else if (error.status === 429) {
          addApiError("global", API_ERRORS_MESSAGES.TOO_MANY_REQUESTS)
        } else {
          setIsRegisterSsoNames(false)
          setNewRegisteredUserError(error)
          setIsCreateUserAnyway(false)
        }

        setIsRegisterNamesLoading(false)
      })
  }

  return { registerUser }
}
