import cn from "classnames"

import { SvgIcon } from "@components/common"
import { StatusIcon as IStatusIcon } from "types/participant"

import StatusIcon from "../StatusIcon"

import "./index.scss"

interface Props {
  avatar: string
  status?: IStatusIcon
  isLarge?: boolean
  isXL?: boolean
  hideStatus?: boolean
  isOrganizer?: boolean
  defaultAvatar: string
  isFullDefaultAvatar?: boolean
  className?: string
  statusClassName?: string
}

const ParticipantIcon = ({
  avatar,
  status,
  isLarge,
  hideStatus,
  isOrganizer,
  isXL,
  defaultAvatar,
  isFullDefaultAvatar,
  className,
  statusClassName,
}: Props) => {
  return (
    <div
      className={cn("people-participants-icon", className, {
        "people-participant-icon-large": isLarge,
        "people-participant-icon-xl": isXL,
      })}>
      {isOrganizer && (
        <SvgIcon
          name="crown-2"
          className={cn("people-participants-organizer", {
            "people-participants-organizer-small": !isLarge,
          })}
        />
      )}
      {avatar && <img src={avatar} className="people-participant-avatar" />}
      {!avatar && defaultAvatar && (
        <div className="people-participant-avatar people-participant-avatar-default">
          {isFullDefaultAvatar ? defaultAvatar : defaultAvatar[0]}
        </div>
      )}

      {!hideStatus && status && (
        <StatusIcon
          status={status}
          isLarge={isLarge}
          isXL={isXL}
          className={statusClassName}
        />
      )}
    </div>
  )
}

export default ParticipantIcon
