import { useMemo } from "react"

import moment from "moment-timezone"

export const useThisWeekDisabledDays = startOfWeek => {
  return useMemo(() => {
    const result = []
    const weekDaysBeforeToday = moment().diff(startOfWeek, "days")
    for (let dayIndex = 0; dayIndex < weekDaysBeforeToday; dayIndex++) {
      const prevDate = moment()
        .startOf("date")
        .subtract(dayIndex + 1, "days")
      result.push(prevDate.format())
    }

    return result
  }, [])
}
