import React, { Component } from "react"

import PropTypes from "prop-types"
import { View, Text } from "react-native"

import { SCREENS } from "@utils/calendarUtils"
import {
  getDatesArray,
  getDateWithTimezone,
  isDateBeforeNow,
  isToday,
  // composeDatetime,
} from "@utils/dateUtils"

import MonthCalendarTimetableContainer from "./MonthCalendarTimetableContainer"
import { MonthCalendarStyles as styles } from "./styles"

class MonthCalendarView extends Component {
  constructor(props) {
    super(props)
    this.weekdays = getDatesArray(props.selectedDate)
  }

  selectDay = date => () => {
    const { changeViewType, newSuggestionProps, selectedDate } = this.props
    if (newSuggestionProps && isDateBeforeNow(date) && !isToday(date)) {
      return
    }
    const selectedDateObj = getDateWithTimezone(selectedDate)
    changeViewType(
      SCREENS.day,
      getDateWithTimezone(date)
        .hour(selectedDateObj.hour())
        .minute(selectedDateObj.minute())
    )
    //changeViewType(SCREENS.day, composeDatetime(date, selectedDate))
  }

  render() {
    const { selectedDate, newSuggestionProps, loadEvents, monthCellWidth } =
      this.props
    return (
      <View style={styles.flex}>
        <View style={styles.headerContainer}>
          {this.weekdays.map((date, index) => {
            return (
              <View style={styles.weekdayWrapper} key={date.format()}>
                <View style={styles.weekdayContainer}>
                  <Text style={styles.weekdayText}>{date.format("ddd")}</Text>
                </View>
                {index !== this.weekdays.length - 1 && (
                  <View style={styles.daysSeparatorContainer}>
                    <View style={styles.daysSeparator} />
                  </View>
                )}
              </View>
            )
          })}
        </View>
        <MonthCalendarTimetableContainer
          monthCellWidth={monthCellWidth}
          hasNewSuggestionProps={!!newSuggestionProps}
          selectedDate={selectedDate}
          selectDay={this.selectDay}
          loadEvents={loadEvents}
        />
      </View>
    )
  }
}

MonthCalendarView.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  changeViewType: PropTypes.func,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      passedEvent: PropTypes.object,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  loadEvents: PropTypes.bool,
}

export default MonthCalendarView
