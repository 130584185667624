import cn from "classnames"

import { getParticipantStatus } from "@utils/peopleUtils"

import ParticipantIcon from "./ParticipantIcon"

import "./index.scss"

const ParticipantItem = ({ participant, hideStatus, className, onClick }) => {
  return (
    <li
      className={cn("people-participant-item", className)}
      onClick={() => onClick && onClick(participant)}>
      <ParticipantIcon
        avatar={participant.avatar}
        status={getParticipantStatus(participant.status)}
        isLarge
        hideStatus={hideStatus}
        isOrganizer={participant.isOrganiser}
        defaultAvatar={participant?.name || participant?.username}
      />

      <div>
        <div className="people-participant-info">
          <h4>{participant.name}</h4>
          {participant.username && <span>@{participant.username}</span>}
        </div>

        <div className="people-participant-last-action">
          {participant?.lastTeeupActivity && participant?.lastTeeupActivity}
        </div>
      </div>
    </li>
  )
}

export default ParticipantItem
