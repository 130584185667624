import React from "react"

import PropTypes from "prop-types"

import { SvgIcon } from "@components/common"

import { Wrapper, Number } from "./mobileSuggestionsCountStyles"

export const MobileSuggestionsCount = ({ amount }) => (
  <Wrapper>
    <SvgIcon name="lamp" color="var(--black)" />

    <Number>{amount}</Number>
  </Wrapper>
)

MobileSuggestionsCount.propTypes = {
  amount: PropTypes.number,
}
