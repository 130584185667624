import { useContext } from "react"

import { useHistory } from "react-router-dom"

import { useAppLink } from "@hooks/useAppLink"
import { useScreenWidth } from "@hooks/useScreenWidth"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { Text } from "./alreadyHaveAccountStyles"

export const AlreadyHaveAccount = () => {
  const { saveInviteInfo } = useContext<any>(TeeupPreviewContext)
  const { isSmallSize } = useScreenWidth()
  const { appLink } = useAppLink()
  const history = useHistory()

  const confirmHandle = () => {
    saveInviteInfo()
    if (isSmallSize) {
      window.location.href = appLink
    } else {
      history.push("/sign-in")
    }
  }

  return (
    <Text $isOnBottom>
      Already have an account?{" "}
      <Text onClick={confirmHandle} className="cursor-pointer underline">
        Log in
      </Text>
    </Text>
  )
}
