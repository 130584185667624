import { Auth0Client } from "@auth0/auth0-spa-js"
import axios from "axios"
import { push } from "connected-react-router"
import ApiCalendar from "react-google-calendar-api"
import { v4 as uuidv4 } from "uuid"

import { resetCalendar } from "@actions/calendarActions"
import { setModalInfo } from "@actions/commonActions"
import { fetchUserInfo, setUserIsLoading } from "@actions/userActions"
import endpoints from "@config/endpoints"
import { modalHandlerTypes } from "@configs/enums"
import { getCurrentTimezone } from "@utils/dateUtils"
import { getRandomBase64Image } from "@utils/imageUtils"
import { deleteAllCookies } from "@utils/localStorage"
import { currentCountry } from "@utils/location"
import { getSignInMethodTitle } from "@utils/loginUtils"
import { logEvent, EVENTS } from "analytics-tracking"
import { clientId, domain, store } from "index"

import api from "../api"
import { AUTH0_TYPES } from "../constants/auth0Types"
import notificationService from "../notificationsService"

import actionTypes from "./actionTypes"

const callClearLoginErrorDispatch = dispatch => {
  dispatch({
    type: actionTypes.SET_LOGIN_ERROR,
    payload: null,
  })
}

export const cleanLoginError = () => {
  return dispatch => {
    callClearLoginErrorDispatch(dispatch)
  }
}

export const setAuth0Type = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_AUTH0_TYPE,
      payload,
    })
  }
}

export const setTeeupInviteInfo = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_TEEUP_INVITE_INFO,
      payload,
    })
  }
}

export const setAuth0Error = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_AUTH0_ERROR,
      payload,
    })
  }
}

export const setIsRegisterSsoNames = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_REGISTER_SSO_NAMES,
      payload,
    })
  }
}

export const setNewRegisteredUser = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_NEW_REGISTERED_USER,
      payload,
    })
  }
}

export const setNewRegisteredUserError = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_NEW_REGISTERED_USER_ERROR,
      payload,
    })
  }
}

export const setIsCreateUserAnyway = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.CREATE_USER_ANYWAY,
      payload,
    })
  }
}

export const loginEmail = (email, password) => {
  return dispatch => {
    callClearLoginErrorDispatch(dispatch)
    dispatch({
      type: actionTypes.SET_IS_EMAIL_LOGIN_LOADING,
      payload: true,
    })

    axios
      .post(
        endpoints.LOGIN,
        {
          email,
          password,
          type: "email",
        },
        {
          baseURL: process.env.REACT_APP_API_ENDPOINT,
        }
      )
      .then(res => {
        const { data } = res
        if (data.user.hasPassword) {
          data.loginType = "email"
          saveUserAndRedirect(data, dispatch)
          notificationService.askForNotificationsPermsions()
          notificationService.setEmail(email)
          logEvent(EVENTS.SIGN_IN, {
            loginType: "email",
            email: data.user.email,
          })
        } else {
          // it's temp password
          dispatch(push("change-temp-password", data.accessToken))
        }
        dispatch({
          type: actionTypes.SET_IS_EMAIL_LOGIN_LOADING,
          payload: false,
        })
      })
      .catch(error => {
        const { status, message, code, data } = error.response.data
        if (status === 401 && message) {
          dispatch({
            type: actionTypes.SET_LOGIN_ERROR,
            payload: { message, errorCode: data?.errorCode },
          })

          if (code === 0 && data?.authType) {
            const auth0TypeKey = data?.authType.split("-")[0]?.toUpperCase()

            if (auth0TypeKey) {
              dispatch({
                type: actionTypes.SET_AUTH0_TYPE,
                payload: AUTH0_TYPES[auth0TypeKey],
              })
            }
          }
        }

        dispatch({
          type: actionTypes.SET_IS_EMAIL_LOGIN_LOADING,
          payload: false,
        })
      })
  }
}

export const logOut = (auth0Logout, isRedirectDisabled = false) => {
  return dispatch => {
    logEvent(EVENTS.LOGOUT)

    if (auth0Logout && ApiCalendar.sign) {
      ApiCalendar.handleSignoutClick()
    }

    dispatch(resetCalendar())

    localStorage.clear()
    deleteAllCookies()

    dispatch({ type: actionTypes.LOG_OUT })
    notificationService.removePlayedId()

    if (auth0Logout) {
      auth0Logout({ returnTo: window.origin + "/sign-in" })
    } else {
      if (!isRedirectDisabled) {
        dispatch(push("/sign-in"))
      }
    }
    logEvent(EVENTS.LOGOUT)

    if (auth0Logout && ApiCalendar.sign) {
      ApiCalendar.handleSignoutClick()
    }

    localStorage.clear()
    deleteAllCookies()

    dispatch({ type: actionTypes.LOG_OUT })
    notificationService.removePlayedId()

    if (auth0Logout) {
      auth0Logout({ returnTo: window.origin + "/sign-in" })
    } else {
      if (!isRedirectDisabled) {
        dispatch(push("/sign-in"))
      }
    }
    window.location.reload()
  }
}

export const parseUserIdFromUserInfo = userInfo =>
  Number.parseInt(userInfo[`https://coo-e.com/userId`]) || null

export const updateUserIdentities = identitiesPayload => ({
  type: actionTypes.UPDATE_USER_IDENTITIES,
  payload: identitiesPayload,
})

export const saveUser = (data, dispatch) => {
  dispatch({ type: actionTypes.SET_USER_INFO, payload: data })
  dispatch(setUserIsLoading(false))
}

export const saveUserAndRedirect = (data, dispatch, noRedirect = false) => {
  api.createSession(data)

  dispatch({ type: actionTypes.SET_USER_INFO, payload: data })
  dispatch(setUserIsLoading(false))
  fetchUserInfo()

  if (noRedirect) return
  dispatch(push("/inbox"))
}

export const verifyUserAndSave = (userInfo, idToken) => {
  // check here if user is authenticated/registered. if either of these two is missing API will throw error
  const provider = userInfo.sub.split("|")[0]
  if (
    !userInfo["https://coo-e.com/userId"] ||
    !userInfo["https://coo-e.com/userType"]
  ) {
    return store.dispatch(
      setModalInfo({
        show: true,
        title: `Something's wrong with ${getSignInMethodTitle(provider)} login`,
        message:
          "You need to register this account or link it from the application",
        handler: modalHandlerTypes.logout,
        canCloseModal: false,
      })
    )
  }
  const userId = parseUserIdFromUserInfo(userInfo)

  return dispatch => {
    // .then((response) => {
    // const { data } = response

    // if(data.registrationType === loginProviders.email) {
    //     alert('User already registered with email');
    //     logout();
    //     dispatch(setUserIsLoading(false));

    //     return;
    // }

    let socialUserInfo = {
      id: userId,
      email: userInfo.email,
      name: userInfo.name || userInfo.givenName,
      firstName: userInfo.givenName || userInfo.name,
      lastName: userInfo.family_name,
      nickname: userInfo.nickname,
      sub: userInfo.sub,
      // avatar,
      loginType: provider,
      accessToken: idToken,
    }

    saveUserAndRedirect(socialUserInfo, dispatch, true)
  }
}

export const sendPasswordRecoveryLink = email => {
  return api.client.post(endpoints.sendPasswordRecoveryLink, { email })
}

export const validateInvitation = value => {
  return api.client.post(endpoints.verifyUserType, value)
}

export const sendBetaCode = (value, id) => {
  return api.client.post(endpoints.sendBetaCode, {
    ...value,
    deviceId: id,
  })
}

export const recoveryRequest = (email, id) => {
  return api.client.post(endpoints.recoverAccount, {
    email,
    deviceId: id,
  })
}

export const recoveryRequestVerify = (value, contanctId) => {
  return api.client.patch(endpoints.recoverAccountVerify + contanctId, {
    code: value,
  })
}

export const recoveryPassword = ({ password, token, deviceId }) => {
  return api.client.post(endpoints.recoverPassword(token), {
    password,
    deviceId,
  })
}

export const changeEmailVerificationRequest = (
  contactMechanismId,
  accessToken
) => {
  return api.client.post(
    endpoints.changeEmailVerificationRequest +
      contactMechanismId +
      "/verifications",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const changeEmailVerificationVerify = (
  value,
  contactMechanismVerificationId,
  designation,
  accessToken
) => {
  return api.client.post(
    endpoints.changeEmailVerificationVerify +
      contactMechanismVerificationId +
      "/verify",
    {
      code: value,
      designation: designation,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const createUserCM = (typeId, title, accessToken) => {
  return api.client.post(
    endpoints.addUserCM,
    {
      typeId,
      value: title,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteUserCM = (cmId, accessToken, typeOfCM) => {
  return api.client.delete(
    `users/contact-mechanisms/${cmId}${typeOfCM}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}

export const deleteUserCMFromEmail = (cmId, accessToken, typeOfCM) => {
  return function (dispatch) {
    return api.client.delete(
      `users/contact-mechanisms/${cmId}${typeOfCM}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }
}

export const getUserCM = accessToken => {
  return api.client.get(endpoints.addUserCM, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const verifyBetaCode = (key, id, code) => {
  return api.client.post(endpoints.verifyBetaCode, {
    [key]: {
      id,
      code,
    },
  })
}

export const checkLoginIdentity = async body => {
  return api.client.post(endpoints.checkSocialIdentity, body)
}

export const register = async (userInfo, accessToken) => {
  const {
    id,
    email,
    // sub,
    nickname,
    password,
    timezone,
    phoneNumber,
    name,
    firstname = "",
    lastname = "",
    deviceId,
    captcha,
    avatar,
  } = userInfo

  // default avatar
  const currentAvatar = avatar ? avatar : await getRandomBase64Image()
  const avatartype = "image/base64"

  let body = {
    id,
    name,
    lastname,
    firstname,
    username: nickname,
    password: password,
    phoneNumber,
    locale: "en",
    // sub: sub,
    email,
    avatar: currentAvatar,
    avatartype,
    type: "email",
    timezone: timezone || getCurrentTimezone(),
    country: currentCountry,
    // countryCode: countryCode || `${getCountryCode() || 1}`,
    deviceId: deviceId || uuidv4(),
  }

  if (captcha) {
    body.captcha = captcha
  }

  return api.client.post(endpoints.register, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const registerWithSocial = async userInfo => {
  const deviceId = uuidv4().replaceAll("-", "")
  const {
    email = "",
    family_name = "",
    given_name = "",
    nickname = "",
    name = "",
    picture,
    sub,
    captcha,
    createAnyways,
    // updated_at,
    // locale,
  } = userInfo

  // default avatar
  const avatar = picture || (await getRandomBase64Image())
  const avatartype = "image/base64"

  let body = {
    createAnyways,
    deviceId,
    name,
    lastname: family_name,
    firstname: given_name,
    username: nickname,
    locale: "en",
    ...(email && { email }),
    avatar,
    avatartype,
    timezone: getCurrentTimezone(),
    country: currentCountry,
    sub,
    type: sub.split("|")[0],
    identityTitle: email ? email : undefined,
  }

  if (captcha) {
    body.captcha = captcha
  }

  return api.client.post(endpoints.register, body)
}

export const getTokenOnVerifiedInvitation = token => {
  return {
    type: actionTypes.GET_TOKEN_ON_VERIFIED_INVITATION,
    payload: token,
  }
}

export const authenticateUser = async connection => {
  const a0 = new Auth0Client({
    domain: domain,
    client_id: clientId,
    connection,
  })
  await a0
    .loginWithPopup({
      max_age: 0,
      connection,
    })
    .catch(error => console.log("caught", error))
  return await a0.getIdTokenClaims()
}
