import React from "react"

import "./participantsInfo.scss"
import NonUser from "../../../assets/images/non-user.svg"
import { AVATARS_SIZES } from "../../TeeupPreviewContent/constants"
import { ParticipantAvatar } from "../../TeeupPreviewContent/teeupPreviewContentStyles"

export const ParticipantsInfo = ({ zone }) => (
  <div className="participants-info">
    <div className="participants-info--left">
      <div className="participants-info__avatars">
        {zone.avatars.map((avatar, avatarIndex) => (
          <ParticipantAvatar size={AVATARS_SIZES.MINI}>
            <img src={avatar || NonUser} alt="user" />
          </ParticipantAvatar>
        ))}
      </div>

      <div className="participants-info__data">
        <span className="participants-info__data-title">{zone.title}</span>

        <span className="participants-info__data-timezone">
          {zone.country} ({zone.abbreviation})
        </span>
      </div>
    </div>

    <div className="participants-info--right">
      <span className="participants-info__date">{zone.daysPeriod}</span>

      <span className="participants-info__time">{zone.time}</span>
    </div>
  </div>
)
