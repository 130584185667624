import { useMemo, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react"

import { loginProviders } from "@config/enums"
import { findEarliestAndLatestEvents } from "@utils/timeUtils"

import { getStartOfDate, getEndOfDate } from "../helpers"

export const useCalendarsList = ({
  isFullList,
  linkGuestCalendar,
  newGuestSuggestion,
  guestSuggestions,
  closeModal,
}) => {
  const [isInProgress, setIsInProgress] = useState(false)
  const { getAccessTokenWithPopup } = useAuth0()

  const eventsRange = useMemo(() => {
    const { earliest, latest } = findEarliestAndLatestEvents(guestSuggestions)

    return {
      startDate: getStartOfDate(earliest?.startDate),
      endDate: getEndOfDate(latest?.endDate),
    }
  }, [guestSuggestions])

  const linkCalendarHandle = async ({ connection, connection_scope }) => {
    setIsInProgress(true)
    try {
      const accessToken = await getAccessTokenWithPopup({
        connection,
        connection_scope,
        prompt: "consent",
        access_type: "offline",
      })
      if (!accessToken) return

      linkGuestCalendar({
        accessToken,
        provider: connection,
        startDate: isFullList
          ? eventsRange.startDate
          : getStartOfDate(newGuestSuggestion.start),
        endDate: isFullList
          ? eventsRange.endDate
          : getEndOfDate(newGuestSuggestion.end),
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsInProgress(false)
      closeModal()
    }
  }

  return [
    {
      iconName: "outlook-2",
      text: "Outlook",
      isDisabled: isInProgress,
      onClick: async () => {
        await linkCalendarHandle({
          connection: loginProviders.windowslive,
          connection_scope: "https://graph.microsoft.com/Calendars.Read",
        })
      },
    },
    {
      iconName: "google",
      text: "Google",
      isDisabled: isInProgress,
      onClick: async () => {
        await linkCalendarHandle({
          connection: loginProviders.google,
          connection_scope: "https://www.googleapis.com/auth/calendar",
        })
      },
    },
  ]
}
