import { useContext } from "react"

import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { NewModal } from "components"

import { PointsList } from "./molecules"
import {
  Description,
  ImagesWrapper,
  Image,
} from "./previewMessageGroupPopupStyles"
import { useScreenWidth, useAppLink } from "hooks"

export const PreviewMessageGroupPopup = ({ close }) => {
  const { saveInviteInfo } = useContext(TeeupPreviewContext)
  const { isSmallSize } = useScreenWidth()
  const { appLink } = useAppLink()
  const history = useHistory()
  const confirmText = isSmallSize ? "Download the app" : "Sign up"

  const confirmHandle = () => {
    saveInviteInfo()
    if (isSmallSize) {
      window.location.href = appLink
    } else {
      history.push("/sign-up")
    }

    close()
  }

  return (
    <NewModal
      isOpen
      isBlackBtn
      isHeader={false}
      isCloseBtn
      title="Message the group!"
      confirmText={confirmText}
      onClose={close}
      onConfirm={confirmHandle}>
      <Description>
        Stay informed about the plan details, attendee status, and receive
        timely reminders
      </Description>

      <ImagesWrapper>
        <Image name="conversation-imgs" />
      </ImagesWrapper>

      <PointsList />
    </NewModal>
  )
}

PreviewMessageGroupPopup.propTypes = {
  close: PropTypes.func,
}
