import React from "react"

import PropTypes from "prop-types"
import { View } from "react-native"

import { getDateWithTimezone } from "@utils/dateUtils"

import { Units } from "../../../theme/"

import { CalendarComponentStyles as styles } from "./styles"

const ROW_HEIGHT = Units.responsiveValue(50)

const CurrentTimeLine = ({ totalWidth, left }) => {
  const currentTime = getDateWithTimezone()
  const position = currentTime.hour() + currentTime.minute() / 60
  return (
    <View
      style={[
        styles.currentTimeContainer,
        {
          top: position * ROW_HEIGHT - 3,
          width: totalWidth + 3,
          left: left - 3,
        },
      ]}>
      <View style={styles.currentTimeDot} />
      <View style={styles.currentTimeLine} />
    </View>
  )
}

CurrentTimeLine.propTypes = {
  totalWidth: PropTypes.number,
  left: PropTypes.number,
}

CurrentTimeLine.defaultProps = {
  left: 0,
}

export default CurrentTimeLine
