import { HTMLProps } from "react"

import cn from "classnames"

import { SvgIcon } from "@components/common"
import "./index.scss"

interface Props extends HTMLProps<HTMLLIElement> {
  children: React.ReactNode
  iconName?: string
  isSelected?: boolean
  isDisabled?: boolean
}

const Pill = ({
  children,
  iconName,
  isSelected,
  isDisabled,
  ...rest
}: Props) => {
  return (
    <li
      className={cn("pill", {
        "pill--selected": isSelected,
        "pill--disabled": isDisabled,
      })}
      {...rest}>
      {iconName && <SvgIcon name={iconName} />}
      {children}
    </li>
  )
}

export default Pill
