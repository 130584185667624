import { useContext } from "react"

import PropTypes from "prop-types"

import { HorizontalScrollList } from "@components/common"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { SuggestionItem } from "components"

import DecidedSuggestion from "./DecidedSuggestion"

export const SuggestionsHorizontalList = ({
  list,
  isReacted,
  isCreateTeeup,
  isWhere,
}) => {
  const decidedSuggestion = list.findLast(suggestion => suggestion.decided)
  const { stepsManager } = useContext(TeeupPreviewContext)

  const openSuggestionPreviewHandle = (id, isCreated) => {
    if (isCreated || isCreateTeeup || isWhere) return

    stepsManager.setSuggestionPreviewId(id)
    stepsManager.jumpToStep(1)
  }

  return (
    <HorizontalScrollList
      isReacted={isReacted}
      listLength={list.length}
      isDecided={!!decidedSuggestion}>
      {decidedSuggestion && (
        <DecidedSuggestion suggestion={decidedSuggestion} />
      )}
      {!decidedSuggestion &&
        list.map(suggestion => (
          <SuggestionItem
            isCollapsed
            isPreview
            isCreateTeeup={isCreateTeeup}
            isReacted={isReacted}
            suggestion={suggestion}
            suggestionsLength={list.length}
            openSuggestionPreview={openSuggestionPreviewHandle}
            key={suggestion.id}
            wrapperClassName="!w-auto"
          />
        ))}
    </HorizontalScrollList>
  )
}

SuggestionsHorizontalList.propTypes = {
  isReacted: PropTypes.bool,
  isCreateTeeup: PropTypes.bool,
  list: PropTypes.array,
}
