import React from "react"

import useInPersonTabState from "../../Hooks/useInPersonTabState"
import useSuggest from "../../Hooks/useSuggest"

import FooterButtonsWrapper from "./FooterButtonsWrapper"

import "./index.scss"
import { useSelector } from "react-redux"

import { selectIsFromCreateTeeUp } from "@selectors/common"

const FooterButtons = ({ onSave, handleClose, teeupId }) => {
  const { activePlace } = useInPersonTabState()
  const { onSubmit } = useSuggest({
    suggestInfo: activePlace,
    suggestType: "in-person",
    teeupID: teeupId,
  })

  const isFromCreateTeeUp = useSelector(selectIsFromCreateTeeUp)
  const makeSuggestion = () => {
    onSubmit(false)
    if (onSave) {
      onSave()
    }
    handleClose()
  }

  return (
    <>
      {isFromCreateTeeUp ? (
        <div className="suggest-buttons">
          <div
            className="suggest-buttons__gameplan"
            onClick={() => {
              onSubmit(true)
              handleClose()
            }}>
            Decide on this
          </div>
          <div className="suggest-buttons__suggest" onClick={makeSuggestion}>
            Suggest
          </div>
        </div>
      ) : (
        <FooterButtonsWrapper handleClose={handleClose} onSave={onSave} />
      )}
    </>
  )
}

export default FooterButtons
