import { push } from "connected-react-router"
import _ from "lodash"

import { setTitle } from "@actions/commonActions"
import { fetchBookContacts } from "@actions/contactActions"
import { selectInvitees } from "@selectors/invitees"
import { selectAnyTeeupById } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { GAMEPLAN_OPTIONS, formatSuggestionOut } from "@utils/gamePlanUtils"
import { EVENTS, logEvent } from "analytics-tracking"
import { useCreateTeeupStore } from "stores/createTeeupStore"

import api from "../api"
import endpoints from "../config/endpoints"
import { userTypes } from "../config/enums"
import { store } from "../index"
import { formatInvitees } from "../utils/contactUtils"
import {
  mergeListItemsById,
  mergeListObjectItemsById,
  mapContactsToTags,
} from "../utils/contacts"

import actionTypes from "./actionTypes"
import { setTeeupActive } from "./activeTeeupActions"
import * as teeUpActions from "./teeupActions"
import { useInboxStore } from "stores/inboxStore"

export const setIsCreateTeeupOpen = payload => ({
  type: actionTypes.SET_IS_CREATE_TEEUP_OPEN,
  payload,
})

export const setCreateTeeupScreenType = payload => ({
  type: actionTypes.SET_CREATE_TEEUP_SCREEN_TYPE,
  payload,
})

export const setCreateTeeupSettings = payload => ({
  type: actionTypes.SET_CREATE_TEEUP_SETTINGS,
  payload,
})

export const setCreateTeeupIsInviteesError = payload => ({
  type: actionTypes.SET_IS_INVITEES_ERROR,
  payload,
})

export const closeCreateTeeup = () => ({
  type: actionTypes.CLOSE_CREATE_TEEUP,
})

export const openCreateTeeup = (draftTeeup = null) => ({
  type: actionTypes.OPEN_CREATE_TEEUP,
  payload: draftTeeup,
})

export const setDraftInvitees = invitees => ({
  type: actionTypes.SET_DRAFT_INVITEES,
  invitees,
})

export const setContacts = invitees => ({
  type: actionTypes.SET_CONTACTS,
  invitees,
})

export const getContacts = () => {
  return dispatch => {
    api.client
      .get(endpoints.teeup.getInviteeSuggestions)
      .then(res => {
        const { data } = res
        dispatch(setContacts(data))
        getContactsWithTags()
        return data
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const fetchUserTags = userId => {
  let url = endpoints.user.tags(userId)
  return api.client
    .get(url)
    .then(res => {
      const { data } = res

      return data
    })
    .catch(error => {
      console.log("error", error)
    })
}

export const getContactsWithTags = async callback => {
  const formatCooeUser = user => ({
    ...user,
    type:
      user.usertype !== userTypes.draft &&
      user.usertype !== userTypes.draftTellUs
        ? "cooeUser"
        : "contact",
  })
  const userId = selectUserId(store.getState())

  try {
    fetchBookContacts()
    let inviteeSuggestions = selectInvitees(store.getState())

    let tagsData = await fetchUserTags(userId)
    let tags = tagsData && tagsData.data ? tagsData.data : {}

    // Just in case backend fails
    let recent = []
    let frequent = []

    if (!_.isEmpty(inviteeSuggestions)) {
      frequent = inviteeSuggestions.mostTeeupedWithUsers.map(formatCooeUser)

      inviteeSuggestions.previousTeeups.forEach(
        ({ users, createdat, updatedat }) => {
          users.forEach(user => {
            user = formatCooeUser(user)
            user.createdAt = createdat
            user.updatedAt = updatedat
            recent.push(user)
          })
        }
      )
      recent = mergeListItemsById(recent)
    }

    const allContacts = mergeListObjectItemsById({
      contactList: [],
      recent,
      frequent,
    })

    let byTags = mapContactsToTags(allContacts)

    tags.forEach(tag => {
      if (!byTags[tag.id]) {
        byTags[tag.id] = []
      }
    })

    const recentTag = {
      count: recent.length,
      id: -100,
      value: "Recent",
    }

    const frequentTag = {
      count: frequent.length,
      id: -101,
      value: "Frequent",
    }

    tags.unshift(recentTag)
    tags.unshift(frequentTag)

    byTags[recentTag.id] = recent
    byTags[frequentTag.id] = frequent

    store.dispatch({
      type: actionTypes.GOT_CONTACTS,
      payload: {
        allContacts,
        recent,
        frequent,
        byTags,
        tags,
      },
    })

    // TODO: make allContacts an object mapping, and allContactsIndexes to not copy all structures all the time
    // TODO: add recent/frequent as normal custom tags

    //check callback, not all getContacts require promise callback
    callback && callback()
  } catch (error) {
    console.log("getContacts error", error)

    //check callback, not all getContacts require promise callback
    callback && callback(error)
  }
}

export const createTeeup = (
  userId,
  name,
  messages,
  invited,
  gamePlans,
  manuals,
  otherData,
  settings
) => {
  return dispatch => {
    const formattedInvited = formatInvitees(invited)
    let gameplansFormatted = []

    gamePlans.forEach(gameplan => {
      const { type, suggestions, availability, startDate, peopleCount } =
        gameplan
      let formattedGameplan = {
        icon: gameplan.icon,
        title: gameplan.title,
        type: gameplan.type,
        isPrimary: gameplan.isPrimary || false,
      }

      formattedGameplan.suggestions = []

      if (type === GAMEPLAN_OPTIONS.startsWhen) {
        const startsWhenStartDate = startDate
        const startsWhenPeopleCount = peopleCount
        let startsWhenAvailability = []
        if (availability && availability.length > 0) {
          startsWhenAvailability = [...availability]
        }
        formattedGameplan.suggestions = [
          {
            startDate: startsWhenStartDate,
            peopleCount: startsWhenPeopleCount,
            availability: startsWhenAvailability,
          },
        ]
      }
      if (type === GAMEPLAN_OPTIONS.whenWorks) {
        const updatedSuggestions = _.cloneDeep(suggestions)
        formattedGameplan.suggestions = updatedSuggestions.map(suggestion => ({
          ...suggestion,
          date: suggestion.date.parseZone
            ? suggestion.date.parseZone() // for moment format
            : suggestion.date,
        }))
      } else {
        if (suggestions && suggestions.length > 0) {
          formattedGameplan.suggestions = suggestions.map(suggestion =>
            formatSuggestionOut(suggestion, type, true)
          )
        }
      }
      gameplansFormatted.push(formattedGameplan)
    })

    const newSettings = { ...settings }

    delete newSettings.allowInviteLink

    api.client
      .post(endpoints.teeup.createTeeup, {
        ownerId: userId,
        name,
        messages,
        invited: formattedInvited,
        gameplanOptions: gameplansFormatted,
        settings: newSettings,
        ...otherData,
      })
      .then(async res => {
        const { data } = res
        const teeup = {}
        teeup.name = name
        teeup.invited = invited
        teeup.id = data.teeupId
        teeup.url = data.teeupUrl
        teeup.photo = otherData.photo
        teeup.createdat = new Date()
        teeup.ownerId = userId
        teeup.createdby = userId
        teeup.status = "planning"
        teeup.gameplans = gamePlans
        teeup.userStatus = "joined"
        const teeupId = Number(teeup.id)

        const teeupExists = selectAnyTeeupById(teeupId)(store.getState())
        if (!teeupExists) {
          store.dispatch(teeUpActions.createTeeup(teeup))
        }

        api.client.post(endpoints.teeup.inviteManual(teeupId), manuals)
        store.dispatch(setIsCreateTeeupOpen(false))
        setTeeupActive(teeup)
        logEvent(EVENTS.CREATE_TEEUP, { userId, status: "planning" })

        store.dispatch(setTitle(name))

        const [teeupUsers, gameplanOptions] = await Promise.all([
          teeUpActions.fetchTeeupUsersById(teeupId),
          teeUpActions.fetchGameplanOptions(teeupId),
        ])

        store.dispatch(teeUpActions.updateGameplan(teeupId, gameplanOptions))
        store.dispatch(teeUpActions.gotTeeupParts([teeupUsers]))

        useInboxStore.getState().updateTriggerTeeupReload()
        useCreateTeeupStore.getState().setIsConfirmCreateTeeupPopupOpen(true)
      })
      .catch(error => {
        console.log("error", error)
      })
  }
}

export const updateGamePlanWhen = payload => ({
  type: actionTypes.UPDATE_GAMEPLAN_WHEN_SUGGESTION,
  payload,
})

export const updateGamePlanWhenSuggestions = payload => ({
  type: actionTypes.UPDATE_GAMEPLAN_WHEN_SUGGESTIONS,
  payload,
})

export const removeFromGamePlanWhen = payload => ({
  type: actionTypes.REMOVE_GAMEPLAN_WHEN_SUGGESTION,
  payload,
})

export const updateGamePlanWhere = payload => ({
  type: actionTypes.UPDATE_GAMEPLAN_WHERE_SUGGESTION,
  payload,
})

export const updateGamePlanWhereSuggestions = payload => ({
  type: actionTypes.UPDATE_GAMEPLAN_WHERE_SUGGESTIONS,
  payload,
})

export const removeFromGamePlanWhere = payload => ({
  type: actionTypes.REMOVE_GAMEPLAN_WHERE_SUGGESTION,
  payload,
})

export const clearGamePlan = () => ({
  type: actionTypes.CLEAR_GAMEPLAN,
})

export const setActiveGamePlanTab = payload => ({
  type: actionTypes.SET_ACTIVE_GAMEPLAN_TAB,
  payload,
})

export const closeActiveGamePlanTab = () => ({
  type: actionTypes.SET_ACTIVE_GAMEPLAN_TAB,
  payload: -1,
})
