import React from "react"

const FinalDateAndTime = ({ finalTime }) => {
  return (
    <div className="final-date-time__wrapper">
      <div className="final-date-time__container">
        <p className="final-date-time__content">{`${finalTime?.line1}, ${finalTime?.startTimeFormatted}-${finalTime?.endTimeFormatted}`}</p>
      </div>
    </div>
  )
}

export default FinalDateAndTime
