import { useCallback } from "react"

import { HOUR_ITEM_WIDTH } from "../constants"

export const usePickerWidth = timeDuration => {
  const getPickerWidth = useCallback(
    newTimeDuration => {
      const pxPerMinute = HOUR_ITEM_WIDTH / 60

      return Math.round(pxPerMinute * (newTimeDuration || timeDuration))
    },
    [timeDuration]
  )

  return { pickerWidth: getPickerWidth(), reinitPickerWidth: getPickerWidth }
}
