import React, { useContext } from "react"

import Skeleton from "react-loading-skeleton"

import {
  TeeupPreviewContext,
  useStatusesList,
} from "contexts/TeeupPreviewContext"

import {
  StatusesList,
  StatusItem,
  StatusTitle,
  StatusIcon,
} from "../rsvpSectionStyles"

export const Statuses = () => {
  const { gameplanInfo, setStatusModalTrigger, statusModalTrigger } =
    useContext(TeeupPreviewContext)
  const list = useStatusesList(gameplanInfo)
  return (
    <>
      {gameplanInfo ? (
        <StatusesList>
          {list
            .filter(({ isShown }) => isShown)
            .map(({ index, imgName, title }) => (
              <StatusItem
                onClick={() => setStatusModalTrigger(index)}
                key={index}
                $isActive={index === statusModalTrigger}>
                <StatusIcon name={imgName} />

                <StatusTitle>{title}</StatusTitle>
              </StatusItem>
            ))}
        </StatusesList>
      ) : (
        <Skeleton width={250} borderRadius={20} />
      )}
    </>
  )
}
