import React from "react"

import { GAMEPLAN_TYPES } from "@constants/gameplan"

import {
  Wrapper,
  IconWrapper,
  Title,
  Description,
  Icon,
} from "./emptyBlockStyles"

export const EmptyBlock = ({ type }) => {
  const isWhen = type === GAMEPLAN_TYPES.when
  const iconName = isWhen ? "clock-2" : "location-2"

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name={iconName} color="var(--radicalRed)" />
      </IconWrapper>

      <Title>No {type} suggestions</Title>
      <Description>
        There are no suggestions, be the first to suggest!
      </Description>
    </Wrapper>
  )
}
