import React, { useContext } from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { updateTeeup } from "@middlewares/backendMiddleware"

import { Wrapper } from "./infoButtonsStyles"

export const InfoButtons = ({ name, onCancel }) => {
  const { teeup } = useContext(NewTeeupPageContext)

  const handleTitleSaving = () => {
    updateTeeup({
      teeUpId: teeup.id,
      teeup: { name },
    })
    onCancel()
  }

  return (
    <Wrapper>
      <ButtonEl
        autoWidth
        isBlack
        variant={BUTTON_VARIANTS.TRANSPARENT}
        text="Cancel"
        onClick={onCancel}
      />

      <ButtonEl
        autoWidth
        isBlack
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Save"
        padding="8px 18px"
        onClick={handleTitleSaving}
      />
    </Wrapper>
  )
}

InfoButtons.propTypes = {
  name: PropTypes.string,
  onCancel: PropTypes.func,
}
