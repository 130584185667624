import React, { useState } from "react"

import { InputEl, ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { validateEmail } from "@utils/dataUtils"

import { Description, ResetInputWrapper } from "../molecules"

export const ForgotPasswordStep = ({
  setStep,
  setCurrentlyEmail,
  handleClickSendRecoveryLink,
}) => {
  const [inputValue, setInputValue] = useState(null)
  const [emailError, setEmailError] = useState(null)

  const handleSubmit = () => {
    setStep(2)
  }

  const onSubmit = () => {
    if (validateEmail(inputValue)) {
      setCurrentlyEmail(inputValue)
      handleClickSendRecoveryLink(inputValue, handleSubmit, setEmailError)

      return
    }

    setEmailError("Invalid email address")
  }

  const handleChangeInput = value => {
    setInputValue(value)

    if (emailError) {
      setEmailError(null)
    }
  }

  return (
    <div>
      <Description>
        Enter the email address you use to login, to receive password reset
        link.
      </Description>

      <ResetInputWrapper>
        <InputEl
          label="Email address"
          name="email"
          value={inputValue}
          placeholder="email@email.com"
          errorMessage={emailError}
          onChange={handleChangeInput}
        />
      </ResetInputWrapper>

      <ButtonEl
        disabled={emailError || !inputValue}
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Get Password Reset Link"
        fullWidth
        onClick={onSubmit}
      />
    </div>
  )
}
