import { useState } from "react"

import { ToggleButton } from "@components/ui/settingsClickableRow/actionButtons"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import "../../Online/MeetingDetails/index.scss"

const AdditionalInformation = () => {
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false)
  const currentAdditionalInformation = useSuggestionInPersonStore(
    state => state.currentAdditionalInformation
  )
  const setCurrentAdditionalInformation = useSuggestionInPersonStore(
    state => state.setCurrentAdditionalInformation
  )
  const coordinates = useSuggestionInPersonStore(state => state.mapCoordinates)
  const selectedName = useSuggestionInPersonStore(state => state.selectedName)
  const updateSuggestion = useSuggestionInPersonStore(
    state => state.updateSuggestion
  )
  const currentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.currentActiveSuggestionIndex
  )
  const isMapShown = (coordinates?.lat && coordinates?.lng) || selectedName

  const handleAdditionalInformationChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value
    console.log("currentActiveSuggestionIndex", currentActiveSuggestionIndex)

    if (currentActiveSuggestionIndex !== -1) {
      updateSuggestion(currentActiveSuggestionIndex, {
        additionalInformation: value,
      })
    }
    setCurrentAdditionalInformation(value)
  }

  if (!isMapShown) return false

  return (
    <>
      <div className="additional-information">
        <h5>Provide Additional Information</h5>
        <ToggleButton
          onToggle={() => setIsAdditionalInfo(prevState => !prevState)}
          value={isAdditionalInfo || !!currentAdditionalInformation}
        />
      </div>

      {isAdditionalInfo && (
        <textarea
          className="custom-textarea"
          rows={5}
          cols={50}
          placeholder="Add anything you think others need to know in order to successfully reach this meeting."
          value={currentAdditionalInformation}
          onChange={handleAdditionalInformationChange}
        />
      )}
    </>
  )
}

export default AdditionalInformation
