import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Wrapper = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
