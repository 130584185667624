export const APP_STORE_APP_LINK = "https://apps.apple.com/app/id1507948580"

export const GOOGLE_PLAY_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.app.cooe"

export const FACEBOOK_LINK = "https://www.facebook.com/CooWeApp"

export const LINKED_IN_LINK = "https://www.linkedin.com/company/coowe/about/"

export const TWITTER_LINK = "https://twitter.com/CooWeApp"

export const INSTAGRAM_LINK = "https://www.instagram.com/coowe_coordinator/"
