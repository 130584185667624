import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const ActionsList = styled.ul`
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
  margin-top: 20px;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    flex-wrap: nowrap;
    overflow-x: auto;
    min-height: 33px;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`

export const ActionItem = styled.li`
  ${({ $isDisabled }) => css`
    background-color: var(--white);
    border: 1px solid var(--gray3);
    border-radius: 90px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    padding: 9px 12px;

    ${$isDisabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};
  `};
`

export const ActionTitle = styled(NSubtitleS)`
  color: var(--gray5);
  font-weight: ${NFontWeight.medium};
  white-space: nowrap;
  line-height: 20px;
`
