import React, { useContext } from "react"

import { connect } from "react-redux"

import { images } from "@utils/imageUtils"

import { TimeWheelContext } from "../../../contexts/TimeWheelContext"
import { selectUserInfo } from "../../../selectors/user"

import "./suggestionWhenCard.scss"

const SuggestionWhenCard = ({ userInfo }) => {
  const { dateLabel, zones } = useContext(TimeWheelContext)
  const getNickname = () => {
    return userInfo.nickname ? `@${userInfo.nickname}` : "your"
  }

  const getTime = () => {
    const zone = zones[0]
    return `${zone?.time} ${zone?.abbreviation}`
  }

  return (
    <section className="suggest-when--wrapper">
      <div className="suggest-when__card">
        <div className="suggest-when__card-header">
          <img
            className="suggest-when__card-clock"
            src={images.ndSuggestClock}
            alt="suggest"
          />
          <span className="suggest-when__card-nickname">{getNickname()}</span>
        </div>

        <span className="suggest-when__card-date">{dateLabel}</span>
        <span className="suggest-when__card-time">{getTime()}</span>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state),
})

export default connect(mapStateToProps)(SuggestionWhenCard)
