import styled from "styled-components"

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;

  & > img {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
`

export const InputWrapper = styled.div`
  flex: 1;
`
