import React, { useContext, useState } from "react"

import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { updateTeeupArchiveStatus } from "@actions/teeupActions"
import { ButtonEl } from "@components/common"
import { teeupStatusKeys } from "@config/enums"
import { BUTTON_VARIANTS } from "@constants/button"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { selectActiveTeeupOrganizers } from "@selectors/activeTeeup"
import { selectTeeupActivePeople } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import { reloadPage } from "@utils/reloadPage"
import { useSnackbarStore } from "stores/snackbarStore"

import {
  dropoutTeeup,
  updateTeeupStatus,
} from "../../../middlewares/backendMiddleware"
import ConfirmAction from "../../../pages/TeeUpPage/TeeUpPageLeftSection/TeeupSettings/ConfirmAction"

import { Wrapper } from "./buttonsPanelStyles"

const ACTIONS_INDEXES = {
  LEAVE: 1,
  CANCEL: 2,
}

export const ButtonsPanel = () => {
  const { teeup, isTeeupOrganiser } = useContext(NewTeeupPageContext)
  const user = useSelector(selectUserInfo)
  const [teeUpActionType, setTeeUpActionType] = useState(0)
  const [isConfirmWindowOpen, setIsConfirmWindowOpen] = useState(false)
  const history = useHistory()
  const organizers = useSelector(selectActiveTeeupOrganizers)
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)

  const handleTeeUpActions = () => {
    if (teeUpActionType === 1) {
      dropoutTeeup({ teeup, userId: user.id })
      document.title = "CooWe Coordinator"
      history.push("/inbox")
      reloadPage()
    } else {
      updateTeeupArchiveStatus(teeup.id)
      updateTeeupStatus(user.id, teeupStatusKeys.cancelled)
    }
  }

  const handleActionClick = index => {
    if (index === ACTIONS_INDEXES.LEAVE && organizers.length < 2) {
      setSnackbarConfig({
        message: "You need to have at least 2 organizers to leave the TeeUp",
        open: true,
      })
      return
    }
    setTeeUpActionType(index)
    setIsConfirmWindowOpen(true)
  }

  return (
    <>
      {isConfirmWindowOpen &&
        ReactDOM.createPortal(
          <ConfirmAction
            type={teeUpActionType}
            close={() => setIsConfirmWindowOpen(false)}
            onClick={() => handleTeeUpActions()}
          />,
          document.querySelector("#modal-root")
        )}

      <Wrapper>
        {isTeeupOrganiser && (
          <ButtonEl
            fullWidth
            isIconFirst
            iconColor="var(--radicalRed)"
            variant={BUTTON_VARIANTS.TRANSPARENT}
            iconName="trash-2"
            fontSize={18}
            text="Cancel TeeUp"
            onClick={() => handleActionClick(ACTIONS_INDEXES.CANCEL)}
          />
        )}

        <ButtonEl
          fullWidth
          isBlack
          isIconFirst
          variant={BUTTON_VARIANTS.ROUNDED}
          iconName="leave"
          text="Leave Teeup"
          onClick={() => handleActionClick(ACTIONS_INDEXES.LEAVE)}
        />
      </Wrapper>
    </>
  )
}
