import React, { useMemo } from "react"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import {
  ButtonEl,
  SuggestionsHorizontalList,
  SvgIcon,
} from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { selectActiveGamePlanTab } from "@selectors/createTeeUp"
import { getSuggestionOnlineOptions } from "@utils/suggestionUtils"
import { setActiveGamePlanTab } from "actions/createTeeupActions"
import SuggestWhenModalNd from "pages/TeeUpPage/TeeUpPageModals/SuggestWhenModalNd"
import SuggestWhereModalNd from "pages/TeeUpPage/TeeUpPageModals/SuggestWhereModalNd"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import dayjs from "utils/dayjs"

import { useCreateTeeUp } from "../../pages/CreateTeeUpNd/CreateTeeUpContext/useCreateTeeUp"
import SuggestWhenModal from "../../pages/CreateTeeUpNd/GamePlan/SuggestWhenModal"
import SuggestWhereModal from "../../pages/CreateTeeUpNd/GamePlan/SuggestWhereModal"

import { Header } from "./atoms"
import Decided from "./atoms/Decided"
import {
  Wrapper,
  Left,
  PlusIcon,
  LeftText,
  PlatformIcon,
} from "./createTeeupGameplanStyles"

export const CreateTeeupGameplan = ({ gameplanData }) => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveGamePlanTab)
  const { name } = useCreateTeeUp()
  const isDisabled = !name
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )
  const setCurrentDate = useSuggestionTimeWheelStore(
    state => state.setCurrentDate
  )

  const meetingPlatforms = useMemo(() => getSuggestionOnlineOptions(), [])

  const openAddSuggestion = index => {
    if (index === 0) {
      setIsWhenModalOpen(true)
    } else {
      setIsWhereModalOpen(true)
    }
    dispatch(setActiveGamePlanTab(index))
  }

  const renderSuggestionsTab = () => {
    if (activeTab === 0) {
      setCurrentDate(dayjs())
      return <SuggestWhenModalNd isFromCreateTeeUp />
      // return <SuggestWhenModal gameplanFromCreate={gameplanData[0]} />
    }
    if (activeTab === 1) {
      return <SuggestWhereModalNd isFromCreateTeeUp />
      // return <SuggestWhereModal forCreateTeeup={true} />
    }
  }

  return (
    <>
      {renderSuggestionsTab()}

      {gameplanData.map((gameplan, index) => {
        const decidedSuggestion = gameplan.suggestions.find(
          item => item?.decided || item?.isDecided
        )
        const suggestionsLength = gameplan?.suggestions?.length
        const currentMeetingIcon = meetingPlatforms.find(
          item => item.label === decidedSuggestion?.value
        )?.iconName

        return (
          <Wrapper
            $isSuggestionsLength={gameplan?.suggestions.length}
            key={index}>
            <Left $isDisabled={isDisabled}>
              {gameplan && (
                <Header
                  title={gameplan.title}
                  isDecided={!!decidedSuggestion}
                  suggestionsLength={suggestionsLength}
                />
              )}
              {!!suggestionsLength && decidedSuggestion && (
                <Decided
                  suggestion={decidedSuggestion}
                  isWhen={gameplan.type === "when"}
                />
              )}
              {!!suggestionsLength && !decidedSuggestion && (
                <SuggestionsHorizontalList
                  isCreateTeeup
                  list={gameplan.suggestions}
                />
              )}
              {!suggestionsLength && !decidedSuggestion && (
                <LeftText>Suggest {gameplan.title}</LeftText>
              )}
            </Left>

            {gameplan?.suggestions.length ? (
              decidedSuggestion ? (
                <>{<PlatformIcon name={currentMeetingIcon || "calendar"} />}</>
              ) : (
                <ButtonEl
                  autoWidth
                  isNoPadding
                  text=""
                  disabled={isDisabled}
                  onClick={() => openAddSuggestion(index)}>
                  <PlusIcon name="circle-plus" />
                </ButtonEl>
              )
            ) : (
              <ButtonEl
                autoWidth
                isNoPadding
                variant={BUTTON_VARIANTS.TRANSPARENT}
                text="Suggest"
                disabled={isDisabled}
                onClick={() => openAddSuggestion(index)}
              />
            )}
          </Wrapper>
        )
      })}
    </>
  )
}

CreateTeeupGameplan.propTypes = {
  gameplanData: PropTypes.array,
}
