import { useMemo, useState } from "react"

export const useLinkingModal = ({
  linkingSuccessMessage,
  closeModals,
  handleRemoveCalendar,
}) => {
  const [isLinkingModal, setIsLinkingModal] = useState(false)
  const [linkingError, setLinkingError] = useState("")
  const [unlinkCalendar, setUnlinkCalendar] = useState(null)

  const linkingModalData = useMemo(
    () => ({
      isOpen:
        isLinkingModal ||
        !!linkingError ||
        !!linkingSuccessMessage ||
        !!unlinkCalendar,
      title: "Link your calendar",
      close: closeModals,
      closeText: (!!linkingError || !!linkingSuccessMessage) && "Close",
      cancelText: unlinkCalendar && "Cancel",
      confirm: () => {
        handleRemoveCalendar()
        closeModals()
      },
      confirmText: unlinkCalendar && "Yes",
    }),
    [isLinkingModal, linkingError, linkingSuccessMessage, unlinkCalendar]
  )

  return {
    setIsLinkingModal,
    linkingModalData,
    linkingError,
    setLinkingError,
    unlinkCalendar,
    setUnlinkCalendar,
  }
}
