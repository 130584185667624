import _ from "lodash"

import { teeupUserStatusKeys } from "@config/enums"
import { statusTypeIds } from "@configs/mappings"
import { teeupStatusTypes } from "@utils/mappings"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"

import actionTypes from "../actions/actionTypes"
import { TEEUPS_CATEGORIES } from "../constants/teeups"

const initialState = {
  // TODO: all operations with isPagination for teeups is temporary, because later we need make all teeups fetching with infinite scroll
  // TODO: so now we temporary use it for avoiding of crushing the app
  isPagination: true,
  isPaginationLoading: false,
  paginationError: null,
  paginationParams: {
    page: 0,
    limit: 15,
    scheduleFilter: "all",
    // TODO: INBOX category is default now because pagination only for Inbox page. In the future pass it from components
    category: TEEUPS_CATEGORIES.INBOX,
    userStatusFilter: "all",
    teeupStatusFilter: "all",
  },
  teeups: [],
  isTeeupListVisible: true,
  archivedTeeups: [],
  removedTeeups: [],
  teeupParticipants: {}, // array of participant ids
  teeupPeople: {}, // object of userId: person structure
  teeupPeopleLocation: {},
  teeupMessages: {}, // teeupId : array of messages
  teeupGameplans: {}, // teeupId : array of gameplans - should refer to teeupSuggestions
  teeupReactions: {}, // teeupId : suggestionId : array of reactions
  teeupLastActions: {}, // teeupId : userId : last action
  teeupsSuggestionMessages: {}, // teeupId : suggestionId : array of suggestion messages
  teeupSuggestions: {}, // teeupId : suggestionId : suggestion structure
  teeupReadTimestamps: {},
  teeupInvitationHistory: {}, // teeupId : array of invitations,
  teeupsUsersInfo: {},
}

const initKey = (updates, state, key, teeupId, initialStructure) => {
  // If updates structure already initiated from state, use it
  let part = updates[key] ? updates[key] : state[key]
  if (teeupId && !part[teeupId]) {
    part = updates[key] ? { ...updates[key], ...part } : { ...part }
    part[teeupId] = initialStructure
  }

  return { [key]: part }
}

const initTeeupStructure = (updates, state, teeup) => {
  _.assign(
    updates,
    initKey(
      updates,
      state,
      "teeupGameplans",
      teeup.id,
      teeup.gameplans ? teeup.gameplans : []
    )
  )
  _.assign(updates, initKey(updates, state, "teeupParticipants", teeup.id, []))
  _.assign(updates, initKey(updates, state, "teeupPeople", teeup.id, {}))
  _.assign(updates, initKey(updates, state, "teeupMessages", teeup.id, []))
  _.assign(updates, initKey(updates, state, "teeupReactions", teeup.id, []))
  _.assign(updates, initKey(updates, state, "teeupLastActions", teeup.id, {}))
  _.assign(
    updates,
    initKey(updates, state, "teeupsSuggestionMessages", teeup.id, {})
  )
  _.assign(updates, initKey(updates, state, "teeupSuggestions", teeup.id, {}))
  _.assign(
    updates,
    initKey(updates, state, "teeupInvitationHistory", teeup.id, [])
  )

  return updates
}
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_TEEUPS_PAGINATION_LOADING:
      return {
        ...state,
        isPaginationLoading: action.payload,
      }
    case actionTypes.SET_TEEUPS_PAGINATION_ERROR:
      return {
        ...state,
        paginationError: action.payload,
      }
    case actionTypes.SET_TEEUPS:
      const { archivedTeeups, teeups, removedTeeups, page, nextPage, total } =
        action.payload

      let updates

      const iteratee = teeup => {
        // Initialize meta objects
        updates = initTeeupStructure(updates, state, teeup)
      }
      // All teeups
      if (total) {
        const isZeroPage = state.paginationParams.page === 0

        updates = {
          archivedTeeups: isZeroPage
            ? archivedTeeups
            : [...state.archivedTeeups, ...archivedTeeups],
          teeups: isZeroPage ? teeups : [...state.teeups, ...teeups],
          removedTeeups: isZeroPage
            ? removedTeeups
            : [...state.removedTeeups, ...removedTeeups],
          isPagination: true,
          paginationParams: {
            ...state.paginationParams,
            page,
            nextPage,
            total,
          },
        }
      } else {
        updates = {
          archivedTeeups,
          teeups,
          removedTeeups,
          isPagination: false,
        }
      }
      teeups.forEach(iteratee)
      archivedTeeups.forEach(iteratee)
      removedTeeups.forEach(iteratee)

      return _.assign({}, state, updates)

    case actionTypes.GOT_TEEUPS_PARTS: {
      let allParts = action.payload

      let teeupGameplans = {}
      let teeupReactions = {}
      let teeupMessages = {}
      let teeupLastActions = {}
      let teeupParticipants = {}
      let teeupInvitationHistory = {}
      let teeupPeople = {}
      let teeupSuggestions = {}

      allParts.forEach(part => {
        if (!part || !part.teeupId) {
          return
        }
        let teeupId = part.teeupId
        if (part.participants) {
          teeupParticipants[teeupId] = part.participants
          teeupPeople[teeupId] = part.people
        } else if (part.messages) {
          teeupMessages[teeupId] = part.messages
          teeupLastActions[teeupId] = part.lastActions
        } else if (part.gameplans) {
          teeupGameplans[teeupId] = part.gameplans
          teeupSuggestions[teeupId] = part.suggestions
          teeupReactions[teeupId] = part.suggestionsReactions
        }
        if (part.invitationHistory) {
          teeupInvitationHistory[teeupId] = part.invitationHistory
        }
      })

      let updates = {
        teeupGameplans,
        teeupReactions,
        teeupMessages,
        teeupLastActions,
        teeupParticipants,
        teeupInvitationHistory,
        teeupPeople,
        teeupSuggestions,
      }

      Object.keys(updates).forEach(key => {
        if (_.isEmpty(updates[key])) {
          delete updates[key]
        }
      })

      return _.assign({}, state, updates)
    }
    case actionTypes.GOT_TEEUP_PARTS: {
      let allParts = action.payload

      let updates = {}

      allParts.forEach((part = {}) => {
        if (part.overview) {
          let teeup = part.overview

          let teeups = [...state.teeups]
          let found = false
          for (let i = 0, len = teeups.length; i < len; i++) {
            if (teeups[i].id === teeup.id) {
              teeups[i] = teeup
              found = true
              break
            }
          }

          if (!found) {
            teeups.unshift(teeup)
          }
        } else if (part.participants) {
          let { teeupId, participants, people } = part
          let teeupParticipants = { ...state.teeupParticipants }
          teeupParticipants[teeupId] = participants
          updates.teeupParticipants = teeupParticipants

          let teeupPeople = { ...state.teeupPeople }
          teeupPeople[teeupId] = people
          updates.teeupPeople = teeupPeople
        } else if (part.messages) {
          let { teeupId, messages, lastActions, suggestionMessages } = part
          let teeupMessages = { ...state.teeupMessages }
          teeupMessages[teeupId] = messages
          updates.teeupMessages = teeupMessages

          let teeupLastActions = { ...state.teeupLastActions }
          teeupLastActions[teeupId] = lastActions
          updates.teeupLastActions = teeupLastActions

          let teeupsSuggestionMessages = {
            ...state.teeupsSuggestionMessages,
          }
          teeupsSuggestionMessages[teeupId] = suggestionMessages
          updates.teeupsSuggestionMessages = teeupsSuggestionMessages
        } else if (part.gameplans) {
          let { teeupId, gameplans, suggestionsReactions, suggestions } = part
          let teeupGameplans = { ...state.teeupGameplans }
          teeupGameplans[teeupId] = gameplans
          updates.teeupGameplans = teeupGameplans

          let teeupSuggestions = { ...state.teeupSuggestions }
          teeupSuggestions[teeupId] = suggestions
          updates.teeupSuggestions = teeupSuggestions

          let teeupReactions = { ...state.teeupReactions }
          teeupReactions[teeupId] = suggestionsReactions
          updates.teeupReactions = teeupReactions
        } else if (part.invitationHistory) {
          let { teeupId, invitationHistory } = part

          let teeupInvitationHistory = {
            ...state.teeupInvitationHistory,
          }
          teeupInvitationHistory[teeupId] = invitationHistory
          updates.teeupInvitationHistory = teeupInvitationHistory
        } else if (part.recommendations) {
          let { teeupId, recommendations } = part
          let teeupRecommendations = { ...state.teeupRecommendations }
          let teeupRecommendationsIds = {
            ...state.teeupRecommendationsIds,
          }
          teeupRecommendationsIds = {
            ...teeupRecommendationsIds,
            [teeupId]: [],
          }
          let recommendationObject = recommendations.reduce((result, item) => {
            teeupRecommendationsIds[teeupId].push(item.id)
            result[item.id] = item
            return result
          }, {})
          teeupRecommendations[teeupId] = recommendationObject
          updates.teeupRecommendations = teeupRecommendations
          updates.teeupRecommendationsIds = teeupRecommendationsIds
        }
      })

      return _.assign({}, state, updates)
    }
    case actionTypes.ARCHIVE_TEEUP:
      const teeUpToUpdate = [
        ...state.teeups,
        ...state.archivedTeeups,
        ...state.removedTeeups,
      ].find(teeup => teeup.id === action.id)
      // const teeUpToUpdate = state.teeups.find((teeup) => teeup.id == action.id);

      if (!teeUpToUpdate) return state
      teeUpToUpdate.isArchived = true
      return {
        ...state,
        teeups: state.teeups.filter(teeup => teeup.id !== action.id),
        archivedTeeups: [].concat(teeUpToUpdate, state.archivedTeeups),
        removedTeeups: state.removedTeeups.filter(
          teeup => teeup.id !== action.id
        ),
      }
    case actionTypes.TEEUP_UPDATE_ARCHIVE_STATUS: {
      let { teeup, status } = action.payload
      let teeups = [...state.teeups]
      let archivedTeeups = [...state.archivedTeeups]

      if (status) {
        archivedTeeups.unshift(teeup)
        teeups = teeups.filter(item => item.id !== teeup.id)
      } else {
        teeups.unshift(teeup)
        archivedTeeups = archivedTeeups.filter(item => item.id !== teeup.id)
      }

      return _.assign({}, state, { archivedTeeups, teeups })
    }
    case actionTypes.GOT_PARTICIPANTS: {
      let { teeupId, participants, people } = action.payload
      let teeupParticipants = { ...state.teeupParticipants }
      teeupParticipants[teeupId] = participants
      let teeupPeople = { ...state.teeupPeople }
      teeupPeople[teeupId] = people

      return _.assign({}, state, { teeupParticipants, teeupPeople })
    }
    case actionTypes.UPDATE_TEEUP_LIST_STATUS:
      const updatedTeeups = state.teeups.map(teeup => {
        if (teeup.id === action.payload.teeupId) {
          return {
            ...teeup,
            status: teeupStatusTypes[action.payload.status],
          }
        }

        return teeup
      })

      return {
        ...state,
        teeups: updatedTeeups,
      }
    case actionTypes.UPDATE_TEEUP_STATUS:
      const { id, status } = action.payload

      let currentFolder
      let teeupToUpdate = state.teeups.find(teeup => teeup.id === id)
      if (teeupToUpdate) currentFolder = TEEUP_LIST_MODES.ACTIVE
      if (currentFolder === undefined) {
        teeupToUpdate = state.archivedTeeups.find(teeup => teeup.id === id)
        if (teeupToUpdate) currentFolder = TEEUP_LIST_MODES.ARCHIVED
      }
      if (currentFolder === undefined) {
        teeupToUpdate = state.removedTeeups.find(teeup => teeup.id === id)
        if (teeupToUpdate) currentFolder = TEEUP_LIST_MODES.SKIPPED
      }
      if (currentFolder === undefined) return { ...state }

      teeupToUpdate.status = status

      if (currentFolder === TEEUP_LIST_MODES.ACTIVE)
        return {
          ...state,
          teeups: state.teeups.map(teeup => {
            if (teeup.id === id) {
              return teeupToUpdate
            }
            return teeup
          }),
        }
      if (currentFolder === TEEUP_LIST_MODES.ARCHIVED)
        return {
          ...state,
          archivedTeeups: state.archivedTeeups.map(teeup => {
            if (teeup.id === id) {
              return teeupToUpdate
            }
            return teeup
          }),
        }
      if (currentFolder === TEEUP_LIST_MODES.SKIPPED)
        return {
          ...state,
          removedTeeups: state.removedTeeups.map(teeup => {
            if (teeup.id === id) {
              return teeupToUpdate
            }
            return teeup
          }),
        }
      return { ...state }
    case actionTypes.TOGGLE_ORGANISER:
      const currentPerson =
        state.teeupPeople[action.payload.teeupId][action.payload.personId]
      if (currentPerson) {
        currentPerson.isOrganiser = !currentPerson.isOrganiser
      }

      return {
        ...state,
        teeupPeople: {
          ...state.teeupPeople,
          [action.payload.teeupId]: {
            ...state.teeupPeople[action.payload.teeupId],
            [action.payload.personId]: currentPerson,
          },
        },
      }
    case actionTypes.UPDATE_TEEUP_INFO:
      const { teeupid, teeup } = action.payload

      return {
        ...state,
        teeups: state.teeups.map(teeUp => {
          if (teeUp.id === teeupid) {
            return { ...teeUp, ...teeup }
          }

          return teeUp
        }),
      }

    case actionTypes.UPDATE_TEEUP:
      const { teeupId, ...rest } = action.payload

      return {
        ...state,
        teeups: state.teeups.map(teeup => {
          if (teeup.id === teeupId) {
            return {
              ...teeup,
              ...rest,
            }
          }

          return teeup
        }),
      }
    case actionTypes.CREATE_TEEUP:
      let createdTeeup = action.teeup
      if (!createdTeeup.userStatus) createdTeeup.userStatus = "joined"
      return {
        ...state,
        teeups: [...state.teeups, createdTeeup],
      }
    case actionTypes.TEEUP_UPDATE_ISARCHIVE_PROMPT_SHOW: {
      let { teeup } = action.payload
      let teeups = _.cloneDeep(state.teeups)
      teeups = teeups.map(i => (i.id === teeup.id ? teeup : i))

      return _.assign({}, state, { teeups })
    }
    case actionTypes.MOVE_TEEUP_TO_TRASH:
      const { teeup: teeupForTrash } = action

      const exists = state.removedTeeups.find(
        teeup => teeup.id === teeupForTrash.id
      )
      if (exists) return state

      return {
        ...state,
        teeups: state.teeups.filter(teeup => teeup.id !== teeupForTrash.id),
        archivedTeeups: state.archivedTeeups.filter(
          teeup => teeup.id !== teeupForTrash.id
        ),
        removedTeeups: state.removedTeeups.concat(teeupForTrash),
      }
    case actionTypes.MOVE_TEEUP_TO_ACTIVE:
      const teeupForActive = action.payload
      const existstingTeeup = state.teeups.find(
        teeup => teeup.id === teeupForActive.id
      )
      if (existstingTeeup) return state

      const updatedTeeup = {
        ...teeupForActive,
        isArchived: false,
        userStatus: teeupUserStatusKeys.joined,
      }

      return {
        ...state,
        teeups: state.teeups.concat(updatedTeeup),
        archivedTeeups: state.archivedTeeups.filter(
          teeup => teeup.id !== teeupForActive.id
        ),
        removedTeeups: state.removedTeeups.filter(
          teeup => teeup.id !== teeupForActive.id
        ),
      }
    case actionTypes.CONFIRM_REACTION:
    case actionTypes.RETRACT_REACTION:
    case actionTypes.ADD_REACTION: {
      const { reactionId, suggestionId, teeupId, userId, typeId } =
        action.payload

      const isRetraction = typeId === statusTypeIds.retract
      let teeupReactions = _.cloneDeep(state.teeupReactions)
      let teeupGameplans = _.cloneDeep(state.teeupGameplans)
      let suggestionsReactions = teeupReactions[teeupId]
      let suggestionReactions = suggestionsReactions
        ? teeupReactions[teeupId][suggestionId] || []
        : []

      let found = false
      for (let i = 0, len = suggestionReactions?.length; i < len; i++) {
        let reaction = suggestionReactions[i]
        const reactedByList = reaction.reactedBy
        let alreadyVotedIndex = reactedByList.indexOf(userId)
        if (alreadyVotedIndex !== -1) {
          if (reaction.reactionId === reactionId && !isRetraction && typeId) {
            found = true
            continue
          }

          reactedByList.splice(alreadyVotedIndex, 1)
          reaction.reactedBy = reactedByList
          suggestionReactions[i] = reaction
          if (reaction.reactionId === reactionId) {
            found = true
          }
        }

        if (
          !found &&
          reaction.reactionId === reactionId &&
          (!isRetraction || !typeId)
        ) {
          reaction.reactedBy = [...reactedByList, userId]
          suggestionReactions[i] = reaction

          found = true
        }
      }

      if (!found && (!isRetraction || !typeId)) {
        suggestionReactions.push({ reactionId, reactedBy: [userId] })
      }

      let teeupSuggestions = _.cloneDeep(state.teeupSuggestions)

      const suggestionCollectionIndex = Object.values(
        teeupSuggestions[teeupId]
      ).findIndex(s => {
        if (!s.time) {
          return false
        }
        const timeIndex = s.time.findIndex(t => t.id === suggestionId)
        if (timeIndex !== -1) {
          return true
        }
      })

      if (suggestionCollectionIndex !== -1) {
        const suggestionObjectIndex = Object.keys(teeupSuggestions[teeupId])[
          suggestionCollectionIndex
        ]
        const suggestionTimeIndex = teeupSuggestions[teeupId][
          suggestionObjectIndex
        ].time.findIndex(t => t.id === suggestionId)
        if (suggestionTimeIndex !== -1) {
          const newReactionsArray = []
          let isUpdated = false
          teeupSuggestions[teeupId][suggestionObjectIndex].time[
            suggestionTimeIndex
          ].reactions.forEach(r => {
            const tempReaction = {
              reactedBy:
                r.reactedBy.filter(user => {
                  if (user !== userId) {
                    return true
                  }
                  if (user === userId && r.reactionId === reactionId) {
                    isUpdated = true
                    return true
                  }
                  return false
                }) || [],
              reactionId: r.reactionId,
            }
            if (tempReaction.reactedBy.length > 0) {
              newReactionsArray.push(tempReaction)
            }
          })
          if (!isUpdated) {
            newReactionsArray.push({
              reactedBy: [userId],
              reactionId: reactionId,
            })
          }
          teeupSuggestions[teeupId][suggestionObjectIndex].time[
            suggestionTimeIndex
          ].reactions = newReactionsArray
        }
      }

      if (action.type === actionTypes.ADD_REACTION && teeupGameplans[teeupId]) {
        let suggestionIndex = -1
        let timeIndex = -1
        const gameplanIndex = teeupGameplans[teeupId].findIndex(g => {
          if (g.suggestions && g.suggestions.length > 0) {
            suggestionIndex = g.suggestions.findIndex(s => {
              if (s.time && s.time.length > 0) {
                timeIndex = s.time.findIndex(t => t.id === suggestionId)
                return timeIndex !== -1
              }
              return false
            })
            return suggestionIndex !== -1
          }
          return false
        })

        let gameplanReaction = []
        if (
          gameplanIndex !== -1 &&
          suggestionIndex !== -1 &&
          timeIndex !== -1
        ) {
          gameplanReaction =
            teeupGameplans[teeupId][gameplanIndex].suggestions[suggestionIndex]
              .time[timeIndex].reactions
        }

        let sKeys = suggestionsReactions
          ? Object.keys(suggestionsReactions)
          : []

        let emptyIndex = null
        sKeys.forEach((key, index) => {
          if (suggestionsReactions[key] === undefined) {
            delete suggestionsReactions[key]
            emptyIndex = index
          }
        })
        if (emptyIndex !== null) {
          delete sKeys[emptyIndex]
        }

        if (
          (sKeys.length > 0 && sKeys.includes(`${suggestionId}`)) ||
          gameplanReaction.length > 0
        ) {
          if (sKeys.length === 0 || !suggestionsReactions[suggestionId]) {
            suggestionsReactions[suggestionId] = gameplanReaction
          }
          const index = suggestionsReactions[suggestionId].findIndex(
            r => r.reactionId === reactionId
          )

          const oldReactionIndex = suggestionsReactions[suggestionId].findIndex(
            r => r.reactedBy.includes(userId)
          )
          if (oldReactionIndex !== -1) {
            const userReactions =
              suggestionsReactions[suggestionId][oldReactionIndex].reactedBy
            const userIndex = userReactions.indexOf(userId)
            if (userIndex !== -1)
              suggestionsReactions[suggestionId][
                oldReactionIndex
              ].reactedBy.splice(userIndex, 1)
          }

          if (index === -1) {
            suggestionsReactions[suggestionId].push({
              reactionId,
              reactedBy: [userId],
            })
          } else {
            if (
              !suggestionsReactions[suggestionId][index].reactedBy.includes(
                userId
              )
            ) {
              suggestionsReactions[suggestionId][index].reactedBy.push(userId)
            }
          }
        } else {
          suggestionsReactions = {}
          suggestionsReactions[suggestionId] = []
          suggestionsReactions[suggestionId].push({
            reactionId,
            reactedBy: [userId],
          })
        }
        teeupReactions[teeupId][suggestionId] =
          suggestionsReactions[suggestionId]

        if (
          gameplanIndex !== -1 &&
          suggestionIndex !== -1 &&
          timeIndex !== -1
        ) {
          teeupGameplans[teeupId][gameplanIndex].suggestions[
            suggestionIndex
          ].time[timeIndex].reactions = suggestionsReactions[suggestionId]
        }
      }

      return _.assign({}, state, {
        teeupReactions,
        teeupSuggestions,
        teeupGameplans,
      })
    }
    case actionTypes.UPDATE_GAMEPLAN:
      let teeupSuggestions = _.cloneDeep(state.teeupSuggestions)
      let teeupGameplans = _.cloneDeep(state.teeupGameplans)
      let teeupReactions = _.cloneDeep(state.teeupReactions)

      teeupSuggestions[action.payload.teeupId] =
        action.payload.gameplan?.suggestions
      teeupGameplans[action.payload.teeupId] =
        action.payload.gameplan?.gameplans
      teeupReactions[action.payload.teeupId] =
        action.payload.gameplan?.suggestionsReactions

      if (
        _.isEqual(
          teeupGameplans[action.payload.teeupId],
          state.teeupGameplans[action.payload.teeupId]
        )
      ) {
        return state
      }

      return _.assign({}, state, {
        teeupSuggestions,
        teeupGameplans,
        teeupReactions,
      })
    case actionTypes.TOGGLE_TEEUP_LIST:
      return {
        ...state,
        isTeeupListVisible: !state.isTeeupListVisible,
      }

    case actionTypes.SET_TEEUP_PEOPLE_AND_PARTICIPANTS:
      const { people, participants, locations, teeupIdentification } =
        action.payload

      if (
        _.isEqual(people, state.teeupPeople[teeupIdentification]) &&
        _.isEqual(participants, state.teeupParticipants[teeupIdentification])
      ) {
        return state
      }
      return {
        ...state,
        teeupPeople: { ...state.teeupPeople, [teeupIdentification]: people },
        teeupPeopleLocation: { ...state.teeupPeopleLocation, locations },
        teeupParticipants: {
          ...state.teeupParticipants,
          [teeupIdentification]: participants,
        },
      }

    default:
      return state
  }
}
