import { useState } from "react"

import { DateRange } from "@mui/x-date-pickers-pro"
import dayjs, { Dayjs } from "dayjs"

import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import { TextField } from "@components/ui/TextField"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"

import DateCalendar from "../../DateCalendar"

import "./index.scss"
import { useSnackbarStore } from "stores/snackbarStore"

const today = dayjs()

const CustomDateRange = () => {
  const isCustomDateRangeOpen = useSuggestionGeneralDateStore(
    state => state.isCustomDateRangeOpen
  )
  const setIsCustomDateRangeOpen = useSuggestionGeneralDateStore(
    state => state.setIsCustomDateRangeOpen
  )
  const updateCustomDateTrigger = useSuggestionGeneralDateStore(
    state => state.updateCustomDateTrigger
  )
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>()
  const [dateLabel, setDateLabel] = useState("")
  const [isFirstDatePickStep, setIsFirstDatePickStep] = useState(true)

  const handleClose = () => {
    setIsCustomDateRangeOpen(false)
  }

  const handleSave = () => {
    if (!dateRange || !dateLabel) return

    const customDateRange = JSON.parse(
      localStorage.getItem("customDateRange") || "[]"
    )
    customDateRange.push({
      startDate: dateRange[0]?.format(),
      endDate: dateRange[1]?.format(),
      label: dateLabel,
    })
    localStorage.setItem("customDateRange", JSON.stringify(customDateRange))
    setDateLabel("")
    setDateRange(undefined)
    updateCustomDateTrigger()
    handleClose()
  }

  const handleDateRangeChange = (date: Dayjs) => {
    if (date.isBefore(today)) {
      setSnackbarConfig({
        open: true,
        message: "You can't select a date that already passed.",
      })
      return
    }
    if (!dateRange?.[0]) {
      setDateRange([date, null])
      return
    } else if (!dateRange?.[1]) {
      setDateRange([dateRange[0], date])
      return
    }

    if (isFirstDatePickStep) {
      setDateRange([date, dateRange[1]])
      setIsFirstDatePickStep(false)
    } else {
      setDateRange([dateRange[0], date])
      setIsFirstDatePickStep(true)
    }
  }
  console.log("dateRange", dateRange)

  return (
    <DefaultModal open={isCustomDateRangeOpen} onClose={handleClose}>
      <div className="custom-date-range">
        <div>
          <h4>Custom Dates/Range</h4>
          <p>Create custom dates/ranges with labels.</p>
        </div>

        <div className="date-range-label">
          <h5>Add Dates/Range Label</h5>
          <TextField
            placeholder="e.g. After holidays"
            value={dateLabel}
            onChange={e => setDateLabel(e.target.value)}
          />
        </div>

        <div className="date-range-calendar">
          <h5>Select Custom Dates/Range</h5>
          <DateCalendar
            onChange={handleDateRangeChange}
            dateRange={dateRange}
          />
        </div>

        <Button
          variant="contained"
          disabled={!dateRange || !dateLabel}
          onClick={handleSave}>
          Save
        </Button>
      </div>
    </DefaultModal>
  )
}

export default CustomDateRange
