import React, { useEffect, useState } from "react"

import WheelPicker from "react-simple-wheel-picker"

import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"

import "./index.scss"
import { getHoursData } from "@utils/suggestionUtils"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { SuggestionDuration } from "types/suggestions"

const Duration = () => {
  const [hoursData, setHoursData] = useState<SuggestionDuration[]>([])
  const duration = useSuggestionDurationStore(state => state.duration)
  const [localDuration, setLocalDuration] = useState<SuggestionDuration>({
    ...duration,
  })
  const setDuration = useSuggestionDurationStore(state => state.setDuration)
  const isOpen = useSuggestionDurationStore(state => state.isOpen)
  const setIsOpen = useSuggestionDurationStore(state => state.setIsOpen)

  useEffect(() => {
    const data = getHoursData()
    setHoursData(data)
  }, [])

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const handleOnChange = target => {
    setLocalDuration(target)
  }

  const handleOnSave = () => {
    setIsOpen(false)
    setDuration(localDuration)
  }

  if (hoursData.length === 0) return false

  return (
    <DefaultModal open={isOpen} onClose={handleOnClose}>
      <div className="duration-modal-container">
        <div className="duration-modal-header">
          <h4>Set duration</h4>
          <p>Scroll the wheel to set the duration meeting</p>
        </div>

        <div className="modal-body-labels">
          <h5>Duration</h5>
          <p>{localDuration.value}</p>
        </div>

        <div className="wheel-picker-container">
          <div className="box-shadow-top"></div>
          <WheelPicker
            data={hoursData}
            onChange={handleOnChange}
            height={308}
            width={431}
            titleText="Enter value same as aria-label"
            itemHeight={36}
            selectedID={duration?.id}
            color="#737373"
            activeColor="#blue"
            backgroundColor="white"
            shadowColor="#fff"
          />
          <div className="box-shadow-bottom"></div>
        </div>

        <Button variant="contained" fullWidth onClick={handleOnSave}>
          Confirm
        </Button>
      </div>
    </DefaultModal>
  )
}

export default Duration
