import React, { useState } from "react"

import ReactTooltip from "react-tooltip"

import { images } from "@utils/imageUtils"

import "./index.scss"

const SortSuggestionsTooltip = ({
  SORTBY_LIKES,
  SORTBY_DATE,
  SORTBY_SOONEST,
  handleChangeSortByProp,
  selectedSort,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  return (
    <ReactTooltip
      id="sortSuggestions-tooltip"
      class="extraClass"
      offset={{ top: 5 }}
      backgroundColor="#ffffff"
      place="bottom"
      effect="solid"
      afterHide={() => setIsOpen(true)}
      delayHide={500}
      // delayShow={ 500 }
      getContent={id => {
        if (!id || !isOpen) {
          return null
        }
        return (
          <>
            <div className="teeup_tooltip_container_sort">
              <div
                className="tooltip_list_sort"
                onClick={() => handleChangeSortByProp(SORTBY_SOONEST)}>
                <span className="tooltip_list_sort__title_teeup">Soonest</span>
                {selectedSort === "Soonest" && (
                  <img
                    className="tooltip_list_sort_img"
                    src={images.iconSortSelected}
                    alt="sort"
                  />
                )}
              </div>
              <div
                className="tooltip_list_sort"
                onClick={() => handleChangeSortByProp(SORTBY_DATE)}>
                <span className="tooltip_list_sort__title_teeup">
                  Most Recent
                </span>
                {selectedSort === "Most Recent" && (
                  <img
                    className="tooltip_list_sort_img"
                    src={images.iconSortSelected}
                    alt="sort"
                  />
                )}
              </div>
              <div
                className="tooltip_list_sort"
                onClick={() => {
                  console.log("sortBy-likes")
                  handleChangeSortByProp(SORTBY_LIKES)
                }}>
                <span className="tooltip_list_sort__title_teeup">
                  Most Likes
                </span>
                {selectedSort === "Most Likes" && (
                  <img
                    className="tooltip_list_sort_img"
                    src={images.iconSortSelected}
                    alt="sort"
                  />
                )}
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default SortSuggestionsTooltip
