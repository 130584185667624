import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { debounce } from "lodash"
import moment from "moment-timezone"
import { useDebouncedCallback } from "use-debounce"
import useDraggableScroll from "use-draggable-scroll"

import { fetchCalendarEvents } from "@utils/calendarSyncUtils"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"
import { formatZones } from "@utils/timezones"

import {
  createSuggestion,
  promoteToGameplan,
} from "../../middlewares/backendMiddleware"

import { MOVING_TYPES } from "./constants"
import {
  getCurrentDateIndex,
  getEndDate,
  getDisabledDate,
  isSimilarSuggestion,
  setDefaultTimeZone,
} from "./helpers"
import {
  useInitDates,
  useInitScrollLeft,
  usePickerWidth,
  useLazyDatesLoading,
  useParsePeriod,
  useTimezoneDateLabel,
} from "./hooks"

export const TimeWheelContext = createContext({})

export const TimeWheelState = ({
  children,
  participants,
  people,
  optionId,
  isFromCreateTeeUp,
  userInfo,
  teeupId,
  currentSuggestions,
  isTimeZoneEnabled,
  initialGameplans,
  setInitialGameplans,
  closeCreate,
  onSave,
  handleClose,
  updateGamePlanWhen,
  closeActiveGamePlanTab,
}) => {
  const [openedInnerEvents, setOpenedInnerEvents] = useState(null)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isDisabledDate, setIsDisabledDate] = useState(false)
  const [isOnDatePick, setIsOnDatePick] = useState(false)
  const [currentPeriod, setCurrentPeriod] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [timeDuration, setTimeDuration] = useState(60)
  const dragScrollRef = useRef(null)
  const { onMouseDown } = useDraggableScroll(dragScrollRef)
  const [nowDate, setNowDate] = useState(null)
  const [parsedCurrentPeriod, setParsedCurrentPeriod] = useState([])
  const [movingType, setMovingType] = useState(MOVING_TYPES.forward)
  const [zones, setZones] = useState([])
  const [peopleWithUnknownTimeZones, setPeopleWithUnknownTimeZones] = useState(
    []
  )
  const timeoutRef = useRef(null)
  const isAlreadyCalled = useRef(false)

  useEffect(() => {
    fetchCalendarEvents({}).then()
  }, [])

  useEffect(() => {
    if (!dragScrollRef.current) return

    dragScrollRef.current.addEventListener("mousedown", event => {
      onMouseDown(event)
    })
  }, [dragScrollRef])

  const { pickerWidth, reinitPickerWidth } = usePickerWidth(timeDuration)

  useEffect(() => {
    const { zones, myTimeZone, peopleWithUnknownTimeZones } = formatZones(
      participants,
      people,
      userInfo,
      startDate,
      endDate
    )

    setDefaultTimeZone(moment, myTimeZone?.timezoneName)
    setZones(zones)
    setPeopleWithUnknownTimeZones(peopleWithUnknownTimeZones)
  }, [participants, people, userInfo, startDate, endDate])

  const { reinitScrollLeft } = useInitScrollLeft({
    startDate,
    nowDate,
    parsedCurrentPeriod,
    dragScrollRef,
    pickerWidth,
  })

  useParsePeriod({
    currentPeriod,
    parsedCurrentPeriod,
    setParsedCurrentPeriod,
    movingType,
    isOnDatePick,
    reinitScrollLeft,
    startDate,
    dragScrollRef,
    pickerWidth,
  })
  const { reinitDates } = useInitDates(setNowDate, setCurrentPeriod)

  useLazyDatesLoading({
    startDate,
    reinitDates,
    movingType,
    setMovingType,
    isScrolling,
  })

  const dateLabel = useTimezoneDateLabel({
    timezoneName: zones[0]?.isMyTZ ? null : zones[0]?.timezoneName,
    startDate,
    endDate,
  })

  const [scrollingDelay, setScrollingDelay] = useState()

  const onScroll = useDebouncedCallback(args => {
    if (isAlreadyCalled.current) return
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    if (args.scrollDirection === "forward") {
      setMovingType(MOVING_TYPES.forward)
    } else {
      setMovingType(MOVING_TYPES.back)
    }

    clearTimeout(scrollingDelay)
    setScrollingDelay(undefined)
    setIsScrolling(true)

    const scrollLeft = dragScrollRef.current?.scrollLeft
    const currentDateIndex = getCurrentDateIndex({
      scrollLeft,
      pickerWidth,
    })
    const currentDate = parsedCurrentPeriod[currentDateIndex]
    // if (
    //   getDisabledDate({ isOnScroll: true, hour: currentDate, nowDate }) &&
    //   !isDisabledDate &&
    //   !isAlreadyCalled.current
    // ) {
    //   isAlreadyCalled.current = true
    //   setIsDisabledDate(true)

    //   const timeoutId = setTimeout(() => {
    //     console.log("==========")
    //     console.log("ADJUSTING POSITION")
    //     console.log("==========")
    //     // setCurrentPeriod([])
    //     // setParsedCurrentPeriod([])
    //     reinitDates()
    //     // reinitScrollLeft({
    //     //   startDate,
    //     //   nowDate,
    //     //   parsedCurrentPeriod,
    //     //   dragScrollRef,
    //     //   pickerWidth,
    //     // })
    //     isAlreadyCalled.current = false
    //     setIsDisabledDate(false)
    //   }, 1000)
    //   timeoutRef.current = timeoutId
    // }

    setStartDate(currentDate)
    setEndDate(
      getEndDate({
        parsedCurrentPeriod,
        currentDateIndex,
        timeDuration,
      })
    )

    setScrollingDelay(
      setTimeout(() => {
        setIsScrolling(false)
      }, 500)
    )
  }, 300)

  const getFormattedSuggestion = () => ({
    value: null,
    value2: null,
    isCustomDate: false,
    isCustomTime: false,
    isFreestyleValues: false,
  })

  const onCreateTeeup = isSelected => {
    const suggestion = [
      {
        type: "when",
        startDate,
        endDate,
        userId: userInfo.id,
        teeupId,
        value: null,
        value2: null,
        isCustomDate: false,
        isCustomTime: false,
        isTimeZoneEnabled: false,
        selected: isSelected,
        decided: isSelected,
        optionId,
        index: 0,
      },
    ]

    if (suggestion.length === 0) return
    updateGamePlanWhen(suggestion)
    closeActiveGamePlanTab()
  }

  const onSuggestSubmit = isSelected => {
    if (isFromCreateTeeUp) {
      onCreateTeeup(isSelected)

      return
    }

    onSave()
    handleClose()

    let suggestion = {
      ...getFormattedSuggestion(),
      optionId,
    }
    const isSimilar = isSimilarSuggestion(currentSuggestions, startDate)

    if (isSimilar) {
      alert("Similar suggestion already exists")
    } else {
      const newSuggestion = {
        ...suggestion,
        type: GAMEPLAN_OPTIONS.when,
        startDate,
        endDate,
        userId: userInfo.id,
        teeupId,
        isCustomDate: false,
        isCustomTime: false,
        isTimeZoneEnabled,
        details: undefined,
        selected: isSelected,
        decided: isSelected,
      }
      if (optionId) {
        newSuggestion.optionId = optionId
      }

      createSuggestion(newSuggestion)

      const createdSuggestionId =
        Object.values(currentSuggestions)[
          Object.values(currentSuggestions).length - 1
        ]?.id + 1

      if (isSelected && !initialGameplans) {
        promoteToGameplan({
          suggestionId: createdSuggestionId,
          type: GAMEPLAN_OPTIONS.when,
        })
      }

      if (isSelected && initialGameplans) {
        setInitialGameplans([
          ...initialGameplans,
          { type: GAMEPLAN_OPTIONS.when },
        ])
      }

      closeCreate()
    }
  }

  const [datePickDelay, setDatePickDelay] = useState()
  const onDatePickerChange = newDate => {
    clearTimeout(datePickDelay)
    setDatePickDelay(undefined)
    setIsOnDatePick(true)
    const currentHours = moment().hours()
    const updatedNewDate = newDate.hours(currentHours).minutes(0)
    reinitDates(updatedNewDate.format())
    setStartDate(updatedNewDate.format())
    setEndDate(updatedNewDate.add(timeDuration, "minutes").format())

    setDatePickDelay(
      setTimeout(() => {
        setIsOnDatePick(false)
      }, 500)
    )
  }

  return (
    <TimeWheelContext.Provider
      value={{
        openedInnerEvents,
        setOpenedInnerEvents,
        isDisabledDate,
        dragScrollRef,
        timeDuration,
        setTimeDuration,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        parsedCurrentPeriod,
        pickerWidth,
        participants,
        people,
        zones,
        dateLabel,
        peopleWithUnknownTimeZones,
        movingType,
        reinitScrollLeft,
        reinitPickerWidth,
        onScroll,
        onSuggestSubmit,
        onDatePickerChange,
      }}>
      {children}
    </TimeWheelContext.Provider>
  )
}

export default TimeWheelState
