import { useCallback, useState } from "react"

import { TabContext, TabPanel } from "@mui/lab"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { updateGamePlanWhereSuggestions } from "@actions/createTeeupActions"
import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { Tab, TabList } from "@components/ui/Tabs"
import { useScreenWidth } from "@hooks/useScreenWidth"
import { selectActiveTeeupGameplans } from "@selectors/activeTeeup"
import { locationTypeIds } from "@utils/gamePlanUtils"
import { createSuggestions } from "middlewares/backendMiddleware"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"
import { useSuggestionOnlineStore } from "stores/suggestions/suggestionOnlineStore"
import {
  InPersonSuggestion,
  OnlineSuggestion,
  SuggestWhereTab,
  URL_TYPES,
} from "types/suggestions"

import InPerson from "./InPerson"
import Online from "./Online"

import "./index.scss"

const SuggestWhereModalNd = ({ isFromCreateTeeUp }) => {
  const gameplan = useSelector(selectActiveTeeupGameplans)
  const dispatch = useDispatch()
  const { isSmallSize } = useScreenWidth()
  const [currentTab, setCurrentTab] = useState(SuggestWhereTab.IN_PERSON)
  const isMapExpanded = useSuggestionInPersonStore(state => state.isMapExpanded)

  const isOpen = useSuggestionInPersonStore(state => state.isWhereModalOpen)
  const setIsOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )
  const clearInPersonState = useSuggestionInPersonStore(
    state => state.clearState
  )
  const clearOnlineState = useSuggestionOnlineStore(state => state.clearState)

  const onClose = () => {
    clearInPersonState()
    clearOnlineState()
    setIsOpen(false)
  }

  const onTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue as SuggestWhereTab)
  }

  const onSave = useCallback(
    (
      suggestions: OnlineSuggestion[] | InPersonSuggestion[],
      isDecided: boolean
    ) => {
      // @ts-ignore
      const isInPerson = suggestions?.[0]?.address
      const onlineDetailsLabels = {
        [URL_TYPES.URL]: "url",
        [URL_TYPES.LINK_LATER]: "Share link later",
        [URL_TYPES.SOMEONE_PROVIDE]: "Need someone to provide link",
      }

      const formattedSuggestions = suggestions.map(suggestion => {
        console.log("suggestion", suggestion)

        const onlineDetails =
          suggestion.urlType === URL_TYPES.URL
            ? suggestion?.urlAddress
            : onlineDetailsLabels[suggestion.urlType]

        const additionalInformation = encodeURIComponent(
          suggestion?.additionalInformation
        )
        console.log(
          "formattedSuggestions additionalInformation",
          additionalInformation
        )

        return {
          details: suggestion?.name || onlineDetails,
          value: suggestion?.address?.label || suggestion?.label,
          comment: additionalInformation,
          fullDetails: additionalInformation,
          isDecided,
          googlePlaceId: suggestion?.address?.place_id,
          latitude: suggestion?.address?.lat,
          longitude: suggestion?.address?.lng,
          type: "where",
          locationType: isInPerson
            ? locationTypeIds.realWorld
            : locationTypeIds.online,
          gameplanOptionId: gameplan[1].optionId,
        }
      })
      if (isFromCreateTeeUp) {
        dispatch(updateGamePlanWhereSuggestions(formattedSuggestions))
      } else {
        createSuggestions(formattedSuggestions)
      }

      onClose()
    },
    [gameplan]
  )

  return (
    <DefaultModal
      open={isOpen}
      onClose={onClose}
      responsiveBreakpoint={isSmallSize}>
      <Header
        onBackClick={onClose}
        title={isMapExpanded ? "Search Address" : "Suggest Where"}
        hideBorder
      />

      <div className="suggest-where-modal">
        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}>
            <TabList onChange={onTabChange} className="suggest-where-tabs">
              <Tab label="In person" value={SuggestWhereTab.IN_PERSON} />
              <Tab label="Online" value={SuggestWhereTab.ONLINE} />
            </TabList>
          </Box>

          <TabPanel
            value={SuggestWhereTab.IN_PERSON}
            className="suggest-where-tabs">
            <InPerson onSave={onSave} />
          </TabPanel>
          <TabPanel value={SuggestWhereTab.ONLINE}>
            <Online onSave={onSave} />
          </TabPanel>
        </TabContext>
      </div>
    </DefaultModal>
  )
}

export default SuggestWhereModalNd
