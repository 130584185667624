import React from "react"

import PropTypes from "prop-types"
import { TextInput, Text } from "react-native"

import { AppColors } from "@theme/"

import styles from "./styles"

const placeholderText = "email@address.com"

const PlaceholderComponent = ({ value, onPress }) => (
  <Text
    style={[styles.contentText, !value && { color: AppColors.brand.grey212 }]}
    onPress={onPress}>
    {value || placeholderText}
  </Text>
)

PlaceholderComponent.propTypes = {
  value: PropTypes.string,
  onPress: PropTypes.func,
}

class EmailInput extends React.Component {
  state = { isFocused: true }

  handleBlur = () => this.setState({ isFocused: false })

  handleFocus = () => this.setState({ isFocused: true })

  render() {
    const { value, index, handleTextChange } = this.props
    const { isFocused } = this.state

    if (!isFocused)
      return <PlaceholderComponent value={value} onPress={this.handleFocus} />
    return (
      <TextInput
        style={styles.contentText}
        keyboardType="email-address"
        autoCompleteType="off"
        selectionColor={AppColors.brand.pink}
        underlineColorAndroid={AppColors.brand.white100}
        // placeholder={placeholderText}
        // placeholderTextColor={AppColors.brand.grey212}
        value={value}
        onChangeText={handleTextChange(index)}
        autoCapitalize="none"
        autoCorrect={false}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        autoFocus={true}
      />
    )
  }
}

EmailInput.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  handleTextChange: PropTypes.func,
}

export default EmailInput
