import { useSelector } from "react-redux"

import {
  closeCreateTeeup,
  createTeeup,
  getContacts,
} from "@actions/createTeeupActions"
import { fetchRequests } from "@actions/requestActions"
import { fetchTeeups } from "@actions/teeupActions"
import { selectUserInfo, selectUserId } from "@selectors/user"

import {
  useCreateTeeUpDispatch,
  useCreateTeeUpState,
} from "./CreatePageContext"
import {
  SET_TEEUP_NAME,
  SET_TEEUP_IMAGE,
  SET_TEEUP_MESSAGE,
  SET_INVITEES,
  SET_IS_INVITEES_OPEN,
} from "./actionTypes"

export const useCreateTeeUp = () => {
  const dispatch = useCreateTeeUpDispatch()
  const { image, name, message, invitees, isInviteesOpen } =
    useCreateTeeUpState()
  const userInfo = useSelector(selectUserInfo)
  const userId = useSelector(selectUserId)

  const setImage = value => {
    dispatch({ type: SET_TEEUP_IMAGE, payload: value })
  }

  const setName = value => {
    dispatch({ type: SET_TEEUP_NAME, payload: value })
  }

  const setMessage = value => {
    dispatch({ type: SET_TEEUP_MESSAGE, payload: value })
  }

  const setInvitees = value => {
    dispatch({ type: SET_INVITEES, payload: value })
  }

  const setIsInviteesOpen = value => {
    dispatch({ type: SET_IS_INVITEES_OPEN, payload: value })
  }

  return {
    image,
    name,
    message,
    invitees,
    userInfo,
    isInviteesOpen,
    userId,
    closeCreateTeeup,
    createTeeup,
    getContacts,
    fetchTeeups,
    fetchRequests,
    setImage,
    setName,
    setMessage,
    setInvitees,
    setIsInviteesOpen,
  }
}
