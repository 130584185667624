import React, { useState, useRef, useEffect, memo } from "react"

import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import FileCard from "./FileCard"

const CAROUSEL_MOVE_STEP = 56

const FileCardCarousel = ({ children, parentWidth, files, removeFile }) => {
  const cardWrapper = useRef()
  const [left, setLeft] = useState(0)
  const [showRightArrow, setShowRightArrow] = useState(false)

  useEffect(() => {
    setShowRightArrow(cardWrapper?.current?.clientWidth + left > parentWidth)
  }, [parentWidth, left, files])

  const handleCarouselMove = direction => e => {
    const contentWidth = cardWrapper.current.clientWidth
    if (direction === "left") {
      setLeft(left > -CAROUSEL_MOVE_STEP ? 0 : left + CAROUSEL_MOVE_STEP)
    }

    if (direction === "right") {
      setLeft(
        parentWidth - left <= CAROUSEL_MOVE_STEP //  it is - left because left is negative number
          ? parentWidth - contentWidth
          : left - CAROUSEL_MOVE_STEP
      )
    }
  }

  return (
    <>
      {left !== 0 && (
        <div
          onClick={handleCarouselMove("left")}
          className="file-card-carousel__arrows left">
          <ReactSVG src={images.ndArrowLeftIcon} />
        </div>
      )}
      {showRightArrow && (
        <div
          onClick={handleCarouselMove("right")}
          className="file-card-carousel__arrows right">
          <ReactSVG src={images.ndArrowRightIcon} />
        </div>
      )}
      <div className="file-card-carousel" style={{ width: parentWidth }}>
        <div
          className="file-card-carousel__card-wrapper"
          ref={cardWrapper}
          style={{ left: `${left}px` }}>
          {files.map(file => (
            <FileCard
              key={`${file.name}-${file.size}`}
              file={file}
              removeFile={removeFile}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(FileCardCarousel)
