import CanceledIcon from "../../assets/images/canceled.svg"
import PendingIcon from "../../assets/images/pending.svg"
import RemovedIcon from "../../assets/images/removed.svg"
import WarningIcon from "../../assets/images/warning.svg"

export const TEEUP_PREVIEW_ERRORS = {
  CANCELED: "CANCELED",
  ENDED: "ENDED",
  NOT_FOUND: 404,
  REMOVED: 403,
}
export const MAX_NAME_LENGHT = 12
const { CANCELED, ENDED, NOT_FOUND, REMOVED } = TEEUP_PREVIEW_ERRORS
export const TEEUP_PREVIEW_ERRORS_INFO = {
  [CANCELED]: {
    icon: CanceledIcon,
    title: "Teeup Canceled!",
    message: "Sorry, this Teeup has been canceled",
  },
  [ENDED]: {
    icon: PendingIcon,
    title: "Teeup Ended!",
    message: "Sorry, this Teeup has already ended",
  },
  [NOT_FOUND]: {
    icon: WarningIcon,
    title: "Teeup not found!",
    message: "Sorry, it seems this teeup link does not work",
  },
  [REMOVED]: {
    icon: RemovedIcon,
    title: "You have been removed!",
  },
}

export const USERS_STATUSES = {
  INVITED: 1,
  GOING: 2,
  JOINED: 5,
}
