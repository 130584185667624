import React, { useContext } from "react"

import { TEEUP_SETTINGS_TYPES } from "@constants/teeups"
import { NewTeeupPageContext } from "contexts/NewTeeupPageContext"

import { Wrapper, ArrowIcon, Text } from "./headerStyles"

export const Header = () => {
  const { settingsType, setSettingsType, setIsSettings } =
    useContext(NewTeeupPageContext)

  let headerLogic
  switch (settingsType) {
    case TEEUP_SETTINGS_TYPES.SELECT_IMAGE:
      headerLogic = {
        text: "Select Image",
        action: () => setSettingsType(null),
      }
      break
    case TEEUP_SETTINGS_TYPES.PERMISSIONS:
      headerLogic = {
        text: "Participants Permissions",
        action: () => setSettingsType(null),
      }
      break
    case TEEUP_SETTINGS_TYPES.PARTICIPANTS:
      headerLogic = {
        text: "Manage participants",
        action: () => {
          setSettingsType(null)
          setIsSettings(false)
        },
      }
      break
    default:
      headerLogic = {
        text: "TeeUp Settings",
        action: () => setIsSettings(false),
      }
      break
  }

  return (
    <Wrapper onClick={headerLogic.action}>
      <ArrowIcon name="arrow-2" color="var(--gray6)" />

      <Text>{headerLogic.text}</Text>
    </Wrapper>
  )
}
