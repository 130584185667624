import React, { useState, useRef, useEffect, useMemo } from "react"

import cn from "classnames"
import _ from "lodash"
import moment from "moment-timezone"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import { View, Text, TouchableOpacity, Image } from "react-native"
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import {
  onIgnoreNudgePrompt,
  onPromptRespond,
} from "@actions/activeTeeupActions"
import { setActiveTab } from "@actions/commonActions"
import { apps } from "@constants/apps"
import {
  SuggestWhenModal,
  SuggestWhereModal,
} from "pages/TeeUpPage/TeeUpPageModals"

import { reJoinTooTeeUp } from "@middlewares/backendMiddleware"

import SugestionOrStatusNudgeResponse from "@components/teeupUserResponseDialogs/suggestionOrStatusNudgeResponse"
import { selectSuggestionToNudgeId } from "@selectors/common"
import {
  selectIgnoredPrompts,
  selectRespondedPrompts,
} from "@selectors/prompts"
import { selectUserId } from "@selectors/user"
import { Units } from "@theme"
import { images } from "@utils/imageUtils"

import arrowRight from "../../../assets/images/arrowRight-grey.png"
import threeDotsIcon from "../../../assets/images/three-dots.svg"

import ActionButtonsBar from "./ActionButtonsBar"
import TeeupItemHeaderTitle from "./Header"
import LeftItemsHeader from "./LeftItemsHeader"
import MyStatus from "./MyStatus"
import TeeupStatus from "./TeeupStatus"
import TeeupTooltip from "./TeeupTooltip"
import propsMapper from "./propsMapper"
import { styles } from "./styles"
import { renderFirstAvatars, TimeFromNow } from "./teeupItemComponents"

// TODO: here was "moment" if everything is okay - remove this comment

import "./index.scss"
import { conversationTypeIds } from "@config/mappings"

import { bindActionCreators } from "redux"

import { conversationTypes } from "@config/enums"
import { Subtitle } from "assets/styles/typographyStyles"

import { ButtonEl } from "../../common"
import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../../constants/button"

import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

const allowedConversationTypes = new Set([
  conversationTypes.message,
  conversationTypes.nudge,
  conversationTypes.reply,
])

const selectTeeupNudgesWithResponses = (teeupNudges, nudgesResponses) => {
  return teeupNudges.length === 0
    ? []
    : teeupNudges.map(nudge => {
        let nudgeAnswers = {}
        nudge.mentionIds.forEach(mentionId => {
          const userResponse = nudgesResponses.find(
            res => res.userId === mentionId && res.messageId === nudge.id
          )
          if (userResponse) {
            nudgeAnswers[mentionId] = userResponse
          }
        })
        return { ...nudge, nudgeAnswers }
      })
}

const selectNudgesResponses = (messages, nudges) => {
  if (messages.length === 0) return messages
  const nudgesResponses = _.filter(messages, message =>
    _.find(nudges, nudge => nudge.id === message.messageId)
  )
  const filtered = _.filter(
    nudgesResponses,
    (res, i, self) =>
      res.id ===
      _.find(
        self,
        b => b.userId === res.userId && res.messageId === b.messageId
      )?.id
  )
  return filtered
}

const selectTeeupNudges = (messages, userId) => {
  if (!messages?.length) return messages

  const teeupNudges = _.filter(
    messages,
    message =>
      message?.conversationTypeId === conversationTypeIds.nudge &&
      (userId === Number(message.senderId) ||
        userId === Number(message.userId) ||
        message.mentionIds.find(id => id === userId)) &&
      !message.messageId
  )

  return teeupNudges
}

const getNudgesWithResponses = (messages, userId) => {
  const nudges = selectTeeupNudges(messages, userId)
  const nudgesWithResponses = selectNudgesResponses(messages, nudges)
  return selectTeeupNudgesWithResponses(nudges, nudgesWithResponses)
}

const filterMessages = (messages, ignoredMessages, respondedMessages) =>
  messages?.filter?.(
    message =>
      allowedConversationTypes.has(message.conversationTypeId) &&
      !ignoredMessages.includes(message.id) &&
      !respondedMessages.includes(message.id)
  )

export const TeeupItem = ({
  ownerId,
  isOrganizer,
  ownerAvatar,
  ownerName,
  name,
  status,
  teeupStatusStyling,
  isGreyOverlay,
  userStatus,
  isArchivePromptShow,
  isInitiallyInvited,
  isDroppedOut,
  isSkippedDeclined,
  onArchivePressed,
  onMoveToActivePress,
  when,
  where,
  goingCount,
  isNewTitle,
  isNewWhen,
  isNewWhere,
  newMessages,
  onPress,
  photo = "",
  ignoreHorizontalMargin = false,
  fromTeeupsSection = false,
  testID,
  style,
  contentStyle,
  fromNowString,
  invitation,
  onDeclineInvitation,
  onAcceptInvitation,
  onKeepInTheListPress,
  people = {},
  showTimestampInHeader,
  isUndecidedWhenInGameplan,
  nudgeInfo,
  isSelected,
  teeup,
  isLast,
  mode,
  isComingUp,
  notificationsArr,
  preventRedirecting,
  preventHover,
  setInviteesContent,
  styleItem,
  fromContactBook,
  handleOnInviteesDone,
  loading,
  suggestionToNudgeId,
  typeToNudge,
  userInfo,
  userId,
  setActiveTab,
  handleIgnoreNudgePrompt,
  ignoredMessages,
  respondedMessages,
  onPromptRespond,
  openSuggestWhenModal,
  openSuggestWhereModal,
  isSuggestWhenModalOpen,
  isSuggestWhereModalOpen,
}) => {
  const [tooltip, setTooltip] = useState(false)
  const [isTeeUpHover, setIsTeeUpHover] = useState(false)
  const [isPromptMoved, setIsPromptMoved] = useState()
  const [isIgnored, setIsIgnored] = useState()
  const [isResponseDialogOpen, setIsResponseDialogOpen] = useState(false)
  const [nudgesWithoutResponses, setNudgesWithoutResponses] = useState([])
  const [hasMultipleNudges, setHasMultipleNudges] = useState(false)
  const [isNudgeResponseReceived, setIsNudgeResponseReceived] = useState(false)
  const [messages, setMessages] = useState([])
  const [mentionedMessages, setMentionedMessages] = useState([])
  const isNew = isNewTitle || isNewWhen || isNewWhere || newMessages > 0
  const ImageWrapper = View
  const history = useHistory()

  const [isUndecidedPromptShow, setUndecidedPrompt] = useState(
    isUndecidedWhenInGameplan
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWhen, setIsWhen] = useState(false)
  const [isWhere, setIsWhere] = useState(false)
  const [nudgeResponseDialogOpen, setNudgeResponseDialogOpen] = useState(false)
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )

  useEffect(() => {
    setMessages(filterMessages(messages, ignoredMessages, respondedMessages))
    // eslint-disable-next-line
  }, [ignoredMessages, respondedMessages])

  useEffect(() => {
    const nudges = getNudgesWithResponses(messages, userId)?.filter(nwr =>
      _.isEmpty(nwr.nudgeAnswers)
    )

    setNudgesWithoutResponses(nudges)

    setHasMultipleNudges(
      nudges?.filter(
        nwr => _.isEmpty(nwr.nudgeAnswers) && nwr.senderId !== userId
      ).length > 1
    )

    setMentionedMessages(
      messages?.filter?.(
        message =>
          message.body &&
          _.isArray(message.body) &&
          message.body.find(m => m.id === userId)
      )
    )
    const nudgesWithAnswers = getNudgesWithResponses(messages, userId)?.filter(
      nwr => !_.isEmpty(nwr.nudgeAnswers)
    )

    const senderNudges = nudgesWithAnswers.find(
      nudge => nudge.senderId === userId
    )

    setIsNudgeResponseReceived(
      senderNudges && !_.isEmpty(senderNudges.nudgeAnswers)
    )
  }, [messages, userId])

  // const {
  //   openSuggestWhenModal,
  //   openSuggestWhereModal,
  //   isSuggestWhenModalOpen,
  //   isSuggestWhereModalOpen,
  // } = useTeeUpPage()

  const openRespondDialog = nudgeType => () => {
    if (nudgeType === 10) {
      setIsModalOpen(true)
      setIsWhen(true)
      return
    }
    if (nudgeType === 11) {
      setIsModalOpen(true)
      setIsWhere(true)
      return
    }
    setNudgeResponseDialogOpen(true)
    // setSubtypeId(nudgeInfo.subTypeId)
    // setNudgeInfo(nudgeInfo)
  }

  const setIsModalStateClosed = () => {
    setIsModalOpen(false)
    setIsWhen(false)
    setIsWhere(false)
  }

  useEffect(() => {
    if (isModalOpen) {
      if (isWhen) {
        setIsWhenModalOpen(true)
        // openSuggestWhenModal()
        return
      }
      if (isWhere) {
        setIsWhereModalOpen(true)
        // openSuggestWhereModal()
        return
      }
    }
  }, [isModalOpen, isWhen, isWhere])

  useEffect(() => {
    if (!isSuggestWhenModalOpen && !isSuggestWhereModalOpen) {
      setIsModalStateClosed()
    }
  }, [isSuggestWhenModalOpen, isSuggestWhereModalOpen])

  const isSeen = notificationsArr?.includes(teeup.id)
  let when1 = ""
  // Sometimes indexes for gameplans are different (ex. when 1 and where 0)
  const selectedWhen = teeup.gameplans?.find(
    gameplan => gameplan.title === "When"
  )
  if (selectedWhen) {
    // eslint-disable-next-line no-unused-expressions
    selectedWhen.suggestions?.forEach(suggestion => {
      if (suggestion.isSelected) {
        when1 = suggestion.when.value
      }
    })
  }

  const whenFormatted = moment(when1).format("llll")

  const currentSelectedWhere = useMemo(() => {
    let whereIcon = null
    let whereTitle = null

    const selectedWhere = teeup.gameplans?.find(
      gameplan => gameplan.title === "Where"
    )
    if (selectedWhere) {
      // eslint-disable-next-line no-unused-expressions
      selectedWhere.suggestions?.forEach(suggestion => {
        if (
          suggestion.where &&
          suggestion.isSelected &&
          suggestion.where.value
        ) {
          whereTitle = suggestion.where.value
          const app = apps.find(el => el.title === suggestion.where.value)
          whereIcon = app?.icon || null
        }
      })
    }

    return {
      title: whereTitle,
      icon: whereIcon,
    }
  }, [teeup.gameplans])

  const [threeDotsTeeup, setThreeDotsTeeup] = useState(false)
  const teeupRef = useRef()
  const onRespondPress = () => {
    if (hasMultipleNudges) {
      history.push(`teeUps/${teeup.id}`)
      setTimeout(() => setActiveTab(3), 1)
      if (messages) {
        onPromptRespond(messages[0]?.id)
      }
    }
    if (
      nudgesWithoutResponses[0].subTypeId === 10 ||
      nudgesWithoutResponses[0].subTypeId === 11
    ) {
      // openRespondDialog(nudgeInfo.nudgeType)
      if (nudgesWithoutResponses[0].subTypeId === 10) {
        setIsModalOpen(true)
        setIsWhen(true)
        return
      }
      if (nudgesWithoutResponses[0].subTypeId === 11) {
        setIsModalOpen(true)
        setIsWhere(true)
        return
      }
      setNudgeResponseDialogOpen(true)
      // setSubtypeId(nudgeInfo.subTypeId)
      // setNudgeInfo(nudgeInfo)
    } else {
      setIsResponseDialogOpen(true)
    }
  }

  const onViewMentionsPress = () => {
    history.push(`teeUps/${teeup.id}`)
    onPromptRespond(messages[0]?.id)
  }

  const onViewNudgeResponse = () => {
    history.push(`teeUps/${teeup.id}`)
    setTimeout(() => setActiveTab(3), 1)
    onPromptRespond(messages[0]?.id)
  }

  const handleIgnorePress = () => {
    if (!messages || !messages[0]?.id) return
    handleIgnoreNudgePrompt(messages[0].id)
  }

  const renderActionsForSelectedTeeup = () => {
    // user status logic is broken
    // const { isArchived, userStatus } = teeup;
    // let isSkipped = userStatus === teeupUserStatusKeys.skipped
    return (
      <View style={styles.actionButtonContainer}>
        {(mode === "ARCHIVED" || mode === "SKIPPED") && (
          <TouchableOpacity
            style={styles.actionButton}
            onPress={onMoveToActivePress}>
            <Image style={styles.actionButtonImage} source={images.users} />
            <Text style={styles.actionButtonText}>Move to Active</Text>
          </TouchableOpacity>
        )}
        {mode !== "ARCHIVED" && (
          <TouchableOpacity
            style={styles.actionButton}
            onPress={onArchivePressed}>
            <Image
              style={styles.actionButtonImage}
              source={images.archiveOutline}
            />
            <Text style={styles.actionButtonText}>Archive</Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const isEnded = status === "ended"
  const isArchivedCancelled = teeup.isArchived && teeup.status === "cancelled"
  const isCancelled = teeup.status === "cancelled"
  const isKickedOut = teeup.userStatus === "kickedOut"
  let statusChangedAt = new Date(teeup.statusChangedAt)
  let now = new Date()
  let isOneDayAfterEnded =
    isEnded && now - statusChangedAt >= 3_600_000 * 24 ? true : false

  return (
    <>
      <div
        data-testid={teeup.name}
        // className={"teeups__container"}
        className={
          "teeups__container" +
          (isInitiallyInvited && !isEnded && !isArchivedCancelled
            ? " invited"
            : "")
        }
        style={{
          minHeight: isInitiallyInvited ? 132 : 130,
          borderRadius: Units.responsiveValue(8),
          marginBottom: isLast || isComingUp ? 0 : Units.responsiveValue(12),
          marginTop: isComingUp ? "5px" : "0",
          position: "relative",
          zIndex: 2,
        }}
        onMouseOver={() => {
          !preventHover && setThreeDotsTeeup(true)
          fromContactBook && setIsTeeUpHover(true)
        }}
        onMouseLeave={() => {
          // ReactTooltip.hide(teeupRef.current)
          !tooltip && setThreeDotsTeeup(false)
          fromContactBook && setIsTeeUpHover(false)
        }}>
        <Link
          // className="teeups__container"
          to={!isDroppedOut && !fromContactBook && `/teeUps/${teeup.id}`}
          onMouseDown={() => {
            if (window.event.which === 2) {
              onPress(teeup)
              !preventRedirecting &&
                setTimeout(
                  window.open(`/teeUps/${teeup.id}`, "_blank").focus(),
                  0
                )
            }
          }}
          onClick={async () => {
            if (isDroppedOut) {
              return
            }

            !fromContactBook && onPress(teeup)
            !preventRedirecting &&
              setTimeout(() => {
                history.push(`/teeUps/${teeup.id}`)
              }, 1000)
          }}>
          {/* {isNew && <View style={styles.newSideBar} />} */}

          {/* <TeeupItemHeaderTitle
              showAvatar={isInitiallyInvited || isOrganizer}
              isOrganizer={isOrganizer}
              ownerAvatar={ownerAvatar}
              ownerName={ownerName}
              isNew={isNew}
              fromNowString={fromNowString}
              isGreyOverlay={isGreyOverlay}
            /> */}
          <View
            style={[
              styles.contentContainer,
              ignoreHorizontalMargin ? styles.noMargin : null,
              fromTeeupsSection ? styles.withBorders : null,
              contentStyle,
              // isInitiallyInvited && styles.contentContainerOrange,
            ]}>
            <View
              style={[
                styles.leftPartContainer,
                (isEnded ||
                  isArchivedCancelled ||
                  isCancelled ||
                  isKickedOut) &&
                  styles.grayout,
              ]}>
              {isInitiallyInvited ? (
                <View style={styles.leftContainer}>
                  <LeftItemsHeader
                    showAvatar={
                      !(
                        isDroppedOut ||
                        isSkippedDeclined ||
                        isArchivePromptShow ||
                        (isOrganizer && isUndecidedWhenInGameplan)
                      )
                    }
                    invitation={invitation}
                    isInvited={isInitiallyInvited}
                    ownerAvatar={ownerAvatar}
                    ownerName={ownerName}
                    isNew={isNew}
                    status={status}
                    isEnded={isEnded}
                    isDroppedOut={isDroppedOut}
                    isDeclined={isSkippedDeclined}
                    isArchivePromptShow={isArchivePromptShow}
                    isGreyOverlay={isGreyOverlay}
                    nudgeInfo={nudgeInfo}
                    isUndecidedWhenInGameplan={
                      isUndecidedPromptShow && isUndecidedWhenInGameplan
                    }
                  />
                  <View style={styles.noImageBg}></View>
                </View>
              ) : (
                <View
                  style={[
                    styles.thumbnailImage,
                    { filter: isDroppedOut && "grayscale(100%)" },
                  ]}>
                  <Image style={styles.flex} source={{ uri: photo }} />
                  {isOrganizer && (
                    <View style={styles.organizerContainer}>
                      <Image
                        style={styles.organizerIcon}
                        source={images.crown}
                      />
                    </View>
                  )}
                </View>
              )}
            </View>

            {isSeen && !isInitiallyInvited && <View style={styles.sideBar} />}
            <View
              style={[
                styles.content,
                (isEnded ||
                  isArchivedCancelled ||
                  isCancelled ||
                  isKickedOut) && {
                  backgroundColor: "#F5F8FA",
                  border: "solid 1px #e3e5e8",
                  borderBottomRightRadius: 8,
                  borderTopRightRadius: 8,
                },
                isInitiallyInvited && {
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  borderBottomLeftRadius: 8,
                  borderTopLeftRadius: 8,
                },
                !isInitiallyInvited && {
                  border: "solid 1px #e3e5e8",
                  borderBottomRightRadius: 8,
                  borderTopRightRadius: 8,
                },
                isDroppedOut && {
                  backgroundColor: "#e3e5e8",
                  borderBottomRightRadius: 8,
                  borderTopRightRadius: 8,
                },
              ]}>
              {/* backgroundColor: '#f5f5f5' */}
              <View>
                <View style={styles.header}>
                  <View style={styles.row}>
                    <TeeupStatus
                      status={status}
                      testID={`${testID}-${status}`}
                      teeupStatusStyling={teeupStatusStyling}
                    />
                    {!!userStatus && <MyStatus userStatus={userStatus} />}
                  </View>
                  {!showTimestampInHeader && (
                    <TimeFromNow
                      teeup={teeup}
                      notificationsArr={notificationsArr}
                      isNew={isNew}
                      fromNowString={fromNowString}
                    />
                  )}
                </View>
                <View style={[styles.row, styles.nameMargin]}>
                  <Text
                    testID="teeupName"
                    style={[
                      styles.nameText,
                      isNewTitle ? styles.hasUpdateText : {},
                      (teeupStatusStyling === "droppedout" ||
                        teeupStatusStyling === "ended" ||
                        isArchivedCancelled) &&
                        styles.nameInactiveText,
                    ]}>
                    {name.slice(0, 64)}
                  </Text>
                </View>
                {isTeeUpHover ? (
                  <div
                    className={cn("teeup-item", {
                      "teeup-item--loading": loading,
                    })}
                    onClick={() => onPress(teeup)}>
                    {loading ? (
                      <div className="teeup-item__loader"></div>
                    ) : (
                      <img
                        src={images.ndInviteToTeeUp}
                        style={{ marginRight: 6 }}
                      />
                    )}
                    Invite to this TeeUp
                  </div>
                ) : (
                  <>
                    {!!whenFormatted && (
                      <View style={styles.row}>
                        <Text
                          numberOfLines={1}
                          ellipsizeMode="tail"
                          style={[
                            styles.mediumText,
                            isNewWhen ? styles.hasUpdateText : {},
                            {
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "200px",
                            },
                          ]}>
                          {whenFormatted === "Invalid date" || !whenFormatted
                            ? ""
                            : whenFormatted}
                        </Text>
                      </View>
                    )}
                    {(currentSelectedWhere.title ||
                      currentSelectedWhere.icon) && (
                      <View style={styles.row}>
                        {currentSelectedWhere.icon && (
                          <Image
                            source={currentSelectedWhere.icon}
                            style={styles.wherePreviewIcon}
                          />
                        )}
                        <Text
                          numberOfLines={1}
                          ellipsizeMode="tail"
                          style={[
                            styles.mediumText,
                            isNewWhere ? styles.hasUpdateText : {},
                            {
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              width: "200px",
                            },
                          ]}>
                          {currentSelectedWhere.title || "No Where"}
                        </Text>
                      </View>
                    )}
                  </>
                )}
              </View>
              {!isTeeUpHover && !isInitiallyInvited && (
                <View
                  style={[
                    styles.usersLine,
                    { filter: isDroppedOut && "grayscale(100%)" },
                  ]}>
                  {renderFirstAvatars(
                    people,
                    ownerId,
                    ImageWrapper,
                    isEnded || isArchivedCancelled
                  )}
                  <Image source={arrowRight} style={styles.arrowIcon} />
                  <Text style={styles.countText}>{goingCount}</Text>
                </View>
              )}
              {(!teeup.isArchived ||
                (teeup.isArchived && !isEnded && !isCancelled)) && (
                <TeeupItemHeaderTitle
                  // isRespondedNudge={isRespondedNudge}
                  teeup={teeup}
                  isNudgeResponseReceived={isNudgeResponseReceived}
                  hasMultipleNudges={hasMultipleNudges}
                  messages={messages}
                  isArchived={teeup.isArchived}
                  showAvatar={
                    !(
                      isDroppedOut ||
                      isSkippedDeclined ||
                      isArchivePromptShow ||
                      (isOrganizer && isUndecidedWhenInGameplan)
                    )
                  }
                  isOneDayAfterEnded={isOneDayAfterEnded}
                  invitation={invitation}
                  isInvited={isInitiallyInvited}
                  ownerAvatar={ownerAvatar}
                  ownerName={ownerName}
                  isNew={isNew}
                  status={status}
                  isEnded={isEnded}
                  isCancelled={isCancelled}
                  isDroppedOut={isDroppedOut}
                  isKickedOut={isKickedOut}
                  isDeclined={isSkippedDeclined}
                  isArchivePromptShow={isArchivePromptShow}
                  isGreyOverlay={isGreyOverlay}
                  nudgeInfo={nudgeInfo}
                  isUndecidedWhenInGameplan={
                    isUndecidedPromptShow && isUndecidedWhenInGameplan
                  }
                  isPromptMoved={isPromptMoved}
                  setIsPromptMoved={setIsPromptMoved}
                  isIgnored={isIgnored}
                  setIsIgnored={setIsIgnored}
                  nudgesWithoutResponses={nudgesWithoutResponses}
                  mentionedMessages={mentionedMessages}>
                  <ActionButtonsBar
                    userId={userId}
                    teeupId={teeup.id}
                    isMentioned={mentionedMessages?.length > 0}
                    hasMultipleNudges={hasMultipleNudges}
                    isPromptMoved={isPromptMoved}
                    setIsPromptMoved={setIsPromptMoved}
                    isInvited={isInitiallyInvited}
                    isDroppedOut={isDroppedOut}
                    isKickedOut={isKickedOut}
                    isSkippedDeclined={isSkippedDeclined}
                    isArchivePromptShow={teeup.isArchivePromptShow}
                    isOneDayAfterEnded={isOneDayAfterEnded}
                    onArchivePressed={onArchivePressed}
                    onDeclineInvitation={onDeclineInvitation}
                    onKeepInTheListPress={onKeepInTheListPress}
                    onAcceptInvitation={onAcceptInvitation}
                    onRespondPress={onRespondPress}
                    onViewMentionsPress={onViewMentionsPress}
                    isGreyOverlay={isGreyOverlay}
                    isEnded={isEnded}
                    onMoveToActivePress={onMoveToActivePress}
                    isArchived={teeup.isArchived}
                    isCancelled={isCancelled}
                    nudgeInfo={nudgeInfo}
                    isIgnored={isIgnored}
                    setIsIgnored={setIsIgnored}
                    isNudgeResponseReceived={isNudgeResponseReceived}
                    onViewNudgeResponse={onViewNudgeResponse}
                    onIgnorePress={handleIgnorePress}
                    nudgesWithoutResponses={nudgesWithoutResponses}
                  />
                </TeeupItemHeaderTitle>
              )}
              {isDroppedOut && (
                <div className="reJoinWrapper">
                  <div className="textReJoinWrapper">
                    <Subtitle>
                      You dropped out of this TeeUp, rejoin it now?
                    </Subtitle>
                  </div>
                  <div className="buttonReJoinWrapper">
                    <ButtonEl
                      size={BUTTON_SIZES.MEDIUM}
                      variant={BUTTON_VARIANTS.ROUNDED}
                      text="Join"
                      onClick={() => reJoinTooTeeUp(teeup.id)}
                    />
                  </div>
                </div>
              )}
            </View>
          </View>
        </Link>
        {!fromContactBook && threeDotsTeeup && teeup && (
          <img
            src={threeDotsIcon}
            ref={teeupRef}
            data-tip={teeup.id}
            data-for="teeup-tooltip"
            data-testid="teeupItemDropdownDots"
            className="three-dots-teeup"
            onMouseDown={e => {
              e.stopPropagation()
              ReactTooltip.show(teeupRef.current)
              setTooltip(true)
            }}
            onMouseUp={e => {
              e.stopPropagation()
              ReactTooltip.show(teeupRef.current)
              setTooltip(true)
            }}
            onClick={e => {
              e.stopPropagation()
              ReactTooltip.show(teeupRef.current)
              // setTooltip(true)
            }}
            alt="dots"
          />
        )}
      </div>
      {nudgesWithoutResponses?.length > 0 && isResponseDialogOpen && (
        <Modal
          isOpen={isResponseDialogOpen}
          style={modalStyles}
          ariaHideApp={false}>
          <SugestionOrStatusNudgeResponse
            teeup={teeup}
            nudgeInfo={nudgesWithoutResponses[0]}
            subTypeId={nudgesWithoutResponses[0].subTypeId}
            close={() => setIsResponseDialogOpen(false)}
            onSave={() => messages[0]?.id && onPromptRespond(messages[0]?.id)}
          />
        </Modal>
      )}
      {isSuggestWhenModalOpen && (
        <SuggestWhenModal
          onSave={() => messages[0]?.id && onPromptRespond(messages[0]?.id)}
        />
      )}
      {isSuggestWhereModalOpen && (
        <SuggestWhereModal
          onSave={() => messages[0]?.id && onPromptRespond(messages[0]?.id)}
        />
      )}
      {tooltip &&
        ReactDOM.createPortal(
          <TeeupTooltip
            isInvited={isInitiallyInvited}
            onArchivePressed={onArchivePressed}
            mode={mode}
            onMoveToActivePress={onMoveToActivePress}
            onClose={() => {
              setTooltip(false)
              ReactTooltip.hide(teeupRef.current)
              setThreeDotsTeeup(false)
            }}
            setThreeDotsTeeup={setThreeDotsTeeup}
            setInviteesContent={setInviteesContent}
            teeup={teeup}
          />,

          document.querySelector("#modal-root")
        )}
    </>
  )
}

const modalStyles = {
  content: {
    backgroundColor: "#F5F8FA",
    width: 320,
    height: 391,
    border: "1px solid #F3F3F3",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}

TeeupItem.propTypes = {
  // In teeup object
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  teeupStatusStyling: PropTypes.string.isRequired,
  isGreyOverlay: PropTypes.bool,
  // userStatus: PropTypes.string,
  isOrganizer: PropTypes.bool,
  // Calculated from teeupGameplans
  when: PropTypes.string,
  where: PropTypes.string,
  // Calculated from teeupPeople
  goingCount: PropTypes.number.isRequired,
  fromNowString: PropTypes.string.isRequired,
  isArchivePromptShow: PropTypes.bool.isRequired,
  onArchivePressed: PropTypes.func,
  invitation: PropTypes.object,
  ownerId: PropTypes.number,
  ownerAvatar: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  ownerName: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string.isRequired,
  ]),
  people: PropTypes.shape({}).isRequired,
  isNewTitle: PropTypes.bool,
  isNewWhen: PropTypes.bool,
  isNewWhere: PropTypes.bool,
  newMessages: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),

  nudgeInfo: PropTypes.object,

  onPress: PropTypes.func.isRequired,
  photo: PropTypes.string,

  ignoreHorizontalMargin: PropTypes.bool,
  fromTeeupsSection: PropTypes.bool,
  testID: PropTypes.string,
  handleIgnoreNudgePrompt: PropTypes.func,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  onDeclineInvitation: PropTypes.func,
  onAcceptInvitation: PropTypes.func,
  onKeepInTheListPress: PropTypes.func,
  isInitiallyInvited: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isSkippedDeclined: PropTypes.bool,
  showTimestampInHeader: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
  ignoredMessages: PropTypes.arrayOf(PropTypes.number),
  respondedMessages: PropTypes.arrayOf(PropTypes.number),
}

const mapDispatchToProps = dispatch => ({
  setActiveTab: bindActionCreators(setActiveTab, dispatch),
  handleIgnoreNudgePrompt: bindActionCreators(onIgnoreNudgePrompt, dispatch),
  onPromptRespond: bindActionCreators(onPromptRespond, dispatch),
})

const mapStateToProps = state => {
  return {
    suggestionToNudgeId: selectSuggestionToNudgeId(state),
    userId: selectUserId(state),
    ignoredMessages: selectIgnoredPrompts(state),
    respondedMessages: selectRespondedPrompts(state),
  }
}
export default connect(propsMapper)(
  connect(mapStateToProps, mapDispatchToProps)(TeeupItem)
)
