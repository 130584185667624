import React from "react"

import PropTypes from "prop-types"

import { CustomEvent } from "../../CustomCalendarView/molecules"

import { Wrapper, Title, EventWrapper } from "./conflictedCalendarEventStyles"

export const ConflictedCalendarEvent = ({ event }) => (
  <Wrapper>
    <div className="text-sm font-bold text-neutral-700">Calendar Conflict:</div>

    <EventWrapper>
      <CustomEvent event={event} />
    </EventWrapper>
  </Wrapper>
)

ConflictedCalendarEvent.propTypes = {
  event: PropTypes.object,
}
