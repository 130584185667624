import React, { useContext, useEffect, useState } from "react"

import PropTypes from "prop-types"

import { SEARCH_TYPES } from "@constants/search"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { contactTypes } from "@utils/contactUtils"
import { isContainsOnlyNumbers } from "@utils/isContainsOnlyNumbers"

import { TrySwitchType, InviteNewContact } from "../atoms"
import { FoundContact } from "../molecules"

import { Wrapper, List, ButtonsWrapper } from "./searchResultsStyles"

export const SearchResults = ({ isTrySwitch, onSwitchType, searchResults }) => {
  const { searchValue } = useContext(InvitePeopleContext)
  const [newInviteContact, setNewInviteContact] = useState(null)

  useEffect(() => {
    if (searchResults.length === 0) {
      setNewInviteContact({
        matchingContact: searchValue,
        type: isContainsOnlyNumbers(searchValue)
          ? contactTypes.PHONE
          : contactTypes.EMAIL,
      })
    }
  }, [searchResults, searchValue])

  return (
    <Wrapper>
      <List>
        {searchResults.map((contact, contactIndex) => (
          <FoundContact
            contact={contact}
            key={`${contact.id}-${contactIndex}`}
          />
        ))}
      </List>

      {isTrySwitch && (
        <ButtonsWrapper>
          <TrySwitchType onClick={() => onSwitchType(SEARCH_TYPES.PHONE)} />
        </ButtonsWrapper>
      )}

      {searchResults.length === 0 && newInviteContact && (
        <ButtonsWrapper>
          <InviteNewContact
            newInviteContact={newInviteContact}
            setNewInviteContact={setNewInviteContact}
          />
        </ButtonsWrapper>
      )}
    </Wrapper>
  )
}

SearchResults.propTypes = {
  isTrySwitch: PropTypes.bool.isRequired,
  onSwitchType: PropTypes.func.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
}
