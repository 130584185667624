import React from "react"

import PropTypes from "prop-types"

import ClosedEyeIcon from "../../../../assets/images/password-eye-closed.svg"
import EyeIcon from "../../../../assets/images/password-eye.svg"

export const PasswordToggler = ({ isShownPassword, onToggle }) => (
  <>
    {isShownPassword ? (
      <img
        className="input-el__password-eye"
        src={ClosedEyeIcon}
        alt="hide-password"
        onClick={onToggle}
      />
    ) : (
      <img
        className="input-el__password-eye"
        src={EyeIcon}
        alt="show-password"
        onClick={onToggle}
      />
    )}
  </>
)

PasswordToggler.propTypes = {
  onToggle: PropTypes.func,
}
