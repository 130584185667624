import dayjs from "dayjs"
import union from "lodash/union"
import { createSelector } from "reselect"

import { teeupStatusKeys } from "@configs/enums"
import { selectActiveTeeups, selectArchivedTeeups } from "@selectors/teeups"
import { AppColors } from "@theme"
import {
  getEventsLayoutForDay,
  getTeeupsAndEventsArray,
  hasConflict,
  EVENT_TYPES,
  getEventWithTime,
  getEndDate,
  CALENDAR_TYPES,
  ignoreExternalEventDuplicate,
  ignoreExternalDuplicateByPeriod,
} from "@utils/calendarUtils"
import { getDateWithTimezone, compareTimeStrings } from "@utils/dateUtils"

import CalendarState from "../reducers/calendarStorage"

// import Calendar from "@components"

const selectCalendar = state => state.calendar

const selectCalendarSyncSettings = state =>
  state.user.settings.calendarSync || {}

export const selectCalendarEventsByDay = createSelector(
  selectCalendar,
  () => CalendarState.calendarEventsByDay || {}
)

export const selectTeeupEventsByDay = createSelector(
  selectCalendar,
  () => CalendarState.teeupEventsByDay || {}
)

export const selectCalendarEvents = createSelector(
  selectCalendar,
  () => CalendarState.calendarEvents || {}
)

export const selectTeeupEvents = createSelector(
  selectCalendar,
  () => CalendarState.teeupEvents || {}
)

export const selectCalendarDays = createSelector(
  selectCalendar,
  () => CalendarState.calendarDays || []
)

export const selectBeforeCalendarDate = createSelector(
  selectCalendar,
  () => CalendarState.isBeforeCalendarDate
)

export const selectIsCalendarSynchronized = createSelector(
  selectCalendar,
  calendar => calendar.isCalendarSynchronized
)

export const selectDisplayCalendar = createSelector(
  selectCalendar,
  calendar => calendar.calendarDisplay
)

export const selectDisplayCalendarHash = createSelector(
  selectCalendar,
  calendar => calendar.calendarDisplayHash
)

export const selectUpdatedValue = createSelector(
  selectCalendar,
  calendar => calendar.updaterValue
)

export const selectCalendarSyncInProgress = createSelector(
  selectCalendar,
  calendar => calendar.calSyncInProgress
)

export const selectTeeupMarkedDates = createSelector(
  selectActiveTeeups,
  selectArchivedTeeups,
  selectTeeupEventsByDay,
  (teeups, archivedTeeups, teeupEventsByDay) => {
    const planning = {
      key: "planning",
      color: AppColors.brand.orange,
    }
    const allset = {
      key: "allset",
      color: AppColors.brand.green,
    }
    const happening = {
      key: "happening",
      color: AppColors.brand.blue,
    }
    let dateObject = {}
    const teeupsArray = [...teeups, ...archivedTeeups]
    Object.keys(teeupEventsByDay).forEach(date => {
      const statuses = []
      const teeupsByDate = teeupEventsByDay[date]
      teeupsByDate.forEach(teeup => {
        const itemTeeup = teeupsArray.find(
          singleTeeup => singleTeeup.id === teeup
        )
        if (itemTeeup) {
          switch (itemTeeup.status) {
            case teeupStatusKeys.planning: {
              statuses.push(planning)

              break
            }
            case teeupStatusKeys.allset: {
              statuses.push(allset)

              break
            }
            case teeupStatusKeys.happening: {
              statuses.push(happening)

              break
            }
            // No default
          }
        }
      })

      const formattedDate = getDateWithTimezone(date)
      if (formattedDate.isValid()) {
        const date = formattedDate.format("YYYY-MM-DD")

        dateObject[date] = { dots: union(statuses) }
      }
    })
    return dateObject
  }
)

export const selectCalendarByTimeInterval = (startDateStr, endDateStr) =>
  createSelector(
    selectCalendarDays,
    selectCalendarEventsByDay,
    selectTeeupEventsByDay,
    selectCalendarEvents,
    selectTeeupEvents,
    (
      calendarDaysList,
      calendarEventsByDay,
      teeupEventsByDay,
      calendarEvents,
      teeupEvents
    ) => {
      let calendar = {}
      try {
        for (const day of calendarDaysList) {
          if (getDateWithTimezone(day).isAfter(endDateStr)) {
            break
          }
          if (getDateWithTimezone(day).isSameOrAfter(startDateStr)) {
            calendar[dayjs(day).format("YYYY-MM-DD")] = getTeeupsAndEventsArray(
              teeupEventsByDay[day],
              calendarEventsByDay[day],
              teeupEvents,
              calendarEvents
            )
          }
        }
        return calendar
      } catch (error) {
        console.error(error)
      }
    }
  )

export const hasConflictingEvents = (
  startDate,
  endDate,
  suggestionId,
  teeupId,
  optionId
) =>
  createSelector(selectCalendarForDay(startDate), conflictingEvents => {
    if (!endDate) {
      endDate = getEndDate({ startDate })
    }
    let res = {
      hasConflict: false,
      events: [],
    }

    if (!conflictingEvents || conflictingEvents.length === 0) {
      return res
    } else {
      // Look for overlapping events
      const event1 = {
        id: teeupId,
        startDate,
        endDate,
        suggestionId,
        optionId,
        type: EVENT_TYPES.teeup,
      }
      for (let i = 0, len = conflictingEvents.length; i < len; i++) {
        const event2 = getEventWithTime(conflictingEvents[i])
        if (
          event2.type === EVENT_TYPES.teeup &&
          event1.id === event2.id &&
          event1.optionId === event2.optionId
        ) {
          break
        }

        const conflict = hasConflict(event1, event2)
        if (conflict) {
          res.hasConflict = true
          res.events.push(`${event2.type}-${event2.id}`)
        }
      }
      return res
    }
  })

export const selectCalendarForDay = (date, hideOptions) =>
  createSelector(
    selectCalendarEventsByDay,
    selectTeeupEventsByDay,
    selectCalendarEvents,
    selectTeeupEvents,
    (calendarEventsByDay, teeupEventsByDay, calendarEvents, teeupEvents) => {
      const dayString = getDateWithTimezone(date)
        .startOf("day")
        .format("YYYY-MM-DD")

      const calendar = getTeeupsAndEventsArray(
        teeupEventsByDay[dayString],
        calendarEventsByDay[dayString],
        teeupEvents,
        calendarEvents,
        hideOptions
      )

      return ignoreExternalEventDuplicate(calendar)
    }
  )

export const selectCalendarForWeek = state => date => {
  const startDate = getDateWithTimezone(date).startOf("week")
  const endDate = getDateWithTimezone(date).endOf("week")
  const weekEvents = selectCalendarByTimeInterval(startDate, endDate)(state)

  return ignoreExternalDuplicateByPeriod(weekEvents)
}

export const selectCalendarForMonth = state => date => {
  const userTimezone = state.user.userInfo.timezone
  const startDate = getDateWithTimezone(date, userTimezone).startOf("month")
  const endDate = getDateWithTimezone(date, userTimezone).endOf("month")
  const monthEvents = selectCalendarByTimeInterval(
    startDate,
    endDate,
    userTimezone
  )(state)

  return ignoreExternalDuplicateByPeriod(monthEvents)
}

export const selectLatestTeeupEventDate = createSelector(
  selectTeeupEventsByDay,
  teeupEvents => {
    let latestTimeString = getDateWithTimezone().startOf("day").format()
    Object.keys(teeupEvents).map(eventTime => {
      if (compareTimeStrings(latestTimeString, eventTime)) {
        latestTimeString = eventTime
      }
    })
    return latestTimeString
  }
)

export const calendarConflictsForTimeWheel = (
  calendarStart,
  calendarEnd,
  maxColumnsCount
) =>
  createSelector(
    selectCalendarByTimeInterval(
      calendarStart.toISOString(),
      calendarEnd.toISOString()
    ),
    conflictingEvents => {
      const events = Object.values(conflictingEvents).flat()
      const { columnsAmount, mappedEventsList } = getEventsLayoutForDay(
        events,
        maxColumnsCount
      )
      if (
        !columnsAmount ||
        !mappedEventsList ||
        mappedEventsList.length === 0
      ) {
        return {
          useConflicts: false,
          mappedEventsList: [],
          columnsAmount: 0,
        }
      }

      return {
        useConflicts: true,
        mappedEventsList,
        columnsAmount,
      }
    }
  )

export const selectShouldSyncTeeupsCalendar = createSelector(
  selectCalendarSyncSettings,
  calendarSyncSettings => calendarSyncSettings?.syncTeeups
)
export const selectShouldSyncOnlyAllSet = createSelector(
  selectCalendarSyncSettings,
  calendarSyncSettings => calendarSyncSettings?.syncOnlyAllSet
)

export const selectSelectedCalendar = createSelector(
  selectCalendarSyncSettings,
  calendarSyncSettings => calendarSyncSettings?.calendar
)

export const selectAdditionalCalendars = createSelector(
  selectCalendarSyncSettings,
  calendarSyncSettings => calendarSyncSettings.additionalCalendars || []
)

export const selectCustomTimezone = createSelector(
  selectCalendarSyncSettings,
  calendarSyncSettings => calendarSyncSettings?.customTimezone
)
export const selectAvaliableCalendars = createSelector(
  selectCalendar,
  () => CalendarState.avaliableCalendars || []
)
export const selectCalendarPermissions = createSelector(
  selectCalendar,
  () => CalendarState.calendarPermissions
)

export const selectSelectedCalendarTitle = createSelector(
  selectAvaliableCalendars,
  selectSelectedCalendar,
  (avaliableCalendars, selectedCalendar) => {
    if (selectCalendar && avaliableCalendars.length > 0) {
      const selectedCalendarInfo = avaliableCalendars.find(
        calendar => calendar.id === selectedCalendar
      )
      return selectedCalendarInfo ? selectedCalendarInfo?.title : ""
    } else {
      return ""
    }
  }
)

export const selectCalendarEmails = createSelector(
  selectCalendar,
  calendar => calendar.calendarEmails
)

export const selectCalendarListSections = createSelector(
  selectCalendar,
  calendar => calendar.calendarListSections
)

export const selectPrimaryGoogleCalendar = createSelector(
  selectCalendar,
  calendar =>
    calendar.calendarEmails.find(
      email => email.primary && email.calendarType === CALENDAR_TYPES.google
    )
)

export const selectPrimaryCalendarIds = createSelector(
  selectCalendarEmails,
  emails =>
    emails?.length
      ? emails.map(email => email.primary && email.calendarEmailId)
      : null
)

export const selectSyncEmail = createSelector(
  selectCalendarEmails,
  selectSelectedCalendar,
  (emails, selectedCalendar) =>
    emails?.length
      ? emails.find(email => email.calendarEmailId === selectedCalendar)
      : ""
)

export const selectCalendarById = id =>
  createSelector(selectCalendarEmails, emails =>
    emails.find(email => email.calendarEmailId === id)
  )

export const selectPrimaryOutlookCalendar = createSelector(
  selectCalendar,
  calendar =>
    calendar.calendarEmails.find(
      email => email.primary && email.calendarType === CALENDAR_TYPES.outlook
    )
)

export const selectCalendarSettingsMsg = createSelector(
  selectCalendar,
  calendar => calendar.showSettingsMessage
)

export const selectDisplayedCalendars = createSelector(
  selectDisplayCalendar,
  selectPrimaryGoogleCalendar,
  selectPrimaryOutlookCalendar,
  selectCalendarEmails,
  (calendarDisplayObj, primaryGoogle, primaryOutlook, calendarEmails) => {
    const calendars = {
      [CALENDAR_TYPES.google]: { primary: null, related: [] },
      [CALENDAR_TYPES.outlook]: { primary: null, related: [] },
    }
    if (!(primaryGoogle || primaryOutlook)) return null
    if (primaryGoogle) calendars[CALENDAR_TYPES.google].primary = primaryGoogle
    if (primaryOutlook)
      calendars[CALENDAR_TYPES.outlook].primary = primaryOutlook

    Object.keys(calendarDisplayObj).forEach(key => {
      if (calendarDisplayObj[key].selected) {
        const calendarObj = calendarEmails.find(
          email => email.calendarEmailId === key
        )
        if (!calendarObj) return
        calendars[calendarDisplayObj[key].type].related.push(calendarObj)
      }
    })
    return calendars
  }
)

export const selectGuestLinkedCalendar = createSelector(
  selectCalendar,
  calendar => calendar.linkedGuestCalendar
)

export const selectGuestCalendarEvents = createSelector(
  selectCalendar,
  calendar => calendar.guestCalendarEvents
)

export const selectIsGuestConnectionError = createSelector(
  selectCalendar,
  calendar => calendar.isGuestConnectionError
)
