import styled from "styled-components"

import {
  NFontWeight,
  NSubtitleS,
  NTitle6,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`

export const LeftSide = styled.div``

export const Title = styled(NTitle6)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NSubtitleS)`
  color: var(--gray4);
`
