export const DEFAULT_MODAL_TEXT = {
  email: {
    SubtitleLInfoText:
      "To add this email address to your profile we need you to verify it. Enter it below and click send to have a verification code sent to that email.",
    title: "Verify Email Address",
    inputLable: "Email address",
    placeholder: "Email",
    selectedVerificationMethod: "Email address",
  },

  phoneNumber: {
    SubtitleLInfoText:
      "To add this mobile number to your profile we need you to verify it. Enter it below and click send to have a verification code sent to that number.",
    title: "Verify Mobile Number",
    inputLable: "Mobile number",
    placeholder: "Number",
    selectedVerificationMethod: "Mobile number",
  },

  codeVerify: {
    SubtitleLInfoText:
      "We just sent you a verification code. Please check your text messages and enter the code below.",
    inputLable: "Verification code",
    placeholder: "Enter code",
    selectedVerificationMethod: "Email address",
    seccessVerifyText: "Verification was successful. Click save to finish.",
  },
}
