import { useMemo } from "react"

import { REGISTER_STEPS } from "../constants"

export const useContinueDisabled = ({
  validationErrors,
  isRegisterEmailLoading,
  apiErrors,
  isCodeVerifying,
  isInviteCodeVerifying,
  isRegisterNamesLoading,
  step,
  touchedFields,
  fullName,
  displayName,
  email,
}) => {
  const isUntouchableFields = useMemo(() => {
    switch (step) {
      case REGISTER_STEPS.EMAIL:
        return !touchedFields.email
      case REGISTER_STEPS.SIGN_UP_CODE:
        return !touchedFields.code
      case REGISTER_STEPS.PASSWORD:
        return !touchedFields.password
      case REGISTER_STEPS.NAMES:
        return !touchedFields.fullName || !touchedFields.displayName
      case REGISTER_STEPS.INVITE_SENDER:
        return !touchedFields.inviteVerificationCode
      default:
        return false
    }
  }, [step, touchedFields, fullName, displayName])

  return useMemo(() => {
    return (
      !!validationErrors ||
      isRegisterEmailLoading ||
      Object.keys(apiErrors).length > 0 ||
      isCodeVerifying ||
      isInviteCodeVerifying ||
      isRegisterNamesLoading ||
      isUntouchableFields
    )
  }, [
    validationErrors,
    isRegisterEmailLoading,
    apiErrors,
    isCodeVerifying,
    isInviteCodeVerifying,
    isRegisterNamesLoading,
    step,
    touchedFields,
    fullName,
    displayName,
    email,
  ])
}
