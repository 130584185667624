import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import { compose } from "redux"
import { createStructuredSelector } from "reselect"

import { selectToken } from "../selectors/user"

export function PrivateRoute({
  component: Component,
  isAuthenticated,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  )
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectToken,
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(PrivateRoute)
