import { SxProps, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Fade from "@mui/material/Fade"
import Modal from "@mui/material/Modal"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

interface Props {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  responsiveBreakpoint?: boolean
}

const style: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  boxSizing: "border-box",
}

const DefaultModal = ({
  open,
  onClose,
  children,
  responsiveBreakpoint,
}: Props) => {
  const isMobile = useMediaQuery(`(max-width:${MEDIA_SIZES.MOBILE})`)

  if (isMobile || responsiveBreakpoint) {
    style.width = "95%"
    style.bottom = 0
    style.transform = "translate(-50%, 0)"
    style.top = "auto"
  } else {
    style.width = "auto"
    style.bottom = "auto"
    style.transform = "translate(-50%, -50%)"
    style.top = "50%"
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Fade in={open}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  )
}

export default DefaultModal
