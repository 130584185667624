import React from "react"

import _ from "lodash"
import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

import { AppColors } from "@theme"

// import { images } from '@utils/imageUtils'
import Avatar from "../../../ui/avatar"

import { styles } from "./styles"

const USERS_TO_RENDER_INDEX = 12

export const renderFirstAvatars = (users, ownerId, ImageWrapper, isEnded) =>
  _.map(
    _.sortBy(Object.values(users), i => (i.id === ownerId ? -1 : 1)),
    (user, index) =>
      user &&
      index < USERS_TO_RENDER_INDEX && (
        <ImageWrapper
          style={[
            {
              marginLeft: index === 0 ? 0 : 4,
              position: "relative",
              zIndex: USERS_TO_RENDER_INDEX - index,
            },
            isEnded && styles.grayout,
          ]}
          key={`user-${user.id}`}>
          <Avatar
            // style={styles.userAvatarStyle(
            //     index === 0,
            //     USERS_TO_RENDER_INDEX - index
            // )}
            imageUrl={user.avatar}
            size={24}
            // borderWidth={2.5}
            innerContentStyle={{
              borderColor: AppColors.brand.white,
            }}
          />
        </ImageWrapper>
      )
  )

// export const getRightBottomIcon = calendarStatus => {
//     switch (calendarStatus) {
//         case 'isSynced':
//             return images.synchedCalendar
//         case 'notSynced':
//             return images.notSynchedCalendar
//         case 'syncError':
//             return images.calendarSyncedError
//         default:
//             return images.synchedCalendar
//     }
// }

export const RightBottomImageBadge = ({ onPress, calendarStatus }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.rightBottomImage}>
      {/* <Image source={getRightBottomIcon(calendarStatus)} /> */}
    </View>
  </TouchableOpacity>
)
RightBottomImageBadge.propTypes = {
  onPress: PropTypes.func.isRequired,
  calendarStatus: PropTypes.string.isRequired,
}

export const TimeFromNow = ({
  teeup,
  notificationsArr,
  isNew,
  fromNowString,
}) => {
  const unread = notificationsArr?.includes(teeup.id)
  return (
    <View style={styles.row}>
      <Text
        style={[
          styles.timeFromNow,
          {
            color: unread ? "#222222" : "#8D8D8D",
            fontWeight: unread ? "700" : "500",
            fontFamily: unread
              ? "greycliffcf-bold-salt"
              : "greycliffcf-medium-salt",
          },
        ]}>
        {fromNowString}
      </Text>
    </View>
  )
}

TimeFromNow.propTypes = {
  isNew: PropTypes.bool.isRequired,
  fromNowString: PropTypes.string.isRequired,
}
