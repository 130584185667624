import { useState } from "react"

export const useSuggestSteps = () => {
  const MIN_STEPS = 0
  const TOTAL_STEPS = 3
  const [currentStep, setCurrentStep] = useState(MIN_STEPS)
  const [suggestionPreviewId, setSuggestionPreviewId] = useState(null)
  const [isMakeSuggestion, setIsMakeSuggestion] = useState(false)

  const goPrevStep = () => {
    if (suggestionPreviewId) {
      return setSuggestionPreviewId(null)
    }
    if (isMakeSuggestion && currentStep === 1) {
      return setIsMakeSuggestion(false)
    }

    setCurrentStep(prevState => {
      return prevState > MIN_STEPS ? prevState - 1 : prevState
    })
  }

  const goNextStep = () => {
    setCurrentStep(prevState => {
      return prevState < TOTAL_STEPS ? prevState + 1 : prevState
    })
  }

  const jumpToStep = step => {
    setCurrentStep(prevState => {
      return step >= MIN_STEPS && step <= TOTAL_STEPS ? step : prevState
    })
  }

  return {
    totalSteps: TOTAL_STEPS,
    currentStep,
    suggestionPreviewId,
    setSuggestionPreviewId,
    isMakeSuggestion,
    setIsMakeSuggestion,
    goPrevStep,
    goNextStep,
    jumpToStep,
  }
}
