import React, { useState } from "react"

import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { connect } from "react-redux"

import { SvgIcon } from "@components/common"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectTeeupPeople } from "@selectors/teeups"
import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"
import getWhereSuggestionDetails from "@utils/gameplan/getWhereSuggestionDetails"
import { GAMEPLAN_TYPES } from "constants/gameplan"

import SuggestionPopup from "../../gamePlansCard/SuggestionPopup"
import { useSuggestionData } from "../hooks"
import { SuggestionItem } from "@components"

import { Wrapper, Info, Value, Details } from "./decidedSuggestionStyles"

const DecidedSuggestion = props => {
  const {
    isPopup,
    isPreview,
    isBorderedPreview,
    decidedSuggestion,
    activeTeeup,
    people,
  } = props
  const dataTestId = isPreview ? "decidedInPreview" : "decidedInTeeUp"
  const [isSuggestionPopupOpen, setIsSuggestionPopupOpen] = useState(false)
  const { value, details } = useSuggestionData(decidedSuggestion)
  const isWhen = decidedSuggestion.type === GAMEPLAN_TYPES.when

  const toggleSuggestionPopupHandle = () => {
    if (isPopup || isPreview) return

    setIsSuggestionPopupOpen(prevState => !prevState)
  }
  const previewSuggestionInfo = isWhen
    ? getWhenSuggestionDetails(decidedSuggestion)
    : getWhereSuggestionDetails(decidedSuggestion)
  return (
    <>
      <Wrapper
        data-testid={dataTestId}
        $isPreview={isPreview}
        $isBorderedPreview={isBorderedPreview}
        onClick={toggleSuggestionPopupHandle}>
        {/*
          {isPreview && isWhen && (
          <div className="mb-3 flex items-center justify-between">
            <div className="text-base font-semibold leading-tight text-neutral-700">
              When
            </div>

            <div class="inline-flex h-6 w-[61px] items-center justify-center gap-1 rounded border border-green-200 bg-green-50 px-1.5 py-0.5">
              <div class="text-right font-['Inter'] text-xs font-semibold leading-tight text-green-700">
                Decided
              </div>
            </div>
          </div>
        )} */}

        <div className="mb-2 flex justify-between">
          <Info>
            <Value>{isPreview ? previewSuggestionInfo.title : value}</Value>

            <Details>
              {(isPreview ? previewSuggestionInfo.subtitle : details) ||
                "No link provided"}
            </Details>
          </Info>

          <SvgIcon name="decided-icon" className="!h-[40px] !w-[40px]" />
        </div>
      </Wrapper>

      {isSuggestionPopupOpen &&
        ReactDOM.createPortal(
          <SuggestionPopup
            activeTeeup={activeTeeup}
            people={people}
            draggableSuggestionRow={
              <SuggestionItem isPopup suggestion={decidedSuggestion} />
            }
            currentSuggestion={decidedSuggestion}
            currentSuggestionData={decidedSuggestion}
            toggleSuggestionPopup={toggleSuggestionPopupHandle}
          />,
          document.querySelector("#modal-root")
        )}
    </>
  )
}

DecidedSuggestion.propTypes = {
  isPopup: PropTypes.bool,
  isPreview: PropTypes.bool,
  isBorderedPreview: PropTypes.bool,
  decidedSuggestion: PropTypes.object,
}

const mapStateToProps = state => ({
  activeTeeup: selectActiveTeeup(state),
  people: selectTeeupPeople(state),
})

export default connect(mapStateToProps)(DecidedSuggestion)
