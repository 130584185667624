import React, { useEffect, useMemo, useState } from "react"

import cn from "classnames"

import { removeSpaces } from "@utils/removeSpaces"

import { ButtonThreeDots } from "../../../components/ButtonThreeDots"

const errorsList = {
  minCharacters: "Must be at least 3 characters",
  maxSpaces: "Cannot be 2 spaces in a row",
}

const NameDisplay = ({ handleAddNames, isLoading }) => {
  const [fullName, setFullName] = useState("")
  const [name, setName] = useState("")
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (fullName.length < 3) {
      setErrors(prevState => ({
        ...prevState,
        fullName: errorsList.minCharacters,
      }))
    } else if (fullName.includes("  ")) {
      setErrors(prevState => ({
        ...prevState,
        fullName: errorsList.maxSpaces,
      }))
    } else {
      const copiedErorrs = { ...errors }
      delete copiedErorrs.fullName
      setErrors(copiedErorrs)
    }
  }, [fullName])

  useEffect(() => {
    if (name.length < 3) {
      setErrors(prevState => ({
        ...prevState,
        name: errorsList.minCharacters,
      }))
    } else {
      const copiedErorrs = { ...errors }
      delete copiedErorrs.name
      setErrors(copiedErorrs)
    }
  }, [name])

  const isErrors = useMemo(() => Object.keys(errors).length, [errors])

  const isButtonDisabled = useMemo(
    () => Object.keys(errors).length > 0 || isLoading,
    [isErrors, isLoading]
  )

  const fullNameError = useMemo(() => {
    return isErrors && errors.hasOwnProperty("fullName")
      ? errors.fullName
      : false
  }, [errors])

  const nameError = useMemo(() => {
    return isErrors && errors.hasOwnProperty("name") ? errors.name : false
  }, [errors])

  return (
    <>
      <span className="forgot__title">Name and Display</span>
      <p className="forgot__subtitle">
        Your display name is how you will be viewed by others in conversations
        and public spaces for privacy.
      </p>
      <form
        onSubmit={e => {
          e.preventDefault()
        }}>
        <label htmlFor="fullname" className="forgot__label">
          Full Name
        </label>
        <div style={{ position: "relative", marginBottom: 15 }}>
          <input
            data-testid="fullNameField"
            id="fullname"
            placeholder="Enter first and last name"
            value={fullName}
            onChange={({ target }) => setFullName(target.value)}
            className="login__input forgot__input"
            maxLength={35}
            type="text"
          />
          {fullNameError && fullName.length > 0 && (
            <span
              style={{
                position: "absolute",
                left: 0,
                bottom: -15,
                fontFamily: "greycliff-cf-medium",
                fontSize: 15,
                fontWeight: 600,
                textAlign: "left",
                color: "#ff0000",
              }}>
              {fullNameError}
            </span>
          )}
        </div>

        <label htmlFor="name" className="forgot__label">
          Display Name
        </label>
        <div style={{ position: "relative" }}>
          <input
            data-testid="displayNameField"
            id="name"
            placeholder="Create a display name"
            value={name}
            onChange={({ target }) => {
              setName(removeSpaces(target.value))
            }}
            className="login__input forgot__input"
            maxLength={35}
            type="text"
          />
          {nameError && name.length > 0 && (
            <span
              style={{
                position: "absolute",
                left: 0,
                bottom: -15,
                fontFamily: "greycliff-cf-medium",
                fontSize: 15,
                fontWeight: 600,
                textAlign: "left",
                color: "#ff0000",
              }}>
              {nameError}
            </span>
          )}
        </div>
        <button
          data-testid="continueButton"
          type="button"
          disabled={isButtonDisabled}
          style={{ marginTop: 40 }}
          className={cn("forgot__button", {
            "forgot__button--disabled": isButtonDisabled,
          })}
          onClick={() => !isButtonDisabled && handleAddNames(fullName, name)}>
          {isLoading ? <ButtonThreeDots /> : "Continue"}
        </button>
      </form>
    </>
  )
}

export default NameDisplay
