import styled, { css } from "styled-components"

export const List = styled.ul`
  height: calc(100% - 300px);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 8px;
  list-style: none;
  overflow-y: auto;
`

export const ListItem = styled.li`
  height: 100px;
  cursor: pointer;
  border: 2px solid transparent;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: var(--radicalRed);
    `};
`

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
