import endpoints from "@config/endpoints"
import api from "api"

import actionTypes from "./actionTypes"

const setSlackIntegrationInfo = payload => ({
  type: actionTypes.SET_SLACK_INTEGRATION_INFO,
  payload,
})

const connectToSlack = info => {
  const url = endpoints.slackConnect
  const body = {
    provider: "slack",
    externalId: info.slackUserId,
    callbackUrl: info.callbackUrl,
  }

  return api.client
    .post(url, body)
    .then(res => {
      const { data } = res

      return data
    })
    .catch(error => console.log(error, "error"))
}

export { setSlackIntegrationInfo, connectToSlack }
