import styled, { css } from "styled-components"

import { SubtitleL, Title2 } from "@assets/styles/typographyStyles"

export const Warning = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 20%;
  left: 50%;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--blackShadow);
  z-index: 9999;
  gap: 8px;
  padding: 40px 5px;
  text-align: center;
  transform: translate(-50%, -50%);

  ${({ isHaveClose }) =>
    isHaveClose &&
    css`
      padding: 15px 20px 30px 45px;
    `};

  > button {
    margin: 8px 0 10px 97%;
  }
`

export const SuccessImg = styled.img`
  width: 56px;
  height: 56px;
`

export const Title = styled(Title2)`
  color: var(--black);
`

export const Description = styled(SubtitleL)`
  color: var(--gray4);
`
