import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  ${({ $isDecided }) => css`
    border: 1px solid var(--lightGray5);
    background-color: ${$isDecided ? "var(--lightMint)" : "var(--lightBlue3)"};
    border-radius: 24px;

    display: flex;
    align-items: center;
    column-gap: 5px;

    padding: 4px 8px;
    position: absolute;
    top: -11px;
    right: 10px;
  `};
`

export const Text = styled(NSubtitleS)`
  ${({ $textColor }) => css`
    color: ${$textColor};
    font-weight: ${NFontWeight.semiBold};
  `};
`
