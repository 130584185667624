import React, { useState } from "react"

import { debounce } from "lodash"
import PhoneNumber from "react-native-phone-input/lib/phoneNumber"

import { userTypes } from "@config/enums"
import { contactTypes } from "@utils/contactUtils"
import { validateEmail } from "@utils/dataUtils"

import { batchVerifyAndGetUserInfo } from "../../../actions/batchVerifyAndGetUserInfo"

import AddContactsViewNd from "./AddManualContactsViewNd"

const countriesMap = require("../../countrySelector/countries.json")

const AddContactsNd = ({
  onDone,
  onClose,
  defaultCountry,
  inviteBy,
  setInviteBy,
  setCurrentCheckbox,
  currentCheckbox,
  error,
  setError,
}) => {
  const [contactList, setContactList] = useState([
    {
      id: 0,
      value: "",
      isValid: true,
    },
  ])
  const [contactType, setContactType] = useState("phone")

  const addTextInputRow = () => {
    const nextId = contactList.length + 1
    setContactList([
      ...contactList,
      {
        id: nextId,
        value: "",
        isValid: true,
      },
    ])
  }

  const removeTextInputRow = index => () => {
    setContactList(contactList.filter(contact => contact.id !== index))
  }

  const getInitPhoneNumber = index => phoneNumber => {
    const newContactList = [...contactList]
    const arrayIndex = newContactList.findIndex(contact => contact.id === index)
    const editedContact = {
      ...newContactList[arrayIndex],
      value: phoneNumber + " ",
      isValid: false,
    }

    newContactList.splice(arrayIndex, 1, editedContact)

    setContactList(newContactList)
  }

  const handleTextChange = index => (text, cca2) => {
    const newContactList = [...contactList]
    const arrayIndex = newContactList.findIndex(contact => contact.id === index)
    let isValidInput
    if (contactType === contactTypes.EMAIL) {
      isValidInput = validateEmail(text)
    }
    if (contactType === contactTypes.PHONE) {
      isValidInput = PhoneNumber.isValidNumber(text, cca2)
    }
    const countryCode = cca2
      ? `+${countriesMap[cca2.toUpperCase()].callingCode}`
      : ""

    _checkForExistedUser(index)

    const editedContact = {
      ...newContactList[arrayIndex],
      value: text,
      isValid: isValidInput,
      countryCode: countryCode,
    }

    newContactList.splice(arrayIndex, 1, editedContact)

    setContactList(newContactList)
  }

  const handleDone = value => {
    const data = contactList.map(({ value }) => value)
    onDone({ contactType, data })
  }

  const checkForExistedUser = async index => {
    let currentInput = contactList.find(i => i.id === index)
    const { value, isValid, existedUser } = currentInput
    if (!isValid || !value) {
      if (existedUser) {
        delete currentInput.existedUser
        setContactList(
          contactList.map(i => (i.id === index ? currentInput : i))
        )
      }
      return null
    }
    let data = await batchVerifyAndGetUserInfo([value.replaceAll(/\s/g, "")])
    const { users, contacts } = data

    let user = null
    if (users.length > 0) user = users[0]
    if (contacts.length > 0) user = contacts[0]
    if (user && user.userType !== userTypes.draft) {
      const {
        contactId,
        familyName,
        givenName,
        firstname,
        lastname,
        userType,
        name,
        nickname,
      } = user
      const username = contactId
        ? `${givenName} ${familyName}`
        : nickname || name || `${firstname} ${lastname}`

      setContactList(
        contactList.map(contact =>
          contact.id === index
            ? {
                ...contact,
                existedUser: { username, isCooe: !!userType },
              }
            : contact
        )
      )
    }
  }

  const _checkForExistedUser = debounce(checkForExistedUser, 250)
  const hasEmptyInput = contactList.some(contact => !contact.value)
  const hasInvalidInput = contactList.some(contact => !contact.isValid)

  return (
    <AddContactsViewNd
      inviteBy={inviteBy}
      currentCheckbox={currentCheckbox}
      setInviteBy={setInviteBy}
      setCurrentCheckbox={setCurrentCheckbox}
      isPhoneContact={contactType === contactTypes.PHONE}
      disableDoneButton={
        contactList.length === 0 || hasEmptyInput || hasInvalidInput
      }
      onClose={onClose}
      contactList={contactList}
      defaultCountry={defaultCountry}
      removeTextInputRow={removeTextInputRow}
      handleTextChange={handleTextChange}
      addTextInputRow={addTextInputRow}
      getInitPhoneNumber={getInitPhoneNumber}
      error={error}
      setError={setError}
      contactType={contactType}
      setContactType={setContactType}
      handleDone={handleDone}
    />
  )
}

export default AddContactsNd
