import ErrorIcon from "@assets/images/shadow-error.svg"
import SuccessIcon from "@assets/images/success.svg"
import WarningIcon from "@assets/images/warning-shadow.svg"

export const SYNC_CONTACTS_MODAL = {
  ADD_ANOTHER: "ADD_ANOTHER",
  ALREADY_ADDED: "ALREADY_ADDED",
  SOMETHING_WEND_WRONG: "SOMETHING_WEND_WRONG",
  ADDED_BY_DIFFERENT_ACCOUNT: "ADDED_BY_DIFFERENT_ACCOUNT",
  CANCELED: "CANCELED",
  CONFIRM_REMOVING: "CONFIRM_REMOVING",
  SUCCESSFULLY_ADDED: "SUCCESSFULLY_ADDED",
  SUCCESSFULLY_REMOVED: "SUCCESSFULLY_REMOVED",
}

const {
  ADD_ANOTHER,
  ALREADY_ADDED,
  SOMETHING_WEND_WRONG,
  ADDED_BY_DIFFERENT_ACCOUNT,
  CANCELED,
  CONFIRM_REMOVING,
  SUCCESSFULLY_ADDED,
  SUCCESSFULLY_REMOVED,
} = SYNC_CONTACTS_MODAL

export const SYNC_CONTACTS_MODAL_TEXT = {
  [ADD_ANOTHER]: {
    title: "Sync Contacts",
    description:
      "Link an account to view your contacts and make it easier to invite people to TeeUp.",
  },
  [ALREADY_ADDED]: {
    title: "Sync Contacts",
    description:
      "You have already added this <b>CONNECTOR_VALUE</b>.\n" +
      "Try another one.",
    icon: ErrorIcon,
  },
  [ADDED_BY_DIFFERENT_ACCOUNT]: {
    title: "Sync Contacts",
    description:
      "This contact connector is already used by a different account.",
    icon: ErrorIcon,
  },
  [SOMETHING_WEND_WRONG]: {
    title: "Sync Contacts",
    description: "Something went wrong.",
    icon: ErrorIcon,
  },
  [CANCELED]: {
    title: "Sync Contacts",
    description: "It seems like you canceled adding a new account.",
    icon: ErrorIcon,
  },
  [CONFIRM_REMOVING]: {
    title: "Remove account CONNECTOR_TYPE",
    description:
      "By removing <b>CONNECTOR_TYPE</b> account <b>CONNECTOR_VALUE</b> contacts link you will no longer see those contacts in the app.",
    icon: WarningIcon,
  },
  [SUCCESSFULLY_ADDED]: {
    title: "Sync Contacts",
    description:
      "<b>CONNECTOR_TYPE</b> contacts <b>CONNECTOR_VALUE</b> connected successfully.",
    icon: SuccessIcon,
  },
  [SUCCESSFULLY_REMOVED]: {
    title: " CONNECTOR_TYPE account is removed",
    description:
      "You have successfully removed <b>CONNECTOR_VALUE</b> and will no longer see contacts from that account.",
    icon: SuccessIcon,
  },
}
