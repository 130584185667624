import DateCalendar from "./DateCalendar"
import DateRange from "./DateRange"
import Duration from "./Duration"
import CustomDateRangeModal from "./Modals/CustomDateRange"
import CustomTimeModal from "./Modals/CustomTime"
import TimeList from "./TimeList"
import YourSuggestionsContainer from "./YourSuggestionsContainer"
import "./index.scss"

const General = ({ onSave }) => {
  return (
    <div className="general-tab">
      <CustomDateRangeModal />
      <CustomTimeModal />

      <h4>Select date</h4>

      <DateCalendar />
      <DateRange />
      <TimeList />
      <Duration />

      <YourSuggestionsContainer onSave={onSave} />
    </div>
  )
}

export default General
