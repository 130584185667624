import { requestStatusId } from "@utils/contactUtils"

export const invitationTypes = {
  group: "group",
  connection: "connection",
  teeup: "teeup",
}

export const requestResponseTypes = {
  accept: "accept",
  decline: "decline",
}

export const isConnected = request =>
  request.id && request.statusId === requestStatusId.accepted
