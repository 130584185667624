import { useMemo } from "react"

export const useConfirmDisabled = ({
  validationErrors,
  isSubmitting,
  apiErrors,
}) => {
  return useMemo(() => {
    return !!validationErrors || isSubmitting || Object.keys(apiErrors).length
  }, [validationErrors, isSubmitting, apiErrors])
}
