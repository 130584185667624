import _ from "lodash"

import actionTypes from "@actions/actionTypes"

export const initialState = {
  interests: {
    list: [],
    filter: {
      interests: [],
      locations: [],
    },
    loading: false,
  },
  matchings: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMUNITIES:
      return _.assign({}, state, {
        interests: {
          ...state.interests,
          loading: true,
        },
      })
    case actionTypes.GOT_COMMUNITIES:
      return _.assign({}, state, {
        interests: {
          ...state.interests,
          list: action.payload,
          loading: false,
        },
      })

    case actionTypes.UPDATE_COMMUNITY_INTEREST: {
      const { interest, icIndex } = action.payload
      let ICs = [...state.interests.list]

      if (ICs.length > icIndex && ICs[icIndex].id === interest.id) {
        ICs[icIndex] = interest
      } else {
        // Something changed. simply add the queried interest in front
        ICs.unshift(interest)
      }

      return _.assign({}, state, {
        interests: {
          ...state.interests,
          list: ICs,
        },
      })
    }

    case actionTypes.UPDATE_INTEREST_FILTER:
      return _.assign({}, state, {
        interests: {
          ...state.interests,
          filter: {
            ...state.interests.filter,
            interests: action.payload,
          },
        },
      })
    case actionTypes.UPDATE_LOCATION_FILTER:
      return _.assign({}, state, {
        interests: {
          ...state.interests,
          filter: {
            ...state.interests.filter,
            locations: action.payload,
          },
        },
      })

    case actionTypes.GET_MATCHINGS: {
      const { matchings } = action.payload

      return _.assign({}, state, {
        matchings,
      })
    }

    default:
      return state
  }
}
