import styled, { css } from "styled-components"

export const Wrapper = styled.section`
  ${({ $isSelectedContacts }) => css`
    height: 95%;
    background-color: var(--white);
    box-sizing: border-box;
    padding-top: 60px;

    display: flex;
    flex-direction: column;

    ${$isSelectedContacts &&
    css`
      padding-bottom: 80px;
    `};
  `};
`

export const SubmitWrapper = styled.div`
  width: 100%;
  height: unset;
  background-color: var(--white);
  box-shadow: var(--blackShadow);
  box-sizing: border-box;
  padding: 10px 15px;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
`
