import actionTypes from "./actionTypes"

export const setSelectedContact = payload => ({
  type: actionTypes.SET_SELECTED_CONTACT,
  payload,
})

export const removeSelectedContact = payload => ({
  type: actionTypes.REMOVE_SELECTED_CONTACT,
  payload,
})

export const resetSelectedContacts = () => ({
  type: actionTypes.RESET_SELECTED_CONTACTS,
})
