import React, { useContext, useEffect, useMemo, useState } from "react"

import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { bindActionCreators } from "redux"

import { setTeeupActive, updateActiveTeeup } from "@actions/activeTeeupActions"
import { handleSetTeeupsMode } from "@actions/commonActions"
import { joinToTeeup } from "@actions/requestActions"
import {
  archiveTeeup,
  fetchGameplanOptions,
  fetchTeeups,
  fetchTeeupUsersById,
  gotTeeupParts,
  updateGameplan,
  updateTeeupArchiveStatus,
} from "@actions/teeupActions"
import { ButtonEl } from "@components/common"
import { teeupUserStatusPriorities } from "@config/enums"
import { userStatusTypes } from "@config/mappings"
import { BUTTON_VARIANTS } from "@constants/button"
import { getPreviewIgnoredStatuses } from "@utils/getPreviewIgnoredStatuses"
import { SideBarContext } from "@utils/sideBarContext"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { EVENTS, logEvent } from "../../../analytics-tracking"
import { ButtonsWrapper } from "../teeupPreviewContentStyles"

const InAppButtons = ({
  isAllDisabled,
  teeups,
  joinToTeeup,
  updateActiveTeeup,
  gotTeeupParts,
  updateGameplan,
  fetchTeeups,
  archiveTeeup,
  handleSetTeeupsModefn,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    previewInfo,
    isInbox,
    isAuthenticated,
    contactWarning,
    isRedirectChecking,
  } = useContext(TeeupPreviewContext)
  const { setFocusCounter } = useContext(SideBarContext)
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated && teeups.length === 0) {
      fetchTeeups()
    }
  }, [isAuthenticated, teeups])

  const handleJoin = () => {
    setIsLoading(true)

    const teeupId = previewInfo.teeupId
    const statusId = teeupUserStatusPriorities.joined
    const currentTeeup = teeups.find(teeup => teeup.id === teeupId)

    joinToTeeup(teeupId, statusId)
      .then(async error => {
        if (currentTeeup) {
          setTeeupActive(currentTeeup)
        }
        updateActiveTeeup({
          id: teeupId,
          userStatus: userStatusTypes[statusId],
        })
        const teeupUsers = await fetchTeeupUsersById(teeupId)
        gotTeeupParts([teeupUsers])
        updateGameplan(teeupId, await fetchGameplanOptions(teeupId))
        history.push(`/teeUps/${teeupId}`)
        setIsLoading(false)

        if (error) {
          console.log("handleRespondToInvitation Error:", {
            error,
          })
        }
      })
      .catch(error => {
        console.log("handleRespondToInvitation catch ERROR:", {
          error,
        })
      })
  }

  const handleAddContact = () => {
    history.push("/settings?option=invitationsDetails")
  }

  const handleBack = () => {
    history.push("/")
  }

  const handleDecline = () => {
    const teeupId = previewInfo.teeupId

    updateTeeupArchiveStatus(teeupId)
    archiveTeeup(teeupId)
    logEvent(EVENTS.MOVE_TEEUP_TO_ARCHIVE, { teeupId })
    handleSetTeeupsModefn(TEEUP_LIST_MODES.ARCHIVED)
    setFocusCounter(2)

    history.push("/")
  }

  const getAddContactBtnText = () => {
    const type = contactWarning.type === "email" ? "Email" : "Phone Number"

    return `Add ${type} to Join Teeup`
  }

  const userSubmitBtnText = useMemo(() => {
    const ignoredStatuses = getPreviewIgnoredStatuses()

    return previewInfo?.invitee.status &&
      !ignoredStatuses.includes(previewInfo?.invitee.status)
      ? "Open Teeup"
      : "Join Invitation"
  }, [previewInfo])

  return (
    <ButtonsWrapper>
      {contactWarning ? (
        <ButtonEl
          isBlack
          text={getAddContactBtnText()}
          isLoading={isLoading}
          disabled={isLoading || isAllDisabled}
          variant={BUTTON_VARIANTS.ROUNDED}
          onClick={handleAddContact}
        />
      ) : (
        <ButtonEl
          isBlack
          text={userSubmitBtnText}
          isLoading={isLoading || isRedirectChecking}
          disabled={isLoading || isAllDisabled || isRedirectChecking}
          variant={BUTTON_VARIANTS.ROUNDED}
          onClick={handleJoin}
        />
      )}

      {isInbox ? (
        <ButtonEl
          isBlack
          text="Decline"
          disabled={isAllDisabled || isRedirectChecking}
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={handleDecline}
        />
      ) : (
        <ButtonEl
          isBlack
          text="Back"
          disabled={isAllDisabled || isRedirectChecking}
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={handleBack}
        />
      )}
    </ButtonsWrapper>
  )
}

const mapStateToProps = ({ teeups }) => ({
  teeups: teeups.teeups,
})

const mapDispatchToProps = dispatch => ({
  joinToTeeup: bindActionCreators(joinToTeeup, dispatch),
  updateActiveTeeup: bindActionCreators(updateActiveTeeup, dispatch),
  gotTeeupParts: bindActionCreators(gotTeeupParts, dispatch),
  updateGameplan: bindActionCreators(updateGameplan, dispatch),
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
  archiveTeeup: bindActionCreators(archiveTeeup, dispatch),
  handleSetTeeupsModefn: bindActionCreators(handleSetTeeupsMode, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InAppButtons)
