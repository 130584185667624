import { createSelector } from "reselect"

import { selectActiveTeeupId } from "@selectors/activeTeeup"

const selectLocalData = state => state.localData

export const selectNewSuggestions = createSelector(
  selectLocalData,
  selectActiveTeeupId,
  (localData, activeTeeupId) =>
    localData.newSuggestions[activeTeeupId] || localData.newSuggestions
)

export const selectRemovedSuggestions = createSelector(
  selectLocalData,
  selectActiveTeeupId,
  (localData, activeTeeupId) =>
    localData.removedSuggestions[activeTeeupId] || []
)

export const selectNewDecidedSuggestions = createSelector(
  selectLocalData,
  selectActiveTeeupId,
  (localData, activeTeeupId) =>
    localData.newDecidedSuggestions[activeTeeupId] || {}
)

export const selectNewUndecidedSuggestions = createSelector(
  selectLocalData,
  selectActiveTeeupId,
  (localData, activeTeeupId) =>
    localData.newUndecidedSuggestions[activeTeeupId] || {}
)

export const selectGameplansNotifications = createSelector(
  selectLocalData,
  selectActiveTeeupId,
  (localData, activeTeeupId) => localData.newSelectedGP[activeTeeupId] || {}
)

export const selectSuggestionsWithDetails = createSelector(
  selectLocalData,
  localData => localData.suggestionsWithDetails
)

export const selectIgnoredUndecidedNudgeTeeups = createSelector(
  selectLocalData,
  localData => localData.ignoredUndecidedNudgeTeeups
)

export const selectIgnoredAllSetNudgeTeeups = createSelector(
  selectLocalData,
  localData => localData.ignoredAllSetNudgeTeeups
)
