import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

// TODO: find better solution than left: calc(50% + 2px);
export const Wrapper = styled.div`
  width: 774px;
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  box-shadow: var(--grayShadow2);
  padding: 15px 25px 10px 25px;
  position: fixed;
  bottom: 0;
  left: calc(50% + 2px);
  transform: translateX(-50%);
  z-index: 99;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    left: 0;
    transform: unset;
  }
`
