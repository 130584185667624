import endpoints from "@config/endpoints"
import { selectUserId, selectUserSettings } from "@selectors/user"
import { setCurrentTimezone } from "@utils/dateUtils"

import api from "../api"
import { store } from "../index"

import actionTypes from "./actionTypes"
import { updateProfile } from "./userActions"

const postSettings = settings => ({
  type: actionTypes.POST_SETTINGS,
  payload: settings,
})

const resyncCalendarTeeupEvents = () => ({
  type: actionTypes.CALENDAR_RESYNC_TEEUP_EVENTS,
})

export const postUserSettings = async settings => {
  let url = endpoints.settings
  if (settings?.calendarSync?.customTimezone !== undefined) {
    delete settings.calendarSync.customTimezone
  }

  return api.client
    .post(url, settings)
    .then(response => {
      const { data } = response
      store.dispatch({ type: actionTypes.SET_SETTINGS, payload: { ...data } })

      return data
    })
    .catch(error => console.log("post user settings error", error))
}

export const changeUserTimeZone = timezone => {
  updateProfile({ timezone: timezone === null ? "" : timezone })
    .then(response => {
      store.dispatch({
        type: actionTypes.CHANGE_TIMEZONE,
        payload: { customTimezone: timezone },
      })
      store.dispatch({
        type: actionTypes.UPDATE_USER_INFO,
        payload: { ...response, timezone },
      })
      setCurrentTimezone(timezone)
    })
    .catch(error => {
      console.log("PATCH changeUserTimeZone error", error)
    })
}

export const changeSettings = async settings => {
  const state = store.getState()
  const userId = selectUserId(state)
  const userSettings = selectUserSettings(state)
  // if (userSettings.sounds) {
  //     delete userSettings.sounds
  // }
  const fullSettings = {
    ...userSettings,
    ...settings,
  }
  if (userId) {
    await postUserSettings(fullSettings, settings.callback)
    if (settings.calendarSync?.customTimezone) {
      const timezone = fullSettings.calendarSync.customTimezone
      changeUserTimeZone(timezone)
    }
  }
}

export { postSettings, resyncCalendarTeeupEvents }
