import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { useInboxStore } from "stores/inboxStore"

const OrganizerArchive = () => {
  const isOpen = useInboxStore(state => state.isOrganizerArchiveModalOpen)
  const setIsOpen = useInboxStore(state => state.setIsOrganizerArchiveModalOpen)

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <DefaultModal open={isOpen} onClose={handleClose}>
      <Header
        title=""
        onClose={handleClose}
        hideBorder
        className="!px-0 w-full my-0 mx-auto"
      />

      <div className="max-w-[450px]">
        <div className="flex items-center justify-center">
          <SvgIcon name="archive-img" className="!w-[95px] !h-[97px]" />
        </div>

        <div>
          <h3 className="text-center text-zinc-800 text-2xl font-semibold mt-4 mb-6">
            New TeeUp Organizer must be assigned before archiving
          </h3>

          <p className="text-center text-neutral-500 text-base font-medium leading-normal">
            Assign your Organizer role to{" "}
            <span className="font-bold">another participant,</span> as archived
            TeeUps still remain active for other participants.
          </p>
        </div>

        <div className="mt-10">
          <Button onClick={handleClose} variant="contained" fullWidth>
            Got it
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default OrganizerArchive
