import { Fragment, useState } from "react"

import cn from "classnames"
import { useSelector } from "react-redux"

import { SvgIcon } from "@components/common"
import { teeupListNudgeTypes } from "@config/enums"
import { messageTypeIds } from "@config/mappings"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import DefaultModal from "@ui/DefaultModal"
import I18n from "i18n"
import { sendNudge } from "middlewares/backendMiddleware"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import ModalHeader from "../../../ui/DefaultModal/Header"
import Checkbox from "../Checkbox"

import ListItem from "./ListItem"

import "./index.scss"

const AskPeopleModal = ({ people }) => {
  const activeTeeupId = useSelector(selectActiveTeeupId)
  const isAskPeopleModalOpen = useTeeupPeopleStore(
    state => state.isAskPeopleModalOpen
  )
  const setIsAskPeopleModalOpen = useTeeupPeopleStore(
    state => state.setIsAskPeopleModalOpen
  )
  const setIsNudgeModalOpen = useTeeupPeopleStore(
    state => state.setIsNudgeModalOpen
  )
  const isOnGoingSelected = useTeeupPeopleStore(
    state => state.isOnGoingSelected
  )
  const [selectedParticipants, setSelectedParticipants] = useState<any[]>([])
  const isSelected = selectedParticipants.length > 0

  const handleChange = (participant: any) => {
    const isSelected = checkIfSelected(participant.id)

    if (isSelected) {
      const filteredParticipants = selectedParticipants.filter(
        item => item.id !== participant.id
      )
      setSelectedParticipants(filteredParticipants)
    } else {
      setSelectedParticipants(prevState => [...prevState, participant])
    }
  }

  const headerTitle = isOnGoingSelected
    ? "Ask People if they are Going"
    : "Ask People where they are"

  const handleClose = () => {
    setIsAskPeopleModalOpen(false)
    setSelectedParticipants([])
  }

  const handleBackClick = () => {
    handleClose()
    setIsNudgeModalOpen(true)
  }

  const checkIfSelected = (id: number) =>
    selectedParticipants.some(item => item.id === id)

  const handleNudgeClick = () => {
    const message = isOnGoingSelected
      ? I18n.nudgePromptContent.areYouGoing.action
      : I18n.nudgePromptContent.whereAreYou.action

    const subTypeId = isOnGoingSelected
      ? teeupListNudgeTypes.areYouGoing
      : teeupListNudgeTypes.whereAreYou

    sendNudge({
      message,
      messageTypeId: messageTypeIds.nudge,
      mentionIds: selectedParticipants.map(item => item.id),
      subTypeId,
      teeupId: activeTeeupId,
    } as any)

    handleClose()
  }

  const handleCancelClick = () => {
    setSelectedParticipants([])
  }

  const onCheckAll = () => {
    if (selectedParticipants.length === people.length) {
      setSelectedParticipants([])
    } else {
      setSelectedParticipants(people)
    }
  }

  return (
    <DefaultModal open={isAskPeopleModalOpen} onClose={handleClose}>
      <div className="people-ask-people-content">
        <ModalHeader
          onBackClick={handleBackClick}
          title={headerTitle}
          subtitle="Select participants to nudge"
        />

        <div className="people-ask-people-container">
          <div className="people-ask-people-select-all">
            <div>
              <div>
                <SvgIcon name="user-friends" />
              </div>
              <span>Select Everyone</span>
            </div>

            <Checkbox
              checked={selectedParticipants.length === people.length}
              onClick={onCheckAll}
            />
          </div>

          {people.map(participant => (
            <Fragment key={participant.id}>
              <ListItem
                participant={participant}
                checked={checkIfSelected(participant.id)}
                onClick={() => handleChange(participant)}
              />
            </Fragment>
          ))}
        </div>

        <div
          className={cn("people-ask-people-footer", {
            "fade-out": selectedParticipants.length === 0,
            "fade-in": isSelected,
          })}>
          <button onClick={handleCancelClick} disabled={!isSelected}>
            Cancel
          </button>
          <button onClick={handleNudgeClick} disabled={!isSelected}>
            Nudge
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default AskPeopleModal
