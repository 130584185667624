import styled from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleXs,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
`

export const Label = styled(NSubtitle)`
  color: var(--black2);
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NSubtitle)`
  color: var(--gray4);
  font-weight: ${NFontWeight.semiBold};
  margin-top: 8px;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuggestionsAmountLabel = styled(NSubtitleXs)`
  background-color: var(--lightBlue5);
  border: 1px solid var(--lightBlue4);
  border-radius: 4px;
  color: var(--darkBlue);
  font-weight: ${NFontWeight.semiBold};
  padding: 0px 8px;
`
