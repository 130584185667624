import React, { useEffect, useRef, useState } from "react"

import { connect } from "react-redux"

import "./index.scss"
import { updateTeeup } from "@middlewares/backendMiddleware"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectUserInfo } from "@selectors/user"
import { ModalHeader } from "components/common/ModalHeader/ModalHeader"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import TeeupSettingsLeftSide from "./TeeupSettingsLeftSide"
import TeeupSettingsRightSide from "./TeeupSettingsRightSide"
import { rightSideData } from "./teeupSettingsData"

const TeeupSettings = ({ userInfo, activeTeeupSettings, close }) => {
  const { teeup, people } = useTeeUpPage()
  const [teeUpSettings, setTeeUpSettings] = useState(null)

  const settingsRef = useRef()
  const shouldUpdateRef = useRef()

  useEffect(() => {
    return () => {
      if (shouldUpdateRef.current) {
        const settings = JSON.parse(JSON.stringify(teeup.settings))
        settingsRef &&
          settingsRef.current.forEach(
            item => (settings[item.settingType] = item.value)
          )
        updateTeeup({
          teeUpId: teeup.id,
          teeup: { settings },
        })
      }
    }
  }, [])

  useEffect(() => {
    if (settingsRef.current && settingsRef.current !== teeUpSettings) {
      shouldUpdateRef.current = true
    }
    settingsRef.current = teeUpSettings
  }, [teeUpSettings])

  useEffect(() => {
    let settingsArray = []

    for (let value in teeup.settings) {
      settingsArray.push({ settingType: value, value: teeup.settings[value] })
    }

    const updatedSettingsArray = rightSideData.map(item => {
      const foundSetting = settingsArray.find(
        data => item.settingType === data.settingType
      )
      return { ...item, value: foundSetting.value }
    })

    setTeeUpSettings(updatedSettingsArray)
  }, [teeup])

  const handleChangeSettings = (newValue, index) => {
    let updatedSettingsArray = JSON.parse(JSON.stringify(teeUpSettings))
    updatedSettingsArray[index] = newValue

    setTeeUpSettings(updatedSettingsArray)
  }

  const isOrganizer = people[userInfo.id]?.isOrganiser

  return (
    <div className="teeup-settings__wrapper" onClick={() => close()}>
      <div
        className="teeup-settings"
        onClick={e => {
          e.stopPropagation()
        }}>
        <ModalHeader title="TeeUp Settings" onClose={close} />

        <div className="teeup-settings__content">
          <TeeupSettingsLeftSide
            isOrganizer={isOrganizer}
            activeTeeupSettings={activeTeeupSettings}
          />

          {teeUpSettings && isOrganizer && (
            <TeeupSettingsRightSide
              teeUpSettings={teeUpSettings}
              handleChangeSettings={handleChangeSettings}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state),
  activeTeeupSettings: selectActiveTeeup(state)?.settings,
})

export default connect(mapStateToProps)(TeeupSettings)
