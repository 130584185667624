import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const SignInFormWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  background: var(--radicalRed);
  display: flex;
  justify-content: center;
`

export const SignInForm = styled.div`
  display: grid;
  row-gap: 17px;
`

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  > button {
    border-radius: 84px;
    width: 100%;
  }

  > button:disabled {
    background: var(--primary-50);
    color: var(--primary-200);
    opacity: 1;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    position: absolute;
    bottom: 44px;
    width: calc(100% - 34px);
  }
`

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Form = styled.form`
  width: 500px;
`

export const ErrorMessage = styled.p`
  font-family: greycliffcf-medium-salt;
  font-size: 18px;
  margin-bottom: 30px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    font-size: 13px;
  }
`

export const BackWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  color: white;
  gap: 8px;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    display: none;
  }
`
