import React, { useEffect, useState } from "react"

import { useDetectClickOutside } from "react-detect-click-outside"
import { createPortal } from "react-dom"

import { useScreenWidth } from "@hooks"

import { deleteUser } from "../../middlewares/backendMiddleware"
import ConfirmDeleteUser from "../../pages/TeeUpPage/TeeUpPageRightSection/TopSection/TopBarManageParticipants/ConfirmDeleteUset"
import InvitationSent from "../../pages/TeeUpPage/TeeUpPageRightSection/TopSection/TopBarManageParticipants/InvitationSent"

import { useActions } from "./hooks"
import { ManageModal } from "./molecules"
import {
  Wrapper,
  Element,
  ActionIcon,
  Text,
} from "./participantListActionsStyles"

export const ParticipantListActions = ({
  person,
  onClose,
  peopleCount,
  setIsSnackbarOpen,
}) => {
  const {
    isConfirmDelete,
    setIsConfirmDelete,
    actionsList,
    isSentInvitationModal,
    setIsSentInvitationModal,
  } = useActions(person)
  const { isSmallSize } = useScreenWidth()

  const ref = useDetectClickOutside({
    onTriggered: () => onClose(),
  })

  const onActionClick = item => {
    item.action()
    if (item.icon !== "remove-user") {
      onClose()
    }
  }

  const handleDeleteUser = () => {
    if (peopleCount === 2) {
      setIsSnackbarOpen(true)
    } else {
      deleteUser(person.id)
    }
    onClose()
  }

  return (
    <>
      {isConfirmDelete &&
        createPortal(
          <ConfirmDeleteUser
            deleteUser={handleDeleteUser}
            closeModal={() => setIsConfirmDelete(false)}
          />,
          document.querySelector("#modal-root")
        )}

      {isSentInvitationModal &&
        createPortal(
          <InvitationSent closeModal={() => setIsSentInvitationModal(false)} />,
          document.querySelector("#modal-root")
        )}

      {isSmallSize ? (
        <ManageModal actionsList={actionsList} onClose={onClose} />
      ) : (
        <Wrapper ref={ref}>
          {actionsList.map(
            (item, index) =>
              !item.isHidden && (
                <Element onClick={() => onActionClick(item)} key={index}>
                  <ActionIcon name={item.icon} color="var(--black)" />
                  <Text>{item.text}</Text>
                </Element>
              )
          )}
        </Wrapper>
      )}
    </>
  )
}
