import { useCallback, useEffect, useMemo } from "react"

import {
  buildEventsWithInner,
  checkIfEventsConflict,
  EVENT_TYPES,
} from "@utils/calendarUtils"
import dayjs from "utils/dayjs"

import { getNotAvailableZone, getUpdatedNewSuggestion } from "../helpers"

import type { Dayjs } from "dayjs"

interface Props {
  newGuestSuggestion: any
  setEventsArr: any
  guestSuggestions: any
  externalEvents: any
  currentSelectedDay: Dayjs
}

export const useFilteredEvents = ({
  newGuestSuggestion,
  setEventsArr,
  guestSuggestions,
  externalEvents,
  currentSelectedDay,
}: Props) => {
  const filterEvents = useCallback(
    events => {
      return events
        .filter(({ start, startDate }) =>
          dayjs(start || startDate).isSame(currentSelectedDay, "date")
        )
        .map(({ start, startDate, end, endDate, ...rest }) => ({
          ...rest,
          start: dayjs(start || startDate).toDate(),
          end: dayjs(end || endDate).toDate(),
        }))
    },
    [currentSelectedDay]
  )

  const teeupSuggestions = useMemo(() => {
    return buildEventsWithInner(
      filterEvents(guestSuggestions),
      EVENT_TYPES.teeup
    )
  }, [newGuestSuggestion, guestSuggestions])

  const setListsConflicts = useCallback(events => {
    return events.reduce((acc, current) => {
      const updatedCurrent = { ...current }

      events.forEach(event => {
        const isConflict = checkIfEventsConflict(current, event)
        if (isConflict && current.id !== event.id) {
          updatedCurrent.isConflict = true
        }
      })

      acc.push(updatedCurrent)

      return acc
    }, [])
  }, [])

  const groupedExternalEvents = useMemo(() => {
    return buildEventsWithInner(externalEvents, EVENT_TYPES.calendar)
  }, [externalEvents])

  // updating the eventsArr value for rendering the events in calendar view
  useEffect(() => {
    const filteredTeeupEvents = teeupSuggestions
    const filteredExternalEvents = filterEvents(groupedExternalEvents)
    const combinedList = setListsConflicts([
      ...filteredTeeupEvents,
      ...filteredExternalEvents,
    ])
    if (!newGuestSuggestion) return

    const newEventsArr = [...combinedList]

    if (currentSelectedDay.isSameOrBefore(dayjs(), "date")) {
      const notAvailableZone = getNotAvailableZone(currentSelectedDay)
      newEventsArr.unshift(notAvailableZone)
    }

    const updatedNewSuggestion = getUpdatedNewSuggestion(newGuestSuggestion)
    // @ts-ignore
    if (!updatedNewSuggestion?.isHidden) {
      newEventsArr.push(updatedNewSuggestion)
    }
    setEventsArr(newEventsArr)
  }, [teeupSuggestions, groupedExternalEvents, newGuestSuggestion])
}
