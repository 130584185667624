import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.section`
  ${({ $photo }) => css`
    width: 100%;
    height: 174px;
    min-height: 174px;
    background: ${$photo
      ? `url("${$photo}") center / cover no-repeat`
      : "var(--gray3)"};
    border-radius: 8px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    padding: 20px 24px;

    @media (max-width: ${MEDIA_SIZES.MOBILE}) {
      width: 100vw;
      height: 60px;
      min-height: 60px;
      padding: 4px 16px;
      border-radius: 0;
    }
  `};
`

export const Line = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const DropdownWrapper = styled.div`
  position: relative;

  .dropdown.dropdown--high {
    margin-top: 0;
  }
`

export const SettingsWrapper = styled.div`
  ${({ $isDisabled }) => css`
    width: 44px;
    height: 44px;
    background: var(--transparentWhite);
    border-radius: 50%;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.4;
    }
    ${$isDisabled &&
    css`
      opacity: 0.4;
    `};
  `};
`

export const SettignsIcon = styled(SvgIcon)`
  width: 15px;
  height: 15px;
`
