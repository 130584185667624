import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  teeupStageButtonLine: {
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(14),
    backgroundColor: AppColors.brand.white,
    marginBottom: Units.responsiveValue(1),
  },
  sectionMargin: { marginBottom: Units.responsiveValue(12) },
  buttonTitle: {
    flexGrow: 1,
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black,
    textAlign: "left",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
  unscheduledText: {
    flexGrow: 1,
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
    textAlign: "left",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.24),
  },
})
