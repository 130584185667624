import { StyleSheet } from "react-native"

import { AppFonts, AppColors, Units } from "@theme"

export const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatarStyle: {
    // marginRight: Units.responsiveValue(6),
    // flexDirection: "row",
    // alignSelf: "baseline",
    // alignItems: "center",
    // marginLeft: "8px",
    // marginRight: "8px",
    // marginTop: "8px",
    // padding: "12px",
  },

  headerTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
  },
  headerTitleInvitationText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    height: "52px",
  },
  nudgeSuggestionTitle: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    marginLeft: "47px",
    // display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
  },
  nudgeTitle: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    marginLeft: "42px",
  },
  pendindNudgesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  pendindNudgesTitle: {
    paddingLeft: "2px",
    paddingTop: "8px",
  },
  nudgesIconAndTitle: {
    paddingLeft: "8px",
    paddingTop: "8px",
  },
  nudgesIcon: {
    width: "10px",
    height: "10px",
  },
  userInfoContainer: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    padding: "8px",
    // marginRight: "8px",
    // marginTop: "8px",
  },
  userInfoPromptContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px",
  },
  usernameContainer: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    marginTop: "8px",
  },
  wouldYouLikeTextContainer: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    marginTop: "8px",
  },
  usernameNudgeContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginLeft: "8px",
  },
  username: {
    marginLeft: "8px",
  },
  suggestionText: {
    marginLeft: "8px",
  },
  userInfo: {
    marginTop: "8px",
  },
  nudgeText: {
    display: "flex",
    marginLeft: "8px",
  },
  nudgeSuggestionTime: {
    marginLeft: "20px",
  },
  nudgeSuggestionPlace: {
    marginLeft: "20px",
  },
  suggestionIcon: {
    width: "10px",
    height: "10px",
    marginRight: "2px",
    padding: "2px",
  },
  headerUserRemovedTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    paddingLeft: "8px",
  },
  headerMentionTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    paddingLeft: "8px",
    paddingTop: "8px",
    display: "flex",
  },
  headerMultipleNudgesTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    paddingLeft: "8px",
    paddingTop: "12px",
    paddingBottom: "8px",
  },
  headerUserRespondedTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    paddingLeft: "8px",
    paddingTop: "8px",
    paddingRight: "8px",
    display: "flex",
  },
  respondedText: {
    marginLeft: "4px",
  },
  nudgeHeaderTitleText: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    display: "flex",
    flexDirection: "column",
  },
  contentBetween: {
    justifyContent: "space-between",
  },
  headerContent: {
    width: "384px",
    backgroundColor: "#fff",
    padding: "8px",
    margin: "12px 4px 17px 16px",
    borderRadius: "4px",
  },
  nudgeInfoHeaderContent: {
    // height: "85px",
    width: "384px",
    margin: "12px 4px 16px 16px",
    borderRadius: "4px",
    backgroundColor: "#f0f2f5",
    paddingRight: "8px",
    paddingBottom: "4px",
    // paddingRight: "8px",
    // paddingLeft: "8px",
    // paddingTop: "8px",
    // paddingBottom: "7.2px",
  },
  archivePrompOverviewPage: {
    height: "92px",
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
    paddingRight: "4px",
    paddingTop: "12px",
    paddingLeft: "12px",
  },

  overviewPrompts: {
    position: "absolute",
    minWidth: "336px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    marginTop: "4px",
    marginRight: "4px",
    marginLeft: "4px",
    paddingRight: "8px",
    paddingTop: "4px",
  },
  largerDiv: {
    borderRadius: "8px",
    backgroundColor: "#fff",
    backgroundPosition: "center",
    // backgroundSize: "cover",
    // boxSizing: "border-box",
    // marginRight: "12px",
    height: "58px",
    flexDirection: "row",
    alignItems: "center",
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    color: AppColors.brand.warmGrey2,
  },
  invitation: {
    width: "384px",
    margin: "12px 4px 16px 16px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    paddingRight: "8px",
    paddingBottom: "4px",
  },
  isKickedOutContent: {
    display: "flex",
    flexDirection: "column",
    height: "85px",
    // width: "384px",
    // margin: "12px 4px 16px 16px",
    //marginTop: "18px",
    // marginRight: "8px",
    // borderRadius: "4px",
    // backgroundColor: "#fff",
    // paddingRight: "8px",
    // paddingLeft: "8px",
    // paddingTop: "8px",
    // paddingBottom: "7.2px",
  },
  removedUserContainer: {
    // display: "flex",
  },
  removedUserButtons: {
    marginLeft: "190px",
  },
})

export const IconProps = {
  email: {
    type: "material-icons",
    name: "email",
    size: Units.responsiveValue(13),
    color: AppColors.brand.pink,
  },
}

export const headerContainer = (
  fromNowString,
  isArchivePromptShow,
  isEnded,
  isKickedOut
) => ({
  display: "flex",
  justifyContent: "end",
  marginLeft: "auto",
  flex: "1",
  paddingTop: "12px",
  width: "100%",

  backgroundColor:
    !isEnded && !isKickedOut ? "white" : "AppColors.brand.grey243",
  borderRadius: Units.responsiveValue(12),
  ...(!fromNowString &&
    !isArchivePromptShow && {
      // backgroundColor: AppColors.brand.grey243,
      backgroundColor: AppColors.brand.cardBackground,
    }),
})

export const invitationRow = (additionalPadding, directionColumn) => ({
  // ...(additionalPadding ? { paddingLeft: Units.responsiveValue(10) } : { marginLeft: Units.responsiveValue(40) }),
  flexDirection: directionColumn ? "column" : "row",
  alignItems: "center",
  marginLeft: 8,
})
