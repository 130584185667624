import { useMemo } from "react"

export const useCalendarControl = ({
  googlePrimaryCalendar,
  outlookPrimaryCalendar,
}) => {
  return useMemo(
    () => ({
      isAddCalendarBlock: !googlePrimaryCalendar || !outlookPrimaryCalendar,
      isAllLinked: !!googlePrimaryCalendar && !!outlookPrimaryCalendar,
    }),
    [googlePrimaryCalendar, outlookPrimaryCalendar]
  )
}
