import { CONTACTS_TABS_LIST } from "@constants/contacts"

export const getContactsByCategories = ({
  activeTab,
  bookContacts,
  cooweContacts,
}) => {
  switch (activeTab) {
    case CONTACTS_TABS_LIST.ALL:
      return [...bookContacts, ...cooweContacts]
    case CONTACTS_TABS_LIST.CONTACTS:
      return bookContacts
    case CONTACTS_TABS_LIST.COOWE_PEOPLE:
      return cooweContacts
    default:
      return []
  }
}
