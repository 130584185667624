import React, { createContext, useState } from "react"

export const TeeupPageContext = createContext({})

export const TeeupPageState = ({ children }) => {
  const [isTeeUpSettingsOpen, setIsTeeUpSettingsOpen] = useState(false)
  const [isInvitePeopleOpen, setIsInvitePeopleOpen] = useState(false)

  return (
    <TeeupPageContext.Provider
      value={{
        isTeeUpSettingsOpen,
        setIsTeeUpSettingsOpen,
        isInvitePeopleOpen,
        setIsInvitePeopleOpen,
      }}>
      {children}
    </TeeupPageContext.Provider>
  )
}
