import React from "react"

import propTypes from "prop-types"
import { View, TouchableOpacity, Image, Text, StyleSheet } from "react-native"

import { AppColors, Units } from "@theme"
import { images } from "@utils/imageUtils"

const TagButton = ({ onPress, value }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.button}>
      <Text style={styles.buttonTitle}>{value}</Text>
      <Image style={styles.close} source={images.close} />
    </View>
  </TouchableOpacity>
)

TagButton.propTypes = {
  onPress: propTypes.func.isRequired,
  value: propTypes.string,
}

export default TagButton

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 0,
    marginBottom: Units.responsiveValue(12),
    paddingLeft: Units.responsiveValue(12),
    flexDirection: "row",
  },
  button: {
    paddingHorizontal: Units.responsiveValue(8),
    paddingVertical: Units.responsiveValue(3),
    marginRight: Units.responsiveValue(8),
    marginBottom: 4,
    borderRadius: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonTitle: {
    fontFamily: "greycliffcf-demibold-salt",
    color: "#222222",
    lineHeight: Units.responsiveValue(13),
    letterSpacing: -Units.responsiveValue(0.3),
    fontSize: 12,
  },
  close: {
    tintColor: AppColors.brand.warmGrey2,
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(7),
    marginLeft: Units.responsiveValue(6),
  },
})
