import React, { useEffect } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { setAuth0Type } from "@actions/loginActions"
import { loginProviders } from "@config/enums"

import { logScreen, SCREENS } from "../../analytics-tracking"
import {
  AuthButtons,
  AuthWrapper,
  OrLine,
} from "../../assets/styles/authButtonsStyles"
import { AUTH0_TYPES } from "../../constants/auth0Types"
import { BUTTON_VARIANTS } from "../../constants/button"
import { useTopLevelContext } from "../../contexts/TopLevelContext"
import { AuthTabs } from "../AuthTabs"
import { AuthButton, ButtonEl } from "../common"

const SingleSignIn = ({ isHiddenTabs, setAuth0Type }) => {
  const { setIsLoginInProgress } = useTopLevelContext()
  const { loginWithPopup } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    logScreen(SCREENS.SIGN_IN, { type: "socialNetworks" })
  }, [])

  const handleGoogleLogin = async event => {
    event.preventDefault()
    setIsLoginInProgress(true)
    setAuth0Type(AUTH0_TYPES.GOOGLE)
    loginWithPopup({
      connection: loginProviders.google,
    })
  }

  const handleFbLogin = event => {
    event.preventDefault()
    setIsLoginInProgress(true)
    setAuth0Type(AUTH0_TYPES.FACEBOOK)
    loginWithPopup({
      connection: loginProviders.fb,
    })
  }

  const handleOutlookLogin = event => {
    event.preventDefault()
    setIsLoginInProgress(true)
    setAuth0Type(AUTH0_TYPES.OUTLOOK)
    loginWithPopup({
      connection: loginProviders.outlook,
    })
  }

  const handleTwitterLogin = async event => {
    event.preventDefault()

    setIsLoginInProgress(true)
    setAuth0Type(AUTH0_TYPES.TWITTER)
    await loginWithPopup({
      connection: loginProviders.twitter,
    })
  }

  const handleAppleLogin = event => {
    event.preventDefault()
    setIsLoginInProgress(true)
    setAuth0Type(AUTH0_TYPES.APPLE)
    loginWithPopup({
      connection: loginProviders.apple,
    })
  }

  return (
    <AuthWrapper>
      {!isHiddenTabs && <AuthTabs />}

      <AuthButtons>
        <AuthButton
          text="Log in with Google"
          dataTestId="singInWithGoogleButton"
          iconType={AUTH0_TYPES.GOOGLE}
          onClick={handleGoogleLogin}
        />

        <AuthButton
          text="Log in with Apple"
          iconType={AUTH0_TYPES.APPLE}
          onClick={handleAppleLogin}
        />

        <AuthButton
          text="Log in with Twitter"
          iconType={AUTH0_TYPES.TWITTER}
          onClick={handleTwitterLogin}
        />

        <AuthButton
          text="Log in with Facebook"
          iconType={AUTH0_TYPES.FACEBOOK}
          onClick={handleFbLogin}
        />

        <AuthButton
          text="Log in with Outlook"
          iconType={AUTH0_TYPES.OUTLOOK}
          onClick={handleOutlookLogin}
        />

        <OrLine />

        <ButtonEl
          fullWidth
          testID="singInWithEmailButton"
          text="Login with email"
          variant={BUTTON_VARIANTS.ROUNDED}
          onClick={() => history.push("/new-sign-in-email")}
        />
      </AuthButtons>
    </AuthWrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  setAuth0Type: type => dispatch(setAuth0Type(type)),
})

export default connect(null, mapDispatchToProps)(SingleSignIn)
