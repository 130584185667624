import React from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { useNavigationLabel } from "../hooks"

import { Wrapper, DateLabel, PrevIcon } from "./arrowNavigationStyles"

export const ArrowNavigation = ({ daysList, setSelectedWeekDay }) => {
  const goPrevWeek = () => {
    setSelectedWeekDay(prev => {
      return prev.clone().subtract(1, "week")
    })
  }

  const goNextWeek = () => {
    setSelectedWeekDay(prev => {
      return prev.clone().add(1, "week")
    })
  }

  const navigationLabel = useNavigationLabel(daysList)

  const renderButton = isNext => {
    const clickAction = isNext ? goNextWeek : goPrevWeek

    return (
      <ButtonEl
        autoWidth
        variant={BUTTON_VARIANTS.TRANSPARENT}
        text=""
        onClick={clickAction}>
        <PrevIcon $isNext={isNext} name="arrow-2" color="var(--black)" />
      </ButtonEl>
    )
  }

  return (
    <Wrapper>
      {renderButton()}

      <DateLabel>{navigationLabel}</DateLabel>

      {renderButton(true)}
    </Wrapper>
  )
}

ArrowNavigation.propTypes = {
  daysList: PropTypes.array,
  setSelectedWeekDay: PropTypes.func,
}
