import React from "react"

import PropTypes from "prop-types"

import { LabelNewWrapper, LabelNewText } from "../suggestionItemStyles"

export const SuggestionLabel = ({ isDot, isRight, text, color }) => (
  <LabelNewWrapper $isRight={isRight}>
    <LabelNewText $color={color}>
      {isDot && <b>&#8226;</b>} {text}
    </LabelNewText>
  </LabelNewWrapper>
)

SuggestionLabel.propTypes = {
  isDot: PropTypes.bool,
  isRight: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
}
