import { images } from "@utils/imageUtils"

export const leftSideData = [
  {
    icon: images.ndSettingsMuteTeeUp,
    alt: "mute",
    title: "Mute the TeeUp",
  },
  {
    icon: images.ndSettingsLeaveTeeUp,
    alt: "leave",
    title: "Leave the Teeup",
  },
  {
    icon: images.ndSettingsCancelTeeUp,
    alt: "cancel",
    title: "Cancel the TeeUp",
  },
]

export const rightSideData = [
  {
    title: "Invite Others",
    value: true,
    settingType: "allowInviteOthers",
  },
  {
    title: "Change TeeUp Title",
    value: true,
    settingType: "allowChangeTitle",
  },
  {
    title: "Mark Game Plan items as Decided",
    value: true,
    settingType: "allowDecideGameplan",
  },
  {
    title: "Change TeeUp to All Set or Planning",
    value: false,
    settingType: "allowPromoteToAllSetOrPlanning",
  },
  {
    title: "Set the TeeUp as Ended",
    value: false,
    settingType: "allowPromoteToEnded",
  },
  {
    title: "Invite via link",
    value: true,
    settingType: "enableSharedLink",
  },
]

// allowAutoEnded: true
// allowAutoScheduled: false
// allowChangeGameplanOptions: false
// allowChangeState: true
// allowChangeTitle: true
// allowDecideGameplan: true
// allowInviteOthers: true
// allowNotifyBeforeHappening: true
// allowPromoteToAllSetOrPlanning: true
// allowPromoteToEnded: true
// allowSelectGameplan: true
// criticalMassAmount: 2
// enableJoinRequestApproval: true
// enableSharedLink: false
// timeBeforeAutoEnded: 12
// whenToNotifyBeforeHappening: 1
