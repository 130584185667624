import React from "react"

import { ToggleButton } from "@ui/settingsClickableRow/actionButtons"

const TeeupSettingsRow = ({
  item,
  onChange,
  isMutedTeeUp,
  toggle,
  theLast,
}) => {
  return (
    <div
      className="teeup-settings__button"
      onClick={() => onChange()}
      style={{
        border: theLast && 0,
        flexDirection: item.description && "column",
        alignItems: item.description && "flex-start",
        justifyContent: item.description && "center",
        height: item.description && "81px",
      }}>
      {item.icon && (
        <img src={item.icon} alt={item.alt} style={{ marginRight: 10 }} />
      )}
      {item.title}
      {item.description && (
        <p className="teeup-settings__button--description">
          {item.description}
        </p>
      )}
      {toggle && (
        <div data-testid={item.title} className="teeup-settings__toggle-button">
          <ToggleButton
            value={isMutedTeeUp ? isMutedTeeUp : item.value}
            onToggle={() => onChange}
          />
        </div>
      )}
    </div>
  )
}

export default TeeupSettingsRow
