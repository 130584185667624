import styled, { css } from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"

export const Headline = styled(NSubtitle)`
  ${({ $isBlue }) =>
    $isBlue &&
    css`
      color: var(--darkBlue);
    `};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${NFontWeight.bold};
`

export const SuggestionChip = styled.div`
  border: 1px solid var(--lightBlue6);
  background-color: var(--lightBlue3);
  color: var(--darkBlue);
  padding: 2px 6px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
`
