import { TextField } from "@ui/TextField"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

const Name = () => {
  const selectedName = useSuggestionInPersonStore(state => state.selectedName)
  const selectedAddress = useSuggestionInPersonStore(
    state => state.selectedAddress
  )
  const setSelectedName = useSuggestionInPersonStore(
    state => state.setSelectedName
  )
  const currentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.currentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionInPersonStore(
    state => state.updateSuggestion
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedName(e.target.value)
    if (currentActiveSuggestionIndex !== -1) {
      updateSuggestion(currentActiveSuggestionIndex, { name: e.target.value })
    }
  }

  if (!selectedName && !selectedAddress) return false

  return (
    <div className="suggest-where-modal-block">
      <h4>Name</h4>
      <TextField
        placeholder="Name"
        value={selectedName}
        onChange={handleChange}
      />
    </div>
  )
}

export default Name
