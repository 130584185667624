import React, { useMemo } from "react"

import ct from "countries-and-timezones"
import momentTimezone from "moment-timezone"

import Avatar from "@ui/avatar"

const TimeByTimeZone = ({
  time,
  people,
  you = false,
  datesPeriod = null,
  date = new Date(),
}) => {
  const timezone = useMemo(
    () => people[0].timezone || String(momentTimezone()),
    [people]
  )

  const timezoneInfo = useMemo(() => ct.getTimezone(timezone), [timezone])

  const participantsLabel = useMemo(() => {
    const firstName = you ? "You" : people[0].username

    switch (people.length) {
      case 1:
        return firstName
      case 2:
        return `${firstName} and ${people[1].username}`
      default:
        return `${firstName} and ${people.length - 1} others`
    }
  }, [people, you])

  const timezoneLabel = useMemo(() => {
    if (!timezoneInfo) return ""
    return `${timezoneInfo.country} (${momentTimezone()
      .tz(timezoneInfo.name) //.zoneName() //.zoneAbbr() // both options valid but return the same thing as .format('z')
      .format("z")})` // ?NOTE this is not optimal solution, as moment timezone has some internal logic that cannot be controlled and for some timezones it returns time difference instead of abbreviation
  }, [timezoneInfo])

  const timezoneDates = useMemo(() => {
    const start = momentTimezone(datesPeriod ? datesPeriod[0] : date)
      .hour(time.start)
      .minute(0)
      .tz(timezone)
      .seconds(0)
      .milliseconds(0)

    const end = momentTimezone(
      datesPeriod ? datesPeriod[1] || datesPeriod[0] : date
    )
      .hour(time.end)
      .minute(0)
      .tz(timezone)
      .seconds(0)
      .milliseconds(0)

    const startDate = start.format("dddd MMM D")
    const endDate = end.format("dddd MMM D")
    const startTime = start.format("hh:mm A")
    const endTime = end.format("hh:mm A")

    return {
      date: startDate === endDate ? startDate : `${startDate} - ${endDate}`,
      time: `${startTime} - ${endTime}`,
    }
  }, [time, date, timezone])

  return (
    <div className="time-by-time-zone">
      <div
        className="time-by-time-zone__avatars"
        style={{ width: `${36 + (people.length - 1) * 22}px` }}>
        {people?.map(({ avatar }, index) => {
          if (index > 19) {
            return false
          }
          return (
            <div
              key={index}
              className="time-by-time-zone__person"
              style={{ left: `${index * 22}px`, zIndex: 25 - index }}>
              {index === 19 && people.length > 20 && (
                <div className="time-by-time-zone__person-shadow">{`+${
                  people.length - 20
                }`}</div>
              )}
              <Avatar
                size={36}
                innerContentStyle={{
                  borderColor: "#ffffff",
                  border: "solid 2px",
                }}
                imageUrl={avatar}
              />
            </div>
          )
        })}
      </div>
      <div className="time-by-time-zone__date-time">
        <span>{participantsLabel}</span>
        <span>{timezoneLabel}</span>
      </div>

      <div className="time-by-time-zone__date-time">
        <span>{timezoneDates.date}</span>
        <span>{timezoneDates.time}</span>
      </div>
    </div>
  )
}

export default TimeByTimeZone
