import React, { useContext } from "react"

import { useDetectClickOutside } from "react-detect-click-outside"

import { TimeWheelContext } from "@contexts/TimeWheelContext"

import { InnerEventsLabel, EventName } from "../atoms"
import {
  useEventMargin,
  useEventParams,
  useEventWidth,
  useModifiedEvents,
  useSameHour,
  useEventsColors,
} from "../hooks"
import { InnerEventsList } from "../molecules"

import { ListWrapper, EventItem } from "./eventsListStyles"

export const EventsList = ({ eventsRows, currentHour }) => {
  const { openedInnerEvents, setOpenedInnerEvents } =
    useContext(TimeWheelContext)

  const toggleInnerEvents = eventKey => {
    if (openedInnerEvents === eventKey) {
      setOpenedInnerEvents(null)
    } else {
      setOpenedInnerEvents(eventKey)
    }
  }

  const ref = useDetectClickOutside({
    onTriggered: () => setOpenedInnerEvents(null),
  })
  const { mappedEventsList } = eventsRows
  const isSameHour = useSameHour(currentHour)
  const getEventWidth = useEventWidth()
  const { getMarginLeft } = useEventMargin()
  const modifiedEvents = useModifiedEvents(mappedEventsList)
  const generateEventParams = useEventParams({
    getEventWidth,
  })
  const getEventColor = useEventsColors()

  return (
    <ListWrapper>
      {modifiedEvents.map((event, eventIndex) => {
        const { eventKey, eventWidth, isInnerEvents } = generateEventParams({
          event,
          eventIndex,
        })
        const eventColors = getEventColor(event.type, event?.calendarType)

        return isSameHour(event) ? (
          <>
            {event?.startColumn < 2 && (
              <EventItem
                ref={ref}
                width={eventWidth}
                marginLeft={getMarginLeft(event.startDate)}
                $colors={eventColors}
                startColumn={event?.startColumn}
                title={event.name}
                onClick={() => toggleInnerEvents(eventKey)}
                key={eventIndex}>
                <EventName textColor={eventColors.main} name={event.name} />

                {isInnerEvents && (
                  <InnerEventsLabel
                    event={event}
                    textColor={eventColors.main}
                  />
                )}

                {openedInnerEvents === eventKey && (
                  <InnerEventsList innerEvents={event.innerEvents} />
                )}
              </EventItem>
            )}
          </>
        ) : null
      })}
    </ListWrapper>
  )
}
