import React from "react"

import PropTypes from "prop-types"
import OutsideClickHandler from "react-outside-click-handler"

import CloseIcon from "@assets/images/close-grey-2.svg"
import SuccessIcon from "@assets/images/success.svg"

import { IconButtonEl } from "../../components/common"

import { Warning, SuccessImg, Title, Description } from "./molecules"

export const ConfirmDeleteAccoutPopup = ({ title, description, onClose }) => (
  <OutsideClickHandler onOutsideClick={onClose && onClose}>
    <Warning isHaveClose={!!onClose}>
      {onClose && (
        <IconButtonEl icon={CloseIcon} onClick={onClose} alt="close" />
      )}
      <SuccessImg src={SuccessIcon} alt="warning" />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Warning>
  </OutsideClickHandler>
)

ConfirmDeleteAccoutPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
