import React from "react"

import PropTypes from "prop-types"
import { View } from "react-native"

import { getDateWithTimezone, isDateBeforeNow } from "@utils/dateUtils"
import { DATE_SELECT_TYPES } from "@utils/gamePlanUtils"

import DatePicker from "./datePicker"
import DurationPicker from "./durationPicker"
import OverlayContainer from "./overlayContainer"
import TimeDurationPicker from "./timeDurationPicker"
import TimePicker from "./timePicker"
import TimeRangePicker from "./timeRangePicker"

const TimePickerView = ({ type, onCancelPress, onSelectPress, ...props }) => {
  const renderPicker = () => {
    if (type === DATE_SELECT_TYPES.date) {
      return <DatePicker {...props} />
    }
    if (type === DATE_SELECT_TYPES.time) {
      return <TimePicker {...props} />
    }
    if (type === DATE_SELECT_TYPES.duration) {
      return <DurationPicker {...props} />
    }
    if (type === DATE_SELECT_TYPES.timeDuration) {
      return <TimeDurationPicker {...props} />
    }
    if (type === DATE_SELECT_TYPES.timeRange) {
      return <TimeRangePicker {...props} />
    }
    return <View />
  }

  const { isStartDateToday, startTime, endTime } = props
  const tzLongerThan6Hrs =
    props.isMultiTimeZone &&
    getDateWithTimezone(startTime)
      .add(6, "hours")
      .add(1, "minute")
      .isBefore(getDateWithTimezone(endTime))
  const disableSelection =
    type === DATE_SELECT_TYPES.timeRange &&
    (getDateWithTimezone(endTime).isBefore(getDateWithTimezone(startTime)) ||
      (isStartDateToday && isDateBeforeNow(startTime)) ||
      tzLongerThan6Hrs)
  return (
    <OverlayContainer
      onCancelPress={onCancelPress}
      onSelectPress={onSelectPress}
      disableSelect={disableSelection}>
      {renderPicker()}
    </OverlayContainer>
  )
}

TimePickerView.propTypes = {
  type: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  onSelectPress: PropTypes.func,
  onCancelPress: PropTypes.func,
  isStartDateToday: PropTypes.bool,
  isMultiTimeZone: PropTypes.bool,
}

export default TimePickerView
