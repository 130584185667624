import React from "react"

import { getBase64, images } from "@utils/imageUtils"

export const ImageButtons = ({ setImage, setIsImageChanged, removeImage }) => (
  <div className="teeup-settings__image--buttons">
    <div className="teeup-settings__image--button">
      <img src={images.ndAddTeeUpImage} />

      <input
        className="teeup-settings__image--input"
        type="file"
        onChange={e => {
          const file = e.target.files[0]
          getBase64(file).then(base64 => {
            setImage(base64)
            setIsImageChanged(true)
          })
        }}
      />
    </div>

    <div
      className="teeup-settings__image--button"
      onClick={() => removeImage()}>
      <img src={images.ndRemoveTeeUpImage} alt="remove teeup" />
    </div>
  </div>
)
