import React from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, Switch } from "react-native"
// import Collapsible from 'react-native-collapsible'
// import { WheelPicker } from 'react-native-wheel-picker-simple'

import { generateDaysArray } from "@utils/dateUtils"

import pickerKeys from "./pickerKeys"
import { ViewStyles, ViewProps } from "./timePickerOverlayStyles"

const dateArray = generateDaysArray()

const isIndexSet = index => index !== undefined

const DatePicker = ({
  startDateVal,
  showDatePicker,
  showEndDatePicker,
  onDateSelected,
  onEndDateSelected,
  endDateVal,
  onToggleEndEnable,
  isEndEnabled,
  startDateIndex,
  endDateIndex,
  isTimeInterval,
  onPickerHeaderPress,
}) => {
  const onStartDatePickerPress = () => {
    if (!isTimeInterval) {
      onPickerHeaderPress({
        pickerKey: pickerKeys.DATE,
        closePickerKey: pickerKeys.END_DATE,
      })
    }
  }
  const onEndDatePickerPress = () => {
    if (!isTimeInterval) {
      onPickerHeaderPress({
        pickerKey: pickerKeys.END_DATE,
        closePickerKey: pickerKeys.DATE,
      })
    }
  }

  const handleEndDateSelected = index => {
    const updatedIndex =
      isIndexSet(startDateIndex) && isIndexSet(index)
        ? index + startDateIndex
        : index
    onEndDateSelected(updatedIndex)
  }

  return (
    <React.Fragment>
      <TouchableOpacity
        style={ViewStyles.rowContainer}
        onPress={onStartDatePickerPress}>
        <Text style={ViewStyles.rowTitle}>
          {isTimeInterval ? "Date" : "Start Date"}
        </Text>
        <Text style={ViewStyles.rowValueActive}>{startDateVal}</Text>
      </TouchableOpacity>
      <View key={"date"} collapsed={!showDatePicker}>
        <View style={ViewStyles.pickerContainer}>
          {/* <WheelPicker
                        nestedScrollEnabled
                        selectedItem={startDateIndex}
                        data={dateArray}
                        onItemSelected={onDateSelected}
                    /> */}
        </View>
      </View>

      {!isTimeInterval && (
        <TouchableOpacity
          style={ViewStyles.rowContainer}
          onPress={onEndDatePickerPress}>
          <Text style={[ViewStyles.rowTitle, ViewStyles.rowTitleSmall]}>
            End Date
          </Text>
          <Switch
            trackColor={ViewProps.navButtonColor}
            onTintColor={ViewProps.navButtonColor}
            thumbColor={null}
            value={isEndEnabled}
            onValueChange={onToggleEndEnable}
          />
          <View style={ViewStyles.flex} />
          <Text style={ViewStyles.rowValue}>{endDateVal}</Text>
        </TouchableOpacity>
      )}
      <View>
        <View key={"date"} collapsed={!showEndDatePicker}>
          <View style={ViewStyles.pickerContainer}>
            {/* <WheelPicker
                            selectedItem={
                                isIndexSet(startDateIndex) &&
                                isIndexSet(endDateIndex)
                                    ? endDateIndex - startDateIndex
                                    : endDateIndex
                            }
                            data={dateArray.slice(startDateIndex)}
                            onItemSelected={handleEndDateSelected}
                        /> */}
          </View>
        </View>
      </View>
    </React.Fragment>
  )
}

DatePicker.propTypes = {
  startDateVal: PropTypes.string,
  endDate: PropTypes.string,
  endDateVal: PropTypes.string,
  startDate: PropTypes.string,
  showDatePicker: PropTypes.bool,
  showEndDatePicker: PropTypes.bool,
  minuteInterval: PropTypes.number,
  onDateSelected: PropTypes.func,
  onEndDateSelected: PropTypes.func,
  onToggleEndEnable: PropTypes.bool,
  isEndEnabled: PropTypes.bool,
  startDateIndex: PropTypes.number.isRequired,
  endDateIndex: PropTypes.number.isRequired,
  isTimeInterval: PropTypes.bool,
  onPickerHeaderPress: PropTypes.func,
}

export default DatePicker
