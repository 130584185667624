import styled from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle2,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
`

export const Title = styled(NTitle2)`
  font-weight: ${NFontWeight.semiBold};
`

export const Description = styled(NCaptionS)`
  color: var(--gray5);
  font-weight: ${NFontWeight.medium};
`
