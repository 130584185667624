import React from "react"

import cn from "classnames"
import { IconContext } from "react-icons"
import { FaCheckCircle, FaTimesCircle, FaCircle } from "react-icons/fa"
import { connect } from "react-redux"
import { useHistory, Redirect } from "react-router-dom"
import { bindActionCreators } from "redux"

import "./index.scss"
import { resetPassword, PasswordChange } from "@actions/userActions"

import logo from "../../assets/images/teeUpsPink.svg"
import {
  CHANGE_PASSWORD_REQUIREMENTS,
  PASSWORD_RULES,
} from "../../constants/password"
import usePassword from "../SignUpPage/usePassword"
import StyledInput from "../components/StyledInput/StyledInput"

const ChangeTempPassword = ({
  resetPassword,
  PasswordChange,
  resetPassErrorMessage,
  loggedChangePassword,
  accessToken,
  setupTeeupListSocket,
  userId,
  setStep,
}) => {
  const history = useHistory()
  const token = accessToken ? accessToken : history.location.state

  const {
    password,
    confirmPassword,
    changePassword: setPassword,
    changeConfirmPassword: setConfirmPassword,
    clearStatus,
    failedRequirements,
    isValid,
    visiblePasswordError,
    visiblePasswordErrorDetail,
    visibleConfirmPasswordError,
  } = usePassword()

  const getRuleIcon = failedRule =>
    failedRule ? (
      <IconContext.Provider value={{ color: "#ff0000" }}>
        <FaTimesCircle />
      </IconContext.Provider>
    ) : (
      <IconContext.Provider value={{ color: "#33b542" }}>
        <FaCheckCircle />
      </IconContext.Provider>
    )

  const handleChangeTempPassword = async () => {
    //Your new password must be different from your previous password
    if (loggedChangePassword) {
      await PasswordChange(password, token)
      setStep(3)
    } else {
      resetPassword(password, token)
    }
  }

  return token ? (
    <div className="forgot__page" style={{ position: "relative" }}>
      <div className="forgot__content">
        <img className="forgot__icon" src={logo} />

        <span className="reset_password__title">
          {loggedChangePassword ? "Set Password" : "Change Password"}
        </span>
        <p className="reset_password__subtitle">
          In order to protect your account, make sure your password:
        </p>
        <span className="reset_password__requirements_list">
          {CHANGE_PASSWORD_REQUIREMENTS.map(req => {
            const failedRule = failedRequirements.includes(req.type)

            return (
              <div key={`req-${req.type}`} className="requirements__item">
                {password ? (
                  getRuleIcon(failedRule)
                ) : (
                  <IconContext.Provider value={{ color: "#3e3e3e" }}>
                    <FaCircle />
                  </IconContext.Provider>
                )}
                <span className="requirements__text">{req.text}</span>
              </div>
            )
          })}

          <div className="requirements__item">
            {password ? (
              getRuleIcon(resetPassErrorMessage === PASSWORD_RULES.PREVIOUS)
            ) : (
              <IconContext.Provider value={{ color: "#3e3e3e" }}>
                <FaCircle />
              </IconContext.Provider>
            )}
            <span className="requirements__text">
              Not be a previously used password
            </span>
          </div>
        </span>
        <StyledInput
          type="password"
          value={password}
          setValue={setPassword}
          placeholder="Create New Password"
          isValid={!visiblePasswordError}
          validationError={"Please enter a valid password"}
        />
        <StyledInput
          type="password"
          value={confirmPassword}
          setValue={setConfirmPassword}
          placeholder="Confirm Password"
          isValid={!visibleConfirmPasswordError}
          validationError={visibleConfirmPasswordError}
        />
        <span className="reset_password--error">
          {resetPassErrorMessage === PASSWORD_RULES.PREVIOUS
            ? ""
            : resetPassErrorMessage}
        </span>
        <button
          data-testid="continueButton"
          disabled={!isValid}
          style={{ marginTop: 10 }}
          className={cn("reset_password_button", {
            "reset_password_button--disabled": !isValid,
          })}
          onClick={handleChangeTempPassword}>
          Continue
        </button>
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  )
}

const mapStateToProps = state => {
  return {
    resetPassErrorMessage: state.user.resetPassErrorMessage,
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
  PasswordChange: bindActionCreators(PasswordChange, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTempPassword)
