import styled, { css, keyframes } from "styled-components"

import { SvgIcon } from "@components/common"

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled(SvgIcon)`
  width: 30px;
  height: 17px;
  animation: ${rotateAnimation} 1s ease-in infinite;
  padding: 7px 0;

  ${({ $isCentered }) => css`
    display: block;
    margin: 0 auto;
  `};
`
