import React from "react"

import PropTypes from "prop-types"

import {
  ImageBackground,
  ImageWrapper,
  ImageTemplate,
} from "./uploadImageStyles"

export const UploadImage = ({ isCreateTeeup, image, openSelectImage }) => (
  <ImageBackground $isCreateTeeup={isCreateTeeup}>
    <ImageWrapper
      data-testid="cameraIcon"
      $image={image}
      onClick={openSelectImage}>
      <ImageTemplate name="image-template" />
    </ImageWrapper>
  </ImageBackground>
)

UploadImage.propTypes = {
  isCreateTeeup: PropTypes.bool,
  image: PropTypes.string,
  openSelectImage: PropTypes.func,
}
