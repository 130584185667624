import styled from "styled-components"

import { NFontWeight, NSubtitleXs } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
  margin-top: 15px;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const ImageWrapper = styled.div`
  width: 34px;
  height: 34px;
  background-color: var(--lightGray4);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled(SvgIcon)`
  width: 15px;
  height: 15px;
`

export const Text = styled(NSubtitleXs)`
  color: var(--gray4);
  font-weight: ${NFontWeight.semiBold};
`
