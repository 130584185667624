import { getEndOfTheDay, getLocaleTime } from "../dateUtils"

const ALL_DAY = "All day"

const isAllDay = (startDate: string, endDate: string) => {
  const endOfTheDay = getEndOfTheDay(startDate)
  return endOfTheDay === endDate
}

const getFormattedTime = (suggestion): string => {
  if (!suggestion.endDate || !suggestion.startDate) {
    return ""
  }

  const startFormattedTime = getLocaleTime(suggestion.startDate)
  const endFormattedTime = getLocaleTime(suggestion.endDate)

  if (isAllDay(suggestion.startDate, suggestion.endDate)) {
    return ALL_DAY
  } else if (startFormattedTime === endFormattedTime) {
    return startFormattedTime
  } else {
    return `${startFormattedTime
      ?.replace(" ", "")
      .toLowerCase()} - ${endFormattedTime?.replace(" ", "")?.toLowerCase()}`
  }
}

export default getFormattedTime
