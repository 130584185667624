import React, { Component } from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  addUserContactMechanism,
  deleteUserContactMechanism,
  addLocation,
  addUrl,
  removeLocation,
  removeUrl,
  publishContactMechanism,
  updateContactMechs,
  unpublishContactMechanism,
} from "@actions/contactMechanismsActions"
import {
  getUserContactMechanisms,
  updateProfile,
  updateUserInfo,
} from "@actions/userActions"
import { selectUserInfo, selectContactMechanisms } from "@selectors/user"
import { userContactTypes, contactTypes } from "@utils/contactUtils"
import { getRandomBase64Image, getBase64 } from "@utils/imageUtils"

import ChooseContactMethod from "../../contactMethodModal"
import { DEFAULT_MODAL_TEXT } from "../../contactMethodModal/constants"

import EditUserProfileView from "./editUserProfileView"

class EditUserProfileContainer extends Component {
  static options() {
    return {}
  }

  constructor(props) {
    super(props)
    // Navigation.events().bindComponent(this)
    this.state = {
      backgroundImage: props.userInfo?.backgroundImageUrl,
      isModalOpened: false,
      modalContent: null,
      content: null,
      isContactMethodOpened: false,
    }
  }

  componentDidMount() {
    this.setBackgroundImage()
  }

  componentDidUpdate(prevProps) {
    const {
      userInfo: { backgroundImageUrl },
    } = this.props
    if (prevProps.userInfo.backgroundImageUrl !== backgroundImageUrl) {
      this.setState({
        backgroundImage: backgroundImageUrl,
      })
    }
  }

  closeModal = () => this.setState({ isModalOpened: false, modalContent: null })
  closeContactMethod = () => this.setState({ modalContent: null })

  setBackgroundImage = async () => {
    if (!this.state.backgroundImage) {
      let backgroundImage = localStorage.getItem("profileBackgroundImage")
      if (!backgroundImage) {
        backgroundImage = await getRandomBase64Image("dark")
        localStorage.setItem("profileBackgroundImage", backgroundImage)
      }

      this.setState({
        backgroundImage,
      })
    }
  }

  handleAvatarChange = e => {
    const file = e.target.files[0]
    getBase64(file).then(base64 => {
      this.updateProfile({ avatar: base64 })
    })
  }

  handleBackgroundChange = e => {
    const file = e.target.files[0]
    getBase64(file).then(base64 => {
      this.updateProfile({ backgroundImageUrl: base64 })
    })
  }

  handleFullNameChange = value => {
    this.updateProfile({ name: value })
  }

  handleNicknameChange = value => {
    const nickNameWhithoutSpaces = value.replaceAll(/\s/g, "")
    this.updateProfile({ nickName: nickNameWhithoutSpaces })
  }

  handleBioChange = value => {
    this.updateProfile({ about: value })
  }

  handleHometownChange = value => {
    this.updateProfile({ primaryLocation: value })
  }

  handleLocationChange = async value => {
    let contactLocation = await addUserContactMechanism({
      value,
      typeId: userContactTypes.LOCATION,
      isPublic: true,
    })
    contactLocation.typeId = userContactTypes.LOCATION
    contactLocation.isPublic = true
    addLocation(contactLocation)
    getUserContactMechanisms()
  }

  handleUrlChange = async value => {
    let contactUrl = await addUserContactMechanism({
      value,
      typeId: userContactTypes.URL,
      isPublic: true,
    })
    contactUrl.typeId = userContactTypes.URL
    contactUrl.isPublic = true
    addUrl(contactUrl)
    getUserContactMechanisms()
  }

  handleUrlRemove = async id => {
    const { removeUrl } = this.props
    await deleteUserContactMechanism(id)
    removeUrl(id)
  }

  handleLocationRemove = async id => {
    const { removeLocation } = this.props

    await deleteUserContactMechanism(id)
    removeLocation(id)
  }

  handlePhoneChange = isChange => {
    const props = {
      isPublic: true,
      isChange,
      type: contactTypes.PHONE,
      typeMethod: userContactTypes.PHONE,
      onClose: this.closeContactMethod,
      modalText: DEFAULT_MODAL_TEXT.phoneNumber,
      verifyText: DEFAULT_MODAL_TEXT.codeVerify,
    }

    this.setState({
      modalContent: <ChooseContactMethod {...props} />,
    })
  }

  handleEmailChange = isChange => {
    const props = {
      isPublic: true,
      isChange,
      typeMethod: userContactTypes.EMAIL,
      type: contactTypes.EMAIL,
      onClose: this.closeContactMethod,
      modalText: DEFAULT_MODAL_TEXT.email,
      verifyText: DEFAULT_MODAL_TEXT.codeVerify,
    }

    this.setState({
      modalContent: <ChooseContactMethod {...props} />,
    })
  }

  onPublishContactMethod = async (id, firstTypeId, secondTypedId, mechType) => {
    const { contactMechanisms } = await publishContactMechanism(id)

    const filteredContactMechs = contactMechanisms.filter(
      item => item.typeid === firstTypeId || item.typeid === secondTypedId
    )

    this.props.updateContactMechs(filteredContactMechs, mechType)
  }

  onUnpublishContactMethod = async (
    id,
    firstTypeId,
    secondTypedId,
    mechType
  ) => {
    const { contactMechanisms } = await unpublishContactMechanism(id)

    const filteredContactMechs = contactMechanisms.filter(
      item => item.typeid === firstTypeId || item.typeid === secondTypedId
    )

    this.props.updateContactMechs(filteredContactMechs, mechType)
  }

  handleRemovePhone = value => {
    const { phone } = this.props
    const removalPhoneId = phone.find(item => item.value === value).id

    unpublishContactMechanism(removalPhoneId).then(() => {
      getUserContactMechanisms()
    })
  }

  handleRemoveEmail = value => {
    const { email } = this.props
    const removalEmailId = email.find(item => item.value === value).id

    unpublishContactMechanism(removalEmailId).then(() => {
      getUserContactMechanisms()
    })
  }

  updateProfile = async body => {
    try {
      let userInfo = await updateProfile(body)
      if (userInfo.username && !userInfo.nickname) {
        userInfo.nickname = userInfo.username
      }
      if (userInfo.primarylocation) {
        userInfo.primaryLocation = userInfo.primarylocation
      }
      this.props.updateUserInfo(userInfo)
    } catch (error) {
      console.log("error updateProfile", error)
    }
  }

  render() {
    const {
      backgroundImage,
      isModalOpened,
      modalContent,
      content,
      isContactMethodOpened,
    } = this.state
    const {
      userInfo,
      closeEdit,
      location,
      url,
      email,
      phone,
      contactMechanisms,
    } = this.props

    // const contactMechanismsNew = contactMechanisms ? [...contactMechanisms] : []
    const contactMechanismsNew = Object.assign({}, contactMechanisms)

    return (
      <EditUserProfileView
        userInfo={userInfo}
        contactMechanisms={contactMechanismsNew}
        // location={contactMechanisms.location}
        // url={contactMechanisms.url}
        // email={email}
        // location={this.state.location ? this.state.location : location }
        // url={this.state.url ? this.state.url : url }
        // email={this.state.email ? this.state.email : email}
        // phone={this.state.phone ? this.state.phome : phone}
        handleBack={closeEdit}
        backgroundImage={backgroundImage}
        handleFullNameChange={this.handleFullNameChange}
        handleNicknameChange={this.handleNicknameChange}
        handleBioChange={this.handleBioChange}
        handleHometownChange={this.handleHometownChange}
        handleLocationChange={this.handleLocationChange}
        handleUrlChange={this.handleUrlChange}
        handlePhoneChange={this.handlePhoneChange}
        handleEmailChange={this.handleEmailChange}
        handleUrlRemove={this.handleUrlRemove}
        handleLocationRemove={this.handleLocationRemove}
        handleRemovePhone={this.handleRemovePhone}
        handleRemoveEmail={this.handleRemoveEmail}
        handleAvatarChange={this.handleAvatarChange}
        handleBackgroundChange={this.handleBackgroundChange}
        isModalOpened={isModalOpened}
        modalContent={modalContent}
        closeModal={this.closeModal}
        content={content}
        isContactMethodOpened={isContactMethodOpened}
      />
    )
  }
}

const mapStateToProps = state => {
  const userInfo = selectUserInfo(state)
  const contactMechanisms = selectContactMechanisms(state)
  // const { url, location, email, phone } = selectPublicContactMechanismsValues(state)
  const { email, location, phone, url } = selectContactMechanisms(state)

  return {
    userInfo,
    url,
    location,
    email,
    phone,
    contactMechanisms,
    state,
  }
}

const mapDispatchToProps = {
  updateUserInfo,
  addLocation,
  addUrl,
  removeLocation,
  removeUrl,
  updateContactMechs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserProfileContainer)

EditUserProfileContainer.propTypes = {
  userInfo: PropTypes.object,
  // componentId: PropTypes.string.isRequired,
  url: PropTypes.array,
  location: PropTypes.array,
  // contactMechanisms: PropTypes.array,
  email: PropTypes.array,
  phone: PropTypes.array,
}
