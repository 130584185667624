import React, { useContext } from "react"

import { NewModal } from "components"
import { useScreenWidth } from "hooks"

import { TeeupPreviewContext } from "../../../../contexts/TeeupPreviewContext"

import { Icon, Title, Description } from "./responseInfoModalStyles"

export const ResponseInfoModal = () => {
  const { isSmallSize } = useScreenWidth()
  const { setIsResponseInfoModal } = useContext(TeeupPreviewContext) as any

  const closeHandle = () => {
    setIsResponseInfoModal(false)
  }

  return (
    // @ts-ignore
    <NewModal
      isOpen
      isBlackBtn
      isBottom={isSmallSize}
      isHeader={false}
      title=""
      confirmText="Close"
      onClose={closeHandle}
      onConfirm={closeHandle}>
      <Icon name="mail-shadow" />

      <Title>Verify your email to continue.</Title>

      <Description>
        Please check your email inbox to verify your email. Once you verify your
        email, your status will be submitted.
      </Description>
    </NewModal>
  )
}
