import styled from "styled-components"

import { FontWeight, Subtitle } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 10px 0 40px 0;
`

export const Text = styled(Subtitle)`
  font-weight: ${FontWeight.medium};
`
