import styled from "styled-components"

export const Label = styled.label`
  height: 18px;
  cursor: pointer;
  color: var(--gray6);
  display: block;
  font-family: greycliffcf-medium-salt;
  font-size: 13px;
  position: relative;
  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const Checkmark = styled.span`
  height: 16px;
  width: 16px;
  border: 1px solid var(--gray6);
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;

  &:before,
  &:after {
    width: 2px;
    background-color: var(--white);
    border-radius: 30px;
    transition: all 0.1s ease-in-out;
    content: "";
    opacity: 0;
    position: absolute;
    z-index: 9;
  }
  &:after {
    height: 10px;
    bottom: 3px;
    left: 9px;
    transform: rotate(45deg);
  }
  &:before {
    height: 7px;
    bottom: 3px;
    left: 4px;
    transform: rotate(-45deg);
  }
`

export const Input = styled.input`
  display: none;

  &:checked ~ ${Checkmark} {
    background-color: var(--radicalRed2);
    border-color: var(--radicalRed2);

    &:after,
    &:before {
      opacity: 1;
    }
  }
`
