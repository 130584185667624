import { useCallback, useEffect } from "react"

import moment from "moment-timezone"

import { getHours, getNextDate, getNowDate, getPrevDate } from "../helpers"

export const useInitDates = (setNowDate, setCurrentPeriod) => {
  const initDates = useCallback(startDate => {
    const localNow = startDate ? moment(startDate) : getNowDate()

    if (!startDate) {
      setNowDate(localNow.format())
    }

    let period = []

    const currentMoment = startDate ? moment(startDate) : moment()
    period.push(getPrevDate(currentMoment), localNow)
    period.push(getNextDate(currentMoment))

    setCurrentPeriod(getHours(period))
  }, [])

  useEffect(() => {
    initDates()
  }, [])

  return { reinitDates: initDates }
}
