import React, { useMemo } from "react"

import PropTypes from "prop-types"

import { getEventsColors } from "../helpers"
import { CustomEvent } from "../molecules"

import { Wrapper, Content } from "./innerEventsListStyles"

export const InnerEventsList = ({ event }) => {
  const eventColors = useMemo(() => {
    return event ? getEventsColors(event) : {}
  }, [event])

  return (
    <Wrapper {...eventColors}>
      <Content $borderColor={eventColors.borderColor}>
        {event.innerEvents.map(innerEvent => (
          <CustomEvent isInner event={innerEvent} key={innerEvent.id} />
        ))}
      </Content>
    </Wrapper>
  )
}

InnerEventsList.propTypes = {
  event: PropTypes.object,
}
