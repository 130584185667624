import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.section`
  width: 50%;
  background-color: var(--radicalRed);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% -0px;
  @media (max-width: 1100px) {
    width: 100%;
    padding: 60px 0;
  }
`

export const OverviewImg = styled.img`
  ${({ isFirst }) =>
    !isFirst &&
    css`
      margin-top: -30px;
    `};
  &:nth-child(odd) {
    margin-left: -50px;
  }
  &:nth-child(even) {
    margin-right: -50px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 224px;
  }
`

export const FooterEl = styled.div`
  display: grid;
  row-gap: 20px;
  margin-top: 50px;
`

export const FooterLogo = styled.img`
  margin: 0 auto;
`
