import { useContext, useMemo } from "react"

import { REACTION_TYPE } from "@constants/reactions"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

export const useReactionsButtons = ({
  reactions,
  suggestionId,
  updateGuestReaction,
}) => {
  const { previewInfo } = useContext(TeeupPreviewContext)
  const participants = previewInfo.participants

  const getReaction = reaction => ({
    ...reaction,
    isActive: reaction.reactedBy.some(
      participant =>
        !!participant.key || previewInfo?.invitee?.id === participant
    ),
  })

  return useMemo(() => {
    if (!reactions) return []
    const { likes, dislikes } = reactions?.reduce((acc, reaction) => {
      if (reaction.reactionId === REACTION_TYPE.UPVOTE) {
        acc.likes = getReaction(reaction)
      } else if (reaction.reactionId === REACTION_TYPE.DOWNVOTE) {
        acc.dislikes = getReaction(reaction)
      }

      return acc
    }, {})

    return [
      {
        label: "Works for me",
        iconName: likes?.isActive ? "thumb-up-filled" : "thumb-up",
        isActive: likes?.isActive,
        reactedBy: likes?.reactedBy,
        amount: likes?.reactedBy.length || 0,
        onClick: e => {
          e.stopPropagation()
          updateGuestReaction({
            isActive: likes?.isActive,
            suggestionId,
            reactionId: REACTION_TYPE.UPVOTE,
            inviteeId: previewInfo?.invitee?.id,
          })
        },
      },
      {
        label: "Doesn’t work",
        iconName: dislikes?.isActive ? "thumb-down-filled" : "thumb-down",
        isActive: dislikes?.isActive,
        reactedBy: dislikes?.reactedBy,
        amount: dislikes?.reactedBy.length || 0,
        onClick: e => {
          e.stopPropagation()
          updateGuestReaction({
            isActive: dislikes?.isActive,
            suggestionId,
            reactionId: REACTION_TYPE.DOWNVOTE,
            inviteeId: previewInfo?.invitee?.id,
          })
        },
      },
    ]
  }, [reactions, participants])
}
