import React from "react"

import LogoSymbol from "../../../assets/images/logo-symbol-icon.svg"
import { LinkEl } from "../LinkEl"

import { Text } from "./atoms"
import { Wrapper, BadgeLeft, LogoWrapper } from "./getAppBadgeStyles"

export const GetAppBadge = ({ appLink, isPreview }) => (
  <Wrapper $isPreview={isPreview}>
    <BadgeLeft>
      <LogoWrapper>
        <img src={LogoSymbol} alt="CooWe" />
      </LogoWrapper>

      <Text />
    </BadgeLeft>

    <LinkEl text="GET" appLink={appLink} />
  </Wrapper>
)
