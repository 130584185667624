import moment from "moment-timezone"

import { MINUTES_DAY_PARTS } from "../constants"

export const getDateTitle = ({ currentHour, parsedCurrentPeriod, index }) => {
  const dateTitle = moment(currentHour).format("D MMM")
  const prevHour = parsedCurrentPeriod[(index - 1) * MINUTES_DAY_PARTS]
  let dateTitleInfo

  if (prevHour) {
    const prevDateTitle = moment(prevHour).format("D MMM")
    dateTitleInfo = prevDateTitle === dateTitle ? null : dateTitle.split(" ")
  } else {
    dateTitleInfo = dateTitle.split(" ")
  }

  return dateTitleInfo
}
