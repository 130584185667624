import styled from "styled-components"

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  color: var(--gray4);
  font-size: 16px;
  gap: 22px;
`

export const Line = styled.span`
  flex-grow: 1;
  height: 1px;
  background: var(--lightGray5);
`
