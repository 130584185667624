import React from "react"

import moment from "moment-timezone"

import { CaptionS, FontWeight } from "@assets/styles/typographyStyles"

import { useEventsColors } from "../hooks"

import {
  InnerEventsContainer,
  InnerEvent,
  EventPoint,
} from "./eventsListStyles"

export const InnerEventsList = ({ innerEvents }) => {
  const getEventColors = useEventsColors()

  const getTime = ({ startDate, endDate }) => {
    const start = moment(startDate).format("hh:mm A")
    const end = moment(endDate).format("hh:mm A")

    return `${start} - ${end}`
  }

  return (
    <InnerEventsContainer>
      {innerEvents.map((event, eventIndex) => {
        const eventColors = getEventColors(event.type, event?.calendarType)

        return (
          <InnerEvent key={eventIndex}>
            <EventPoint color={eventColors.main}></EventPoint>

            <CaptionS style={{ fontWeight: FontWeight.bold }}>
              {getTime(event)}
            </CaptionS>

            <CaptionS
              style={{
                color: "var(--gray5)",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginTop: 7,
              }}>
              {event?.name}
            </CaptionS>
          </InnerEvent>
        )
      })}
    </InnerEventsContainer>
  )
}
