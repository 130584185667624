import { createRef } from "react"

import { useDispatch } from "react-redux"

import {
  Wrapper,
  InputBlock,
  InputWrapper,
  TitleLabel,
  TitleInput,
  PencilIcon,
} from "./topBarStyles"

import { useCreateTeeUp } from "../CreateTeeUpContext/useCreateTeeUp"

import "../index.scss"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { UploadImage } from "@components"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import { TEEUP_TITLE_LENGTH } from "@utils/contacts"

const TopBar = ({ isInputToLongError, isInputError, handleNameChange }) => {
  const dispatch = useDispatch()
  const titleInputRef = createRef(null)
  const { image, name, setImage, setName } = useCreateTeeUp()

  const openSelectImage = () => {
    dispatch(setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.SELECT_IMAGE))
  }

  const handlePencilClick = () => {
    titleInputRef.current.focus()
  }

  return (
    <Wrapper>
      <UploadImage
        isCreateTeeup
        image={image}
        openSelectImage={openSelectImage}
      />

      <InputBlock>
        <InputWrapper>
          <TitleLabel>
            Title
            <TitleInput
              data-testid="teeupTitleInput"
              type="text"
              placeholder="Add a Title"
              value={name}
              ref={titleInputRef}
              onChange={e => {
                const newVal = e.target.value
                setName(newVal)
                handleNameChange(newVal)
              }}
            />
          </TitleLabel>

          <PencilIcon name="pencil" onClick={handlePencilClick} />
        </InputWrapper>
        {isInputToLongError && (
          <div className="create-teeup__name--error">
            The title should be less than {TEEUP_TITLE_LENGTH} characters
          </div>
        )}
        {isInputError && (
          <div className="create-teeup__name--error">
            The title should be more than 1 character
          </div>
        )}
      </InputBlock>
    </Wrapper>
  )
}

export default TopBar
