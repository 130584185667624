import React, { useEffect, useState, useRef } from "react"

import { geocodeByAddress, getLatLng } from "react-places-autocomplete"

import { images } from "@utils/imageUtils"

import useAddressPredictions from "../../Hooks/useAddressPredictions"
import useInPersonTabState from "../../Hooks/useInPersonTabState"

import "./index.scss"
import SearchFieldPlaceItem from "./SearchFieldPlaceItem"

import api from "api"
import endpoints from "@config/endpoints"

const SearchField = ({ userLocation }) => {
  const {
    activePlace,
    isSearchNearby,
    setMapCenter,
    setActivePlace,
    setIsSearchNearby,
    setNearbyPlaces,
    clearNearbyPlaces,
  } = useInPersonTabState()
  const [temporaryPlace, setTemporaryPlace] = useState("")
  const [searchHistory, setSearchHistory] = useState(null)
  const [isSerchHistoryOpen, setIsSerchHistoryOpen] = useState(false)
  const [comment, setComment] = useState("")

  const detailsRef = useRef()

  const { predictions, placePhotos } = useAddressPredictions(
    temporaryPlace,
    detailsRef,
    userLocation
  )

  useEffect(() => {
    const url = endpoints.suggestHistory
    api.client
      .get(url)
      .then(res => {
        const { data } = res

        setSearchHistory(data)
      })
      .catch(error => console.log(error, "error"))
  }, [])

  useEffect(() => {
    if (isSearchNearby && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }
  }, [isSearchNearby])

  useEffect(() => {
    clearNearbyPlaces()

    isSearchNearby &&
      predictions.map(prediction => {
        geocodeByAddress(prediction.description)
          .then(results => {
            return getLatLng(results[0])
          })
          .then(latLng => {
            setNearbyPlaces(latLng)
          })
          .catch(error => console.error("Error", error))
      })
  }, [isSearchNearby, predictions])

  useEffect(() => {
    temporaryPlace.length === 0 && setIsSearchNearby(false)
  }, [temporaryPlace])

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const formattedAddress = results[0].formatted_address.split(", ")
        setActivePlace({
          firstRow: formattedAddress[0],
          secondRow: formattedAddress.slice(1).join(", "),
          placeId: results[0].place_id,
          fullDetails: results[0]?.formatted_address,
          // coordinates: results?.geometry.location, // to be used in future probably
        })

        return getLatLng(results[0])
      })
      .then(latLng => {
        setMapCenter({
          lat: latLng.lat,
          lng: latLng.lng,
        })
      })
      .catch(error => console.error("Error", error))
  }

  const handleRemoveSuggestInfo = () => {
    setActivePlace(null)
    setTemporaryPlace("")
  }

  return (
    <div className="suggest-in-person__search-field">
      <div ref={detailsRef}></div>

      {activePlace ? (
        <>
          <div className="suggest-details-info" style={{ marginTop: 0 }}>
            {activePlace.secondRow
              ? activePlace.secondRow.length > 25
                ? activePlace.secondRow.slice(0, 30) + "..."
                : activePlace.secondRow
              : activePlace.firstRow}
            <div
              className="suggest-details-info--clear meeting-info"
              onClick={() => handleRemoveSuggestInfo()}></div>
          </div>

          <div className="suggest-comment" style={{ marginTop: 0 }}>
            <textarea
              className="suggest-comment__textarea"
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Add a comment..."
            />
          </div>
        </>
      ) : (
        <>
          <div className="suggest-in-person__search--wrapper">
            <img src={images.ndSuggestLocation} alt="suggest location" />
            <input
              type="text"
              value={temporaryPlace}
              onChange={e => setTemporaryPlace(e.target.value)}
              className="suggest-in-person__search"
              placeholder="Search here for a location..."
              onFocus={() => setIsSerchHistoryOpen(true)}
              // onBlur={() => setIsSerchHistoryOpen(false)}
            />
            <div className="suggest-in-person-places__list">
              {temporaryPlace.length > 0 && !isSearchNearby && (
                <div
                  className="suggest-in-person-places__item"
                  onClick={() => setIsSearchNearby(true)}>
                  <div className="suggest-in-person-places__icon">
                    <img
                      src={images.ndSuggestSearchNearbyItem}
                      alt="search nearby item"
                    />
                  </div>

                  <div className="suggest-in-person-places__info">
                    <h5 className="suggest-in-person-places__name">
                      {temporaryPlace}
                    </h5>
                    Search Nearby
                  </div>
                </div>
              )}

              {temporaryPlace.length === 0 &&
                isSerchHistoryOpen &&
                searchHistory?.map((item, index) => (
                  <SearchFieldPlaceItem
                    key={index}
                    isSearchHistory
                    prediction={item}
                    onClick={() =>
                      handleSelect(`${item.value}, ${item.details}`)
                    }
                  />
                ))}

              {temporaryPlace.length > 0 &&
                predictions.map((prediction, index) => {
                  const placePhoto = placePhotos.find(
                    item => item.placeId === prediction.place_id
                  )

                  return (
                    <SearchFieldPlaceItem
                      key={index}
                      isSearchNearby={isSearchNearby}
                      prediction={prediction}
                      placePhoto={placePhoto}
                      onClick={() => handleSelect(prediction.description)}
                    />
                  )
                })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SearchField
