import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: var(--lightGray);
  border-radius: 15px;
  overflow: hidden;
`

export const Progress = styled.div`
  ${({ $percent }) => css`
    width: ${$percent}%;
    height: 100%;
    background-color: var(--black2);
    border-radius: 15px;
  `}};
`
