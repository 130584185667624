import React from "react"

import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { Keyboard } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  toggleParticipantDetails,
  toggleDetailsUser,
} from "@actions/activeTeeupActions"
import teeupCardActions from "@actions/teeupCardActions"
import { teeupListNudgeTypes, teeupUserStatusKeys } from "@configs/enums"
import { messageTypeIds } from "@configs/mappings"
import I18n from "@i18n"
import { sendNudge } from "@middlewares/backendMiddleware"
// import {
//     showUserProfile,
//     showParticipantDetails,
//     showHighlightsScreen,
// } from '@nav'
import { selectTeeupInvitationHistory } from "@selectors/activeTeeup"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
// import teeupActions from '@actions/teeupActions'

import ParticipantPopupView from "./participantPopupView"

class ParticipantPopupContainer extends React.Component {
  componentDidMount = () => {
    Keyboard.dismiss()
    console.log(this.props.participantInfo, "participantInfo")
  }

  handleClose = () => {
    this.props.onClose(this.props.componentId)
  }

  linkParticipantDetail = () => {
    const { userId, toggleParticipantDetails, toggleDetailsUser } = this.props
    toggleParticipantDetails(true)
    toggleDetailsUser(+userId)
    this.handleClose()
  }

  linkProfile = () => {
    // const { userId, isMyProfile } = this.props
    // this.handleClose()
    // showUserProfile({
    //     isMyProfile,
    //     userId,
    // })
  }

  nudgeAreYouGoing = async () => {
    const { userId } = this.props

    await sendNudge({
      message: I18n.nudgePromptContent.areYouGoing.action,
      messageTypeId: messageTypeIds.nudge,
      mentionIds: [userId],
      subTypeId: teeupListNudgeTypes.areYouGoing,
    })

    // showHighlightsScreen({ initialIndex: highlightTypes.nudges })
    this.handleClose()
  }

  nudgeWhereAreYou = async () => {
    const { userId } = this.props

    await sendNudge({
      message: I18n.nudgePromptContent.whereAreYou.action,
      messageTypeId: messageTypeIds.nudge,
      mentionIds: [userId],
      subTypeId: teeupListNudgeTypes.whereAreYou,
    })

    // showHighlightsScreen({ initialIndex: highlightTypes.nudges })
    this.handleClose()
  }

  mentionUser = () => {
    const { expandConversationCard, setActiveTextMessage, participantInfo } =
      this.props
    setActiveTextMessage(
      `@${participantInfo.username || participantInfo.name} `
    )
    this.handleClose()
  }

  render() {
    const { participantInfo, isMyProfile, inviterName } = this.props

    return ReactDOM.createPortal(
      <ParticipantPopupView
        participantInfo={participantInfo}
        handleClose={this.handleClose}
        linkParticipantDetail={this.linkParticipantDetail}
        linkProfile={this.linkProfile}
        nudgeAreYouGoing={this.nudgeAreYouGoing}
        nudgeWhereAreYou={this.nudgeWhereAreYou}
        mentionUser={this.mentionUser}
        isMyProfile={isMyProfile}
        isInvited={participantInfo.status === teeupUserStatusKeys.invited}
        inviterName={inviterName}
      />,
      document.querySelector("#modal-root")
    )
  }
}

ParticipantPopupContainer.propTypes = {
  componentId: PropTypes.string,
  onClose: PropTypes.func,

  userId: PropTypes.string,
  userInfo: PropTypes.object,
  participantInfo: PropTypes.object,
  isMyProfile: PropTypes.bool,
  sendNudge: PropTypes.func,
  expandConversationCard: PropTypes.func,
  setActiveTextMessage: PropTypes.func,
  inviterName: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const userInfo = selectUserInfo(state)
  const teeupPeople = selectTeeupPeople(state)
  const participantInfo = teeupPeople[ownProps.userId] || {}
  const invitationHistory = selectTeeupInvitationHistory(state)
  const isMyProfile = +ownProps.userId === userInfo.id
  let inviterName = null

  invitationHistory.forEach(invitation => {
    if (invitation?.invitees?.includes(String(ownProps.userId))) {
      let inviter = teeupPeople[invitation.inviter]

      if (inviter) {
        inviterName = inviter.username
      }
    }
  })

  return {
    userInfo,
    participantInfo,
    isMyProfile,
    inviterName,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // ...teeupActions,
      ...teeupCardActions,
      toggleParticipantDetails,
      toggleDetailsUser,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantPopupContainer)
