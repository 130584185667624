import React, { useContext, useEffect } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setShowSideBar } from "@actions/commonActions"
import { useAppLink, useScreenWidth } from "@hooks"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { TeeupPreviewContent } from "../TeeupPreviewContent"
import { TeeupPreviewError } from "../TeeupPreviewError"
import { GetAppBadge } from "../common"

import { Back } from "./atoms"
import { PreviewHeader } from "./molecules"
import { PreviewWrapper, Preview } from "./teeupPreviewStyles"

const defaultImgUrl =
  "https://images.unsplash.com/photo-1493305457700-d3d24daf8c8c?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1000&ixid=MnwxfDB8MXxyYW5kb218MHwxNzUwODN8fHx8fHx8MTY5ODMxMTk5MQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1440"

const TeeupPreview = ({ setShowSideBar }) => {
  const { appLink } = useAppLink()
  const { isMobile } = useScreenWidth()
  const {
    isInbox,
    previewInfo,
    previewErrorInfo,
    closePreviewError,
    isAuthenticated,
  } = useContext(TeeupPreviewContext)

  useEffect(() => {
    setShowSideBar(false)

    return () => {
      setShowSideBar(true)
    }
  }, [])

  return (
    <PreviewWrapper>
      <PreviewHeader />

      {isMobile && <GetAppBadge isPreview appLink={appLink} />}

      {previewErrorInfo && <TeeupPreviewError close={closePreviewError} />}

      <Preview
        $isAuth={isAuthenticated}
        $img={previewInfo?.photo || defaultImgUrl}>
        {isInbox && <Back />}

        <TeeupPreviewContent />
      </Preview>
    </PreviewWrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  setShowSideBar: bindActionCreators(setShowSideBar, dispatch),
})

export default connect(null, mapDispatchToProps)(TeeupPreview)
