import React from "react"

import { connect } from "react-redux"

import {
  setCreateTeeupIsInviteesError,
  setCreateTeeupSettings,
} from "@actions/createTeeupActions"
import {
  selectCreateTeeupIsInviteesError,
  selectCreateTeeupSettings,
} from "@selectors/createTeeUp"
import { ToggleButton } from "@ui/settingsClickableRow/actionButtons"

const TeeupPermissionsItemNd = ({ title, text, value, onChange }) => {
  return (
    <div className="teeup-permissions__item" onClick={() => onChange(!value)}>
      <div className="teeup-permissions__text--wrapper">
        <p className="teeup-permissions__text">{title}</p>
        {text && <span className="teeup-permissions__span">{text}</span>}
      </div>
      <div data-testid={title}>
        <ToggleButton onToggle={() => onChange} value={value} />{" "}
      </div>
    </div>
  )
}

const TeeupPermissionsNd = ({
  title,
  settings,
  setSettings,
  isInviteesError,
  setIsInviteesError,
}) => {
  const handleChangeSettings = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    })

    if (key === "allowInviteLink" && value && isInviteesError) {
      setIsInviteesError(false)
    }
  }

  return (
    <div
      className="teeup-permissions"
      onClick={e => {
        e.stopPropagation()
      }}>
      <h3 className="teeup-permissions__title">TeeUp Permissions</h3>
      <div className="teeup-permissions__content">
        <h4 data-testid={title} className="teeup-permissions__subtitle">
          Allow anyone to
        </h4>

        <TeeupPermissionsItemNd
          title="Invite Others"
          value={settings.allowInviteOthers}
          onChange={value => {
            handleChangeSettings("allowInviteOthers", value)
          }}
        />
        <TeeupPermissionsItemNd
          title="Mark Game Plan items as Decided"
          text="Includes placing suggestion on, removing from, & withdrawing suggestion"
          value={settings.allowDecideGameplan}
          onChange={value => {
            handleChangeSettings("allowDecideGameplan", value)
          }}
        />
        <TeeupPermissionsItemNd
          title="Change TeeUp Title"
          value={settings.allowChangeTitle}
          onChange={value => {
            handleChangeSettings("allowChangeTitle", value)
          }}
        />
        <TeeupPermissionsItemNd
          title="Change TeeUp to All Set or Planning"
          value={settings.allowPromoteToAllSetOrPlanning}
          onChange={value => {
            handleChangeSettings("allowPromoteToAllSetOrPlanning", value)
          }}
        />
        <TeeupPermissionsItemNd
          title="Set the TeeUp as Ended"
          value={settings.allowPromoteToEnded}
          onChange={value => {
            handleChangeSettings("allowPromoteToEnded", value)
          }}
        />

        <h4 className="teeup-permissions__subtitle">Sharing TeeUp</h4>
        <TeeupPermissionsItemNd
          title="Invite via link"
          value={settings.allowInviteLink}
          onChange={value => {
            handleChangeSettings("allowInviteLink", value)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  isInviteesError: selectCreateTeeupIsInviteesError(state),
  settings: selectCreateTeeupSettings(state),
})

const mapDispatchToProps = dispatch => ({
  setSettings: payload => dispatch(setCreateTeeupSettings(payload)),
  setIsInviteesError: payload =>
    dispatch(setCreateTeeupIsInviteesError(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeupPermissionsNd)
