import React from "react"

import { IconContext } from "react-icons"
import { FaCheckCircle, FaRegCheckCircle, FaTimesCircle } from "react-icons/fa"

export const useRuleIcon = () => {
  return (failedRule, errorConfirmPassword) => {
    if (errorConfirmPassword && failedRule) {
      return (
        <IconContext.Provider value={{ color: "var(--mainRed)" }}>
          <FaTimesCircle />
        </IconContext.Provider>
      )
    }

    return failedRule ? (
      <IconContext.Provider value={{ color: "var(--iconNeutral)" }}>
        <FaRegCheckCircle />
      </IconContext.Provider>
    ) : (
      <IconContext.Provider value={{ color: "var(--green)" }}>
        <FaCheckCircle />
      </IconContext.Provider>
    )
  }
}
