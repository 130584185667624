import { useMemo } from "react"

import { getDatesArray } from "@utils/dateUtils"

export const useDaysList = selectedWeekDay => {
  return useMemo(() => {
    const momentDates = getDatesArray(selectedWeekDay)

    return momentDates.map(date => ({
      date,
      monthDay: date.format("D"),
      weekDay: date.format("dd"),
    }))
  }, [selectedWeekDay])
}
