import React from "react"

import { View, Text, TouchableOpacity, Image } from "react-native"

import { images } from "@utils/imageUtils"

import styles from "./styles"

const UnreadBar = ({ count, onClose, onPress }) =>
  !!count && (
    <View style={styles.unreadBarContainer}>
      <View style={styles.unreadBar}>
        <TouchableOpacity onPress={onPress} style={styles.unreadBarContent}>
          <Text style={styles.unreadBarText}>
            {count} unread message{count > 1 && "s"}
          </Text>
        </TouchableOpacity>
        {onClose && (
          <TouchableOpacity onPress={onClose} style={styles.unreadBarClose}>
            <Image source={images.closeUnread} />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )

export default UnreadBar
