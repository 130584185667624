import styled, { css } from "styled-components"

import { FontWeight, Subtitle } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  background-color: var(--white);
  padding: 0 12px 10px 12px;
  margin-top: 25px;
`

export const ContentActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  height: 40px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const ActionItem = styled.div`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 15px 10px;
  margin: 5px 0;

  &:hover {
    background-color: var(--lightGray4);
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};
`

export const Text = styled(Subtitle)`
  color: var(--gray6);
  font-weight: ${FontWeight.medium};
`
