import React from "react"

import PropTypes from "prop-types"

import { PreviewErrorIcon } from "../previewErrorStyles"

export const ErrorIcon = ({ previewErrorInfo }) => (
  <PreviewErrorIcon
    className="preview-error__icon"
    src={previewErrorInfo.icon}
    alt={previewErrorInfo.title}
  />
)

ErrorIcon.propTypes = {
  previewErrorInfo: PropTypes.any,
}
