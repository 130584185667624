import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Link = styled.a`
  background-color: #f42862;
  border-radius: 25px;
  color: #fff;
  font-family: greycliffcf-medium-salt;
  text-align: center;
  ${({ isLarge }) =>
    isLarge
      ? css`
          padding: 13px 46px;
        `
      : css`
          padding: 7px 15px;
        `};

  @media (min-width: ${MEDIA_SIZES.MOBILE}) and (max-width: ${MEDIA_SIZES.TABLET_L}) {
    margin: 0 130px;
  }
`
