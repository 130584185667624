import { Alert } from "react-native"
import * as RNLocalize from "react-native-localize"
// import { PERMISSIONS, RESULTS } from 'react-native-permissions'
// import Geolocation from '@react-native-community/geolocation'

import I18n from "@i18n"
// import { checkModulePermission } from '@utils/permissionUtils'

export const DELTAS = {
  latitudeDelta: 0.01,
  longitudeDelta: 0.05,
}

export const DEFAULT_NY_LOCATION = {
  latitude: 40.785_091,
  longitude: -73.968_285,
  ...DELTAS,
}
export const DEFAULT_PLACE_ID = "ChIJlUDqHZtYwokRNrmBnyZqz18"

export const earthRadius = 6371e3
export const maxDistance = 2004e4

const toRadians = value => {
  // Converts numeric degrees to radians
  return (value * Math.PI) / 180
}

const DEFAULT_PRECISION = 1e3 // 1km
const DEFAULT_PRECISION_THRESHOLD = 2 * DEFAULT_PRECISION // 2km

const getDefaultPrecision = currectDistance => {
  return currectDistance >= DEFAULT_PRECISION_THRESHOLD ? DEFAULT_PRECISION : 1
}

export const getDistanceByCoordinates = (location1, location2, precision) => {
  if (!location1 || !location2) {
    return 0
  }
  const { latitude: latitude1, longitude: longitude1 } = location1
  const { latitude: latitude2, longitude: longitude2 } = location2
  if (!latitude1 || !longitude1 || !latitude2 || !longitude2) {
    return 0
  }

  const φ1 = toRadians(latitude1)
  const φ2 = toRadians(latitude2)
  const Δφ = toRadians(latitude2 - latitude1)
  const Δλ = toRadians(longitude2 - longitude1)

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const exactDistance = earthRadius * c
  const precisionValue = precision || getDefaultPrecision(exactDistance)
  const distance = Math.round(exactDistance / precisionValue) * precisionValue

  return distance
}

export const calculateDeltasFromDistanceInMeters = ({
  distanceInMeters,
  currentLatitude,
}) => {
  const oneDegreeOfLatitudeInMeters = 50 * 1000 // I reduced it for better display on the map

  const latitudeDelta = distanceInMeters / oneDegreeOfLatitudeInMeters
  const longitudeDelta =
    distanceInMeters /
    (oneDegreeOfLatitudeInMeters * Math.cos(currentLatitude * (Math.PI / 180)))
  return { latitudeDelta, longitudeDelta }
}

export function getDescriptionFromLocations(locations) {
  return locations
    ? locations.length > 0
      ? `${locations[0].name} · ${locations[0].radius}mi`
      : "Anywhere"
    : "My Locations"
}

export function alertNotificationError(error) {
  if (error.code === 1) {
    Alert.alert(
      I18n.t("location.noPermissionTitle"),
      I18n.t("location.noPermissionDescription")
    )
  }
}

const noOp = () => {}

let currentLocation = DEFAULT_NY_LOCATION
let locationWatchId

export const isDefaultLocation = () => currentLocation === DEFAULT_NY_LOCATION

export const getCurrentLocation = () => {
  initWatchLocation()
  return currentLocation
}

export const getLocation = async (
  successCallback = noOp,
  errorCallback = noOp,
  settings = {}
) => {
  // let res = RESULTS.GRANTED
  // let res = true
  try {
    // if (!isIOS) {
    //     res = await checkModulePermission(
    //         PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
    //     )
    // }
    // if (res !== RESULTS.GRANTED) return null
    // Geolocation.getCurrentPosition(
    //     (success) => {
    //         // latitude, longitude
    //         currentLocation = { ...success.coords, ...DELTAS }
    //         successCallback(currentLocation)
    //     },
    //     (error) => {
    //         alertNotificationError(error)
    //         errorCallback(error)
    //     },
    //     {
    //         enableHighAccuracy: false,
    //         timeout: 5000,
    //         maximumAge: 1000,
    //         ...settings,
    //     }
    // )
  } catch (error) {
    console.log("getLocation Error:", error)
  }
}

export const initWatchLocation = async (
  successCallback = noOp,
  errorCallback = noOp,
  settings = {}
) => {
  // let res = RESULTS.GRANTED
  // let res = true
  if (locationWatchId) return
  // if (!isIOS) {
  //     res = await checkModulePermission(
  //         PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
  //     )
  // }
  // if (res !== RESULTS.GRANTED) return null

  // locationWatchId = Geolocation.watchPosition(
  //     (success) => {
  //         // latitude, longitude
  //         currentLocation = { ...success.coords, ...DELTAS }
  //         successCallback(currentLocation)
  //     },
  //     (error) => {
  //         // alertNotificationError(error)
  //         errorCallback(error)
  //     },
  //     {
  //         enableHighAccuracy: false,
  //         timeout: 5000,
  //         maximumAge: 1000,
  //         ...settings,
  //     }
  // )
}

export const SELECT_LOCATION_TYPES = {
  customPlaceName: "customPlaceName",
  placeName: "placeName",
  details: "details",
  placeTitle: "placeTitle",
}

export const clearWatchLocation = () => {
  if (locationWatchId) {
    // Geolocation.clearWatch(locationWatchId)
    locationWatchId = null
  }
}

export const currentCountry = RNLocalize.getCountry()
