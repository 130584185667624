import React from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

export const SwitchSearchType = ({ text, onClick }) => (
  <ButtonEl
    autoWidth
    isNoPadding
    isIconFirst
    iconName="plus-symbol"
    variant={BUTTON_VARIANTS.TRANSPARENT}
    text={text}
    textColor="var(--black)"
    onClick={onClick}
  />
)

SwitchSearchType.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
}
