export const TEEUP_IMAGES = [
  require("@assets/images/teeupImages/teeup-img-1.jpg"),
  require("@assets/images/teeupImages/teeup-img-2.jpeg"),
  require("@assets/images/teeupImages/teeup-img-3.jpeg"),
  require("@assets/images/teeupImages/teeup-img-4.jpeg"),
  require("@assets/images/teeupImages/teeup-img-5.jpeg"),
  require("@assets/images/teeupImages/teeup-img-6.jpeg"),
  require("@assets/images/teeupImages/teeup-img-7.jpeg"),
  require("@assets/images/teeupImages/teeup-img-8.jpeg"),
  require("@assets/images/teeupImages/teeup-img-9.jpeg"),
  require("@assets/images/teeupImages/teeup-img-10.jpeg"),
  require("@assets/images/teeupImages/teeup-img-11.jpeg"),
  require("@assets/images/teeupImages/teeup-img-12.jpeg"),
]
