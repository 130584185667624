const EndMessage = () => {
  return (
    <div className=" py-3 justify-center items-center gap-2.5 flex w-full animate-fade">
      <div>🎉</div>
      <div className="text-center text-black text-sm font-semibold leading-tight">
        You’re up to date
      </div>
    </div>
  )
}

export default EndMessage
