import styled from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.ul`
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  border-radius: 8px;
  box-sizing: border-box;
  list-style: none;
  position: absolute;
  bottom: 0;
  right: 20px;
`

export const Element = styled.li`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  padding: 15px;
  &:hover {
    background-color: var(--lightGray5);
  }
`

export const ActionIcon = styled(SvgIcon)``

export const Text = styled(NSubtitle)`
  color: var(--gray5);
  font-weight: ${NFontWeight.medium};
`
