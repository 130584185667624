import moment from "moment-timezone"

import {
  MINUTES_DAY_PART_WIDTH,
  MINUTES_STEP,
} from "@contexts/TimeWheelContext"
import { getDateWithTimezone } from "@utils/dateUtils"

export const useEventWidth = () => {
  return ({ startDate, endDate }) => {
    let eventDuration = moment(endDate).diff(moment(startDate), "minutes")

    // if event takes two days: minus rest of event duration from next day and cut him till the end of day
    const isSameDate = getDateWithTimezone(startDate).isSame(
      getDateWithTimezone(endDate),
      "date"
    )
    if (!isSameDate) {
      eventDuration -= getDateWithTimezone(endDate).diff(
        getDateWithTimezone(endDate).startOf("date"),
        "minutes"
      )
    }

    const minutesBlocks = eventDuration / MINUTES_STEP

    return minutesBlocks * MINUTES_DAY_PART_WIDTH
  }
}
