import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { Stage } from "types/teeup"

import TimeLine from "./TimeLine"

import "./index.scss"

interface Props {
  isOpen: boolean
  onClose: () => void
  changeStatus: (stage: Stage) => void
  currentStage: Stage | null
  isOnlyGeneralWhenSuggestion: boolean
}

const StageModal = ({
  currentStage,
  isOpen,
  onClose,
  changeStatus,
  isOnlyGeneralWhenSuggestion,
}: Props) => {
  return (
    <DefaultModal open={isOpen} onClose={onClose}>
      <Header
        title="TeeUp Progression"
        subtitle="See the stage of the TeeUp"
        onClose={onClose}
      />

      <div className="stage-modal__content">
        <TimeLine
          currentStage={currentStage}
          changeStatus={changeStatus}
          isOnlyGeneralWhenSuggestion={isOnlyGeneralWhenSuggestion}
        />
      </div>
    </DefaultModal>
  )
}

export default StageModal
