import { useMemo } from "react"

import { setSuggestionAsSeen } from "@actions/teeupActions"

export const useLabelNew = ({ userInfo, suggestion, teeupId, isPreview }) => {
  return useMemo(() => {
    const isNew =
      !!suggestion?.creator?.id &&
      userInfo?.id !== suggestion?.creator?.id &&
      !suggestion?.seenInfo?.isSeen &&
      !isPreview

    if (isNew && !suggestion.isWithdrawn && suggestion?.id) {
      setSuggestionAsSeen({
        teeupId,
        suggestionId: suggestion?.id,
      })
    }

    return isNew
  }, [suggestion])
}
