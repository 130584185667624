import React, { useEffect, useState } from "react"

import { getFileSizeFromBytes, isImageFile } from "@utils/fileUtils"
import { getNewDesignFileIcon, images } from "@utils/imageUtils"

import "./index.scss"
import { ReactSVG } from "react-svg"

const FileCard = ({ file, removeFile }) => {
  const [hover, setHover] = useState(false)
  const [image, setImage] = useState(null)
  const { name, type, size } = file

  useEffect(() => {
    if (!isImageFile(type)) return

    const image = URL.createObjectURL(file)
    setImage(image)

    return () => {
      URL.revokeObjectURL(file)
    }
  }, [file])

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const Close = () => (
    <div className="file-card__close" onClick={() => removeFile(name)}>
      <ReactSVG src={images.ndCloseFilledIcon} />
    </div>
  )

  if (isImageFile(type)) {
    return (
      <div
        className="file-card__image-type"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {hover && <Close />}
        <img className="file-card__image-typepp" alt="upload" src={image} />
      </div>
    )
  }

  const { size: fileSize, sizeType } = getFileSizeFromBytes(size)
  return (
    <div
      className="file-card__document-type"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {hover && <Close />}
      <div className="file-card__document-type__icon">
        <ReactSVG
          src={getNewDesignFileIcon(type.replace("application/", "."))}
        />
      </div>
      <div className="file-card__document-type__info">
        <div className="file-card__document-type__info-name">
          <span>{name.split(".")[0]}</span>
          {`.${name.split(".")[1] || ""}`}
        </div>
        <span className="file-card__document-type__info-size">{`${fileSize} ${sizeType}`}</span>
      </div>
    </div>
  )
}

export default FileCard
