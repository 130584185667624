import { useMemo } from "react"

export const useFilteredContacts = (bookContacts, cooweContacts) => {
  const filterContacts = contacts => {
    return contacts?.reduce((acc, currentContact) => {
      if (
        currentContact?.name?.trim()?.length &&
        currentContact?.username !== "Deleted Account" &&
        !acc?.some(contact => contact?.id === currentContact?.id) &&
        (currentContact?.emails?.length || currentContact?.phoneNumbers?.length)
      ) {
        acc.push(currentContact)
      }

      return acc
    }, [])
  }

  return useMemo(
    () => ({
      filteredBookContacts: filterContacts(bookContacts),
      filteredCooweContacts: filterContacts(cooweContacts),
    }),
    [bookContacts, cooweContacts]
  )
}
