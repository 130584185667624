import endpoints from "@config/endpoints"
import { teeupUserStatusPriorities } from "@config/enums"
import { getSettingStatusEndpoint } from "@utils/guestHelpers"

import api from "../api"

import actionTypes from "./actionTypes"

export const setGuestRegistrationData = payload => ({
  type: actionTypes.SET_GUEST_REGISTRATION_TYPE,
  payload,
})

export const setGuestSuggestions = payload => ({
  type: actionTypes.SET_GUEST_SUGGESTIONS,
  payload,
})

export const setGuestCalendarConflicts = payload => ({
  type: actionTypes.SET_GUEST_CALENDAR_CONFLICTS,
  payload,
})

export const publishGuestCreatedSuggestions = payload => ({
  type: actionTypes.PUBLISH_GUEST_CREATED_SUGGESTIONS,
  payload,
})

export const removeGuestSuggestion = payload => ({
  type: actionTypes.REMOVE_GUEST_SUGGESTIONS,
  payload,
})

export const updateGuestReaction = payload => ({
  type: actionTypes.UPDATE_GUEST_REACTION,
  payload,
})

export const setNewGuestSuggestion = payload => ({
  type: actionTypes.SET_NEW_GUEST_SUGGESTION,
  payload,
})

export const setGuestName = payload => ({
  type: actionTypes.SET_GUEST_NAME,
  payload,
})

export const setMyGuestTeeupReactions = payload => ({
  type: actionTypes.SET_MY_GUEST_TEEUP_REACTIONS,
  payload,
})

export const resetAllGuestData = () => ({
  type: actionTypes.RESET_ALL_GUEST_DATA,
})

export const postGuestData = async payload => {
  const { isSharedLink, slug, data, status } = payload
  const endpoint = getSettingStatusEndpoint({
    isSharedLink,
    slug,
    status,
  })

  return api.client.post(endpoint, data)
}

export const postSharedLinkGuestData = ({ teeupId, status, data }) => {
  const endpoint = endpoints.teeup.connectSharedLinkGuest(teeupId)

  return api.client.put(endpoint, {
    statusId: teeupUserStatusPriorities[status],
    ...data,
  })
}
