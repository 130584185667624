import { DateRange } from "@mui/lab"
import { Dayjs } from "dayjs"

import { Participant } from "./participant"

export type SuggestionStatusLabel =
  | "Past"
  | "Withdrawn"
  | "Decided"
  | "Calendar conflict"
  | "Available"
  | ""

export interface SuggestionStatus {
  label: SuggestionStatusLabel
  className: string
}

export enum SuggestWhereTab {
  IN_PERSON = "In person",
  ONLINE = "Online",
}

export enum SuggestWhenTab {
  GENERAL = "General",
  TIME_WHEEL = "Time wheel",
}

export interface Location {
  lat: number
  lng: number
  placeId?: string
}

interface MatchedSubstring {
  length: number
  offset: number
}

interface StructuredFormatting {
  main_text: string
  main_text_matched_substrings: MatchedSubstring[]
  secondary_text: string
}

interface Term {
  offset: number
  value: string
}

export interface Prediction {
  label: string
  iconName: string
  description?: string
  matched_substrings?: MatchedSubstring[]
  place_id?: string | "currentLocation"
  reference?: string
  structured_formatting?: StructuredFormatting
  terms?: Term[]
  types?: string[]
  isRecent?: boolean
  isCurrentLocation?: boolean
  isSeparator?: boolean
  isCustom?: boolean
  lat?: number
  lng?: number
}

export interface Platform extends Prediction {
  id?: MEETING_PLATFORMS
  label: string
  iconName: string
}

export enum URL_TYPES {
  URL,
  LINK_LATER,
  SOMEONE_PROVIDE,
}

export interface OnlineSuggestion extends Platform {
  urlType: URL_TYPES
  urlAddress?: string
  additionalInformation?: string
}

export interface HourBox {
  label: string
  dateString: string
  isDisabled?: boolean
  isDaySeparator?: boolean
  timezone?: string
  isToday?: boolean
  isUnknownTimezone?: boolean
}

export interface IDateRange {
  startDate: string
  endDate: string
  label: string
}

export interface TimeListOption {
  label: string
  iconName: string
  from: number
  to: number
  isDisabled?: boolean
  timeRange?: string
}

export interface GeneralDateSuggestion {
  label: string
  date: DateRange<Dayjs>
  duration: number
  time?: TimeListOption
}

export interface TimeWheelSuggestion {
  date: DateRange<Dayjs>
  duration: number | string
}

export interface InPersonSuggestion {
  name: string
  additionalInformation?: string
  address: Prediction | null
}

export interface SuggestionDuration {
  id: string
  value: string
}

interface Creator {
  id: number
  name: string
  avatar: string
}

interface SeenInfo {
  id: number
  teeupUserId: number
  suggestionId: number
  userId: number
  isSeen: boolean
  createdAt: string
  updatedAt: string
}

export interface Suggestion {
  id: number
  optionId: number
  selected: boolean
  decided: boolean
  value: string
  value2: string
  isCalendarConflict?: boolean
  isCustomDate: boolean
  isCustomTime: boolean
  isTimeZoneEnabled: boolean
  startDate: string
  endDate: string
  createdBy: number
  creator: Creator
  seenInfo: SeenInfo
  time: any[]
  isWithdrawn: boolean
  type: string
  customDate?: string
  customTime?: string
  duration?: number
  isDecided?: boolean
  googlePlaceId?: string
  comment?: string
  details?: string
  fullDetails?: string
  reactions: any[]
  when: any
  gameplanOptionId?: number
}

export enum MEETING_PLATFORMS {
  DISCORD,
  ZOOM,
  GOOGLE_MEET,
  TEAMS,
  SKYPE,
  SLACK,
  WEBEX,
  CALL,
}

export interface Preview {
  isPreview?: boolean
  shortReactions?: boolean
  participant?: Participant
}
