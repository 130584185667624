// import generalTimeOptions from "app/configs/generalTimes"
import inRange from "lodash/inRange"

import { selectTeeupPeople } from "@selectors/teeups"
import { getTimeListOptions } from "@utils/suggestionUtils"
import { store } from "index"
import dayjs from "utils/dayjs"

const timeMapping: {
  [key: string]: number
} = {
  Morning: 6,
  Afternoon: 10,
  Evening: 18,
  Night: 22,
}

const parseCustomTimeWithTimezone = (
  createdBy?: number,
  customTime?: string,
  participants?: any
): string => {
  const timeListOptions = getTimeListOptions()
  if (!createdBy || !customTime || customTime === timeListOptions[0].label) {
    return customTime || ""
  }

  const state = store.getState() as any
  const userTimezone = state?.user?.info?.timezone
  const people: {
    [key: string]: any
  } = selectTeeupPeople(state) as any
  const timezone =
    participants && Object.keys(participants).length > 0
      ? participants[createdBy]?.timezone
      : people[createdBy]?.timezone

  if (userTimezone && userTimezone !== timezone) {
    // const now = dayjs.utc().valueOf()
    const timezoneOffsetUtc = dayjs.tz(timezone)?.utcOffset()
    const myTimezoneOffsetUtc = dayjs.tz(userTimezone)?.utcOffset()

    if (timezoneOffsetUtc && myTimezoneOffsetUtc) {
      const difference = (timezoneOffsetUtc - myTimezoneOffsetUtc) / 60

      const hoursShifting = Math.abs(difference) + timeMapping[customTime]

      const date = dayjs(new Date()).add(hoursShifting, "hours")

      if (date) {
        date.hour(hoursShifting)
      }

      const hours = date ? date.hour() : 0

      const foundedOption = timeListOptions.find(time => {
        return inRange(hours, time.from, time.to)
      })

      return foundedOption?.label || customTime
    }

    return customTime
  }

  return customTime
}

export default parseCustomTimeWithTimezone
