import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"

export const Button = styled.button`
  width: 24px;
  height: 24px;
  background-color: var(--white);
  border: 1px solid var(--gray3);
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 22px;
  right: -12px;

  outline: none;
  z-index: 4;
`

export const ArrowIcon = styled(SvgIcon)`
  width: 10px;
  transition: transform 0.2s ease-in-out;

  ${({ $isWrapped }) => css`
    transform: rotate(${$isWrapped ? "-90deg" : "90deg"});
  `};
`
