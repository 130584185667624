import React, { useContext } from "react"

import PropTypes from "prop-types"

import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"

import { ToggleButton } from "../../ui/settingsClickableRow/actionButtons"

import {
  Wrapper,
  LeftSide,
  Title,
  Description,
  ArrowIcon,
} from "./settingsRowStyles"

export const SettingsRow = ({
  isToggle,
  title,
  description,
  value,
  type,
  onToggle,
}) => {
  const { setSettingsType } = useContext(NewTeeupPageContext)

  const handleClick = () => {
    if (isToggle) return

    setSettingsType(type)
  }

  return (
    <Wrapper
      $isDescription={!!description}
      $isToggle={isToggle}
      onClick={handleClick}>
      <LeftSide>
        <Title>{title}</Title>
        {!!description && <Description>{description}</Description>}
      </LeftSide>

      {isToggle ? (
        <ToggleButton onToggle={() => onToggle(!value)} value={value} />
      ) : (
        <ArrowIcon name="arrow-2" color="var(--gray4)" />
      )}
    </Wrapper>
  )
}

SettingsRow.propTypes = {
  isToggle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.bool,
  type: PropTypes.string,
  onToggle: PropTypes.func,
}
