import React from "react"

import PropTypes from "prop-types"
import { TouchableOpacity, View, Text, StyleSheet } from "react-native"
import { connect } from "react-redux"

import { selectTeeupReactionsBySuggestionId } from "@selectors/teeups"
import { Units, AppColors, AppFonts } from "@theme/"
import Icon from "@ui/icon"
import {
  getGameplanIcon,
  GAMEPLAN_OPTIONS,
  getSuggestionValue,
  formatSuggestion,
} from "@utils/gamePlanUtils"

import ReactionButton from "../gamePlansCard/reactionButton"

const styles = StyleSheet.create({
  container: {
    marginTop: -Units.rem(0.5),
    marginBottom: Units.rem(0.5),
    marginHorizontal: Units.responsiveValue(8),
  },
  withoutMargin: {
    marginTop: 0,
    marginBottom: 0,
    marginHorizontal: 0,
  },

  typeContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  typeText: {
    ...AppFonts.extraSmallExtrabold,
    lineHeight: Units.responsiveValue(13),
    letterSpacing: 0.45,
    color: AppColors.brand.darkGrey2,
    paddingLeft: Units.responsiveValue(5),
  },
  linesWithReactionsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  linesContainer: {
    maxWidth: "75%",
  },
  lineText: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -0.4,
    color: AppColors.brand.blackTwo,
    paddingTop: Units.responsiveValue(4),
  },
})

const contentContainer = onTeeupOverview => ({
  minHeight: Units.responsiveValue(80),
  width: "100%",
  borderRadius: 10,
  backgroundColor: onTeeupOverview
    ? AppColors.brand.white
    : AppColors.brand.grey247,
  padding: Units.responsiveValue(8),
})

const SuggestionNudge = props => {
  const {
    onSuggestionNudge,
    onTeeupOverview,
    suggestion,
    reactions,
    onNudgesScreen,
  } = props
  const { type, id } = suggestion
  const suggestionValues = getSuggestionValue(
    formatSuggestion(suggestion),
    type
  )

  const renderGameplanTypeIcon = type => {
    const iconName = getGameplanIcon({ type })
    return (
      <Icon
        type="font-awesome5"
        solid={type === GAMEPLAN_OPTIONS.when}
        name={iconName}
        size={9}
        color={AppColors.brand.darkGrey2}
      />
    )
  }

  return (
    <TouchableOpacity
      style={[styles.container, onNudgesScreen && styles.withoutMargin]}
      activeOpacity={1}
      onPress={onSuggestionNudge}>
      <View style={contentContainer(onTeeupOverview)}>
        <View style={styles.typeContainer}>
          {renderGameplanTypeIcon(type)}
          <Text style={styles.typeText}>{type && type.toUpperCase()}</Text>
        </View>
        <View style={styles.linesWithReactionsContainer}>
          <View style={styles.linesContainer}>
            <Text style={styles.lineText} numberOfLines={1}>
              {suggestionValues.line1}
            </Text>
            <Text style={styles.lineText} numberOfLines={1}>
              {suggestionValues.line2}
            </Text>
          </View>
          <View>
            <ReactionButton
              isInNudge
              inactive={!onTeeupOverview}
              reactions={reactions}
              suggestionId={id}
            />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

SuggestionNudge.defaultProps = {
  suggestion: {},
  onSuggestionNudge: () => {},
}

SuggestionNudge.propTypes = {
  suggestion: PropTypes.object,
  onNudgesScreen: PropTypes.bool,
  onTeeupOverview: PropTypes.bool,
  onSuggestionNudge: PropTypes.func,
  reactions: PropTypes.array,
}

const mapStateToProps = (state, props) => {
  const { suggestion } = props
  const suggestionId = suggestion ? suggestion.id : null
  const reactions =
    suggestion?.reactions ||
    selectTeeupReactionsBySuggestionId(suggestionId)(state)

  return {
    reactions,
  }
}

export default connect(mapStateToProps, null)(SuggestionNudge)
