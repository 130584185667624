import React from "react"

import cn from "classnames"
import moment from "moment-timezone"

import "./datePickerNav.scss"
import { images } from "@utils/imageUtils"

import { DATE_PICKER_NAV } from "../../../contexts/TimeWheelContext"
import { usePrevNavDisabled } from "../../../contexts/TimeWheelContext/hooks"

export const DatePickerNav = ({
  startDate,
  isOnlyWeek,
  startOfWeek,
  onChangeCalendarPeriod,
  toggleShowCurrentWeekOnly,
}) => {
  const isPrevNavDisabled = usePrevNavDisabled(isOnlyWeek, startOfWeek)

  const getLabelText = () => {
    const startOfWeekMoment = moment(startOfWeek)
    const startDateMoment = moment(startDate)

    return startOfWeekMoment.isSame(startDateMoment, "week")
      ? startDateMoment.format("MMMM YYYY")
      : moment(startOfWeek).format("MMMM YYYY")
  }

  return (
    <div className="date-picker-nav">
      <button
        className="date-picker-nav__prev"
        disabled={isPrevNavDisabled}
        onClick={() => onChangeCalendarPeriod(DATE_PICKER_NAV.prev)}>
        <img src={images.chevron} alt="prev" />
      </button>

      <div className="date-picker-nav__label">
        <span className="date-picker-nav__label-date">{getLabelText()}</span>

        <button
          className={cn("date-picker-nav__toggle", {
            "rotated-calendar-toggler": !isOnlyWeek,
          })}
          onClick={toggleShowCurrentWeekOnly}>
          <img src={images.expandCalendarArrow} alt="toggle" />
        </button>
      </div>

      <button
        className="date-picker-nav__next"
        onClick={() => onChangeCalendarPeriod(DATE_PICKER_NAV.next)}>
        <img src={images.chevron} alt="next" />
      </button>
    </div>
  )
}
