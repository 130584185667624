import { StyleSheet } from "react-native"

import { AppFonts, Units, AppColors } from "@theme/"

//48 - timeblock width

export const styles = StyleSheet.create({
  hours: ({ isOdd, withOpacity }) => ({
    backgroundColor: isOdd
      ? AppColors.brand.lightGrey
      : AppColors.brand.lightGrey2,
    alignItems: "flex-start",
    opacity: withOpacity ? 0.5 : 1,
  }),
  block: fullSize => ({
    height: Units.responsiveValue(56),
    width: Units.responsiveValue(48 / (fullSize ? 1 : 4)),
    justifyContent: "center",
    backgroundColor: "transparent",
  }),

  dayRowContainer: {
    position: "absolute",
  },

  userRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  timeLabels: {
    position: "absolute",
    justifyContent: "center",
  },
  headerLine: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  userAvatarsLayer: {
    ...StyleSheet.absoluteFill,
    marginTop: Units.responsiveValue(56),
  },
  flagLayer: { position: "absolute", top: Units.responsiveValue(-15) },
  flagImage: (position, additionalPosition) => ({
    position: "absolute",
    left: position * 12 + additionalPosition,
  }),
  avatarWrapper: {
    paddingLeft: Units.responsiveValue(30),
    alignItems: "flex-start",
    width: "100%",
  },
  absoluteLine: {
    position: "absolute",
    flexDirection: "row",
    height: Units.responsiveValue(24),
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  notAvailableLine: {
    backgroundColor: AppColors.brand.lightGrey3,
  },
  notGoingLine: {
    backgroundColor: AppColors.brand.red,
  },
  setAvailabilityLine: {
    backgroundColor: AppColors.brand.pink,
  },
  pencilImage: {
    tintColor: AppColors.brand.white,
    height: Units.responsiveValue(9),
    width: Units.responsiveValue(9),
    marginLeft: Units.responsiveValue(4),
  },
  availableText: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.white,
  },
  avatarStyle: ({ isDragged, invited }) => ({
    opacity: isDragged ? 0.15 : invited ? 0.5 : 1,
    zIndex: 4,
    flexShrink: 1,
    flexGrow: 0,
  }),
  avatarAccesory: {
    top: Units.responsiveValue(20),
  },
  avatarColor: ({ isCurrentUser, decided, going, notgoing }) => ({
    backgroundColor:
      isCurrentUser && !decided
        ? AppColors.brand.white
        : notgoing
        ? AppColors.brand.red
        : going
        ? AppColors.brand.blue2
        : undefined,
  }),
  usersTimeLabels: position => ({
    position: "absolute",
    height: Units.responsiveValue(24),
    left: Units.responsiveValue((position + 1) * 12),
    justifyContent: "center",
    paddingHorizontal: Units.responsiveValue(12),
    paddingTop: Units.responsiveValue(3),
    paddingBottom: Units.responsiveValue(3),
    borderRadius: Units.responsiveValue(16),
    flexDirection: "row",
    alignItems: "center",
  }),
  editImage: {
    height: Units.responsiveValue(9),
    width: Units.responsiveValue(9),
    marginLeft: Units.responsiveValue(4),
    tintColor: AppColors.brand.white,
  },
  goingTimeLabel: {
    backgroundColor: AppColors.brand.green,
  },
  mightGoTimeLabel: {
    backgroundColor: AppColors.brand.orange,
  },
  userStartText: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.white,
  },
  hourText: position => ({
    position: "absolute",
    justifyContent: "center",
    alignItems: "flex-start",
    width: Units.responsiveValue(48),
    left: Units.responsiveValue((position * 48) / 4),
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.black2,
  }),
  overlayLine: tick => {
    return {
      position: "absolute",
      left: Units.responsiveValue(tick * 12 + 2),
      height: Units.responsiveValue(24),
      width: Units.responsiveValue(2),
      backgroundColor: "rgba(255,255,255,0.8)",
    }
  },
  fillLine: ({ tick, fillColor, width }) => {
    return {
      position: "absolute",
      left: Units.responsiveValue(tick * 12) + 4,
      height: Units.responsiveValue(24),
      width: Units.responsiveValue(width * 12),
      borderRadius: Units.responsiveValue(16),
      backgroundColor: fillColor,
    }
  },
  addtionalRowStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },

  // position: 'absolute',
  //             height: 56,
  //             width: '100%',
  //             backgroundColor: 'red',
  firstUserRowStyle: {
    backgroundColor: "white",
    width: "100%",
    height: 56,
    position: "absolute",
  },
})
