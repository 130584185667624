import React from "react"

import { useAuth0 } from "@auth0/auth0-react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { loginProviders } from "@config/enums"
import { AUTH0_TYPES } from "@constants/auth0Types"
import { BUTTON_VARIANTS } from "@constants/button"
import { useTopLevelContext } from "@contexts/TopLevelContext"

import { ButtonEl } from "../../common"
import { LOGIN_ERRORS } from "../constants"
import { FormButtons } from "../molecules"

const SubmitButtons = ({
  isSsoError,
  isLoginLoading,
  loginErrorCode,
  isSubmitDisabled,
  auth0Type,
}) => {
  const { setIsLoginInProgress } = useTopLevelContext()
  const { loginWithPopup } = useAuth0()
  const history = useHistory()
  const loginWith = () => {
    if (!auth0Type) return
    setIsLoginInProgress(true)
    const providerKey =
      auth0Type === AUTH0_TYPES.FACEBOOK ? "fb" : auth0Type.toLowerCase()

    loginWithPopup({
      connection: loginProviders[providerKey],
    })
  }

  return (
    <FormButtons>
      {isSsoError && (
        <ButtonEl
          fullWidth
          text="Go Back"
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={() => history.push("/")}
        />
      )}
      {loginErrorCode &&
      loginErrorCode === LOGIN_ERRORS.CANNOT_LOGIN_PASSWORD ? (
        <ButtonEl
          fullWidth
          text={`Log in with ${auth0Type || "..."}`}
          variant={BUTTON_VARIANTS.ROUNDED}
          onClick={loginWith}
        />
      ) : (
        <ButtonEl
          fullWidth
          testID="submitLoginForm"
          text="Log in"
          type="submit"
          isLoading={isLoginLoading}
          disabled={isSubmitDisabled}
          variant={BUTTON_VARIANTS.ROUNDED}
        />
      )}
    </FormButtons>
  )
}

SubmitButtons.propTypes = {
  isSsoError: PropTypes.bool,
  isLoginLoading: PropTypes.bool,
  loginErrorCode: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,
}

const mapStateToProps = ({ user }) => ({
  auth0Type: user.auth0Type,
})

export default connect(mapStateToProps)(SubmitButtons)
