import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"

export const Block = styled.div`
  width: 100%;
  min-height: 72px;
  box-sizing: border-box;
  padding: 16px 0 16px 16px;
  position: relative;

  &:first-child {
    border-radius: 4px 4px 0 0;
    &::after {
      content: "";
      width: 90%;
      height: 1px;
      background-color: var(--lightGray5);
      position: absolute;
      bottom: 0;
      left: 5%;
    }
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      flex-direction: column-reverse;
    `};
  ${({ $isSmallSize }) =>
    $isSmallSize &&
    css`
      flex-direction: row;
      align-items: flex-end;
      column-gap: 10px;
    `};
`

export const ActionsPanel = styled.div`
  width: max-content;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  margin: 17px 0 0 auto;
  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      width: 100%;
    `};
`

export const SortIcon = styled(SvgIcon)`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  ${({ $isNewest }) =>
    !$isNewest &&
    css`
      transform: scale(1, -1);
    `};
`

export const ShowMoreIcon = styled(SvgIcon)`
  margin-left: 5px;
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      transform: rotate(-90deg);
    `};
`
