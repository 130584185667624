import { StyleSheet } from "react-native"

import { AppSizes, AppColors, Units, AppFonts } from "@theme/"

export const PopupWrapperStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  overlayContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: AppColors.transparentOverlay,
    zIndex: 1,
  },
  dialogWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
    paddingBottom: Units.responsiveValue(16 + AppSizes.additionalBottomSpace),
    paddingHorizontal: Units.responsiveValue(16),
    backgroundColor: "rgba(0,0,0, 0.4)",
  },
  dialogContainer: {
    maxWidth: Units.responsiveValue(434),
    minWidth: Units.responsiveValue(434),
    borderRadius: Units.responsiveValue(10),
    // backgroundColor: AppColors.brand.white,
    // paddingTop: Units.responsiveValue(32),
    paddingBottom: Units.responsiveValue(16),
    overflow: "hidden",
    shadowColor: "black",
    shadowOpacity: 0.15,
    shadowRadius: 20,
    elevation: 10,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },

  closeButtonPosition: {
    position: "absolute",
    top: Units.responsiveValue(0),
    right: Units.responsiveValue(0),
  },
  closeButton: {
    width: Units.responsiveValue(42),
    height: Units.responsiveValue(42),
    paddingTop: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(8),
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  closeIcon: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
  },
})

export const ParticipantPopupStyles = StyleSheet.create({
  avatarContainer: {
    alignItems: "center",
    marginBottom: Units.responsiveValue(16),
  },
  overlayWrapper: {
    backgroundColor: "#ffffff",
    paddingTop: Units.responsiveValue(32),
    borderRadius: 10,
  },
  avatarAccesoryContainer: {
    top: Units.responsiveValue(166),
    right: null,
    left: Units.responsiveValue(144),
  },
  profileInfoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    paddingLeft: Units.responsiveValue(16),
    paddingRight: Units.responsiveValue(8),
  },
  profileWrapper: {
    justifyContent: "flex-end",
  },
  profileIconContainer: {
    width: Units.responsiveValue(36),
    height: Units.responsiveValue(28),
    justifyContent: "flex-end",
    alignItems: "center",
  },
  nameContainer: {
    alignItems: "flex-start",
  },
  fullName: {
    ...AppFonts.header20,
    color: AppColors.brand.black2,
  },
  displayName: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black2,
  },
  separator: {
    marginVertical: Units.responsiveValue(8),
    marginHorizontal: Units.responsiveValue(20),
    height: 1,
    backgroundColor: AppColors.brand.lightGrey,
  },
  activityContainer: {
    marginHorizontal: Units.responsiveValue(16),
    marginBottom: Units.responsiveValue(8),
  },
  activity: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.darkGrey2,
  },
  participantDetailsLinkContainer: {
    marginHorizontal: Units.responsiveValue(16),
    marginVertical: Units.responsiveValue(16),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  participantDetailsLink: {
    ...AppFonts.middleMedium,
    color: AppColors.brand.pink,
    fontFamily: "greycliffcf-regular-salt",
    fontSize: "15px",
    lineHeight: 1.2,
    letterSpacing: "-0.3px",
  },
  participantDetailsLinkIcon: {
    width: 16,
    height: 16,
    tintColor: "#f42862",
    marginRight: 4,
  },
  nudgesContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    // marginHorizontal: Units.responsiveValue(14),
    backgroundColor: "#ffffff",
    marginTop: 8,
    borderRadius: Units.responsiveValue(10),
    paddingVertical: 8,
  },
  nudgeContainer: {
    flex: 1,
    alignItems: "center",
    // borderColor: AppColors.brand.grey112,
    // borderWidth: 1,
    // marginHorizontal: Units.responsiveValue(2),
    paddingHorizontal: Units.responsiveValue(14),
    paddingVertical: Units.responsiveValue(11),
    flexDirection: "row",
    width: "100%",
  },
  nudgeIcon: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
    // tintColor: AppColors.brand.grey112,
    tintColor: "#222222",
    marginRight: 14,
  },
  nudgeText: {
    fontFamily: "greycliffcf-regular-salt",
    fontSize: "17px",
    lineHeight: 1.06,
    letterSpacing: "-0.34px",
    color: "#222222",
    textAlign: "center",
  },
})
