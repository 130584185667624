import React from "react"

import PropTypes from "prop-types"
import { PanResponder } from "react-native"

import { Units } from "@theme"
import { getDateWithTimezone } from "@utils/dateUtils"

export const ROW_HEIGHT = Units.responsiveValue(50)
export const KNOB_HEIGHT = Units.responsiveValue(20)

export default class SelectedDateBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    const { onDurationChanged } = props
    this._evenStartTop = null
    this._eventStartBottom = null
    this._panResponderTop = this.buildGestures(onDurationChanged)
    this._panResponderBottom = this.buildGestures(onDurationChanged, "bottom")
  }

  buildGestures = (onDurationChanged, direction = "top") =>
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderGrant: ({ nativeEvent }) => {
        if (direction === "top") {
          this._evenStartTop = nativeEvent
        } else {
          this._eventStartBottom = nativeEvent
        }
      },
      onPanResponderMove: ({ nativeEvent }) => {
        const { pageY } = nativeEvent
        const startEvent =
          direction === "top" ? this._evenStartTop : this._eventStartBottom
        const prevPageY = startEvent.pageY
        const length = (pageY - prevPageY) / 50
        if (Math.abs(length) > 0.08) {
          onDurationChanged({ direction, length })

          if (direction === "top") {
            this._evenStartTop = nativeEvent
          } else {
            this._eventStartBottom = nativeEvent
          }
        }
      },
      onPanResponderRelease: () => {},
      onPanResponderTerminate: () => {},
    })

  getPosition() {
    const { selectedDate, duration } = this.props

    const currentDate = getDateWithTimezone(selectedDate)
    let multiplyIndex = 1
    if (duration || duration >= 0) {
      multiplyIndex = duration / 60
    }
    const position = currentDate.hour() + currentDate.minute() / 60
    return { position, multiplyIndex }
  }
}

SelectedDateBlockComponent.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duration: PropTypes.number,
  newSuggestionProps: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onDurationChanged: PropTypes.func,
}
