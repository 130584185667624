import { Component } from "react"

class ErrorBoundary extends Component {
  state = { hasError: false }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError error", error)

    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("componentDidCatch eee", error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      //   @ts-ignore
      return this.props.fallback
    }
    //   @ts-ignore
    return this.props.children
  }
}

export default ErrorBoundary
