import dayjs from "utils/dayjs"

export const setDefaultTimeZone = (moment, timezoneName) => {
  moment.tz.setDefault(timezoneName)
  dayjs.tz.setDefault(timezoneName)
  moment.updateLocale("en", {
    week: {
      dow: 0,
    },
  })
}
