import { useEffect, useMemo, useRef, useState } from "react"

import cn from "classnames"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { SvgIcon } from "@components/common"
import { getParticipantStatus } from "@utils/peopleUtils"

import Info from "./Info"
import InviteButtons from "./Invite/Buttons"
import InviteHeadInfo from "./Invite/HeadInfo"
import MenuButtons from "./MenuButtons"
import { TEEUP_STATUS, TeeUpCardStage, TeeupItem } from "types/teeup"
import { Participant, USER_STATUS } from "types/participant"

import { useInboxStore } from "stores/inboxStore"
import {
  MAX_PARTICIPANTS,
  getCardWhenInfo,
  getCardWhereInfo,
} from "@utils/inbox"
import {
  getStageOptionsByStatusId,
  removedStageOption,
} from "@utils/teeupStateUtils"
import StageLabel from "./StageLabel"
import TeeUpPhoto from "./TeeUpPhoto"
import { slice } from "lodash"
import Nudge from "./Nudge"
import { updateTeeupStatus } from "@actions/teeupActions"
import { teeupCategories, teeupUserStatusPriorities } from "@config/enums"
import dayjs from "utils/dayjs"
import { useSelector } from "react-redux"
import { selectUserId } from "@selectors/user"

const findRemovedFromTeeup = (participants, userId) =>
  participants.find(
    item =>
      item.userId === userId &&
      // @ts-ignore
      item.status === teeupUserStatusPriorities.removed
  )

const highlightText = (text, searchValue) => {
  if (!searchValue.trim()) {
    return text
  }
  const regex = new RegExp(`(${searchValue})`, "gi")
  const parts = text.split(regex)

  return parts.map((part, index) =>
    regex.test(part) ? (
      <span
        key={index}
        className="bg-amber-100 py-0.5 rounded-md mix-blend-multiply">
        {part}
      </span>
    ) : (
      part
    )
  )
}

interface Props {
  teeUp: TeeupItem
  isArchiveTab?: boolean
  onClick: (teeUp: TeeupItem, removedFromById?: number | null) => void
  onAcceptInvitation?: (teeUp: TeeupItem) => void
  onDismissInvitation?: (teeUp: TeeupItem) => void
  onPreviewClick?: (teeUp: TeeupItem) => void
  refetchTeeUps?: () => void
}

const Card = ({
  teeUp,
  isArchiveTab,
  onClick,
  onAcceptInvitation,
  onDismissInvitation,
  onPreviewClick,
  refetchTeeUps,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [status, setStatus] = useState<TeeUpCardStage | null>(null)
  const [isCalendarConflict, setIsCalendarConflict] = useState(false)
  const setIsArchiveSnackBarOpen = useInboxStore(
    state => state.setIsArchiveSnackBarOpen
  )
  const [removedFromById, setRemovedFromById] = useState<number | null>(null)
  const setIsLoading = useInboxStore(state => state.setIsLoading)

  const users = useInboxStore(store => store.users)
  const participants = useInboxStore(state => state.teeupParticipants[teeUp.id])
  const teeUpState = useInboxStore(state => state.teeUpsState[teeUp.id])
  const setCurrentActiveTeeUp = useInboxStore(
    state => state.setCurrentActiveTeeUp
  )
  const setIsOrganizerArchiveModalOpen = useInboxStore(
    state => state.setIsOrganizerArchiveModalOpen
  )
  const setIsArchiveModalOpen = useInboxStore(
    state => state.setIsArchiveModalOpen
  )
  const searchValue = useInboxStore(state => state.searchValue)
  const gameplanOptions = useInboxStore(
    state => state.gameplanOptions[teeUp.id]
  )
  const nudges = useInboxStore(state => state.nudges[teeUp.id])
  const userId = useSelector(selectUserId)

  const touchStartX = useRef(0)
  const touchEndX = useRef(0)

  const isInvitation = teeUp?.userstatus === USER_STATUS.INVITED
  const isGrayOut =
    teeUp.status === TEEUP_STATUS.CANCELLED ||
    teeUp.status === TEEUP_STATUS.ENDED
  // &&  teeUp.status !== TEEUP_STATUS.ENDED

  const { whenSubtitle, whenTitle, whereTitle, whereSubtitle, whereIconName } =
    useMemo(() => {
      if (!gameplanOptions) return {} as any

      const when = gameplanOptions.find(option => option.type === "when")
      const where = gameplanOptions.find(option => option.type === "where")

      const isCalendarConflict = when?.suggestions.some(
        suggestion => suggestion.isCalendarConflict
      )

      setIsCalendarConflict(!!isCalendarConflict)
      return {
        ...getCardWhenInfo(when?.suggestions),
        ...getCardWhereInfo(where?.suggestions),
      }
    }, [gameplanOptions])

  const timeLabel = useMemo(() => {
    const now = dayjs()
    const date = dayjs(teeUp.lastActedAt)
    const diff = now.diff(date, "minute")

    const format = (number, devideBy) => {
      return Math.floor(number / devideBy)
    }

    if (!diff) {
      const seconds = now.diff(date, "second")
      return `${seconds}s ago`
    } else if (diff > 0 && diff < 60) {
      return `${diff}m ago`
    } else if (diff > 60 && diff < 1440) {
      return `${format(diff, 60)}h ago`
    } else if (diff > 1440 && diff < 43800) {
      return `${format(diff, 1440)}d ago`
    } else if (diff > 43800) {
      return `${format(diff, 10080)} month ago`
    }
  }, [teeUp.lastActedAt])

  useEffect(() => {
    const removedParticipant = findRemovedFromTeeup(
      participants,
      userId
    ) as Participant
    if (removedParticipant?.userId) {
      setStatus(removedStageOption)
      setRemovedFromById(removedParticipant.userId)
      return
    }

    const status = getStageOptionsByStatusId()?.[teeUp?.status]
    setStatus(status || null)
  }, [participants])

  const slicedParticipants = useMemo(
    () => slice(participants, 0, MAX_PARTICIPANTS),
    [participants?.length]
  ) as Participant[]

  const handleClick = () => {
    if (isInvitation) return

    onClick(teeUp, removedFromById)
  }

  const handleSwipeStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX
  }
  const handleSwipeEnd = (e: React.TouchEvent) => {
    touchEndX.current = e.changedTouches[0].clientX

    if (touchStartX.current > touchEndX.current + 50) {
      setIsMenuOpen(true)
    } else if (touchStartX.current < touchEndX.current - 50) {
      setIsMenuOpen(false)
    }
  }

  const handleArchiveClick = async (isArchiveTab?: boolean) => {
    setCurrentActiveTeeUp(teeUp)

    const organizers = Object.values(participants).filter(
      participant => participant.isOrganiser
    )
    const isOrganizer = participants.some(
      item => item.isOrganiser && item.userId === userId
    )

    if (isOrganizer && organizers.length < 2) {
      setIsOrganizerArchiveModalOpen(true)
      return
    }
    if (!isArchiveTab) {
      setIsArchiveModalOpen(true)
      return
    }

    if (isArchiveTab) {
      setIsLoading(true)
      setIsArchiveSnackBarOpen(true)
      await updateTeeupStatus(teeUp?.id, teeupCategories.teeups)
      refetchTeeUps && refetchTeeUps()
    }
  }

  return (
    <div
      onClick={e => e.stopPropagation()}
      onTouchStart={handleSwipeStart}
      onTouchEnd={handleSwipeEnd}
      className={cn(
        "relative rounded-lg border border-neutral-300 flex cursor-pointer animate-fade",
        {
          "bg-blue-50": isInvitation,
          "bg-white": !isInvitation && !isGrayOut,
          "opacity-50 bg-white": isGrayOut && !isInvitation,
        }
      )}>
      <div
        className="w-full p-4 transition-all duration-300 ease-in-out"
        onClick={handleClick}>
        {isInvitation && <InviteHeadInfo participant={participants[0]} />}
        {!isInvitation && (
          <div className="flex gap-2 justify-between items-center">
            <div className="flex gap-2">
              <StageLabel status={status} />

              {isCalendarConflict && (
                <div className="bg-rose-50 rounded border border-red-200 px-1.5 py-0.5 flex items-center justify-center">
                  <SvgIcon name="calendar-conflict" className="!size-4" />
                </div>
              )}
            </div>

            {!teeUpState?.seen && (
              <div className="w-3 h-3 bg-blue-500 rounded-full" />
            )}
          </div>
        )}

        <div className="mt-2 flex items-center justify-between">
          <h3 className="text-base font-semibold text-neutral-900">
            {highlightText(teeUp.name, searchValue)}
          </h3>

          {isInvitation && <StageLabel status={status} />}
        </div>

        <div
          className={cn("mt-2 flex items-center justify-between", {
            "mt-4": isInvitation,
          })}>
          <div>
            <div className="flex flex-col gap-1">
              <Info
                iconName="clock-2"
                title={whenTitle}
                subtitle={whenSubtitle}
              />

              <Info
                iconName={whereIconName || "gray-location"}
                title={whereTitle}
                subtitle={whereSubtitle}
              />
            </div>

            <div className="mt-2 flex gap-1">
              {slicedParticipants?.map(participant => (
                <ParticipantIcon
                  avatar={participant?.avatar}
                  status={getParticipantStatus(participant.status)}
                  isOrganizer={participant.isOrganiser}
                  defaultAvatar={participant?.username || "A"}
                  className="!size-6"
                  statusClassName="!size-3"
                  key={`${teeUp.id}-${participant.userId}`}
                />
              ))}

              {participants?.length > MAX_PARTICIPANTS && (
                <ParticipantIcon
                  avatar={""}
                  defaultAvatar={`+${participants?.length - MAX_PARTICIPANTS}`}
                  isFullDefaultAvatar
                  className="!size-6"
                  statusClassName="!size-3"
                />
              )}
            </div>
          </div>

          <TeeUpPhoto src={teeUp.photo} isInvitation={isInvitation} />
        </div>

        {isInvitation && (
          <InviteButtons
            onAcceptInvitation={onAcceptInvitation}
            onDismissInvitation={onDismissInvitation}
            onPreviewClick={onPreviewClick}
            teeUp={teeUp}
          />
        )}

        {!isInvitation && (
          <div
            className="absolute right-[-30px] top-1/2 -translate-y-1/2 xs:hidden md:block"
            onClick={e => {
              e.stopPropagation()
              setIsMenuOpen(!isMenuOpen)
            }}>
            <SvgIcon name="three-dots-2" className="!size-6 cursor-pointer" />
          </div>
        )}

        {!isInvitation && (
          <div className="w-full flex justify-end text-neutral-500 text-xs font-medium">
            {timeLabel}
          </div>
        )}

        <Nudge
          teeUpId={teeUp.id}
          nudge={nudges}
          users={users}
          onOpen={() => onClick(teeUp, removedFromById)}
        />
      </div>

      {!isInvitation && (
        <MenuButtons
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isArchiveTab={!!isArchiveTab}
          onArchiveClick={handleArchiveClick}
        />
      )}
    </div>
  )
}

export default Card
