import styled, { css } from "styled-components"

export const Wrapper = styled.section`
  ${({ $isCreateTeeup }) => css`
    height: 95%;
    background-color: var(--white);
    box-sizing: border-box;
    padding: ${$isCreateTeeup ? "60px 0 10% 0" : "0"};
  `};
`
