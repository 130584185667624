import React from "react"

import { View, ActivityIndicator } from "react-native"

import styles from "./templateMessageStyles"

const TemplateMessage = () => (
  <View style={styles.templateMessageContainer}>
    <ActivityIndicator
      style={styles.replyMessageSender}
      size="large"
      color="#0000ff"
    />
  </View>
)

export default TemplateMessage
