import { object, string } from "yup"

import { EMAIL_VALIDATION_REGEX } from "../../constants/global"

export const newSignInSchema = object().shape({
  email: string()
    .matches(EMAIL_VALIDATION_REGEX, "Please enter a valid email address.")
    .required(),
  password: string().required(),
})
