import React from "react"

import "./index.scss"
import cn from "classnames"
import find from "lodash/find"

const FilterSection = ({
  title,
  buttons,
  onFilterButtonPress,
  currentFiltering,
}) => {
  return (
    <div className="filtersection">
      <h2 className="filtersection__title">{title}</h2>
      <div className="filtersection__content">
        {buttons.map(item => (
          <div
            key={item.title}
            className={cn("filtersection__button", {
              "filtersection__button--active": !!find(
                currentFiltering,
                i => i && i.id === item.id
              ),
            })}
            onClick={() => onFilterButtonPress(item)}>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterSection
