import YourSuggestions from "components/ui/YourSuggestions"

import { GoogleApiWrapper } from "google-maps-react"

import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import Map from "./Map"
import Name from "./Name"

import "./index.scss"
import { SvgIcon } from "@components/common"
import Pill from "@components/ui/Pill"
import { InPersonSuggestion } from "types/suggestions"

import AdditionalInformation from "./AdditionalInformation"
import Search from "./Search"

interface Props {
  onSave: (suggestions: InPersonSuggestion[], isDecided: boolean) => void
}

const InPerson = ({ onSave }: Props) => {
  const selectedName = useSuggestionInPersonStore(state => state.selectedName)
  const selectedAddress = useSuggestionInPersonStore(
    state => state.selectedAddress
  )
  const setSelectedName = useSuggestionInPersonStore(
    state => state.setSelectedName
  )
  const setSelectedAddress = useSuggestionInPersonStore(
    state => state.setSelectedAddress
  )
  const isMapExpanded = useSuggestionInPersonStore(state => state.isMapExpanded)
  const suggestions = useSuggestionInPersonStore(state => state.suggestions)
  const addSuggestion = useSuggestionInPersonStore(state => state.addSuggestion)
  const removeSuggestion = useSuggestionInPersonStore(
    state => state.removeSuggestion
  )
  const setCurrentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const currentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.currentActiveSuggestionIndex
  )
  const currentAdditionalInformation = useSuggestionInPersonStore(
    state => state.currentAdditionalInformation
  )
  const setCurrentAdditionalInformation = useSuggestionInPersonStore(
    state => state.setCurrentAdditionalInformation
  )
  const isMeetingDetails = selectedName || selectedAddress
  const isSuggestionExist = suggestions.length > 0

  const handleAddAnother = () => {
    const newSuggestion = {
      name: selectedName,
      address: selectedAddress,
      additionalInformation: currentAdditionalInformation,
    }

    if (!isSuggestionExist) {
      addSuggestion(newSuggestion)
    }
    addSuggestion(newSuggestion)
    setSelectedName("")
    setSelectedAddress(null)

    setCurrentAdditionalInformation("")
    if (isSuggestionExist) {
      setCurrentActiveSuggestionIndex(suggestions.length)
    } else {
      setCurrentActiveSuggestionIndex(suggestions.length + 1)
    }
  }

  const handleSuggestionClick = (
    suggestion: InPersonSuggestion,
    index: number
  ) => {
    setSelectedName(suggestion.name)
    setSelectedAddress(suggestion.address)
    setCurrentAdditionalInformation(suggestion?.additionalInformation || "")
    setCurrentActiveSuggestionIndex(index)
  }

  const handleSuggestClick = (isDecided: boolean) => {
    if (suggestions.length > 0) {
      if (suggestions.length === 1 && currentAdditionalInformation) {
        onSave(
          [
            {
              ...suggestions[0],
              additionalInformation: currentAdditionalInformation,
            },
          ],
          isDecided
        )
      }
      onSave(suggestions, isDecided)
      return
    }

    onSave(
      [
        {
          name: selectedName,
          address: selectedAddress,
          additionalInformation: currentAdditionalInformation,
        },
      ],
      isDecided
    )
  }

  return (
    <div className="suggest-where-in-person">
      <h4>
        {isMeetingDetails ? "Meeting Details" : "Where do you want to meet?"}
      </h4>

      <Name />

      <Search />

      <Map />

      <AdditionalInformation />

      <YourSuggestions
        isShown={
          (!!selectedAddress || suggestions.length > 0) && !isMapExpanded
        }
        suggestions={suggestions}
        onDecideClick={() => handleSuggestClick(true)}
        onSuggestClick={() => handleSuggestClick(false)}
        onAddAnotherClick={handleAddAnother}
        // @ts-ignore
        renderPill={(suggestion: InPersonSuggestion, index) => (
          <Pill
            isSelected={currentActiveSuggestionIndex === index}
            onClick={() => handleSuggestionClick(suggestion, index)}>
            <span>
              {suggestion.name} <span>{suggestion?.address?.label}</span>
            </span>

            {suggestions.length > 1 && (
              <SvgIcon
                name="cross"
                onClick={e => {
                  e.stopPropagation()
                  removeSuggestion(index)
                }}
              />
            )}
          </Pill>
        )}
        defaultPill={
          <Pill isSelected>
            <span>
              {selectedName} <span>{selectedAddress?.label}</span>
            </span>
          </Pill>
        }
      />
    </div>
  )
}

const PropsWrapper = props => {
  const WrapperInPerson = GoogleApiWrapper({
    apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
    // @ts-ignore
  })(InPerson)

  // @ts-ignore
  return <WrapperInPerson onSave={props.onSave} />
}

export default PropsWrapper
