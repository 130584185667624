import React, { useCallback, useEffect } from "react"

import { connect, useDispatch } from "react-redux"

import { setIsFromCreateTeeUp } from "@actions/commonActions"
import {
  clearGamePlan,
  setCreateTeeupIsInviteesError,
  getContacts,
} from "@actions/createTeeupActions"
import { fetchRequests } from "@actions/requestActions"
import { fetchTeeups } from "@actions/teeupActions"
import { getRandomUnsplashPhoto } from "@config/externalApi"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import {
  selectCreateTeeupIsInviteesError,
  selectCreateTeeupScreenType,
} from "@selectors/createTeeUp"
import { getRandomBase64Image } from "@utils/imageUtils"
import { logScreen, SCREENS } from "analytics-tracking"
import { InvitePeopleState } from "contexts/InvitePeopleContext"

import {
  SelectImage,
  CreateTeeupMainScreen,
  CreateTeeupInviteScreen,
} from "components"

import { useCreateTeeUp } from "./CreateTeeUpContext/useCreateTeeUp"

import "./index.scss"

import TeeupPermissionsNd from "./TeeUpPermissionsNd"

const CreateTeeUpNd = ({ isInviteesError, setIsInviteesError, screenType }) => {
  const { image, invitees, setImage } = useCreateTeeUp() //from useCreateTeeUp hooks

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsFromCreateTeeUp(true))
    dispatch(fetchTeeups())
    dispatch(getContacts())
    dispatch(fetchRequests()) //loading user contacts above
    logScreen(SCREENS.CREATE_TEEUP)

    return () => {
      dispatch(clearGamePlan())
    }
  }, [])

  // const fetchImg = useCallback(async () => {
  //   const newImage = await getRandomBase64Image(null, null, true)
  //   if (!newImage) return
  //   setImage(newImage)
  // }, [])

  // useEffect(() => {
  //   if (image) return

  //   fetchImg()
  // }, [])

  useEffect(() => {
    isInviteesError && invitees.length > 0 && setIsInviteesError(false)
  }, [invitees])

  const renderContent = () => {
    switch (screenType) {
      case CREATE_TEEUP_SCREENS.SETTINGS:
        return <TeeupPermissionsNd />
      case CREATE_TEEUP_SCREENS.SELECT_IMAGE:
        return <SelectImage isCreateTeeup setImage={setImage} />
      case CREATE_TEEUP_SCREENS.ADD_PEOPLE:
        return (
          <InvitePeopleState>
            <CreateTeeupInviteScreen />
          </InvitePeopleState>
        )
      default:
        return <CreateTeeupMainScreen />
    }
  }

  return renderContent()
}

const mapStateToProps = state => ({
  isInviteesError: selectCreateTeeupIsInviteesError(state),
  screenType: selectCreateTeeupScreenType(state),
})

const mapDispatchToProps = dispatch => ({
  setIsInviteesError: payload =>
    dispatch(setCreateTeeupIsInviteesError(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeeUpNd)
