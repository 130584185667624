import React, { useEffect, useState } from "react"

import { IoMdMore } from "react-icons/io"
import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import PeopleThreeDots from "../PeopleThreeDots"

import "./index.scss"

export const peopleStatus = [
  {
    status: "joined",
    image: images.ndJoinedStatus,
    color: "rgb(127, 127, 127)",
  },
  {
    status: "invited",
    image: images.envelope,
    color: "rgb(157, 157, 157)",
  },
  {
    status: "going",
    image: images.ndArrowIconWhite,
    color: "#00c5ae",
  },
  {
    status: "mightgo",
    image: images.question,
    color: "rgb(255, 111, 37)",
  },
  {
    status: "notgoing",
    image: images.times,
    color: "rgb(245, 45, 34)",
  },
  {
    status: "onmyway",
    image: images.rocket,
    color: "rgb(82, 39, 166)",
  },
  {
    status: "runningLate",
    image: images.runningLateNoBg2,
    color: "rgb(255, 111, 37)",
  },
  {
    status: "arrived",
    image: images.flag,
    color: "rgb(0, 188, 246)",
  },
  {
    status: "interested",
    image: images.interested,
    color: "rgba(244, 40, 98)",
  },
  {
    status: "notInterested",
    image: images.notInterested,
    color: "rgba(204, 204, 204, 1)",
  },
]

const PeopleRow = ({ peopleInfo, close }) => {
  const [isHover, setIsHover] = useState(false)
  const [isThreeDotsOpen, setIsThreeDotsOpen] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(null)

  useEffect(() => {
    setCurrentStatus(
      peopleStatus.find(item => item.status === peopleInfo.status)
    )
  }, [])

  return (
    <div
      className="people-column-item"
      data-testid="peopleColum"
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false)
        setIsThreeDotsOpen(false)
      }}>
      <div className="people-column-item__avatar--wrapper">
        {peopleInfo.isOrganiser && (
          <ReactSVG
            data-testid="organizer"
            className="people-column-item__avatar--organizer"
            src={images.ndCrown}
          />
        )}
        {peopleInfo.avatar ? (
          <img
            className="people-column-item__avatar"
            src={peopleInfo.avatar}
            alt="avatar"
          />
        ) : (
          <p className="people-column-item__avatar--empty">
            {peopleInfo.name.split("")[0]}
          </p>
        )}
        {currentStatus && (
          <div
            className="people-column-item__status"
            style={{ backgroundColor: currentStatus.color }}>
            <img
              className="people-column-item__status--icon"
              src={currentStatus.image}
              alt={currentStatus.status}
            />
          </div>
        )}
      </div>
      {!close && (
        <>
          <div className="people-column-item__introduce">
            <h4 className="people-column-item__name">{peopleInfo.name}</h4>
            {peopleInfo.username}
          </div>

          {isHover && (
            <div
              className="people-column-item__dots"
              onClick={() => setIsThreeDotsOpen(!isThreeDotsOpen)}>
              <div
                data-testid="dotsButton"
                className="people-column-item__dots--icon">
                <IoMdMore size={16} color="#a9b0b9" />
              </div>
              {isThreeDotsOpen && <PeopleThreeDots data={peopleInfo} />}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default PeopleRow
