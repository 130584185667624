import React from "react"

import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"

// import I18n from '../../../../i18n';
// import { getNudgeText } from '@utils/teeupUtils'
import { connect } from "react-redux"

import { selectMentionsPrompts } from "@selectors/prompts"
import {
  selectTeeupsPeople,
  selectTeeupReactionsBySuggestionId,
  selectTeeupSuggestions,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { AppColors } from "@theme"
import { images } from "@utils/imageUtils"

import {
  getLocaleTime,
  stringToDateFormat,
} from "../../../../../src/utils/dateUtils"
import Avatar from "../../../ui/avatar"

// import Icon from '@ui/icon'
import { styles, headerContainer } from "./styles"

const teeupListPrompts = {
  organizer: "You're the organizer",
  afterDecline: "You declined this TeeUp, join it now?",
  afterDroppout: "You dropped out of this TeeUp, rejoin it now?",
  archive: "Archive this TeeUp?",
  isUndecidedWhenInGameplan:
    "Your Action is Required to Decide on the date/time",
  mention: "mentioned you in conversation.",
  removed: "You've been removed from this TeeUp",
  responded: "responded to your nudge.",
  4: "Does this work for you?",
  5: "Are you going?",
  6: "Where are you?",
  10: "Would like you to make a when suggestion.",
  11: "Would like you to make a where suggestion.",
  multipleNudgesText: "You have multiple nudges waiting for your response.",
  mentionText: "mentioned you.",
}

const OrganizerText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.organizer}
  </Text>
)

const InvitationUserName = ({ invitation }) => (
  <Text style={[styles.row, styles.headerTitleText, { lineHeight: "20px" }]}>
    {invitation.userName}{" "}
  </Text>
)

const InvitationText = ({ invitation, isFromOverviewPage }) => (
  <Text style={styles.headerTitleText}>
    {isFromOverviewPage ? (
      <Text style={[styles.row, styles.headerTitleInvitationText]}>
        <Text style={styles.userInfoContainer}>
          <Text style={styles.avatarStyle}>
            <Avatar
              size={28}
              imageUrl={invitation?.userAvatar}
              innerContentStyle={{
                borderColor: AppColors.brand.white,
                border: "solid 2px",
              }}
            />
          </Text>
          <Text style={styles.usernameContainer}>
            <Text style={styles.username}>{invitation?.userName} </Text>
            <Text style={styles.username}>invited you to</Text>
          </Text>
        </Text>
      </Text>
    ) : (
      <Text></Text>
    )}
  </Text>
)

InvitationText.propTypes = {
  invitation: PropTypes.object,
}

const DeclinedRejoinText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.afterDecline}
  </Text>
)

const DroppedOutRejoinText = () => (
  <Text style={[styles.row, styles.headerTitleText]}>
    {teeupListPrompts.afterDroppout}
  </Text>
)

const ArchiveText = ({ isFromOverviewPage }) => (
  <Text
    style={
      isFromOverviewPage
        ? styles.archivePrompOverviewPage
        : [styles.row, styles.headerTitleText]
    }>
    {teeupListPrompts.archive}
  </Text>
)
const UserRemovedText = () => (
  <Text style={[styles.row, styles.headerUserRemovedTitleText]}>
    {teeupListPrompts.removed}
  </Text>
)

const DoesThisWorkForYouNudge = ({
  nudgeType,
  nudgeIssuerName,
  nudgeIssuerAvatar,
  suggestionPromptDateTime,
  suggestionPropmtPlace,
  isLargerDiv,
}) => (
  <Text
    style={[
      isLargerDiv ? styles.largerDiv : [styles.row, styles.headerTitleText],
    ]}>
    <Text style={styles.userInfoContainer}>
      <Text style={styles.avatarStyle}>
        <Avatar
          size={28}
          imageUrl={nudgeIssuerAvatar}
          innerContentStyle={{
            borderColor: AppColors.brand.white,
            border: "solid 2px",
          }}
        />
      </Text>
      <Text style={styles.usernameContainer}>
        <Text style={styles.username}>{nudgeIssuerName} asked you</Text>
        <Text style={styles.nudgeText}>{teeupListPrompts[nudgeType]}</Text>
        {suggestionPromptDateTime !== "" && (
          <Text style={styles.suggestionText}>
            <Image
              style={styles.suggestionIcon}
              source={images.ndSuggestClock}
            />
            {suggestionPromptDateTime}
          </Text>
        )}
        {suggestionPropmtPlace !== "" && (
          <Text style={styles.suggestionText}>
            <Image
              style={styles.suggestionIcon}
              source={images.iconPinGamePlan}
            />
            {suggestionPropmtPlace}
          </Text>
        )}
      </Text>
    </Text>
  </Text>
)
const WouldYouSuggestNudge = ({
  nudgeType,
  nudgeIssuerName,
  nudgeIssuerAvatar,
  isFromOverviewPage,
}) => (
  <Text style={[styles.row, styles.headerTitleText]}>
    <Text style={styles.userInfoContainer}>
      <Text style={styles.avatarStyle}>
        <Avatar
          size={28}
          imageUrl={nudgeIssuerAvatar}
          innerContentStyle={{
            borderColor: AppColors.brand.white,
            border: "solid 2px",
          }}
        />
      </Text>
      <Text style={styles.usernameContainer}>
        <Text style={styles.nudgeText}>{nudgeIssuerName}</Text>
        <Text style={styles.nudgeText}>{teeupListPrompts[nudgeType]}</Text>
      </Text>
    </Text>
  </Text>
)

const NudgeText = ({ nudgeType, nudgeIssuerName, nudgeIssuerAvatar }) => (
  <Text style={[styles.row, styles.headerTitleText]}>
    <Text style={styles.userInfoPromptContainer}>
      <Text style={styles.avatarStyle}>
        <Avatar
          size={28}
          imageUrl={nudgeIssuerAvatar}
          innerContentStyle={{
            borderColor: AppColors.brand.white,
            border: "solid 2px",
          }}
        />
      </Text>
      <Text style={[styles.row, styles.nudgeHeaderTitleText]}>
        <Text style={styles.usernameNudgeContainer}>
          {nudgeIssuerName} asked you
          <Text> {teeupListPrompts[nudgeType]}</Text>
        </Text>
      </Text>
    </Text>
  </Text>
)

const UserRespondedToNudgeText = ({ nudgeIssuerName, nudgeIssuerAvatar }) => (
  <Text style={[styles.row, styles.headerUserRespondedTitleText]}>
    <Avatar
      size={28}
      imageUrl={nudgeIssuerAvatar}
      innerContentStyle={{
        borderColor: AppColors.brand.white,
        border: "solid 2px",
      }}
    />
    <Text style={styles.respondedText}>
      {nudgeIssuerName} {teeupListPrompts.responded}
    </Text>
  </Text>
)

const MultipleNudgesText = () => (
  <Text style={styles.pendindNudgesContainer}>
    <Text style={styles.nudgesIconAndTitle}>
      <Image style={styles.nudgesIcon} source={images.nudge} />
      <Text style={styles.pendindNudgesTitle}>Pending Nudges</Text>
    </Text>
    <Text style={[styles.row, styles.headerMultipleNudgesTitleText]}>
      {teeupListPrompts.multipleNudgesText}
    </Text>
  </Text>
)

const UserMentionedYouText = ({ nudgeIssuerName, nudgeIssuerAvatar }) => (
  <Text style={[styles.row, styles.headerMentionTitleText]}>
    <Avatar
      size={28}
      imageUrl={nudgeIssuerAvatar}
      innerContentStyle={{
        borderColor: AppColors.brand.white,
        border: "solid 2px",
      }}
    />
    <Text style={styles.respondedText}>{nudgeIssuerName}</Text>
    <Text> {teeupListPrompts.mentionText}</Text>
  </Text>
)
const TeeupItemHeaderTitle = ({
  showAvatar = true,

  isOrganizer = false,
  invitation,
  isInvited,

  isArchivePromptShow,

  fromNowString,

  children,
  isEnded,
  isCancelled,
  isOneDayAfterEnded,
  isArchived,

  isKickedOut,
  nudgeInfo,
  teeup,
  userInfo,

  suggestionToNudgeId,
  typeToNudge,
  teeupSuggestions,
  states,
  messages,
  teeupsState,
  suggestions,
  nudgesWithoutResponses,
  userId,
  hasMultipleNudges,
  mentionedMessages,
  isNudgeResponseReceived,
  isFromOverviewPage,
  // isRespondedNudge,
  isLargerDiv,
  mentionsPrompts,
}) => {
  const getTargetId = () => {
    if (nudgesWithoutResponses && nudgesWithoutResponses[0]) {
      let targetId = nudgesWithoutResponses[0].targetId
      return targetId
    }
  }
  let targetId = getTargetId()

  let nudgeSuggestionStartTime

  teeup.gameplans &&
    teeup.gameplans[0].suggestions.map(item => {
      if (targetId == item.id) {
        if (item.when) {
          nudgeSuggestionStartTime = item.when.value
        } else if (item.startDate) {
          nudgeSuggestionStartTime = item.startDate
        }
      }
    })

  let nudgeSuggestionEndTime
  teeup.gameplans &&
    teeup.gameplans[0].suggestions.map(item => {
      if (targetId == item.id) {
        if (item.when) {
          nudgeSuggestionEndTime = item.when.endDate
        } else if (item.endDate) {
          nudgeSuggestionEndTime = item.endDate
        }
      }
    })

  let nudgeSuggestionPlace

  teeup.gameplans &&
    teeup.gameplans[1].suggestions.map(item => {
      if (targetId == item.id) {
        nudgeSuggestionPlace = item.where
      }
    })

  let place
  if (nudgeSuggestionPlace) {
    let placeDetails = nudgeSuggestionPlace.details
    let placeValue = nudgeSuggestionPlace.value
    place = `${placeValue}, ${placeDetails}`
  } else place = ""

  const initialValue = {
    line1: null,
    line2: null,
    full: null,
    empty: true,
  }

  let processedValue = { ...initialValue }
  let time

  if (nudgeSuggestionStartTime && nudgeSuggestionEndTime) {
    const startFormatted = getLocaleTime(nudgeSuggestionStartTime)
    const endFormatted = getLocaleTime(nudgeSuggestionEndTime)

    if (startFormatted === endFormatted) {
      time = startFormatted
    } else {
      time = startFormatted + "-" + endFormatted
      processedValue.startTimeFormatted = startFormatted
      processedValue.endTimeFormatted = endFormatted
    }
  } else if (nudgeSuggestionStartTime) {
    time = getLocaleTime(nudgeSuggestionStartTime)
  } else {
    time = ""
  }

  let date = null
  if (nudgeSuggestionStartTime && nudgeSuggestionEndTime) {
    const format = "dddd, D MMM "
    const startFormatted = stringToDateFormat(nudgeSuggestionStartTime, format)

    const endFormatted = stringToDateFormat(nudgeSuggestionEndTime, format)

    date =
      startFormatted === endFormatted
        ? startFormatted
        : startFormatted + "-" + endFormatted
  } else if (nudgeSuggestionStartTime) {
    date = stringToDateFormat(nudgeSuggestionStartTime, "dddd, D MMM ")
  } else date = ""

  processedValue.line1 = date

  let suggestionPromptDateTime = `${processedValue.line1}${time}`

  let textComponent

  if (isNudgeResponseReceived && nudgeInfo) {
    let nudgeIssuerInfo = {}
    Object.keys(userInfo).map(key => {
      return Object.keys(userInfo[key]).map(userKeys => {
        if (nudgeInfo.nudgeIssuer == userInfo[key][userKeys].id) {
          nudgeIssuerInfo = userInfo[key][userKeys]
        }
      })
    })
    const nudgeIssuerName = nudgeIssuerInfo.name
    const nudgeIssuerAvatar = nudgeIssuerInfo.avatar

    textComponent = (
      <UserRespondedToNudgeText
        nudgeIssuerName={nudgeIssuerName}
        nudgeIssuerAvatar={nudgeIssuerAvatar}
      />
    )
  }

  if (isInvited && invitation) {
    textComponent = (
      <InvitationText
        invitation={invitation}
        isFromOverviewPage={isFromOverviewPage}
      />
    )
  }

  if (isKickedOut && !isArchived && teeup.isArchivePromptShow) {
    textComponent = <UserRemovedText />
  }

  if (nudgeInfo && nudgesWithoutResponses[0]) {
    let nudgeIssuerInfo = {}
    Object.keys(userInfo).map(key => {
      return Object.keys(userInfo[key]).map(userKeys => {
        if (nudgeInfo.nudgeIssuer == userInfo[key][userKeys].id) {
          nudgeIssuerInfo = userInfo[key][userKeys]
        }
      })
    })
    const nudgeIssuerName = nudgeIssuerInfo.name
    const nudgeIssuerAvatar = nudgeIssuerInfo.avatar

    if (nudgesWithoutResponses[0]?.subTypeId === 4 && !isInvited) {
      textComponent = (
        <DoesThisWorkForYouNudge
          nudgeType={nudgesWithoutResponses[0]?.subTypeId}
          nudgeIssuerName={nudgeIssuerName}
          nudgeIssuerAvatar={nudgeIssuerAvatar}
          suggestionPromptDateTime={suggestionPromptDateTime}
          suggestionPropmtPlace={place}
          isLargerDiv={isLargerDiv}
        />
      )
    }

    if (
      (nudgesWithoutResponses[0]?.subTypeId === 10 ||
        nudgesWithoutResponses[0]?.subTypeId === 11) &&
      !isInvited &&
      nudgesWithoutResponses[0]?.senderId !== userId
    ) {
      textComponent = (
        <WouldYouSuggestNudge
          nudgeType={nudgesWithoutResponses[0]?.subTypeId}
          // nudgeType={nudgeInfo.nudgeType}
          nudgeIssuerName={nudgeIssuerName}
          nudgeIssuerAvatar={nudgeIssuerAvatar}
          isFromOverviewPage={isFromOverviewPage}
        />
      )
    }

    if (
      (nudgesWithoutResponses[0]?.subTypeId === 5 ||
        nudgesWithoutResponses[0]?.subTypeId === 6) &&
      !isInvited &&
      nudgesWithoutResponses[0]?.senderId !== userId
    )
      textComponent = (
        <NudgeText
          nudgeType={nudgesWithoutResponses[0]?.subTypeId}
          nudgeIssuerName={nudgeIssuerName}
          nudgeIssuerAvatar={nudgeIssuerAvatar}
        />
      )
  }

  if (hasMultipleNudges && !isInvited) {
    textComponent = <MultipleNudgesText />
  }

  if (isOneDayAfterEnded && !isArchived && isArchivePromptShow) {
    textComponent = <ArchiveText isFromOverviewPage={isFromOverviewPage} />
  }
  if (isCancelled && !isArchived && teeup.isArchivePromptShow && !isInvited) {
    textComponent = <ArchiveText isFromOverviewPage={isFromOverviewPage} />
  }

  if (mentionsPrompts?.[teeup.id]) {
    const author = userInfo[teeup.id][mentionsPrompts[teeup.id]]

    if (author) {
      textComponent = (
        <UserMentionedYouText
          nudgeIssuerName={author.name}
          nudgeIssuerAvatar={author.avatar}
        />
      )
    }
  }

  if (mentionedMessages?.length > 0 && !isInvited && !invitation) {
    let nudgeIssuerInfo = {}
    Object.keys(userInfo).map(key => {
      return Object.keys(userInfo[key]).map(userKeys => {
        if (mentionedMessages[0].senderId == userInfo[key][userKeys].id) {
          nudgeIssuerInfo = userInfo[key][userKeys]
        }
      })
    })
    const nudgeIssuerName = nudgeIssuerInfo.name
    const nudgeIssuerAvatar = nudgeIssuerInfo.avatar

    textComponent = (
      <UserMentionedYouText
        nudgeIssuerName={nudgeIssuerName}
        nudgeIssuerAvatar={nudgeIssuerAvatar}
      />
    )
  }

  if (!textComponent) {
    return null
  }

  return (
    <View
      style={
        isFromOverviewPage
          ? styles.overviewPrompts
          : headerContainer(
              fromNowString,
              isArchivePromptShow,
              isEnded,
              isCancelled
            )
      }>
      <View
        style={[
          isFromOverviewPage && styles.fromOverViewPage,
          (nudgeInfo || mentionedMessages?.length > 0 || hasMultipleNudges) &&
          !isInvited &&
          !isFromOverviewPage
            ? styles.nudgeInfoHeaderContent
            : isFromOverviewPage
            ? styles.fromOverViewPage
            : styles.headerContent,
          isKickedOut && styles.isKickedOutContent,
          (isArchivePromptShow || isEnded || isCancelled || isKickedOut) && [
            styles.row,
            styles.contentBetween,
          ],
        ]}>
        {isKickedOut ? (
          <Text style={styles.removedUserContainer}>
            <Text>{textComponent}</Text>
            <Text style={styles.removedUserButtons}>{children}</Text>
          </Text>
        ) : (
          <>
            {textComponent}
            {children}
          </>
        )}
      </View>
    </View>
  )
}

TeeupItemHeaderTitle.propTypes = {
  showAvatar: PropTypes.bool,
  isNew: PropTypes.bool.isRequired,
  isOrganizer: PropTypes.bool,
  fromNowString: PropTypes.string,
  ownerAvatar: PropTypes.string,
  ownerName: PropTypes.string,
  invitation: PropTypes.object,
  nudgeInfo: PropTypes.object,
  isInvited: PropTypes.bool,
  isArchivePromptShow: PropTypes.bool,
  isDeclined: PropTypes.bool,
  isDroppedOut: PropTypes.bool,
  isGreyOverlay: PropTypes.bool,
  isUndecidedWhenInGameplan: PropTypes.bool,
  isNudgeResponseReceived: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const { suggestion } = props
  const suggestionId = suggestion ? suggestion.id : null
  const reactions =
    suggestion?.reactions ||
    selectTeeupReactionsBySuggestionId(suggestionId)(state)
  return {
    userInfo: selectTeeupsPeople(state),
    reactions: reactions,
    teeupSuggestions: selectTeeupSuggestions(state),
    userId: selectUserId(state),
    mentionsPrompts: selectMentionsPrompts(state),
  }
}
const mapDispatchToProps = dispatch => ({})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeeupItemHeaderTitle)
