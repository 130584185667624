import React from "react"

import { formatNumber } from "libphonenumber-js"
import PropTypes from "prop-types"
import { Clipboard } from "react-native"
import PhoneInput from "react-phone-input-2"
import { ReactSVG } from "react-svg"

import { escapeRegExp } from "@utils/dataUtils"
import { images } from "@utils/imageUtils"
import "react-phone-input-2/lib/style.css"
import "./phoneInput.scss"

class PhoneInputField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: props.value,
      showCountryCodeModal: false,
    }
  }

  componentDidMount() {
    if (this.phoneRef && this.props.isLastRow) {
      this.props.getInitPhoneNumber(this.props.index)(this.phoneRef.getValue())
      this.phoneRef.focus()
    }
  }

  handleChangePhoneNumber = async (number, cca2) => {
    const { handleTextChange, index } = this.props
    const clipboardContent = await Clipboard.getString()

    const isTextFromClipboardOnLastPos =
      clipboardContent &&
      number.slice(-clipboardContent.length) === clipboardContent

    if (
      isTextFromClipboardOnLastPos &&
      new RegExp(escapeRegExp(clipboardContent), "gi").test(number)
    ) {
      number = Number.parseInt(clipboardContent)
        ? clipboardContent
        : number.replace(clipboardContent, "")
    }
    handleTextChange(index)(formatNumber(number, "International"), cca2)
    this.setState({ phoneNumber: number })
  }

  showCountryCodeModal = value => {
    this.setState({ showCountryCodeModal: value })
  }

  render() {
    const {
      value,
      disabled,
      defaultCountry,
      fromInvitation,
      onChangeInputCountry,
      isCountryDropdown,
      countryCode,
      label,
      onlyNumber,
    } = this.props
    // defaull country is passed in upper case from RNLocalize
    const country = defaultCountry ? defaultCountry.toLowerCase() : "us"
    let containerStyles = this.state.showCountryCodeModal
      ? { marginLeft: "170px" }
      : { marginLeft: 0 }
    let inputClassStyles = ""

    const isCountryDropdownShown = onChangeInputCountry && isCountryDropdown

    if (isCountryDropdownShown) {
      containerStyles = {
        ...containerStyles,
        height: 30,
        width: "100%",
        display: "inline-block",
      }
      inputClassStyles = "settings_countryCode__codeInput"
    } else if ((!onChangeInputCountry || !isCountryDropdown) && onlyNumber) {
      inputClassStyles = "settings_countryCode__codeInput_onlyNumber"
    }

    this.props.countryCodeEl &&
      this.props.countryCodeEl.addEventListener("click", () => {
        this.showCountryCodeModal(true)
      })
    return (
      <>
        {this.state.showCountryCodeModal && (
          <>
            <div className="settings_countryCode_wrapper">
              <span className="settings_countryCode_heading">Country Code</span>
              <img
                src={images.crossSettings}
                style={{ cursor: "pointer" }}
                alt="setting icon"
                onClick={() => this.showCountryCodeModal(false)}
              />
            </div>
            <div
              className="settings_countryCode_background"
              onClick={e => {
                e.stopPropagation()
                this.showCountryCodeModal(false)
              }}></div>
          </>
        )}

        <span className={"settings_countryCode_phone_wrapper__label"}>
          {label}
        </span>
        <div
          data-testid={label}
          style={
            (!onChangeInputCountry || !isCountryDropdown) && fromInvitation
              ? { zIndex: 2 }
              : {}
          }
          className={`settings_countryCode_phone_wrapper ${
            fromInvitation &&
            "settings_countryCode_phone_wrapper__fromInvitation"
          } ${
            this.state.showCountryCodeModal &&
            "settings_countryCode_phone_wrapper_displayNone"
          }`}>
          <PhoneInput
            country={countryCode ? countryCode.countryCode : country}
            value={onChangeInputCountry ? countryCode.name : value}
            onChange={(phone, country) => {
              onChangeInputCountry && onChangeInputCountry(country)
              this.showCountryCodeModal(false)
              !fromInvitation &&
                this.handleChangePhoneNumber("+" + phone, country.countryCode)
              fromInvitation &&
                this.handleChangePhoneNumber("+" + phone, countryCode.country)
            }}
            disabled={disabled}
            inputClass={inputClassStyles}
            inputStyle={{ paddingLeft: "15px" }}
            buttonClass={`${onlyNumber && "settings_countryCode__codeInput"}`}
            // inputStyle={customInputStyle ? customInputStyle : inputStyle}
            containerStyle={containerStyles}
            dropdownStyle={
              fromInvitation
                ? {}
                : {
                    position: "fixed",
                    top: "50%",
                    right: "50%",
                    transform: "translate(50% ,-50%)",
                    width: "344px",
                    height: "524px",
                    maxHeight: "525px",
                  }
            }
          />
          {isCountryDropdownShown && (
            <span className="settings_countryCode_phone_wrapper__valueState">
              +{countryCode.dialCode} {countryCode.name}
            </span>
          )}
          {isCountryDropdownShown && (
            <ReactSVG
              src={images.arrowsOptions}
              className="settings_countryCode__codeInput__arrow"
            />
          )}
        </div>
      </>
    )
  }
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  // handleTextChange: PropTypes.func.isRequired,
  isLastRow: PropTypes.bool,
  defaultCountry: PropTypes.string,
  getInitPhoneNumber: PropTypes.func,
  countryCode: PropTypes.string,
}

export default PhoneInputField
