import React from "react"

import "react-loading-skeleton/dist/skeleton.css"
import PropTypes from "prop-types"

import { TeeupPreviewState } from "contexts/TeeupPreviewContext"

import { TeeupPreview } from "components"

export const TeeupPreviewPage = ({ isInbox = false, isSharedLink }) => (
  <TeeupPreviewState isInbox={isInbox} isSharedLink={isSharedLink}>
    <TeeupPreview />
  </TeeupPreviewState>
)

TeeupPreviewPage.propTypes = {
  isInbox: PropTypes.bool,
  isSharedLink: PropTypes.bool,
}
