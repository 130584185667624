import { AI_USERS_IDS } from "constants/userIds"

const env = process.env.REACT_APP_ENVIRONMENT

export const showAiFeature = (userId: number) => {
  if (env) {
    return AI_USERS_IDS[env || ""]?.includes(userId)
  }

  return false
}
