import styled from "styled-components"

import { Caption } from "@assets/styles/typographyStyles"

export const Description = styled(Caption)`
  color: var(--gray6);
  margin-top: 10px;
  text-align: center;
`

export const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
`
