import {
  SET_INTERACTION_AREA,
  OPEN_PEOPLE_AREA,
  SET_IS_DROPDOWN_OPEN,
} from "./actionTypes"

export const TeeUpPageReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_INTERACTION_AREA:
      return {
        ...state,
        interactionArea: payload,
      }
    case OPEN_PEOPLE_AREA:
      return {
        ...state,
        openPeopleArea: payload,
      }
    // case SET_IS_SUGGEST_WHERE_OPEN:
    //   return {
    //     ...state,
    //     isSuggestWhereModalOpen: payload,
    //   }
    // case SET_IS_SUGGEST_WHEN_OPEN:
    //   return {
    //     ...state,
    //     isSuggestWhenModalOpen: payload,
    //   }
    case SET_IS_DROPDOWN_OPEN:
      return {
        ...state,
        isThreeDotsDropDownOpen: payload,
      }
    default: {
      return state
    }
  }
}
