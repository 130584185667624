import styled, { css } from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 16px;

  &:not(:first-child) {
    border-top: 1px solid var(--lightGray5);
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default;
      opacity: 0.3;
    `};
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const Icon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
`

export const Text = styled(NSubtitle)`
  color: var(--gray6);
  font-weight: ${NFontWeight.semiBold};
`

export const ArrowIcon = styled(SvgIcon)``
