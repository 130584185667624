import React from "react"

import { ReactSVG } from "react-svg"

import { SvgIcon } from "@components/common"

import { Wrapper, Icon, Title, Description } from "./descriptionInfoStyles"

export const DescriptionInfo = () => (
  <Wrapper>
    <Icon name="clock-icon" />

    <Title>Suggest When</Title>

    <Description>
      No one has made a suggestion yet. <br />
      Kick off the planning, by creating the first suggestion!
    </Description>
  </Wrapper>
)
