import React, { useState, useRef, useEffect } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"
import { IconContext } from "react-icons"
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa"
import { MdCheck } from "react-icons/md"
import { Oval } from "react-loader-spinner"
import { useSelector } from "react-redux"

import {
  requestVerifyContactMechanism,
  addUserContactMechanism,
  deleteContactMechanism,
  verifyContactMechanism,
  deleteLoginMechanism,
  deleteRecoveryMechanism,
  publishContactMechanism,
  unpublishContactMechanism,
} from "@actions/contactMechanismsActions"
import {
  NewPasswordChange,
  verifyPassword,
  getUserContactMechanisms,
  fetchUserInfo,
} from "@actions/userActions"
import { NewModal } from "@components"
import {
  CHANGE_PASSWORD_REQUIREMENTS,
  PASSWORD_RULES,
} from "@constants/password"
import { selectContactMechanisms } from "@selectors/user"
import { contactTypes, userContactTypes } from "@utils/contactUtils"

import api from "../../api"
import {
  SubtitleL,
  Caption,
  CaptionS,
} from "../../assets/styles/typographyStyles"
import { usePasswordValidation } from "../../hooks"
import {
  ModalDescription,
  PasswordRequirementsContainer,
  PasswordRequirementWrapper,
  PasswordRequirement,
} from "../ChangePasswordModal/molecules"
import checkValid from "../profile/EditUserProfile/utils"

import "./index.scss"

const getRuleIcon = failedRule =>
  failedRule ? (
    <IconContext.Provider value={{ color: "#A9B0B9" }}>
      <FaRegCheckCircle />
    </IconContext.Provider>
  ) : (
    <IconContext.Provider value={{ color: "#00C5AE" }}>
      <FaCheckCircle />
    </IconContext.Provider>
  )

const ChooseContactMethod = ({
  isPublic,
  isAddEmailAccount,
  type,
  onClose,
  modalText,
  verifyText,
  typeMethod,
  isChange,
}) => {
  const mechanisms = useSelector(selectContactMechanisms)
  const isEmail = type === contactTypes.EMAIL
  const timerSec = 60
  const storage = JSON.parse(localStorage.getItem("userInfo"))
  const token = storage.accessToken

  const inputType = type === contactTypes.EMAIL ? "email" : "text"

  const { password, setPassword, failedRequirements } = usePasswordValidation()

  const [verifyId, setVerifyId] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [resendTimer, setResendTimer] = useState(0)
  const [isCodeVerified, setIsCodeVerified] = useState(false)
  const [isCodeVerifying, setIsCodeVerifying] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [saveMechanismId, setSaveMechanismId] = useState(null)

  const selectedVerificationMethodValue = useRef()
  const prevMech = mechanisms[type]
    .filter(item => item.typeId === typeMethod)
    .find(item => item.id !== saveMechanismId)

  const handleResendCode = () => {
    sendVerife(saveMechanismId)
    setErrorMessage(null)
    setResendTimer(timerSec)
  }

  const setStepVerify = mechanismId => {
    setResendTimer(timerSec)
    sendVerife(mechanismId)
    setSaveMechanismId(mechanismId)
  }

  const updateDataForMech = async () => {
    if (isAddEmailAccount && !isChange) {
      passChange()
    }

    await getUserContactMechanisms()
    await fetchUserInfo()
  }

  const handleLastStep = async mechanismId => {
    if (isChange) {
      handleDeleteMech(true, mechanismId || saveMechanismId)
    }

    if (isPublic) {
      await publishContactMechanism(mechanismId || saveMechanismId)
    }

    updateDataForMech()
    onClose()
  }

  const passVerify = mechanismId => {
    verifyPassword(password)
      .finally(() => {
        mechanismId && setSaveMechanismId(mechanismId)
        if (!saveMechanismId) {
          selectedVerificationMethodValue.current = inputValue
        }
      })
      .then(() => {
        setErrorMessage({ payload: PASSWORD_RULES.PREVIOUS })
      })
      .catch(({ data }) => {
        if (data.message === "The password is wrong") {
          setStepVerify(saveMechanismId || mechanismId)
        }
      })
  }

  const passChange = () => {
    NewPasswordChange(password, token)
      .then(({ data }) => {
        api.createSession(data)
      })
      .catch(({ data }) => {
        setErrorMessage({ payload: data.message })
      })
  }

  const handleDeleteMech = async (isPrevMech, mechanismId) => {
    const id = isPrevMech && prevMech ? prevMech.id : mechanismId

    if (!mechanismId) return

    if (isAddEmailAccount) {
      return await deleteLoginMechanism(id)()
    }

    if (isPublic) {
      return await unpublishContactMechanism(id)
    }

    await (typeMethod === userContactTypes.RECOVERY_EMAIL ||
    typeMethod === userContactTypes.RECOVERY_PHONE
      ? deleteRecoveryMechanism(id)()
      : deleteContactMechanism(id))

    updateDataForMech()
  }

  const setInviteMechanism = async () => {
    if (!saveMechanismId) {
      addUserContactMechanism(
        {
          typeId: typeMethod,
          value: inputValue,
          isPublic: true,
        },
        token
      )
        .then(({ id: mechanismId, isVerified }) => {
          if (isVerified) {
            return handleLastStep(mechanismId)
          }

          isAddEmailAccount && !isChange
            ? passVerify(mechanismId)
            : setStepVerify(mechanismId)
        })
        .catch(error => {
          if (error.status === 422) {
            return setErrorMessage(`Invalid ${type}`)
          }

          setErrorMessage(error.data.message)
        })
    } else if (isAddEmailAccount && !isChange) passVerify()
  }

  const sendVerife = mechanismId => {
    requestVerifyContactMechanism(mechanismId, token)
      .then(res => {
        if (!saveMechanismId) {
          selectedVerificationMethodValue.current = inputValue
        }

        setVerifyId(res.contactMechanismVerificationId)
        setIsCodeVerifying(true)
        setInputValue("")
      })
      .catch(error => {
        if (error.data.status === 403) {
          setErrorMessage(`This ${type} is already in use, try another one`)
        }

        setErrorMessage(error.data.message)
      })
  }

  const handlContinue = () => {
    if (
      !isCodeVerified &&
      !(
        checkValid.isValidEmail(inputValue) ||
        checkValid.isValidPhoneNumber(inputValue)
      )
    ) {
      setErrorMessage("invalid value")

      return
    }

    if (!isCodeVerified) {
      setErrorMessage(null)
      setInviteMechanism()
      return
    }

    if (errorMessage) {
      return
    }

    handleLastStep()
  }

  const handleInput = event => {
    if (isCodeVerified) {
      return
    }

    const value = event.target.value

    if (value.length === 6 && isCodeVerifying) {
      verifyContactMechanism(verifyId, value, token)
        .then(() => {
          setIsCodeVerified(true)
          setIsCodeVerifying(false)
          setErrorMessage(null)
        })
        .catch(error => {
          setErrorMessage(error.data.message)
        })
    }

    setInputValue(value)
  }

  const handleClose = () => {
    handleDeleteMech(isChange)
    onClose()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setResendTimer(prevSeconds => prevSeconds - 1)
    }, 1000)

    if (resendTimer === 0) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [resendTimer])

  const title = isCodeVerifying
    ? verifyText.title || `Verify ${type} ${isEmail ? "address" : "number"}`
    : modalText.title
  const SubtitleLInfoText = isCodeVerifying
    ? verifyText.SubtitleLInfoText
    : modalText.SubtitleLInfoText
  const inputLable =
    isCodeVerifying || isCodeVerified
      ? verifyText.inputLable
      : modalText.inputLable
  const placeholder = isCodeVerifying
    ? verifyText.placeholder
    : modalText.placeholder
  const seccessVerifyText = verifyText.seccessVerifyText

  return (
    <NewModal
      isDisabledConfirm={
        inputValue === "" ||
        (isAddEmailAccount && !isChange && failedRequirements.length !== 1)
      }
      confirmText={isCodeVerified ? `Save ${type}` : "Send"}
      cancelText="Cancel"
      onClose={onClose}
      onConfirm={!isCodeVerifying && handlContinue}
      onCancel={handleClose}
      isOpen={true}
      title={title}>
      <div className="verify-modal__wrapper">
        {(isCodeVerifying || isCodeVerified) && (
          <div className="verification-method-value_wrapper">
            <SubtitleL>{modalText.selectedVerificationMethod}</SubtitleL>
            <div className="verification-method-value__container">
              <SubtitleL>{selectedVerificationMethodValue.current}</SubtitleL>
            </div>
          </div>
        )}
        {!isCodeVerified && (
          <SubtitleL className="titel-info">{SubtitleLInfoText}</SubtitleL>
        )}
        <div
          className={classNames("action_wrapper", {
            action_verify_wrapper: isCodeVerifying || isCodeVerified,
          })}>
          <SubtitleL>{inputLable}</SubtitleL>
          <div className="input_wrapper">
            {isCodeVerifying
              ? inputValue.length === 6 && (
                  <Oval width="13px" height="13px" color="#A9B0B9" />
                )
              : isCodeVerified && <MdCheck color="#00C5AE" />}
            <input
              onChange={event => handleInput(event)}
              value={inputValue}
              type={inputType}
              placeholder={placeholder}
            />
          </div>
          <div className="resend__wrapper">
            {errorMessage && !errorMessage.payload && (
              <CaptionS className="error message_verify">
                {errorMessage}
              </CaptionS>
            )}
            {isCodeVerifying
              ? isCodeVerifying && (
                  <Caption
                    onClick={
                      (resendTimer === 0 || errorMessage) && handleResendCode
                    }
                    className="resend">
                    resend code{" "}
                    {!(resendTimer === 0 || errorMessage) && `(${resendTimer})`}
                  </Caption>
                )
              : isCodeVerified && (
                  <SubtitleL style={{ width: "100%" }} className="titel-info">
                    {seccessVerifyText}
                  </SubtitleL>
                )}
          </div>
          {isAddEmailAccount &&
            !isChange &&
            !isCodeVerifying &&
            !isCodeVerified && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "24px",
                }}>
                <SubtitleL style={{ marginBottom: "4px" }}>
                  Create password
                </SubtitleL>
                <div className="input_wrapper">
                  <input
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    placeholder="password"
                  />
                </div>
                {errorMessage && errorMessage.payload && (
                  <CaptionS style={{ marginTop: "9px" }} className="error">
                    {errorMessage?.payload}
                  </CaptionS>
                )}
                <div style={{ marginTop: "24px" }}>
                  <ModalDescription>Your new password must:</ModalDescription>
                  <PasswordRequirementsContainer>
                    {CHANGE_PASSWORD_REQUIREMENTS.map(req => {
                      const failedRule =
                        password === "" ||
                        failedRequirements.includes(req.description)

                      return (
                        <PasswordRequirementWrapper>
                          {getRuleIcon(failedRule)}
                          <PasswordRequirement>{req.text}</PasswordRequirement>
                        </PasswordRequirementWrapper>
                      )
                    })}
                  </PasswordRequirementsContainer>
                </div>
              </div>
            )}
        </div>
      </div>
    </NewModal>
  )
}

ChooseContactMethod.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  modalText: PropTypes.array,
  verifyText: PropTypes.array,
  setPublicMech: PropTypes.func,
}

export default ChooseContactMethod
