import React from "react"

import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import useSuggest from "../../Hooks/useSuggest"

import "./index.scss"

const FooterButtonsWrapper = props => {
  const { suggestInfo, initComment } = props
  const { onSubmit } = useSuggest({
    suggestInfo,
    suggestType: "online",
    initComment,
  })
  const { closeSuggestWhereModal } = useTeeUpPage()

  return (
    <>
      <div
        data-testid="decideOnThisButton"
        className="suggest-online__button"
        onClick={async () => {
          if (await onSubmit(true)) {
            props.onSuccess()
            closeSuggestWhereModal()
          } else {
            props.onError()
          }
        }}>
        Decide on this
      </div>
      <div
        data-testid="suggestButton"
        className="suggest-online__suggest"
        onClick={async () => {
          if (await onSubmit(false)) {
            props.onSuccess()
            closeSuggestWhereModal()
          } else {
            props.onError()
          }
        }}>
        Suggest
      </div>
    </>
  )
}

export default FooterButtonsWrapper
