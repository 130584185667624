import { useMemo } from "react"

import { getIsEmail } from "@utils/getIsEmail"

export const useContactWarning = ({
  isAuthenticated,
  contactValue,
  contactMechanisms,
}) => {
  const getMessageObject = (type, variant) => ({
    type,
    message: `You must add this ${variant} to your account in order to join this Teeup`,
  })

  return useMemo(() => {
    if (isAuthenticated && contactValue) {
      const contactMechanismsValues = contactMechanisms.map(
        mechanism => mechanism.value
      )

      if (!contactMechanismsValues.includes(contactValue)) {
        return getIsEmail(contactValue)
          ? getMessageObject("email", "email")
          : getMessageObject("phone", "mobile number")
      }
    }

    return null
  }, [isAuthenticated, contactValue, contactMechanisms])
}
