import { teeupUserStatusKeys, teeupUserStatusMessage } from "@config/enums"

export const useTexts = confirmingStatus => {
  const isResponse =
    confirmingStatus === teeupUserStatusKeys.interested ||
    confirmingStatus === teeupUserStatusKeys.notInterested
  const modalTitle = isResponse ? "" : "RSVP Status Shared"
  const statusMessage = teeupUserStatusMessage[confirmingStatus]
  const modalDescriptionAction = isResponse
    ? `Status shared as "${statusMessage}"`
    : `You set your status as <b>"${statusMessage}"</b>.`
  const modalDescriptionUpdates =
    confirmingStatus === teeupUserStatusKeys.notInterested
      ? "You will no longer receive updates about this TeeUp."
      : "You will receive updates about this TeeUp via email."

  return {
    modalTitle,
    modalDescriptionAction,
    modalDescriptionUpdates,
  }
}
