import React, { useEffect, useMemo, useRef, useState } from "react"

import { debounce, differenceBy, find } from "lodash"
import { connect } from "react-redux"

import {
  selectAllContactsWithoutInvitedToTeeup,
  selectAllContactsWithoutInvitedToGroup,
  selectAllContacts,
  selectBookContacts,
  selectContactsByTags,
  selectTags,
} from "@selectors/contacts"
import { selectConnectionRequests } from "@selectors/requests"
import { selectTeeupsPeople } from "@selectors/teeups"
import {
  selectUserId,
  selectContactsSyncedAt,
  selectContactsSyncSettings,
  selectUserVerifiedCountryCode,
} from "@selectors/user"
import {
  contactTypes,
  getInviteesWithoutDuplicates,
  prepareManualContact,
  formatContact,
  userTypes,
} from "@utils/contactUtils"
import {
  sections,
  findContactsByText,
  fetchSyncContactsData,
} from "@utils/contacts"

import { batchVerifyAndGetUserInfo } from "../../actions/batchVerifyAndGetUserInfo"
import tagIcon from "../../assets/images/tag.svg"
import MyContactItemNd from "../Contacts/MyContactItem/MyContactItemNd"
import Toast from "../ui/toast"

import AddManualContactsContainer from "./AddContacts"
import AddInviteesViewNd from "./AddInviteesViewNd"

import "./index.scss"

const showToastAboutDuplicates = () => {
  Toast.show("This user is already invited. Duplicate was removed", {
    bottomOffset: 8,
  })
}

const handleSearchLogic = (
  props,
  state,
  newSearchText,
  newSelectedTag,
  sortByName
) => {
  // Searching logic:
  // 1) if any tags selected, filter by tags
  // 2) if any search text, search by text
  const {
    selectedTag,
    setSelectedTag,
    searchText,
    setSearchText,
    expandedContactItemId,
    setExpandedContactItemId,
    setFoundContacts,
  } = state

  const { contactsByTags, contacts, tags, combinedPeople } = props

  setExpandedContactItemId(expandedContactItemId)

  let usedSearchText = searchText
  let usedSelectedTag = selectedTag

  if (newSearchText !== null) {
    // Searched via text
    setSearchText(newSearchText)
    usedSearchText = newSearchText
  }
  if (!newSearchText && searchText) {
    setExpandedContactItemId({})
  }

  if (newSelectedTag) {
    // Searched via tag. Even if toggling tag off, newSelectedTag will have a value

    if (selectedTag && newSelectedTag.id === selectedTag.id) {
      // Unselected
      usedSelectedTag = null
    } else {
      // New tag selected
      usedSelectedTag = newSelectedTag
    }

    setSelectedTag(usedSelectedTag)
  }

  if (selectedTag && !find(tags, tag => tag.id === selectedTag.id)) {
    usedSelectedTag = null
    setSelectedTag(usedSelectedTag)
  }

  let foundContacts = combinedPeople ? combinedPeople : contacts

  if (sortByName) {
    setSelectedTag(null)
  }

  if (!sortByName && usedSelectedTag) {
    foundContacts = contactsByTags[usedSelectedTag.id]
    // foundContacts = usedSelectedTag.id == 'previousTeeups' ? contactsByTags[usedSelectedTag.id][0].users : contactsByTags[usedSelectedTag.id]
  }

  if (usedSearchText) {
    const expression = new RegExp(usedSearchText, "gi")
    foundContacts = findContactsByText(foundContacts, usedSearchText)
    foundContacts.forEach(contact => {
      if (contact?.phoneNumbers?.length) {
        contact.phoneNumbers.forEach(phone => {
          if (phone?.number.match(expression)?.length) {
            setExpandedContactItemId({
              ...expandedContactItemId,
              [contact.id]: true,
            })
          }
        })
      }
      if (contact?.emails?.length) {
        contact.emails.forEach(email => {
          if (email?.email.match(expression)?.length) {
            setExpandedContactItemId({
              ...expandedContactItemId,
              [contact.id]: true,
            })
          }
        })
      }
    })
  }

  setFoundContacts(foundContacts)
}

const AddInviteesContainerNd = ({
  activeTabIndex,
  componentId,
  userId,
  onDone,
  contacts,
  userPhoneNumbers,
  userEmails,
  viewHeader,
  isInitInvitationScreen,
  alreadyInvitedIds,
  myContacts,
  requests,
  onHeaderLeft,
  lightTheme,
  tags,
  skipContactsLoading,
  verifiedCountryCode,
  contactSyncedAt,
  contactSyncSettings,
  // combinedPeople,
  onClose,
  setContent,
  close,
  fromCreateTeeup,
  isTeeupInvite,
  handleOnInviteesDone,
  fromTeeupTooltip,
  showButtons,
  fromContact,
  noHover,
  inviteToNewTeeup,
  closeOptionWithAnimations,
  contactsByTags,
  invitees,
  setInvitees,
  allContacts,
  teeupPeople,
  contactsConnectors,
  fromContactsBook,
  combinedPeople,
  openInviteToTeeupModal,
  tagUser,
  openTagPopUp,
  activePopUpOpen,
}) => {
  const [showInvitees, setShowInvitees] = useState(true)
  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [foundContacts, setFoundContacts] = useState(
    combinedPeople || contacts || []
  )
  const [loadingContacts, setLoadingContacts] = useState(
    skipContactsLoading ? false : true
  )
  const [sectionOpenItemCount, setSectionOpenItemCount] = useState({
    [sections.FREQUENT]: 2,
    [sections.PREVIOUS]: 2,
    [sections.RECOMMENDED]: 2,
  })
  const [expandedContactItemId, setExpandedContactItemId] = useState({})
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [isMoreMenuOpened, setIsMoreMenuOpened] = useState(false)
  const [manualInvitees, setManualInvitees] = useState(
    invitees.filter(invite => invite?.userType === userTypes.MANUAL)
  )
  const [modalContent, setModalContent] = useState(null)
  const [currentTag, setCurrentTag] = useState({})
  const [loading, setLoading] = useState(false)

  const [contactsFetched, setContactsFetched] = useState(false) // Only once per opening this screen
  let manualContactId = 0
  const searchRef = useRef()

  useEffect(() => {
    setFoundContacts(combinedPeople)
  }, [combinedPeople])

  useEffect(() => {
    if (!contactsFetched) {
      // Only after screen appeared, to speed up appearance
      setContactsFetched(true)
      handleRefetchContacts()
    }
    setLoadingContacts(false)
  }, [])

  useEffect(() => {
    const currentManualInvitees = invitees.filter(
      invite => invite?.userType === userTypes.MANUAL
    )
    if (manualInvitees.length !== currentManualInvitees.length) {
      setManualInvitees(currentManualInvitees)
    }

    !combinedPeople && setFoundContacts(contacts)
  }, [contacts])

  useEffect(() => {
    if (activeTabIndex === 0) {
      if (!contacts?.length) {
        setFoundContacts(combinedPeople)
      } else if (combinedPeople?.length) {
        setFoundContacts([...contacts, ...combinedPeople])
      } else {
        setFoundContacts(contacts)
      }
    }
    if (myContacts) {
      setFoundContacts(combinedPeople)
    } else {
      setFoundContacts(contacts)
    }
  }, [myContacts, activeTabIndex])

  useEffect(() => {
    let newFoundContacts = combinedPeople ? combinedPeople : contacts

    if (selectedTags.length > 0) {
      selectedTags.forEach(
        tagId =>
          (newFoundContacts = newFoundContacts.filter(
            item => item.tags && item.tags.some(tag => tag.id === tagId)
          ))
      )

      setFoundContacts(newFoundContacts)
    } else {
      setFoundContacts(combinedPeople ? combinedPeople : contacts)
    }
  }, [selectedTags])

  const contactsByCategory = useMemo(() => {
    if (activeTabIndex === 0) {
      return foundContacts
    }
    return foundContacts?.filter(item =>
      myContacts
        ? Number.isInteger(item.cooeId)
        : !Number.isInteger(item.cooeId)
    )
  }, [foundContacts, activeTabIndex, myContacts])

  const handleSearchByText = useMemo(() => {
    if (foundContacts) {
      return contactsByCategory.filter(
        item =>
          (item.name &&
            item.name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.username &&
            item.username.toLowerCase().includes(searchText.toLowerCase()))
      )
    }
  }, [searchText, contactsByCategory, activeTabIndex, foundContacts])

  const handleSearchByCustomTags = newSelectedTagId => {
    if (selectedTags.length > 0 && selectedTags.includes(newSelectedTagId)) {
      setSelectedTags(selectedTags.filter(tagId => tagId !== newSelectedTagId))
    } else {
      newSelectedTagId &&
        setSelectedTags(selectedTags => [...selectedTags, newSelectedTagId])
    }
  }

  const handleRefetchContacts = async () => {
    let allowSynchronization = contactSyncSettings.allowSynchronization

    if (!contactSyncedAt && !allowSynchronization) {
    }
  }

  const handleCancel = () => null

  const handleAdd = () => {
    onDone(invitees)
    onClose()
  }

  const handleSearchTextChange = searchText => {
    _searchUsers(searchText)
  }

  const searchUsers = searchText => {
    handleSearch(searchText)
  }

  const _searchUsers = debounce(searchUsers, 250)

  const handleResetSearchText = () => {
    handleSearch("")
  }

  const handleToggleContact = ({ data }) => {
    const diffInvitees = differenceBy(invitees, [data], "id")
    const isInvited = diffInvitees.length !== invitees.length
    setShowInvitees(true)
    setInvitees(isInvited ? diffInvitees : [...invitees, { ...data }])
  }

  const handleViewMore = section => () => {
    const showMoreCount = 8
    const count = sectionOpenItemCount[section]
    const nextCount = count + showMoreCount
    setSectionOpenItemCount({
      ...sectionOpenItemCount,
      [section]: nextCount,
    })
  }

  const openPreviousTeeupParticipants = teeup => () => {
    const componentName = "previousTeeupParticipants"
    const componentId = `${componentName}.${teeup.title}`
    const closeComponent = () => "Navigation.pop(componentId)"
  }

  const onContactsScrolled = scrolledUp => {
    if (showInvitees !== scrolledUp) {
      setShowInvitees(scrolledUp)
    }
  }

  const handleAddManualContacts = async ({ contactType, data }) => {
    const userPhoneNumber = new Set(
      userPhoneNumbers.map(number => number.value.replaceAll(/\s+/g, ""))
    )
    const userEmail = new Set(
      userEmails.map(email => email.value.toLowerCase())
    )
    const manualInviteesValues = manualInvitees.map(invite => invite?.value)
    const updatedData = [...manualInviteesValues, ...data]
    const newUniqueData = [...new Set(updatedData)]

    if (updatedData.length !== newUniqueData.length) {
      showToastAboutDuplicates()
    }
    const contactMechanismValues = newUniqueData
      .map(value => value.replaceAll(/\s+/g, "").toLowerCase())
      .filter(value => {
        if (
          (contactType === contactTypes.PHONE && userPhoneNumber.has(value)) ||
          (contactType === contactTypes.EMAIL && userEmail.has(value))
        ) {
          Toast.show(
            `You can't add yourself in TeeUp. Contact was removed from the list`,
            {
              bottomOffset: 8,
            }
          )
          return false
        }
        if (value.trim().length === 0) {
          return false
        }
        return true
      })
    let formattedInvitees = invitees
    try {
      let { users, contacts, rest } = await batchVerifyAndGetUserInfo(
        contactMechanismValues
      )
      if (manualInvitees.length > 0) {
        manualContactId = Math.min(...manualInvitees.map(invite => invite.id))
      }

      const formattedManualContacts = rest.map(value => {
        // use negative id to avoid duplication to user id
        const alreadyAddedInvite = manualInvitees.find(
          invite => invite.value === value
        )
        if (!alreadyAddedInvite) {
          manualContactId--
        }
        return alreadyAddedInvite
          ? alreadyAddedInvite
          : prepareManualContact({
              manualContactId: manualContactId,
              contactType,
              value,
            })
      })
      formattedInvitees = formattedInvitees.concat(
        users,
        contacts.map(contact => formatContact(contact)),
        formattedManualContacts
      )
      setManualInvitees([...formattedManualContacts])
    } catch (error) {
      console.warn("Error batchVerifyAndGetUserInfo:", error.message)
    }

    setInvitees(
      getInviteesWithoutDuplicates({
        formattedInvitees,
        alreadyInvitedIds,
        showToastAboutDuplicates,
      })
    )
  }

  const openAddContacts = contactType => {
    const content = (
      <>
        <AddManualContactsContainer
          onDone={contacts => {
            handleAddManualContacts(contacts)
          }}
          onClose={() => setModalContent(null)}
          contactType={contactType}
        />
      </>
    )
    setModalContent(content)
  }

  const openMenu = () => {
    if (!isMenuOpened) {
      setIsMenuOpened(true)
    }
  }

  const openMoreMenu = callback => {
    setIsMoreMenuOpened(true)
    callback() //????
  }

  const closeMoreMenu = () => {
    setIsMoreMenuOpened(false)
  }

  const handleToggleExpand = id => () => {
    setExpandedContactItemId({
      ...expandedContactItemId,
      [id]: !expandedContactItemId[id],
    })
  }

  const handleAndroidBackPress = () => {
    onHeaderLeft ? onHeaderLeft() : handleCancel()
    return true
  }

  const handleChangeContactType = ({
    id,
    selectedContact,
    selectedIndex,
    data,
  }) => {
    // Change default contact type, i.e. if default is phone number, but you want to invite via email
    let inviteesCopy = [...invitees]
    const { contactType, label, value } = selectedContact

    let updatedContact = {
      ...data,
      contactType,
      contactLabel: label ? label : contactType,
      value,
      selectedIndex,
    }
    // contactActions.changeContactDefaultType(updatedContact)

    let alreadyInvited = false
    for (let i = 0, len = inviteesCopy.length; i < len; i++) {
      let invitee = inviteesCopy[i]
      if (invitee.id === id) {
        inviteesCopy[i] = updatedContact
        alreadyInvited = true
        break
      }
    }

    if (!alreadyInvited) {
      inviteesCopy.push(updatedContact)
    }

    setInvitees(inviteesCopy)
  }

  const handleSearchEnabled = () => setIsSearchEnabled(true)

  const handleSearchDisable = () => !searchText && setIsSearchEnabled(false)

  const handleTagRender = tag => {
    const { value, id, onPress } = tag
    const isTagSelected = selectedTag ? id === selectedTag.id : false
    const onPressEvent = onPress ? onPress : handleTagTouch

    const classes = isTagSelected ? "svg-wrapper tag-selected" : "svg-wrapper"
    return (
      <div key={`${value} + ${id}`} onPress={onPressEvent(tag)}>
        <div className="addinvitees__tag--wrapper">
          <img src={tagIcon} className={classes} alt="tags" />
          <h2 className="addinvitees__tag--text">{value}</h2>
        </div>
      </div>
    )
  }

  const handleTagTouch = selectedTag => () => {
    handleSearch(null, selectedTag)
  }

  const handleSearch = (newSearchText, newSelectedTag, sortByName) => {
    const state = {
      setSelectedTag,
      searchText,
      setSearchText,
      expandedContactItemId,
      setExpandedContactItemId,
      setFoundContacts,
    }
    const props = { contactsByTags, contacts, tags, combinedPeople }
    handleSearchLogic(props, state, newSearchText, newSelectedTag, sortByName)
  }

  const renderMyContactItem = ({ item, isInvitees }, noHover) => {
    return (
      <MyContactItemNd
        item={item}
        isInvitees={isInvitees}
        onRemoveInvitee={() => handleToggleContact({ data: item })}
        selectedTags={selectedTag ? [selectedTag] : []}
        onRefetchContacts={handleRefetchContacts}
        connectUsers={connectUsers}
        isMoreMenuOpened={isMoreMenuOpened}
        openMoreMenu={openMoreMenu}
        closeMoreMenu={closeMoreMenu}
        setContent={setContent}
        close={close}
        myContacts={myContacts}
        invitees={invitees}
        noHover={fromCreateTeeup || noHover}
        handleOnInviteesDone={handleOnInviteesDone}
        onDone={() => setInvitees([])}
        fromContactsBook={fromContactsBook}
        openInviteToTeeupModal={openInviteToTeeupModal}
        tagUser={tagUser}
        openTagPopUp={openTagPopUp}
        activePopUpOpen={activePopUpOpen}
      />
    )
  }

  const connectUsers = async (userId, message) => {
    if (!loading) {
      setLoading(true)
        .then(error => {
          if (error) {
            Toast.show(error)
          } else {
            handleRefetchContacts()
            setLoading(false)
            Toast.show("Connection request sent!")
          }
        })
        .finally(setLoading(false))
    }
  }

  const rememberSelectedTag = currentTagI => {
    setCurrentTag(currentTagI)
  }

  const isSearching = Boolean(searchText || selectedTag)
  // const additionalStyle = isTeeupInvite ? {top: 5} : fromCreateTeeup ? {top: 0} : myContacts ? {right: 15, top: 0} : {};

  let searchResultsText =
    (foundContacts ? foundContacts.length : 0) + " Results for "
  if (searchText) {
    searchResultsText += '"' + searchText + '"'
  }

  if (selectedTag) {
    searchResultsText +=
      (searchText ? " with " : "") + 'people tagged "' + selectedTag.value + '"'
  }

  useEffect(() => {
    fetchSyncContactsData(contactsConnectors)
  }, [contactsConnectors])

  return (
    <AddInviteesViewNd
      isMenuOpened={isMenuOpened}
      showInvitees={showInvitees}
      viewHeader={viewHeader}
      invitees={invitees}
      searchText={searchText}
      // foundContacts={combinedPeople ? combinedPeople : foundContacts}
      foundContacts={foundContacts}
      sectionOpenItemCount={sectionOpenItemCount}
      expandedContactItemId={expandedContactItemId}
      userTags={tags}
      isSearchEnabled={isSearchEnabled}
      onOpenMenu={openMenu}
      onToggleContact={handleToggleContact}
      onToggleExpand={handleToggleExpand}
      onChangeContactType={handleChangeContactType}
      onAdd={handleAdd}
      onClose={onHeaderLeft ? null : handleCancel}
      onBack={onHeaderLeft}
      doneTitle={
        myContacts ? "Select" : isInitInvitationScreen ? "Next" : "Done"
      }
      onSearchTextChange={handleSearchTextChange}
      onResetSearchText={handleResetSearchText}
      onViewMore={handleViewMore}
      onOpenPreviousTeeupParticipants={openPreviousTeeupParticipants}
      loadingContacts={loadingContacts}
      onContactsScrolled={onContactsScrolled}
      onSearchEnable={handleSearchEnabled}
      onSearchDisable={handleSearchDisable}
      onTagRender={handleTagRender}
      searchRef={searchRef}
      testID={"AddInviteesView"}
      sortingType={selectedTag ? selectedTag.id : null}
      searchResultsText={searchResultsText}
      isSearching={isSearching}
      myContacts={myContacts}
      renderMyContactItem={myContacts ? renderMyContactItem : null}
      lightTheme={lightTheme}
      showButtons={showButtons}
      closeContent={onClose}
      isTeeupInvite={isTeeupInvite}
      fromContact={fromContact}
      fromCreateTeeup={fromCreateTeeup}
      openPhone={() => openAddContacts("phone")}
      openEmail={() => openAddContacts("email")}
      noHover={noHover}
      selectedTag={selectedTag}
      rememberSelectedTag={rememberSelectedTag}
      handleSearch={handleSearch}
      contacts={contacts}
      currentTag={currentTag}
      fromContactsBook={fromContactsBook}
      openInviteToTeeupModal={openInviteToTeeupModal}
      handleOnInviteesDone={handleOnInviteesDone}
      handleSearchByCustomTags={handleSearchByCustomTags}
      selectedTags={selectedTags}
      setSelectedTags={setSelectedTags}
      handleSearchByText={handleSearchByText}
      setSearchText={setSearchText}
      tagUser={tagUser}
      openTagPopUp={openTagPopUp}
      activePopUpOpen={activePopUpOpen}
    />
  )
}

const mapStateToProps = (state, passedProps) => {
  const { isTeeupInvite, isGroupInvite, alreadyInvitedIds } = passedProps
  const userId = selectUserId(state)
  const nonBookContacts =
    alreadyInvitedIds && isTeeupInvite
      ? selectAllContactsWithoutInvitedToTeeup(state)
      : alreadyInvitedIds && isGroupInvite
      ? selectAllContactsWithoutInvitedToGroup(state)
      : selectAllContacts(state)
  const contacts = [...selectBookContacts(state), ...nonBookContacts]
  const contactsByTags = selectContactsByTags(state)
  const tags = selectTags(state)
  const requests = selectConnectionRequests(state)
  const verifiedCountryCode = selectUserVerifiedCountryCode(state)
  const contactSyncedAt = selectContactsSyncedAt(state)
  const contactSyncSettings = selectContactsSyncSettings(state)
  const userPhoneNumbers = []
  const userEmails = []
  const teeupPeople = selectTeeupsPeople(state)
  const allContacts = selectAllContacts(state)

  return {
    userId,
    contacts,
    contactsByTags,
    tags,
    userPhoneNumbers,
    userEmails,
    requests,
    verifiedCountryCode,
    contactSyncedAt,
    contactSyncSettings,
    allContacts,
    teeupPeople,
    contactsConnectors: state.contacts.syncConnectors,
  }
}

export default connect(mapStateToProps, null)(AddInviteesContainerNd)
