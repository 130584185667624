import {
  HALF_OF_TIME_WHEEL,
  HOUR_ITEM_WIDTH,
  MINUTES_DAY_PARTS,
} from "../constants"

export const getCurrentDateIndex = ({ scrollLeft, pickerWidth }) => {
  return Math.floor(
    (scrollLeft + HALF_OF_TIME_WHEEL - pickerWidth / 2) /
      (HOUR_ITEM_WIDTH / MINUTES_DAY_PARTS)
  )
}
