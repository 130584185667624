import actionTypes from "@actions/actionTypes"

const initialState = {
  slackIntegrationInfo: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SLACK_INTEGRATION_INFO:
      return {
        ...state,
        slackIntegrationInfo: payload,
      }
    default:
      return state
  }
}
