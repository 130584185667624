import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { updateGuestReaction } from "@actions/guestActions"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { useReactionsButtons } from "./hooks"
import { Wrapper, Icon } from "./previewSuggestionReactionsStyles"

const PreviewSuggestionReactions = ({
  reactions,
  suggestionId,
  updateGuestReaction,
}) => {
  const reactionsButtons = useReactionsButtons({
    reactions,
    suggestionId,
    updateGuestReaction,
  })

  return (
    <Wrapper>
      {reactionsButtons.map((reaction, reactionIndex) => {
        const color = reaction.isActive ? "var(--black2)" : "var(--gray3)"

        return (
          <ButtonEl
            className="h-[30px] w-[48px] !rounded-[54px] !border !border-solid !border-[var(--lightGray5)] !bg-white !p-0"
            autoWidth
            isReverse
            textColor={color}
            variant={BUTTON_VARIANTS.TRANSPARENT}
            text={reaction.amount}
            onClick={reaction.onClick}
            key={reactionIndex}>
            <Icon name={reaction.iconName} color={color} />
          </ButtonEl>
        )
      })}
    </Wrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  updateGuestReaction: bindActionCreators(updateGuestReaction, dispatch),
})

export default connect(null, mapDispatchToProps)(PreviewSuggestionReactions)

PreviewSuggestionReactions.propTypes = {
  reactions: PropTypes.array,
  suggestionId: PropTypes.number,
}
