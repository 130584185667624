import ParticipantItem from "../../../Content/Participants/ParticipantItem"
import Checkbox from "../../Checkbox"

import "./index.scss"

const ListItem = ({ participant, checked, onClick }) => {
  return (
    <div className="people-ask-people-item" key={participant.id}>
      <ParticipantItem
        participant={participant}
        hideStatus
        className="people-ask-people-participant"
      />
      <div className="people-ask-people-checkbox">
        <Checkbox checked={checked} onClick={() => onClick(participant)} />
      </div>
    </div>
  )
}

export default ListItem
