import _ from "lodash"

import actionTypes from "@actions/actionTypes"
import { membershipRules } from "@utils/groupUtils"

export const initialState = {
  createGroup: {
    private: true,
    membershipRules: membershipRules.invite,
    publicVisibility: {
      general: true,
      membershipRules: true,
      teeUps: true,
      comments: true,
    },
    matureContent: false,
    interests: [],
  },
  activeGroup: {}, //currently opened
  groups: [], //array of groups
  groupsId: {}, //id of group / group
  groupsTeeups: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUPS: {
      const groups = action.payload
      let activeGroup = { ...state.activeGroup }
      let groupsId = {}
      groups.map(group => {
        if (activeGroup.id === group.id)
          activeGroup = { ...activeGroup, ...group }
        groupsId[group.id] = group
      })

      return _.assign({}, state, {
        groups,
        groupsId,
        activeGroup,
      })
    }

    case actionTypes.SET_ACTIVE_GROUP: {
      const { group } = action.payload
      let activeGroup = { ...state.activeGroup }
      if (_.isEmpty(group)) {
        activeGroup = group
      } else if (activeGroup.id === group.id) {
        if (group.membershipRule) {
          activeGroup = { ...activeGroup, ...group }
        } else if (!group.membershipRule && state.groups[group.id]) {
          activeGroup = { ...activeGroup, ...state.groups[group.id] }
        } else activeGroup = { ...activeGroup, ...group }
      } else activeGroup = { ...group }

      return _.assign({}, state, { activeGroup })
    }

    case actionTypes.SET_GROUP_TEEUPS: {
      const { teeups, groupId } = action.payload
      let groupsTeeups = {
        [groupId]: teeups,
      }
      return _.assign({}, state, {
        ...state.groupsTeeups,
        groupsTeeups,
      })
    }

    case actionTypes.EDIT_VALUES:
      return _.assign({}, state, {
        createGroup: {
          ...state.createGroup,
          ...action.payload,
        },
      })

    case actionTypes.RESET_VALUES:
      return _.assign({}, state, {
        createGroup: initialState.createGroup,
      })

    case actionTypes.LOGOUT:
      return _.assign({}, state, initialState)

    default:
      return state
  }
}
