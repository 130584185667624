import { Snackbar } from "@components/ui/Snackbar"
import { useSnackbarStore } from "stores/snackbarStore"

// TODO: add several states like: error, warning, info, success
const GlobalSnackbar = () => {
  const config = useSnackbarStore(state => state.snackbarConfig)

  return <Snackbar {...config} />
}

export default GlobalSnackbar
