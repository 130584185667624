import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"
import {
  NFontWeight,
  NCaption,
  NTitle5,
} from "assets/styles/newTypographyStyles"

export const ContentWrapper = styled.div`
  ${({ $isHeader, $isNewHeader, $isNoPadding }) => css`
    display: flex;
    flex-direction: column;
    ${$isHeader
      ? css`
          padding: 10px;
        `
      : $isNewHeader
      ? css`
          padding: 0;
        `
      : css`
          align-items: center;
          padding: 30px;
        `};
    ${$isNoPadding &&
    css`
      padding: 0;
    `};
  `};
`

export const Title = styled(NTitle5)`
  font-weight: ${NFontWeight.semiBold};
  text-align: center;
  margin-bottom: 10px;
`

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 15px;
  word-break: break-word;
`

export const Description = styled(NCaption)`
  text-align: center;
  color: var(--gray4);

  > * {
    margin-top: 12px;
  }

  :first-child {
    margin-top: 0px;
  }
`

export const Footer = styled.div`
  ${({ isHeader, isHorizontalBtns }) => css`
    display: flex;
    ${isHeader
      ? css`
          justify-content: flex-end;
        `
      : css`
          width: 100%;
          flex-direction: ${isHorizontalBtns ? "row-reverse" : "column"};
        `};
    margin-top: 24px;
    row-gap: 10px;
    grid-column-gap: 10px;
  `};
`

export const StatusIcon = styled.img`
  margin-bottom: 10px;
  width: 36px;
  height: 36px;
`

export const CustomIcon = styled(SvgIcon)`
  width: 60px;
  height: 60px;

  ${({ $isOnly }) => css`
    ${$isOnly && "margin-bottom: 25px;"}
  `}
`

export const CloseBtn = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  outline: none;
`

export const CloseIcon = styled(SvgIcon)`
  width: 100%;
  height: 100%;
`
