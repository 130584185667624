import React from "react"

import PropTypes from "prop-types"

import { Wrapper, LeftSide, Icon, Text, ArrowIcon } from "./optionItemStyles"

export const OptionItem = ({ isFirst, option }) => (
  <Wrapper $isDisabled={option.isDisabled} onClick={option.onClick}>
    <LeftSide>
      {option.iconName && <Icon name={option.iconName} />}

      <Text>{option.text}</Text>
    </LeftSide>

    <ArrowIcon name="arrow-right-2" color="var(--black2)" />
  </Wrapper>
)

OptionItem.propTypes = {
  isFirst: PropTypes.bool,
  option: PropTypes.object,
}
