import React from "react"

import { ThreeDots } from "react-loader-spinner"

import "./ButtonThreeDotsStyles.scss"

export const ButtonThreeDots = ({ color }) => (
  <div className="three-dots-wrapper">
    <ThreeDots color={color || "white"} height={10} width={80} />
  </div>
)
