import React, { useContext } from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { ErrorIcon } from "./atoms"
import {
  PreviewErrorWrapper,
  PreviewError,
  PreviewErrorTitle,
  PreviewErrorMessage,
  PreviewErrorButtons,
} from "./previewErrorStyles"

export const TeeupPreviewError = ({ close }) => {
  const { previewErrorInfo } = useContext(TeeupPreviewContext)

  return (
    <PreviewErrorWrapper>
      <PreviewError>
        <ErrorIcon previewErrorInfo={previewErrorInfo} />

        <PreviewErrorTitle>{previewErrorInfo.title}</PreviewErrorTitle>

        {previewErrorInfo.message && (
          <PreviewErrorMessage>{previewErrorInfo.message}</PreviewErrorMessage>
        )}

        <PreviewErrorButtons>
          <ButtonEl
            fullWidth
            text="Close"
            variant={BUTTON_VARIANTS.ROUNDED}
            onClick={close}
          />
        </PreviewErrorButtons>
      </PreviewError>
    </PreviewErrorWrapper>
  )
}

TeeupPreviewError.propTypes = {
  close: PropTypes.func,
}
