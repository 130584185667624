import React from "react"

import PropTypes from "prop-types"
import { View, FlatList } from "react-native"

import EmptyConversation from "../EmptyConversation"

import { styles } from "./UserNudgesScreenStyles"

const UserNudgesComponent = ({ nudgesWithResponses, onNudgeRender }) => {
  if (nudgesWithResponses.length === 0) {
    return (
      <EmptyConversation
        iconName="noNudge"
        title="Nothing nudged"
        description="Nudge people to ask them to participate"
      />
    )
  }
  return (
    <View style={[styles.flex, styles.nudgesContainer]}>
      <FlatList
        contentContainerStyle={styles.listPadding}
        data={nudgesWithResponses}
        renderItem={onNudgeRender}
      />
    </View>
  )
}
UserNudgesComponent.propTypes = {
  nudgesWithResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onNudgeRender: PropTypes.func.isRequired,
  insideTabContainer: PropTypes.bool,
}

export default UserNudgesComponent
