import React from "react"

import { connect } from "react-redux"

import "./auth0LoadingPage.scss"
import { useTopLevelContext } from "../../contexts/TopLevelContext"
import { AuthLayout } from "../../hoc"

const Auth0LoadingPage = ({ auth0Type }) => {
  const { isLoginInProgress } = useTopLevelContext()

  const getActionType = () => (isLoginInProgress ? "Log in" : "Sign Up")

  const getTitle = () => `${getActionType()} with ${auth0Type}`

  const getMessage = () => {
    return `Please follow the directions in the popup window to ${getActionType()?.toLowerCase()} with ${auth0Type}`
  }

  return (
    <section className="auth0-loading-p--wrapper">
      <AuthLayout title={getTitle()}>
        <p className="auth0-loading-p__message">{getMessage()}</p>
      </AuthLayout>
    </section>
  )
}

const mapStateToProps = ({ user }) => ({
  auth0Type: user.auth0Type,
})

export default connect(mapStateToProps)(Auth0LoadingPage)
