export const PASSWORD_RULES = {
  LENGTH: "min",
  NUMBER: "digits",
  UPCASE: "uppercase",
  LOWCASE: "lowercase",
  SYMBOL: "symbols",
  PREVIOUS: "Your new password must be different from your previous password",
}

export const PASSWORD_REQUIREMENTS = [
  {
    type: PASSWORD_RULES.LENGTH,
    text: "Have at least 6 characters",
    description: "Must be at least 6 characters",
  },
  {
    type: PASSWORD_RULES.NUMBER,
    text: "At least one number",
    description: "Must have at least one number",
  },
  {
    type: PASSWORD_RULES.UPCASE,
    text: "At least one uppercase letter",
    description: "Must have at least one uppercase letter",
  },
  {
    type: PASSWORD_RULES.LOWCASE,
    text: "At least one lowercase letter",
    description: "Must have at least one lowercase letter",
  },
  {
    type: PASSWORD_RULES.SYMBOL,
    text: "At least one symbol",
    description: "Must have at least one symbol",
  },
]

export const CHANGE_PASSWORD_REQUIREMENTS = [
  {
    type: PASSWORD_RULES.LENGTH,
    text: "Have at least 6 characters",
    description: "Must be at least 6 characters",
  },
  {
    type: PASSWORD_RULES.NUMBER,
    text: "Contain at least one number",
    description: "Must have at least one number",
  },
  {
    type: PASSWORD_RULES.UPCASE,
    text: "At least one uppercase letter",
    description: "Must have at least one uppercase letter",
  },
  {
    type: PASSWORD_RULES.LOWCASE,
    text: "At least one lowercase letter",
    description: "Must have at least one lowercase letter",
  },
  {
    type: PASSWORD_RULES.SYMBOL,
    text: "At least one symbol",
    description: "Must have at least one symbol",
  },
]
