import React, { useMemo, useState } from "react"

import PropTypes from "prop-types"

import { useScreenWidth } from "@hooks"
import {
  SuggestionItem,
  SuggestionsList as SuggestionsListEl,
} from "components"

import { MobileSuggestionsCount } from "../atoms"
import { SuggestionsActions } from "../molecules"

import { ListWrapper } from "./gameplanBlockStyles"

export const SuggestionsList = ({
  suggestions,
  isNewest,
  isDecidedSuggestion,
  setIsNewest,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { isSmallSize } = useScreenWidth()

  const isList = useMemo(() => {
    return !isDecidedSuggestion || (isDecidedSuggestion && !isCollapsed)
  }, [isDecidedSuggestion, isCollapsed])

  return (
    <ListWrapper $isCollapsed={isCollapsed} $isSmallSize={isSmallSize}>
      {isList && (
        <>
          {isSmallSize && (
            <MobileSuggestionsCount amount={suggestions.length} />
          )}

          <SuggestionsListEl isCollapsed>
            {suggestions.map(suggestion => (
              <SuggestionItem
                isCollapsed
                suggestion={suggestion}
                suggestionsLength={suggestions.length}
                key={suggestion.id}
              />
            ))}
          </SuggestionsListEl>
        </>
      )}

      {!isSmallSize && (
        <SuggestionsActions
          isCollapsed={isCollapsed}
          isDecidedSuggestion={isDecidedSuggestion}
          suggestionsLength={suggestions.length}
          isNewest={isNewest}
          setIsCollapsed={setIsCollapsed}
          setIsNewest={setIsNewest}
        />
      )}
    </ListWrapper>
  )
}

SuggestionsList.propTypes = {
  suggestions: PropTypes.array,
  isNewest: PropTypes.bool,
  isDecidedSuggestion: PropTypes.bool,
  setIsNewest: PropTypes.func,
}
