import { useDispatch, useSelector } from "react-redux"

import { updateGamePlanWhere } from "@actions/createTeeupActions"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectIsFromCreateTeeUp } from "@selectors/common"
import {
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectTeeupSuggestions,
  selectTeeupGameplans,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import {
  validateSuggestion,
  GAMEPLAN_OPTIONS,
  locationTypeIds,
} from "@utils/gamePlanUtils"
import { createSuggetion } from "middlewares/backendMiddleware"

const formatSections = (
  gameplans,
  recommendationsItems,
  recommendationsIds
) => {
  let sections = gameplans.map((gameplan, index) => ({
    ...gameplan,
    data: [index],
    index,
  }))
  let recommendations = recommendationsIds
    ? recommendationsIds.map(
        recommendationId => recommendationsItems[recommendationId]
      )
    : []

  if (recommendations.length > 0) {
    sections.push({
      index: gameplans.length,
      title: "Possibilities for your TeeUp",
      data: [gameplans.length],
      recommendations,
    })
  }

  return sections
}

const useSuggest = ({
  suggestInfo,
  suggestType,
  teeupID = null,
  initComment,
}) => {
  const currentSuggestions = useSelector(selectTeeupSuggestions)
  const gameplans = useSelector(selectTeeupGameplans)
  const recommendations = useSelector(selectTeeupRecommendations)
  const recommendationsIds = useSelector(selectTeeupRecommendationsIds)
  const userId = useSelector(selectUserId)
  const idTeeup = useSelector(selectActiveTeeupId)
  const teeupId = teeupID === null ? idTeeup : teeupID
  const isFromCreateTeeUp = useSelector(selectIsFromCreateTeeUp)
  const dispatch = useDispatch()

  const sections = formatSections(
    gameplans,
    recommendations,
    recommendationsIds
  )
  const locationType =
    suggestType === "online"
      ? locationTypeIds.online
      : locationTypeIds.realWorld
  const type = GAMEPLAN_OPTIONS.where
  const currentSection = sections.find(section => section.type === type)
  const optionId = sections.length && currentSection?.optionId

  const onSubmit = async selected => {
    const newSuggestion = {
      locationType,
      value:
        suggestType === "online"
          ? suggestInfo.title === "Other"
            ? suggestInfo.appName
            : suggestInfo.title
          : suggestInfo.firstRow?.trim() ?? suggestInfo.secondRow?.trim(),
      value2: undefined,
      details:
        suggestType === "online"
          ? suggestInfo.details
          : suggestInfo.secondRow?.trim(),
      optionId,
    }
    const isValid = validateSuggestion(currentSuggestions, newSuggestion, type)
    if (!isValid) return isValid

    const dataToUpdate = {
      type,
      userId,
      teeupId,
      value: newSuggestion.value,
      value2: newSuggestion.value2,
      locationType,
      fullDetails: suggestInfo.fullDetails || null,
      isCustomDate: true,
      isCustomTime: true,
      isTimeZoneEnabled: undefined,
      details: newSuggestion.details,
      startDate: undefined,
      googlePlaceId: null,
      selected,
      decided: selected,
    }

    if (optionId) {
      dataToUpdate.optionId = optionId
    }

    dataToUpdate.index = 1
    if (isFromCreateTeeUp) {
      dispatch(updateGamePlanWhere([dataToUpdate]))
    } else {
      createSuggetion(dataToUpdate, initComment)
      // TODO: delete getTeeupById if it's okay
      // getTeeupById(teeupId)
    }

    return isValid
  }

  return {
    onSubmit,
  }
}

export default useSuggest
