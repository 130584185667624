import React, { useContext, useEffect, useState } from "react"

import { connect } from "react-redux"

import SuggestionItem from "@components/gamePlansCard/SuggestionPopup/molecules/SuggestionItem"
import {
  selectGuestCalendarEvents,
  selectGuestLinkedCalendar,
} from "@selectors/calendar"
import { selectGuestSuggestions } from "@selectors/guest"
import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"
import getWhereSuggestionDetails from "@utils/gameplan/getWhereSuggestionDetails"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { getStartOfDate, getEndOfDate } from "../helpers"
import { useCurrentSuggestion, useCertainConflictedEvent } from "../hooks"
import {
  StepperHeader,
  ReactionsList,
  ConflictedCalendarEvent,
} from "../molecules"

import { Wrapper, SuggestionWrapper } from "./currentSuggestionScreenStyles"

const CurrentSuggestionScreen = ({
  linkedGuestCalendar,
  calendarEvents,
  guestSuggestions,
}) => {
  const {
    stepsManager: { suggestionPreviewId },
    fetchGuestCalendarsEvents,
    gameplanInfo,
    previewInfo,
  } = useContext(TeeupPreviewContext)
  const [details, setDetails] = useState(null)

  const currentSuggestion = useCurrentSuggestion({
    guestSuggestions,
    suggestionPreviewId,
    whereSuggestions: gameplanInfo?.where?.suggestions,
  })

  const conflictedEvent = useCertainConflictedEvent({
    calendarEvents,
    currentSuggestion,
  })

  useEffect(() => {
    if (!currentSuggestion) return

    const details =
      currentSuggestion?.type === "when"
        ? getWhenSuggestionDetails(currentSuggestion)
        : getWhereSuggestionDetails(currentSuggestion)
    setDetails(details)

    if (!linkedGuestCalendar) return
    const { startDate } = currentSuggestion

    fetchGuestCalendarsEvents({
      calendarCredentials: linkedGuestCalendar,
      startDate: getStartOfDate(startDate),
      endDate: getEndOfDate(startDate),
    })
  }, [currentSuggestion])

  // const getBackText = () => {
  //   const creatorName = currentSuggestion.creator?.name

  //   return creatorName ? `${creatorName}'s Suggestion` : "My Suggestion"
  // }

  return (
    <Wrapper>
      <StepperHeader goBackText="Suggestion details" />

      <SuggestionWrapper>
        {currentSuggestion && (
          <SuggestionItem
            preview={{
              isPreview: true,
              shortReactions: true,
            }}
            title={details?.title}
            subtitle={details?.subtitle}
            suggestion={currentSuggestion}
          />
        )}

        {!!conflictedEvent && (
          <ConflictedCalendarEvent event={conflictedEvent} />
        )}
      </SuggestionWrapper>

      {!!currentSuggestion?.reactions && (
        <ReactionsList
          reactions={currentSuggestion.reactions}
          previewInfo={previewInfo}
        />
      )}
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  linkedGuestCalendar: selectGuestLinkedCalendar(state),
  calendarEvents: selectGuestCalendarEvents(state),
  guestSuggestions: selectGuestSuggestions(state),
})

export default connect(mapStateToProps)(CurrentSuggestionScreen)
