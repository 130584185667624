import React from "react"

import PropTypes from "prop-types"
import {
  View,
  TouchableWithoutFeedback,
  TouchableOpacity,
  BackHandler,
  Image,
} from "react-native"

// import navigationConfig from '@nav/NavigationConfig'
import { Units } from "@theme/"
import { images } from "@utils/imageUtils"

import { PopupWrapperStyles as styles } from "./styles"

class PopupOverlayWrapper extends React.Component {
  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.handleAndroidBackPress
    )
  }

  componentWillUnmount() {
    if (this.backHandler) {
      this.backHandler.remove()
    }
  }

  handleAndroidBackPress = () => {
    this.props.handleClose()
    return true
  }

  renderCloseButton = () => {
    const { handleClose } = this.props
    return (
      <View style={styles.closeButtonPosition}>
        <TouchableOpacity
          hitSlop={Units.hitSlop()}
          style={styles.closeButton}
          onPress={handleClose}>
          <Image
            style={styles.closeIcon}
            source={images.closeDialog}
            resizeMode="contain"
          />
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    const { handleClose, children } = this.props

    return (
      <View style={styles.overlayContainer}>
        <TouchableWithoutFeedback style={styles.flex} onPress={handleClose}>
          <View style={styles.dialogWrapper}>
            <TouchableWithoutFeedback>
              <View style={styles.dialogContainer}>
                {children}
                {this.renderCloseButton()}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  }
}

PopupOverlayWrapper.propTypes = {
  handleClose: PropTypes.func,
}

export default PopupOverlayWrapper
