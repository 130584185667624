import dayjs from "dayjs"

import { checkIfSameDate } from "../helpers"

export const useDateSetting = ({
  setNewGuestSuggestion,
  setIsChecked,
  fetchCalendarEvents,
  currentSelectedDay,
  setCurrentSelectedDay,
}) => {
  return date => {
    setIsChecked(true)
    const isSameDate = checkIfSameDate(date, currentSelectedDay)
    if (isSameDate) return

    setNewGuestSuggestion({
      start: date.set({
        hour: currentSelectedDay?.hours?.() || currentSelectedDay?.hour?.(),
        minutes:
          currentSelectedDay.minutes?.() || currentSelectedDay.minute?.(),
      }),
      end: dayjs(date).add(1, "hour"),
    })
    setCurrentSelectedDay(date)
    fetchCalendarEvents(date)
  }
}
