import {
  compareDates,
  formatStatusMessage,
  checkStatusMessage,
  formatResults,
  checkResults,
} from "@utils/messagesUtils"
import { formatLastAction } from "@utils/teeupUtils"
import api from "api"
import endpoints from "config/endpoints"
import { teeupCategories, teeupStatusKeys } from "config/enums"
import { conversationTypeIds, messageTypeIds } from "config/mappings"
import { store } from "index"

import { formatMessage } from "../middlewares/backendMiddleware"

import actionTypes from "./actionTypes"
import { fetchTeeupOverview, updateGameplan } from "./teeupActions"

export const setTeeupActive = async teeup => {
  store.dispatch(
    setTeeupActiveState({
      ...teeup,
      messages: [],
    })
  )
  if (!teeup.id) return

  const teeupId = teeup.id

  const [overview, messages, organisers, invitationHistory] = await Promise.all(
    [
      fetchTeeupOverview(teeupId),
      fetchMessages(teeupId),
      fetchTeeupOrganisers(teeupId),
      fetchTeeupHistory(teeupId),
    ]
  )

  const { teeup: updatedTeeup, gameplans } = overview

  store.dispatch(
    updateActiveTeeup({
      ...updatedTeeup,
      gameplans: gameplans.gameplans,
      messages,
      organisers,
      invitationHistory,
    })
  )
}

export const getTeeupById = async teeupId => {
  let messages = []
  const data = await fetchTeeupOverview(teeupId)

  if (data?.messages) {
    messages = receiveMessages(data.messages)
  } else {
    messages = await fetchMessages(teeupId)
  }
  // if there is no data, it will redirect to teeUps page
  if (!data) return

  const {
    teeup,
    people,
    participants,
    gameplanOptions,
    locations,
    organizers,
    history,
  } = data

  // todo remove once we confirm it is not needed for sure
  // const organizers = await fetchTeeupOrganisers(teeupId)
  // const invitationHistory = await fetchTeeupHistory(teeupId)

  store.dispatch({
    type: actionTypes.SET_TEEUP_PEOPLE_AND_PARTICIPANTS,
    payload: {
      people,
      participants,
      gameplanOptions,
      locations,
      teeupIdentification: Number(teeup?.id),
    },
  })

  store.dispatch(updateGameplan(Number(teeup?.id), gameplanOptions))

  store.dispatch(
    setTeeupActiveState({
      ...teeup,
      messages,
      organisers: organizers,
      invitationHistory: history,
      gameplans: gameplanOptions.gameplans,
    })
  )
}

const receiveMessages = responseMessages => {
  let messages = []
  let suggestionMessages = {}
  let lastActions = {}

  // Compose initial message
  let initialMessage = {
    conversationTypeId: conversationTypeIds.initial,
    invitees: [],
    gameplans: [],
  }
  let messageIndex = 0 // points to index where initial message ends
  for (
    let messagesLength = responseMessages.length;
    messageIndex < messagesLength;
    messageIndex++
  ) {
    let backendMessage = responseMessages[messageIndex]

    if (backendMessage.conversationTypeId !== conversationTypeIds.initial) {
      // Done with initial messages
      break
    }

    // All initial messages have conversationTypeId === 4 (doesn't matter if it's text, status, suggestion...)
    // So using more primitive thing to determine which is which.
    if (backendMessage.statusId) {
      backendMessage.conversationTypeId = conversationTypeIds.status
      initialMessage.invitees.push(formatMessage(backendMessage))
    } else if (backendMessage.reactions) {
      backendMessage.conversationTypeId = conversationTypeIds.suggestion
      initialMessage.gameplans.push(formatMessage(backendMessage))
    } else if (backendMessage.video) {
      backendMessage.conversationTypeId = conversationTypeIds.video
    } else if (backendMessage.image) {
      backendMessage.conversationTypeId = conversationTypeIds.image
    } else if (backendMessage.file) {
      backendMessage.conversationTypeId = conversationTypeIds.file
    } else if (backendMessage.text) {
      backendMessage.conversationTypeId = backendMessage.messageId
        ? conversationTypeIds.reply
        : conversationTypeIds.message
      initialMessage = {
        ...formatMessage(backendMessage),
        ...initialMessage,
      }
    }
  }

  responseMessages.forEach((backendMessage, i) => {
    if (
      i >= messageIndex &&
      backendMessage.conversationTypeId === conversationTypeIds.initial
    ) {
      if (backendMessage.reactions) {
        initialMessage.gameplans.push(backendMessage)
      } else if (backendMessage.text) {
        backendMessage.conversationTypeId = backendMessage.messageId
          ? conversationTypeIds.reply
          : conversationTypeIds.message
        backendMessage = {
          ...formatMessage(backendMessage),
          ...initialMessage,
        }
      }
    }
  })

  for (let i = responseMessages.length - 1; i >= messageIndex; i--) {
    let message = formatMessage(responseMessages[i])
    const messagesLength = messages.length
    let previousMessage = messages[messagesLength - 1]
    if (!message) {
      // Maybe an empty message or a new, not yet handled type
      continue
    }
    // eslint-disable-next-line
    if (!lastActions.hasOwnProperty(message.senderId)) {
      lastActions[message.senderId] = formatLastAction(message)
    }

    let suggestionId = message.suggestionId
    if (suggestionId) {
      if (!suggestionMessages[suggestionId]) {
        suggestionMessages[suggestionId] = []
      }
      suggestionMessages[suggestionId].push(message)
    }

    if (previousMessage) {
      const dateDifference = compareDates(message, previousMessage)

      // comparing messages timestamps for chat daybreaks and push new message immediately if the dates are not equal
      if (dateDifference) {
        messages.push(dateDifference, message)
        continue
      }

      const formattedStatusMessage = formatStatusMessage(
        previousMessage,
        message
      )
      const check = checkStatusMessage(previousMessage, message, true)
      if (
        formattedStatusMessage &&
        formattedStatusMessage !== formatResults.PUSH
      ) {
        messages[messagesLength - 1] = formattedStatusMessage
        continue
      } else if (check === checkResults.REMOVE_ALL) {
        messages.pop()
        // remove daybreak message too if we have it in queue
        if (
          messages[messagesLength - 1] &&
          // eslint-disable-next-line
          messages[messagesLength - 1].hasOwnProperty("daybreak")
        ) {
          messages.pop()
        }
        continue
      } else if (check === checkResults.REMOVE_PREVIOUS) {
        if (message.timestamp > previousMessage.timestamp) {
          messages[messagesLength - 1] = message
        }
        continue
      }
    }
    messages.push(message)
  }

  if (initialMessage.senderId) {
    const dateDifference =
      messages.length > 0 ? compareDates(initialMessage, messages.at(-1)) : null
    if (dateDifference) {
      messages.push(dateDifference)
    }
    messages.push(initialMessage)
  }

  return messages
}

export const fetchMessages = async teeupId => {
  const messagesUrl = endpoints.teeup.messages(teeupId)

  return api.client
    .get(messagesUrl)
    .then(response => receiveMessages(response?.data?.messages || []))
    .catch(error => {
      console.error(error)
    })
}

export const setTeeupActiveState = teeup => ({
  type: actionTypes.SET_ACTIVE_TEEUP,
  payload: teeup,
})

export const manageTeeUpSuggestions = payload => ({
  type: actionTypes.MANAGE_TEEUP_SUGGESTIONS,
  payload,
})

export const updateActiveTeeUpStatus = payload => ({
  type: actionTypes.UPDATE_ACTIVE_TEEUP_STATUS,
  payload,
})

export const u = messages => ({
  type: actionTypes.SET_MESSAGES,
  messages,
})

export const updateActiveTeeup = payload => ({
  type: actionTypes.UPDATE_ACTIVE_TEEUP,
  payload,
})

export const addMessage = (message, teeupId) => ({
  type: actionTypes.ADD_MESSAGE,
  payload: { message, teeupId },
})

const fetchTeeupOrganisers = teeupId => {
  const organizersUrl = endpoints.teeup.organisers(teeupId)

  return api.client
    .get(organizersUrl)
    .then(response => {
      const { data: organisers } = response.data

      return organisers
    })
    .catch(error => {
      console.error("fetchTeeupOrganisers error", error)
    })
}

const fetchTeeupHistory = teeupId => {
  const historyUrl = endpoints.teeup.invitationHistory(teeupId)

  return api.client
    .get(historyUrl)
    .then(response => {
      const { history } = response.data

      return history
    })
    .catch(error => {
      console.error("fetchTeeupHistory error", error)
    })
}

export const updateTeeupOrganisers = (teeupId, organisers) => {
  let url = endpoints.teeup.organisers(teeupId)
  let organiserIds = organisers.map(({ id }) => id)

  return api.client
    .put(url, { organisers: organiserIds })
    .then(response => {
      store.dispatch(
        updateActiveTeeup({ id: teeupId, organisers: response.data.data })
      )
    })
    .catch(error => {
      console.error("updateTeeupOrganisers error", error)
    })
}

export const uploadFile = (teeupId, message) => {
  const messageTypeId = messageTypeIds.file
  const dataType = "file"
  const data = message
  const url = endpoints.teeup.teeupSaveFiles(teeupId)
  const body = new FormData()
  body.append("message", JSON.stringify({ messageTypeId }))
  body.append(dataType, data)

  return api.client
    .post(url, body)
    .then(response => {
      const message = formatMessage(response.data)
      if (message) {
        store.dispatch(addMessage(message, teeupId))
      }
    })
    .catch(error => {
      console.error("uploadMessageFileError", error)
    })
}

export const updateMessage = message => ({
  type: actionTypes.UPDATE_MESSAGE,
  payload: message,
})

export const moveToActive = teeup => {
  const url = endpoints.teeup.categories(teeup.id, teeupCategories.teeups)
  // const url = endpoints.teeup.activateTeeup(teeup.id);

  // return api.client.post(url).then(() => {
  return api.client
    .put(url)
    .then(() => {
      store.dispatch({
        type: actionTypes.MOVE_TEEUP_TO_ACTIVE,
        payload: teeup,
      })
    })
    .catch(error => {
      console.error("moveToActive", error)
    })
}

export const toggleParticipantDetails = payload => ({
  type: actionTypes.TOGGLE_PARTICIPANT_DETAILS,
  payload,
})

export const toggleDetailsUser = payload => ({
  type: actionTypes.SELECT_DETAILS_USER,
  payload,
})

export const openSuggestWhenModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHEN_OPEN,
  payload: true,
})

export const closeSuggestWhenModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHEN_OPEN,
  payload: false,
})

export const openSuggestWhenAIModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHEN_AI_OPEN,
  payload: true,
})

export const openSuggestWhereModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHERE_OPEN,
  payload: true,
})

export const closeSuggestWhereModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHERE_OPEN,
  payload: false,
})

export const closeSuggestWhenAiModalAction = () => ({
  type: actionTypes.SET_IS_SUGGEST_WHEN_AI_OPEN,
  payload: false,
})

export const onIgnoreNudgePrompt = payload => ({
  type: actionTypes.IGNORE_MESSAGE_PROMPT,
  payload,
})

export const onPromptRespond = payload => ({
  type: actionTypes.RESPOND_MESSAGE_PROPMT,
  payload,
})
