import { useContext, useMemo } from "react"

import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"

import { useCreatedSuggestions } from "./useCreatedSuggestions"

export const usePostingSuggestions = (suggestions, whenGameplanOptionId) => {
  const { previewInfo, gameplanInfo } = useContext(TeeupPreviewContext)
  const createdSuggestions = useCreatedSuggestions(suggestions)

  return useMemo(() => {
    return createdSuggestions.map(
      ({ id, creator, isCreated, isPublished, key, title, ...rest }) => ({
        ...rest,
        isSelected: false,
        isDecided: false,
        gameplanOptionId:
          whenGameplanOptionId || previewInfo?.suggestionsPlan.whenOptionId,
        customDate: "",
        customTime: "",
        isTimeZoneEnabled: true,
        isCustomDate: false,
        isCustomTime: false,
        type: GAMEPLAN_OPTIONS.when,
        comment: null,
      })
    )
  }, [createdSuggestions, gameplanInfo, whenGameplanOptionId])
}
