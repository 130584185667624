import { object, string, lazy } from "yup"

import { isProdEnv } from "@utils/checkEnv"

import { EMAIL_VALIDATION_REGEX } from "../../constants/global"

import { REGISTER_STEPS } from "./constants"

export const getValidationSchema = step => {
  const isProd = isProdEnv()

  return lazy(() => {
    return object().shape({
      email:
        step === REGISTER_STEPS.EMAIL
          ? string()
              .matches(
                EMAIL_VALIDATION_REGEX,
                "Please enter a valid email address"
              )
              .required("Email is a required field")
          : string(),
      code:
        step === REGISTER_STEPS.SIGN_UP_CODE ? string().required() : string(),
      password:
        step === REGISTER_STEPS.PASSWORD
          ? string()
              .min(6, "Have at least 6 characters")
              .matches(/[!"#$%&()*,.:<=>?@^{|}-]/, "At least one symbol")
              .matches(/\d/, "Contain at least one number")
              .matches(/[A-Z]/, "At least one uppercase letter")
              .matches(/[a-z]/, "At least one lowercase letter")
              .required("Password is required field")
          : string(),
      fullName:
        step === REGISTER_STEPS.NAMES
          ? string()
              .min(3, "Must be at least 3 characters")
              .required("Full name is a required field")
          : string(),
      captcha:
        step === REGISTER_STEPS.NAMES && isProd
          ? string().required("Captcha is required")
          : null,
      displayName:
        step === REGISTER_STEPS.NAMES
          ? string()
              .matches(
                /^(?!.*\.\..*)[\dA-Za-z][\d.A-Za-z]*[\dA-Za-z]$/,
                "Invalid value"
              )
              .min(3, "Must be at least 3 characters")
              .required("Display name is a required field")
          : string(),
      inviteVerificationCode:
        step === REGISTER_STEPS.INVITE_SENDER ? string().required() : string(),
    })
  })
}
