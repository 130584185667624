import actionTypes from "@actions/actionTypes"
import { formatSingleCalendarEvent } from "@utils/calendarSyncUtils"
import {
  EVENT_TYPES,
  putTeeupOnCalendarWithoutDuplicates,
  detectTeeupCalendarDay,
  CALENDAR_TYPES,
  formatCalendarEventsUtil,
} from "@utils/calendarUtils"
import { dateComparator } from "@utils/dateUtils"

// import { gameplanTypes } from '@configs/mappings'
import CalendarState from "./calendarStorage"

export const initialState = {
  updaterValue: Date.now(),
  showSettingsMessage: true,
  calendarListSections: [
    {
      title: "",
      data: [],
      status: {},
    },
  ],
  calendarEmails: [],
  calendarDisplay: {},
  linkedGuestCalendar: null,
  guestCalendarEvents: [],
  isGuestConnectionError: false,
  calendarDisplayHash: "",
  calendarSyncEmail: "",
  calSyncInProgress: true,
  isCalendarSynchronized: false,
}

// Event structure (mandatory fields)
// id
// startDate
// endDate
// type: EVENT_TYPES.calendar
// name

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GOT_CALENDAR: {
      const { events } = action.payload

      const { calendarDays, calendarEvents, calendarEventsByDay } =
        formatCalendarEventsUtil(events, state?.user?.userInfo?.timezone)

      CalendarState.calendarEventsByDay = calendarEventsByDay
      CalendarState.calendarEvents = calendarEvents
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.SET_CALENDAR_EMAIL_DATA: {
      const { data, calendarType, relatedTo } = action.payload
      if (calendarType === CALENDAR_TYPES.google) {
        const modifiedData = data
          .map(calendarListEntry => ({
            calendarEmailId: calendarListEntry.id,
            summary:
              calendarListEntry.summaryOverride ?? calendarListEntry.summary,
            primary: calendarListEntry.primary ?? false,
            selected: false,
            colorId: calendarListEntry.colorId,
            calendarType,
            relatedTo,
          }))
          .sort((a, b) => Number(b.primary) - Number(a.primary))
        return {
          ...state,
          calendarEmails: [].concat(
            modifiedData,
            state.calendarEmails.filter(email => {
              for (const el of modifiedData)
                if (email.calendarEmailId === el.calendarEmailId) return false
              return true
            })
          ),
        }
      }
      if (calendarType === CALENDAR_TYPES.outlook) {
        const modifiedData = data
          .map(calendarListEntry => ({
            calendarEmailId: calendarListEntry.id,
            summary: calendarListEntry.isDefaultCalendar
              ? calendarListEntry.owner.address
              : calendarListEntry.name,
            primary: calendarListEntry.isDefaultCalendar ?? false,
            selected: false,
            colorId: calendarListEntry.color,
            calendarType,
            relatedTo,
            accountId: action.payload.accountId,
            accEmail: calendarListEntry.owner.address,
          }))
          .sort((a, b) => Number(b.primary) - Number(a.primary))
        return {
          ...state,
          calendarEmails: [].concat(
            modifiedData,
            state.calendarEmails.filter(email => {
              for (const el of modifiedData)
                if (email.calendarEmailId === el.calendarEmailId) return false
              return true
            })
          ),
        }
      }
      return { ...state }
    }

    case actionTypes.SET_TEEUPS: {
      let teeupEvents = {}
      let calendarDays = [...CalendarState.calendarDays]
      let { teeups } = action.payload
      let teeupEventsByDay = {
        ...CalendarState.teeupEventsByDay,
      }

      teeups.forEach(teeup => {
        teeupEvents[teeup.id] = {
          ...(!!CalendarState.teeupEvents[teeup.id] &&
            CalendarState.teeupEvents[teeup.id]),
          id: teeup.id,
          name: teeup.name,
          events: teeup.events || [],
          type: EVENT_TYPES.teeup,
        }
        if (teeupEvents[teeup.id]) {
          const { teeupEvent, beginningOfTheDay, isExactDate } =
            detectTeeupCalendarDay({
              teeupEvent: teeupEvents[teeup.id],
              teeup,
            })

          if (!(beginningOfTheDay in teeupEventsByDay) && isExactDate) {
            teeupEventsByDay[beginningOfTheDay] = []
            calendarDays.push(beginningOfTheDay)
          }

          teeupEventsByDay = putTeeupOnCalendarWithoutDuplicates({
            teeupEventsByDay,
            teeup,
            beginningOfTheDay,
          })
          teeupEvents[teeup.id] = {
            ...teeupEvents[teeup.id],
            ...teeupEvent,
          }
        }
      })

      calendarDays = [...new Set(calendarDays)]
      calendarDays = calendarDays.sort(dateComparator)

      CalendarState.teeupEvents = teeupEvents
      CalendarState.teeupEventsByDay = teeupEventsByDay
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }

    // not used currently
    case actionTypes.CALENDAR_ADD_TEEUP_EVENTS: {
      const newlySyncedTeeups = action.payload
      let teeupEvents = { ...CalendarState.teeupEvents }
      let calendarDays = [...CalendarState.calendarDays]
      let teeupEventsByDay = {
        ...CalendarState.teeupEventsByDay,
      }

      const teeupEventsIds = Object.keys(newlySyncedTeeups)

      teeupEventsIds.forEach(teeupId => {
        const events = newlySyncedTeeups[teeupId].events
        teeupEvents[teeupId] = {
          ...(!!CalendarState.teeupEvents[teeupId] &&
            CalendarState.teeupEvents[teeupId]),
          id: teeupId,
          name: newlySyncedTeeups[teeupId].name,
          type: EVENT_TYPES.teeup,
          events,
        }

        const { teeupEvent, beginningOfTheDay, isExactDate } =
          detectTeeupCalendarDay({
            teeupEvent: teeupEvents[teeupId],
            teeup: newlySyncedTeeups[teeupId],
          })

        if (!(beginningOfTheDay in teeupEventsByDay) && isExactDate) {
          teeupEventsByDay[beginningOfTheDay] = []
          calendarDays.push(beginningOfTheDay)
        }
        teeupEventsByDay = putTeeupOnCalendarWithoutDuplicates({
          teeupEventsByDay,
          teeup: teeupEvents[teeupId],
          beginningOfTheDay,
        })

        teeupEvents[teeupId] = {
          ...teeupEvents[teeupId],
          ...teeupEvent,
        }
      })

      calendarDays = [...new Set(calendarDays)]
      calendarDays = calendarDays.sort(dateComparator)
      CalendarState.teeupEvents = teeupEvents
      CalendarState.teeupEventsByDay = teeupEventsByDay
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.GOT_AVALIABLE_CALENDARS: {
      const calendars = action.payload
      CalendarState.avaliableCalendars = calendars
      return { ...state, updaterValue: Date.now() }
    }

    case actionTypes.SET_CALENDAR_PERMISSIONS: {
      const permissions = action.payload
      CalendarState.calendarPermissions = permissions
      return { ...state, updaterValue: Date.now() }
    }
    case actionTypes.RESET_CALENDAR_STATE: {
      CalendarState.calendarEventsByDay = {}
      CalendarState.calendarEvents = {}
      return { ...initialState }
    }
    case actionTypes.RESET_SINGLE_CALENDAR_STATE: {
      const { calendarId, calendarType } = action.payload
      let calendarEventsToMutate = { ...CalendarState.calendarEvents }

      const events = Object.values(calendarEventsToMutate).filter(
        event => event.calendarType !== calendarType
      )

      const { calendarDays, calendarEvents, calendarEventsByDay } =
        formatCalendarEventsUtil(events)

      CalendarState.calendarDays = calendarDays
      CalendarState.calendarEvents = calendarEvents
      CalendarState.calendarEventsByDay = calendarEventsByDay

      const newObj = {}
      const currentDisplay = { ...state.calendarDisplay }
      Object.keys(currentDisplay).forEach(key => {
        if (currentDisplay[key].relatedTo !== calendarId)
          newObj[key] = currentDisplay[key]
      })
      return {
        ...state,
        calendarEmails: state.calendarEmails.filter(
          email => email.relatedTo !== calendarId
        ),
        calendarDisplay: newObj,
        updaterValue: Date.now(),
      }
    }
    case actionTypes.CREATE_CALENDAR_EVENT: {
      const event = action.payload
      let calendarEventsToMutate = { ...CalendarState.calendarEvents }

      const formattedEvent = formatSingleCalendarEvent(event)
      calendarEventsToMutate[event.id] = formattedEvent

      const { calendarDays, calendarEvents, calendarEventsByDay } =
        formatCalendarEventsUtil(Object.values(calendarEventsToMutate))

      CalendarState.calendarEventsByDay = calendarEventsByDay
      CalendarState.calendarEvents = calendarEvents
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }
    case actionTypes.DELETE_CALENDAR_EVENT: {
      const eventId = action.payload
      let calendarEventsToMutate = { ...CalendarState.calendarEvents }

      const events = Object.values(calendarEventsToMutate).filter(
        event => event.id !== eventId
      )

      const { calendarDays, calendarEvents, calendarEventsByDay } =
        formatCalendarEventsUtil(events)

      CalendarState.calendarEventsByDay = calendarEventsByDay
      CalendarState.calendarEvents = calendarEvents
      CalendarState.calendarDays = calendarDays

      return { ...state, updaterValue: Date.now() }
    }
    case actionTypes.SET_DISPLAYED_DATA: {
      const calendarDisplayObj = { ...action.payload }
      for (let calendarKey in calendarDisplayObj) {
        if (state.calendarDisplay.hasOwnProperty(calendarKey)) {
          delete calendarDisplayObj[calendarKey]
        }
      }

      return {
        ...state,
        calendarDisplay: { ...state.calendarDisplay, ...calendarDisplayObj },
        calendarDisplayHash: (Math.random() + 1).toString(36).slice(7),
      }
    }
    case actionTypes.SET_DISPLAYED_CALENDAR: {
      return {
        ...state,
        calendarDisplay: action.payload,
      }
    }
    case actionTypes.MANAGE_DISPLAYED_CALENDARS: {
      const displayedCalendarsList = action.payload
      const justResult = Object.keys(state.calendarDisplay).reduce(
        (acc, currentKey) => {
          acc[currentKey] = {
            ...state.calendarDisplay[currentKey],
            selected: displayedCalendarsList.some(
              displayedCalendar => displayedCalendar.id === currentKey
            ),
          }

          return acc
        },
        {}
      )

      return {
        ...state,
        calendarDisplay: {
          ...state.calendarDisplay,
          ...justResult,
        },
      }
    }
    case actionTypes.GET_SUGGESTION_WEEK:
      return {
        ...state,
        suggestWeek: action.payload,
      }
    case actionTypes.SET_BEFORE_CALENDAR_DATE:
      return {
        ...state,
        isBeforeCalendarDate: action.payload,
      }
    case actionTypes.SET_CALENDAR_LIST_SECTIONS:
      return {
        ...state,
        calendarListSections: action.payload,
      }
    case actionTypes.SET_CALENDAR_SYNC_EMAIL:
      return {
        ...state,
        calendarSyncEmail: action.payload?.calendarEmailId ?? action.payload,
      }
    case actionTypes.SET_HIDE_CALENDAR_SETTINGS_MESSAGE:
      return {
        ...state,
        showSettingsMessage: false,
      }
    case actionTypes.SET_CALENDAR_SYNC_IN_PROGRESS:
      return {
        ...state,
        calSyncInProgress: action.payload,
      }
    case actionTypes.SET_IS_CALENDAR_SYNCHRONIZED:
      return {
        ...state,
        isCalendarSynchronized: action.payload,
      }
    case actionTypes.SET_GUEST_CALENDAR_INFO:
      return {
        ...state,
        linkedGuestCalendar: action.payload,
      }
    case actionTypes.SET_GUEST_CALENDAR_EVENTS:
      return {
        ...state,
        guestCalendarEvents: action.payload,
      }
    case actionTypes.SET_IS_GUEST_CONNECTION_ERROR:
      return {
        ...state,
        isGuestConnectionError: action.payload,
      }
    case actionTypes.UNLINK_GUEST_CALENDAR:
      return {
        ...state,
        linkedGuestCalendar: null,
        guestCalendarEvents: [],
      }
    default:
      return state
  }
}
