import SuggestWhenOverview from "../../assets/images/suggest-when-overview.svg"
import SuggestionsOverview from "../../assets/images/suggestions-overview.svg"
import TeeupOverview from "../../assets/images/triple-teeup-overview.svg"

export const INFO_LIST = [
  {
    name: "teeup",
    title: "Get everyone planning, with TeeUps",
    description:
      "TeeUps keep all of your planning activity in one place, making it easy to see all details at a glance.",
    image: TeeupOverview,
  },
  {
    name: "suggest when",
    title: "Coordinate Multiple Timezones",
    description:
      "Our straightforward timezone overview allows you to visually compare time zones with ease.",
    image: SuggestWhenOverview,
  },
  {
    name: "suggestion",
    title: "Suggest, React, Decide.",
    description:
      "Make suggestions for when or where to meet and effortlessly see what works for everyone.",
    image: SuggestionsOverview,
  },
]
