import ViewByTeeupsItem from "./components/ViewByTeeupsItem"
import ViewByteeupsSearchBar from "./components/ViewByTeeupsSearchBar"

import React, { useState, useMemo, useEffect } from "react"

import { differenceBy } from "lodash"
import get from "lodash/get"
import { connect } from "react-redux"

import "./index.scss"
import {
  selectAllContactsWithoutInvitedToTeeup,
  selectAllContactsWithoutInvitedToGroup,
  selectAllContacts,
} from "@selectors/contacts"
import {
  selectTeeupParticipants,
  selectTeeupsPeople,
  selectActiveTeeups,
  selectTeeupsGameplans,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import {
  DEFAULT_SORT,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
} from "@utils/teeupUtils"

import SortingAndFilter from "../../components/SideFilterSortMenu"
import Invitees from "../AddInvitees/Invitees"
import MyContactItem from "../Contacts/MyContactItem"

const ViewByTeeups = ({
  contacts,
  activeTeeups,
  teeupPeople,
  teeupsGameplans,
  all,
  getUserId,
  inviteesProp,
  setInviteesProp,
  handleOnInviteesDone,
  setContent,
  fromCreateTeeup,
  fromTeeup,
  setInviteesFromCreateTeeup,
  noHover,
  inviteToNewTeeup,
  closeOptionWithAnimations,
  isTeeupInvite,
}) => {
  const { notificationTeeups, notifications } = all
  const [teeupFilter, setTeeupFilter] = useState("")
  const [sorting, setSorting] = useState(DEFAULT_SORT)
  const [filtering, setFiltering] = useState(initialFiltersValues)
  const [toggleFilter, setToggleFilter] = useState(false)
  const [invitees, setInvitees] = useState(inviteesProp || [])

  const sortedList = useMemo(() => {
    const sorted = teeupSorting(activeTeeups, sorting, teeupsGameplans)
    return sorting.desc ? sorted.reverse() : sorted
  }, [sorting, activeTeeups])

  const filterAndSortedData = () => {
    const { userId, teeupsGameplans, teeupFilter } = all
    let teeupsData = teeupsFiltering(
      sortedList,
      filtering,
      userId,
      teeupsGameplans
    )
    if (notificationTeeups) {
      const notificationsArr = new Set(
        notifications.map(notification => notification.id)
      )
      return teeupsData.filter(teeup => notificationsArr.has(teeup.id))
    }
    if (teeupFilter) {
      return teeupsData.filter(
        (teeup = {}) =>
          teeup.name && teeup.name.match(new RegExp(teeupFilter, "i"))
      )
    }
    return teeupsData
  }

  const resetSortAndFilter = () => {
    setSorting(DEFAULT_SORT)
    setFiltering(initialFiltersValues)
  }

  useEffect(() => {
    setInviteesFromCreateTeeup && setInviteesFromCreateTeeup(invitees)
    fromTeeup && setInviteesProp(invitees)
  }, [invitees])

  const handleToggleContact =
    ({ data }) =>
    () => {
      setInvitees(invitees => {
        const diffInvitees = differenceBy(invitees, [data], "id")
        const isInvited = diffInvitees.length !== invitees.length
        fromTeeup &&
          setInviteesProp(isInvited ? diffInvitees : [...invitees, { ...data }])
        return isInvited ? diffInvitees : [...invitees, { ...data }]
      })
    }

  const renderMyContactItem = ({ item, isInvitees }, noHover) => {
    return (
      <MyContactItem
        item={item}
        isInvitees={isInvitees}
        onRemoveInvitee={handleToggleContact({ data: item })}
        invitees={invitees}
        setContent={setContent}
        noHover={noHover}
      />
    )
  }

  const additionalStyle = {
    left: "calc((100vw - 608px)/2 + 624px)",
    // right: '15px'
    // right: fromCreateTeeup ? '-315px' : 0
  }
  return (
    <div
      className="viewteeups__container"
      style={fromCreateTeeup && { maxHeight: "820px", overflow: "hidden" }}>
      {!fromCreateTeeup && (
        <h2 className="viewteeups_header">View by TeeUps</h2>
      )}
      <ViewByteeupsSearchBar
        teeupFilter={teeupFilter}
        setTeeupFilter={setTeeupFilter}
        setToggleFilter={setToggleFilter}
        toggleFilter={toggleFilter}
        fromCreateTeeup={fromCreateTeeup}
      />
      {toggleFilter && (
        <SortingAndFilter
          closeMenu={() => setToggleFilter(false)}
          teeupSorting={sorting}
          teeupFiltering={filtering}
          teeupsData={filterAndSortedData()}
          addTeeupsSorting={setSorting}
          addTeeupsFiltering={setFiltering}
          clearTeeupsFiltering={resetSortAndFilter}
        />
      )}
      {invitees.length > 0 && (
        <Invitees
          renderMyContactItem={renderMyContactItem}
          invitees={invitees}
          additionalStyles={fromCreateTeeup ? {} : additionalStyle}
          handleOnInviteesDone={handleOnInviteesDone}
          onDone={() => setInvitees([])}
          noHover
          inviteToNewTeeup={inviteToNewTeeup}
          closeOptionWithAnimations={closeOptionWithAnimations}
          fromCreateTeeup={fromCreateTeeup}
          isTeeupInvite={isTeeupInvite}
        />
      )}
      <div
        className="viewteeups__wrapper"
        style={{ height: fromCreateTeeup && "calc(100vh - 270px)" }}>
        {filterAndSortedData()
          .filter(teeup =>
            teeup.name.toLowerCase().includes(teeupFilter.toLowerCase())
          )
          .map(activeTeeup => (
            <ViewByTeeupsItem
              key={activeTeeup.id}
              contacts={contacts}
              handleToggleContact={handleToggleContact}
              invitees={invitees}
              setInvitees={setInvitees}
              activeTeeup={activeTeeup}
              people={get(teeupPeople, activeTeeup.id, {})}
              getUserId={getUserId}
              fromCreateTeeup={fromCreateTeeup}
              handleOnInviteesDone={handleOnInviteesDone}
              noHover={noHover}
            />
          ))}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const alreadyInvitedIds = selectTeeupParticipants(state)
  const isTeeupInvite = true
  const isGroupInvite = false
  const getUserId = selectUserId(state)
  const contacts =
    alreadyInvitedIds && isTeeupInvite
      ? selectAllContactsWithoutInvitedToTeeup(state)
      : alreadyInvitedIds && isGroupInvite
      ? selectAllContactsWithoutInvitedToGroup(state)
      : selectAllContacts(state)
  const activeTeeups = selectActiveTeeups(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupsGameplans = selectTeeupsGameplans(state)
  const all = state

  return {
    contacts,
    activeTeeups,
    teeupPeople,
    teeupsGameplans,
    getUserId,
    all,
  }
}

export default connect(mapStateToProps, null)(ViewByTeeups)
