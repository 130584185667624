import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  removeFromGamePlanWhen,
  removeFromGamePlanWhere,
} from "@actions/createTeeupActions"
import { removeGuestSuggestion } from "@actions/guestActions"
import { CircleButton } from "@components/common"
import { GAMEPLAN_TYPES } from "@constants/gameplan"

import { Wrapper } from "./removeSuggestionBtnStyles"

const RemoveSuggestionBtn = ({
  type,
  id,
  isInner,
  isNoPadding,
  isInline,
  isCreateTeeup,
  removeGuestSuggestion,
  removeFromGamePlanWhen,
  removeFromGamePlanWhere,
}) => {
  const handleRemoveSuggestion = () => {
    if (isCreateTeeup) {
      return type === GAMEPLAN_TYPES.when
        ? removeFromGamePlanWhen(id)
        : removeFromGamePlanWhere(id)
    }

    removeGuestSuggestion(id)
  }

  return (
    <Wrapper
      $isInline={isInline}
      $isInner={isInner}
      onMouseDown={handleRemoveSuggestion}>
      <CircleButton iconName="close" isNoPadding={isNoPadding || isInner} />
    </Wrapper>
  )
}

const mapDispatchToProps = dispatch => ({
  removeGuestSuggestion: bindActionCreators(removeGuestSuggestion, dispatch),
  removeFromGamePlanWhen: bindActionCreators(removeFromGamePlanWhen, dispatch),
  removeFromGamePlanWhere: bindActionCreators(
    removeFromGamePlanWhere,
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(RemoveSuggestionBtn)

RemoveSuggestionBtn.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  isInner: PropTypes.bool,
  isNoPadding: PropTypes.bool,
  isCreateTeeup: PropTypes.bool,
  isInline: PropTypes.bool,
}
