import React, { useState, useEffect } from "react"

import * as RNLocalize from "react-native-localize"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { bindActionCreators } from "redux"
import { v4 as uuidv4 } from "uuid"

import { setModalInfo } from "@actions/commonActions"
import {
  register,
  createUserCM,
  changeEmailVerificationRequest,
} from "@actions/loginActions"
import { fetchUserInfo, setUserInfo } from "@actions/userActions"
import ModalInfo from "@components/ui/modal"
import { selectUserInfo } from "@selectors/user"
import { getCountryCodeFromPhoneNumber } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { TEMPLATE_TYPES } from "pages/components/CustomTemplateSign/constants"
import CustomTemplateSign from "pages/components/CustomTemplateSign/index"

import api from "../../api"
import VerifyCode from "../VerifyCode"
import "./index.scss"
import { useTopLevelContext } from "../../contexts/TopLevelContext"
import Success from "../components/Success"

import CreateAccount from "./components/CreateAccount"
import CreatePassword from "./components/CreatePassword"
import NameDisplay from "./components/NameDisplay"

let userInfo = {}
const deviceId = uuidv4()

const SignUp = ({ setUserInfo, token, location }) => {
  const [isEmailSetting, setIsEmailSetting] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { setIsRegistrationInProgress, isRegistrationInProgress } =
    useTopLevelContext()

  const { state: params, search } = history.location
  const queryEmail = new URLSearchParams(search).get("email")

  const [step, setStep] = useState(1)
  const [isPreview, setIsPreview] = useState(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  const [currentEmail, setCurrentEmail] = useState(params?.defaultEmail)
  const [error, setError] = useState("")
  const [contactMechanismVerificationId, setContactMechanismVerificationId] =
    useState()

  useEffect(() => {
    if (!!queryEmail?.length || location.state) {
      history.push("/sign-up-email?email=" + queryEmail)
      setIsPreview(true)
    }
  }, [queryEmail])

  const handleAddEmail = email => {
    userInfo.email = email
    // nextStep();
  }

  const handleAddPassword = password => {
    userInfo.password = password
    nextStep()
  }

  const handleAddNames = (fullName, name) => {
    userInfo.name = fullName
    userInfo.nickname = name

    if (params) {
      const { userId, phoneNumber, deviceId } = params
      userInfo.id = userId
      userInfo.deviceId = deviceId

      if (phoneNumber) {
        userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber)
        userInfo.phoneNumber = phoneNumber
      }
    } else {
      const { userId, phoneNumber } = userInfo
      // userInfo.id = userId;
      userInfo.deviceId = deviceId

      if (phoneNumber) {
        userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber)
        userInfo.phoneNumber = phoneNumber
      }
    }

    // if (phoneNumber) {
    //   userInfo.countryCode = getCountryCodeFromPhoneNumber(phoneNumber);
    //   userInfo.phoneNumber = phoneNumber;
    // }
    setIsLoadingBtn(true)

    register(userInfo)
      .then(response => {
        userInfo = {
          userId: response.data.id,
          ...response.data,
          loginType: "email",
        }
        setIsLoadingBtn(false)
        nextStep()
      })
      .catch(error => {
        if (error.status === 409) {
          dispatch(
            setModalInfo({
              show: true,
              title: "This email is already in use!",
            })
          )
          setIsLoadingBtn(false)
          setStep(1)
        } else {
          dispatch(
            setModalInfo({
              show: true,
              title: "Something went wrong! Try again later.",
            })
          )
        }
      })
  }

  const handleRedirect = () => {
    api.createSession(userInfo)
    setUserInfo(userInfo)
    fetchUserInfo(true)
  }

  const handleRedirectAddContact = async () => {
    api.createSession(userInfo)
    setUserInfo(userInfo)
    setIsRegistrationInProgress(true)
    const fetchedUserInfo = await fetchUserInfo()
    setIsRegistrationInProgress(false)
    if (fetchedUserInfo) {
      history.push("/contact")
    }
  }

  const nextStep = () => setStep(step + 1)
  const previousStep = () => setStep(step - 1)

  // const [editMode, setEditMode] = useState(false)
  // const [value, setValue] = useState('');
  // const [popup, setPopup] = useState(false)
  // const [addedCM, setAddedCM] = useState()

  const handleSignUp = email => {
    setIsEmailSetting(true)
    api.client
      .post("/users", {
        deviceId: deviceId || uuidv4(),
        email: email,
        timezone: RNLocalize.getTimeZone(),
      })
      .then(response => {
        userInfo = { ...userInfo, ...response.data.user }
        setContactMechanismVerificationId(response.data.contactMechanism.id)
        nextStep()
        setIsEmailSetting(false)
      })
      .catch(error => {
        if (error.status === 409) {
          setError(() => "This email is already in use!")
        } else {
          dispatch(
            setModalInfo({
              show: true,
              title: "Something went wrong! Try again later.",
            })
          )
        }
        setIsEmailSetting(false)
      })
  }

  const onDoneHandler = async email => {
    await createUserCM(7, email, token)
      .then(res => {
        // setAddedCM(res.data)
        changeEmailVerificationRequest(res.data.id, token)
          .then(response => {
            setContactMechanismVerificationId(
              response.data.contactMechanismVerificationId
            )
          })
          .catch(error => {
            setError("The contact is invalid")
          })
      })
      .catch(error => {
        console.error("CM error", error)
      })
  }

  return (
    <>
      <ModalInfo />
      <CustomTemplateSign type={TEMPLATE_TYPES.signup}>
        <div className="signup__page" style={{ position: "relative" }}>
          {/* <div
          className="signup__progress"
          style={{ width: `${step * 25}%` }}
        ></div> */}
          <div className="signup__content">
            {step === 1 && (
              <CreateAccount
                error={error}
                isEmailSetting={isEmailSetting}
                isPreview={isPreview}
                emailFromPreview={
                  location.state ? location.state.emailFromPreview : ""
                }
                handleAddEmail={handleAddEmail}
                defaultEmail={
                  isPreview
                    ? params?.defaultEmail
                    : location.state
                    ? location.state.emailFromPreview
                    : queryEmail || ""
                }
                handleClickVerify={token ? onDoneHandler : handleSignUp}
                // handleClickVerify={onDoneHandler}
                setCurrentEmail={setCurrentEmail}
              />
            )}
            {step === 2 && (
              <VerifyCode
                deviceId={deviceId}
                emailVerification
                type={"email"}
                contactMechanismVerification={contactMechanismVerificationId}
                accessToken={token}
                nextStep={nextStep}
                previousStep={previousStep}
                value={currentEmail}
              />
            )}
            {step === 3 && (
              <CreatePassword onClickContinueBtn={handleAddPassword} />
            )}
            {step === 4 && (
              <NameDisplay
                handleAddNames={handleAddNames}
                isLoading={isLoadingBtn}
              />
            )}
            {step === 5 && (
              <Success
                image={images.successCreate}
                title="Great! Just one more step"
                subtitle="Your account is active, but we suggest that you set up contact methods now so your friends can easily invite you to make plans."
                buttonText="Setup Contact Methods"
                onClick={handleRedirectAddContact}
                secondaryButton={
                  <span
                    onClick={handleRedirect}
                    className="signup__later"
                    data-testid="iWillDoItLaterButton">
                    I’ll do this later
                  </span>
                }
              />
            )}
          </div>
        </div>
      </CustomTemplateSign>
    </>
  )
}

const mapStateToProps = state => ({
  token: state.signUpWithInvitation.token,
  userInfo: selectUserInfo(state),
})

const mapDispatchToProps = dispatch => ({
  setUserInfo: bindActionCreators(setUserInfo, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
