import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { NTitle5 } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  ${({ $isMainScreen }) => css`
    width: 100%;
    background-color: ${$isMainScreen ? "var(--radicalRed)" : "var(--white)"};
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    @media (max-width: ${MEDIA_SIZES.MOBILE}) {
      padding: 15px;
    }
  `};
`

export const BackIcon = styled(SvgIcon)`
  transform: rotate(90deg);
  margin-right: 10px;
`

export const BackText = styled(NTitle5)`
  ${({ $isMain }) => css`
    color: ${$isMain ? "var(--white)" : "var(--black)"};
  `};
`

export const SettingsIcon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
`
