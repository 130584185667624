import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;

  overflow-y: auto;
`

export const TabsWrapper = styled.div`
  background-color: var(--white);
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;
`
