class SuggestionDrag {
  constructor() {
    this._dropRef = null
    this._onTouchStart = null
    this._onTouchMove = null
    this._onTouchEnd = null
  }

  get dropRef() {
    return this._dropRef
  }

  set dropRef(r) {
    this._dropRef = r
  }

  get onTouchStart() {
    return this._onTouchStart
  }

  set onTouchStart(r) {
    this._onTouchStart = r
  }

  get onTouchMove() {
    return this._onTouchMove
  }

  set onTouchMove(r) {
    this._onTouchMove = r
  }

  get onTouchEnd() {
    return this._onTouchEnd
  }

  set onTouchEnd(r) {
    this._onTouchEnd = r
  }
}

const suggestionDragGlobal = new SuggestionDrag()
export default suggestionDragGlobal
