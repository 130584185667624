import { v4 as uuidv4 } from "uuid"

import { images } from "@utils/imageUtils"

import actionTypes from "../actions/actionTypes"

const initialState = {
  isCreateTeeupOpen: false,
  isInviteesError: false,
  screenType: null,
  settings: {
    allowInviteOthers: true,
    allowChangeGameplanOptions: true,
    allowDecideGameplan: true,
    allowChangeTitle: true,
    allowPromoteToAllSetOrPlanning: true,
    allowPromoteToEnded: false,
    allowInviteLink: true,
  },
  isModalOpen: false,
  draftTeeup: null,
  gameplanWhen: {
    icon: images.ndGamePlanWhen,
    isPrimary: true,
    suggestions: [],
    title: "When",
    type: "when",
  },
  gameplanWhere: {
    icon: images.ndGamePlanWhere,
    isPrimary: true,
    suggestions: [],
    title: "Where",
    type: "where",
  },
  activeGamePlanTab: -1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_CREATE_TEEUP_OPEN:
      return {
        ...state,
        isCreateTeeupOpen: action.payload,
      }
    case actionTypes.SET_CREATE_TEEUP_SCREEN_TYPE:
      return {
        ...state,
        screenType: action.payload,
      }
    case actionTypes.SET_CREATE_TEEUP_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      }
    case actionTypes.SET_IS_INVITEES_ERROR:
      return {
        ...state,
        isInviteesError: action.payload,
      }
    case actionTypes.CLOSE_CREATE_TEEUP:
      return {
        ...state,
        isModalOpen: false,
        draftTeeup: null,
      }
    case actionTypes.OPEN_CREATE_TEEUP:
      return {
        ...state,
        isModalOpen: true,
        draftTeeup: action.payload,
      }
    case actionTypes.UPDATE_GAMEPLAN_WHEN_SUGGESTION: {
      const newWhenSuggestion = {
        ...action.payload[0],
        slug: uuidv4(),
      }

      return {
        ...state,
        gameplanWhen: {
          ...state.gameplanWhen,
          suggestions: [...state.gameplanWhen.suggestions, newWhenSuggestion],
        },
      }
    }
    case actionTypes.UPDATE_GAMEPLAN_WHEN_SUGGESTIONS: {
      const newWhenSuggestions = action.payload.map(suggestion => ({
        ...suggestion,
        slug: uuidv4(),
      }))

      return {
        ...state,
        gameplanWhen: {
          ...state.gameplanWhen,
          suggestions: [
            ...state.gameplanWhen.suggestions,
            ...newWhenSuggestions,
          ],
        },
      }
    }
    case actionTypes.REMOVE_GAMEPLAN_WHEN_SUGGESTION: {
      return {
        ...state,
        gameplanWhen: {
          ...state.gameplanWhen,
          suggestions: state.gameplanWhen.suggestions.filter(
            suggestion => suggestion.slug !== action.payload
          ),
        },
      }
    }
    case actionTypes.REMOVE_GAMEPLAN_WHERE_SUGGESTION: {
      return {
        ...state,
        gameplanWhere: {
          ...state.gameplanWhere,
          suggestions: state.gameplanWhere.suggestions.filter(
            suggestion => suggestion.slug !== action.payload
          ),
        },
      }
    }
    case actionTypes.UPDATE_GAMEPLAN_WHERE_SUGGESTION: {
      const newWhereSuggestion = {
        ...action.payload[0],
        slug: uuidv4(),
      }

      return {
        ...state,
        gameplanWhere: {
          ...state.gameplanWhere,
          suggestions: [...state.gameplanWhere.suggestions, newWhereSuggestion],
        },
      }
    }
    case actionTypes.UPDATE_GAMEPLAN_WHERE_SUGGESTIONS: {
      const newWhereSuggestions = action.payload.map(suggestion => ({
        ...suggestion,
        slug: uuidv4(),
      }))

      return {
        ...state,
        gameplanWhere: {
          ...state.gameplanWhere,
          suggestions: [
            ...state.gameplanWhere.suggestions,
            ...newWhereSuggestions,
          ],
        },
      }
    }
    case actionTypes.SET_ACTIVE_GAMEPLAN_TAB: {
      return {
        ...state,
        activeGamePlanTab: action.payload,
      }
    }
    case actionTypes.CLEAR_GAMEPLAN: {
      return {
        ...state,
        gameplanWhen: {
          ...state.gameplanWhen,
          suggestions: [],
        },
        gameplanWhere: {
          ...state.gameplanWhere,
          suggestions: [],
        },
      }
    }
    default:
      return state
  }
}
