import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes } from "@theme"

export const styles = StyleSheet.create({
  sideMenuWrapper: {
    flex: 1,
    backgroundColor: AppColors.brand.lightGrey,
    // height: '85vh'
  },
  title: {
    fontFamily: "greycliff-cf-bold",
    fontSize: 20,
    lineHeight: "21px",
    letterSpacing: "-0.4px",
    color: "#222",
    marginBottom: 12,
    display: "block",
    height: 18,
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Units.responsiveValue(16),
    paddingTop: Units.responsiveValue(AppSizes.statusBarHeight),
    height: Units.responsiveValue(56 + AppSizes.statusBarHeight),
    backgroundColor: AppColors.brand.white,
  },
  navButtonRight: {
    paddingLeft: Units.responsiveValue(16),
  },
  navButtonLeft: {
    paddingRight: Units.responsiveValue(16),
  },
  content: {
    flex: 1,
    overflow: "auto",
    height: "85vh",
    minHeight: "50vh",
    marginBottom: 110,
  },
  footer: {
    // padding: Units.responsiveValue(14),
    // height: Units.responsiveValue(108),
    flex: 1,
    justifyContent: "flex-end",
    backgroundColor: AppColors.brand.white,
    position: "absolute",
    bottom: 34,
    left: 0,
    right: 0,
  },
  button: {
    // flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: Units.responsiveValue(34),
    width: 140,
    cursor: "pointer",
  },
  buttonRow: {
    height: Units.responsiveValue(36),
    marginTop: Units.responsiveValue(16),
  },
  leftButton: {
    marginRight: Units.responsiveValue(6),
    borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.pink,
    backgroundColor: AppColors.brand.white,
  },
  rightButton: {
    backgroundColor: AppColors.brand.pink,
  },
  rightButtonDisabled: {
    backgroundColor: AppColors.brand.grey2,
  },
  //images
  backArrow: {
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
  },
  closeButton: {
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
  },
  //text
  headerTitle: {
    flexGrow: 1,
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.black,
    textAlign: "center",
    lineHeight: Units.responsiveValue(26),
    letterSpacing: -Units.responsiveValue(0.5),
  },
  teeupsCount: {
    textAlign: "center",
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 12,
    lineHeight: "21px",
    letterSpacing: "-0.24px",
    color: "#222222",
  },
  footerButtonText: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 17,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.34),
  },
  clearText: {
    color: AppColors.brand.pink,
  },
  doneText: {
    color: AppColors.brand.white,
  },
})
