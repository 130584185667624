import React, { useContext, useEffect, useRef, useState } from "react"

import { UploadImage } from "@components"
import { IconButtonEl, SvgIcon, TransparentInput } from "@components/common"
import { TEEUP_SETTINGS_TYPES } from "@constants/teeups"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { TEEUP_TITLE_LENGTH } from "@utils/contacts"

import { InfoButtons } from "../molecules"
import { BlockWrapper } from "../newTeeupSettingsStyles"

import { TitleBlock, Top, InputWrapper } from "./infoStyles"

export const Info = () => {
  const { isTeeupLoading, teeup, isTeeupOrganizer, setSettingsType } =
    useContext(NewTeeupPageContext)
  const [isEditActive, setIsEditActive] = useState(false)
  const [teeupImage, setTeeupImage] = useState(null)
  const [titleVal, setTitleVal] = useState("")
  const [titleValCopy, setTitleValCopy] = useState("")
  const [titleError, setTitleError] = useState(false)

  const inputRef = useRef(null)

  const isTitleChanging =
    teeup.settings.allowChangeTitle ||
    (!teeup.settings.allowChangeTitle && isTeeupOrganizer)

  useEffect(() => {
    if (teeup.id) {
      const { name, photo } = teeup
      setTitleVal(name)
      setTeeupImage(photo)
    }
  }, [teeup])

  useEffect(() => {
    if (isEditActive && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditActive])

  const handleToggleClick = () => {
    if (!isEditActive) {
      setTitleValCopy(titleVal)
    }
    setIsEditActive(prev => !prev)
  }

  const handleOnCancel = () => {
    setTitleVal(titleValCopy)
    setIsEditActive(false)
  }

  const handleTitleChange = value => {
    if (value.length > TEEUP_TITLE_LENGTH) {
      setTitleError(true)
      return
    } else {
      setTitleError(false)
    }
    setTitleVal(value)
  }

  return (
    <BlockWrapper>
      <UploadImage
        image={teeupImage}
        openSelectImage={() => {
          setSettingsType(TEEUP_SETTINGS_TYPES.SELECT_IMAGE)
        }}
      />

      <TitleBlock>
        <Top>
          <InputWrapper>
            <TransparentInput
              value={titleVal}
              label="Title"
              placeholder="Enter title"
              ref={inputRef}
              isDisabled={!isEditActive}
              onChange={e => handleTitleChange(e.target.value)}
              isError={titleError}
            />
          </InputWrapper>

          {isTitleChanging && !isEditActive && (
            <SvgIcon
              name="pencil"
              size={44}
              disabled={isTeeupLoading}
              onClick={handleToggleClick}
            />
          )}
        </Top>

        {isEditActive && (
          <InfoButtons name={titleVal} onCancel={handleOnCancel} />
        )}
      </TitleBlock>
    </BlockWrapper>
  )
}
