import { log } from "console"
import { useDebouncedCallback } from "use-debounce"
import endpoints from "@config/endpoints"
import api from "api"
import cloneDeep from "lodash/cloneDeep"
import qs from "qs"
import { useEffect, useMemo } from "react"
import { useInboxStore } from "stores/inboxStore"
import { FetchTeeupsArgs, FilterTypes } from "types/inbox"
import { shallow } from "zustand/shallow"
import { setTeeupActive } from "@actions/activeTeeupActions"
import { initialTeeup } from "@config/enums"
import { useSelector } from "react-redux"
import { selectUserId } from "@selectors/user"
import { formatTeeups, teeupSorting } from "@utils/inbox"
import { GameplanItem, TeeupItem } from "types/teeup"
import { selectCalendarEvents } from "@selectors/calendar"
import { conversationTypeIds } from "@config/mappings"
import { isEmpty } from "lodash"

interface WorkerMessage {
  withConflicts: Record<number, GameplanItem[]>
  original: Record<number, GameplanItem[]>
}

const FETCH_DELAY = 500

export const useFetchTeeUps = () => {
  const teeUps = useInboxStore(state => state.teeUps)
  const teeUpsState = useInboxStore(state => state.teeUpsState)
  const teeupParticipants = useInboxStore(state => state.teeupParticipants)
  const calendarEvents = useSelector(selectCalendarEvents)
  const currentSortBy = useInboxStore(state => state.currentSortBy)
  const tempGameplanOptions = useInboxStore(state => state.tempGameplanOptions)

  const setTeeUps = useInboxStore(state => state.setTeeUps)
  const setPagination = useInboxStore(state => state.setPagination)
  const setIsLoading = useInboxStore(state => state.setIsLoading)
  const setIsFetchError = useInboxStore(state => state.setIsFetchError)
  const setTeeupParticipants = useInboxStore(
    state => state.setTeeupParticipants
  )
  const setTeeUpState = useInboxStore(state => state.setTeeUpsState)
  const setGameplanOptions = useInboxStore(state => state.setGameplanOptions)
  const setNudges = useInboxStore(state => state.setNudges)
  const setTempGameplanOptions = useInboxStore(
    state => state.setTempGameplanOptions
  )

  const userId = useSelector(selectUserId)

  const worker = useMemo(
    () =>
      new Worker(new URL("./worker_checkCalendarConflicts", import.meta.url)),
    []
  )

  const handleWorkerGameplanOptions = (e: MessageEvent<WorkerMessage>) => {
    const gameplanOptions = useInboxStore.getState().gameplanOptions
    const withConflicts = e.data?.withConflicts
    const original = e.data?.original
    console.log("handleWorkerGameplanOptions", { withConflicts, original })

    const gameplanOptionsParsed = withConflicts || original
    setGameplanOptions({
      ...gameplanOptions,
      ...gameplanOptionsParsed,
    })
  }

  const handleNudgesAndMentions = (
    messages: Record<number, any[]>,
    userId: number
  ) => {
    const ignoredMentions = localStorage.getItem("ignoredMentions")
    const ignoredNudges = localStorage.getItem("ignoredNudges")

    const nudges = Object.entries(messages).reduce(
      (acc, [teeupId, messages]) => {
        let filteredMentions: any[] = []
        let filteredNudges: any[] = []

        const mentions = messages?.filter?.(
          message =>
            message.conversationTypeId === conversationTypeIds.message &&
            message?.mentionIds?.includes(userId)
        )
        const nudgesList = messages?.filter?.(
          message => message.conversationTypeId === conversationTypeIds.nudge
        )

        if (ignoredMentions) {
          const ignoredMentionsList = JSON.parse(ignoredMentions)
          filteredMentions = mentions.filter(
            message => !ignoredMentionsList.includes(message.id)
          )
        }

        if (ignoredNudges) {
          const ignoredNudgesList = JSON.parse(ignoredNudges)
          filteredNudges = nudgesList.filter(
            message => !ignoredNudgesList.includes(message.id)
          )
        }

        acc[teeupId] = { mentions: [], nudges: [] }
        acc[teeupId].mentions =
          filteredMentions.length > 0 || ignoredMentions?.length
            ? filteredMentions
            : mentions
        acc[teeupId].nudges =
          filteredNudges.length > 0 || ignoredNudges?.length
            ? filteredNudges
            : nudgesList

        return acc
      },
      {}
    )
    setNudges(nudges)
  }

  const prepareQueryParams = (
    {
      selectedFilters,
      currentOpenTab,
      currentPage,
      searchValue: search,
    }: FetchTeeupsArgs,
    shouldClearPrevState: boolean
  ) => {
    const teeUpStageFilters = selectedFilters[FilterTypes.stage]
    const teeUpUserStatusFilters = selectedFilters[FilterTypes.userStatus]

    const filterByUserStatus = teeUpUserStatusFilters.map(item => item.id)
    const filterByTeeupStage = teeUpStageFilters.map(item => item.id)

    const userStatusFilter =
      currentOpenTab?.filterQuery === "invited" ? "invited" : "all"
    const teeupStatusFilter =
      currentOpenTab?.filterQuery === "ended" ? "ended" : "all"

    const query = {
      page: shouldClearPrevState ? 0 : currentPage,
      limit: 15,
      participants: true,
      gameplanOptions: true,
      states: true,
      messages: true,
      // TODO: if "remake: true/false" included - filtration not working on backend
      // remake: false,

      category: currentOpenTab?.requestType,
      scheduleFilter: currentOpenTab?.scheduleFilter,

      userStatusFilter,
      teeupStatusFilter,

      teeupStatus: filterByTeeupStage,
      userStatus: filterByUserStatus,

      search,
    }

    return qs.stringify(query)
  }

  const fetchPaginatedTeeups = useDebouncedCallback(
    async (state: FetchTeeupsArgs, shouldClearPrevState: boolean) => {
      if (!api?.client) return setIsLoading(false)

      try {
        let sortedTeeUps: TeeupItem[] | null = null
        const params = prepareQueryParams(state, shouldClearPrevState)
        const url = `${endpoints.paginatedTeeups}?${params}`

        const response = await api.client.get(url)
        const data = response?.data

        handleNudgesAndMentions(data?.messages, userId)

        const formattedTeeups = formatTeeups(data?.teeups, data?.participants)

        const teeUpsCollected = [...teeUps, ...formattedTeeups]

        if (shouldClearPrevState) {
          sortedTeeUps = teeupSorting(teeUpsCollected, currentSortBy, userId)
        }

        setTempGameplanOptions({
          ...tempGameplanOptions,
          ...data?.gameplanOptions,
        })
        setTeeUps(sortedTeeUps || teeUpsCollected)
        setTeeUpState({ ...teeUpsState, ...data?.states })
        setPagination({
          total: data?.total,
          page: data?.page,
          nextPage: data?.nextPage,
          previousPage: data?.previousPage,
        })
        setTeeupParticipants({
          ...teeupParticipants,
          ...data?.participants,
        })
      } catch (e) {
        console.log("error", e)
        setIsFetchError(true)
      } finally {
        setIsLoading(false)
      }
    },
    FETCH_DELAY
  )

  const subscribeFiltration = () => {
    const unSubscribe = useInboxStore.subscribe(
      state => ({
        currentOpenTab: state.currentOpenTab,
        selectedFilters: state.selectedFilters,
        searchValue: state.searchValue,
        currentPage: state.pagination.page,
        triggerTeeupReload: state.triggerTeeupReload,
      }),
      (selectedState, prevState) => {
        setIsLoading(true)

        const shouldClearPrevState =
          selectedState.currentPage === prevState.currentPage

        if (shouldClearPrevState) {
          setTeeUps([])
        }
        fetchPaginatedTeeups(selectedState, shouldClearPrevState)
      },
      {
        equalityFn: shallow,
        fireImmediately: true,
      }
    )

    return unSubscribe
  }

  const subscribeSorting = () => {
    const unSubscribe = useInboxStore.subscribe(
      state => ({ currentSortBy: state.currentSortBy }),
      ({ currentSortBy }) => {
        const teeUps = useInboxStore.getState().teeUps

        const sortedTeeUps = teeupSorting(teeUps, currentSortBy, userId)

        setTeeUps(cloneDeep(sortedTeeUps))
      },
      {
        equalityFn: shallow,
        fireImmediately: false,
      }
    )

    return unSubscribe
  }

  useEffect(() => {
    setTeeupActive(initialTeeup)

    worker.onmessage = e => {
      handleWorkerGameplanOptions(e)
    }

    const unSubscribePagination = subscribeFiltration()
    const unSubscribeSorting = subscribeSorting()

    return () => {
      unSubscribePagination()
      unSubscribeSorting()
    }
  }, [])

  useEffect(() => {
    const gameplanOptions = useInboxStore.getState().tempGameplanOptions

    if (isEmpty(gameplanOptions)) return

    worker.postMessage(JSON.stringify({ gameplanOptions, calendarEvents }))
  }, [calendarEvents, tempGameplanOptions])

  return { fetchPaginatedTeeups, handleNudgesAndMentions }
}
