import _ from "lodash"

import { loginProviders } from "@config/enums"
import { selectTeeups } from "@selectors/teeups"
import dayjs from "utils/dayjs"

import { store } from "../index"

import {
  addTimeCalendarEventDate,
  dateComparator,
  getDateWithTimezone,
} from "./dateUtils"

const MomentRange = require("moment-range")
const Moment = require("moment-timezone")

const moment = MomentRange.extendMoment(Moment)

export const DEFAULT_EVENT_DURATION_MINS = 30 // for external events
export const DEFAULT_SYNCED_DURATION_MINS = 60 // for synced GP without duration

export const EVENT_TYPES = {
  calendar: "calendar",
  teeup: "teeup",
  not_avaliable: "not_avaliable",
}

export const SCREENS = {
  comingUp: "Coming Up",
  day: "Day",
  week: "Week",
  month: "Month",
}

const DATES_HALF_INTERVAL = {
  Day: 25,
  Week: 10,
  Month: 0,
}

const INTERVAL_TYPES = {
  Day: "days",
  Week: "weeks",
  Month: "months",
}

export const COMPARING_TYPES = {
  Day: "day",
  Week: "week",
  Month: "month",
}

export const CALENDAR_TYPES = {
  google: loginProviders.google,
  apple: "apple",
  outlook: loginProviders.outlook,
}

export const isOverlaping = (
  startDate,
  endDate,
  currentStartDate,
  currentEndDate
) => {
  const range1 = moment.range(startDate, endDate)
  const range2 = moment.range(currentStartDate, currentEndDate)
  return range1.overlaps(range2)
}

export const fitsIntoInverval = (startDate, endDate, event) =>
  getDateWithTimezone(event.startDate).isSameOrAfter(
    getDateWithTimezone(startDate)
  ) &&
  getDateWithTimezone(event.endDate).isSameOrBefore(
    getDateWithTimezone(endDate)
  )

export const hasConflict = (event1, event2) => {
  if (
    !!event1.suggestionId &&
    event2.suggestionId &&
    event1.suggestionId === event2.suggestionId
  ) {
    return false
  }
  return (
    isOverlaping(
      event1.startDate,
      event1.endDate,
      event2.startDate,
      event2.endDate
    ) &&
    !(
      event1.type === EVENT_TYPES.calendar &&
      event2.type === EVENT_TYPES.calendar
    )
  )
}

const newCheckOverlaps = data => {
  for (let i = 0; i < data.length; i++) {
    const obj1 = data[i]

    for (let j = i + 1; j < data.length; j++) {
      const obj2 = data[j]

      const range1 = moment.range(obj1.startDate, obj1.endDate)
      const range2 = moment.range(obj2.startDate, obj2.endDate)

      if (range1.overlaps(range2)) {
        obj1.isOverlapped = true
        data[j].isOverlapped = true
        break
      }
    }
  }

  return data
}

export const getEventsLayoutForDay = (eventsList, maxColumnsCount) => {
  let columnsAmount = 1 // if no overlaps, there is one column per day
  let mappedEventsColumns = {
    1: [],
  }
  let mappedEventsList = []
  let conflictPosition = 0

  eventsList.forEach(event => {
    let isPositioned = false
    let currectColumn = 1
    for (
      ;
      // let currectColumn = 1;
      currectColumn <= columnsAmount;
      currectColumn++
    ) {
      if (
        !mappedEventsColumns[currectColumn].some(positionedEvent =>
          isOverlaping(
            positionedEvent.startDate,
            positionedEvent.endDate,
            event.startDate,
            event.endDate
          )
        )
      ) {
        isPositioned = true
        break
      }
    }

    // if it's not possiple to position event in any of existion columns, add a new one
    if (
      !isPositioned &&
      (!maxColumnsCount || columnsAmount < maxColumnsCount)
    ) {
      columnsAmount++
      mappedEventsColumns[currectColumn] = []
    }

    if (mappedEventsColumns[currectColumn]) {
      const conflict = eventsList.some(eventToCompare =>
        hasConflict(eventToCompare, event)
      )
      if (conflict) {
        const date = getDateWithTimezone(event.startDate)
        const eventPosition = date.hour() + date.minute() / 60
        if (eventPosition > conflictPosition) {
          conflictPosition = eventPosition
        }
      }
      mappedEventsColumns[currectColumn].push({
        ...event,
        startColumn: currectColumn,
        hasConflict: conflict,
        width: 1,
      })
    }
  })

  mappedEventsList = _.concat(
    mappedEventsList,
    ...Object.values(mappedEventsColumns)
  )

  for (let i = 0; i < mappedEventsList.length; i++) {
    const event = mappedEventsList[i]
    if (event.startColumn === columnsAmount) {
      // events are sorted by column because of the concat
      break
    }

    let endColumn = columnsAmount
    while (endColumn > event.startColumn) {
      if (
        mappedEventsList.some(
          eventToCompare =>
            isOverlaping(
              eventToCompare.startDate,
              eventToCompare.endDate,
              event.startDate,
              event.endDate
            ) &&
            eventToCompare.id !== event.id &&
            eventToCompare.startColumn > event.startColumn
        )
      ) {
        endColumn = endColumn - 1
      } else {
        break
      }
    }

    mappedEventsList[i].width = endColumn - event.startColumn + 1
  }

  return {
    columnsAmount,
    mappedEventsList: newCheckOverlaps(mappedEventsList),
    conflictPosition,
  }
}

export const getConflictsForMonthCalendar = eventsList => {
  let conflicts = []
  eventsList.forEach(event => {
    const conflict = eventsList.some(eventToCompare => {
      return hasConflict(eventToCompare, event)
    })
    if (conflict) {
      conflicts.push(event.id)
    }
  })

  return conflicts
}

export const getTeeupsAndEventsArray = (
  teeupIds,
  eventIds,
  teeupsInfo,
  eventsInfo,
  hideOptions
) => {
  const calendar = []
  let syncedEventsIds = []
  if (teeupIds) {
    teeupIds.forEach(teeupId => {
      if (
        teeupsInfo[teeupId] &&
        (!hideOptions ||
          (hideOptions.activeTeeupId !== teeupId &&
            hideOptions.optionId !== teeupsInfo[teeupId].optionId))
      ) {
        calendar.push(teeupsInfo[teeupId])
        syncedEventsIds = [
          ...syncedEventsIds,
          ...(teeupsInfo[teeupId].events
            ? teeupsInfo[teeupId].events.map(event => event.eventId)
            : []),
        ]
      }
    })
  }

  if (eventIds) {
    eventIds.forEach(eventId => {
      if (!syncedEventsIds.includes(eventId)) {
        calendar.push(eventsInfo[eventId])
      }
    })
  }

  return calendar
}

export const putTeeupOnCalendarWithoutDuplicates = ({
  teeupEventsByDay,
  teeup,
  beginningOfTheDay,
}) => {
  let newTeeupEvents = { ...teeupEventsByDay }
  Object.keys(newTeeupEvents).forEach(teeupDay => {
    if (newTeeupEvents[teeupDay].includes(Number(teeup.id))) {
      let copyTeeupEventsByDay = [...newTeeupEvents[teeupDay]]
      newTeeupEvents[teeupDay] = copyTeeupEventsByDay.filter(
        teeupEventId => Number(teeupEventId) !== Number(teeup.id)
      )
    }
  })
  newTeeupEvents[beginningOfTheDay] = [
    ...newTeeupEvents[beginningOfTheDay],
    Number(teeup.id),
  ]

  return newTeeupEvents
}

export const detectTeeupCalendarDay = ({
  teeupEvent = {},
  teeup = { gameplans: [] },
  calendarEvents = {},
}) => {
  let editedEvent = { ...teeupEvent }
  const whenGameplan = (teeup.gameplans?.length &&
    teeup.gameplans.find(gameplan => gameplan.title === "When")) || {
    suggestions: [],
  }
  const suggestion =
    whenGameplan.suggestions.find(suggestion => suggestion.isDecided) || {}
  let externalEvent
  if (teeup.events) {
    externalEvent = teeup.events.find(event => event.external)
  }
  if (!externalEvent && teeupEvent.events) {
    externalEvent = teeupEvent.events.find(event => event.external)
  }

  let isExactDate = false
  let beginningOfTheDay = "noExactDate"

  if (suggestion?.when) {
    isExactDate = !(suggestion.when.type === "CustomTime")
    beginningOfTheDay = isExactDate
      ? getDateWithTimezone(suggestion.when.value).startOf("day").format()
      : "noExactDate"
    editedEvent = {
      ...editedEvent,
      suggestionId: suggestion.id,
      optionId: suggestion.optionId,
      startDate: isExactDate
        ? getDateWithTimezone(suggestion.when.value)
        : null,
      endDate: isExactDate
        ? getEndDate({
            startDate: suggestion.when.value,
            endDate: suggestion.when.endDate,
          })
        : null,
    }
  } else if (externalEvent && calendarEvents[externalEvent.eventId]) {
    const event = calendarEvents[externalEvent.eventId]
    isExactDate = !!event.startDate
    beginningOfTheDay = isExactDate
      ? getDateWithTimezone(event.startDate).startOf("day").format()
      : "noExactDate"
    editedEvent = {
      ...editedEvent,
      startDate: event.startDate,
      endDate: isExactDate ? getEndDate(event) : null,
    }
  }

  return {
    teeupEvent: editedEvent,
    beginningOfTheDay,
    isExactDate,
  }
}

export const getEndDate = ({
  endDate,
  startDate,
  overrideDuration = false,
  defaultDurationMins = DEFAULT_EVENT_DURATION_MINS,
}) =>
  endDate && !overrideDuration
    ? getDateWithTimezone(endDate)
    : addTimeCalendarEventDate(startDate, defaultDurationMins, "minutes")

export const getEventWithTime = event => {
  if (event.endDate) {
    return event
  } else {
    const endDate = getEndDate(event)
    return {
      ...event,
      endDate,
    }
  }
}

export const getCalendarsSections = (
  calendars,
  showEditableOnly = false,
  hideCalendars = []
) => {
  const sources = {}
  calendars.forEach(calendar => {
    if (showEditableOnly && !calendar.allowsModifications) {
      return
    }
    if (hideCalendars.includes(calendar.id)) {
      return
    }
    if (!sources[calendar.source]) {
      sources[calendar.source] = []
    }
    sources[calendar.source].push(calendar)
  })

  const sections = Object.keys(sources).map(source => ({
    title: source,
    data: sources[source],
  }))

  return sections
}

export const getDatesArrayForSwiping = (
  selectedDate,
  activeScreen = SCREENS.day,
  allowGoToPast = true
) => {
  const halfInterval = DATES_HALF_INTERVAL[activeScreen]
  const intervalType = INTERVAL_TYPES[activeScreen]
  const comparingType = COMPARING_TYPES[activeScreen]
  let date,
    currentDayIndex,
    datesArray = []
  const today = getDateWithTimezone().startOf(comparingType)
  const selected = getDateWithTimezone(selectedDate).startOf(comparingType)
  date =
    !allowGoToPast &&
    selected.isSameOrBefore(today, comparingType) &&
    today.diff(selected, intervalType) < halfInterval
      ? getDateWithTimezone().startOf(comparingType)
      : getDateWithTimezone(selectedDate)
          .startOf(comparingType)
          .subtract(halfInterval, intervalType)

  for (let i = 0; i < 2 * halfInterval + 1; i++) {
    datesArray.push(date.format())
    if (date.isSame(selectedDate, intervalType)) {
      currentDayIndex = i
    }
    date.add(1, intervalType)
  }

  return {
    datesArray,
    currentDayIndex,
  }
}

export const findPositionInExistingDatesArray = (
  datesArray,
  selectedDate,
  activeScreen = SCREENS.day
) => {
  const formattedDate = getDateWithTimezone(selectedDate)
    .startOf(COMPARING_TYPES[activeScreen])
    .format()
  const newIndex = datesArray.indexOf(formattedDate)
  return newIndex
}

export const formatCalendarEventsUtil = events => {
  let calendarDays = []
  let calendarEventsByDay = {}
  let calendarEvents = {}
  events.forEach(calendarEvent => {
    if (!calendarEvent) return
    const beginningOfTheDay = getDateWithTimezone(calendarEvent?.startDate)
      .startOf("day")
      .format()
    const { id } = calendarEvent
    if (!(beginningOfTheDay in calendarEventsByDay)) {
      calendarEventsByDay[beginningOfTheDay] = []
      calendarDays.push(beginningOfTheDay)
    }
    calendarEventsByDay[beginningOfTheDay].push(id)
    calendarEvents[id] = {
      ...calendarEvent,
      type: EVENT_TYPES.calendar,
    }
  })
  calendarDays = calendarDays.sort(dateComparator)
  return { calendarDays, calendarEventsByDay, calendarEvents }
}

const catchIsDuplicatedEvent = (event, currentEvent) => {
  return (
    event.name === currentEvent.name &&
    event.type === "teeup" &&
    moment(event?.startDate).format() === currentEvent.startDate
  )
}

export const ignoreExternalEventDuplicate = calendar => {
  const state = store.getState()
  const teeups = selectTeeups(state).teeups

  return calendar.reduce((acc, current) => {
    const existingEvent = acc.find(event =>
      catchIsDuplicatedEvent(event, current)
    )
    const eventTeeup =
      current.type === "teeup"
        ? teeups.find(teeup => teeup.id === current.id)
        : null

    if (
      !existingEvent &&
      (!eventTeeup || (eventTeeup && eventTeeup.userStatus !== "kickedOut"))
    ) {
      acc.push(current)
    }

    return acc
  }, [])
}

export const ignoreExternalDuplicateByPeriod = period => {
  const periodCopy = { ...period }

  for (let key in periodCopy) {
    periodCopy[key] = ignoreExternalEventDuplicate(periodCopy[key])
  }

  return periodCopy
}

export const changeEventRangeKeys = list => {
  return list.map(({ start, end, startDate, endDate, ...rest }) => ({
    ...rest,
    start: start?.dateTime || start || startDate,
    end: end?.dateTime || end || endDate,
  }))
}

// export const getCurrentEventRange = event => {
//   return moment.range(
//     event?.startDate || event?.start?.dateTime || event?.start,
//     event?.endDate || event?.end?.dateTime || event?.end
//   )
// }

// export const checkIfEventsConflict = (firstEvent, secondEvent) => {
//   const range1 = getCurrentEventRange(firstEvent)
//   const range2 = getCurrentEventRange(secondEvent)

//   return range1.overlaps(range2)
// }

export const getCurrentEventRange = event => {
  const start = dayjs(
    event?.startDate || event?.start?.dateTime || event?.start
  )
  const end = dayjs(event?.endDate || event?.end?.dateTime || event?.end)

  return { start, end }
}

export const checkIfEventsConflict = (firstEvent, secondEvent) => {
  const range1 = getCurrentEventRange(firstEvent)
  const range2 = getCurrentEventRange(secondEvent)

  return range1.start.isBefore(range2.end) && range2.start.isBefore(range1.end)
}

const sortEventsByDuration = events => {
  return events.sort((a, b) => {
    const durationA = dayjs(a.end).diff(dayjs(a.start))
    const durationB = dayjs(b.end).diff(dayjs(b.start))
    return durationB - durationA
  })
}

export const buildEventsWithInner = (list, eventType) => {
  const updatedList = changeEventRangeKeys(list)
  console.log("updatedList", updatedList)
  const sortedByDuration = sortEventsByDuration(updatedList)

  return sortedByDuration.reduce((acc, current) => {
    const overlappingGroup = acc.find(groupedEvent =>
      checkIfEventsConflict(current, groupedEvent)
    )

    if (overlappingGroup) {
      // adding of inner events
      overlappingGroup.innerEvents.push({
        ...current,
        eventType,
        title: "",
      })
    } else {
      // creating new main event for inner events
      const mainEvent = {
        ...current,
        eventType,
        title: "",
        innerEvents: [],
      }
      acc.push({
        ...mainEvent,
        innerEvents: [mainEvent],
      })
    }

    return acc
  }, [])
}

export const addSummaryForOutlook = list => {
  return list.map(event => {
    const newEvent = {
      ...event,
      summary: event.subject,
      start: {
        dateTime: dayjs(event.start.dateTime)
          .subtract(new Date().getTimezoneOffset(), "minutes")
          .format(),
      },
      end: {
        dateTime: dayjs(event.end.dateTime)
          .subtract(new Date().getTimezoneOffset(), "minutes")
          .format(),
      },
    }

    delete newEvent.subject
    return newEvent
  })
}
