import { useCallback, useEffect, useState } from "react"

import { getSizeAsNumber } from "@utils/getSizeAsNumber"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

const initialValues = {
  isDesktop: false,
  isLaptop: false,
  isTabletL: false,
  isTablet: false,
  isMobile: false,
}

const catchWidth = width => {
  switch (true) {
    case width < getSizeAsNumber(MEDIA_SIZES.MOBILE):
      return { ...initialValues, isMobile: true }
    case width < getSizeAsNumber(MEDIA_SIZES.TABLET):
      return { ...initialValues, isTablet: true }
    case width < getSizeAsNumber(MEDIA_SIZES.TABLET_L):
      return { ...initialValues, isTabletL: true }
    case width < getSizeAsNumber(MEDIA_SIZES.LAPTOP):
      return { ...initialValues, isLaptop: true }
    default:
      return { ...initialValues, isDesktop: true }
  }
}

export const useScreenWidth = () => {
  const [result, setResult] = useState<ReturnType<typeof catchWidth> | null>(
    null
  )

  let resizeDelay: NodeJS.Timeout | null = null

  // watching and setting values on resize
  const handleResize = useCallback(() => {
    if (resizeDelay) {
      clearTimeout(resizeDelay)
    }

    resizeDelay = setTimeout(() => {
      setResult(catchWidth(document.body.clientWidth))
    }, 500)
  }, [resizeDelay])

  // setting the initial values
  useEffect(() => {
    setResult(catchWidth(document.body.clientWidth))

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  return {
    ...result,
    isSmallSize: result?.isMobile || result?.isTablet || result?.isTabletL,
  }
}
