// import { duration } from "moment"

import { Dayjs } from "dayjs"
import { useSelector } from "react-redux"

import { SvgIcon } from "@components/common"
import YourSuggestions from "@components/ui/YourSuggestions"
import { selectActiveTeeupGameplans } from "@selectors/activeTeeup"
import { selectGamePlanWhenFromCreate } from "@selectors/createTeeUp"
import { selectUserTimezone } from "@selectors/user"
import { getHoursData } from "@utils/suggestionUtils"
import Pill from "components/ui/Pill"
import { useSnackbarStore } from "stores/snackbarStore"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import { Suggestion, TimeWheelSuggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

const YourSuggestionsContainer = ({ onSave, isFromCreateTeeUp }) => {
  const currentDate = useSuggestionTimeWheelStore(state => state.currentDate)
  const setCurrentDate = useSuggestionTimeWheelStore(
    state => state.setCurrentDate
  )
  const suggestions = useSuggestionTimeWheelStore(state => state.suggestions)
  const addSuggestion = useSuggestionTimeWheelStore(
    state => state.addSuggestion
  )
  const removeSuggestion = useSuggestionTimeWheelStore(
    state => state.removeSuggestion
  )
  const updateSuggestion = useSuggestionTimeWheelStore(
    state => state.updateSuggestion
  )
  const setCurrentActiveSuggestionIndex = useSuggestionTimeWheelStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const currentActiveSuggestionIndex = useSuggestionTimeWheelStore(
    state => state.currentActiveSuggestionIndex
  )
  const updateCurrentSuggestionTrigger = useSuggestionTimeWheelStore(
    state => state.updateCurrentSuggestionTrigger
  )
  const updateOnAddAnotherClickTrigger = useSuggestionTimeWheelStore(
    state => state.updateOnAddAnotherClickTrigger
  )

  const duration = useSuggestionDurationStore(state => state.duration)
  const setDuration = useSuggestionDurationStore(state => state.setDuration)
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)
  const gameplanWhen = useSelector(selectActiveTeeupGameplans)[0]
  const createGameplanWhen = useSelector(selectGamePlanWhenFromCreate)
  const timezone = useSelector(selectUserTimezone)

  const isShown = !!currentDate || suggestions.length > 0

  const checkAlreadyExistedSuggestions = () => {
    const alreadyExist = suggestions.some(
      (item, index) =>
        item.date[0]?.isSame(currentDate, "hour") &&
        item.date[0]?.isSame(currentDate, "minute") &&
        index !== currentActiveSuggestionIndex
    )
    const currentGameplanSuggestions = isFromCreateTeeUp
      ? createGameplanWhen.suggestions
      : gameplanWhen.suggestions

    const alreadyExistInGameplan = currentGameplanSuggestions.some(
      (item: Suggestion, index) =>
        dayjs(item.startDate)?.isSame(currentDate, "hour") &&
        dayjs(item.startDate)?.isSame(currentDate, "minute")
    )

    if (alreadyExist || alreadyExistInGameplan) {
      setSnackbarConfig({
        message: "Some of suggestions are on same time",
        open: true,
      })
      return true
    }

    return false
  }

  const onAddAnotherClick = () => {
    if (checkAlreadyExistedSuggestions()) return

    const durationNum = +duration.id
    if (!currentDate) return

    // setCurrentDate(dayjs())
    addSuggestion({
      date: [currentDate, currentDate.add(durationNum, "minutes")],
      duration: durationNum,
    })
    const isEmpty = suggestions.length === 0
    if (isEmpty) {
      addSuggestion({
        date: [currentDate, currentDate.add(durationNum, "minutes")],
        duration: durationNum,
      })
    }
    updateOnAddAnotherClickTrigger()
    setDuration({
      id: "60",
      value: "1 hour",
    })
    setCurrentActiveSuggestionIndex(
      isEmpty ? suggestions.length + 1 : suggestions.length
    )
  }

  const handleSuggestionClick = (date: TimeWheelSuggestion, index: number) => {
    const duration = getHoursData().find(d => d.id === date.duration.toString())
    setCurrentDate(date[0])
    if (duration) {
      setDuration(duration)
    }
    setCurrentActiveSuggestionIndex(index)
    updateCurrentSuggestionTrigger()
  }

  const handleSuggestClick = (isDecided: boolean) => {
    if (checkAlreadyExistedSuggestions()) return

    if (suggestions.length > 0) {
      onSave(
        suggestions.map(s => ({
          date: [s.date[0]?.format(), s.date[1]?.format()],
          duration: +duration.id,
        })),
        isDecided
      )
    } else if (currentDate) {
      onSave(
        [
          {
            date: [
              currentDate.format(),
              currentDate.add(+duration.id, "minute").format(),
            ],
            duration: +duration.id,
          },
        ],
        isDecided
      )
    }
  }

  return (
    <YourSuggestions
      isShown={isShown}
      suggestions={suggestions}
      onAddAnotherClick={onAddAnotherClick}
      onDecideClick={() => handleSuggestClick(true)}
      onSuggestClick={() => handleSuggestClick(false)}
      defaultPill={
        <Pill isSelected>
          {duration.id === "1440" ? (
            <>
              <span>{currentDate?.tz(timezone)?.format("ddd, MMM D")}</span>
              <span className="-ml-1 font-normal">· All day</span>
            </>
          ) : (
            <>
              <span>{currentDate?.tz(timezone)?.format("ddd, MMM D")} </span>
              <span>
                · {currentDate?.tz(timezone).format("h:mma")} -{" "}
                {currentDate
                  ?.add(+duration.id, "minute")
                  ?.tz(timezone)
                  .format("h:mma")}
              </span>
            </>
          )}
        </Pill>
      }
      // @ts-ignore
      renderPill={(pill: TimeWheelSuggestion, index) => (
        <Pill
          isSelected={index === currentActiveSuggestionIndex}
          onClick={() => handleSuggestionClick(pill, index)}>
          {pill.duration === 1440 ? (
            <>
              <span>{pill.date[0]?.tz(timezone)?.format("ddd, MMM D")}</span>
              <span className="-ml-1 font-normal">· All day</span>
            </>
          ) : (
            <>
              <span>{pill.date[0]?.tz(timezone)?.format("ddd, MMM D")}</span>
              <span className="-ml-1">
                · {pill.date[0]?.tz(timezone)?.format("h:mma")} -{" "}
                {pill.date[0]
                  ?.add(+pill.duration, "minute")
                  ?.tz(timezone)
                  .format("h:mma")}
              </span>
            </>
          )}

          {suggestions.length > 1 && (
            <SvgIcon name="cross" onClick={() => removeSuggestion(index)} />
          )}
        </Pill>
      )}
    />
  )
}

export default YourSuggestionsContainer
