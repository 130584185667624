import { useMemo } from "react"

import dayjs from "dayjs"
import _ from "lodash"
import { useSelector } from "react-redux"

import { promoteOrDecide } from "@utils/gamePlanUtils"
import { isOrganiser as getIsOrganiser } from "@utils/teeupUtils"

import { EVENTS, logEvent } from "../../../../analytics-tracking"
import {
  markAsDecided,
  pinUnpinSuggestion,
  removeFromGameplan,
  toggleWithdraw,
} from "../../../../middlewares/backendMiddleware"
import { CONTENT_TYPES } from "../constants"
import { selectActiveTeeupSettings } from "@selectors/activeTeeup"

// TODO: methods of these actions are copied from SuggestionPopup component - refactor them.
// TODO: from here - src/components/gamePlansCard/SuggestionPopup/index.js

export const usePopupActions = ({
  currentSuggestion,
  completeCurrentSuggestion,
  setModalPeopleNudge,
  activeTeeup,
  userId,
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
  closeSuggestionPopup,
  setPopupContentType,
  isGeneralSuggestion,
}) => {
  const settings = useSelector(selectActiveTeeupSettings)
  const { allowDecideGameplan, allowChangeGameplanOptions } =
    activeTeeup.settings
  const isDecided = currentSuggestion.selected || currentSuggestion.isSelected
  const isOrganiser = getIsOrganiser(userId, activeTeeup.organisers)
  const isDecidingDisabled =
    (!allowDecideGameplan && !isOrganiser) ||
    !settings?.allowChangeGameplanOptions
  const isPast = dayjs().isAfter(dayjs(currentSuggestion.startDate))

  return useMemo(
    () =>
      [
        // SET AS DECIDED / UNDECIDED ACTION
        {
          isDisabled: isDecidingDisabled,
          isHidden:
            currentSuggestion.isWithdrawn || isGeneralSuggestion || isPast,
          iconName:
            currentSuggestion.selected || currentSuggestion.isSelected
              ? "undecided-suggestion-action"
              : "moveToGameplanNew",
          text:
            currentSuggestion.selected || currentSuggestion.isSelected
              ? `Set "${_.capitalize(currentSuggestion.type)}" as Undecided`
              : "Set as Decided",
          tooltip:
            "Only organizer is able to Set suggestion as Decided/Undecided",
          method: () => {
            if (isDecidingDisabled) return

            // TODO: we need to fix BE for this moment (decided -> isDecided)
            if (currentSuggestion.decided || currentSuggestion.isDecided) {
              removeFromGameplan({
                suggestionId: currentSuggestion?.id,
                activeTeeupStatus: activeTeeup.status,
              })
              markAsDecided(currentSuggestion?.id, false)
              setSuggestionRemovedStatus(currentSuggestion?.id, activeTeeup.id)
              setTimeout(() => {
                removeSuggestionRemovedStatus(
                  currentSuggestion?.id,
                  activeTeeup.id
                )
              }, 15_000)
              // TODO: we need to fix BE for this moment (selected -> isSelected)
            } else if (
              currentSuggestion.selected ||
              currentSuggestion.isSelected
            ) {
              removeFromGameplan({
                suggestionId: currentSuggestion?.id,
              })
              setSuggestionRemovedStatus(currentSuggestion?.id, activeTeeup.id)
              setTimeout(() => {
                removeSuggestionRemovedStatus(
                  currentSuggestion?.id,
                  activeTeeup.id
                )
              }, 15_000)
            } else promoteOrDecide(currentSuggestion, currentSuggestion.type)
            closeSuggestionPopup()
          },
        },
        // ASK OTHERS "DOES THIS WORK FOR YOU?" ACTION
        {
          testID: "askOthersInsideModal",
          iconName: "nudgesNew",
          text: "Ask others “does this work for you?”",
          isHidden: currentSuggestion.isWithdrawn,
          method: () =>
            setModalPeopleNudge(true, currentSuggestion.id, "nudgeWorkForYou"),
        },
        // COMMENT ACTION
        {
          iconName: "comment",
          text: "Reply",
          method: () => {
            setPopupContentType(CONTENT_TYPES.REPLIES)
          },
        },
        // ADD TO PINBOARD/REMOVE FROM PINBOARD ACTION
        {
          iconName: completeCurrentSuggestion?.isPinned
            ? "unpinMessageNew"
            : "pinMessageNew",
          text: completeCurrentSuggestion?.isPinned
            ? "Remove from Pinboard"
            : "Add to Pinboard",
          isHidden: currentSuggestion.isWithdrawn,
          method: () => {
            pinUnpinSuggestion({
              isPinned: !completeCurrentSuggestion?.isPinned,
              suggestionId: currentSuggestion.id,
            })
            const eventName = completeCurrentSuggestion?.isPinned
              ? EVENTS.REMOVE_FROM_PINBOARD
              : EVENTS.ADD_TO_PINBOARD
            logEvent(eventName, {
              teeupId: activeTeeup.id,
              suggestionid: currentSuggestion.id,
            })
          },
        },
        // WITHDRAW/RESTORE SUGGESTION ACTION
        {
          isHidden: isDecided || !allowChangeGameplanOptions,
          iconName: completeCurrentSuggestion?.isWithdrawn
            ? "restoreNew"
            : "trash-2",
          text: completeCurrentSuggestion?.isWithdrawn
            ? "Restore suggestion"
            : "Withdraw",
          method: () => {
            removeFromGameplan({
              suggestionId: completeCurrentSuggestion?.id,
              activeTeeupStatus: activeTeeup.status,
            })
            toggleWithdraw({
              suggestionId: completeCurrentSuggestion?.id,
              isWithdrawn: !completeCurrentSuggestion?.isWithdrawn,
            })
            closeSuggestionPopup()
          },
        },
      ].filter(action => !action.isHidden),
    [currentSuggestion, completeCurrentSuggestion]
  )
}
