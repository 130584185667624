import styled from "styled-components"

export const AuthWrapper = styled.section`
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    padding: 0 20px;
  }
`

export const AuthButtons = styled.div`
  width: 350px;
  display: grid;
  grid-row-gap: 10px;
  padding: 45px 0;
  @media (max-width: 400px) {
    width: 100%;
  }
`

export const SSOButton = styled.button`
  text-wrap: nowrap;
  place-self: center;
  align-items: center;
  width: 226px;
  background-color: #fff;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 12px 8px;
`

export const SSOButtonText = styled.span`
  color: #505050;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  margin-left: 10px;
`

export const SSOButtonIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
`

export const OrLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dadada;
  position: relative;
  margin: 28px 0;
  &::after {
    background-color: #fff;
    content: "or";
    font-family: "greycliffcf-medium-salt";
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
  }
`
