import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../../theme/"

export const WeekCalendarStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: Units.responsiveValue(51),
    shadowOffset: { height: Units.responsiveValue(3) },
    shadowColor: AppColors.brand.black,
    shadowOpacity: 0.08,
    shadowRadius: Units.responsiveValue(3),
    elevation: 2,
    backgroundColor: AppColors.brand.white,
    paddingLeft: Units.responsiveValue(56),
    paddingBottom: Units.responsiveValue(8),
  },
  flex: {
    flex: 1,
  },
  weekdayWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  weekdayContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  weekdayText: {
    ...AppFonts.smallestBold,
    letterSpacing: -Units.responsiveValue(0.1),
    textTransform: "uppercase",
    color: AppColors.brand.black,
  },
  dayText: {
    ...AppFonts.smallestBold,
    letterSpacing: -Units.responsiveValue(0.1),
    color: AppColors.brand.black,
  },
  dayContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: Units.responsiveValue(18),
    height: Units.responsiveValue(18),
    borderRadius: Units.responsiveValue(6),
    marginTop: Units.responsiveValue(4),
  },
  activeDayText: {
    color: AppColors.brand.white,
  },
  activeDayContainer: {
    backgroundColor: AppColors.brand.pink,
  },
  disabledText: {
    color: AppColors.brand.warmGrey2,
  },
  daysSeparatorContainer: {
    width: Units.responsiveValue(1),
  },
  daysSeparator: {
    position: "absolute",
    right: 0,
    top: Units.responsiveValue(30),
    bottom: -Units.responsiveValue(30),
    width: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.grey2,
  },
})

export const WeekCalendarTimetableStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  topPadding: {
    height: Units.responsiveValue(20),
  },
  eventsOverlayContainer: {
    position: "absolute",
    top: Units.responsiveValue(20),
    left: 0,
    right: 0,
    bottom: 0,
  },
  eventsOverlay: {
    flex: 1,
    flexDirection: "row",
    alignItems: "stretch",
    marginLeft: Units.responsiveValue(56),
  },
  dayColumn: {
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: AppColors.brand.lightGrey,
  },
  dayColumnBorder: {
    borderRightColor: AppColors.brand.grey2,
  },
  selectedDateBlock: ({ ROW_HEIGHT, multiplyIndex, totalWidth }) => ({
    width: totalWidth,
    height: ROW_HEIGHT * multiplyIndex,
    left: 0,
    borderWidth: 1,
    borderColor: AppColors.brand.pink,
    backgroundColor: AppColors.brand.pink03,
  }),
  selectedTimeBlockContainer: ({
    position,
    ROW_HEIGHT,
    //  KNOB_HEIGHT
  }) => ({
    position: "absolute",
    top: position * ROW_HEIGHT,
    width: "100%",
  }),
  suggestionDragBox: KNOB_HEIGHT => ({
    height: KNOB_HEIGHT,
    width: "100%",
    backgroundColor: "transparent", //Fix Android view not touchable
  }),
})
