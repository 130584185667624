import { useEffect, useState } from "react"

import { loginProviders } from "@config/enums"
import { changeSyncCalendar } from "@utils/calendarSyncUtils"

export const useSyncLinkedCalendar = ({
  syncEmail,
  googlePrimaryCalendar,
  outlookPrimaryCalendar,
  selectedCalendars = [],
}) => {
  const [syncingType, setSyncingType] = useState(null)

  const syncLinkedCalendar = () => {
    if (syncingType === loginProviders.google && !!googlePrimaryCalendar) {
      changeSyncCalendar(googlePrimaryCalendar.calendarEmailId)
      setSyncingType(null)
    }
    if (
      syncingType === loginProviders.windowslive &&
      !!outlookPrimaryCalendar
    ) {
      changeSyncCalendar(outlookPrimaryCalendar.calendarEmailId)
      setSyncingType(null)
    }
  }

  useEffect(() => {
    if (!!syncEmail || selectedCalendars.length === 0) return

    syncLinkedCalendar()
  }, [
    syncEmail,
    syncingType,
    googlePrimaryCalendar,
    outlookPrimaryCalendar,
    selectedCalendars,
  ])

  return { setSyncingType }
}
