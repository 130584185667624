import styled, { css } from "styled-components"

import { Subtitle, FontWeight } from "@assets/styles/typographyStyles"

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const LabelText = styled(Subtitle)`
  display: block;
  font-weight: ${FontWeight.medium};
  margin-bottom: 7px;
`

export const InputBlock = styled.div`
  ${({ $isDisabled }) => css`
    width: 100%;
    position: relative;
    ${$isDisabled &&
    css`
      opacity: 0.5;
    `};
  `};
`

export const Select = styled.div`
  ${({ $isDisabled, $isError, $isOpenedList }) => css`
    width: 100%;
    height: 46px;
    background-color: var(--white);
    border: 1px solid var(--lightGray5);
    border-radius: 84px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: ${FontWeight.medium};
    padding: 12px 35px 12px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    ${$isDisabled &&
    css`
      cursor: default;
    `};
    ${$isError &&
    css`
      border-color: var(--mainRed);
    `};
    ${$isOpenedList &&
    css`
      border-color: var(--black);
      box-shadow: var(--blackShadow3);
    `};
  `};
`

export const Arrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%) rotate(90deg);
`

export const List = styled.ul`
  max-height: 150px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 14px rgba(19, 10, 46, 0.13);
  border-radius: 8px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  list-style: none;
  z-index: 10;
`

export const ListItem = styled.li`
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 20px;

  &:not(:first-child) {
    border-top: 1px solid var(--lightGray5);
  }
  &:hover {
    background-color: var(--lightGray2);
  }
`

export const ListItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 7px;
`

export const ListItemText = styled(Subtitle)`
  display: block;
  font-weight: ${FontWeight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SelectIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

export const SelectTitle = styled(Subtitle)`
  ${({ isSelected }) =>
    !isSelected &&
    css`
      color: var(--gray);
    `};
`

export const NotFoundText = styled(Subtitle)`
  color: var(--gray);
  display: block;
  margin: 15px 0 15px 15px;
`

export const SearchInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -90;
`
