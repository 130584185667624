import { GAMEPLAN_TYPES } from "@constants/gameplan"

import { GameplanBlock } from "./molecules"
import { Wrapper } from "./newTeeupGameplanStyles"

export const NewTeeupGameplan = () => (
  <Wrapper>
    <GameplanBlock type={GAMEPLAN_TYPES.WHEN} />
    <GameplanBlock type={GAMEPLAN_TYPES.WHERE} />
  </Wrapper>
)
