import React, { useState, useMemo } from "react"

import Modal from "@components/Modal"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import { SuggestModalHeader, SuggestModalWrapper } from "../components"

import InPersonTab from "./InPersonTab"
import OnlineTab from "./OnlineTab"

const SUGGEST_WHERE_TABS = [
  { id: "online", name: "Online" },
  { id: "inPerson", name: "In Person" },
]

const SuggestWhereModal = ({ onSave, closeModal, teeupId }) => {
  const [activeTab, setActiveTab] = useState(SUGGEST_WHERE_TABS[0].id)

  const { closeSuggestWhereModal } = useTeeUpPage()

  const handleTabClick = tabId => {
    setActiveTab(tabId)
  }

  const handleClose = () => {
    if (closeModal) {
      closeModal()
    } else closeSuggestWhereModal()
  }

  const content = useMemo(() => {
    switch (activeTab) {
      case "inPerson":
        return (
          <InPersonTab
            onSave={onSave}
            handleClose={handleClose}
            teeupId={teeupId}
          />
        )
      case "online":
      default:
        return <OnlineTab />
    }
  }, [activeTab])

  return (
    <Modal>
      <SuggestModalWrapper>
        <SuggestModalHeader
          title="Suggest Where"
          tabs={SUGGEST_WHERE_TABS}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          handleClose={handleClose}></SuggestModalHeader>
        {content}
      </SuggestModalWrapper>
    </Modal>
  )
}

export default SuggestWhereModal
