import React, { useContext, useMemo } from "react"

import { ThreeDots } from "react-loader-spinner"

import AppleLogo from "@assets/images/apple.svg"
import GoogleLogo from "@assets/images/google.svg"
import { Subtitle, FontWeight } from "@assets/styles/typographyStyles"
import { ButtonEl } from "@components/common"
import { loginProviders, loginProvidersText } from "@config/enums"
import { BUTTON_VARIANTS } from "@constants/button"
import {
  SyncContactsContext,
  SYNC_CONTACTS_MODAL,
} from "@contexts/SyncContactsContext"

import {
  ContactsConnectorItem,
  ConnectorProvider,
  ConnectorIcon,
  RemoveBtn,
  LeftSide,
} from "./contactsStyles"

export const ConnectorItem = ({
  connector,
  connectProvider,
  isFirst,
  isLast,
}) => {
  const {
    isDeletingConnector,
    setConnectorType,
    setModalType,
    setConnectorEmail,
    setConnectorId,
    handleSyncGoogleContacts,
  } = useContext(SyncContactsContext)

  const provider = useMemo(() => {
    switch (connector?.provider || connectProvider) {
      case loginProviders.google:
        return {
          icon: GoogleLogo,
          name: loginProvidersText.google,
        }
      case loginProviders.apple:
        return {
          icon: AppleLogo,
          name: loginProvidersText.apple,
        }
      default:
        return null
    }
  }, [])

  const handleRemove = () => {
    if (isDeletingConnector) return
    setConnectorId(connector?.id)
    setConnectorType(loginProvidersText[connector?.provider])
    setConnectorEmail(connector?.email)
    setModalType(SYNC_CONTACTS_MODAL.CONFIRM_REMOVING)
  }

  return (
    <ContactsConnectorItem isLast={isLast}>
      <LeftSide>
        {!!provider && (
          <ConnectorProvider>
            <ConnectorIcon src={provider.icon} alt={provider.name} />

            <Subtitle>{provider.name}</Subtitle>
          </ConnectorProvider>
        )}

        <Subtitle
          style={{
            color: "var(--gray)",
            fontWeight: FontWeight.medium,
            marginTop: "-4px",
          }}>
          {connector?.email || "connect account to sync contacts"}
        </Subtitle>
      </LeftSide>

      {connector ? (
        <RemoveBtn isFirst={isFirst} onClick={handleRemove}>
          {isDeletingConnector ? (
            <ThreeDots color="#595f82" height={10} width={70} />
          ) : (
            "Remove"
          )}
        </RemoveBtn>
      ) : (
        <ButtonEl
          isIconFirst
          autoWidth
          padding="10px 15px"
          backgroundColor="var(--lightGray4)"
          border="1px solid var(--gray3)"
          textColor="var(--black3)"
          loadingColor="var(--lightGray3)"
          variant={BUTTON_VARIANTS.ROUNDED}
          text="Continue with Google"
          iconName="google"
          onClick={handleSyncGoogleContacts}
        />
      )}
    </ContactsConnectorItem>
  )
}
