import styled from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"

export const SwitchButton = styled.button`
  width: 100%;
  background-color: var(--lightGray4);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${NFontWeight.bold};
  text-decoration: underline;
  padding: 10px;

  &:active {
    opacity: 0.4;
  }
`
