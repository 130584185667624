import React, { useEffect, useRef, useState } from "react"

import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { useHistory } from "react-router-dom"

import { updateTeeupArchiveStatus } from "@actions/teeupActions"
import changeNameIcon from "@assets/images/newDesign/icons/ndChangeTeeUpName.svg"
import { teeupStatusKeys } from "@config/enums"
import { getRandomBase64Image } from "@utils/imageUtils"
import { reloadPage } from "@utils/reloadPage"
import {
  updateTeeup,
  dropoutTeeup,
  updateTeeupStatus,
} from "middlewares/backendMiddleware"
import { useTeeUpPage } from "pages/TeeUpPage/TeeUpPageContext"

import ConfirmAction from "./ConfirmAction"
import TeeupSettingsRow from "./TeeupSettingsRow"
import { ImageButtons } from "./molecules"
import { leftSideData } from "./teeupSettingsData"

import "./index.scss"

const TeeupSettingsLeftSide = ({ isOrganizer, activeTeeupSettings }) => {
  const { teeup, user } = useTeeUpPage()

  const [name, setName] = useState(teeup.name)
  const [image, setImage] = useState(teeup.photo)

  const [isInputDisabled, setIsInputDisabled] = useState(true)
  const [showInputButtons, setShowInputButtons] = useState(false)
  const [isMutedTeeUp, setIsMutedTeeUp] = useState(false)
  const [isImageChanged, setIsImageChanged] = useState(false)
  const [isConfirmWindowOpen, setIsConfirmWindowOpen] = useState(false)
  const [teeUpActionType, setTeeUpActionType] = useState(0)

  const inputRef = useRef(null)
  const history = useHistory()

  useEffect(() => {
    if (isImageChanged) {
      updateTeeup({
        teeUpId: teeup.id,
        teeup: { photo: image },
      })
      setIsImageChanged(false)
    }
  }, [isImageChanged])

  const allowChangeInput = () => {
    setIsInputDisabled(false)
    setTimeout(() => inputRef.current.focus(), 200)
  }

  const handleChangeInput = e => {
    setName(e.target.value)
    setShowInputButtons(true)
  }

  const handleChangeMute = () => {
    setIsMutedTeeUp(!isMutedTeeUp)
  }

  const handleUpdateName = () => {
    updateTeeup({
      teeUpId: teeup.id,
      teeup: { name },
    })
  }

  const removeImage = async () => {
    const newImage = await getRandomBase64Image("gray")
    setImage(newImage)
    setIsImageChanged(true)
  }

  const handleTeeUpActions = () => {
    if (teeUpActionType === 1) {
      dropoutTeeup({ teeup: teeup, userId: user.id })
      document.title = "CooWe Coordinator"
      history.push("/inbox")
      reloadPage()
    } else {
      updateTeeupArchiveStatus(teeup.id)
      updateTeeupStatus(user.id, teeupStatusKeys.cancelled)
    }
  }

  const filteredData = leftSideData.filter(
    item => (item.alt === "cancel" && isOrganizer) || item.alt !== "cancel"
  )

  const isTitleChanging =
    activeTeeupSettings.allowChangeTitle ||
    (!activeTeeupSettings.allowChangeTitle && isOrganizer)

  return (
    <div className="teeup-settings__container">
      <div
        className="teeup-settings__image"
        style={{ backgroundImage: `url(${image})` }}>
        {isOrganizer && (
          <ImageButtons
            setImage={setImage}
            setIsImageChanged={setIsImageChanged}
            removeImage={removeImage}
          />
        )}
      </div>

      <div className="teeup-settings__name">
        <input
          data-testid="titleInput"
          className="teeup-settings__input"
          type="text"
          value={name}
          ref={inputRef}
          onChange={e => handleChangeInput(e)}
          onClick={e => e.stopPropagation()}
          placeholder={name}
          disabled={isInputDisabled}
        />
        {showInputButtons && (
          <div className="teeup-settings__name--buttons">
            <button
              data-testid="submitTitle"
              className="teeup-settings__name--button"
              type="button"
              onClick={() => {
                handleUpdateName()
                setIsInputDisabled(true)
                setShowInputButtons(false)
              }}
            />
            <button
              className="teeup-settings__name--button"
              type="button"
              onClick={() => {
                setName(teeup.name)
                setIsInputDisabled(true)
                setShowInputButtons(false)
              }}
            />
          </div>
        )}

        {!showInputButtons && isTitleChanging && (
          <button
            data-testid="editTitleButton"
            className="teeup-settings__change-name"
            type="button"
            onClick={() => allowChangeInput()}>
            <img src={changeNameIcon} />
          </button>
        )}
      </div>

      <div className="teeup-settings__buttons">
        {filteredData.map((item, index) => (
          <TeeupSettingsRow
            index={index}
            item={item}
            onChange={() => {
              if (index === 0) {
                handleChangeMute()
              } else {
                setTeeUpActionType(index)
                setIsConfirmWindowOpen(true)
              }
            }}
            isMutedTeeUp={isMutedTeeUp}
            toggle={index === 0 ? true : false}
            key={index}
          />
        ))}
      </div>
      {isConfirmWindowOpen &&
        ReactDOM.createPortal(
          <ConfirmAction
            type={teeUpActionType}
            close={() => setIsConfirmWindowOpen(false)}
            onClick={() => handleTeeUpActions()}
          />,
          document.querySelector("#modal-root")
        )}
    </div>
  )
}

TeeupSettingsLeftSide.propTypes = {
  isOrganizer: PropTypes.bool,
  activeTeeupSettings: PropTypes.object,
}

export default TeeupSettingsLeftSide
