import React, { useContext, useEffect } from "react"

import { connect } from "react-redux"

import { getContactsConnectors } from "@actions/contactActions"
import { NewModal } from "@components"
import { ButtonEl } from "@components/common"
import { loginProviders } from "@config/enums"
import { BUTTON_VARIANTS } from "@constants/button"
import {
  SyncContactsContext,
  SYNC_CONTACTS_MODAL,
} from "@contexts/SyncContactsContext"

import { ConnectorItem } from "./ConnectorItem"
import { ModalsBody } from "./ModalsBody"
import { ContactsWrapper } from "./contactsStyles"

const Contacts = ({ contactsConnectors }) => {
  useEffect(() => {
    if (contactsConnectors.length === 0) {
      getContactsConnectors()
    }
  }, [contactsConnectors])

  const { contactsModalData, setModalType } = useContext(SyncContactsContext)

  return (
    <ContactsWrapper>
      {contactsConnectors.length > 0 ? (
        contactsConnectors.map((connector, connectorIndex) => (
          <ConnectorItem
            connector={connector}
            isFirst={connectorIndex === 0}
            isLast={contactsConnectors.length - 1 === connectorIndex}
            key={connector.id}
          />
        ))
      ) : (
        <ConnectorItem
          connectProvider={loginProviders.google}
          isFirst={true}
          isLast={true}
        />
      )}

      <ButtonEl
        variant={BUTTON_VARIANTS.BORDERED}
        text="Add another"
        onClick={() => setModalType(SYNC_CONTACTS_MODAL.ADD_ANOTHER)}
      />

      <NewModal {...contactsModalData}>
        <ModalsBody />
      </NewModal>
    </ContactsWrapper>
  )
}

const mapStateToProps = ({ contacts }) => ({
  contactsConnectors: contacts.syncConnectors,
})

export default connect(mapStateToProps)(Contacts)
