import React from "react"

import {
  BOX_SIZE,
  HOURS_BEFORE_CURRENT_HOUR,
  PROPORTION_BOX_SIZE,
} from "utils/suggestionUtils"

import "./index.scss"

const CurrentTimePin = () => {
  const currentMinute = new Date().getMinutes()
  const leftOffset =
    currentMinute * PROPORTION_BOX_SIZE + BOX_SIZE * HOURS_BEFORE_CURRENT_HOUR

  return (
    <div className="current-time-pin-container" style={{ left: leftOffset }}>
      <div></div>
      <div></div>
    </div>
  )
}

export default CurrentTimePin
