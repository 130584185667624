import { useMemo } from "react"

import moment from "moment-timezone"

export const useModifiedEvents = mappedEventsList => {
  return useMemo(() => {
    return mappedEventsList.reduce((acc, current) => {
      const updatedCurrent = {
        ...current,
        innerEvents: [],
      }

      mappedEventsList.forEach(event => {
        const range1 = moment.range(current.startDate, current.endDate)
        const range2 = moment.range(event.startDate, event.endDate)

        if (range1.overlaps(range2)) {
          updatedCurrent.innerEvents.push(event)
        }
      })

      acc.push(updatedCurrent)

      return acc
    }, [])
  }, [mappedEventsList])
}
