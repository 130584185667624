import React from "react"

import PropTypes from "prop-types"

import { images } from "@utils/imageUtils"

const Tag = ({ data, isSelected, isLast }) => (
  <div className="tagNd__wrapper">
    <img className="tagNd__image" alt="tag" src={images.tag} />
    <p className="tagNd">{isLast ? data.value : `${data.value},`}</p>
  </div>
)

Tag.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
}
export default Tag
