import { GAMEPLAN_TYPES } from "@constants/gameplan"

export const useChatSuggestionLabel = (suggestion, isPast) => {
  let result = {}
  const { type, selected, isWithdrawn } = suggestion

  if (isWithdrawn) {
    return {
      iconName: "cancel-cross",
      text: "Withdrawn",
    }
  }

  result =
    type === GAMEPLAN_TYPES.when
      ? {
          iconName: selected ? "checked-mark-wait" : "clock-2",
          text: isPast ? "Past" : `When`,
        }
      : {
          iconName: selected ? "checked-mark-wait" : "location-2",
          text: `Where`,
        }

  return result
}
