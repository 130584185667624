import { useState } from "react"

import * as RNLocalize from "react-native-localize"

import api from "../../../api"
import { API_ERRORS_MESSAGES, REGISTER_STEPS } from "../constants"

export const useRegisterEmail = ({
  deviceId,
  setUserInfo,
  setContactMechanismVerificationId,
  setStep,
  addApiError,
  removeApiError,
}) => {
  const [isRegisterEmailLoading, setIsRegisterEmailLoading] = useState(false)

  const registerEmail = email => {
    setIsRegisterEmailLoading(true)

    api.client
      .post("/users", {
        deviceId,
        email,
        timezone: RNLocalize.getTimeZone(),
      })
      .then(response => {
        setUserInfo(prevState => ({
          ...prevState,
          ...response.data.user,
          email,
        }))
        setContactMechanismVerificationId(response.data.contactMechanism.id)
        setStep(REGISTER_STEPS.SIGN_UP_CODE)
        setIsRegisterEmailLoading(false)
        removeApiError("email")
      })
      .catch(error => {
        if (error.status === 409) {
          addApiError("email", API_ERRORS_MESSAGES.EMAIL_IS_USING)
        } else {
          addApiError("email", API_ERRORS_MESSAGES.SOMETHING_WENT_WRONG)
        }
        setIsRegisterEmailLoading(false)
      })
  }

  return { isRegisterEmailLoading, registerEmail }
}
