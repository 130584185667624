import { useEffect, useMemo } from "react"

import { useSelector } from "react-redux"

import { teeupUserStatusKeys } from "@configs/enums"
import { selectUserId } from "@selectors/activeTeeup"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserTimezone } from "@selectors/user"
import Collapse from "@ui/Collapse"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import Content from "./Content"
import SubTitleContent from "./SubTitleContent"
import TitleContent from "./TitleContent"

const attendanceStatuses = new Set([
  teeupUserStatusKeys.going,
  teeupUserStatusKeys.onmyway,
  teeupUserStatusKeys.runningLate,
  teeupUserStatusKeys.arrived,
])

const TeeupPeople = ({ onClick }) => {
  const isCollapsed = useTeeupPeopleStore(state => state.isCollapsed)

  const clearStore = useTeeupPeopleStore(state => state.clear)
  const people = Object.values(useSelector(selectTeeupPeople))
  const userId = useSelector(selectUserId)
  const timezone = useSelector(selectUserTimezone)

  const { peopleAttending, isMultiTimezone, isOrganizer } = useMemo(() => {
    const peopleAttending = people.reduce((accumulatedCount, item) => {
      return attendanceStatuses.has(item.status)
        ? accumulatedCount + 1
        : accumulatedCount
    }, 0)

    const isMultiTimezone = people?.some(
      (item, _, self) => self[0].timezone !== item.timezone
    )

    const isOrganizer = people.some(
      item => item.isOrganiser && item.id === userId
    )

    return { peopleAttending, isMultiTimezone, isOrganizer }
  }, [people, timezone])

  useEffect(() => {
    return () => {
      clearStore()
    }
  }, [])

  const handlePeopleClick = () => {
    onClick && onClick()
  }

  return (
    <Collapse
      isParent
      isCollapsed={isCollapsed}
      setIsCollapsed={handlePeopleClick}
      title="People"
      titleContent={
        <TitleContent
          total={people.length}
          attending={peopleAttending}
          isMultiTimezone={isMultiTimezone}
        />
      }
      subTitleContent={
        <SubTitleContent
          people={people}
          isCollapsed={isCollapsed}
          isOrganizer={isOrganizer}
        />
      }>
      <Content people={people} isMultiTimezone={isMultiTimezone} />
    </Collapse>
  )
}

export default TeeupPeople
