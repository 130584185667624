import { EMAIL_VALIDATION_REGEX } from "../../../constants/global"

const checkValid = {
  isValidURL(url) {
    const urlPattern =
      /^(http(s):\/\/.)[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)$/
    const dotPattern = /^(?!.*\.{2})/

    return dotPattern.test(url) && urlPattern.test(url)
  },

  isValidDisplayName(displayName) {
    const invalidNames =
      /^(unknown|admin|organizer|organizers|administrator|removed|deleted)$/i
    const symbolPattern = /^(?!.*\.\..*)[\dA-Za-z][\d.A-Za-z]*[\dA-Za-z]$/

    return !invalidNames.test(displayName) && symbolPattern.test(displayName)
  },

  isValidEmail(email) {
    const emailPattern = EMAIL_VALIDATION_REGEX

    return emailPattern.test(email)
  },

  isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\+\d{2,}$/

    return phonePattern.test(phoneNumber)
  },
}

export default checkValid
