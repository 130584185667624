import React, { useEffect } from "react"

import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import { selectCreateTeeupScreenType } from "@selectors/createTeeUp"
import { resetSelectedContacts } from "actions/inviteesActions"

import CreateTeeUp from "../../pages/CreateTeeUpNd"

import { Background, Wrapper } from "./createTeeupStyles"
import { Header } from "./molecules"

const CreateTeeupModal = ({
  screenType,
  setCreateTeeupScreenType,
  onClose,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.MAIN)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetSelectedContacts())
    }
  }, [])

  return (
    <Background>
      <Wrapper $isMain={screenType === CREATE_TEEUP_SCREENS.MAIN}>
        <Header onClose={onClose} />

        <CreateTeeUp />
      </Wrapper>
    </Background>
  )
}

CreateTeeupModal.propTypes = {
  onClose: PropTypes.func,
}

const mapStateToProps = state => ({
  screenType: selectCreateTeeupScreenType(state),
})

const mapDispatchToProps = dispatch => ({
  setCreateTeeupScreenType: payload =>
    dispatch(setCreateTeeupScreenType(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeeupModal)
