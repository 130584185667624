import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"

import { OptionItem } from "./atoms"

export const OptionsModal = ({ title, description, options, onClose }) => {
  return (
    <DefaultModal open onClose={onClose}>
      <div className="flex w-full max-w-[420px] flex-col">
        <Header title={title} subtitle={description} />

        <div className="mx-[-16px]" onClick={e => e.stopPropagation()}>
          {options.map((option, optionIndex) => (
            <OptionItem option={option} key={optionIndex} />
          ))}
        </div>
      </div>
    </DefaultModal>
  )
}
