import React, { useState } from "react"

import { connect, useSelector } from "react-redux"

import "./index.scss"

import { TabContext, TabPanel } from "@mui/lab"
import { Box } from "@mui/material"
import OutsideClickHandler from "react-outside-click-handler"

import {
  setReplyMessage,
  setModalPeopleNudge,
  hideSuggestionListFooter,
} from "@actions/commonActions"
import {
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
} from "@actions/teeupActions"
import Header from "@components/ui/DefaultModal/Header"
import { Tab, TabList } from "@components/ui/Tabs"
import {
  selectMessageById,
  selectActiveTeeupId,
  selectActiveTeeupGameplans,
} from "@selectors/activeTeeup"
import { selectTeeupGameplans } from "@selectors/teeups"
import { locationTypeIds } from "@utils/gamePlanUtils"
import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"

import { store } from "../../../index"

import { CONTENT_TYPES } from "./constants"
import { PopupActions } from "./molecules"
import MeetingDetails from "./molecules/MeetingDetails"
import OtherSuggestions from "./molecules/OtherSuggestions"
import Reactions from "./molecules/Reactions"
import Replies from "./molecules/Replies"
import SuggestionItem from "./molecules/SuggestionItem"
import Timezones from "./molecules/Timezones"
import {
  PopupContentWrapper,
  SuggestionContentWrapper,
  SuggestionWrapper,
} from "./newSuggestionPopupStyles"

const SuggestionPopup = ({
  currentSuggestion,
  toggleSuggestionPopup,
  setCurrentSuggestion,
  currentSuggestionData,
  // people,
  // renderLines,
  // renderRightHeaderPart,
  // setModalPeopleNudge,
  // setReplyMessage,
  // setSuggestionRemovedStatus,
  // removeSuggestionRemovedStatus,
  // onAddSuggestion,
  handleOpenSuggestion,
  // hideSuggestionListFooter,
  // draggableSuggestionRow,
  // initialSuggestions,
}) => {
  const [popupContentType, setPopupContentType] = useState(
    CONTENT_TYPES.DEFAULT
  )
  const isWhenSuggestion = currentSuggestionData.type === "when"
  const defaultTabLabel = isWhenSuggestion ? "Date details" : "Meeting details"
  const currentGamePlan = useSelector(selectActiveTeeupGameplans).find(
    gamePlan => gamePlan.type === currentSuggestionData.type
  )
  const meetingDetails = isWhenSuggestion
    ? getWhenSuggestionDetails(currentSuggestionData)
    : getWhenSuggestionDetails(currentSuggestionData)
  console.log("meetingDetails", meetingDetails)
  const isGeneralSuggestion =
    (!meetingDetails?.empty && isWhenSuggestion) ||
    currentSuggestion.isCustomDate
  const isTimeWheelSuggestion =
    meetingDetails?.empty && isWhenSuggestion && !currentSuggestion.isCustomDate

  const closeSuggestionPopup = () => {
    toggleSuggestionPopup(false)
    setCurrentSuggestion && setCurrentSuggestion({})
  }

  const isMap =
    currentSuggestion.type === "where" &&
    currentSuggestion?.locationType === locationTypeIds.realWorld

  return (
    <div
      className="suggestion__popup--wrapper"
      onClick={e => e.stopPropagation()}>
      <OutsideClickHandler onOutsideClick={closeSuggestionPopup}>
        <PopupContentWrapper>
          <Header
            title="Suggestion details"
            onBackClick={closeSuggestionPopup}
          />

          <SuggestionContentWrapper
            $isDefault={popupContentType === CONTENT_TYPES.DEFAULT}>
            <SuggestionWrapper>
              <SuggestionItem
                suggestion={currentSuggestion}
                title={meetingDetails.title}
                subtitle={meetingDetails.subtitle}
              />
            </SuggestionWrapper>

            <PopupActions
              currentSuggestion={currentSuggestion}
              toggleSuggestionPopup={toggleSuggestionPopup}
              setCurrentSuggestion={setCurrentSuggestion}
              handleOpenSuggestion={handleOpenSuggestion}
              setPopupContentType={setPopupContentType}
              isGeneralSuggestion={isGeneralSuggestion}
            />
          </SuggestionContentWrapper>

          <TabContext value={popupContentType}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(e, newValue) => setPopupContentType(newValue)}>
                <Tab label={defaultTabLabel} value={CONTENT_TYPES.DEFAULT} />
                {isTimeWheelSuggestion && (
                  <Tab label="Timezones" value={CONTENT_TYPES.TIMEZONES} />
                )}
                <Tab label="Replies" value={CONTENT_TYPES.REPLIES} />
                <Tab label="Reactions" value={CONTENT_TYPES.REACTIONS} />
                <Tab
                  label="Other suggestions"
                  value={CONTENT_TYPES.SUGGESTIONS}
                />
              </TabList>
            </Box>

            <TabPanel value={CONTENT_TYPES.DEFAULT}>
              <MeetingDetails
                suggestion={currentSuggestion}
                title={meetingDetails.title}
                subtitle={meetingDetails.subtitle}
                isMap={isMap}
                isWhenSuggestion={isWhenSuggestion}
              />
            </TabPanel>
            {isTimeWheelSuggestion && (
              <TabPanel value={CONTENT_TYPES.TIMEZONES}>
                <Timezones />
              </TabPanel>
            )}
            <TabPanel value={CONTENT_TYPES.REACTIONS}>
              <Reactions suggestion={currentSuggestion} />
            </TabPanel>
            <TabPanel value={CONTENT_TYPES.REPLIES}>
              <Replies />
            </TabPanel>
            <TabPanel value={CONTENT_TYPES.SUGGESTIONS}>
              <OtherSuggestions suggestions={currentGamePlan?.suggestions} />
            </TabPanel>
          </TabContext>
        </PopupContentWrapper>
      </OutsideClickHandler>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    initialSuggestions: state.activeTeeup.messages.find(
      message => message.suggestionIds
    )?.gameplans,
    gameplans: selectTeeupGameplans(state),
    activeTeeupId: selectActiveTeeupId(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setModalPeopleNudge: (value, id, typeId) =>
      dispatch(setModalPeopleNudge(value, id, typeId)),
    setReplyMessage: message => dispatch(setReplyMessage(message)),
    setSuggestionRemovedStatus: (id, activeTeeupId) =>
      dispatch(setSuggestionRemovedStatus(id, activeTeeupId)),
    removeSuggestionRemovedStatus: (id, activeTeeupId) =>
      dispatch(removeSuggestionRemovedStatus(id, activeTeeupId)),
    hideSuggestionListFooter: () => dispatch(hideSuggestionListFooter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionPopup)
