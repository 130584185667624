import styled from "styled-components"

export const Input = styled.input`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
`
