import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../../theme/"

export const CalendarComponentStyles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    height: Units.responsiveValue(50),
  },
  timeContainer: {
    width: Units.responsiveValue(56),
  },
  time: {
    position: "absolute",
    top: -Units.responsiveValue(8),
    right: Units.responsiveValue(14),
    color: AppColors.brand.warmGrey2,
    ...AppFonts.smallishDemibold,
    letterSpacing: -Units.responsiveValue(0.2),
  },
  rowEventsContainer: {
    flex: 1,
    marginRight: Units.responsiveValue(12),
    borderTopColor: AppColors.brand.grey2,
    borderTopWidth: 1,
  },
  currentTimeContainer: {
    position: "absolute",
    left: -3,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentTimeDot: {
    borderColor: AppColors.brand.pink,
    borderWidth: 3,
    borderRadius: 3,
  },
  currentTimeLine: {
    height: 1,
    flex: 1,
    backgroundColor: AppColors.brand.pink,
  },
})
