import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.section`
  ${({ $isBottomPadding }) => css`
    width: 650px;
    border: 1px solid var(--gray3);
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 20px auto 0 auto;
    overflow-y: auto;

    @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
      width: 100%;
      margin: 0;
      ${$isBottomPadding &&
      css`
        padding-bottom: 140px;
      `};
    }
  `};
`

export const SelectImageWrapper = styled.div`
  background-color: var(--white);
`

export const BlockWrapper = styled.div`
  background-color: var(--white);
  border-top: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);
  box-sizing: border-box;
  display: flex;
  column-gap: 20px;
  padding: 24px 16px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding: 10px;
  }
`

export const Description = styled(NSubtitleS)`
  color: var(--gray4);
  margin: 10px 0 10px 20px;
`
