import React from "react"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import { SvgIcon } from "@components/common"
import { useInboxStore } from "stores/inboxStore"

interface Props {
  title: string
  subtitle?: string
  icon: string
  hasBadge?: boolean
  children: React.ReactNode
}

const accordionStyles = {
  ".MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}

const summuryStyles = {
  ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0 !important",
  },
}

const Row = ({ title, subtitle, icon, hasBadge, children }: Props) => {
  const currentOpenFilter = useInboxStore(state => state.currentOpenFilter)
  const setCurrentOpenFilter = useInboxStore(
    state => state.setCurrentOpenFilter
  )

  const isOpen = currentOpenFilter === title

  return (
    <>
      <Accordion
        expanded={isOpen}
        onChange={() => setCurrentOpenFilter(title)}
        className="!m-0 !shadow-none [&:before]:!opacity-100"
        sx={accordionStyles}>
        <AccordionSummary
          expandIcon={<SvgIcon name="arrow" />}
          sx={summuryStyles}>
          <div className="flex items-center justify-between">
            <span className="flex items-center gap-2 font-['Inter'] !text-sm !text-neutral-700">
              <SvgIcon name={icon} className="!size-[19px]" />
              <span>
                {title}{" "}
                {subtitle && <span className="font-semibold">{subtitle}</span>}
              </span>
              {hasBadge && (
                <div className="size-2 rounded-full border border-blue-600 bg-blue-600"></div>
              )}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="bg-neutral-100 !p-0">
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Row
