import React, { useContext } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { confirmAlert } from "react-confirm-alert"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { logOut } from "@actions/loginActions"
import { loginProviders } from "@config/enums"
import { selectUserInfo } from "@selectors/user"
import { images } from "@utils/imageUtils"
import { SideBarContext } from "@utils/sideBarContext"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

import { NavItem } from "./molecules"
import { ListItem } from "./sidebarStyles"

import "./user-info-popover.scss"

const menuItems = [
  {
    name: "Profile",
    icon: "user-circle",
    pathname: "/my-profile",
    className: "user-info-popover__menu-icon",
  },
  {
    name: "Settings",
    icon: "menu-settings",
    className: "user-info-popover__menu-icon",
    pathname: "/settings",
  },
]

const UserInfoPopover = ({ onPopoverClose }) => {
  const { logout } = useAuth0()
  const { handleToggleSidebar } = useContext(SideBarContext)
  const userInfo = useSelector(selectUserInfo)
  const history = useHistory()
  const dispatch = useDispatch()

  const redirectTo = nextPath => {
    history.push(nextPath)
    onPopoverClose()
    if (document.body.offsetWidth <= Number.parseInt(MEDIA_SIZES.MOBILE)) {
      handleToggleSidebar(false)
    }
  }

  const onLogout = () => {
    const { registrationType, loginType } = userInfo

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="react-confirm-alert-body"
            style={{ transform: "translateY(-50%)" }}>
            <img
              src={images.closeSettings}
              className="settings__modal__closeIcon"
              alt="close"
              onClick={onClose}
            />
            <h1>Logout</h1>
            Are you sure you want to logout?
            <div className="react-confirm-alert-button-group">
              <button onClick={onClose}>Cancel</button>
              <button
                onClick={() => {
                  onPopoverClose()

                  if (
                    (registrationType &&
                      registrationType !== loginProviders.email) ||
                    (loginType && loginType !== loginProviders.email)
                  ) {
                    dispatch(logOut(logout))
                  } else dispatch(logOut())
                  onClose()
                }}>
                Logout
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <div>
      <div className="user-info-popover">
        <div className="user-info-popover__avatar">
          <img src={userInfo.avatar} alt="avatar" />
        </div>
        <div className="user-info-popover__info">
          <p className="user-info-popover__name">{userInfo.name}</p>
          <p className="user-info-popover__nickname">@{userInfo.nickname}</p>
        </div>
      </div>

      {menuItems.map(item => (
        <ListItem
          $isClickable
          $isWrapped={false}
          key={item.pathname}
          onClick={() => redirectTo(item.pathname)}>
          <NavItem staticElement isActive={false} item={item} />
        </ListItem>
      ))}

      <div className="user-info-popover__logout" onClick={onLogout}>
        <p className="user-info-popover__logout_action">Log out</p>
      </div>
    </div>
  )
}

export default UserInfoPopover
