import React, { useContext, useMemo, useState } from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  linkGuestCalendar,
  setIsGuestConnectionError,
  unlinkGuestCalendar,
} from "@actions/calendarActions"
import { ButtonEl, ErrorModal, CloseButton, SvgIcon } from "@components/common"
import { ToggleButton } from "@components/ui/settingsClickableRow/actionButtons"
import { BUTTON_VARIANTS, BUTTON_SIZES } from "@constants/button"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"
import {
  selectGuestLinkedCalendar,
  selectIsGuestConnectionError,
} from "@selectors/calendar"
import {
  selectGuestSuggestions,
  selectNewGuestSuggestion,
} from "@selectors/guest"
import { OptionsModal } from "components"

import { useListActions, useCalendarsList } from "../hooks"
import { UnlinkCalendarPopup } from "../molecules"

import { Wrapper, UnlinkButtonWrapper } from "./suggestionsListActionsStyles"

const SuggestionsListActions = ({
  actions,
  isFullList,
  linkedCalendar,
  linkGuestCalendar,
  isConnectionError,
  setIsGuestConnectionError,
  unlinkGuestCalendar,
  newGuestSuggestion,
  guestSuggestions,
}) => {
  const {
    stepsManager: { setIsMakeSuggestion },
  } = useContext(TeeupPreviewContext)
  const [isMyAvailability, setIsMyAvailability] = useState(false)
  const [isUnlinkCalendar, setIsUnlinkCalendar] = useState(false)
  const buttons = useListActions({
    linkedCalendar,
    setIsMyAvailability,
    setIsUnlinkCalendar,
    setIsMakeSuggestion,
  })
  const calendarsList = useCalendarsList({
    isFullList,
    linkGuestCalendar,
    guestSuggestions,
    newGuestSuggestion,
    closeModal: () => setIsMyAvailability(false),
  })

  const closeErrorModal = () => {
    setIsGuestConnectionError(false)
  }

  const tryAgain = () => {
    closeErrorModal()
    setIsMyAvailability(true)
  }

  const closeUnlinkPopup = () => {
    setIsUnlinkCalendar(false)
  }

  const unlinkCalendarHandle = () => {
    unlinkGuestCalendar()
    setIsUnlinkCalendar(false)
  }

  const filteredButtons = useMemo(() => {
    if (!actions || actions.length === 0) return buttons

    return buttons.filter(button => actions.includes(button.id))
  }, [buttons])

  return (
    <>
      {isMyAvailability && (
        <OptionsModal
          title="Overlay my calendar"
          description={
            <div className="whitespace-wrap mt-2 max-w-[420px] text-sm font-semibold text-neutral-500">
              View your calendar items and detect scheduling conflicts.
              <div className="mt-4">
                Calendar information is only viewable by you and is not shared
                with anyone.
              </div>
            </div>
          }
          options={calendarsList}
          onClose={() => setIsMyAvailability(false)}
        />
      )}

      {isUnlinkCalendar && (
        <UnlinkCalendarPopup
          closeUnlinkPopup={closeUnlinkPopup}
          unlinkCalendarHandle={unlinkCalendarHandle}
        />
      )}

      {isConnectionError && (
        <ErrorModal
          title="Error"
          text="We couldn’t connect your calendar. Please try again"
          cancelText="Close"
          confirmText="Try again"
          onCancel={closeErrorModal}
          onClose={closeErrorModal}
          onConfirm={tryAgain}
        />
      )}

      <Wrapper className="flex justify-end">
        {filteredButtons.map(button => (
          <div key={button.id} className="flex items-center justify-end gap-2">
            <span className="flex items-center gap-2 text-sm font-medium text-neutral-900">
              <SvgIcon name={button.iconName} className="!h-4 !w-4" />
              {button.text}
            </span>

            <ToggleButton
              onToggle={button.action}
              value={button.isCalendar}
              isBlack
            />
          </div>
          // <ButtonEl
          //   autoWidth
          //   isIconFirst
          //   size={BUTTON_SIZES.SMALL}
          //   variant={BUTTON_VARIANTS.ROUNDED}
          //   backgroundColor="var(--lightGray4)"
          //   textColor="var(--gray4)"
          //   border={`1px solid ${button.borderColor}`}
          //   text={button.text}
          //   iconName={button.iconName}
          //   iconColor={!button.isColorIgnore ? "var(--gray4)" : null}
          //   onClick={button.action}
          //   key={buttonIndex}>
          //   {button.isCalendar && renderUnlinkButton()}
          // </ButtonEl>
        ))}
      </Wrapper>
    </>
  )
}

const mapStateToProps = state => ({
  linkedCalendar: selectGuestLinkedCalendar(state),
  isConnectionError: selectIsGuestConnectionError(state),
  newGuestSuggestion: selectNewGuestSuggestion(state),
  guestSuggestions: selectGuestSuggestions(state),
})

const mapDispatchToProps = dispatch => ({
  linkGuestCalendar: bindActionCreators(linkGuestCalendar, dispatch),
  setIsGuestConnectionError: bindActionCreators(
    setIsGuestConnectionError,
    dispatch
  ),
  unlinkGuestCalendar: bindActionCreators(unlinkGuestCalendar, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionsListActions)

SuggestionsListActions.propTypes = {
  actions: PropTypes.array,
  isFullList: PropTypes.bool,
}
