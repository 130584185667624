import React from "react"

import propTypes from "prop-types"
import Modal from "react-modal"
import { View, Text, TouchableOpacity, Image } from "react-native"

import { images } from "@utils/imageUtils"

import { styles } from "./SideFilterSortMenuStyles"

const SideMenuHeader = ({ selectedScreen, onBackPress, onClosePress }) => (
  <View style={styles.header}>
    <View style={[styles.navButton, styles.navButtonLeft]}>
      {selectedScreen && (
        <TouchableOpacity onPress={onBackPress}>
          <Image style={styles.backArrow} source={images.back} />
        </TouchableOpacity>
      )}
    </View>
    <Text style={styles.headerTitle}>
      {selectedScreen ? selectedScreen.title : "Filter & Sort"}
    </Text>
    <View style={[styles.navButton, styles.navButtonRight]}>
      <TouchableOpacity onPress={onClosePress}>
        <Image style={styles.closeButton} source={images.close} />
      </TouchableOpacity>
    </View>
  </View>
)

SideMenuHeader.propTypes = {
  onBackPress: propTypes.func,
  onClosePress: propTypes.func.isRequired,
  selectedScreen: propTypes.shape({
    title: propTypes.string.isRequired,
    screen: propTypes.string.isRequired,
  }),
}
const SideMenuFooter = ({ teeupsCount, onClearPress, onDonePress }) => (
  <View style={styles.footer}>
    <Text style={styles.teeupsCount}>
      {teeupsCount ? `Show ${teeupsCount} items` : `0 items to show!`}
    </Text>
    <View style={[styles.row, styles.buttonRow]}>
      <TouchableOpacity
        style={[styles.button, styles.leftButton]}
        onPress={onClearPress}>
        <Text style={[styles.footerButtonText, styles.clearText]}>
          Clear all
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.button,
          styles.rightButton,
          !teeupsCount && styles.rightButtonDisabled,
        ]}
        onPress={onDonePress}
        disabled={!teeupsCount}>
        <Text style={[styles.footerButtonText, styles.doneText]}>Done</Text>
      </TouchableOpacity>
    </View>
  </View>
)

SideMenuFooter.propTypes = {
  teeupsCount: propTypes.number,
  onClearPress: propTypes.func.isRequired,
  onDonePress: propTypes.func.isRequired,
}

const modalStyles = {
  content: {
    width: 640,
    height: 500,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "#fff",
    padding: 16,
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "#00000066",
    position: "absolute",
    zIndex: 999,
  },
}

const SideFilterSortMenuComponent = ({
  renderScreenContent,
  onClosePress,
  onBackPress,
  onClearPress,
  onDonePress,
  selectedScreen,
  filteredTeeupsLength = 1000,
  renderFilters,
}) => (
  <Modal
    isOpen={true}
    onRequestClose={onClosePress}
    style={modalStyles}
    ariaHideApp={false}>
    {/* <SideMenuHeader
                selectedScreen={selectedScreen}
                onBackPress={onBackPress}
                onClosePress={onClosePress}
            /> */}
    <Text style={styles.title}>Filters</Text>
    {renderFilters()}
    <SideMenuFooter
      teeupsCount={filteredTeeupsLength}
      onClearPress={onClearPress}
      onDonePress={onDonePress}
    />
  </Modal>
)

SideFilterSortMenuComponent.propTypes = {
  onBackPress: propTypes.func,
  renderScreenContent: propTypes.func.isRequired,
  onClosePress: propTypes.func.isRequired,
  onClearPress: propTypes.func.isRequired,
  onDonePress: propTypes.func.isRequired,
  selectedScreen: propTypes.shape({
    title: propTypes.string.isRequired,
    screen: propTypes.string.isRequired,
  }),
  filteredTeeupsLength: propTypes.number,
}

export default SideFilterSortMenuComponent
