import React, { useEffect } from "react"

import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroll-component"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import { setTeeupFilter } from "@actions/commonActions"
import { fetchTeeups, setTeeupsPaginationError } from "@actions/teeupActions"
import { ArrowLoader, UpToDate, OopsError } from "@components/common"
import { SIDEBAR_MENU } from "@constants/sidebar"
import { selectTeeupSearch } from "@selectors/common"
import {
  selectTeeupsPaginationParams,
  selectIsTeeupsPagination,
  selectIsTeeupsPaginationLoading,
  selectTeeupsPaginationError,
} from "@selectors/teeups"
import { checkIsPath } from "@utils/checkIsPath"
import { images } from "@utils/imageUtils"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"

import EmptyView from "../ui/emptyView"

import { useTeeupsReloading } from "./hooks"

const TeeupListView = ({
  teeups,
  searchTerm,
  teeupsMode,
  renderItem,
  teeupFilter,
  teeupsPaginationParams,
  isPagination,
  isPaginationLoading,
  paginationError,
  setTeeupFilter,
  fetchTeeups,
  resetSortAndFilter,
  setTeeupsPaginationError,
  closeSortTooltip,
}) => {
  let empty = teeups.length === 0
  const isMoreTeeups =
    teeupsPaginationParams.page !== teeupsPaginationParams.nextPage
  const isInbox = checkIsPath(SIDEBAR_MENU.INBOX.PATHNAME)

  const { fetchNextTeeups } = useTeeupsReloading({
    teeupsPaginationParams,
    fetchTeeups,
  })

  useEffect(() => {
    return () => {
      setTeeupsPaginationError(null)
    }
  }, [])

  return (
    <>
      {empty ? (
        searchTerm ? (
          <EmptyView
            title={"No TeeUps Match\nthe Search or Filter"}
            imageSrc={images.noTeeupsInSearch}
          />
        ) : teeupsMode === TEEUP_LIST_MODES.ARCHIVED ? (
          <EmptyView
            title={"No Archived TeeUps"}
            imageSrc={images.noArchivedTeeups}
          />
        ) : teeupsMode === TEEUP_LIST_MODES.SKIPPED ? (
          <EmptyView
            title={"No TeeUps in Trash"}
            imageSrc={images.noTeeupsInTrash}
          />
        ) : teeupFilter ? (
          <EmptyView
            title={"No TeeUps Matching the Search or Filter"}
            imageSrc={images.NoTeeupsMatching}
            setTeeupFilter={setTeeupFilter}
            resetSortAndFilter={resetSortAndFilter}
            noTeeupsMatching
          />
        ) : (
          <>
            <EmptyView
              title={"It’s kinda lonely here…"}
              subtitle={"Join a TeeUp or start one of your own"}
              imageSrc={images.homeIcon}
            />

            {paginationError && <OopsError text={paginationError} />}
          </>
        )
      ) : (
        <div id="infinite-scroll-target" className={`teeup__list`}>
          <InfiniteScroll
            dataLength={teeups.length}
            next={fetchNextTeeups}
            hasMore={isMoreTeeups && isPagination}
            scrollableTarget="infinite-scroll-target"
            endMessage={isInbox && <UpToDate />}>
            <div className="teeups__item">
              {teeups.map((teeuup, index) =>
                renderItem({ item: teeuup, index })
              )}
            </div>
          </InfiniteScroll>

          {paginationError && (
            <OopsError isSideMargins text={paginationError} />
          )}

          {isPaginationLoading && isPagination && <ArrowLoader />}
        </div>
      )}
    </>
  )
}

TeeupListView.propTypes = {
  teeups: PropTypes.array,
  searchTerm: PropTypes.string,
  teeupsMode: PropTypes.string,
  listHeader: PropTypes.node,
  refreshing: PropTypes.bool,
  onRefresh: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  scrollEnabled: PropTypes.bool,
  testID: PropTypes.string,
  onMomentumScrollBegin: PropTypes.func,
  onMomentumScrollEnd: PropTypes.func,
}

TeeupListView.defaultProps = {
  teeups: [],
  listHeader: null,
  refreshing: false,
}

const mapStateToProps = state => ({
  teeupFilter: selectTeeupSearch(state),
  teeupsPaginationParams: selectTeeupsPaginationParams(state),
  isPagination: selectIsTeeupsPagination(state),
  isPaginationLoading: selectIsTeeupsPaginationLoading(state),
  paginationError: selectTeeupsPaginationError(state),
})

const mapDispatchToProps = dispatch => ({
  setTeeupFilter: bindActionCreators(setTeeupFilter, dispatch),
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
  setTeeupsPaginationError: bindActionCreators(
    setTeeupsPaginationError,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeupListView)
