import React, { useContext } from "react"

import PropTypes from "prop-types"

import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { DecidedSuggestion } from "../../NewTeeupGameplan/molecules"
import {
  PreviewCollapsedSuggestions,
  PreviewSuggestionsCounter,
  EmptySuggestions,
} from "components"

import { Wrapper } from "./whereSuggestionsStyles"

export const WhereSuggestions = ({ type }) => {
  const { gameplanInfo } = useContext(TeeupPreviewContext)
  const decidedSuggestion = gameplanInfo && gameplanInfo[type].decidedSuggestion
  const suggestions = gameplanInfo ? gameplanInfo[type]?.suggestions : []
  const suggestionsLength = suggestions.length

  const renderSuggestionsContent = () => {
    if (decidedSuggestion) {
      return (
        <DecidedSuggestion isPreview decidedSuggestion={decidedSuggestion} />
      )
    }

    const emptyTitle = type === GAMEPLAN_TYPES.when ? "No Time" : "No location"

    return suggestionsLength ? (
      <PreviewCollapsedSuggestions type={type} />
    ) : (
      <EmptySuggestions
        title={emptyTitle}
        description="Details to be decided"
      />
    )
  }

  return (
    <Wrapper>
      <PreviewSuggestionsCounter
        type={type}
        decidedSuggestion={decidedSuggestion}
        listLength={suggestionsLength}
      />

      {renderSuggestionsContent()}
    </Wrapper>
  )
}

WhereSuggestions.propTypes = {
  type: PropTypes.string,
}
