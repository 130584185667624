import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const ReplyOverlayStyles = StyleSheet.create({
  overlay: isActive => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: isActive ? 1 : 0,
    backgroundColor: AppColors.brand.grey40,
  }),
  keyboardAvoidingContainer: {
    flex: 1,
  },
  overlayContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  extraPadding: {
    marginBottom: Units.responsiveValue(23),
  },
})

export const ReplyOnFileStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: AppColors.brand.white,
    borderRadius: Units.responsiveValue(8),
    shadowColor: "black",
    shadowOpacity: 0.24,
    shadowRadius: 10,
    elevation: 4,
    overflow: "hidden",
    marginTop: Units.responsiveValue(10),
    width: 250,
  },
  pinkBorder: {
    width: Units.responsiveValue(3),
    height: "100%",
    backgroundColor: AppColors.brand.pink,
  },
  icon: {
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
    marginHorizontal: Units.responsiveValue(5),
  },
  descriptionContainer: {
    marginVertical: Units.responsiveValue(8),
    flex: 1,
  },
  topRowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    height: "100%",
    width: Units.responsiveValue(48),
  },
  fileTypeContainer: {
    height: "100%",
    width: 50,
    borderWidth: Units.responsiveValue(3),
    borderColor: AppColors.brand.white,
    backgroundColor: AppColors.brand.pinkLight2,
    alignItems: "center",
    paddingVertical: Units.responsiveValue(10),
  },
  notesTextContainer: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.darkGrey2,
  },
  extraLeftMargin: {
    marginLeft: Units.responsiveValue(20),
  },
  docImage: {
    width: 25,
    height: 25,
  },
})
