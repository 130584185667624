export const getExternalCalendars = calendars => {
  if (!calendars) return { outlookCalendar: null, googleCalendar: null }

  return calendars?.reduce(
    (acc, current) => {
      if (current.provider === "google-oauth2") {
        acc.googleCalendar = current || null
      }

      if (current.provider === "windowslive") {
        acc.outlookCalendar = current || null
      }

      return acc
    },
    { outlookCalendar: null, googleCalendar: null }
  )
}
