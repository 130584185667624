import React, { Fragment } from "react"

import SuggestionItem from "../SuggestionItem"

import "./index.scss"
import EmptyConversation from "@components/TeeUpsOverview/Chat/discussionCard/EmptyConversation"
import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"

const OtherSuggestions = ({ suggestions }) => {
  if (!suggestions?.length)
    return (
      <EmptyConversation
        iconName="noSuggestion"
        title="No suggestions"
        description="No one has made other suggestions yet!"
      />
    )

  return (
    <div className="other-suggestions">
      {suggestions.map(suggestion => {
        const meetingDetails =
          suggestion.type === "when"
            ? getWhenSuggestionDetails(suggestion)
            : getWhenSuggestionDetails(suggestion)
        return (
          <Fragment key={suggestion.id}>
            <SuggestionItem
              suggestion={suggestion}
              title={meetingDetails.title}
              subtitle={meetingDetails.subtitle}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

export default OtherSuggestions
