import React, { PureComponent } from "react"

import find from "lodash/find"
import get from "lodash/get"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  setTeeupActive,
  updateActiveTeeup,
  moveToActive,
} from "@actions/activeTeeupActions"
import {
  setNotificationTeeups,
  setTeeupFilter,
  handleSetTeeupsMode,
} from "@actions/commonActions"
import { fetchRequests, updateUserStatus } from "@actions/requestActions"
import {
  updateTeeupArchiveStatus,
  updateTeeup,
  archiveTeeup,
  updateTeeupIsArchivePromptShow,
  updateIsArchivePromptShow,
  fetchGameplanOptions,
  updateGameplan,
  gotTeeupParts,
  fetchTeeupUsersById,
  moveTeeupToTrash,
  fetchTeeups,
} from "@actions/teeupActions"
import { userStatusTypes } from "@config/mappings"
import { teeupUserStatusPriorities } from "@configs/enums"
import { SIDEBAR_MENU } from "@constants/sidebar"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectNotificationTeeups } from "@selectors/common"
import { selectTeeupInvitationsRequests } from "@selectors/requests"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
  selectTeeupsPaginationParams,
  selectUnseenTeeups,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { checkIsPath } from "@utils/checkIsPath"
import { SideBarContext } from "@utils/sideBarContext"
import { TEEUP_LIST_MODES } from "@utils/teeupUtils"
import { EVENTS, logEvent } from "analytics-tracking"

import LoadingView from "../ui/LoadingView"

import ComingUpList from "./ComingUpList"
import TeeupItem from "./TeeupItem"
import TeeupListHeader from "./TeeupListHeader"
import TeeupListView from "./teeupListView"

class TeeupList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      threeDots: false,
      isSortTooltip: false,
      inviteContent: null,
      teeupsPage: null,
    }
    this.sortTeeupRef = React.createRef()
  }

  checkTeeupsPage = () => {
    const pathname = window.location.pathname
    const { setFocusCounter } = this.context

    Object.values(SIDEBAR_MENU).forEach(menutItem => {
      if (menutItem.PATHNAME === pathname) {
        setFocusCounter(menutItem.INDEX)
        this.props.handleSetTeeupsMode(menutItem.TEEUPS_MODE)
      }
    })
  }

  componentDidMount() {
    this.checkTeeupsPage()
    this.setState({ loading: true })
    this.props
      .fetchRequests(this.props.userId)
      .then(res => this.setState({ loading: false }))
  }

  handleRespondToInvitation = teeup => response => {
    const {
      applicationSoundEnabled,
      updateGameplan,
      updateActiveTeeup,
      gotTeeupParts,
    } = this.props
    const { id: teeupId, isArchived } = teeup
    const statusId = teeupUserStatusPriorities.joined

    if (response) {
      this.props
        .updateUserStatus(teeupId, statusId)
        .then(async error => {
          setTeeupActive(teeup)
          updateActiveTeeup({
            id: teeupId,
            userStatus: userStatusTypes[statusId],
          })
          const teeupUsers = await fetchTeeupUsersById(teeupId)
          gotTeeupParts([teeupUsers])
          updateGameplan(teeup.id, await fetchGameplanOptions(teeupId))

          if (error) {
            console.log("handleRespondToInvitation Error:", {
              error,
            })
          }
        })
        .catch(error => {
          console.log("handleRespondToInvitation catch ERROR:", {
            error,
          })
        })
    } else {
      updateTeeupArchiveStatus(teeupId)
      this.props.archiveTeeup(teeupId)
    }

    setTimeout(() => {
      this.forceUpdate()
    }, 500)
  }

  onArchivePressed = async teeup => {
    const { teeups, fetchTeeups, teeupsPaginationParams } = this.props
    const isInbox = checkIsPath(SIDEBAR_MENU.INBOX.PATHNAME)

    await updateTeeupArchiveStatus(teeup.id)
    if (teeups.length < Number(teeupsPaginationParams.total) && isInbox) {
      fetchTeeups(teeupsPaginationParams)
    }
    this.props.archiveTeeup(teeup.id)
    logEvent(EVENTS.MOVE_TEEUP_TO_ARCHIVE, { teeupId: teeup?.id })
  }

  onMoveToActivePress = teeup => {
    moveToActive(teeup)
  }

  handleKeepInTheListPress = teeup => {
    const { userId, updateTeeupIsArchivePromptShow } = this.props
    const { id: teeupId } = teeup

    updateTeeupIsArchivePromptShow({
      userId,
      teeup: { ...teeup, isArchivePromptShow: false },
    })
    updateIsArchivePromptShow(teeupId, userId, false)
  }

  setInviteesContent = inviteContent => {
    this.setState({ inviteContent })
  }

  renderItem = ({ item, index }) => {
    const {
      teeupParticipants,
      teeupInvitations,
      userId,
      teeupPeople,
      teeupGameplans,
      activeTeeupId,
      teeups,
      notifications,
      notificationTeeups,
    } = this.props
    const { id, name } = item

    const notificationsArr = notifications.map(notification => notification.id)
    if (notificationTeeups && !notificationsArr?.includes(id)) return

    return (
      <TeeupItem
        key={`teeupItem-${id}`}
        teeup={item}
        currentUserId={userId}
        invitation={find(teeupInvitations, ["teeupId", id], null)}
        participants={get(teeupParticipants, id, [])}
        people={get(teeupPeople, id, {})}
        gameplans={get(teeupGameplans, id, [])}
        onPress={() => {
          this.props.onTeeupPressed(item)
        }}
        onKeepInTheListPress={() => this.handleKeepInTheListPress(item)}
        onArchivePressed={() => this.onArchivePressed(item)}
        onMoveToActivePress={() => this.onMoveToActivePress(item)}
        name={name}
        onRespondToInvitation={this.handleRespondToInvitation(item)}
        testID={`teeup-${id}`}
        isSelected={id === activeTeeupId}
        isLast={index + 1 === teeups.length}
        mode={this.props.teeupsMode}
        notificationsArr={notificationsArr}
        setInviteesContent={this.setInviteesContent}
      />
    )
  }
  toggleSortTooltip = () => {
    this.setState({ isSortTooltip: !this.state.isSortTooltip })
  }

  render() {
    const {
      teeups,
      searchTerm,
      teeupsMode,
      teeupPeople,
      teeupGameplans,
      listHeader,
      onRefresh,
      toggleSort,
      onCustomScroll,
      scrollEnabled,
      onScrollEndDrag,
      nestedScrollEnabled,
      emptyScrollEnabled,
      onMomentumScrollBegin,
      onMomentumScrollEnd,
      applicationSoundEnabled,
      // openedSort,
      sorting,
      setNotificationTeeups,
      notificationTeeups,
      setTeeupFilter,
      notifications,
      renderFilterButtons,
      addTeeupsSorting,
      sortingType,
      teeupFilter,
      resetSortAndFilter,
      filtering,
      setFiltering,
    } = this.props

    const { inviteContent } = this.state
    return (
      <>
        <div className="teeup__list-container">
          {teeupsMode === TEEUP_LIST_MODES.COMING_UP ? (
            <div className="teeup__list--comingUp--header">
              <span className="teeup__list--comingUp--header__text">
                Coming Up
              </span>
            </div>
          ) : (
            <TeeupListHeader
              mode={teeupsMode}
              notification={notificationTeeups}
              // TODO temporary; as both values come from redux they should be just read inside the component that is using them instead of prop drilling
              setTeeupFilter={setTeeupFilter}
              // TODO prop drilling is not a good solution.consider moving stuff to context
              filtering={filtering}
              setFiltering={setFiltering}
              notifications={notifications}
              // openedSort={openedSort}
              sorting={sorting}
              addTeeupsSorting={addTeeupsSorting}
              sortingType={sortingType}
              unreadNotifications={setNotificationTeeups}
            />
          )}
          {/* <View
            style={[
              ViewStyles.row,
              { marginBottom: 20, width: "calc(100% - 20px)" },
            ]}
          >
            <Text style={[ViewStyles.headerTitle, { lineHeight: "none" }]}>
              {teeupsMode
                ? teeupsMode.toLowerCase()
                : notificationTeeups
                ? "Unread"
                : "Inbox"}
            </Text>
            {!teeupsMode && (
              <>
                <div style={{ flexGrow: 1 }}>
                  <SearchInput
                    setTeeupFilter={setTeeupFilter}
                    teeupFilter={teeupFilter}
                  />
                </div>
                {notifications.length > 0 && (
                  <div
                    className={cn("notifications__wrapper", {
                      "notifications__wrapper--active": notificationTeeups,
                    })}
                    onClick={setNotificationTeeups}
                  >
                    <div className="notifications__count">
                      {notifications.length}
                    </div>
                    <img src={images.card} />
                  </div>
                )}
              </>
            )}
          </View> */}
          {/* {!teeupsMode && (
            <View
              style={[
                ViewStyles.row,
                ViewStyles.listHeader,
                {
                  minHeight: 30,
                  width: "calc(100% - 20px)",
                  paddingRight: 100,
                },
              ]}
            >
              <TouchableOpacity
                style={[ViewStyles.row, { marginRight: 9, marginBottom: 4 }]}
                onPress={() => toggleSort(!openedSort)}
              >
                <img
                  style={{ height: 10, width: 10, marginRight: 8 }}
                  src={images.filterIcon}
                />
                <Text style={ViewStyles.sortTitle}>Filter</Text>
              </TouchableOpacity>
              <div>
                {notificationTeeups && (
                  <TouchableOpacity
                    style={ViewStyles.scrollContainerTag}
                    onPress={() => setNotificationTeeups()}
                  >
                    <View style={ViewStyles.buttonTag}>
                      <Text style={ViewStyles.buttonTitleTag}>Unread</Text>
                      <Image
                        style={ViewStyles.closeTag}
                        source={images.close}
                      />
                    </View>
                  </TouchableOpacity>
                )}
                {renderFilterButtons}
              </div>

              {ReactDOM.createPortal(
                <SortTooltip
                  changeSortType={addTeeupsSorting}
                  sorting={sortingType}
                  onClose={() => {
                    ReactTooltip.hide(this.sortTeeupRef.current)
                    this.setState({ threeDots: false })
                    this.toggleSortTooltip()
                  }}
                  isSortTooltip={this.state.isSortTooltip}
                />,
                document.getElementById("modal-root")
              )}
              <TouchableOpacity
                style={[
                  ViewStyles.row,
                  { position: "absolute", right: 0, top: -4 },
                ]}
                onMouseOver={(e) => {
                  e.stopPropagation()
                  this.setState({ threeDots: true })
                }}
                // onMouseLeave={(e)=> {
                //     e.stopPropagation()
                //     ReactTooltip.hide(this.sortTeeupRef.current)
                //     this.setState({threeDots: false})
                // }}
              >
                {this.state.threeDots && (
                  <img
                    src={threeDotsIcon}
                    ref={this.sortTeeupRef}
                    data-tip={1}
                    data-for="sort-tooltip"
                    className="reactions-view"
                    onClick={(e) => {
                      e.stopPropagation()
                      this.toggleSortTooltip()
                      ReactTooltip.show(this.sortTeeupRef.current)
                    }}
                    alt=""
                  />
                )}
                <img
                  style={{ height: 10, width: 10, marginRight: 8 }}
                  src={images.teeupsSort}
                />
                <Text style={ViewStyles.sortTitle}>{sorting.title}</Text>
              </TouchableOpacity>
            </View>
          )} */}
        </div>
        <div className="teeup-list-wrapper">
          {teeupsMode === TEEUP_LIST_MODES.COMING_UP ? (
            <div className="teeup__commingup">
              <ComingUpList />
            </div>
          ) : this.state.loading ? (
            <LoadingView className="teeup__loading" />
          ) : (
            <TeeupListView
              teeups={teeups}
              searchTerm={searchTerm}
              teeupsMode={teeupsMode}
              teeupGameplans={teeupGameplans}
              teeupPeople={teeupPeople}
              listHeader={listHeader}
              onRefresh={onRefresh}
              renderItem={this.renderItem}
              onCustomScroll={onCustomScroll}
              scrollEnabled={scrollEnabled}
              onScrollEndDrag={onScrollEndDrag}
              nestedScrollEnabled={nestedScrollEnabled}
              emptyScrollEnabled={emptyScrollEnabled}
              testID={"teeupList"}
              onMomentumScrollBegin={onMomentumScrollBegin}
              onMomentumScrollEnd={onMomentumScrollEnd}
              applicationSoundEnabled={applicationSoundEnabled}
              onArchivePressed={this.onArchivePressed}
              onMoveToActivePress={this.onMoveToActivePress}
              notificationTeeups={notificationTeeups}
              resetSortAndFilter={resetSortAndFilter}
            />
          )}

          {inviteContent &&
            ReactDOM.createPortal(
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 3,
                    display: "flex",
                  }}
                  onClick={() => this.setInviteesContent(null)}>
                  <div
                    style={{
                      width: "660px",
                      backgroundColor: "white",
                      margin: "auto",
                      borderRadius: "8px",
                    }}
                    onClick={e => e.stopPropagation()}>
                    {inviteContent}
                  </div>
                </div>
              </>,
              document.querySelector("#modal-root")
            )}
        </div>
      </>
    )
  }
}

TeeupList.propTypes = {
  teeups: PropTypes.array,
  searchTerm: PropTypes.string,
  teeupsMode: PropTypes.string,
  teeupInvitations: PropTypes.array,
  teeupsState: PropTypes.object,
  teeupParticipants: PropTypes.object,
  teeupPeople: PropTypes.object,
  teeupGameplans: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.object]),
  // loading: PropTypes.bool.isRequired,
  listHeader: PropTypes.node,
  onRefresh: PropTypes.func,
  showArchiveButton: PropTypes.bool,
  onTeeupPressed: PropTypes.func.isRequired,
  onCustomScroll: PropTypes.func,
  customScrollEnabled: PropTypes.bool,
  onScrollEndDrag: PropTypes.func,
  nestedScrollEnabled: PropTypes.bool,
  emptyScrollEnabled: PropTypes.bool,
  scrollEnabled: PropTypes.bool,
  // updateTeeupArchiveStatus: PropTypes.func.isRequired,
  // updateTeeupIsArchivePromptShow: PropTypes.func.isRequired,
  deleteTeeupForUser: PropTypes.func,
  onMomentumScrollBegin: PropTypes.func,
  onMomentumScrollEnd: PropTypes.func,
  applicationSoundEnabled: PropTypes.bool,
  isInternetConnected: PropTypes.bool,
  updateTeeupActiveStatus: PropTypes.func,
}

TeeupList.defaultProps = {
  teeups: [],
  teeupInvitations: [],
  teeupParticipants: {},
  teeupPeople: {},
  teeupGameplans: {},
  listHeader: null,
}

const mapStateToProps = state => {
  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const activeTeeupId = selectActiveTeeupId(state)
  const notifications = selectUnseenTeeups(state)
  const teeupInvitations = selectTeeupInvitationsRequests(state)
  const userId = selectUserId(state)
  const notificationTeeups = selectNotificationTeeups(state)

  return {
    teeupParticipants,
    teeupInvitations,
    teeupPeople,
    teeupGameplans,
    userId,
    activeTeeupId,
    notifications,
    notificationTeeups,
    teeupsPaginationParams: selectTeeupsPaginationParams(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchRequests: bindActionCreators(fetchRequests, dispatch),
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
  updateTeeup: bindActionCreators(updateTeeup, dispatch),
  archiveTeeup: bindActionCreators(archiveTeeup, dispatch),
  updateTeeupIsArchivePromptShow: bindActionCreators(
    updateTeeupIsArchivePromptShow,
    dispatch
  ),
  updateGameplan: bindActionCreators(updateGameplan, dispatch),
  updateActiveTeeup: bindActionCreators(updateActiveTeeup, dispatch),
  gotTeeupParts: bindActionCreators(gotTeeupParts, dispatch),
  moveTeeupToTrash: bindActionCreators(moveTeeupToTrash, dispatch),
  setNotificationTeeups: bindActionCreators(setNotificationTeeups, dispatch),
  setTeeupFilter: bindActionCreators(setTeeupFilter, dispatch),
  handleSetTeeupsMode: bindActionCreators(handleSetTeeupsMode, dispatch),
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
})

TeeupList.contextType = SideBarContext

export default connect(mapStateToProps, mapDispatchToProps)(TeeupList)
