import { createApi } from "unsplash-js"

import { getRandomNumber } from "@utils/getRandomNumber"

const size = "1440x1000"

export const unsplash = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY as string,
})

export const unsplashApi = {
  random: `https://source.unsplash.com/collection/175083/${size}`,
  randomProfile: `https://source.unsplash.com/collection/175083/640x168`,
  randomSmaller: `https://source.unsplash.com/collection/175083/300x200`,
  dark: `https://source.unsplash.com/${size}/?dark`,
  gray: `https://source.unsplash.com/300x200/?gray`,
}

export const searchUnsplashPhoto = async (keywordsString, isList) => {
  const {
    // @ts-ignore
    response: { results },
  } = await unsplash.search.getPhotos({
    query: keywordsString.replaceAll(/\s+/g, "+"),
    page: getRandomNumber({ min: 1, max: 3 }),
    perPage: isList ? 10 : 1,
  })

  return isList
    ? results
    : results?.[0]?.urls?.small_s3 || results[0]?.urls?.small
}

export const getRandomUnsplashPhoto = async () => {
  try {
    const result = await unsplash.photos.getRandom({})
    if (!result) return

    // @ts-ignore
    return result?.response?.urls?.small_s3 || result?.response?.urls?.small
  } catch (error) {
    console.log("error", error)
    return
  }
}
