import cn from "classnames"

import { SvgIcon } from "@components/common"

interface Props {
  title: string
  onClick: () => void
  iconName?: string
  className?: string
}

const Option = ({
  title,
  onClick,
  iconName = "nudgePeopleNew",
  className,
}: Props) => {
  return (
    <div
      className={cn("people-nudge-option-item", className)}
      onClick={onClick}>
      <div>
        <SvgIcon name={iconName} color="var(--gray4)" />
        <span>{title}</span>
      </div>
      <SvgIcon
        name="arrow-2"
        color="var(--gray4)"
        className="collapse-icon-rotate"
      />
    </div>
  )
}

export default Option
