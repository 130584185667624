import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 16px 44px 16px;
  box-sizing: border-box;
  box-shadow: 0px -2px 7px -1px rgba(168, 170, 173, 0.14);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  align-self: flex-end;
  justify-self: flex-end;
  background-color: var(--white);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${({ $isShown }) =>
    $isShown &&
    css`
      opacity: 1;
    `}
`
