import { useCallback } from "react"

export const useEventParams = ({ getEventWidth }) => {
  return useCallback(
    ({ event, eventIndex }) => ({
      eventKey: `${event.startDate}_${eventIndex}`,
      eventWidth: getEventWidth(event),
      isInnerEvents: event.innerEvents.length > 1,
    }),
    []
  )
}
