import dayjs from "utils/dayjs"

import { stringToDateFormat } from "../dateUtils"

const formatCustomTimeWithTimezone = (
  format: string,
  endDate?: string | null,
  customDate?: string
): string => {
  if (!endDate) {
    return customDate || ""
  }
  const endFormattedDate = stringToDateFormat(endDate, format)

  if (customDate === "Today" || customDate === "Tomorrow") {
    const currentDate = dayjs(new Date())
    const labelDayjs = dayjs.utc(endDate)

    if (labelDayjs.isSame(currentDate, "day")) {
      return "Today"
    } else if (labelDayjs.isSame(currentDate.clone().add(1, "day"), "day")) {
      return "Tomorrow"
    } else if (
      labelDayjs.isSame(currentDate.clone().subtract(1, "day"), "day")
    ) {
      return "Yesterday"
    } else {
      return dayjs(endDate).format(format)
    }
  }

  return customDate || endFormattedDate || ""
}

export default formatCustomTimeWithTimezone
