export const useGetIdToken = ({
  setIsLocalLoading,
  getAccessTokenSilently,
  getIdTokenClaims,
  verifyUserAndSave,
  user,
  inviteSSORegisterToken,
  newRegisteredUser,
  setAuth0Type,
  setAuth0Error,
  setInviteSSORegisterToken,
}) => {
  const getIdToken = async (isRegistration = false) => {
    setIsLocalLoading(true)
    try {
      if (inviteSSORegisterToken) {
        verifyUserAndSave(newRegisteredUser, inviteSSORegisterToken)
        setInviteSSORegisterToken(null)
      } else {
        if (isRegistration) {
          await getAccessTokenSilently({ ignoreCache: true })
        }
        const idToken = await getIdTokenClaims()
        verifyUserAndSave(
          isRegistration ? newRegisteredUser : user,
          idToken.__raw
        )
      }
      setAuth0Type(null)
      setAuth0Error(null)
    } catch (error) {
      console.error(error.message)
    }
    setIsLocalLoading(false)
  }

  return { getIdToken }
}
