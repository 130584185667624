import React from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

import { Units } from "@theme"
import { formatHours } from "@utils/timezones"

import { CalendarComponentStyles as styles } from "./styles"

let { hourElements } = formatHours()

const defaultHitSlop = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

const CalendarTimetableRow = ({
  hour,
  meridiem,
  onSelectTimeSquare = () => {},
}) => {
  return (
    <TouchableOpacity
      style={styles.rowContainer}
      activeOpacity={1}
      onPress={event => {
        onSelectTimeSquare({ event, hour: Number(hour), meridiem })
      }}
      hitSlop={Units.hitSlop(defaultHitSlop)}>
      <View style={styles.timeContainer}>
        <Text style={styles.time}>
          {hour} {meridiem}
        </Text>
      </View>
      <View style={styles.rowEventsContainer} />
    </TouchableOpacity>
  )
}

CalendarTimetableRow.propTypes = {
  hour: PropTypes.string,
  meridiem: PropTypes.string,
  onSelectTimeSquare: PropTypes.func,
}

const CalendarTimetable = ({ onSelectTimeSquare }) => {
  return hourElements.map(hourElement => (
    <CalendarTimetableRow
      key={`${hourElement.line1}${hourElement.line2}`}
      hour={hourElement.line1}
      meridiem={hourElement.line2}
      onSelectTimeSquare={onSelectTimeSquare}
    />
  ))
}

CalendarTimetable.propTypes = {
  onSelectTimeSquare: PropTypes.func,
}

export default CalendarTimetable
