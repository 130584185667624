import { REACTION_TYPE } from "@constants/reactions"

export const updateLocalReactions = ({
  originReactions,
  myTeeupReactions,
  suggestionId,
  reactionId,
  isActive,
}) => {
  const isSuggestion = originReactions.hasOwnProperty(suggestionId)
  const isReaction =
    isSuggestion && originReactions[suggestionId] === reactionId

  if ((isSuggestion && isReaction) || isActive) {
    const { [suggestionId]: deletedKey, ...rest } = originReactions

    if (myTeeupReactions.includes(suggestionId)) {
      return {
        ...originReactions,
        [suggestionId]: REACTION_TYPE.NOT_VOTE,
      }
    }

    return rest
  }

  return {
    ...originReactions,
    [suggestionId]: reactionId,
  }
}
