import cn from "classnames"

interface Props {
  src: string
  isInvitation: boolean
}

const TeeUpPhoto = ({ src, isInvitation }: Props) => {
  return (
    <div
      className={cn(
        "size-[72px] rounded border border-neutral-200 overflow-hidden",
        {
          "-translate-y-1/3": !isInvitation,
        }
      )}>
      {src && (
        <img src={src} alt="teeup image" className="size-[72px] object-cover" />
      )}
    </div>
  )
}

export default TeeUpPhoto
