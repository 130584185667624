import { DETAILS_MODAL_CONTENT } from "@utils/peopleUtils"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import Options from "./Options"
import Tabs from "./Tabs"

interface Props {
  onClose: () => void
}

const Content = ({ onClose }: Props) => {
  const detailsModalContent = useTeeupPeopleStore(
    state => state.detailsModalContent
  )
  const setDetailsModalContent = useTeeupPeopleStore(
    state => state.setDetailsModalContent
  )

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setDetailsModalContent(newValue)
  }
  const currentParticipant = useTeeupPeopleStore(
    state => state.currentParticipant
  )

  const renderContent = () => {
    if (!currentParticipant) return null

    switch (detailsModalContent) {
      case DETAILS_MODAL_CONTENT.OPTIONS:
        return (
          <Options onClose={onClose} currentParticipant={currentParticipant} />
        )
      case DETAILS_MODAL_CONTENT.REACTIONS:
        return (
          <Tabs
            currentTab={DETAILS_MODAL_CONTENT.REACTIONS}
            onTabChange={handleTabChange}
            currentParticipant={currentParticipant}
          />
        )
      case DETAILS_MODAL_CONTENT.SUGGESTIONS:
        return (
          <Tabs
            currentTab={DETAILS_MODAL_CONTENT.SUGGESTIONS}
            onTabChange={handleTabChange}
            currentParticipant={currentParticipant}
          />
        )
      default:
        return (
          <Options onClose={onClose} currentParticipant={currentParticipant} />
        )
    }
  }

  return <div>{renderContent()}</div>
}

export default Content
