import { getCurrentEnvName } from "@utils/checkEnv"

const chat = process.env.REACT_APP_SOCKET_ENDPOINT
const auth0domain = process.env.REACT_APP_AUTH0_DOMAIN
const api = process.env.REACT_APP_API_ENDPOINT

const endpoints = {
  // Login
  LOGIN: "/users/login",
  TEEUPS: "/users/teeups",
  paginatedTeeups: "/users/teeups/paginated",
  register: "users/register",
  verifyContactMechanism: "/verify/contact-mechanism",
  checkSocialIdentity: "/users/check-identity",
  Login: "users/login",
  token: "users/token",
  sendBetaCode: "verify/send",
  verifyBetaCode: "verify/validate",
  logout: `https://${auth0domain}/v2/logout`,
  userInfo: "users",
  emailForDeleteUser: "/users/email/delete/get",
  deleteUser: token => `/users/email/delete/${token}`,
  cancelDeleteUser: token => `/users/email/delete/cancel/${token}`,
  draftTeeup: () => "users/recommend/teeup",
  newSendPasswordRecoveryLink: "/users/email/resetpassword/get",
  newPasswordRecovery: token => `/users/email/resetpassword/${token}`,
  sendPasswordRecoveryLink: "tmp-passwords/recover",
  resetPassword: "users/passwords",
  //recover email
  recoverEmail: "recovery",
  recoverAccount: "contact-mechanisms/recovery",
  recoverAccountVerify: "contact-mechanisms/recovery/",
  recoverPassword: token => `users/passwords/verify-token/${token}`,
  changeEmailVerificationRequest: "users/contact-mechanisms/",
  changeEmailVerificationVerify: "users/contact-mechanisms/verifications/",
  addUserCM: "users/contact-mechanisms",
  slackConnect: "/users/authorize-app",
  MSGraphApi: "https://graph.microsoft.com/v1.0",
  googleApi: "https://www.googleapis.com",
  googleContactsApi: "https://people.googleapis.com/v1/",
  appleApi: "https://api.apple-cloudkit.com/database/1/",

  addSocialSignIn: "users/identities",
  removeSocialSignIn: id => `users/identities/${id}`,

  //beta login
  verifyUserType: "verify/users/type",
  // Verify Current Password
  verifyPassword: "users/passwords/verify",
  // TellUs
  syncContacts: () => "users/contacts",
  fetchContacts: () => "users/contacts",
  editContact: (userId, recordId) => `users/contacts/${recordId}`,
  fetchContact: (userId, contactId) => `users/contacts/${contactId}`,

  // User Tags
  getUserTags: () => "users/tags",
  createUserTag: () => "users/tags",
  deleteUserTag: (userId, tagId) => "users/tags/" + tagId,

  // User Connections
  removeConnection: () => "users/connections",

  // Blacklist
  blacklist: () => `users/blacklists`,

  // Tag Users
  tagUsers: () => "users/tags/add",
  removeTags: () => "users/tags/remove",

  // Community
  community: {
    communitiesSearch: "communities/search",
  },

  // Calendar
  calendarEvent: teeupId => "teeups/" + teeupId + "/events",
  msCalendar: {
    getCalendarsList: calendarId => `/users/${calendarId}/calendars`,
    // getCalendarEvents: (calendarId, startDate, endDate) =>
    //   `/users/${calendarId}/calendar/events?$top=20$count=true`,
    getCalendarEvents: (accountId, calendarId, startDate, endDate) =>
      `/users/${accountId}/calendars/${calendarId}/calendarView?startDateTime=${startDate}&endDateTime=${endDate}&$top=1000
      &$select=start,body,end,isAllDay,subject`,
    createCalendarEvent: (accountId, calendarId) =>
      `/users/${accountId}/calendars/${calendarId}/events`,
    updateDeleteCalendarEvent: (accountId, calendarId, eventId) =>
      `/users/${accountId}/calendars/${calendarId}/events/${eventId}`,
  },
  googleCalendar: {
    getCalendarsList: () => "/calendar/v3/users/me/calendarList",
    getInsertEventsList: calendarId =>
      `/calendar/v3/calendars/${calendarId}/events`,
    updateDeleteEvent: (calendarId, eventId) =>
      `/calendar/v3/calendars/${calendarId}/events/${eventId}`,
    connectGuest: () => `teeups/preview/calendar/token`,
  },
  contacts: {
    getMyGoogleInfo: () => "/oauth2/v2/userinfo",
    linkContacts: () => "/users/contacts/connect",
    unlinkContacts: connectorId => `/users/contacts/connector/${connectorId}`,
    getContactsConnectors: () => "/users/contacts/connectors",
    getGoogleContacts: () =>
      "people/me/connections?personFields=names,emailAddresses,photos,phoneNumbers",
    getAppleContacts: () => {
      const env = getCurrentEnvName() === "PROD" ? "production" : "development"

      return `${process.env.REACT_APP_APPLE_CONTAINER}/${env}/public/users/current`
    },
  },

  // TeeUp
  teeup: {
    createTeeup: "teeups",
    teeups: () => "users/teeups",
    teeupsState: () => "users/teeups/state",
    teeupStateSeen: id => "users/teeups/" + id + "/state/seen",
    overview: id => "teeups/" + id,
    mutualAvailability: teeupId =>
      `teeups/${teeupId}/suggestions/mutual-availability`,
    setAsSeen: ({ teeupId, suggestionId }) =>
      `teeups/${teeupId}/suggestions/seen/${suggestionId}`,
    organisers: id => "teeups/" + id + "/organizers",
    users: id => "teeups/" + id + "/users",
    user: (id, userId) => "teeups/" + id + "/users/" + userId,
    suggestions: id => "teeups/" + id + "/suggestions",
    gameplanOptions: id => "teeups/" + id + "/gameplan-options",
    gameplanOption: (teeupId, gameplanOptionId) =>
      "teeups/" + teeupId + "/gameplan-options/" + gameplanOptionId,
    messages: id => "teeups/" + id + "/messages",
    reactions: (teeupId, suggestionId) =>
      "teeups/" + teeupId + "/suggestions/" + suggestionId + "/reactions",
    userStatus: teeupId => "teeups/" + teeupId + "/status/user",
    joinToTeeup: teeupId => "teeups/" + teeupId + "/status/user/sharedlink",
    markAsDecided: (teeupId, suggestionId) =>
      "teeups/" + teeupId + "/suggestions/" + suggestionId + "/decide",
    addInvitees: teeupId => "teeups/" + teeupId + "/invitees",
    getInviteeSuggestions: "users/suggest-invitees",
    invitationHistory: teeupId => "teeups/" + teeupId + "/invite-history",
    categories: (teeupId, categoryId) =>
      `teeups/${teeupId}/categories/${categoryId}`,

    teeupArchiveStatus: teeupId => "teeups/" + teeupId + "/archiving",
    deleteTeeup: teeupId => `teeups/${teeupId}/users/skip`,
    activateTeeup: teeupId => `teeups/${teeupId}/activate`,

    resetSlug: teeupId => "teeups/" + teeupId + "/slug",

    teeupSaveFiles: teeupId => "teeups/" + teeupId + "/messages",
    updateStartsWhenSuggestion: (teeupId, gameplanOptionsId, suggestionId) =>
      "teeups/" +
      teeupId +
      "/gameplan-options/" +
      gameplanOptionsId +
      "/suggestions/" +
      suggestionId,
    inviteManual: teeupId => `teeups/${teeupId}/invitees/send`,
    // preview: (teeupId) => `teeups/${teeupId}/preview`, //endpoint for testing Preview page
    preview: teeupId => `/teeups/invitees/${teeupId}/preview `,
    previewBySlug: teeupSlug => `/teeups/${teeupSlug}/preview`,
    previewSetGuestStatus: (teeupSlug, statusId) =>
      `teeups/invitees/${teeupSlug}/guest/${statusId}`,
    previewSetSharedLinkStatus: (teeupSlug, status) =>
      `teeups/invitees/sharedLink/${teeupSlug}/guest/${status}`,
    previewPostGuestData: teeupSlug => `teeups/invitees/${teeupSlug}/guest`,
    connectSharedLinkGuest: teeupId =>
      `teeups/${teeupId}/status/user/sharedlink`,
    confirmStatus: slug => `teeups/invitees/sharedLink/${slug}/confirm`,
    userLocation: "/users/locations",
  },

  // Notifications
  notification: {
    registerDevice: "devices",
    deleteUserDeviceId: deviceId => "devices/" + deviceId,
    notifications: () => "users/notifications",
    readNotification: notificationId => "users/notifications/" + notificationId,
    removeNotification: notificationId =>
      "users/notifications/" + notificationId,
  },

  // Users
  user: {
    profile: userId => `users/${userId}/view`,
    profileWithTags: (userId, currentUserId) =>
      `users/${userId}/view/${currentUserId}`,
    interests: () => `users/interests`,
    tags: () => `users/tags`,
    attributes: () => `users/attributes`,
    edit: () => `users`,
    avatar: `users/avatar`,
    teeupsUsers: `users/teeups/users`,
    teeupsGO: `users/teeups/gameplan-options`,
    matchings: () => `users/matchings`,
    locations: () => `users/locations`,
    editProfile: `users/profiles`,
  },

  interests: {
    interests: () => "interests",
    userInterests: () => "users/interests",
    follow: () => "users/interests",
    unfollow: (userId, interestId) => "users/interests/" + interestId,
    update: (userId, interestId) => "users/interests/" + interestId,
  },

  // Chat
  chat,

  //settings
  settings: `users/settings`,
  deleteUserContacts: () => "users/contacts/bulk-delete",

  //user contacts
  contactMechanisms: () => `users/contact-mechanisms`,
  contactMechanismsId: contactMechanismId =>
    `users/contact-mechanisms/${contactMechanismId}`,
  contactMechanismsIdRecovery: contactMechanismId =>
    `users/contact-mechanisms/${contactMechanismId}/recovery`,
  contactMechanismsIdLogin: contactMechanismId =>
    `users/contact-mechanisms/${contactMechanismId}/login`,
  contactMechanismVerifications: contactMechanismId =>
    `users/contact-mechanisms/${contactMechanismId}/verifications`,
  contactMechanismVerificationsVerify: verificationId =>
    `users/contact-mechanisms/verifications/${verificationId}/verify/`,
  batchVerifyAndGetUserInfo: () => `/users/search`,
  contactMechanismVerify: `/users/contact-mechanisms/verify`,
  contactMechanismPublish: contactMechanismId =>
    `/users/contact-mechanisms/${contactMechanismId}/publish`,
  contactMechanismUnpublish: contactMechanismId =>
    `/users/contact-mechanisms/${contactMechanismId}/unpublish`,

  // Groups
  userGroups: () => "users/groups",
  group: {
    overview: groupId => `groups/${groupId}`,
    inviteUser: groupId => `groups/${groupId}/invitees`,
    getTeeups: groupId => `groups/${groupId}/teeups`,
    addInvitees: groupId => `groups/${groupId}/invitees`,
  },

  // Requests
  requests: () => "users/requests",
  responseToRequest: (userId, requestId) => "users/requests/" + requestId,
  directMessagesList: () => "users/directMessages",
  directConversation: (userId, targetId) =>
    "users/directMessages/conversation/" + targetId,
  api,

  suggestHistory: "users/suggestions/where?limit=10",
  log: "/webhooks/log-event",
}

export default endpoints
