import React from "react"

import { Title2 } from "@assets/styles/typographyStyles"

export const Title = () => {
  return (
    <Title2 style={{ color: "var(--white)", textAlign: "center" }}>
      Make Meetings Happen
    </Title2>
  )
}
