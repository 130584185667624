import React from "react"

import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"

import { getFileSizeFromBytes } from "@utils/fileUtils"
import { images, getFileIcon } from "@utils/imageUtils"
// import backend from '@apis/backend'

import { ReplyOnFileStyles as styles } from "./replyOverlayStyles"

export class CommentOnImage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      imageSizeText: " ",
    }
  }

  componentDidMount = () => {
    const { image, setReplyImageSize } = this.props
    // getRemoteImageSize(image)
  }

  render() {
    const { username, image, replyImageSize } = this.props
    const { size, sizeType } = getFileSizeFromBytes(
      replyImageSize,
      false,
      false
    )
    return (
      <View style={styles.container}>
        <View style={styles.pinkBorder} />
        <View style={styles.descriptionContainer}>
          <View style={styles.topRowContainer}>
            <Image
              style={styles.icon}
              source={images.cameraGrey}
              resizeMode="contain"
            />

            <Text style={styles.notesTextContainer}>{username} posted</Text>
          </View>

          <Text style={[styles.notesTextContainer, styles.extraLeftMargin]}>
            {replyImageSize ? `${size}${sizeType}` : " "}
          </Text>
        </View>
        <View style={styles.imageContainer}>
          <Image style={styles.flex} source={{ uri: image || "" }} />
        </View>
      </View>
    )
  }
}

CommentOnImage.propTypes = {
  username: PropTypes.string,
  image: PropTypes.string,
  setReplyImageSize: PropTypes.func,
  replyImageSize: PropTypes.string,
}

export const CommentOnFile = ({ username, file }) => {
  const { size, sizeType } = getFileSizeFromBytes(file.size, false, false)

  return (
    <View style={styles.container}>
      <View style={styles.pinkBorder} />
      <View style={styles.descriptionContainer}>
        <View style={styles.topRowContainer}>
          <Image
            style={styles.icon}
            source={images.fileGrey}
            resizeMode="contain"
          />
          <Text style={styles.notesTextContainer}>{username} posted</Text>
        </View>

        <Text style={[styles.notesTextContainer, styles.extraLeftMargin]}>
          {file.name}
          {file.type} {size}
          {sizeType}
        </Text>
      </View>
      <View style={styles.fileTypeContainer}>
        <Image
          resizeMode="contain"
          style={styles.docImage}
          source={getFileIcon(file.type)}
        />
      </View>
    </View>
  )
}

CommentOnFile.propTypes = {
  username: PropTypes.string,
  file: PropTypes.object,
}
