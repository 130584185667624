import { RefObject, useEffect, useRef, useState } from "react"

import { Dayjs } from "dayjs"
import { useSelector } from "react-redux"
import { FixedSizeGrid, GridOnScrollProps, ScrollDirection } from "react-window"
import { useDebouncedCallback } from "use-debounce"

import { selectCalendarEvents } from "@selectors/calendar"
import { selectSelectedContacts } from "@selectors/invitees"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import { Participant } from "types/participant"
import { HourBox } from "types/suggestions"
import dayjs from "utils/dayjs"
import { UNKNOWN_TIMEZONE, groupByTimezone } from "utils/peopleUtils"
import {
  BOX_SIZE,
  BOX_COUNT,
  TIME_WHEEL_WIDTH,
  START_OF_DAY_HOUR,
  HOURS_BEFORE_CURRENT_HOUR,
  HOURS_PER_DAY,
  HOUR_IN_MINUTES,
  SCROLL_DEBOUNCE_DELAY,
} from "utils/suggestionUtils"

import { easeInOutQuint } from "./useTimeWheelDates"

export const useTimeWheelDates = (
  scrollRef: RefObject<FixedSizeGrid<any>>,
  isFromCreateTeeUp?: boolean
) => {
  const animationStartTime = useRef<number>(0)
  const currentDate = useSuggestionTimeWheelStore(state => state.currentDate)
  const setCurrentDate = useSuggestionTimeWheelStore(
    state => state.setCurrentDate
  )
  const updateDatesRangeTrigger = useSuggestionTimeWheelStore(
    state => state.updateDatesRangeTrigger
  )
  const triggerGenerateDatesChange = useSuggestionTimeWheelStore(
    state => state.triggerGenerateDatesChange
  )
  const updateSuggestion = useSuggestionTimeWheelStore(
    state => state.updateSuggestion
  )
  const currentActiveSuggestionIndex = useSuggestionTimeWheelStore(
    state => state.currentActiveSuggestionIndex
  )
  const triggerCurrentSuggestionChange = useSuggestionTimeWheelStore(
    state => state.triggerCurrentSuggestionChange
  )
  const triggerOnAddAnotherClick = useSuggestionTimeWheelStore(
    state => state.triggerOnAddAnotherClick
  )
  const calendarEvents = useSelector(selectCalendarEvents)

  const [boxesInfoByTimezone, setBoxesInfoByTimezone] = useState<HourBox[][]>(
    []
  )
  const selectedContacts = useSelector(selectSelectedContacts)
  const [isMounted, setIsMounted] = useState(false)
  // const [isScrolling, setIsScrolling] = useState(false)

  const duration = useSuggestionDurationStore(state => state.duration)
  const people = useSelector(selectTeeupPeople)
  const userInfo = useSelector(selectUserInfo)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (scrollRef.current?.state) {
      onScroll({ ...scrollRef.current?.state } as GridOnScrollProps)
    }
  }, [duration?.id])

  useEffect(() => {
    const peopleContainer = getPeopleInfo()

    if (!peopleContainer?.length || boxesInfoByTimezone.length > 0) return

    getDatesByTimezone(peopleContainer)
  }, [people, currentDate, selectedContacts])

  useEffect(() => {
    if (!triggerGenerateDatesChange || !isMounted) return

    const peopleContainer = getPeopleInfo()
    if (!peopleContainer?.length) return

    getDatesByTimezone(peopleContainer, true && isMounted)
  }, [triggerGenerateDatesChange])

  useEffect(() => {
    if (
      !isMounted ||
      !triggerCurrentSuggestionChange ||
      !currentDate ||
      boxesInfoByTimezone.length === 0
    )
      return

    scrollToDayHour(
      boxesInfoByTimezone?.[0]?.[0]?.dateString,
      currentDate,
      currentDate?.hour()
    )
  }, [triggerCurrentSuggestionChange])

  useEffect(() => {
    if (!triggerOnAddAnotherClick || !isMounted) return
    const minutesToAdd = 15

    scrollRef.current?.scrollTo({
      scrollLeft:
        // @ts-ignore
        scrollRef?.current?.state?.scrollLeft +
        (BOX_SIZE / HOUR_IN_MINUTES) * minutesToAdd,
    })
  }, [triggerOnAddAnotherClick])

  const getPeopleInfo = () => {
    const peopleArr: Participant[] = Object.values(people)
    const peopleContainer: Participant[] = isFromCreateTeeUp
      ? [...selectedContacts]
      : [...peopleArr]

    if (isFromCreateTeeUp) peopleContainer.unshift(userInfo)

    if (!currentDate && peopleContainer.length === 0) return

    return peopleContainer
  }

  const getDatesByTimezoneForPerson = (
    timezone: string,
    isTriggeredFromOutside?: boolean
  ) => {
    const isUnknownTimezone = timezone === UNKNOWN_TIMEZONE
    const today = dayjs().tz(isUnknownTimezone ? "UTC" : timezone)
    const isToday = currentDate?.isSame(today, "day")

    const date = dayjs(currentDate)
      .second(0)
      .minute(0)
      .tz(isUnknownTimezone ? "UTC" : timezone)
      .subtract(
        // isTriggeredFromOutside && !isToday
        //   ? HOURS_PER_DAY
        // :
        HOURS_BEFORE_CURRENT_HOUR,
        "hour"
      )

    const calendarEventsArr = Object.values(calendarEvents) as any[]

    const dates = Array.from({ length: BOX_COUNT }, (_, i) => {
      const currentDate = date.add(i, "hour")
      const event = calendarEventsArr.find(
        e => e.dateString === currentDate.format()
      )

      return {
        label: currentDate.format("ha"),
        dateString: currentDate.format(),
        isDisabled: currentDate.isBefore(today),
        isDaySeparator: currentDate.hour() === START_OF_DAY_HOUR,
        isToday: currentDate.isSame(today, "day"),
        timezone,
        event,
        isUnknownTimezone,
      }
    })

    return dates
  }

  const getDatesByTimezone = (
    people: Participant[],
    isTriggeredFromOutside?: boolean
  ) => {
    const dates: HourBox[][] = []

    const peopleByTimezone = groupByTimezone(people)

    const peopleByTimezoneValues = Object.values(
      peopleByTimezone
    ) as Participant[]

    peopleByTimezoneValues.forEach(person => {
      const timezone = person[0].timezone

      const personDates = getDatesByTimezoneForPerson(
        timezone,
        isTriggeredFromOutside
      )
      dates.push(personDates)
    })

    if (isTriggeredFromOutside && dates?.[0]?.[0]?.dateString) {
      scrollToDayHour(dates[0][0].dateString, currentDate, 1)
    }
    setBoxesInfoByTimezone(dates)
  }

  const roundMinutesToNearest15 = (date: Dayjs) => {
    const roundedMinutes = Math.round(date.minute() / 15) * 15
    return date.minute(roundedMinutes).second(0).millisecond(0)
  }

  const calcCurrentHour = (scrollLeft: number) => {
    const startDateString = boxesInfoByTimezone?.[0]?.[0]?.dateString

    if (!startDateString) return

    const boxesFromStart = scrollLeft / BOX_SIZE
    const boxesToCenter = TIME_WHEEL_WIDTH / 2 / BOX_SIZE

    const currentCenter = boxesFromStart + boxesToCenter

    let currentHour = dayjs(startDateString).add(currentCenter, "hour")

    if (duration?.id && duration?.id !== "1440") {
      currentHour = currentHour.subtract(+duration.id / 2, "minute")
    }
    currentHour = roundMinutesToNearest15(currentHour)

    const isDisabledDate = currentHour.isBefore(new Date())

    return { currentHour, isDisabledDate }
  }

  const reInitScrollPosition = () => {
    if (!scrollRef.current) return

    const offset = 5
    // const half = 2
    const start = boxesInfoByTimezone?.[0]?.[0]?.dateString
    const firstNotDisabledDate = boxesInfoByTimezone?.[0]?.find(
      (hour, index) => !hour.isDisabled && index !== 0
    )
    const diff = dayjs(firstNotDisabledDate?.dateString).diff(start, "hour")

    // const durationInBoxes = duration?.id
    //   ? +duration.id / HOUR_IN_MINUTES / half + offset
    //   : offset

    scrollRef.current.scrollTo({
      scrollLeft: BOX_SIZE * (diff - offset),
    })
  }

  const generateNewDates = (
    isForward: boolean,
    boxesInfoByTimezone: HourBox[][],
    calendarEvents: any
  ) => {
    const today = dayjs()
    const calendarEventsArr = Object.values(calendarEvents) as any[]

    return boxesInfoByTimezone?.map(day =>
      day.map((hour, index) => {
        const isUnknownTimezone = hour.timezone === UNKNOWN_TIMEZONE
        const offset = isForward ? 1 : -1
        const currentDateFormatted = dayjs(hour.dateString)
          .tz(isUnknownTimezone ? "UTC" : hour.timezone)
          .add(offset, "day")
        const event = calendarEventsArr.find(
          e => e.dateString === currentDateFormatted.format()
        )

        return {
          ...hour,
          label: currentDateFormatted.format("ha"),
          dateString: currentDateFormatted.format(),
          isDisabled: currentDateFormatted.isBefore(today),
          isDaySeparator: currentDateFormatted.hour() === START_OF_DAY_HOUR,
          isToday: currentDateFormatted.isSame(today, "day"),
          event,
        }
      })
    )
  }

  const updateScrollPosition = (isForward: boolean) => {
    if (!scrollRef?.current) return

    const columnIndex = isForward ? 41 : 25 + HOURS_BEFORE_CURRENT_HOUR
    scrollRef.current.scrollToItem({
      align: "center",
      columnIndex,
      rowIndex: 0,
    })
  }

  const scrollToDayHour = (
    firstDayInRow: string,
    dayToScroll: dayjs.Dayjs | null,
    hour?: number
  ) => {
    if (!dayToScroll || !hour) return

    const diff = dayToScroll.hour(hour).diff(firstDayInRow, "hour")
    const hourOffset = 1
    scrollRef.current?.scrollToItem({
      align: "center",
      columnIndex: diff + hourOffset,
      rowIndex: 0,
    })
  }

  const generateNewDays = (
    currentDate: Dayjs,
    horizontalScrollDirection: ScrollDirection
  ) => {
    const daysOffset = 3
    const lastScrollableDateIndex =
      boxesInfoByTimezone?.[0]?.length - HOURS_BEFORE_CURRENT_HOUR - daysOffset

    const lastDate =
      boxesInfoByTimezone?.[0]?.[lastScrollableDateIndex]?.dateString

    const firstDate =
      boxesInfoByTimezone?.[0]?.[HOURS_BEFORE_CURRENT_HOUR + daysOffset]
        ?.dateString

    const currentHour = dayjs().add(HOURS_BEFORE_CURRENT_HOUR, "hour").format()
    const isForward = horizontalScrollDirection === "forward"

    if (isForward && currentDate?.isSameOrAfter(lastDate)) {
      generateDaysByScrollDirection(isForward)
      updateDatesRangeTrigger()
      // TODO: remove magic number
      return 35 * BOX_SIZE
    } else if (
      !isForward &&
      currentDate?.isSameOrBefore(firstDate) &&
      !currentDate?.isSameOrBefore(currentHour)
    ) {
      generateDaysByScrollDirection(isForward)
      updateDatesRangeTrigger()
      // TODO: remove magic number
      return (18 + HOURS_BEFORE_CURRENT_HOUR) * BOX_SIZE
    }
  }

  const generateDaysByScrollDirection = (isForward: boolean) => {
    const newDates = generateNewDates(
      isForward,
      boxesInfoByTimezone,
      calendarEvents
    )
    updateScrollPosition(isForward)
    setBoxesInfoByTimezone(newDates)
  }

  const animateScrolling = (
    scrollOffsetFinal: number,
    scrollOffsetInitial: number
  ) => {
    const duration = SCROLL_DEBOUNCE_DELAY

    const reqId = requestAnimationFrame(() => {
      const now = performance.now()
      const ellapsed = now - animationStartTime.current
      const scrollDelta = scrollOffsetFinal - scrollOffsetInitial
      const easedTime = easeInOutQuint(Math.min(1, ellapsed / duration))
      const scrollOffset = scrollOffsetInitial + scrollDelta * easedTime

      scrollRef?.current?.scrollTo({
        scrollLeft: scrollOffset,
      })

      if (ellapsed < duration) {
        animateScrolling(scrollOffsetFinal, scrollOffsetInitial)
      } else {
        animationStartTime.current = 0
      }
    })

    setTimeout(() => {
      cancelAnimationFrame(reqId)
    }, duration)
  }

  const scrollWithAnimation = (scrollLeft: number) => {
    if (animationStartTime.current) return

    const interation = BOX_SIZE / 4
    const offset = Math.round(scrollLeft / interation) * interation

    animationStartTime.current = performance.now()
    animateScrolling(offset, scrollLeft)
  }

  const onScroll = useDebouncedCallback(
    ({ scrollLeft, horizontalScrollDirection }: GridOnScrollProps) => {
      const datesData = calcCurrentHour(scrollLeft)
      if (!datesData) return

      const currentHour = datesData.currentHour
      const isDisabledDate = datesData.isDisabledDate

      if (isDisabledDate) return reInitScrollPosition()

      setCurrentDate(currentHour)

      const currentScrollLeft = generateNewDays(
        currentHour,
        horizontalScrollDirection
      )

      scrollWithAnimation(currentScrollLeft || scrollLeft)

      if (currentActiveSuggestionIndex !== -1) {
        updateSuggestion(
          {
            date: [currentHour, currentHour.add(+duration.id, "minute")],
            duration: +duration.id,
          },
          currentActiveSuggestionIndex
        )
      }
    },
    SCROLL_DEBOUNCE_DELAY
  )

  return { boxesInfoByTimezone, onScroll }
}
