import React, { useContext } from "react"

import { ContactAvatar } from "@components/common"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"

import { ParticipantAvatarWrapper } from "../../ParticipantAvatarWrapper"
import { BlockWrapper } from "../newTeeupSettingsStyles"

import {
  Wrapper,
  Title,
  List,
  ListItem,
  OrganiserName,
} from "./organizersStyles"

export const Organizers = () => {
  const { teeup } = useContext(NewTeeupPageContext)

  return (
    <BlockWrapper>
      <Wrapper>
        <Title>Organizer(s)</Title>

        <List>
          {teeup.organisers.map(organizer => (
            <ListItem key={organizer.id}>
              <ParticipantAvatarWrapper isOrganiser>
                <ContactAvatar
                  src={organizer.avatar}
                  text={organizer.name}
                  size={48}
                />
              </ParticipantAvatarWrapper>
              <OrganiserName>@{organizer.username}</OrganiserName>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </BlockWrapper>
  )
}
