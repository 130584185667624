import Skeleton from "react-loading-skeleton"

import { SvgIcon } from "@components/common"

import "./index.scss"

const TitleContent = ({ total, attending, isMultiTimezone }) => {
  if ((!total && total !== 0) || (!attending && attending !== 0))
    return <Skeleton width={100} borderRadius={50} />

  return (
    <div className="people-title-content-container">
      <div>
        · {total} Total · {attending} Attending
      </div>

      {isMultiTimezone && (
        <div>
          <SvgIcon name="planet" color="var(--gray4)" />
          Multi-timezone
        </div>
      )}
    </div>
  )
}

export default TitleContent
