import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  ${({ backgroundColor, borderColor, color }) => css`
    width: calc(100% + 2px);
    background-color: ${backgroundColor};
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: ${borderColor};
    color: ${color};
    padding: 0 20px;
    position: absolute;
    top: 98%;
    left: -1px;
    z-index: 10;
  `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  ${({ $borderColor }) => css`
    border-top: 1px solid ${$borderColor};
    padding: 15px 0;
  `};
`
