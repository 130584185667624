import { useMemo } from "react"

export const useIsInvite = ({
  teeupInviteInfo,
  email,
  user,
  isRegisterSsoNames,
}) => {
  return useMemo(() => {
    if (!teeupInviteInfo) return false

    if (user?.email && isRegisterSsoNames) {
      return user.email !== teeupInviteInfo.email
    }

    return (
      (teeupInviteInfo.type === "SMS" && !!teeupInviteInfo.phone) ||
      (teeupInviteInfo.type === "email" &&
        !!email &&
        email !== teeupInviteInfo.email)
    )
  }, [teeupInviteInfo, email, user, isRegisterSsoNames])
}
