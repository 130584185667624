export const animate0to1 = (animatedValue, outputRange) => {
  return animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange,
  })
}

export const preventBounceParams = {
  tension: -2,
  friction: 5,
}
