import { TEEUP_LIST_MODES } from "@utils/teeupUtils"

const SIDEBAR_GROUPS = {
  TEEUPS: "TeeUps",
  CALENDAR: "Calendar",
  PEOPLE: "People",
}

export const SIDEBAR_MENU_NAMES = {
  AI_CREATE: "AI Create",
  CREATE: "Create new...",
  INBOX: "Inbox",
  TRASH: "Trash",
  COMING_UP: "Coming Up",
  ALL: "All",
  CONTACTS: "Contacts",
  SETTINGS: "Settings",
  PROFILE: "Profile",
}

export const COMMON_SIDEBAR_MENU = [
  {
    name: "",
    items: [
      {
        index: 7,
        name: SIDEBAR_MENU_NAMES.AI_CREATE,
        icon: "plus-sm",
        testId: "create-teeup-ai",
        color: "#F42862",
      },
      {
        index: 0,
        name: SIDEBAR_MENU_NAMES.CREATE,
        icon: "plus-sm",
        testId: "create-teeup",
        color: "#F42862",
      },
    ],
  },
  {
    name: SIDEBAR_GROUPS.TEEUPS,
    items: [
      {
        index: 1,
        name: SIDEBAR_MENU_NAMES.INBOX,
        icon: "inbox-2",
        pathname: "/inbox",
        group: SIDEBAR_GROUPS.TEEUPS,
      },
    ],
  },
  {
    name: SIDEBAR_GROUPS.CALENDAR,
    items: [
      {
        index: 4,
        name: SIDEBAR_MENU_NAMES.COMING_UP,
        icon: "coming-up",
        pathname: "/coming-up",
        group: SIDEBAR_GROUPS.CALENDAR,
      },
      {
        index: 5,
        name: SIDEBAR_MENU_NAMES.ALL,
        icon: "thin-calendar",
        pathname: "/calendar",
        group: SIDEBAR_GROUPS.CALENDAR,
      },
    ],
  },
  {
    name: SIDEBAR_GROUPS.PEOPLE,
    items: [
      {
        index: 6,
        name: SIDEBAR_MENU_NAMES.CONTACTS,
        icon: "contacts-2",
        pathname: "/contacts",
        group: SIDEBAR_GROUPS.CONTACTS,
      },
    ],
  },
]
export const SETTINGS_SIDEBAR_MENU = [
  {
    name: "",
    title: "Settings",
    items: [
      {
        index: 1,
        name: "Account settings",
        icon: "user-account",
        pathname: "/settings",
        option: "accountManagement",
      },
      {
        index: 1,
        name: "See invitations sent to",
        icon: "menu-emails",
        pathname: "/settings",
        option: "invitationsDetails",
      },
      {
        index: 2,
        name: "Sync contacts",
        icon: "sync-contacts",
        pathname: "/settings",
        option: "syncContacts",
      },
      {
        index: 3,
        name: "Calendars",
        icon: "menu-calendar",
        pathname: "/settings",
        option: "calendars",
      },
      {
        index: 4,
        name: "Time zones",
        icon: "timezone",
        pathname: "/settings",
        option: "timezones",
      },
      {
        index: 5,
        name: "Notifications",
        icon: "notification",
        pathname: "/settings",
        option: "notificationSettings",
      },
    ],
  },
]

export const SETTINGS_BOTTOM_SIDEBAR_MENU = [
  { index: 16, name: "Privacy Policy", pathname: "https://privacy.coowe.com/" },
  { index: 15, name: "Terms of Service", pathname: "https://tos.coowe.com/" },
  { index: 14, name: "Licenses", pathname: "https://licenses.coowe.com/" },
]

export const NEW_SIDEBAR_MENU = {
  settings: SETTINGS_SIDEBAR_MENU,
  common: COMMON_SIDEBAR_MENU,
}

export const BOTTOM_SIDEBAR_MENU = {
  settings: SETTINGS_BOTTOM_SIDEBAR_MENU,
  common: [],
}

// TODO: remove together with old components
export const SIDEBAR_MENU = {
  CREATE: {
    INDEX: 0,
    PATHNAME: "/create",
  },
  INBOX: {
    INDEX: 1,
    PATHNAME: "/inbox",
    TEEUPS_MODE: TEEUP_LIST_MODES.ACTIVE,
  },
  ARCHIVE: {
    INDEX: 2,
    PATHNAME: "/archive",
    TEEUPS_MODE: TEEUP_LIST_MODES.ARCHIVED,
  },
  TRASH: {
    INDEX: 3,
    PATHNAME: "/trash",
    TEEUPS_MODE: TEEUP_LIST_MODES.SKIPPED,
  },
  COMING_UP: {
    INDEX: 4,
    PATHNAME: "/coming-up",
    TEEUPS_MODE: TEEUP_LIST_MODES.COMING_UP,
  },
  CALENDAR: {
    INDEX: 5,
    PATHNAME: "/calendar",
  },
  CONTACTS: {
    INDEX: 6,
    PATHNAME: "/contacts",
  },
  SETTINGS: {
    INDEX: 11,
    PATHNAME: "/settings",
  },
  HELP: {
    INDEX: 12,
    PATHNAME: "/mock-HELP",
  },
  MY_PROFILE: {
    INDEX: 13,
    PATHNAME: "/my-profile",
  },
  MY_PROFILE_2: {
    INDEX: 14,
    PATHNAME: "/profile-2/:id",
  },
}
