import React, { useContext } from "react"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { setSelectedContact } from "@actions/inviteesActions"
import { ContactAvatar } from "@components/common"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { selectSelectedContacts } from "@selectors/invitees"
import { highlightText } from "@utils/highlightText"

import {
  Wrapper,
  Info,
  NameLine,
  Name,
  ContactValue,
} from "./foundContactStyles"

export const FoundContact = ({ contact }) => {
  const selectedContacts = useSelector(selectSelectedContacts)
  const { avatar, name, username, emails, phoneNumbers } = contact
  const { searchValue, setSearchValue } = useContext(InvitePeopleContext)
  const dispatch = useDispatch()

  const getContactValue = () => {
    return emails.length > 0 ? `@${username}` : phoneNumbers[0]?.number || "..."
  }

  const handleContactClick = () => {
    if (selectedContacts.some(({ id }) => id === contact.id)) return

    dispatch(setSelectedContact(contact))
    setSearchValue("")
  }

  return (
    <Wrapper onClick={handleContactClick}>
      <ContactAvatar src={avatar} text={name} />

      <Info>
        <NameLine>
          <Name
            dangerouslySetInnerHTML={{
              __html: highlightText(name, searchValue),
            }}
          />
        </NameLine>

        <ContactValue
          dangerouslySetInnerHTML={{
            __html: highlightText(getContactValue(), searchValue),
          }}
        />
      </Info>
    </Wrapper>
  )
}

FoundContact.propTypes = {
  contact: PropTypes.object,
}
