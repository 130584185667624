import React from "react"

import PropTypes from "prop-types"

import { Statuses } from "./molecules"
import { Wrapper, Description, DecidedDescription } from "./rsvpSectionStyles"

export const RSVPSection = ({ isDecided }) => {
  const renderText = () => {
    return isDecided ? (
      <div className="font-semibold text-neutral-800">
        Does this Work for You?
      </div>
    ) : (
      <Description>Respond without sharing time preference</Description>
    )
  }

  return (
    <Wrapper>
      {renderText()}

      <Statuses />
    </Wrapper>
  )
}

RSVPSection.propTypes = {
  isDecided: PropTypes.bool,
}
