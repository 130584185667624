import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingBottom: 80,
  },
  headerTitle: {
    ...AppFonts.header22Demibold,
  },
  closeButtonWrapper: {
    paddingRight: Units.responsiveValue(25),
  },
  closeButton: {
    height: Units.rem(1),
    width: Units.rem(1),
    marginHorizontal: Units.rem(1),
    tintColor: AppColors.brand.pink,
  },
  rowItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: -1,
  },
  buttonStyle: {
    paddingVertical: 14,
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
  },
  marginBottom: {
    marginBottom: 1,
  },
  icon: {
    height: Units.rem(1),
    width: Units.rem(1),
  },
  text: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 13,
    lineHeight: 23,
    letterSpacing: -0.26,
    color: "#f42862",
  },
  contentText: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
    margin: 0,
    padding: 0,
    flex: 1,
  },
  contentTextInput: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
  },
  existedUser: {
    ...AppFonts.smallishMedium,
    lineHeight: Units.responsiveValue(21),
    letterSpacing: -Units.responsiveValue(0.26),
    color: AppColors.brand.warmGrey2,
  },
  cooeIcon: {
    tintColor: AppColors.brand.pink,
    height: Units.responsiveValue(13),
    width: Units.responsiveValue(13),
    marginLeft: Units.responsiveValue(5),
  },
  userWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 84,
  },
})

export default styles
