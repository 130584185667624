import React, { useState } from "react"

import copy from "copy-to-clipboard"
import { Image } from "react-native"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { bindActionCreators } from "redux"

import "./index.scss"
import {
  setReplyMessage,
  setNudgePeopleOpen,
  setActiveTab,
  setModalPeopleNudge,
} from "@actions/commonActions"
import {
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
} from "@actions/teeupActions"
import { setActiveHighlightedMessage } from "@actions/teeupCardActions"
import { teeupUserStatusKeys } from "@config/enums"
import { conversationTypeIds } from "@configs/mappings"
import {
  selectMessageById,
  selectGameplanById,
  selectActiveTeeupId,
} from "@selectors/activeTeeup"
import { selectMentionedMessages } from "@selectors/common"
import { selectUserStatus } from "@selectors/teeups"
import { isPastSuggestion, promoteOrDecide } from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import { EVENTS, logEvent } from "analytics-tracking"
import {
  markAsDecided,
  pinUnpinMessage,
  pinUnpinSuggestion,
  removeFromGameplan,
  toggleWithdraw,
} from "middlewares/backendMiddleware"

import { store } from "../../../../index"

const MessageTooltip = ({
  currentUser,
  setReplyMessage,
  setModalPeopleNudge,
  setActiveHighlightedMessage,
  mentionedMessages,
  setActiveTab,
  activeTeeupId,
  setSuggestionRemovedStatus,
  removeSuggestionRemovedStatus,
  inputRef,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  const getSuggestionActions = message => {
    const { id, isPinned, isWithdrawn, selected, decided, type } = message
    const isSuggestionInPast = isPastSuggestion(
      message,
      !message?.endDate && "day"
    )
    const inactive = isSuggestionInPast || isWithdrawn
    const allowComment = currentUser?.status !== teeupUserStatusKeys.invited
    const allowMoveToGameplan = !inactive && !selected
    const allowRemoveFromGameplan = !inactive && selected
    return (
      <>
        {!isSuggestionInPast && (
          <div
            className="tooltip_list_item"
            onClick={() => {
              console.log("Does this work for you ?")
              setModalPeopleNudge(true, id, "nudgeWorkForYou")
              // hideTooltip();
            }}>
            <Image
              source={images.nudgesNew}
              style={{ width: 16, height: 16, tintColor: "#222222" }}
            />
            <span className="tooltip_list_item__title">
              Ask "does this work for you?"
            </span>
          </div>
        )}
        {allowComment && (
          <div
            className="tooltip_list_item"
            onClick={() => {
              setReplyMessage(message)
              inputRef.current && inputRef.current.focus()
              // hideTooltip();
            }}>
            <img
              src={images.replyMessageNew}
              className="tooltip-icon"
              alt="reply message icon"
            />
            <span className="tooltip_list_item__title">Comment</span>
          </div>
        )}
        {/* {!inactive &&
                    <div className="tooltip_list_item"
                        onClick={ () => {
                            setNudgePeopleOpen(true);
                            hideTooltip();
                        }}
                    >
                        <img src={ nudgeIcon } className="tooltip-icon" />
                        <span className="tooltip_list_item__title">
                            Nudge People
                        </span>
                    </div> } */}
        {!inactive &&
          (isPinned ? (
            <div
              className="tooltip_list_item"
              onClick={() => {
                pinUnpinSuggestion({ isPinned: false, suggestionId: id })
                // hideTooltip();
                logEvent(EVENTS.REMOVE_FROM_PINBOARD, {
                  teeupId: activeTeeupId,
                  suggestionid: id,
                })
              }}>
              <img
                src={images.unpinMessageNew}
                className="tooltip-icon"
                alt="unpin message icon"
              />
              <span className="tooltip_list_item__title">
                Remove from pinboard
              </span>
            </div>
          ) : (
            <div
              className="tooltip_list_item"
              onClick={() => {
                pinUnpinSuggestion({ isPinned: true, suggestionId: id })
                // hideTooltip();
                logEvent(EVENTS.ADD_TO_PINBOARD, {
                  teeupId: activeTeeupId,
                  suggestionid: id,
                })
              }}>
              <img
                src={images.pinMessageNew}
                className="tooltip-icon"
                alt="pin message icon"
              />
              <span className="tooltip_list_item__title">Add to pinboard</span>
            </div>
          ))}
        {allowMoveToGameplan && (
          <div
            className="tooltip_list_item"
            onClick={() => {
              promoteOrDecide(message, type)
              // hideTooltip();
            }}>
            <img
              src={images.moveToGameplanNew}
              className="tooltip-icon"
              alt="move to gameplan icon"
            />
            <span className="tooltip_list_item__title">Decide on this</span>
          </div>
        )}
        {allowRemoveFromGameplan && (
          <div
            className="tooltip_list_item"
            onClick={() => {
              if (decided) {
                markAsDecided(id, false)
                removeFromGameplan({ suggestionId: id })
              } else {
                removeFromGameplan({ suggestionId: id })
              }
              setSuggestionRemovedStatus(id, activeTeeupId)
              setTimeout(() => {
                removeSuggestionRemovedStatus(id, activeTeeupId)
              }, 15_000)
              // hideTooltip();
            }}>
            <img
              src={images.removeFromGameplanNew}
              className="tooltip-icon"
              alt="remove from gameplan icon"
            />
            <span className="tooltip_list_item__title">
              Remove from Game Plan
            </span>
          </div>
        )}

        {!isSuggestionInPast && (
          <div
            className="tooltip_list_item"
            onClick={() => {
              toggleWithdraw({ suggestionId: id, isWithdrawn: !isWithdrawn })
              // hideTooltip();
            }}>
            {isWithdrawn ? (
              <img
                src={images.restoreNew}
                className="tooltip-icon"
                alt="restore icon"
              />
            ) : (
              <img
                src={images.iconTrash}
                className="tooltip-icon"
                alt="trash icon"
              />
            )}
            <span className="tooltip_list_item__title">
              {isWithdrawn ? "Restore" : "Withdraw"} Suggestion
            </span>
          </div>
        )}
      </>
    )
  }

  return (
    <ReactTooltip
      id="message-tooltip"
      class="extraClass"
      backgroundColor="#ffffff"
      place="top"
      effect="solid"
      afterHide={() => {
        setIsOpen(true)
      }}
      delayHide={300}
      // delayShow={500}
      getContent={messageId => {
        if (!messageId || !isOpen) {
          return false
        }
        // First try to find message by id in teeup's messages
        let message = selectMessageById(messageId)(store.getState())
        // If there is no message, find message by id in initial message's gameplans
        if (!message) {
          message = selectGameplanById(messageId)(store.getState())
          return false
        }
        const { isPinned, text, conversationTypeId, isSuggestion } = message
        return (
          <div className="tooltip_container">
            {isSuggestion ? (
              getSuggestionActions(message)
            ) : (
              <>
                {mentionedMessages && (
                  <div className="tooltip_list_item">
                    <img
                      src={images.replyMessageNew}
                      alt="reply message icon"
                    />
                    <span
                      className="tooltip_list_item__title"
                      onClick={() => {
                        setActiveTab(0)
                        setTimeout(() => {
                          setActiveHighlightedMessage(messageId)
                        }, 5000)
                        // hideTooltip();
                      }}>
                      {" "}
                      Show in conversation
                    </span>
                  </div>
                )}
                <div
                  className="tooltip_list_item"
                  onClick={() => {
                    mentionedMessages && setActiveTab(0)
                    setTimeout(() => {
                      setReplyMessage(message)
                      inputRef.current && inputRef.current.focus()
                    }, 1000)
                    // hideTooltip();
                  }}>
                  <img src={images.replyMessageNew} alt="reply message icon" />
                  <span className="tooltip_list_item__title">Reply</span>
                </div>
                {isPinned ? (
                  <div className="tooltip_list_item">
                    <img
                      src={images.unpinMessageNew}
                      alt="unpin message icon"
                    />
                    <span
                      className="tooltip_list_item__title"
                      onClick={() => {
                        pinUnpinMessage({
                          isPinned: false,
                          messageId,
                        })
                        hideTooltip()
                      }}>
                      {" "}
                      Remove from pinboard
                    </span>
                  </div>
                ) : (
                  <div className="tooltip_list_item">
                    <img src={images.pinMessageNew} alt="pin message icon" />
                    <span
                      className="tooltip_list_item__title"
                      onClick={() => {
                        pinUnpinMessage({
                          isPinned: true,
                          messageId,
                        })
                        // hideTooltip();
                      }}>
                      {" "}
                      Add to pinboard{" "}
                    </span>
                  </div>
                )}
                {text && conversationTypeId === conversationTypeIds.message && (
                  <div
                    className="tooltip_list_item"
                    onClick={() => {
                      copy(text)
                      // hideTooltip();
                    }}>
                    <img src={images.copyNew} alt="copy icon" />
                    <span className="tooltip_list_item__title">Copy Text</span>
                  </div>
                )}
              </>
            )}
          </div>
        )
      }}
    />
  )
}

const mapStateToProps = state => ({
  currentUser: selectUserStatus(state),
  mentionedMessages: selectMentionedMessages(state),
  activeTeeupId: selectActiveTeeupId(state),
})

const mapDispatchToProps = dispatch => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
  setNudgePeopleOpen: bindActionCreators(setNudgePeopleOpen, dispatch),
  setActiveHighlightedMessage: bindActionCreators(
    setActiveHighlightedMessage,
    dispatch
  ),
  setActiveTab: bindActionCreators(setActiveTab, dispatch),
  setSuggestionRemovedStatus: bindActionCreators(
    setSuggestionRemovedStatus,
    dispatch
  ),
  removeSuggestionRemovedStatus: bindActionCreators(
    removeSuggestionRemovedStatus,
    dispatch
  ),
  setModalPeopleNudge: bindActionCreators(setModalPeopleNudge, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageTooltip)
