import styled, { css } from "styled-components"

import { Subtitle, SubtitleL } from "../../../assets/styles/typographyStyles"

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 32px;

  ${({ token }) =>
    token &&
    css`
      margin: 0px 0px 16px 0px;
    `};
`

export const PasswordRequirementsContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--background-1, #f0f2f5);
  margin-top: 10px;
`

export const PasswordRequirementWrapper = styled.div`
  display: flex;
  alig-items center;
  gap: 6px;
`

export const ActionFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
`

export const ModalDescription = styled(SubtitleL)`
  color: var(--text-label-dark, #767676);
  font-weight: 400;
`

export const InputLable = styled(Subtitle)`
  color: var(--text-default, #222);
`

export const PasswordRequirement = styled(Subtitle)`
  color: var(--text-default, #222);
`

export const Input = styled.input`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--border-shadow, #e3e5e8);
  font-size: 18px;
  color: var(--text-default, #222);

  ::placeholder {
    color: #a9b0b9;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid var(--error-message, #f52d22);
    `};
`

export const ForgotPasswordAction = styled(Subtitle)`
  color: var(--text-labels, #595f82);
  text-decoration-line: underline;
  cursor: pointer;
  text-align: end;
`
