import { useState, useEffect, useCallback } from "react"

import { combineValidationErrors } from "@utils/combineValidationErrors"

export const useValidationForm = ({ formValues, validationSchema }) => {
  const [isValidating, setIsValidating] = useState(false)
  const [validationErrors, setValidationErrors] = useState(null)

  const validateForm = useCallback(async () => {
    if (!isValidating) {
      setIsValidating(true)
    }

    try {
      await validationSchema.validate(formValues, {
        abortEarly: false,
      })
      setValidationErrors(null)
      return null
    } catch (error) {
      const localErrors = combineValidationErrors(error)
      if (localErrors) {
        setValidationErrors(localErrors)

        return localErrors
      }
    }
  }, [...Object.values(formValues), validationSchema])

  useEffect(() => {
    if (!isValidating) return

    validateForm().then()
  }, Object.values(formValues))

  return {
    validationErrors,
    validateForm,
    setIsValidating,
  }
}
