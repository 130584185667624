import React, { useState } from "react"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { InputEl, ButtonEl } from "@components/common"
import { searchUnsplashPhoto } from "@config/externalApi"
import { BUTTON_VARIANTS } from "@constants/button"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"
import { useDelay } from "@hooks"
import { getRandomBase64Image } from "@utils/imageUtils"

import { PhotosList } from "./molecules"
import {
  Wrapper,
  SelectedPhoto,
  SubmitWrapper,
} from "./searchUnsplashPhotoStyles"

export const SearchUnsplashPhoto = ({ isCreateTeeup, setImage }) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [setSearchDelay, clearSearchDelay] = useDelay()
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [previewList, setPreviewList] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const handleSearch = newVal => {
    setSearch(newVal)
    clearSearchDelay()

    if (newVal) {
      setIsSearching(true)
      setSearchDelay(async () => {
        const res = await searchUnsplashPhoto(newVal, true)

        if (res) {
          setSelectedPhoto(res[0])
          setPreviewList(res)
        }
        setIsSearching(false)
      }, 1500)
    }
  }

  const handleSaveSelection = async () => {
    setIsSaving(true)
    const newImage = await getRandomBase64Image(null, selectedPhoto.urls.full)

    if (selectedPhoto && newImage) {
      setImage(newImage)
      if (isCreateTeeup) {
        dispatch(setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.MAIN))
      }
    }
  }

  return (
    <Wrapper $isCreateTeeup={isCreateTeeup}>
      <InputEl
        placeholder="Search photo"
        value={search}
        isLoading={isSearching}
        onChange={handleSearch}
      />

      {selectedPhoto && (
        <SelectedPhoto src={selectedPhoto.urls.regular} alt="selected-photo" />
      )}

      <PhotosList
        selectedPhotoId={selectedPhoto?.id}
        previewList={previewList}
        onSelect={setSelectedPhoto}
      />

      {!!selectedPhoto && (
        <SubmitWrapper $isCreateTeeup={isCreateTeeup}>
          <ButtonEl
            isBlack
            fullWidth
            variant={BUTTON_VARIANTS.ROUNDED}
            text="Save Selection"
            isLoading={isSaving}
            disabled={isSaving}
            onClick={handleSaveSelection}
          />
        </SubmitWrapper>
      )}
    </Wrapper>
  )
}

SearchUnsplashPhoto.propTypes = {
  isCreateTeeup: PropTypes.bool,
  setImage: PropTypes.func.isRequired,
}
