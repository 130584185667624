import React from "react"

import { SectionDescription } from "./atoms"
import { Contacts } from "./molecules"
import { Wrapper } from "./syncContactsStyles"

export const SyncContacts = () => (
  <div>
    <Wrapper>
      <SectionDescription />

      <Contacts />
    </Wrapper>
  </div>
)
