import React from "react"

import PropTypes from "prop-types"
import { View, Text } from "react-native"

import { conversationTypeIds } from "@configs/mappings"
import { truncateString } from "@utils/dataUtils"
import { gameplanUpdateTypes } from "@utils/messagesUtils"

import styles from "./gameplanSelectMessageStyles"
import SuggestionCard from "./suggestionCard/suggestionCard"

const isSuggestionMessageOfAction = (message, suggestionId, selectType) => {
  if (!message) return false

  return (
    message.conversationTypeId === conversationTypeIds.suggestion &&
    message.isSuggestion &&
    message.id === suggestionId &&
    selectType !== gameplanUpdateTypes.REPLACE
  )
}

const deduceFactors = (message, people, suggestions, isMyMessage) => {
  const {
    isDecided,
    isGamePlan,
    suggestionId,
    previousSuggestionId,
    senderId,
  } = message
  let selectType
  if (!isDecided && !isGamePlan) {
    selectType = gameplanUpdateTypes.REMOVE
  } else if (previousSuggestionId) {
    selectType = gameplanUpdateTypes.REPLACE
  } else {
    selectType = gameplanUpdateTypes.SELECT
  }

  const suggestion = suggestions[suggestionId]
  const previousSuggestion =
    selectType === gameplanUpdateTypes.REPLACE
      ? suggestions[previousSuggestionId]
      : null
  const user = people[senderId]
  const userDisplayName = truncateString(
    isMyMessage ? "I" : user?.username || "Unknown",
    20
  )

  return {
    selectType,
    suggestion,
    previousSuggestion,
    userDisplayName,
  }
}

const FullSelectMessage = ({
  suggestion,
  previousSuggestion,
  selectType,
  userDisplayName,
  people,
  isMyMessage,
  goToSuggestion,
  newDesign,
}) => {
  let actionMessage
  if (selectType === gameplanUpdateTypes.SELECT) {
    actionMessage = "placed this suggestion on the Game Plan"
  } else if (selectType === gameplanUpdateTypes.REMOVE) {
    actionMessage = "removed this suggestion from the Game Plan"
  } else {
    actionMessage = "changed the Game Plan from"
  }

  const suggestionCreator = people[suggestion.createdBy]
  const containerStyle = [styles.container, isMyMessage && styles.myContainer]

  if (selectType !== gameplanUpdateTypes.REPLACE) {
    return (
      <View style={containerStyle}>
        <Text style={styles.senderText}>
          {userDisplayName} {actionMessage}
        </Text>
        <SuggestionCard
          suggestion={suggestion}
          creator={suggestionCreator}
          onPress={() => goToSuggestion(suggestion)}
          newDesign={newDesign}
        />
      </View>
    )
  }

  return (
    <View style={containerStyle}>
      <Text style={styles.senderText}>
        {userDisplayName} {actionMessage}
      </Text>
      <SuggestionCard
        suggestion={previousSuggestion}
        creator={suggestionCreator}
        onPress={() => goToSuggestion(previousSuggestion)}
      />
      <Text style={styles.senderText}>to</Text>
      <SuggestionCard
        suggestion={suggestion}
        creator={suggestionCreator}
        onPress={() => goToSuggestion(suggestion)}
      />
    </View>
  )
}

FullSelectMessage.propTypes = {
  suggestion: PropTypes.object.isRequired,
  previousSuggestion: PropTypes.object,
  selectType: PropTypes.oneOf([
    gameplanUpdateTypes.SELECT,
    gameplanUpdateTypes.REMOVE,
    gameplanUpdateTypes.REPLACE,
  ]),
  userDisplayName: PropTypes.string.isRequired,
  people: PropTypes.object.isRequired,
  isMyMessage: PropTypes.bool,
  goToSuggestion: PropTypes.func.isRequired,
}

const SimpleSelectMessage = ({ selectType, isMyMessage }) => {
  let actionMessage
  actionMessage =
    selectType === gameplanUpdateTypes.REMOVE
      ? "I removed this suggestion from the Game Plan"
      : "and placed it on the Game Plan"

  const containerStyle = [styles.container, isMyMessage && styles.myContainer]

  return (
    <View style={containerStyle}>
      <Text style={styles.senderText}>{actionMessage}</Text>
    </View>
  )
}

SimpleSelectMessage.propTypes = {
  selectType: PropTypes.oneOf([
    gameplanUpdateTypes.SELECT,
    gameplanUpdateTypes.REMOVE,
    gameplanUpdateTypes.REPLACE,
  ]),
  isMyMessage: PropTypes.bool,
}

const GamePlanSelectMessage = ({
  people,
  suggestions,
  item,
  isMyMessage,
  neighboringMessage,
  onSuggestionTap,
  newDesign,
}) => {
  const actionDetails = deduceFactors(item, people, suggestions, isMyMessage)

  if (!actionDetails.suggestion) return false
  const isAttachedToSuggestion = isSuggestionMessageOfAction(
    neighboringMessage,
    actionDetails.suggestion.id,
    actionDetails.selectType
  )

  if (isAttachedToSuggestion) {
    return <SimpleSelectMessage {...actionDetails} isMyMessage={isMyMessage} />
  }

  return (
    <FullSelectMessage
      {...actionDetails}
      isMyMessage={isMyMessage}
      people={people}
      goToSuggestion={onSuggestionTap}
      newDesign={newDesign}
    />
  )
}

GamePlanSelectMessage.propTypes = {
  people: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  isMyMessage: PropTypes.bool,
  neighboringMessage: PropTypes.object,
  onSuggestionTap: PropTypes.func.isRequired,
}

export default GamePlanSelectMessage
