export const modalStyles = {
  content: {
    maxWidth: 600,
    width: "fit-content",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxSizing: "border-box",
    height: "max-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    overflow: "initial",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: 99,
  },
}

export const modalBottomStyles = {
  width: "100%",
  maxWidth: "unset",
  top: "unset",
  bottom: 0,
  left: 0,
  transform: "none",
}

export const tabletStyles = {
  width: "65%",
  maxWidth: "unset",
  top: "50%",
  transform: "translate(-50%, -50%)",
}

export const mobileStyles = {
  width: "95%",
  maxWidth: "unset",
  top: "50%",
  transform: "translate(-50%, -50%)",
}
