import { ReactNode, useEffect, useMemo, useState } from "react"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import {
  MainInfoParticipantsLabel,
  ParticipantList,
} from "@components/TeeupPreviewContent/teeupPreviewContentStyles"
import { teeupUserStatusPriorities } from "@config/enums"
import { getParticipantStatus, sortByStatusToTop } from "@utils/peopleUtils"
import { PREVIEW_PARTICIPANTS_LIMIT } from "@utils/teeupUtils"
import { usePreviewScreenStore } from "stores/previewScreen"
import { Participant } from "types/participant"

import { MockParticipants } from "../../MockParticipants"

interface Props {
  participants: Participant[]
  participantsCount?: number
}

interface ParticipantLabel {
  isEmpty: boolean
  content: ReactNode
}

const People = ({ participants, participantsCount }: Props) => {
  const [participantsLabel, setParticipantsLabel] =
    useState<ParticipantLabel | null>(null)
  const setIsParticipantsModalOpen = usePreviewScreenStore(
    state => state.setIsParticipantsModalOpen
  )

  const sortedParticipants = useMemo(() => {
    const goingStatusId = 2
    return (participants as any[])?.sort((a, b) =>
      sortByStatusToTop(a, b, goingStatusId)
    )
  }, [participants])

  useEffect(() => {
    formatParticipants(participants)
  }, [participants])

  const formatParticipants = (participants: Participant[]) => {
    if (!participants?.length) return

    const names = participants
      ?.filter(
        participant =>
          // @ts-ignore
          participant.status === teeupUserStatusPriorities.going
      )
      ?.map(participant => participant.name)

    let participantLabelObj: ParticipantLabel = {
      isEmpty: true,
      content: null,
    }

    switch (names.length) {
      case 0: {
        participantLabelObj.content = "No one is going"

        break
      }
      case 1: {
        participantLabelObj.content = (
          <div>
            <span>{names[0].split(" ")[0]}</span> is going
          </div>
        )

        break
      }
      case 2: {
        participantLabelObj.content = (
          <div>
            <span>{names[0].split(" ")[0]}</span> and{" "}
            <span>{names[1].split(" ")[0]}</span>
            are going
          </div>
        )

        break
      }
      default: {
        const remainingCount = names?.length - 2
        participantLabelObj.content = (
          <div>
            <span>{names[0].split(" ")[0]}</span>,{" "}
            <span>{names[1].split(" ")[0]}</span> and{" "}
            <b style={{ textDecoration: "underline" }}>{remainingCount} more</b>{" "}
            are going
          </div>
        )
      }
    }

    setParticipantsLabel(participantLabelObj)
  }

  const handleParticipantClick = () => {
    setIsParticipantsModalOpen(true)
  }

  return (
    <>
      <ParticipantList onClick={handleParticipantClick}>
        {sortedParticipants?.length > 0 ? (
          <>
            {sortedParticipants
              .slice(0, PREVIEW_PARTICIPANTS_LIMIT)
              .map(user => (
                <ParticipantIcon
                  avatar={user.avatar}
                  status={getParticipantStatus(user.status)}
                  defaultAvatar={user?.name?.[0] || "A"}
                  isOrganizer={user.isOrganiser}
                />
              ))}
            {participantsCount &&
              participantsCount > PREVIEW_PARTICIPANTS_LIMIT && (
                <ParticipantIcon
                  avatar=""
                  isFullDefaultAvatar
                  defaultAvatar={`+${
                    participantsCount - PREVIEW_PARTICIPANTS_LIMIT
                  }`}
                />
              )}
          </>
        ) : (
          <MockParticipants />
        )}
      </ParticipantList>

      {participantsLabel?.content && (
        <MainInfoParticipantsLabel>
          {participantsLabel?.content}
        </MainInfoParticipantsLabel>
      )}
    </>
  )
}

export default People
