import { useCallback } from "react"

import moment from "moment-timezone"

import { DAY_PARTS, MINUTES_DAY_PART_WIDTH } from "@contexts/TimeWheelContext"
import { roundMinutesToFifteen } from "@contexts/TimeWheelContext/helpers"

export const useEventMargin = () => {
  const getMarginLeft = useCallback(startDate => {
    const dateString = moment.isMoment(startDate)
      ? startDate.format()
      : startDate
    const roundedTime = roundMinutesToFifteen(dateString)

    return DAY_PARTS[moment(roundedTime).minutes()] * MINUTES_DAY_PART_WIDTH
  }, [])

  return { getMarginLeft }
}
