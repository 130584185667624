import React from "react"

import PropTypes from "prop-types"

import { Link } from "./linkStyles"

export const LinkEl = ({ isLarge, appLink, text }) => (
  <Link href={appLink} isLarge={isLarge}>
    {text}
  </Link>
)

LinkEl.propTypes = {
  isLarge: PropTypes.bool,
  appLink: PropTypes.string,
  text: PropTypes.string,
}
