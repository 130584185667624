import { useInboxStore } from "stores/inboxStore"
import Card from "./Card"
import Empty from "./Empty"
import ArchiveModal from "./Modals/ArchiveModal"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "./Loader"
import EndMessage from "./EndMessage"
import { FetchTeeupsArgs, INBOX_TABS } from "types/inbox"
import { forwardRef, useCallback } from "react"
import { useHistory } from "react-router-dom"
import {
  joinTeeupInvitation,
  updateTeeupTrashStatus,
} from "@actions/teeupActions"
import { useSelector } from "react-redux"
import { selectUserId } from "@selectors/user"
import { TeeupItem } from "types/teeup"
import { useSnackbarStore } from "stores/snackbarStore"
import UnderlineModal from "./Modals/UnderlineModal"
import OrganizerArchive from "./Modals/OrganizerArchive"

interface Props {
  fetchTeeUps: (state: FetchTeeupsArgs, shouldClearPrevState: boolean) => void
}

const TeeUpList = ({ fetchTeeUps }: Props) => {
  const isLoading = useInboxStore(state => state.isLoading)
  const setIsLoading = useInboxStore(state => state.setIsLoading)
  const teeUps = useInboxStore(state => state.teeUps)
  const setTeeUps = useInboxStore(state => state.setTeeUps)

  const pagination = useInboxStore(state => state.pagination)
  const setPagination = useInboxStore(state => state.setPagination)
  const isArchiveTab = useInboxStore(
    state => state.currentOpenTab.value === INBOX_TABS.ARCHIVE
  )
  const currentOpenTab = useInboxStore(state => state.currentOpenTab)
  const selectedFilters = useInboxStore(state => state.selectedFilters)
  const searchValue = useInboxStore(state => state.searchValue)
  const currentPage = useInboxStore(state => state.pagination.page)
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)
  const users = useInboxStore(store => store.users)

  const userId = useSelector(selectUserId)
  const history = useHistory()

  const handleNextPage = () => {
    setPagination({ ...pagination, page: pagination.nextPage })
  }

  const handleOpenTeeUp = useCallback(
    (teeUp: TeeupItem, removedFromById?: number | null) => {
      if (removedFromById) {
        const participant = users[removedFromById]
        if (!participant) return

        setSnackbarConfig({
          open: true,
          message: `"${participant.name}" has removed you from "${teeUp.name}"`,
        })
        return
      }
      history.push(`/teeUps/${teeUp.id}`)
    },
    [users]
  )

  const onAcceptInvitation = useCallback(
    async (teeUp: TeeupItem) => {
      if (!userId) return

      await joinTeeupInvitation({
        teeupId: teeUp.id,
        userId,
        categoryId: teeUp.categoryId,
      })
      handleOpenTeeUp(teeUp)
    },
    [userId]
  )

  const onDismissInvitation = useCallback(async (teeUp: TeeupItem) => {
    await updateTeeupTrashStatus(teeUp.id)
    setIsLoading(true)
    setTeeUps([])
    fetchTeeUps(
      { currentOpenTab, selectedFilters, searchValue, currentPage },
      true
    )
  }, [])

  const onPreviewClick = useCallback(async (teeUp: TeeupItem) => {
    history.push(`/teeUps/${teeUp.id}`)
  }, [])

  if (!isLoading && teeUps.length === 0) return <Empty />
  else if (isLoading && teeUps.length === 0) return <Loader />

  return (
    <div>
      <div className="mx-auto my-0 xs:w-[99vw] md:max-w-[840px] xs:mt-3 md:mt-8 xs:px-3 md:px-6">
        <InfiniteScroll
          dataLength={teeUps.length}
          className="flex flex-col gap-2.5 !overflow-visible"
          scrollableTarget="infinite-scroll-target"
          next={handleNextPage}
          hasMore={teeUps.length < pagination.total}
          scrollThreshold={0.8}
          loader={<Loader />}
          endMessage={<EndMessage />}>
          {teeUps.map(teeUp => (
            <Card
              teeUp={teeUp}
              key={teeUp.id}
              isArchiveTab={isArchiveTab}
              onClick={handleOpenTeeUp}
              onAcceptInvitation={onAcceptInvitation}
              onDismissInvitation={onDismissInvitation}
              onPreviewClick={onPreviewClick}
              refetchTeeUps={() => {
                setTeeUps([])
                fetchTeeUps(
                  { currentOpenTab, selectedFilters, searchValue, currentPage },
                  true
                )
              }}
            />
          ))}
        </InfiniteScroll>
      </div>

      <ArchiveModal />
      <OrganizerArchive />
    </div>
  )
}

export default TeeUpList
