import React, { useEffect, useState, memo } from "react"

import PropTypes from "prop-types"
import { View } from "react-native"
import { useDispatch, useSelector } from "react-redux"

import { setModalInfo } from "@actions/commonActions"
import { reactionTypes } from "@configs/mappings"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import {
  selectTeeupReactionsBySuggestionId,
  selectTeeupPeople,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"

import { addReaction } from "../../middlewares/backendMiddleware"

import { GameplansRowStyles } from "./gameplansStyles"
import { ReactionButtonViewMemoized } from "./reactionButtonView"

const ReactionButton = ({
  suggestionId,
  inSuggestion,
  isFromSuggestionListHeader,
  inactive,
  newDesign,
  disableReaction,
  previewMode,
  isMyMessage,
  isInNudge,
}) => {
  const [userReaction, setUserReaction] = useState(-1)
  const [pressedButton, setPressedButton] = useState(-1)
  const [isLoadingReaction, setIsLoadingReaction] = useState(false)
  const [reactionNumber, setReactionNumber] = useState({
    [reactionTypes.upvote]: 0,
    [reactionTypes.downvote]: 0,
  })
  const [sections, setSections] = useState({ likes: [], dislikes: [] })

  //*----- SELECTORS -----//
  const reactions = useSelector(
    selectTeeupReactionsBySuggestionId(suggestionId)
  )
  const people = useSelector(selectTeeupPeople)
  const userId = useSelector(selectUserId)
  const teeup = useSelector(selectActiveTeeup)

  const dispatch = useDispatch()

  useEffect(() => {
    let timer
    if (isLoadingReaction) {
      timer = setTimeout(() => {
        dispatch(
          setModalInfo({
            show: true,
            title: "There was a mix up.",
          })
        )
        setIsLoadingReaction(false)
        setUserReaction(-1)
      }, 3000)

      return () => clearTimeout(timer)
    } else {
      // return () => setSections({ likes: [], dislikes: [] })
    }
  }, [isLoadingReaction])

  //*---- UPDATE FROM BACKEND ----//
  useEffect(() => {
    let updates = {}
    const likes = []
    const dislikes = []
    if (reactions) {
      reactions.map(({ reactionId, reactedBy }) => {
        if (Boolean(reactedBy?.length)) {
          updates[reactionId] = reactedBy.length
          let data = reactedBy.map(peopleId => {
            return {
              id: peopleId,
              name: people[peopleId]?.name,
              avatar: people[peopleId]?.avatar,
              status: people[peopleId]?.status,
            }
          })

          if (reactionId === 2) {
            if (data.some(user => user.id === userId)) {
              setUserReaction(2)
            }
            likes.push(...data)
          } else if (reactionId === 3) {
            if (data.some(user => user.id === userId)) {
              setUserReaction(3)
            }
            dislikes.push(...data)
          }
        }

        if (updates && !updates[reactionTypes.upvote]) {
          updates[reactionTypes.upvote] = 0
        }
        if (updates && !updates[reactionTypes.downvote]) {
          updates[reactionTypes.downvote] = 0
        }
        if (updates && !updates[reactionTypes.notVote]) {
          updates[reactionTypes.notVote] = 0
        }
      })
    }
    setSections({ likes, dislikes })
    setReactionNumber(updates) // update reactions object
    setIsLoadingReaction(false)
  }, [people, reactions, userId])

  const onReaction = reactionId => {
    setIsLoadingReaction(true)
    setPressedButton(reactionId)
    addReaction({
      reactionId: reactionId,
      suggestionId,
    })

    if (reactionId === 2) {
      userReaction === 2 ? setUserReaction(-1) : setUserReaction(2)
    }
    if (reactionId === 3) {
      userReaction === 3 ? setUserReaction(-1) : setUserReaction(3)
    }
  }

  return (
    <>
      <View
        testID="reactionView"
        style={[
          GameplansRowStyles.reactionsContainer,
          isFromSuggestionListHeader &&
            GameplansRowStyles.reactionsContainerInSuggestionHeader,
        ]}>
        <>
          <ReactionButtonViewMemoized
            id={reactionTypes.upvote}
            loadingReaction={isLoadingReaction}
            disableReaction={disableReaction}
            teeup={teeup}
            previewMode={previewMode}
            isMyMessage={isMyMessage}
            inSuggestion={inSuggestion}
            isInNudge={isInNudge}
            sections={sections.likes}
            suggestionId={suggestionId}
            userReaction={userReaction}
            isPressedButton={pressedButton}
            onReaction={onReaction}
            number={reactionNumber}
            inactive={inactive}
            newDesign={newDesign}
          />

          <ReactionButtonViewMemoized
            id={reactionTypes.downvote}
            loadingReaction={isLoadingReaction}
            disableReaction={disableReaction}
            teeup={teeup}
            previewMode={previewMode}
            isMyMessage={isMyMessage}
            inSuggestion={inSuggestion}
            isInNudge={isInNudge}
            sections={sections.dislikes}
            suggestionId={suggestionId}
            userReaction={userReaction}
            isPressedButton={pressedButton}
            onReaction={onReaction}
            inactive={inactive}
            newDesign={newDesign}
            number={reactionNumber}
          />
        </>
      </View>
    </>
  )
}

export default memo(ReactionButton)

ReactionButton.propTypes = {
  reactions: PropTypes.array,
  disableReaction: PropTypes.bool,
  previewMode: PropTypes.bool,
  isMyMessage: PropTypes.bool,
  suggestionId: PropTypes.number,
  customReactionId: PropTypes.number,
  teeup: PropTypes.object,
  inSuggestion: PropTypes.bool,
  inactive: PropTypes.bool,
  isInNudge: PropTypes.bool,
}
