import styled from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle5,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 20px;
`

export const Icon = styled(SvgIcon)`
  width: 40px;
  height: 40px;
`

export const Title = styled(NTitle5)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NCaptionS)`
  color: var(--gray5);
  text-align: center;
`
