import { useEffect } from "react"

import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { selectUserId } from "@selectors/activeTeeup"
import { getPreviewIgnoredStatuses } from "@utils/getPreviewIgnoredStatuses"

export const useTeeupRedirect = ({
  isPreviewLoading,
  previewInfo,
  setIsRedirectChecking,
}) => {
  const history = useHistory()
  const userId = useSelector(selectUserId)

  useEffect(() => {
    try {
      const isAlreadyInvited = previewInfo?.participants.some(
        participant => participant.id === userId
      )
      setIsRedirectChecking(true)
      const ignoredStatuses = getPreviewIgnoredStatuses()

      if (isPreviewLoading || !previewInfo || !isAlreadyInvited) return

      const { status, value } = previewInfo.invitee
      if (
        (!ignoredStatuses.includes(status) && previewInfo?.teeupId && value) ||
        isAlreadyInvited
      ) {
        history.push(`/teeUps/${previewInfo?.teeupId}`)
      }
    } catch (error) {
      console.error("useTeeupRedirect catch ERROR:", {
        error,
      })
    } finally {
      setIsRedirectChecking(false)
    }
  }, [isPreviewLoading, previewInfo])
}
