import { useEffect } from "react"

export const useCatchInviteeData = ({
  previewInfo,
  setStatus,
  setFullName,
  setTimezone,
  setEmail,
}) => {
  useEffect(() => {
    if (previewInfo) {
      const { status, name, timezone, email, value } = previewInfo?.invitee

      if (status) {
        setStatus(status)
      }
      if (name) {
        setFullName(name)
      }
      if (timezone) {
        setTimezone(timezone)
      }
      if (email) {
        setEmail(value)
      }
    }
  }, [previewInfo])
}
