import React from "react"

import { IoIosClose } from "react-icons/io"

import { Title, Header, CrossButton } from "./modalHeaderStyles"

export const ModalHeader = ({ title, onClose }) => (
  <Header>
    <Title>{title}</Title>

    <CrossButton onClick={onClose} data-testid="crossIcon">
      <IoIosClose size={32} color="#a9b0b9" />
    </CrossButton>
  </Header>
)
