import React, { useContext } from "react"

import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { ContactWarning, AlreadyHaveAccount } from "../atoms"
import { RSVPBadgeType } from "../constants"
import { useDoneApproved } from "../hooks"
import ParticipantsModal from "../modals/ParticipantModal"
import {
  InviteMainInfo,
  RSVPBadge,
  MessageTheGroup,
  WhereSuggestions,
  InAppButtons,
} from "../molecules"
import {
  PreviewContent,
  PreviewBackground,
  RestContentWrapper,
} from "../teeupPreviewContentStyles"
import {
  SetRSVPModal,
  InvitesSuggest,
  ResponseInfoModal,
  GuestDoneModal,
} from "components"

import SentTo from "./SentBy"
import { useScreenWidth } from "@hooks"

export const PreviewScreen = () => {
  const {
    previewInfo,
    isAuthenticated,
    contactWarning,
    isRSVPStatusBadge,
    RSVPWarningBadgeVal,
    isStatusModal,
    isResponseInfoModal,
    isStatusConfirmedModal,
    confirmingBadgeVal,
    isSharedLink,
  } = useContext(TeeupPreviewContext)

  const { isSmallSize } = useScreenWidth()
  const { isDoneModal, closeDoneModal } = useDoneApproved(
    isStatusConfirmedModal
  )

  const isRsvpBadge =
    isRSVPStatusBadge && !confirmingBadgeVal && !isResponseInfoModal

  return (
    <PreviewContent $isSmallSize={isSmallSize}>
      <PreviewBackground />

      <InviteMainInfo />

      {(!!RSVPWarningBadgeVal || confirmingBadgeVal) && (
        <RSVPBadge
          type={RSVPBadgeType.WARNING}
          message={RSVPWarningBadgeVal || confirmingBadgeVal}
        />
      )}

      {isRsvpBadge && <RSVPBadge type={RSVPBadgeType.SUCCESS} />}

      {contactWarning && <ContactWarning message={contactWarning.message} />}

      {!isAuthenticated && (
        <>
          <InvitesSuggest isFullWidth={isSmallSize} />

          <SentTo
            isSentToShown={!isSharedLink}
            sentTo={previewInfo?.invitee?.value}
          />
        </>
      )}

      <RestContentWrapper>
        {isAuthenticated && <WhereSuggestions type={GAMEPLAN_TYPES.when} />}

        <WhereSuggestions type={GAMEPLAN_TYPES.where} />

        {!isAuthenticated && <MessageTheGroup />}

        {!isAuthenticated && <AlreadyHaveAccount />}

        {isAuthenticated && <InAppButtons isAllDisabled={!previewInfo} />}
      </RestContentWrapper>

      {!isAuthenticated && isStatusModal && <SetRSVPModal />}

      {isResponseInfoModal && <ResponseInfoModal />}

      <ParticipantsModal participants={previewInfo?.participants} />

      {isDoneModal && !isStatusConfirmedModal && (
        <GuestDoneModal
          onClose={closeDoneModal}
          isStatusModalOpen={isStatusConfirmedModal}
        />
      )}
    </PreviewContent>
  )
}
