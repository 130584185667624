import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleXs } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MainLeft = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const Text = styled(NSubtitleXs)`
  font-weight: ${NFontWeight.bold};
`

export const ArrowIcon = styled(SvgIcon)`
  transition: all 0.2s ease-in-out;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          transform: rotate(0);
        `
      : css`
          transform: rotate(-90deg);
        `};
`
