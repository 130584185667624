import _ from "lodash"
import { createSelector } from "reselect"

import { selectActiveTeeupGroupId } from "@selectors/activeTeeup"
import { selectRequestsList } from "@selectors/requests"
import {
  membershipRules,
  checkUserGroupMembership,
  getApprovalStatus,
} from "@utils/groupUtils"

const selectGroup = state => state.group

const selectUserId = state => state.user.userInfo.id

export const selectGroupsIds = createSelector(
  selectGroup,
  group => group.groupsId || {}
)

export const selectGroups = createSelector(
  selectGroup,
  group => group.groups || []
)

export const selectGroupsTeeups = createSelector(
  selectGroup,
  group => group.groupsTeeups || {}
)

export const selectCreateGroupValues = createSelector(
  selectGroup,
  group => group.createGroup || {}
)

export const selectGroupsByActiveTeeupGroupId = createSelector(
  selectGroups,
  selectActiveTeeupGroupId,
  (groups, activeTeeupGroupId) =>
    _.filter(groups, ({ id }) => id === activeTeeupGroupId) || []
)

export const selectActiveGroup = createSelector(
  selectGroup,
  group => group.activeGroup || {}
)

export const selectActiveGroupMembers = createSelector(
  selectActiveGroup,
  group => group.members || []
)

export const selectActiveGroupMembersIds = createSelector(
  selectActiveGroupMembers,
  members => members.map(member => member.id)
)

export const selectUserGroupMembership = createSelector(
  selectActiveGroup,
  selectUserId,
  (activeGroup, userId) =>
    checkUserGroupMembership({
      activeGroup,
      userId,
    })
)

export const selectUserGroupMembershipStatus = createSelector(
  selectActiveGroupMembers,
  selectUserId,
  (members, userId) => {
    const member = _.find(members, member =>
      member.userid ? member.userid === userId : member.id === userId
    )
    return member ? member.roleId : null
  }
)

export const selectGroupApprovalStatus = createSelector(
  selectActiveGroup,
  selectRequestsList,
  selectUserGroupMembership,
  (activeGroup, requests, isJoined) => {
    const approvalRequestStatus =
      !isJoined && activeGroup.membershipRule === membershipRules.approval
        ? getApprovalStatus({ activeGroup, requests })
        : null
    return approvalRequestStatus
  }
)

export const selectGroupsUserCanJoin = userId =>
  createSelector(selectGroups, groups => {
    return (
      groups.filter(
        group =>
          !group.members || !group.members.some(member => member.id === userId)
      ) || []
    )
  })
