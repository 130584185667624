import _ from "lodash"

const membershipRules = {
  open: "Open",
  invite: "Invite",
  approval: "Approval",
}

const memberRoles = {
  organizer: 1,
  member: 2,
  invited: 3,
  awaitingApprove: 4,
}

const checkUserGroupMembership = ({ activeGroup, userId }) =>
  _.some(
    activeGroup.members,
    member =>
      member &&
      (member.roleId === memberRoles.organizer ||
        member.roleId === memberRoles.member) &&
      (member.userid ? member.userid === userId : member.id === userId)
  )

const filterGroupMembers = ({ members = [], currectUserStatus }) => {
  if (currectUserStatus === memberRoles.organizer) {
    return members
  }
  return currectUserStatus === memberRoles.member
    ? _.filter(members, member => member.roleId !== memberRoles.awaitingApprove)
    : _.filter(
        members,
        member =>
          member.roleId !== memberRoles.awaitingApprove &&
          member.roleId !== memberRoles.invited
      )
}

const getApprovalStatus = ({ activeGroup, requests }) => {
  let approvalStatus = null
  Object.keys(requests).forEach(requestId => {
    // requests[requestId]
    //check if group has an invitation request and if that request were accepted
    if (
      requests[requestId].groupId === activeGroup.id
      // TODO add user Id
      // requests[requestId].statusId === requestStatusId.accepted
    ) {
      approvalStatus = requests[requestId].statusId
    }
  })
  return approvalStatus
}

export {
  membershipRules,
  memberRoles,
  checkUserGroupMembership,
  filterGroupMembers,
  getApprovalStatus,
}
