import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../../theme/"

export const DayCalendarStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  headerContainer: {
    height: Units.responsiveValue(51),
    shadowOffset: { height: Units.responsiveValue(3) },
    shadowColor: AppColors.brand.black,
    shadowOpacity: 0.08,
    shadowRadius: Units.responsiveValue(3),
    elevation: 2,
    backgroundColor: AppColors.brand.white,
    // paddingHorizontal: Units.responsiveValue(12),
    paddingBottom: Units.responsiveValue(7),
  },
  daysLineContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  weekdayContainer: {
    alignItems: "center",
    paddingBottom: Units.responsiveValue(3),
  },
  dayText: {
    ...AppFonts.smallestBold,
    letterSpacing: -Units.responsiveValue(0.1),
    color: AppColors.brand.warmGrey2,
    // width: Units.responsiveValue(14),
  },
  dayTextContainer: {
    // paddingHorizontal: Units.responsiveValue(8),
  },
  activeDayText: {
    ...AppFonts.biggerBold,
    letterSpacing: -Units.responsiveValue(0.15),
    color: AppColors.brand.white,
  },
  activeDayContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.pink,
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(28),
    borderRadius: Units.responsiveValue(5.8),
  },
  dayOfTheWeekText: {
    ...AppFonts.smallestBold,
    letterSpacing: -Units.responsiveValue(0.1),
    textTransform: "uppercase",
    color: AppColors.brand.black,
  },
  delimiter: {
    flex: 1,
    height: 1,
    backgroundColor: AppColors.brand.grey2,
  },
})

export const DayCalendarTimetableStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  topPadding: {
    height: Units.responsiveValue(20),
  },
  eventsOverlayContainer: {
    position: "absolute",
    top: Units.responsiveValue(20),
    left: 0,
    right: 0,
    bottom: 0,
  },
  eventsOverlay: {
    flex: 1,
    marginLeft: Units.responsiveValue(56),
    marginRight: Units.responsiveValue(12),
  },
  suggestionText: {
    marginTop: Units.responsiveValue(9),
    marginLeft: Units.responsiveValue(8),
    ...AppFonts.smallDemibold,
    lineHeight: Units.responsiveValue(16),
    letterSpacing: -Units.responsiveValue(0.2),
    color: AppColors.brand.pink,
  },
  suggestionDragBox: KNOB_HEIGHT => ({
    height: KNOB_HEIGHT,
    width: "100%",
    backgroundColor: "transparent", //Fix Android view not touchable
  }),
})

export const selectedTimeBlockContainer = ({
  position,
  ROW_HEIGHT,
  // KNOB_HEIGHT
}) => ({
  position: "absolute",
  top: position * ROW_HEIGHT,
  width: "100%",
})

export const selectedTimeBlock = ({ ROW_HEIGHT, multiplyIndex }) => ({
  height: Units.responsiveValue(ROW_HEIGHT * multiplyIndex || 1),
  borderWidth: 1,
  borderColor: AppColors.brand.pink,
  backgroundColor: AppColors.brand.pink03,
})
