import styled from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle6,
} from "@assets/styles/newTypographyStyles"

export const ModalTitle = styled(NTitle6)`
  width: 100%;
  font-weight: ${NFontWeight.bold};
  text-align: left;
`

export const ModalDescription = styled(NCaptionS)`
  width: 100%;
  color: var(--gray4);
  margin: 10px 0 30px 0;
  text-align: left;
`
