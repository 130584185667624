import { useContext } from "react"

import { InvitePeopleContext } from "@contexts/InvitePeopleContext"
import { doesIncludes } from "@utils/doesIncludes"

export const useTypingSearchResults = ({ isOnlyNumbers, selectedContacts }) => {
  const { filteredContacts, searchValue } = useContext(InvitePeopleContext)

  return filteredContacts
    .map(contact => {
      const { phoneNumbers, name, username, emails } = contact

      const matchingContact = isOnlyNumbers
        ? phoneNumbers.find(phoneObj => phoneObj.number.includes(searchValue))
        : emails.find(emailObj => doesIncludes(emailObj.email, searchValue))

      if (matchingContact) {
        return {
          ...contact,
          matchingContact: isOnlyNumbers
            ? matchingContact.number
            : matchingContact.email,
        }
      }

      return doesIncludes(name, searchValue) ||
        doesIncludes(username, searchValue)
        ? {
            ...contact,
            matchingContact: emails[0]?.email || phoneNumbers[0]?.number,
          }
        : null
    })
    .filter(
      contact =>
        contact && !selectedContacts.some(({ id }) => id === contact.id)
    )
}
