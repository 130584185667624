import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--pink);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 15px;
  margin: 5px 0;
`

export const Text = styled(NSubtitleS)`
  color: var(--darkRed);
  font-weight: ${NFontWeight.bold};
`
