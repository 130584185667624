export const CREATE_TEEUP_SCREENS = {
  MAIN: "MAIN",
  SELECT_IMAGE: "SELECT_IMAGE",
  SETTINGS: "SETTINGS",
  ADD_PEOPLE: "ADD_PEOPLE",
}

export const TEEUPS_CATEGORIES = {
  INBOX: 1,
  ARCHIVE: 2,
  TRASH: 3,
}

export const TEEUP_SETTINGS_TYPES = {
  SELECT_IMAGE: "SELECT_IMAGE",
  PARTICIPANTS: "PARTICIPANTS",
  PERMISSIONS: "PERMISSIONS",
}

export const TEEUP_PERMISSIONS_SETTINGS = {
  allow_anyone_to: {
    title: "Allow anyone to",
    items: [
      {
        title: "Invite Others",
        description:
          "When invite others is toggled on, then all users have limited access to manage participants.",
        key: "allowInviteOthers",
      },
      {
        title: "Change TeeUp title",
        key: "allowChangeTitle",
      },
      {
        title: "Change Game Plan",
        description:
          "Includes placing suggestion on, removing from, & withdrawing suggestion",
        key: "allowChangeGameplanOptions",
      },
      {
        title: 'Mark Game Plan items as "Decided"',
        key: "allowDecideGameplan",
      },
      {
        title: "Change TeeUp to “All Set” or “Planning”",
        key: "allowPromoteToAllSetOrPlanning",
      },
      {
        title: "Set the TeeUp as “Ended”",
        key: "allowPromoteToEnded",
      },
    ],
  },
  participant_management: {
    title: "Participant management",
    items: [
      {
        type: TEEUP_SETTINGS_TYPES.PARTICIPANTS,
        title: "Manage Participants",
        key: "manage_participants",
      },
    ],
  },
}
