import React, { useContext } from "react"

import PropTypes from "prop-types"

import { InputEl } from "@components/common"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"

export const EmailSearch = ({ onChange }) => {
  const { searchValue } = useContext(InvitePeopleContext)

  return (
    <InputEl
      isSearch
      isNoClear
      testId="invite-search"
      value={searchValue}
      placeholder="Invite people by name or email adress"
      onChange={onChange}
    />
  )
}

EmailSearch.propTypes = {
  onChange: PropTypes.func,
}
