import _ from "lodash"

import endpoints from "@config/endpoints"
import { selectTeeupsState, selectArchivedTeeups } from "@selectors/teeups"
import { formatSuggestion } from "@utils/gamePlanUtils"

import api from "../api"
import { store } from "../index"

import actionTypes from "./actionTypes"

const getNotifications = () => ({ type: actionTypes.GET_NOTIFICATIONS })
const readNotification = notification => ({
  type: actionTypes.READ_NOTIFICATION,
  payload: notification,
})
const removeNotification = notification => ({
  type: actionTypes.REMOVE_NOTIFICATION,
  payload: notification,
})
const updateNotificationLastSeen = () => ({
  type: actionTypes.UPDATE_NOTIFICATION_LAST_SEEN,
})
const startUserNotifications = () => ({
  type: actionTypes.NOTIFICATIONS_START,
})

export const loadNotifications = () => {
  const url = endpoints.teeup.teeupsState()

  return api.client
    .get(url)
    .then(({ data: response }) => {
      let teeupsToCheck = []
      let teeupsStateObj = {}

      const teeups = selectTeeupsState(store.getState())
      const archivedTeeups = selectArchivedTeeups(store.getState())
      const teeupsWithEvents = [...teeups, ...archivedTeeups]
      const teeupsState = response.map(r => {
        const event = {
          ...r,
          when: r.when ? formatSuggestion(r.when) : r.when,
        }
        const aTeeup = _.find(
          teeupsWithEvents,
          teeup => teeup.id === event.teeupId
        )
        if (aTeeup) {
          teeupsToCheck.push(aTeeup)
          teeupsStateObj[event.teeupId] = event
        }
        return event
      })
      store.dispatch({
        type: actionTypes.GOT_TEEUPS_STATE,
        payload: teeupsState,
      })
    })
    .catch(error => {
      console.log("Fetch teeups state error", error)
    })
}

export default {
  getNotifications,
  readNotification,
  removeNotification,
  updateNotificationLastSeen,
  startUserNotifications,
}
