import React from "react"

import propTypes from "prop-types"
import { TouchableOpacity, View, Text, Image } from "react-native"

import { images } from "@utils/imageUtils"

import { styles } from "./styles"

const SORT_SCREEN = {
  title: "Sort by",
  screen: "sortBy",
}

const DEFAULT_SORT_TITLE = "Recent activity"

const LineButton = ({ onPress, title, style, selectedValue }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={[styles.lineButton, style]}>
      <Text style={styles.buttonTitle}>{title}</Text>
      {selectedValue && (
        <Text numberOfLines={1} style={styles.selectedValue}>
          {selectedValue}
        </Text>
      )}
      <Image style={styles.chevron} source={images.chevron} />
    </View>
  </TouchableOpacity>
)
LineButton.propTypes = {
  title: propTypes.string.isRequired,
  onPress: propTypes.func.isRequired,
  style: propTypes.oneOfType([propTypes.object, propTypes.number]),
  selectedValue: propTypes.string,
}

const LineHeader = ({ title }) => (
  <View style={styles.titleWrapper}>
    <Text style={styles.titleText}>{title}</Text>
  </View>
)
LineHeader.propTypes = {
  title: propTypes.string.isRequired,
}

const DefaultContent = ({
  teeupSorting = {},
  teeupFiltering,
  screensButtons,
  onScreenOpen,
}) =>
  Object.values(screensButtons).map((button, index) => {
    return button.screen === SORT_SCREEN.screen ? (
      <View key={`defaultSectionItem-${button.screen}`}>
        <LineHeader title={"Sort"} />
        <LineButton
          key={`${button.title}-${index}`}
          onPress={onScreenOpen(SORT_SCREEN)}
          style={styles.sortLine}
          title={button.title}
          selectedValue={teeupSorting.title || DEFAULT_SORT_TITLE}
        />
      </View>
    ) : (
      <View key={`defaultSectionItem-${button.screen}`}>
        {index === 1 && <LineHeader title={"Filter"} />}
        <LineButton
          key={`${button.title}-${index}`}
          onPress={onScreenOpen(button)}
          style={styles.lineButtonSeparator}
          title={button.title}
          selectedValue={
            teeupFiltering[button.screen] &&
            teeupFiltering[button.screen].length > 0
              ? teeupFiltering[button.screen]
                  .map(filter => filter.title)
                  .join(", ")
              : null
          }
        />
      </View>
    )
  })

DefaultContent.propTypes = {
  teeupSorting: propTypes.shape({
    title: propTypes.string,
    screen: propTypes.string,
  }),
  teeupFiltering: propTypes.object,
  screensButtons: propTypes.object,
  onScreenOpen: propTypes.func,
}

export default DefaultContent
