import endpoints from "@config/endpoints"
import { loginProviders } from "@config/enums"
import { prepareGoogleContacts } from "@utils/contacts"
import api, { customCalInstance } from "api"
import { store } from "index"

import actionTypes from "./actionTypes"

const getContacts = () => ({
  type: actionTypes.GET_CONTACTS,
})

const fetchBookContacts = () => {
  api.client
    .get(endpoints.fetchContacts())
    .then(response => {
      store.dispatch(gotBookContacts(response.data.data))
    })
    .catch(error => {
      console.log("fetchUserTeeupsUsers error ")
      console.log(error)
    })
}

const gotBookContacts = contacts => ({
  type: actionTypes.GOT_BOOK_CONTACTS,
  payload: { contacts },
})

const addTagsToUsers = (userId, body, onTagUsersEnd) => ({
  type: actionTypes.ADD_TAGS_TO_USERS,
  payload: { userId, body, onTagUsersEnd },
})

const removeTagsFromUsers = (userId, body, onTagUsersEnd) => ({
  type: actionTypes.REMOVE_TAGS_FROM_USERS,
  payload: { userId, body, onTagUsersEnd },
})

const initiateSendEmailsAndSMS = (invitees, name, teeupUrl, type) => ({
  type: actionTypes.INITIATE_SEND_INVITES,
  payload: { invitees, name, teeupUrl, type },
})

const initiateEmitSocketInvitees = invited => ({
  type: actionTypes.INITIATE_EMIT_SOCKET_INVITES,
  payload: { invited },
})

const changeContactDefaultType = updatedContact => ({
  type: actionTypes.CONTACT_CHANGE_DEFAULT_TYPE,
  payload: { updatedContact },
})

const resyncContactsBook = () => ({
  type: actionTypes.RESYNC_CONTACTS_BOOK,
})

const getGoogleContacts = (accessToken, connectorEmail) => {
  customCalInstance({
    url: `${
      endpoints.googleContactsApi
    }${endpoints.contacts.getGoogleContacts()}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then(({ data }) => {
      store.dispatch(
        gotBookContacts(prepareGoogleContacts(data.connections, connectorEmail))
      )
    })
    .catch(error => {
      throw error
    })
}

const getAppleContacts = (accessToken, connectorEmail) => {
  customCalInstance({
    url: `${endpoints.appleApi}${endpoints.contacts.getAppleContacts()}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  }).then(() => {
    // TODO: make a logic
  })
}

const linkSyncContacts = (accessToken, provider) => {
  return api.client
    .post(`${endpoints.contacts.linkContacts()}`, { signature: accessToken })
    .then(({ data: { connectors, isCreated } }) => {
      const newConnector = connectors.at(-1)

      if (!newConnector) return
      if (!isCreated) return { email: newConnector.email, isTryAnother: true }

      store.dispatch({
        type: actionTypes.SET_NEW_SYNC_CONTACT,
        payload: newConnector,
      })

      if (provider === loginProviders.google) {
        getGoogleContacts(newConnector.accessToken, newConnector.email)
      } else if (provider === loginProviders.apple) {
        getAppleContacts(newConnector.accessToken, newConnector.email)
      }

      return newConnector
    })
    .catch(error => {
      throw error
    })
}

const getContactsConnectors = () => {
  return api.client
    .get(`${endpoints.contacts.getContactsConnectors()}`)
    .then(({ data }) => {
      const connectors = data.connectors

      if (connectors.length === 0) return

      store.dispatch({
        type: actionTypes.SET_SYNC_CONTACTS_LIST,
        payload: connectors,
      })

      return connectors
    })
}

const unlinkContacts = (connectorId, connectorEmail) => {
  return api.client
    .delete(`${endpoints.contacts.unlinkContacts(connectorId)}`)
    .then(() => {
      store.dispatch({
        type: actionTypes.DELETE_CONTACTS_CONNECTOR,
        payload: connectorId,
      })

      store.dispatch({
        type: actionTypes.DELETE_SYNC_CONTACTS,
        payload: connectorEmail,
      })
    })
    .catch(error => {
      throw error
    })
}

export {
  getContacts,
  addTagsToUsers,
  removeTagsFromUsers,
  initiateSendEmailsAndSMS,
  initiateEmitSocketInvitees,
  resyncContactsBook,
  changeContactDefaultType,
  fetchBookContacts,
  getGoogleContacts,
  getAppleContacts,
  linkSyncContacts,
  getContactsConnectors,
  unlinkContacts,
}
