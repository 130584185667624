import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  border-bottom: 1px solid var(--lightGray);
`

export const Text = styled(NSubtitleS)`
  width: max-content;
  border-bottom: 2px solid var(--black);
  color: var(--gray6);
  display: block;
  font-weight: ${NFontWeight.bold};
  padding: 15px;
`
