import { useEffect, useState } from "react"

import axios from "axios"

export const useGenerateSharedLink = (teeupName, teeupSlug) => {
  const urlOrigin = window.location.origin
  const url = `${urlOrigin}/s/${teeupSlug}`
  const [sharedLink, setSharedLink] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const generateLink = async () => {
    try {
      setIsLoading(true)
      const result = await axios.post("https://api2.branch.io/v1/url", {
        branch_key: process.env.REACT_APP_BRANCH_IO_KEY,
        data: {
          $fallback_url: url,
          $android_url: url,
          $desktop_url: url,
          $ios_url: url,
        },
      })
      const invitationText = `Hey, I would like you to join my TeeUp:\n"${teeupName}". You can do it using this link:\n${result.data.url}`

      setSharedLink(invitationText)
    } catch {
      setSharedLink("")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    generateLink()
  }, [teeupSlug])

  return { sharedLink, isLoading }
}
