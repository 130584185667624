import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes, AppStyles } from "@theme/"

const ViewStyles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  container: {
    flexDirection: "column",
    flex: 1,
  },
  choicesHeader: {
    flexDirection: "row",
    justifyContent: "center",
    padding: Units.rem(1),
  },
  flex: {
    flex: 1,
  },
  splitter: {
    width: Units.rem(2),
  },
  padding: {
    paddingTop: Units.rem(1),
  },
  buttonTitle: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.black,
  },
  separator: {
    height: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  sectionHeaderContainer: {
    backgroundColor: AppColors.brand.whiteGrey,
    paddingTop: Units.rem(1.375),
    paddingBottom: Units.rem(0.5),
    paddingHorizontal: Units.rem(1),
  },
  sectionTitle: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.bigDemibold,
  },
  sectionFooterContainer: {
    backgroundColor: AppColors.brand.whiteGrey,
    paddingTop: Units.rem(0.25),
    paddingBottom: Units.rem(0.1875),
    paddingHorizontal: Units.rem(1),
  },
  footerTitle: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishDemibold,
  },
  emptyHeader: {
    height: 1,
  },
  headerContainer: {
    paddingTop: AppSizes.statusBarHeight,
    backgroundColor: AppColors.brand.black,
  },
  fakeCardTop: {
    marginLeft: Units.rem(1),
    marginRight: Units.rem(1),
    marginTop: Units.rem(0.375),
    backgroundColor: AppColors.brand.grey2,
    borderTopLeftRadius: Units.rem(0.5),
    borderTopRightRadius: Units.rem(0.5),
    height: Units.rem(0.5),
  },
  headerWithButtons: {
    backgroundColor: AppColors.brand.white,
    borderTopLeftRadius: Units.rem(0.5),
    borderTopRightRadius: Units.rem(0.5),
    height: Units.rem(3.5),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.rem(1),
  },
  headerTitleView: {
    alignItems: "center",
  },
  headerText: {
    fontSize: Units.rem(1.375),
    fontFamily: AppFonts.family.demibold,
    color: AppColors.brand.black,
  },
  teeupType: {
    flexDirection: "row",
    alignItems: "center",
  },
  teeupTypeText: {
    lineHeight: Units.rem(1.25),
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumDemibold,
  },
  arrowImage: {
    width: 7,
    marginHorizontal: Units.rem(0.25),
    marginTop: 4,
    tintColor: AppColors.brand.darkGrey2,
  },
  timezone: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: AppColors.brand.grey40,
    zIndex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.pink,
  },
  inactiveButtonStyle: {
    color: AppColors.brand.warmGrey2,
  },
  loadingButton: {
    width: Units.responsiveValue(50),
  },
})

const ViewProps = {
  selectizeTintColor: AppColors.brand.pink,
  inputHintColor: AppColors.brand.warmGrey2,
  navButtonColor: AppColors.brand.pink,
  fontFamily: AppFonts.family.bold,

  activeColor: AppColors.brand.pink,
  inactiveColor: AppColors.brand.whiteGrey,
}

const PopoverStyles = StyleSheet.create({
  contentContainer: {
    padding: 0,
    width: AppSizes.screen.width - Units.rem(1.25),
    borderRadius: Units.rem(0.5),
  },
  row: {
    padding: Units.rem(0.75),
    paddingRight: Units.rem(1),
    borderBottomWidth: 1,
    borderBottomColor: AppColors.brand.grey2,
    flexDirection: "row",
    alignItems: "center",
  },
  lastRow: {
    borderBottomWidth: 0,
  },
  rowImage: {
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    backgroundColor: AppColors.brand.grey2,
  },
  rowImageContent: {
    width: 18,
    tintColor: AppColors.brand.darkGrey2,
  },
  rowContent: {
    flex: 1,
    paddingHorizontal: Units.rem(0.75),
    paddingBottom: 4,
  },
  rowPrimaryText: {
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  rowSecondaryText: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumMedium,
  },
})

const RowStyles = StyleSheet.create({
  suggestionLineContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    // paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(10),
  },
  suggestionTitle: {
    textTransform: "uppercase",
    ...AppFonts.extraSmallExtrabold,
    lineHeight: Units.responsiveValue(13),
    letterSpacing: -Units.responsiveValue(0.45),
    color: AppColors.brand.warmGrey2,
  },
  suggestionContent: {
    flexGrow: 1,
    justifyContent: "center",
    // marginHorizontal: Units.responsiveValue(12),
  },
  suggestionTitleHeader: { flexDirection: "row", alignItems: "center" },
  decidedIcon: {
    height: Units.responsiveValue(21),
    width: Units.responsiveValue(20),
    resizeMode: "cover",
  },
  title: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.bigDemibold,
  },
  buttonTitle: {
    flex: 1,
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.blackTwo,
  },
  buttonTitleNoPadding: {
    color: AppColors.brand.blackTwo,
    ...AppFonts.bigDemibold,
    flex: 1,
  },
  buttonTitlePlaceholder: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.pink,
  },
  rightButton: {
    paddingHorizontal: Units.rem(1),
    marginRight: -Units.rem(1),
    height: "100%",
    justifyContent: "center",
  },
  input: {
    paddingBottom: 0,
    paddingTop: 0,
    ...AppFonts.mediumBig,
    flex: 1,
  },
  container: {
    height: 56,
    paddingHorizontal: Units.rem(1),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  suggestionButton: {
    flex: 1,
    flexDirection: "row",
  },
  gamePlansHideImage: {
    transform: [
      {
        rotate: "90deg",
      },
    ],
    tintColor: AppColors.brand.warmGrey2,
  },
  rowType: {
    width: Units.rem(2),
    height: Units.rem(2),
    justifyContent: "center",
    alignItems: "center",
  },
  rowGameplanImageContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  rowGameplanImage: {
    width: Units.responsiveValue(26),
    height: Units.responsiveValue(24),
    tintColor: AppColors.brand.darkGrey2,
  },
  rowGameplanImageActive: {
    tintColor: AppColors.brand.pink,
  },
  locationNameText: {
    color: AppColors.brand.black2,
    ...AppFonts.biggerMedium,
  },
  locationNamePlaceholder: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.biggerMedium,
  },
  chevronStyle: {
    tintColor: AppColors.brand.warmGrey2,
  },
  interestTagHeader: {
    height: 48,
    paddingHorizontal: Units.rem(1),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  interestTagText: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumDemibold,
  },
  interestTagPlaceholder: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.mediumDemibold,
  },
  interestTagList: {
    paddingHorizontal: Units.rem(0.75),
    paddingBottom: Units.rem(0.5),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rowAdditionalMargin: {
    marginLeft: Units.responsiveValue(8),
  },
  startsWhenImage: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
  },
})

const GameplanStyles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  mesengerDetails: {
    backgroundColor: AppColors.brand.grey244,
    flexDirection: "column",
    borderRadius: 5,
    paddingBottom: Units.responsiveValue(15),
    paddingHorizontal: Units.responsiveValue(12),
  },
  headerDetails: {
    marginTop: Units.responsiveValue(16),
    flexDirection: "row",
    alignItems: "center",
  },
  detailIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
  },
  headerTxt: {
    ...AppFonts.biggerDemibold,
    marginLeft: Units.responsiveValue(4),
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.4),
  },
  subTitle: {
    marginTop: Units.responsiveValue(24),
  },
  subTitleTxt: {
    ...AppFonts.smallBold,
    color: AppColors.brand.grey144,
    textTransform: "uppercase",
    lineHeight: Units.responsiveValue(14),
    letterSpacing: -Units.responsiveValue(0.24),
  },
  detailsDescription: {
    marginTop: Units.responsiveValue(4),
    ...AppFonts.biggerMedium,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.34),
  },
  header: {
    backgroundColor: AppColors.brand.white,
  },
  calendarButton: {
    padding: Units.rem(1),
  },
  calendarButtonImage: {
    height: Units.responsiveValue(20),
    width: Units.responsiveValue(20),
  },
  calendarButtonImageBlack: {
    tintColor: AppColors.brand.blackTwo,
  },
  flex: { flex: 1 },
  flexGrow0: { flexGrow: 0 },
  whereContent: {
    flex: 1,
    marginBottom: Units.responsiveValue(AppSizes.additionalBottomSpace),
  },
  suggestionContainer: {
    backgroundColor: AppColors.brand.whiteGrey,
    paddingTop: Units.rem(0.5),
    paddingBottom: Units.rem(1),
    pointerEvents: "none",
    // ...AppStyles.shadow,
  },
  mainContainer: {
    marginBottom: Units.responsiveValue(AppSizes.additionalBottomSpace),
  },
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: AppColors.brand.white,
    borderRadius: Units.rem(0.5),
    padding: Units.rem(1),
  },
  containerWhen: {},
  containerWhat: {
    backgroundColor: AppColors.brand.white,
    padding: Units.rem(1),
    flex: 1,
  },
  bgGrey: { backgroundColor: AppColors.brand.whiteGrey },
  scrollView: {
    backgroundColor: AppColors.brand.whiteGrey,
  },
  fieldsContainer: {
    flex: 1,
  },
  whereFieldsContainer: {
    zIndex: 1,
    ...StyleSheet.absoluteFill, //to later display over the map
    top: Units.responsiveValue(16),
    bottom: undefined,
    flexShrink: 1,
    paddingHorizontal: Units.responsiveValue(16),
  },
  whereContainer: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
    paddingVertical: Units.responsiveValue(16),
  },
  fieldContainer: {},
  commonFieldStyle: {
    borderRadius: Units.responsiveValue(4),
    borderColor: AppColors.brand.whiteGrey,
    borderWidth: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.white,
  },
  field: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  fieldWhen: {
    height: Units.rem(3),
    marginBottom: Units.rem(0.25),
    borderRadius: Units.rem(0.25),
    overflow: "hidden",

    borderColor: AppColors.brand.whiteGrey,
    borderWidth: 1,
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
  },
  fieldWhenBorder: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: Units.rem(0.25),
    borderColor: AppColors.brand.grey2,
    borderWidth: 1,
  },
  fieldInput: {
    flexDirection: "row",
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(12),
    flex: 1,
    boxSizing: "content-box",
    // padding: 0,
    margin: 0,
    borderWidth: 0,
    ...AppFonts.bigMedium,
    lineHeight: Units.responsiveValue(21),
    letterSpacing: -Units.responsiveValue(0.4),
    color: AppColors.brand.blackTwo,
    textAlignVertical: "top",
  },
  closeButton: { alignSelf: "center" },
  subValueText: { color: AppColors.brand.warmGrey2 },
  fieldLabel: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    marginBottom: Units.rem(0.5),
    marginLeft: Units.rem(0.5),
  },
  fieldLabelWhen: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    marginTop: Units.rem(0.25),
    marginBottom: Units.rem(0.5),
  },
  fieldSelected: {
    borderColor: AppColors.brand.darkGrey2,
  },
  fieldContainerSplitter: {
    paddingBottom: Units.responsiveValue(16),
  },
  fieldIcon: {
    tintColor: AppColors.brand.pink,
  },
  fieldIconWhite: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
    tintColor: AppColors.brand.white,
  },
  fieldCancelIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    tintColor: AppColors.brand.grey165,
  },
  fieldAction: {
    width: Units.rem(3),
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldActionPink: {
    backgroundColor: AppColors.brand.pink,
  },
  pickerButton: {
    paddingLeft: Units.rem(0.875),
    paddingRight: Units.rem(0.625),
    paddingVertical: Units.rem(0.75),
    borderRadius: 100,
    marginLeft: Units.rem(1),
    overflow: "hidden",
    backgroundColor: AppColors.brand.pink,

    // TODO: shadow's not working
    ...AppStyles.shadow,
  },
  fieldTitle: {
    flex: 1,
    ...AppFonts.bigMedium,
    color: AppColors.brand.warmGrey2,
    lineHeight: Units.responsiveValue(21),
    letterSpacing: -Units.responsiveValue(0.4),
    paddingRight: 16,
  },
  fieldTitleWhen: {
    flex: 1,
    ...AppFonts.bigMedium,
    color: AppColors.brand.warmGrey2,
    paddingHorizontal: Units.rem(1),
  },
  fieldTitleValue: {
    color: AppColors.brand.black,
  },
  rowContainer: {
    flexDirection: "row",
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.rem(1),
    paddingVertical: Units.rem(0.5),
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: AppColors.brand.whiteGrey,
  },
  rowTitle: {
    ...AppFonts.big,
    flex: 1,
  },
  whenButtonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  svgLines: {
    ...AppStyles.absolute,
    flexDirection: "column",
  },
  svgLine: {
    flex: 1,
    flexDirection: "row",
  },
  recommendationsContainer: {
    paddingTop: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(16),
    borderTopWidth: 2,
    //borderTopColor: AppColors.brand.lightGrey,
    flex: 1,
  },
  recommendationsTitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    paddingBottom: Units.responsiveValue(16),
    paddingLeft: Units.responsiveValue(16),
  },
  customTimeInput: {
    backgroundColor: AppColors.brand.white,
    flex: 1,
    paddingHorizontal: Units.responsiveValue(16),
    paddingTop: Units.responsiveValue(11),
    paddingBottom: Units.responsiveValue(15),
    borderTopWidth: 1,
    // borderColor: AppColors.brand.lightGrey,
    color: AppColors.brand.black,
    ...AppFonts.mediumDemibold,
  },
  freestyleRow: { flexDirection: "row" },
  durationContainer: {
    marginBottom: AppSizes.additionalBottomSpace,
    alignItems: "stretch",
  },
  durationText: {
    ...AppFonts.biggerMedium,
    letterSpacing: Units.responsiveValue(-0.4),
    color: AppColors.brand.black,
  },
  durationOptionalText: {
    color: AppColors.brand.white20,
  },
  durationButtonContainer: {
    width: Units.responsiveValue(111),
    height: Units.responsiveValue(32),
    borderColor: AppColors.brand.pink,
    borderWidth: 1,
    borderRadius: 4,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  durationOpacityButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  durationSpliter: {
    borderColor: AppColors.brand.pink,
    borderWidth: 0.5,
    width: Units.responsiveValue(1),
  },
  durationButtonText: {
    color: AppColors.brand.pink,
    ...AppFonts.bigger,
  },
  row: { flexDirection: "row" },
  paddingRight: undefined,
  mapImageMargin: { marginRight: Units.responsiveValue(48) },
  mapIcon: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: Units.responsiveValue(48),
    backgroundColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonIcon: {
    tintColor: AppColors.brand.warmGrey2,
  },
  whatInput: {
    ...AppFonts.mediumDemibold,
    backgroundColor: AppColors.brand.white,
    borderWidth: 1,
    borderColor: AppColors.brand.lightGrey2,
    borderRadius: Units.rem(1.5),
    height: Units.rem(3),
    margin: Units.rem(1),
    paddingHorizontal: Units.rem(1),
  },
  suggestionButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: Units.responsiveValue(12),
    height: Units.rem(3),
    borderRadius: Units.rem(0.5),
    backgroundColor: AppColors.brand.pink,
  },
  suggestionButtonDisabled: {
    //backgroundColor: AppColors.brand.lightGrey,
  },
  suggestionButtonText: {
    ...AppFonts.biggerBold,
    color: AppColors.brand.white,
  },
  suggestionButtonTextDisabled: {
    color: AppColors.brand.warmGrey2,
  },
  exactDateWarning: {
    margin: Units.responsiveValue(12),
    marginBottom: Units.responsiveValue(4),
    flexDirection: "row",
  },
  exactDateWarningText: {
    color: AppColors.brand.blackTwo,
    marginTop: Units.responsiveValue(-2),
    textAlign: "center",
    ...AppFonts.smallishDemibold,
  },
  putGameplanRow: {
    height: Units.responsiveValue(56),
    //borderColor: AppColors.brand.lightGrey,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingHorizontal: Units.rem(1),
    flexDirection: "row",
    alignItems: "center",
    // marginTop: '109%',
  },
  putGameplanRowTextContainer: {
    flex: 1,
  },
  putGameplanRowText: {
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  stubRightButton: {
    width: Units.responsiveValue(48),
  },
  tabbarStyle: {
    height: Units.responsiveValue(40),
    paddingTop: 0,
  },
})

const SelectizeStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    backgroundColor: AppColors.brand.white,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  rightImageContainer: {
    position: "absolute",
    right: Units.rem(1),
    // paddingVertical: Units.rem(1),
    top: 0,
    bottom: 0,
    justifyContent: "center",
  },
  rightImage: {},
})

const GameplanOptionSubrowStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
    borderBottomColor: AppColors.brand.grey,
    borderBottomWidth: 1,
    backgroundColor: AppColors.brand.whiteGrey,
  },
  activeSuggestionCustomStyle: {
    minHeight: Units.rem(5),
  },
  lockIcon: {
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(52),
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.responsiveValue(8.5),
  },
  middle: { flex: 1, flexDirection: "column", justifyContent: "center" },
  right: { justifyContent: "center" },
  spacer: { width: 5 },
  expandContainer: {
    marginHorizontal: Units.rem(1),
    height: Units.rem(2.5),
    padding: Units.responsiveValue(5),
    borderRadius: Units.rem(1),
    flexDirection: "row",
    alignItems: "center",
  },
  expandContainerActive: { backgroundColor: AppColors.brand.grey },
  title: {
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  subtitle: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.mediumDemibold,
  },
  rowType: {
    backgroundColor: AppColors.brand.grey,
  },
  disabledRowOverlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.grey196_40,
  },
})

const GameplanOptionStyles = StyleSheet.create({
  columnHeader: {
    flexDirection: "row",
    paddingHorizontal: 26,
    paddingVertical: 8,
    backgroundColor: AppColors.brand.white,
  },
  columnHeaderText: {
    marginRight: 19,
    color: AppColors.brand.warmGrey2,
    ...AppFonts.mediumDemibold,
  },
  rowContainer: {
    flexDirection: "column",
  },
  row: {
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: AppColors.brand.whiteGrey,
    backgroundColor: AppColors.brand.white,
  },
  rowMovingIndicator: {
    position: "absolute",
    width: 2,
    height: 64,
    left: 0,
    top: 0,
    backgroundColor: AppColors.brand.red,
  },
  rowDropIndicator: {
    position: "absolute",
    left: 0,
    right: 0,
    top: -3,
    justifyContent: "center",
    height: 6,
  },
  rowDropIndicatorLeftCircle: {
    position: "absolute",
    width: 6,
    height: 6,
    borderRadius: 3,
    left: 0,
    backgroundColor: AppColors.brand.red,
  },
  rowDropIndicatorRightCircle: {
    position: "absolute",
    width: 6,
    height: 6,
    borderRadius: 3,
    right: 0,
    backgroundColor: AppColors.brand.red,
  },
  rowDropIndicatorLine: {
    height: 2,
    backgroundColor: AppColors.brand.red,
  },
  rowReorder: {
    paddingLeft: 12,
    paddingRight: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  invisible: {
    opacity: 0,
  },
  rowType: {
    marginRight: 12,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: AppColors.brand.lightBlue3,
    justifyContent: "center",
    alignItems: "center",
  },
  rowSelectedBorder: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: AppColors.brand.blue2,
  },
  rowIndicatorLine: {
    position: "absolute",
    width: 2,
    height: 12,
    left: 45,
    bottom: 0,
    backgroundColor: AppColors.brand.blue2,
  },
  rowGameplanImageContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  rowGameplanImage: {
    width: Units.responsiveValue(26),
    height: Units.responsiveValue(24),
  },
  searchSection: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  searchIcon: {
    borderRadius: 10,
    backgroundColor: AppColors.navbarButton,
    color: AppColors.brand.white,
    ...AppFonts.smallestExtrabold,
    paddingLeft: Units.responsiveValue(5.5),
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
  },
  rowInput: {
    width: 156,
    height: 40,
    borderBottomWidth: 2,
    borderColor: AppColors.brand.warmGrey2,
    color: AppColors.brand.blackTwo,
    ...AppFonts.biggerDemibold,
  },
  rowInputError: {
    marginRight: -Units.responsiveValue(16),
  },
  rowInputText: {
    lineHeight: 40,
  },
  rowInputFocused: {
    borderColor: AppColors.brand.pink,
  },
  rowPlaceholder: {
    alignSelf: "center",
    borderBottomWidth: 0,
    color: AppColors.brand.warmGrey2,
  },
  footer: {
    overflow: "hidden",
    paddingBottom: 144,
    opacity: 1,
  },
  footerDisabled: {
    opacity: 0.3,
  },
  footerShadow: {
    height: 1,
    backgroundColor: AppColors.brand.grey2,
    shadowColor: AppColors.brand.black,
    shadowOpacity: 0.8,
    shadowRadius: 4,
  },
  footerDescription: {
    margin: 16,
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishMedium,
  },
  footerButton: {
    alignSelf: "center",
    height: 48,
    borderRadius: 24,
    paddingHorizontal: 40,
    justifyContent: "center",
    backgroundColor: AppColors.brand.pink,
  },
  footerButtonText: {
    color: AppColors.brand.white,
    ...AppFonts.biggerDemibold,
  },
  footerTypesContainer: {
    paddingHorizontal: 8,
    flexDirection: "row",
  },
  footerTypeCard: {
    flex: 1,
    padding: 12,
    marginHorizontal: 8,
    borderRadius: 10,
    shadowColor: AppColors.brand.black,
    shadowOffset: { height: 1 },
    shadowOpacity: 0.24,
    shadowRadius: 6,
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
  },
  footerTypeIcon: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
  },
  footerTypeLabel: {
    textAlign: "center",
    color: AppColors.brand.blackTwo,
    marginTop: 4,
    marginBottom: 2,
    ...AppFonts.biggerDemibold,
  },
  footerTypeDescription: {
    textAlign: "center",
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallishMedium,
  },
  removeButton: {
    position: "absolute",
    width: "100%",
    height: 128,
    left: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.red,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  removeButtonText: {
    marginLeft: 8,
    color: AppColors.brand.white,
    ...AppFonts.biggerDemibold,
  },
  dotIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
    borderRadius: Units.responsiveValue(10) / 2,
    backgroundColor: AppColors.brand.pink,
  },
  triangle: {
    position: "absolute",
    left: 58,
    bottom: 12,
    tintColor: AppColors.brand.warmGrey2,
  },
  triangleUp: {
    position: "absolute",
    left: 60,
    bottom: 8,
    tintColor: AppColors.brand.warmGrey2,
    transform: [
      {
        rotate: "-135deg",
      },
    ],
  },
  rowGameplanIconImage: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
  },
})

const GameplanOptionIconsStyles = (itemSize, itemPadding, paddingTop) =>
  StyleSheet.create({
    iconsList: {
      position: "absolute",
      width: "100%",
      height: "100%",
      paddingTop,
    },
    touchBg: {
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    scrollWrapper: {
      borderTopWidth: 2,
      borderColor: AppColors.brand.blue2,
      flex: 1,
    },
    iconsListContent: {
      padding: itemPadding / 2,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    icon: {
      width: itemSize,
      height: itemSize,
      margin: itemPadding / 2,
      backgroundColor: AppColors.brand.white,
      justifyContent: "center",
      alignItems: "center",
      borderColor: AppColors.brand.blue2,
    },
    iconSelected: {
      borderWidth: 3,
    },
  })

const createSuggestionButton = isSuggestButtonEnabled => ({
  margin: Units.responsiveValue(12),
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  paddingVertical: Units.responsiveValue(14),
  borderRadius: Units.responsiveValue(8),
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: isSuggestButtonEnabled
    ? AppColors.brand.pink
    : AppColors.brand.lightGrey,
})

const createSuggestionText = isSuggestButtonEnabled => ({
  ...AppFonts.biggerBold,
  lineHeight: Units.responsiveValue(20),
  letterSpacing: -Units.responsiveValue(0.3),
  color: isSuggestButtonEnabled
    ? AppColors.brand.white
    : AppColors.brand.warmGrey2,
})

const multilineStyle = isMultiline => ({
  height: Units.responsiveValue((isMultiline ? 48 * 3 : 48) - 24),
})

const whereOnlineFieldsContainer = isDetails => ({
  zIndex: 1,
  marginTop: Units.responsiveValue(isDetails ? 0 : 16),
  flexShrink: 1,
  paddingHorizontal: Units.responsiveValue(16),
})

export {
  ViewStyles,
  ViewProps,
  PopoverStyles,
  RowStyles,
  SelectizeStyles,
  GameplanStyles,
  GameplanOptionStyles,
  GameplanOptionIconsStyles,
  GameplanOptionSubrowStyles,
  createSuggestionButton,
  createSuggestionText,
  multilineStyle,
  whereOnlineFieldsContainer,
}
