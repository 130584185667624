import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { CREATE_TEEUP_SCREENS } from "@constants/teeups"

import { selectCreateTeeupScreenType } from "../../../selectors/createTeeUp"

import { Wrapper, BackText, BackIcon, SettingsIcon } from "./headerStyles"

const Header = ({ screenType, setCreateTeeupScreenType, onClose }) => {
  const isMainScreen = screenType === CREATE_TEEUP_SCREENS.MAIN

  const goBack = () => {
    if (isMainScreen) {
      onClose()
    } else {
      setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.MAIN)
    }
  }

  const getTitle = () => {
    switch (screenType) {
      case CREATE_TEEUP_SCREENS.SELECT_IMAGE:
        return "Select Image"
      case CREATE_TEEUP_SCREENS.SETTINGS:
        return "Settings"
      case CREATE_TEEUP_SCREENS.ADD_PEOPLE:
        return "Invite People"
      default:
        return "New TeeUp"
    }
  }

  return (
    <Wrapper $isMainScreen={isMainScreen}>
      <ButtonEl
        autoWidth
        padding="0"
        variant={BUTTON_VARIANTS.TRANSPARENT}
        onClick={goBack}>
        <BackIcon
          color={isMainScreen ? "var(--white)" : "var(--black)"}
          name="arrow-2"
        />

        <BackText $isMain={isMainScreen}>{getTitle()}</BackText>
      </ButtonEl>

      {isMainScreen && (
        <ButtonEl
          autoWidth
          padding="0"
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={() => {
            setCreateTeeupScreenType(CREATE_TEEUP_SCREENS.SETTINGS)
          }}>
          <SettingsIcon color="var(--white)" name="settings-2" />
        </ButtonEl>
      )}
    </Wrapper>
  )
}

Header.propTypes = {
  onClose: PropTypes.func,
}

const mapStateToProps = state => ({
  screenType: selectCreateTeeupScreenType(state),
})

const mapDispatchToProps = dispatch => ({
  setCreateTeeupScreenType: payload =>
    dispatch(setCreateTeeupScreenType(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
