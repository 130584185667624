import { selectDisplayCalendar } from "@selectors/calendar"
import { fetchCalendarEvents } from "@utils/calendarSyncUtils"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

const useFetchCalendarEvents = () => {
  const displayCalendar = useSelector(selectDisplayCalendar)

  const isDisplayedCalendars = useMemo(
    () =>
      Object.values(displayCalendar)
        .map((calendar: any) => calendar.selected)
        .some(Boolean),
    [displayCalendar]
  )

  useEffect(() => {
    fetchCalendarEvents({})
  }, [isDisplayedCalendars])
}

export default useFetchCalendarEvents
