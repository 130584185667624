import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.brand.whiteGrey,
    width: "50vw",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: 10,
  },
  header: {
    backgroundColor: "transparent",
    // paddingBottom: 12,
  },
  headerTitle: {
    ...AppFonts.biggerBold,
    color: "#222222",
    // textAlign: 'center',
    lineHeight: "21px",
    fontSize: "20px",
    // lineHeight: Units.rem(1.5625),
  },
  headerSubtitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black,
    textAlign: "center",
    lineHeight: Units.rem(1.25),
  },
  titleContainerStyle: {
    // flex: 1,
    alignItems: "center",
    // justifyContent: 'center',
  },
  searchBar: {
    flexDirection: "column",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: Units.responsiveValue(40),
    borderRadius: Units.responsiveValue(20),
    backgroundColor: AppColors.brand.white,
    paddingHorizontal: Units.responsiveValue(12),
    minWidth: Units.responsiveValue(40),
    boxSizing: "border-box",
    // backgroundColor: '#f5f5f5',
  },
  buttonText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.pink,
    cursor: "pointer",
  },
  borderShadow: {
    backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0), #000000)",
    height: 4,
    opacity: 0.08,
  },
  searchLineWrapper: {
    height: Units.responsiveValue(72),
    paddingHorizontal: 16,
    paddingVertical: 20,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    alignItems: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: "100%",
  },
  searchButtonText: {
    fontFamily: "greycliffcf-bold-salt",
    color: AppColors.brand.darkGrey2,
    letterSpacing: -0.4,
  },
  searchText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.blackTwo,
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    marginHorizontal: Units.rem(0.5),
  },
  searchResultsText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.blackTwo,
    letterSpacing: -0.35,
    lineHeight: 22,
    marginHorizontal: Units.responsiveValue(16),
    marginTop: Units.responsiveValue(8),
    marginBottom: Units.responsiveValue(10),
  },
  tagText: {
    marginLeft: 6,
    // color: AppColors.brand.blueGrey,
    color: "#767676",
    fontFamily: "greycliffcf-demibold-salt",
  },
  tagImage: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
    tintColor: AppColors.brand.blueGrey,
  },
  tagSettingsImage: {
    width: Units.responsiveValue(15),
    height: Units.responsiveValue(16),
    tintColor: AppColors.brand.pink,
  },
  tagSettingsContainer: {
    borderColor: AppColors.brand.pink,
    borderWidth: 1,
    borderRadius: Units.responsiveValue(4),
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(8),
  },
  tagWrapper: {
    flexDirection: "row",
    alignItems: "center",
    height: 33,
    paddingHorizontal: Units.responsiveValue(12),
    paddingVertical: Units.responsiveValue(8),
    marginRight: Units.responsiveValue(8),
    marginTop: 8,
    // borderWidth: Units.responsiveValue(1),
    borderRadius: Units.responsiveValue(34),
    // borderColor: AppColors.brand.blueGrey,
    // backgroundColor: AppColors.brand.lightGrey,
    backgroundColor: "#F0F2F5",
    // backgroundColor: '#f0f0f0',
  },
  sortByCommonTagsText: {
    marginLeft: 0,
    // color: AppColors.brand.blueGrey,
    color: "#767676",
    fontFamily: "greycliffcf-demibold-salt",
  },
  selectedWrapperStyle: {
    backgroundColor: AppColors.brand.blueGrey,
  },
  selectedImageStyle: {
    tintColor: AppColors.brand.lightGrey,
  },
  selectedTextStyle: {
    color: AppColors.brand.lightGrey,
  },
  tagList: {
    flex: 1,
  },
  tagContentContainerStyle: {
    paddingHorizontal: Units.responsiveValue(12),
  },
  containerStyle: {
    width: "50vw",
    backgroundColor: "transparent",
  },
})

export default styles
