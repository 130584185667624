export const INNER_EVENTS_HEIGHT = 250
export const TIME_WHEEL_WIDTH = 898
export const HALF_OF_TIME_WHEEL = TIME_WHEEL_WIDTH / 2
export const HOUR_ITEM_WIDTH = 56

// we have 4 parts of one hour: 0/15/30/45 minutes
export const MINUTES_DAY_PARTS = 4
export const MINUTES_DAY_PART_WIDTH = HOUR_ITEM_WIDTH / MINUTES_DAY_PARTS
export const MINUTES_STEP = 15
export const DEFAULT_INIT_DATE_INDEX = 40_000

export const DAY_PARTS = {
  0: 0,
  15: 1,
  30: 2,
  45: 3,
}

export const PART_OF_THE_DAY = {
  daytime: "daytime",
  night: "night",
  inBetween: "in-between",
}

export const MOVING_TYPES = {
  back: "back",
  forward: "forward",
}

export const DATE_PICKER_NAV = {
  prev: "prev",
  next: "next",
}
