import { useState } from "react"

import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import { TextField } from "@components/ui/TextField"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"

import "../CustomDateRange/index.scss"
import { TimeListOption } from "types/suggestions"

const CustomTime = () => {
  const isCustomTimeOpen = useSuggestionGeneralDateStore(
    state => state.isCustomTimeOpen
  )
  const setIsCustomTimeOpen = useSuggestionGeneralDateStore(
    state => state.setIsCustomTimeOpen
  )
  const updateCustomTimeTrigger = useSuggestionGeneralDateStore(
    state => state.updateCustomTimeTrigger
  )
  const [timeLabel, setTimeLabel] = useState("")

  const handleClose = () => {
    setIsCustomTimeOpen(false)
  }

  const handleSave = () => {
    if (!timeLabel) return

    const customTime = JSON.parse(
      localStorage.getItem("customTime") || "[]"
    ) as TimeListOption[]

    customTime.push({
      label: timeLabel,
      iconName: "",
      from: -1,
      to: -1,
    })

    localStorage.setItem("customTime", JSON.stringify(customTime))

    updateCustomTimeTrigger()
    setTimeLabel("")
    handleClose()
  }

  return (
    <DefaultModal open={isCustomTimeOpen} onClose={handleClose}>
      <div className="custom-date-range custom-time">
        <div>
          <h4>Add a custom time</h4>
          <p>Create a custom time suggestion with a label.</p>
        </div>

        <div className="date-range-label">
          <h5>Custom Time Label</h5>
          <TextField
            placeholder="e.g. after work"
            value={timeLabel}
            onChange={e => setTimeLabel(e.target.value)}
          />
        </div>

        <Button variant="contained" disabled={!timeLabel} onClick={handleSave}>
          Save
        </Button>
      </div>
    </DefaultModal>
  )
}

export default CustomTime
