import {
  Wrapper,
  LeftSide,
  NameInfo,
  NameFirstLine,
  Name,
  Username,
} from "@components/ContactsList/molecules/listContactStyles"
import { CheckboxEl, ContactAvatar } from "@components/common"
import { IContact } from "types/teeup"

interface IContactItemProps {
  contact: IContact
  onChange: (contact: IContact) => void
  checked: boolean
  isCheckboxHidden?: boolean
}

const ContactItem = ({
  contact,
  onChange,
  checked,
  isCheckboxHidden,
}: IContactItemProps) => {
  return (
    <Wrapper>
      <LeftSide>
        <ContactAvatar src={contact.avatar} text={contact.name} />
        <NameInfo>
          <NameFirstLine>
            <Name>{contact.name}</Name>
            <Username>{contact.username}</Username>
          </NameFirstLine>
        </NameInfo>
      </LeftSide>
      {!isCheckboxHidden && (
        <CheckboxEl value={checked} onChange={() => onChange(contact)} />
      )}
    </Wrapper>
  )
}

export default ContactItem
