import React, { useCallback, useContext, useEffect, useState } from "react"

import moment from "moment-timezone"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setNewGuestSuggestion } from "@actions/guestActions"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"
import {
  selectGuestLinkedCalendar,
  selectGuestCalendarEvents,
} from "@selectors/calendar"
import { selectNewGuestSuggestion } from "@selectors/guest"
import { usePreviewScreenStore } from "stores/previewScreen"
import dayjs from "utils/dayjs"

import { getCalendarRequestParams, checkIfSameDate } from "../helpers"
import {
  useDaysList,
  useTodayCalendarEvents,
  useTodayDate,
  useDateSetting,
} from "../hooks"
import { ArrowNavigation } from "../molecules"

import {
  Wrapper,
  DaysList,
  DayItem,
  WeekDay,
  MonthDay,
} from "./customToolbarStyles"

const CustomToolbar = ({
  linkedGuestCalendar,
  calendarEvents,
  setNewGuestSuggestion,
}) => {
  const { fetchGuestCalendarsEvents } = useContext(TeeupPreviewContext)
  const [isChecked, setIsChecked] = useState(false)
  const [selectedWeekDay, setSelectedWeekDay] = useState(moment())
  const daysList = useDaysList(selectedWeekDay)

  const currentSelectedDay = usePreviewScreenStore(
    state => state.currentSelectedDay
  )
  const setCurrentSelectedDay = usePreviewScreenStore(
    state => state.setCurrentSelectedDay
  )

  const { checkIsToday, setTodayDate } = useTodayDate(setNewGuestSuggestion)

  const checkIsPast = date => {
    return dayjs(date).isBefore(dayjs().startOf("day"), "day")
  }

  const checkIsSelected = useCallback(
    date => {
      return date.isSame(currentSelectedDay, "date")
    },
    [currentSelectedDay]
  )

  const fetchCalendarEvents = date => {
    if (!linkedGuestCalendar) return

    fetchGuestCalendarsEvents(
      getCalendarRequestParams(date, linkedGuestCalendar)
    )
  }

  const setDateHandle = useDateSetting({
    setNewGuestSuggestion,
    setIsChecked,
    fetchCalendarEvents,
    currentSelectedDay,
    setCurrentSelectedDay,
  })

  useEffect(() => {
    setTodayDate()
  }, [])

  useTodayCalendarEvents({
    isChecked,
    calendarEvents,
    checkIfSameDate,
    setIsChecked,
    fetchCalendarEvents,
  })

  return (
    <Wrapper>
      <ArrowNavigation
        daysList={daysList}
        setSelectedWeekDay={setSelectedWeekDay}
      />

      <DaysList>
        {daysList.map((day, dayIndex) => (
          <DayItem
            onClick={() => setDateHandle(day.date)}
            key={dayIndex}
            $isPast={checkIsPast(day.date)}>
            <WeekDay
              $isToday={checkIsToday(day.date)}
              $isSelected={checkIsSelected(day.date)}>
              {day.weekDay}
            </WeekDay>
            <MonthDay>{day.monthDay}</MonthDay>
          </DayItem>
        ))}
      </DaysList>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  linkedGuestCalendar: selectGuestLinkedCalendar(state),
  calendarEvents: selectGuestCalendarEvents(state),
  newGuestSuggestion: selectNewGuestSuggestion(state),
})

const mapDispatchToProps = dispatch => ({
  setNewGuestSuggestion: bindActionCreators(setNewGuestSuggestion, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbar)
