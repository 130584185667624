import React from "react"

import TimePickerItem from "./TimePickerItem"

const StartTimeContainer = ({
  startTimeHourNumbers,
  setStartTimeHourNumbers,
  startTimeMinuteNumbers,
  setStartTimeMinuteNumbers,
  chosenStartHour,
  chosenStartDayPart,
  chosenStartMinute,
}) => {
  const selectStartHour = chosenHour => {
    let arrayWithSelectedItem = startTimeHourNumbers.map(item => {
      if (item.value === chosenHour) {
        item.selected = true
        return item
      } else {
        item.selected = false
        return item
      }
    })

    setStartTimeHourNumbers(arrayWithSelectedItem)
  }

  const selectStartMinute = chosenMinute => {
    let arrayWithSelectedItem = startTimeMinuteNumbers.map(item => {
      if (item.value === chosenMinute) {
        item.selected = true
        return item
      } else {
        item.selected = false
        return item
      }
    })
    setStartTimeMinuteNumbers(arrayWithSelectedItem)
  }

  return (
    <div className="start-time__container">
      <p className="start-time__title">
        Start Time:{" "}
        <span className="start-time__time">{`${chosenStartHour}:${chosenStartMinute} ${chosenStartDayPart}`}</span>
      </p>
      <div className="start-time__picker-wrapper">
        <TimePickerItem
          pickHour
          startTimeHourNumbers={startTimeHourNumbers}
          selectStartHour={selectStartHour}
        />
        <TimePickerItem
          small
          startTimeMinuteNumbers={startTimeMinuteNumbers}
          selectStartMinute={selectStartMinute}
          noMargin
        />
      </div>
    </div>
  )
}

export default StartTimeContainer
