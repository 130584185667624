import styled from "styled-components"

export const Image = styled.img`
  width: 1em;
  height: 1em;
  ${({ css }) => css && css};
`

export const ImageMask = styled.div`
  width: 1em;
  height: 1em;
  ${({ css }) => css && css};
  background: ${props =>
    props.fill && props.fill !== "default" ? props.fill : "currentColor"};
  -webkit-mask-image: ${({ src }) => `url(${src})`};
  mask-image: ${({ src }) => `url(${src})`};
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
`
