import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  container: {
    maxWidth: "100%",
    fontFamily: " greycliffcf-medium-salt",
    backfaceVisibility: "hidden",
  },
  textMessage: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.black2,
    textAlign: "left",
    lineHeight: Units.responsiveValue(22),
    letterSpacing: Units.responsiveValue(-0.3),
  },
  myMessage: {
    textAlign: "right",
    color: AppColors.brand.darkBlue,
  },
  textMessageMention: {
    color: AppColors.brand.pink,
    textDecorationColor: AppColors.brand.pink,
  },
  myAccTextMessageMention: {
    color: AppColors.brand.orange,
    textDecorationColor: AppColors.brand.orange,
  },
  thumbtackIcon: {
    position: "absolute",
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    tintColor: AppColors.brand.warmGrey2,
  },
})
