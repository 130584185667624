import { useContext, useEffect, useState } from "react"

import { createPortal } from "react-dom"
import { useSelector } from "react-redux"

import { selectUserId } from "selectors/user"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { NewTeeupSettings, TeeupOverviewBlockWrapper } from "components"
import { useScreenWidth } from "hooks"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"

import { FullScreenLayout } from "../../hoc"
import { useFetchTeeUps } from "@components/TeeUpsNd/hooks/useFetchTeeUps"
import { NewTeeupGameplan } from "../NewTeeupGameplan"
import { OpenedGameplan } from "../OpenedGameplan"
import OverviewConversation from "../TeeUpsOverview/OverviewConversation"
import People from "../TeeupPeople"

import { ContentWrapper, LeftSide } from "./teeupPageContentStyles"
import { selectActiveTeeupId } from "@selectors/activeTeeup"

export const TeeupPageContent = () => {
  const [isGamePlan, setIsGamePlan] = useState(false)
  const { handleNudgesAndMentions } = useFetchTeeUps()

  const { isSettings, isGameplanOpen, setIsGameplanOpen } =
    useContext(NewTeeupPageContext)
  const userId = useSelector(selectUserId)
  const isPeopleOpen = useTeeupPeopleStore(state => state.isCollapsed)
  const messages = useSelector(state => state?.activeTeeup?.messages)
  const setIsPeopleOpen = useTeeupPeopleStore(state => state.setIsCollapsed)
  const clearPeopleState = useTeeupPeopleStore(state => state.clear)
  const teeupId = useSelector(selectActiveTeeupId)

  const { isSmallSize } = useScreenWidth()

  useEffect(() => {
    console.log("messages", messages)
    console.log("teeupId", teeupId)
    if (messages?.length) {
      handleNudgesAndMentions({ [teeupId]: messages }, userId)
    }

    return () => {
      clearPeopleState()
    }
  }, [])

  const handleGameplanClick = () => {
    if (isSmallSize) {
      return setIsGamePlan(true)
    }
    if (!isGameplanOpen) {
      setIsPeopleOpen(false)
    }
    setIsGameplanOpen(!isGameplanOpen)
  }

  const handlePeopleClick = () => {
    if (!isPeopleOpen) {
      setIsGameplanOpen(false)
    }
    setIsPeopleOpen(!isPeopleOpen)
  }

  const renderContent = () => {
    return isSettings ? (
      <NewTeeupSettings />
    ) : (
      <ContentWrapper>
        {isGamePlan &&
          createPortal(
            <FullScreenLayout
              title="Game Plan"
              onClose={() => setIsGamePlan(false)}>
              <OpenedGameplan isFullScreen />
            </FullScreenLayout>,
            document.querySelector("#modal-root")
          )}
        <LeftSide>
          <People onClick={handlePeopleClick} />

          <TeeupOverviewBlockWrapper
            title="Game plan"
            isGameplanOpen={isGameplanOpen}
            onClick={handleGameplanClick}>
            <NewTeeupGameplan />

            {isGameplanOpen && !isSmallSize && <OpenedGameplan />}
          </TeeupOverviewBlockWrapper>

          {isSmallSize && (
            <TeeupOverviewBlockWrapper title="Conversation" shouldExpand isWide>
              <OverviewConversation newDesign />
            </TeeupOverviewBlockWrapper>
          )}
        </LeftSide>

        {!isSmallSize && (
          <TeeupOverviewBlockWrapper title="Conversation" shouldExpand isWide>
            <OverviewConversation newDesign />
          </TeeupOverviewBlockWrapper>
        )}
      </ContentWrapper>
    )
  }

  return renderContent()
}
