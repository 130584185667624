import React from "react"

import PropTypes from "prop-types"
import { ThreeDots } from "react-loader-spinner"
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from "react-native"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Slider from "react-slick"

import strings from "@i18n"
import { selectSyncEmail, selectDisplayedCalendars } from "@selectors/calendar"
import { Units, AppColors } from "@theme"
import {
  SCREENS,
  getEventWithTime,
  EVENT_TYPES,
  CALENDAR_TYPES,
} from "@utils/calendarUtils"
import {
  getDateWithTimezone,
  composeDatetime,
  addDayTimestamp,
} from "@utils/dateUtils"
import { images } from "@utils/imageUtils"

import DayCalendar from "./dayCalendar"
import MonthCalendar from "./monthCalendar"
import { CalendarStyles as styles, itemWrapper } from "./styles"
import WeekCalendar from "./weekCalendar"

import "./index.scss"
import cn from "classnames"

class CalendarView extends React.Component {
  constructor(props) {
    super(props)

    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 80,
      waitForInteraction: false,
    }
    this.divRef = React.createRef()
    this.state = {
      activeSlide: props.currentDayIndex,
      goToToday: false,
      isSlicking: false,
    }
  }

  componentDidMount() {
    const { currentDayIndex, fromProfile } = this.props

    if (fromProfile) {
      this.slider.slickGoTo(currentDayIndex)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      moveCalendarForward,
      moveCalendarBackward,
      activeScreen,
      fromProfile,
      currentDayIndex,
    } = this.props
    const { activeSlide } = this.state

    if (fromProfile) {
      if (prevProps.activeScreen !== activeScreen) {
        let indexToAdd = currentDayIndex
        if (activeScreen === SCREENS.week) {
          indexToAdd -= 1
          moveCalendarForward(true)
          moveCalendarBackward()
        }
        if (prevProps.activeScreen === SCREENS.week) {
          indexToAdd -= 1
        }
        if (
          activeScreen === SCREENS.month &&
          prevProps.activeScreen === SCREENS.day
        ) {
          indexToAdd -= 1
          moveCalendarForward(true)
        }

        if (
          prevProps.activeScreen === SCREENS.month &&
          activeScreen === SCREENS.day
        ) {
          indexToAdd -= 1
        }

        setTimeout(() => {
          this.slider.slickGoTo(indexToAdd)
          prevProps.activeScreen === SCREENS.week && moveCalendarForward()
          prevProps.activeScreen === SCREENS.day &&
            activeScreen === SCREENS.month &&
            moveCalendarBackward()
          prevProps.activeScreen === SCREENS.month &&
            activeScreen === SCREENS.day &&
            moveCalendarBackward()
        }, 500)

        this.setState({ activeSlide: indexToAdd })
        return true
      }

      if (
        prevProps.activeScreen === activeScreen &&
        prevState.activeSlide > activeSlide &&
        !this.state.goToToday
      ) {
        moveCalendarBackward()
      }

      if (
        prevProps.activeScreen === activeScreen &&
        prevState.activeSlide < activeSlide &&
        !this.state.goToToday
      ) {
        moveCalendarForward()
      }
    }
  }

  toggleToday = () => {
    this.setState({ goToToday: true })
    setTimeout(() => this.setState({ goToToday: false }), 1000)
  }

  handleCalendar = ({ suggestion }) => {
    let updatedSuggestion = suggestion
      ? suggestion
      : this.getFormattedSuggestion()
    const { teeupName } = this.props
    const { startDate: selectedDate } = this.state

    const passedEvent = selectedDate
      ? {
          endDate: getEventWithTime(updatedSuggestion).endDate,
          startDate: updatedSuggestion.startDate,
          startTime: updatedSuggestion.startDate,
          duration: updatedSuggestion.duration,
          type: EVENT_TYPES.teeup,
          isTemporaryEvent: true,
          name: teeupName,
        }
      : null
    this.setState({ isCalendarShown: true, passedEvent })
  }

  handleBackArrow = () => {
    if (this.state.isSlicking) return

    this.setState({ isSlicking: true })
    this.props
      .newMoveBack()
      .then(() => this.slider.slickPrev())
      .catch(() => this.slider.slickGoTo(this.props.currentDayIndex))
      .finally(() => {
        // current timeout is just for avoiding of frequent navigation pressing
        setTimeout(() => {
          this.setState({ isSlicking: false })
        }, 900)
      })
  }

  handleNextArrow = () => {
    if (this.state.isSlicking) return

    this.setState({ isSlicking: true })
    this.props
      .newMoveForward()
      .then(() => this.slider.slickNext())
      .catch(() => this.slider.slickGoTo(this.props.currentDayIndex))
      .finally(() => {
        // current timeout is just for avoiding of frequent navigation pressing
        setTimeout(() => {
          this.setState({ isSlicking: false })
        }, 900)
      })
  }

  renderCalendar() {
    const {
      selectedDate,
      activeScreen,
      comingUpComponent,
      isLoaded,
      datesArray,
      currentDayIndex,
      onMomentumScrollEnd,
      flatListRef,
      isFromSugggestionDetails,
      fromProfile,
      comingUpWidth,
    } = this.props
    if (activeScreen === SCREENS.comingUp) {
      return comingUpComponent()
    }
    const settings = {
      arrows: false,
      swipeToSlide: false,
      speed: 300,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: current => this.setState({ activeSlide: current }),
    }
    if (fromProfile) {
      return (
        <div className="new-calendar--body">
          <Slider
            key={activeScreen}
            className={cn({
              fullHeight__calendar: activeScreen !== SCREENS.month,
            })}
            ref={slider => (this.slider = slider)}
            {...settings}>
            {datesArray.map(this.renderItem)}
          </Slider>
        </div>
      )
    }
    return (
      <div
        ref={this.divRef}
        className={cn("calendar__wrapper", {
          "calendar__wrapper-profile": fromProfile,
        })}>
        <FlatList
          key={activeScreen}
          // windowSize={15}
          extraData={selectedDate}
          // ref={flatListRef}
          horizontal
          initialScrollIndex={currentDayIndex - 1}
          // viewabilityConfig={this.viewabilityConfig}
          // showsHorizontalScrollIndicator={false}
          // decelerationRate={'fast'}
          onMomentumScrollEnd={onMomentumScrollEnd}
          onScroll={onMomentumScrollEnd}
          // pagingEnabled
          // snapToInterval={50}
          data={datesArray}
          renderItem={this.renderItem}
          getItemLayout={this.getItemLayout}
          keyExtractor={this.keyExtractor}
          scrollEnabled={!isFromSugggestionDetails}
        />
        {!isLoaded && (
          <View style={styles.loaderContainer}>
            <ActivityIndicator size="large" color={AppColors.brand.pink} />
          </View>
        )}
      </div>
    )
  }

  renderItem = (...rest) => {
    const {
      selectedDate,
      activeScreen,
      changeDate,
      allowGoToPast,
      changeViewType,
      moveCalendarForward,
      moveCalendarBackward,
      newSuggestionProps,
      duration,
      onDurationChange,
      verticalScrollPosition,
      setVerticalScrollPosition,
      currentDayIndex,
      onPickTimerangeFromCalendar,
      isFromSugggestionDetails,
      fromProfile,
      calendarWidth,
      comingUpWidth,
      ITEM_WIDTH,
      datesArray,
    } = this.props

    let { item, index } = rest[0]

    if (fromProfile) {
      ;[item, index] = rest
    }

    let curIndex = index

    if (fromProfile && activeScreen === SCREENS.week) {
      curIndex = index + 1
    }

    const renderedItemDate = currentDayIndex === curIndex ? selectedDate : item
    const key = this.keyExtractor(item)
    switch (activeScreen) {
      case SCREENS.day: {
        return (
          <div
            key={key}
            style={itemWrapper(fromProfile, calendarWidth, comingUpWidth)}>
            <DayCalendar
              allowClickToPast={allowGoToPast}
              selectedDate={renderedItemDate}
              changeDate={changeDate}
              duration={duration}
              onDurationChange={onDurationChange}
              newSuggestionProps={newSuggestionProps}
              onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
              moveCalendarForward={moveCalendarForward}
              moveCalendarBackward={moveCalendarBackward}
              changeViewType={changeViewType}
              scrollOffset={verticalScrollPosition}
              setOffset={setVerticalScrollPosition}
              isFromSugggestionDetails={isFromSugggestionDetails}
            />
          </div>
        )
      }
      case SCREENS.week: {
        return (
          <div
            key={key}
            style={itemWrapper(fromProfile, calendarWidth, comingUpWidth)}>
            <WeekCalendar
              allowClickToPast={allowGoToPast}
              selectedDate={renderedItemDate}
              changeDate={changeDate}
              duration={duration}
              onDurationChange={onDurationChange}
              newSuggestionProps={newSuggestionProps}
              onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
              moveCalendarForward={moveCalendarForward}
              moveCalendarBackward={moveCalendarBackward}
              changeViewType={changeViewType}
              scrollOffset={verticalScrollPosition}
              setOffset={setVerticalScrollPosition}
            />
          </div>
        )
      }
      case SCREENS.month: {
        return (
          <div
            key={key}
            style={itemWrapper(fromProfile, calendarWidth, comingUpWidth)}>
            <MonthCalendar
              allowClickToPast={allowGoToPast}
              monthCellWidth={ITEM_WIDTH / 7}
              selectedDate={datesArray[index]}
              changeDate={changeDate}
              duration={duration}
              onDurationChange={onDurationChange}
              newSuggestionProps={newSuggestionProps}
              moveCalendarForward={moveCalendarForward}
              moveCalendarBackward={moveCalendarBackward}
              changeViewType={changeViewType}
              scrollOffset={verticalScrollPosition}
              setOffset={setVerticalScrollPosition}
            />
          </div>
        )
      }
      default:
        return false
    }
  }

  getItemLayout = (date, index) => {
    const { ITEM_WIDTH } = this.props

    return {
      length: ITEM_WIDTH,
      offset: ITEM_WIDTH * index,
      index,
    }
  }

  keyExtractor = item => `scrollable-calendar-${item}`

  getEndDate = (state, updates, newEndDate, settingTime, propsDuration) => {
    const { isEndDate, isEndTime, startDate } = updates
    const { duration, endDate } = state

    // Many options possibl:
    //  1) with timezones, we only look at duration
    //  2) only setting TIME part, no DATE (isEndDate === false) part of endDate
    //  3) only setting DATE part, no TIME (isEndTime === false) part of endDate
    //  4) setting TIME part of endDate, DATE part is already set
    //  5) setting DATE part of endDate, TIME part is already set

    let finalEndDate = null
    // Only set end date if it's explicitly added someplace
    if (propsDuration === 0) {
      finalEndDate = startDate
    } else if (duration) {
      finalEndDate = endDate
    } else if (!isEndDate && !isEndTime) {
      // No end date needed at all
    } else if (settingTime) {
      // Setting TIME part of end date
      if (isEndDate) {
        // End date specified, combine it with end time.
        // If case isEndTime was turned off, prepare to use TIME from startDate
        finalEndDate = composeDatetime(
          endDate,
          isEndTime ? newEndDate : startDate
        )
      } else {
        // No end date specified, use startDate
        finalEndDate = composeDatetime(startDate, newEndDate)
      }
    } else {
      // Setting DATE part of end date
      if (isEndTime) {
        // End date specified, combine it with end time
        // If case isEndDate was turned off, prepare to use DATE from startDate
        finalEndDate = composeDatetime(
          isEndDate ? newEndDate : startDate,
          endDate
        )
      } else {
        // No end date specified, use startDate
        finalEndDate = composeDatetime(newEndDate, startDate)
      }
    }

    return finalEndDate
  }

  onDatesSelected = dateProps => {}
  onDayClick = value => {
    const { changeViewType } = this.props

    changeViewType(SCREENS.day, value)
  }

  renderSettings() {
    const { openSettings, calendarCardStyles, moveCalendarForward } = this.props

    return (
      <TouchableOpacity
        onPress={openSettings}
        style={[
          styles.settingsBtnContainer,
          calendarCardStyles && calendarCardStyles.settingsBtnContainer,
        ]}
        hitSlop={Units.hitSlop({
          top: calendarCardStyles ? 0 : 6,
          bottom: 6,
          right: 7,
          left: 7,
        })}>
        <Image
          source={images.calendarSettingsGear}
          style={styles.settingsIcon}
        />
      </TouchableOpacity>
    )
  }

  renderTypeSwitch() {
    const { activeScreen, isFromSugggestionDetails, buttons } = this.props
    return (
      <div className="new-calendar--modes">
        {buttons.map(button => (
          <span
            key={button.title}
            onClick={button.callback}
            className={`new-calendar--modes-item ${
              activeScreen.toLowerCase() === button.title.toLowerCase()
                ? "active-mode"
                : ""
            }`}
            disabled={isFromSugggestionDetails}>
            {button.title}
          </span>
        ))}
      </div>
    )
  }

  renderControls() {
    const { isSlicking } = this.state
    const { currentDayIndex, selectedDate, datesArray, activeScreen } =
      this.props

    const renderText = () => {
      const type = activeScreen.toLowerCase()
      switch (type) {
        case "month":
          return selectedDate.format("MMMM YYYY")
        case "day":
          return selectedDate.format("dddd, MMMM DD, YYYY")
        case "week": {
          const startOfWeek = addDayTimestamp(
            selectedDate,
            -1 * selectedDate.format("d"),
            "DD"
          )
          const endOfWeek = addDayTimestamp(
            selectedDate,
            6 - selectedDate.format("d"),
            "DD"
          )
          return `${selectedDate.format(
            "MMMM"
          )} ${startOfWeek} - ${endOfWeek}, ${selectedDate.format("YYYY")}`
        }
        // No default
      }
    }

    return (
      <div className="new-calendar--navigation">
        <p className="new-calendar--navigation-text">{renderText()}</p>
        <button
          className="new-calendar--navigation-arrow"
          disabled={isSlicking}
          onClick={currentDayIndex === 0 ? null : this.handleBackArrow}>
          {currentDayIndex !== 0 && (
            <img
              src={images.chevron}
              alt="chevron icon"
              className="arrow-left"
            />
          )}
        </button>
        <button
          className="new-calendar--navigation-arrow"
          disabled={isSlicking}
          onClick={
            currentDayIndex === datesArray.length - 1
              ? null
              : this.handleNextArrow
          }>
          {currentDayIndex !== datesArray.length - 1 && (
            <img
              src={images.chevron}
              alt="chevron icon"
              className="arrow-right"
            />
          )}
        </button>
      </div>
    )
  }

  renderGoToToday(getNewHorizontalScrollIndex) {
    const { newSelectToday, calendarCardStyles, isFromSugggestionDetails } =
      this.props
    const { isSlicking } = this.state

    const handleSelectToday = () => {
      this.setState({ isSlicking: true })
      newSelectToday().then(() => {
        this.slider.slickGoTo(this.props.currentDayIndex)
        // current timeout is just for avoiding of frequent navigation pressing
        setTimeout(() => {
          this.setState({ isSlicking: false })
        }, 900)
      })
    }

    return (
      <button
        className="new-calendar--today"
        style={calendarCardStyles && calendarCardStyles.goTodayBtnContainer}
        onClick={() => handleSelectToday()}
        disabled={!!isFromSugggestionDetails || isSlicking}>
        <p>Today</p>
      </button>
    )
  }

  renderConnectedItem({ type, emailCorp, emailPerson, extraText = [] }) {
    return (
      <div className="nc-connected-item">
        <img
          src={
            type === CALENDAR_TYPES.google
              ? images.ndGoogleCalendarCard
              : images.ndOutlookCalendarCard
          }
          alt="calendar icon"
        />
        <div>
          <p className="nc-connected-item-main">{emailCorp}</p>
          {extraText.includes(emailCorp)
            ? [].concat(
                <p key={emailCorp} className="nc-connected-item-sec">
                  {emailCorp}
                </p>,
                extraText
                  .filter(text => text !== emailCorp)
                  .map(text => (
                    <p key={text} className="nc-connected-item-sec">
                      {text}
                    </p>
                  ))
              )
            : extraText.map(text => (
                <p key={text} className="nc-connected-item-sec">
                  {text}
                </p>
              ))}
        </div>
      </div>
    )
  }

  renderCalendarCardNoContent({ noConnected, noDisplayed, syncEmail }) {
    return (
      <>
        <div className="nc-connected-card-noConn-Img">
          <img src={images.noConnectedCalendar} alt="No connected calendars" />
        </div>
        <div className="nc-connected-card-noConn-title">
          {noConnected && noDisplayed && !syncEmail
            ? strings.calendar.noConnectedCalendarsYet
            : strings.calendar.noConnectedCalendarsSyncDisplay}
        </div>
        <p className="nc-connected-card-noConn-paragraph">
          {!noConnected && noDisplayed && !syncEmail
            ? strings.calendar.noConnectedCalendarsSyncDisplayParagraph
            : strings.calendar.noConnCalendarsParagraph}
        </p>
      </>
    )
  }

  renderCalendarCard({ syncEmail, displayedCalendars }) {
    return (
      <div className="nc-connected">
        <h3 className="nc-connected-title">
          {strings.calendar.linkedCalendars}
        </h3>
        <div data-testid="linkedCalendarSection" className="nc-connected-card">
          {displayedCalendars ? (
            displayedCalendars[CALENDAR_TYPES.google].related.length > 0 ||
            displayedCalendars[CALENDAR_TYPES.outlook].related.length > 0 ||
            syncEmail ? (
              <>
                {syncEmail && (
                  <p className="nc-connected-card-title">Syncing</p>
                )}
                {syncEmail &&
                  this.renderConnectedItem({
                    type:
                      syncEmail.calendarType === CALENDAR_TYPES.google
                        ? CALENDAR_TYPES.google
                        : CALENDAR_TYPES.outlook,
                    emailCorp: `${syncEmail.summary}`,
                    emailPerson: `${syncEmail.summary}`,
                  })}
                {(displayedCalendars[CALENDAR_TYPES.google].related.length >
                  0 ||
                  displayedCalendars[CALENDAR_TYPES.outlook].related.length >
                    0) && (
                  <h3 className="nc-connected-card-title">Displayed</h3>
                )}
                {displayedCalendars[CALENDAR_TYPES.google].related.length > 0 &&
                  this.renderConnectedItem({
                    type: CALENDAR_TYPES.google,
                    emailCorp: `${
                      displayedCalendars[CALENDAR_TYPES.google].primary.summary
                    }`,
                    extraText: displayedCalendars[
                      CALENDAR_TYPES.google
                    ].related.map(emailData => emailData.summary),
                  })}
                {displayedCalendars[CALENDAR_TYPES.outlook].related.length >
                  0 &&
                  this.renderConnectedItem({
                    type: CALENDAR_TYPES.outlook,
                    emailCorp: `${
                      displayedCalendars[CALENDAR_TYPES.outlook].primary.summary
                    }`,
                    extraText: displayedCalendars[
                      CALENDAR_TYPES.outlook
                    ].related.map(emailData => emailData.summary),
                  })}
              </>
            ) : (
              this.renderCalendarCardNoContent({
                noConnected: false,
                noDisplayed: true,
                syncEmail,
              })
            )
          ) : (
            this.renderCalendarCardNoContent({
              noConnected: true,
              noDisplayed: true,
              syncEmail,
            })
          )}
          <div className="nc-connected-card-manage-wrapper">
            <div className="nc-connected-card-hr-break" />
            <Link
              to={{
                pathname: "/settings",
                state: { activeScreen: 3 },
              }}>
              <div className="nc-connected-card-manage">
                <img src={images.ndCalendarManage} alt="calendar manage" />
                <p>
                  {displayedCalendars
                    ? strings.calendar.manageCalendars
                    : strings.calendar.connectCalendar}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      selectedDate,
      activeScreen,
      newSuggestionProps,
      onSelectDateForSuggestion,
      isDateDifferentFromInitial,
      isFromSugggestionDetails,
      fromProfile,
      currentDayIndex,
      datesArray,
      refVal,
      syncEmail,
      displayedCalendars,
      getDateChangeProps,
      getNewHorizontalScrollIndex,
      isEventsLoading,
    } = this.props
    const { isSlicking } = this.state

    const isChooseButtonVisible =
      !!newSuggestionProps &&
      activeScreen !== SCREENS.month &&
      isDateDifferentFromInitial &&
      !isFromSugggestionDetails
    const isComingUp = activeScreen === SCREENS.comingUp
    // const { date } = getDateChangeProps()
    // const today = getDateWithTimezone()
    // const nowIndex = getNewHorizontalScrollIndex(date, today)

    return (
      <div
        className={cn("", {
          me__calendar: fromProfile,
          "me__base-calendar": !isComingUp,
        })}
        ref={refVal}>
        <div
          className="new-calendar"
          style={{
            height: isChooseButtonVisible ? "calc(100% - 6rem)" : "100%",
          }}>
          <div
            className="new-calendar--wrapper"
            style={{ width: isComingUp ? "100%" : "60%" }}>
            <div
              className={cn("new-calendar--content", {
                "new-calendar--content__comingUp": isComingUp,
              })}>
              {!isComingUp && (
                <div className="new-calendar--header">
                  <div className="new-calendar--options">
                    <h3 className="new-calendar--options-title">Calendar</h3>
                    {fromProfile ? (
                      this.renderTypeSwitch()
                    ) : (
                      <Text style={styles.currentMonthText}>
                        {selectedDate.format("MMMM YYYY")}
                      </Text>
                    )}

                    {(isEventsLoading || isSlicking) && (
                      <ThreeDots color="var(--black)" height={10} width={70} />
                    )}
                  </div>
                  {fromProfile && (
                    <div className="new-calendar--actions">
                      {this.renderGoToToday(getNewHorizontalScrollIndex)}
                      {this.renderControls()}
                    </div>
                  )}
                </div>
              )}
              {this.renderCalendar()}
            </div>
            {!isComingUp && (
              <ul className="new-calendar--legend">
                <li>
                  <span /> All set TeeUps
                </li>
                <li>
                  <span /> Planning TeeUps
                </li>
                <li>
                  <span /> Past TeeUps
                </li>
              </ul>
            )}
          </div>
          {this.renderCalendarCard({ syncEmail, displayedCalendars })}
        </div>
        {isChooseButtonVisible && (
          <div className="new-calendar--choosetime">
            <ChooseTimeButton onPress={onSelectDateForSuggestion} />
          </div>
        )}
      </div>
    )
  }
}

const ChooseTimeButton = ({ onPress }) => (
  <View style={styles.buttonWrapper}>
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <Text style={styles.buttonText}>
        Choose this time for your Suggestion
      </Text>
    </TouchableOpacity>
  </View>
)
ChooseTimeButton.propTypes = {
  onPress: PropTypes.func.isRequired,
}

CalendarView.propTypes = {
  isEventsLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  datesArray: PropTypes.array,
  currentDayIndex: PropTypes.number,
  onMomentumScrollEnd: PropTypes.func,
  flatListRef: PropTypes.func,
  verticalScrollPosition: PropTypes.number,
  setVerticalScrollPosition: PropTypes.func,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activeScreen: PropTypes.string,
  moveCalendarForward: PropTypes.func,
  moveCalendarBackward: PropTypes.func,
  changeViewType: PropTypes.func,
  changeDate: PropTypes.func,
  openViewTypes: PropTypes.func,
  openSettings: PropTypes.func,
  isDateDifferentFromInitial: PropTypes.bool,
  comingUpComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  calendarCardStyles: PropTypes.object,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      isFromCreateTeeup: PropTypes.bool,
      passedEvent: PropTypes.object,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  onPickTimerangeFromCalendar: PropTypes.func,
  onSelectDateForSuggestion: PropTypes.func,
  duration: PropTypes.number,
  onDurationChange: PropTypes.func,
  allowGoToPast: PropTypes.bool,
  selectToday: PropTypes.func,
  isFromSugggestionDetails: PropTypes.bool,
}

const mapStateToProps = state => ({
  syncEmail: selectSyncEmail(state),
  displayedCalendars: selectDisplayedCalendars(state),
})

export default connect(mapStateToProps, null)(CalendarView)
