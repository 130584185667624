import { Suggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

interface Props {
  isWhen?: boolean
  suggestion?: Suggestion
}

const titleStyles = { fontWeight: 600, fontSize: 18, marginBottom: 4 }
const subtitleStyles = { fontWeight: 600, fontSize: 14, color: "var(--gray4)" }

const Decided = ({ isWhen, suggestion }: Props) => {
  if (isWhen) {
    const startDate = dayjs(suggestion?.startDate)
    return (
      <div>
        <div style={titleStyles}>{startDate.format("ddd, MMM D")}</div>
        <div style={subtitleStyles}>
          {startDate.format("h:mm A") +
            " - " +
            startDate
              .add(suggestion?.duration || 0, "minutes")
              .format("h:mm A")}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div style={titleStyles}>{suggestion?.value}</div>

      {suggestion?.details && (
        <div style={subtitleStyles}>{suggestion?.details}</div>
      )}
    </div>
  )
}

export default Decided
