import { useDispatch } from "react-redux"

import { setActiveTab } from "@actions/commonActions"
import { SvgIcon } from "@components/common"
import { useInboxStore } from "stores/inboxStore"
import { InboxNudge } from "types/inbox"
import { Participant } from "types/participant"

interface Props {
  teeUpId: number
  nudge: InboxNudge
  onOpen?: () => void
  users: Record<number, Participant>
}

const Nudge = ({ teeUpId, nudge, onOpen, users }: Props) => {
  const dispatch = useDispatch()
  if (!nudge) return false

  const isMention = nudge?.mentions?.length > 0
  const isNudge = nudge?.nudges?.length > 0
  const ignoreNudge = useInboxStore(state => state.ignoreNudge)

  const getText = () => {
    const formatText = (count, singular, plural) =>
      `${count} ${count === 1 ? singular : plural}`

    const getMentionText = () => {
      const user = users[nudge?.mentions?.[0]?.userId] || {}
      const baseName = user?.name?.split(" ")?.[0] || ""
      if (nudge.mentions.length === 1) {
        return (
          <span>
            <span className="font-bold">{baseName}</span> mentioned you
          </span>
        )
      }
      return (
        <span>
          You have{" "}
          <span className="font-bold">
            {formatText(nudge.mentions.length, "mention", "mentions")}
          </span>
        </span>
      )
    }

    const getNudgeText = () => {
      const user = users[nudge?.nudges?.[0]?.userId] || {}
      const baseName = user?.name?.split(" ")?.[0] || ""
      if (nudge.nudges.length === 1) {
        return (
          <span>
            <span className="font-bold">{baseName}</span> nudged you
          </span>
        )
      }
      return (
        <span>
          You have{" "}
          <span className="font-bold">
            {formatText(nudge.nudges.length, "nudge", "nudges")}
          </span>
        </span>
      )
    }

    const getCombinedText = () => {
      const nudgeCount = formatText(nudge?.nudges?.length, "Nudge", "Nudges")
      const mentionCount = formatText(
        nudge?.mentions?.length,
        "Mention",
        "Mentions"
      )
      const mainText = `${nudgeCount} + ${mentionCount}`
      return (
        <span className="font-normal">
          You have <span className="font-bold">{mainText}</span>
        </span>
      )
    }

    if (isMention && isNudge) return getCombinedText()
    if (isMention) return getMentionText()
    if (isNudge) return getNudgeText()
    return null
  }

  const ignoreNudgesAndMentions = () => {
    if (isNudge) {
      const ignoredNudges = localStorage.getItem("ignoredNudges")
      const ignoredNudgesList = ignoredNudges ? JSON.parse(ignoredNudges) : []
      const nudgeIds = nudge?.nudges?.map(mention => mention.id)

      localStorage.setItem(
        "ignoredNudges",
        JSON.stringify([...ignoredNudgesList, ...nudgeIds])
      )
      ignoreNudge(teeUpId, nudge.nudges[0], "nudges")
    }

    if (isMention) {
      const ignoredMentions = localStorage.getItem("ignoredMentions")
      const ignoredMentionsList = ignoredMentions
        ? JSON.parse(ignoredMentions)
        : []
      const mentionIds = nudge?.mentions?.map(mention => mention.id)
      localStorage.setItem(
        "ignoredMentions",
        JSON.stringify([...ignoredMentionsList, ...mentionIds])
      )
      ignoreNudge(teeUpId, nudge.mentions[0], "mentions")
    }
  }

  const handleOpen = () => {
    ignoreNudgesAndMentions()
    dispatch(setActiveTab(isNudge ? 3 : 2))

    if (onOpen) {
      onOpen()
    }
  }

  if (!isMention && !isNudge) return false

  return (
    <div
      className="flex items-center justify-between gap-2 text-sm border border-blue-100 rounded-lg text-blue-500 bg-blue-50 py-2.5 px-3 animate-fade mt-4"
      onClick={e => e.stopPropagation()}>
      <div className="flex items-center gap-1">
        <SvgIcon
          name={isNudge ? "nudge_blue" : "mention_blue"}
          className="!size-4"
        />
        <span>{getText()}</span>
        {/* <span>{isMention ? "mentioned you" : "nudged you"}</span> */}
      </div>

      <div className="text-sm font-medium leading-tight flex items-center gap-4">
        <div className="cursor-pointer" onClick={handleOpen}>
          Open
        </div>
        <div className="cursor-pointer" onClick={ignoreNudgesAndMentions}>
          Ignore
        </div>
      </div>
    </div>
  )
}

export default Nudge
