import { useCallback, useState } from "react"

import { filtersList } from "@utils/inbox"
import { useInboxStore } from "stores/inboxStore"
import { FilterListItem } from "types/inbox"

import Item from "./Item"
import Search from "./Search"

const Tabs = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const totalCount = useInboxStore(state => state.pagination.total)
  const currentOpenTab = useInboxStore(state => state.currentOpenTab)
  const setCurrentOpenTab = useInboxStore(state => state.setCurrentOpenTab)
  const isLoading = useInboxStore(state => state.isLoading)

  return (
    <div className="md:m-6 inline-flex xs:w-[100vw] md:w-[calc(100%-50px)] flex-col items-start justify-start gap-2 md:rounded-lg border-b border-neutral-300 bg-rose-500 px-6 pt-6">
      <Search isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />

      <div className="flex gap-2 overflow-auto xs:max-w-[100vw] md:max-w-[65vw] xs:ml-[-20px] md:ml-0">
        {filtersList.map(filter => (
          <Item
            key={filter.value}
            title={filter.title}
            active={filter.title === currentOpenTab.title}
            paginationCount={isLoading ? "" : totalCount}
            onClick={() => setCurrentOpenTab(filter)}
          />
        ))}
      </div>
    </div>
  )
}

export default Tabs
