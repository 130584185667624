import { StyleSheet } from "react-native"

import { Units, AppColors, AppFonts, AppSizes } from "@theme/"

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignSelf: "baseline",
    backgroundColor: AppColors.brand.lightGrey,
  },
  strictContactsBackground: {
    height: AppSizes.screen.height,
    width: Units.responsiveValue(122),
    backgroundColor: AppColors.brand.lightGrey,
    left: 0,
    position: "absolute",
  },
  lightRow: {
    backgroundColor: AppColors.brand.white50,
  },
  darkRow: {
    backgroundColor: AppColors.brand.card2Grey50,
  },
  month: {
    ...AppFonts.bigMedium,
    color: AppColors.brand.black2_54,
  },
  dayNumber: {
    ...AppFonts.xlBold,
    color: AppColors.brand.black2_08,
    marginTop: Units.responsiveValue(2),
  },
  day: {
    ...AppFonts.middle,
    color: AppColors.brand.black2_08,
  },
  time: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.black2_54,
    marginTop: Units.responsiveValue(4),
  },
  totalCount: {
    marginLeft: Units.responsiveValue(6),
    justifyContent: "center",
  },
  totalIcon: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(14),
    borderRadius: Units.responsiveValue(14) / 2,
    justifyContent: "center",
    alignItems: "center",
  },
  activeTotalIcon: {
    backgroundColor: AppColors.brand.green,
  },
  zeroTotalIcon: {
    backgroundColor: AppColors.brand.warmGrey2,
  },
  totalIconTick: {
    width: Units.responsiveValue(8),
    height: Units.responsiveValue(6),
  },
  timeSuggestions: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: Units.responsiveValue(48),
    flex: 1,
  },
  emptyColumn: {
    width: Units.responsiveValue(41),
    height: Units.responsiveValue(126),
    backgroundColor: AppColors.brand.lightGrey,
    flex: 1,
  },
  total: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: Units.responsiveValue(16),
  },
  lightColumn: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  darkColumn: {
    backgroundColor: AppColors.brand.darkGreyBackground,
  },
  timeList: {
    width: AppSizes.screen.width,
  },
  contactsBlock: {
    width: AppSizes.screen.width,
    marginTop: Units.responsiveValue(136),
    position: "absolute",
    alignSelf: "baseline",
  },
  backBlock: {
    width: Units.responsiveValue(110),
    height: AppSizes.screen.height,
    backgroundColor: AppColors.brand.lightGrey,
    justifyContent: "center",
    position: "absolute",
    flex: 1,
  },
  contactRow: {
    height: Units.responsiveValue(56),
    borderTopWidth: Units.responsiveValue(1),
    borderColor: AppColors.brand.darkGrey2_20,
    justifyContent: "center",
  },
  userContactRow: {
    backgroundColor: AppColors.transparent,
  },
  userContactOpacity: {
    backgroundColor: AppColors.brand.white70,
  },
  statusLine: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "flex-start",
    width: AppSizes.screen.width,
    zIndex: 9997,
  },
  reactionImage: {
    zIndex: 10_000,
    position: "absolute",
  },
  statusLineColor: {
    backgroundColor: AppColors.brand.greyStatus_13,
    height: Units.responsiveValue(24),
  },
  redLine: {
    backgroundColor: AppColors.brand.pink,
    zIndex: 10_000,
  },
  vip: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    margin: Units.responsiveValue(8),
  },
  userAvatar: {
    marginLeft: Units.responsiveValue(10),
    width: Units.responsiveValue(40),
    height: Units.responsiveValue(40),
    zIndex: 10_000,
  },
  closePollBlock: {
    height: Units.responsiveValue(64),
    width: AppSizes.screen.width,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: AppColors.brand.white70,
    position: "absolute",
    zIndex: 9998,
  },
  closePollBtn: {
    marginRight: Units.responsiveValue(16),
    marginTop: Units.responsiveValue(8),
    marginBottom: Units.responsiveValue(8),
    backgroundColor: AppColors.navbarButton,
    borderRadius: Units.responsiveValue(28),
    marginLeft: Units.responsiveValue(16),
    height: Units.responsiveValue(46),
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  closePollText: {
    color: AppColors.brand.white,
    ...AppFonts.biggerDemibold,
  },
  statusLabel: {
    marginLeft: Units.responsiveValue(50),
    color: AppColors.brand.white,
  },
})
