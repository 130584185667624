import { Checkbox } from "@mui/material"

import { FilterOption } from "types/inbox"

interface Props {
  option: FilterOption
  onClick: (option: FilterOption) => void
  localSelectedOptions: FilterOption[]
}

const Option = ({ option, onClick, localSelectedOptions }: Props) => {
  return (
    <div
      className="flex h-14 w-full cursor-pointer flex-col justify-center gap-2 bg-stone-50 px-6"
      key={option.id}
      onClick={() => onClick(option)}>
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex h-[17px] items-center justify-start gap-2">
          <div className="select-none font-['Inter'] text-sm text-neutral-700">
            {option.title}
          </div>
        </div>

        <Checkbox
          checked={localSelectedOptions.some(
            selectedOption => selectedOption.id === option.id
          )}
          className="!text-[#303030]"
          sx={{ ".Mui-checked": { color: "#303030" } }}
          onClick={() => onClick(option)}
        />
      </div>
    </div>
  )
}

export default Option
