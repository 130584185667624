import copy from "copy-to-clipboard"
import { useSelector } from "react-redux"

import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import ModalHeader from "@components/ui/DefaultModal/Header"
import { useGenerateSharedLink } from "@hooks/useGenerateSharedLink"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectTeeupPeople } from "@selectors/teeups"
import { history } from "configureStore"
import TeeupItem from "pages/Profile/TeeupItem"
import { useCreateTeeupStore } from "stores/createTeeupStore"
import { useSnackbarStore } from "stores/snackbarStore"

import "./index.scss"

const CreateTeeupConfirmPopup = () => {
  const activeTeeup = useSelector(selectActiveTeeup)
  const teeupPeople = Object.values(useSelector(selectTeeupPeople))

  const { sharedLink } = useGenerateSharedLink(
    activeTeeup.name,
    activeTeeup.slug
  )
  const isOpen = useCreateTeeupStore(
    state => state.isConfirmCreateTeeupPopupOpen
  )
  const setIsOpen = useCreateTeeupStore(
    state => state.setIsConfirmCreateTeeupPopupOpen
  )
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)

  const handleClose = () => setIsOpen(false)

  const handleOpenClick = () => {
    history.push(`/teeUps/${activeTeeup.id}`)
    handleClose()
  }

  const handleSharedLinkCopy = () => {
    const isSuccess = copy(sharedLink)
    if (isSuccess) {
      setSnackbarConfig({
        open: true,
        message: "Link copied to clipboard",
      })
    }
  }

  if (!activeTeeup) return false

  return (
    <DefaultModal open={isOpen} onClose={handleClose}>
      <ModalHeader title="" onClose={handleClose} />
      <div className="create-teeup-confirm-modal-content">
        <h4>New TeeUp Created!</h4>

        <TeeupItem
          teeup={activeTeeup}
          teeupPeople={teeupPeople}
          isFromOutside
        />

        <div className="create-teeup-confirm-modal-footer">
          <Button variant="contained" onClick={handleOpenClick}>
            Open TeeUp
          </Button>
          <Button variant="text" onClick={handleSharedLinkCopy}>
            Copy Link
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default CreateTeeupConfirmPopup
