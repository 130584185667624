import React from "react"

import cn from "classnames"

import { SvgIcon } from "@components/common"
import { Stage } from "types/teeup"

interface ItemProps {
  stage: Stage
  currentStage: Stage | null
  isFirstItem: boolean
  isLastItem: boolean
  onClick: () => void
  isHappeningNowDisabled: boolean
}

const Item = ({
  stage,
  currentStage,
  isFirstItem,
  isLastItem,
  onClick,
  isHappeningNowDisabled,
}: ItemProps) => {
  const { labelToDisplay, position, color, backgroundColor, isDisabled } = stage
  const isSelected = currentStage && currentStage.position === position
  const isPassed = currentStage && currentStage.position >= position
  const isNext = currentStage && currentStage.position === position - 1
  const isFullyDisabled =
    (isDisabled || isHappeningNowDisabled) && !isSelected && !isPassed

  const selectedStyles = isSelected
    ? {
        backgroundColor,
        border: `1px solid ${color}`,
      }
    : {}

  return (
    <div className="time-line-item">
      <div
        className={cn("time-line-item__line", {
          "time-line-item__line__first": isFirstItem,
          "time-line-item__line__future": !isPassed && !isNext,
          "time-line-item__line__next": isNext,
        })}
      />
      <div className="time-line-item__info__container" onClick={onClick}>
        <div
          className={cn("time-line-item__circle", {
            "time-line-item__circle__passed": isPassed,
            "time-line-item__circle__next": isNext,
            "time-line-item__circle__disabled": isFullyDisabled,
          })}>
          {isPassed && (
            <div className="time-line-item__circle__check">
              <SvgIcon name="check" />
            </div>
          )}
          {isNext && <div className="time-line-item__circle__dot" />}
        </div>

        <div
          className={cn("time-line-item__label", {
            "time-line-item__label__selected": isSelected,
            "time-line-item__label__disabled": isFullyDisabled,
          })}
          style={selectedStyles}>
          <p style={{ color }}>{labelToDisplay}</p>
        </div>
      </div>

      {isLastItem && (
        <div className="time-line-item__line time-line-item__line__first time-line-item__line__future" />
      )}
    </div>
  )
}

export default Item
