import { Suggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

import "./index.scss"
import { getTimeListOptions } from "@utils/suggestionUtils"

import DefaultDetails from "./DefaultDetails"
import MapDetails from "./MapDetails"
import OnlineDetails from "./OnlineDetails"

const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  let formattedDuration = ""

  if (hours > 0) {
    formattedDuration += hours === 1 ? "1 hour" : hours + " hours"
    if (minutes > 0) {
      formattedDuration += " "
    }
  }

  if (minutes > 0) {
    formattedDuration += minutes === 1 ? "1 minute" : minutes + " minutes"
  }

  return formattedDuration
}

interface Props {
  suggestion: Suggestion
  title: string
  subtitle: string
  isMap: boolean
  isWhenSuggestion?: boolean
}

const MeetingDetails = ({
  suggestion,
  title,
  subtitle,
  isMap,
  isWhenSuggestion,
}: Props) => {
  return isMap ? (
    // @ts-ignore
    <MapDetails suggestion={suggestion} />
  ) : isWhenSuggestion ? (
    <DefaultDetails suggestion={suggestion} title={title} subtitle={subtitle} />
  ) : (
    <OnlineDetails suggestion={suggestion} />
  )
}

export default MeetingDetails
