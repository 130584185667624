import { Fragment, useCallback, useEffect, useState } from "react"

import { useSelector } from "react-redux"

import Option from "@components/TeeupPeople/Modals/Nudge/Option"
import { teeupListNudgeTypes } from "@config/enums"
import { messageTypeIds } from "@config/mappings"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import I18n from "i18n"
import { sendMessage, sendNudge } from "middlewares/backendMiddleware"
import { useConversationStore } from "stores/conversationStore"
import { useTeeupPeopleStore } from "stores/teeupPeopleStore"
import { NudgeAction, OptionsItem, Participant } from "types/participant"

import "./index.scss"

interface Props {
  onClose: () => void
  currentParticipant: Participant
}

const Options = ({ onClose, currentParticipant }: Props) => {
  const [options, setOptions] = useState<OptionsItem[]>([])
  const activeTeeupId = useSelector(selectActiveTeeupId)
  const setChatMessage = useConversationStore(state => state.setChatMessage)

  useEffect(() => {
    const options = [
      {
        title: "Are you Going?",
        icon: "nudgePeopleNew",
        action: () => handleNudgeClick("areYouGoing"),
      },
      {
        title: "Where Are You?",
        icon: "nudgePeopleNew",
        action: () => handleNudgeClick("whereAreYou"),
      },
      {
        title: "Suggest for Where?",
        icon: "nudgePeopleNew",
        action: () => handleNudgeClick("wouldYouSuggestWhere"),
      },
      {
        title: "Suggest for When?",
        icon: "nudgePeopleNew",
        action: () => handleNudgeClick("wouldYouSuggestWhen"),
      },
      {
        title: "Mention User",
        icon: "ndMentionIcon",
        action: () => mentionUser(),
      },
    ]
    setOptions(options)
  }, [])

  const handleNudgeClick = useCallback(
    (action: NudgeAction) => {
      const actions = {
        areYouGoing: {
          message: I18n.nudgePromptContent.areYouGoing.action,
          subTypeId: teeupListNudgeTypes.areYouGoing,
        },
        whereAreYou: {
          message: I18n.nudgePromptContent.whereAreYou.action,
          subTypeId: teeupListNudgeTypes.whereAreYou,
        },
        wouldYouSuggestWhen: {
          message: I18n.nudgePromptContent.wouldYouSuggestWhen.action,
          subTypeId: teeupListNudgeTypes.wouldYouSuggestWhen,
        },
        wouldYouSuggestWhere: {
          message: I18n.nudgePromptContent.wouldYouSuggestWhere.action,
          subTypeId: teeupListNudgeTypes.wouldYouSuggestWhere,
        },
      }

      const { message, subTypeId } = actions[action]

      sendNudge({
        message,
        messageTypeId: messageTypeIds.nudge,
        mentionIds: [currentParticipant?.id],
        subTypeId,
        teeupId: activeTeeupId,
      } as any)

      onClose()
    },
    [activeTeeupId, currentParticipant]
  )

  const mentionUser = () => {
    if (!currentParticipant) return
    setChatMessage(` @${currentParticipant.username}`)
    // const payload = {
    //   message: "@" + currentParticipant.username,
    //   messageTypeId: messageTypeIds.message,
    //   replyMessage: null,
    //   mentionIds: [currentParticipant.id],
    // }

    // sendMessage(
    //   payload.message,
    //   payload.messageTypeId,
    //   payload.replyMessage,
    //   payload.mentionIds
    // )

    onClose()
  }

  return (
    <div className="modal-participant-details-options">
      <div>
        {options.map(item => (
          <Fragment key={item.title}>
            <Option
              onClick={item.action}
              title={item.title}
              iconName={item.icon}
              className="modal-participant-details-option-item"
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default Options
