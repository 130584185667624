import { updateTeeupStatus } from "@actions/teeupActions"
import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import { teeupCategories } from "@config/enums"
import cn from "classnames"
import { useEffect } from "react"
import { useInboxStore } from "stores/inboxStore"
import { INBOX_TABS } from "types/inbox"

const ArchiveUndo = () => {
  const isArchiveSnackBarOpen = useInboxStore(
    state => state.isArchiveShackBarOpen
  )
  const setIsArchiveSnackBarOpen = useInboxStore(
    state => state.setIsArchiveSnackBarOpen
  )
  const isArchiveTab = useInboxStore(
    state => state.currentOpenTab.value === INBOX_TABS.ARCHIVE
  )
  const currentActiveTeeUp = useInboxStore(state => state.currentActiveTeeUp)
  const setTeeUps = useInboxStore(state => state.setTeeUps)
  const teeUps = useInboxStore(state => state.teeUps)

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (isArchiveSnackBarOpen) {
      timerId = setTimeout(() => {
        setIsArchiveSnackBarOpen(false)
      }, 5000)
    }

    return () => clearTimeout(timerId)
  }, [isArchiveSnackBarOpen])

  const handleUndo = async () => {
    if (!currentActiveTeeUp) return

    const currentCategory = isArchiveTab
      ? teeupCategories.archivedTeeups
      : teeupCategories.teeups

    await updateTeeupStatus(currentActiveTeeUp?.id, currentCategory)

    const clonedTeeUps = [...teeUps]
    clonedTeeUps.unshift(currentActiveTeeUp)

    setTeeUps(clonedTeeUps)
    setIsArchiveSnackBarOpen(false)
  }

  return (
    <div
      className={cn(
        "flex items-center justify-between bg-black px-6 py-3 rounded-lg fixed bottom-4 right-4 w-[calc(100%-232px)] z-50 animate-fade transition-all duration-300",
        {
          "!translate-x-0": isArchiveSnackBarOpen,
          "translate-x-[110%]": !isArchiveSnackBarOpen,
        }
      )}>
      <div className="flex items-center gap-2 text-neutral-50 text-sm font-medium leading-tight">
        <SvgIcon name="archive-2" className="filter invert-[1]" />
        <div>
          {currentActiveTeeUp?.name}{" "}
          {isArchiveTab ? "unarhived" : "moved to Archive"}
        </div>
      </div>

      <Button
        variant="contained"
        className="!bg-white !text-black !py-1 !px-5"
        onClick={handleUndo}>
        Undo
      </Button>
    </div>
  )
}

export default ArchiveUndo
