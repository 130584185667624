export const highlightText = (text, searchVal) => {
  const escapedSearchVal = searchVal.replaceAll(
    /[$()*+.?[\\\]^{|}]/g,
    String.raw`\$&`
  )
  return escapedSearchVal
    ? text.replaceAll(
        new RegExp(`(${escapedSearchVal})`, "gi"),
        "<mark>$1</mark >"
      )
    : text
}
