import { useCallback } from "react"

export const useHandleGetTokenId = ({
  isAuthenticated,
  isUserValid,
  isRegisteredUserValid,
  isIdentityVerified,
  setUserIsLoading,
  getIdToken,
  setIsIdentityVerified,
  user,
  newRegisteredUser,
}) => {
  return useCallback(() => {
    if (
      isAuthenticated &&
      (isUserValid || isRegisteredUserValid) &&
      isIdentityVerified
    ) {
      setUserIsLoading(true)
      getIdToken(isRegisteredUserValid)
      setIsIdentityVerified(false)
    }
  }, [isAuthenticated, user, isIdentityVerified, newRegisteredUser])
}
