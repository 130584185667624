import React, { memo } from "react"

import { SvgIcon } from "@components/common"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import "./index.scss"
import { BOX_SIZE, HOUR_IN_MINUTES } from "@utils/suggestionUtils"

import cn from "classnames"

const TimeLine = ({ isStatic }) => {
  const duration = useSuggestionDurationStore(state => state.duration)
  const setIsOpen = useSuggestionDurationStore(state => state.setIsOpen)

  const isAllDay = +duration.id / HOUR_IN_MINUTES === 24
  const timeLineWidth = +duration.id * (BOX_SIZE / HOUR_IN_MINUTES)

  return (
    <div className="time-line-container">
      {!isStatic && (
        <div className="time-line-label" onClick={() => setIsOpen(true)}>
          <span>{duration.value}</span>
          <SvgIcon name="arrow-2" color="#fff" />
        </div>
      )}

      <div
        className={cn("time-line", {
          "static-time-line": isStatic,
          "all-day-time-line": isAllDay,
        })}
        style={{ width: timeLineWidth }}></div>
    </div>
  )
}

export default memo(TimeLine)
