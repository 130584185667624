import { StyleSheet } from "react-native"

import { AppFonts, AppColors, Units } from "@theme/"

export const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: "row", alignItems: "center" },
  leftPartContainer: {
    // height: Units.responsiveValue(80),
    flexDirection: "row",
    // marginLeft: Units.responsiveValue(16),
    width: "50%",
  },
  contentContainer: {
    width: "97%",
    height: "160px",
    flexDirection: "row",
  },
  noMargin: {
    marginHorizontal: 0,
  },
  crownContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    zIndex: 1,
    backgroundColor: "#ffb520",
    borderRadius: "50%",
    border: "2px solid white",
    paddingHorizontal: 5,
    paddingVertical: 8,
    // width:
  },
  crownImage: {
    height: "12px",
    width: "18px",
  },
  newSideBar: {
    width: Units.responsiveValue(16),
    alignItems: "center",
    borderLeftWidth: Units.responsiveValue(4),
    borderLeftColor: AppColors.brand.pink,
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
  },
  content: {
    width: "50%",
    flexGrow: 1,
    paddingBottom: "15px",
    paddingTop: "15px",
    // paddingTop: Units.responsiveValue(10),
    // paddingBottom: Units.responsiveValue(7),
    marginHorizontal: Units.responsiveValue(12),
  },
  hasUpdateText: {
    color: AppColors.brand.pink,
  },
  thumbnailImage: {
    // width: Units.responsiveValue(88),
    width: "100%",
    height: "100%",
    // maxHeight: Units.responsiveValue(48),
    // borderRadius: Units.responsiveValue(4),
    overflow: "hidden",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: AppColors.brand.grey,
  },
  nameMargin: {
    marginBottom: Units.responsiveValue(4),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameText: {
    flex: 1,
    flexWrap: "wrap",
    fontFamily: "greycliff-cf-bold",
    fontSize: "17px",
    // lineHeight: 1.29,
    color: "#222222",
    // ...AppFonts.biggerBold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.4),
    // color: AppColors.brand.black2,
  },
  nameInactiveText: {
    color: AppColors.brand.darkGrey2,
  },
  mediumText: {
    flex: 1,
    // ...AppFonts.mediumDemibold,
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "13px",
    color: "#9d9d9d",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.26),
    // color: AppColors.brand.warmGrey2,
  },
  divider: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  usersLine: {
    position: "absolute",
    bottom: "15px",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    marginTop: Units.responsiveValue(6.5),
  },
  countText: {
    ...AppFonts.mediumMedium,
    lineHeight: AppFonts.sizes.medium,
    color: AppColors.brand.warmGrey2,
  },
  arrowIcon: {
    resizeMode: "contain",
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
    marginLeft: Units.responsiveValue(4),
    marginRight: Units.responsiveValue(2),
  },
  withBorders: {
    borderTopWidth: 0,
  },
})

export const container = isGreyOverlay => ({
  // flex: 1,
  width: "100%",
  borderRadius: 8,
  backgroundColor: AppColors.brand[isGreyOverlay ? "grey" : "white"],
})
