import React, { useMemo } from "react"

import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import useCopyToClipboard from "@hooks/useCopyToClipboard"
import { Suggestion } from "types/suggestions"

interface Props {
  suggestion: Suggestion
}

const OnlineDetails = ({ suggestion }: Props) => {
  const currentValue = suggestion?.details || suggestion?.value
  const { copyToClipboard } = useCopyToClipboard()
  // @TODO remove this shit
  const additionalInformation =
    suggestion?.fullDetails === "undefined"
      ? undefined
      : suggestion?.fullDetails

  const isUrl = useMemo(() => {
    var pattern = new RegExp(
      String.raw`^(https?:\/\/)?` +
        String.raw`((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|` +
        String.raw`((\d{1,3}\.){3}\d{1,3}))` +
        String.raw`(\:\d+)?(\/[-a-z\d%_.~+]*)*` +
        String.raw`(\?[;&a-z\d%_.~+=-]*)?` +
        String.raw`(\#[-a-z\d_]*)?$`,
      "i"
    )
    return !!pattern.test(currentValue)
  }, [currentValue])

  const handleJoinMeeting = () => {
    window.location.href = currentValue
  }

  return (
    <div className="meeting-details">
      <div className="meeting-details__block">
        <h5>URL/Address</h5>
        {isUrl && (
          <div>
            <div className="link-box">
              <p>{currentValue}</p>

              <SvgIcon
                name="link-square-black"
                onClick={() => copyToClipboard(currentValue)}
              />
            </div>

            <Button fullWidth onClick={handleJoinMeeting} variant="contained">
              Join meeting <SvgIcon name="open-outline" />
            </Button>
          </div>
        )}

        {!isUrl && <p>{currentValue}</p>}
      </div>

      <div className="meeting-details__block">
        <h5>Additional Information</h5>
        <pre>{additionalInformation || "No additional information posted"}</pre>
      </div>
    </div>
  )
}

export default OnlineDetails
