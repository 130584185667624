import React from "react"

import { FormCodeMessage } from "../newSignUpEmailStyles"

export const CodeMessage = () => (
  <FormCodeMessage>
    We just sent you a sign-up code. Please check your inbox and paste the code
    below.
  </FormCodeMessage>
)
