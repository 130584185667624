import React from "react"

import classnames from "classnames"
import { ReactSVG } from "react-svg"

import "./index.scss"

const defaultElement = "button"
/**
 *
 * @param as allows for the button to be any html element such as: div, a, button...
 * @param size predefined button sizes: default, big, small // todo
 * @param shape predefined button shapes: round | rectangular | ...; round should be default // todo
 * @param type of the button that will set the particular color schema to the button; options: default, primary, disabled
 * @param icon optional; should be the value from imageUtils.js -> images; has to be SVG image
 * @param border optional; adds border in the same color as font
 * @param notification optional; type number;
 * @param notificationStyle optional; "default" | "primary"
 * @param active optional; type boolean; if true apply style as for hover
 * Other parameters are related to the html element type
 */

function Button({
  onClick,
  children,
  // size,
  as,
  icon,
  type = "default",
  border = false,
  notification,
  notificationStyle = "default",
  active,
  ...rest
}) {
  const TagName = as || defaultElement

  return (
    <TagName
      {...rest}
      onClick={onClick}
      className={classnames("button", {
        border: border,
        // ...(size && { [style[size]]: size }),
        [`type-${type}`]: type,
        active: active,
      })}>
      {icon && <ReactSVG src={icon} className={"icon"} />}
      {children}
      {notification > 0 && (
        <div
          className={classnames("number-notification-indicator", {
            [`type-${type}`]: type,
            [`notification-${notificationStyle}`]: notificationStyle,
          })}>
          {notification > 9 ? (
            <div className={"number-notification-indicator__dot"} />
          ) : (
            notification
          )}
        </div>
      )}
    </TagName>
  )
}

export default Button
