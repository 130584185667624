import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Wrapper = styled.section`
  ${({ $isFullWidth, $isSuggestions, $isOnlySuggest }) => css`
    // HERE
    width: ${$isFullWidth ? "90%" : "55%"};
    background-color: var(--lightGray7);
    border: 1px solid var(--lightGray5);
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 2;
    margin-bottom: 16px;

    ${$isOnlySuggest
      ? css`
          padding: 80px 15px;
        `
      : css`
          padding: 15px;
        `};

    ${!$isSuggestions &&
    css`
      width: ${$isFullWidth ? "90%" : "55%"};
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: var(--lightBlue7);
    `}
  `};
`

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  ${({ $isDecidedSuggestion }) =>
    $isDecidedSuggestion &&
    css`
      width: 100%;
      flex-direction: column-reverse;
      margin: 0 auto;

      @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
        width: 80%;
      }

      @media (max-width: ${MEDIA_SIZES.MOBILE}) {
        width: 100%;
      }
    `};
`

export const DecidedWrapper = styled.div`
  width: 100%;
  margin: 10px auto 20px auto;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 80%;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
  }
`
