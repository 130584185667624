import { PickersDayProps } from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"
import { DateRange } from "@mui/x-date-pickers-pro"

import "./index.scss"
import cn from "classnames"

interface Props extends PickersDayProps<Dayjs> {
  selectedDate?: DateRange<Dayjs>
  isCustomDateRangeOpen?: boolean
}

const DayItem = ({
  day,
  selectedDate,
  selected,
  onDaySelect,
  outsideCurrentMonth,
  disabled,
  isCustomDateRangeOpen,
  ...rest
}: Props) => {
  //   console.log("rest props", rest)

  const isSelectedInRange = day.isBetween(
    selectedDate?.[0],
    selectedDate?.[1],
    "hour"
  )
  const isPrimarySelectedDay =
    day.isSame(selectedDate?.[0], "day") || day.isSame(selectedDate?.[1], "day")

  return (
    <div
      onClick={() => onDaySelect(day)}
      className={cn(
        "calendar-day-item",
        {
          "calendar-day-item__default": !isCustomDateRangeOpen,
          "calendar-day-item__selected":
            (isSelectedInRange || selected) && !isCustomDateRangeOpen,
          "calendar-day-item__outside-range": outsideCurrentMonth || disabled,
        },
        {
          "calendar-day-item__range__selected":
            isCustomDateRangeOpen && isSelectedInRange,
          "calendar-day-item__range__primary-selected":
            isCustomDateRangeOpen && isPrimarySelectedDay,
        }
      )}>
      {day.format("DD")}
    </div>
  )
}

export default DayItem
