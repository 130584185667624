import OneSignal from "react-onesignal"

import endpoints from "@config/endpoints"

import api from "./api"

const NOTIFICATION_PERMISSIONS = {
  granted: "granted",
  default: "default",
}

class NotificationService {
  init() {
    const appId = process.env.REACT_APP_ONE_SIGNAL_APP_ID
    const safari_web_id = process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID

    const options = {
      safari_web_id,
    }

    OneSignal.initialize(appId, options, [
      {
        event: "notificationDisplay",
        callback: event => console.log(event, "NOTIFICATION"),
      },
    ])
  }

  async setEmail(userEmail) {
    try {
      await OneSignal.setEmail(userEmail)
    } catch (error) {
      console.error("Error in setEmail", error.message)
    }
  }

  async setTags(tags) {
    try {
      await OneSignal.sendTags(tags)
    } catch (error) {
      console.error("Error in setTags", error.message)
    }
  }

  async askForNotificationsPermsions() {
    try {
      let currentState = await OneSignal.getNotificationPermission()
      if (currentState !== NOTIFICATION_PERMISSIONS.granted) {
        // Workaround to force OneSignal prompt to popup if user denied notifications in native prompt
        setTimeout(() => OneSignal.showSlidedownPrompt(), 5000)
        await OneSignal.registerForPushNotifications()
        currentState = await OneSignal.getNotificationPermission()
      }

      if (currentState === NOTIFICATION_PERMISSIONS.granted) {
        await OneSignal.registerForPushNotifications()

        const playerId = await OneSignal.getPlayerId()
        api.client
          .post(endpoints.notification.registerDevice, {
            deviceId: playerId,
          })
          .catch(error => {
            console.log("error registering device", error)
          })
      }
    } catch (error) {
      console.error("Error in askForNotificationsPermsions", error.message)
    }
  }

  async removePlayedId() {
    try {
      const playerId = await OneSignal.getPlayerId()

      const deleteUrl = endpoints.notification.deleteUserDeviceId(playerId)
      api.client.delete(deleteUrl).catch(error => {
        console.log("error removing device id", error)
      })
    } catch (error) {
      console.error("Error in removePlayedId", error.message)
    }
  }
}

const notificationService = new NotificationService()

export default notificationService
