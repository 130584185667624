import { useMemo } from "react"

import { useInboxStore } from "stores/inboxStore"
import { FilterOption, FilterTypes } from "types/inbox"

import Option from "./Option"

const Applied = () => {
  const selectedFilters = useInboxStore(state => state.selectedFilters)
  const setSelectedFilters = useInboxStore(state => state.setSelectedFilters)
  const setDefaultSelectedFilters = useInboxStore(
    state => state.setDefaultSelectedFilters
  )

  const joinedOptions = useMemo(
    () => Object.values(selectedFilters).flat(),
    [selectedFilters]
  )

  const handleOptionRemove = (option: FilterOption) => {
    const filteredOptions = selectedFilters[option.filterType].filter(
      f => f.id !== option.id
    )

    setSelectedFilters(filteredOptions, option.filterType)
  }

  const handleClearAll = () => {
    setDefaultSelectedFilters()
  }

  if (joinedOptions?.length === 0) return false

  return (
    <div className="mx-6 my-4">
      <div className="mb-2 text-sm font-medium text-neutral-700 flex justify-between">
        <div>Applied filters</div>
        <div
          onClick={handleClearAll}
          className="text-sm text-neutral-700 cursor-pointer">
          Delete all
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        {joinedOptions.map(option => (
          <Option
            key={option.id}
            option={option}
            onRemove={handleOptionRemove}
          />
        ))}
      </div>
    </div>
  )
}

export default Applied
