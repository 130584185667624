import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: AppColors.brand.white,
    paddingLeft: Units.rem(1),
    paddingRight: Units.rem(2),
    paddingVertical: Units.rem(0.75),
  },
  contactInfo: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  extraRightPadding: {
    paddingRight: Units.rem(1.25),
  },
  avatar: {
    height: Units.rem(2.5),
    width: Units.rem(2.5),
    borderRadius: Units.rem(2.5) / 2,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.rem(1),
  },
  inviteeIcon: {
    backgroundColor: AppColors.brand.grey2,
  },
  inviteeInitial: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
  },
  flexFull: { flex: 1 },
  titleText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.blackTwo,
  },
  checkboxContainer: {
    justifyContent: "center",
  },
  extraContactsContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    paddingLeft: Units.rem(4.5),
    paddingRight: Units.rem(2),
  },
  noBorder: {
    borderTopWidth: 0,
  },
  extraContactButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.rem(0.625),
    paddingBottom: Units.rem(0.75),
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.grey2,
  },
  extraContact: {
    flex: 1,
  },
  extraContactValue: {
    ...AppFonts.biggerMedium,
    color: AppColors.brand.blackTwo,
    height: Units.responsiveValue(22),
    flexDirection: "row",
    alignItems: "center",
  },
  selectedIconContainer: {
    height: Units.rem(1.5),
    width: Units.rem(1.5),
    justifyContent: "center",
    alignItems: "center",
  },
  selectedIcon: {
    tintColor: AppColors.brand.darkGrey2,
    height: Units.rem(1),
    width: Units.rem(1),
  },
  contactLineRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  contactLineColumn: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  contactTypeLine: {
    height: Units.responsiveValue(20),
  },
  tagTitle: {
    ...AppFonts.smallish,
    color: AppColors.brand.darkGrey2,
  },
  userTagImage: {
    tintColor: AppColors.brand.darkGrey2,
    height: Units.responsiveValue(10),
    width: Units.responsiveValue(10),
  },
  arrowImage: {
    marginHorizontal: Units.rem(0.3),
    tintColor: AppColors.brand.darkGrey2,
  },
  username: {
    fontFamily: "greycliffcf-medium-salt",
    color: AppColors.brand.darkGrey2,
    height: Units.responsiveValue(20),
    alignItems: "center",
    flexDirection: "row",
  },
  matchingContact: { backgroundColor: AppColors.brand.yellowBG },
})

export const getExpandedStyles = isExpanded => {
  return [
    styles.arrowImage,
    {
      transform: [{ rotate: isExpanded ? "180deg" : "0deg" }, { scale: 0.8 }],
    },
  ]
}

export default styles
