import React, { useState } from "react"

import dayjs from "dayjs"
import _, { truncate } from "lodash"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { connect } from "react-redux"

import { RemoveSuggestionBtn } from "@components/common"
import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { selectActiveTeeup, selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserInfo, selectToken } from "@selectors/user"
import { locationTypeIds } from "@utils/gamePlanUtils"
import { MAX_CREATOR_NAME_LENGTH } from "@utils/suggestionUtils"

import { useLabelNew, useSuggestionData } from "../NewTeeupGameplan/hooks"
import { Reactions } from "../NewTeeupGameplan/molecules"
import SuggestionPopup from "../gamePlansCard/SuggestionPopup"
import { PreviewSuggestionReactions, CreatorAvatar } from "components"

import { SuggestionLabel, StatusLabel } from "./atoms"
import { useValue } from "./hooks"
import {
  OutsideWrapper,
  Wrapper,
  MainInfo,
  Image,
  CreatorName,
} from "./suggestionItemStyles"

const SuggestionItem = props => {
  const {
    isCollapsed,
    isPopup,
    suggestion,
    suggestionsLength,
    userInfo,
    teeupId,
    activeTeeup,
    people,
    isPreview = false,
    isCreateTeeup = false,
    isReacted,
    linkedGuestCalendar,
    openSuggestionPreview,
    isAuthenticated,
    children,
    isDisableOnClick,
    contentClassName,
    wrapperClassName,
  } = props

  const [isSuggestionPopupOpen, setIsSuggestionPopupOpen] = useState(false)
  const { img, value, details } = useSuggestionData(suggestion)
  const isDecided = suggestion.selected || suggestion.isSelected
  const isDecidedInPopup = isPopup && isDecided

  const detailsValue = details || "No link provided"
  const isWithdrawn = suggestion.isWithdrawn
  const isPast =
    dayjs().isAfter(dayjs(suggestion.startDate)) && !isWithdrawn && !isDecided

  const isMap =
    suggestion.type === "where" &&
    suggestion?.locationType === locationTypeIds.realWorld

  const { renderValues } = useValue({
    isPopup,
    isDecided,
    detailsValue,
    isCollapsed,
    value,
    isDecidedInPopup,
    isMap,
  })

  const isLabelNew = useLabelNew({
    userInfo,
    suggestion,
    teeupId,
    isPreview,
  })

  const toggleSuggestionPopupHandle = () => {
    if (isDisableOnClick) return

    if (isPreview) {
      if (isAuthenticated || !openSuggestionPreview) return

      return openSuggestionPreview(suggestion.id, suggestion.isCreated)
    } else if (!isPopup) {
      setIsSuggestionPopupOpen(prevState => !prevState)
    }
  }

  const creatorName = `@${suggestion.creator?.name}`
  const creatorAvatar = suggestion.creator?.avatar || userInfo?.avatar
  const isPreviewReactions =
    isPreview &&
    (!isCollapsed || isReacted) &&
    suggestion.reactions &&
    !isDecided
  const isTeeupReactions =
    !isPreview && !isCollapsed && !isWithdrawn && !suggestion.reactions
  const isRemoveBtn =
    isCreateTeeup ||
    (suggestion.isCreated && !suggestion.isPublished && isCollapsed)

  const renderStatusLabel = () => {
    let labelText = _.capitalize(suggestion.type)

    if (isLabelNew) {
      labelText += " · New Suggestion"
    } else if (isWithdrawn) {
      labelText += " · Withdrawn"
    } else if (isDecided) {
      labelText += " · Decided"
    }

    return (
      <StatusLabel
        isDecided={isDecided}
        text={labelText}
        type={suggestion.type}
      />
    )
  }

  const renderConflictLabel = () => {
    const conflictStatusText =
      !!linkedGuestCalendar && suggestion.isCalendarConflict
        ? "Calendar conflict"
        : "Available"
    const conflictStatusColor =
      !!linkedGuestCalendar && suggestion.isCalendarConflict
        ? "var(--red2)"
        : "var(--green2)"

    switch (true) {
      case isPreview && isCollapsed && !isCreateTeeup:
        return (
          <SuggestionLabel
            text={truncate(creatorName, {
              length: MAX_CREATOR_NAME_LENGTH,
              separator: "...",
            })}
          />
        )
      case isPreview && !isCollapsed && !!linkedGuestCalendar:
        return (
          <SuggestionLabel
            isDot
            color={conflictStatusColor}
            text={conflictStatusText}
          />
        )
      default:
        return null
    }
  }

  return (
    <OutsideWrapper className={wrapperClassName}>
      {!isCollapsed && (
        <CreatorAvatar
          isCollapsed={isCollapsed}
          creatorAvatar={creatorAvatar}
        />
      )}

      <Wrapper
        $isPreview={isPreview}
        $isMap={isMap}
        $isDecidedInPreview={isDecided && isPreview}
        $isCollapsed={isCollapsed}
        $isWithdrawn={isWithdrawn || isPast}
        $suggestionsLength={suggestionsLength}
        $decidedInPopup={isDecidedInPopup}
        onClick={toggleSuggestionPopupHandle}
        className={contentClassName}>
        {!isPreview && !isCollapsed && renderStatusLabel()}

        {renderConflictLabel()}

        {!isCollapsed && <CreatorName>{creatorName}</CreatorName>}

        <MainInfo
          $isWhen={suggestion.type === GAMEPLAN_TYPES.when}
          $isCollapsed={isCollapsed}>
          {isCollapsed && (
            <CreatorAvatar
              isCollapsed={isCollapsed}
              creatorAvatar={creatorAvatar}
            />
          )}

          {/* {img && <Image src={img} alt={value} />} */}

          {renderValues()}
        </MainInfo>

        {isPreviewReactions && (
          <PreviewSuggestionReactions
            suggestionId={suggestion.id}
            reactions={suggestion.reactions}
          />
        )}
        {isTeeupReactions && <Reactions suggestion={suggestion} />}

        {isMap && children}

        {isRemoveBtn && (
          <RemoveSuggestionBtn
            isCreateTeeup={isCreateTeeup}
            isNoPadding
            isInline
            type={suggestion.type}
            id={isCreateTeeup ? suggestion.slug : suggestion.id}
          />
        )}
      </Wrapper>

      {isSuggestionPopupOpen &&
        ReactDOM.createPortal(
          <SuggestionPopup
            activeTeeup={activeTeeup}
            people={people}
            draggableSuggestionRow={
              <SuggestionItem {...props} isPopup isCollapsed={false} />
            }
            currentSuggestion={suggestion}
            currentSuggestionData={suggestion}
            toggleSuggestionPopup={toggleSuggestionPopupHandle}
          />,
          document.querySelector("#modal-root")
        )}
    </OutsideWrapper>
  )
}

SuggestionItem.propTypes = {
  isCollapsed: PropTypes.bool,
  isPopup: PropTypes.bool,
  isMap: PropTypes.bool,
  suggestion: PropTypes.object,
  suggestionsLength: PropTypes.number,
  isPreview: PropTypes.bool,
  isCreateTeeup: PropTypes.bool,
  isCalendarConflict: PropTypes.bool,
  isReacted: PropTypes.bool,
  isDisableOnClick: PropTypes.bool,
  openSuggestionPreview: PropTypes.func,
  contentClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state),
  teeupId: selectActiveTeeupId(state),
  activeTeeup: selectActiveTeeup(state),
  people: selectTeeupPeople(state),
  linkedGuestCalendar: selectGuestLinkedCalendar(state),
  isAuthenticated: selectToken(state),
})

export default connect(mapStateToProps)(SuggestionItem)
