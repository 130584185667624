import React, { memo } from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"
import { ReactSVG } from "react-svg"

// import Icon from 'react-native-vector-icons/FontAwesome5'
import { images } from "@utils/imageUtils"

import styles, {
  commentedSuggestion,
  messageSenderText,
} from "./commentedSuggestionStyles"

import "./commentSuggestion.scss"

const CommentedSuggestion = ({
  isMyMessage,
  name,
  suggestionName,
  suggestionType,
  suggestionCreatorName,
  goToSuggestion,
  isDisabled,
  onSuggestionCommentScreen,
  newDesign,
}) => {
  const messageSender = isMyMessage
    ? onSuggestionCommentScreen
      ? "My comment"
      : "My comment on"
    : onSuggestionCommentScreen
    ? `${name} commented`
    : `${name} commented on`
  const suggestionMessage =
    `${suggestionCreatorName} suggested` +
    (suggestionType === "when" ? " When:" : "")

  return (
    <View style={commentedSuggestion(isMyMessage)}>
      <Text style={messageSenderText(isMyMessage, newDesign)}>
        {messageSender}
      </Text>
      {onSuggestionCommentScreen || newDesign ? null : (
        <TouchableOpacity
          disabled={isDisabled}
          onPress={goToSuggestion}
          style={styles.suggestionContainer}>
          <View style={styles.suggestionBar} />
          {/* <Icon
                        name={getGameplanIcon({ type: suggestionType })}
                        style={styles.suggestionIcon}
                        size={10}
                        solid
                    /> */}
          <View style={styles.suggestionInfo}>
            <Text style={styles.suggestionLine}>{suggestionMessage}</Text>
            <Text style={styles.suggestionLine}>{suggestionName}</Text>
          </View>
        </TouchableOpacity>
      )}
      {onSuggestionCommentScreen || !newDesign ? null : (
        <div className="commented-suggestion">
          <div className="commented-suggestion__commentator">
            <ReactSVG
              className="commented-suggestion__commentator-icon"
              src={
                suggestionType === "when"
                  ? images.ndClockIcon
                  : images.ndLocationPinIcon
              }
            />
            {`${suggestionCreatorName} suggested`}
          </div>
          <div className="commented-suggestion__suggestion">
            {suggestionName}
          </div>
        </div>
      )}
    </View>
  )
}

CommentedSuggestion.propTypes = {
  isMyMessage: PropTypes.bool,
  name: PropTypes.string.isRequired,
  // suggestionName: PropTypes.string.isRequired,
  suggestionName: PropTypes.string,
  suggestionType: PropTypes.string,
  // suggestionCreatorName: PropTypes.string.isRequired,
  suggestionCreatorName: PropTypes.string,
  goToSuggestion: PropTypes.func,
  isDisabled: PropTypes.bool,
  onSuggestionCommentScreen: PropTypes.bool,
}

export default memo(CommentedSuggestion)
