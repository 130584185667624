import { forwardRef } from "react"

import { Scrollbars } from "react-custom-scrollbars"

const SlimScroll = forwardRef(
  ({ children, renderView, hideHorizontal }, ref) => {
    const renderThumbVertical = ({ style, ...props }) => {
      const thumbStyle = {
        cursor: "pointer",
        borderRadius: "inherit",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        visibility: "hidden",
        width: "6px",
      }
      return (
        <div
          style={{ ...style, ...thumbStyle }}
          className="scroll-thumb"
          {...props}
        />
      )
    }

    const renderTrack = ({ style }) => {
      const thumbStyle = {
        position: "absolute",
        width: 8,
        right: 4,
        bottom: 2,
        top: 2,
        borderRadius: 3,
      }
      return <div style={{ ...style, ...thumbStyle }} />
    }

    return (
      <Scrollbars
        ref={ref}
        renderView={renderView}
        renderThumbVertical={renderThumbVertical}
        renderTrackVertical={renderTrack}>
        {children}
      </Scrollbars>
    )
  }
)

export default SlimScroll
