import { useSnackbarStore } from "stores/snackbarStore"

const useCopyToClipboard = () => {
  const setSnackbarConfig = useSnackbarStore(state => state.setSnackbarConfig)

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)

    setSnackbarConfig({
      message: "Copied to clipboard",
      open: true,
    })
  }
  return { copyToClipboard }
}

export default useCopyToClipboard
