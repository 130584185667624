import React from "react"

import "./circleLoader.scss"

export const CircleLoader = () => (
  <div className="circle-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)
