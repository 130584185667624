import React, { useEffect, createContext, useState } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  setAuth0Error,
  setAuth0Type,
  setIsCreateUserAnyway,
  setIsRegisterSsoNames,
} from "../../actions/loginActions"
import { useErrorContent } from "../../components/Auth0Errors/hooks"
import { AUTH0_ERRORS } from "../../pages/Auth0ContentPage/constants"

export const Auth0ErrorContext = createContext({})

const Auth0ErrorState = ({
  children,
  auth0Type,
  auth0Error,
  isRegisterSsoNames,
  setAuth0Type,
  setAuth0Error,
  setIsRegisterSsoNames,
  setIsCreateUserAnyway,
}) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [password, setPassword] = useState("")
  const [isRememberMe, setIsRememberMe] = useState(false)
  const errorInfo = useErrorContent({
    auth0Type,
    auth0Error,
    isRegisterSsoNames,
    setIsRegisterSsoNames,
    setIsCreateUserAnyway,
  })

  const onRememberMeHandle = () => {
    setIsRememberMe(prevState => !prevState)
  }

  const clearErrors = () => {
    setAuth0Type(null)
    setAuth0Error(null)
  }

  useEffect(() => {
    const pathname = window.location.pathname

    return () => {
      if (
        auth0Error?.code !== AUTH0_ERRORS.TRY_EMAIL &&
        !pathname.includes("sign-up-with")
      ) {
        setAuth0Type(null)
        setAuth0Error(null)
      }
    }
  }, [])

  useEffect(() => {
    if (auth0Error?.errorTitle) {
      setIsErrorModalOpen(true)
    }
  }, [auth0Error])

  return (
    <Auth0ErrorContext.Provider
      value={{
        errorInfo,
        password,
        setPassword,
        isRememberMe,
        auth0Error,
        onRememberMeHandle,
        clearErrors,
        isErrorModalOpen,
        setIsErrorModalOpen,
      }}>
      {children}
    </Auth0ErrorContext.Provider>
  )
}

const mapStateToProps = ({ user }) => ({
  loginErrorCode: user.loginErrorCode,
  auth0Type: user.auth0Type,
  auth0Error: user.auth0Error,
  isRegisterSsoNames: user.isRegisterSsoNames,
})

const mapDispatchToProps = dispatch => ({
  setAuth0Type: bindActionCreators(setAuth0Type, dispatch),
  setAuth0Error: bindActionCreators(setAuth0Error, dispatch),
  setIsRegisterSsoNames: bindActionCreators(setIsRegisterSsoNames, dispatch),
  setIsCreateUserAnyway: bindActionCreators(setIsCreateUserAnyway, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth0ErrorState)
