import { DateRange } from "@mui/lab"
import { Dayjs } from "dayjs"
import { create } from "zustand"

import { GeneralDateSuggestion, TimeListOption } from "types/suggestions"

interface SuggestionGeneralDateStore {
  currentDate: DateRange<Dayjs> | undefined
  setCurrentDate: (currentDate: DateRange<Dayjs>) => void
  isCustomDateRangeOpen: boolean
  setIsCustomDateRangeOpen: (isCustomDateRangeOpen: boolean) => void
  triggerCustomDateRangeUpdate: number
  updateCustomDateTrigger: () => void
  isCustomTimeOpen: boolean
  setIsCustomTimeOpen: (isCustomTimeOpen: boolean) => void
  triggerCustomTimeUpdate: number
  updateCustomTimeTrigger: () => void
  suggestions: GeneralDateSuggestion[]
  addSuggestion: (suggestions: GeneralDateSuggestion) => void
  updateSuggestion: (
    index: number,
    update: Partial<GeneralDateSuggestion>
  ) => void
  removeSuggestion: (index: number) => void
  currentTime: TimeListOption | null
  setCurrentTime: (currentTime: TimeListOption) => void
  currentLabel: string
  setCurrentLabel: (currentLabel: string) => void
  clearState: () => void
  currentActiveSuggestionIndex: number
  setCurrentActiveSuggestionIndex: (
    currentActiveSuggestionIndex: number
  ) => void
}

export const useSuggestionGeneralDateStore =
  create<SuggestionGeneralDateStore>()((set, get) => ({
    currentDate: undefined,
    setCurrentDate: currentDate => set({ currentDate }),

    isCustomDateRangeOpen: false,
    setIsCustomDateRangeOpen: isCustomDateRangeOpen =>
      set({ isCustomDateRangeOpen }),

    triggerCustomDateRangeUpdate: 0,
    updateCustomDateTrigger: () =>
      set({
        triggerCustomDateRangeUpdate: get().triggerCustomDateRangeUpdate + 1,
      }),

    isCustomTimeOpen: false,
    setIsCustomTimeOpen: isCustomTimeOpen => set({ isCustomTimeOpen }),

    triggerCustomTimeUpdate: 0,
    updateCustomTimeTrigger: () =>
      set({
        triggerCustomTimeUpdate: get().triggerCustomTimeUpdate + 1,
      }),

    suggestions: [],
    addSuggestion: suggestion =>
      set({ suggestions: [...get().suggestions, suggestion] }),
    updateSuggestion: (index: number, update: Partial<GeneralDateSuggestion>) =>
      set({
        suggestions: [
          ...get().suggestions.slice(0, index),
          { ...get().suggestions[index], ...update },
          ...get().suggestions.slice(index + 1),
        ],
      }),
    removeSuggestion: (index: number) =>
      set({
        suggestions: [
          ...get().suggestions.slice(0, index),
          ...get().suggestions.slice(index + 1),
        ],
        currentActiveSuggestionIndex: 0,
      }),

    currentTime: null,
    setCurrentTime: currentTime => set({ currentTime }),

    currentLabel: "",
    setCurrentLabel: currentLabel => set({ currentLabel }),

    currentActiveSuggestionIndex: -1,
    setCurrentActiveSuggestionIndex: currentActiveSuggestionIndex =>
      set({ currentActiveSuggestionIndex }),

    clearState: () =>
      set({
        currentDate: [null, null],
        currentTime: null,
        currentLabel: "",
        isCustomDateRangeOpen: false,
        isCustomTimeOpen: false,
        triggerCustomDateRangeUpdate: 0,
        triggerCustomTimeUpdate: 0,
        suggestions: [],
        currentActiveSuggestionIndex: -1,
      }),
  }))
