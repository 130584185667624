import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const FontWeight = {
  extraBold: 800,
  bold: 700,
  semibold: 500,
  medium: 600,
  regular: 400,
  thin: 100,
}

export const Title2 = styled.h2`
  font-family: "greycliff-cf";
  font-size: 37px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    font-size: 24px;
  }
`

export const Title5 = styled.h5`
  font-family: "greycliff-cf";
  font-size: 24px;
`

export const Title6 = styled.h6`
  font-family: "greycliff-cf";
  font-size: 18px;
`

export const SubtitleL = styled.span`
  font-family: "greycliff-cf";
  font-size: 18px;
`

export const CaptionL = styled.span`
  font-family: "greycliff-cf";
  font-size: 18px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    font-size: 15px;
  }
`

export const Subtitle = styled.span`
  font-family: "greycliff-cf";
  font-size: 15px;
`

export const SubtitleXS = styled.span`
  font-family: "greycliff-cf";
  font-size: 10px;
`

export const SubtitleS = styled.span`
  font-family: "greycliff-cf";
  font-size: 13px;
`

export const Caption = styled.p`
  font-family: "greycliff-cf";
  font-size: 15px;
`

export const CaptionS = styled.p`
  font-family: "greycliff-cf";
  font-size: 12px;
`
