import React, { useState, useEffect } from "react"

import "./index.scss"
import { confirmAlert } from "react-confirm-alert" // Import
import { connect, useSelector } from "react-redux"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import cn from "classnames"

import { SvgIcon } from "@components/common"
import { statusText } from "@config/mappings"
import { teeupStatusKeys } from "@configs/enums"
import { getStageOptions } from "@utils/teeupStateUtils"
import { Stage } from "types/teeup"

import { updateTeeupStatus } from "../../../middlewares/backendMiddleware"
import {
  selectActiveTeeup,
  selectActiveTeeupGameplans,
} from "../../../selectors/activeTeeup"
import { selectUserId } from "../../../selectors/user"

import StageModal from "./StageModal"
import { StatusDot } from "./dropdownStyles"

const { planning, allset, happeningSoon, happening, ended, cancelled } =
  teeupStatusKeys

const messages = {
  planning:
    "Changing the TeeUp state back to Planning, will result in the When suggestion being removed from the Game Plan. Do you want to continue?",
  allset: "Are you sure you want to mark the TeeUp as All Set?",
  allsetNoDecided:
    "A suggestion with an exact date and time must be in the Game Plan to change the TeeUp to All Set",
  happening: "Are you sure you want to switch the TeeUp to Happening Now?",
  ended: "Are you sure you want to end the TeeUp?",
}

const DropDown = ({ userId, teeUp, active, isHigh = false }) => {
  const [status, setStatus] = useState<Stage | null>(null)
  const { settings, organisers } = teeUp
  const organizer = organisers.map(({ id }) => id).includes(userId)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOnlyGeneralWhenSuggestion, setIsOnlyGeneralWhenSuggestion] =
    useState(false)
  const gameplan = useSelector(selectActiveTeeupGameplans)
  console.log("isOnlyGeneralWhenSuggestion", isOnlyGeneralWhenSuggestion)

  const statusButtonStyles = {
    color: status?.color,
    backgroundColor: isModalOpen ? status?.color : status?.backgroundColor,
  }

  useEffect(() => {
    setIsOnlyGeneralWhenSuggestion(
      gameplan?.[0]?.suggestions?.every(item => item?.isCustomDate)
    )
  }, [gameplan])

  useEffect(() => {
    if (!active) return

    setStatus(
      getStageOptions().find(({ label }) => label === active.status) || null
    )
  }, [active])

  const openModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const isAllowPromoteToAllSetOrPlaning = (option: Stage) => {
    return (
      (option.label === allset || option.label === planning) &&
      settings.allowPromoteToAllSetOrPlanning
    )
  }

  const submitConfirmAlertHandle = option => {
    const isSuggestionsDecided =
      gameplan[0].suggestions.some(item => item?.decided || item?.isDecided) &&
      gameplan[1].suggestions.some(item => item?.decided || item?.isDecided)

    let message = messages[option.label]
    let buttons = [
      {
        label: "No",
        onClick: closeModal,
      },
      {
        label: "Yes",
        onClick: () => {
          setStatus(option.label)
          updateTeeupStatus(userId, option)
          closeModal()
        },
      },
    ]

    if (!isSuggestionsDecided && option.label === allset) {
      message = messages.allsetNoDecided
      buttons = [{ label: "Okay", onClick: closeModal }]
    }

    confirmAlert({
      message,
      buttons,
    })
  }

  const changeStatus = (option: Stage) => {
    if (
      option.label === happeningSoon ||
      (option.label === happening && isOnlyGeneralWhenSuggestion)
    ) {
      return
    }

    if (option.label === planning && status?.label !== allset) {
      return submitConfirmAlertHandle(option)
    }

    if (
      (organizer || isAllowPromoteToAllSetOrPlaning(option)) &&
      settings.allowChangeState
    ) {
      if (option.label === planning) {
        setStatus(option)
        setIsModalOpen(false)
        updateTeeupStatus(userId, option)
      } else {
        submitConfirmAlertHandle(option)
      }
    }
  }

  // const cancelTeeUp = () => {
  //   confirmAlert({
  //     title: "Cancel TeeUp?",
  //     message: `Are you sure you want to cancel "${teeUp.name}"? This will end the TeeUp for all participants and be archived. This action cannot be undone.`,
  //     buttons: [
  //       {
  //         label: "Go Back",
  //         onClick: () => setIsModalOpen(false),
  //       },
  //       {
  //         label: "Cancel",
  //         onClick: () => {
  //           setStatus(cancelled)
  //           setIsModalOpen(false)
  //           updateTeeupArchiveStatus(teeUp.id)
  //           updateTeeupStatus(
  //             userId,
  //             teeupStatusOptions[teeupStatusOptions.length - 1]
  //           )
  //         },
  //       },
  //     ],
  //   })
  // }

  return (
    <>
      <StageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        currentStage={status}
        changeStatus={changeStatus}
        isOnlyGeneralWhenSuggestion={isOnlyGeneralWhenSuggestion}
      />

      <div
        className={cn("dropdown", {
          "dropdown--is-active": isModalOpen,
          "dropdown--high": isHigh,
        })}
        style={statusButtonStyles}
        onClick={openModal}>
        {/* @ts-ignore */}
        <StatusDot $color={status?.color} />
        <span data-testid="teeup-stage">{statusText[status?.label]}</span>
        <div
          className={cn("dialog-button__dropdown-arrow", {
            "arrow-color__orange": status?.label === planning,
            "arrow-color__green": status?.label === allset,
            "arrow-color__blue": status?.label === happening,
            "arrow-color__purple": status?.label === happeningSoon,
            "arrow-color__gray": status?.label === ended,
            "arrow-color__red": status?.label === cancelled,
          })}>
          <SvgIcon name="arrow-2" color={status?.color} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    userId: selectUserId(state),
    active: selectActiveTeeup(state),
  }
}

export default connect(mapStateToProps, null)(DropDown)
