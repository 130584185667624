import StatusIcon from "../../../Content/Participants/ParticipantItem/StatusIcon"

import "./index.scss"

const Options = ({ title, options, onClick }) => {
  return (
    <div className="people-attendance-options">
      <h4>{title}</h4>
      <ul>
        {options.map(item => (
          <li key={item.status} onClick={() => onClick(item)}>
            <StatusIcon status={item} isBlock isLarge />
            <span>{item.text}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Options
