import React, { memo, useMemo, useState } from "react"

import PropTypes from "prop-types"

import NonUser from "@assets/images/non-user.svg"
import { CreatorAvatar } from "@components"
import { RemoveSuggestionBtn } from "@components/common"
import { getTimeRange } from "@utils/dateUtils"

import { InnerEvents } from "../molecules"

import { Wrapper, Right, Info, Text } from "./teeupCustomEventContentStyles"

export const TeeupCustomEventContent = ({ isInner, event }) => {
  const {
    id,
    creator,
    start,
    end,
    isConflict,
    isDecided,
    isPublished,
    innerEvents,
    key: ownKey,
  } = event
  const creatorName = ownKey ? "You" : creator?.name
  const userText = isDecided ? "set this time as" : "suggested"
  const timeText = isDecided ? "Decided" : getTimeRange(start, end)

  return (
    <Wrapper $isInner={isInner} draggable={false}>
      {innerEvents?.length > 1 ? (
        <InnerEvents event={event} />
      ) : (
        <>
          <CreatorAvatar isCollapsed={true} creator={creator || NonUser} />

          <Right>
            <Info
              $isOwn={!!ownKey}
              $isPublished={isPublished}
              $isInner={isInner}
              $isConflict={isConflict}>
              <Text>
                <b>@{creatorName}</b> {userText}
              </Text>
              <Text>{timeText}</Text>
            </Info>

            {ownKey && !isPublished && (
              <RemoveSuggestionBtn id={id} isInner={isInner} />
            )}
          </Right>
        </>
      )}
    </Wrapper>
  )
}

TeeupCustomEventContent.propTypes = {
  isInner: PropTypes.bool,
  event: PropTypes.object,
}
