import { object, string, ref } from "yup"

import { getPasswordErrorMessage } from "@utils/getPasswordErrorMessage"

import { PASSWORD_RULES } from "../../constants/password"

export const passwordSchema = object().shape({
  password: string()
    .min(6, getPasswordErrorMessage(PASSWORD_RULES.LENGTH))
    .matches(
      /[!"#$%&()*,.:<=>?@^{|}-]/,
      getPasswordErrorMessage(PASSWORD_RULES.SYMBOL)
    )
    .matches(/\d/, getPasswordErrorMessage(PASSWORD_RULES.NUMBER))
    .matches(/[A-Z]/, getPasswordErrorMessage(PASSWORD_RULES.UPCASE))
    .matches(/[a-z]/, getPasswordErrorMessage(PASSWORD_RULES.LOWCASE))
    .required("Password is required field"),
  confirmPassword: string()
    .min(6, getPasswordErrorMessage(PASSWORD_RULES.LENGTH))
    .matches(
      /[!"#$%&()*,.:<=>?@^{|}-]/,
      getPasswordErrorMessage(PASSWORD_RULES.SYMBOL)
    )
    .matches(/\d/, getPasswordErrorMessage(PASSWORD_RULES.NUMBER))
    .matches(/[A-Z]/, getPasswordErrorMessage(PASSWORD_RULES.UPCASE))
    .matches(/[a-z]/, getPasswordErrorMessage(PASSWORD_RULES.LOWCASE))
    .oneOf([ref("password"), null], "Passwords do not match")
    .required("Password is required field"),
})
