import { Tab } from "./../components/ui/Tabs/index"
export interface FilterOption {
  id: number
  title: string
  filter: string
  filterType: FilterTypes
}

export enum FilterTypes {
  stage = "stage",
  userStatus = "userStatus",
}

export interface SortOption {
  id: string
  sort: string
  desc: boolean
  title: string
}

export enum INBOX_TABS {
  ACTIVE,
  INVITATIONS,
  SCHEDULED,
  UNSCHEDULED,
  ARCHIVE,
  ENDED,
}

export interface FilterListItem {
  title: string
  value: INBOX_TABS
  requestType?: any
  scheduleFilter?: "scheduled" | "unscheduled"
  filterQuery?: "invited" | "ended"
}

export interface Pagination {
  total: number
  page: number
  nextPage: number
  previousPage: number
}

export interface InboxEmptyData {
  title: string
  description: string
  underlineText?: string
  underlineDescription?: string
  iconName: string
}

export interface WhereSuggestionDetails {
  title: string
  subtitle: string
  iconName?: string
  empty?: boolean
}

export interface TeeUpState {
  seen: boolean
  teeupId: number
  updatedAt: string
}

export interface InboxNudge {
  nudges: any[]
  mentions: any[]
}

export interface FetchTeeupsArgs {
  currentOpenTab: FilterListItem
  selectedFilters: Record<FilterTypes, FilterOption[]>
  searchValue: string
  currentPage: number
}
