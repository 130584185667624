import { CUSTOM_ACTION_KEY } from "@constants/global"

import { removeAnotherReaction } from "./removeAnotherReaction"

export const pushReactionType = ({ suggestion, reactionId, inviteeId }) => {
  const updatedReaction = { ...suggestion.reactions[0] }
  removeAnotherReaction(updatedReaction, inviteeId)

  return {
    ...suggestion,
    reactions: [
      updatedReaction,
      {
        reactedBy: [{ key: CUSTOM_ACTION_KEY }],
        reactionId,
      },
    ],
  }
}
