import { useEffect, useMemo, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { createTeeup } from "@actions/createTeeupActions"
import { useContacts } from "@hooks/useContacts/useContacts"
import { TTime } from "@hooks/useOpenAi/useOpenAi"
import {
  selectGamePlanWhenFromCreate,
  selectGamePlanWhereFromCreate,
} from "@selectors/createTeeUp"
import { selectUserId } from "@selectors/user"
import { formatContact } from "@utils/contactUtils"
import { getRandomBase64Image } from "@utils/imageUtils"
import { createSuggestion } from "middlewares/backendMiddleware"
import { IContact, ISuggestion } from "types/teeup"

const DEFAULT_TEEUP_SETTINGS = {
  allowChangeGameplanOptions: true,
  allowChangeTitle: true,
  allowDecideGameplan: true,
  allowInviteLink: true,
  allowInviteOthers: true,
  allowPromoteToAllSetOrPlanning: true,
  allowPromoteToEnded: false,
}

const DEFAULT_SUGGESTION = {
  type: "when",
  value: null,
  value2: null,
  isCustomDate: false,
  isCustomTime: false,
  isTimeZoneEnabled: false,
  selected: false,
  optionId: null,
  teeupId: null,
  decided: false,
}

const createTeeupToDTO = async ({
  title,
  invitees,
  gamePlans,
  suggestions,
}) => {
  const formattedInvitees = invitees.map(formatContact)
  const image = await getRandomBase64Image("randomSmaller")
  const gameplanData = gamePlans.map(gamePlan => {
    if (gamePlan.type === "when") {
      return {
        ...gamePlan,
        suggestions,
      }
    }

    return gamePlan
  })

  return {
    name: title.trim(),
    message: " ",
    invitees: formattedInvitees,
    gameplanData,
    dataForManualInvitees: {
      phones: [],
      emails: [],
    },
    otherData: {
      photo: image,
      enableSharedLink: true,
    },
    settings: DEFAULT_TEEUP_SETTINGS,
  }
}

interface IUseCreateTeeupProps {
  time: TTime
  onClose: () => void
  participantsNames: string[]
  meetingTitle: string
}

export const useCreateTeeup = ({
  onClose,
  time,
  participantsNames,
  meetingTitle,
}: IUseCreateTeeupProps) => {
  const dispatch = useDispatch()
  const { cooweContacts } = useContacts()

  const userId = useSelector(selectUserId)
  const gamePlanWhen = useSelector(selectGamePlanWhenFromCreate)
  const gamePlanWhere = useSelector(selectGamePlanWhereFromCreate)

  const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [suggestion, setSuggestion] = useState<ISuggestion | null>(null)
  const [selectedContacts, setSelectedContacts] = useState<IContact[]>([])
  const [selectedSuggestions, setSelectedSuggestions] = useState<ISuggestion[]>(
    []
  )

  const handleTeeupCreate = async () => {
    setIsLoading(true)

    const {
      name,
      message,
      gameplanData,
      invitees,
      otherData,
      settings,
      dataForManualInvitees,
    } = await createTeeupToDTO({
      title,
      invitees: selectedContacts,
      gamePlans: [gamePlanWhen, gamePlanWhere],
      suggestions: selectedSuggestions,
    })
    dispatch(
      createTeeup(
        userId,
        name,
        message,
        invitees,
        gameplanData,
        dataForManualInvitees,
        otherData,
        settings
      )
    )

    setIsLoading(false)
    onClose()
  }

  const handleContactSelect = (contact: IContact) => {
    setSelectedContacts(prev => {
      const isExist = prev.some(item => item.id === contact.id)

      if (isExist) {
        return prev.filter(item => item.id !== contact.id)
      }

      return [...prev, contact]
    })
  }

  const removeSuggestion = (suggestion: ISuggestion) => {
    setSelectedSuggestions(prev =>
      prev.filter(innerSuggestion => innerSuggestion.index !== suggestion.index)
    )
  }

  const handleSuggestionSave = (
    isSelected: boolean,
    suggestionTime?: TTime
  ) => {
    const selectedTime = suggestionTime || time

    const suggestion = {
      ...DEFAULT_SUGGESTION,
      userId,
      selected: isSelected,
      decided: isSelected,
      startDate: selectedTime.startDate,
      endDate: selectedTime.endDate,
      index: selectedSuggestions.length,
    }
    setSelectedSuggestions(prev => [...prev, suggestion])
    createSuggestion(suggestion)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setTitle(value)
  }

  useEffect(() => {
    const isShouldSetSuggestion = time.startDate && time.endDate && userId

    if (isShouldSetSuggestion) {
      const currentSuggestion = {
        ...DEFAULT_SUGGESTION,
        userId,
        teeupId: null,
        optionId: null,
        startDate: time.startDate,
        endDate: time.endDate,
        index: selectedSuggestions.length,
      }

      setSuggestion(currentSuggestion)
    }
  }, [time, userId])

  useEffect(() => {
    if (meetingTitle) {
      setTitle(meetingTitle)
    }
  }, [meetingTitle])

  const suggestedContacts = useMemo(() => {
    const result = cooweContacts.filter((contact: any) => {
      const isNameMatch = participantsNames.some(
        name => name && contact.name.toLowerCase().includes(name.toLowerCase())
      )
      const isUserNameMatch = participantsNames.some(
        name =>
          name && contact.username.toLowerCase().includes(name.toLowerCase())
      )
      const emails = contact.emails.map((item: { email: string }) =>
        item.email.toLowerCase()
      )
      const isEmailsMatch = emails.some((email: string) =>
        participantsNames.some(
          name => name && email.includes(name.toLowerCase())
        )
      )

      return isNameMatch || isUserNameMatch || isEmailsMatch
    })

    return result
  }, [participantsNames, cooweContacts])

  return {
    title,
    isLoading,
    suggestion,
    selectedContacts,
    suggestedContacts,
    selectedSuggestions,
    removeSuggestion,
    handleContactSelect,
    handleSuggestionSave,
    handleTitleChange,
    handleTeeupCreate,
  }
}
