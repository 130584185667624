import styled from "styled-components"

import { NSubtitle, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 15px;

  &:hover {
    background-color: var(--black2);
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const NameLine = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled(NSubtitle)`
  ${Wrapper}:hover & {
    color: var(--white);
  }
`

export const ContactValue = styled(NSubtitleS)`
  color: var(--gray4);

  ${Wrapper}:hover & {
    color: var(--white);
  }
`
