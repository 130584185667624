import { Dayjs } from "dayjs"
import moment from "moment-timezone"

import { LAST_MINUTES_STEP } from "constants/global"

export const roundTimeToFifteenMinutes = (date: Dayjs) => {
  const roundedMinutes = Math.round(date.minute() / 15) * 15

  return roundedMinutes === 60 ? LAST_MINUTES_STEP : roundedMinutes
}

// TODO: check ts-ignore and earliestEvent.startDate, smt strange
export const findEarliestAndLatestEvents = events => {
  if (events.length === 0) return { earliest: null, latest: null }

  let earliestEvent = moment(events[0])
  let latestEvent = moment(events[0])

  events.forEach(event => {
    // @ts-ignore
    if (moment(event.startDate).isBefore(earliestEvent.startDate)) {
      earliestEvent = event
    }

    // @ts-ignore
    if (moment(event.endDate).isAfter(latestEvent.endDate)) {
      latestEvent = event
    }
  })

  return { earliest: earliestEvent, latest: latestEvent }
}
