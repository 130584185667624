import { whenSuggestion } from "./../../e2e-tests/helpers/api/body-api"
import {
  teeupUserGenericStatus,
  teeupUserStatusKeys,
  teeupUserStatusPriorities,
} from "@config/enums"
import { TEEUPS_CATEGORIES } from "constants/teeups"
import dayjs from "utils/dayjs"
import {
  FilterListItem,
  FilterTypes,
  INBOX_TABS,
  InboxEmptyData,
  SortOption,
  WhereSuggestionDetails,
} from "types/inbox"
import { Suggestion } from "types/suggestions"
import { GameplanItem, TeeupItem } from "types/teeup"
import getWhereSuggestionDetails from "./gameplan/getWhereSuggestionDetails"
import getWhenSuggestionDetails from "./gameplan/getWhenSuggestionDetails"
import sortBy from "lodash/sortBy"
import { cloneDeep } from "lodash"

export const DEFAULT_INBOX_TAB = {
  title: "Active",
  requestType: TEEUPS_CATEGORIES.INBOX,
  value: INBOX_TABS.ACTIVE,
}

export const MAX_PARTICIPANTS = 10

export const filtersList: FilterListItem[] = [
  DEFAULT_INBOX_TAB,
  {
    title: "Invitations",
    filterQuery: "invited",
    value: INBOX_TABS.INVITATIONS,
  },
  {
    title: "Scheduled",
    scheduleFilter: "scheduled",
    value: INBOX_TABS.SCHEDULED,
  },
  {
    title: "Unscheduled",
    scheduleFilter: "unscheduled",
    value: INBOX_TABS.UNSCHEDULED,
  },
  {
    title: "Archive",
    requestType: TEEUPS_CATEGORIES.ARCHIVE,
    value: INBOX_TABS.ARCHIVE,
  },
  {
    title: "Ended",
    filterQuery: "ended",
    value: INBOX_TABS.ENDED,
  },
]

const reverseMapping = (keys, mapping) => {
  let reverse = {}
  for (let i = 0, len = keys.length; i < len; i++) {
    const key = keys[i]
    reverse[mapping[key]] = key
  }
  return reverse
}

const prepareMappings = mapping => {
  const keys = Object.keys(mapping)
  const reverse = reverseMapping(keys, mapping)
  const values = Object.keys(reverse)

  return {
    mapping,
    keys,
    values,
    reverse,
    ...mapping,
  }
}

const teeupStages = {
  planning: 1,
  allset: 2,
  happening: 3,
  happeningSoon: 6,
  ended: 4,
  cancelled: 5,
}

const teeupStageFilterKeys = {
  planning: "planning",
  allset: "allset",
  happeningSoon: "happeningSoon",
  happening: "happening",
  ended: "ended",
  cancelled: "cancelled",
}

export const filtersCreator =
  (titles: { [key: string]: string }, filterType: FilterTypes) =>
  (statusKey: string) => ({
    id: teeupStages[statusKey] || teeupUserStatusPriorities[statusKey],
    filter: statusKey,
    filterType,
    title: titles[statusKey],
  })

export const teeupStatus = prepareMappings({
  planning: "Planning",
  allset: "All set",
  happeningSoon: "Happening Soon",
  happening: "Happening now",
  ended: "Ended",
  cancelled: "Cancelled",
})

export const teeupFilterButtonTitles = prepareMappings({
  ...teeupStatus,
  planning: "Planning",
})

export const teeupStatusFilters = Object.values(teeupStageFilterKeys).map(
  filtersCreator(teeupFilterButtonTitles, FilterTypes.stage)
)

const statusesToPickOnFilters = new Set([
  teeupUserStatusKeys.invited,
  teeupUserStatusKeys.joined,
  teeupUserStatusKeys.going,
  teeupUserStatusKeys.mightgo,
  teeupUserStatusKeys.notgoing,
  teeupUserStatusKeys.onmyway,
  teeupUserStatusKeys.arrived,
  teeupUserStatusKeys.runningLate,
])

export const userStatusFilters = Object.values(teeupUserStatusKeys)
  .filter(i => statusesToPickOnFilters.has(i))
  .map(filtersCreator(teeupUserGenericStatus, FilterTypes.userStatus))
  .filter(item => !!item.title)

const SORT_KEYS = {
  updatedAt: "updatedat",
  createdAt: "createdat",
  createdby: "createdby",
}

export const DEFAULT_SORT = {
  id: "defaultSort",
  sort: SORT_KEYS.updatedAt,
  desc: true,
  title: "Recent activity (Default)",
}

export const sortButtons = {
  default: DEFAULT_SORT,
  createdAtAsc: {
    id: "createdAtAsc",
    sort: SORT_KEYS.createdAt,
    desc: false,
    title: "Oldest",
  },
  createdByMe: {
    id: "createdByMe",
    sort: SORT_KEYS.createdby,
    desc: false,
    title: "Created by me",
  },
}

export const getCardWhenInfo = (whenSuggestions?: Suggestion[]) => {
  const defaultFormattedDate = {
    whenTitle: "---",
    whenSubtitle: "",
  }

  if (!whenSuggestions?.length) return defaultFormattedDate

  const decidedSuggestion = whenSuggestions?.find(option => option.isDecided)
  if (!decidedSuggestion) return defaultFormattedDate

  const date = getWhenSuggestionDetails(decidedSuggestion)

  return date?.empty
    ? defaultFormattedDate
    : {
        whenTitle: date.title,
        whenSubtitle: date.subtitle,
      }
}

const possibleIconLabels = [
  "Discord",
  "Google Meet",
  "Teams",
  "Skype",
  "Slack",
  "WebEx",
  "Zoom",
  "Call",
]

export const getCardWhereInfo = (whereSuggestions?: Suggestion[]) => {
  const defaultFormattedLocation = {
    whereTitle: "---",
    whereSubtitle: "",
    whereIconName: "",
  }
  const decidedSuggestion = whereSuggestions?.find(option => option.isDecided)
  if (!decidedSuggestion) return defaultFormattedLocation

  const details = getWhereSuggestionDetails(decidedSuggestion) as any

  if (possibleIconLabels.includes(details?.title)) {
    details.whereIconName = details.title.toLocaleLowerCase().replace(" ", "-")
  }

  return details?.empty
    ? defaultFormattedLocation
    : {
        whereTitle: details.title,
        whereSubtitle: details.subtitle,
        whereIconName: details.whereIconName,
      }
}

export const getEmptyData = (
  currentOpenTab: INBOX_TABS,
  isSearch?: boolean,
  isFilters?: boolean
): InboxEmptyData => {
  const emptyData = {
    [INBOX_TABS.ACTIVE]: {
      title: "No Active TeeUps",
      description: "Create a TeeUp to start planning.",
      underlineText: "Don’t see your invitation here?",
      underlineDescription: "PLACEHOLDER",
      iconName: "emptyListPlaceholders/inbox/all-empty",
    },
    [INBOX_TABS.INVITATIONS]: {
      title: "No Invitations",
      description:
        "You have no active invitations associated with this account.",
      underlineText: "Don’t see your invitation here?",
      underlineDescription:
        "Go back to your original TeeUp invitation, and tap the link",
      iconName: "emptyListPlaceholders/inbox/invitation-empty",
    },
    [INBOX_TABS.SCHEDULED]: {
      title: "No Scheduled TeeUps",
      description:
        "A TeeUp becomes Scheduled once the “When” has been set to Decided",
      iconName: "emptyListPlaceholders/inbox/scheduled-empty",
    },
    [INBOX_TABS.UNSCHEDULED]: {
      title: "No Unscheduled TeeUps",
      description: "Create a TeeUp to start planning.",
      iconName: "emptyListPlaceholders/inbox/unscheduled-empty",
    },
    [INBOX_TABS.ARCHIVE]: {
      title: "Nothing in archive",
      description: "You have no archived TeeUps.",
      iconName: "emptyListPlaceholders/inbox/archive-empty",
    },
    [INBOX_TABS.ENDED]: {
      title: "No Ended TeeUps here",
      description: "You have no ended TeeUps to display here",
      iconName: "emptyListPlaceholders/inbox/archive-empty",
    },
    SEARCH: {
      title: "No search results",
      description:
        "You don’t have TeeUps or TeeUp invitations matching the search criteria.",
      iconName: "emptyListPlaceholders/inbox/search-empty",
    },
    NO_FILTERS: {
      title: "No TeeUps Match the Filters Applied",
      description:
        "You don’t have TeeUps or TeeUp invitations matching the filter selection",
      iconName: "emptyListPlaceholders/inbox/all-empty",
    },
  }

  if (isSearch) return emptyData.SEARCH
  if (isFilters) return emptyData.NO_FILTERS

  return emptyData[currentOpenTab]
}

export const teeupSorting = (
  teeups: TeeupItem[] = [],
  selectedSorting: SortOption,
  userId: number
) => {
  console.log("teeups", teeups)

  const clonedTeeups = cloneDeep(teeups)
  if (selectedSorting?.id === DEFAULT_SORT.id) {
    const sortedTeeUps = sortBy(clonedTeeups, ["lastActedAt"])

    if (selectedSorting.desc) {
      sortedTeeUps.reverse()
    }
    console.log("sortedTeeUps", sortedTeeUps)
    return sortedTeeUps
  }

  // @ts-ignore
  const sortedTeeUps = clonedTeeups.sort((a, b) => {
    if (selectedSorting.sort === SORT_KEYS.createdby) {
      const aCreateByCurrentUser = a.createdby === userId
      const bCreateByCurrentUser = b.createdby === userId

      if (aCreateByCurrentUser === bCreateByCurrentUser) return 0
      else if (bCreateByCurrentUser) return 1
      else if (aCreateByCurrentUser) return -1
    } else {
      if (a.isInactive || b.isInactive) {
        if (a.isInactive && !b.isInactive) {
          return 1
        } else if (b.isInactive && !a.isInactive) {
          return -1
        }
      }

      const timeDif =
        new Date(a.createdat).valueOf() - new Date(b.createdat).valueOf()
      return timeDif > 0 ? 1 : timeDif < 0 ? -1 : 0
    }
  })

  if (selectedSorting.desc) {
    sortedTeeUps.reverse()
  }
  return sortedTeeUps
}

const isJoined = status =>
  status !== teeupUserStatusKeys.invited &&
  status !== teeupUserStatusKeys.approvalWaiting

export const formatTeeups = (responseTeeup, allParticipants) => {
  return responseTeeup.map(teeup => {
    const participants = allParticipants[teeup.id]
    let isInactive = false

    if (participants) {
      isInactive =
        participants?.filter(p => isJoined(p.status))?.length === 1 &&
        dayjs().diff(teeup.lastActedAt, "day") >= 5
    }

    return {
      isInactive,
      ...teeup,
    }
  })
}
