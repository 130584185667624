import React, { useMemo } from "react"

import {
  teeupStatusFilterButtons,
  userStatusFilterButtons,
  teeupTypeFilterButtons,
  OtherFilterButtons,
} from "@utils/filters"
import { initialFiltersValues } from "@utils/teeupUtils"

import Button from "../../ui/Button"
import Checkbox from "../../ui/Checkbox/index"

import "./index.scss"
import classnames from "classnames"

const filterSections = [
  {
    title: "Teeup Stage",
    screen: "status",
    buttons: teeupStatusFilterButtons,
  },
  {
    title: "My Status",
    screen: "userStatus",
    buttons: userStatusFilterButtons,
  },
  {
    title: "Teeup Type",
    screen: "type",
    buttons: teeupTypeFilterButtons,
  },
  {
    title: "Other",
    screen: "other",
    buttons: OtherFilterButtons,
  },
]

const TeeupListFilter = ({ filtering, setFiltering, toggleFilter }) => {
  const handleCheckboxClick = value => {
    const filterType = filtering[value.filterType]
    const newFilter = { ...filtering }
    if (filterType.find(({ id }) => id === value.id)) {
      // remove
      newFilter[value.filterType] = filtering[value.filterType].filter(
        ({ id }) => id !== value.id
      )
    } else {
      // add
      newFilter[value.filterType] = [...filtering[value.filterType], value]
    }

    setFiltering(newFilter)
  }

  const handleClearFilter = () => {
    setFiltering(initialFiltersValues)
  }

  const isChecked = useMemo(
    () => value =>
      !!filtering[value.filterType].find(({ id }) => id === value.id),
    [filtering]
  )

  const handleToggleFilter = () => {
    toggleFilter()
  }

  return (
    <div className="list-filter">
      <div className="list-filter-options">
        {filterSections.map(section => {
          return (
            <div
              key={section.screen}
              className={classnames("filter-section", {
                "single-row-section": section.buttons.length <= 7,
                "double-row-section": section.buttons.length > 7,
              })}>
              <span className="filter-section-title">{section.title}</span>
              <div className="filter-options">
                {section.buttons.map(option => {
                  const optionValue = {
                    ...option,
                    filterType: section.screen,
                  }
                  return (
                    <Checkbox
                      key={option.id}
                      onChange={() => handleCheckboxClick(optionValue)}
                      value={option.id}
                      checked={isChecked(optionValue)}>
                      <span className="filter-option-label">
                        {option.title}
                      </span>
                    </Checkbox>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <div className="list-filter-actions">
        <Button border onClick={handleClearFilter}>
          Clear Filters
        </Button>
        <Button type="primary" onClick={handleToggleFilter}>
          Minimize Filters
        </Button>
      </div>
    </div>
  )
}

export default TeeupListFilter
