import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"

export const PreviewWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    padding-top: 70px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding-top: 90px;
    width: 100vw;
  }
`

export const Preview = styled.section`
  ${({ $isAuth, $img }) => css`
    width: 100%;
    min-height: 100%;
    background: center / cover url(${$img});
    padding-top: 70px;
    position: relative;
  `};

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    padding-top: 10px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    // 70px is height of GetAppBadge
    // padding-top: 70px;
  }
`

export const AuthWrapper = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(50px + 5%) 0 5% 0;
  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
  }
`

export const AuthContent = styled.div`
  background-color: var(--white);
  border-radius: 24px;
  border: 8px solid transparent;
  box-shadow: var(--grayShadow);
  padding: 20px 20px 0 20px;
  transition: border 0.2s ease-in-out;
  ${({ $isBlink }) =>
    $isBlink &&
    css`
      border-color: var(--transparentBrown);
    `};
`

export const LogoImg = styled.img`
  margin-top: 40px;
`

export const Header = styled.div`
  width: 100%;
  height: 70px;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    height: 90px;
    background-color: var(--radicalRed);
    flex-direction: column;
    justify-content: center;
  }
`

export const HeaderLogo = styled.div`
  width: 151px;
  height: 29px;
  background-image: url(${({ $pinkLogo }) => $pinkLogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 151px 29px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    background-image: url(${({ $whiteLogo }) => $whiteLogo});
    margin-bottom: 10px;
  }
`

export const HeaderText = styled(NSubtitle)`
  color: var(--radicalRed);
  font-weight: ${NFontWeight.bold};

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    color: var(--white);
  }
`

export const HeaderAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(70px + 5%);
  left: 5%;
  color: var(--white);
`

export const BackLinkIcon = styled.img`
  margin-right: 6px;
  margin-bottom: 3px;
`

export const AppOverviewWrapper = styled.section`
  ${({ $isPaddingTop }) => css`
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    ${$isPaddingTop &&
    css`
      padding: 15px 15px 0 15px;
    `}
  `};
`

export const Footer = styled.footer`
  width: 100%;
  background-color: var(--radicalRed);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
`
