import React, { ReactNode, useCallback } from "react"

import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"

import { setActiveTab } from "@actions/commonActions"
import { selectActiveTab } from "@selectors/common"

import "./tabBar.scss"
import { useConversationStore } from "stores/conversationStore"

interface Tab {
  id: number
  icon?: ReactNode
  activeIcon?: ReactNode
  title: string
}

interface Props {
  tabs: Tab[]
  activeTab?: string | number
}

const TabBar = ({ tabs }: Props) => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)
  const conversationRedDot = useConversationStore(
    state => state.conversationRedDot
  )
  const setRedDot = useConversationStore(state => state.setRedDot)

  const handleTabSelect = useCallback(id => {
    dispatch(setActiveTab(id))
    setRedDot(id, false)
  }, [])

  return (
    <div className="tab-bar">
      {tabs.map(({ id, title }) => (
        <div
          className={classNames("tab", { active: id === activeTab })}
          onClick={() => handleTabSelect(id)}
          key={id}>
          <span data-testid={title + "Tab"}>{title}</span>

          {conversationRedDot.get(id) && <div className="red-dot" />}
        </div>
      ))}
    </div>
  )
}

export default TabBar
