import { useMemo } from "react"

import { unlinkContacts } from "@actions/contactActions"

import { SYNC_CONTACTS_MODAL, SYNC_CONTACTS_MODAL_TEXT } from "../constants"

export const useModalData = ({
  modalType,
  connectorType,
  connectorEmail,
  connectorId,
  setIsDeletingConnector,
  setModalType,
  closeModals,
}) => {
  const closeText = useMemo(() => {
    switch (modalType) {
      case SYNC_CONTACTS_MODAL.CANCELED:
      case SYNC_CONTACTS_MODAL.ADDED_BY_DIFFERENT_ACCOUNT:
      case SYNC_CONTACTS_MODAL.SOMETHING_WEND_WRONG:
      case SYNC_CONTACTS_MODAL.SUCCESSFULLY_ADDED:
      case SYNC_CONTACTS_MODAL.ALREADY_ADDED:
      case SYNC_CONTACTS_MODAL.SUCCESSFULLY_REMOVED:
        return "Close"
      default:
        return ""
    }
  }, [modalType])

  const isConfirmModal = useMemo(() => {
    return modalType === SYNC_CONTACTS_MODAL.CONFIRM_REMOVING
  }, [modalType])

  return useMemo(() => {
    const title = SYNC_CONTACTS_MODAL_TEXT[modalType]?.title
      .replace("CONNECTOR_TYPE", connectorType)
      .replace("CONNECTOR_VALUE", connectorEmail)
    const description = SYNC_CONTACTS_MODAL_TEXT[modalType]?.description
      .replace("CONNECTOR_TYPE", connectorType)
      .replace("CONNECTOR_VALUE", connectorEmail)

    return {
      isOpen: !!modalType,
      title,
      description,
      icon: SYNC_CONTACTS_MODAL_TEXT[modalType]?.icon,
      onClose: closeModals,
      closeText,
      onCancel: closeModals,
      onConfirm: () => {
        setIsDeletingConnector(true)
        unlinkContacts(connectorId, connectorEmail).then(() => {
          setIsDeletingConnector(false)
          setModalType(SYNC_CONTACTS_MODAL.SUCCESSFULLY_REMOVED)
        })
      },
      cancelText: isConfirmModal && "Cancel",
      confirmText: isConfirmModal && "Yes",
    }
  }, [modalType, connectorType, connectorEmail, closeText, isConfirmModal])
}
