import React from "react"

import PropTypes from "prop-types"

import { peopleStatus } from "../../pages/TeeUpPage/TeeUpPageRightSection/PeopleSection/PeopleRow/PeopleRow"

import {
  Wrapper,
  Crown,
  StatusBackground,
  StatusIcon,
} from "./participantAvatarWrapperStyles"

export const ParticipantAvatarWrapper = ({ isOrganiser, status, children }) => {
  const currentStatus =
    status && peopleStatus.find(pStatus => pStatus.status === status)

  return (
    <Wrapper>
      {isOrganiser && <Crown name="crown-2" />}

      {currentStatus && (
        <StatusBackground $color={currentStatus.color}>
          <StatusIcon src={currentStatus.image} alt={currentStatus.status} />
        </StatusBackground>
      )}

      {children}
    </Wrapper>
  )
}

ParticipantAvatarWrapper.propTypes = {
  isOrganiser: PropTypes.bool,
  status: PropTypes.string,
}
