import React from "react"

import capitalize from "lodash/capitalize"
import PropTypes from "prop-types"

import { Wrapper, Text } from "./previewSuggestionsCounterStyles"

export const PreviewSuggestionsCounter = ({
  type,
  decidedSuggestion,
  listLength,
  isNonUserFlow,
}) => {
  const typeText = capitalize(type)

  return (
    <Wrapper $isNonUserFlow={isNonUserFlow} $isDecided={!!decidedSuggestion}>
      <Text>{typeText}</Text>

      {!decidedSuggestion && listLength > 0 && (
        <Text $color="var(--darkBlue)" $isNonUserFlow={isNonUserFlow}>
          {!isNonUserFlow && "·"} {listLength} suggestion{listLength > 1 && "s"}
        </Text>
      )}

      {decidedSuggestion && (
        <div className="inline-flex h-6 w-[61px] items-center justify-center gap-1 rounded border border-green-200 bg-green-50 px-1.5 py-0.5">
          <div className="text-right font-['Inter'] text-xs font-semibold leading-tight text-green-700">
            Decided
          </div>
        </div>
      )}
    </Wrapper>
  )
}

PreviewSuggestionsCounter.propTypes = {
  type: PropTypes.string,
  decidedSuggestion: PropTypes.object,
  listLength: PropTypes.number,
}
