import React from "react"

import {
  View,
  TouchableOpacity,
  Text,
  TextInput,
  Platform,
  ScrollView,
} from "react-native"
// import * as Animatable from 'react-native-animatable'

import { AppColors, Units } from "@theme"
import { images } from "@utils/imageUtils"

import search from "../../assets/images/search.svg"
import ViewWithHeader from "../ui/viewWithHeader"

import styles from "./AddInviteesStyles"
import ContactList from "./ContactList"

const isIOS = Platform.OS === "ios"

const searchButtonWidth = Units.responsiveValue(40)

// const renderTagButton = (onSettingsPress) => {
//     return (
//         <TouchableOpacity key={'tagButton'} onPress={onSettingsPress}>
//             <View style={styles.tagSettingsContainer}>
//                 <img src={tag} />
//             </View>
//         </TouchableOpacity>
//     )
// }

const renderTagsRow = (userTags, onTagRender) => {
  let tagsRow = []
  if (userTags) {
    tagsRow = userTags.map(onTagRender)
  }

  return tagsRow
}

const AddInviteesView = ({
  viewHeader,
  invitees = [],
  onDone,
  onClose,
  searchText,
  onSearchTextChange,
  onResetSearchText,
  foundContacts = [],
  onOpenMenu,
  expandedContactItemId,
  onToggleExpand,
  onToggleContact,
  onChangeContactType,
  sectionOpenItemCount,
  onViewMore,
  onOpenPreviousTeeupParticipants,
  onSearchEnable,
  onSearchDisable,
  onTagRender,
  isSearchEnabled,
  userTags,
  searchRef,
  sortingType,
  onContactsScrolled,
  showInvitees,
  searchResultsText,
  isSearching,
  loadingContacts,
  onSettingsPress,
  isMenuOpened,
  openEmail,
  openPhone,
  openContactsFilterModal,

  // My Contacts screen props
  myContacts,
  renderMyContactItem,
  doneTitle,
  onBack,
  lightTheme,
  closeContent,
  showButtons,
  onAdd,
  isTeeupInvite,
  fromContact,
  fromCreateTeeup,
  noHover,
}) => {
  const shouldSearchBarExpand = isSearchEnabled || !!searchText
  return (
    <ViewWithHeader
      lightTheme={lightTheme}
      style={[
        styles.container,
        isTeeupInvite && { width: "100%" },
        lightTheme && styles.containerLight,
        fromContact && { width: "608px" },
        fromCreateTeeup && { width: "100%" },
      ]}
      containerStyle={[
        !isTeeupInvite && styles.containerStyle,
        fromContact && { width: "608px", margin: "auto" },
        fromCreateTeeup && { width: "100%" },
      ]}
      // style={[styles.container, isTeeupInvite && {width: '100%'}, lightTheme && styles.containerLight, fromContact && {width: '40vw'}, fromCreateTeeup && {width : '100%'}]}
      // containerStyle={[!isTeeupInvite && styles.containerStyle, fromContact && {width: '40vw', margin: 'auto'}, fromCreateTeeup && {width : '100%'}]}
      headerStyle={styles.header}
      titleContainerStyle={styles.titleContainerStyle}
      titleView={
        myContacts && (
          <View>
            <Text
              style={[
                styles.headerTitle,
                fromContact && { paddingBottom: "12px" },
              ]}>
              {!fromCreateTeeup && (viewHeader || "Invite to TeeUp")}
            </Text>
            {/* {invitees.length > 0 ? (
                        <Text style={styles.headerSubtitle}>
                            {invitees.length > 1
                                ? `${invitees.length} people`
                                : `${invitees.length} person`}
                        </Text>
                    ) : null} */}
          </View>
        )
      }
      // onDoneTitle={doneTitle}
      // onDone={onDone}
      onDoneDisabled={
        myContacts ? foundContacts.length === 0 : invitees.length === 0
      }
      // onClose={onClose}
      // onBack={onBack}
    >
      {/* {showButtons && <div className="settings__top">
                <Text onPress={closeContent} style={[styles.buttonText]}>Cancel</Text>
                <span>Add Invitees</span>
                <Text onPress={() => onAdd(invitees)} style={[styles.buttonText]}>Done</Text>
            </div>} */}
      {/* {!myContacts && (
                <InviteeList
                    invitees={invitees}
                    shouldShow={showInvitees}
                    onRemoveInvitee={onToggleContact}
                />
            )} */}
      <View style={styles.searchBar}>
        <View
          style={[
            styles.searchLineWrapper,
            fromCreateTeeup && { backgroundColor: "#F0F2F5" },
          ]}>
          {/* backgroundColor: #f0f0f0 */}

          <View
            transition="width"
            duration={500}
            style={[
              styles.searchContainer,
              {
                width: "100%",
              },
              fromCreateTeeup && { backgroundColor: "#ffffff" },
            ]}>
            <View>
              <img src={search} alt="search" />
            </View>
            <TextInput
              ref={searchRef}
              placeholder="Search"
              placeholderTextColor={AppColors.brand.warmGrey2}
              style={styles.searchText}
              onChangeText={onSearchTextChange}
              onBlur={onSearchDisable}
              autoCorrect={false}
              testID={"inviteContactsSearchBar"}
            />
            <TouchableOpacity
              hitSlop={Units.hitSlop()}
              onPress={searchText ? onResetSearchText : onSearchDisable}>
              {/* <Text
                                        style={styles.searchButtonText}
                                        numberOfLines={1}
                                    >
                                        {searchText
                                            ? strings.systemButtons.clear
                                            : strings.systemButtons.cancel}

                                    </Text> */}
              <img
                src={images.filter}
                alt="filters"
                style={{ width: 20, height: 20 }}
                onClick={() => openContactsFilterModal()}
              />
            </TouchableOpacity>
          </View>
          <ScrollView
            horizontal
            keyboardShouldPersistTaps="always"
            showsHorizontalScrollIndicator={false}
            style={styles.tagList}
            contentContainerStyle={styles.tagContentContainerStyle}>
            {renderTagsRow(userTags, onTagRender, onSettingsPress)}
          </ScrollView>
        </View>
        {isSearching && (
          <Text style={styles.searchResultsText}>{searchResultsText}</Text>
        )}
      </View>
      <View style={styles.borderShadow}></View>
      {/* {foundContacts.length < 1 ? (
                <ActivityIndicator />
            ) : ( */}
      <div
        className="no-scroll"
        style={{
          flex: 1,
          maxHeight: fromCreateTeeup
            ? "calc(90vh - 160px)"
            : myContacts
            ? "calc(100vh - 210px)"
            : isTeeupInvite
            ? "calc(100vh - 175px)"
            : "calc(100vh - 236px)",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          overflow: "auto",
        }}>
        <ContactList
          searchText={searchText}
          onContactsScrolled={onContactsScrolled}
          allContacts={foundContacts}
          invitees={invitees}
          isSearching={isSearching}
          sectionOpenItemCount={sectionOpenItemCount}
          expandedContactItemId={expandedContactItemId}
          onViewMore={onViewMore}
          onOpenMenu={onOpenMenu}
          openPreviousTeeupParticipants={onOpenPreviousTeeupParticipants}
          onToggleExpand={onToggleExpand}
          onToggleContact={onToggleContact}
          onChangeContactType={onChangeContactType}
          sortingType={sortingType}
          renderContactItem={renderMyContactItem}
          openPhone={openPhone}
          openEmail={openEmail}
          fromCreateTeeup={fromCreateTeeup}
        />
      </div>
      {/* )} */}
    </ViewWithHeader>
  )
}

// AddInviteesView.propTypes = {
//     isSearchEnabled: propTypes.bool,
//     searchText: propTypes.string,

//     expandedContactItemId: propTypes.shape({}),
//     foundContacts: propTypes.arrayOf(propTypes.shape({})),
//     invitees: propTypes.arrayOf(propTypes.shape({})),
//     userTags: propTypes.arrayOf(propTypes.shape({})),
//     sectionOpenItemCount: propTypes.shape({}),
//     searchRef: propTypes.shape({}),

//     onDone: propTypes.func.isRequired,
//     onClose: propTypes.func,
//     onBack: propTypes.func,
//     onSearchTextChange: propTypes.func.isRequired,
//     onResetSearchText: propTypes.func.isRequired,
//     onOpenMenu: propTypes.func.isRequired,
//     onToggleExpand: propTypes.func.isRequired,
//     onToggleContact: propTypes.func.isRequired,
//     onChangeContactType: propTypes.func.isRequired,
//     onViewMore: propTypes.func.isRequired,
//     onOpenPreviousTeeupParticipants: propTypes.func.isRequired,
//     onSearchEnable: propTypes.func.isRequired,
//     onSearchDisable: propTypes.func.isRequired,
//     onTagRender: propTypes.func.isRequired,
//     viewHeader: propTypes.string,
//     showInvitees: propTypes.bool,
//     searchResultsText: propTypes.string,
//     sortingType: propTypes.oneOfType([propTypes.string, propTypes.number]),
//     onContactsScrolled: propTypes.func.isRequired,
//     isSearching: propTypes.bool,
//     myContacts: propTypes.bool,
//     renderMyContactItem: propTypes.func,
//     lightTheme: propTypes.bool,
//     doneTitle: propTypes.string.isRequired,
//     loadingContacts: propTypes.bool,
//     onSettingsPress: propTypes.func.isRequired,
// }

export default AddInviteesView
