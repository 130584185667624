import styled from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const Label = styled.label`
  font-weight: ${NFontWeight.bold};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: ${NFontWeight.bold};
  padding: 10px 0;
`
