import React, { useContext, useMemo } from "react"

import CheckmarkIcon from "@assets/images/checked-mark.svg"
import WarningIcon from "@assets/images/warning-icon.svg"
import { Subtitle } from "@assets/styles/typographyStyles"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"

import { RSVPBadgeType } from "../constants"
import { Badge, BadgeInfo, BadgeIcon } from "../teeupPreviewContentStyles"

export const RSVPBadge = ({ type, message = "..." }) => {
  const { guestStatusMessage } = useContext(TeeupPreviewContext)

  const badgeContent = useMemo(() => {
    switch (type) {
      case RSVPBadgeType.SUCCESS:
        return {
          icon: CheckmarkIcon,
          message: `Status set as: <b>${guestStatusMessage}</b>`,
        }
      case RSVPBadgeType.WARNING:
        return {
          icon: WarningIcon,
          message: `${message}`,
        }
      default:
        return "..."
    }
  }, [type, message, guestStatusMessage])

  // TODO: if we really don't need this button - remove it
  // const openChange = () => {
  //   setStatusModalTrigger(userStatusTypes[previewInfo.invitee.status])
  // }

  return (
    <Badge type={type}>
      <BadgeInfo>
        <BadgeIcon src={badgeContent.icon} alt={type} />

        <Subtitle dangerouslySetInnerHTML={{ __html: badgeContent.message }} />
      </BadgeInfo>

      {/*TODO: if we really don't need this button - remove it*/}
      {/*{type === RSVPBadgeType.SUCCESS && (*/}
      {/*  <ButtonEl*/}
      {/*    autoWidth*/}
      {/*    variant={BUTTON_VARIANTS.UNDERLINE}*/}
      {/*    text="Change"*/}
      {/*    textColor="var(--green)"*/}
      {/*    onClick={openChange}*/}
      {/*  />*/}
      {/*)}*/}
    </Badge>
  )
}
