import { GAMEPLAN_OPTIONS, locationTypeIds } from "@utils/gamePlanUtils"

import getWhereSuggestionDetails from "./getWhereSuggestionDetails"

const getRecentInPersonSuggestions = (
  teeups: any[],
  userId: number,
  teeupId?: number
): any[] => {
  const onlineSuggestions = teeups
    .filter(item => (teeupId ? item.id !== teeupId : true))
    .slice(0, 15)
    .flatMap(teeup => teeup.gameplanOptions || [])
    .filter(
      gameplan => gameplan.title?.toLowerCase() === GAMEPLAN_OPTIONS.where
    )
    .flatMap(gameplan => gameplan.suggestions || [])
    .filter(suggestion => {
      const isCreatedByCurrentUser =
        suggestion.createdBy === userId || suggestion.createdby === userId

      return (
        !!suggestion?.where?.latitude &&
        !!suggestion?.where?.longitude &&
        isCreatedByCurrentUser
      )
    })

  const decidedSuggestions = onlineSuggestions
    .filter(suggestion => suggestion.isDecided)
    .slice(0, 3)
  const undecidedSuggestions = onlineSuggestions.filter(
    suggestion => !suggestion.isDecided
  )

  return [...decidedSuggestions, ...undecidedSuggestions]
    .slice(0, 5)
    .map(item => ({
      details: item.where?.details || "",
      value: item.where?.value || "",
      info: item.where?.info || "",
      isDecided: false,
      latitude: item.where?.latitude || 0,
      longitude: item.where?.longitude || 0,
      googlePlaceId: item.where?.googlePlaceId || "",
      type: GAMEPLAN_OPTIONS.where,
      locationType: locationTypeIds.realWorld,
    }))
    .map(item => ({
      ...item,
      ...getWhereSuggestionDetails(item),
    }))
}

export default getRecentInPersonSuggestions
