import React from "react"

import _ from "lodash"
import { Text, Clipboard } from "react-native"

import { chatMessageTypes, teeupUserStatusKeys } from "@configs/enums"
import { conversationTypeIds, statusTypeIds } from "@configs/mappings"
import I18n from "@i18n"
import { AppColors, AppSizes } from "@theme"
import FileMessage from "@ui/chat/FileMessage"
import ImageMessage from "@ui/chat/ImageMessage"
import DurationUpdateMessage from "@ui/chat/durationUpdateMessage/durationUpdateMessage"
import GameplanSelectMessage from "@ui/chat/gameplanSelectMessage/gameplanSelectMessage"
import GameplanUpdateMessage from "@ui/chat/gameplanUpdateMessage/gameplanUpdateMessage"
import ReplyButton from "@ui/chat/messageSwipeoutButtons/ReplyButton"
import ReplyMessage from "@ui/chat/replyMessage/replyMessage"
import StatusMessage from "@ui/chat/statusMessage/statusMessage"
import SuggestionMessage from "@ui/chat/suggestionMessage/suggestionMessage"
import TemplateFileMessage from "@ui/chat/templateFileMessage/templateFileMessage"
import TemplateMessage from "@ui/chat/templateMessage/templateMessage"
import TextMessage from "@ui/chat/textMessage/textMessage"
import styles from "@ui/chat/textMessage/textMessageStyles"
import Toast from "@ui/toast"
import { getUsernameOrName } from "@utils/contactUtils"
import { getInitials } from "@utils/dataUtils"
import { getCalendarFormat, getTimeFormat } from "@utils/dateUtils"
import {
  isNewSuggestion,
  getSuggestionValue,
  getSuggestionStatus,
  isPastSuggestion,
  // promoteToGameplan,
  getNotAllowedTextColor,
  getNotAllowedIconTintColor,
  // openSuggestionOnMap,
} from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import { isTeeupCancelled } from "@utils/teeupUtils"
import { EVENTS, logEvent } from "analytics-tracking"
// import { downloadAndOpenFile } from '@utils/linkUtils'

// import teeupActions from '@actions/teeupActions'
// import teeupCardActions from '@actions/teeupCardActions'
// import {
//     showActionsheet,
//     showSuggestionList,
//     showNudgeParticipantsList,
// } from '@nav'

const noOp = () => {}

const isWithIndent = messageType => {
  return (
    messageType === chatMessageTypes.DEFAULT ||
    messageType === chatMessageTypes.TAIL ||
    messageType === chatMessageTypes.MENTION_HEAD
  )
}

const getMessageType = (next, message, prev) => {
  const { conversationTypeId, senderId, suggestionId } = message
  if (
    (conversationTypeId === conversationTypeIds.message && suggestionId) ||
    conversationTypeId === conversationTypeIds.initial ||
    conversationTypeId === conversationTypeIds.status ||
    conversationTypeId === conversationTypeIds.imageChange ||
    conversationTypeId === conversationTypeIds.gameplanUpdate
  ) {
    return chatMessageTypes.DEFAULT
  }

  if (
    +senderId === +prev.senderId &&
    +senderId === +next.senderId &&
    next.conversationTypeId !== conversationTypeIds.initial &&
    next.conversationTypeId !== conversationTypeIds.status &&
    prev.conversationTypeId !== conversationTypeIds.initial &&
    prev.conversationTypeId !== conversationTypeIds.status &&
    (next.conversationTypeId === conversationTypeIds.message ||
      next.conversationTypeId === conversationTypeIds.reply ||
      next.conversationTypeId === conversationTypeIds.image ||
      next.conversationTypeId === conversationTypeIds.file) &&
    !next.suggestionId &&
    (prev.conversationTypeId === conversationTypeIds.message ||
      prev.conversationTypeId === conversationTypeIds.reply ||
      prev.conversationTypeId === conversationTypeIds.image ||
      prev.conversationTypeId === conversationTypeIds.file) &&
    !prev.suggestionId
  ) {
    return chatMessageTypes.MIDDLE
  }

  if (
    (+senderId !== +next.senderId ||
      (+senderId === +next.senderId &&
        next.conversationTypeId === conversationTypeIds.message &&
        next.suggestionId) ||
      (+senderId === +next.senderId &&
        (next.conversationTypeId === conversationTypeIds.initial ||
          next.conversationTypeId === conversationTypeIds.status ||
          next.conversationTypeId === conversationTypeIds.imageChange))) &&
    +senderId === +prev.senderId &&
    prev.conversationTypeId !== conversationTypeIds.initial &&
    prev.conversationTypeId !== conversationTypeIds.status
  ) {
    return chatMessageTypes.TAIL
  }

  if (
    +senderId === +next.senderId &&
    next.conversationTypeId !== conversationTypeIds.initial &&
    next.conversationTypeId !== conversationTypeIds.status &&
    !(
      next.conversationTypeId === conversationTypeIds.message &&
      next.suggestionId
    )
  ) {
    return chatMessageTypes.HEAD
  }

  return chatMessageTypes.DEFAULT
}

const getMentionedUserStyle = ({
  highlightMyMention,
  isCurrentUser,
  hightlightUserInput,
  isMentionedUserExists,
  isEverybody,
}) => {
  if (highlightMyMention && (isCurrentUser || isEverybody)) {
    return styles.myAccTextMessageMention
  }
  if (hightlightUserInput || isMentionedUserExists || isEverybody) {
    return styles.textMessageMention
  }
  return {}
}

const renderMentionedUser =
  (
    people,
    openParticipantDetails,
    hightlightUserInput,
    currentUserId,
    highlightMyMention = false
  ) =>
  matchingString => {
    const isWhiteSpaceAtStart = matchingString[0] === " "
    let isMentionedUserExists = false
    const match = matchingString.trim()
    const pattern = match.replaceAll("@", "")
    let mentionedUserId
    if (people) {
      Object.values(people).find(user => {
        if (!user) return
        const userPart = getUsernameOrName(user)
        const userName = userPart.trim()
        if (userName.toLowerCase() === pattern.toLowerCase()) {
          isMentionedUserExists = true
          mentionedUserId = Number(user.id)
          return
        }
      })
    }

    const style = getMentionedUserStyle({
      highlightMyMention,
      isCurrentUser: currentUserId === mentionedUserId,
      hightlightUserInput,
      isMentionedUserExists,
      isEverybody: pattern.toLowerCase() === "Everybody".toLowerCase(),
    })

    const redirectToProfile = isMentionedUserExists && !!openParticipantDetails
    return (
      <>
        {isWhiteSpaceAtStart ? " " : ""}
        <Text
          onPress={
            redirectToProfile
              ? () => openParticipantDetails(mentionedUserId)
              : noOp
          }
          style={style}>
          {match}
        </Text>
      </>
    )
  }

const getMessageOptions = (options, override = {}, newDesign = false) => {
  const {
    item,
    people,
    expanded,
    standAlone,
    toggleExpansion,
    teeup,
    userInfo,
    suggestions,
    messages,
    permissions,
    newSuggestions,
    suggestionMessages,
    index,
    goToSuggestion,
    teeupTitle,
    onUnpinMessage,
    showOnMentionsScreen,
    onStatusMessagePress,
    expandedStatusMessagesArray,
    onContextMenu,
    openPreview,
    onReplyStart,
    toggleSwiping,
    handleCloseAddFilesMenu,
    settings,
    isTimestampsVisible,
    draggingSuggestionId,
    activeReplyMessageId,
    openParticipantDetails,
    handleKeyboardClose,
    isKeyboardActive,
    isReplyDisabled,
    onSuggestionCommentPress,
    onSuggestionCommentScreen = false,
    uploadFiles,
    onFileUploadCancel,
    onFileRetry,
  } = options

  const {
    senderId,
    createdBy,
    conversationTypeId,
    typeId,
    suggestionId,
    isSuggestion,
    text,
    image,
    // video,
    file,
    messageId,
    id,
    timestamp,
    pinnedById,
    isPinned,
    suggestionSenderId = null,
  } = item

  let messageType,
    isSenderAvatarVisible,
    useAvatarBorder,
    isSenderUsernameVisible,
    isMyMessage,
    isStatusMessage,
    isReactionMessage,
    isComment,
    isGamePlanSelect,
    children,
    suggestionName,
    suggestionType,
    suggestionCreatorName,
    suggestionStatus,
    initials,
    pinnedUserName,
    name,
    avatar,
    status,
    // rightSwipeButtons,
    isReply,
    canReply

  const defaultLeftSwipeButtons = [
    {
      component: <ReplyButton />,
      backgroundColor: AppColors.transparent,
    },
  ]

  messageType = getMessageType(
    messages[index - 1] || {},
    { conversationTypeId, senderId, suggestionId },
    messages[index + 1] || {}
  )

  const isInitial = conversationTypeId === conversationTypeIds.initial
  const isGamePlanUpdate =
    conversationTypeId === conversationTypeIds.gameplanUpdate

  messageType = onUnpinMessage
    ? chatMessageTypes.DEFAULT
    : showOnMentionsScreen
    ? chatMessageTypes.MENTION_HEAD
    : messageType

  isMyMessage = onUnpinMessage ? false : +senderId === +userInfo.id

  useAvatarBorder = showOnMentionsScreen

  isSenderAvatarVisible =
    messageType === chatMessageTypes.TAIL ||
    messageType === chatMessageTypes.DEFAULT ||
    messageType === chatMessageTypes.MENTION_HEAD
  isSenderUsernameVisible =
    messageType === chatMessageTypes.HEAD ||
    messageType === chatMessageTypes.DEFAULT
  isReactionMessage = typeId === statusTypeIds.reaction
  isGamePlanSelect =
    conversationTypeId === conversationTypeIds.gameplanSelection

  isComment =
    !isGamePlanUpdate &&
    !!suggestionId &&
    !isReactionMessage &&
    !isGamePlanSelect
  isReply = false
  canReply = false

  // if (!people[senderId] && !Array.isArray(senderId) && !isSystem) {
  //     return
  // }
  if (people[pinnedById]) {
    pinnedUserName = people[pinnedById].username
  }
  const sender = people[suggestionSenderId || senderId || createdBy]

  name = sender ? sender?.username || sender?.name : undefined
  avatar = people[senderId] ? people[senderId].avatar : undefined
  status = people[senderId] ? people[senderId].status : undefined

  const suggestion = suggestions[id]

  initials = getInitials(name)

  if (conversationTypeId === conversationTypeIds.message) {
    if (suggestionId && suggestions[suggestionId]) {
      suggestionType = suggestions[suggestionId].type
      suggestionName = getSuggestionValue(
        suggestions[suggestionId],
        suggestionType
      ).full
      const person = people[suggestions[suggestionId].createdBy]
      suggestionCreatorName = person
        ? person.username || person.name
        : "Unknown"
      isSenderAvatarVisible = true
      isSenderUsernameVisible = true
    }
    canReply = true
    children = (
      <TextMessage
        text={text}
        isMyMessage={isMyMessage}
        renderMentionedUser={renderMentionedUser}
        people={people}
        onUserPress={openParticipantDetails}
        currentUserId={userInfo.id}
        highlightMyMention={showOnMentionsScreen}
      />
    )
  }

  if (
    conversationTypeId === conversationTypeIds.suggestion &&
    isSuggestion &&
    suggestion
  ) {
    const { type } = item
    const {
      allowSelectGameplan: canSelectGameplan,
      allowDecideGameplan: canDecideGameplan,
      allowChangeGameplanOptions: canChangeGameplanOptions,
    } = permissions

    suggestionStatus = getSuggestionStatus(
      newSuggestions,
      suggestion,
      userInfo.id
    )
    const suggestionValues = getSuggestionValue(suggestion, type)
    const suggestionOptions = {
      suggestion,
      type,
      name,
      canSelectGameplan,
      canDecideGameplan,
      canChangeGameplanOptions,
      messageType,
      isMyMessage,
      initiateStatusUpdate: true,
      isRowExpanded: true,
      suggestionStatus,
      forChat: true,
      suggestionValues,
      goToSuggestion,
      commentsCount: suggestionMessages[suggestion.id]
        ? suggestionMessages[suggestion.id].length
        : 0,
      key: id,
      isNew: isNewSuggestion(newSuggestions, suggestion),
      timestamp: getCalendarFormat(item.timestamp),
      isTeeupCancelled: isTeeupCancelled(teeup),
      isDragging: suggestion.id === draggingSuggestionId,
      handleOnLongPress: props =>
        onContextMenu
          ? onContextMenu(
              null,
              item
            )({
              ...props,
              onSuggestionCommentPress,
            })
          : noOp(),
    }
    canReply = true
    children = (
      <SuggestionMessage {...suggestionOptions} newDesign={newDesign} />
    )
  }

  if (
    isInitial ||
    conversationTypeId === conversationTypeIds.status ||
    conversationTypeId === conversationTypeIds.imageChange
  ) {
    isSenderAvatarVisible = isInitial
    isSenderUsernameVisible = isInitial
    isStatusMessage = true
    children = (
      <StatusMessage
        index={index}
        item={item}
        people={people}
        suggestions={suggestions}
        teeupTitle={teeupTitle}
        permissions={permissions}
        standAlone={standAlone}
        onStatusMessagePress={onStatusMessagePress}
        expandedStatusMessagesArray={expandedStatusMessagesArray}
        isTeeupCancelled={isTeeupCancelled(teeup)}
        handleOnLongPress={onContextMenu || noOp}
        settings={settings}
        isMyMessage={isMyMessage}
        newDesign={newDesign}
      />
    )
  }

  if (conversationTypeId === conversationTypeIds.reply) {
    let repliedMessage = messages.find(message => message.id === messageId)
    if (repliedMessage) {
      isReply = true
      const { senderId } = repliedMessage

      // Fixes crash with bad data
      const person = people[senderId]
      const repliedMessageAuthor = person
        ? person.username || person.name
        : "Unknown"
      canReply = true
      children = (
        <ReplyMessage
          repliedMessageAuthor={repliedMessageAuthor}
          repliedMessage={repliedMessage}
          isMyMessage={isMyMessage}
          text={text}
          name={name}
          renderMentionedUser={renderMentionedUser}
          onUserPress={openParticipantDetails}
        />
      )
    }
  }

  if (conversationTypeId === conversationTypeIds.image && image) {
    canReply = true
    children = <ImageMessage isPinnedScreen={!!onUnpinMessage} image={image} />
  }

  if (conversationTypeId === conversationTypeIds.template) {
    children = item.fileName ? (
      <TemplateFileMessage
        item={item}
        uploadFiles={uploadFiles}
        onFileRetry={onFileRetry}
        onFileUploadCancel={onFileUploadCancel}
      />
    ) : (
      <TemplateMessage />
    )
  }

  let downloadFile
  if (conversationTypeId === conversationTypeIds.file && file) {
    canReply = true
    downloadFile = () => {
      // if (!isFileOpen) {
      //     onFilePress(true)
      //     downloadAndOpenFile({ url: file.url }, onFilePress)
      // }
    }
    children = (
      <FileMessage
        isPinnedScreen={!!onUnpinMessage}
        isMyMessage={isMyMessage}
        {...file}
      />
    )
  }

  if (conversationTypeId === conversationTypeIds.durationUpdate) {
    isSenderAvatarVisible = false
    isSenderUsernameVisible = false
    isStatusMessage = true
    children = <DurationUpdateMessage item={item} people={people} />
  }

  if (conversationTypeId === conversationTypeIds.gameplanUpdate) {
    isSenderAvatarVisible = false
    isSenderUsernameVisible = false
    isStatusMessage = true
    children = (
      <GameplanUpdateMessage
        item={item}
        people={people}
        suggestions={suggestions}
      />
    )
  }

  if (conversationTypeId === conversationTypeIds.gameplanSelection) {
    const hasNeighboringMessage =
      messageType === chatMessageTypes.MIDDLE ||
      messageType === chatMessageTypes.TAIL
    const neighboringMessage = hasNeighboringMessage
      ? messages[index + 1]
      : null

    canReply = false
    children = (
      <GameplanSelectMessage
        item={item}
        people={people}
        suggestions={suggestions}
        onSuggestionTap={suggestion =>
          openPreview(id, { isGamePlanSelect: true }, { suggestion })()
        }
        neighboringMessage={neighboringMessage}
        isMyMessage={isMyMessage}
        newDesign={newDesign}
      />
    )
  }

  if (!expanded || isReplyDisabled) {
    canReply = false
  }

  return {
    id,
    expanded,
    messageType,
    isTeeupCancelled: isTeeupCancelled(teeup),
    isSenderAvatarVisible,
    showOnMentionsScreen,
    useAvatarBorder,
    isSenderUsernameVisible,
    isInitialMessage: isInitial,
    isMyMessage,
    isStatusMessage,
    isComment,
    isSuggestion,
    isGamePlanSelect,
    isReply,
    suggestionStatus,
    suggestionName,
    suggestionType,
    suggestionCreatorName,
    avatar,
    status,
    name,
    children,
    initials,
    pinnedUserName,
    isPinned,
    isTimestampsVisible,
    onUnpinMessage,
    isImage: !!image,
    isFile: !!file,
    timestamp: getTimeFormat(timestamp),
    timestampDate: getTimeFormat(timestamp, "MM/DD/YY"),
    goToSuggestion: isComment
      ? () => goToSuggestion(suggestions[item.suggestionId])
      : noOp,
    toggleExpansion: toggleExpansion || noOp,
    onContextMenu:
      !onUnpinMessage && !isGamePlanSelect && onContextMenu
        ? onContextMenu(id)
        : noOp,
    openPreview:
      downloadFile ||
      (openPreview ? openPreview(id, { isReply, isComment }) : noOp),
    handleCloseAddFilesMenu: handleCloseAddFilesMenu || noOp,
    onMessageAvatarPress: isSenderAvatarVisible
      ? () => openParticipantDetails(senderId)
      : noOp,
    leftSwipeButtons:
      canReply && !isTimestampsVisible ? defaultLeftSwipeButtons : [],
    rightSwipeButtons: [],
    onLeftSwipe:
      canReply && !isTimestampsVisible ? () => onReplyStart(id) : noOp,
    onSwipe: canReply && !isTimestampsVisible ? toggleSwiping : noOp,
    isSwipeoutClosed:
      canReply && !isTimestampsVisible ? activeReplyMessageId === id : true,
    isKeyboardActive,
    handleKeyboardClose,
    onSuggestionCommentScreen,
    ...override,
  }
}

const mentionPatternForInput = /((?:\n|^| |,|.)(@\w+\.?\w+|@\w|@))/i

const handleCheckIfInputValid = (text, people, withSuggestions) => {
  const formatted = text.replaceAll(" ", "")
  let isInputValidToSend = false
  if (!formatted.startsWith("@")) {
    isInputValidToSend = true
  } else if (formatted === "@") {
    isInputValidToSend = false
  } else {
    const namesArr = formatted.split("@")
    const isMentionMatchUser = (userMention, isFullyMatch = false) =>
      people.find(user => {
        const username = getUsernameOrName(user).toLowerCase?.()
        const lowercaseMention = userMention.toLowerCase()
        return isFullyMatch
          ? username === lowercaseMention
          : username.startsWith(lowercaseMention)
      })

    if (withSuggestions && people.length > 0) {
      for (let i = 0, len = namesArr.length; i < len; i++) {
        const mention = namesArr[i]
        if (!isMentionMatchUser(mention) && mention) {
          isInputValidToSend = true
          break
        }
      }
    } else if (formatted.length > 0) {
      isInputValidToSend = true
    }
  }
  return { isInputValidToSend }
}

const getMessageById = (id, messages) =>
  _.filter(messages, message => message.id === id)

const showChatMessageContextMenu = ({
  messages,
  id,
  suggestionMessage,
  passedProps = {},
  pinUnpinMessage,
  pinUnpinSuggestion,
  toggleExpansion,
  downloadFile,
  expanded,
  teeup,
  permissions,
  decidedGameplans = {},
  userStatus,
  hiddenPinnedMessages = [],
  isKeyboardActive,
  setRequestedContextMenu,
  onFilePress,
  onShowOnConversation = noOp,
  setIsContextMenuVisible = noOp,
  highlighterRefSetVisible = noOp,
  highlighterRefSetBottomPanelHeight = noOp,
  onReplyStart,
  outsideOfChat = false,
}) => {
  const highlightId = `message-${id || passedProps?.suggestion?.id}`
  if (isKeyboardActive) {
    setRequestedContextMenu(highlightId)
  } else {
    highlighterRefSetVisible(highlightId)
  }

  const [message] = id ? getMessageById(id, messages) : [suggestionMessage]
  const { suggestion, onSuggestionCommentPress } = passedProps
  let { isPinned, isSuggestion } = message
  isPinned = isPinned && !hiddenPinnedMessages.includes(id)

  const pinnedButton = {
    title: isPinned ? I18n.t("pinboard.removeFrom") : I18n.t("pinboard.addTo"),
    image: isPinned ? images.unpinIcon : images.borderedThumbstack,
    callback: () => {
      if (isSuggestion || suggestion?.id) {
        pinUnpinSuggestion({
          isPinned: !isPinned,
          suggestionId: suggestion?.id || id,
        })
      } else {
        pinUnpinMessage({
          isPinned: !isPinned,
          messageId: id,
        })
      }
      const eventName = isPinned
        ? EVENTS.REMOVE_FROM_PINBOARD
        : EVENTS.ADD_TO_PINBOARD
      logEvent(eventName, {
        teeupId: teeup.id,
        suggestionId: suggestion?.id || id,
      })
    },
  }
  const actionsheetObject = suggestion || message || suggestionMessage
  const isCopyEnabled =
    message.text && message.conversationTypeId === conversationTypeIds.message

  const canPinAndReply =
    message.conversationTypeId === conversationTypeIds.message ||
    message.conversationTypeId === conversationTypeIds.reply ||
    message.conversationTypeId === conversationTypeIds.suggestionMessage ||
    message.conversationTypeId === conversationTypeIds.suggestion ||
    message.conversationTypeId === conversationTypeIds.file ||
    message.conversationTypeId === conversationTypeIds.video ||
    message.conversationTypeId === conversationTypeIds.image

  const canDownload =
    message.conversationTypeId === conversationTypeIds.file ||
    message.conversationTypeId === conversationTypeIds.image

  const canSelectGameplan =
    message.conversationTypeId === conversationTypeIds.suggestion &&
    !actionsheetObject.selected &&
    !actionsheetObject.decided &&
    !actionsheetObject.isWithdrawn &&
    permissions.allowSelectGameplan

  const canRemoveGameplan =
    message.conversationTypeId === conversationTypeIds.suggestion &&
    actionsheetObject.selected &&
    !actionsheetObject.decided &&
    permissions.allowSelectGameplan

  const canRestoreSuggestion =
    message.conversationTypeId === conversationTypeIds.suggestion &&
    actionsheetObject.isWithdrawn
  const canViewOnMap =
    message.conversationTypeId === conversationTypeIds.suggestion &&
    suggestion?.googlePlaceId

  if (!expanded && !canPinAndReply) toggleExpansion()

  const { type } = actionsheetObject
  const allowCommentOrReply =
    isSuggestion || suggestion?.id
      ? userStatus !== teeupUserStatusKeys.invited
      : true
  const isSuggestionInPast = isSuggestion
    ? isPastSuggestion(actionsheetObject)
    : true

  const isImage = message.conversationTypeId === conversationTypeIds.image

  const buttons = canPinAndReply
    ? [
        outsideOfChat && {
          title: I18n.t("showInConversation"),
          image: images.reply,
          callback: () => {
            //   teeupCardActions.setActiveHighlightedMessage(id)
            onShowOnConversation()
          },
        },
        canDownload && {
          title: `Save ${isImage ? "image" : "file"}`,
          image: images.downloadDark,
          callback: () => {
            //   if (isImage) {
            //       downloadFile(message.image)
            //   } else {
            //       downloadAndOpenFile(message.file, onFilePress)
            //   }
          },
        },
        canViewOnMap && {
          title: I18n.t("gameplans.viewOnMap"),
          image: images.directions,
          iconStyle: { tintColor: AppColors.brand.darkGrey2 },
          callback: () => {
            //   teeupActions
            //       .getSuggestionPlace(suggestion.googlePlaceId)
            //       .then((res) => openSuggestionOnMap(res))
          },
        },
        !canRestoreSuggestion && {
          title: I18n.t("gameplans.nudgeAboutSuggestion"),
          image: images.nudgePeople,
          callback: () => {
            //   showNudgeParticipantsList({
            //       suggestionId: suggestion?.id,
            //       subTypeId: teeupListNudgeTypes.suggestion,
            //   })
          },
        },
        expanded && {
          title:
            isSuggestion || suggestion?.id
              ? I18n.t("gameplans.commentOnSuggestion")
              : "Reply",
          image: images.reply,
          iconStyle: getNotAllowedIconTintColor(allowCommentOrReply),
          textStyle: getNotAllowedTextColor(allowCommentOrReply),
          callback: () => {
            if (!allowCommentOrReply) return
            if ((isSuggestion || suggestion?.id) && onSuggestionCommentPress) {
              onSuggestionCommentPress()
            }
            onReplyStart(message.id, suggestionMessage)
          },
        },
        canSelectGameplan && {
          title: I18n.t("gameplans.moveToGameplan"),
          image: images.makeGameplan,
          callback: () => {
            if (isSuggestionInPast) {
              Toast.show(I18n.t("gameplans.pastSuggestion"), {
                bottomOffset: 8,
              })
            } else {
              //   promoteToGameplan({
              //       suggestion: actionsheetObject,
              //       decidedGameplanId,
              //       permissions,
              //       activeTeeupId: teeup.id,
              //   })
            }
          },
        },
        canRemoveGameplan && {
          title: I18n.t("gameplans.removeFromGameplan"),
          image: images.makeGameplan,
          callback: () => {},
          //   promoteToGameplan({
          //       suggestion: actionsheetObject,
          //       decidedGameplanId,
          //       permissions,
          //       activeTeeupId: teeup.id,
          //       isRemove: true,
          //   }),
        },
        canRestoreSuggestion && {
          title: I18n.t("gameplans.restoreSuggestion"),
          image: images.trashcanOutline,
          callback: () => {
            //   teeupActions.toggleWithdrawSuggestion(suggestion.id)
          },
        },
        pinnedButton,
        isCopyEnabled && {
          title: I18n.t("systemButtons.copyText"),
          image: images.copyToClipboard,
          callback: () => Clipboard.setString(message.text),
        },
        (isSuggestion || suggestion) && {
          title: I18n.t("gameplans.goToSuggestionsList"),
          image: images.suggestionListMenu,
          callback: () => {
            //   showSuggestionList({
            //       optionId: actionsheetObject.optionId,
            //   })
          },
        },
      ]
    : []

  setIsContextMenuVisible(true)
  const length = buttons.filter(b => !!b).length + 1
  highlighterRefSetBottomPanelHeight(
    length * 30 + AppSizes.additionalBottomSpace
  )

  // showActionsheet(buttons, { disableDim: true }, () => {
  //     setIsContextMenuVisible(false)
  //     highlighterRefSetVisible('')
  // })
}

export {
  getMessageType,
  getMessageOptions,
  renderMentionedUser,
  isWithIndent,
  mentionPatternForInput,
  handleCheckIfInputValid,
  getMessageById,
  showChatMessageContextMenu,
}
