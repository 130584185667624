import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  ${({ $isInner }) => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    position: relative;
    padding: 0 ${$isInner ? 0 : "15px"};
    ${$isInner &&
    css`
      flex-direction: row-reverse;
    `};
  `};
`

export const Info = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $isConflict }) =>
    $isConflict &&
    css`
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2px;
    `}};
`

export const Text = styled(NSubtitleS)`
  font-weight: ${NFontWeight.bold};
`
