import React from "react"

import PropTypes from "prop-types"
import { View, Animated, Text, Image } from "react-native"

import { teeupUserGenericStatus, teeupStatus } from "@configs/enums"
import { statusTypeIds } from "@configs/mappings"
import { escapeRegExp } from "@utils/dataUtils"

import ImageMessage from "../ImageMessage"

import ExpandingButton from "./expandingButton"
import PeopleSubview from "./peopleSubview"
import styles from "./statusMessageStyles"

// const emojiPatterns = /👍|👎|🚫/

const userStatuses = Object.keys(teeupUserGenericStatus.values)
  .map(statusKey => teeupUserGenericStatus.values[statusKey])
  .join("|")
const teeupStatuses = Object.keys(teeupStatus.values)
  .map(statusKey => teeupStatus.values[statusKey])
  .join("|")

const getWrapper = isAnimated => (isAnimated ? Animated.View : View)

const TitleMessage = ({
  text,
  item,
  mainview,
  subviews,
  senders,
  isInitial,

  isAnimated = false,
  expanded = false,
  onPress,
  isTeeupCancelled,
  isStatusMessage,
  image,
  isImageChange,

  onLayout,
  passedRef,
  style,
  animatedStyle,
  containerStyle,
  isGameplanUpdate,
  icon,
  userStatusText,
  ...otherProps
}) => {
  let content

  if (
    text &&
    userStatusText &&
    typeof userStatusText === "string" &&
    typeof text === "string" &&
    icon
  ) {
    content = (
      <View style={styles.userStatusWrapper}>
        <Text selectable style={styles.text}>
          {text}
        </Text>
        <Image source={icon} style={styles.icon} />
        <Text
          selectable
          style={styles.text}
          parse={[
            {
              pattern: new RegExp(escapeRegExp(userStatuses)),
              style: styles.textBold,
            },
            {
              pattern: new RegExp(escapeRegExp(teeupStatuses)),
              style: styles.textBold,
            },
          ]}
          childrenProps={{ allowFontScaling: false }}>
          {userStatusText}
        </Text>
      </View>
    )
  } else if (text && typeof text === "string") {
    content = (
      <Text
        // selectable
        style={[styles.text, { maxWidth: "410px" }]}
        // parse={[
        //     {
        //         type: 'url',
        //         renderText: renderLink,
        //         onPress: openUrl,
        //     },
        //     {
        //         pattern: emojiPatterns,
        //         style: styles.textEmoji,
        //     },
        //     {
        //         pattern: new RegExp(escapeRegExp(userStatuses)),
        //         style: styles.textBold,
        //     },
        //     {
        //         pattern: new RegExp(escapeRegExp(teeupStatuses)),
        //         style: styles.textBold,
        //     },
        // ]}
        // childrenProps={{ allowFontScaling: false }}
      >
        {text}
      </Text>
    )
  } else if (mainview) {
    const MainViewComponent = mainview
    content = <MainViewComponent onPress={onPress} expanded={expanded} />
  }

  const Wrapper = getWrapper(isAnimated)
  const wrapperProps = isAnimated
    ? {
        style: [styles.mainContainer, animatedStyle],
      }
    : {
        style: [styles.mainContainer, style],
        onLayout: onLayout,
        ref: passedRef,
        ...otherProps,
      }

  return (
    <Wrapper {...wrapperProps}>
      <View
        style={[
          // styles.boxContainer({
          //     isStatusMessage,
          //     isInitial,
          //     isImageChange,
          //     isGameplanUpdate,
          // }),
          isTeeupCancelled ? styles.boxContainerGrey : null,
        ]}>
        <View style={[styles.wrapperContainer, containerStyle]}>
          {content}
          {((isStatusMessage &&
            senders.length > 1 &&
            item.typeId !== statusTypeIds.retract) ||
            isImageChange) && (
            <ExpandingButton
              onPress={onPress}
              expanded={expanded}
              isTeeupCancelled={isTeeupCancelled}
            />
          )}
        </View>
        {isStatusMessage &&
          item.typeId !== statusTypeIds.retract &&
          senders.length > 1 &&
          expanded && <PeopleSubview senders={senders} item={item} />}
        {isImageChange && expanded && (
          <ImageMessage
            isPinnedScreen={false}
            image={image}
            isImageChange={isImageChange}
          />
        )}

        {expanded && subviews}
      </View>
    </Wrapper>
  )
}

TitleMessage.propTypes = {
  image: PropTypes.string,
  isImageChange: PropTypes.bool,
  text: PropTypes.string,
  mainview: PropTypes.func,
  item: PropTypes.object,
  subviews: PropTypes.array,
  senders: PropTypes.array,
  isInitial: PropTypes.bool,
  isAnimated: PropTypes.bool,
  namePatterns: PropTypes.string,
  expanded: PropTypes.bool,
  onPress: PropTypes.func,
  isTeeupCancelled: PropTypes.bool,
  isStatusMessage: PropTypes.bool,
  onLayout: PropTypes.func,
  passedRef: PropTypes.func,
  // style: PropTypes.object,
  style: PropTypes.number,
  animatedStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  isGameplanUpdate: PropTypes.bool,
  icon: PropTypes.string,
  userStatusText: PropTypes.string,
}

class TitleMessageContainer extends React.Component {
  constructor(props) {
    super(props)
    const { expanded, item } = this.props
    const initAnimatedPosition = this.isExpanded(expanded, item.id) ? 1 : 0
    this.state = {
      collapsedHeight: 0,
      expandedHeight: 0,
      heightCalculated: false,
      animatedHeight: new Animated.Value(initAnimatedPosition),
    }
  }

  isExpanded = (expandedId, itemId) => expandedId === itemId

  setHeightCalculated = callback => {
    const { collapsedHeight, expandedHeight } = this.state

    if (collapsedHeight && expandedHeight) {
      this.setState(
        {
          heightCalculated: true,
        },
        callback
      )
    }
  }

  onLayout = () => {
    if (!this._expandedRef) return
    this._expandedRef.measure((x, y, width, height) => {
      if (height) {
        this.setState({ expandedHeight: height }, () =>
          this.setHeightCalculated()
        )
      }
    })
  }

  onCollapsedLayout = () => {
    if (!this._collapsedRef) return
    this._collapsedRef.measure((x, y, width, height) => {
      if (height) {
        this.setState({ collapsedHeight: height }, () =>
          this.setHeightCalculated()
        )
      }
    })
  }

  onPress = () => {
    const { collapsedHeight, expandedHeight } = this.state

    if (!collapsedHeight || !expandedHeight) {
      this.onLayout()
      this.onCollapsedLayout()

      setTimeout(() => {
        this.setHeightCalculated(this.animateAndTriggerOnPress)
      }, 10)
    } else {
      this.animateAndTriggerOnPress()
    }
  }

  animateAndTriggerOnPress = () => {
    const { expanded } = this.props
    if (expanded) {
      this.state.animatedHeight.setValue(0)
    } else {
      this.state.animatedHeight.setValue(1)
    }

    this.props.onPress()
  }

  render() {
    const {
      item,
      senders,
      isInitial,
      isStatusMessage,
      isImageChange,
      expanded,
    } = this.props
    const {
      collapsedHeight,
      expandedHeight,
      heightCalculated,
      animatedHeight,
    } = this.state
    const isExpandable =
      isInitial ||
      isImageChange ||
      (isStatusMessage &&
        senders.length > 1 &&
        item.typeId !== statusTypeIds.retract)

    if (!isExpandable) {
      return <TitleMessage {...this.props} />
    }

    const isExpanded = this.isExpanded(expanded, item.id)

    return (
      <>
        {heightCalculated && (
          <TitleMessage
            {...this.props}
            onPress={this.onPress}
            // animatedStyle={animatedStyle}
            isAnimated
          />
        )}

        {!heightCalculated && (
          <TitleMessage
            {...this.props}
            onPress={this.onPress}
            expanded={false}
            onLayout={this.onCollapsedLayout}
            passedRef={ref => (this._collapsedRef = ref)}
            style={isExpanded ? styles.waitingForLayout : null}
          />
        )}
        {!heightCalculated && (
          <TitleMessage
            {...this.props}
            expanded={true}
            onLayout={this.onLayout}
            passedRef={ref => (this._expandedRef = ref)}
            style={isExpanded ? null : styles.waitingForLayout}
            pointerEvents={"none"}
          />
        )}
      </>
    )
  }
}

TitleMessageContainer.propTypes = {
  image: PropTypes.string,
  isImageChange: PropTypes.bool,
  text: PropTypes.string,
  mainview: PropTypes.func,
  item: PropTypes.object,
  subviews: PropTypes.array,
  senders: PropTypes.array,
  isInitial: PropTypes.bool,
  namePatterns: PropTypes.string,
  expanded: PropTypes.string,
  onPress: PropTypes.func,
  isTeeupCancelled: PropTypes.bool,
  isStatusMessage: PropTypes.bool,
}

export default TitleMessageContainer
