import React from "react"

import PropTypes from "prop-types"
import { View, Text } from "react-native"
// import { WheelPicker } from 'react-native-wheel-picker-simple'

import { generateHoursArray, generateMinutesArray } from "@utils/dateUtils"

import { ViewStyles, TimeDurationStyles } from "./timePickerOverlayStyles"

const hoursArray = generateHoursArray({ maxHour: 7 })
const minutesArray = generateMinutesArray(5)

const DisabledDurationWheel = props => {
  const { durationHoursIndex, durationMinutesIndex } = props
  return (
    <View
      style={[
        ViewStyles.multyPickerContainer,
        TimeDurationStyles.wheelContainer,
      ]}>
      <View style={TimeDurationStyles.durationDisableOverlay} />
      <View style={ViewStyles.multiPickerWheelContainer}>
        <View style={TimeDurationStyles.durationWheelContainer}>
          {/* <WheelPicker
                        itemStyle={{ color: AppColors.brand.warmGrey2 }}
                        nestedScrollEnabled
                        selectedItem={durationHoursIndex}
                        data={hoursArray}
                        style={TimeDurationStyles.durationWheel}
                        selectedItemTextColor={AppColors.brand.warmGrey2}
                        indicatorColor={AppColors.brand.warmGrey2}
                    /> */}
        </View>
        <View style={TimeDurationStyles.durationHoursContainer}>
          <Text style={TimeDurationStyles.disabledDurationHoursText}>h</Text>
        </View>
      </View>
      <View style={ViewStyles.multiPickerWheelContainer}>
        <View style={TimeDurationStyles.durationWheelContainer}>
          {/* <WheelPicker
                        itemStyle={{ color: AppColors.brand.warmGrey2 }}
                        nestedScrollEnabled
                        selectedItem={durationMinutesIndex}
                        data={minutesArray}
                        style={TimeDurationStyles.durationWheel}
                        selectedItemTextColor={AppColors.brand.warmGrey2}
                        indicatorColor={AppColors.brand.warmGrey2}
                    /> */}
        </View>

        <View style={TimeDurationStyles.durationHoursContainer}>
          <Text style={TimeDurationStyles.disabledDurationHoursText}>min</Text>
        </View>
      </View>
    </View>
  )
}

DisabledDurationWheel.propTypes = {
  durationHoursIndex: PropTypes.number,
  durationMinutesIndex: PropTypes.number,
}

export default DisabledDurationWheel
