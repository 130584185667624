import styled from "styled-components"

import {
  NCaption,
  NFontWeight,
  NTitle6,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  padding: 30px 0;
  text-align: center;
`

export const IconWrapper = styled.div`
  width: 75px;
  height: 75px;
  background-color: var(--primary-50);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled(NTitle6)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NCaption)`
  color: var(--gray5);
`

export const Icon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
`
