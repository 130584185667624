import { useCallback, useState } from "react"

import { TabContext, TabPanel } from "@mui/lab"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { updateGamePlanWhenSuggestions } from "@actions/createTeeupActions"
import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { Tab, TabList } from "@components/ui/Tabs"
import { useScreenWidth } from "@hooks/useScreenWidth"
import { selectActiveTeeupGameplans } from "@selectors/activeTeeup"
import { createSuggestions } from "middlewares/backendMiddleware"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import {
  GeneralDateSuggestion,
  SuggestWhenTab,
  TimeWheelSuggestion,
} from "types/suggestions"

import Duration from "./Duration"
import General from "./General"
import TimeWheelTab from "./TimeWheelTab"

import "./index.scss"
import dayjs from "utils/dayjs"

const SuggestWhenModalNd = ({ isFromCreateTeeUp }) => {
  const gameplan = useSelector(selectActiveTeeupGameplans)
  const [currentTab, setCurrentTab] = useState(SuggestWhenTab.TIME_WHEEL)
  const dispatch = useDispatch()
  const { isSmallSize } = useScreenWidth()
  const isOpen = useSuggestionInPersonStore(state => state.isWhenModalOpen)
  const setIsOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const clearTimeWheelState = useSuggestionTimeWheelStore(
    state => state.clearState
  )
  const clearGeneralState = useSuggestionGeneralDateStore(
    state => state.clearState
  )

  const onClose = () => {
    clearTimeWheelState()
    clearGeneralState()
    setIsOpen(false)
  }

  const onTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue as SuggestWhenTab)
  }

  const onSuggestClick = useCallback(
    (
      suggestions: GeneralDateSuggestion[] | TimeWheelSuggestion[],
      isDecided: boolean,
      isGeneralSuggestion?: boolean
    ) => {
      const formattedSuggestions = suggestions.map(suggestion => {
        console.log("suggestion", suggestion)

        const isAllDay = suggestion.duration === 1440 && !isGeneralSuggestion
        const startDate = isAllDay
          ? dayjs(suggestion?.date?.[0])?.startOf?.("day")
          : suggestion?.date?.[0]
        const endDate = isAllDay
          ? dayjs(suggestion?.date?.[0])?.endOf?.("day")
          : suggestion?.date?.[1]

        return {
          customDate: suggestion?.label || "",
          customTime: suggestion?.time?.label || "",
          startDate: startDate?.format?.() || startDate,
          endDate: endDate?.format?.() || endDate,
          isDecided: isDecided,
          isCustomTime: !!suggestion?.time?.label,
          isCustomDate: !!isGeneralSuggestion,
          duration: suggestion.duration,
          type: "when",
          gameplanOptionId: gameplan[0].optionId,
        }
      })

      if (isFromCreateTeeUp) {
        dispatch(updateGamePlanWhenSuggestions(formattedSuggestions))
      } else {
        createSuggestions(formattedSuggestions)
      }

      onClose()
    },
    [gameplan]
  )

  return (
    <DefaultModal
      open={isOpen}
      onClose={onClose}
      responsiveBreakpoint={isSmallSize}>
      <Header onBackClick={onClose} title="Suggest When" />
      <Duration />

      <div className="suggest-when-modal">
        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}>
            <TabList onChange={onTabChange} className="suggest-where-tabs">
              <Tab label="Time wheel" value={SuggestWhenTab.TIME_WHEEL} />
              <Tab label="General" value={SuggestWhenTab.GENERAL} />
            </TabList>
          </Box>

          <TabPanel value={SuggestWhenTab.TIME_WHEEL}>
            <TimeWheelTab
              onSave={onSuggestClick}
              isFromCreateTeeUp={isFromCreateTeeUp}
            />
          </TabPanel>
          <TabPanel value={SuggestWhenTab.GENERAL}>
            <General onSave={onSuggestClick} />
          </TabPanel>
        </TabContext>
      </div>
    </DefaultModal>
  )
}

export default SuggestWhenModalNd
