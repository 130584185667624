import React, { useState } from "react"

import "./index.scss"
import FinalDateAndTime from "./components/FinalDateAndTime"
import TimePickerContainer from "./components/TimePickerContainer"

const TimezonesTimePicker = ({
  setIsTimePickerOpened,
  suggestionValues,
  handleTimePick,
  duration,
  isTemporalCustom,
}) => {
  const [finalTime, setFinalTime] = useState(suggestionValues)

  return (
    <div
      className={`timezones-time-picker__wrapper ${
        isTemporalCustom && "--custom"
      }`}>
      <div className="timezones-time-picker__container">
        <FinalDateAndTime finalTime={finalTime} />
        <TimePickerContainer
          setIsTimePickerOpened={setIsTimePickerOpened}
          handleTimePick={handleTimePick}
          finalTime={finalTime}
          setFinalTime={setFinalTime}
          duration={duration}
        />
      </div>
    </div>
  )
}

export default TimezonesTimePicker
