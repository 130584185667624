export const groupByAlphabet = arr => {
  const grouped = arr.reduce((result, obj) => {
    const firstLetter = obj.name[0].toLowerCase()

    result[firstLetter] = result[firstLetter] || []
    result[firstLetter].push(obj)

    return result
  }, {})

  for (const key in grouped) {
    if (grouped.hasOwnProperty(key)) {
      grouped[key].sort((a, b) => a.name.localeCompare(b.name))
    }
  }

  return grouped
}
