import React from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS, BUTTON_SIZES } from "@constants/button"

import { Footer } from "../newModalStyles"

export const ModalFooter = ({
  isBlackBtn,
  closeText,
  cancelText,
  confirmText,
  onClose,
  onCancel,
  onConfirm,
  isDisabledConfirm,
  isLoadingConfirm,
  isHeader,
  isHorizontalBtns,
}) => (
  <Footer isHeader={isHeader} isHorizontalBtns={isHorizontalBtns}>
    {!!closeText && !!onClose && (
      <ButtonEl
        isBlack={isBlackBtn}
        fullWidth={isHorizontalBtns}
        size={isHeader && BUTTON_SIZES.MEDIUM}
        variant={BUTTON_VARIANTS.ROUNDED}
        text={closeText}
        onClick={onClose}
      />
    )}

    {!!confirmText && !!onConfirm && (
      <ButtonEl
        isBlack={isBlackBtn}
        fullWidth={isHorizontalBtns}
        size={isHeader && BUTTON_SIZES.MEDIUM}
        variant={BUTTON_VARIANTS.ROUNDED}
        text={confirmText}
        isLoading={isLoadingConfirm}
        onClick={onConfirm}
        disabled={isDisabledConfirm}
      />
    )}

    {!!cancelText && !!onCancel && (
      <ButtonEl
        isBlack={isBlackBtn}
        fullWidth={isHorizontalBtns}
        size={isHeader && BUTTON_SIZES.MEDIUM}
        variant={BUTTON_VARIANTS.TRANSPARENT}
        text={cancelText}
        onClick={onCancel}
      />
    )}
  </Footer>
)

ModalFooter.propTypes = {
  isBlackBtn: PropTypes.bool,
  closeText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}
