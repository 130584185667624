import cn from "classnames"
import { isNaN } from "lodash"

export interface FilterTabsItemProps {
  title: string
  active: boolean
  onClick: () => void
  paginationCount?: number | string
}

const Item = ({
  title,
  active,
  paginationCount,
  onClick,
}: FilterTabsItemProps) => {
  const isLoading = false

  return (
    <div
      className={cn(
        "flex gap-2 px-3 py-4 pb-3 font-['Inter'] text-[13px] font-semibold text-white cursor-pointer select-none box-border transition-all ease-in-out duration-300",
        {
          "border-b-white border-b-2": active,
          "opacity-60 border-b-2 border-b-transparent": !active,
        }
      )}
      onClick={onClick}>
      <div>{title}</div>

      <div className="min-w-3">
        {!isLoading && !isNaN(paginationCount) && active && (
          <div>{paginationCount}</div>
        )}
      </div>
    </div>
  )
}

export default Item
