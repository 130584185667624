import React from "react"

import { TimeScroller } from "../TimeScroller"

import { SuggestionWhenCard, DatePicker } from "./molecules"

export const TimeWheelContent = () => {
  return (
    <>
      <SuggestionWhenCard />

      <DatePicker />

      <TimeScroller />
    </>
  )
}
