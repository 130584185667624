import cn from "classnames"

import { SvgIcon } from "@components/common"
import { StatusIcon as IStatusIcon } from "types/participant"

import "./index.scss"

interface Props {
  isLarge?: boolean
  isXL?: boolean
  status: IStatusIcon
  isBlock?: boolean
  disabled?: boolean
  className?: string
}

const StatusIcon = ({
  isLarge,
  isXL,
  status,
  isBlock,
  disabled,
  className,
}: Props) => {
  const { color, iconName } = status || {}

  if (!color || !iconName) return null

  return (
    <div
      className={cn("people-participants-status", className, {
        "people-participants-status-large": isLarge,
        "people-participants-status-xl": isXL,
        "people-participants-status-block": isBlock,
        "people-participants-status-disabled": disabled,
      })}>
      <SvgIcon name={iconName} />
    </div>
  )
}

export default StatusIcon
