import React, { useContext } from "react"

import { FixedSizeList as List } from "react-window"

import "../timezones/zones/index.scss"
import "./timeScroller.scss"
import {
  TimeWheelContext,
  MINUTES_DAY_PARTS,
  HOUR_ITEM_WIDTH,
  TIME_WHEEL_WIDTH,
  INNER_EVENTS_HEIGHT,
} from "contexts/TimeWheelContext"

import {
  HourItem,
  ParticipantsList,
  TimePicker,
  UnknownTimezones,
  CalendarLabel,
} from "./molecules"

const TimeScroller = () => {
  const {
    openedInnerEvents,
    dragScrollRef,
    parsedCurrentPeriod,
    zones,
    peopleWithUnknownTimeZones,
    onScroll,
  } = useContext(TimeWheelContext)

  const renderHourItem = props => {
    return <HourItem {...props} />
  }

  const innerEventsHeight = openedInnerEvents ? INNER_EVENTS_HEIGHT + 40 : 0

  return (
    <div className="timewheel-wrapper">
      <TimePicker />

      <div className="timewheel-wrapper__hours-wrapper">
        <ParticipantsList zones={zones} />

        <CalendarLabel zones={zones} />

        <List
          className="infinite-list"
          height={(60 + 50) * zones.length + 70 + innerEventsHeight}
          itemCount={parsedCurrentPeriod.length / MINUTES_DAY_PARTS}
          itemSize={HOUR_ITEM_WIDTH}
          width={TIME_WHEEL_WIDTH}
          layout="horizontal"
          onScroll={onScroll}
          outerRef={dragScrollRef}>
          {renderParams =>
            renderHourItem({
              ...renderParams,
              zones,
              parsedCurrentPeriod,
            })
          }
        </List>

        {peopleWithUnknownTimeZones.length > 0 ? (
          <UnknownTimezones participantsList={peopleWithUnknownTimeZones} />
        ) : null}
      </div>
    </div>
  )
}

export default TimeScroller
