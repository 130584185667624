import styled from "styled-components"

import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "@assets/styles/newTypographyStyles"

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
`

export const ListItem = styled.li`
  background-color: var(--white);
  border-bottom: 1px solid var(--lightGray5);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: relative;

  & > img:last-child {
    cursor: pointer;
  }
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`

export const Name = styled(NSubtitle)`
  font-weight: ${NFontWeight.semiBold};
`

export const InvitedBy = styled(NSubtitleS)`
  color: var(--gray);
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`
