import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../../theme/"

export const MonthCalendarStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: Units.responsiveValue(51),
    backgroundColor: AppColors.brand.white,
    paddingBottom: Units.responsiveValue(19),
    borderBottomColor: AppColors.brand.grey2,
    borderBottomWidth: 1,
  },
  weekdayWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  weekdayContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  weekdayText: {
    ...AppFonts.smallestBold,
    letterSpacing: -Units.responsiveValue(0.1),
    textTransform: "uppercase",
    color: AppColors.brand.black,
  },
  daysSeparatorContainer: {
    width: Units.responsiveValue(1),
  },
  daysSeparator: {
    position: "absolute",
    right: 0,
    top: Units.responsiveValue(20),
    bottom: -Units.responsiveValue(20),
    width: Units.responsiveValue(1),
    backgroundColor: AppColors.brand.grey2,
  },
})

export const MonthCalendarTimetableStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    backgroundColor: AppColors.brand.white,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: AppColors.brand.grey2,
    minHeight: "5vh",
    alignItems: "flex-start",
  },
  lastBlockBorder: {
    borderBottomColor: AppColors.brand.lightGrey,
  },
})

export const DayOnMonthCalendarView = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    borderRightWidth: 1,
    borderRightColor: AppColors.brand.grey2,
    height: "100%",
  },
  lastBlockBorder: {
    borderRightColor: AppColors.brand.lightGrey,
  },
  dateContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: Units.responsiveValue(18),
    height: Units.responsiveValue(18),
    borderRadius: Units.responsiveValue(6),
    marginTop: Units.responsiveValue(6),
  },
  todayDayContainer: {
    backgroundColor: AppColors.brand.pink,
  },
  dayText: {
    ...AppFonts.smallestBold,
    color: AppColors.brand.warmGrey2,
  },
  disabledText: {
    color: AppColors.brand.warmGrey2,
  },
  thisMonthDayText: {
    color: AppColors.brand.black,
  },
  todayDayText: {
    color: AppColors.brand.white,
  },
  selectedDayBlock: {
    borderWidth: 1,
    borderColor: AppColors.brand.pink,
    backgroundColor: AppColors.brand.pink03,
  },
  eventsContainer: {
    flex: 1,
    alignSelf: "stretch",
    alignItems: "flex-start",
  },
  eventTextConatiner: {
    lineHeight: Units.responsiveValue(15),
  },
  eventText: {
    ...AppFonts.smallestDemibold,
    color: AppColors.brand.black,
    marginBottom: Units.responsiveValue(2),
  },
  teeupDot: {
    color: AppColors.brand.pink,
  },
  eventDot: {
    color: AppColors.brand.blue2,
  },
  conflict: {
    color: AppColors.brand.red,
  },
})
