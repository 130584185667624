import React from "react"

import { View, Text, Image } from "react-native"

import { teeupUserStatus } from "@configs/enums"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import { getUsernameOrName } from "@utils/contactUtils"
import { getContactInitials } from "@utils/dataUtils"
import { getTimeFormat } from "@utils/dateUtils"
import { userStatusForNudgesImages } from "@utils/teeupUtils"

import {
  styles,
  nudgeAnswerContent,
  nudgeTimestamp,
} from "./UserNudgesScreenStyles"

export const NudgeAnswerPlainText = ({ respondTitle, text, date }) => {
  return (
    <View style={[styles.flex, styles.responseTextWrapper]}>
      <View style={styles.flex}>
        <Text style={styles.currentUserAnswerTitle}>{respondTitle}</Text>
        <Text style={styles.currentUserAnswerStatus}>{text}</Text>
      </View>
      <Text style={nudgeTimestamp(false)}>
        {getTimeFormat(date, "MMMM D, hh:mm A")}
      </Text>
    </View>
  )
}

// NudgeAnswerPlainText.propTypes = {
//     respondTitle: PropTypes.string,
//     text: PropTypes.string,
//     date: PropTypes.string,
// }

export const NudgeAnswerAvatarAndStatus = ({
  user,
  userStatusKey,
  date,
  index,
  totalAmount,
}) => {
  const username = getUsernameOrName(user)

  return (
    <View style={nudgeAnswerContent(index, totalAmount)}>
      <View style={styles.avatarStyle}>
        {user.avatar ? (
          <Image style={styles.avatarImageStyle} source={user.avatar} />
        ) : (
          <Text>{getContactInitials(user)}</Text>
        )}
        <UserStatusAccesory
          userStatus={user.status}
          accesoryStyle={styles.userStatusIcon}
        />
      </View>
      <View style={[styles.flex, styles.responseTextWrapper]}>
        <View>
          <Text style={styles.nudgeAnswerUsername}>{username}</Text>
          <View style={[styles.row, styles.nudgeAnswerStatusRow]}>
            <View style={styles.nudgeAnswerStatusImage}>
              <Image source={userStatusForNudgesImages(userStatusKey)} />
            </View>
            <Text style={styles.nudgeAnswerStatus}>
              {teeupUserStatus[userStatusKey]}
            </Text>
          </View>
        </View>
      </View>
      <Text style={nudgeTimestamp(false)}>
        {getTimeFormat(date, "MMMM D, hh:mm A")}
      </Text>
    </View>
  )
}
