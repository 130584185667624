import React, { useState } from "react"

import lowerCase from "lodash/lowerCase"
import PropTypes from "prop-types"

import { useTeeUpPage } from "../../../pages/TeeUpPage/TeeUpPageContext"
import { GameplanHeader } from "../atoms"
import { useGameplans } from "../hooks"
import { SuggestionsList, DecidedSuggestion } from "../molecules"

import { Block } from "./gameplanBlockStyles"

export const GameplanBlock = ({ type }) => {
  const [isNewest, setIsNewest] = useState(true)
  const { teeup } = useTeeUpPage()
  const lowerType = lowerCase(type)
  const { suggestions, decidedSuggestion } = useGameplans({
    teeup,
    type: lowerType,
    isNewest,
  })

  return (
    <Block data-testid={type}>
      <GameplanHeader
        isDecidedSuggestion={!!decidedSuggestion}
        type={type}
        suggestionsAmount={suggestions.length}
      />

      {!!decidedSuggestion && (
        <DecidedSuggestion decidedSuggestion={decidedSuggestion} />
      )}

      {suggestions.length > 0 && (
        <SuggestionsList
          type={type}
          suggestions={suggestions}
          isNewest={isNewest}
          isDecidedSuggestion={!!decidedSuggestion}
          setIsNewest={setIsNewest}
        />
      )}
    </Block>
  )
}

GameplanBlock.propTypes = {
  type: PropTypes.string,
}
