import { useMemo } from "react"

export const useCreatedSuggestions = guestSuggestions => {
  return useMemo(() => {
    return guestSuggestions
      .filter(({ isCreated, isPublished }) => isCreated && !isPublished)
      .map(event => ({
        ...event,
        isPublished: false,
      }))
  }, [guestSuggestions])
}
