import React from "react"

import { Caption, FontWeight } from "@assets/styles/typographyStyles"

export const EventName = ({ textColor, name }) => (
  <Caption
    style={{
      color: textColor,
      cursor: "default",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%",
      whiteSpace: "nowrap",
      fontWeight: FontWeight.bold,
    }}>
    {name}
  </Caption>
)
