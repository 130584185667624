import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export default StyleSheet.create({
  messageTimestampContainer: (isTimestampVisible, isInitialMessage) => ({
    width: isTimestampVisible ? Units.responsiveValue(55) : 0,
    flexShrink: 1,
    alignItems: "flex-end",
    justifyContent: "center",
    alignSelf: isInitialMessage ? "flex-end" : "center",
    marginLeft: isTimestampVisible ? Units.responsiveValue(5) : 0,
  }),
  messageTimestampDate: {
    ...AppFonts.smallestMedium,
    color: AppColors.brand.warmGrey2,
    letterSpacing: Units.responsiveValue(0.5),
    marginBottom: Units.responsiveValue(-2),
    lineHeight: Units.responsiveValue(13),
    textAlign: "right",
  },
  messageTimestamp: {
    ...AppFonts.smallishMedium,
    color: AppColors.brand.darkGrey2,
    letterSpacing: -Units.responsiveValue(0.23),
    lineHeight: Units.responsiveValue(22),
  },
})
