import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  ${({ $isFullScreen }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    ${$isFullScreen &&
    css`
      height: 100%;
    `};
  `}
`
