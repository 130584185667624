import React from "react"

import cn from "classnames"

export const MentionInputEl = ({ value }) => (
  <span
    className={cn("input-el__mention", {
      "input-el__mention--placeholder": !value,
    })}>
    @
  </span>
)
