import React, { useRef, useState } from "react"

import PropTypes from "prop-types"

import { List } from "./horizontalScrollListStyles"

export const HorizontalScrollList = ({
  listLength,
  isReacted,
  children,
  isDecided,
}) => {
  const scrollContainerRef = useRef(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = e => {
    setIsMouseDown(true)
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft)
    setScrollLeft(scrollContainerRef.current.scrollLeft)
  }

  const handleMouseMove = e => {
    if (!isMouseDown) return

    const scrollSpeedMultiplier = 2
    const x = e.pageX - scrollContainerRef.current.offsetLeft
    const walk = (x - startX) * scrollSpeedMultiplier
    scrollContainerRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
  }

  const handleMouseLeave = () => {
    setIsMouseDown(false)
  }
  return (
    <List
      ref={scrollContainerRef}
      $listLength={listLength}
      $isReacted={isReacted}
      $isDecided={isDecided}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}>
      {children}
    </List>
  )
}

HorizontalScrollList.propTypes = {
  isReacted: PropTypes.bool,
  listLength: PropTypes.number,
}
