import React from "react"

import NonUser from "../../../assets/images/non-user.svg"
import { AVATARS_SIZES } from "../constants"
import {
  ParticipantAvatar,
  MockParticipantsList,
} from "../teeupPreviewContentStyles"

export const MockParticipants = () => (
  <MockParticipantsList>
    {[1, 2, 3, 4, 5].map(el => (
      <ParticipantAvatar size={AVATARS_SIZES.SMALL} key={el}>
        <img src={NonUser} alt="unknown" />
      </ParticipantAvatar>
    ))}
  </MockParticipantsList>
)
