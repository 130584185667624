import styled from "styled-components"

// TODO: rename all constants after removing of old ones
export const NFontWeight = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  regular: 400,
  thin: 100,
}

export const NTitle6 = styled.h6`
  font-family: "Inter";
  font-size: 18px;
`

export const NTitle5 = styled.h5`
  font-family: "Inter";
  font-size: 20px;
`

export const NTitle2 = styled.h2`
  font-family: "Inter";
  font-size: 28px;
`

export const NSubtitleXs = styled.span`
  font-family: "Inter";
  font-size: 12px;
`

export const NSubtitleS = styled.span`
  font-family: "Inter";
  font-size: 14px;
`

export const NSubtitle = styled.span`
  font-family: "Inter";
  font-size: 16px;
`

export const NCaption = styled.p`
  font-family: "Inter";
  font-size: 16px;
`

export const NCaptionS = styled.p`
  font-family: "Inter";
  font-size: 13px;
`
