import { useMemo } from "react"

import { GAMEPLAN_TYPES } from "@constants/gameplan"

export const useGamePlan = previewInfo => {
  const getSuggestionsCreators = ({ suggestions, participants }) => {
    return suggestions.map(suggestion => ({
      ...suggestion,
      creator: participants.find(
        participant => participant.id === suggestion.createdBy
      ),
    }))
  }

  return useMemo(() => {
    const suggestionsPlan = previewInfo?.suggestionsPlan || {}
    const suggestionsPlanKeys = Object.keys(suggestionsPlan).filter(
      key => key === GAMEPLAN_TYPES.when || key === GAMEPLAN_TYPES.where
    )

    const result = suggestionsPlanKeys.reduce((acc, currentKey) => {
      const filteredSuggestions = suggestionsPlan[currentKey].filter(
        suggestion => !suggestion.isWithdrawn
      )
      const updatedSuggestions = getSuggestionsCreators({
        suggestions: filteredSuggestions,
        participants: previewInfo?.participants || [],
      })

      acc[currentKey] = {
        decidedSuggestion: previewInfo?.gameplan[currentKey],
        suggestions: updatedSuggestions,
      }

      return acc
    }, {})

    return Object.keys(result).length > 0 ? result : null
  }, [previewInfo])
}
