import React from "react"

import "./footer.scss"

const SuggestModalFooter = ({ children }) => {
  return (
    <div
      className="suggest-modal__footer"
      style={{
        justifyContent: children?.length > 1 ? "space-around" : "center",
      }}>
      {children}
    </div>
  )
}

export default SuggestModalFooter
