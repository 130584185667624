import React from "react"

import cn from "classnames"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { BUTTON_VARIANTS } from "../../../constants/button"
import { ButtonEl } from "../../common"

export const SubmitButtons = ({
  navBtnText,
  secondBtnText,
  secondBtnAction,
}) => {
  const history = useHistory()

  const goBackHandle = () => {
    history.push("/")
  }

  const getFirstBtnVariant = () => {
    return secondBtnText ? BUTTON_VARIANTS.ROUNDED : BUTTON_VARIANTS.TRANSPARENT
  }

  return (
    <div
      className={cn("auth0-errors__buttons", {
        "auth0-errors__buttons--double": secondBtnText,
      })}>
      <ButtonEl
        text={navBtnText}
        variant={getFirstBtnVariant()}
        onClick={goBackHandle}
      />

      {secondBtnText && (
        <ButtonEl
          text={secondBtnText}
          variant={BUTTON_VARIANTS.TRANSPARENT}
          onClick={secondBtnAction}
        />
      )}
    </div>
  )
}

SubmitButtons.propTypes = {
  navBtnText: PropTypes.string,
  auth0Error: PropTypes.string,
}
