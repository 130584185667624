import React from "react"

import FacebookIcon from "../../../assets/images/facebook-white.svg"
import InstagramIcon from "../../../assets/images/instagram-white.svg"
import LinkedInIcon from "../../../assets/images/linked-in-white.svg"
import TwitterIcon from "../../../assets/images/twitter-white.svg"
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKED_IN_LINK,
  TWITTER_LINK,
} from "../../../constants/appLinks"

import { SocialNetworks } from "./footerSocialNetworksStyles"

const socialNetworks = [
  { url: FACEBOOK_LINK, icon: FacebookIcon, alt: "Facebook" },
  { url: LINKED_IN_LINK, icon: LinkedInIcon, alt: "Linked In" },
  { url: TWITTER_LINK, icon: TwitterIcon, alt: "Twitter" },
  { url: INSTAGRAM_LINK, icon: InstagramIcon, alt: "Instagram" },
]

export const FooterSocialNetworks = () => (
  <SocialNetworks>
    {socialNetworks.map((network, networkIndex) => (
      <a href={network.url} target="_blank" key={networkIndex} rel="noreferrer">
        <img src={network.icon} alt={network.alt} />
      </a>
    ))}
  </SocialNetworks>
)
