import React from "react"

import PropTypes from "prop-types"
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
} from "react-native"

import { Units } from "@theme/"
import Avatar from "@ui/avatar"
import TabView from "@ui/tabViewGameplan"
import { getNameOrUsername } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"

import { ViewProps, ViewStyles } from "./userProfileStyles"

const UserProfileView = ({
  routes,
  sceneMapping,
  userInfo = {},
  isTeeupDisabled,
  handleBack,
  handleSettings,
  backgroundImage,
  isMyProfile,
  userProfile,
  renderScene,
  isContact,
  divRef,
}) => {
  const { username, nickname } = userInfo
  const { hometown, bio, avatar } = userProfile

  return (
    <View style={ViewStyles.container}>
      <ImageBackground
        source={backgroundImage ? { uri: backgroundImage } : null}
        blurRadius={1}
        style={ViewStyles.headerImage}
      />
      <View style={ViewStyles.header}>
        <View style={ViewStyles.headerActions}>
          {/* <TouchableOpacity
                        onPress={handleBack}
                        hitSlop={Units.hitSlop()}
                        style={ViewStyles.headerButton}
                    >
                        <Image
                            source={images.back}
                            style={ViewStyles.headerButtonIcon}
                        />
                    </TouchableOpacity> */}
          {/* {isMyProfile && (
                        <TouchableOpacity
                            hitSlop={Units.hitSlop()}
                            style={ViewStyles.headerButton}
                            onPress={handleSettings}
                            style={{ width: 4, height: 20 }}
                        >
                            <Image style={{height: 24}} source={images.participantSettings} />
                        </TouchableOpacity>
                    )} */}
        </View>
      </View>
      <View style={ViewStyles.contentContainer}>
        <View style={ViewStyles.avatarStatusWrapper}>
          <TouchableOpacity
            hitSlop={Units.hitSlop()}
            onPress={() => {}}
            activeOpacity={1}>
            <Avatar
              imageUrl={avatar}
              size={ViewProps.avatarSize}
              disabled={isTeeupDisabled}
              accesory={
                isContact ? (
                  <View style={ViewStyles.contactIcon}>
                    <Image source={images.profileContact} />
                  </View>
                ) : null
              }
            />
          </TouchableOpacity>
        </View>
        <View style={ViewStyles.contentWrapper}>
          <View style={ViewStyles.userContainer}>
            <View ref={divRef} style={ViewStyles.userContainerInformation}>
              <Text style={ViewStyles.nameText}>
                {getNameOrUsername(userInfo)}
              </Text>
              {(!!username || !!nickname) && (
                <Text style={ViewStyles.additionalText}>
                  @{username || nickname}
                </Text>
              )}
              {!!hometown && (
                <Text style={ViewStyles.additionalText}>{hometown}</Text>
              )}
              {!!bio && <Text style={ViewStyles.shortBioText}>{bio}</Text>}
            </View>
          </View>

          <TabView
            routes={routes}
            sceneMapping={sceneMapping}
            renderScene={renderScene}
            customTabbarStyle={ViewStyles.tabbar}
            customTabLabelStyle={ViewStyles.tabbarLabel}
          />
        </View>
      </View>
    </View>
  )
}

export default UserProfileView

UserProfileView.propTypes = {
  routes: PropTypes.array.isRequired,
  sceneMapping: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  isTeeupDisabled: PropTypes.bool,
  handleBack: PropTypes.func,
  handleSettings: PropTypes.func,
  backgroundImage: PropTypes.string,
  isMyProfile: PropTypes.bool,
  userProfile: PropTypes.object,
  renderScene: PropTypes.func,
  isContact: PropTypes.bool,
}
