import { StyleSheet } from "react-native"

import { Units, AppFonts, AppColors } from "@theme"

export const styles = StyleSheet.create({
  invitationButtonContainer: {
    flexDirection: "row",
    // marginTop: Units.responsiveValue(6),
    // marginBottom: Units.responsiveValue(12),
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "8px",
    marginBottom: "8px",
    marginLeft: "8px",
  },
  buttonsWrapper: {
    alignSelf: "flex-end",
    paddingBottom: "4px",
  },
  // cancelledOrEnded: {
  //   flexDirection: "row",
  //   alignItems: "end",
  //   justifyContent: "flex-end",
  //   marginTop: "8px",
  //   marginBottom: "8px",
  //   marginLeft: "8px",
  // },
  invitationButton: ({ buttonStatus, isGreyOverlay }) => {
    let backgroundColor = AppColors.brand[buttonStatus ? "pink" : "transparent"]

    let paddingHorizontal = Units.responsiveValue(buttonStatus ? 20 : 0)
    let paddingVertical = Units.responsiveValue(buttonStatus ? 11 : 0)
    if (isGreyOverlay) {
      backgroundColor =
        AppColors.brand[buttonStatus ? "transparent" : "grey155"]
      paddingHorizontal = Units.responsiveValue(buttonStatus ? 0 : 27)
    }
    return {
      height: Units.responsiveValue(24),
      borderRadius: Units.responsiveValue(15),
      paddingHorizontal,
      paddingVertical,
      marginRight: Units.responsiveValue(buttonStatus ? 0 : 16),
      alignItems: "center",
      justifyContent: "center",
      backgroundColor,
    }
  },
})

export const invitationButton = ({ buttonStatus, isGreyOverlay }) => {
  let backgroundColor = AppColors.brand[buttonStatus ? "pink" : "transparent"]
  let paddingHorizontal = Units.responsiveValue(buttonStatus ? 20 : 0)
  let paddingVertical = Units.responsiveValue(buttonStatus ? 13 : 0)

  if (isGreyOverlay) {
    backgroundColor = AppColors.brand[buttonStatus ? "transparent" : "grey155"]
    paddingHorizontal = Units.responsiveValue(buttonStatus ? 0 : 27)
  }
  return {
    height: Units.responsiveValue(24),
    borderRadius: Units.responsiveValue(15),
    paddingHorizontal,
    paddingVertical,
    marginRight: Units.responsiveValue(buttonStatus ? 0 : 16),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor,
  }
}

export const invitationButtonText = ({ buttonStatus, isGreyOverlay }) => {
  let color = AppColors.brand[buttonStatus ? "white" : "pink"]
  if (isGreyOverlay) {
    color = AppColors.brand[buttonStatus ? "grey155" : "white"]
  }
  return {
    ...AppFonts.mediumDemibold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
    // fontSize: '12px',
    color,
  }
}
