const ContactState = {
  byTags: {},
  tags: [],
  allContacts: [],
  bookContacts: [],

  // Probably these aren't needed as they're just tags
  recent: [],
  frequent: [],
}

export default ContactState
