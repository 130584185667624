import React from "react"

const ConfirmAction = ({ type, close, onClick }) => {
  return (
    <div className="confirm-action__wrapper" onClick={() => close()}>
      <div className="confirm-action" onClick={e => e.stopPropagation()}>
        <h3 className="confirm-action__title">
          Are you sure you want to {type === 1 ? "leave" : "cancel"} this teeup?
        </h3>
        <div className="confirm-action__buttons">
          <button
            className="confirm-action__cancel"
            type="button"
            onClick={() => close()}>
            No
          </button>
          <button
            className="confirm-action__delete"
            type="button"
            onClick={() => {
              onClick()
              close()
            }}>
            {type === 1 ? "Leave" : "Cancel"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmAction
