import { StyleSheet, Platform } from "react-native"

import { AppColors, AppFonts, Units, AppStyles } from "@theme/"

const isIOS = Platform.OS === "ios"

const ViewStyles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(0.6875),
    paddingBottom: Units.rem(0.9375),
    alignItems: "center",
  },
  rowContainerBorder: {
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  rowContainerBottomBorder: {
    borderBottomWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  rowTitle: {
    ...AppFonts.bigDemibold,
    flex: 1,
    marginRight: Units.rem(2),
  },
  rowPickerTitle: {
    ...AppFonts.mediumDemibold,
    lineHeight: 15,
    color: AppColors.brand.black2,
    flex: 1,
  },
  rowTitleSmall: {
    flex: 0,
  },
  flex: {
    flex: 1,
  },
  rowValue: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.warmGrey2,
  },
  rowValueActive: {
    ...AppFonts.bigDemibold,
    color: AppColors.brand.pink,
  },
  timezoneContainerBottomPadding: {
    paddingBottom: Units.rem(2),
  },
  pickerContainer: {
    alignItems: "center",
    height: isIOS ? Units.responsiveValue(215) : undefined,
  },
  multyPickerContainer: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    height: isIOS ? Units.responsiveValue(215) : undefined,
  },
  multiPickerWheelContainer: {
    // width: '50%',
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  multiPickerWheel: {
    width: "80%",
    height: "100%",
  },
  multiPickerText: {
    width: "20%",
    marginTop: Units.responsiveValue(isIOS ? 0 : -40),
  },
})

const DurationStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    height: isIOS ? Units.responsiveValue(215) : undefined,
    width: "100%",
    marginTop: Units.rem(1.25),
  },
  wheelWithHoursContainer: {
    width: "20%",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  wheelContainer: {
    width: "60%",
    height: "100%",
  },
  wheel: {
    width: null,
    height: Units.responsiveValue(150),
  },
  durationHoursContainer: {
    marginTop: Units.responsiveValue(isIOS ? 0 : -32),
    marginLeft: Units.responsiveValue(-10),
  },
  durationHoursText: {
    fontSize: 15,
    color: AppColors.brand.black2,
  },
  durationBorderContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: isIOS ? Units.rem(5.375) : Units.rem(2.625),
    height: isIOS ? Units.rem(2.6875) : Units.rem(1.9375),
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: isIOS ? AppColors.brand.warmGrey2 : AppColors.brand.black2,
  },
})

const TimeRangeStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  rowContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingHorizontal: Units.rem(1),
    alignItems: "center",
    justifyContent: "space-between",
    height: Units.responsiveValue(44),
    width: "100%",
  },
  pickerContainer: {
    width: "50%",
    alignItems: "center",
  },
  wheelContainer: {
    width: "90%",
    alignItems: "center",
  },
  dateWheelContainer: {
    width: "100%",
  },
  dateWheelContainerAndroid: {
    width: null,
    flex: 3,
    height: Units.responsiveValue(150),
  },
})

const TimeDurationStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  rowContainer: {
    flexDirection: "row",
    backgroundColor: "white",
    paddingHorizontal: Units.rem(1),
    alignItems: "center",
    justifyContent: "space-between",
    height: Units.responsiveValue(44),
    width: "100%",
    marginBottom: 10,
  },
  pickerContainer: {
    width: "50%",
    alignItems: "center",
  },
  wheelContainer: {
    width: "100%",
    alignItems: "center",
  },
  dateWheelContainer: {
    width: "100%",
  },
  dateWheelContainerAndroid: {
    width: null,
    flex: 3,
    height: Units.responsiveValue(150),
  },
  durationWheelContainer: {
    width: "100%",
    height: "100%",
  },
  durationWheel: {
    width: "100%",
    height: Units.responsiveValue(150),
  },
  durationHoursContainer: {
    // marginTop: Units.responsiveValue(isIOS ? 0 : -2),
    // marginLeft: Units.responsiveValue(-18),
    position: "absolute",
    top: "44%",
    left: "65%",
  },
  durationHoursText: {
    fontSize: 15,
    color: AppColors.brand.black2,
  },
  disabledDurationHoursText: {
    fontSize: 15,
    color: AppColors.brand.warmGrey2,
  },
  durationPickerTitle: {
    ...AppFonts.mediumDemibold,
    lineHeight: 15,
    color: AppColors.brand.black2,
  },
  durationDisableOverlay: {
    ...StyleSheet.absoluteFill,
    zIndex: 2,
  },
  switchStyle: {
    width: Units.responsiveValue(51),
    height: Units.responsiveValue(31),
    borderRadius: Units.responsiveValue(15.5),
    padding: Units.responsiveValue(1),
  },
  switchCircle: {
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(28),
    borderRadius: Units.responsiveValue(14),
  },
})

const ViewProps = {
  selectizeTintColor: AppColors.brand.pink,
  inputHintColor: AppColors.brand.warmGrey2,
  navButtonColor: AppColors.brand.pink,
}

const OverlayContainerStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.whiteGrey,
    paddingBottom: 0,
  },
  topOverlayContainer: {
    flex: 1,
    backgroundColor: AppColors.brand.grey40,
  },
  topContainer: {
    backgroundColor: "white",
  },
  topButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.lightWhite,
  },
  buttonText: {
    ...AppFonts.mediumMedium,
    color: AppColors.brand.pink,
  },
  disabledText: {
    opacity: 0.3,
  },
})

const ZoneStyles = StyleSheet.create({
  headersContainer: {
    ...AppStyles.absolute,
  },
  headersContainerInitial: {},
  bottomPadding: {
    paddingBottom: Units.responsiveValue(20),
  },
  splitterContainer: {
    ...AppStyles.absolute,
    alignItems: "center",
    marginTop: Units.responsiveValue(4),
  },
  splitter: {
    flex: 1,
    alignItems: "center",
    backgroundColor: AppColors.brand.lightGrey,
    width: Units.responsiveValue(6),
  },
  innerSplitter: {
    backgroundColor: AppColors.brand.pink,
    width: Units.responsiveValue(2),
    flex: 1,
  },
  splitterDarkTheme: {
    backgroundColor: AppColors.brand.white70,
    width: 2,
    flex: 1,
    marginTop: -2,
  },
  durationSplitter: {
    backgroundColor: AppColors.brand.pink_16,
    flex: 1,
  },
  durationSplitterDarkTheme: {
    backgroundColor: AppColors.brand.white20,
  },
  durationSplitterBorder: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 6,
    backgroundColor: AppColors.brand.lightGrey,
    alignItems: "center",
  },
  durationSplitterBorderDarkTheme: {
    width: 0,
    backgroundColor: "red",
  },
  durationSplitterBorderDarkThemeSmall: {
    position: "absolute",
    top: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.white50,
    width: 1,
  },
  durationSplitterLeftBorderSmall: {
    left: -0.5,
  },
  durationSplitterRightBorderSmall: {
    right: -0.5,
  },
  durationSplitterLeftBorder: {
    left: 0,
  },
  durationSplitterRightBorder: {
    right: 0,
  },
  leftTriangle: {
    position: "absolute",
    top: 0,
    left: 3,
  },
  leftTriangleDarkTheme: {
    left: 1.5,
  },
  leftTriangleBottom: {
    position: "absolute",
    bottom: 0,
    left: 3,
  },
  leftTriangleDarkThemeBottom: {
    left: 1.5,
  },
  rightTriangle: {
    position: "absolute",
    top: 0,
    right: 3,
  },
  rightTriangleDarkTheme: {
    right: 1.5,
  },
  rightTriangleBottom: {
    position: "absolute",
    bottom: 0,
    right: 3,
  },
  rightTriangleDarkThemeBottom: {
    right: 1.5,
  },
  durationSplitterInner: {
    width: 3,
    backgroundColor: AppColors.brand.pink,
    flex: 1,
  },
  durationSplitterInnerDarkTheme: {
    backgroundColor: AppColors.brand.white70,
  },
  headerContainer: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(0.75),
    paddingTop: Units.rem(1.25),
    paddingBottom: Units.rem(0.375),
    alignItems: "flex-end",
  },
  headerNameContainer: {
    flexDirection: "column",
    paddingHorizontal: Units.rem(0.5),
  },
  headerNameContainerLightBackground: {
    backgroundColor: AppColors.brand.lightGrey_80,
    paddingRight: 12,
  },
  headerNameContainerBackground: {
    backgroundColor: AppColors.brand.black,
    borderColor: "transparent",
    borderRadius: Units.responsiveValue(8),
  },
  headerAvatarMargin: {
    marginLeft: -Units.rem(1),
  },
  headerName: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
    lineHeight: Units.rem(1.125),
  },
  headerDate: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
    lineHeight: Units.rem(1.25),
  },
  headerTimezone: {
    color: "black",
    ...AppFonts.mediumDemibold,
    lineHeight: Units.rem(1.125),
  },
  headerTimezoneLight: {
    color: "white",
  },
  itemContainer: {
    flexDirection: "column",
    paddingHorizontal: Units.rem(0.5),
    paddingTop: Units.rem(0.4375),
    paddingBottom: Units.rem(0.5625),
    borderRightWidth: 1,
    borderColor: AppColors.brand.lightGrey,
  },
  itemContainerNight: {
    backgroundColor: AppColors.brand.purpleBlue,
  },
  itemContainerDaytime: {
    backgroundColor: AppColors.brand.lightYellow,
  },
  itemContainerInBetween: {
    backgroundColor: AppColors.brand.lightOrange,
  },
  itemTitle: {
    ...AppFonts.bigDemibold,
    lineHeight: Units.rem(1.375),
  },
  itemTextNight: {
    color: AppColors.brand.white,
  },
  itemTextDaytime: {
    color: AppColors.brand.black2,
  },
  itemTextInBetween: {
    color: AppColors.brand.black2,
  },
  itemSubtitle: {
    ...AppFonts.smallDemibold,
    lineHeight: Units.rem(1.125),
  },
  timeContainer: {
    paddingHorizontal: Units.rem(0.625),
    paddingBottom: Units.rem(0.1875),
    borderRadius: Units.rem(0.5),
    marginTop: Units.rem(0.5),
    backgroundColor: AppColors.brand.pink,
  },
  timeText: {
    ...AppFonts.mediumDemibold,
    color: "white",
  },
  triangle: {
    width: 12,
    height: 8,
    marginBottom: -2,
    tintColor: AppColors.brand.pink,
  },
  triangleBottom: {
    width: 12,
    height: 8,
    marginBottom: 2,
    tintColor: AppColors.brand.pink,
    transform: [{ rotateX: "180deg" }],
  },
  triangleDarkTheme: {
    width: 12,
    height: 8,
    marginTop: 4,
    tintColor: AppColors.brand.white70,
  },
  triangleDarkThemeBottom: {
    width: 12,
    height: 8,
    marginTop: -1,
    tintColor: AppColors.brand.white70,
    transform: [{ rotateX: "180deg" }],
  },
  dateContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  itemSeparator: {
    width: 1,
  },
  invisible: {
    opacity: 0,
  },
  durationMargin: {
    marginRight: 12,
  },
  daytimeIcon: {
    position: "absolute",
    top: 5.5,
    right: 5.5,
  },
})

const ZoneProps = {
  avatarSize: Units.rem(2),
}

const DateStyles = StyleSheet.create({
  itemContainer: {
    padding: Units.rem(1),
    flexDirection: "column",
    alignItems: "center",
  },
  overlay: {
    ...AppStyles.absolute,
    alignItems: "center",
  },
  overlayMarker: {
    backgroundColor: AppColors.brand.pink,
    flex: 1,
    borderRadius: Units.rem(0.5),
  },
})

const HourStyles = StyleSheet.create({})

export {
  ViewStyles,
  OverlayContainerStyles,
  DateStyles,
  HourStyles,
  ZoneStyles,
  ZoneProps,
  ViewProps,
  TimeDurationStyles,
  TimeRangeStyles,
  DurationStyles,
}
