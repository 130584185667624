import React from "react"

import { connect } from "react-redux"

import "./Auth0ContentPage.scss"
import { Auth0Errors } from "../../components/Auth0Errors"
import { NewSignUpEmail } from "../../components/NewSignUpEmail"
import { Auth0ErrorState } from "../../contexts/Auth0ErrorContext"
import { SignUpEmailState } from "../../contexts/SignUpEmailContext"

const Auth0ContentPage = ({ isRegisterSsoNames }) => (
  <>
    <Auth0ErrorState>
      {isRegisterSsoNames ? (
        <SignUpEmailState>
          <NewSignUpEmail />
        </SignUpEmailState>
      ) : (
        <Auth0Errors />
      )}
    </Auth0ErrorState>
  </>
)

const mapStateToProps = ({ user }) => ({
  isRegisterSsoNames: user.isRegisterSsoNames,
})

export default connect(mapStateToProps)(Auth0ContentPage)
