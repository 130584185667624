import styled, { css } from "styled-components"

export const PlanWrapper = styled.div`
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-bottom: 10px;
    `};
`

export const PlanBlock = styled.div`
  width: 100%;
  background-color: var(--lightGray);
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 0 10px 12px;
  position: relative;
  margin: 4px 0;
`

export const PendingWhenIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`

export const DecidedLabel = styled.div`
  background-color: var(--lightMint);
  border: 1px solid var(--mint);
  border-radius: 4px;
  padding: 0 8px;
  ${({ $isAbsolute }) =>
    $isAbsolute &&
    css`
      position: absolute;
      top: 12px;
      right: 12px;
    `};
`

export const ReactionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ReactionsValues = styled.div`
  background-color: #f5f5f5;
  border-radius: 50px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3px;
  line-height: 9px;
  padding: 5px 0 5px 5px;
  margin-left: 7px;
`

export const SuggestionsListEl = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  overflow: hidden;
`

export const SuggestionItemEl = styled.li`
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 14px;
  margin-right: 8px;
`

export const SuggestionItemIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 4px;
`

export const PlanHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DecidedTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const DecidedWhereIcon = styled.img`
  width: 21px;
  height: 21px;
  margin-right: 7px;
`
