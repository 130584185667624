import { useEffect, useState } from "react"

import { getTimezone } from "countries-and-timezones"
import { countryCodeEmoji } from "country-code-emoji"

import { SvgIcon } from "@components/common"
import dayjs from "@utils/dayjs"

import ParticipantIcon from "../../Participants/ParticipantItem/ParticipantIcon"
import TimeWheel from "../TimeWheel"

import "./index.scss"
import { UNKNOWN_TIMEZONE } from "@utils/peopleUtils"
import { Dayjs } from "dayjs"

const parseParticipantsLabel = (participantsCount, firstParticipantName) => {
  const remainingParticipants = participantsCount - 1
  const additionalLabel =
    remainingParticipants > 0
      ? `${firstParticipantName} and ${remainingParticipants} more`
      : firstParticipantName
  return additionalLabel
}

const getParticipantsLabel = (participants, user) => {
  const userIndex = participants.findIndex(item => item.id === user.id)

  if (userIndex === -1) {
    const firstName =
      participants?.[0]?.name?.split(" ")?.[0] ||
      participants?.[0]?.matchingContact?.split("@")[0]
    return parseParticipantsLabel(participants.length, firstName)
  } else {
    return parseParticipantsLabel(participants.length, "You")
  }
}

const getGroupTimezoneInfo = timezone => {
  try {
    if (timezone === UNKNOWN_TIMEZONE) {
      return {
        cityName: UNKNOWN_TIMEZONE,
        countryCode: "",
      }
    }

    const countryCode = getTimezone(timezone)?.countries[0] || ""

    return {
      cityName: timezone?.split("/")?.[1],
      countryCode,
    }
  } catch (error) {
    console.error("Failed to get timezone info", { timezone, error })
    return {
      cityName: "UTC",
      countryFlag: "🌐",
    }
  }
}

const TimezoneItem = ({
  peopleByTimezone,
  user,
  getBoxInfo,
  isBoxesHidden,
  currentDate,
  isAllDay,
  duration,
}) => {
  const [currentTime, setCurrentTime] = useState(null)
  const [groupTimezoneInfo, setGroupTimezoneInfo] = useState({
    countryCode: "",
    countryFlag: "",
  })
  const isUnknownTimezone = groupTimezoneInfo?.cityName === UNKNOWN_TIMEZONE
  const isCountryFlagShown = groupTimezoneInfo?.countryFlag || isUnknownTimezone

  useEffect(() => {
    const groupTimezone = peopleByTimezone[0].timezone
    const date = currentDate ? dayjs(currentDate) : dayjs()

    const isUnknownTimezone = groupTimezone === UNKNOWN_TIMEZONE
    const currentTime = date.tz(isUnknownTimezone ? "UTC" : groupTimezone)

    const timezoneGroupInfo = getGroupTimezoneInfo(groupTimezone)

    setGroupTimezoneInfo(timezoneGroupInfo)
    setCurrentTime(currentTime)
  }, [peopleByTimezone])

  useEffect(() => {
    if (!currentDate) return
    const groupTimezone = peopleByTimezone[0].timezone
    const isUnknownTimezone = groupTimezone === UNKNOWN_TIMEZONE

    setCurrentTime(currentDate.tz(isUnknownTimezone ? "UTC" : groupTimezone))
  }, [currentDate])

  if (!isBoxesHidden && isUnknownTimezone) return false

  return (
    <div>
      <div className="people-timezone-container">
        <div className="people-timezone">
          {peopleByTimezone.map(participant => (
            <div key={participant.id}>
              <ParticipantIcon
                avatar={participant.avatar}
                defaultAvatar={
                  participant?.name ||
                  participant?.username ||
                  participant?.matchingContact
                }
              />
            </div>
          ))}
        </div>

        <div className="people-timezone-info-container">
          <div className="people-timezone-info">
            <div>{getParticipantsLabel(peopleByTimezone, user)}</div>
            <div>
              {isCountryFlagShown ? (
                groupTimezoneInfo.countryFlag
              ) : (
                <img
                  src={`https://flagcdn.com/48x36/${groupTimezoneInfo.countryCode.toLowerCase()}.png`}
                />
              )}{" "}
              {groupTimezoneInfo.cityName}
            </div>
          </div>

          {isUnknownTimezone ? (
            <div></div>
          ) : (
            <div>
              <div className="people-timezone-time">
                {isAllDay ? (
                  <>
                    <div className="title">
                      {currentTime?.startOf("day")?.format("h:mm A")} -{" "}
                      {currentTime?.endOf("day")?.format("h:mm A")}
                    </div>
                    <div className="subtitle">
                      {currentTime?.format("ddd MMM D")}
                    </div>
                  </>
                ) : duration ? (
                  <>
                    <div className="title">
                      {currentTime?.format("h:mm A")} -{" "}
                      {currentTime
                        ?.add(+duration?.id, "minute")
                        .format("h:mm A")}
                    </div>
                    <div className="subtitle">
                      {currentTime?.format("ddd MMM D")}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title">{currentTime?.format("h:mm A")}</div>
                    <div className="subtitle">
                      {currentTime?.format("ddd MMM D")}
                    </div>
                  </>
                )}
              </div>
              <div>
                {currentTime && (
                  <SvgIcon name={getBoxInfo(currentTime.format("ha")).icon} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {!isBoxesHidden && !isUnknownTimezone && (
        <TimeWheel middleDate={currentTime} getBoxInfo={getBoxInfo} />
      )}
    </div>
  )
}

export default TimezoneItem
