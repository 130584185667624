import { DateRange } from "@mui/lab"
import { Dayjs } from "dayjs"

import { SvgIcon } from "@components/common"
import Pill from "@components/ui/Pill"
import YourSuggestions from "@components/ui/YourSuggestions"
import { getHoursData } from "@utils/suggestionUtils"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"
import { GeneralDateSuggestion } from "types/suggestions"

const YourSuggestionsContainer = ({ onSave }) => {
  const currentDate = useSuggestionGeneralDateStore(
    state => state.currentDate
  ) as DateRange<Dayjs>
  const currentLabel = useSuggestionGeneralDateStore(
    state => state.currentLabel
  )
  const currentTime = useSuggestionGeneralDateStore(state => state.currentTime)
  const setCurrentDate = useSuggestionGeneralDateStore(
    state => state.setCurrentDate
  )
  const addSuggestion = useSuggestionGeneralDateStore(
    state => state.addSuggestion
  )
  const removeSuggestion = useSuggestionGeneralDateStore(
    state => state.removeSuggestion
  )
  const setCurrentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const duration = useSuggestionDurationStore(state => state.duration)
  const setDuration = useSuggestionDurationStore(state => state.setDuration)
  const clearDurationState = useSuggestionDurationStore(
    state => state.clearState
  )
  const suggestions = useSuggestionGeneralDateStore(state => state.suggestions)
  const currentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.currentActiveSuggestionIndex
  )

  const setCurrentLabel = useSuggestionGeneralDateStore(
    state => state.setCurrentLabel
  )
  const setCurrentTime = useSuggestionGeneralDateStore(
    state => state.setCurrentTime
  )
  const isShown = !!currentDate?.[0] || suggestions.length > 0

  const onAddAnotherClick = () => {
    // if (currentActiveSuggestionIndex !== -1) {
    //   setCurrentActiveSuggestionIndex(-1)
    //   clearDurationState()
    // }

    const currentSuggestion: GeneralDateSuggestion = {
      date: currentDate,
      label: currentLabel,
      duration: +duration.id,
    }
    if (currentTime) {
      currentSuggestion.time = currentTime
    }
    if (suggestions.length === 0) {
      addSuggestion(currentSuggestion)
    }
    addSuggestion(currentSuggestion)
    setCurrentActiveSuggestionIndex(suggestions.length)
    clearDurationState()
  }

  const handleSuggestionClick = (
    pill: GeneralDateSuggestion,
    index: number
  ) => {
    setCurrentDate(pill.date)
    setCurrentLabel(pill.label)
    pill.time && setCurrentTime(pill.time)
    setCurrentActiveSuggestionIndex(index)

    const duration = getHoursData().findLast(
      hour => hour.id === pill.duration.toString()
    )
    duration && setDuration(duration)
  }

  const handleSuggestClick = (isDecided: boolean) => {
    if (suggestions.length > 0) {
      onSave(suggestions, isDecided, true)
    } else {
      onSave(
        [
          {
            date: currentDate,
            label: currentLabel,
            time: currentTime,
            duration: +duration.id,
          },
        ],
        isDecided,
        true
      )
    }
  }

  return (
    <YourSuggestions
      hideDecideButton
      isShown={isShown}
      suggestions={suggestions}
      onAddAnotherClick={onAddAnotherClick}
      onDecideClick={() => handleSuggestClick(true)}
      onSuggestClick={() => handleSuggestClick(false)}
      defaultPill={
        <Pill isSelected>
          <span>
            {currentLabel ? (
              currentLabel
            ) : (
              <>{currentDate?.[0]?.format("ddd, MMM DD")}</>
            )}
          </span>
          {currentTime?.label && <span>· {currentTime.label}</span>}
        </Pill>
      }
      // @ts-ignore
      renderPill={(pill: GeneralDateSuggestion, index: number) => (
        <Pill
          onClick={() => handleSuggestionClick(pill, index)}
          isSelected={index === currentActiveSuggestionIndex}>
          <span>
            {pill.label ? (
              pill.label
            ) : (
              <>{pill.date?.[0]?.format("ddd, MMM DD")}</>
            )}
          </span>
          {pill.time?.label && <span>· {pill.time.label}</span>}

          {suggestions.length > 1 && (
            <SvgIcon name="cross" onClick={() => removeSuggestion(index)} />
          )}
        </Pill>
      )}
    />
  )
}

export default YourSuggestionsContainer
