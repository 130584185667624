import React from "react"

import PropTypes from "prop-types"

import { Wrapper, Progress } from "./progressLineStyles"

export const ProgressLine = ({ percent }) => (
  <Wrapper>
    <Progress $percent={percent} />
  </Wrapper>
)

ProgressLine.propTypes = {
  percent: PropTypes.number,
}
