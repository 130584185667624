import { useAuth0 } from "@auth0/auth0-react"

import { loginProviders } from "@config/enums"

import { SYNC_CONTACTS_MODAL } from "../constants"

export const useAccessToken = ({
  setIsGoogleLoading,
  setIsAppleLoading,
  setModalType,
}) => {
  const { getAccessTokenWithPopup } = useAuth0()

  const getAccessToken = ({ connection, connection_scope }) => {
    return getAccessTokenWithPopup({
      connection,
      connection_scope,
      prompt: "consent",
      access_type: "offline",
    })
      .then(res => res)
      .catch(() => {
        switch (connection) {
          case loginProviders.google:
            setIsGoogleLoading(false)
            break
          case loginProviders.apple:
            setIsAppleLoading(false)
            break
          default:
            break
        }

        setModalType(SYNC_CONTACTS_MODAL.CANCELED)
      })
  }

  return getAccessToken
}
