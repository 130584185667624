import { SnackbarProps } from "@mui/material/Snackbar"
import { create } from "zustand"

interface SnackbarStore {
  snackbarConfig: SnackbarProps
  setSnackbarConfig: (config: Partial<SnackbarProps>) => void
  onSnackbarClose: () => void

  // TODO: add snackbar actions
}

export const useSnackbarStore = create<SnackbarStore>()((set, get) => ({
  snackbarConfig: {
    message: "",
    severity: "info",
    open: false,
    autoHideDuration: 3000,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    onClose: () => get().onSnackbarClose(),
  },
  setSnackbarConfig: config =>
    set(state => ({ snackbarConfig: { ...state.snackbarConfig, ...config } })),
  onSnackbarClose: () =>
    set(state => ({
      snackbarConfig: {
        ...state.snackbarConfig,
        open: false,
        autoHideDuration: 3000,
      },
    })),
}))
