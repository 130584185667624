import styled, { css } from "styled-components"

import { NSubtitleS, NFontWeight } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  ${({ $isInner }) => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 7px;
    padding: 0 ${$isInner ? 0 : "15px"};
    position: relative;
  `};
`

export const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $isOwn, $isPublished, $isInner }) =>
    $isOwn &&
    !$isPublished &&
    css`
      padding-right: ${$isInner ? "6%" : "5%"};
    `};
  ${({ $isConflict }) =>
    $isConflict &&
    css`
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2px;
    `};
`

export const Text = styled(NSubtitleS)`
  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: ${NFontWeight.semiBold};
    `};
`
