import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"
import {
  NFontWeight,
  NSubtitle,
  NSubtitleS,
} from "assets/styles/newTypographyStyles"

import { AVATARS_SIZES, RSVPBadgeType } from "./constants"

export const PreviewContent = styled.section<any>`
  ${({ $isSmallSize }) => css`
    width: 744px;
    min-height: calc(100% - 80px);
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto 0 auto;
    position: relative;

    ${$isSmallSize &&
    css`
      min-height: calc(100% - 20px);
      margin: 20px auto 0 auto;
    `};

    @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
      width: 100%;
    }
  `};
`

export const PreviewBackground = styled.div`
  background-color: #fff;
  width: 100%;
  height: calc(100% - 47px);
  border-radius: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    border-radius: 0;
  }
`

export const RestContentWrapper = styled.div<any>`
  width: 100%;
  background-color: var(--lightGray);
  flex-grow: 1;
  z-index: 2;
  ${({ $isAuthed }) =>
    $isAuthed &&
    css`
      padding-bottom: 170px;
    `};
`

export const ParticipantAvatar = styled.div<any>`
  ${({ size = AVATARS_SIZES.SMALL }) =>
    size === AVATARS_SIZES.MINI
      ? css`
          width: 32px;
          height: 32px;
        `
      : size === AVATARS_SIZES.SMALL_S
      ? css`
          width: 40px;
          height: 40px;
        `
      : size === AVATARS_SIZES.SMALL
      ? css`
          width: 48px;
          height: 48px;
        `
      : size === AVATARS_SIZES.MEDIUM
      ? css`
          width: 64px;
          height: 64px;
        `
      : css`
          width: 128px;
          height: 128px;
        `};
  background-color: var(--white2);
  ${({ $isShadow }) =>
    $isShadow &&
    css`
      box-shadow: var(--grayShadow);
    `};
  ${({ $isBorder, $isStepper }) =>
    $isBorder &&
    css`
      border: solid var(--white);
      ${$isStepper
        ? css`
            border-width: 1px;
          `
        : css`
            border-width: 3px;
          `};
    `}
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const MainInfo = styled.section<{ $isStepper: boolean }>`
  width: 95%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  ${({ $isStepper }) =>
    $isStepper &&
    css`
      flex-direction: row;

      @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
        margin-left: 20px;
      }
    `};
`

export const MainInfoParticipantsLabel = styled.div`
  margin-top: 8px;
  color: var(--gray5);
`

export const TextWrapper = styled.div<{ $isStepper: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $isStepper }) =>
    !$isStepper &&
    css`
      margin: 10px 0;
    `};

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    flex-direction: column-reverse;
  }
`

export const Text = styled(NSubtitle)`
  color: var(--gray5);
  text-align: center;
`

export const TitleWrapper = styled.div<{ $isStepper: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $isStepper }) =>
    $isStepper
      ? css`
          align-items: flex-start;
          margin-left: 25px;
        `
      : css`
          margin: 20px 0 10px 0;
        `};
`

export const PeopleBlock = styled.section`
  width: 100%;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 7px 16px;
  margin: 10px 0;
  z-index: 2;
`

export const PeopleTitle = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
`

export const ParticipantsText = styled(NSubtitleS)`
  color: var(--gray4);
  margin-left: 5px;
`

export const SentToText = styled(NSubtitleS)`
  color: var(--gray5);
  text-align: center;
  position: relative;
  z-index: 20;
  margin: 10px 0 16px 0;
  display: block;
`

export const ParticipantList = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 5px;
  cursor: pointer;
`

export const ParticipantEl = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const OrganiserCrown = styled.img`
  width: 11px;
  height: 11px;
  position: absolute;
  top: -1px;
  left: -1px;
`

export const StatusIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`

export const GamePlanWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  z-index: 2;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  box-shadow: var(--blackShadow2);
  box-sizing: border-box;
  display: grid;
  row-gap: 9px;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 40px;
  z-index: 2;
`

export const MockParticipantsList = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 7px;
`

export const Warning = styled.div`
  width: 100%;
  background-color: var(--white2);
  border: 1px solid var(--lightYellow);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 20px 0 10px 0;
  z-index: 2;
`

export const WarningImg = styled.img`
  margin-right: 10px;
`

export const Badge = styled.div<any>`
  ${({ type }) => css`
    width: 94%;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 11px;
    margin: 20px auto;
    z-index: 1;

    ${type === RSVPBadgeType.SUCCESS &&
    css`
      background-color: var(--lightMint3);
      border: 1px solid var(--lightMint2);
      color: var(--green);
    `};

    ${type === RSVPBadgeType.WARNING &&
    css`
      background-color: var(--white2);
      border: 1px solid var(--lightYellow);
      color: var(--orange);
    `};
  `};
`

export const BadgeInfo = styled.div`
  display: flex;
  align-items: center;
`

export const BadgeIcon = styled.img`
  margin-right: 10px;
`
