import { Fragment, useEffect, useState } from "react"

import { TextField } from "@components/ui/TextField"
import {
  getSuggestionOnlineOptions,
  platformLinkPatterns,
} from "@utils/suggestionUtils"
import { useSuggestionOnlineStore } from "stores/suggestions/suggestionOnlineStore"
import { Platform as IPlatform, URL_TYPES } from "types/suggestions"

import Search from "../../Autocomplete"
import Option from "../../Autocomplete/option"

import "./index.scss"
import ConfirmCopyLink from "../../Modals/ConfirmCopyLink"

import Platform from "./Platform"

const meetingPlatforms = getSuggestionOnlineOptions()

const ChoosePlatform = () => {
  const setCurrentPlatform = useSuggestionOnlineStore(
    state => state.setCurrentPlatform
  )
  const suggestions = useSuggestionOnlineStore(state => state.suggestions)
  const addSuggestion = useSuggestionOnlineStore(state => state.addSuggestion)
  const setIsYourSuggestionsShown = useSuggestionOnlineStore(
    state => state.setIsYourSuggestionsShown
  )
  const setCurrentUrlAddress = useSuggestionOnlineStore(
    state => state.setCurrentUrlAddress
  )
  const setIsConfirmLinkWasShown = useSuggestionOnlineStore(
    state => state.setIsConfirmLinkWasShown
  )
  const isConfirmLinkWasShown = useSuggestionOnlineStore(
    state => state.isConfirmLinkWasShown
  )
  const [meetingPlatformsOptions, setMeetingPlatformOptions] = useState(
    getSuggestionOnlineOptions()
  )
  const [searchValue, setSearchValue] = useState("")
  const [isConfirmCopyLinkOpen, setIsConfirmCopyLinkOpen] = useState(false)
  const [tempPlatform, setTempPlatform] = useState<IPlatform | null>(null)
  const [tempUrlAddress, setTempUrlAddress] = useState<string | null>(null)

  useEffect(() => {
    checkClipboard()
  }, [])

  const checkClipboard = async () => {
    if (isConfirmLinkWasShown) return

    try {
      const clipboardData = await navigator.clipboard.readText()
      const platformPattern = Object.entries(platformLinkPatterns).find(
        ([key, pattern]) => pattern?.test(clipboardData)
      )
      const platformId = platformPattern?.[0]
      const platform = meetingPlatforms.find(
        option => option.id?.toString() === platformId
      )

      if (platform) {
        setTempPlatform(platform)
        setTempUrlAddress(clipboardData)
        setIsConfirmCopyLinkOpen(true)
        setIsConfirmLinkWasShown(true)
      }
    } catch (error) {
      console.error("Failed to read clipboard contents:", error)
    }
  }

  const handlePlatformSelect = (platform: IPlatform) => {
    if (suggestions.length > 0) {
      addSuggestion({
        label: platform.label,
        iconName: platform.iconName,
        urlType: URL_TYPES.URL,
        urlAddress: "",
        additionalInformation: "",
      })
    }
    setCurrentPlatform(platform)
    setIsYourSuggestionsShown(true)
  }

  const handleSearch = (_, value: string) => {
    handleCustomOption(value)

    setSearchValue(value)
  }

  const handleCustomOption = (value: string) => {
    if (!value) setMeetingPlatformOptions(meetingPlatforms)

    const isCustomAdded = meetingPlatformsOptions.some(item => item.isCustom)
    const copyPlatforms = [...meetingPlatformsOptions]

    if (value && isCustomAdded) {
      copyPlatforms[0].label = `“${value}”`
      setMeetingPlatformOptions(copyPlatforms)
    } else if (value && !isCustomAdded) {
      copyPlatforms.unshift({
        label: `“${value}”`,
        iconName: "",
        isCustom: true,
      })
      setMeetingPlatformOptions(copyPlatforms)
    }
  }

  const handleConfirmCopyLink = () => {
    setCurrentPlatform(tempPlatform)
    if (tempUrlAddress) {
      setCurrentUrlAddress(tempUrlAddress)
    }
    setIsConfirmCopyLinkOpen(false)
    setTempPlatform(null)
  }

  return (
    <div className="suggestion-where-online">
      <ConfirmCopyLink
        open={isConfirmCopyLinkOpen}
        onClose={() => setIsConfirmCopyLinkOpen(false)}
        onConfirm={handleConfirmCopyLink}
        tempPlatform={tempPlatform}
      />

      <h4>Select Meeting Platform</h4>

      <Search
        options={meetingPlatformsOptions}
        inputValue={searchValue}
        onInputChange={handleSearch}
        renderInput={params => (
          <TextField {...params} placeholder="Suggest Where" />
        )}
        renderOption={(element, option) => (
          <Fragment key={option.label}>
            <Option
              option={option}
              onSelect={handlePlatformSelect}
              isCustom={option.isCustom}
              isArrowShown={!option.isCustom}
            />
          </Fragment>
        )}
      />

      <ul className="suggestion-where-platform-container">
        {meetingPlatforms.map(platform => (
          <Fragment key={platform.label}>
            <Platform platform={platform} onClick={handlePlatformSelect} />
          </Fragment>
        ))}
      </ul>
    </div>
  )
}

export default ChoosePlatform
