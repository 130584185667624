import React from "react"

import PropTypes from "prop-types"

import { Label, Input, Checkmark } from "./checkboxElStyles"

export const CheckboxEl = ({ label, name, value, onChange }) => (
  <Label className="checkbox-el">
    {label}
    <Input
      type="checkbox"
      className="checkbox-el__input"
      name={name}
      checked={value}
      onChange={onChange}
    />

    <Checkmark className="checkbox-el__checkmark" />
  </Label>
)

CheckboxEl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
