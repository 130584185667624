import { useMemo, useState } from "react"

import dayjs from "dayjs"

import { MINUTES_DAY_PARTS } from "@contexts/TimeWheelContext"
import {
  getDateTitle,
  getDisabledDate,
  getHourInfo,
  getSpecialTzCurrentHour,
  setDayPart,
} from "contexts/TimeWheelContext/helpers"

import { TimeWheelHour } from "../atoms"
import { TimeWheelDate } from "../molecules"

export const HourItem = props => {
  const [lastIndex, setLastIndex] = useState(0)
  let { index, style, zones, parsedCurrentPeriod } = props

  return (
    <div style={style} key={index}>
      {zones.map(zone => {
        if (lastIndex < index) {
          setLastIndex(index)
        } else {
          index = lastIndex
        }
        let currentHour = parsedCurrentPeriod[index * MINUTES_DAY_PARTS]
        let tzNowDate = dayjs().format()
        if (!zone.isMyTZ) {
          currentHour = getSpecialTzCurrentHour(currentHour, zone, zones[0])
          tzNowDate = getSpecialTzCurrentHour(tzNowDate, zone, zones[0])
        }
        const hourInfo = getHourInfo(currentHour)
        let hourClass = setDayPart(currentHour)
        if (getDisabledDate({ hour: currentHour, nowDate: tzNowDate })) {
          hourClass = "disabled"
        }
        const dateTitleInfo = getDateTitle({
          currentHour,
          parsedCurrentPeriod,
          index,
        })
        return (
          <div className="hours-line" key={zone.timezoneName}>
            <TimeWheelHour hourClass={hourClass} hourInfo={hourInfo} />

            {zone.isMyTZ && (
              <div>
                <TimeWheelDate
                  {...props}
                  dateTitleInfo={dateTitleInfo}
                  currentHour={currentHour}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
