import { useMemo } from "react"

import { APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from "@constants/appLinks"

export const useAppLink = () => {
  return useMemo(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // AppStore link as default
    let appLink = APP_STORE_APP_LINK
    let isMobile = false
    let isTablet = false

    // PlayMarket link
    if (/android/i.test(userAgent)) {
      appLink = GOOGLE_PLAY_APP_LINK
    }

    if (window.screen.width < 451) {
      isMobile = true
    }

    if (window.screen.width < 768) {
      isTablet = true
    }

    return { isMobile, isTablet, appLink }
  }, [])
}
