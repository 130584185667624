import propTypes from "prop-types"
import { View, FlatList } from "react-native"

import EmptyConversation from "../EmptyConversation"

import { styles } from "./PinnedMessagesScreenStyles"
import "./pinnedMessagesScreenComponent.scss"

const keyExtractor = (item, index) => `${item.id} + ${index}`

const PinnedMessagesScreenComponent = ({
  messages,
  onMessageItemRender,
  suggestions,
  insideTabContainer,
  newDesign,
}) => {
  if (messages.length === 0)
    return (
      <EmptyConversation
        iconName="noPinned"
        title="Nothing pinned"
        description="Pin your messages or suggestions"
      />
    )

  return (
    <View
      style={[
        styles.container,
        newDesign && { backgroundColor: "transparent" },
      ]}>
      <FlatList
        contentContainerStyle={styles.listPadding}
        data={messages}
        extraData={suggestions}
        initialListSize={4}
        renderItem={onMessageItemRender}
        keyExtractor={keyExtractor}
      />
    </View>
  )
}
PinnedMessagesScreenComponent.propTypes = {
  messages: propTypes.arrayOf(propTypes.shape({})),
  onMessageItemRender: propTypes.func.isRequired,
  suggestions: propTypes.shape({}),
  insideTabContainer: propTypes.bool,
}

export default PinnedMessagesScreenComponent
