import React from "react"

export const UnknownTimezones = ({ participantsList }) => {
  const getFirstLetter = ({ label }) => (label ? label[0].toUpperCase() : "...")

  return (
    <div className="unknown-tz--wrapper">
      {participantsList.map(participant => (
        <div className="unknown-tz" key={participant.label}>
          <div className="unknown-tz__img">{getFirstLetter(participant)}</div>

          <div className="unknown-tz__info">
            <span className="unknown-tz__info-email">{participant.label}</span>
            <span className="unknown-tz__info-title">Unknown Timezone</span>
          </div>
        </div>
      ))}
    </div>
  )
}
