export function getItem(keyName) {
  try {
    return JSON.parse(localStorage.getItem(keyName))
  } catch (error) {
    console.log(error)
    return null
  }
}

export function setItem(keyName, value) {
  localStorage.setItem(keyName, JSON.stringify(value))
}

export function removeItem(keyName) {
  localStorage.removeItem(keyName)
}

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";")

  for (var cookie of cookies) {
    var eqPos = cookie.indexOf("=")
    var name = eqPos > -1 ? cookie.slice(0, Math.max(0, eqPos)) : cookie
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
}
