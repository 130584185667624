import { create } from "zustand"

import { OnlineSuggestion, Platform, URL_TYPES } from "types/suggestions"

interface SuggestionOnlineStore {
  currentPlatform: Platform | null
  setCurrentPlatform: (value: Platform | null) => void
  currentActiveSuggestionIndex: number
  setCurrentActiveSuggestionIndex: (index: number) => void
  suggestions: OnlineSuggestion[]
  addSuggestion: (suggestion: OnlineSuggestion) => void
  removeSuggestion: (index: number) => void
  updateSuggestion: (
    index: number,
    suggestion: Partial<OnlineSuggestion>
  ) => void
  currentUrlAddress: string
  setCurrentUrlAddress: (value: string) => void
  currentAdditionalInformation: string
  setCurrentAdditionalInformation: (value: string) => void
  currentUrlType: URL_TYPES
  setCurrentUrlType: (type: URL_TYPES) => void
  isYourSuggestionsShown: boolean
  setIsYourSuggestionsShown: (value: boolean) => void
  isConfirmLinkWasShown: boolean
  setIsConfirmLinkWasShown: (value: boolean) => void
  clearState: () => void
}

export const useSuggestionOnlineStore = create<SuggestionOnlineStore>()(
  (set, get) => ({
    isYourSuggestionsShown: false,
    setIsYourSuggestionsShown: value => set({ isYourSuggestionsShown: value }),
    currentActiveSuggestionIndex: -1,
    setCurrentActiveSuggestionIndex: index => {
      set({ currentActiveSuggestionIndex: index })
    },
    currentPlatform: null,
    setCurrentPlatform: value => set({ currentPlatform: value }),
    currentUrlAddress: "",
    currentUrlType: URL_TYPES.URL,
    currentAdditionalInformation: "",
    suggestions: [],
    setCurrentUrlType: (type: URL_TYPES) => set({ currentUrlType: type }),
    addSuggestion: (suggestion: OnlineSuggestion) =>
      set({ suggestions: [...get().suggestions, suggestion] }),
    updateSuggestion: (index, suggestion) =>
      set({
        suggestions: get().suggestions.map((s, i) =>
          i === index ? { ...s, ...suggestion } : s
        ),
      }),
    removeSuggestion: (index: number) => {
      const suggestion = get().suggestions

      set({
        currentActiveSuggestionIndex: 0,
        currentUrlType: suggestion[0].urlType,
        currentUrlAddress: suggestion[0].urlAddress,
        currentAdditionalInformation: suggestion[0].additionalInformation,
        suggestions: suggestion.filter((_, i) => i !== index),
      })
    },
    setCurrentUrlAddress: value => set({ currentUrlAddress: value }),
    setCurrentAdditionalInformation: value =>
      set({ currentAdditionalInformation: value }),
    isConfirmLinkWasShown: false,
    setIsConfirmLinkWasShown: value => set({ isConfirmLinkWasShown: value }),
    clearState: () =>
      set({
        currentPlatform: null,
        currentUrlAddress: "",
        currentUrlType: URL_TYPES.URL,
        currentAdditionalInformation: "",
        suggestions: [],
        currentActiveSuggestionIndex: -1,
        isYourSuggestionsShown: false,
        isConfirmLinkWasShown: false,
      }),
  })
)
