// TODO: remove and fix
// @ts-nocheck
import { useMemo } from "react"

import { Image, ImageMask } from "./svgIconStyles"

export interface Props {
  name: string
  css?: any
  className?: string
  fill?: string
  color?: string
  onClick?: (e: MouseEventHandler<HTMLImageElement>) => void
}

export const SvgIcon = ({
  name,
  css,
  className,
  fill,
  color,
  onClick = e => false,
}: Props) => {
  const img = useMemo(() => {
    if (!name) return null

    return require(`../../../assets/images/${name}.svg`)
  }, [name])

  if (!name) return false

  const colorValue = fill || color

  const fillColor = () => {
    if (colorValue?.includes("primary")) {
      if (colorValue.includes("var(--")) return colorValue
      return `var(--${colorValue})`
    }
    return colorValue
  }

  return colorValue ? (
    <ImageMask
      src={img}
      fill={fillColor()}
      className={className}
      css={css}
      onClick={onClick}
    />
  ) : (
    <Image src={img} css={css} className={className} onClick={onClick} />
  )
}
