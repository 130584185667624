import React, { useState } from "react"

import propTypes from "prop-types"
import { Image, TouchableOpacity } from "react-native"

import { styles } from "./ImageMessageStyles"
import ImagePreviewModal from "./ImagePreviewModal"

const ImageMessageComponent = ({ isPinnedScreen, image, isImageChange }) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)

  return (
    <>
      {isPreviewModalOpen && (
        <ImagePreviewModal
          isOpen={isPreviewModalOpen}
          closeModal={() => setIsPreviewModalOpen(false)}
          image={image}
        />
      )}
      <TouchableOpacity
        onPress={() => setIsPreviewModalOpen(true)}
        style={[
          styles.imageWrapper,
          isPinnedScreen && styles.pinnedImageWrapper,
          isImageChange && styles.changeImageWrapper,
        ]}>
        <Image
          testID="imageMessage"
          style={styles.image}
          source={{ uri: image || "" }}
        />
      </TouchableOpacity>
    </>
  )
}

ImageMessageComponent.propTypes = {
  image: propTypes.string.isRequired,
  isPinnedScreen: propTypes.bool.isRequired,
  isImageChange: propTypes.bool,
}

export default ImageMessageComponent
