import React from "react"

import PropTypes from "prop-types"

import { MODAL_STATUSES } from "@constants/modal"
import { NewModal } from "components"

import { Text } from "./errorModalStyles"

export const ErrorModal = ({
  title,
  text,
  cancelText,
  confirmText,
  onCancel,
  onClose,
  onConfirm,
}) => (
  <NewModal
    isOpen
    isBlackBtn
    isHeader={false}
    title={title}
    status={MODAL_STATUSES.ERROR}
    onClose={onClose}
    cancelText={cancelText}
    onCancel={onCancel}
    confirmText={confirmText}
    contentWrapperClassName="w-[400px]"
    onConfirm={onConfirm}>
    {!!text && <Text>{text}</Text>}
  </NewModal>
)

ErrorModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}
