import React from "react"

import { useSelector } from "react-redux"

import { selectMessagesWithoutNugdes } from "@selectors/activeTeeup"
import { selectTeeupSuggestions } from "@selectors/teeups"

import EmptyConversation from "../EmptyConversation"

import { Wrapper } from "./suggestionsScreenStyles"

export const SuggestionsScreen = ({ renderChatItem }) => {
  const messages = useSelector(selectMessagesWithoutNugdes)
  const suggestions = useSelector(selectTeeupSuggestions)

  const suggestionsMessages = messages?.filter?.(({ id }) =>
    Object.keys(suggestions).includes(`${id}`)
  )

  if (suggestionsMessages.length === 0)
    return (
      <EmptyConversation
        iconName="noSuggestion"
        title="No suggestions"
        description="Pinn your messages or suggestions to have them always available"
      />
    )

  return (
    <Wrapper>
      <div>
        {suggestionsMessages.map((message, index) => (
          <React.Fragment key={`message-sug-${message.id || index}`}>
            {renderChatItem({ item: message, index })}
          </React.Fragment>
        ))}
      </div>
    </Wrapper>
  )
}
