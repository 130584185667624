import { useMemo } from "react"

import moment from "moment-timezone"

export const usePrevNavDisabled = (isOnlyWeek, startOfWeek) => {
  return useMemo(() => {
    const granularity = isOnlyWeek ? "week" : "month"

    return moment().isSame(startOfWeek, granularity)
  }, [startOfWeek])
}
