import { useMemo } from "react"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { getParticipantStatus, sortByStatusToTop } from "@utils/peopleUtils"
import { usePreviewScreenStore } from "stores/previewScreen"
import { Participant } from "types/participant"

import "./styles.scss"

const ParticipantsModal = ({
  participants,
}: {
  participants: Participant[]
}) => {
  const isParticipantsModalOpen = usePreviewScreenStore(
    state => state.isParticipantsModalOpen
  )
  const setIsParticipantsModalOpen = usePreviewScreenStore(
    state => state.setIsParticipantsModalOpen
  )

  const sortedParticipants = useMemo(() => {
    const goingStatusId = 2
    return participants?.sort((a, b) => sortByStatusToTop(a, b, goingStatusId))
  }, [participants])

  const handleClose = () => {
    setIsParticipantsModalOpen(false)
  }

  if (!participants?.length) return false

  return (
    <DefaultModal open={isParticipantsModalOpen} onClose={handleClose}>
      <Header
        className="w-[calc(100%+32px)]"
        title={`Participants ${
          participants?.length ? participants?.length : ""
        }`}
        onClose={handleClose}
      />

      <div className="participants-modal">
        <div className="participants-modal__participants">
          {sortedParticipants.map(participant => (
            <div key={participant.id} className="participants-modal__item">
              <div>
                {/* TODO: Add statuses and organazer check */}
                <ParticipantIcon
                  avatar={participant?.avatar}
                  defaultAvatar={participant?.name[0]}
                  status={getParticipantStatus(participant.status)}
                  isOrganizer={participant.isOrganiser}
                />
              </div>

              <div>
                <p className="participants-modal__item__name">
                  {participant?.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </DefaultModal>
  )
}

export default ParticipantsModal
