import actionTypes from "@actions/actionTypes"

export const initialState = {
  draggingSuggestion: null,
  // draggingSuggestion: {
  //     props,
  //     touch,
  //     rect,
  // },
  activeReplyMessage: null,
  activeTextMessage: null,
  activeHighlightedMessage: null,
  replyImageSize: null,
  isConversationCardExpanded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_DRAGGING_SUGGESTION:
      return {
        ...state,
        draggingSuggestion: action.payload,
      }

    case actionTypes.UPDATE_DRAGGING_SUGGESTION:
      return {
        ...state,
        draggingSuggestion:
          state.draggingSuggestion && action.payload
            ? {
                ...state.draggingSuggestion,
                ...action.payload,
              }
            : null,
      }

    // case actionTypes.SET_REPLY_MESSAGE: {
    //     const { activeReplyMessage } = action.payload
    //     return {
    //         ...state,
    //         activeReplyMessage,
    //     }
    // }

    case actionTypes.SET_TEXT_MESSAGE: {
      return {
        ...state,
        activeTextMessage: action.payload,
      }
    }

    case actionTypes.SET_HIGHLIGHTED_MESSAGE: {
      const { activeHighlightedMessage } = action.payload
      return {
        ...state,
        activeHighlightedMessage,
      }
    }

    case actionTypes.SET_REPLY_IMAGE_SIZE: {
      const { replyImageSize } = action.payload
      return {
        ...state,
        replyImageSize,
      }
    }

    case actionTypes.EXPAND_CONVERSATION_CARD: {
      const { expanded, callback } = action.payload
      const uniqueSignalId = Date.now()
      return {
        ...state,
        isConversationCardExpanded: expanded ? uniqueSignalId : -uniqueSignalId,
        conversationCardExpansionCallback: callback,
      }
    }
    default:
      return state
  }
}
