import { useRef, useState } from "react"

import cn from "classnames"
import { useDetectClickOutside } from "react-detect-click-outside"

import { SvgIcon } from "@components/common"
import { teeupStatusFilters, userStatusFilters } from "@utils/inbox"
import { useInboxStore } from "stores/inboxStore"
import { FilterTypes } from "types/inbox"

import Applied from "./Applied"
import Content from "./Content"
import Row from "./Row"
import SortingOptions from "./SortingOptions"

const Filters = () => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedFilters = useInboxStore(state => state.selectedFilters)
  const currentSortBy = useInboxStore(state => state.currentSortBy)
  const setCurrentOpenFilter = useInboxStore(
    state => state.setCurrentOpenFilter
  )
  const handleClose = () => {
    setIsOpen(false)
    setCurrentOpenFilter("")
  }

  const ref = useDetectClickOutside({
    onTriggered: handleClose,
  })

  const handleOpenRecent = () => {
    // TODO: add value instead of title
    setCurrentOpenFilter("Sort by:")
    setIsOpen(true)
  }

  const filtersLength = Object.values(selectedFilters).reduce(
    (acc, curr) => acc + curr.length,
    0
  )

  return (
    <div
      ref={ref}
      onClick={e => e.stopPropagation()}
      className="xs:mt-4 md:mt-0">
      <div className="box-border flex w-full items-center justify-between pl-6 pr-7">
        <div
          className="flex items-center gap-2 text-stone-500 text-sm font-medium cursor-pointer"
          onClick={handleOpenRecent}>
          <SvgIcon name="newDesign/ndSort" className="!size-3" />
          {currentSortBy.id === "defaultSort" ? "Recent" : currentSortBy.title}
        </div>

        <div
          onClick={() => setIsOpen(true)}
          className="flex cursor-pointer items-center gap-2 text-stone-500 text-sm font-medium">
          <SvgIcon name="filter" className="!size-3" />
          Filter by{" "}
          {filtersLength > 0 && (
            <div className="text-stone-500 px-1.5 bg-neutral-200 rounded text-sm font-medium">
              {filtersLength}
            </div>
          )}
        </div>
      </div>

      <div
        className={cn(
          "bg-white fixed top-0 bottom-0 right-0 xs:w-full md:w-[430px] transition-all ease-in-out duration-300 z-50 shadow-xl",
          {
            "!translate-x-0": isOpen,
            "translate-x-[100%]": !isOpen,
          }
        )}>
        <div className="flex items-center justify-between p-4">
          <div className="text-center font-['Inter'] text-lg font-bold text-black">
            Filters & sort
          </div>

          <button onClick={handleClose}>
            <SvgIcon name="close-2" className="!size-8" />
          </button>
        </div>

        <div>
          <Applied />

          <Row title="Sort by:" subtitle={currentSortBy.title} icon="sortBy">
            <SortingOptions />
          </Row>

          <Row
            title="TeeUp stage"
            icon="teeUpStage"
            hasBadge={selectedFilters[FilterTypes.stage]?.length > 0}>
            <Content
              filterType={FilterTypes.stage}
              options={teeupStatusFilters}
            />
          </Row>

          <Row
            title="My status"
            icon="myStatus"
            hasBadge={selectedFilters[FilterTypes.userStatus]?.length > 0}>
            <Content
              options={userStatusFilters}
              filterType={FilterTypes.userStatus}
            />
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Filters
