import cn from "classnames"

import { SvgIcon } from "@components/common"
import { useInboxStore } from "stores/inboxStore"
import { useEffect, useRef } from "react"

interface Props {
  isSearchOpen: boolean
  setIsSearchOpen: (isSearchOpen: boolean) => void
}

const Search = ({ isSearchOpen, setIsSearchOpen }: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const searchValue = useInboxStore(state => state.searchValue)
  const setSearchValue = useInboxStore(state => state.setSearchValue)

  useEffect(() => {
    if (!ref.current) return

    if (isSearchOpen) {
      ref.current.focus()
    } else {
      setSearchValue("")
    }
  }, [isSearchOpen])

  return (
    <div
      className={cn(
        "flex h-8 w-full items-center justify-between md:px-4 transition-all duration-300",
        {
          "!justify-start": isSearchOpen,
        }
      )}>
      <div
        className={cn(
          "text-xl font-bold text-white opacity-100 transition-all duration-[600ms]",
          {
            "!opacity-0 !w-0": isSearchOpen,
          }
        )}>
        Inbox
      </div>
      <div className="relative h-9 w-full">
        {isSearchOpen && (
          <SvgIcon
            name="search"
            className="absolute left-3 top-1/2 z-10 -translate-y-1/2"
          />
        )}
        <input
          ref={ref}
          placeholder="Search by teeup or user"
          type="text"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          className={cn(
            "w-0 h-9 opacity-0 bg-white transition-all duration-300 rounded-[40px] px-10",
            {
              "!w-full opacity-100 ": isSearchOpen,
            }
          )}
        />
        <SvgIcon
          name="bold-cross"
          className={cn(
            "absolute right-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer opacity-0 !w-0 transition-[opacity] duration-300",
            {
              "opacity-100 !w-5": isSearchOpen,
            }
          )}
          onClick={() => setIsSearchOpen(false)}
        />
      </div>

      <div>
        <SvgIcon
          name="search-loop"
          className={cn(
            "!size-10 cursor-pointer opacity-0 !w-0 transition-[opacity] duration-300",
            {
              "!opacity-100 !w-full": !isSearchOpen,
            }
          )}
          onClick={() => setIsSearchOpen(true)}
        />
      </div>
    </div>
  )
}

export default Search
