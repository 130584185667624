import styled from "styled-components"

import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  border-radius: 24px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  position: absolute;
  bottom: -23px;
  right: 0px;
  padding: 2px 8px;
`

export const Icon = styled(SvgIcon)`
  margin-right: 5px;
  width: 10px;
  height: 9px;
`
