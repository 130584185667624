import React, { useContext, useState } from "react"

import { NewModal } from "@components"
import { InputEl } from "@components/common"
import { TeeupPreviewContext } from "@contexts/TeeupPreviewContext"
import { useDeviceTimezone, useApiErrors, useScreenWidth } from "@hooks"

import {
  useValidationForm,
  useCatchTimezone,
  useSubmit,
  useTriggerModal,
  useCatchInviteeData,
  useConfirmDisabled,
} from "./hooks"
import { ContentWrapper, Description } from "./setRSVPModalStyles"
import { getValidationSchema } from "./validationSchema"

export const SetRSVPModal = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [status, setStatus] = useState()
  const [timezone, setTimezone] = useState()
  const [email, setEmail] = useState("")
  const [fullName, setFullName] = useState()
  const { apiErrors, addApiError, removeApiError } = useApiErrors()

  const closeModal = () => {
    setIsStatusModal(false)
  }

  const {
    previewInfo,
    gameplanInfo,
    isSharedLink,
    isPhoneInvite,
    statusModalTrigger,
    setStatusModalTrigger,
    setIsStatusModal,
  } = useContext(TeeupPreviewContext)
  const { isSmallSize } = useScreenWidth()
  const isEmailField =
    (isSharedLink && !previewInfo?.invitee?.isSharedLink) || isPhoneInvite
  const { validationErrors, validateForm } = useValidationForm({
    formValues: { status, timezone, email, fullName },
    validationSchema: getValidationSchema(isEmailField),
  })
  const { currentDeviceTimezone } = useDeviceTimezone({
    isAuthenticated: false,
  })
  useCatchTimezone({
    currentDeviceTimezone,
    setTimezone,
  })
  useCatchInviteeData({
    previewInfo,
    setStatus,
    setFullName,
    setTimezone,
    setEmail,
  })
  const handleConfirm = useSubmit({
    status,
    timezone,
    email,
    fullName,
    invitee: previewInfo.invitee,
    isSharedLink,
    isPhoneInvite,
    validateForm,
    setIsSubmitting,
    addApiError,
    closeModal,
  })

  useTriggerModal({
    statusModalTrigger,
    isGameplanReady: !!gameplanInfo,
    setStatus,
    setStatusModalTrigger,
  })

  const isConfirmDisabled = useConfirmDisabled({
    validationErrors,
    isSubmitting,
    apiErrors,
  })

  return (
    <NewModal
      isOpen
      isBlackBtn
      isBottom={isSmallSize}
      isHorizontalBtns={isSmallSize}
      isHeader={false}
      title="Share your Response"
      iconName="hand"
      cancelText="Back"
      confirmText="Share"
      isDisabledConfirm={isConfirmDisabled}
      isLoadingConfirm={isSubmitting}
      onCancel={closeModal}
      onClose={closeModal}
      onConfirm={handleConfirm}>
      <Description>
        To share your response to the invitation and receive plan updates,
        please verify your identity. Your name and email will be visible to
        other TeeUp participants.
      </Description>

      <ContentWrapper>
        <InputEl
          isRounded
          name="fullName"
          value={fullName}
          placeholder="Full name"
          errorMessage={validationErrors?.fullName}
          label="Name"
          onChange={setFullName}
        />

        {isEmailField && (
          <InputEl
            isRounded
            name="email"
            value={email}
            placeholder="Email"
            errorMessage={validationErrors?.email || apiErrors?.email}
            label="Email"
            onChange={val => {
              setEmail(val)
              removeApiError("email")
            }}
          />
        )}
      </ContentWrapper>
    </NewModal>
  )
}
