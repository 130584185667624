import React from "react"

const ProgressBar = ({ completed }) => {
  const containerStyles = {
    height: 3,
    width: 100,
    backgroundColor: "#FF9170",
    borderRadius: 2,
    margin: 50,
  }

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "white",
    opacity: 1,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 1s ease-in",
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  )
}

export default ProgressBar
