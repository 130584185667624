import React, { useState } from "react"

import { connect } from "react-redux"

import { selectTags } from "@selectors/contacts"

import { images } from "../../utils/imageUtils.js"

import "./index.scss"

const ContactsFilterModalNd = ({
  handleSearch,
  closeContactsFilterModal,
  rememberSelectedTag,
  currentTag,
  tags,
  selectedTag,
  fromContactsBook,
  handleSearchByCustomTags,
  selectedTags,
  setSelectedTags,
}) => {
  const [initialFilter, setInitialFilter] = useState(true)
  const [viewAll, setViewAll] = useState(false)
  // const [selectedTagsGroup, setSelectedTagsGroup] = useState([])

  const handleTagTouch = (selectedTag, sortByName) => () => {
    handleSearch(null, selectedTag, sortByName)
  }

  const sortByCustomTags = tag => {
    const { value, id, onPress } = tag
    const isTagSelected = selectedTags.includes(id) ? true : false
    const onPressEvent = onPress ? onPress : handleSearchByCustomTags

    return (
      <div
        key={`${value} + ${id}`}
        className="filter-tagNd"
        onClick={() => {
          onPressEvent(tag.id)
          rememberSelectedTag(tag)
        }}
        style={{
          backgroundColor: isTagSelected && "#595f82",
        }}>
        {!["Frequent", "Recent"].includes(tag.value) && (
          <p
            className="filter-tagNd__text"
            style={{
              color: isTagSelected && "#fff",
            }}>
            {value}
          </p>
        )}
      </div>
    )
  }

  const renderTagsRow = (userTags, onTagRender) => {
    let tagsRow = []
    if (userTags) {
      tagsRow = userTags.map(onTagRender)
    }

    let asds = tagsRow.filter(item => item.props.children !== false)

    return asds.sort((a, b) => {
      return (
        a.props.children.props.children.length -
        b.props.children.props.children.length
      )
    })
  }

  const selectDefaultFilter = () => {
    handleTagTouch(null, true)()
    rememberSelectedTag({})
  }

  return (
    <div
      className="contact-filtersNd"
      style={{
        width: fromContactsBook && "100%",
        height: fromContactsBook && "max-content",
      }}>
      <div className="contact-filtersNd__header">
        <h3 className="contact-filtersNd__title">Filter by Tags</h3>
        <div className="contact-filtersNd__buttons">
          <div
            className="contact-filtersNd__button"
            onClick={() => {
              selectDefaultFilter()
              setSelectedTags([])
            }}>
            <img
              src={images.ndClearFilters}
              style={{ marginRight: 6 }}
              alt="clear filters"
            />
            Clear
          </div>
          <div
            className="contact-filtersNd__button"
            onClick={() => {
              closeContactsFilterModal()
            }}>
            <img
              src={images.ndMinimizeFilters}
              alt="minimize filters"
              style={{ marginRight: 6 }}
            />
            Minimize
          </div>
        </div>
      </div>
      <div className="contact-filtersNd__bridge"></div>
      <div
        className="contact-filtersNd__tags"
        style={{
          marginBottom: fromContactsBook && "25px",
        }}>
        {renderTagsRow(tags, sortByCustomTags)}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const tags = selectTags(state)
  return {
    tags,
  }
}

export default connect(mapStateToProps, null)(ContactsFilterModalNd)
