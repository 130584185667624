import React, { memo } from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

// import Icon from 'react-native-vector-icons/FontAwesome5'
import { ReactSVG } from "react-svg"

import { getSuggestionValue } from "@utils/gamePlanUtils"
// import Icon from 'react-native-vector-icons/FontAwesome5'
import { images } from "@utils/imageUtils"

import styles from "./suggestionCardStyles"
import "./suggestionCard.scss"

const SuggestionCard = ({
  suggestion,
  creator,
  disabled,
  onPress,
  newDesign,
}) => {
  const creatorName = creator?.username || creator?.name || "Unknown"
  const suggestionMessage =
    `${creatorName} suggested` + (suggestion.type === "when" ? " When:" : "")
  const suggestionName = getSuggestionValue(suggestion, suggestion.type).full

  if (newDesign) {
    return (
      <div className="commented-suggestion">
        <div className="commented-suggestion__commentator">
          <ReactSVG
            className="commented-suggestion__commentator-icon"
            src={
              suggestion.type === "when"
                ? images.ndClockIcon
                : images.ndLocationPinIcon
            }
          />
          {`${creatorName} suggested`}
        </div>
        <div className="commented-suggestion__suggestion">{suggestionName}</div>
      </div>
    )
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.container, newDesign && { backgroundColor: "#f0f2f5" }]}>
      <View style={styles.bar} />
      {/* <Icon
                name={getGameplanIcon({ type: suggestion.type })}
                style={styles.icon}
                size={10}
                solid
            /> */}
      <View style={[styles.info, { width: "100%" }]}>
        <Text style={[styles.line, newDesign && { color: "#a9b0b9" }]}>
          {suggestionMessage}
        </Text>
        <Text style={[styles.line, newDesign && { color: "#a9b0b9" }]}>
          {suggestionName}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

SuggestionCard.propTypes = {
  suggestion: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  newDesign: PropTypes.bool,
}

export default memo(SuggestionCard)
