import React, { useContext } from "react"

import { useScreenWidth } from "@hooks"
import { NewModal } from "components"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { Description, Title } from "./confirmedStatusModalStyles"
import { useTexts, useSubmitButtonInfo } from "./hooks"

export const ConfirmedStatusModal = () => {
  const { isSmallSize } = useScreenWidth()
  const {
    statusModalTrigger,
    confirmingStatus,
    setConfirmingStatus,
    setIsStatusConfirmedModal,
  } = useContext(TeeupPreviewContext)
  const currentStatus = confirmingStatus || statusModalTrigger
  const { modalTitle, modalDescriptionAction, modalDescriptionUpdates } =
    useTexts(currentStatus)
  const closeHandle = () => {
    setIsStatusConfirmedModal(false)
    setConfirmingStatus("")
  }

  const { confirmText, confirmAction } = useSubmitButtonInfo(
    currentStatus,
    closeHandle
  )

  return (
    <NewModal
      isOpen
      isBlackBtn
      isBottom={isSmallSize}
      isHeader={false}
      iconName={`${currentStatus}-shadow`}
      title={modalTitle || undefined}
      confirmText={confirmText}
      cancelText="Close"
      onClose={closeHandle}
      onCancel={closeHandle}
      onConfirm={confirmAction}>
      <div onClick={e => e.stopPropagation()}>
        <Title
          dangerouslySetInnerHTML={{
            __html: modalDescriptionAction,
          }}
        />
        <Description className="mb-[155px]">
          {modalDescriptionUpdates}
        </Description>
      </div>
    </NewModal>
  )
}
