import React from "react"

import truncate from "lodash/truncate"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { TEEUP_TITLE_LENGTH } from "@utils/contacts"

import { Wrapper, Arrow, TeeupName } from "./goBackStyles"

export const GoBack = ({ name }) => {
  const history = useHistory()

  const handleGoBack = () => {
    history.push("/inbox")
  }

  return (
    <Wrapper onClick={handleGoBack}>
      <Arrow name="arrow-2" color="var(--white)" />

      <TeeupName>{truncate(name, { length: TEEUP_TITLE_LENGTH })}</TeeupName>
    </Wrapper>
  )
}

GoBack.propTypes = {
  name: PropTypes.string,
}
