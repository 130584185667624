import React from "react"

import PropTypes from "prop-types"

import { SwitchButton } from "./trySwitchTypeStyles"

export const TrySwitchType = ({ onClick }) => (
  <SwitchButton onClick={onClick}>
    Are you trying to invite by phone number?
  </SwitchButton>
)

TrySwitchType.propTypes = {
  onClick: PropTypes.func.isRequired,
}
