import React, { useEffect, useState } from "react"

import { GoogleApiWrapper } from "google-maps-react"

import {
  SuggestModalContent,
  SuggestModalSubHeader,
  SuggestModalLeftSide,
} from "../../components"

import FooterButtons from "./FooterButtons"
import MapContainer from "./MapContainer"
import Pinboard from "./Pinboard"
import SearchField from "./SearchField"

import "./index.scss"
import useInPersonTabState from "../Hooks/useInPersonTabState"

const InPersonTab = ({
  close,
  onSave,
  handleClose,
  teeupId,
  forCreateTeeup,
}) => {
  const { activePlace, setMapCenter, setActivePlace } = useInPersonTabState()

  const [userLocation, setUserLocation] = useState(null)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }

    return () => {
      setMapCenter({
        lat: 49.282_729_1,
        lng: -123.120_737_5,
      })
      setActivePlace(null)
    }
  }, [])

  return (
    <div className="suggest-in-person">
      <SuggestModalLeftSide>
        <SuggestModalSubHeader>
          <Pinboard activePlace={activePlace} />
        </SuggestModalSubHeader>
        <SuggestModalContent>
          {userLocation && <SearchField userLocation={userLocation} />}
        </SuggestModalContent>

        {activePlace && (
          <FooterButtons
            handleClose={forCreateTeeup ? close : handleClose}
            teeupId={teeupId}
            onSave={onSave}
          />
        )}
      </SuggestModalLeftSide>
      <MapContainer />
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
})(InPersonTab)
