import { SvgIcon } from "@components/common"
import { getEmptyData } from "@utils/inbox"
import { useEffect, useState } from "react"
import { useInboxStore } from "stores/inboxStore"
import { InboxEmptyData } from "types/inbox"

const Empty = () => {
  const [emptyData, setEmptyData] = useState<InboxEmptyData | null>(null)
  const currentOpenTab = useInboxStore(state => state.currentOpenTab)
  const setIsUnderlineModalOpen = useInboxStore(
    state => state.setIsUnderlineModalOpen
  )
  const searchValue = useInboxStore(state => state.searchValue)
  const filters = useInboxStore(state =>
    Object.values(state.selectedFilters).flat()
  )

  useEffect(() => {
    const emptyData = getEmptyData(
      currentOpenTab.value,
      !!searchValue,
      !!filters.length
    )
    setEmptyData(emptyData)
  }, [currentOpenTab])

  if (!emptyData) return false

  return (
    <div className="overflow-x-hidden flex flex-col items-center justify-center gap-4 text-center fixed top-1/2 left-1/2 xs:-translate-x-1/2 md:-translate-x-1/3 -translate-y-1/2 animate-fade min-w-[300px]">
      {searchValue && (
        <SvgIcon name={emptyData.iconName} className="!w-auto !h-auto mb-6" />
      )}

      <h4 className="text-neutral-700 text-xl font-bold leading-7">
        {emptyData.title}
      </h4>

      <div>
        <p className="text-neutral-500 text-base font-medium leading-normal">
          {emptyData.description}
        </p>

        {emptyData?.underlineText && (
          <div
            className="flex items-center gap-1 justify-center mt-1 cursor-pointer"
            onClick={() => setIsUnderlineModalOpen(true)}>
            <p className="text-neutral-500 text-base font-medium leading-normal underline">
              {emptyData?.underlineText}
            </p>
            <SvgIcon
              name="questionIcon"
              className="!w-auto !h-auto"
              // color="#737373"
            />
          </div>
        )}
      </div>

      {!searchValue && (
        <SvgIcon name={emptyData.iconName} className="!w-auto !h-auto mt-6" />
      )}
    </div>
  )
}

export default Empty
