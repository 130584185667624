import { create } from "zustand"

import { InPersonSuggestion, Location, Prediction } from "types/suggestions"

interface SuggestionInPersonStore {
  isWhenModalOpen: boolean
  setIsWhenModalOpen: (value: boolean) => void

  isWhereModalOpen: boolean
  setIsWhereModalOpen: (value: boolean) => void

  userLocation: Location | null
  setUserLocation: (value: Location | null) => void

  mapCoordinates: Location
  setMapCoordinates: (value: Location) => void

  isMapLoading: boolean
  setIsMapLoading: (value: boolean) => void

  selectedAddress: Prediction | null
  setSelectedAddress: (value: Prediction | null) => void

  currentAdditionalInformation: string
  setCurrentAdditionalInformation: (value: string) => void

  selectedName: string
  setSelectedName: (value: string) => void

  isMapExpanded: boolean
  setIsMapExpanded: (value: boolean) => void

  suggestions: InPersonSuggestion[]
  addSuggestion: (suggestion: InPersonSuggestion) => void
  updateSuggestion: (
    index: number,
    suggestion: Partial<InPersonSuggestion>
  ) => void
  removeSuggestion: (index: number) => void
  clearState: () => void

  currentActiveSuggestionIndex: number
  setCurrentActiveSuggestionIndex: (index: number) => void
}

export const useSuggestionInPersonStore = create<SuggestionInPersonStore>()(
  set => ({
    currentActiveSuggestionIndex: -1,
    setCurrentActiveSuggestionIndex: index =>
      set({ currentActiveSuggestionIndex: index }),
    isMapLoading: false,
    setIsMapLoading: value => set({ isMapLoading: value }),

    isWhenModalOpen: false,
    setIsWhenModalOpen: value => set({ isWhenModalOpen: value }),

    isWhereModalOpen: false,
    setIsWhereModalOpen: value => set({ isWhereModalOpen: value }),

    userLocation: null,
    setUserLocation: value => set({ userLocation: value }),

    mapCoordinates: { lat: 0, lng: 0 },
    setMapCoordinates: value => set({ mapCoordinates: value }),

    selectedAddress: null,
    setSelectedAddress: value => set({ selectedAddress: value }),

    selectedName: "",
    setSelectedName: value => set({ selectedName: value }),

    currentAdditionalInformation: "",
    setCurrentAdditionalInformation: value =>
      set({ currentAdditionalInformation: value }),

    isMapExpanded: false,
    setIsMapExpanded: value => set({ isMapExpanded: value }),

    suggestions: [],
    addSuggestion: suggestion =>
      set(state => ({
        suggestions: [...state.suggestions, suggestion],
      })),
    updateSuggestion: (index, suggestion) =>
      // @ts-ignore
      set(state => ({
        suggestions: state.suggestions.map((item, i) =>
          i === index
            ? {
                ...suggestion,
                address: {
                  ...item.address,
                  ...suggestion.address,
                },
              }
            : item
        ),
      })),
    removeSuggestion: index =>
      set(state => ({
        suggestions: state.suggestions.filter((_, i) => i !== index),
        currentActiveSuggestionIndex: 0,
      })),
    clearState: () =>
      set({
        suggestions: [],
        selectedAddress: null,
        selectedName: "",
        isMapExpanded: true,
        isMapLoading: false,
        userLocation: null,
        mapCoordinates: { lat: 0, lng: 0 },
        currentActiveSuggestionIndex: -1,
      }),
  })
)
