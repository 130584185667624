import { StyleSheet } from "react-native"

import { Units, AppColors, AppFonts } from "@theme"

export const styles = StyleSheet.create({
  fileDescription: {
    flex: 1,
    marginHorizontal: Units.responsiveValue(10),
  },
  docImage: {
    width: 25,
    height: 25,
  },
})

export const fileContainerMain = isMyMessage => ({
  flexDirection: isMyMessage ? "row" : "row-reverse",
  alignItems: "center",
  padding: 2,
})

export const fileContainer = isPinnedScreen => ({
  // width: !isPinnedScreen
  //     ? AppSizes.screen.widthThreeQuarters
  //     : AppSizes.screen.widthThreeQuarters - Units.responsiveValue(18),
  paddingTop: Units.responsiveValue(10),
  paddingBottom: Units.responsiveValue(14),
  paddingRight: Units.responsiveValue(10),
  alignItems: "stretch",
})

export const fileIconWrapper = isMyMessage => {
  const borderStyle = isMyMessage
    ? {
        borderTopStartRadius: 20,
        borderBottomStartRadius: 20,
        marginRight: 40,
      }
    : {
        borderTopEndRadius: 20,
        borderBottomEndRadius: 20,
        marginLeft: 40,
      }

  return {
    backgroundColor: AppColors.brand.white,
    width: 50,
    height: "100%",
    borderColor: isMyMessage
      ? AppColors.brand.lightBlue5
      : AppColors.brand.warmGrey2,
    alignItems: "center",
    justifyContent: "center",
    ...borderStyle,
  }
}

export const filename = isMyMessage => ({
  flex: 1,
  flexWrap: "wrap",
  ...AppFonts.biggerDemibold,
  color: isMyMessage ? AppColors.brand.darkBlue : AppColors.brand.black2,
  paddingVertical: 5,
})

export const filesize = isMyMessage => ({
  ...AppFonts.medium,
  color: isMyMessage
    ? AppColors.brand.lightDarkBlue
    : AppColors.brand.warmGrey2,
  paddingBottom: 5,
})
