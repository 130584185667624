import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const FormErrorText = styled.div`
  color: #ff0000;
  font-family: greycliffcf-regular-salt;
  font-size: 15px;
  margin-bottom: 20px;
  p:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    font-size: 13px;
  }
`
