import React from "react"

import { useDispatch } from "react-redux"

import { setIsCreateTeeupOpen } from "@actions/createTeeupActions"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { useCreateTeeUp } from "../CreateTeeUpContext/useCreateTeeUp"

import { Wrapper } from "./buttonStyles"

const CreateTeeUpButtons = ({ isLoading, create }) => {
  const dispatch = useDispatch()
  const { name } = useCreateTeeUp()

  return (
    <Wrapper>
      <ButtonEl
        isBlack
        fullWidth
        isLoading={isLoading}
        disabled={isLoading || name.length === 0}
        testID="createTeeupButton"
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Create TeeUp"
        onClick={create}
      />

      <ButtonEl
        isBlack
        variant={BUTTON_VARIANTS.TRANSPARENT}
        text="Cancel"
        testID="cancelTeeupButton"
        onClick={() => dispatch(setIsCreateTeeupOpen(false))}
      />
    </Wrapper>
  )
}

export default CreateTeeUpButtons
