import React, { useContext } from "react"

import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { ContactAvatar } from "@components/common"
import { SIDEBAR_MENU_NAMES } from "@constants/sidebar"
import { selectUserInfo } from "@selectors/user"
import { SideBarContext } from "@utils/sideBarContext"

import { NavItemWrapper } from "../atoms"
import { Name } from "../sidebarStyles"

import { Icon } from "./navItemStyles"

export const NavItem = ({
  staticElement,
  item,
  profile,
  isActive,
  disabled,
}) => {
  const { isSideBarOpen } = useContext(SideBarContext)
  const iconColor = isActive ? "var(--radicalRed)" : "var(--gray6)"
  const userInfo = useSelector(selectUserInfo)

  const renderImage = () => {
    return profile ? (
      <ContactAvatar size={32} src={userInfo.avatar} text={userInfo.name} />
    ) : (
      <Icon
        name={item.icon}
        className={item.className}
        color={item.color || iconColor}
      />
    )
  }

  return (
    <NavItemWrapper
      disabled={disabled}
      staticElement={staticElement}
      pathname={item.pathname}
      option={item.option}>
      {renderImage()}

      {(isSideBarOpen || staticElement) && (
        <Name $isActive={isActive} $color={item.color}>
          {item.name}
        </Name>
      )}
    </NavItemWrapper>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
}
