import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const SuggestionNudgeResponseStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  titleContainer: {
    width: "100%",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.responsiveValue(8),
    paddingBottom: Units.responsiveValue(16),
    paddingHorizontal: Units.responsiveValue(8),
  },
  senderContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: Units.responsiveValue(12),
  },
  senderIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
    marginRight: Units.responsiveValue(2),
    tintColor: AppColors.brand.pink,
  },
  sender: {
    ...AppFonts.smallishDemibold,
    color: AppColors.brand.grey112,
  },
  questionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Units.responsiveValue(8),
  },
  question: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.black2,
  },
  suggestionNudgeContainer: {
    width: "70%",
    paddingTop: Units.responsiveValue(8),
    margin: "0 auto",
  },

  statusesContainer: {
    backgroundColor: "var(--lightGray)",
    width: "100%",
    flexShrink: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    columnGap: 10,
    paddingTop: Units.responsiveValue(24),
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15,
    marginBottom: 15,
    flexWrap: "wrap",
  },

  statusItemMargin: {
    marginBottom: Units.responsiveValue(16),
  },
  nudgeStatusIcon: {
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
  },

  viewInHighlightsContainer: {
    marginTop: Units.responsiveValue(24),
    height: Units.responsiveValue(40),
    justifyContent: "center",
  },
  viewInHighlightsText: {
    ...AppFonts.smallExtraBold,
    color: AppColors.brand.pink,
    textTransform: "uppercase",
  },
})
