import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  ${({ $isMarginBottom }) =>
    $isMarginBottom &&
    css`
      margin-bottom: 20px;
    `};
`
