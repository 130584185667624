import React from "react"

import _ from "lodash"
import propTypes from "prop-types"

import {
  teeupStatusFilterButtons,
  userStatusFilterButtons,
  teeupTypeFilterButtons,
  OtherFilterButtons,
} from "@utils/filters"

import DefaultContent from "./ContentParts/DefaultContent"
import FilterPartContent from "./ContentParts/FilterPartContent"
import FilterSection from "./ContentParts/FilterSection"
import SortByContent from "./ContentParts/SortByContent"
import SideFilterSortMenuComponent from "./SideFilterSortMenuComponent"

const screensButtons = {
  // sortBy: {
  //     title: 'Sort by',
  //     screen: 'sortBy',
  // },
  teeupStage: {
    title: "Teeup Stage",
    screen: "status",
    buttons: teeupStatusFilterButtons,
  },
  myStatus: {
    title: "My Status",
    screen: "userStatus",
    buttons: userStatusFilterButtons,
  },
  // teeupType: {
  //     title: 'TeeUp Type',
  //     screen: 'type',
  // },
  other: {
    title: "Other",
    screen: "other",
    buttons: OtherFilterButtons,
  },
}

const filterSections = [
  {
    title: "Teeup Stage",
    screen: "status",
    buttons: teeupStatusFilterButtons,
  },
  {
    title: "My Status",
    screen: "userStatus",
    buttons: userStatusFilterButtons,
  },
  {
    title: "Other",
    screen: "other",
    buttons: OtherFilterButtons,
  },
]

class SideFilterSortMenuContainer extends React.Component {
  state = {
    selectedScreen: null,
  }

  handleBackPress = () => this.setState({ selectedScreen: null })

  handleOpenScreen = selectedScreen => () => this.setState({ selectedScreen })

  handleClearPress = () => this.props.clearTeeupsFiltering()

  handleSelectSorting = teeupSorting => () =>
    this.props.addTeeupsSorting(teeupSorting)

  handleFilterButtonPress = (filterType, filterValue) =>
    this.handleAddFilter({
      filterType,
      filterValue,
    })

  handleAddFilter = ({ filterType, filterValue }) => {
    const { teeupFiltering } = this.props
    let currentFilter = [...teeupFiltering[filterType]]

    const isValueAlreadyExist = _.find(
      currentFilter,
      value => value && value.id === filterValue.id
    )
    if (isValueAlreadyExist) {
      currentFilter = _.filter(
        currentFilter,
        value => value && value.id !== filterValue.id
      )
    } else currentFilter.push({ ...filterValue, filterType })

    this.props.addTeeupsFiltering({
      ...this.props.teeupFiltering,
      [filterType]: currentFilter,
    })
  }

  renderFilters = () => {
    const { teeupFiltering } = this.props

    return (
      <>
        {filterSections.map(item => (
          <FilterSection
            key={item.title}
            title={item.title}
            buttons={item.buttons}
            currentFiltering={teeupFiltering[item.screen]}
            onFilterButtonPress={teeupFiltering =>
              this.handleFilterButtonPress(item.screen, teeupFiltering)
            }
          />
        ))}
      </>
    )
  }

  handleRenderScreenContent = selectedScreen => {
    const { teeupSorting, teeupFiltering } = this.props
    if (!selectedScreen) {
      return (
        <DefaultContent
          teeupSorting={teeupSorting}
          teeupFiltering={teeupFiltering}
          screensButtons={screensButtons}
          onScreenOpen={this.handleOpenScreen}
        />
      )
    }

    const currentScreen = selectedScreen.screen

    switch (currentScreen) {
      case screensButtons.sortBy.screen:
        return (
          <SortByContent
            onSortButtonPress={this.handleSelectSorting}
            teeupSorting={teeupSorting}
          />
        )
      case screensButtons.teeupStage.screen:
        return (
          <FilterPartContent
            buttons={teeupStatusFilterButtons}
            onFilterButtonPress={teeupFiltering =>
              this.handleFilterButtonPress(currentScreen, teeupFiltering)
            }
            currentFiltering={teeupFiltering[currentScreen]}
            additionalMarginIndexes={[1, 3]}
          />
        )
      case screensButtons.myStatus.screen:
        return (
          <FilterPartContent
            buttons={userStatusFilterButtons}
            onFilterButtonPress={teeupFiltering =>
              this.handleFilterButtonPress(currentScreen, teeupFiltering)
            }
            currentFiltering={teeupFiltering[currentScreen]}
            additionalMarginIndexes={[1, 4, 6]}
          />
        )
      case screensButtons.teeupType.screen:
        return (
          <FilterPartContent
            buttons={teeupTypeFilterButtons}
            onFilterButtonPress={teeupFiltering =>
              this.handleFilterButtonPress(currentScreen, teeupFiltering)
            }
            currentFiltering={teeupFiltering[currentScreen]}
          />
        )
      case screensButtons.other.screen:
        return (
          <FilterPartContent
            buttons={OtherFilterButtons}
            onFilterButtonPress={teeupFiltering =>
              this.handleFilterButtonPress(currentScreen, teeupFiltering)
            }
            currentFiltering={teeupFiltering[currentScreen]}
          />
        )
    }
  }

  render() {
    const { selectedScreen } = this.state
    return (
      <SideFilterSortMenuComponent
        selectedScreen={selectedScreen}
        filteredTeeupsLength={this.props.teeupsData.length}
        onClosePress={this.props.closeMenu}
        onBackPress={this.handleBackPress}
        onClearPress={this.props.clearTeeupsFiltering}
        onDonePress={this.props.closeMenu}
        renderScreenContent={this.handleRenderScreenContent}
        renderFilters={this.renderFilters}
      />
    )
  }
}

SideFilterSortMenuContainer.propTypes = {
  teeupSorting: propTypes.object,
  teeupsData: propTypes.array.isRequired,
  teeupFiltering: propTypes.object,
  addTeeupsSorting: propTypes.func.isRequired,
  addTeeupsFiltering: propTypes.func.isRequired,
  clearTeeupsFiltering: propTypes.func.isRequired,
  closeMenu: propTypes.func,
}

export default SideFilterSortMenuContainer
