import React, { Fragment, useEffect, useState } from "react"

import { Dayjs } from "dayjs"

import Pill from "@components/ui/Pill"
import dayjs from "utils/dayjs"

import "./index.scss"
import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"
import { getDefaultDateRanges } from "@utils/suggestionUtils"
import { IDateRange } from "types/suggestions"

import { DateRange as MuiDateRange } from "@mui/lab"

const DateRange = () => {
  const setCurrentDate = useSuggestionGeneralDateStore(
    state => state.setCurrentDate
  )
  const setIsCustomDateRangeOpen = useSuggestionGeneralDateStore(
    state => state.setIsCustomDateRangeOpen
  )
  const triggerCustomDateRangeUpdate = useSuggestionGeneralDateStore(
    state => state.triggerCustomDateRangeUpdate
  )
  const currentLabel = useSuggestionGeneralDateStore(
    state => state.currentLabel
  )
  const setCurrentLabel = useSuggestionGeneralDateStore(
    state => state.setCurrentLabel
  )
  const suggestions = useSuggestionGeneralDateStore(state => state.suggestions)
  const currentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.currentActiveSuggestionIndex
  )
  const setCurrentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionGeneralDateStore(
    state => state.updateSuggestion
  )
  const addSuggestion = useSuggestionGeneralDateStore(
    state => state.addSuggestion
  )
  const [dateRanges, setDateRanges] = useState<IDateRange[]>([])

  useEffect(() => {
    getDateRanges()
  }, [])

  useEffect(() => {
    if (!triggerCustomDateRangeUpdate) return

    getDateRanges(true)
  }, [triggerCustomDateRangeUpdate])

  const getDateRanges = (isCustomDateRangeUpdate?: boolean) => {
    const ranges = [...getDefaultDateRanges()]

    const customDateRange = JSON.parse(
      localStorage.getItem("customDateRange") || "[]"
    ) as IDateRange[]

    if (customDateRange.length > 0) {
      ranges.push(...customDateRange)
    }
    setDateRanges(ranges)

    if (isCustomDateRangeUpdate) {
      const lastCustomDate = ranges?.[ranges?.length - 1]
      if (!lastCustomDate) return

      setCurrentLabel(lastCustomDate.label)
      setCurrentDate([
        dayjs(lastCustomDate.startDate),
        dayjs(lastCustomDate.endDate),
      ])
    }
  }

  const handleDateRangeClick = (dateRange: IDateRange) => {
    const date = [
      dayjs(dateRange.startDate),
      dayjs(dateRange.endDate),
    ] as MuiDateRange<Dayjs>

    setCurrentDate(date)
    setCurrentLabel(dateRange.label)
    if (suggestions.length > 0 && currentActiveSuggestionIndex === -1) {
      addSuggestion({
        date,
        label: dateRange.label,
        duration: 60,
      })
      setCurrentActiveSuggestionIndex(suggestions.length)
    } else if (currentActiveSuggestionIndex !== -1) {
      updateSuggestion(currentActiveSuggestionIndex, {
        date,
        label: dateRange.label,
      })
    }
  }

  return (
    <div className="date-range-container">
      <ul className="date-range">
        {dateRanges.map((dateRange, index) => (
          <Fragment key={index}>
            <Pill
              label={dateRange.label}
              onClick={() => handleDateRangeClick(dateRange)}
              isSelected={currentLabel === dateRange.label}>
              <span>{dateRange.label}</span>
            </Pill>
          </Fragment>
        ))}
      </ul>

      <div
        onClick={() => setIsCustomDateRangeOpen(true)}
        className="add-date-range">
        + Custom dates or range
      </div>
    </div>
  )
}

export default DateRange
