export const removeAnotherReaction = (updatingReaction, inviteeId) => {
  const isAnotherReaction = updatingReaction?.reactedBy?.some(
    participant => !!participant.key || participant.id === inviteeId
  )

  if (isAnotherReaction) {
    updatingReaction.reactedBy = updatingReaction?.reactedBy?.filter(
      participant => !participant.key && participant.id !== inviteeId
    )
  }
}
