import React, { forwardRef } from "react"

import PropTypes from "prop-types"

import { TEEUP_TITLE_LENGTH } from "@utils/contacts"

import { Wrapper, Label, Input } from "./transparentInputStyles"

export const TransparentInput = forwardRef(
  ({ value, label, placeholder, isDisabled, onChange, isError }, ref) => {
    return (
      <Wrapper>
        {!!label && (
          <Label>
            {label}

            {isError && (
              <div>
                <p className="error">
                  Title is too long (max {TEEUP_TITLE_LENGTH} characters)
                </p>
              </div>
            )}
          </Label>
        )}

        <Input
          ref={ref}
          type="text"
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
        />
      </Wrapper>
    )
  }
)

TransparentInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  ref: PropTypes.any,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
