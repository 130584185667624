import React, { useContext } from "react"

import { CONTACTS_TABS_LIST_TEXT, contactsTabsList } from "@constants/contacts"
import { InvitePeopleContext } from "@contexts/InvitePeopleContext"

import { MenuTabs } from "../MenuTabs"

import { Wrapper, TabsWrapper } from "./contactsListStyles"
import { List } from "./molecules"

export const ContactsList = () => {
  const { activeTab, setActiveTab } = useContext(InvitePeopleContext)

  return (
    <Wrapper>
      <TabsWrapper>
        <MenuTabs
          isSideBorder
          tabs={contactsTabsList}
          tabsText={CONTACTS_TABS_LIST_TEXT}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </TabsWrapper>

      <List key={activeTab} />
    </Wrapper>
  )
}
