import { useMemo, useState } from "react"

import cn from "classnames"
import { useSelector } from "react-redux"

import ParticipantIcon from "@components/TeeupPeople/Content/Participants/ParticipantItem/ParticipantIcon"
import { SvgIcon } from "@components/common"
import { selectTeeupParticipant } from "@selectors/teeups"
import { URL_PATTERN, getSuggestionOnlineOptions } from "@utils/suggestionUtils"
import { Preview, Suggestion, SuggestionStatus } from "types/suggestions"
import dayjs from "utils/dayjs"

import "./index.scss"
import PreviewReactionButtons from "./PreviewReactionButtons"
import Status from "./Status"
import useSubtitle from "./hooks/useSubtitle"

const reactionButtonsStatusBlacklist = new Set(["Past"])

interface Props {
  suggestion: Suggestion
  title: string
  subtitle: string
  onClick?: () => void
  preview?: Preview
}

const SuggestionItem = ({
  suggestion,
  title,
  subtitle,
  preview,
  onClick,
}: Props) => {
  const [currentStatus, setCurrentStatus] = useState<SuggestionStatus>({
    label: "",
    className: "",
  })
  const possibleIcon = useMemo(
    () =>
      getSuggestionOnlineOptions().find(item => item.label === title)?.iconName,
    [title]
  )

  const currentParticipant = useSelector(
    selectTeeupParticipant(
      // @ts-ignore
      suggestion?.creator?.id || suggestion?.when?.createdBy
    )
  )
  const suggestionCreatorName = suggestion?.creator?.name?.split(" ")?.[0]

  const { getSubtitle, isUrl } = useSubtitle({
    suggestion,
    subtitle,
  })

  return (
    <div className="suggestion-item" onClick={onClick}>
      <div className="suggestion-item__header">
        <div>
          <span>@{currentParticipant?.username || suggestionCreatorName}</span>{" "}
          suggested
        </div>

        <Status
          suggestion={suggestion}
          status={currentStatus}
          setStatus={setCurrentStatus}
        />
      </div>

      <div className="suggestion-item__content">
        <div>
          <ParticipantIcon
            avatar={suggestion?.creator?.avatar}
            defaultAvatar={suggestion?.creator?.name?.[0]}
          />
        </div>
        <div className="suggestion-item__content__info">
          <div>
            <h4>
              {possibleIcon && <SvgIcon name={possibleIcon} />}
              {title}
            </h4>
          </div>
          {possibleIcon && (
            <div
              className={cn("suggestion-item__content__info__time", {
                "suggestion-item__content__info__time__url": isUrl,
              })}>
              {getSubtitle()}
            </div>
          )}

          {!possibleIcon && (
            <div className="suggestion-item__content__info__time">
              {subtitle}
            </div>
          )}
        </div>

        {/* <div className="suggestion-item__content__reactions">
          <Reactions suggestion={suggestion} />
        </div> */}

        {preview?.isPreview &&
          !reactionButtonsStatusBlacklist.has(currentStatus?.label) && (
            <PreviewReactionButtons suggestion={suggestion} preview={preview} />
          )}
      </div>
    </div>
  )
}

export default SuggestionItem
