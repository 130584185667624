import styled from "styled-components"

import {
  NCaptionS,
  NFontWeight,
  NTitle2,
} from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Icon = styled(SvgIcon)`
  width: 56px;
  height: 56px;
`

export const Title = styled(NTitle2)`
  font-weight: ${NFontWeight.semiBold};
  text-align: center;
  margin: 10px 0 15px 0;
`

export const Description = styled(NCaptionS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  text-align: center;
`
