import React from "react"

import PropTypes from "prop-types"

import { FormErrorText } from "./molecules"

import "./formErrorMessage.scss"

export const FormErrorMessage = ({ errorMessage }) => (
  <FormErrorText
    className="form-error-message"
    dangerouslySetInnerHTML={{ __html: errorMessage }}
  />
)

FormErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}
