import React from "react"

import PropTypes from "prop-types"
import { View, Text, Image, StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"
import { contactTypes } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"

const styles = StyleSheet.create({
  icon: {
    width: Units.rem(0.875),
    height: Units.rem(0.875),
    resizeMode: "contain",
  },
  pinkIcon: {
    tintColor: AppColors.brand.pink,
  },
  greyIcon: {
    tintColor: AppColors.brand.warmGrey2,
  },
  subTitleText: {
    ...AppFonts.medium,
    color: AppColors.brand.darkGrey2,
    lineHeight: AppFonts.sizes.medium,
    marginLeft: Units.rem(0.25),
  },
  contactType: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Units.responsiveValue(4),
  },
})

const getIconAndLabel = contactType => {
  switch (contactType) {
    case contactTypes.USER:
      return {
        icon: images.teeups,
        iconStyles: styles.pinkIcon,
        // fallbackLabel: 'CooWe',
      }
    case contactTypes.PHONE:
      return {
        icon: images.phone,
        iconStyles: styles.greyIcon,
        fallbackLabel: "phone",
      }
    case contactTypes.EMAIL:
      return {
        icon: images.envelope,
        iconStyles: styles.greyIcon,
        fallbackLabel: "email",
      }
    default:
      return {
        icon: images.teeups,
        iconStyles: styles.pinkIcon,
        // fallbackLabel: 'CooWe',
      }
  }
}

const showUserIcon = (isCooeUser, contactType) =>
  contactType === contactTypes.PHONE ||
  contactType === contactTypes.EMAIL ||
  isCooeUser

const ContactType = ({ contactType, label, isCooeUser }) => {
  const { icon, iconStyles, fallbackLabel } = getIconAndLabel(contactType)
  const showIcon = showUserIcon(isCooeUser, contactType)
  if (!showIcon && !fallbackLabel) return null
  return (
    <View style={styles.contactType}>
      {showIcon && <Image style={[styles.icon, iconStyles]} source={icon} />}
      <Text style={styles.subTitleText}>{label || fallbackLabel}</Text>
    </View>
  )
}

ContactType.propTypes = {
  contactType: PropTypes.string,
  isCooeUser: PropTypes.bool,
  label: PropTypes.string,
}

export default ContactType
