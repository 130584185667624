export const EMAIL_VALIDATION_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/

export const CUSTOM_ACTION_KEY = "You"

export const IS_SHARE_POPUP_KEY = "IS_SHARED_CLOSED"

export const LAST_MINUTES_STEP = 45

export const SOMETHING_WENT_WRONG = "Something went wrong! Try again later."
