import React from "react"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import {
  setSelectedContact,
  removeSelectedContact,
} from "@actions/inviteesActions"
import { ContactAvatar, CheckboxEl, SvgIcon } from "@components/common"
import { loginProviders } from "@config/enums"
import { selectSelectedContacts } from "@selectors/invitees"
import { userTypes } from "@utils/contactUtils"

import {
  Wrapper,
  LeftSide,
  NameInfo,
  NameFirstLine,
  Name,
  Username,
} from "./listContactStyles"

export const ListContact = ({ contact }) => {
  const { id, avatar, name, username, type, syncType } = contact

  const dispatch = useDispatch()
  const selectedContacts = useSelector(selectSelectedContacts)
  const isSelected = selectedContacts.some(selected => selected.id === id)

  const toggleIsSelected = () => {
    isSelected
      ? dispatch(removeSelectedContact(id))
      : dispatch(setSelectedContact(contact))
  }

  const getTypeIcon = () => {
    if (syncType === loginProviders.google) {
      return <SvgIcon name="google" />
    }

    if (type === userTypes.COO_E) {
      return <SvgIcon name="coowe-small-icon" />
    }

    return false
  }

  return (
    <Wrapper>
      <LeftSide>
        <ContactAvatar src={avatar} text={name} />

        <NameInfo>
          <NameFirstLine>
            <Name>{name}</Name>

            {getTypeIcon()}
          </NameFirstLine>

          {username && <Username>@{username}</Username>}
        </NameInfo>
      </LeftSide>

      <CheckboxEl
        label=""
        name=""
        value={isSelected}
        onChange={toggleIsSelected}
      />
    </Wrapper>
  )
}

ListContact.propTypes = {
  contact: PropTypes.object.isRequired,
}
