import React, { memo, useState } from "react"

import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"

// import backend from '@apis/backend'
import { conversationTypeIds } from "@configs/mappings"
import { getFileSizeFromBytes } from "@utils/fileUtils"
import { images, getFileIcon } from "@utils/imageUtils"

import TextMessage from "../textMessage/textMessage"

import styles, { repliedMessageContainer } from "./replyMessageStyles"

const CommentedText = ({ name, text }) => (
  <View style={repliedMessageContainer(false)}>
    <View style={styles.repliedMessageBar} />
    <Text style={styles.replyMessageSender}>{name}</Text>
    <Text style={styles.replyMessageText}>{text}</Text>
  </View>
)

const CommentedFile = ({ name, file }) => {
  const { size, sizeType } = getFileSizeFromBytes(file.size, true, false)

  return (
    <View style={repliedMessageContainer(true)}>
      <View style={styles.repliedMessageBar} />
      <Image style={styles.generalIcon} source={images.fileGrey} />
      <View style={styles.attachmentContainer}>
        <Text style={styles.attachmentText}>{name} posted</Text>
        <Text style={styles.attachmentText}>
          {file.name + file.type} {size}
          {sizeType}
        </Text>
      </View>
      <View style={styles.attachmentIconWrapper}>
        <Image style={styles.attachmentIcon} source={getFileIcon(file.type)} />
      </View>
    </View>
  )
}

const CommentedImage = ({ name, image }) => {
  const [imageSize, setImageSize] = useState(null)

  // useEffect(() => {
  //     backend.getRemoteImageSize(image, (bytes) => {
  //         const { size, sizeType } = getFileSizeFromBytes(bytes, true, true)
  //         setImageSize(`${size}${sizeType}`)
  //     })
  // }, [image])

  return (
    <View style={repliedMessageContainer(true)}>
      <View style={styles.repliedMessageBar} />
      <Image style={styles.generalIcon} source={images.cameraGrey} />
      <View style={styles.attachmentContainer}>
        <Text style={styles.attachmentText}>{name} posted</Text>
        {imageSize && <Text style={styles.attachmentText}>{imageSize}</Text>}
      </View>
      <View style={styles.attachmentIconWrapper}>
        <Image style={styles.imageThumb} source={{ uri: image || "" }} />
      </View>
    </View>
  )
}

const RepliedMessage = ({ author, message }) => {
  const { conversationTypeId, file, image, text } = message

  if (text) return <CommentedText name={author} text={text} />
  if (conversationTypeId === conversationTypeIds.file && file)
    return <CommentedFile name={author} file={file} />
  if (conversationTypeId === conversationTypeIds.image && image)
    return <CommentedImage name={author} image={image} />
  return false
}

const ReplyMessage = ({
  repliedMessageAuthor,
  repliedMessage,
  text,
  name,
  isMyMessage,
  renderMentionedUser,
  onUserPress,
}) => (
  <>
    <View style={styles.senderContainer}>
      {!!repliedMessage.text && (
        <Image source={isMyMessage ? images.replyBlue : images.replyGrey} />
      )}
      <Text style={styles.senderText}>
        {repliedMessage.text
          ? isMyMessage
            ? "My reply to"
            : `${name} replied to`
          : isMyMessage
          ? "My comment on"
          : `${name} commented on`}
      </Text>
    </View>
    <RepliedMessage message={repliedMessage} author={repliedMessageAuthor} />
    <View style={styles.messageContainer}>
      <TextMessage
        text={text}
        renderMentionedUser={renderMentionedUser}
        onUserPress={onUserPress}
        isMyMessage={isMyMessage}
      />
    </View>
  </>
)

ReplyMessage.propTypes = {
  repliedMessageAuthor: PropTypes.string.isRequired,
  repliedMessage: PropTypes.object,
  isMyMessage: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  renderMentionedUser: PropTypes.func,
  onUserPress: PropTypes.func,
}

RepliedMessage.propTypes = {
  author: PropTypes.string,
  message: PropTypes.object,
}

CommentedText.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
}

CommentedFile.propTypes = {
  name: PropTypes.string,
  file: PropTypes.object,
}

CommentedImage.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
}

export default memo(ReplyMessage)
