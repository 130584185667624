import React, { useContext, useEffect, useState } from "react"

import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { useSelector } from "react-redux"

import { SvgIcon } from "@components/common"
import { selectContactsByTags } from "@selectors/contacts"
import { groupByAlphabet } from "@utils/groupByAlphabet"
import { frequentSorting, recentSorting } from "@utils/teeupUtils"
import { InvitePeopleContext } from "contexts/InvitePeopleContext"

import { ListContact } from "../molecules"

import {
  Panel,
  StyledList,
  ListItem,
  GroupTitle,
  InnerList,
} from "./listStyles"

import "./index.scss"

export const List = () => {
  const [isReverse, setIsReverse] = useState(false)
  const [currentSort, setCurrentSort] = useState("name")
  const contactsByTags = useSelector(selectContactsByTags)
  const [list, setList] = useState([])
  const { activeTab, contactsCategory } = useContext(InvitePeopleContext)

  useEffect(() => {
    setList(groupByAlphabet(contactsCategory))
  }, [activeTab, contactsCategory])

  let groupsList = Object.keys(list).sort((a, b) =>
    a.localeCompare(b, "en", { sensitivity: "base" })
  )

  if (isReverse) {
    groupsList = groupsList.reverse()
  }

  // const toggleIsReverse = () => {
  //   setIsReverse(prevState => !prevState)
  // }

  const handleSortChange = value => {
    switch (value) {
      case "name": {
        setList(groupByAlphabet(contactsCategory))

        break
      }
      case "frequent": {
        const sorted = frequentSorting(Object.values(contactsCategory))
        setList(sorted)

        break
      }
      case "recent": {
        const sorted = recentSorting(Object.values(contactsCategory))
        setList(sorted)

        break
      }
      // No default
    }
    setCurrentSort(value)
  }

  return (
    <>
      <Panel>
        <FormControl variant="standard" className="contacts-list__sort">
          <Select
            value={currentSort}
            onChange={e => handleSortChange(e.target.value)}
            IconComponent={() => (
              <SvgIcon
                name="double-arrow"
                onClick={() => handleSortChange("name")}
              />
            )}>
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"frequent"}>Frequent</MenuItem>
            <MenuItem value={"recent"}>Recent</MenuItem>
          </Select>
        </FormControl>
        {/* <ButtonEl
          autoWidth
          variant={BUTTON_VARIANTS.TRANSPARENT}
          iconName="double-arrow"
          text="Name"
          textColor="var(--gray4)"
          onClick={toggleIsReverse}
        /> */}
      </Panel>

      <StyledList>
        {currentSort === "name" &&
          groupsList.map((groupName, index) => (
            <>
              <ListItem key={index}>
                <GroupTitle>{groupName}</GroupTitle>
              </ListItem>

              <InnerList key={`${index}-inner-list`}>
                {list[groupName].map(contact => (
                  <ListContact contact={contact} key={contact.id} />
                ))}
              </InnerList>
            </>
          ))}

        {currentSort !== "name" &&
          Object.values(list).map(contact => (
            <ListContact contact={contact} key={contact.id} />
          ))}
      </StyledList>
    </>
  )
}
