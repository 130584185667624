import PropTypes from "prop-types"

import { Button, SvgEl } from "./iconButtonElStyles"

export const IconButtonEl = ({
  icon,
  alt,
  isSvg,
  size,
  disabled = false,
  onClick,
}) => (
  <Button $disabled={disabled} disabled={disabled} onClick={onClick}>
    {/* {isSvg ? (
        <SvgEl name={icon} $size={24} />
      ) : ( */}
    <img src={icon} alt={alt || "icon"} />
    {/* )} */}
  </Button>
)

IconButtonEl.defaultProps = {
  icon: PropTypes.any,
  isSvg: PropTypes.bool,
  alt: PropTypes.string,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
