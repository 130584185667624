import React from "react"

import { Title, Description } from "./atoms"
import { Wrapper } from "./makesMeetingHappenStyles"
import { Overviews, Footer } from "./molecules"

export const MakesMeetingHappen = () => (
  <Wrapper>
    <Title />

    <Description />

    <Overviews />

    <Footer />
  </Wrapper>
)
