import { useState } from "react"

export const useApiErrors = () => {
  const [apiErrors, setApiErrors] = useState({})

  const addApiError = (errorName, errorMessage) => {
    setApiErrors(prevState => ({
      ...prevState,
      [errorName]: errorMessage,
    }))
  }

  const removeApiError = errorName => {
    setApiErrors(prevState => {
      const errors = { ...prevState }
      delete errors[errorName]

      return errors
    })
  }

  const removeAllApiErrors = () => {
    setApiErrors({})
  }

  return { apiErrors, addApiError, removeApiError, removeAllApiErrors }
}
