import { useMemo } from "react"

import ct from "countries-and-timezones"

import dayjs from "utils/dayjs"

import { useDeviceTimezone } from "hooks"

// const getTimezones = () => ct.getAllTimezones()

const getName = ({ name, key } = {}) => {
  if (!name) {
    return "(You)"
  } else if (key) {
    return `${name} (You)`
  } else {
    return name
  }
}

export const useReactionsList = (reactions, participants) => {
  const { currentDeviceTimezone } = useDeviceTimezone({})

  return useMemo(() => {
    return reactions?.reduce((acc, current) => {
      current.reactedBy.forEach(participant => {
        const isId = typeof participant === "number"
        const participantFromList = isId
          ? participants.find(p => p.id === participant)
          : participant
        const timezone = participant.timezone || currentDeviceTimezone
        const timezoneLabel = timezone?.split("/")?.[1]

        const name = getName(participantFromList)
        const timezoneUTC = dayjs().tz(timezone).format("z Z")

        acc.push({
          ...participantFromList,
          reactionId: current.reactionId,
          name,
          timezone: timezoneLabel,
          timezoneUTC,
        })
      })

      return acc
    }, [])
  }, [reactions])
}
