import React, { createContext, useContext, useReducer, useEffect } from "react"

import { connect } from "react-redux"
import { useParams, useLocation } from "react-router-dom"

import { getTeeupById } from "../../../actions/activeTeeupActions"
import { selectActiveTeeupId } from "../../../selectors/activeTeeup"

import { TeeUpPageReducer } from "./reducer"

const TeeUpPageStateContext = createContext({})
const TeeUpPageDispatchContext = createContext(undefined)

const TeeUpPageProvider = props => {
  const { children, activeTeeupId } = props
  const [state, dispatch] = useReducer(TeeUpPageReducer, {
    interactionArea: null, // area that opens for example when suggestions are clicked. it pushes the conversation to the right, and right side area gets closed.
    openPeopleArea: false,
    // isSuggestWhenModalOpen: false,
    // isSuggestWhereModalOpen: false,
    isThreeDotsDropDownOpen: false,
  })
  const param = useParams()
  const location = useLocation()

  // fetch teeup by id on teeup overview load
  useEffect(() => {
    if (activeTeeupId) return

    if (param.id && location.pathname.includes("teeUps")) {
      getTeeupById(param.id)
    }
  }, [param.id, location.pathname, activeTeeupId])

  return (
    <TeeUpPageStateContext.Provider value={state}>
      <TeeUpPageDispatchContext.Provider value={dispatch}>
        {children}
      </TeeUpPageDispatchContext.Provider>
    </TeeUpPageStateContext.Provider>
  )
}

const useTeeUpPageState = () => {
  const context = useContext(TeeUpPageStateContext)

  if (context === undefined) {
    throw new Error("useTeeUpPageState must be used within a TeeUpPageProvider")
  }
  return context
}

const useTeeUpPageDispatch = () => {
  const context = useContext(TeeUpPageDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useTeeUpPageDispatch must be used within a TeeUpPageProvider"
    )
  }
  return context
}

const mapStateToProps = state => {
  const activeTeeupId = selectActiveTeeupId(state)

  return {
    activeTeeupId,
  }
}

export default connect(mapStateToProps)(TeeUpPageProvider)

export { TeeUpPageProvider, useTeeUpPageState, useTeeUpPageDispatch }
