export const CONTACTS_TABS_LIST = {
  ALL: "ALL",
  CONTACTS: "CONTACTS",
  COOWE_PEOPLE: "COOWE_PEOPLE",
}

export const CONTACTS_TABS_LIST_TEXT = {
  ALL: "All",
  CONTACTS: "Contacts",
  COOWE_PEOPLE: "CooWe People",
}

export const contactsTabsList = Object.keys(CONTACTS_TABS_LIST)
