import React from "react"

import TeeupSettingsRow from "./TeeupSettingsRow"

import "./index.scss"

const TeeupSettingsRightSide = ({ teeUpSettings, handleChangeSettings }) => {
  return (
    <div className="teeup-settings__container right-side">
      <h3 className="teeup-settings__subtitle">allow anyone to</h3>
      {teeUpSettings.slice(0, 6).map((item, index) => (
        <TeeupSettingsRow
          index={index}
          item={item}
          key={index}
          toggle
          onChange={() => {
            handleChangeSettings(
              {
                ...item,
                value: !item.value,
              },
              index
            )
          }}
        />
      ))}

      <h3 className="teeup-settings__subtitle">sharing TeeUp</h3>
      {teeUpSettings.slice(6).map((item, index) => (
        <TeeupSettingsRow
          index={index}
          item={item}
          key={index}
          toggle
          theLast={index === 1}
          onChange={() => {
            handleChangeSettings(
              {
                ...item,
                value: !item.value,
              },
              index + 6
            )
          }}
        />
      ))}
    </div>
  )
}

export default TeeupSettingsRightSide
