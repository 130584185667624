import React from "react"

import { Wrapper, Content } from "./fullScreenLayoutStyles"
import { Header } from "./molecules"

export const FullScreenLayout = ({ title, onClose, children }) => (
  <Wrapper>
    <Content>
      <Header title={title} onClose={onClose} />

      {children}
    </Content>
  </Wrapper>
)
