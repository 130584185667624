// import Config from 'react-native-config'

// const fetchConfig = {
//     ios: {
//         headers: {
//             'x-ios-bundle-identifier': Config.BUNDLE_ID,
//         },
//         key: Config.GOOGLE_API_IOS_KEY,
//     },
//     android: {
//         headers: {
//             'x-android-package': Config.BUNDLE_ID,
//             'x-android-cert': Config.ANDROID_CERT,
//         },
//         key: Config.GOOGLE_API_ANDROID_KEY,
//     },
// }

const url = "https://maps.googleapis.com/maps/api"

const endpoints = {
  autocompletePlace: `${url}/place/queryautocomplete/json`,
  autocompletePlaceWithoutId: `${url}/place/autocomplete/json`,
  nearbySearch: `${url}/place/nearbysearch/json`,
  findByPlaceId: `${url}/geocode/json`,
}

export default endpoints
