import { useCallback, useContext } from "react"

import { EMAIL_VALIDATION_REGEX } from "@constants/global"
import { getEmailBeforeDog } from "@utils/getEmailBeforeDog"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

export const useSubmit = ({
  status,
  timezone,
  email,
  fullName,
  invitee,
  isSharedLink,
  isPhoneInvite,
  validateForm,
  setIsSubmitting,
  addApiError,
  closeModal,
}) => {
  const { setGuestStatus, setIsResponseInfoModal, setConfirmingStatus } =
    useContext(TeeupPreviewContext)

  const getOriginName = useCallback(() => {
    if (invitee) {
      const inviteeVal = invitee.value
      const isEmail = EMAIL_VALIDATION_REGEX.test(inviteeVal)

      if (isEmail) {
        return getEmailBeforeDog(inviteeVal)
      }

      return inviteeVal
    }
  }, [invitee])

  return async () => {
    setIsSubmitting(true)
    const errors = await validateForm()
    if (!!errors) {
      return setIsSubmitting(false)
    }

    const confirmObject = {
      status,
      timezone,
      fullName: fullName || getOriginName(),
    }

    const isSameEmail = invitee.value === email
    if ((isSharedLink || isPhoneInvite) && !!email && !isSameEmail) {
      confirmObject.email = email
    }

    const { isErrors, data } = await setGuestStatus(confirmObject)
    if (isErrors) {
      setIsSubmitting(false)
      for (let key in data) {
        addApiError(key, data[key])
      }
      return
    }

    closeModal()
    setIsSubmitting(false)

    setConfirmingStatus(data.status)
    setIsResponseInfoModal(true)
  }
}
