import _ from "lodash"

import actionTypes from "@actions/actionTypes"

export const initialState = {
  newSuggestions: {},
  removedSuggestions: {},
  newSelectedGP: {},
  newDecidedSuggestions: {},
  newUndecidedSuggestions: {},
  recommendations: {},
  suggestionsWithDetails: [],
  notificationMessageId: null,
  isOnboardingWasShown: false,
  notifications: [],
  ignoredUndecidedNudgeTeeups: [],
  ignoredAllSetNudgeTeeups: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IGNORED_UNDECIDED_NUDGES: {
      const teeupId = action.payload
      return _.assign({}, state, {
        ignoredUndecidedNudgeTeeups: [
          ...state.ignoredUndecidedNudgeTeeups,
          teeupId,
        ],
      })
    }
    case actionTypes.SET_IGNORED_ALLSET_NUDGES: {
      const teeupId = action.payload
      return _.assign({}, state, {
        ignoredAllSetNudgeTeeups: [...state.ignoredAllSetNudgeTeeups, teeupId],
      })
    }
    case actionTypes.PROMOTE_TO_GAMEPLAN:
    case actionTypes.REMOVE_IGNORED_UNDECIDED_NUDGES: {
      const teeupId = action.payload
      const filteredIgnoredUndecidedNudgeTeeups =
        state.ignoredUndecidedNudgeTeeups.filter(item => item !== teeupId)
      return _.assign({}, state, {
        ignoredUndecidedNudgeTeeups: filteredIgnoredUndecidedNudgeTeeups,
      })
    }
    case actionTypes.REMOVE_IGNORED_ALLSET_NUDGES: {
      const teeupId = action.payload
      const filteredIgnoredAllSetNudgeTeeups =
        state.ignoredAllSetNudgeTeeups.filter(item => item !== teeupId)
      return _.assign({}, state, {
        ignoredAllSetNudgeTeeups: filteredIgnoredAllSetNudgeTeeups,
      })
    }
    case actionTypes.SET_SHOW_SUGGESTION_DETAILS: {
      const { suggestionId } = action.payload
      const suggestionsWithDetails = [
        ...state.suggestionsWithDetails,
        suggestionId,
      ]

      return _.assign({}, state, {
        suggestionsWithDetails,
      })
    }
    case actionTypes.GOT_TEEUPS_PARTS:
    case actionTypes.GOT_TEEUP_PARTS: {
      console.log("GOT_TEEUP_PARTS")
      let allParts = action.payload
      let teeupSuggestions = action.payload[1] || {}
      let selectedGameplans = {}

      allParts.forEach(part => {
        if (!part || !part.teeupId) {
          return
        }

        if (part.suggestions) {
          let teeupId = part.teeupId
          teeupSuggestions[teeupId] = {}
          selectedGameplans[teeupId] = {}
          Object.keys(part.suggestions).forEach(suggestion => {
            teeupSuggestions[teeupId][suggestion] = {
              isNew:
                state.newSuggestions[teeupId] &&
                state.newSuggestions[teeupId][suggestion]
                  ? state.newSuggestions[teeupId][suggestion].isNew
                  : true,
            }
            selectedGameplans[teeupId][suggestion] = {
              recentlyPromoted:
                state.newSelectedGP[teeupId] &&
                state.newSelectedGP[teeupId][suggestion]
                  ? state.newSelectedGP[teeupId][suggestion].recentlyPromoted
                  : part.suggestions[suggestion].selected,
              selected: part.suggestions[suggestion].selected,
            }
          })
        }
      })
      return _.assign({}, state, {
        newSuggestions: teeupSuggestions,
        newSelectedGP: selectedGameplans,
      })
    }

    case actionTypes.GOT_NOTIFICATION: {
      const notification = action.payload
      return _.assign({}, state, {
        notifications: [...state.notifications, notification],
      })
    }

    case actionTypes.CLEAR_NOTIFICATIONS: {
      const teeupId = action.payload
      const filteredNotifications = state.notifications.filter(
        item => item?.teeupId !== teeupId
      )
      return _.assign({}, state, {
        notifications: filteredNotifications,
      })
    }

    case actionTypes.UPDATE_SUGGESTIONS: {
      const { response, teeupId } = action.payload

      let suggestionsIsNew = {}

      Object.keys(response.suggestions).forEach(suggestionId => {
        if (
          //
          state.newSuggestions[teeupId] &&
          state.newSuggestions[teeupId][suggestionId]
        ) {
          suggestionsIsNew[suggestionId] = {
            isNew: false,
          }
        } else {
          suggestionsIsNew[suggestionId] = {
            isNew: true,
          }
        }
      })

      return _.assign({}, state, {
        newSuggestions: {
          ...state.newSuggestions,
          [teeupId]: {
            ...state.newSuggestions[teeupId],
            ...suggestionsIsNew,
          },
        },
      })
    }

    case actionTypes.GOT_GAMEPLANS: {
      const { suggestions, teeupId } = action.payload
      const prevGameplans = state.newSelectedGP[teeupId] || {}
      let teeupGameplans = {}

      Object.keys(suggestions).forEach(suggestion => {
        teeupGameplans[suggestion] = {}
        const prevSelected =
          prevGameplans && (prevGameplans[suggestion] || {}).selected

        if (suggestions[suggestion].selected === prevSelected) {
          teeupGameplans[suggestion] = {
            ...state.newSelectedGP[teeupId][suggestion],
          }
        } else {
          teeupGameplans[suggestion].selected = suggestions[suggestion].selected
          teeupGameplans[suggestion].recentlyPromoted =
            suggestions[suggestion].selected
        }
      })

      return _.assign({}, state, {
        newSelectedGP: {
          ...state.newSelectedGP,
          [teeupId]: teeupGameplans,
        },
      })
    }

    case actionTypes.REMOVE_SUGGESTION_REMOVED_STATUS: {
      // const { suggestionId, teeupId } = action.payload

      // let suggestionsIsRemoved = {
      //     [suggestionId]: {
      //         isRemoved: false,
      //     },
      // }
      // console.log('removeeee')
      // return _.assign({}, state, {
      //     removedSuggestions: {
      //         ...state.removedSuggestions,
      //         [teeupId]: {
      //             ...state.removedSuggestions[teeupId],
      //             ...suggestionsIsRemoved,
      //         },
      //     },
      // })
      return _.assign({}, state, {
        removedSuggestions: {},
      })
    }

    case actionTypes.SET_SUGGESTION_REMOVED_STATUS: {
      const { suggestionId, teeupId } = action.payload
      let suggestionsIsRemoved = {
        [suggestionId]: {
          isRemoved: true,
        },
      }

      return _.assign({}, state, {
        removedSuggestions: {
          ...state.removedSuggestions,
          [teeupId]: {
            ...state.removedSuggestions[teeupId],
            ...suggestionsIsRemoved,
          },
        },
      })
    }

    case actionTypes.REMOVE_SUGGESTION_NEW_DECIDED_STATUS: {
      const { suggestionId, teeupId } = action.payload

      let suggestionsIsDecided = {
        [suggestionId]: {
          isNewDecided: false,
        },
      }

      return _.assign({}, state, {
        newDecidedSuggestions: {
          ...state.newDecidedSuggestions,
          [teeupId]: {
            ...state.newDecidedSuggestions[teeupId],
            ...suggestionsIsDecided,
          },
        },
      })
    }

    case actionTypes.SET_SUGGESTION_NEW_DECIDED_STATUS: {
      const { suggestionId, teeupId } = action.payload
      let suggestionsIsDecided = {
        [suggestionId]: {
          isNewDecided: true,
        },
      }

      return _.assign({}, state, {
        newDecidedSuggestions: {
          ...state.newDecidedSuggestions,
          [teeupId]: {
            ...state.newDecidedSuggestions[teeupId],
            ...suggestionsIsDecided,
          },
        },
      })
    }

    case actionTypes.SET_SUGGESTION_NEW_UNDECIDED_STATUS: {
      const { suggestionId, teeupId } = action.payload
      let suggestionsIsUndecided = {
        [suggestionId]: {
          isNewUndecided: true,
        },
      }

      return _.assign({}, state, {
        newUndecidedSuggestions: {
          ...state.newUndecidedSuggestions,
          [teeupId]: {
            ...state.newUndecidedSuggestions[teeupId],
            ...suggestionsIsUndecided,
          },
        },
      })
    }

    case actionTypes.REMOVE_SUGGESTION_NEW_UNDECIDED_STATUS: {
      const { suggestionId, teeupId } = action.payload

      let suggestionsIsUndecided = {
        [suggestionId]: {
          isNewUndecided: false,
        },
      }

      return _.assign({}, state, {
        newUndecidedSuggestions: {
          ...state.newUndecidedSuggestions,
          [teeupId]: {
            ...state.newUndecidedSuggestions[teeupId],
            ...suggestionsIsUndecided,
          },
        },
      })
    }

    case actionTypes.REMOVE_SUGGESTION_NEW_STATUS: {
      const { suggestionId, teeupId } = action.payload

      let suggestionsIsNew = {
        [suggestionId]: {
          isNew: false,
        },
      }

      return _.assign({}, state, {
        newSuggestions: {
          ...state.newSuggestions,
          [teeupId]: {
            ...state.newSuggestions[teeupId],
            ...suggestionsIsNew,
          },
        },
      })
    }

    case actionTypes.SET_SUGGESTION_NEW_STATUS: {
      const { suggestionId, teeupId } = action.payload
      let suggestionsIsNew = {
        [suggestionId]: {
          isNew: true,
        },
      }

      return _.assign({}, state, {
        newSuggestions: {
          ...state.newSuggestions,
          [teeupId]: {
            ...state.newSuggestions[teeupId],
            ...suggestionsIsNew,
          },
        },
      })
    }

    case actionTypes.SET_SUGGESTION_PROMOTED_STATUS: {
      const { suggestionId, teeupId } = action.payload

      let suggestionsIsPromoted = {
        [suggestionId]: {
          recentlyPromoted: false,
          selected: state.newSelectedGP[teeupId][suggestionId].selected,
        },
      }

      return _.assign({}, state, {
        newSelectedGP: {
          ...state.newSelectedGP,
          [teeupId]: {
            ...state.newSelectedGP[teeupId],
            ...suggestionsIsPromoted,
          },
        },
      })
    }

    case actionTypes.SET_NOTIFICATION_MESSAGE_ID: {
      const message = action.payload

      return _.assign({}, state, { notificationMessageId: message })
    }

    case actionTypes.SET_ONBOARDING_WAS_SHOWN: {
      const isOnboardingWasShown = action.payload
      return _.assign({}, state, { isOnboardingWasShown })
    }

    default:
      return state
  }
}
