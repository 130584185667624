import React, { memo, useRef, useState } from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { SvgIcon } from "@components/common"
import { loginProviders } from "@config/enums"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { EVENT_TYPES } from "@utils/calendarUtils"
import { usePreviewScreenStore } from "stores/previewScreen"

import { InnerEventsList } from "../molecules"

import { Wrapper, Main, MainLeft, Text, ArrowIcon } from "./innerEventsStyles"

const InnerEvents = ({ event, linkedCalendar }) => {
  const { eventType, innerEvents } = event
  const isCalendar = eventType === EVENT_TYPES.calendar
  const isCalendarIcon = isCalendar && !!linkedCalendar
  const arrowColor = isCalendar ? "var(--red3)" : "var(--darkBlue)"
  const calendarIconName =
    linkedCalendar?.provider === loginProviders.google ? "google" : "outlook-2"

  const makeSuggestionInnerEventsOpen = usePreviewScreenStore(
    state => state.makeSuggestionInnerEventsOpen
  )
  const setMakeSuggestionInnerEventsOpen = usePreviewScreenStore(
    state => state.setMakeSuggestionInnerEventsOpen
  )
  const isOpen = makeSuggestionInnerEventsOpen.includes(event.id)

  return (
    <Wrapper>
      <Main
        onMouseDown={e => {
          e.preventDefault()
          setMakeSuggestionInnerEventsOpen(event.id)
        }}>
        <MainLeft>
          {isCalendarIcon && <SvgIcon name={calendarIconName} />}
          <Text>+{innerEvents.length} items</Text>
        </MainLeft>

        <ArrowIcon $isOpen={isOpen} name="arrow-2" color={arrowColor} />
      </Main>

      {isOpen && <InnerEventsList event={event} />}
    </Wrapper>
  )
}

InnerEvents.propTypes = {
  event: PropTypes.object,
}

const mapStateToProps = state => ({
  linkedCalendar: selectGuestLinkedCalendar(state),
})

export default connect(mapStateToProps)(memo(InnerEvents))
