import { useContext, useState, useCallback } from "react"

import { useDispatch, useSelector } from "react-redux"

import { updateTeeupOrganisers } from "@actions/activeTeeupActions"
import { teeupUserStatusKeys } from "@config/enums"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { selectUserId } from "@selectors/user"

import {
  addTeeupInvitees,
  toggleOrganiser,
} from "../../../actions/teeupActions"

export const useActions = person => {
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [isSentInvitationModal, setIsSentInvitationModal] = useState(false)
  const dispatch = useDispatch()
  const {
    teeup,
    localOrganisers,
    setLocalOrganisers,
    disableOrganiserActions,
    setSettingsType,
  } = useContext(NewTeeupPageContext)
  const authedUserId = useSelector(selectUserId)
  const ifAuthedUserOrganiser = disableOrganiserActions(person)
  const isInvited = teeupUserStatusKeys.invited === person.status

  const saveOrganisers = useCallback(
    newOrganisers => {
      setLocalOrganisers(newOrganisers)
      updateTeeupOrganisers(teeup.id, newOrganisers)
    },
    [teeup.id]
  )

  const makeOrganizerAction = useCallback(() => {
    const newOrganisers = [...localOrganisers, person]
    saveOrganisers(newOrganisers)
    dispatch(toggleOrganiser({ teeupId: teeup.id, personId: person.id }))
  }, [localOrganisers, person, teeup.id, dispatch, saveOrganisers])

  const demoteOrganizerAction = useCallback(() => {
    const newOrganisers = localOrganisers.filter(({ id }) => id !== person.id)
    saveOrganisers(newOrganisers)
    dispatch(toggleOrganiser({ teeupId: teeup.id, personId: person.id }))
    if (authedUserId === person.id) {
      setSettingsType(null)
    }
  }, [localOrganisers, person.id, teeup.id, dispatch, saveOrganisers])

  return {
    isConfirmDelete,
    setIsConfirmDelete,
    isSentInvitationModal,
    setIsSentInvitationModal,
    actionsList: [
      {
        isHidden: ifAuthedUserOrganiser || isInvited || person.isOrganiser,
        text: "Make an organizer",
        icon: "organiser",
        action: makeOrganizerAction,
      },
      {
        isHidden: ifAuthedUserOrganiser || isInvited || !person.isOrganiser,
        text: "Demote from Organizer",
        icon: "remove-user",
        action: demoteOrganizerAction,
      },
      {
        isHidden: !isInvited,
        text: "Resend Invite",
        icon: "repeat",
        action: () => {
          addTeeupInvitees(teeup.id, [{ id: person.id }]).then(() => {
            setIsSentInvitationModal(true)
          })
        },
      },
      {
        isHidden: ifAuthedUserOrganiser,
        text: "Remove from the TeeUp",
        icon: "remove-user",
        action: () => setIsConfirmDelete(true),
      },
    ].filter(item => !item.isHidden),
  }
}
