export const setIdentify = async ({ userId, ...payload }) => {
  await window.analytics.identify(userId, { ...payload })
}

export const logEvent = async (eventName, properties) => {
  await window.analytics.track(
    eventName,
    { properties },
    { "Facebook Pixel": { contentType: "mycustomtype" } } // add this line based on https://segment.com/docs/connections/destinations/catalog/facebook-pixel/#track
  )
}

export const logScreen = async (screenName, args) => {
  await window.analytics.page({ properties: { screenName, ...args } })
}

export const EVENTS = {
  // CooWe Coordinator
  SIGN_UP: "Sign Up", //X
  // Sign In
  SIGN_IN: "Sign In", //X
  // Create Teeup
  CREATE_TEEUP: "Create TeeUp", //X
  // TeeUp List
  MOVE_TEEUP_TO_ARCHIVE: "Move TeeUp to Archive", //X
  MOVE_TEEUP_TO_TRASH: "Move TeeUp to Trash", //X
  // TeeUp
  SET_WHEN_AS_DECIDED: "Set When as Decided",
  SET_WHERE_AS_DECIDED: "Set Where as Decided",
  CHANGE_TEEUP_STATUS: "Change TeeUp Status", //X
  CHANGE_USER_STATUS: "Change User Status", //X
  ADD_TO_PINBOARD: "Add to Pinboard", //X
  REMOVE_FROM_PINBOARD: "Remove from Pinboard", //X
  ADD_REACTION: "Add Reaction",
  RETRACT_REACTION: "Retract Reaction",
  SEND_MESSAGE: "Send Message", //X
  SEND_NUDGE: "Send Nudge",
  // TeeUp Settings
  CHANGE_TEEUP_INFO: "Change TeeUp Information",
  CHANGE_TEEUP_PERMISSIONS: "Change TeeUp Permissions",
  CHANGE_TEEUP_PROGRESSION_SETTINGS: "Change TeeUp Progression Settings",
  ADD_ORGANISER: "Add Organizer",
  REMOVE_ORGANISER: "Remove Organizer",
  CANCEL_TEEUP: "Cancel TeeUp",
  DROP_OUT_OF_TEEUP: "Drop Out of TeeUp",
  REMOVE_PARTICIPANT: "Remove Participant",
  // Invite people
  INVITE_TO_TEEUP: "Invite to TeeUp",
  //Suggest When / Where
  ADD_WHEN_SUGGESTION: "Add When Suggestion",
  ADD_WHERE_SUGGESTION: "Add Where Suggestion",
  // My People
  VIEW_PROFILE: "View Profile",
  TAG_USER: "Tag User",
  EDIT_USER_TAG: "Edit User Tag",
  // User Profile
  UPDATE_USER: "Update user",
  // Account Management
  LOGOUT: "Logout", //X
  ADD_RECOVERY_EMAIL: "Add recovery email",
  ADD_RECOVERY_PHONE: "Add recovery phone",
  ADD_SIGN_IN_METHOD: "Add signIn method",
  REMOVE_SIGN_IN_METHOD: "Remove signIn method",
  CHANGE_PASSWORD: "Change password",
  // Connection Details
  ADD_CONNECTION_EMAIL: "Add connection email",
  ADD_CONNECTION_PHONE: "Add connection phone",
  REMOVE_CONNECTION_EMAIL: "Remove connection email",
  REMOVE_CONNECTION_PHONE: "Remove connection phone",
  // Sync contacts
  ENABLE_CONTACTS_SYNC: "Enable contacts sync",
  DISABLE_CONTACTS_SYNC: "Disable contacts sync",
  // Calendars
  SELECT_MAIN_CALENDAR: "Select main caledar",
  REMOVE_MAIN_CALENDAR: "Remove main caledar",
  ADD_DISPLAY_CALENDAR: "Add Display calendar",
  REMOVE_DISPLAY_CALENDAR: "Remove Display calendar",
  // Time-Zones
  UPDATE_ACTIVE_TIMEZONE: "Update active timezone",
  // Notification settings
  SET_IN_APP_SOUNDS: "Set In-App Sounds",
  SET_RECEIVE_PUSH_NOTIFICATIONS: "Set receive Push Notifications",
  UNSET_RECEIVE_PUSH_NOTIFICATIONS: "Unset receive Push Notifications",
  UPDATE_NOTIFICATION_SETTINGS: "Update notification settings",

  LEARN_MORE: "Learn More",
  GIVE_FEEDBACK: "Give Feedback",
}

export const SCREENS = {
  TEEUP_LIST: "TeeUp List", //X
  TEEUP: "TeeUp", //X
  ME: "Me", //X
  USER_PROFILE: "User Profile", //X
  EDIT_USER_PROFILE: "Edit User Profile",
  SETTINGS: "Settings", //X
  ACCOUNT_MANAGEMENT: "Account Management", //X
  INVITATIONS_DETAILS: "Invitations Details", //X
  SYNC_CONTACTS: "Sync Contacts", //X
  CALENDARS: "Calendars Settings", //X
  TIME_ZONES: "Time-Zones Settings", //X
  NOTIFICATION_SETTINGS: "Notification Settings", //X
  ABOUT: "About", //X
  BUG_REPORTING: "Bug Reporting & Feedback", //X
  INVITE_PEOPLE: "Invite people", //X
  TAG_USERS_SCREEN: "Tag users",
  EDIT_USER_TAGS: "Edit user tags",
  TEEUP_SETTINGS: "TeeUp Settings",
  TEEUP_PERMISSIONS: "TeeUp Permissions",
  TEEUP_PROGRESSION_SETTINGS: "TeeUp Progression Settings",
  CREATE_TEEUP: "Create TeeUp", //X
  MANAGE_PARTICIPANTS: "Manage Participants",
  TEEUP_LIST_ONBOARDING_LIST: "Teeup List User Onboarding Card",
  SUGGESTION_LIST: "Suggestion List", //X
  SUGGESTION_DETAILS: "Suggestion Details",
  SUGGEST_WHEN: "Suggest When", //X
  SUGGEST_WHERE: "Suggest Where", //X
  SUGGEST_WHEN_AI: "Suggest When AI", //X
  HIGHLIGHTS: "Highlights",
  PARTICIPANT_DETAILS: "Participant Details", //X
  MY_PEOPLE: "My People",
  COOE_COORDINATOR: "CooWe Coordinator",
  SIGN_IN: "Sign In", //X
  CREATE_TEEUP_PERMISSIONS: "Create Teeup Permissions",
  ARCHIVE: "Archived TeeUps", //X
  TRASH: "Trash TeeUps", //X
  COMING_UP: "Comming Up", //X
  CALENDAR: "Calendar", //X
  CONTACTS: "Contacts", //X
}
