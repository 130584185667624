import styled, { css } from "styled-components"

import { FontWeight, Subtitle, Title6 } from "@assets/styles/typographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding-right: 15px;
  margin-top: 10px;

  ${({ $isPreview, $isBorderedPreview }) => css`
    ${$isPreview &&
    css`
      background-color: var(--white);
      padding-right: 0;
    `};
    ${$isPreview &&
    $isBorderedPreview &&
    css`
      border: 1px solid var(--lightGray5);
      border-radius: 8px;
      padding: 16px;
    `};
  `};
`

export const Info = styled.div`
  max-width: 80%;
`

export const Value = styled(Title6)`
  font-weight: 600;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
`

export const Details = styled(Subtitle)`
  font-weight: ${FontWeight.medium};
  color: var(--gray4);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`

export const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isPreview }) =>
    $isPreview &&
    css`
      border: 1px solid var(--lightGray);
    `};
`

export const Icon = styled(SvgIcon)`
  width: 19px;
  height: 21px;
`

export const AppIcon = styled.img`
  width: 27px;
  height: 27px;
`

export const MapIcon = styled(SvgIcon)`
  width: 15px;
  height: 20px;
`
