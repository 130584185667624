import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"

import "./index.scss"

const ConfirmCopyLink = ({ open, onClose, onConfirm, tempPlatform }) => {
  if (!tempPlatform) return false

  return (
    <DefaultModal open={open} onClose={onClose}>
      <div className="confirm-copy-link-content">
        <h4>Use Meeting Link from Clipboard?</h4>
        <p>
          we noticed you have a {tempPlatform.label} link in your clipboard.
          Would you like to use this link to make a suggestion?
        </p>

        <div className="buttons-container">
          <Button fullWidth variant="contained" onClick={onConfirm}>
            Yes, add link to suggestion
          </Button>
          <Button fullWidth variant="text" onClick={onClose}>
            No, continue without pasting link
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default ConfirmCopyLink
