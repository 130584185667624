import React, { useContext } from "react"

import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"
import { Participant } from "types/participant"

import { InviteTitle } from "../../atoms"
import { AVATARS_SIZES } from "../../constants"
import {
  ParticipantAvatar,
  MainInfo,
  TextWrapper,
} from "../../teeupPreviewContentStyles"
// import NonUser from "assets/images/non-user.svg"

import People from "./People"

export const InviteMainInfo = ({ isStepper }) => {
  const { previewInfo, isAuthenticated, isSharedLink } = useContext(
    TeeupPreviewContext
  ) as any
  const participants = previewInfo?.participants as Participant[]

  const avatarData = isSharedLink
    ? previewInfo?.organizer?.avatar
    : previewInfo?.inviter?.avatar

  const avatarSize = isStepper ? AVATARS_SIZES.SMALL_S : AVATARS_SIZES.MEDIUM

  return (
    <MainInfo $isStepper={isStepper}>
      <ParticipantAvatar
        $isBorder
        $isStepper={isStepper}
        $isShadow={isAuthenticated}
        size={avatarSize}>
        <img src={avatarData} alt="avatar" />
      </ParticipantAvatar>

      <TextWrapper $isStepper={isStepper}>
        <InviteTitle isStepper={isStepper} previewInfo={previewInfo} />

        <People
          participants={participants}
          participantsCount={previewInfo?.participantCount}
        />
      </TextWrapper>
    </MainInfo>
  )
}
