import { push } from "connected-react-router"

import {
  saveUserAndRedirect,
  updateUserIdentities,
} from "@actions/loginActions"
import endpoints from "@config/endpoints"
import { loginProviders } from "@configs/enums"
import { selectAllContacts } from "@selectors/contacts"
import { selectTeeupsPeople } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { setItem } from "@utils/localStorage"
import dayjs from "utils/dayjs"

import api from "../api"
import { PASSWORD_RULES } from "../constants/password"
import { store } from "../index"

import actionTypes from "./actionTypes"

export const updateUserInfo = userInfo => ({
  type: actionTypes.UPDATE_USER_INFO,
  payload: userInfo,
})

export const setUserInfo = userInfo => ({
  type: actionTypes.SET_USER_INFO,
  payload: userInfo,
})

export const setInviteSSORegisterToken = payload => ({
  type: actionTypes.SET_INVITE_SSO_REGISTER_TOKEN,
  payload,
})

export const fetchUserInfo = async (redirectToTeeups = false) => {
  return api.client
    .get(endpoints.userInfo)
    .then(response => {
      const { data } = response

      if (data.loginType === loginProviders.fb) {
        data.facebookUsername = data.nickname
      }

      if (data.loginType === loginProviders.twitter) {
        data.twitterUsername = data.nickname
      }

      store.dispatch(updateUserInfo(data))
      store.dispatch(updateUserIdentities(data.identities))

      if (redirectToTeeups) {
        store.dispatch(push("/inbox"))
      }
      console.log("===============")
      console.log("SET DEFAULT TIMEZONE", data?.timezone)
      console.log("===============")
      if (data?.timezone) {
        dayjs.tz.setDefault(data?.userInfo?.timezone)
      }
      return data
    })
    .catch(error => console.error("fetch userInfo error", error))
}

export const fetchProfile = userId => {
  let url = endpoints.user.profile(userId)
  return api.client
    .get(url)
    .then(response => {
      const { data } = response

      return data
    })
    .catch(error => console.error("fetch profile error", error))
}

export const updateProfile = data =>
  api.client
    .patch(endpoints.user.editProfile, data)
    .then(response => {
      const { data } = response

      return data
    })
    .catch(error => console.error("fetch profile error", error))

export const getUserContactMechanisms = () => {
  const url = endpoints.contactMechanisms()

  return api.client
    .get(url)
    .then(response => {
      const { data } = response

      store.dispatch({
        type: actionTypes.SET_USER_CONTACT_MECHANISMS,
        payload: data.contactMechanisms,
      })
    })
    .catch(error => console.error("fetch profile error", error))
}

export const resetPassword = (
  password,
  token,
  noRedirect = false,
  callback
) => {
  return dispatch => {
    api.client
      .post(
        endpoints.resetPassword,
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => {
        const userInfo = {
          ...response.data,
        }

        setItem("userInfo", userInfo)
        if (callback) callback()
        if (!noRedirect) {
          saveUserAndRedirect(userInfo, dispatch)
        }
      })
      .catch(error => {
        console.error("resetPassword error", error)

        if (error && error.data && error.status === 403) {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload: PASSWORD_RULES.PREVIOUS,
          })
        } else {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload:
              "An error occurred while trying to change the password. Please try again.",
          })
        }
      })
  }
}

export const PasswordChange = (password, token) => {
  return dispatch => {
    api.client
      .post(
        endpoints.resetPassword,
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => {
        const userInfo = {
          ...response.data,
        }

        setItem("userInfo", userInfo)
      })
      .catch(error => {
        console.error("resetPassword error", error)

        if (error && error.data && error.status === 403) {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload: PASSWORD_RULES.PREVIOUS,
          })
        } else {
          dispatch({
            type: actionTypes.SET_RESET_PASS_ERROR,
            payload:
              "An error occurred while trying to change the password. Please try again.",
          })
        }
      })
  }
}

export const NewPasswordChange = (password, token) => {
  return api.client.post(
    endpoints.resetPassword,
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const getUserLocation = () => {
  let url = endpoints.teeup.userLocation

  return api.client
    .get(url)
    .then(res => {
      const { data } = res
      store.dispatch({
        type: actionTypes.GET_USER_PRIMARY_LOCATION,
        payload: data,
      })
    })
    .catch(error => console.error("get user location failed", error))
}

export const postUserLocation = body => {
  let url = endpoints.teeup.userLocation

  return api.client
    .post(url, {
      longitude: body.longitude,
      latitude: body.latitude,
    })
    .then(res => {
      const { data } = res

      return data
    })
    .catch(error => console.error("post user location failed", error))
}

export const fetchProfileWithTags = (userTag, currentUserId) => {
  let url = endpoints.user.profileWithTags(`@${userTag}`, currentUserId)
  return api.client
    .get(url)
    .then(response => {
      const { data } = response
      return data
    })
    .catch(error => {
      console.error("fetch profile error", error)
    })
}

export const getConnectionsWithTags = () => {
  return dispatch => {
    const teeupPeople = selectTeeupsPeople(store.getState())
    const allContacts = selectAllContacts(store.getState())
    const userId = selectUserId(store.getState())

    const peopleFromAllTeeups = []
    Object.values(teeupPeople).map(teeup =>
      peopleFromAllTeeups.push(...Object.values(teeup))
    )

    let map = new Map()
    for (let person of peopleFromAllTeeups) {
      map.set(person.id, person)
    }

    const connectionContact = []
    map.forEach(person => connectionContact.push(person))

    for (let i = 0; i < connectionContact.length; i++) {
      for (const allContact of allContacts) {
        if (connectionContact[i].id === allContact.id) {
          connectionContact[i] = allContact
        }
      }
    }

    const result = Promise.all(
      connectionContact.map(async person => {
        if (person.username) {
          const curUserInfo = await fetchProfileWithTags(
            person.username,
            userId
          )
          person.tags = curUserInfo.tags
        }
      })
    )

    connectionContact.forEach(async person => {
      if (!person.tags && person.username) {
        const personWithTags = await fetchProfileWithTags(
          person.username,
          userId
        )
        person.tags = personWithTags.tags
      }
    })

    result.then(() => {
      dispatch({
        type: actionTypes.SET_USER_CONNECTIONS,
        payload: connectionContact,
      })
    })
  }
}

export const getBlacklist = () => {
  const url = endpoints.blacklist()

  return api.client
    .get(url)
    .then(response => {
      const { data } = response
      return data
    })
    .catch(error => console.error("fetch blacklist error", error))
}

export const getUserTags = userId => {
  const url = endpoints.getUserTags(userId)
  return api.client
    .get(url)
    .then(response => {
      const { data } = response
      return data
    })
    .catch(error => console.error("fetch user tags error", error))
}

export const createUserTag = (userId, tagName) => {
  const url = endpoints.createUserTag(userId)
  return api.client
    .post(url, { value: tagName.trim() })
    .then(response => {
      const { data } = response
      return data
    })
    .catch(error => console.error("create user tags error", error))
}

export const deleteUserTag = (userId, tagId) => {
  const url = endpoints.deleteUserTag(userId, tagId)
  return api.client
    .delete(url)
    .catch(error => console.error("delete user tags error", error))
}

export const addTagsToUsers = (userId, body, onTagUsersEnd) => {
  const url = endpoints.tagUsers(userId)

  return api.client
    .post(url, body)
    .then(() => {
      if (onTagUsersEnd) {
        onTagUsersEnd()
      }
    })
    .catch(error => {
      console.error("tagUsers error", error)
    })
}

export const removeTagsToUsers = (userId, body, onTagUsersEnd) => {
  const url = endpoints.removeTags(userId)

  return api.client
    .delete(url, { data: body })
    .then(() => {
      if (onTagUsersEnd) {
        onTagUsersEnd()
      }
    })
    .catch(error => {
      console.error("removeTags error", error)
    })
}

export const setUserIsLoading = payload => ({
  type: actionTypes.SET_USER_IS_LOADING,
  payload,
})

export const verifyPassword = password => {
  return api.client.post(endpoints.verifyPassword, { password })
}

export const newSendDeleteAccountLink = async () => {
  return api.client
    .get(endpoints.emailForDeleteUser)
    .then(response => fetchUserInfo())
    .catch(error => {
      throw new Error(`deleteUser`)
    })
}

export const deleteUser = async token => {
  return api.client.delete(endpoints.deleteUser(token))
}

export const cancelDeleteUser = async token => {
  return api.client.delete(endpoints.cancelDeleteUser(token))
}

export const newSendPasswordRecoveryLink = async () => {
  return api.client.get(endpoints.newSendPasswordRecoveryLink)
}

export const newPasswordRecovery = async (password, token) => {
  return api.client.put(endpoints.newPasswordRecovery(token), { password })
}

export const removeLoginMethod = id => {
  return api.client.delete(endpoints.removeSocialSignIn(id)).then(() => {
    fetchUserInfo()
  })
}
