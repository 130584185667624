import React from "react"

import { NewSignUpEmail } from "../../components/NewSignUpEmail"
import { SignUpEmailState } from "../../contexts/SignUpEmailContext"

export const NewSignUpPage = () => (
  <SignUpEmailState>
    <NewSignUpEmail />
  </SignUpEmailState>
)
