import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const DateLabel = styled(NSubtitleS)`
  font-weight: ${NFontWeight.bold};
`

export const PrevIcon = styled(SvgIcon)`
  ${({ $isNext }) => css`
    transform: ${$isNext ? "rotate(-90deg)" : "rotate(90deg)"};
  `};
`
