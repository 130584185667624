import React from "react"

import PropTypes from "prop-types"
import { Text, StyleSheet } from "react-native"

import { AppColors, AppFonts } from "@theme"

import { statusText } from "../../../../config/mappings"

const styles = StyleSheet.create({
  text: {
    ...AppFonts.smallestExtrabold,
  },
})

const TeeupStatus = ({ status, teeupStatusStyling, testID }) => (
  <Text
    testID={testID}
    style={[
      styles.text,
      { color: AppColors.status.teeup[teeupStatusStyling] },
    ]}>
    {statusText[status]}
  </Text>
)

TeeupStatus.propTypes = {
  status: PropTypes.string.isRequired,
  teeupStatusStyling: PropTypes.string.isRequired,
  testID: PropTypes.string,
}

export default TeeupStatus
