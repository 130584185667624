import React from "react"

import Overview1 from "../../../assets/images/teeup-overview-1.svg"
import Overview2 from "../../../assets/images/teeup-overview-2.svg"
import Overview3 from "../../../assets/images/teeup-overview-3.svg"
import { OverviewImg } from "../makesMeetingHappenStyles"

export const Overviews = () => (
  <>
    <OverviewImg isFirst src={Overview1} alt="overview 1" />
    <OverviewImg src={Overview2} alt="overview 2" />
    <OverviewImg src={Overview3} alt="overview 3" />
  </>
)
