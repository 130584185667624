import React from "react"

import cn from "classnames"

import "./index.scss"

const StyledInputField = ({
  value,
  setValue,
  isValid,
  validationError,
  hasIcon = false,
  ...props
}) => {
  const hasError = !!value && !isValid && !!validationError

  return (
    <>
      <div style={{ position: "relative" }}>
        <input
          className={cn("styled_input", {
            "styled_input--error": hasError,
            "styled_input--icon": hasIcon,
          })}
          value={value}
          onChange={e => setValue(e.target.value)}
          {...props}
        />
        {/* { value.trim().length > 0 && <img onClick={ () => setValue('') } className="forgot__cancel" src={ cancel } alt="" /> } */}
      </div>
      <span
        data-testid="error"
        className={hasError ? "styled_input__error" : "styled_input__success"}>
        {hasError ? validationError : value ? "Strong Password" : ""}
      </span>
    </>
  )
}

export default StyledInputField
