import { useEffect, useState } from "react"

import { userContactTypes } from "@utils/contactUtils"

import {
  requestVerifyContactMechanism,
  verifyContactMechanism,
} from "../../../actions/contactMechanismsActions"
import { createUserCM, verifyBetaCode } from "../../../actions/loginActions"
import { fetchUserInfo } from "../../../actions/userActions"
import { API_ERRORS_MESSAGES, REGISTER_STEPS } from "../constants"

export const useVerifyCode = ({
  addApiError,
  getTokenOnVerifiedInvitation,
  isInvite,
  step,
  teeupInviteInfo,
  setStep,
  token,
  isRegisterSsoNames,
  localSSOUser,
  setContactMechanismId,
  setContactVerificationId,
  setUserInfoAction,
  setNewRegisteredUser,
  setIsRegisterSsoNames,
}) => {
  const [isCodeVerified, setIsCodeVerified] = useState(false)
  const [isCodeVerifying, setIsCodeVerifying] = useState(false)
  const [isSentMechanism, setIsSentMechanism] = useState(false)

  const setInviteMechanism = () => {
    const typeId =
      teeupInviteInfo.type === "email"
        ? userContactTypes.EMAIL
        : userContactTypes.PHONE
    const value = teeupInviteInfo.email || teeupInviteInfo.phone

    createUserCM(typeId, value, token).then(res => {
      setIsSentMechanism(true)
      const mechanismId = res.data.id

      setContactMechanismId(mechanismId)
      requestVerifyContactMechanism(
        mechanismId,
        isRegisterSsoNames ? token : null
      ).then(res => {
        setContactVerificationId(res.contactMechanismVerificationId)
      })
    })
  }

  useEffect(() => {
    if (
      !isSentMechanism &&
      step === REGISTER_STEPS.INVITE_SENDER &&
      teeupInviteInfo &&
      token
    ) {
      setInviteMechanism()
    }
  }, [step, teeupInviteInfo, token, isSentMechanism])

  const verifyCode = (contactMechanismVerificationId, code) => {
    setIsCodeVerifying(true)
    verifyBetaCode("email1", contactMechanismVerificationId, code)
      .then(response => {
        if (response.data.email1) {
          setIsCodeVerified(true)
          getTokenOnVerifiedInvitation(response.data.token)
          setStep(REGISTER_STEPS.PASSWORD)
        } else {
          addApiError("code", API_ERRORS_MESSAGES.INCORRECT_CODE)
        }
        setIsCodeVerifying(false)
      })
      .catch(() => {
        setIsCodeVerifying(false)
      })
  }

  const verifyInviteCode = ({ contactVerificationId, code, userInfo }) => {
    setIsCodeVerifying(true)
    verifyContactMechanism(contactVerificationId, code, token)
      .then(response => {
        // good response
        if (response.data.teeupIds) {
          setIsCodeVerified(true)
          if (isRegisterSsoNames) {
            setNewRegisteredUser(localSSOUser)
            setIsRegisterSsoNames(false)
          } else {
            setUserInfoAction(userInfo)
            fetchUserInfo(true)
          }
        } else {
          // error response
          addApiError(
            "inviteVerificationCode",
            API_ERRORS_MESSAGES.INCORRECT_CODE
          )
        }
        setIsCodeVerifying(false)
      })
      .catch(() => {
        addApiError(
          "inviteVerificationCode",
          API_ERRORS_MESSAGES.INCORRECT_CODE
        )
        setIsCodeVerifying(false)
      })
  }

  return {
    isCodeVerifying,
    isCodeVerified,
    verifyCode: isInvite ? verifyInviteCode : verifyCode,
  }
}
