import actionTypes from "@actions/actionTypes"

export const initialState = {
  token: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TOKEN_ON_VERIFIED_INVITATION:
      return { token: action.payload }

    default:
      return state
  }
}
