import React, { useContext, useMemo } from "react"

import { TEEUP_SETTINGS_TYPES } from "@constants/teeups"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { updateTeeup } from "@middlewares/backendMiddleware"
import { saveSettingsValue } from "@utils/teeupUtils"

import { Info, Organizers, SettingsRow, ButtonsPanel } from "../molecules"
import { Description } from "../newTeeupSettingsStyles"

export const MainSettingsScreen = () => {
  const { teeup, isTeeupOrganiser } = useContext(NewTeeupPageContext)
  const isShareLink = useMemo(() => teeup.settings?.enableSharedLink, [teeup])
  const isMute = useMemo(() => teeup?.isMute, [teeup])

  const handleShareLink = val => {
    saveSettingsValue({
      teeUpId: teeup.id,
      settings: teeup.settings,
      key: "enableSharedLink",
      val,
    })
  }

  const handleMuteTeeup = val => {
    updateTeeup({
      teeUpId: teeup.id,
      teeup: { isMute: val },
    })
  }

  return (
    <>
      <Info />

      <Organizers />

      {isTeeupOrganiser && (
        <div>
          <SettingsRow
            title="Participants Permissions"
            type={TEEUP_SETTINGS_TYPES.PERMISSIONS}
          />

          <SettingsRow
            isToggle
            title="Share link"
            value={isShareLink}
            onToggle={handleShareLink}
          />
        </div>
      )}

      <Description>
        Only organizers have permission to edit participant settings.
      </Description>

      <SettingsRow
        isToggle
        title="Mute TeeUp"
        value={isMute}
        onToggle={handleMuteTeeup}
      />

      <Description>
        By muting the TeeUp, you will no longer receive notifications from this
        TeeUp to your phone
      </Description>

      <ButtonsPanel />
    </>
  )
}
