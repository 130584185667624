// import { Suggestion } from "app/@types/gameplan"

type ReturnType = {
  title: string
  empty?: boolean
  subtitle: string
}

const getWhereSuggestionDetails = (suggestion: any): ReturnType => {
  const formattedSuggestion = {
    ...suggestion,
    value: suggestion.value === suggestion?.details ? "" : suggestion?.value,
  }

  if (
    formattedSuggestion?.where?.value &&
    formattedSuggestion?.where?.details
  ) {
    return {
      title: formattedSuggestion?.where?.value,
      subtitle: formattedSuggestion?.where?.details,
    }
  } else if (
    formattedSuggestion?.where?.value &&
    !formattedSuggestion?.where?.details
  ) {
    return {
      title: formattedSuggestion?.where?.value,
      subtitle: "",
    }
  }

  if (formattedSuggestion?.value && formattedSuggestion?.details) {
    return {
      title: formattedSuggestion?.value,
      subtitle: formattedSuggestion?.details,
    }
  }

  if (formattedSuggestion?.value && !formattedSuggestion?.details) {
    return {
      title: formattedSuggestion?.value,
      subtitle: "",
    }
  }

  return {
    title: formattedSuggestion?.details || "Other",
    subtitle: formattedSuggestion?.value || "",
    empty: !formattedSuggestion?.details && !formattedSuggestion?.value,
  }
}

export default getWhereSuggestionDetails
