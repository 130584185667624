import { useContext, useRef } from "react"

import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { ConfirmedStatusModal } from ".."

import {
  PreviewScreen,
  StepperWrapper,
  SuggestionsScreen,
  CurrentSuggestionScreen,
  MakeSuggestionScreen,
  ConfirmationScreen,
  ChoicesSubmitScreen,
} from "./molecules"

export const TeeupPreviewContent = () => {
  const {
    isStatusConfirmedModal,
    statusModalTrigger,
    stepsManager: {
      currentStep,
      suggestionPreviewId,
      isMakeSuggestion,
      setIsMakeSuggestion,
    },
  } = useContext(TeeupPreviewContext)

  const renderScreen = () => {
    switch (true) {
      case currentStep === 1 && isMakeSuggestion:
        return <MakeSuggestionScreen />
      case currentStep === 1 && !suggestionPreviewId:
        return <SuggestionsScreen setIsMakeSuggestion={setIsMakeSuggestion} />
      case currentStep === 1 && !!suggestionPreviewId:
        return <CurrentSuggestionScreen />
      case currentStep === 2:
        return <ConfirmationScreen statusModalTrigger={statusModalTrigger} />
      case currentStep === 3:
        return <ChoicesSubmitScreen />
      default:
        return null
    }
  }

  return (
    <>
      {currentStep ? (
        <StepperWrapper>{renderScreen()}</StepperWrapper>
      ) : (
        <PreviewScreen />
      )}

      {isStatusConfirmedModal && <ConfirmedStatusModal />}
    </>
  )
}
