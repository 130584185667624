import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  ${({ $isCreateTeeup }) => css`
    box-sizing: border-box;
    padding: ${$isCreateTeeup ? "20px 5px" : "15px 0 0 0"};
    ${$isCreateTeeup &&
    css`
      height: 95%;
    `}
  `};
`

export const SelectedPhoto = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-top: 10px;
`

export const SubmitWrapper = styled.div`
  ${({ $isCreateTeeup }) => css`
    width: 100%;
    background-color: var(--white);
    box-shadow: var(--blackShadow);
    box-sizing: border-box;
    border-radius: 8px 8px 0 0;
    position: ${$isCreateTeeup ? "fixed" : "sticky"};
    bottom: 0;
    left: 0;
    padding: 10px;
  `};
`
