import React from "react"

import "./index.scss"
import { connect } from "react-redux"

import {
  showSuggestionListFooter,
  setSideBarContent,
  isOnlineTabOpened,
} from "@actions/commonActions"
import { selectSideBarContent } from "@selectors/common"
import { images } from "@utils/imageUtils"

import OverviewConversation from "../OverviewConversation"

const SuggestionContainer = ({
  handleCloseSuggestion,
  content,
  setContent,
  suggestionListContent,
  setSuggestionListContent,
  setPeopleSideBar,
  showSuggestionListFooter,
  activeTeeUp,
  sideBarContent,
  setSideBarContent,
  isOnlineTab,
  isOnlineTabOpened,
}) => {
  const closeSuggestions = () => {
    setContent(null)
    setSuggestionListContent(null)
    handleCloseSuggestion()
    setPeopleSideBar(false)
    showSuggestionListFooter()
    setSideBarContent([])
    isOnlineTabOpened(false)
  }

  const isWhereSuggestion =
    (!!sideBarContent && sideBarContent[0]?.props.type === "where") ||
    content?.props.type === "where"

  return (
    <div className="suggestion__wrapper">
      <div
        className="suggestion__container"
        style={{ width: isWhereSuggestion && "calc(100vw - 680px)" }}>
        <div style={{ display: "flex" }}>
          <div
            className="suggestion__left-side"
            style={{
              width:
                isWhereSuggestion &&
                document.documentElement.clientWidth <= 1450
                  ? "305px"
                  : document.documentElement.clientWidth <= 1550 && "340px",
              minWidth:
                isWhereSuggestion &&
                document.documentElement.clientWidth <= 1450
                  ? "305px"
                  : document.documentElement.clientWidth <= 1550 && "340px",
            }}>
            <div className="teeup__name-wrapper">
              <div className="teeup__name">
                <p style={{ marginLeft: "10px" }}>{activeTeeUp.name}</p>
              </div>
              <img
                src={activeTeeUp.photo}
                className="teeup__image"
                style={{ width: "100%", maxHeight: "380px" }}
              />
            </div>
            {(sideBarContent && sideBarContent[1]) || suggestionListContent}
          </div>
          <OverviewConversation
            fromSuggestionContainer
            isWhereSuggestion={isWhereSuggestion}
          />
          <div
            className="suggestion__right-side"
            style={{
              width:
                (!isWhereSuggestion && "380px") ||
                (isWhereSuggestion && isOnlineTab ? "380px" : "680px"),
            }}>
            <img
              className="close__suggestion__modal"
              style={{ right: isWhereSuggestion && !isOnlineTab && "312px" }}
              src={images.cross}
              onClick={closeSuggestions}
              alt="close icon"
            />
            {(sideBarContent && sideBarContent[0]) || content}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    sideBarContent: selectSideBarContent(state),
    isOnlineTab: state.common.isOnlineTab,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSuggestionListFooter: () => dispatch(showSuggestionListFooter()),
    setSideBarContent: () => dispatch(setSideBarContent()),
    isOnlineTabOpened: isOpened => dispatch(isOnlineTabOpened(isOpened)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionContainer)
