import React, { useContext, useState } from "react"

import moment from "moment-timezone"
import ReactDOM from "react-dom"

import "./timerPicker.scss"
import { images } from "@utils/imageUtils"

import { TimeWheelContext } from "../../../contexts/TimeWheelContext"
import { roundMinutesToFifteen } from "../../../contexts/TimeWheelContext/helpers"
import { usePickerLabel } from "../../../contexts/TimeWheelContext/hooks"
import TimezonesTimePicker from "../../timezones/TimezonesTimePicker"
import { useSuggestionValues } from "../hooks"

export const TimePicker = () => {
  const {
    timeDuration,
    setTimeDuration,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    pickerWidth,
    dateLabel,
    zones,
    parsedCurrentPeriod,
    dragScrollRef,
    reinitScrollLeft,
    reinitPickerWidth,
  } = useContext(TimeWheelContext)
  const [isTimePickerOpened, setIsTimePickerOpened] = useState(false)
  const suggestionValues = useSuggestionValues({
    dateLabel,
    zones,
    startDate,
    endDate,
  })
  const pickerLabel = usePickerLabel(timeDuration)

  const onTimePick = (_, hours, minutes, duration) => {
    const updatedStartDate = moment(startDate).hours(hours).minutes(minutes)
    const updatedEndDate = moment(updatedStartDate).add(duration, "minutes")
    const roundedDate = roundMinutesToFifteen(updatedStartDate.format())
    setTimeDuration(duration)

    reinitScrollLeft({
      startDate: null,
      nowDate: roundedDate,
      parsedCurrentPeriod,
      dragScrollRef,
      pickerWidth: reinitPickerWidth(duration),
    })

    setTimeout(() => {
      setStartDate(updatedStartDate.format())
      setEndDate(updatedEndDate.format())
    }, 0)
  }

  const renderPopUpDuration = () => {
    return ReactDOM.createPortal(
      <TimezonesTimePicker
        setIsTimePickerOpened={() => setIsTimePickerOpened(false)}
        suggestionValues={suggestionValues}
        handleTimePick={onTimePick}
        duration={timeDuration}
        isTemporalCustom={true}
      />,
      document.querySelector("#modal-root")
    )
  }

  return (
    <div style={{ width: pickerWidth }} className="time-picker">
      {isTimePickerOpened && renderPopUpDuration()}

      <button
        className="time-picker__toggle"
        onClick={() => setIsTimePickerOpened(true)}>
        <img
          className="time-picker__toggle-icon"
          src={images.PurplePencilSVG}
          alt="edit"
        />

        {pickerLabel}
      </button>
    </div>
  )
}
