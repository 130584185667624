import {
  SET_TEEUP_NAME,
  SET_TEEUP_IMAGE,
  SET_TEEUP_MESSAGE,
  SET_INVITEES,
  SET_IS_INVITEES_OPEN,
} from "./actionTypes"

export const CreateTeeUpReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_TEEUP_NAME:
      return {
        ...state,
        name: payload,
      }
    case SET_TEEUP_IMAGE:
      return {
        ...state,
        image: payload,
      }
    case SET_TEEUP_MESSAGE:
      return {
        ...state,
        message: payload,
      }
    case SET_INVITEES:
      return {
        ...state,
        invitees: payload,
      }
    case SET_IS_INVITEES_OPEN:
      return {
        ...state,
        isInviteesOpen: payload,
      }
    default: {
      return state
    }
  }
}
