import React from "react"

const Item = ({
  noMargin,
  item,
  selectStartHour,
  selectStartMinute,
  selectDurationHour,
  selectDurationMinute,
}) => {
  const selectFunction =
    selectStartHour ||
    selectStartMinute ||
    selectDurationHour ||
    selectDurationMinute

  const durationPart = !!selectDurationHour || !!selectDurationMinute

  return (
    <div
      key={item.value}
      className="item__container"
      style={{
        backgroundColor: item.selected && "#ffffff",
        marginLeft: noMargin && 0,
        width: durationPart && "60px",
      }}
      onClick={() => selectFunction(item.value)}>
      {selectStartHour && (
        <p data-testid={item.value} className="item__number">
          {item.value.split(" ")[0]}
          <span className="item__day-part">{item.value.split(" ")[1]}</span>
        </p>
      )}
      {(selectStartMinute || selectDurationHour || selectDurationMinute) && (
        <p
          data-testid={selectStartMinute && item.value}
          className="item__number">
          {item.value}
        </p>
      )}
    </div>
  )
}

export default Item
