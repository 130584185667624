import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import { useInboxStore } from "stores/inboxStore"

const UnderlineModal = () => {
  const isUnderlineModalOpen = useInboxStore(
    state => state.isUnderlineModalOpen
  )
  const setIsUnderlineModalOpen = useInboxStore(
    state => state.setIsUnderlineModalOpen
  )
  const handleClose = () => {
    setIsUnderlineModalOpen(false)
  }

  return (
    <DefaultModal open={isUnderlineModalOpen} onClose={handleClose}>
      <div>
        <SvgIcon name="no-invitations" className="!size-14" />

        <div className="mt-4">
          <h5 className="text-black text-lg font-semibold leading-7">
            Don't see your invitations here?
          </h5>
          <p
            className="mt-2 text-stone-500 text-sm font-normal leading-tight
">
            Go back to your original TeeUp invitation, and tap the link.
          </p>
        </div>

        <div className="flex justify-end mt-8">
          <Button
            variant="contained"
            onClick={handleClose}
            className="!py-2.5 !px-4">
            Close
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default UnderlineModal
