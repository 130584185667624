import React, { Component } from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity } from "react-native"

import { SCREENS } from "@utils/calendarUtils"
import {
  isToday,
  getDateWithTimezone,
  isDateBeforeNow,
  // composeDatetime,
} from "@utils/dateUtils"

import WeekCalendarTimetableContainer from "./WeekCalendarTimetableContainer"
import { WeekCalendarStyles as styles } from "./styles"

const getDatesArray = selectedDate => {
  const startOfWeek = getDateWithTimezone(selectedDate).startOf("week")
  let days = []
  for (let i = 0; i < 7; i++) {
    days.push(getDateWithTimezone(startOfWeek).add(i, "d"))
  }
  return days
}

class WeekCalendarView extends Component {
  constructor(props) {
    super(props)
    this.weekdays = getDatesArray(props.selectedDate)
  }

  selectDay = date => () => {
    const { changeViewType, newSuggestionProps, selectedDate } = this.props
    if (newSuggestionProps && isDateBeforeNow(date) && !isToday(date)) {
      return
    }
    const selectedDateObj = getDateWithTimezone(selectedDate)
    changeViewType(
      SCREENS.day,
      getDateWithTimezone(date)
        .hour(selectedDateObj.hour())
        .minute(selectedDateObj.minute())
    )
    // changeViewType(SCREENS.day, composeDatetime(date, selectedDate))
  }

  render() {
    const {
      selectedDate,
      newSuggestionProps,
      onPickTimerangeFromCalendar,
      changeDate,
      onDurationChange,
      duration,
      allowClickToPast,
      loadEvents,
      scrollOffset,
      setOffset,
    } = this.props

    return (
      <View style={styles.flex}>
        <View style={[styles.headerContainer]}>
          {this.weekdays.map((date, index) => {
            const today = isToday(date, newSuggestionProps?.selectedDate)
            const isDisabled =
              newSuggestionProps && isDateBeforeNow(date) && !today
            return (
              <TouchableOpacity
                style={styles.weekdayWrapper}
                key={date.format()}
                onPress={this.selectDay(date)}
                disabled={!allowClickToPast && isDisabled}>
                <View style={styles.weekdayContainer}>
                  <Text
                    style={[
                      styles.weekdayText,
                      isDisabled && styles.disabledText,
                    ]}>
                    {date.format("ddd")}
                  </Text>
                  <View
                    style={[
                      styles.dayContainer,
                      today && styles.activeDayContainer,
                    ]}>
                    <Text
                      style={[
                        styles.dayText,
                        today && styles.activeDayText,
                        isDisabled && styles.disabledText,
                      ]}>
                      {date.format("D")}
                    </Text>
                  </View>
                </View>
                {index !== this.weekdays.length - 1 && (
                  <View style={styles.daysSeparatorContainer}>
                    <View style={styles.daysSeparator} />
                  </View>
                )}
              </TouchableOpacity>
            )
          })}
        </View>
        <WeekCalendarTimetableContainer
          hasNewSuggestionProps={!!newSuggestionProps}
          onPickTimerangeFromCalendar={onPickTimerangeFromCalendar}
          selectedDate={selectedDate}
          duration={duration}
          weekdays={this.weekdays}
          changeDate={changeDate}
          onDurationChange={onDurationChange}
          loadEvents={loadEvents}
          scrollOffset={scrollOffset}
          setOffset={setOffset}
        />
      </View>
    )
  }
}

WeekCalendarView.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  changeViewType: PropTypes.func,
  onDurationChange: PropTypes.func,
  changeDate: PropTypes.func,
  duration: PropTypes.number,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      passedEvent: PropTypes.object,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  onPickTimerangeFromCalendar: PropTypes.func,
  allowClickToPast: PropTypes.bool,
  loadEvents: PropTypes.bool,
  scrollOffset: PropTypes.number,
  setOffset: PropTypes.func,
}

WeekCalendarView.defaultProps = {
  allowClickToPast: true,
}

export default WeekCalendarView
