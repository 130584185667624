import { gapi } from "gapi-script"
import ApiCalendar from "react-google-calendar-api"

import { fetchUserInfo } from "@actions/userActions"
import endpoints from "@config/endpoints"
import { loginProviders } from "@config/enums"
import {
  fetchCalendarEvents,
  fetchCalendarEventsData,
  fetchGuestOutlookCalendarEvents,
  getGuestOutlookCalendars,
} from "@utils/calendarSyncUtils"
import { addSummaryForOutlook } from "@utils/calendarUtils"

import api from "../api"

import actionTypes from "./actionTypes"

export const gotCalendar = payload => ({
  type: actionTypes.GOT_CALENDAR,
  payload,
})

export const gotAvaliableCalendars = calendars => ({
  type: actionTypes.GOT_AVALIABLE_CALENDARS,
  payload: calendars,
})

export const setCalendarPermissions = permissions => ({
  type: actionTypes.SET_CALENDAR_PERMISSIONS,
  payload: permissions,
})

export const calendarAddTeeupEvents = newlySyncedTeeups => ({
  type: actionTypes.CALENDAR_ADD_TEEUP_EVENTS,
  payload: newlySyncedTeeups,
})

export const setBeforeCalendarDate = payload => ({
  type: actionTypes.SET_BEFORE_CALENDAR_DATE,
  payload,
})

export const setGoogleCalendarId = payload => ({
  type: actionTypes.SET_GOOGLE_CALENDAR_ID,
  payload,
})

export const linkGoogleCalendar = async accessToken => {
  try {
    const { data } = await api.client.post("/users/calendars", {
      signature: accessToken,
    })
    ApiCalendar.updateSigninStatus(true)

    gapi.client.setToken({ access_token: data.calendars[0].accessToken })

    setGoogleCalendarId(data.calendars[0].id)

    await fetchUserInfo()
  } catch (error) {
    throw error
  }
}

export const manageGuestGoogleEvents = ({
  calendarCredentials,
  startDate,
  endDate,
}) => {
  return async dispatch => {
    try {
      gapi.client.setToken({ access_token: calendarCredentials.accessToken })
      const fetchResult = await fetchCalendarEventsData(
        calendarCredentials.email,
        undefined,
        {
          timeMin: startDate,
          timeMax: endDate,
        }
      )

      dispatch({
        type: actionTypes.SET_GUEST_CALENDAR_EVENTS,
        payload: fetchResult.data.items,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const manageGuestOutlookEvents = ({
  calendarCredentials,
  startDate,
  endDate,
}) => {
  return async dispatch => {
    const calendarsList = await getGuestOutlookCalendars(
      calendarCredentials.accessToken,
      calendarCredentials.uid
    )
    const defaultCalendar = calendarsList.value.find(
      calendar => calendar.isDefaultCalendar
    )
    try {
      const outlookFetchResult = await fetchGuestOutlookCalendarEvents({
        accessToken: calendarCredentials.accessToken,
        accEmail: calendarCredentials.email,
        calendarId: defaultCalendar.id,
        startDate,
        endDate,
      })
      const updatedEvents = addSummaryForOutlook(outlookFetchResult.value)

      dispatch({
        type: actionTypes.SET_GUEST_CALENDAR_EVENTS,
        payload: updatedEvents,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const linkGuestCalendar = ({
  accessToken,
  provider,
  startDate,
  endDate,
}) => {
  return async dispatch => {
    try {
      const {
        data: { calendarCredentials },
      } = await api.client.post(`teeups/preview/calendar/token`, {
        signature: accessToken,
      })
      const params = {
        calendarCredentials,
        startDate,
        endDate,
      }

      dispatch({
        type: actionTypes.SET_GUEST_CALENDAR_INFO,
        payload: calendarCredentials,
      })

      if (provider === loginProviders.google) {
        dispatch(manageGuestGoogleEvents(params))
      } else {
        dispatch(manageGuestOutlookEvents(params))
      }
    } catch {
      dispatch(setIsGuestConnectionError(true))
    }
  }
}

export const setIsGuestConnectionError = payload => ({
  type: actionTypes.SET_IS_GUEST_CONNECTION_ERROR,
  payload,
})

export const unlinkGuestCalendar = () => ({
  type: actionTypes.UNLINK_GUEST_CALENDAR,
})

export const newOutlookLink = async accessToken => {
  try {
    const response = await api.client.post("/users/calendars", {
      signature: accessToken,
    })
    const newOutlookCalendar = response.data.calendars.find(
      calendar => calendar.provider === "windowslive"
    )

    await fetchCalendarEvents({
      newOutlookCalendar,
      isOutlookLinking: true,
    })
    await fetchUserInfo()
  } catch (error) {
    throw error
  }
}

export const unlinkExternalCalendar = async calendarId => {
  return api.client
    .delete(`/users/calendars/${calendarId}`)
    .then(async () => {
      ApiCalendar.updateSigninStatus(false)

      gapi.client.setToken({ access_token: "" })

      // TODO: check if we need this line
      // await fetchCalendarEvents()

      await fetchUserInfo()
    })
    .catch(error => {
      console.log("Delete Google Calendar failed", error)
    })
}

export const calendarTeeupRemoved = async (teeupId, eventIdBE) => {
  const url = endpoints.calendarEvent(teeupId) + "/" + eventIdBE
  try {
    const response = await api.client.delete(url)
    const { data } = response
    return data
  } catch (error) {
    return console.log("delete calendar error", error)
  }
}

export const calendarTeeupAdd = async ({
  teeupId,
  userId,
  eventId,
  eventIdBE,
  external = false,
}) => {
  let url = endpoints.calendarEvent(teeupId)
  let body = {
    eventId,
    external,
  }

  if (eventIdBE) {
    // Means event already exists in BE, need to update it
    url += "/" + eventIdBE
    try {
      const response = await api.client.put(url)
      const { data } = response
      return data
    } catch (error) {
      return console.log("update calendar error", error)
    }
  } else {
    body.userId = userId
  }

  try {
    const response_3 = await api.client.post(url, body)
    const { data: data_1 } = response_3
    return data_1
  } catch (error) {
    return console.log("add calendar error", error)
  }
}

export const resetCalendar = () => ({
  type: actionTypes.RESET_CALENDAR_STATE,
})

export const resetSingleCalendar = payload => ({
  type: actionTypes.RESET_SINGLE_CALENDAR_STATE,
  payload,
})

export const createCalendarEvent = payload => ({
  type: actionTypes.CREATE_CALENDAR_EVENT,
  payload,
})

export const setIsCalendarSynchronized = payload => ({
  type: actionTypes.SET_IS_CALENDAR_SYNCHRONIZED,
  payload,
})

export const deleteCalendarEvent = payload => ({
  type: actionTypes.DELETE_CALENDAR_EVENT,
  payload,
})

export const setCalendarSyncInProgress = payload => ({
  // DESCRIPTION: Set sync in progress for Google Calendar
  type: actionTypes.SET_CALENDAR_SYNC_IN_PROGRESS,
  payload,
})

export const setDisplayCalendar = payload => ({
  type: actionTypes.SET_DISPLAYED_CALENDAR,
  payload,
})

export const manageDisplayedCalendars = payload => ({
  type: actionTypes.MANAGE_DISPLAYED_CALENDARS,
  payload,
})

export const dispatchSections = payload => {
  return {
    type: actionTypes.SET_CALENDAR_LIST_SECTIONS,
    payload,
  }
}
export const setSyncCalendar = payload => {
  return {
    type: actionTypes.SET_CALENDAR_SYNC_EMAIL,
    payload,
  }
}
export const setCalendarEmailData = payload => {
  return {
    type: actionTypes.SET_CALENDAR_EMAIL_DATA,
    payload,
  }
}
export const setDisplayedData = payload => {
  return {
    type: actionTypes.SET_DISPLAYED_DATA,
    payload,
  }
}

export const setHideCalendarSettingsMsg = () => {
  return {
    type: actionTypes.SET_HIDE_CALENDAR_SETTINGS_MESSAGE,
  }
}
