import _ from "lodash"
// TODO: here was "moment" if everything is okay - remove this comment
import moment from "moment-timezone"

import {
  conversationTypeIds,
  reactionsTranslations,
  gameplanTypes,
} from "@configs/mappings"
import strings from "i18n"

import {
  teeupUserGenericStatus,
  teeupStatus,
  teeupUserStatusPriorities,
  teeupUserStatusKeys,
  teeupParticipantStatusPriorities,
  teeupLastActivityTypes,
  teeupStatusKeys,
} from "../config/enums"
import { updateTeeup } from "../middlewares/backendMiddleware"

import { getUsernameOrName } from "./contactUtils"
import { getDiffInDays, dateComparator } from "./dateUtils"
import { PARTICIPANTS } from "./filters"
import { images } from "./imageUtils"
import { userStatusTypes, reactionTypes, teeupStatusTypes } from "./mappings"
import { UNKNOWN_TIMEZONE } from "./peopleUtils"

export const PREVIEW_PARTICIPANTS_LIMIT = 5

const SORT_KEYS = {
  updatedAt: "updatedAt",
  createdat: "createdat",
  startsWhen: "startsWhen",
  noExactWhen: "noExactWhen",
  lastActedAt: "lastActedAt",
}

const DEFAULT_SORT = {
  id: "defaultSort",
  sort: SORT_KEYS.updatedAt,
  desc: true,
  title: "Most Recent",
  // title: 'Recently Active'
}

const TEEUP_LIST_MODES = {
  ACTIVE: null,
  ARCHIVED: "ARCHIVED",
  SKIPPED: "TRASH",
  COMING_UP: "COMING UP",
}

const formatUserStatusText = statusId => {
  switch (statusId) {
    case "1":
      return "Invited"
    case "2":
      return "Going"
    case "3":
      return "Might Go"
    case "4":
      return "Not Going"
    case "5":
      return "Joined"
    case "6":
      return "On MyWay"
    case "7":
      return "Arrived"
    case "9":
      return "Backed Out"
    case "10":
      return "Dropped out"
  }
}

const formatTeeupStatusText = statusId => {
  switch (statusId) {
    case "1":
      return "Planning"
    case "2":
      return "It's On"
    case "3":
      return "Happening"
    case "4":
      return "Ended"
    case "5":
      return "Cancelled"
  }
}

const getPeopleCount = (participants, people, statusSelection) => {
  if (!participants || !people) return 0
  let goingCount = 0
  const goingPeople = participants.filter(
    participantId =>
      people[participantId] && statusSelection(people[participantId].status)
  )
  goingPeople.forEach(participantId => {
    if (people[participantId].additionalCount) {
      goingCount += people[participantId].additionalCount + 1
    } else goingCount++
  })

  return goingCount
}

const isJoined = status => status !== teeupUserStatusKeys.invited
const isGoing = status => status === teeupUserStatusKeys.going
const isArrived = status => status === teeupUserStatusKeys.arrived
const isOnMyWay = status => status === teeupUserStatusKeys.onmyway
const isMightGo = status => status === teeupUserStatusKeys.mightgo
const isNotGoing = status => status === teeupUserStatusKeys.notgoing
const isRunningLate = status => status === teeupUserStatusKeys.runningLate
const isBackedOut = status => status === teeupUserStatusKeys.backedOut

const getGoingCount = (participants, people) => {
  return getPeopleCount(participants, people, isGoing)
}

export const getJoinedCount = (participants, people) => {
  return getPeopleCount(participants, people, isJoined, true)
}

export const getArrivedCount = (participants, people) => {
  return getPeopleCount(participants, people, isArrived)
}

export const getOnMyWayCount = (participants, people) => {
  return getPeopleCount(participants, people, isOnMyWay)
}

export const getMightGoCount = (participants, people) => {
  return getPeopleCount(participants, people, isMightGo)
}

export const getNotGoingCount = (participants, people) => {
  return getPeopleCount(participants, people, isNotGoing)
}

export const getRunningLateCount = (participants, people) => {
  return getPeopleCount(participants, people, isRunningLate)
}

export const getBackedOutCount = (participants, people) => {
  return getPeopleCount(participants, people, isBackedOut)
}

export const getCritmassGoingCount = (participants, people) => {
  return getPeopleCount(participants, people, countForCritmass)
}

const teeupsFiltering = (
  teeupsData,
  selectedFiltering,
  currentUserId,
  teeupsGameplans
) => {
  let userTeeups = [...teeupsData]
  Object.keys(selectedFiltering).forEach(filterKey => {
    const filters = selectedFiltering[filterKey]
    if (filters.length === 0) return
    const isOthersFilter = filterKey === "other"
    userTeeups = _.filter(userTeeups, teeup =>
      _.find(filters, filterItem => {
        if (!filterItem) return null
        // const isScheduledUnscheduled =
        //   filterItem.id === teeupStageFilterKeys.planning ||
        //   filterItem.id === teeupStageFilterKeys.planning_unscheduled
        // if (isScheduledUnscheduled) {
        //   const isScheduled = filterItem.id === teeupStageFilterKeys.planning
        //   if (teeup.status !== teeupStageFilterKeys.planning) {
        //     return false
        //   }

        //   const teeupGameplans = teeupsGameplans[teeup.id]
        //   if (!teeupGameplans) return false
        //   for (let gameplan of teeupGameplans) {
        //     if (!gameplan.suggestions.length) return isScheduled ? false : true
        //     for (let suggestion of gameplan.suggestions) {
        //       return isScheduled
        //         ? checkIfScheduled(suggestion)
        //         : checkIfNoExactWhen(suggestion)
        //     }
        //   }
        // }
        if (isOthersFilter) {
          const {
            filter: {
              isCurrentUserCreator,
              isBlankGamePlan,
              isStale,
              isUnseen,
            },
          } = filterItem
          if (isCurrentUserCreator) {
            return teeup.createdby === currentUserId
          }
          // if (isNoExactWhen) {
          //   const teeupGameplans = teeupsGameplans[teeup.id]
          //   if (!teeupGameplans) return false
          //   for (let gameplan of teeupGameplans) {
          //     if (!gameplan.suggestions.length) return true
          //     for (let suggestion of gameplan.suggestions) {
          //       return checkIfNoExactWhen(suggestion) // noExactWhen
          //     }
          //   }
          // }
          if (isBlankGamePlan) {
            return isGameplansEmpty(teeup.gameplans)
          }
          if (isStale) {
            const diff = getDiffInDays(teeup.updatedAt || teeup.updatedat)
            return diff >= 7 ? true : false
          }
          if (isUnseen) {
            const { seen, newTitle, newWhen, newWhere, newMessages } = teeup
            const hasUpdate = seen === false
            const isNewTitle = hasUpdate && !!newTitle
            const isNewWhen = hasUpdate && !!newWhen
            const isNewWhere = hasUpdate && !!newWhere
            return (
              isNewTitle ||
              isNewWhen ||
              isNewWhere ||
              (hasUpdate && newMessages > 0)
            )
          }
        }
        return filterItem.id === teeup[filterKey]
      })
    )
  })
  return userTeeups
}

const checkIfNoExactWhen = sug =>
  (sug && !sug.selected && !sug.decided && !isExactWhen(sug)) ||
  sug.startDate === undefined

const isExactWhen = suggestion =>
  !_.isEmpty(suggestion) && !suggestion.isCustomDate && !suggestion.isCustomTime

export const getDecidedWhen = teeup => {
  const decidedSuggestion = teeup.gameplans
    .find(gameplan => gameplan.title === "When")
    ?.suggestions.find(
      suggestion => suggestion.isDecided || suggestion.isSelected
    )
  if (!decidedSuggestion) return null
  return decidedSuggestion.when
}

export const isTeeupDecided = teeup => {
  const whenGameplan = teeup.gameplans.find(
    gameplan => gameplan.title === "When"
  )
  if (whenGameplan.suggestions.length > 0)
    return Boolean(
      whenGameplan.suggestions.find(suggestion => suggestion.isDecided)
    )
  return false
}

const isGameplansEmpty = gameplans => {
  let isGPEmpty = true
  if (!gameplans || gameplans.length === 0) return isGPEmpty
  for (let gameplan of gameplans) {
    if (gameplan && gameplan.suggestions && gameplan.suggestions.length > 0) {
      isGPEmpty = false
      break
    } else continue
  }
  return isGPEmpty
}

const teeupSorting = (teeups = [], selectedSorting, teeupsGameplans) =>
  [...teeups].sort((a, b) => {
    const firstTeeupGameplans = teeupsGameplans[a.id]
    const secondTeeupGameplans = teeupsGameplans[b.id]
    if (selectedSorting.sort === SORT_KEYS.startsWhen) {
      return startsWhenSorting(firstTeeupGameplans, secondTeeupGameplans)
    } else if (selectedSorting.sort === SORT_KEYS.noExactWhen) {
      return unscheduledSorting(firstTeeupGameplans, secondTeeupGameplans)
    } else {
      const timeDif =
        new Date(a[selectedSorting.sort]) - new Date(b[selectedSorting.sort])
      return timeDif > 0 ? 1 : timeDif < 0 ? -1 : 0
    }
  })

const startsWhenSorting = (firstTeeupGameplans, secondTeeupGameplans) => {
  if (!firstTeeupGameplans && !secondTeeupGameplans) return 0
  else if (!secondTeeupGameplans) return 1
  else if (!firstTeeupGameplans) return -1
  const gameplansLength =
    firstTeeupGameplans.length >= secondTeeupGameplans.length
      ? firstTeeupGameplans.length
      : secondTeeupGameplans.length
  for (let i = 0; i < gameplansLength; i++) {
    const gameplan1 = firstTeeupGameplans[i]
    const gameplan2 = secondTeeupGameplans[i]

    if (!gameplan1?.suggestions && !gameplan2?.suggestions) return 0
    else if (!gameplan2?.suggestions) return 1
    else if (!gameplan1?.suggestions) return -1
    const suggestionsLength =
      gameplan1.suggestions.length >= gameplan2.suggestions.length
        ? gameplan1.suggestions.length
        : gameplan2.suggestions.length
    for (let n = 0; n < suggestionsLength; n++) {
      const suggestion1 = gameplan1.suggestions[n]
      const suggestion2 = gameplan2.suggestions[n]
      if (!suggestion2 && !suggestion1) return 0
      else if (!suggestion1) return 1
      else if (!suggestion2) return -1

      if (
        (suggestion1.startsWhen &&
          suggestion1.startsWhen.startDate &&
          suggestion2.startsWhen &&
          suggestion2.startsWhen.startDate) ||
        (suggestion1.startDate && suggestion2.startDate)
      ) {
        const firstSuggestionDiff = new Date(suggestion1.startDate) - Date.now()
        const secondSuggestionDiff =
          new Date(suggestion2.startDate) - Date.now()
        const difference = firstSuggestionDiff - secondSuggestionDiff
        if (firstSuggestionDiff < 0 && secondSuggestionDiff < 0) {
          return difference > 0 ? -1 : difference < 0 ? 1 : 0
        } else if (firstSuggestionDiff < 0) {
          return 1
        } else if (secondSuggestionDiff < 0) {
          return -1
        }
        return difference < 0 ? -1 : difference > 0 ? 1 : 0
      } else if (suggestion1.startsWhen && suggestion1.startsWhen.startDate) {
        return -1
      } else if (suggestion2.startsWhen && suggestion2.startsWhen.startDate) {
        return 1
      } else return 0
    }
  }
}

const unscheduledSorting = (firstTeeupGameplans, secondTeeupGameplans) => {
  let firstGameplansRes = false
  let secondGameplansRes = false
  for (let gameplan of firstTeeupGameplans) {
    for (let suggestion of gameplan.suggestions) {
      if (!checkIfNoExactWhen(suggestion)) {
        firstGameplansRes = true
      }
      continue
    }
    continue
  }
  for (let secondGameplan of secondTeeupGameplans) {
    for (let secondSuggestion of secondGameplan.suggestions) {
      if (!checkIfNoExactWhen(secondSuggestion)) {
        secondGameplansRes = true
      }
      continue
    }
    continue
  }
  if (firstGameplansRes && secondGameplansRes) return 0
  if (secondGameplansRes) return -1
  if (firstGameplansRes) return 1

  return 0
}

const initialFiltersValues = {
  status: [], // one of teeupStatusKeys
  userStatus: [], // one of teeupUserStatusKeys
  type: [], // one of teeupTypes
  other: [],
}

const formatTeeupUsers = (users = [], teeupId) => {
  const people = {}
  const filteredUsers = users.filter(user => user.status !== 19)
  let participants = filteredUsers.map(user => {
    let person = formatTeeupUser(user, users)
    people[person.id] = person
    return person.id
  })

  return { teeupId, participants, people }
}

const getUserStatus = (status, prevStatus) => {
  let correctStatus = status
  if (prevStatus) {
    if (
      [teeupUserStatusKeys.droppedout, teeupUserStatusKeys.skipped].includes(
        status
      )
    )
      correctStatus = userStatusTypes[prevStatus] || status
    if (
      prevStatus === teeupUserStatusPriorities.droppedout &&
      correctStatus === teeupUserStatusKeys.droppedout
    ) {
      correctStatus = teeupUserStatusKeys.joined
    }
  }

  return correctStatus
}

const formatTeeupUser = (
  {
    id,
    name,
    isVip,
    avatar,
    status,
    prevStatus,
    timezone,
    username,
    countryCode,
    additionalCount,
    lastTeeupActivity,
    backgroundImageUrl,
    primaryLocation,
    joinedAt,
    statusChangedAt,
    isOrganiser,
    contactList,
    invitedBy,
  },
  users
) => ({
  id,
  username,
  avatar,
  additionalCount,
  isVip,
  timezone: timezone || "UTC",
  countryCode,
  name: name ? name : "unknown",
  status: status
    ? getUserStatus(userStatusTypes[status], prevStatus)
    : "invited",
  lastTeeupActivity: convertLastTeeupActivityRow(lastTeeupActivity, users),
  lastTeeupActivityCreatedAt: lastTeeupActivity?.createdAt,
  statusChangedAt,
  backgroundImageUrl,
  primaryLocation,
  joinedAt,
  isOrganiser,
  prevStatus,
  contactList: contactList || [],
  invitedBy,
  invitedByUsername: users.find(user => user.id === invitedBy)?.username,
})

const convertLastTeeupActivityRow = (activity, users) => {
  try {
    if (!activity) return

    let status
    let row = gameplanTypes.reverse[activity.data?.type]
    let key = teeupLastActivityTypes.reverse[activity.type]
    let type = `${activity.data.type}`
    let names
    let count
    switch (activity.type) {
      case teeupLastActivityTypes.changeUserStatus: {
        status = teeupUserGenericStatus[userStatusTypes[activity.data.status]]
        break
      }
      case teeupLastActivityTypes.changeTeeupStatus: {
        status = teeupStatus[teeupStatusTypes[activity.data.status]]
        break
      }
      case teeupLastActivityTypes.inviteToTeeup: {
        if (!activity.data.userIds || activity.data.userIds.length === 0) {
          console.log("Teeup user activity is invalid")
          return
        }
        const {
          data: { userIds },
        } = activity
        count = userIds.length
        if (count > 2) {
          key = "invitePeopleToTeeup"
        } else {
          names = users
            .filter(user => userIds.includes(user.id))
            .map(user => user.name)
            .join(", ")

          if (names.length === 0) {
            console.log(userIds, "are not teeup participants")
            return
          }
        }
        break
      }
    }
    return strings.formatString(strings.teeupActivity[key], {
      status,
      row,
      type,
      names,
      count,
    })
  } catch (error) {
    console.error(error)
    // console.log("Teeup user activity is invalid")
    return
  }
}
const frequentSorting = (foundedContacts = []) => {
  if (foundedContacts.length === 0) {
    return foundedContacts
  }
  return foundedContacts.sort((a, b) => b.count - a.count)
}

const recentSorting = (foundedContacts = []) => {
  if (foundedContacts.length === 0) {
    return foundedContacts
  }
  return foundedContacts.sort(
    (a, b) =>
      moment(b.updatedAt ? b.updatedAt : b.createdAt).valueOf() -
      moment(a.updatedAt ? a.updatedAt : a.createdAt).valueOf()
  )
}

const EMPTY_AVATAR = 1_000_000_001

const filterParticipants = (participants, people, teeup) => {
  if (!people) {
    return participants
  }

  if (teeup.filter === PARTICIPANTS.LAST_ACTIVITY) {
    let sortedByLastActivity = [...participants]
    sortedByLastActivity.sort((a, b) =>
      dateComparator(
        people[b].lastTeeupActivityCreatedAt,
        people[a].lastTeeupActivityCreatedAt,
        true
      )
    )
    return sortedByLastActivity
  }

  if (teeup.filter === PARTICIPANTS.STATUS_CHANGE) {
    let sortedByLastStatusChange = [...participants]
    sortedByLastStatusChange.sort((a, b) =>
      dateComparator(people[b].statusChangedAt, people[a].statusChangedAt, true)
    )
    return sortedByLastStatusChange
  }

  if (teeup.filter === PARTICIPANTS.STATUS) {
    let sortedParticipantsByStatus = [...participants]
    sortedParticipantsByStatus.sort((a, b) => {
      if (!people || !people[a] || !people[b]) {
        return false
      }
      return (
        teeupParticipantStatusPriorities[people[a].status] -
        teeupParticipantStatusPriorities[people[b].status]
      )
    })
    return sortedParticipantsByStatus
  }

  if (teeup.filter === PARTICIPANTS.NAME) {
    let sortedParticipantsByName = [...participants]
    sortedParticipantsByName.sort((a, b) => {
      if (!people || !people[a] || !people[b]) {
        return false
      }
      return people[a].name.localeCompare(people[b].name)
    })

    return sortedParticipantsByName
  }

  return participants
}

const isShowAdditionalPeople = (status, hasAdditionalPeople) =>
  !!hasAdditionalPeople &&
  (status === teeupUserStatusKeys.going ||
    status === teeupUserStatusKeys.mightgo ||
    status === teeupUserStatusKeys.onmyway ||
    status === teeupUserStatusKeys.arrived ||
    status === teeupUserStatusKeys.runningLate)

const userStatusImage = (userStatus, hasBg = true) => {
  let image
  switch (userStatus) {
    case teeupUserStatusKeys.going:
      image = hasBg ? images.going : images.goingNoBg
      break
    case teeupUserStatusKeys.notgoing:
      image = hasBg ? images.notgoing : images.notgoingNoBg
      break
    case teeupUserStatusKeys.mightgo:
      image = hasBg ? images.mightgo : images.mightgoNoBg
      break
    case teeupUserStatusKeys.invited:
      image = hasBg ? images.invited : images.invitedNoBg
      break
    case teeupUserStatusKeys.onmyway:
      image = hasBg ? images.onmyway : images.onmywayNoBg
      break
    case teeupUserStatusKeys.arrived:
      image = hasBg ? images.arrived : images.arrivedNoBg
      break
    case teeupUserStatusKeys.attended:
      image = hasBg ? images.attended : null
      break
    case teeupUserStatusKeys.droppedout:
      image = hasBg ? images.droppedout : images.droppedoutNoBg
      break
    case teeupUserStatusKeys.runningLate:
      image = hasBg ? images.runningLate : images.runningLateNoBg
      break
    case teeupUserStatusKeys.backedOut:
      image = hasBg ? images.backedOut : images.backedOutNoBg
      break
    default:
      break
  }

  return image
}

const getAvatarSize = expanded => (expanded ? 60 : 44)

const getStatusSize = (expanded, polling) => {
  if (polling) {
    return expanded ? 23 : 16
  }
  return expanded ? 20 : 20
}

const getBestSuggestionPoll = ({ pollsSuggestions, people }) => {
  if (!pollsSuggestions || !people) {
    return
  }
  let bestChoice = { bestLevel: 0, reactions: [] }
  let bestChoiceUsersVoted = getVotedUsersInPoll(bestChoice, notVipUsersIds)
  let bestChoiceVipUsersVoted = getVotedUsersInPoll(bestChoice, vipUsersIds)

  const notVipUsersIds = Object.keys(people).filter(key => !people[key].isVip)
  const vipUsersIds = Object.keys(people).filter(key => people[key].isVip)
  for (let poll of pollsSuggestions) {
    if (bestChoice && poll) {
      let positiveReactions = []
      let negativeReactions = []
      let neutralReactions = []
      let notVipUsersVoted = 0
      let vipUsersVoted = 0
      poll.reactions?.length &&
        poll.reactions.forEach(reaction => {
          if (reaction.reactionId === reactionTypes.checkMark) {
            positiveReactions = reaction.reactedBy
          }
          if (reaction.reactionId === reactionTypes.XMark) {
            negativeReactions = reaction.reactedBy
          }
          if (reaction.reactionId === reactionTypes.question) {
            neutralReactions = reaction.reactedBy
          }
        })
      _.forEach(notVipUsersIds, id => {
        if (
          _.find(positiveReactions, posVotedUser => posVotedUser === Number(id))
        ) {
          notVipUsersVoted++
        }
      })
      _.forEach(vipUsersIds, id => {
        if (
          _.find(positiveReactions, posVotedUser => posVotedUser === Number(id))
        ) {
          vipUsersVoted++
        }
      })
      const hasMoreReactions =
        bestChoice?.reactions?.findIndex(
          r =>
            r.reactionId === reactionTypes.checkMark &&
            r.reactedBy?.length > positiveReactions.length
        ) === -1
      if (positiveReactions.length === Object.keys(people).length) {
        bestChoiceUsersVoted = notVipUsersVoted
        bestChoiceVipUsersVoted = vipUsersVoted
        bestChoice = { ...poll, bestLevel: 4 }
      } else if (
        notVipUsersVoted >= bestChoiceUsersVoted &&
        vipUsersVoted >= bestChoiceVipUsersVoted &&
        bestChoice.bestLevel <= 3 &&
        hasMoreReactions
      ) {
        bestChoiceUsersVoted = notVipUsersVoted
        bestChoiceVipUsersVoted = vipUsersVoted
        bestChoice = { ...poll, bestLevel: 3 }
      } else if (
        positiveReactions.length > negativeReactions.length &&
        positiveReactions.length > neutralReactions.length &&
        bestChoice.bestLevel <= 2 &&
        hasMoreReactions
      ) {
        bestChoiceUsersVoted = notVipUsersVoted
        bestChoiceVipUsersVoted = vipUsersVoted
        bestChoice = { ...poll, bestLevel: 2 }
      } else if (
        positiveReactions.length > 0 &&
        bestChoice.bestLevel <= 1 &&
        hasMoreReactions
      ) {
        bestChoiceUsersVoted = notVipUsersVoted
        bestChoiceVipUsersVoted = vipUsersVoted
        bestChoice = { ...poll, bestLevel: 1 }
      }
    }
  }
  return bestChoice
}

const countForCritmass = status =>
  status === "going" || status === "arrived" || status === "onmyway"

// const getPollTime = (
//   pollStatus,
//   people,
//   suggestionsList,
//   gameplans,
//   reactions
// ) => {
//   switch (pollStatus) {
//     case gameplanOptionStatusIds.POLLING: {
//       let pollsSuggestions = []
//       // TODO
//       // suggestionsList?.forEach((s) => {
//       //     if (s.time && s.time.length > 0) {
//       //         pollsSuggestions = pollsSuggestions.concat(s.time)
//       //     }
//       // })
//       const c = getBestSuggestionPoll({
//         pollsSuggestions,
//         people,
//       })
//       return c
//     }
//     case gameplanOptionStatusIds.SCHEDULED:
//     case gameplanOptionStatusIds.POLL_COMPLETE:
//     case gameplanOptionStatusIds.POLL_CLOSED:
//     default:
//       return getSelectedSuggestion(suggestionsList, gameplans, reactions)
//   }
// }

const getReactionsResult = (
  pollStatus,
  people,
  suggestionsList,
  gameplans,
  reactions
) => {
  let reactionsTotal = { approve: 0, cancel: 0, question: 0 }

  // TODO
  // suggestion?.reactions?.forEach((r) => {
  //     if (
  //         r.reactionId === reactionTypes.checkMark ||
  //         r?.reactionId === worksWhenToWhenReaction(reactionTypes.checkMark)
  //     ) {
  //         reactionsTotal = {
  //             ...reactionsTotal,
  //             approve: reactionsTotal.approve + r.reactedBy.length,
  //         }
  //     }
  //     if (
  //         r.reactionId === reactionTypes.XMark ||
  //         r?.reactionId === worksWhenToWhenReaction(reactionTypes.XMark)
  //     ) {
  //         reactionsTotal = {
  //             ...reactionsTotal,
  //             cancel: reactionsTotal.cancel + r.reactedBy.length,
  //         }
  //     }
  //     if (
  //         r.reactionId === reactionTypes.question ||
  //         r?.reactionId === worksWhenToWhenReaction(reactionTypes.question)
  //     ) {
  //         reactionsTotal = {
  //             ...reactionsTotal,
  //             question: reactionsTotal.question + r.reactedBy.length,
  //         }
  //     }
  // })
  return reactionsTotal
}

const getSelectedSuggestion = (
  suggestions = [],
  gameplans = [],
  reactions = []
) => {
  let suggestion = {}
  // TODO
  // suggestions?.forEach((s) => {
  //     if (s.decided && s.selected) {
  //         const currentSuggestion = JSON.parse(JSON.stringify(s))
  //         if (
  //             !currentSuggestion.reactions ||
  //             currentSuggestion.reactions.length === 0
  //         ) {
  //             currentSuggestion.reactions = reactions
  //         }
  //         suggestion = { ...currentSuggestion }
  //     }
  // })

  if (suggestions.length === 0) {
    gameplans.forEach(g => {
      if (g.suggestions && g.suggestions.length > 0) {
        const sIndex = g.suggestions.findIndex(s => s.decided && s.selected)
        if (sIndex !== -1) {
          const currentSuggestion = JSON.parse(
            JSON.stringify(g.suggestions[sIndex])
          )
          if (
            !currentSuggestion.reactions ||
            currentSuggestion.reactions.length === 0
          ) {
            currentSuggestion.reactions = reactions
          }
          suggestion = { ...currentSuggestion }
        }
      }
    })
  }

  return suggestion
}

const isOrganiser = (userId, organisers = []) =>
  organisers.some(({ id }) => id === userId)

const isOwner = (userId, teeup = {}) => userId === teeup.createdby

const isTeeupCancelled = teeup => {
  if (_.isString(teeup.status)) {
    return teeup.status === "cancelled"
  } else {
    // To avoid formatting teeups when only checking if update is needed
    return (
      teeup.status === Number.parseInt(teeupStatusTypes.reverse["cancelled"])
    )
  }
}

const getVotedUsersInPoll = (poll, usersArray) => {
  let votedUsersIds = []
  const checkPollFunc = part =>
    _.forEach(
      part.reactions,
      reaction =>
        reaction &&
        reaction.reactedBy &&
        _.forEach(reaction.reactedBy, votedUserId => {
          if (votedUserId) {
            _.forEach(usersArray, participantId => {
              if (
                Number(participantId) === Number(votedUserId) &&
                !_.some(votedUsersIds, id => Number(id) === Number(votedUserId))
              ) {
                votedUsersIds.push(Number(votedUserId))
              }
            })
          }
        })
    )
  if (poll) {
    if (Array.isArray(poll.time)) {
      for (let time of poll.time) {
        checkPollFunc(time)
      }
    } else checkPollFunc(poll)
  }
  return votedUsersIds.length
}
const isTeeupDisabled = teeup => {
  if (_.isString(teeup.status)) {
    return teeup.status === "ended" || teeup.status === "cancelled"
  } else {
    // To avoid formatting teeups when only checking if update is needed
    return (
      teeup.status === Number.parseInt(teeupStatusTypes.reverse["ended"]) ||
      teeup.status === Number.parseInt(teeupStatusTypes.reverse["cancelled"])
    )
  }
}

const isTeeupActive = teeup => {
  if (teeup.isArchived) {
    return false
  }

  return !isTeeupDisabled(teeup)
}

// const worksWhenToWhenReaction = (id) => {
//   switch (id) {
//     case reactionTypes.checkMark:
//       return reactionTypes.upvote
//     case reactionTypes.XMark:
//       return reactionTypes.downvote
//     default:
//       return reactionTypes.question
//   }
// }

const userPollingStatusImage = voteStatus => {
  let image
  switch (voteStatus) {
    case reactionTypes.checkMark:
      image = images.respondedStatus
      break
    case reactionTypes.question:
      image = images.respondedQuestionStatus
      break
    case reactionTypes.XMark:
      image = images.respondedCantStatus
      break
    default:
      break
  }

  return image
}

const getMentionedUsersIds = (text, people, userId) => {
  let mentionedUserIds = []
  const matchedUsers = getAllUserMentions(text)
  const participants = Object.values(people)
  if (
    matchedUsers.find(
      mention => mention.toLowerCase() === "Everybody".toLowerCase()
    )
  ) {
    mentionedUserIds = participants
      .filter(i => i.id !== userId)
      .map(user => user.id)
  } else {
    matchedUsers.forEach(matchedUsername => {
      const matchedUser = participants.find(
        user =>
          // user.username.toLowerCase().trim() === matchedUsername.toLowerCase()
          getUsernameOrName(user).toLowerCase().trim() ===
          matchedUsername.toLowerCase()
      )
      if (matchedUser) {
        mentionedUserIds.push(matchedUser.id)
      }
    })
  }
  return mentionedUserIds
}

const getAllUserMentions = text => {
  const userRegex = /((?:\n|^| |,|.|-)(@([\d()-{}—]*)))/gi
  const match = text.match(userRegex)

  if (!match) {
    return []
  }

  let mentions = []

  match.forEach(m => {
    const matchedUser = getUsenameFromMentionText(m)
    mentions.push(matchedUser)
  })
  return mentions
}

const getUsenameFromMentionText = (mentionText = "") =>
  mentionText.split("@")[1].trim()

const getSuffixForAdditionalPeople = status => {
  if (status === teeupUserStatusKeys.going) {
    return "and bringing"
  }

  if (status === teeupUserStatusKeys.mightgo) {
    return "and bring"
  }

  if (
    status === teeupUserStatusKeys.onmyway ||
    status === teeupUserStatusKeys.runningLate ||
    status === teeupUserStatusKeys.arrived
  ) {
    return "with"
  }
}

const userStatusSystemMessageImage = userStatus => {
  let image
  switch (userStatus) {
    case teeupUserStatusKeys.going:
      image = images.systemStatusGoing
      break
    case teeupUserStatusKeys.notgoing:
      image = images.systemStatusNotGoing
      break
    case teeupUserStatusKeys.mightgo:
      image = images.systemStatusMightGo
      break
    case teeupUserStatusKeys.onmyway:
      image = images.systemStatusOnMyWay
      break
    case teeupUserStatusKeys.arrived:
      image = images.systemStatusArrived
      break
    case teeupUserStatusKeys.runningLate:
      image = images.systemStatusRunningLate
      break
    case teeupUserStatusKeys.backedOut:
      image = images.systemStatusBackedOut
      break
    default:
      break
  }

  return image
}

const formatLastAction = message => {
  const { statusId, typeId, conversationTypeId } = message
  let text
  switch (conversationTypeId) {
    case conversationTypeIds.suggestion:
      text = `Added ${message.type} suggestion`
      break
    case conversationTypeIds.message:
    case conversationTypeIds.reply:
      text = `Added a message`
      break
    case conversationTypeIds.status:
      switch (typeId) {
        case 1: {
          // Teeup status
          text = `Set TeeUp status to ${formatTeeupStatusText(statusId)}`

          break
        }
        case 2: {
          // User status
          text = `Set status to ${formatUserStatusText(statusId)}`

          break
        }
        case 3: {
          // Reaction
          text =
            `Reacted to a suggestion with ` + reactionsTranslations[statusId]

          break
        }
        // No default
      }
      break
    default:
      text = `Other last action`
  }

  return {
    text,
    timestamp: message.timestamp,
  }
}

const isMultizoneTeeup = people => {
  let timezone
  let multizone = false
  let participants = Object.keys(people)
  for (let i = 0, len = participants.length; i < len; i++) {
    let participant = people[participants[i]]

    if (!participant.timezone) {
      continue
    }

    if (!timezone) {
      timezone = participant.timezone
      continue
    }

    if (participant.timezone !== timezone) {
      multizone = true
      break
    }
  }
  return multizone
}

const extractGameplansFromExistingTeeup = (
  defaultGameplans,
  gameplans,
  useWhen
) => {
  const newGameplans = useWhen
    ? gameplans
    : gameplans.filter(gameplan => gameplan.type !== "when")
  return defaultGameplans.map(defaultGameplan => {
    const newGameplan = newGameplans.find(
      gp => gp.type === defaultGameplan.type
    )
    if (newGameplan) {
      return {
        ...defaultGameplan,
        suggestions: newGameplan.suggestions,
      }
    }
    return defaultGameplan
  })
}

const formatAskedUsers = (users = []) => {
  switch (users.length) {
    case 1:
      return users[0]
    case 2:
      return `${users[0]} and ${users[1]}`
    case 3:
      return `${users[0]}, ${users[1]} and ${users[2]}`
    default:
      if (users.length > 3)
        return `${users[0]}, ${users[1]} and ${users.length - 2} others`
  }
}

const userStatusForNudgesImages = userStatus => {
  let image
  switch (userStatus) {
    case teeupUserStatusKeys.going:
      image = images.goingWithoutBG
      break
    case teeupUserStatusKeys.notgoing:
      image = images.notGoingWithoutBG
      break
    case teeupUserStatusKeys.mightgo:
      image = images.mighgoWithoutBG
      break
    case teeupUserStatusKeys.onmyway:
      image = images.onmywayWithoutBG
      break
    case teeupUserStatusKeys.arrived:
      image = images.arrivedWithoutBG
      break
    case teeupUserStatusKeys.runningLate:
      image = images.runningLateWithoutBG
      break
    case teeupUserStatusKeys.backedOut:
      image = images.backedoutWithoutBG
      break
    default:
      break
  }

  return image
}

const saveSettingsValue = ({ teeUpId, settings, key, val }) => {
  updateTeeup({
    teeUpId,
    teeup: {
      settings: {
        ...settings,
        [key]: val,
      },
    },
  })
}

const teeupStatusOptions = [
  {
    value: "var(--orange2)",
    backgroundColor: "var(--white2)",
    label: teeupStatusKeys.planning,
  },
  {
    value: "var(--darkGreen)",
    backgroundColor: "var(--lightMint3)",
    label: teeupStatusKeys.allset,
  },
  {
    value: "var(--violet)",
    backgroundColor: "var(--lightViolet)",
    label: teeupStatusKeys.happeningSoon,
  },
  {
    value: "var(--lightBlue)",
    backgroundColor: "var(--lightBlue2)",
    label: teeupStatusKeys.happening,
  },
  {
    value: "var(--gray4)",
    backgroundColor: "var(--lightGray)",
    label: teeupStatusKeys.ended,
  },
  {
    value: "var(--red2)",
    backgroundColor: "var(--pink)",
    label: teeupStatusKeys.cancelled,
  },
]

const getTeeupStatusOption = statusStr => {
  return teeupStatusOptions.find(item => item.label === statusStr)
}

export {
  isMultizoneTeeup,
  DEFAULT_SORT,
  SORT_KEYS,
  TEEUP_LIST_MODES,
  initialFiltersValues,
  teeupsFiltering,
  teeupSorting,
  getGoingCount,
  formatTeeupUsers,
  frequentSorting,
  recentSorting,
  EMPTY_AVATAR,
  filterParticipants,
  isShowAdditionalPeople,
  userStatusImage,
  getAvatarSize,
  getStatusSize,
  getBestSuggestionPoll,
  getReactionsResult,
  getSelectedSuggestion,
  isOrganiser,
  isOwner,
  isTeeupCancelled,
  isTeeupDisabled,
  isTeeupActive,
  userPollingStatusImage,
  getMentionedUsersIds,
  getAllUserMentions,
  getSuffixForAdditionalPeople,
  userStatusSystemMessageImage,
  formatLastAction,
  formatAskedUsers,
  userStatusForNudgesImages,
  extractGameplansFromExistingTeeup,
  formatUserStatusText,
  saveSettingsValue,
  teeupStatusOptions,
  getTeeupStatusOption,
}
