import { useMemo } from "react"

import { loginProviders } from "@config/enums"

import { BUTTONS_ACTIONS } from "../constants"

export const useListActions = ({
  linkedCalendar,
  setIsMyAvailability,
  setIsUnlinkCalendar,
}) => {
  return useMemo(() => {
    const calendarIcon =
      linkedCalendar?.provider === loginProviders.google
        ? "google"
        : "outlook-2"
    const isCalendar = !!linkedCalendar
    const defaultBorderColor = "var(--lightGray5)"
    const calendarBtnBorderColor = isCalendar
      ? "var(--gray4)"
      : defaultBorderColor

    return [
      // {
      //   id: BUTTONS_ACTIONS.MAKE_SUGGESTION,
      //   iconName: "lamp",
      //   borderColor: defaultBorderColor,
      //   text: "Make a Suggestion",
      //   action: () => {
      //     setIsMakeSuggestion(true)
      //   },
      // },
      {
        id: BUTTONS_ACTIONS.CALENDAR_ACTION,
        isCalendar: isCalendar,
        borderColor: calendarBtnBorderColor,
        iconName: isCalendar ? calendarIcon : "",
        isColorIgnore: isCalendar,
        text: isCalendar ? "Calendar connected" : "Overlay my calendar",
        action: () => {
          if (linkedCalendar) {
            setIsUnlinkCalendar(true)
          } else {
            setIsMyAvailability(true)
          }
        },
      },
    ]
  }, [linkedCalendar])
}
