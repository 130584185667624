import React from "react"

import _ from "lodash"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { updateUserStatus } from "@actions/requestActions"
import { IconButtonEl, SvgIcon } from "@components/common"
import SuggestionNudge from "@components/nudges/suggestionNudge"
import {
  teeupListNudgeTypes,
  teeupUserStatus,
  teeupUserStatusKeys,
} from "@configs/enums"
import I18n from "@i18n"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import {
  selectTeeupReactionsBySuggestionId,
  selectTeeupPeople,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { images } from "@utils/imageUtils"

import { userStatusTypes } from "../../../config/mappings"
import { DialogButtonWithIcon } from "../dialogButtons/dialogButtons"

import { SuggestionNudgeResponseStyles as styles } from "./styles"

const SuggestionNudgeResponseView = ({
  fromTeeupList,
  subTypeId,
  nudgeIssuer,
  suggestionResponse,
  onSave,
  handleClose,
  suggestion,
  isTeeupSocketConnected,
  reactions,
  currentUserId,
  teeup,
  people,
  updateUserStatus,
}) => {
  let userStatus = teeupUserStatusKeys.invited
  let additionalCount = 0
  let userParticipant = people[currentUserId]

  if (userParticipant) {
    userStatus = userParticipant.status
    additionalCount = userParticipant.additionalCount || 0
  }

  const includesAlreadyResponded = !!reactions.find(
    reaction =>
      reaction &&
      reaction.reactedBy &&
      reaction.reactedBy.includes(currentUserId)
  )

  const handleUserStatusSelected = (chosenStatus, additionalCount) => {
    if (!chosenStatus) {
      return
    }

    const userStatusKey = teeupUserStatus[chosenStatus]
      ? userStatus
      : teeupUserStatus.reverse[chosenStatus]
    let statusId = userStatusTypes.reverse[userStatusKey]
    updateUserStatus(teeup.id, statusId, additionalCount)
  }

  const handleClickStatus = item => {
    if (userStatus === item.status) {
      // Send an empty string as a parameter to dismiss the overlay
      handleUserStatusSelected("")
    } else {
      handleUserStatusSelected(item.title)
    }
  }

  const renderItem = (item, index) => {
    const reaction = reactions.find(rt => rt.reactionId === item.id)
    const alreadyResponded =
      reaction &&
      reaction.reactedBy &&
      reaction.reactedBy.includes(currentUserId)

    const isLast = index === suggestionResponse - 1
    const buttonImageProps = item.icon
      ? {
          icon: item.icon,
          useIconComponent: true,
        }
      : {
          primaryIcon: item.image,
          primaryIconStyles: styles.nudgeStatusIcon,
        }

    return (
      <DialogButtonWithIcon
        key={item.id}
        // containerStyles={{
        //     ...(!isLast && styles.statusItemMargin),
        // }}
        containerStyles={!isLast && styles.statusItemMargin}
        {...buttonImageProps}
        title={alreadyResponded ? `Still ${item.title}` : item.title}
        // title={alreadyResponded ? `Still` : 'Hello'}
        // onClickPrimary={onSave(alreadyResponded ? -1 : item.id)}
        onClickPrimary={() => {
          handleClickStatus(item)
          onSave(alreadyResponded ? -1 : item.id)()
        }}
        disabled={alreadyResponded}
        includesAlreadyResponded={includesAlreadyResponded}
      />
    )
  }

  const renderHeader = () => {
    return (
      <View style={styles.titleContainer}>
        <View style={styles.questionContainer}>
          <Text style={styles.question}>
            {
              I18n.nudgePromptContent[teeupListNudgeTypes.reverse[subTypeId]]
                .action
            }
          </Text>

          <SvgIcon
            name="close-2"
            css={{ width: 32, height: 32, cursor: "pointer" }}
            onClick={handleClose}
          />
        </View>

        {subTypeId === teeupListNudgeTypes.suggestion && (
          <View style={styles.suggestionNudgeContainer}>
            <SuggestionNudge onNudgesScreen suggestion={suggestion} />
          </View>
        )}
      </View>
    )
  }

  const hideButtonsForSuggestionType =
    _.isEmpty(suggestion) && subTypeId === teeupListNudgeTypes.suggestion

  return (
    <View style={{ width: "100%" }}>
      {renderHeader()}
      {(fromTeeupList || !hideButtonsForSuggestionType) && (
        <View style={styles.statusesContainer}>
          {suggestionResponse?.map(renderItem)}
        </View>
      )}
    </View>
  )
}

SuggestionNudgeResponseView.propTypes = {
  fromTeeupList: PropTypes.bool,
  subTypeId: PropTypes.number,
  suggestionResponse: PropTypes.array,
  suggestion: PropTypes.object,
  onSave: PropTypes.func,
  handleClose: PropTypes.func,
  nudgeIssuer: PropTypes.object,
  showViewInHighlightsButton: PropTypes.bool,
  isTeeupSocketConnected: PropTypes.bool,
  reactions: PropTypes.array,
  currentUserId: PropTypes.number,
}

const mapStateToProps = (state, props) => {
  const { suggestion, teeup } = props
  const suggestionId = suggestion ? suggestion.id : null
  const reactions =
    suggestion?.reactions ||
    selectTeeupReactionsBySuggestionId(suggestionId)(state)
  const currentUserId = selectUserId(state)
  const currentTeeup = teeup ?? selectActiveTeeup(state)
  const people = selectTeeupPeople(state)

  return {
    reactions,
    currentUserId,
    teeup: currentTeeup,
    people,
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionNudgeResponseView)
