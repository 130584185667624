import cn from "classnames"
import { useInboxStore } from "stores/inboxStore"

interface Props {
  isMenuOpen: boolean
  setIsMenuOpen: (isMenuOpen: boolean) => void
  onArchiveClick: (isArchiveTab?: boolean) => void
  isArchiveTab: boolean
}

const MenuButtons = ({
  isMenuOpen,
  setIsMenuOpen,
  onArchiveClick,
  isArchiveTab,
}: Props) => {
  const handleArchiveClick = () => {
    onArchiveClick(isArchiveTab)
  }

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={cn("overflow-hidden flex-1 z-30", {
        "!flex-auto": isMenuOpen,
      })}>
      <div
        className={cn(
          "flex flex-col border-l h-full border-neutral-200 text-base font-semibold text-neutral-800 transition-all duration-300",
          {
            "translate-x-0": isMenuOpen,
            "translate-x-full": !isMenuOpen,
          }
        )}>
        <div
          className="flex flex-1 cursor-pointer items-center justify-center px-10 py-3"
          onClick={handleArchiveClick}>
          {isArchiveTab ? "Unarchive" : "Archive"}
        </div>
        <div
          className="flex flex-1 cursor-pointer items-center justify-center border-t border-neutral-200 px-10 py-3"
          onClick={() => setIsMenuOpen(false)}>
          Close
        </div>
      </div>
    </div>
  )
}

export default MenuButtons
