import _ from "lodash"

import { teeupStatusTypes } from "@utils/mappings"

import actionTypes from "../actions/actionTypes"

const initialState = {
  id: null,
  name: null,
  invited: [],
  userStatus: "joined",
  gameplans: [
    {
      type: "when",
      icon: "clock",
      title: "When",
      isPrimary: true,
      suggestions: [],
    },
    {
      type: "where",
      icon: "map-marker-alt",
      title: "Where",
      isPrimary: true,
      suggestions: [],
    },
  ],
  organisers: [],
  settings: {},
  type: "basic",
  photo: null,
  interests: [],
  status: "planning",
  isPrivate: true,
  valid: false,
  messages: [],
  ignoredMessages: [],
  respondedMessages: [],
  detailsOpen: false,
  detailsUser: null,
  invitationHistory: [],
  isSuggestWhenModalOpen: false,
  isSuggestWhereModalOpen: false,
  isSuggestWhenAiModalOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_TEEUP:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.UPDATE_ACTIVE_TEEUP:
      const { payload } = action

      if (payload.id !== state.id) {
        return state
      }

      return {
        ...state,
        ...payload,
      }
    case actionTypes.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      }
    case actionTypes.ADD_MESSAGE:
      const { teeupId, message: newMessage } = action.payload

      const exists = state.messages.find(
        message => message.id === newMessage.id
      )
      if (exists || state.id !== teeupId) return state

      return {
        ...state,
        messages: [newMessage, ...state.messages],
      }
    case actionTypes.MANAGE_TEEUP_SUGGESTIONS:
      return {
        ...state,
        gameplans: action.payload.gameplanOptions,
        messages: [...state.messages],
      }
    case actionTypes.UPDATE_ACTIVE_TEEUP_STATUS:
      let updatedData = {}

      if (String(state.id) === String(action.payload.teeupId)) {
        updatedData = {
          status: teeupStatusTypes[action.payload.newStatus],
          messages: [...state.messages],
        }
      }

      return {
        ...state,
        ...updatedData,
      }
    case actionTypes.UPDATE_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(message => {
          if (message.id === action.payload.id) {
            return {
              ...message,
              ...action.payload,
            }
          } else if (message.gameplans) {
            // If message has GPs (initial message) id of GP can be passed
            const updatedGPs = message.gameplans.map(gp => {
              return gp.id === action.payload.id
                ? { ...gp, ...action.payload }
                : gp
            })
            return {
              ...message,
              gameplans: updatedGPs,
            }
          }
          return message
        }),
      }
    case actionTypes.GOT_TEEUP_ORGANISERS: {
      const { teeupId, organisers } = action.payload
      return state.id === teeupId ? _.assign({}, state, { organisers }) : state
    }
    case actionTypes.TOGGLE_PARTICIPANT_DETAILS: {
      return {
        ...state,
        detailsOpen: action.payload,
      }
    }
    case actionTypes.SELECT_DETAILS_USER: {
      return {
        ...state,
        detailsUser: action.payload,
      }
    }
    case actionTypes.SET_IS_SUGGEST_WHEN_OPEN: {
      return {
        ...state,
        isSuggestWhenModalOpen: action.payload,
      }
    }
    case actionTypes.SET_IS_SUGGEST_WHEN_AI_OPEN: {
      return {
        ...state,
        isSuggestWhenAiModalOpen: action.payload,
      }
    }
    case actionTypes.SET_IS_SUGGEST_WHERE_OPEN: {
      return {
        ...state,
        isSuggestWhereModalOpen: action.payload,
      }
    }
    case actionTypes.IGNORE_MESSAGE_PROMPT: {
      if (
        state.ignoredMessages.length > 0 &&
        state.ignoredMessages.find(im => im === action.payload)
      ) {
        return state
      }
      return {
        ...state,
        ignoredMessages: [...state.ignoredMessages, action.payload],
      }
    }
    case actionTypes.RESPOND_MESSAGE_PROPMT: {
      if (
        state.respondedMessages.length > 0 &&
        state.respondedMessages.find(rm => rm === action.payload)
      ) {
        return state
      }
      return {
        ...state,
        respondedMessages: [...state.respondedMessages, action.payload],
      }
    }
    default:
      return state
  }
}
