import React from "react"

import PropTypes from "prop-types"

import { SvgIcon } from "@components/common"
import { REACTION_TYPE } from "@constants/reactions"

import { Wrapper } from "./reactionStyles"

export const Reaction = ({ reaction }) => {
  const iconName =
    reaction.reactionId === REACTION_TYPE.DOWNVOTE
      ? "thumb-down-filled"
      : "thumb-up-filled"

  return <SvgIcon name={iconName} color="var(--gray4)" />
}

Reaction.propTypes = {
  reaction: PropTypes.object,
}
