// TODO: here was "moment" if everything is okay - remove this comment
import React from "react"

import moment from "moment-timezone"
import "./index.scss"

const CalendarListItem = ({ itemData: { name, startDate, endDate, id } }) => {
  return (
    <div className="comingUpListItem" key={id}>
      <div className="comingUpListItem__content">
        <div className="comingUpListItem__flag" />
        <div className="comingUpListItem--text">
          <div className="comingUpListItem__content">
            <span className="comingUpListItem--text__title">
              {name ?? "Private event"}
            </span>
          </div>
          <div className="comingUpListItem__content">
            <span className="comingUpListItem--text__time">
              {startDate && moment(startDate).format("LT")}{" "}
              {endDate && `- ${moment(endDate).format("LT")}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarListItem
