import React, { useEffect } from "react"

import { apps } from "@constants/apps"
import strings from "@i18n"

import useOnlineTabState from "../../Hooks/useOnlineTabState"

import {
  suggestionOnlineTypeOfLinks,
  getSuggestionOnlineTypeOfLink,
  getSuggestionOnlineValuesFromLink,
} from "@utils/gamePlanUtils"

import "./index.scss"
import { phoneRegExp } from "../../../../../../constants/phone"

const MeetingLink = ({ pasteText, comment, setPasteText, setComment }) => {
  const {
    activeApp,
    setActiveApp,
    setActiveButtonId,
    addSuggestDetails,
    setInitComment,
  } = useOnlineTabState()
  const onlineType = suggestionOnlineTypeOfLinks
  const suggestedType = getSuggestionOnlineTypeOfLink(pasteText)
  const suggestionData = getSuggestionOnlineValuesFromLink(pasteText)

  useEffect(() => {
    setInitComment(comment)
  }, [comment])

  const getMetaValue = key => {
    if (suggestionData) {
      const found = suggestionData?.metadata?.find(m => m.label === key)
      return found ? found.value : ""
    }
  }

  useEffect(() => {
    if (
      pasteText.includes("https://") &&
      activeApp.name &&
      !pasteText.includes(activeApp.name)
    ) {
      //determine whether it is a link or message and the correct link for the selected app or not
      const filteredApps = apps.filter(item => pasteText.includes(item.name))

      filteredApps.length === 0
        ? setActiveApp(apps.at(-1))
        : setActiveApp(filteredApps[0])
    } else if (phoneRegExp.test(pasteText)) {
      const phoneApp = apps.find(item => item.title === "Phone")

      if (phoneApp) {
        setActiveApp(phoneApp)
      }
    }
  }, [pasteText])

  const handleDeletePasteLink = () => {
    setPasteText(null)
    setComment(null)
    addSuggestDetails(null)
    setActiveButtonId(1)
  }

  return (
    <div className="suggest-details-info">
      <h5 className="suggest-details-info__title">
        {pasteText.includes("https://") ? "Meeting Link" : "Meeting Message"}
      </h5>
      {suggestedType?.type === onlineType.zoom && (
        <div className="suggest-details-info__details">
          {suggestionData.link && (
            <div className="suggest-details-info__info">
              <div className="suggest-details-info__info-title">Zoom Link</div>
              <div className="suggest-details-info__info-value suggest-details-info__info-value--link">
                <a href={suggestionData.link} target="_blank" rel="noreferrer">
                  {suggestionData.link}
                </a>
              </div>
            </div>
          )}
          {getMetaValue(strings.suggestionOnlineLinks.roomOwner) && (
            <div className="suggest-details-info__info">
              <div className="suggest-details-info__info-title">Room owner</div>
              <div className="suggest-details-info__info-value">
                {getMetaValue(strings.suggestionOnlineLinks.roomOwner)}
              </div>
            </div>
          )}
          {getMetaValue(strings.suggestionOnlineLinks.topic) && (
            <div className="suggest-details-info__info">
              <div className="suggest-details-info__info-title">Topic</div>
              <div className="suggest-details-info__info-value">
                {getMetaValue(strings.suggestionOnlineLinks.topic)}
              </div>
            </div>
          )}
          {getMetaValue(strings.suggestionOnlineLinks.meetingId) && (
            <div className="suggest-details-info__info">
              <div className="suggest-details-info__info-title">Meeting id</div>
              <div className="suggest-details-info__info-value">
                {getMetaValue(strings.suggestionOnlineLinks.meetingId)}
              </div>
            </div>
          )}
          {getMetaValue(strings.suggestionOnlineLinks.password) && (
            <div className="suggest-details-info__info">
              <div className="suggest-details-info__info-title">Password</div>
              <div className="suggest-details-info__info-value">
                {getMetaValue(strings.suggestionOnlineLinks.password)}
              </div>
            </div>
          )}
        </div>
      )}
      {suggestedType?.type !== onlineType.zoom && <div>{pasteText}</div>}
      <div
        className="suggest-details-info--clear meeting-info"
        onClick={() => handleDeletePasteLink()}></div>
    </div>
  )
}

export default MeetingLink
