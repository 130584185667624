import { SvgIcon } from "@components/common"

const Loader = () => {
  return (
    <div className="py-4 overflow-x-hidden flex w-full justify-center items-center mt-6 animate-fade translate-x-3">
      <SvgIcon name="loader" className="!w-[30px] !h-[17px] animate-spin " />
    </div>
  )
}

export default Loader
