import styled from "styled-components"

import { NCaption, NFontWeight } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Description = styled(NCaption)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  text-align: center;
`

export const ImagesWrapper = styled.div`
  width: 90%;
  background-color: var(--lightGray4);
  border-radius: 8px;
  padding: 15px;
  margin-top: 30px;
`

export const Image = styled(SvgIcon)`
  max-width: 100%;
  width: auto;
  height: auto;
`
