import React, { useState } from "react"

import ReactDOM from "react-dom"
import { TouchableOpacity } from "react-native"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"

import "./index.scss"
import { bindActionCreators } from "redux"

import { setActiveInviteesTab } from "@actions/commonActions"
import {
  addTeeupInvitees,
  gotTeeupParts,
  fetchTeeupUsersById,
} from "@actions/teeupActions"
import { teeupStatusKeys } from "@config/enums"
import { teeupStatusTypes } from "@config/mappings"
import { selectActiveInviteesTab } from "@selectors/common"
import { selectAnyTeeupById } from "@selectors/teeups"
import { formatInvitees } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"

import { store } from "../../../../index"
import AddInvitees from "../../../AddInvitees"
import CooEVerse from "../../../CooEVerse"
import TabInvitees from "../../../CreateTeeUp/addInviteesTabs"
import ViewByTeeups from "../../../ViewByTeeups"

const InviteError = ({ closeSelect }) => {
  const wrapperStyles = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  }

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "400px",
    height: "180px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    animationName: "bottom-slide",
    animationTimingFunction: "ease-out",
    animationDirection: "alternate",
    animationDuration: "0.3s",
  }

  const textStyles = {
    paddingTop: "15px",
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "17px",
    color: "#222222",
    textAlign: "center",
    width: 270,
    margin: "0 auto",
  }

  const ButtonStyles = {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: "17px",
    color: "#f42862",
    width: "160px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "38px",
    border: "solid 2px #f42862",
    backgroundColor: "#ffffff",
    marginVertical: "0",
    marginHorizontal: "auto",
    marginBottom: "5px",
    marginTop: "15px",
  }

  return (
    <div style={wrapperStyles}>
      <div style={containerStyles}>
        <p style={textStyles}>
          You can't invite some one before joining to the teeUP
        </p>

        <TouchableOpacity style={ButtonStyles} onPress={closeSelect}>
          Ok
        </TouchableOpacity>
      </div>
    </div>
  )
}

const HandleInvite = ({
  setActiveInviteesTab,
  gotTeeupParts,
  setInviteesContent,
  teeup,
}) => {
  const [activeInviteesTab, setInviteesTab] = useState(1)
  const [invitees, setInvitees] = useState([])
  const [modalContent, setModalContent] = useState(null)

  // const { showAddInvitees, inviteesContent, openOption, participants, setModalContent, activeInviteesTab, setActiveInviteesTab } = this.props
  // const { invitees } = this.state;

  // let inviteesCopy = invitees
  // if (inviteesContent == null) inviteesCopy = []

  const handleOnInviteesDone = async () => {
    // const {invitees} = this.state;
    // const { participants, activeTeeupId, closeOption, gotTeeupParts, showAddInvitees } = this.props
    const formattedInvitees = formatInvitees(invitees)

    await addTeeupInvitees(teeup.id, formattedInvitees)
    const teeupUsers = await fetchTeeupUsersById(teeup.id)
    gotTeeupParts([teeupUsers])
    setInviteesContent(null)
    // closeOption();
  }

  return (
    <>
      {modalContent &&
        ReactDOM.createPortal(
          <div
            className="addinvitees__overlay"
            onClick={() => setModalContent(null)}>
            <div
              className="addinvitees__modal"
              onClick={event => event.stopPropagation()}>
              {modalContent}
            </div>
          </div>,
          document.querySelector("#modal-root")
        )}
      <div
        style={{ paddingLeft: 12, paddingRight: 8 }}
        className="create__top next-fullHeight">
        <span className="invitees__title">Invite to TeeUp</span>
        <img
          src={images.cross}
          onClick={() => setInviteesContent(null)}
          style={{ height: 25, width: 25, cursor: "pointer" }}
        />
      </div>
      <div className="tabInvitees_wrapper">
        <TabInvitees
          index={1}
          title={"Contacts"}
          action={setActiveInviteesTab}
          setInviteesTab={setInviteesTab}
        />
        <TabInvitees
          index={2}
          title={"CooWe-verse"}
          action={setActiveInviteesTab}
          setInviteesTab={setInviteesTab}
        />
        <TabInvitees
          index={3}
          title={"Teeups"}
          action={setActiveInviteesTab}
          setInviteesTab={setInviteesTab}
        />
      </div>
      {(activeInviteesTab == undefined || activeInviteesTab == 1) && (
        <AddInvitees
          setInvitees={invitees => setInvitees(invitees)}
          invitees={invitees}
          alreadyInvitedIds={[]}
          isTeeupInvite={true}
          fromTeeupTooltip
          // calc((100vw - 610px)/2 + 620px)
          handleOnInviteesDone={handleOnInviteesDone}
          setModalContent={setModalContent}
          fromCreateTeeup={true}
          // setInvitees={(invitees) => this.setState({invitees})}
          // invitees={inviteesCopy}
          // alreadyInvitedIds={participants}
          // isTeeupInvite={true}
          // handleOnInviteesDone={this.handleOnInviteesDone}
          // setModalContent={setModalContent}
          // fromCreateTeeup={true}
        />
      )}
      {activeInviteesTab === 2 && (
        <CooEVerse
          setInvitees={setInvitees}
          handleOnInviteesDone={handleOnInviteesDone}
          setModalContent={() => {}}
          fromCreateTeeup={true}
          invitees={invitees}
          fromTeeupTooltip
          isTeeupInvite
          // setInvitees={(invitees) => this.setState({invitees})}
          // handleOnInviteesDone={this.handleOnInviteesDone}
          // setModalContent={setModalContent}
          // fromCreateTeeup={true}
          // invitees={inviteesCopy}
        />
      )}
      {activeInviteesTab === 3 && (
        <ViewByTeeups
          setInviteesProp={setInvitees}
          handleOnInviteesDone={handleOnInviteesDone}
          fromTeeup
          fromCreateTeeup={true}
          inviteesProp={invitees}
          noHover
          isTeeupInvite
          // setInviteesProp={(invitees) => this.setState({invitees})}
          // handleOnInviteesDone={this.handleOnInviteesDone}
          // fromTeeup
          // fromCreateTeeup={true}
          // inviteesProp={inviteesCopy}
          // noHover
        />
      )}
    </>
  )
}

const TeeupTooltip = ({
  onArchivePressed,
  mode,
  onMoveToActivePress,
  onClose,
  setInviteesContent,
  setActiveInviteesTab,
  gotTeeupParts,
  teeup,
  isInvited,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [error, setError] = useState(false)

  const hideTooltip = () => {
    setIsOpen(false)
    ReactTooltip.hide()
  }

  return (
    <ReactTooltip
      id="teeup-tooltip"
      class="extraClassTeeup"
      backgroundColor="#ffffff"
      place="top"
      effect="solid"
      // offset={{left: 100}}
      afterHide={() => setIsOpen(true)}
      delayHide={500}
      // delayShow={ 500 }
      getContent={id => {
        if (!id || !isOpen) {
          return null
        }
        // First try to find message by id in teeup's messages
        let teeup = selectAnyTeeupById(Number(id))(store.getState())
        const {
          newStatus,
          isArchived,
          isArchivePromptShow: showArchivePrompt,
          status,
        } = teeup
        // let message = selectMessageById(messageId)(store.getState())
        // If there is no message, find message by id in initial message's gameplans
        // if (!message) {
        // message = selectGameplanById(messageId)(store.getState())
        // }
        // if(!message) {
        // return null;
        // }
        // const { isPinned, text, conversationTypeId, isSuggestion } = message;
        const statusID = teeup?.teeupStatus?.id || null

        let teeupStatus =
          typeof newStatus === "number"
            ? teeupStatusTypes[newStatus]
            : statusID
            ? teeupStatusTypes[statusID]
            : status

        let isArchivePromptShow = false
        if (
          !isArchived &&
          teeupStatus === teeupStatusKeys.ended &&
          showArchivePrompt
          // && getDiffInDays(statusChangedAt) >= 1
        ) {
          isArchivePromptShow = true
        }
        return (
          <>
            <div className="teeup_tooltip_container">
              <>
                {(mode === "ARCHIVED" ||
                  mode === "SKIPPED" ||
                  mode === "TRASH") && (
                  <div className="tooltip_list_item">
                    <img src={images.iconActiveTeeupAction} />
                    <span
                      data-testid="moveToActiveButton"
                      className="tooltip_list_item__title_teeup"
                      onClick={() => {
                        onMoveToActivePress(teeup)
                        hideTooltip()
                      }}>
                      {" "}
                      Move to Active{" "}
                    </span>
                  </div>
                )}
                {/* {isArchivePromptShow && ( */}
                {mode !== "ARCHIVED" && (
                  <div
                    className="tooltip_list_item"
                    onClick={() => {
                      onArchivePressed(teeup)
                      hideTooltip()
                    }}>
                    <img src={images.iconArchive} />
                    <span
                      data-testid="archiveButton"
                      className="tooltip_list_item__title_teeup">
                      Archive
                    </span>
                  </div>
                )}

                {mode !== "ARCHIVED" && mode !== "TRASH" && (
                  <div
                    className="tooltip_list_item"
                    onClick={
                      isInvited
                        ? () => setError(true)
                        : () => {
                            setInviteesContent(
                              <HandleInvite
                                setActiveInviteesTab={setActiveInviteesTab}
                                gotTeeupParts={gotTeeupParts}
                                setInviteesContent={setInviteesContent}
                                teeup={teeup}
                              />
                            )
                            onClose()
                          }
                    }>
                    <img src={images.inviteToTeeupsFromList} />

                    <span
                      data-testid="invitePeopleButton"
                      className="tooltip_list_item__title_teeup">
                      Invite People
                    </span>
                  </div>
                )}
                {error &&
                  ReactDOM.createPortal(
                    <InviteError closeSelect={() => setError(false)} />,
                    document.querySelector("#modal-root")
                  )}
              </>
            </div>
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              onClick={onClose}
            />
          </>
        )
      }}
    />
  )
}

const mapStateToProps = state => ({
  activeInviteesTab: selectActiveInviteesTab(state),
})

const mapDispatchToProps = dispatch => ({
  setActiveInviteesTab: bindActionCreators(setActiveInviteesTab, dispatch),
  gotTeeupParts: bindActionCreators(gotTeeupParts, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeupTooltip)
