import React, { useState } from "react"

import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { setCreateTeeupScreenType } from "@actions/createTeeupActions"
import { MenuTabs, SearchUnsplashPhoto, UploadFromDevice } from "@components"
import { SELECT_IMAGE_MENU_TABS, SELECT_IMAGE_TABS_TEXT } from "@constants/tabs"

import { Wrapper } from "./selectImageStyles"

export const SelectImage = ({ isCreateTeeup, setImage }) => {
  const tabs = Object.keys(SELECT_IMAGE_MENU_TABS)
  const [activeTab, setActiveTab] = useState(tabs[0])
  const dispatch = useDispatch()

  const handleUploadImage = imageBase64 => {
    setImage(imageBase64)
    if (isCreateTeeup) {
      dispatch(setCreateTeeupScreenType(null))
    }
  }

  const renderContent = () => {
    if (activeTab === SELECT_IMAGE_MENU_TABS.STOCK_IMAGE) {
      return (
        <SearchUnsplashPhoto
          isCreateTeeup={isCreateTeeup}
          setImage={setImage}
        />
      )
    }

    return <UploadFromDevice onUpload={handleUploadImage} />
  }

  return (
    <Wrapper $isCreateTeeup={isCreateTeeup}>
      <MenuTabs
        tabs={tabs}
        tabsText={SELECT_IMAGE_TABS_TEXT}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {renderContent()}
    </Wrapper>
  )
}

SelectImage.propTypes = {
  isCreateTeeup: PropTypes.bool,
  setImage: PropTypes.func.isRequired,
}
