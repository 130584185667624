import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Panel = styled.div`
  background-color: var(--white);
  display: flex;
  justify-content: flex-end;
  border-right: 1px solid var(--lightGray5);
  border-left: 1px solid var(--lightGray5);

  padding: 5px 15px;
  position: sticky;
  top: 52px;
  left: 0;
  z-index: 2;
`

export const StyledList = styled.ul`
  height: 100%;
  border-right: 1px solid var(--lightGray5);
  border-left: 1px solid var(--lightGray5);
  padding: 0 15px;
  list-style: none;
`

export const ListItem = styled.li`
  border-bottom: 1px solid var(--gray3);
  padding: 5px 10px;
`

export const GroupTitle = styled(NSubtitleS)`
  color: var(--black2);
  font-weight: ${NFontWeight.semiBold};
  text-transform: uppercase;
`

export const InnerList = styled.ul`
  border-bottom: 1px solid var(--gray3);
  list-style: none;
  padding: 0;
`
