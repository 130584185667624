import React from "react"

import { SvgIcon } from "@components/common"

import { Wrapper, Text } from "./upToDateStyles"

export const UpToDate = () => (
  <Wrapper>
    <SvgIcon name="hooray" />

    <Text>You’re up to date</Text>
  </Wrapper>
)
