import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  sortLine: {
    marginBottom: Units.responsiveValue(12),
  },
  lineButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: Units.responsiveValue(48),
    paddingHorizontal: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.white,
  },
  lineButtonSeparator: {
    marginBottom: Units.responsiveValue(1),
  },
  chevron: {
    tintColor: AppColors.brand.warmGrey,
    marginLeft: Units.responsiveValue(7),
  },
  titleWrapper: {
    marginVertical: Units.responsiveValue(8),
    flexDirection: "row",
  },
  //text
  titleText: {
    marginLeft: Units.responsiveValue(16),
    ...AppFonts.biggerBold,
    color: AppColors.brand.black2,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.34),
  },
  selectedValue: {
    ...AppFonts.mediumDemibold,
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    textAlign: "right",
    color: AppColors.brand.warmGrey2,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
    marginLeft: Units.responsiveValue(7),
  },
  buttonTitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black,
    textAlign: "left",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
})
