import { useSelector } from "react-redux"

import { selectUserId } from "@selectors/activeTeeup"

import Participants from "./Participants"
import Timezone from "./Timezone"
import YourStatus from "./YourStatus"

import "./index.scss"

const Content = ({ people, isMultiTimezone }) => {
  const userId = useSelector(selectUserId)
  return (
    <div className="people-content">
      <YourStatus user={people.find(i => i.id === userId)} />

      <Participants people={people} />

      {isMultiTimezone && <Timezone people={people} />}
    </div>
  )
}

export default Content
