import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

import createRootReducer from "./reducers"

export const history = createBrowserHistory()
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["prompts"],
}
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
)

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk
      )
    )
  )
  let persistor = persistStore(store)

  return { store, persistor }
}
