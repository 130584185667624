import React, { useContext, useEffect } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { setGuestCalendarConflicts } from "@actions/guestActions"
import SuggestionItem from "@components/gamePlansCard/SuggestionPopup/molecules/SuggestionItem"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"
import {
  selectGuestCalendarEvents,
  selectGuestLinkedCalendar,
} from "@selectors/calendar"
import { selectGuestSuggestions } from "@selectors/guest"
import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"

// import {SuggestionItem} from "components"

import { useFullCalendarEvents } from "../hooks"

import { List } from "./suggestionsFullListStyles"
import { useFilteredGuestSuggestions } from "hooks"

const SuggestionsFullList = ({
  calendarEvents,
  linkedGuestCalendar,
  setGuestCalendarConflicts,
  guestSuggestions,
}) => {
  const { stepsManager, fetchGuestCalendarsEvents, previewInfo } =
    useContext(TeeupPreviewContext)

  const filteredSuggestions = useFilteredGuestSuggestions(guestSuggestions)
  useFullCalendarEvents({
    guestSuggestions,
    linkedGuestCalendar,
    fetchGuestCalendarsEvents,
  })

  useEffect(() => {
    setGuestCalendarConflicts({
      calendarEvents,
    })
  }, [calendarEvents])

  const openSuggestionPreviewHandle = id => {
    stepsManager.setSuggestionPreviewId(id)
  }

  return (
    <List>
      {filteredSuggestions.map(suggestion => {
        const meetingDetails = getWhenSuggestionDetails(suggestion)
        const participant = previewInfo.participants.find(
          participant => participant?.id === suggestion?.creator?.id
        )
        if (!participant) return false

        return (
          <SuggestionItem
            preview={{
              isPreview: true,
              participant,
            }}
            suggestion={suggestion}
            onClick={() => openSuggestionPreviewHandle(suggestion?.id)}
            key={suggestion.id}
            title={meetingDetails.title}
            subtitle={meetingDetails.subtitle}
          />
        )
      })}
    </List>
  )
}

const mapStateToProps = state => ({
  linkedGuestCalendar: selectGuestLinkedCalendar(state),
  calendarEvents: selectGuestCalendarEvents(state),
  guestSuggestions: selectGuestSuggestions(state),
})

const mapDispatchToProps = dispatch => ({
  setGuestCalendarConflicts: bindActionCreators(
    setGuestCalendarConflicts,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionsFullList)
