import { useCallback, useMemo } from "react"

import { uniqueId } from "lodash"
import { useDispatch, useSelector } from "react-redux"

import {
  closeSuggestWhenModalAction,
  getTeeupById,
} from "@actions/activeTeeupActions"
import {
  updateGamePlanWhen,
  closeActiveGamePlanTab,
} from "@actions/createTeeupActions"
import { selectIsFromCreateTeeUp } from "@selectors/common"
import { selectGamePlanWhenFromCreate } from "@selectors/createTeeUp"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import {
  createSuggestions,
  promoteToGameplan,
} from "middlewares/backendMiddleware"

import {
  useGeneralTimeDispatch,
  useGeneralTimeState,
} from "./GeneralTimeContext"
import { SET_DAYS_SELECTED, SET_QUICK_SELECT, SET_TIME } from "./actionTypes"

const useGeneralTimeTab = () => {
  const context = useGeneralTimeDispatch()
  const { daysSelected, quickSelect, time } = useGeneralTimeState()

  const teeup = useSelector(state => state.activeTeeup)
  const user = useSelector(selectUserInfo)
  const people = useSelector(selectTeeupPeople)

  const isFromCreateTeeUp = useSelector(selectIsFromCreateTeeUp)
  const gamePlanWhen = useSelector(selectGamePlanWhenFromCreate)

  const dispatch = useDispatch()

  const closeSuggestWhenModal = useCallback(() => {
    dispatch(closeSuggestWhenModalAction())
  }, [dispatch])

  const setQuickSelect = useCallback(
    value => {
      if (daysSelected.length > 0) return

      let checkSelect = value
      if (quickSelect === checkSelect) checkSelect = ""

      context({
        type: SET_QUICK_SELECT,
        payload: checkSelect,
      })
    },
    [context, quickSelect, daysSelected]
  )

  const setDaysSelected = useCallback(
    value => {
      context({
        type: SET_DAYS_SELECTED,
        payload: [...value],
      })
    },
    [context]
  )

  const setTime = useCallback(
    value => {
      context({
        type: SET_TIME,
        payload: value,
      })
    },
    [context]
  )

  const setDayTime = (startDate, endDate) => {
    startDate.setMinutes(0)
    startDate.setHours(time ? time.start : 0)
    startDate.setSeconds(0)
    startDate.setMilliseconds(0)
    endDate.setMinutes(time ? 0 : 59)
    endDate.setHours(time ? time.end : 23)
    endDate.setSeconds(0)
    endDate.setMilliseconds(0)
  }

  const generateSuggestObject = ({
    startDate,
    endDate,
    selected,
    gamePlanOption,
    isTime,
  }) => ({
    type: "when",
    startDate,
    userId: user.id,
    teeupId: teeup.id,
    endDate,
    value: null,
    value2: null,
    isCustomDate: false,
    isCustomTime: false,
    isTimeZoneEnabled: false,
    selected,
    decided: selected,
    optionId: gamePlanOption?.optionId || 0,
    index: 0,
    isTime,
  })

  const onSuggest = useCallback(
    selected => {
      // if !daysSelected && quickSelect has a value use quickSelect, otherwise use daysSelected

      let data =
        daysSelected.length === 0 && quickSelect
          ? [...quickSelect.date]
          : [...daysSelected]

      let suggest
      if (quickSelect) {
        const startDate = new Date(quickSelect.date[0])
        const endDate = new Date(quickSelect.date[1])

        setDayTime(startDate, endDate)
        const gamePlanOption = teeup.gameplans.find(
          option => option.type === "when"
        )
        suggest = [
          generateSuggestObject({
            startDate,
            endDate,
            selected,
            gamePlanOption,
            isTime: !!time,
          }),
        ]
      } else {
        suggest = data.map(day => {
          const startDate = new Date(day)
          const endDate = new Date(day)

          // default full day
          setDayTime(startDate, endDate)

          const gamePlanOption = teeup.gameplans.find(
            option => option.type === "when"
          )

          return generateSuggestObject({
            startDate,
            endDate,
            selected,
            gamePlanOption,
            isTime: !!time,
          })
        })
      }

      if (suggest.length === 0) return

      // let user know if suggestion is already in gameplan
      if (
        String(suggest[0].startDate) ===
        String(gamePlanWhen.suggestions[0]?.startDate)
      ) {
        return alert("Similar suggestion already exists")
      }
      if (!isFromCreateTeeUp) createSuggestions(suggest)

      if (selected && !isFromCreateTeeUp) {
        promoteToGameplan({ suggestionId: uniqueId(), type: "when" })
        // requires this one for proper ui setup
        getTeeupById(teeup.id)
      }

      if (isFromCreateTeeUp) {
        dispatch(updateGamePlanWhen(suggest))
        dispatch(closeActiveGamePlanTab())
      } else {
        getTeeupById(teeup.id)
        closeSuggestWhenModal()
      }
    },
    [
      closeSuggestWhenModal,
      gamePlanWhen,
      daysSelected,
      quickSelect,
      teeup,
      time,
      user,
      dispatch,
      isFromCreateTeeUp,
    ]
  )

  const canSuggest = useMemo(() => {
    return daysSelected.length || quickSelect?.date?.length
  }, [daysSelected, quickSelect])

  return {
    daysSelected,
    quickSelect,
    time,
    setQuickSelect,
    setDaysSelected,
    setTime,
    canSuggest,
    onSuggest,
    teeup,
    user,
    people,
    initialGamePlanWhen: gamePlanWhen.suggestions[0],
  }
}

export default useGeneralTimeTab
