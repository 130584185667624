import _ from "lodash"

import { userContactTypes } from "@utils/contactUtils"
import { currentCountry } from "@utils/location"

import actionTypes from "../actions/actionTypes"

export const initialState = {
  userInfo: {
    userId: null,
  },
  teeupInviteInfo: null,
  inviteSSORegisterToken: null,
  loginErrorMessage: null,
  loginErrorCode: null,
  auth0Type: null,
  auth0Error: null,
  isRegisterSsoNames: false,
  // TODO: rename newRegisteredUser to registeredUser and store actions also
  newRegisteredUser: null,
  newRegisteredUserError: null,
  isCreateUserAnyway: false,
  isEmailLoginLoading: false,
  resetPassErrorMessage: null,
  hasInternetConnection: false,
  avatar: "https://www.w3schools.com/w3images/avatar2.png",
  friendlist: [],

  customDates: [],
  customTimes: [],
  settings: {
    notifications: {},
    calendarSync: {},
    contacts: {},
    notificationLevel: "",
    applicationSoundEnabled: true,
  },
  contactMechanisms: {
    phone: [],
    email: [],
    location: [],
    userTitles: ["Home", "Work", "School", "Hangout"],
    url: [],
  },
  interests: [],
  authDetails: {
    verifyAttempt: 0,
  },
  primaryLocation: {},
  isLoading: false,
  loginMethod: "",
  connections: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH0_TYPE:
      return {
        ...state,
        auth0Type: action.payload,
      }
    case actionTypes.SET_TEEUP_INVITE_INFO:
      return {
        ...state,
        teeupInviteInfo: action.payload,
      }
    case actionTypes.SET_USER_INFO:
      // Need to check if country is present because not all users were registered with country mentioned in user info
      let { country } = action.payload
      return {
        ...state,
        userInfo: { ...action.payload, country: country || currentCountry },
      }
    case actionTypes.UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
        settings: action.payload?.settings || state.settings,
      }
    case actionTypes.SET_INVITE_SSO_REGISTER_TOKEN:
      return {
        ...state,
        inviteSSORegisterToken: action.payload,
      }
    case actionTypes.SET_LOGIN_ERROR:
      return {
        ...state,
        loginErrorMessage: action.payload ? action.payload.message : null,
        loginErrorCode: action.payload ? action.payload.errorCode : null,
      }
    case actionTypes.SET_AUTH0_ERROR:
      return {
        ...state,
        auth0Error: action.payload,
      }
    case actionTypes.SET_REGISTER_SSO_NAMES:
      return {
        ...state,
        isRegisterSsoNames: action.payload,
      }
    case actionTypes.SET_NEW_REGISTERED_USER:
      return {
        ...state,
        newRegisteredUser: action.payload,
      }
    case actionTypes.SET_NEW_REGISTERED_USER_ERROR:
      return {
        ...state,
        newRegisteredUserError: action.payload,
      }
    case actionTypes.CREATE_USER_ANYWAY:
      return {
        ...state,
        isCreateUserAnyway: action.payload,
      }
    case actionTypes.SET_IS_EMAIL_LOGIN_LOADING:
      return {
        ...state,
        isEmailLoginLoading: action.payload,
      }
    case actionTypes.ADD_USER_CONTACT_LOCATION: {
      let location = action.payload
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          location: [...state.contactMechanisms.location, location],
        },
      })
    }
    case actionTypes.REMOVE_USER_CONTACT_LOCATION: {
      let id = action.payload
      let location = state.contactMechanisms.url.filter(
        singleLocation => singleLocation.id !== id
      )
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          location,
        },
      })
    }
    case actionTypes.REMOVE_USER_CONTACT_URL: {
      let id = action.payload
      let url = state.contactMechanisms.url.filter(
        singleUrl => singleUrl.id !== id
      )
      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          url,
        },
      })
    }
    case actionTypes.ADD_NEW_USER_CONTACT: {
      const { type, newContactMechanism } = action.payload

      if (!type) return

      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          [type]: [...state.contactMechanisms.email, newContactMechanism],
        },
      })
    }
    case actionTypes.SET_USER_CONTACT_MECHANISMS: {
      let contactMechanisms = action.payload
      let contactsNew = {
        phone: [],
        email: [],
        location: [],
        url: [],
      }

      contactMechanisms.map(contact => {
        if (contact.value) {
          const newContact = {
            ...contact,
          }

          switch (contact.typeid) {
            case userContactTypes.PHONE:
            case userContactTypes.PRIMARY_PHONE:
            case userContactTypes.RECOVERY_PHONE: {
              contactsNew.phone.push(newContact)

              break
            }
            case userContactTypes.EMAIL:
            case userContactTypes.PRIMARY_EMAIL:
            case userContactTypes.RECOVERY_EMAIL: {
              contactsNew.email.push(newContact)

              break
            }
            case userContactTypes.LOCATION: {
              contactsNew.location.push(newContact)

              break
            }
            case userContactTypes.URL: {
              contactsNew.url.push(newContact)

              break
            }
            // No default
          }
        }
      })

      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          ...contactsNew,
        },
      })
    }
    case actionTypes.ADD_USER_CONTACT_URL: {
      let url = action.payload

      return _.assign({}, state, {
        contactMechanisms: {
          ...state.contactMechanisms,
          url: [...state.contactMechanisms.url, url],
        },
      })
    }
    case actionTypes.CLEAR_USER_CONTACT_LOCATION: {
      let url = action.payload
      return {
        contactMechanisms: {
          ...state.contactMechanisms,
          url: [...state.contactMechanisms.url, url],
        },
      }
    }
    case actionTypes.UPDATE_USER_CONTACT_MECHANISMS: {
      const { contactMechanisms, mechType } = action.payload
      let updatedContactMechanisms = _.assign({}, state.contactMechanisms)
      updatedContactMechanisms[mechType] = contactMechanisms
      return _.assign({}, state, {
        contactMechanisms: updatedContactMechanisms,
      })
    }

    case actionTypes.DELETE_USER_CONTACT_MECHANISM: {
      const keys = Object.keys(state.contactMechanisms)
      const filterFunc = c => c.id !== action.payload
      const updatedContacts = []
      keys.forEach(key => {
        updatedContacts[key] = state.contactMechanisms[key].filter(filterFunc)
      })

      return {
        ...state,
        contactMechanisms: updatedContacts,
      }
    }
    case actionTypes.SET_SETTINGS: {
      let newSettings = { ...state.settings, ...action.payload }

      return _.assign({}, state, {
        settings: newSettings,
      })
    }
    case actionTypes.SET_USER_CONNECTIONS: {
      return {
        ...state,
        connections: action.payload,
      }
    }
    case actionTypes.CHANGE_TIMEZONE: {
      return _.assign({}, state, {
        settings: {
          ...state.settings,
          calendarSync: {
            ...state.settings.calendarSync,
            ...action.payload,
          },
        },
      })
    }
    case actionTypes.SET_RESET_PASS_ERROR: {
      return {
        ...state,
        resetPassErrorMessage: action.payload,
      }
    }
    case actionTypes.SET_USER_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case actionTypes.GET_USER_PRIMARY_LOCATION: {
      return {
        ...state,
        primaryLocation: action.payload,
      }
    }
    case actionTypes.UPDATE_USER_IDENTITIES: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          identities: action.payload,
        },
      }
    }
    default:
      return state
  }
}
