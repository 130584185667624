import CheckboxMui from "@mui/material/Checkbox"

const Checkbox = ({ checked, onClick }) => {
  return (
    <CheckboxMui
      checked={checked}
      onClick={onClick}
      sx={{
        padding: 0,
        color: "#D4D4D4",
        "&.Mui-checked": {
          color: "#F42862",
        },
      }}
    />
  )
}

export default Checkbox
