import styled from "styled-components"

import { NCaption } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Text = styled(NCaption)`
  color: var(--gray4);
  text-align: center;
`

export const Image = styled(SvgIcon)`
  width: auto;
  height: 230px;
  margin: 20px auto;
`
