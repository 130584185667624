import React from "react"

import { Auth0Provider } from "@auth0/auth0-react"
import * as msal from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { ConnectedRouter } from "connected-react-router"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { msalConfig } from "@config/msalConfig"
import { TopLevelProvider, useTopLevelContext } from "contexts/TopLevelContext"
import { SideBarState } from "utils/sideBarContext"

import App from "./App"
import configureStore, { history } from "./configureStore"

export const { store, persistor } = configureStore({})
export const domain = process.env.REACT_APP_AUTH0_DOMAIN
export const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
export const captchaId = process.env.REACT_APP_GOOGLE_API_CAPTCHA_KEY
export const AUTH0SCOPE =
  "read:current_user update:current_user_identities update:current_user_metadata"
export const AUTH0AUDIENCE = `https://${domain}/api/v2/`
export const msalInstance = new msal.PublicClientApplication(msalConfig)

const AppWithProviders = () => {
  const { setIsRegistrationInProgress, setSocialRegistrationType } =
    useTopLevelContext()

  const onRedirectCallback = appState => {
    if (appState?.isRegistrationInProgress) {
      setIsRegistrationInProgress(appState.isRegistrationInProgress)
    }
    if (appState?.socialRegistrationType) {
      setSocialRegistrationType(appState.socialRegistrationType)
    }
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin || "https://web.coowe.com"}
      audience={AUTH0AUDIENCE}
      scope={AUTH0SCOPE}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <SideBarState>
                <App />
              </SideBarState>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </MsalProvider>
    </Auth0Provider>
  )
}

const root = createRoot(document.querySelector("#root"))

root.render(
  <TopLevelProvider>
    <AppWithProviders />
  </TopLevelProvider>
)
