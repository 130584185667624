import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"

import actionTypes from "actions/actionTypes"

import activeTeeup from "./activeTeeupReducer"
import calendarReducer from "./calendarReducer"
import commonReducer from "./commonReducer"
import communityReducer from "./communityReducer"
import contactsReducer from "./contactsReducer"
import groupReducer from "./groupReducer"
import guest from "./guestReducer"
import invitees from "./inviteesReducer"
import localDataReducer from "./localDataReducer"
import notificationReducer from "./notificationReducer"
import promptsReducer from "./promptsReducer"
import requests from "./requestReducer"
import signUpWithInvitation from "./signUpWithInvitation"
import slackIntegration from "./slackIntegrationReducer"
import suggestReducer from "./suggestReducer"
import teeupCardReducer from "./teeupCardReducer"
import createTeeup from "./teeupCreateReducer"
import teeups from "./teeupsReducer"
import teeupsStateReducer from "./teeupsStateReducer"
import user from "./userReducer"

const createRootReducer = history => (state, action) => {
  if (action.type === actionTypes.LOG_OUT) {
    state = undefined
  }

  return appReducer(history)(state, action)
}

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    guest,
    teeups,
    activeTeeup,
    createTeeup,
    invitees,
    calendar: calendarReducer,
    requests,
    teeupCard: teeupCardReducer,
    contacts: contactsReducer,
    community: communityReducer,
    group: groupReducer,
    localData: localDataReducer,
    teeupsState: teeupsStateReducer,
    common: commonReducer,
    notification: notificationReducer,
    signUpWithInvitation: signUpWithInvitation,
    suggest: suggestReducer,
    prompts: promptsReducer,
    slackIntegration,
  })

export default createRootReducer
