import actionTypes from "./actionTypes"

const startDraggingSuggestion = data => ({
  type: actionTypes.START_DRAGGING_SUGGESTION,
  payload: data,
})

const updateDraggingSuggestion = data => ({
  type: actionTypes.UPDATE_DRAGGING_SUGGESTION,
  payload: data,
})

const stopDraggingSuggestion = () => ({
  type: actionTypes.UPDATE_DRAGGING_SUGGESTION,
  payload: null,
})

const setActiveReplyMessage = activeReplyMessage => ({
  type: actionTypes.SET_REPLY_MESSAGE,
  payload: { activeReplyMessage },
})

export const setActiveTextMessage = activeTextMessage => ({
  type: actionTypes.SET_TEXT_MESSAGE,
  payload: activeTextMessage,
})

export const setActiveHighlightedMessage = activeHighlightedMessage => ({
  type: actionTypes.SET_HIGHLIGHTED_MESSAGE,
  payload: { activeHighlightedMessage },
})

const setReplyImageSize = replyImageSize => ({
  type: actionTypes.SET_REPLY_IMAGE_SIZE,
  payload: { replyImageSize },
})

const expandConversationCard = expanded => ({
  type: actionTypes.EXPAND_CONVERSATION_CARD,
  payload: {
    expanded,
  },
})

const setNotificationMentionMessage = message => ({
  type: actionTypes.SET_NOTIFICATION_MENTION_MESSAGE,
  payload: message,
})

const checkIsOnboardingWasShown = () => dispatch => {
  // AsyncStorage.getItem('ONBOARDING_WAS_SHOWN').then((res) => {
  // const isOnboardingWasShown = res ? JSON.parse(res) : false
  const isOnboardingWasShown = false

  dispatch({
    type: actionTypes.SET_ONBOARDING_WAS_SHOWN,
    payload: !!isOnboardingWasShown,
  })
  // })
}

const setIsOnboardingWasShown = isOnboardingWasShown => ({
  type: actionTypes.SET_ONBOARDING_WAS_SHOWN,
  payload: isOnboardingWasShown,
})

export default {
  startDraggingSuggestion,
  updateDraggingSuggestion,
  stopDraggingSuggestion,
  setActiveReplyMessage,
  setActiveTextMessage,
  setActiveHighlightedMessage,
  setReplyImageSize,
  expandConversationCard,
  setNotificationMentionMessage,
  checkIsOnboardingWasShown,
  setIsOnboardingWasShown,
}
