import { create } from "zustand"

import { DEFAULT_INBOX_TAB, DEFAULT_SORT } from "@utils/inbox"
import {
  FilterListItem,
  FilterOption,
  FilterTypes,
  INBOX_TABS,
  InboxNudge,
  Pagination,
  SortOption,
  TeeUpState,
} from "types/inbox"
import { subscribeWithSelector } from "zustand/middleware"
import { Participant } from "types/participant"
import { GameplanItem, TeeupItem } from "types/teeup"

type TeeUpId = number
type UserId = number

interface InboxStore {
  currentActiveTeeUp: TeeupItem | null
  setCurrentActiveTeeUp: (teeup: TeeupItem) => void

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  isFetchError: boolean
  setIsFetchError: (isError: boolean) => void

  teeUps: any[]
  setTeeUps: (teeUps: any[]) => void

  nudges: Record<TeeUpId, InboxNudge>
  setNudges: (nudges: Record<TeeUpId, InboxNudge>) => void
  ignoreNudge: (
    teeUpId: number,
    nudge: any,
    type: "mentions" | "nudges"
  ) => void

  gameplanOptions: Record<TeeUpId, GameplanItem[]>
  setGameplanOptions: (gameplanOptions: Record<TeeUpId, GameplanItem[]>) => void

  teeUpsState: Record<TeeUpId, TeeUpState>
  setTeeUpsState: (teeUps: Record<TeeUpId, TeeUpState>) => void

  teeupParticipants: Record<TeeUpId, Participant[]>
  setTeeupParticipants: (participants: Record<TeeUpId, Participant[]>) => void

  users: Record<UserId, Participant>
  setUsers: (users: Record<UserId, Participant>) => void

  pagination: Pagination
  setPagination: (pagination: Pagination) => void

  searchValue: string
  setSearchValue: (value: string) => void

  selectedFilters: Record<FilterTypes, FilterOption[]>
  setSelectedFilters: (filters: FilterOption[], filterType: FilterTypes) => void
  setDefaultSelectedFilters: () => void

  currentSortBy: SortOption
  setCurrentSortBy: (option: SortOption) => void

  currentOpenFilter: string
  setCurrentOpenFilter: (filter: string) => void

  currentOpenTab: FilterListItem
  setCurrentOpenTab: (tab: FilterListItem) => void

  isArchiveModalOpen: boolean
  setIsArchiveModalOpen: (isOpen: boolean) => void

  triggerTeeupReload: number
  updateTriggerTeeupReload: () => void

  isArchiveShackBarOpen: boolean
  setIsArchiveSnackBarOpen: (isOpen: boolean) => void

  isUnderlineModalOpen: boolean
  setIsUnderlineModalOpen: (isOpen: boolean) => void

  isOrganizerArchiveModalOpen: boolean
  setIsOrganizerArchiveModalOpen: (isOpen: boolean) => void

  tempGameplanOptions: Record<TeeUpId, GameplanItem[]>
  setTempGameplanOptions: (
    gameplanOptions: Record<TeeUpId, GameplanItem[]>
  ) => void
}

export const useInboxStore = create<InboxStore>()(
  subscribeWithSelector((set, get) => ({
    currentActiveTeeUp: null,
    setCurrentActiveTeeUp: teeup => set({ currentActiveTeeUp: teeup }),

    isLoading: true,
    setIsLoading: isLoading => set({ isLoading }),

    isFetchError: false,
    setIsFetchError: isError => set({ isFetchError: isError }),

    teeUps: [],
    setTeeUps: teeUps => set({ teeUps }),

    isArchiveShackBarOpen: false,
    setIsArchiveSnackBarOpen: isOpen => set({ isArchiveShackBarOpen: isOpen }),

    nudges: {},
    setNudges: nudges => set({ nudges }),
    ignoreNudge: (teeUpId, nudge, type) =>
      set({
        nudges: {
          ...get().nudges,
          [teeUpId]: {
            ...get().nudges[teeUpId],
            [type]: [...get().nudges[teeUpId][type]].filter(
              item => item.id !== nudge.id
            ),
          },
        },
      }),
    gameplanOptions: [],
    setGameplanOptions: gameplanOptions => set({ gameplanOptions }),

    teeUpsState: [],
    setTeeUpsState: teeUpsState => set({ teeUpsState }),

    teeupParticipants: [],
    setTeeupParticipants: participants =>
      set({ teeupParticipants: participants }),

    users: {},
    setUsers: users => set({ users }),

    pagination: {
      total: 0,
      page: 0,
      nextPage: 0,
      previousPage: 0,
    },
    setPagination: pagination => set({ pagination }),

    searchValue: "",
    setSearchValue: value => set({ searchValue: value }),

    selectedFilters: {
      [FilterTypes.stage]: [],
      [FilterTypes.userStatus]: [],
    },
    setSelectedFilters: (filters, filterType) =>
      set({
        selectedFilters: {
          ...get().selectedFilters,
          [filterType]: filters,
        },
      }),
    setDefaultSelectedFilters: () =>
      set({
        selectedFilters: {
          [FilterTypes.stage]: [],
          [FilterTypes.userStatus]: [],
        },
      }),

    currentSortBy: DEFAULT_SORT,
    setCurrentSortBy: option => set({ currentSortBy: option }),

    currentOpenFilter: "",
    setCurrentOpenFilter: filter =>
      set({
        currentOpenFilter: get().currentOpenFilter === filter ? "" : filter,
      }),

    currentOpenTab: DEFAULT_INBOX_TAB,
    setCurrentOpenTab: tab =>
      set({
        currentOpenTab: tab,
      }),

    isArchiveModalOpen: false,
    setIsArchiveModalOpen: isOpen => set({ isArchiveModalOpen: isOpen }),

    // To trigger teeups refetch from outside
    triggerTeeupReload: 0,
    updateTriggerTeeupReload: () =>
      set({ triggerTeeupReload: get().triggerTeeupReload + 1 }),

    isUnderlineModalOpen: false,
    setIsUnderlineModalOpen: isOpen => set({ isUnderlineModalOpen: isOpen }),

    isOrganizerArchiveModalOpen: false,
    setIsOrganizerArchiveModalOpen: isOpen =>
      set({ isOrganizerArchiveModalOpen: isOpen }),

    tempGameplanOptions: {},
    setTempGameplanOptions: gameplanOptions =>
      set({ tempGameplanOptions: gameplanOptions }),
  }))
)
