import { useCallback } from "react"

import { EVENT_TYPES } from "@utils/calendarUtils"

import { getEventsColors } from "../helpers"

export const useEventPropGetter = () => {
  const getClasses = ({ isConflict, eventType, key, isCreated }) => {
    let classes = "rbc-ev-clear"

    if (isConflict && (!key || isCreated)) {
      classes = "rbc-ev-conflict"
      if (eventType === EVENT_TYPES.teeup || isCreated) {
        classes += " rbc-ev-left-pos"
      } else if (eventType === EVENT_TYPES.calendar) {
        classes += " rbc-ev-right-pos"
      }
    }

    if (!key || (key && isCreated)) {
      classes += " rbc-ev-no-anchor"
    }

    return classes
  }

  return useCallback(
    event => ({
      style: {
        borderRadius: 0,
        padding: 0,
        ...getEventsColors(event),
      },
      className: getClasses(event),
    }),
    []
  )
}
