import _ from "lodash"

import actionTypes from "@actions/actionTypes"

export const initialState = {
  teeups: {}, // teeup states
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      return initialState
    }

    case actionTypes.GOT_TEEUPS_STATE: {
      const newTeeups = {}
      const teeupStates = action.payload || []
      teeupStates.forEach(s => {
        newTeeups[s.teeupId] = s
      })
      return _.assign({}, state, {
        teeups: { ...state.teeups, ...newTeeups },
      })
    }

    case actionTypes.READ_TEEUP_STATE: {
      const { teeupId } = action.payload
      const teeupState = state.teeups[teeupId]
      if (!teeupState) {
        return state
      }

      const newTeeupState = { ...teeupState, seen: true }
      return _.assign({}, state, {
        teeups: { ...state.teeups, [teeupId]: newTeeupState },
      })
    }

    case actionTypes.READ_ALL_TEEUPS: {
      const teeups = _.cloneDeep(state.teeups)
      const teeupsArr = Object.values(teeups).map(teeup => ({
        ...teeup,
        seen: true,
      }))
      let newTeeupsState = {}
      teeupsArr.forEach(teeup => {
        newTeeupsState[teeup.teeupId] = teeup
      })

      return _.assign({}, state, {
        teeups: newTeeupsState,
      })
    }

    default:
      return state
  }
}
