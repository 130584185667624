import React from "react"

import Logo from "@assets/images/newDesign/ndLoginSiginLogo.png"

import { Wrapper, LogoImg, Content, Title, Icon } from "./authLayoutStyles"

export const AuthLayout = ({
  children,
  title,
  center,
  statusIcon,
  isEmail = false,
}) => (
  <Wrapper>
    <a href="/">
      <LogoImg className="auth-layout__logo" src={Logo} alt="logo" />
    </a>

    <Content isEmail={isEmail}>
      {statusIcon && <Icon center={center} src={statusIcon} alt="status" />}
      <Title statusIcon={statusIcon} isEmail={isEmail}>
        {title}
      </Title>

      {children}
    </Content>
  </Wrapper>
)
