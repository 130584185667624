import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

export const StyledLink = styled(Link)`
  ${({ $isWrapped, $disabled }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 16px 16px 16px 12px;
    position: relative;

    ${$isWrapped &&
    css`
      justify-content: center;
    `};

    ${$disabled &&
    css`
      pointer-events: none;
    `};
  `};
`

export const StyledDirectLink = styled(Link)`
  ${({ $disabled }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 16px 16px 16px 12px;
    position: relative;

    ${$disabled &&
    css`
      pointer-events: none;
    `};
  `};
`
