import styled, { css } from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

export const Wrapper = styled.div`
  ${({ $isWide, $isOpen }) => css`
    background-color: var(--white);
    border: 1px solid var(--lightGray5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 16px;

    @media (max-width: ${MEDIA_SIZES.MOBILE}) {
      border-radius: 0;
      cursor: pointer;

      ${$isOpen &&
      css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `}
    }

    ${$isWide &&
    css`
      flex: 1;

      @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
        min-height: 400px;
      }
    `}
  `}
`

export const Header = styled.div`
  ${({ $isClick }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      transform: rotate(-90deg);
    }

    ${$isClick &&
    css`
      cursor: pointer;
    `};
  `}
`

export const Title = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
  display: flex;
  gap: 8px;

  & > div {
    transform: rotate(-270deg);
  }
`

export const GoIcon = styled(SvgIcon)`
  transform: rotate(-90deg);
`
