import _ from "lodash"

import actionTypes from "@actions/actionTypes"

export const initialState = {
  requests: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONNECTION_REQUESTS: {
      const data = action.payload
      let requests = {}
      data.forEach(singleRequest => {
        requests[singleRequest.id] = singleRequest
      })
      return _.assign({}, state, {
        requests,
      })
    }

    case actionTypes.RECEIVED_REQUEST_MESSAGE: {
      const message = action.payload

      let messages = [message, ...state.requests[message.targetId].messages]

      return _.assign({}, state, {
        requests: {
          ...state.requests,
          [message.targetId]: {
            ...state.requests[message.targetId],
            messages,
          },
        },
      })
    }

    default:
      return state
  }
}
