import React, { useState, useEffect } from "react"

import { floor } from "lodash"

import DurationContainer from "./DurationContainer"
import StartTimeContainer from "./StartTimeContainer"
import TimePickerFooter from "./TimePickerFooter"

const TimePickerContainer = ({
  setIsTimePickerOpened,
  handleTimePick,
  finalTime,
  setFinalTime,
  duration,
}) => {
  const [startTimeHourNumbers, setStartTimeHourNumbers] = useState([])
  const [startTimeMinuteNumbers, setStartTimeMinuteNumbers] = useState([])
  const [chosenStartHour, setChosenStartHour] = useState("")
  const [chosenStartDayPart, setChosenStartDayPart] = useState("")
  const [chosenStartMinute, setChosenStartMinute] = useState("")

  const [durationHourNumbers, setDurationHourNumbers] = useState([])
  const [durationMinuteNumbers, setDurationMinuteNumbers] = useState([])
  const [chosenDurationHour, setChosenDurationHour] = useState(1)
  const [chosenDurationMinute, setChosenDurationMinute] = useState(0)

  //For Start Time
  const defaultValues = finalTime.startTimeFormatted.split(" ")

  const startTimeHours = 24
  let startTimeHoursArray = []

  const startTimeMinutes = 12
  let startTimeMinutesArray = []

  useEffect(() => {
    for (let i = 1; i <= startTimeHours; i++) {
      if (i <= 12)
        startTimeHoursArray.push({
          value: `${i} AM`,
          selected:
            defaultValues[1] === "AM" && +defaultValues[0].split(":")[0] === i
              ? true
              : false,
        })
      else
        startTimeHoursArray.push({
          value: `${i - 12} PM`,
          selected:
            defaultValues[1] === "PM" &&
            +defaultValues[0].split(":")[0] === i - 12
              ? true
              : false,
        })
    }
    setStartTimeHourNumbers(startTimeHoursArray)

    for (let i = 0; i < startTimeMinutes; i++) {
      startTimeMinutesArray.push({
        value: i * 5,
        selected: +defaultValues[0].split(":")[1] === i * 5 ? true : false,
      })
    }
    setStartTimeMinuteNumbers(startTimeMinutesArray)
  }, [])

  useEffect(() => {
    let currentStartHour = startTimeHourNumbers
      .find(item => item.selected)
      ?.value.split(" ")[0]
    let currentStartDayPart = startTimeHourNumbers
      .find(item => item.selected)
      ?.value.split(" ")[1]
    let currentStartMinute =
      startTimeMinuteNumbers.find(item => item.selected)?.value < 10
        ? "0" + startTimeMinuteNumbers.find(item => item.selected)?.value
        : startTimeMinuteNumbers.find(item => item.selected)?.value

    // let currentDurationHour = Math.trunc(duration/60)
    // let currentDurationMinute = duration%60 ? duration%60 : 0
    let currentDurationHour = durationHourNumbers.find(
      item => item.selected
    )?.value
    let currentDurationMinute = durationMinuteNumbers.find(
      item => item.selected
    )?.value

    if (currentStartHour) setChosenStartHour(currentStartHour)
    else setChosenStartHour("")

    if (currentStartDayPart) setChosenStartDayPart(currentStartDayPart)
    else setChosenStartDayPart("")

    if (currentStartMinute) setChosenStartMinute(currentStartMinute)
    else setChosenStartMinute("00")

    let endHour =
      +currentStartHour +
      currentDurationHour +
      floor(
        ((+currentStartMinute ? +currentStartMinute : 0) +
          currentDurationMinute) /
          60
      )
    let endMinute =
      ((+currentStartMinute ? +currentStartMinute : 0) +
        currentDurationMinute) %
      60

    finalTime.startTimeFormatted = `${currentStartHour}:${
      currentStartMinute ? currentStartMinute : "00"
    } ${currentStartDayPart}`
    finalTime.endTimeFormatted = `${endHour <= 12 ? endHour : endHour - 12}:${
      endMinute < 10 ? "0" + endMinute : endMinute
    } ${endHour > 12 ? "AM" : "PM"}`

    setFinalTime(finalTime)
  }, [startTimeHourNumbers, startTimeMinuteNumbers])

  //For Duration Time
  const durationHours = 6
  let durationHoursArray = []

  const durationMinutes = 12
  let durationMinutesArray = []

  useEffect(() => {
    let currentDurationHour = durationHourNumbers.find(
      item => item.selected
    )?.value
    let currentDurationMinute = durationMinuteNumbers.find(
      item => item.selected
    )?.value

    let currentStartHour = startTimeHourNumbers
      .find(item => item.selected)
      ?.value.split(" ")[0]
    let currentStartMinute =
      startTimeMinuteNumbers.find(item => item.selected)?.value < 10
        ? "0" + startTimeMinuteNumbers.find(item => item.selected)?.value
        : startTimeMinuteNumbers.find(item => item.selected)?.value

    if (currentDurationHour) setChosenDurationHour(currentDurationHour)
    else setChosenDurationHour("")

    if (currentDurationMinute) setChosenDurationMinute(currentDurationMinute)
    else setChosenDurationMinute("")

    let endHour =
      +currentStartHour +
      currentDurationHour +
      floor(
        ((+currentStartMinute ? +currentStartMinute : 0) +
          currentDurationMinute) /
          60
      )
    let endMinute =
      ((+currentStartMinute ? +currentStartMinute : 0) +
        currentDurationMinute) %
      60

    finalTime.endTimeFormatted = `${endHour <= 12 ? endHour : endHour - 12}:${
      endMinute < 10 ? "0" + endMinute : endMinute
    } ${endHour > 12 ? "AM" : "PM"}`

    setFinalTime(finalTime)
  }, [durationHourNumbers, durationMinuteNumbers])

  useEffect(() => {
    for (let i = 0; i <= durationHours; i++) {
      durationHoursArray.push({
        value: i,
        selected: i === Math.trunc(duration / 60) ? true : false,
        // selected: i === 1 ? true : false,
      })
    }
    setDurationHourNumbers(durationHoursArray)

    for (let i = 0; i < durationMinutes; i++) {
      durationMinutesArray.push({
        value: i * 5,
        selected: i * 5 === duration % 60 ? true : false,
        // selected: !i ? true : false,
      })
    }
    setDurationMinuteNumbers(durationMinutesArray)
  }, [])

  return (
    <div className="time-picker__wrapper">
      <div className="time-picker__container">
        <StartTimeContainer
          startTimeHourNumbers={startTimeHourNumbers}
          setStartTimeHourNumbers={setStartTimeHourNumbers}
          startTimeMinuteNumbers={startTimeMinuteNumbers}
          setStartTimeMinuteNumbers={setStartTimeMinuteNumbers}
          chosenStartHour={chosenStartHour}
          chosenStartDayPart={chosenStartDayPart}
          chosenStartMinute={chosenStartMinute}
        />
        <DurationContainer
          durationHourNumbers={durationHourNumbers}
          setDurationHourNumbers={setDurationHourNumbers}
          durationMinuteNumbers={durationMinuteNumbers}
          setDurationMinuteNumbers={setDurationMinuteNumbers}
          chosenDurationHour={chosenDurationHour}
          setChosenDurationHour={setChosenDurationHour}
          chosenDurationMinute={chosenDurationMinute}
          setChosenDurationMinute={setChosenDurationMinute}
        />
        <TimePickerFooter
          setIsTimePickerOpened={setIsTimePickerOpened}
          handleTimePick={handleTimePick}
          chosenStartHour={chosenStartHour}
          chosenStartDayPart={chosenStartDayPart}
          chosenStartMinute={chosenStartMinute}
          chosenDurationHour={chosenDurationHour}
          chosenDurationMinute={chosenDurationMinute}
        />
      </div>
    </div>
  )
}

export default TimePickerContainer
