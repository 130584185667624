import { useCallback, useEffect, useMemo, useState } from "react"

import cn from "classnames"
import GoogleMapReact, { ClickEventValue } from "google-map-react"

import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import useCopyToClipboard from "@hooks/useCopyToClipboard"
import { getCoords } from "@utils/suggestionUtils"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import Search from "../Search"

import "./index.scss"

interface Props {
  externalCoordinates?: {
    lat: number
    lng: number
    address: string
  }
}

const googleMapOptions = {
  styles: [
    {
      featureType: "poi.business",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
  ],
  zoomControl: false,
  fullscreenControl: false,
}

// const Marker = ({ lat, lng, ...rest }: { lat: number; lng: number }) => {
//   console.log("rest", rest)

//   return (
//     <img
//       src="https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png"
//       style={{ width: "20px", height: "20px" }}
//     />
//   )
// }

const Map = ({ externalCoordinates }: Props) => {
  const { copyToClipboard } = useCopyToClipboard()
  const isMapExpanded = useSuggestionInPersonStore(state => state.isMapExpanded)
  const setIsMapExpanded = useSuggestionInPersonStore(
    state => state.setIsMapExpanded
  )
  const [marker, setMarker] = useState<any | null>(null)

  const coordinates = useSuggestionInPersonStore(state => state.mapCoordinates)
  const selectedName = useSuggestionInPersonStore(state => state.selectedName)
  const selectedAddress = useSuggestionInPersonStore(
    state => state.selectedAddress
  )
  const setMapCoordinates = useSuggestionInPersonStore(
    state => state.setMapCoordinates
  )
  const setSelectedAddress = useSuggestionInPersonStore(
    state => state.setSelectedAddress
  )
  const currentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.currentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionInPersonStore(
    state => state.updateSuggestion
  )
  const geocoder = useMemo(() => new window.google.maps.Geocoder(), [])

  useEffect(() => {
    if (!externalCoordinates) return

    setIsMapExpanded(false)
  }, [externalCoordinates])

  const renderMarkers = useCallback(
    (map, maps) => {
      const marker = new maps.Marker({
        position: {
          lat: externalCoordinates?.lat || coordinates.lat,
          lng: externalCoordinates?.lng || coordinates.lng,
        },
        icon: {
          url: "https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png",
          scaledSize: new maps.Size(25, 25),
        },
        map,
      })
      setMarker(marker)
      return marker
    },
    [externalCoordinates, coordinates]
  )

  const isMapShown =
    (coordinates?.lat && coordinates?.lng) ||
    selectedName ||
    externalCoordinates

  const handleConfirmAddress = () => {
    setIsMapExpanded(false)
  }

  const handleMapExpand = () => {
    if (externalCoordinates) return

    setIsMapExpanded(true)
  }

  const handleMapClick = async ({
    lat: latArg,
    lng: lngArg,
  }: ClickEventValue) => {
    const response = await getCoords(geocoder, null, latArg, lngArg)
    if (!response) return

    const { address, lat, lng, placeId } = response
    setMapCoordinates({ lat, lng, placeId: placeId || "" })

    if (address) {
      const newAddress = {
        label: address,
        place_id: placeId || "",
        isCurrentLocation: true,
        iconName: "",
      }
      setSelectedAddress(newAddress)
      if (currentActiveSuggestionIndex !== -1) {
        updateSuggestion(currentActiveSuggestionIndex, { address: newAddress })
      }
    }

    if (marker?.setPosition) {
      marker?.setPosition({ lat, lng })
    }
  }

  if (!isMapShown) return false

  return (
    <div
      className={cn("suggest-where-modal-block", {
        "suggestion-where-map-container-collapsed": isMapExpanded,
      })}>
      {!isMapExpanded && !externalCoordinates && <h4>Address</h4>}

      <div className="suggestion-where-map-container" onClick={handleMapExpand}>
        <div>
          {externalCoordinates?.address ? (
            <div className="external-address">
              <p>{externalCoordinates?.address}</p>
              <SvgIcon
                name="newDesign/SuggestOnline/ndSuggestPasteLink"
                onClick={() =>
                  copyToClipboard(externalCoordinates?.address || "")
                }
              />
            </div>
          ) : (
            <Search isFromMap outsideValue={selectedAddress} />
          )}
        </div>

        <div
          className={cn("suggestion-where-map", {
            "is-external": externalCoordinates,
          })}>
          <GoogleMapReact
            center={externalCoordinates || coordinates}
            zoom={17}
            onClick={handleMapClick}
            options={googleMapOptions}
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            yesIWantToUseGoogleMapApiInternals>
            {/* {(coordinates.lat || externalCoordinates?.lat) && (
              <Marker
                lat={externalCoordinates?.lat || coordinates.lat}
                lng={externalCoordinates?.lng || coordinates.lng}
              />
            )} */}
          </GoogleMapReact>
        </div>
      </div>

      {selectedAddress && isMapExpanded && (
        <div className="suggestion-where-confirm-address">
          <div>
            <h3>{selectedAddress?.label}</h3>
            <p>{selectedAddress?.description}</p>
          </div>

          <Button variant="contained" onClick={handleConfirmAddress}>
            Confirm Address
          </Button>
        </div>
      )}
    </div>
  )
}

export default Map
