import React, { useContext, useEffect, useMemo, useState } from "react"

import moment from "moment-timezone"
import Calendar from "react-calendar"

import "./datePicker.scss"
import {
  DATE_PICKER_NAV,
  TimeWheelContext,
} from "../../../contexts/TimeWheelContext"
import {
  createNewDate,
  createNewMomentDate,
  getTileClassName,
  getTileDisabled,
} from "../../../contexts/TimeWheelContext/helpers"
import { useThisWeekDisabledDays } from "../../../contexts/TimeWheelContext/hooks"

import { DatePickerNav } from "./DatePickerNav"

const defaultPickerConfig = {
  locale: "en-US",
  prevLabel: "",
  prev2Label: "",
  nextLabel: "",
  next2Label: "",
  onDrillDown: () => {},
}

export const DatePicker = () => {
  const [isOnlyWeek, setIsOnlyWeek] = useState(true)
  const { startDate, endDate, movingType, onDatePickerChange } =
    useContext(TimeWheelContext)
  const [startOfWeek, setStartOfWeek] = useState()
  const [endOfWeek, setEndOfWeek] = useState()
  const selectedDate = useMemo(() => {
    return moment(startDate)
  }, [startDate])
  const [activeStartDate, setActiveStartDate] = useState(
    createNewDate(moment(), true)
  )

  const initWeekFrames = () => {
    setStartOfWeek(moment(selectedDate).startOf("week"))
    setEndOfWeek(moment(selectedDate).endOf("week"))
  }

  const toggleShowCurrentWeekOnly = () => {
    if (!isOnlyWeek) {
      initWeekFrames()
      const newDate = createNewDate(selectedDate)
      setActiveStartDate(newDate)
    }

    setIsOnlyWeek(prevState => !prevState)
  }

  useEffect(() => {
    initWeekFrames()
  }, [selectedDate])

  const thisWeekDisabledDays = useThisWeekDisabledDays(startOfWeek)

  const onChangeCalendarPeriod = type => {
    const amountStep = type === DATE_PICKER_NAV.next ? 1 : -1
    const unitStep = isOnlyWeek ? "week" : "month"

    const newStartOfWeek = moment(startOfWeek).add(amountStep, unitStep)
    const newEndOfWeek = moment(endOfWeek).add(amountStep, unitStep)
    setStartOfWeek(newStartOfWeek)
    setEndOfWeek(newEndOfWeek)

    const isPrevMonth =
      newStartOfWeek.diff(startOfWeek.endOf("month"), "month") < 0
    const isNextMonth =
      newStartOfWeek.diff(startOfWeek.startOf("month"), "month") > 0
    if (isPrevMonth || isNextMonth) {
      const newDate = createNewDate(newStartOfWeek)
      setActiveStartDate(newDate)
    }
  }

  useEffect(() => {
    // this useEffect updates activeStartDate value on TimeWheel scroll from current month to prev/next month
    if (
      startOfWeek &&
      startOfWeek?.format() !== "Invalid date" &&
      !startOfWeek?.isSame(moment(activeStartDate), "month")
    ) {
      const newDate = createNewDate(startOfWeek)
      setActiveStartDate(newDate)
    }
  }, [startDate, startOfWeek, movingType])

  const onChangeHandle = newVal => {
    const generatedDate = createNewMomentDate(newVal, true)

    onDatePickerChange(generatedDate)
  }

  return (
    <div className="date-picker">
      <DatePickerNav
        startDate={startDate}
        isOnlyWeek={isOnlyWeek}
        startOfWeek={startOfWeek}
        onChangeCalendarPeriod={type => onChangeCalendarPeriod(type)}
        toggleShowCurrentWeekOnly={toggleShowCurrentWeekOnly}
      />

      {selectedDate ? (
        <Calendar
          {...defaultPickerConfig}
          onChange={newVal => onChangeHandle(newVal)}
          value={createNewDate(selectedDate, true)}
          tileDisabled={e => getTileDisabled(e, thisWeekDisabledDays)}
          activeStartDate={activeStartDate}
          tileClassName={params =>
            getTileClassName(params, {
              startDate,
              endDate,
              endOfWeek,
              startOfWeek,
              isOnlyWeek,
              activeStartDate,
            })
          }
        />
      ) : null}
    </div>
  )
}
