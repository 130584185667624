import React from "react"

import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { reloadPage } from "@utils/reloadPage"

import { Wrapper, LeftSide, Text, Icon } from "./oopsErrorStyles"

export const OopsError = ({ text, isSideMargins }) => (
  <Wrapper $isSideMargins={isSideMargins}>
    <LeftSide>
      <Icon name="cancel-cross" color="var(--red2)" />
      <Text>{text}</Text>
    </LeftSide>

    <ButtonEl
      autoWidth
      variant={BUTTON_VARIANTS.UNDERLINE}
      text="Try again"
      textColor="var(--red2)"
      onClick={reloadPage}
    />
  </Wrapper>
)

OopsError.propTypes = {
  text: PropTypes.string,
  isSideMargins: PropTypes.bool,
}
