import React from "react"

import cn from "classnames"
import PropTypes from "prop-types"

import { useScreenWidth } from "@hooks"

export const ErrorMessage = ({ errorMessage }) => {
  const { isMobile } = useScreenWidth()
  return (
    <div
      data-testid="error"
      className={cn("input-el__error-message", {
        "input-el__error-message-mobile": isMobile,
      })}
      dangerouslySetInnerHTML={{ __html: errorMessage }}
    />
  )
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}
