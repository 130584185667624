import React, { useState, useMemo, useEffect, useRef } from "react"

import _ from "lodash"
import { MentionsInput, Mention } from "react-mentions"
import { connect } from "react-redux"
import { ReactSVG } from "react-svg"
import ReactTooltip from "react-tooltip"
import { bindActionCreators } from "redux"

import "./index.scss"

import { uploadFile } from "@actions/activeTeeupActions"
import { setReplyMessage } from "@actions/commonActions"
import { updateUserStatus } from "@actions/requestActions"
import { gotTeeupParts, updateGameplan } from "@actions/teeupActions"
import { setActiveTextMessage } from "@actions/teeupCardActions"
import { teeupUserStatusPriorities } from "@config/enums"
import { messageTypeIds } from "@config/mappings"
import { selectActiveTeeupId, selectActiveTeeup } from "@selectors/activeTeeup"
import { selectActiveMessage } from "@selectors/teeupCard"
import { selectTeeupPeople } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import Avatar from "@ui/avatar"
import { isImageFile } from "@utils/fileUtils"
import { images } from "@utils/imageUtils"
import { EVENTS, logEvent } from "analytics-tracking"
import { sendMessage } from "middlewares/backendMiddleware"
import { useConversationStore } from "stores/conversationStore"

import everyoneAvatar from "../../../assets/images/everyoneAvatar.png"

import FileCardCarousel from "./FileCardCarousel"
import FilePreview from "./FilePreview"

const ChatInput = ({
  createMessage,
  users,
  currentUserId,
  activeTeeupId,
  setReplyMessage,
  activeMessage,
  updateUserStatus,
  gotTeeupParts,
  activeTeeup,
  inputRef,
  setActiveTextMessage,
  newDesign,
  fromCommentsInput,
  inputPlaceholder = "Say something...",
}) => {
  const [text, setText] = useState("")
  const [files, setFiles] = useState([])
  const [isMounted, setIsMounted] = useState(false)
  const [isShift, setIsShift] = useState(false)
  const inputEl = useRef(null)
  const infoRef = useRef()
  const fileRef = useRef()
  const chatMessage = useConversationStore(state => state.chatMessage)
  const setChatMessage = useConversationStore(state => state.setChatMessage)
  const textRef = useRef(text)

  // For unknown reason, the text state is not working as expected
  useEffect(() => {
    textRef.current = text
  }, [text])

  useEffect(() => {
    if (!isMounted || !chatMessage) return

    setText(chatMessage)
    setChatMessage(null)
    inputRef.current.focus()
  }, [chatMessage, isMounted])

  useEffect(() => {
    setIsMounted(true)

    return () => {
      if (textRef.current) {
        setChatMessage(textRef.current)
      }
    }
  }, [])

  useEffect(() => {
    setReplyMessage(null)
    setText("")
  }, [activeTeeupId])

  useEffect(() => {
    if (activeMessage) setText(text + " " + activeMessage)
  }, [activeMessage])

  const resetState = () => {
    setText("")
    setFiles([])
    setActiveTextMessage(null)
  }

  const handleSubmit = e => {
    const statusId = teeupUserStatusPriorities.joined

    e.preventDefault()
    createMessage(text)

    // if (activeTeeup.userStatus !== teeupUserStatusKeys.joined) {
    //   updateUserStatus(activeTeeupId, statusId).then(async (error) => {
    //     const teeupUsers = await fetchTeeupUsersById(activeTeeupId)
    //     gotTeeupParts([teeupUsers])
    //     updateGameplan(activeTeeupId, await fetchGameplanOptions(activeTeeupId))
    //   })
    // }

    files.forEach(file => {
      if (isImageFile(file.type)) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener("load", function () {
          const url = reader.result
          sendMessage(url, messageTypeIds.image)
        })
      } else {
        uploadFile(activeTeeupId, file)
      }
    })
    logEvent(EVENTS.SEND_MESSAGE, {
      teeupId: activeTeeupId,
      messageType: messageTypeIds.message,
    })

    resetState()
  }

  const onFileChoose = event => {
    if (files.length >= 10) {
      alert("Maximum number of files for upload is 10.")
    } else {
      const newFiles = [...event.target.files]
      setFiles([...files, ...newFiles])

      event.target.value = ""
    }
  }

  const usersDisplay = useMemo(() => {
    const usersForMentions = Object.values(users)
      .filter(({ id, status }) => id !== currentUserId && status !== "invited")
      .map(({ id, username, name, avatar }) => ({
        id,
        display: username || name,
        avatar,
      }))

    return [
      {
        id: -1,
        display: "Everybody",
        avatar: everyoneAvatar,
      },
      ...usersForMentions,
    ]
  }, [users])

  const showSendButton = () => !_.isEmpty(text) || !_.isEmpty(files)

  const removeFile = name => {
    const updatedFiles = files.filter(file => file.name !== name)
    setFiles(updatedFiles)
  }

  const handleEnterPress = e => {
    if (!isShift && e.keyCode === 13) handleSubmit(e)
    if (e.keyCode === 17) setIsShift(true)
    if (isShift && e.keyCode === 13) {
      setText(text + "\r\n")
      setIsShift(false)
    } else return
  }

  // const renderInput = () => (

  // )

  const triggerMentions = () => {
    setText(prevText => prevText + " @")
    inputRef.current.focus()
  }

  return (
    <div
      className={`chat-input ${
        fromCommentsInput ? "inside-comments-tab" : ""
      }`}>
      <div className="chat-input__files" ref={fileRef}>
        {files.length > 0 && (
          <FileCardCarousel
            parentWidth={fileRef.current.clientWidth}
            files={files}
            removeFile={removeFile}
          />
        )}
      </div>
      <div className="chat-input__text-input">
        <form
          onKeyDown={e => handleEnterPress(e)}
          onKeyUp={() => setIsShift(false)}>
          <MentionsInput
            // inputRef={inputEl}
            inputRef={inputRef}
            className="chatinput"
            markup="@{{__type__||__id__||__display__}}"
            value={text}
            onChange={(e, newValue, newPlainTextValue, mentions) => {
              setText(newPlainTextValue)
            }}
            placeholder={inputPlaceholder}
            allowSuggestionsAboveCursor>
            <Mention
              trigger="@"
              data={usersDisplay}
              appendSpaceOnAdd
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={suggestion => {
                const { avatar, display } = suggestion
                return (
                  <>
                    <Avatar
                      size={40}
                      borderWidth={0}
                      imageUrl={avatar}
                      hiddenCount={false}
                      fromMentions
                    />
                    <span className="mention-name">{display}</span>
                  </>
                )
              }}
            />
          </MentionsInput>
        </form>
      </div>
      <div className="chat-input__actions">
        <label
          className="chat-input__action-button"
          data-tip={"Attach Files"}
          data-for="information-tooltip"
          htmlFor="media-input">
          <ReactSVG src={images.ndPaperClipIcon} />
        </label>
        <input id="media-input" type="file" onChange={onFileChoose} />
        <div
          className="chat-input__action-button"
          data-tip={"Mention someone"}
          data-for="information-tooltip"
          onClick={triggerMentions}
          ref={infoRef}>
          <ReactSVG src={images.ndMentionIcon} />
        </div>
        <div
          data-testid="sendButton"
          className="chat-input__action-button"
          data-tip={
            '<div>Send Now <br><div style="color: #a9b0b9; display: flex;">Press<div style="color: #ffffff; background-color: #000000; opacity: 1; border-radius: 4px; width: fit-content; padding: 0 2px; margin-left: 2px;">Enter</div></div></div>'
          }
          data-for="information-tooltip"
          onClick={handleSubmit}>
          <ReactSVG src={images.ndPaperPlaneIcon} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    users: selectTeeupPeople(state),
    currentUserId: selectUserId(state),
    activeTeeupId: selectActiveTeeupId(state),
    activeMessage: selectActiveMessage(state),
    activeTeeup: selectActiveTeeup(state),
  }
}

const mapDispatchToProps = dispatch => ({
  setReplyMessage: bindActionCreators(setReplyMessage, dispatch),
  updateUserStatus: bindActionCreators(updateUserStatus, dispatch),
  gotTeeupParts: bindActionCreators(gotTeeupParts, dispatch),
  updateGameplan: bindActionCreators(updateGameplan, dispatch),
  setActiveTextMessage: bindActionCreators(setActiveTextMessage, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput)
