import React, { useState } from "react"

import cn from "classnames"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators } from "redux"

import { getContacts } from "@actions/createTeeupActions"
import { fetchRequests } from "@actions/requestActions"
import TeeUps from "@components/TeeUps"
import { selectIsNudgePeopleOpen, selectPeopleSideBar } from "@selectors/common"
import {
  selectIsTeeupListVisible,
  selectActiveTeeups,
  selectTeeupsPaginationParams,
} from "@selectors/teeups"
import { selectUserId, selectToken } from "@selectors/user"
import { fetchTeeups } from "actions/teeupActions"
import TeeUpNd from "components/TeeUpsNd"

import { useFetchData } from "./hooks"

const TeeUpsPage = props => {
  const {
    fetchTeeups,
    userId,
    token,
    teeups,
    peopleSideBar,
    teeupsPaginationParams,
    getContacts,
    isComingUp,
  } = props
  const [setIsCalendarSettingsOpen] = useState(false)

  if (isComingUp) {
    useFetchData({
      isInitialCall: true,
      teeups,
      userId,
      teeupsPaginationParams,
      token,
      fetchTeeups,
      getContacts,
    })
  }

  return (
    <div>
      {!isComingUp ? (
        <TeeUpNd />
      ) : (
        <TeeUps
          openCalendarSettings={async () => {
            setIsCalendarSettingsOpen(true)
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  userId: selectUserId(state),
  token: selectToken(state),
  isTeeupListVisible: selectIsTeeupListVisible(state),
  isNudgePeopleOpen: selectIsNudgePeopleOpen(state),
  teeups: selectActiveTeeups(state),
  peopleSideBar: selectPeopleSideBar(state),
  teeupsPaginationParams: selectTeeupsPaginationParams(state),
})

const mapDispatchToProps = dispatch => ({
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
  fetchRequests: bindActionCreators(fetchRequests, dispatch),
  getContacts: bindActionCreators(getContacts, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeUpsPage)
