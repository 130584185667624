import styled from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  column-gap: 5px;
`

export const BackIcon = styled(SvgIcon)`
  transform: rotate(90deg);
`

export const Text = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
`
