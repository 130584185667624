import styled, { css } from "styled-components"

import { SvgIcon } from "@components/common"
import { BUTTON_SIZES } from "@constants/button"

export const Button = styled.button`
  width: 32px;
  height: 32px;
  background-color: var(--lightGray2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $size }) =>
    $size === BUTTON_SIZES.SMALL &&
    css`
      width: 17px;
      height: 17px;
    `};
`

export const CloseIcon = styled(SvgIcon)`
  ${({ $size }) =>
    $size === BUTTON_SIZES.SMALL &&
    css`
      width: 8px;
      height: 8px;
    `};
`
