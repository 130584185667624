import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../theme/"

const CalendarStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.white,
    borderTopRightRadius: Units.responsiveValue(10),
    borderTopLeftRadius: Units.responsiveValue(10),
    position: "relative",
    // bottom: 0,
    // left: 0,
    // right: 0,
    height: Units.responsiveValue(97),
    // shadowColor: AppColors.brand.black,
    // shadowOffset: {
    //     width: 0,
    //     height: 3,
    // },
    // shadowOpacity: 0.4,
    // shadowRadius: 6,
    elevation: 12,
    width: 100,
  },
  grabberContainer: {
    height: Units.responsiveValue(44),
    paddingTop: Units.responsiveValue(8),
    paddingBottom: Units.responsiveValue(4),
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    borderTopRightRadius: Units.responsiveValue(10),
    borderTopLeftRadius: Units.responsiveValue(10),
  },
  grabberIndicator: {
    width: Units.responsiveValue(32),
    height: Units.responsiveValue(4),
    borderRadius: Units.responsiveValue(2),
    backgroundColor: AppColors.brand.grey193,
  },
  linearGradient: {
    position: "absolute",
    top: Units.responsiveValue(-6),
    left: 0,
    right: 0,
    width: "100%",
    height: Units.responsiveValue(16),
    paddingLeft: 12,
    paddingRight: 12,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  shadow: {
    position: "absolute",
    left: 0,
    right: 0,
    top: -Units.responsiveValue(8),
    opacity: 0.08,
    transform: [{ scaleY: -1 }],
    height: Units.responsiveValue(8),
  },
  calendarHeaderWrapper: {
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: Units.responsiveValue(2),
    marginTop: -Units.responsiveValue(3),
  },
  calendarViewPickerContainer: {
    marginTop: Units.responsiveValue(0),
  },
  goTodayBtnContainer: {
    marginTop: Units.responsiveValue(0),
  },
  settingsBtnContainer: {
    marginTop: Units.responsiveValue(0),
  },
  currentMonthContainer: {
    position: "relative",
    top: Units.responsiveValue(12),
    paddingBottom: Units.responsiveValue(14),
  },
  headerLeftContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  viewPickerText: {
    ...AppFonts.mediumDemibold,
    letterSpacing: -Units.responsiveValue(0.2),
    color: AppColors.brand.black2,
  },
  headerSettingsIcon: {
    width: Units.responsiveValue(15),
    height: Units.responsiveValue(16),
    tintColor: AppColors.brand.darkGrey2,
  },
  daysContainer: {
    flex: 1,
    justifyContent: "space-evenly",
    flexDirection: "row",
  },
  daysRow: {
    alignItems: "center",
    paddingHorizontal: 15,
  },
  dayText: {
    ...AppFonts.smallestBold,
    color: AppColors.brand.black2,
    letterSpacing: -Units.responsiveValue(0.1),
  },
  monthContainer: {
    marginTop: Units.responsiveValue(4),
    borderRadius: Units.responsiveValue(6),
    height: Units.responsiveValue(18),
    width: Units.responsiveValue(18),
    marginBottom: Units.responsiveValue(14),
    justifyContent: "center",
    alignItems: "center",
  },
  activeMonthContainer: {
    backgroundColor: AppColors.brand.pink,
  },
  todayMonthContainer: {
    backgroundColor: AppColors.brand.grey2,
  },
  activeMonth: {
    color: AppColors.brand.white,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: AppColors.brand.black40,
    width: 100,
  },
  comingUpList: {
    // paddingBottom: Units.responsiveValue(6),
    paddingLeft: Units.responsiveValue(16),
    // paddingRight: '5px'
  },
  comingUpHeader: {
    height: Units.responsiveValue(51),
    backgroundColor: AppColors.brand.white,
  },
  comingUpListHeaderText: {
    textTransform: "uppercase",
    fontFamily: "greycliff-cf-bold",
    letterSpacing: "normal",
    fontSize: "15px",
    lineHeight: "1.4",
    color: "#494949",
    // ...AppFonts.biggerDemibold,
    // letterSpacing: -Units.responsiveValue(0.23),
    // color: AppColors.brand.black2,
    paddingRight: Units.responsiveValue(8),
  },
  comingUpListEmptyContainer: {
    paddingVertical: 60,
    justifyContent: "flex-start",
  },
  comingUpListEmptyHeaderText: {
    color: AppColors.brand.warmGrey2,
  },
  comingUpListHeaderSeparator: {
    flex: 1,
    height: 1,
    backgroundColor: AppColors.brand.grey2,
  },
  teeupContainer: {
    flex: 1,
    width: "100%",
    minHeight: "44px",
    // boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    // marginTop: 10,
  },
  indicatorContainer: {
    position: "absolute",
    bottom: Units.responsiveValue(8),
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
  },
  indicator: {
    height: Units.responsiveValue(4),
    width: Units.responsiveValue(4),
    borderRadius: Units.responsiveValue(4),
    marginHorizontal: Units.responsiveValue(2),
  },
  planningIndicator: {
    backgroundColor: AppColors.brand.orange,
  },
  allsetIndicator: {
    backgroundColor: AppColors.brand.green,
  },
  happeningIndicator: {
    backgroundColor: AppColors.brand.blue,
  },
  flex: {
    flex: 1,
  },
})

export { CalendarStyles }
