import React, { useEffect, useState } from "react"

import { formatNumber } from "libphonenumber-js"
import PropTypes from "prop-types"
import { Clipboard } from "react-native"
import PhoneInput from "react-phone-input-2"
import { ReactSVG } from "react-svg"

import { escapeRegExp } from "@utils/dataUtils"
import { images } from "@utils/imageUtils"
import "react-phone-input-2/lib/style.css"
import "./phoneInputNd.scss"

const PhoneInputFieldNd = ({
  value,
  inputVal,
  setInputVal,
  index,
  handleTextChange,
  isLastRow,
  defaultCountry,
  getInitPhoneNumber,
  countryCode,
  phoneRef,
  disabled,
  inputStyle,
  customInputStyle,
  fromInvitation,
  onChangeInputCountry,
  label,
  onlyNumber,
  countryCodeEl,
  setError,
  error,
  defCountry,
  setDefCountry,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(value)
  const [showCountryCodeModal, setShowCountryCodeModal] = useState(false)

  useEffect(() => {
    if (phoneRef && isLastRow) {
      getInitPhoneNumber(index)(phoneRef.getValue())
      phoneRef.focus()
    }
  }, [])

  const handleChangePhoneNumber = async (number, cca2) => {
    const clipboardContent = await Clipboard.getString()

    const isTextFromClipboardOnLastPos =
      clipboardContent &&
      number.slice(-clipboardContent.length) === clipboardContent

    if (
      isTextFromClipboardOnLastPos &&
      new RegExp(escapeRegExp(clipboardContent), "gi").test(number)
    ) {
      number = Number.parseInt(clipboardContent)
        ? clipboardContent
        : number.replace(clipboardContent, "")
    }
    handleTextChange(index)(formatNumber(number, "International"), cca2)
    setPhoneNumber(number)
  }

  const getShowCountryCodeModal = value => {
    setShowCountryCodeModal(value)
  }

  // defaull country is passed in upper case from RNLocalize
  const country = defaultCountry ? defaultCountry.toLowerCase() : "us"
  let containerStyles = showCountryCodeModal
    ? { marginLeft: "170px" }
    : { marginLeft: 0 }
  let inputClassStyles = ""
  if (onChangeInputCountry) {
    containerStyles = {
      ...containerStyles,
      height: 30,
      width: "100%",
      display: "inline-block",
    }
    inputClassStyles = "settings_countryCode__codeInput"
  } else if (!onChangeInputCountry && onlyNumber) {
    inputClassStyles = "settings_countryCode__codeInput_onlyNumber"
  }

  countryCodeEl &&
    countryCodeEl.addEventListener("click", () => {
      showCountryCodeModal(true)
    })

  return (
    <>
      <div className="">
        <PhoneInput
          data-testid="phoneInput"
          country={defCountry.countryCode}
          value={inputVal}
          onChange={(phone, country) => {
            setInputVal(phone)
            setError(false)
            onChangeInputCountry && onChangeInputCountry(country)
            getShowCountryCodeModal(false)
            !fromInvitation &&
              handleChangePhoneNumber("+" + phone, country.countryCode)
            fromInvitation &&
              handleChangePhoneNumber("+" + phone, countryCode.country)
            setDefCountry(country)
          }}
          disabled={disabled}
          placeholder="  "
          containerClass={
            error ? "phoneInput__container--error" : "phoneInput__container"
          }
          dropdownStyle={{
            width: "237px",
            height: "500px",
            padding: "4px",
            boxSizing: "border-box",
            borderRadius: "12px",
            backgroundColor: "#fff",
            marginLeft: "-13px",
            marginTop: "2px",
          }}
          searchClass={{
            backgroundColor: "#fff",
          }}
          buttonStyle={{
            backgroundColor: "#fff",
          }}
          inputStyle={{
            width: "200px",
            height: "31px",
            fontFamily: "greycliffcf-demibold-salt",
            color: "#a9b0b9",
            fontSize: "13px",
          }}
          buttonClass={`${onlyNumber && "settings_countryCode__codeInput"}`}
        />
        {onChangeInputCountry && (
          <span className="settings_countryCode_phone_wrapper__valueState">
            +{countryCode.dialCode} {countryCode.name}
          </span>
        )}
        {onChangeInputCountry && (
          <ReactSVG
            src={images.arrowsOptions}
            className="settings_countryCode__codeInput__arrow"
          />
        )}
      </div>
    </>
  )
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  // handleTextChange: PropTypes.func.isRequired,
  isLastRow: PropTypes.bool,
  defaultCountry: PropTypes.string,
  getInitPhoneNumber: PropTypes.func,
  countryCode: PropTypes.string,
}

export default PhoneInputFieldNd
