import moment from "moment-timezone"

export const getEndDate = ({
  parsedCurrentPeriod,
  currentDateIndex,
  timeDuration,
}) => {
  return moment(parsedCurrentPeriod[currentDateIndex])
    ?.add(timeDuration, "minutes")
    .format()
}
