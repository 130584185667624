import React, { useRef } from "react"

import propTypes from "prop-types"
import { View, Image, Text, TouchableOpacity } from "react-native"

import { getFileSizeFromBytes } from "@utils/fileUtils"
import { getFileIcon } from "@utils/imageUtils"

import {
  styles,
  filename,
  filesize,
  fileIconWrapper,
  fileContainerMain,
} from "./FileMessageStyles"

const FileMessageComponent = ({
  isMyMessage,
  isPinnedScreen,
  url,
  name,
  size,
  type,
}) => {
  const { size: fileSize, sizeType } = getFileSizeFromBytes(size)
  const downloadLinkRef = useRef(null)

  const downloadFile = () => {
    downloadLinkRef.current.click()
  }

  return (
    <TouchableOpacity
      onPress={downloadFile}
      style={fileContainerMain(isMyMessage)}>
      <View style={fileIconWrapper(isMyMessage)}>
        <Image
          resizeMode="contain"
          style={styles.docImage}
          source={getFileIcon(type)}
        />
      </View>
      <View style={styles.fileDescription}>
        <Text style={filename(isMyMessage)}>{name + type}</Text>
        <Text style={filesize(isMyMessage)}>
          {fileSize} {sizeType}
        </Text>
        <a ref={downloadLinkRef} className="download-link" href={url} download>
          Download
        </a>
      </View>
    </TouchableOpacity>
  )
}

FileMessageComponent.propTypes = {
  url: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  size: propTypes.number.isRequired,
  type: propTypes.string.isRequired,
  isMyMessage: propTypes.bool.isRequired,
  isPinnedScreen: propTypes.bool.isRequired,
}

export default FileMessageComponent
