import React, { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  closeCreateTeeup,
  createTeeup,
  setCreateTeeupIsInviteesError,
} from "@actions/createTeeupActions"
import { resetSelectedContacts } from "@actions/inviteesActions"
import { searchUnsplashPhoto } from "@config/externalApi"
import {
  selectCreateTeeupIsInviteesError,
  selectCreateTeeupSettings,
  selectGamePlanWhenFromCreate,
  selectGamePlanWhereFromCreate,
} from "@selectors/createTeeUp"
import { TEEUP_TITLE_LENGTH } from "@utils/contacts"
import { getRandomBase64Image } from "@utils/imageUtils"

import Buttons from "../../pages/CreateTeeUpNd/Buttons"
import { useCreateTeeUp } from "../../pages/CreateTeeUpNd/CreateTeeUpContext/useCreateTeeUp"
import Message from "../../pages/CreateTeeUpNd/Message"
import People from "../../pages/CreateTeeUpNd/People"
import TopBar from "../../pages/CreateTeeUpNd/TopBar"
import { CreateTeeupGameplan } from "../CreateTeeupGameplan"
import InvitePeopleNd from "../InvitePeopleNd"

import { useDelay } from "@hooks"

export const CreateTeeupMainScreen = () => {
  const dispatch = useDispatch()
  const { image, name, invitees, userId, message, setImage, setInvitees } =
    useCreateTeeUp()
  const gamePlanWhen = useSelector(selectGamePlanWhenFromCreate)
  const gamePlanWhere = useSelector(selectGamePlanWhereFromCreate)
  const isInviteesError = useSelector(selectCreateTeeupIsInviteesError)
  const settings = useSelector(selectCreateTeeupSettings)
  const [setTitleDelay, clearTitleDelay] = useDelay()

  const gameplanData = [gamePlanWhen, gamePlanWhere]
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isInputToLongError, setIsInputToLongError] = useState(false)
  const [isInputError, setIsInputError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const inviteesAreaRef = useRef(null)

  useEffect(() => {
    isInputError && name.length > 0 && setIsInputError(false)
    name.length >= TEEUP_TITLE_LENGTH
      ? setIsInputToLongError(true)
      : setIsInputToLongError(false)
  }, [name]) //set email char limit

  const scrollToInviteesError = () => {
    inviteesAreaRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  const handleNameChange = newNameVal => {
    clearTitleDelay()

    if (!newNameVal) return

    setTitleDelay(async () => {
      const imageUrl = await searchUnsplashPhoto(newNameVal)
      // const newImage = await getRandomBase64Image(null, null, true)
      if (!imageUrl) return

      setImage(imageUrl)
    }, 2000)
  }

  const create = async () => {
    if (isLoading || isInputToLongError) return
    if (name.length === 0) {
      return setIsInputError(true)
    }
    if (invitees.length === 0 && !settings.allowInviteLink) {
      scrollToInviteesError()
      return dispatch(setCreateTeeupIsInviteesError(true))
    }

    setIsLoading(true)

    const otherData = {}
    const emails = []
    const phones = []
    const manualInvitees = invitees.filter(
      invitee => invitee.userType === "manual"
    )
    manualInvitees.forEach(element => {
      if (element.contactType === "phone") phones.push(element.value)
      if (element.contactType === "email") emails.push(element.value)
    })
    const dataForManualInvitees = { phones, emails }
    otherData.photo = image || (await getRandomBase64Image("randomSmaller"))
    otherData.enableSharedLink = settings.allowInviteLink
    dispatch(
      createTeeup(
        userId,
        name.trim(),
        message.trim() || " ",
        invitees,
        gameplanData,
        dataForManualInvitees,
        otherData,
        settings
      )
    )
    dispatch(closeCreateTeeup())
    dispatch(resetSelectedContacts())
  }

  return (
    <div data-testid="createTeupForm" className="create-teeup">
      {isInviteModalOpen && (
        <InvitePeopleNd
          setInvitees={setInvitees}
          invitees={invitees}
          onClose={setIsInviteModalOpen}
        />
      )}
      {/* Invitees error modal like on mobile, not in this scope so commented
      <InviteesErrorModal
        create={create}
        open={isInviteesError}
        setOpen={setIsInviteesError}
      /> */}

      <div className="create-teeup__wrapper">
        <div className="create-teeup__flex-wrapper">
          <TopBar
            isInputToLongError={isInputToLongError}
            setIsPermissionsOpen={() => {}}
            isInputError={isInputError}
            handleNameChange={handleNameChange}
          />

          <section className="create-teeup__content--section">
            <div className="create-teeup__content create-teeup__footer">
              <CreateTeeupGameplan gameplanData={gameplanData} />

              <People
                settings={settings}
                isHover={isHover}
                setIsHover={setIsHover}
                openInviteesModal={() => setIsInviteModalOpen(true)}
                isInviteesError={isInviteesError}
                inviteesAreaRef={inviteesAreaRef}
              />

              <Message />

              <Buttons create={create} isLoading={isLoading} />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
