import React from "react"

import { useCreateTeeUp } from "../CreateTeeUpContext/useCreateTeeUp"

import {
  Wrapper,
  Title,
  Counter,
  TextareaWrapper,
  TextareaEl,
} from "./messageStyles"

const maxLength = 240

const CreateTeeUpMessage = () => {
  const { message, setMessage } = useCreateTeeUp()
  const counterText = `${message.length}/${maxLength}`

  return (
    <Wrapper className="create-teeup__message">
      <Title>Conversation</Title>

      <TextareaWrapper>
        <Counter>{counterText}</Counter>

        <TextareaEl
          className="create-teeup__text"
          data-testid="conversetionField"
          placeholder="Start a conversation with a message"
          value={message}
          maxLength={maxLength}
          onChange={e => {
            setMessage(e.target.value)
          }}
        />
      </TextareaWrapper>
    </Wrapper>
  )
}

export default CreateTeeUpMessage
