import { StyleSheet } from "react-native"

import { AppColors } from "@theme"

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: AppColors.brand.darkGrey2_12,
    backgroundColor: AppColors.brand.darkGrey2_12,
    marginBottom: 24,
  },
  button: {
    height: 28,
    width: 0,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  label: {
    fontFamily: "greycliffcf-medium-salt",
  },
  buttonActive: {
    backgroundColor: AppColors.brand.lightWhite,
    // boxShadow: '1px 1px 0.5px 1px grey',
    boxShadow: " 0 3px 8px 0 rgba(0, 0, 0, 0.12)",
    border: "solid 0.5px rgba(0, 0, 0, 0.04)",
  },
  labelActive: {
    fontFamily: "greycliffcf-demibold-salt",
  },
})
