import styled from "styled-components"

import { NSubtitleS, NFontWeight } from "@assets/styles/newTypographyStyles"

export const Text = styled(NSubtitleS)`
  color: var(--gray5);
  font-weight: ${NFontWeight.medium};
  margin: 15px 0;
  z-index: 2;

  ${({ $isOnBottom }) =>
    $isOnBottom &&
    `
    display: block;
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%;
    text-align: center;
    `}
`
