import React from "react"

import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { closeSuggestWhenModalAction } from "@actions/activeTeeupActions"
import GameplanContainer from "@components/CreateTeeUp/gameplanContainer"
import { selectTeeupGameplans, selectPermissions } from "@selectors/teeups"
import { GAMEPLAN_OPTIONS } from "@utils/gamePlanUtils"

import { SuggestModalContent } from "../../components"

const TimeWheelTab = ({
  onSave,
  handleClose,
  gameplanFromCreate,
  CreateTeeupInvitees,
}) => {
  // const { closeSuggestWhenModal } = useTeeUpPage()
  const gameplans = useSelector(selectTeeupGameplans)
  const permissions = useSelector(selectPermissions)
  const dispatch = useDispatch()

  const closeSuggestWhenModal = () => {
    dispatch(closeSuggestWhenModalAction())
  }

  const renderBody = () => {
    const { allowSelectGameplan } = permissions
    const foundWhenGameplan = gameplans.find(
      gameplan => gameplan.type === GAMEPLAN_OPTIONS.when
    )

    return (
      <GameplanContainer
        {...(foundWhenGameplan || gameplanFromCreate)}
        suggestions={[]}
        closeCreate={() => closeSuggestWhenModal()}
        isTemporalCustom={true}
        allowPutOnGameplan={
          allowSelectGameplan && !foundWhenGameplan?.suggestions?.length
        }
        onSave={onSave}
        handleClose={handleClose}
        CreateTeeupInvitees={CreateTeeupInvitees}
      />
    )
  }
  return (
    <>
      <SuggestModalContent type="when" className="-timeWheel">
        {renderBody()}
      </SuggestModalContent>
    </>
  )
}

TimeWheelTab.propTypes = {
  onSave: PropTypes.func,
}
export default TimeWheelTab
