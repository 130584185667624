import { useMemo } from "react"

import { checkIfEventsConflict, EVENT_TYPES } from "@utils/calendarUtils"
import dayjs from "utils/dayjs"

export const useCertainConflictedEvent = ({
  calendarEvents,
  currentSuggestion,
}) => {
  return useMemo(() => {
    const conflictedEvent = calendarEvents.find(event =>
      checkIfEventsConflict(currentSuggestion, event)
    )

    if (conflictedEvent) {
      const { start, end } = conflictedEvent

      return {
        ...conflictedEvent,
        eventType: EVENT_TYPES.calendar,
        start: dayjs(start.dateTime),
        end: dayjs(end.dateTime),
      }
    }
  }, [currentSuggestion])
}
