import { updateTeeupArchiveStatus } from "@actions/teeupActions"
import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import DefaultModal from "@components/ui/DefaultModal"
import Header from "@components/ui/DefaultModal/Header"
import { useState } from "react"
import { useInboxStore } from "stores/inboxStore"

const ArchiveModal = () => {
  const [isLoading, setIsLoading] = useState(false)
  const isOpen = useInboxStore(state => state.isArchiveModalOpen)
  const setIsOpen = useInboxStore(state => state.setIsArchiveModalOpen)
  const currentActiveTeeUp = useInboxStore(state => state.currentActiveTeeUp)
  const teeUps = useInboxStore(state => state.teeUps)
  const setTeeUps = useInboxStore(state => state.setTeeUps)
  const setIsArchiveSnackBarOpen = useInboxStore(
    state => state.setIsArchiveSnackBarOpen
  )

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleArchiveAndLeave = async () => {
    if (!currentActiveTeeUp) return
    try {
      setIsLoading(true)

      await updateTeeupArchiveStatus(currentActiveTeeUp?.id)
      const filteredTeeUps = teeUps.filter(
        teeup => teeup.id !== currentActiveTeeUp.id
      )
      setTeeUps(filteredTeeUps)
      setIsArchiveSnackBarOpen(true)
      handleClose()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DefaultModal open={isOpen} onClose={handleClose}>
      <Header
        title=""
        onClose={handleClose}
        hideBorder
        className="!px-0 w-full my-0 mx-auto"
      />
      <div className="max-w-[520px]">
        <div className="flex items-center justify-center">
          <SvgIcon name="archive-img" className="!w-[95px] !h-[97px]" />
        </div>

        <div>
          <h3 className="text-center text-zinc-800 text-2xl font-semibold leading-loose mt-4 mb-6">
            Archiving this TeeUp will confuse others!
          </h3>

          <p className="text-center text-neutral-500 text-base font-medium leading-normal">
            You won’t reveive notifications about changes and the other people
            will still see you in the TeeUp
          </p>
        </div>

        <div className="flex flex-col gap-y-2 mt-10">
          <Button
            variant="contained"
            className="!h-14"
            onClick={handleArchiveAndLeave}>
            Archive & leave the TeeUp
          </Button>
          <Button
            variant="text"
            className="!text-neutral-500"
            onClick={handleArchiveAndLeave}>
            Archive & stay
          </Button>
          <Button
            variant="text"
            className="!text-neutral-500"
            onClick={handleClose}>
            Do not Archive
          </Button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default ArchiveModal
