import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes } from "@theme/"

const HEADER_HEIGHT = Units.responsiveValue(42)
const AVATAR_SIZE = Units.responsiveValue(80)
const AVATAR_PADDING_TOP = Units.responsiveValue(17)
const STATUS_BAR_HEIGHT = AppSizes.statusBarHeight

const ViewProps = {
  navButtonColor: AppColors.brand.pink,
  avatarSize: AVATAR_SIZE,
}

const ViewStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerImage: {
    width: "100%",
    height: Units.responsiveValue(100),
    position: "absolute",
    top: 0,
  },
  tabbar: {
    backgroundColor: AppColors.brand.white,
    height: Units.responsiveValue(40),
  },
  tabbarLabel: {
    ...AppFonts.smallExtraBold,
    color: AppColors.brand.grey157,
  },
  contentContainer: {
    paddingTop:
      HEADER_HEIGHT +
      AVATAR_PADDING_TOP +
      STATUS_BAR_HEIGHT +
      AVATAR_SIZE / 2 -
      Units.responsiveValue(8),
    flex: 1,
  },
  contentWrapper: {
    //backgroundColor: AppColors.brand.lightGrey,
    flex: 1,
  },
  header: {
    top: AppSizes.statusBarHeight,
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 300,
  },
  headerText: {
    ...AppFonts.headerSmallDemibold,
    color: AppColors.brand.white,
    letterSpacing: -Units.responsiveValue(0.76),
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(4),
    paddingRight: 15,
  },
  headerButton: {
    width: Units.responsiveValue(42),
    height: Units.responsiveValue(42),
    zIndex: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  headerButtonIcon: {
    color: AppColors.brand.white,
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
  },
  avatarStatusWrapper: {
    position: "absolute",
    flexDirection: "row",
    top: HEADER_HEIGHT + STATUS_BAR_HEIGHT + AVATAR_PADDING_TOP,
    width: "100%",
    paddingHorizontal: Units.responsiveValue(16),
    zIndex: 2,
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: Units.responsiveValue(80),
  },
  contactIcon: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 3,
  },
  userContainer: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(1),
    paddingTop: Units.rem(3.5),
    paddingBottom: Units.rem(0.5),
    backgroundColor: AppColors.brand.white,
    zIndex: 1,
  },
  userContainerInformation: {
    flexDirection: "column",
    flex: 1,
  },
  nameText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.blackTwo,
    letterSpacing: -Units.responsiveValue(0.5),
    paddingBottom: Units.responsiveValue(4),
  },
  additionalText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(18),
  },
  shortBioText: {
    paddingTop: Units.responsiveValue(8),
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(18),
  },
  avatarAccesory: {
    bottom: 0,
    right: Units.responsiveValue(8),
  },
  contactInfoWrapper: {
    paddingTop: Units.responsiveValue(16),
  },
  publicInfoWrapper: {
    padding: Units.responsiveValue(12),
  },
  publicInfoText: {
    ...AppFonts.mediumMedium,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
    color: AppColors.brand.darkGrey2,
    paddingLeft: Units.responsiveValue(4),
  },
  publicInfoRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  publicInfoIcon: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(18),
    alignItems: "flex-end",
  },
  addContactButton: {
    width: "auto",
    paddingRight: Units.responsiveValue(10),
    marginHorizontal: 0,
  },
})

export { ViewStyles, ViewProps }
