import React, { useEffect, useState } from "react"

import ModalInfo from "@components/ui/modal"

import "./index.scss"
import { selectModalInfo } from "@selectors/common"

import ProgressBar from "../components/ProgressBar"

import { useSelector } from "react-redux"

const LoadingPage = () => {
  const [completed, setCompleted] = useState(10)
  const [seconds, setSeconds] = useState(0)
  const modalInfoData = useSelector(selectModalInfo)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCompleted(prevCompleted => Math.min(prevCompleted + 10, 100))
    }, 1000)

    const timer = setInterval(() => {
      setSeconds(prev => prev + 1)
    }, 1000)
    if (seconds === 10) {
      clearInterval(timer)
    }

    return function cleanup() {
      clearInterval(intervalId)
      clearInterval(timer)
    }
  }, [seconds])

  return (
    <div className="loading_page">
      <img src={require("@assets/images/logoWhiteND.svg")} alt="Coowe" />
      <ProgressBar completed={completed} />
      <ModalInfo
        show={modalInfoData.show}
        title={modalInfoData.title}
        handler={modalInfoData.handler}
        message={modalInfoData.message}
        canCloseModal={modalInfoData.canCloseModal}
      />
    </div>
  )
}

export default LoadingPage
