import React, { useCallback, useState, useEffect, useMemo } from "react"

import cn from "classnames"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import "./index.scss"
import { bindActionCreators } from "redux"

import {
  verifyBetaCode,
  sendBetaCode,
  changeEmailVerificationVerify,
  getTokenOnVerifiedInvitation,
} from "@actions/loginActions"
import { images } from "@utils/imageUtils"

import { ButtonThreeDots } from "../../components/ButtonThreeDots"
import Success from "../components/Success"

import CodeInputNeo from "./common/CodeInputNeo/CodeInputNeo"
import commonStyles from "./styles"

const VerifyCode = ({
  type,
  userId,
  id,
  deviceId,
  value,
  verifyEmail,
  nextStep,
  emailVerification,
  contactMechanismVerification,
  accessToken,
  getTokenOnVerifiedInvitation,
  previousStep,
  firstVerStep,
}) => {
  const [isCodeVerifying, setIsCodeVerifying] = useState(false)
  const [code, setCode] = useState("")
  const [counter, setCounter] = useState(60)
  const [error, setError] = useState("")
  const [verified, setVerified] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)
  const history = useHistory()

  const handleChangeCode = useCallback(val => {
    setCode(val)
  })

  const handleEnterPress = e => {
    let pressedKeyCode = e.keyCode || e.which
    if (pressedKeyCode === 13) handleVerifyCode()
  }

  const key = type === "email" ? "email1" : "phoneNumber"

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleVerifyCode = () => {
    setIsCodeVerifying(true)
    if (firstVerStep || accessToken) {
      if (emailVerification) {
        contactMechanismVerification &&
          changeEmailVerificationVerify(
            code,
            contactMechanismVerification,
            4,
            accessToken
          )
            .then(res => {
              nextStep()
              setIsCodeVerifying(false)
            })
            .catch(error => {
              console.log("error", error)
              setError("The code is invalid")
              setIsCodeVerifying(false)
            })
      } else {
        verifyBetaCode(key, id, code)
          .then(response => {
            if (response.data[key]) {
              setVerified(true)
              getTokenOnVerifiedInvitation(response.data["token"])
            } else {
              setError("Please Enter Valid Verification Code:")
            }
            setIsCodeVerifying(false)
          })
          .catch(error => {
            console.log("error", error)
            setIsCodeVerifying(false)
          })
      }
    } else {
      verifyBetaCode("email1", contactMechanismVerification, code)
        .then(response => {
          if (response.data["email1"]) {
            setVerified(true)
            setVerifiedEmail(true)
            // console.log('token', response.data['token'])
            getTokenOnVerifiedInvitation(response.data["token"])
          } else {
            setError("Please Enter Valid Verification Code:")
          }
          setIsCodeVerifying(false)
        })
        .catch(error => {
          console.log("error", error)
          setIsCodeVerifying(false)
        })
    }
  }

  const resendCode = () => {
    setCounter(60)
    setCode("")
    sendBetaCode({ [key]: value }, deviceId)
  }

  const navigateToCreateAccount = () => {
    const defaultEmail = type === "email" ? value : ""
    const phoneNumber = type === "phone" ? value : ""

    history.push("/sign-up", { userId, defaultEmail, phoneNumber, deviceId })
  }

  const isVerifyDisabled = useMemo(() => {
    return code.length !== 6 || isCodeVerifying
  }, [code, isCodeVerifying])

  return (
    <div
      // className="forgot__page"
      style={{ flexDirection: "column" }}
      onKeyPress={e => handleEnterPress(e)}>
      <div className="forgot__content">
        {verified ? (
          emailVerification ? (
            <Success
              image={images.invitationVerified}
              title="Email Verified"
              subtitle="You can now proceed to creating your account"
              buttonText="Create Account"
              onClick={nextStep}
            />
          ) : (
            <Success
              image={images.invitationVerified}
              title="Invitation Verified"
              subtitle="You can now proceed to creating your account"
              buttonText="Create Account"
              onClick={verifiedEmail ? nextStep : navigateToCreateAccount}
              // onClick={navigateToCreateAccount}
            />
          )
        ) : (
          <>
            {/* <img className="forgot__icon" src={images.cooeModal} /> */}
            <span className="forgot__title">
              Verify {emailVerification ? "Email" : "Invitation"}
            </span>
            <p className="forgot__subtitle mb-0">
              Enter the code we sent to: &nbsp;
              <br />
              {/* {value?.slice(0, 1) + 'x'.repeat(value.length-6) + value?.replace(/ /g,'')?.slice(value.length-5, value.length)} */}
            </p>
            <span className="forgot__subtitle wrap-text">{value}</span>
            {error ? (
              <span className="forgot__error">{error}</span>
            ) : (
              <p
                className="forgot__subtitle"
                style={{ fontSize: 16, color: "#454c52", marginBottom: 8 }}>
                Verification Code:
              </p>
            )}
            <CodeInputNeo
              value={code}
              errorMessage={error}
              customContainerStyle={commonStyles.inputMargin16}
              onChange={handleChangeCode}
            />

            <div
              style={{
                width: 327,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
              }}>
              <button
                className="verify__resend verify__resend--recovery"
                onClick={() => previousStep()}
                style={{ width: 88, height: 48, margin: 0 }}>
                Cancel
              </button>
              <button
                data-testid="verifyButton"
                type="submit"
                disabled={isVerifyDisabled}
                className={cn("forgot__button", {
                  "forgot__button--disabled": isVerifyDisabled,
                })}
                style={{ width: 227 }}
                onClick={handleVerifyCode}>
                {isCodeVerifying ? <ButtonThreeDots /> : "Verify"}
              </button>
            </div>
            <button
              data-testid="verifyResentButton"
              disabled={counter > 0}
              className={cn("verify__resend", {
                "verify__resend--disabled": counter > 0,
              })}
              onClick={resendCode}>
              {"Resend code " + (counter > 0 ? `(${counter})` : "")}
            </button>
            <span className="verify__text">
              We recommend checking your SPAM folder if you don’t receive it
            </span>
          </>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getTokenOnVerifiedInvitation: bindActionCreators(
      getTokenOnVerifiedInvitation,
      dispatch
    ),
  }
}

export default connect(null, mapDispatchToProps)(VerifyCode)
