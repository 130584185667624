import PropTypes from "prop-types"
import { View, FlatList } from "react-native"

import { styles } from "./UserMentionsScreenStyles"

import "./mentionsScreenComponent.scss"
import EmptyConversation from "../EmptyConversation"

const UserMentionsComponent = ({ mentions, renderItem, newDesign }) => {
  if (mentions.length === 0) {
    return (
      <EmptyConversation
        iconName="noMention"
        title="Nothing mentioned"
        description="Mention people to ask them directly"
      />
    )
  }
  return (
    <View
      style={[
        styles.flex,
        styles.mentionsContainer,
        newDesign && { backgroundColor: "transparent" },
      ]}>
      <View style={styles.chatContainer}>
        <FlatList
          contentContainerStyle={styles.listPadding}
          data={mentions}
          renderItem={renderItem}
        />
      </View>
    </View>
  )
}

UserMentionsComponent.propTypes = {
  mentions: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
}

export default UserMentionsComponent
