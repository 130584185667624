import { SvgIcon } from "@components/common"
import Pill from "@components/ui/Pill"
import YourSuggestions from "@components/ui/YourSuggestions"
import { useSuggestionOnlineStore } from "stores/suggestions/suggestionOnlineStore"
import { OnlineSuggestion, URL_TYPES } from "types/suggestions"

const YourSuggestionsContainer = ({ onSave }) => {
  const selectedPlatform = useSuggestionOnlineStore(
    state => state.currentPlatform
  )
  const currentUrlAddress = useSuggestionOnlineStore(
    state => state.currentUrlAddress
  )
  const currentAdditionalInformation = useSuggestionOnlineStore(
    state => state.currentAdditionalInformation
  )
  const currentUrlType = useSuggestionOnlineStore(state => state.currentUrlType)
  const suggestions = useSuggestionOnlineStore(state => state.suggestions)
  const setCurrentUrlAddress = useSuggestionOnlineStore(
    state => state.setCurrentUrlAddress
  )
  const setCurrentUrlType = useSuggestionOnlineStore(
    state => state.setCurrentUrlType
  )
  const addSuggestion = useSuggestionOnlineStore(state => state.addSuggestion)
  const updateSuggestion = useSuggestionOnlineStore(
    state => state.updateSuggestion
  )
  const removeSuggestion = useSuggestionOnlineStore(
    state => state.removeSuggestion
  )
  const setCurrentAdditionalInformation = useSuggestionOnlineStore(
    state => state.setCurrentAdditionalInformation
  )
  const setSelectedPlatform = useSuggestionOnlineStore(
    state => state.setCurrentPlatform
  )
  const currentActiveSuggestionIndex = useSuggestionOnlineStore(
    state => state.currentActiveSuggestionIndex
  )
  const setCurrentActiveSuggestionIndex = useSuggestionOnlineStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const isYourSuggestionsShown = useSuggestionOnlineStore(
    state => state.isYourSuggestionsShown
  )

  const isSuggestionsExist = suggestions.length > 0

  const handleSuggestClick = (isDecided: boolean) => {
    if (suggestions.length > 0) {
      onSave(suggestions, isDecided)
      return
    }

    onSave(
      [
        {
          urlType: currentUrlType,
          urlAddress: currentUrlAddress,
          additionalInformation: currentAdditionalInformation,
          label: selectedPlatform?.label || "",
          iconName: selectedPlatform?.iconName || "",
        },
      ],
      isDecided
    )
  }

  const handleAddToSuggestions = () => {
    if (!currentUrlAddress) return

    updateSuggestion(currentActiveSuggestionIndex, {
      label: selectedPlatform?.label || "",
      iconName: selectedPlatform?.iconName || "",
      urlType: currentUrlType,
      urlAddress: currentUrlAddress,
      additionalInformation: currentAdditionalInformation,
    })
  }

  const handleAddAnother = () => {
    if (!selectedPlatform) return
    if (isSuggestionsExist) {
      handleAddToSuggestions()

      setCurrentActiveSuggestionIndex(suggestions.length)
    } else {
      addSuggestion({
        label: selectedPlatform.label,
        iconName: selectedPlatform.iconName,
        urlType: currentUrlType,
        urlAddress: currentUrlAddress,
        additionalInformation: currentAdditionalInformation,
      })

      setCurrentActiveSuggestionIndex(1)
    }

    setSelectedPlatform(null)
    setCurrentUrlAddress("")
    setCurrentUrlType(URL_TYPES.URL)
    setCurrentAdditionalInformation("")
  }

  const handleSuggestionClick = (index: number) => {
    const suggestion = suggestions[index]
    setSelectedPlatform({
      label: suggestion.label,
      iconName: suggestion.iconName,
    })
    setCurrentUrlType(suggestion.urlType)

    if (suggestion?.urlAddress) {
      setCurrentUrlAddress(suggestion.urlAddress)
    }
    if (suggestion?.additionalInformation) {
      setCurrentAdditionalInformation(suggestion.additionalInformation)
    }

    setCurrentActiveSuggestionIndex(index)
  }

  const handleRemoveSuggestion = (e, index: number) => {
    e.stopPropagation()
    removeSuggestion(index)
  }

  return (
    <YourSuggestions
      isShown={
        !!selectedPlatform ||
        (isYourSuggestionsShown &&
          (suggestions.length > 0 || !!selectedPlatform))
      }
      suggestions={suggestions}
      onDecideClick={() => handleSuggestClick(true)}
      onSuggestClick={() => handleSuggestClick(false)}
      onAddAnotherClick={handleAddAnother}
      // @ts-ignore
      renderPill={(suggestion: OnlineSuggestion, index) => (
        <Pill
          isSelected={index === currentActiveSuggestionIndex}
          onClick={() => handleSuggestionClick(index)}>
          <span>
            {suggestion.label}{" "}
            {suggestion?.urlAddress && suggestion.urlType === URL_TYPES.URL && (
              <span>· {suggestion?.urlAddress}</span>
            )}
          </span>

          {suggestions.length > 1 && (
            <SvgIcon
              name="cross"
              onClick={e => handleRemoveSuggestion(e, index)}
            />
          )}
        </Pill>
      )}
      defaultPill={
        <Pill isSelected>
          <span>
            {selectedPlatform?.label}{" "}
            {currentUrlAddress && currentUrlType === URL_TYPES.URL && (
              <span>· {currentUrlAddress}</span>
            )}
          </span>
        </Pill>
      }
    />
  )
}

export default YourSuggestionsContainer
