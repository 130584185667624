import { useEffect, useRef, useState } from "react"

import classNames from "classnames"

import "./chat.scss"
import { CircleLoader } from "@components/common"

export type TMessage = {
  role: "user" | "assistant"
  message: string
  id: number
}

interface IChatProps {
  sendMessage: (message: TMessage) => void
  messages: TMessage[]
  isLoading: boolean
}

const Chat = ({ sendMessage, messages, isLoading }: IChatProps) => {
  const [message, setMessage] = useState("")
  const ref = useRef<HTMLDivElement | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value)
  }

  const handleMessageSend = (chatMessage: string) => {
    sendMessage({ role: "user", message: chatMessage, id: messages.length + 1 })
    setMessage("")
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="chat">
      <div ref={ref} className="chat__messages">
        {messages
          .sort((a, b) => b.id - a.id)
          .map((item: TMessage, index: number) => {
            return (
              <div
                className={classNames("chat__message", {
                  chat__message_right: item.role === "user",
                })}
                key={index}>
                <div className="chat__messageContent">
                  <div className={"chat__messageRole"}>
                    {item.role === "assistant" ? "Assistant" : "User"}
                  </div>
                  <div className={"chat__messageText"}>{item.message}</div>
                </div>
              </div>
            )
          })
          .reverse()}
        {isLoading && (
          <div
            className={classNames("chat__message", {
              chat__message_right: false,
            })}>
            <CircleLoader />
          </div>
        )}
      </div>
      <div className={"chat__actionsWrap"}>
        <input
          className="chat__input"
          value={message}
          onChange={handleChange}
          type="text"
          placeholder="Type a message"
        />
        <button onClick={() => handleMessageSend(message)}>Send</button>
      </div>
    </div>
  )
}

export default Chat
