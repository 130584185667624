import { userStatusTypes } from "@config/mappings"
import { requestTypes } from "@utils/contactUtils"
import { EVENTS, logEvent } from "analytics-tracking"

import api from "../api"
import endpoints from "../config/endpoints"
import { store } from "../index"
import { selectUserId } from "../selectors/user"

import actionTypes from "./actionTypes"

const getRequests = () => ({
  type: actionTypes.GET_CONNECTION_REQUESTS,
})
const approveRequest = (userId, requestId, approvalAction, inviteType) => ({
  type: actionTypes.APROVE_CONNECTION_REQUEST,
  payload: { userId, requestId, approvalAction, inviteType },
})
const deleteRequest = id => ({
  type: actionTypes.DELETE_CONNECTION_REQUEST,
  payload: { id },
})
// const sendRequest = (inviteeId, message, type, groupId) => ({
//     type: actionTypes.SEND_CONNECTION_REQUEST,
//     payload: { inviteeId, message, type, groupId },
// })

const markAsReadRequest = () => ({
  type: actionTypes.MARK_AS_READ_REQUESTS,
})

const changeUserTeeupStatus = ({ teeupId, statusId, additionalCount = 0 }) => ({
  type: actionTypes.CHANGE_USER_TEEUP_STATUS,
  payload: { teeupId, statusId, additionalCount },
})

const formatRequests = requests => {
  return requests.map(request => {
    return {
      ...request,
      userName: request.username,
      messages: request.messages ? request.messages.reverse() : [],
    }
  })
}

const fetchRequests = userId => dispatch => {
  const url = endpoints.requests(userId)

  return api.client
    .get(url)
    .then(response => {
      dispatch({
        type: actionTypes.SET_CONNECTION_REQUESTS,
        payload: formatRequests(response.data),
      })
    })
    .catch(error => {
      console.log("fetchUserTeeupsUsersById error ")
      console.log(error)
    })
}

const updateUserStatus =
  (teeupId, statusId, additionalCount = 0) =>
  async (dispatch, getState) => {
    let url = endpoints.teeup.userStatus(teeupId)
    const userId = selectUserId(getState)

    let body = {
      userId,
      statusId,
      additionalCount,
    }

    return api.client
      .put(url, body)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_TEEUP,
          payload: { userStatus: userStatusTypes[statusId], teeupId },
        })
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_TEEUP,
          payload: { userStatus: userStatusTypes[statusId], id: teeupId },
        })
        dispatch(fetchRequests(userId))
        logEvent(EVENTS.CHANGE_USER_STATUS, { teeupId, statusId })
      })
      .catch(error => {
        console.log("updateUserStatus", error)
      })
  }

const joinToTeeup =
  (teeupId, statusId, additionalCount = 0) =>
  async (dispatch, getState) => {
    let url = endpoints.teeup.joinToTeeup(teeupId)
    const userId = selectUserId(getState)

    let body = {
      userId,
      statusId,
      additionalCount,
    }

    return api.client
      .put(url, body)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_TEEUP,
          payload: { userStatus: userStatusTypes[statusId], teeupId },
        })
        dispatch({
          type: actionTypes.UPDATE_ACTIVE_TEEUP,
          payload: { userStatus: userStatusTypes[statusId], id: teeupId },
        })
        dispatch(fetchRequests(userId))
        logEvent(EVENTS.CHANGE_USER_STATUS, { teeupId, statusId })
      })
      .catch(error => {
        console.log("joinToTeeup", error)
      })
  }

const removeConnection = userIdToRemove => {
  const url = endpoints.removeConnection()
  const body = { userId: userIdToRemove, statusId: 2 }

  return api.client.delete(url, body).catch(error => {
    console.log("remove connection", error)
  })
}

const sendRequest = (userId, inviteeId, message, type, groupId) => {
  const url = endpoints.requests(userId)
  const request = {
    message: message || " ",
    request_type: type,
  }

  if (
    type === requestTypes.inviteToGroup ||
    type === requestTypes.inviteToConnect
  ) {
    request.invitee_id = inviteeId
  }
  if (type === requestTypes.inviteToGroup || type === requestTypes.joinGroup) {
    request.group_id = groupId
  }
  return api.client
    .post(url, request)
    .then(() => {
      store.dispatch(fetchRequests(userId))
    })
    .catch(error => {
      console.log("add connection", error)
    })
}

export {
  getRequests,
  approveRequest,
  deleteRequest,
  sendRequest,
  markAsReadRequest,
  changeUserTeeupStatus,
  fetchRequests,
  updateUserStatus,
  joinToTeeup,
  removeConnection,
}
