import { useEffect, useRef } from "react"

import debounce from "lodash.debounce"

export const usePrevDateValue = startDate => {
  const ref = useRef()

  useEffect(() => {
    const debouncedSetRef = debounce(() => {
      ref.current = startDate
    }, 300)

    debouncedSetRef()

    return () => {
      debouncedSetRef.cancel()
    }
  }, [startDate])

  return ref.current
}
