import { useState, useEffect } from "react"

import Validator from "password-validator"

import actionTypes from "@actions/actionTypes"

import { PASSWORD_REQUIREMENTS, PASSWORD_RULES } from "../../constants/password"
import { store } from "../../index"

const schema = new Validator()
schema
  .is()
  .min(6)
  .has()
  .digits()
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()

// TODO: it's ODL password validation hook. in the future we must change it by new usePasswordValidation hook
const usePassword = (initialPassword = "") => {
  const [password, setPassword] = useState(initialPassword)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordDirty, setPasswordDirty] = useState(false)
  const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false)
  const [failedRequirements, setFailedRequirements] = useState([])
  const [passwordError, setPasswordError] = useState(null)
  const [passwordErrorDetail, setPasswordErrorDetail] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  useEffect(() => {
    if (password) {
      setFailedRequirements(schema.validate(password, { list: true }))
    } else {
      setFailedRequirements(Object.values(PASSWORD_RULES))
    }
  }, [password])

  useEffect(() => {
    if (failedRequirements.length === 0) {
      setPasswordError(null)
      setPasswordErrorDetail(null)
    } else {
      setPasswordError("Please enter a valid password")

      const firstViolation = PASSWORD_REQUIREMENTS.find(
        req => req.type === failedRequirements[0]
      )
      setPasswordErrorDetail(firstViolation.description)
    }
  }, [failedRequirements])

  useEffect(() => {
    if (password === confirmPassword) {
      setConfirmPasswordError(null)
    } else {
      setConfirmPasswordError("Passwords do not match")
    }
  }, [password, confirmPassword])

  const changePassword = val => {
    setPasswordDirty(true)
    setPassword(val)
    store.dispatch({
      type: actionTypes.SET_RESET_PASS_ERROR,
      payload: null,
    })
  }

  const changeConfirmPassword = val => {
    setConfirmPasswordDirty(true)
    setConfirmPassword(val)
    store.dispatch({
      type: actionTypes.SET_RESET_PASS_ERROR,
      payload: null,
    })
  }

  const clearStatus = () => {
    setPasswordDirty(false)
    setConfirmPasswordDirty(false)
  }

  return {
    password,
    confirmPassword,
    changePassword,
    changeConfirmPassword,
    clearStatus,
    failedRequirements,
    isValid: !passwordError && !confirmPasswordError,
    visiblePasswordError: passwordDirty && passwordError,
    visiblePasswordErrorDetail: passwordDirty && passwordErrorDetail,
    visibleConfirmPasswordError: confirmPasswordDirty && confirmPasswordError,
  }
}

export default usePassword
