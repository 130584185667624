import {
  useState,
  useRef,
  useMemo,
  SyntheticEvent,
  Fragment,
  useCallback,
  useEffect,
} from "react"

import uniqBy from "lodash/uniqBy"
import { useSelector } from "react-redux"

import Pill from "@components/ui/Pill"
import { TextField } from "@components/ui/TextField"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserId } from "@selectors/user"
import getRecentInPersonSuggestions from "@utils/gameplan/getRecentInPersonSuggestions"
import { getCoords } from "@utils/suggestionUtils"
import useAddressPredictions from "pages/TeeUpPage/TeeUpPageModals/SuggestWhereModal/Hooks/useAddressPredictions"
import { useInboxStore } from "stores/inboxStore"
import { useRecentMapSearchStore } from "stores/recentMapSearchStore"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"
import { InPersonSuggestion, Prediction } from "types/suggestions"

import Autocomplete from "../../Autocomplete"
import Option from "../../Autocomplete/option"

import "./index.scss"

const excludedFromSearch = new Set(["Current Location", "Recent"])

interface Props {
  isFromMap?: boolean
  outsideValue?: Prediction | null
}

const Search = ({ isFromMap, outsideValue }: Props) => {
  const [addressValue, setAddressValue] = useState("")
  const recentAddresses = useRecentMapSearchStore(
    state => state.recentAddresses
  )
  const addRecent = useRecentMapSearchStore(state => state.add)
  const removeRecent = useRecentMapSearchStore(state => state.remove)
  const addSuggestion = useSuggestionInPersonStore(state => state.addSuggestion)
  const teeupId = useSelector(selectActiveTeeupId)
  const userId = useSelector(selectUserId)
  const teeups = useInboxStore(state => state.teeUps)

  const setMapCoordinates = useSuggestionInPersonStore(
    state => state.setMapCoordinates
  )
  const isMapLoading = useSuggestionInPersonStore(state => state.isMapLoading)
  const setIsMapLoading = useSuggestionInPersonStore(
    state => state.setIsMapLoading
  )

  const setSelectedAddress = useSuggestionInPersonStore(
    state => state.setSelectedAddress
  )
  const setSelectedName = useSuggestionInPersonStore(
    state => state.setSelectedName
  )

  const selectedName = useSuggestionInPersonStore(state => state.selectedName)
  const selectedAddress = useSuggestionInPersonStore(
    state => state.selectedAddress
  )
  const currentActiveSuggestionIndex = useSuggestionInPersonStore(
    state => state.currentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionInPersonStore(
    state => state.updateSuggestion
  )
  const [autocompleteValue, setAutocompleteValue] = useState<Prediction | null>(
    null
  )

  const detailsRef = useRef(null)

  const geocoder = useMemo(() => new window.google.maps.Geocoder(), [])

  const { predictions } = useAddressPredictions(addressValue, detailsRef)

  useEffect(() => {
    if (outsideValue) {
      setAutocompleteValue(outsideValue)
    }
  }, [outsideValue])

  const updateCurrentSuggestion = (
    name: string | null,
    address: Partial<Prediction> | null
  ) => {
    if (currentActiveSuggestionIndex === -1) return

    const suggestion: Partial<InPersonSuggestion> = {}
    if (name) suggestion.name = name
    // @ts-ignore
    if (address) suggestion.address = address
    updateSuggestion(currentActiveSuggestionIndex, suggestion)
  }

  const handleCurrentLocation = () => {
    if (!navigator.geolocation) {
      return console.log("Geolocation is not supported by this browser.")
    }
    navigator.geolocation.getCurrentPosition(async position => {
      setIsMapLoading(true)
      const response = await getCoords(
        geocoder,
        null,
        position.coords.latitude,
        position.coords.longitude
      )

      if (!response) return

      const { address, lat, lng, placeId } = response
      setMapCoordinates({ lat, lng, placeId: placeId || "" })
      if (address) {
        setSelectedAddress({
          label: address,
          place_id: placeId || "",
          isCurrentLocation: true,
          iconName: "current-location",
          lat,
          lng,
        })
        setAddressValue(address)
        updateCurrentSuggestion(null, address)
      }
      setIsMapLoading(false)
    })
  }

  const handleSelect = async (value: Prediction | null) => {
    if (!value?.place_id) return

    if (value.isCurrentLocation) {
      handleCurrentLocation()
      return
    }

    if (value.isCustom) {
      setSelectedName(addressValue)
      updateCurrentSuggestion(addressValue, null)
      return
    }

    setSelectedAddress(value)
    updateCurrentSuggestion(null, value)

    const response = await getCoords(geocoder, value.place_id)
    if (!response) return

    const { address, lat, lng, placeId } = response
    setMapCoordinates({ lat, lng, placeId: placeId || "" })
    address && setAddressValue(address)
    addRecent(value)
  }

  const handleInput = (_: SyntheticEvent<Element, Event>, value: string) => {
    if (excludedFromSearch.has(value)) return

    setAddressValue(value || "")
  }

  const handleRemoveRecent = (option: Prediction) => {
    if (!option?.isRecent) return
    if (option?.place_id) {
      removeRecent(option.place_id)
    }
  }

  const autocompleteOptions = useMemo(() => {
    if (addressValue) {
      predictions.unshift({
        label: `“${addressValue}”`,
        place_id: "custom",
        isCustom: true,
        iconName: "",
      })
      return predictions
    }

    const options: Prediction[] = [
      {
        label: "Current Location",
        place_id: "currentLocation",
        isCurrentLocation: true,
        iconName: "current-location",
      },
      ...predictions,
    ]

    if (recentAddresses.length > 0) {
      options.push(
        {
          label: "Recent",
          place_id: "recent",
          isSeparator: true,
          iconName: "",
        },
        ...recentAddresses
      )
    }

    return options
  }, [predictions, recentAddresses, addressValue])

  const recentSuggestions = useMemo(() => {
    if (!userId || teeups?.length === 0) {
      return []
    }

    return uniqBy(
      getRecentInPersonSuggestions(teeups, userId, teeupId),
      "title"
    )
  }, [userId, teeupId, teeups])

  const autofillSuggestions = useCallback(
    (index: number) => {
      const suggestion = recentSuggestions[index]

      // @TODO update this maybe sometime
      if (suggestion) {
        addSuggestion({
          name: "",
          address: {
            label: suggestion?.value || "",
            description: suggestion?.details || "",
            lat: suggestion?.latitude || 0,
            lng: suggestion?.longitude || 0,
            iconName: "",
          },
          additionalInformation: suggestion?.info || "",
        })
        setMapCoordinates({
          lat: suggestion.latitude,
          lng: suggestion.longitude,
          placeId: suggestion.googlePlaceId,
        })
        setSelectedAddress({
          place_id: suggestion.googlePlaceId || "",
          label: suggestion.value || "",
          description: suggestion.details || "",
          lat: suggestion.latitude || 0,
          lng: suggestion.longitude || 0,
          iconName: "",
        })
      }
    },
    [recentSuggestions]
  )

  if ((selectedName || selectedAddress) && !isFromMap) return false

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        popupIcon={null}
        className="custom-autocomplete"
        options={autocompleteOptions}
        inputValue={addressValue}
        value={autocompleteValue}
        onInputChange={handleInput}
        ListboxProps={{
          className: "suggest-where-search-list",
        }}
        renderInput={params => (
          <TextField
            {...params}
            value={addressValue}
            placeholder="Suggest Where"
            disabled={isMapLoading}
          />
        )}
        renderOption={(_, option) => (
          <Fragment key={option.place_id}>
            <Option
              option={option}
              isArrowShown={
                !option?.isRecent && !option?.isSeparator && !option?.isCustom
              }
              isCrossShown={option.isRecent}
              isCustom={option.isCustom}
              isDisabled={option?.isSeparator}
              onSelect={handleSelect}
              onOptionRemove={handleRemoveRecent}
            />
          </Fragment>
        )}
        disabled={isMapLoading}
        loading={isMapLoading}
      />

      {!isFromMap && (
        <ul className="suggest-where-pills-container">
          <Pill onClick={handleCurrentLocation} iconName="current-location">
            Current Location
          </Pill>

          {recentSuggestions.length > 0 && (
            <>
              {recentSuggestions.map((suggestion, index) => (
                <Pill
                  iconName="refresh"
                  key={suggestion.id}
                  onClick={() => autofillSuggestions(index)}>
                  <p className="suggest-where-pill-item">
                    {!!suggestion.title && <b>{suggestion.title}</b>}
                    {!!suggestion.subtitle && (
                      <>&nbsp;· {suggestion.subtitle}</>
                    )}
                  </p>
                </Pill>
              ))}
            </>
          )}
        </ul>
      )}
    </div>
  )
}

export default Search
