import { useDispatch, useSelector } from "react-redux"

import {
  openSuggestWhenModalAction,
  closeSuggestWhenModalAction,
  openSuggestWhereModalAction,
  closeSuggestWhereModalAction,
  openSuggestWhenAIModalAction,
  closeSuggestWhenAiModalAction,
} from "@actions/activeTeeupActions"
import {
  selectIsSuggestWhenAiModalOpen,
  selectIsSuggestWhenModalOpen,
  selectIsSuggestWhereModalOpen,
} from "@selectors/activeTeeup"
import { selectTeeupPeople, selectTeeupParticipants } from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"

import { useTeeUpPageDispatch, useTeeUpPageState } from "./TeeUpPageContext"
import {
  SET_INTERACTION_AREA,
  OPEN_PEOPLE_AREA,
  SET_IS_DROPDOWN_OPEN,
} from "./actionTypes"

// TODO: remake to normal context - NewTeeupPageContext that already exists
const useTeeUpPage = () => {
  // react context
  const context = useTeeUpPageDispatch()
  const { interactionArea, openPeopleArea, isThreeDotsDropDownOpen } =
    useTeeUpPageState()
  // redux
  const teeup = useSelector(state => state.activeTeeup)
  const user = useSelector(selectUserInfo)
  const participants = useSelector(selectTeeupParticipants)
  const people = useSelector(selectTeeupPeople)
  const peopleLocations = useSelector(
    state => state.teeups.teeupPeopleLocation.locations
  )
  const isSuggestWhenModalOpen = useSelector(selectIsSuggestWhenModalOpen)
  const isSuggestWhereModalOpen = useSelector(selectIsSuggestWhereModalOpen)
  const isSuggestWhenAiModalOpen = useSelector(selectIsSuggestWhenAiModalOpen)

  const dispatch = useDispatch()

  const setInteractionArea = value => {
    context({
      type: SET_INTERACTION_AREA,
      payload: value,
    })
  }

  const closeInteractionArea = () => {
    context({
      type: SET_INTERACTION_AREA,
      payload: null,
    })
  }

  const setOpenPeopleArea = value => {
    context({
      type: OPEN_PEOPLE_AREA,
      payload: value,
    })
  }

  const openSuggestWhenAiModal = () => {
    dispatch(openSuggestWhenAIModalAction())
  }

  const closeSuggestWhenAiModal = () => {
    dispatch(closeSuggestWhenAiModalAction())
  }

  const openSuggestWhenModal = () => {
    dispatch(openSuggestWhenModalAction())
  }

  const closeSuggestWhenModal = () => {
    dispatch(closeSuggestWhenModalAction())
  }

  const openSuggestWhereModal = () => {
    dispatch(openSuggestWhereModalAction())
  }

  const closeSuggestWhereModal = () => {
    dispatch(closeSuggestWhereModalAction())
  }

  const setIsThreeDotsDropDownOpen = value => {
    context({
      type: SET_IS_DROPDOWN_OPEN,
      payload: value,
    })
  }

  return {
    teeup,
    user,
    interactionArea,
    openPeopleArea,
    participants,
    people,
    setInteractionArea,
    setOpenPeopleArea,
    closeInteractionArea,
    peopleLocations,
    isSuggestWhenModalOpen,
    isSuggestWhereModalOpen,
    isSuggestWhenAiModalOpen,
    openSuggestWhenModal,
    closeSuggestWhenModal,
    openSuggestWhereModal,
    openSuggestWhenAiModal,
    closeSuggestWhenAiModal,
    closeSuggestWhereModal,
    isThreeDotsDropDownOpen,
    setIsThreeDotsDropDownOpen,
  }
}

export default useTeeUpPage
