import React from "react"

import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"

import { Description, PasswordUpdateWrapper } from "../molecules"

export const PasswordUpdatedStep = () => {
  const history = useHistory()

  const handleSubmit = () => {
    history.push("/new-sign-in-email")
  }

  return (
    <PasswordUpdateWrapper>
      <Description>
        Your password has been updated successfully. You can now use this new
        password to log into your CooWe account.
      </Description>

      <ButtonEl
        className="forgot_password-button"
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Continue to Login"
        fullWidth
        onClick={handleSubmit}
      />
    </PasswordUpdateWrapper>
  )
}
