import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Wrapper = styled.div`
  width: max-content;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  margin: 80px auto 0 auto;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    margin: 20px auto 0 auto;
  }
`

export const ContentWrapper = styled.div`
  width: 774px;
  height: 100%;
  background-color: var(--lightGray7);
  border-radius: 16px 16px 0 0;
  margin-top: 25px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    width: 100%;
    height: unset;
    min-height: 100%;
    border-radius: 0;
  }
`
