import React from "react"

import _ from "lodash"
import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS, BUTTON_SIZES } from "@constants/button"
import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { useScreenWidth } from "@hooks"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import { useTeeUpPage } from "../../../pages/TeeUpPage/TeeUpPageContext"

import { ActionsPanel, SortIcon } from "./gameplanBlockStyles"

export const SuggestionsActions = ({
  type,
  isCollapsed,
  isDecidedSuggestion,
  isNewest,
  setIsNewest,
}) => {
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )
  const { isSmallSize } = useScreenWidth()
  const buttonProps = {
    autoWidth: true,
    size: BUTTON_SIZES.SMALL,
    variant: BUTTON_VARIANTS.TRANSPARENT,
    textColor:
      !isCollapsed || isDecidedSuggestion ? "var(--gray2)" : "var(--gray6)",
  }
  const isWhen = type === GAMEPLAN_TYPES.when

  const handleSuggestClick = () => {
    isWhen ? setIsWhenModalOpen(true) : setIsWhereModalOpen(true)
  }

  return (
    <ActionsPanel $isCollapsed={isCollapsed}>
      {!isCollapsed && (
        <>
          <ButtonEl
            {...buttonProps}
            text="Sort by: Newest"
            onClick={() => setIsNewest(prevState => !prevState)}>
            <SortIcon $isNewest={isNewest} name="sort" color="var(--gray2)" />
          </ButtonEl>

          {isSmallSize && (
            <ButtonEl
              autoWidth
              variant={BUTTON_VARIANTS.TRANSPARENT}
              text={`+ Suggest ${_.capitalize(type)}`}
              onClick={handleSuggestClick}
            />
          )}
        </>
      )}
    </ActionsPanel>
  )
}

SuggestionsActions.propTypes = {
  isCollapsed: PropTypes.bool,
  isDecidedSuggestion: PropTypes.bool,
  isNewest: PropTypes.bool,
  setIsNewest: PropTypes.func,
}
