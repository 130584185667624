import React from "react"

import cn from "classnames"

import { images } from "@utils/imageUtils"

import "./index.scss"

const SearchFieldPlaceItem = ({
  isSearchNearby,
  isSearchHistory,
  prediction,
  placePhoto,
  onClick,
}) => {
  return (
    <div
      className={cn("suggest-in-person-places__item", {
        nearby: isSearchNearby,
      })}
      onClick={() => !isSearchNearby && onClick()}
      style={{ cursor: !isSearchNearby && "pointer" }}>
      {isSearchNearby ? (
        <div
          className="suggest-in-person-places__photo"
          style={{
            backgroundImage:
              placePhoto && placePhoto.photo && `url(${placePhoto.photo})`,
            backgroundColor: placePhoto && !placePhoto.photo && "#e0e8ee",
          }}>
          {placePhoto && !placePhoto.photo && (
            <img
              src={images.ndSuggestSearchNearbyItem}
              className="suggest-in-person-places__photo--empty"
            />
          )}
        </div>
      ) : (
        <div className="suggest-in-person-places__icon">
          <img
            src={
              isSearchHistory
                ? images.ndSuggestSearchRecentItem
                : images.ndSuggestSearchItem
            }
            alt="search item"
          />
        </div>
      )}

      <div className="suggest-in-person-places__info">
        <h5 className="suggest-in-person-places__name">
          {isSearchHistory
            ? prediction.value
            : prediction.structured_formatting.main_text}
        </h5>
        {isSearchHistory
          ? prediction.details
          : prediction.structured_formatting.secondary_text}

        {isSearchNearby && (
          <button
            type="button"
            className="suggest-in-person-places__button"
            onClick={() => isSearchNearby && onClick()}>
            Select
          </button>
        )}
      </div>
    </div>
  )
}

export default SearchFieldPlaceItem
