import actionTypes from "../actions/actionTypes"

const initialState = {
  invitees: [],
  invited: [],
  draftInvitees: [],
  selectedContacts: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACTS:
      return {
        ...state,
        invitees: action.invitees,
      }
    case actionTypes.SET_DRAFT_INVITEES:
      return {
        ...state,
        draftInvitees: action.invitees,
      }
    case actionTypes.SET_SELECTED_CONTACT:
      const newSelectedContacts = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]

      return {
        ...state,
        selectedContacts: [...state.selectedContacts, ...newSelectedContacts],
      }
    case actionTypes.REMOVE_SELECTED_CONTACT:
      return {
        ...state,
        selectedContacts: Array.isArray(action.payload)
          ? state.selectedContacts.filter(
              contact => !action.payload.includes(contact.id)
            )
          : state.selectedContacts.filter(
              contact => contact.id !== action.payload
            ),
      }
    case actionTypes.RESET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: [],
      }
    default:
      return state
  }
}
