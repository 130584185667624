import React, { useMemo } from "react"

import { URL_PATTERN } from "@utils/suggestionUtils"
import { Suggestion } from "types/suggestions"

interface Props {
  suggestion: Suggestion
  subtitle: string
}

const useSubtitle = ({ suggestion, subtitle }: Props) => {
  const isUrl = useMemo(
    () => !!URL_PATTERN.test(suggestion?.details || subtitle),
    [subtitle, suggestion?.details]
  )

  const getSubtitle = () => {
    if (subtitle && isUrl) {
      return <a href={subtitle}>{subtitle}</a>
    } else if (subtitle && !isUrl) {
      return subtitle
    } else if (suggestion?.details && isUrl) {
      return <a href={suggestion?.details}>{suggestion?.details}</a>
    } else if (suggestion?.details && !isUrl) {
      return suggestion?.details
    }

    return "no link provided"
  }

  return { getSubtitle, isUrl }
}

export default useSubtitle
