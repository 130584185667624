import styled from "styled-components"

export const ButtonsList = styled.ul`
  list-style: none;
  margin-top: 30px;
`

export const ButtonsListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
