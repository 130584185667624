import { push } from "connected-react-router"

import { setModalSuccess } from "@actions/commonActions"
import { loadNotifications } from "@actions/notificationActions"
import { setMentionPrompt } from "@actions/promptsActions"
import { updateUserInfo } from "@actions/userActions"
import { teeupListNudgeTypes } from "@config/enums"
import {
  statusTypeIds,
  teeupStatusTypes,
  conversationTypeIds,
  messageTypeIds,
  userStatusTypes,
  gameplanTypes,
} from "@config/mappings"
import {
  selectTeeupSuggestions,
  selectTeeupsGameplans,
  selectAnyTeeupById,
  selectTeeupParticipants,
  selectTeeupPeople,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { getUnixTimestamp, getTimestampString } from "@utils/dateUtils"
import { formatSuggestion, formatSuggestionOut } from "@utils/gamePlanUtils"
import { getQueryString } from "@utils/queryStrings"
import { reloadPage } from "@utils/reloadPage"
import { formatTeeupUsers } from "@utils/teeupUtils"
import { EVENTS, logEvent } from "analytics-tracking"
import { useConversationStore } from "stores/conversationStore"
import { DiscussionTabs } from "types/discussionCard"
import dayjs from "utils/dayjs"

import actionTypes from "../actions/actionTypes"
import {
  updateActiveTeeup,
  addMessage,
  updateMessage,
  manageTeeUpSuggestions,
  updateActiveTeeUpStatus,
} from "../actions/activeTeeupActions"
import { fetchRequests } from "../actions/requestActions"
import {
  updateTeeup as updateTeeupState,
  updateTeeupStatusState,
  createTeeup,
  moveTeeupToTrash,
  formatTeeup,
  fetchTeeupUsersById,
  gotTeeupParts,
  fetchTeeupOverview,
  updateReactions,
  fetchGameplanOptions,
  updateGameplan,
  updateTeeupInfo,
  archiveTeeup,
  updateTeeUpListStatus,
} from "../actions/teeupActions"
import endpoints from "../config/endpoints"
import googleAPI from "../config/googleAPI"
import { store } from "../index"
import { selectActiveTeeupId } from "../selectors/activeTeeup"
import { useInboxStore } from "stores/inboxStore"

const io = require("socket.io-client")

let teeupSocket = null
let teeupListSocket = null

const handleFetchFromGoogleAPI = (url, urlParams) => {
  // const config = googleAPI.fetchConfig[Platform.OS]
  const fullUrl = `${url}?${getQueryString({
    key: "AIzaSyC8ce4byJ3uPdPTNZ6_dpw9utQVGClnVL4",
  })}&${urlParams}`
  // return api.client.get(fullUrl)
  return fetch(fullUrl, {
    method: "GET",
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Cross-Origin-Resource-Policy': 'cross-origin',
    // },
    mode: "no-cors",
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error("handleFetchFromGoogleAPI error", error)
    })
}

export const autocompletePlace = (query, location) => {
  let queryParams = { input: query }
  if (location) {
    queryParams.origin = location
    queryParams.location = location
    queryParams.radius = 20_000 // radius of 20km
  }
  const params = getQueryString(queryParams)
  return handleFetchFromGoogleAPI(googleAPI.autocompletePlace, params)
}

export const findByPlaceId = query => {
  const params = getQueryString({ place_id: query })
  return handleFetchFromGoogleAPI(googleAPI.findByPlaceId, params)
}

export const findByPlaceCoords = ({ latitude, longitude }) => {
  const params = getQueryString({ address: "" + latitude + "," + longitude })
  return handleFetchFromGoogleAPI(googleAPI.findByPlaceId, params)
}

export const autocompleteNearbySearch = (query, location) => {
  let queryParams = { keyword: query }
  if (location) {
    queryParams.location = location
    queryParams.rankBy = "distance"
    queryParams.radius = 20_000 // radius of 20km
  }
  const params = getQueryString(queryParams)
  return handleFetchFromGoogleAPI(googleAPI.nearbySearch, params)
}

// const getTeeupOrganisers = (teeupId, dontDispatch) => {
//   let url = endpoints.teeup.organisers(teeupId)

//   return api.client
//     .get(url)
//     .then((response) => {
//       if (dontDispatch) {
//         return { teeupId, organisers: response.data }
//       } else {
//         store.dispatch({
//           type: actionTypes.GOT_TEEUP_ORGANISERS,
//           payload: { teeupId, organisers: response.data },
//         })
//       }
//     })
//     .catch((error) => {
//       console.error("getTeeupOrganisers error", error)
//     })
// }

const fetchTeeupGameplansDispatch = (
  teeupId,
  shouldRefreshTeeups = false,
  shouldUpdateSuggestions = false
) => {
  return fetchGameplanOptions(teeupId, true)
    .then(response => {
      store.dispatch(updateGameplan(teeupId, response))

      if (shouldUpdateSuggestions) {
        const userId = selectUserId(store.getState())
        store.dispatch({
          type: actionTypes.UPDATE_SUGGESTIONS,
          payload: { response, teeupId, userId },
        })

        store.dispatch(
          updateActiveTeeup({
            id: teeupId,
            gameplans: response.gameplans,
          })
        )
      }
    })
    .catch(error => console.error("fetchTeeupGameplansDispatch error:", error))
}

// const fetchTeeupUsersDispatch = (teeupId) => {
//   let url = endpoints.teeup.users(teeupId)

//   return api.client
//     .get(url)
//     .then((response) => formatTeeupUsers(response.users, teeupId))
//     .catch((error) => {
//       console.error(error)
//     })
//     .then((response) =>
//       store.dispatch({
//         type: actionTypes.GOT_PARTICIPANTS,
//         payload: response,
//       })
//     )
// }

export const setupCurrentTeeupSocket = (token, teeupId, userId) => {
  let uri = endpoints.chat

  let options = {
    query: {
      token: token,
      teeup_id: teeupId,
      user_id: userId,
    },
    transports: ["websocket"],
  }

  if (teeupSocket) teeupSocket.close()

  teeupSocket = io.connect(uri, options)

  // teeupSocket.on("teeup:userUpdate", async (event) => {
  teeupSocket.on("teeup:userUpdate", async ({ user }, info) => {
    try {
      const teeupId = info.teeupId
      const userId = user.id
      const people = selectTeeupPeople(store.getState())
      let participants = selectTeeupParticipants(store.getState())
      if (!people[userId]) return
      const formattedUsers = formatTeeupUsers([user], Number(teeupId))
      if (formattedUsers.people[userId]) {
        people[userId] = formattedUsers.people[userId]
      } else {
        delete people[userId]
        participants = participants.filter(item => item === userId)
      }

      let suggestions = selectTeeupSuggestions(store.getState())
      suggestions = Object.fromEntries(
        Object.keys(suggestions).map(key => [key, { isNew: true }])
      )

      store.dispatch(
        gotTeeupParts([{ people, participants, teeupId }, suggestions])
      )
    } catch (error) {
      console.log("error", error)
    }
  })

  teeupSocket.on("user:statusChange", async event => {
    const { status, teeupId } = event
    store.dispatch({
      type: actionTypes.UPDATE_TEEUP,
      payload: { userStatus: status, teeupId },
    })
  })

  teeupSocket.on("teeup:update", event => {
    const { teeup, message } = event
    const teeupGamePlan = selectTeeupsGameplans(store.getState())
    const teeupWithGamePlan = { ...teeup, gameplans: teeupGamePlan[teeup.id] }
    const formatedTeeup = formatTeeup(teeupWithGamePlan)
    loadNotifications()
    store.dispatch(updateTeeupInfo({ teeupid: teeup.id, teeup: formatedTeeup }))
    store.dispatch(updateActiveTeeup(formatedTeeup))
    const systemMessage = formatMessage(message)
    if (systemMessage) {
      store.dispatch(addMessage(systemMessage, teeup.id))
    }
  })

  teeupSocket.on("pinboard", event => {
    updateConversationRedDot(DiscussionTabs.PinnedMessages, true)
    const { isPinned = false } = event
    const conversationTypeId =
      event.image && !event.text
        ? conversationTypeIds.image
        : event.conversationTypeId
    store.dispatch(updateMessage({ ...event, isPinned, conversationTypeId }))
  })

  teeupSocket.on("pinboard:suggestion", event => {
    const { suggestionId, isPinned = false, pinnedById = null } = event
    store.dispatch(updateMessage({ id: suggestionId, isPinned, pinnedById }))
  })

  teeupSocket.on("teeup:new", async teeup => {
    // It gets multiple events so have to check if teeup is already added
    const teeupExists = selectAnyTeeupById(Number(teeup.id))(store.getState())
    if (teeupExists) return
    const formatedTeeup = formatTeeup(teeup)
    store.dispatch(createTeeup(formatedTeeup))
    const teeupUsers = await fetchTeeupUsersById(teeup.id)

    store.dispatch(gotTeeupParts([teeupUsers]))
  })

  teeupSocket.on("teeup:skip", event => {
    const { teeupId } = event
    const teeup = selectAnyTeeupById(Number(teeupId))(store.getState())
    if (!teeup) return

    store.dispatch(moveTeeupToTrash(teeup))
  })

  teeupSocket.on("listener_error", event => {
    if (event?.error?.message && event?.event === "suggestion") {
      alert(event.error.message)
      useConversationStore
        .getState()
        .setIsSettingSuggestionToDecidedInProgress(false)
    }
  })

  // teeupSocket.on("teeup:archive", (event) => {
  teeupSocket.on("teeup:archive", (_, { teeupId }) => {
    // const { teeupId } = event;
    store.dispatch(archiveTeeup(teeupId))
  })

  teeupSocket.on("teeup:message", (event, { teeupId }) => {
    const message = formatMessage(event)
    if (!message) return

    // Don't add reaction message duplicates, as they are already added in the "suggestion:react" event
    if (
      event.typeId === statusTypeIds.reaction &&
      event.suggestionId &&
      event.conversationTypeId === conversationTypeIds.status
    )
      return

    const isMention = message?.mentionIds?.length > 0
    isMention && updateConversationRedDot(DiscussionTabs.UserMentions, true)

    store.dispatch(addMessage(message, teeupId))
  })

  teeupSocket.on("reply", (event, { teeupId }) => {
    event.conversationTypeId = event.typeId
      ? conversationTypeIds.nudge
      : conversationTypeIds.reply
    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
  })

  teeupSocket.on("comment", (event, { teeupId }) => {
    event.conversationTypeId = conversationTypeIds.message
    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
  })

  teeupSocket.on("updateAvailability", (event, { teeupId }) => {
    event.conversationTypeId = conversationTypeIds.durationUpdate
    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
  })

  teeupSocket.on("statusChange", async (event, { teeupId }) => {
    const { changeFor, statusId } = event

    event.time = getTimestampString()
    event.conversationTypeId = conversationTypeIds.status
    event.typeId =
      event.changeFor === "user" ? statusTypeIds.user : statusTypeIds.teeup

    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }

    if (changeFor === "teeup") {
      const status = teeupStatusTypes[statusId]
      if (status) {
        logEvent(EVENTS.CHANGE_TEEUP_STATUS, { teeupId, status })
        store.dispatch(updateTeeupStatusState(teeupId, status))
        store.dispatch(updateTeeupInfo({ teeupid: teeupId, teeup: { status } }))
        store.dispatch(updateActiveTeeup({ id: teeupId, status }))
      }
      if (status === teeupStatusTypes[5]) {
        store.dispatch(push("/inbox"))
        reloadPage()
      }
    }

    if (changeFor === "user") {
      const status = userStatusTypes[statusId]

      store.dispatch({
        type: actionTypes.UPDATE_TEEUP,
        payload: { userStatus: status, teeupId },
      })
    }
  })

  // teeupSocket.on("reaction", (event, { teeupId }) => {
  teeupSocket.on("suggestion:react", ({ reaction }, { teeupId }) => {
    const { reactionId, suggestionId, updatedBy, typeId } = reaction

    reaction.conversationTypeId = conversationTypeIds.status
    const message = formatMessage(reaction)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
      store.dispatch(
        updateReactions(reactionId, suggestionId, teeupId, updatedBy, typeId)
      )
    }
  })

  // teeupSocket.on("retract", (event, { teeupId }) => {
  //   event.conversationTypeId = conversationTypeIds.status;
  //   event.typeId = statusTypeIds.retract;
  //   const message = formatMessage(event);
  //   if (message) {
  //     store.dispatch(addMessage(message, teeupId));
  //   }
  // });

  teeupSocket.on("upgradeToGamePlan", async event => {
    const teeupId = selectActiveTeeupId(store.getState())
    const gameplanOptions = await fetchGameplanOptions(teeupId)

    store.dispatch(updateGameplan(teeupId, gameplanOptions))

    store.dispatch(updateMessage({ id: event.suggestionId, selected: true }))
    event.conversationTypeId = conversationTypeIds.gameplanSelection
    event.isGamePlan = true

    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
  })

  teeupSocket.on("suggestion:removeFromGamePlan", async event => {
    const { suggestion } = event

    store.dispatch(manageTeeUpSuggestions(event))
    store.dispatch(
      updateMessage({ id: suggestion.id, selected: suggestion.isSelected })
    )
  })

  teeupSocket.on("decide", (event, { teeupId }) => {
    fetchTeeupGameplansDispatch(teeupId)
    store.dispatch(manageTeeUpSuggestions(event))
    event.conversationTypeId = conversationTypeIds.gameplanUpdate
    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
    useConversationStore
      .getState()
      .setIsSettingSuggestionToDecidedInProgress(false)
  })

  teeupSocket.on("suggestions/withdraw", (event, { teeupId }) => {
    const { suggestionId, isWithdrawn } = event
    store.dispatch(manageTeeUpSuggestions(event))

    store.dispatch(updateMessage({ id: suggestionId, isWithdrawn }))
    fetchTeeupGameplansDispatch(teeupId)
  })

  teeupSocket.on("suggestions", async event => {
    const teeupId = selectActiveTeeupId(store.getState())
    event.conversationTypeId = conversationTypeIds.suggestion
    fetchTeeupGameplansDispatch(teeupId, false, true)

    event.suggestions.map(suggestion => {
      const message = formatMessage({ ...event, suggestion })

      if (message) {
        store.dispatch(addMessage(message, teeupId))
      }
      return message
    })
  })

  teeupListSocket.on("teeup_update", ({ newStatus, teeupId }) => {
    if (!newStatus) return

    store.dispatch(
      updateActiveTeeUpStatus({
        newStatus,
        teeupId,
      })
    )
  })

  teeupSocket.on("suggestion", async event => {
    const teeupId = selectActiveTeeupId(store.getState())
    event.conversationTypeId = conversationTypeIds.suggestion
    const message = formatMessage(event)
    if (message) {
      store.dispatch(addMessage(message, teeupId))
    }
    if (event.suggestion && event.suggestion.isSelected) {
      const gameplanOptions = await fetchGameplanOptions(teeupId)

      store.dispatch(updateGameplan(teeupId, gameplanOptions))

      store.dispatch(updateMessage({ id: event.suggestionId, selected: true }))
      event.conversationTypeId = conversationTypeIds.gameplanSelection
      event.isGamePlan = true

      const message = formatMessage(event)
      if (message) {
        store.dispatch(addMessage(message, teeupId))
      }
      const selectionEvent = {
        suggestionId: event.suggestion.id,
        time: event.time + 1,
        updatedBy: event.suggestion.createdBy,
        isGamePlan: true,
        conversationTypeId: conversationTypeIds.gameplanSelection,
      }
      const selectionMessage = formatMessage(selectionEvent)
      if (selectionMessage) {
        store.dispatch(addMessage(selectionMessage, teeupId))
      }
    }

    await fetchTeeupGameplansDispatch(teeupId, false, true)
  })

  teeupSocket.on("teeup:statusUpdate", ({ statusId, teeupId }) => {
    if (statusId && teeupStatusTypes.keys.includes(statusId)) {
      store.dispatch(
        updateActiveTeeUpStatus({
          newStatus: statusId,
          teeupId,
        })
      )
    }
  })

  teeupSocket.on("droppedUser", () => {
    // getTeeupOrganisers(teeupId)
    // fetchTeeupUsersDispatch(teeupId)
  })

  teeupSocket.on("droppedUser", (_, { teeupId }) => {
    store.dispatch(
      updateTeeupInfo({ teeupid: teeupId, teeup: { userStatus: "droppedout" } })
    )
    store.dispatch(archiveTeeup(teeupId))
  })
}

export const closeCurrentTeeupSocket = () => {
  if (teeupSocket) teeupSocket.close()
}

export const reJoinTooTeeUp = teeupId => {
  teeupListSocket.emit("teeup:participant:status", { status: 5 }, { teeupId })
}

export const joinTeeup = async ({ userStatus, teeupId, additionalCount }) => {
  const status = userStatusTypes.reverse[userStatus]
  if (!status) return

  // let requestFinished = false;

  teeupListSocket.emit(
    "teeup:participant:status",
    { status, additionalCount },
    { teeupId },
    () => {
      const inboxStore = useInboxStore.getState()
      const teeUps = inboxStore.teeUps
      const currentIndex = teeUps.findIndex(teeup => teeup.id === teeupId)

      if (teeUps[currentIndex]) {
        teeUps[currentIndex].userstatus = status
        inboxStore.setTeeUps([...teeUps])
      }
    }
  )
}

export const setupTeeupListSocket = (token, userId) => {
  let options = {
    query: {
      token: token,
      user_id: userId,
    },
  }

  let uri = endpoints.chat

  if (teeupListSocket) {
    teeupListSocket.close()
  }

  teeupListSocket = io.connect(uri, options)

  teeupListSocket.on("teeup:statusUpdate", ({ statusId, teeupId }) => {
    if (statusId) {
      store.dispatch(updateTeeUpListStatus({ status: statusId, teeupId }))
    }
  })

  teeupListSocket.on("profile:update", ({ profile: userInfo }) => {
    dayjs.tz.setDefault(userInfo?.timezone)
    store.dispatch(updateUserInfo(userInfo))
  })

  // TODO: temporary I've commented this block - because it trigger all opened browser tabs
  // TODO: so if everything is okay - remove this commented block
  // teeupListSocket.on("teeup:participant:status", (response) => {
  //   const teeupId = response.state.teeupId
  //
  //   store.dispatch(push(`/teeUps/${teeupId}`))
  //   window.location.reload()
  // })

  teeupListSocket.on("teeup:invitation", async event => {
    const { id: teeupId } = event

    const userId = selectUserId(store.getState())
    store.dispatch(fetchRequests(userId))
    const teeupOverview = await fetchTeeupOverview(teeupId)
    const teeupExists = selectAnyTeeupById(Number(teeupId))(store.getState())
    if (teeupExists || !teeupOverview) return
    const { teeup } = teeupOverview
    teeup.userStatus = userStatusTypes[1]
    store.dispatch(createTeeup(teeup))
    const teeupUsers = await fetchTeeupUsersById(teeupId)
    const teeupGO = await fetchGameplanOptions(teeupId)
    store.dispatch(gotTeeupParts([teeupUsers, teeupGO]))
  })

  teeupListSocket.on("teeup:message", ({ teeupId, senderId }) => {
    store.dispatch(
      setMentionPrompt({
        teeupId,
        senderId,
      })
    )
  })

  teeupListSocket.on("teeup_update", event => {
    const { newStatus, teeupId } = event
    if (newStatus) {
      store.dispatch(updateTeeUpListStatus({ status: newStatus, teeupId }))
    }
    store.dispatch({
      type: actionTypes.GOT_TEEUPS_STATE,
      payload: [event],
    })
  })

  teeupListSocket.on(
    "decide",
    async ({ gameplanOptions: gameplans }, { teeupId }) => {
      store.dispatch(updateTeeupState({ gameplans, teeupId }))
    }
  )

  teeupListSocket.on(
    "suggestion",
    async ({ state, gameplanOptions: gameplans }) => {
      const { teeupId } = state
      updateConversationRedDot(DiscussionTabs.Suggestions, true)
      store.dispatch(updateTeeupState({ gameplans, teeupId }))
      // TODO: next row is wrong way - we did it because hadn't a time.
      // TODO: manage the store via reducer with current WS response - same for teeupSocket.on("suggestion" listener
      fetchTeeupGameplansDispatch(teeupId, false, true)
    }
  )

  teeupListSocket.on("user:createPassword", event => {
    event.error &&
      store.dispatch({
        type: actionTypes.SET_RESET_PASS_ERROR,
        payload: event.error.message,
      })
    !event.error && store.dispatch(setModalSuccess(true))
  })
}

export const formatMessage = (event = {}) => {
  let message
  let { createdAt, time, id } = event

  let conversationTypeId

  if (event.conversationTypeId) {
    conversationTypeId =
      event.conversationTypeId === conversationTypeIds.reply && event.typeId
        ? conversationTypeIds.nudge
        : event.conversationTypeId
  } else if (event.suggestion) {
    conversationTypeId = event.suggestion.conversationTypeId
  }

  // In case some new conversationTypeId that we're not handling comes in, skip it
  if (
    !conversationTypeId ||
    (conversationTypeId !== conversationTypeIds.suggestion &&
      // !conversationTypeId == conversationTypeIds.suggestionMessage &&
      conversationTypeId !== conversationTypeIds.message &&
      conversationTypeId !== conversationTypeIds.reply &&
      conversationTypeId !== conversationTypeIds.status &&
      conversationTypeId !== conversationTypeIds.image &&
      conversationTypeId !== conversationTypeIds.video &&
      conversationTypeId !== conversationTypeIds.file &&
      conversationTypeId !== conversationTypeIds.initial &&
      conversationTypeId !== conversationTypeIds.template &&
      conversationTypeId !== conversationTypeIds.durationUpdate &&
      conversationTypeId !== conversationTypeIds.imageChange &&
      conversationTypeId !== conversationTypeIds.gameplanUpdate &&
      conversationTypeId !== conversationTypeIds.nudge &&
      conversationTypeId !== conversationTypeIds.gameplanSelection)
  ) {
    return null
  }

  const timestamp = time ? time : createdAt
  let text
  if (event.text) {
    text = event.text
  } else if (event.message) {
    text = event.message
  } else if (event.comment) {
    text = event.comment
  } else if (event.reply) {
    text = event.reply
  }

  switch (conversationTypeId) {
    case conversationTypeIds.suggestion: {
      let suggestion = event.suggestion ? event.suggestion : event
      message = formatSuggestion(suggestion)
      message.id = event.suggestionId || suggestion.id || timestamp.toString()
      message.conversationTypeId = conversationTypeId
      message.optionId = suggestion.gameplanOptionId || suggestion.optionId
      message.isSuggestion = true
      message.timestamp = timestamp
      message.senderId = message.createdBy
      message.isPinned = event.isPinned
      message.pinnedById = event.pinnedById

      break
    }
    case conversationTypeIds.status:
    case conversationTypeIds.imageChange: {
      const {
        isSystem,
        additionalCount = 0,
        statusId,
        image,
        reactionId,
        suggestionId,
        typeId,
        userId,
        updatedBy,
        id,
      } = event
      message = {
        isSystem,
        conversationTypeId,
        additionalCount,
        timestamp,
        typeId,
        image,
        text,
        timestamps: [getUnixTimestamp(timestamp)],
        suggestionId: suggestionId,
        statusId: statusId ? statusId : reactionId,
        senderId: updatedBy ? updatedBy : userId,
        id: id || timestamp?.toString() || Date.now(),
      }

      break
    }
    case conversationTypeIds.initial: {
      const { createdBy: senderId, userId } = event

      if (!senderId) {
        return null
      }

      message = {
        conversationTypeId: conversationTypeIds.status,
        statusId: null,
        typeId: statusTypeIds.invite,
        suggestionId: null,
        timestamps: [getUnixTimestamp(timestamp)],
        userId,
        timestamp,
        senderId,
      }

      break
    }
    default:
      if (event.image) {
        const { image, senderId, userId, isPinned, pinnedById } = event
        message = {
          id,
          isPinned,
          pinnedById,
          conversationTypeId: conversationTypeIds.image,
          statusId: null,
          suggestionId: null,
          image,
          timestamp,
          senderId: senderId || userId,
        }
      } else if (event.file) {
        const { file, senderId, userId, isPinned, pinnedById } = event
        message = {
          id,
          isPinned,
          pinnedById,
          conversationTypeId: conversationTypeIds.file,
          statusId: null,
          suggestionId: null,

          file,
          timestamp,
          senderId: senderId || userId,
        }
      } else if (event.video) {
        const { video, senderId, userId, isPinned, pinnedById } = event
        message = {
          id,
          isPinned,
          pinnedById,
          conversationTypeId: conversationTypeIds.video,
          statusId: null,
          suggestionId: null,
          video,
          timestamp,
          senderId: senderId || userId,
        }
      } else
        switch (conversationTypeId) {
          case conversationTypeIds.durationUpdate: {
            const {
              userId,
              createdAt,
              endTime,
              gamePlanOptionId,
              id,
              startTime,
              suggestionId,
              updatedAt,
            } = event

            message = {
              conversationTypeId,
              timestamp,
              timestamps: [getUnixTimestamp(timestamp)],
              senderId: userId,
              createdAt,
              endTime,
              gamePlanOptionId,
              id,
              startTime,
              suggestionId,
              updatedAt,
            }

            break
          }
          case conversationTypeIds.template: {
            message = event

            break
          }
          case conversationTypeIds.gameplanUpdate: {
            const {
              isDecided,
              isGamePlan,
              teeupId,
              userId,
              suggestionId,
              previousSuggestionId,
              createdAt,
              updatedAt,
              id,
              updatedBy,
            } = event

            message = {
              conversationTypeId,
              timestamp,
              timestamps: [getUnixTimestamp(timestamp)],
              senderId: updatedBy || userId,
              createdAt,
              id,
              suggestionId,
              previousSuggestionId,
              updatedAt,
              isGamePlan,
              isDecided,
              teeupId,
            }

            const isSelectOrUnselect =
              message.conversationTypeId ===
                conversationTypeIds.gameplanUpdate &&
              ((message.isGamePlan === false && !message.isDecided) || // unselect
                (message.isGamePlan &&
                  !message.isDecided &&
                  message.suggestionId !== message.previousSuggestionId)) // select or replace
            if (isSelectOrUnselect) {
              message.conversationTypeId = conversationTypeIds.gameplanSelection
            }

            break
          }
          case conversationTypeIds.gameplanSelection: {
            // Direction (immediate) message update from socket stream
            const {
              isDecided,
              isGamePlan,
              teeupId,
              userId,
              suggestionId,
              previousSuggestionId,
              createdAt,
              updatedAt,
              id,
              updatedBy,
            } = event

            message = {
              conversationTypeId,
              timestamp,
              timestamps: [getUnixTimestamp(timestamp)],
              senderId: updatedBy || userId,
              createdAt,
              id: id || String(timestamp),
              suggestionId,
              previousSuggestionId,
              updatedAt,
              isGamePlan,
              isDecided,
              teeupId,
            }

            break
          }
          default:
            if (
              (event.messageTypeId === messageTypeIds.nudge &&
                event.subTypeId !== teeupListNudgeTypes.mention) ||
              (event.typeId && conversationTypeId === messageTypeIds.nudge)
            ) {
              message = {
                ...event,
                conversationTypeId: conversationTypeIds.nudge,
                id: id || timestamp.toString(),
              }
            } else {
              let senderId = event.senderId ? event.senderId : event.userId
              message = {
                ...event,
                conversationTypeId,
                text,
                timestamp,
                senderId,
                id: id || timestamp.toString(),
              }

              if (event.suggestionId) {
                message.suggestionId = event.suggestionId
              }
            }
        }
  }

  if (
    typeof message.timestamp === "string" ||
    typeof message.timestamp === "number"
  ) {
    message.timestamp = getUnixTimestamp(message.timestamp)
  }
  return message
}

export const updateTeeupStatus = (userId, teeupStatus) => {
  const statusType =
    typeof teeupStatus === "object"
      ? teeupStatusTypes.reverse[teeupStatus.label]
      : teeupStatusTypes.reverse[teeupStatus]

  if (!statusType) return

  teeupSocket.emit("statusChange", {
    status: statusType,
    updatedBy: userId,
    changeFor: "teeup",
    typeId: statusTypeIds.teeup,
  })
}

export const createPassword = (accessToken, password) => {
  teeupListSocket.emit(
    "user:createPassword",
    {
      password,
    },
    { token: accessToken }
  )
}

export const sendMessage = (
  message,
  messageTypeId,
  replyMessage,
  mentionIds
) => {
  if (messageTypeId === messageTypeIds.image) {
    // teeupSocket.emit("message:teeup", message, messageTypeId)
    teeupSocket.emit("teeup:message", {
      messageTypeId: messageTypeIds.image,
      image: message,
    })
  } else if (replyMessage) {
    if (replyMessage.suggestionId || replyMessage.isSuggestion) {
      teeupSocket.emit("comment", {
        text: message,
        suggestionId: replyMessage.suggestionId
          ? +replyMessage.suggestionId
          : replyMessage.id,
      })
    } else {
      teeupSocket.emit("reply", { text: message, messageId: replyMessage.id })
    }
  } else {
    teeupSocket.emit(
      // 'message:teeup',
      "teeup:message",
      { text: message, mentionIds },
      messageTypeId
    )
  }
}

export const sendNudge = ({
  message,
  subTypeId,
  messageTypeId,
  mentionIds,
  targetId,
  teeupId,
}) => {
  // const state = store.getState()
  // const { userStatus, teeupId } = selectActiveTeeup(
  //     state
  // )
  // const userId = selectUserId(state)
  // let messageObj = {
  //     conversationTypeId: conversationTypeIds.nudge,
  //     senderId: userId,
  //     userId,
  //     message,
  //     messageTypeId,
  //     mentionIds,
  //     time: Date.now(),
  // }

  // const formattedMessage = formatMessage(messageObj)
  // !!formattedMessage &&
  //     'id' in formattedMessage &&
  //     delete formattedMessage.id
  // !!formattedMessage &&
  //     userStatus !== teeupUserStatusKeys.invited &&
  //     addMessage({
  //         message: formattedMessage,
  //         teeupId,
  //     })
  // "message",

  teeupSocket.emit(
    "teeup:message",
    { text: message, subTypeId, mentionIds, targetId, messageTypeId },
    { teeupId }
    // messageTypeId
  )
}

export const promoteToGameplan = ({ suggestionId, type }) => {
  let typeId = gameplanTypes[type]
  teeupSocket.emit("upgradeToGamePlan", {
    id: suggestionId,
    typeId,
  })
}

export const removeFromGameplan = ({ suggestionId, activeTeeupStatus }) => {
  const userId = selectUserId(store.getState())
  teeupSocket.emit("suggestion:removeFromGamePlan", {
    suggestionId,
  })

  if (activeTeeupStatus && activeTeeupStatus === "planning") return

  updateTeeupStatus(userId, {
    value: "rgb(255, 111, 37)",
    label: "planning",
  })
}

export const toggleWithdraw = ({ suggestionId, isWithdrawn }) => {
  teeupSocket.emit("suggestions/withdraw", { suggestionId, isWithdrawn })
}

export const pinUnpinMessage = ({ isPinned, messageId }) => {
  teeupSocket.emit("pinboard", {
    isPinned,
    messageId,
  })
}

export const pinUnpinSuggestion = ({ isPinned, suggestionId }) => {
  teeupSocket.emit("pinboard:suggestion", { isPinned, suggestionId })
}

export const updateTeeup = ({ teeup }) => {
  teeupSocket.emit("teeup:update", teeup)
}
export const addReaction = ({ suggestionId, reactionId }) => {
  // "suggestion:react"
  teeupSocket.emit("reaction", {
    reactionId,
    suggestionId,
  })
}

export const dropoutTeeup = (teeup, userId) => {
  // if (!teeup.isArchived) {
  //     store.dispatch({
  //         type: actionTypes.TEEUP_UPDATE_ARCHIVE_STATUS,
  //         payload: {
  //             userId,
  //             teeup: { ...teeup, isArchived: true },
  //             status: true,
  //             withToast: false,
  //             isDroppedOut: true,
  //         },
  //     })
  // }
  // updateUserStatus({
  //     teeupId: teeup.id,
  //     statusId: teeupUserStatusPriorities.droppedout,
  // })
  teeupSocket.emit("droppedUser")
  store.dispatch(fetchRequests(userId))
}

export const deleteUser = userId => {
  teeupSocket.emit("teeup:kickUser", { userId })
}

export const createSuggetion = (suggestion, initComment = null) => {
  const { teeupId } = suggestion
  let formatted = formatSuggestionOut(suggestion)

  if (teeupId) {
    teeupSocket.emit("suggestion", {
      ...formatted,
      comment: initComment,
    })
  }
}

// {
//     "type": "when",
//     "startDate": "2022-06-08T11:18:04+02:00",
//     "userId": 2469,
//     "teeupId": 4848,
//     "endDate": "2022-06-08T12:18:04+02:00",
//     "value": null,
//     "value2": null,
//     "isCustomDate": false,
//     "isCustomTime": false,
//     "isTimeZoneEnabled": false,
//     "selected": false,
//     "optionId": 9671,
//     "index": 0
// }
// {
//     "suggestion": {
//         "type": "when",
//         "startDate": "2022-11-11T05:00:40.000Z",
//         "userId": 2469,
//         "teeupId": 4848,
//         "endDate": "2022-11-11T07:00:40+01:00",
//         "value": null,
//         "value2": null,
//         "isCustomDate": false,
//         "isCustomTime": false,
//         "isTimeZoneEnabled": false,
//         "selected": false,
//         "optionId": 9671,
//         "index": 0
//     },
//     "formatted": {
//         "isSelected": false,
//         "isDecided": false,
//         "gameplanOptionId": 9671,
//         "customDate": "",
//         "customTime": "",
//         "startDate": "2022-11-11T06:00:00+01:00",
//         "endDate": "2022-11-11T07:00:00+01:00",
//         "isTimeZoneEnabled": false,
//         "isCustomDate": false,
//         "isCustomTime": false
//     }
// }
// created this just to fix the spelling without changing the old usage through out the app
export const createSuggestion = createSuggetion

export const createSuggestions = suggestions => {
  // let teeupId = ""
  // let formatted = suggestions

  // if (Array.isArray(suggestions) && suggestions.length) {
  //   teeupId = suggestions[0].teeupId
  //   // formatted = suggestions.map(s => formatSuggestionOut(s))
  // }
  teeupSocket.emit("suggestions", suggestions)
}

export const replyToNudge = async ({ typeId, message, messageId }) => {
  // await updateTeeupUserStatusToJoined()
  teeupSocket.emit("reply", { text: message, messageId, typeId })
}

export const changeUserTimeZone = async timezone => {
  teeupListSocket.emit("profile:update", { timezone })
}

// Copied from mobile, might be refactored in the future
let emitInviteesInterval = null
export const inviteToTeeup = invited => {
  if (emitInviteesInterval) {
    clearInterval(emitInviteesInterval)
  }
  emitInviteesInterval = setInterval(() => {
    if (teeupSocket) {
      clearInterval(emitInviteesInterval)
      teeupSocket.emit("invite", { invited })
    }
  }, 300)
}

export const markAsDecided = (
  suggestionId,
  isDecided
  // teeupId,
  // callbackAfterDecided,
  // forceUpdate,
) => {
  // checkIfSuggestionOnCorrectPlace(
  //     () => {
  useConversationStore
    .getState()
    .setIsSettingSuggestionToDecidedInProgress(true)
  teeupSocket.emit("decide", { suggestionId, isDecided })
  //         if (callbackAfterDecided) {
  //             callbackAfterDecided()
  //         }
  //         const whenInGameplan = selectChosenGameplanByType('when')(
  //             globalGetState()
  //         )
  //         const teeupSuggestions = selectTeeupSuggestions(globalGetState())
  //         const suggestion = teeupSuggestions[suggestionId]
  //         const isSuggestionWithExactDateTime = Boolean(suggestion.startDate)
  //         if (
  //             isDecided &&
  //             !isSuggestionWithExactDateTime &&
  //             suggestion?.type === GAMEPLAN_OPTIONS.when
  //         ) {
  //             globalDispatch(
  //                 teeupActions.changeIsInexactDateTimeInDecidedShown(true)
  //             )
  //         } else if (
  //             !whenInGameplan ||
  //             (!isSuggestionWithExactDateTime &&
  //                 suggestion?.type === GAMEPLAN_OPTIONS.when)
  //         ) {
  //             globalDispatch(
  //                 teeupActions.changeIsInexactDateTimeInDecidedShown(false)
  //             )
  //         }
  //     },
  //     { suggestionId, teeupId, forceUpdate },
  //     'selected'
  // )
}

const updateConversationRedDot = (tab, value) => {
  const state = useConversationStore.getState()

  state.setRedDot(tab, value)
}
