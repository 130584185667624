import React from "react"

import "./index.scss"

const Pinboard = ({ activePlace }) => {
  return (
    <div className="suggest-pinboard-online">
      {activePlace && activePlace.firstRow ? (
        activePlace.firstRow
      ) : (
        <p className="suggest-pinboard-online__placeholder">Place Name</p>
      )}
      <div>
        {activePlace && activePlace.secondRow ? (
          activePlace.secondRow.length > 25 ? (
            activePlace.secondRow.slice(0, 30) + "..."
          ) : (
            activePlace.secondRow
          )
        ) : (
          <p className="suggest-pinboard-online__placeholder">Place Details</p>
        )}
      </div>
    </div>
  )
}

export default Pinboard
