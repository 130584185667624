import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const List = styled.ul`
  width: 55%;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  margin: 0 auto;
  padding-bottom: 120px;
  height: 400px;
  overflow-y: auto;

  & > div {
    width: 100%;
  }

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
    padding-bottom: 100px;
  }
`
