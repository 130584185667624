import React from "react"

import SuccessIcon from "@assets/images/success.svg"
import { Subtitle } from "@assets/styles/typographyStyles"
import { NewModal } from "@components/NewModal"

import { MessageWrapper } from "./molecules"

export const PasswordChangedModal = ({ onClose }) => {
  const message = "Your password has been changed successfully."

  return (
    <NewModal
      isOpen
      title="Password changed successfully"
      closeText="Close"
      onClose={onClose}>
      <MessageWrapper>
        <img src={SuccessIcon} alt="error" />
        <Subtitle
          style={{ color: "#767676", wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </MessageWrapper>
    </NewModal>
  )
}
