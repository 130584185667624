import React from "react"

import { Subtitle, FontWeight } from "assets/styles/typographyStyles"

export const Label = ({ name, label, isBold }) => (
  <label className="input-el__label" htmlFor={name}>
    <Subtitle
      style={{ fontWeight: isBold ? FontWeight.semibold : FontWeight.medium }}>
      {label}
    </Subtitle>
  </label>
)
