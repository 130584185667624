import React from "react"

import PropTypes from "prop-types"

import { ErrorMessage } from "../molecules"
import "./auth0ErrorLogin.scss"

export const Auth0ErrorLogin = ({ email }) => (
  <>
    <ErrorMessage>
      Looks like you already have and account with {email}.
    </ErrorMessage>
    <ErrorMessage>Enter the password to log in.</ErrorMessage>
  </>
)

Auth0ErrorLogin.propTypes = {
  email: PropTypes.string,
}
