import React from "react"

import { images } from "@utils/imageUtils"

import search from "../../../assets/images/search.svg"
// import { Image } from "react-native";

const ViewByteeupsSearchBar = ({
  teeupFilter,
  setTeeupFilter,
  setToggleFilter,
  toggleFilter,
  fromCreateTeeup,
}) => {
  return (
    <div className="searchbar__container">
      <div className="searchbar__wrapper">
        <div className="searchbar__text">
          <img
            className="searchbar__searchIcon"
            src={search}
            style={{ marginRight: 10 }}
          />
          <input
            type="text"
            value={teeupFilter}
            onChange={e => setTeeupFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
        {/* <Image
              onClick={()=>setToggleFilter(!toggleFilter)}
              source={images.filter}
              style={{
                width: 20,
                height: 19,
                margin: '1 0 0 7.9',
                objectFit: 'contain'
              }}      
          /> */}
        <img
          onClick={() => setToggleFilter(!toggleFilter)}
          src={images.filter}
          style={{
            width: 20,
            height: 19,
            margin: "1px 0px 0px 8px",
            objectFit: "contain",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  )
}

export default ViewByteeupsSearchBar
