import Autocomplete from "@mui/material/Autocomplete"

const Search: typeof Autocomplete = props => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      popupIcon={null}
      className="custom-autocomplete"
      ListboxProps={{
        className: "suggest-where-search-list",
      }}
      {...props}
    />
  )
}

export default Search
