import { Fragment, useContext } from "react"

import { TEEUP_PERMISSIONS_SETTINGS } from "@constants/teeups"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { saveSettingsValue } from "@utils/teeupUtils"

import { SettingsRow } from "../molecules"

import { GroupName } from "./permissionsScreenStyles"

export const PermissionsScreen = () => {
  const { teeup } = useContext(NewTeeupPageContext)

  const handleToggle = (val, key) => {
    saveSettingsValue({
      teeUpId: teeup.id,
      settings: teeup.settings,
      key,
      val,
    })
  }

  return (
    <>
      {Object.keys(TEEUP_PERMISSIONS_SETTINGS).map(groupKey => (
        <Fragment key={groupKey}>
          <GroupName>{TEEUP_PERMISSIONS_SETTINGS[groupKey].title}</GroupName>

          {TEEUP_PERMISSIONS_SETTINGS[groupKey].items.map(item => (
            <Fragment key={item.key}>
              {item.type ? (
                <SettingsRow title={item.title} type={item.type} />
              ) : (
                <SettingsRow
                  isToggle
                  title={item.title}
                  description={item.description}
                  value={teeup.settings[item.key]}
                  onToggle={val => handleToggle(val, item.key)}
                />
              )}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </>
  )
}
