import React from "react"

import PropTypes from "prop-types"
import { View, Text, TouchableOpacity, Platform } from "react-native"

import { EVENT_TYPES } from "@utils/calendarUtils"

import { DayOnMonthCalendarView as styles } from "./styles"

const defaultHitSlop = { top: 0, bottom: 0, left: 0, right: 0 }

const Event = ({ event, conflictsDetected }) => {
  const hasConflict = conflictsDetected.includes(event.id)
  return (
    <Text
      style={[styles.eventText, hasConflict && styles.conflict]}
      numberOfLines={1}
      ellipsizeMode={"clip"}>
      <Text
        style={[
          styles.dot,
          event.type === EVENT_TYPES.teeup && styles.teeupDot,
          event.type === EVENT_TYPES.calendar && styles.eventDot,
          hasConflict && styles.conflict,
        ]}>{`・`}</Text>
      {event.name}
      {"\n"}
    </Text>
  )
}

Event.propTypes = {
  event: PropTypes.object,
  conflictsDetected: PropTypes.array,
}

const DayOnMonthCalendarView = ({
  date,
  eventsList = [],
  conflictsDetected = [],
  isLastInRow,
  isThisMonth,
  isToday,
  onPress,
  isDateDisabled,
  isSelectedTimeForSuggestWhen,
  hasNewSuggestionProps,
  monthCellWidth,
}) => {
  const isSelectBorder = hasNewSuggestionProps && isSelectedTimeForSuggestWhen
  const isDisabledColor = hasNewSuggestionProps && isDateDisabled
  return (
    <TouchableOpacity
      style={[
        styles.container,
        isLastInRow && styles.lastBlockBorder,
        isSelectBorder && styles.selectedDayBlock,
      ]}
      onPress={onPress}
      // hitSlop={Units.hitSlop(defaultHitSlop)}
    >
      <View
        style={[
          styles.dateContainer,
          isToday && isThisMonth && styles.todayDayContainer,
        ]}>
        <Text
          style={[
            styles.dayText,
            isThisMonth && styles.thisMonthDayText,
            isToday && isThisMonth && styles.todayDayText,
            isDisabledColor && styles.disabledText,
          ]}>
          {date.format("D")}
        </Text>
      </View>
      <View style={[styles.eventsContainer, { maxWidth: monthCellWidth }]}>
        {eventsList.map(event => (
          <Text
            key={event.id}
            style={styles.eventTextConatiner}
            numberOfLines={Platform.OS === "ios" ? null : 4}
            ellipsizeMode={"tail"}>
            <Event event={event} conflictsDetected={conflictsDetected} />
          </Text>
        ))}
      </View>
    </TouchableOpacity>
  )
}

DayOnMonthCalendarView.propTypes = {
  date: PropTypes.object,
  eventsList: PropTypes.array,
  conflictsDetected: PropTypes.array,
  isLastInRow: PropTypes.bool,
  isThisMonth: PropTypes.bool,
  isToday: PropTypes.bool,
  isSelectedTimeForSuggestWhen: PropTypes.bool,
  hasNewSuggestionProps: PropTypes.bool,
  isDateDisabled: PropTypes.bool,
  onPress: PropTypes.func,
}

export default DayOnMonthCalendarView
