import { Suggestion } from "types/suggestions"
import dayjs from "utils/dayjs"

import { stringToDateFormat } from "../dateUtils"

import formatCustomTimeWithTimezone from "./formatCustomTimeWithTimezone"
import getFormattedTime from "./getFormattedTime"
import getSuggestionWhenTitle from "./getSuggestionWhenTitle"
import parseCustomTimeWithTimezone from "./parseCustomTimeWithTimezone"

type ReturnType = {
  title: string
  empty?: boolean
  subtitle: string
}

const getWhenSuggestionDetails = (
  suggestion: Suggestion,
  isRemake = false,
  participants?: any
): ReturnType => {
  const createdBy = suggestion?.createdBy || suggestion?.createdBy
  const format =
    isRemake && dayjs().isBefore(suggestion.startDate, "year")
      ? "ddd MMM D, YYYY"
      : "ddd, MMM D"
  const suggestionTitle = getSuggestionWhenTitle(suggestion, format)

  const isCustomTime = suggestion?.when?.endType === "CustomTime"
  const isCustomDate = suggestion?.when?.type === "CustomDateTime"

  const endDate = suggestion?.when?.endDate
  const startDate = suggestion?.when?.startDate || suggestion?.when?.value

  if (!createdBy) {
    console.log("here1")
    return !suggestion.startDate && !suggestion.endDate
      ? {
          title: "",
          empty: true,
          subtitle: "",
        }
      : {
          title: suggestionTitle,
          subtitle: getFormattedTime(suggestion),
        }
  }

  const startFormattedDate = stringToDateFormat(
    suggestion?.startDate || startDate,
    format
  )

  if (
    (suggestion.customDate && suggestion.customTime) ||
    (isCustomTime && isCustomDate)
  ) {
    console.log("here1")

    return {
      title: formatCustomTimeWithTimezone(
        format,
        suggestion?.endDate || endDate,
        suggestion.customDate
      ),
      subtitle: parseCustomTimeWithTimezone(
        createdBy,
        suggestion.customTime,
        participants
      ),
    }
  }

  if (
    (suggestion.customDate && !suggestion.customTime) ||
    (isCustomDate && !isCustomTime)
  ) {
    console.log("here1")
    return {
      title: formatCustomTimeWithTimezone(
        format,
        suggestion?.endDate || endDate,
        suggestion.customDate
      ),
      subtitle: "",
    }
  }

  if (
    (suggestion.customTime && !suggestion.customDate) ||
    (isCustomTime && !isCustomDate)
  ) {
    console.log("here1")
    return {
      title: startFormattedDate,
      subtitle: parseCustomTimeWithTimezone(
        createdBy,
        suggestion.customTime,
        participants
      ),
    }
  }

  if (!suggestion?.customDate && !suggestion?.customTime) {
    console.log("here1")
    return {
      title: startFormattedDate,
      subtitle:
        suggestion.isCustomDate && !suggestion.customTime
          ? ""
          : getFormattedTime(suggestion),
      empty: true,
    }
  }

  return {
    title: "Other",
    subtitle: "",
  }
}

export default getWhenSuggestionDetails
