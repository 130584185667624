import { useCallback, useRef, useState } from "react"

import moment from "moment"
import OpenAI from "openai"
import { TextContentBlock } from "openai/resources/beta/threads/index.mjs"

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY,
  dangerouslyAllowBrowser: true,
})

const createTeeupAssistantKey =
  process.env.REACT_APP_OPEN_AI_CREATE_TEEUP_ASSISTANT_ID || ""
const editTeeupAssistantKey =
  process.env.REACT_APP_OPEN_AI_EDIT_TEEUP_ASSISTANT_ID || ""

const getAiRunCost = (inputTokens: number, completionsTokens: number) => {
  const inputCost = (inputTokens * 0.0005) / 1000
  const completionsCost = (completionsTokens * 0.0015) / 1000
  return inputCost + completionsCost
}

type TMessage = {
  role: "user" | "assistant"
  message: string
  id: number
}

interface IToolsOutput {
  tool_call_id: string
  output: string
}

type TPricing = {
  promptTokens: number
  completionTokens: number
  tokens: number
  cost: number
}

const PRICING_DEFAULT = {
  tokens: 0,
  cost: 0,
  promptTokens: 0,
  completionTokens: 0,
}

// const defaultMessage = {
//   role: 'assistant' as 'assistant',
//   message: 'Hello! I am your assistant. How can I help you?',
//   id: 0
// }

export type TTime = {
  startDate: string
  endDate: string
  duration?: number
}

const DEFAULT_MESSAGE = {
  role: "assistant" as "assistant",
  message: "What would you like to coordinate?",
  id: 0,
}

export const useOpenAI = ({ type }: { type: "create" | "edit" }) => {
  const checkStatusRef = useRef<ReturnType<typeof setInterval> | number>(0)
  const [title, setTitle] = useState<string>("")
  const [chatMessages, setChatMessages] = useState<TMessage[]>([
    DEFAULT_MESSAGE,
  ])
  const [timeSpots, setTimeSpots] = useState<TTime[]>([])
  const [participantsNames, setParticipantsNames] = useState<string[]>([])
  const [time, setTime] = useState<TTime>({
    startDate: "",
    endDate: "",
    duration: 0,
  })
  const [threadData, setThreadData] = useState<{
    threadId: string
    runId: string
  } | null>(null)
  const [isGenerating, setIsGenerating] = useState(false)
  const [pricing, setPricing] = useState<TPricing>(PRICING_DEFAULT)

  const assistantId =
    type === "create" ? createTeeupAssistantKey : editTeeupAssistantKey

  const createTeeup = async (date: string, duration: string) => {
    const startDate = moment(date).utc().format()
    const endDate = moment(date).add(duration, "minutes").format()

    setTime({
      startDate,
      endDate,
      duration: Number(duration),
    })

    return {
      startDate,
      endDate,
    }
  }

  const getParticipantsNames = useCallback(
    (names: string[], similarNames: string[]) => {
      const isNamesExist = Array.isArray(names) && names.length
      const isSimilarNamesExist =
        Array.isArray(similarNames) && similarNames.length

      const namesList = isNamesExist
        ? names?.map(name => name.toLowerCase())
        : []
      const similarNamesList = isSimilarNamesExist
        ? similarNames?.map(name => name.toLowerCase())
        : []

      setParticipantsNames([...namesList, ...similarNamesList])
    },
    []
  )

  const getTitle = useCallback((title: string) => {
    setTitle(title)
  }, [])

  const createTimeSpots = (spots: { date: string; duration: string }[]) => {
    const timeSpots = spots.map(spot => {
      const startDate = moment(spot.date).utc().format()
      const endDate = moment(spot.date)
        .utc()
        .add(spot.duration, "minutes")
        .format()

      return {
        startDate,
        endDate,
        duration: Number(spot.duration),
      }
    })

    setTimeSpots(timeSpots)

    // setTimeSpots((prev) => {
    //   const result = [...prev, ...timeSpots];
    //   return result.filter((spot, index, self) => {
    //     return index === self.findIndex((t) => (
    //       t.startDate === spot.startDate
    //     ))
    //   });
    // });
  }

  const checkStatusAndPrintMessage = useCallback(
    async (threadId: string, runId: string) => {
      const runStatus = await openai.beta.threads.runs.retrieve(threadId, runId)

      if (runStatus.status === "requires_action") {
        const requiredActions =
          runStatus.required_action?.submit_tool_outputs.tool_calls

        const toolsOutput = requiredActions?.map(action => {
          const funcName = action.function.name
          const funcArgs = JSON.parse(action.function.arguments)

          if (funcName === "getTimeSuggestions") {
            createTimeSpots(funcArgs.suggestions)

            return {
              tool_call_id: action.id,
              output: JSON.stringify({ message: "Title received" }),
            }
          }

          if (funcName === "getTitle") {
            getTitle(funcArgs.title)
            return {
              tool_call_id: action.id,
              output: JSON.stringify({ message: "Title received" }),
            }
          }

          if (funcName === "getParticipantsNames") {
            getParticipantsNames(funcArgs.names, funcArgs?.similarNames)
            return {
              tool_call_id: action.id,
              output: JSON.stringify({
                message: "Participants names received",
              }),
            }
          }

          if (funcName === "createTeeup") {
            const res = createTeeup(funcArgs.date, funcArgs.duration)

            return {
              tool_call_id: action.id,
              output: JSON.stringify(res),
            }
          }
        }) as IToolsOutput[]

        await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
          tool_outputs: toolsOutput || [],
        })
      }

      if (runStatus.status === "completed") {
        if (runStatus.usage) {
          setPricing({
            promptTokens: runStatus.usage.prompt_tokens,
            completionTokens: runStatus.usage.completion_tokens,
            tokens: runStatus.usage.total_tokens,
            cost: getAiRunCost(
              runStatus.usage.prompt_tokens,
              runStatus.usage?.completion_tokens
            ),
          })
        }

        clearInterval(checkStatusRef.current)
        const messages = await openai.beta.threads.messages.list(threadId)
        const lastMessage: TextContentBlock = messages.data[0]
          .content[0] as TextContentBlock

        setChatMessages(prev => [
          ...prev,
          {
            role: "assistant",
            message: lastMessage.text.value,
            id: prev.length + 1,
          },
        ])
        setIsGenerating(false)
      }
    },
    []
  )

  const assistantCreate = useCallback(async () => {
    const todayDate = moment(new Date()).utc().format("DD-MM-YYYY")
    const assistant = await openai.beta.assistants.retrieve(assistantId)
    const instructionsWithNewDate = assistant.instructions
      ?.split(".")
      .filter(item => !item.includes("today date"))
      .join(".")
      .concat(` today date is ${todayDate}.`)

    const newAssistant = await openai.beta.assistants.update(assistant.id, {
      instructions: instructionsWithNewDate,
    })

    return newAssistant
  }, [assistantId])

  const runAssistant = useCallback(
    async (threadId: string) => {
      const assistant = await assistantCreate()

      const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistant.id,
      })

      checkStatusRef.current = setInterval(() => {
        checkStatusAndPrintMessage(threadId, run.id)
      }, 3000)
    },
    [checkStatusAndPrintMessage, assistantCreate]
  )

  const continueThread = useCallback(
    async (threadId: string, message: string) => {
      await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: message,
      })

      runAssistant(threadId)
    },
    [runAssistant]
  )

  const startThread = useCallback(
    async (message: string) => {
      setIsGenerating(true)
      const isShouldCreateThread =
        chatMessages.filter(msg => msg.role === "assistant").length === 1

      if (assistantId && isShouldCreateThread) {
        const assistant = await assistantCreate()
        const thread = await openai.beta.threads.create()

        await openai.beta.threads.messages.create(thread.id, {
          role: "user",
          content: message,
        })

        const run = await openai.beta.threads.runs.create(thread.id, {
          assistant_id: assistant.id,
        })

        setThreadData({ threadId: thread.id, runId: run.id })
        checkStatusRef.current = setInterval(() => {
          checkStatusAndPrintMessage(thread.id, run.id)
        }, 3000)

        return
      }

      if (threadData?.threadId) {
        continueThread(threadData?.threadId, message)
      }
    },
    [
      checkStatusAndPrintMessage,
      threadData?.threadId,
      assistantCreate,
      continueThread,
      chatMessages,
      assistantId,
    ]
  )

  return {
    time,
    pricing,
    title,
    timeSpots,
    threadData,
    chatMessages,
    isGenerating,
    participantsNames,
    continueThread,
    setChatMessages,
    assistantCall: startThread,
  }
}
