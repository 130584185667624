import React from "react"

import PropTypes from "prop-types"

import { NewModal } from "components"
import { IS_SHARE_POPUP_KEY } from "constants/global"

import { Text, Image } from "./shareSuggestionsPopupStyles"

export const ShareSuggestionsPopup = ({ setIsSharePopup }) => {
  const closeHandle = () => {
    setIsSharePopup(false)
    sessionStorage.setItem(IS_SHARE_POPUP_KEY, "true")
  }

  return (
    <NewModal
      isOpen
      isBlackBtn
      isHeader={false}
      title="Share your Suggestions"
      confirmText="Close"
      onClose={closeHandle}
      onConfirm={closeHandle}>
      <Text>
        Select and drag a time-frame on the calendar, then click “Suggest” to
        confirm your selection.
      </Text>

      <Image name="suggest-screen" />
    </NewModal>
  )
}

ShareSuggestionsPopup.propTypes = {
  setIsSharePopup: PropTypes.func,
}
