import React, { useEffect, useRef } from "react"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"
import useOnlineTabState from "../Hooks/useOnlineTabState"

import FooterButtons from "./FooterButtons"
import MeetingApp from "./MeetingApp"
import MeetingDetails from "./MeetingDetails"
import Pinboard from "./Pinboard"

import "./index.scss"

const OnlineTab = ({ close }) => {
  const { activeApp, setActiveApp } = useOnlineTabState()
  const meetingDetailsRef = useRef()
  const handleError = () => {
    meetingDetailsRef.current.isLinkValid(false)
  }

  const handleSuccess = () => {
    meetingDetailsRef.current.isLinkValid(true)
  }

  useEffect(() => {
    return () => {
      setActiveApp(null)
    }
  }, [])

  return (
    <>
      <SuggestModalSubHeader>
        <Pinboard suggestInfo={activeApp} />
      </SuggestModalSubHeader>
      <SuggestModalContent>
        <MeetingApp />
        {activeApp && <MeetingDetails ref={meetingDetailsRef} />}
      </SuggestModalContent>
      <SuggestModalFooter>
        {activeApp && (
          <FooterButtons
            suggestInfo={activeApp}
            close={close}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        )}
      </SuggestModalFooter>
    </>
  )
}

export default OnlineTab
