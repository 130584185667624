import {
  TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS,
  TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE,
  TOP_LEVEL_SET_IS_LOGIN_IN_PROGRESS,
  TOP_LEVEL_SET_IS_REGISTER_CHECK_IDENTITY,
} from "./types"

export const TopLevelReducer = (state, { type, payload }) => {
  switch (type) {
    case TOP_LEVEL_SET_IS_REGISTRATION_IN_PROGRESS:
      return {
        ...state,
        isRegistrationInProgress: payload,
      }
    case TOP_LEVEL_SET_SOCIAL_REGISTRATION_TYPE:
      return {
        ...state,
        socialRegistrationType: payload,
      }
    case TOP_LEVEL_SET_IS_LOGIN_IN_PROGRESS:
      return {
        ...state,
        isLoginInProgress: payload,
      }
    case TOP_LEVEL_SET_IS_REGISTER_CHECK_IDENTITY:
      return {
        ...state,
        isRegisterCheckIdentity: payload,
      }
    default: {
      return state
    }
  }
}
