import { useMemo } from "react"

export const useGameplans = ({ teeup, type, isNewest }) => {
  const sortSuggestions = suggestions => {
    return suggestions?.sort((a, b) => {
      const comparisonResult = isNewest ? b.id - a.id : a.id - b.id

      if (a.isWithdrawn === b.isWithdrawn) {
        return comparisonResult
      } else {
        return a.isWithdrawn ? 1 : -1
      }
    })
  }

  const getDecidedSuggestion = suggestions => {
    return suggestions?.find(
      ({ selected, isSelected, decided, isDecided }) =>
        selected || isSelected || decided || isDecided
    )
  }

  const plan = useMemo(() => {
    return teeup.gameplans?.find(plan => plan.type === type)
  }, [teeup, type])

  return useMemo(
    () => ({
      suggestions: sortSuggestions(plan?.suggestions) || [],
      decidedSuggestion: getDecidedSuggestion(plan?.suggestions),
    }),
    [plan, isNewest]
  )
}
