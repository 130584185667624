import React from "react"

import PropTypes from "prop-types"

import { Wrapper, Tab, TabText } from "./menuTabsStyles"

export const MenuTabs = ({
  tabs,
  tabsText,
  isSideBorder,
  activeTab,
  setActiveTab,
}) => (
  <Wrapper $isSideBorder={isSideBorder}>
    {tabs.map(tab => {
      const isActiveTab = tab === activeTab

      return (
        <Tab
          $isActive={isActiveTab}
          onClick={() => setActiveTab(tab)}
          key={tab}>
          <TabText $isActive={isActiveTab}>{tabsText[tab]}</TabText>
        </Tab>
      )
    })}
  </Wrapper>
)

MenuTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  tabsText: PropTypes.object,
  isSideBorder: PropTypes.bool,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
}
