import { create } from "zustand"

import { DiscussionTabs } from "types/discussionCard"

interface ConversationStore {
  chatMessage: string | null
  setChatMessage: (message: string) => void

  conversationRedDot: Map<DiscussionTabs, boolean>
  setRedDot: (id: DiscussionTabs, value: boolean) => void

  isSettingSuggestionToDecidedInProgress: boolean
  setIsSettingSuggestionToDecidedInProgress: (
    isSettingSuggestionToDecidedInProgress: boolean
  ) => void
}

export const useConversationStore = create<ConversationStore>()(set => ({
  chatMessage: null,
  setChatMessage: message => set({ chatMessage: message }),
  conversationRedDot: new Map(),
  setRedDot: (id, value) => {
    set(state => {
      const newMap = new Map(state.conversationRedDot)
      newMap.set(id, value)
      return { conversationRedDot: newMap }
    })
  },
  // Preventing multiple requests to the server on decide button click
  isSettingSuggestionToDecidedInProgress: false,
  setIsSettingSuggestionToDecidedInProgress:
    isSettingSuggestionToDecidedInProgress =>
      set({
        isSettingSuggestionToDecidedInProgress,
      }),
}))
