import React from "react"

import { SentToText } from "@components/TeeupPreviewContent/teeupPreviewContentStyles"

const SentBy = ({
  isSentToShown,
  sentTo,
}: {
  isSentToShown: boolean
  sentTo?: string
}) => {
  return (
    <div>
      {isSentToShown && !!sentTo && (
        <SentToText>
          Invitation sent to <b>{sentTo}</b>
        </SentToText>
      )}
    </div>
  )
}

export default SentBy
