import { createSelector } from "reselect"

export const selectTeeups = state => state.createTeeup

export const selectIsCreateTeeupOpen = createSelector(
  selectTeeups,
  createTeeup => createTeeup.isCreateTeeupOpen
)

export const selectCreateTeeupScreenType = createSelector(
  selectTeeups,
  createTeeup => createTeeup.screenType
)

export const selectCreateTeeupSettings = createSelector(
  selectTeeups,
  createTeeup => createTeeup.settings
)

export const selectCreateTeeupIsInviteesError = createSelector(
  selectTeeups,
  createTeeup => createTeeup.isInviteesError
)

export const getCreateTeeupState = createSelector(
  selectTeeups,
  createTeeup => createTeeup.isModalOpen || null
)

export const getDraftTeeup = createSelector(
  selectTeeups,
  createTeeup => createTeeup.draftTeeup
)

export const selectGamePlanWhenFromCreate = createSelector(
  selectTeeups,
  createTeeup => createTeeup.gameplanWhen
)

export const selectGamePlanWhereFromCreate = createSelector(
  selectTeeups,
  createTeeup => createTeeup.gameplanWhere
)

export const selectActiveGamePlanTab = createSelector(
  selectTeeups,
  createTeeup => createTeeup.activeGamePlanTab
)
