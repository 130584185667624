export const peopleStatus = [
  {
    status: "joined",
    iconName: "invited",
    color: "rgb(127, 127, 127)",
    text: "Joined",
    id: 5,
  },
  {
    status: "invited",
    iconName: "invited",
    color: "rgb(157, 157, 157)",
    text: "Invited",
    id: 1,
  },
  {
    status: "going",
    iconName: "going",
    color: "#00c5ae",
    text: "I'm going",
    id: 2,
  },
  {
    status: "mightgo",
    iconName: "mightgo",
    color: "rgb(255, 111, 37)",
    text: "Might go",
    id: 3,
  },
  {
    status: "runningLate",
    iconName: "runningLate",
    color: "rgb(255, 111, 37)",
    text: "Running late",
    id: 17,
  },
  {
    status: "arrived",
    iconName: "arrived",
    color: "rgb(0, 188, 246)",
    text: "I'm here",
    id: 7,
  },
  {
    status: "onmyway",
    iconName: "onmyway",
    color: "rgb(82, 39, 166)",
    text: "On my way",
    id: 6,
  },
  {
    status: "notgoing",
    iconName: "notgoing",
    color: "rgb(245, 45, 34)",
    text: "Not going",
    id: 4,
  },
  {
    status: "interested",
    iconName: "new-interested",
    color: "rgb(245, 45, 34)",
    text: "Interested",
    id: 20,
  },
  {
    status: "notInterested",
    iconName: "not-interested",
    color: "#00c5ae",
    text: "Not Interested",
    id: 21,
  },
]

export const UNKNOWN_TIMEZONE = "Unknown"
export const getParticipantStatus = status => {
  const currentStatus = peopleStatus.find(
    item => item.status === status || item.id === status
  )
  return {
    iconName: currentStatus?.iconName || "invited",
    color: currentStatus?.color || "rgb(127, 127, 127)",
  }
}

export const getAttendanceItems = () => {
  const necessaryStatuses = new Set([
    "going",
    "mightgo",
    "runningLate",
    "arrived",
    "onmyway",
    "notgoing",
  ])
  const attendanceItems = peopleStatus.filter(item =>
    necessaryStatuses.has(item.status)
  )

  return attendanceItems
}

export const DETAILS_MODAL_CONTENT = {
  OPTIONS: "OPTIONS",
  SUGGESTIONS: "SUGGESTIONS",
  REACTIONS: "REACTIONS",
}

export const groupByTimezone = people => {
  const peopleByTimezone = people.reduce((group, person) => {
    const timezone = person?.timezone

    if (!timezone) {
      person.timezone = UNKNOWN_TIMEZONE
      group[UNKNOWN_TIMEZONE] = group[UNKNOWN_TIMEZONE] || []
      group[UNKNOWN_TIMEZONE].push(person)
      return group
    }

    group[timezone] = group[timezone] || []
    group[timezone].push(person)
    return group
  }, {})

  return peopleByTimezone
}

export const getParticipantStatusNonUser = statusId => {
  if (!statusId) return peopleStatus.find(item => item.status === "unknown")

  const currentStatus = peopleStatus.find(item => item.id === statusId)
  return currentStatus
}

export const sortByStatusToTop = (a, b, statusId) => {
  if (a.isOrganiser && !b.isOrganiser) {
    return -1
  }
  if (!a.isOrganiser && b.isOrganiser) {
    return 1
  }

  if (a.status === statusId && b.status !== statusId) {
    return -1
  }
  if (a.status !== statusId && b.status === statusId) {
    return 1
  }

  return 0
}
