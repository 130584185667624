import React, { memo } from "react"

import PropTypes from "prop-types"

import { formatStatusBarTime } from "@utils/dateUtils"

import TitleMessage from "../statusMessage/titleMessage"

const DurationUpdateMessage = ({ item, people }) => {
  const user = people[item.senderId]
  const startTime = formatStatusBarTime(item.startTime)
  const endTime = formatStatusBarTime(item.endTime)
  let text = `${user.username} set new availability from ${startTime} to ${endTime}`

  return (
    <TitleMessage
      text={text}
      item={item}
      senders={[user]}
      containerStyle={containerStyle}
    />
  )
}

DurationUpdateMessage.propTypes = {
  item: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
}

export default memo(DurationUpdateMessage)

const containerStyle = {
  paddingHotizontal: 8,
  paddingVertical: 8,
  marginHorizontal: 8,
}
