import React, { createContext, useContext, useReducer } from "react"

import { GeneralTimeReducer } from "./reducer"

const GeneralTimeStateContext = createContext({})
const GeneralTimeDispatchContext = createContext(undefined)

const GeneralTimeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GeneralTimeReducer, {
    daysSelected: [],
    quickSelect: null,
    time: null,
  })

  return (
    <GeneralTimeStateContext.Provider value={state}>
      <GeneralTimeDispatchContext.Provider value={dispatch}>
        {children}
      </GeneralTimeDispatchContext.Provider>
    </GeneralTimeStateContext.Provider>
  )
}

const useGeneralTimeState = () => {
  const context = useContext(GeneralTimeStateContext)

  if (context === undefined) {
    throw new Error(
      "useGeneralTimeState must be used within a GeneralTimeProvider"
    )
  }
  return context
}

const useGeneralTimeDispatch = () => {
  const context = useContext(GeneralTimeDispatchContext)

  if (context === undefined) {
    throw new Error(
      "useGeneralTimeDispatch must be used within a GeneralTimeProvider"
    )
  }
  return context
}

export { GeneralTimeProvider, useGeneralTimeState, useGeneralTimeDispatch }
