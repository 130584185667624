import { push } from "connected-react-router"
import _ from "lodash"

import { updateActiveTeeup } from "@actions/activeTeeupActions"
import { formatTeeupGameplanOptions } from "@utils/gamePlanUtils"
import { formatTeeupUsers } from "@utils/teeupUtils"

import actionTypes from "../actions/actionTypes"
import api from "../api"
import endpoints from "../config/endpoints"
import {
  teeupStatusKeys,
  teeupCategories,
  teeupUserStatusKeys,
} from "../config/enums"
import { userStatusTypes, teeupStatusTypes } from "../config/mappings"
import { store } from "../index"
import {
  selectAnyTeeupById,
  selectTeeupPeopleByTeeupId,
} from "../selectors/teeups"
import { selectUserId } from "../selectors/user"
import { joinTeeup } from "middlewares/backendMiddleware"

const manageTeeupsData = (dispatch, getState) => {
  Promise.all([fetchUserTeeupsUsers(), fetchUserTeeupsGO(getState)]).then(
    responses => {
      dispatch({
        type: actionTypes.GOT_TEEUPS_PARTS,
        payload: _.flattenDeep(responses),
      })
    }
  )
}

const setIsPaginationLoading = (dispatch, payload) => ({
  type: actionTypes.SET_IS_TEEUPS_PAGINATION_LOADING,
  payload,
})

export const setTeeupsPaginationError = payload => ({
  type: actionTypes.SET_TEEUPS_PAGINATION_ERROR,
  payload,
})

// TODO: set legacy state
export const fetchTeeups = (paginationParams, isNextPage = false) => {
  return (dispatch, getState) => {
    if (paginationParams) {
      const { page, nextPage } = paginationParams
      let updatedParams

      if (isNextPage && page !== nextPage) {
        updatedParams = {
          ...paginationParams,
          page: paginationParams.nextPage,
        }
      }
      if (page === nextPage) {
        return
      }

      return fetchPaginatedTeeups(dispatch, updatedParams || paginationParams)
        .then(() => manageTeeupsData(dispatch, getState))
        .catch(error => {
          console.error("error", error) // TODO: add error handling
        })
    }

    fetchTeeup(dispatch)
      .then(() => manageTeeupsData(dispatch, getState))
      .catch(error => {
        console.error("error", error) // TODO: add error handling
      })
  }
}

export const fetchPaginatedTeeups = (dispatch, params) => {
  dispatch(setIsPaginationLoading(dispatch, true))

  return api.client
    .get(endpoints.paginatedTeeups, { params })
    .then(({ data }) => {
      const { page, nextPage, total } = data
      const payload = separateActiveArchivedTeeups(data.teeups)

      dispatch({
        type: actionTypes.SET_TEEUPS,
        payload: { ...payload, page, nextPage, total },
      })
    })
    .catch(() => {
      dispatch(setTeeupsPaginationError("Oops, unable to load TeeUps"))
    })
    .finally(() => {
      dispatch(setIsPaginationLoading(dispatch, false))
    })
}

export const fetchTeeup = dispatch => {
  return api.client.get(endpoints.TEEUPS).then(response => {
    const { data } = response

    const payload = separateActiveArchivedTeeups(data.teeups)
    dispatch({ type: actionTypes.SET_TEEUPS, payload })
  })
}

export const fetchUserTeeupsUsers = () => {
  return api.client
    .get(endpoints.user.teeupsUsers)
    .then(response => {
      const { users } = response.data
      let usersArray = Object.keys(users).map(teeupId => {
        const teeupUsers = users[teeupId]
        return formatTeeupUsers(teeupUsers, Number(teeupId))
      })
      return usersArray
    })
    .catch(error => {
      console.log("fetchUserTeeupsUsers error ")
      console.error(error)
    })
}

export const fetchUserTeeupsGO = getState => {
  const userId = selectUserId(getState())

  return api.client
    .get(endpoints.user.teeupsGO)
    .then(response => {
      const gameplanOptionsRes = response.data.gameplanOptions
      let GOarray = []

      Object.keys(gameplanOptionsRes).forEach(teeupId => {
        const state = getState()
        const teeup = selectAnyTeeupById(Number(teeupId))(state)

        const teeupPeople = selectTeeupPeopleByTeeupId(teeupId)(state)
        const gameplanOptions = gameplanOptionsRes[teeupId]
        if (teeup) {
          GOarray.push(
            formatTeeupGameplanOptions(
              userId,
              teeup,
              gameplanOptions,
              teeupPeople
            )
          )
        }
      })

      return GOarray
    })
    .catch(error => {
      console.log("fetchUserTeeupsGO error")
      console.error(error)
    })
}

const separateActiveArchivedTeeups = responseTeeups => {
  const allResponseTeeups = [...responseTeeups]
  const archivedTeeups = []
  const removedTeeups = []
  const teeups = [] // active

  allResponseTeeups.forEach(responseTeeup => {
    let teeup = formatTeeup(responseTeeup)

    if (teeup.isArchived) {
      archivedTeeups.push(teeup)
    } else if (teeup.userStatus === "skipped") {
      removedTeeups.push(teeup)
    } else {
      teeups.push(teeup)
    }
  })

  const allTeeups = { teeups, archivedTeeups, removedTeeups }

  return allTeeups
}

export const formatTeeup = responseTeeup => {
  const {
    status,
    private: isPrivate,
    archived: isArchived,
    gameplanOptions: gameplans,
    userstatus,
    ...rest
  } = responseTeeup
  let teeup = {
    isPrivate,
    isArchived,
    invited: [],
    valid: true,
    status: status ? teeupStatusTypes[status] : teeupStatusKeys.planning,
    gameplans,
    updatedAt: rest?.updatedat,
    createdAt: rest?.createdat,
    ...rest,
  }
  if (userstatus) {
    teeup.userStatus = userStatusTypes[userstatus] || "going"
  }
  return teeup
}

export const updateTeeupStatus = async (teeupId, status) => {
  const url = endpoints.teeup.categories(teeupId, status)

  return api.client.put(url)
}

export const updateTeeupArchiveStatus = async teeupId => {
  const url = endpoints.teeup.categories(
    teeupId,
    teeupCategories.archivedTeeups
  )

  return api.client.put(url)
}

export const updateTeeupTrashStatus = async teeupId => {
  const url = endpoints.teeup.categories(teeupId, teeupCategories.removedTeeups)

  return api.client.put(url)
}

export const updateTeeupStatusState = (id, status) => ({
  type: actionTypes.UPDATE_TEEUP_STATUS,
  payload: { id, status },
})

export const updateTeeUpListStatus = payload => ({
  type: actionTypes.UPDATE_TEEUP_LIST_STATUS,
  payload,
})

export const updateTeeup = payload => ({
  type: actionTypes.UPDATE_TEEUP,
  payload,
})

export const updateTeeupInfo = payload => ({
  type: actionTypes.UPDATE_TEEUP_INFO,
  payload,
})

export const toggleOrganiser = payload => ({
  type: actionTypes.TOGGLE_ORGANISER,
  payload,
})

export const archiveTeeup = id => ({
  type: actionTypes.ARCHIVE_TEEUP,
  id,
})

export const toggleIsTeeupListVisible = () => ({
  type: actionTypes.TOGGLE_TEEUP_LIST,
})

export const createTeeup = teeup => ({
  type: actionTypes.CREATE_TEEUP,
  teeup,
})

export const moveTeeupToTrash = teeup => ({
  type: actionTypes.MOVE_TEEUP_TO_TRASH,
  teeup,
})

export const gotTeeupParts = payload => {
  return {
    type: actionTypes.GOT_TEEUP_PARTS,
    payload,
  }
}

export const updateReactions = (
  reactionId,
  suggestionId,
  teeupId,
  updatedBy,
  typeId
) => ({
  type: actionTypes.CONFIRM_REACTION,
  payload: {
    reactionId: +reactionId,
    suggestionId,
    teeupId,
    userId: +updatedBy,
    typeId,
  },
})

export const updateGameplan = (teeupId, gameplan) => ({
  type: actionTypes.UPDATE_GAMEPLAN,
  payload: {
    teeupId,
    gameplan,
  },
})

export const updateTeeupIsArchivePromptShow = ({ userId, teeup }) => ({
  type: actionTypes.TEEUP_UPDATE_ISARCHIVE_PROMPT_SHOW,
  payload: { userId, teeup },
})

export const fetchTeeupUsersById = teeupId => {
  const url = endpoints.teeup.users(teeupId)

  return api.client
    .get(url)
    .then(response => {
      const { users } = response.data
      const formatedUsers = formatTeeupUsers(users, Number(teeupId))

      return formatedUsers
    })
    .catch(error => {
      console.log("fetchUserTeeupsUsersById error ")
      console.error(error)
    })
}

export const fetchTeeupOverview = async teeupId => {
  const url = endpoints.teeup.overview(teeupId)
  const userId = selectUserId(store.getState())

  return api.client
    .get(url)
    .then(response => {
      const {
        teeup,
        users,
        organisers,
        invited,
        history,
        gameplanOptions,
        locations,
        messages,
      } = response.data

      const formattedTeeup = formatTeeup(teeup)
      const { people, participants } = formatTeeupUsers(users, Number(teeupId))

      const formattedGameplan = formatTeeupGameplanOptions(
        userId,
        formattedTeeup,
        gameplanOptions,
        people
      )

      return {
        teeup: formattedTeeup,
        users,
        organizers: organisers,
        invited,
        history,
        gameplanOptions: formattedGameplan,
        people,
        participants,
        locations,
        gameplans: formattedGameplan,
        messages,
      }
    })
    .catch(error => {
      // if it catches error, it will redirect to notFound page
      store.dispatch(push("/"))
      console.error(error)
    })
}

export const setSuggestionAsSeen = payload => {
  const url = endpoints.teeup.setAsSeen(payload)

  return api.client.post(url)
}

export const fetchGameplanOptions = (teeupId, activeTeeup = false) => {
  const userId = selectUserId(store.getState())
  const teeup = activeTeeup
    ? store.getState().activeTeeup
    : selectAnyTeeupById(Number(teeupId))(store.getState())
  const teeupPeople = selectTeeupPeopleByTeeupId(teeupId)(store.getState())
  const url = endpoints.teeup.gameplanOptions(teeupId)

  return api.client
    .get(url)
    .then(response => {
      return formatTeeupGameplanOptions(
        userId,
        teeup,
        response.data,
        teeupPeople
      )
    })
    .catch(error => {
      console.log("fetchGamelanOptions error ")
      console.error(error)
    })
}

export const updateIsArchivePromptShow = (
  teeupId,
  userId,
  isArchivePromptShow
) => {
  let url = endpoints.teeup.users(teeupId)

  return api.client
    .put(url, { isArchivePromptShow })
    .then(response => {})
    .catch(error => {
      console.log("updateIsArchivePromptShow error ")
      console.error(error)
    })
}

export const addTeeupInvitees = (teeupId, invited) => {
  const url = endpoints.teeup.addInvitees(teeupId)

  let invitedTrimmed = invited.map(invitee => {
    if (invitee.countryCode) {
      let updatedInvitee = { ...invitee }
      delete updatedInvitee.countryCode
      return updatedInvitee
    }

    return invitee
  })

  return api.client
    .post(url, { invited: invitedTrimmed })
    .then(response => {
      return response
    })
    .catch(error => {
      console.log("add invitees error ")
      console.error(error)
    })
}

export const inviteManual = (teeupId, invitees) => {
  const url = endpoints.teeup.inviteManual(teeupId)
  const manuals = {
    emails: invitees.map(invitee => invitee.email).filter(Boolean),
    phones: invitees.map(invitee => invitee.phone).filter(Boolean),
  }

  return api.client.post(url, manuals)
}

export const setSuggestionRemovedStatus = (suggestionId, teeupId) => ({
  type: actionTypes.SET_SUGGESTION_REMOVED_STATUS,
  payload: { suggestionId, teeupId },
})

export const removeSuggestionRemovedStatus = (suggestionId, teeupId) => ({
  type: actionTypes.REMOVE_SUGGESTION_REMOVED_STATUS,
  payload: { suggestionId, teeupId },
})

export const removeSuggestionNewStatus = (suggestionId, teeupId) => ({
  type: actionTypes.REMOVE_SUGGESTION_NEW_STATUS,
  payload: { suggestionId, teeupId },
})

export const resetTeeupSlug = payload => {
  const { teeupId } = payload
  const url = endpoints.teeup.resetSlug(teeupId)

  api.client
    .post(url, null)
    .then(response => {
      console.log("Teeup slug reset successfully:", response)
      store.dispatch(
        updateActiveTeeup({ id: teeupId, slug: response.data.slug })
      )
    })
    .catch(error => {
      console.log("Error reseting teeup slug:", error)
    })
}

export const joinTeeupInvitation = async ({ teeupId, userId, categoryId }) => {
  const payload = {
    userId,
    teeupId,
    userStatus: teeupUserStatusKeys.joined,
    categoryId,
  }
  await joinTeeup(payload)
}
