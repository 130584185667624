import styled, { css } from "styled-components"

export const BodyIcon = styled.img`
  margin-right: 15px;
`

export const BodyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isSyncButtons }) =>
    isSyncButtons &&
    css`
      margin-bottom: 40px;
    `};
`

export const BodyButtonWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
