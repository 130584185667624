import React, { useContext, useMemo } from "react"

import { connect } from "react-redux"

import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import {
  selectGuestSuggestions,
  selectNewGuestSuggestion,
} from "@selectors/guest"
import { getTimeRange } from "@utils/dateUtils"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { Wrapper } from "./suggestBottomStyles"

const SuggestBottom = ({ newGuestSuggestion, guestSuggestions }) => {
  const {
    suggestionsManager: { createSuggestion },
    stepsManager: { goNextStep },
  } = useContext(TeeupPreviewContext)

  const timeRangeLabel = useMemo(() => {
    if (!newGuestSuggestion) return
    const { start, end } = newGuestSuggestion

    return `Suggest ${getTimeRange(start, end)}`
  }, [newGuestSuggestion])

  const createdSuggestions = useMemo(
    () => guestSuggestions.filter(event => event.isCreated),
    [guestSuggestions]
  )
  const continueText = `Continue with (${
    createdSuggestions.length
  }) suggestion${createdSuggestions.length > 1 ? "s" : ""}`
  const isSuggestionExist =
    createdSuggestions.length > 0 && newGuestSuggestion.isHidden
  const isDisabled = !timeRangeLabel || newGuestSuggestion.isConflict

  return (
    <Wrapper>
      <ButtonEl
        fullWidth
        isBlack
        text={isSuggestionExist ? continueText : timeRangeLabel}
        variant={BUTTON_VARIANTS.ROUNDED}
        onClick={isSuggestionExist ? goNextStep : createSuggestion}
        disabled={isDisabled}
      />
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  newGuestSuggestion: selectNewGuestSuggestion(state),
  guestSuggestions: selectGuestSuggestions(state),
})

export default connect(mapStateToProps)(SuggestBottom)
