import styled, { css } from "styled-components"

export const StatusDot = styled.div`
  ${({ $color }) => css`
    width: 6px;
    height: 6px;
    background-color: ${$color};
    border-radius: 50%;
    margin-right: 5px;
  `};
`
