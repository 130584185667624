import styled from "styled-components"

import { NCaptionS, NFontWeight } from "@assets/styles/newTypographyStyles"

export const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  row-gap: 25px;
  padding: 20px 0;
  margin-top: 25px;
`

export const Description = styled(NCaptionS)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  text-align: center;
  margin-top: 10px;
`
