import React, { useContext, useEffect, useRef } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import cn from "classnames"
import ReCAPTCHA from "react-google-recaptcha"
import { connect } from "react-redux"

import { SignUpEmailContext } from "@contexts/SignUpEmailContext"

import "./publicInfoForm.scss"
import { isProdEnv } from "@utils/checkEnv"
import { getBase64 } from "@utils/imageUtils"

import { captchaId } from "../../../index"
import { InputEl } from "../../common"
import { ErrorMessage } from "../../common/InputEl/atoms"
import { UserAvatar } from "../atoms"
import { REGISTER_STEPS } from "../constants"
import { InviteInfo, ResendCode } from "../molecules"

const PublicInfoForm = ({ teeupInviteInfo, isRegisterSsoNames }) => {
  const {
    step,
    fullName,
    setFullName,
    displayName,
    setCaptcha,
    avatar,
    setAvatar,
    isInviteCodeVerifying,
    isInviteCodeVerified,
    setDisplayName,
    validationErrors,
    inviteVerificationCode,
    setInviteVerificationCode,
    touchField,
    apiErrors,
  } = useContext(SignUpEmailContext)
  const { user } = useAuth0()

  const isProd = isProdEnv()

  useEffect(() => {
    if (isRegisterSsoNames && user) {
      setFullName(user.name || "")
      if (user.name) {
        touchField("fullName", true)
      }
      setAvatar(user.avatar || null)
      if (user.name) {
        touchField("avatar", true)
      }
      setDisplayName(user.nickname.slice(0, 12).replaceAll(" ", "") || "")
      if (user.nickname) {
        touchField("displayName", true)
      }
    }
  }, [isRegisterSsoNames, user])

  const handleAvatarChange = event => {
    const file = event.target.files[0]
    getBase64(file).then(base64 => {
      setAvatar(base64)
    })
  }

  const CAPTCHA_ID = captchaId
  const ref = useRef()

  return (
    <div className="public-info-form">
      <UserAvatar onChange={handleAvatarChange} picture={avatar} />

      <InputEl
        label="What is your full name?"
        placeholder="Full name"
        name="fullName"
        errorMessage={validationErrors?.fullName}
        value={fullName}
        maxLength={40}
        disabled={step === REGISTER_STEPS.INVITE_SENDER}
        onChange={newVal => {
          touchField("fullName", newVal)
          setFullName(newVal)
        }}
      />

      <InputEl
        label="What should be your display name in chat messages?"
        placeholder="displayname"
        name="displayName"
        errorMessage={validationErrors?.displayName || apiErrors?.displayName}
        value={displayName}
        maxLength={12}
        disabled={step === REGISTER_STEPS.INVITE_SENDER}
        isMention={true}
        onChange={newVal => {
          touchField("displayName", newVal)
          setDisplayName(newVal.replaceAll(" ", ""))
        }}
      />
      {isProd && (
        <div>
          <ReCAPTCHA
            ref={ref}
            sitekey={CAPTCHA_ID}
            size="normal"
            onChange={val => {
              setCaptcha(val)
            }}
          />
          <div
            className={cn("input-el__footer", {
              "input-el__footer--error": validationErrors?.captcha,
            })}>
            {validationErrors?.captcha && (
              <ErrorMessage errorMessage={validationErrors?.captcha} />
            )}
          </div>
        </div>
      )}
      {step === REGISTER_STEPS.INVITE_SENDER && (
        <>
          {teeupInviteInfo && <InviteInfo info={teeupInviteInfo} />}

          <InputEl
            isLoading={isInviteCodeVerifying}
            isValid={isInviteCodeVerified}
            label="Verification code"
            placeholder="verification code"
            name="inviteVerificationCode"
            errorMessage={apiErrors?.inviteVerificationCode || null}
            value={inviteVerificationCode}
            maxLength={6}
            onChange={newVal => {
              touchField("inviteVerificationCode", newVal)
              setInviteVerificationCode(newVal)
            }}>
            <ResendCode isInvite setCode={setInviteVerificationCode} />
          </InputEl>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  isRegisterSsoNames: user.isRegisterSsoNames,
  teeupInviteInfo: user.teeupInviteInfo,
})

export default connect(mapStateToProps)(PublicInfoForm)
