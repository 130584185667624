/**
 * App Theme - Sizes
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */
import { Dimensions, Platform } from "react-native"

const { width, height } = Dimensions.get("window")
const scale = Dimensions.get("screen").scale
const screenHeight = width < height ? height : width
const screenWidth = width < height ? width : height
const iPhoneSEWidth = 320

export default {
  // Window Dimensions
  screen: {
    height: screenHeight,
    width: screenWidth,
    scale,

    widthHalf: screenWidth * 0.5,
    widthThird: screenWidth * 0.333,
    widthTwoThirds: screenWidth * 0.666,
    widthQuarter: screenWidth * 0.25,
    widthThreeQuarters: screenWidth * 0.75,

    heightThird: screenHeight * 0.333,
    heightTwoThirds: screenHeight * 0.666,
    heightQuarter: screenHeight * 0.25,
    heightHalf: screenHeight * 0.5,
  },
  // navbarHeight: Platform.OS === 'ios' ? 56 : 54,
  statusBarHeight: 0,
  tabbarHeight: 50,
  tabButtonHeight: 50,

  padding: 20,
  paddingSml: 10,

  borderRadius: 20,
  cardBorderRadius: 10,
  additionalBottomSpace: 0,

  isSmallAndroid: Platform.OS !== "ios" && screenHeight <= 650,
  isSmallPhone: screenHeight <= 650,
  isIPhoneSE: Platform.OS === "ios" && screenWidth <= iPhoneSEWidth,
}
