import { useCallback } from "react"

import { View } from "react-native"
import { connect, useSelector } from "react-redux"

import {
  selectActiveTeeup,
  selectVisibleTeeupPinnedMessages,
  selectTeeupMentions,
} from "@selectors/activeTeeup"
import {
  selectPermissions,
  selectSuggestionMessages,
  selectTeeupGameplans,
  selectTeeupSuggestions,
  selectTeeupPeople,
} from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
import ChatMessage from "@ui/chatMessage"
import { getMessageOptions, showChatMessageContextMenu } from "@utils/chatUtils"

import UserMentionsScreenComponent from "./UserMentionsScreenComponent"
import { styles } from "./UserMentionsScreenStyles"

const noOp = () => {}

interface Props {
  newDesign?: boolean
  handleClose: () => void
}

const UserMentionsScreenContainer = ({
  newDesign = true,
  handleClose,
}: Props) => {
  const userInfo = useSelector(selectUserInfo)
  const people = useSelector(selectTeeupPeople)
  const mentions = useSelector(selectTeeupMentions)
  const permissions = useSelector(selectPermissions)
  // const pinnedMessages = useSelector(selectVisibleTeeupPinnedMessages)
  const suggestionMessages = useSelector(selectSuggestionMessages)
  const teeup = useSelector(selectActiveTeeup)
  // const gameplans = useSelector(selectTeeupGameplans)
  const suggestions = useSelector(selectTeeupSuggestions)

  // const pinnedMessagesCount = pinnedMessages.length
  // const teeupReadTimestamp = []
  // const newSuggestions = []
  const handleNudgeItemRender = useCallback(({ item, index }) => {
    const params = {
      people,
      expanded: true,
      teeup,
      userInfo,
      suggestions,
      messages: mentions,
      permissions,
      newSuggestions: [],
      suggestionMessages,
      item,
      index,
      showOnMentionsScreen: true,
      expandedStatusMessagesArray: [],
      isTimestampsVisible: false,
      openPreview: noOp,
      isReplyDisabled: true,
      onContextMenu: onContextMenu,
      activeReplyMessageId: null,
      openParticipantDetails: noOp,
      handleKeyboardClose: noOp,
      isKeyboardActive: false,
    }

    const options = getMessageOptions(params)

    const getViewObj = (_options = options, style) => (
      <View collapsable={false} style={[styles.mentionsMessage, style]}>
        <ChatMessage
          {..._options}
          key={item.id}
          // @ts-ignore
          messages={params.messages}
          showOnMentionsScreen={params.showOnMentionsScreen}
          newDesign={newDesign}
        />
      </View>
    )

    return getViewObj(options, styles.mentionsMessage)
  }, [])

  const onContextMenu =
    (id, suggestionMessage) =>
    (passedProps = {}) => {
      // @ts-ignore
      showChatMessageContextMenu({
        outsideOfChat: true,
        messages: mentions,
        id,
        suggestionMessage,
        passedProps,
        expanded: true,
        teeup,
        permissions,
        userStatus: userInfo.status,
        isKeyboardActive: false,
        onShowOnConversation: handleClose,
      })
    }

  return (
    <UserMentionsScreenComponent
      mentions={mentions}
      renderItem={handleNudgeItemRender}
      newDesign={newDesign}
    />
  )
}

export default UserMentionsScreenContainer
