import { MODAL_STATUSES } from "@constants/modal"

import {
  AUTH0_ACTION_TYPES,
  AUTH0_ERRORS,
} from "../../pages/Auth0ContentPage/constants"

export const useHandleAuthError = ({
  user,
  setAuth0Error,
  setNewRegisteredUser,
  setIsIdentityVerified,
  setIsLoginInProgress,
  setIsRegistrationInProgress,
  setNewRegisteredUserError,
  logout,
}) => {
  const handleAuthError = (e, actionType) => {
    if (e.status === 409) {
      const {
        code,
        data: { title: errorTitle, email: errorEmail = "" },
      } = e.data

      if (code === 6 && actionType === AUTH0_ACTION_TYPES.SIGN_UP) {
        setAuth0Error({
          errorEmail,
          code: AUTH0_ERRORS.EXISTING_USER,
          status: MODAL_STATUSES.ERROR,
        })
        return
      }

      if (code === AUTH0_ERRORS.LINKED_ALREADY) {
        setNewRegisteredUser(user)
        setIsIdentityVerified(true)
      }

      setAuth0Error({ code, errorTitle, errorEmail })
    } else {
      setAuth0Error({ code: AUTH0_ERRORS.WAS_A_PROBLEM })
    }
    setIsLoginInProgress(false)
    setIsRegistrationInProgress(false)
    setNewRegisteredUserError(null)
    if (e.data?.code !== 6) {
      logout({ localOnly: true })
    }
  }

  return { handleAuthError }
}
