import { createSelector } from "reselect"

export const selectSuggest = state => state.suggest

export const selectActiveMeetingApp = createSelector(
  selectSuggest,
  state => state.activeMeetingApp
)

export const selectInitComment = createSelector(
  selectSuggest,
  state => state.initComment
)

export const selectActiveButtonId = createSelector(
  selectSuggest,
  state => state.activeButtonId
)

export const selectMapCenter = createSelector(
  selectSuggest,
  state => state.mapCenter
)

export const selectActivePlace = createSelector(
  selectSuggest,
  state => state.activePlace
)

export const selectIsSearchNearby = createSelector(
  selectSuggest,
  state => state.isSearchNearby
)

export const selectNearbyPlaces = createSelector(
  selectSuggest,
  state => state.nearbyPlaces
)
