import cn from "classnames"

import { SvgIcon } from "@components/common"
import { Platform, Prediction } from "types/suggestions"

import "./index.scss"

type PossibleOption = Platform | Prediction

interface Props<T extends PossibleOption> {
  option: T
  onSelect: (value: T) => void
  onOptionRemove?: (option: T) => void
  isArrowShown?: boolean
  isDisabled?: boolean
  isCrossShown?: boolean
  isCustom?: boolean
}

const Option = <T extends PossibleOption>({
  option,
  onSelect,
  onOptionRemove,
  isArrowShown = true,
  isDisabled,
  isCrossShown,
  isCustom,
}: Props<T>) => {
  const handleClick = () => {
    if (!isDisabled) {
      onSelect(option)
      return
    }
  }

  const handleRemoveRecent = e => {
    e.stopPropagation()
    onOptionRemove && onOptionRemove(option)
  }

  return (
    <li
      className={cn("suggestion-where-search-option", {
        "suggestion-where-search-separator": isDisabled,
      })}
      onClick={handleClick}>
      <div>
        <SvgIcon name={option.iconName} />
        <span>{option.label}</span>
      </div>

      {isCrossShown && onOptionRemove && (
        <SvgIcon name="cross" color="#000" onClick={handleRemoveRecent} />
      )}

      {isArrowShown && <SvgIcon name="arrow" />}
      {isCustom && <SvgIcon name="add-icon" />}
    </li>
  )
}

export default Option
