import React from "react"

import "./index.scss"

const InvitationSent = ({ closeModal }) => {
  return (
    <div className="invitation-sent__wrapper" onClick={() => closeModal()}>
      <div className="invitation-sent" onClick={e => e.stopPropagation()}>
        <h3 className="invitation-sent__title">
          Invitation was successfully sent
        </h3>

        <button
          className="invitation-sent__button"
          type="button"
          onClick={() => closeModal()}>
          OK
        </button>
      </div>
    </div>
  )
}

export default InvitationSent
