import actionTypes from "@actions/actionTypes"
import { checkIfEventsConflict } from "@utils/calendarUtils"
import {
  updateLocalReactions,
  updateSuggestionReaction,
} from "@utils/guestHelpers"

export const initialState = {
  guestRegistrationData: null,
  newSuggestion: null,
  suggestions: [],
  reactions: {},
  guestName: "",
  myTeeupReactions: [], // suggestions ids
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GUEST_REGISTRATION_TYPE:
      return {
        ...state,
        guestRegistrationData: action.payload,
      }
    case actionTypes.SET_GUEST_SUGGESTIONS:
      return {
        ...state,
        suggestions:
          state.suggestions.length > 0
            ? [...state.suggestions, ...action.payload]
            : action.payload,
      }
    case actionTypes.SET_GUEST_CALENDAR_CONFLICTS:
      const { calendarEvents } = action.payload
      return {
        ...state,
        suggestions: state.suggestions.map(suggestion => {
          const updatedLocalSuggestion = { ...suggestion }
          calendarEvents.forEach(calendarEvent => {
            const isCalendarConflict = checkIfEventsConflict(
              suggestion,
              calendarEvent
            )

            if (isCalendarConflict) {
              updatedLocalSuggestion.isCalendarConflict = true
            }
          })

          return updatedLocalSuggestion
        }),
      }
    case actionTypes.PUBLISH_GUEST_CREATED_SUGGESTIONS:
      return {
        ...state,
        suggestions: state.suggestions.map(event =>
          event.isCreated
            ? {
                ...event,
                isPublished: true,
                creator: {
                  ...event.creator,
                  name: action.payload,
                },
              }
            : event
        ),
      }
    case actionTypes.REMOVE_GUEST_SUGGESTIONS:
      return {
        ...state,
        suggestions: state.suggestions.filter(
          event => event.id !== action.payload
        ),
      }
    case actionTypes.UPDATE_GUEST_REACTION:
      return {
        ...state,
        reactions: updateLocalReactions({
          originReactions: state.reactions,
          myTeeupReactions: state.myTeeupReactions,
          suggestionId: action.payload.suggestionId,
          reactionId: action.payload.reactionId,
          isActive: action.payload.isActive,
        }),
        suggestions: updateSuggestionReaction({
          originSuggestions: state.suggestions,
          suggestionId: action.payload.suggestionId,
          reactionId: action.payload.reactionId,
          isActive: action.payload.isActive,
          inviteeId: action.payload.inviteeId,
        }),
      }
    case actionTypes.SET_NEW_GUEST_SUGGESTION:
      return {
        ...state,
        newSuggestion: state.newSuggestion
          ? {
              ...state.newSuggestion,
              ...action.payload,
            }
          : action.payload,
      }
    case actionTypes.SET_GUEST_NAME:
      return {
        ...state,
        guestName: action.payload,
      }
    case actionTypes.SET_MY_GUEST_TEEUP_REACTIONS:
      return {
        ...state,
        myTeeupReactions: action.payload,
      }
    case actionTypes.RESET_ALL_GUEST_DATA:
      return initialState
    default:
      return state
  }
}
