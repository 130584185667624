import { Button } from "@components/ui/ButtonNd"
import { TeeupItem } from "types/teeup"

interface Props {
  onAcceptInvitation?: (teeUp: TeeupItem) => void
  onDismissInvitation?: (teeUp: TeeupItem) => void
  onPreviewClick?: (teeUp: TeeupItem) => void
  teeUp: TeeupItem
}

const InviteButtons = ({
  onAcceptInvitation,
  onDismissInvitation,
  onPreviewClick,
  teeUp,
}: Props) => {
  return (
    <div className="mt-4 flex justify-end gap-2">
      <Button
        variant="text"
        className="!h-9 !text-blue-500"
        onClick={() => onDismissInvitation?.(teeUp)}>
        Dismiss
      </Button>

      <Button
        variant="text"
        className="!h-9 !text-blue-500"
        onClick={() => onPreviewClick?.(teeUp)}>
        Preview
      </Button>

      <Button
        onClick={() => onAcceptInvitation?.(teeUp)}
        variant="contained"
        className="!h-9 rounded-[400px] !bg-blue-500 text-white">
        Accept invitation
      </Button>
    </div>
  )
}

export default InviteButtons
