import { useSuggestionOnlineStore } from "stores/suggestions/suggestionOnlineStore"

import ChoosePlatform from "./ChoosePlatform"
import MeetingDetails from "./MeetingDetails"
import YourSuggestionsContainer from "./YourSuggestionsContainer"

import "./index.scss"

const Online = ({ onSave }) => {
  const currentPlatform = useSuggestionOnlineStore(
    state => state.currentPlatform
  )

  return (
    <div className="suggestion-where-online">
      {currentPlatform ? <MeetingDetails /> : <ChoosePlatform />}

      <YourSuggestionsContainer onSave={onSave} />
    </div>
  )
}

export default Online
