import { Fragment, ReactNode, useEffect, useRef } from "react"

import PropTypes from "prop-types"
import ContentLoader from "react-content-loader"
import { View, Text, TouchableOpacity } from "react-native"
import { useSelector } from "react-redux"

import { highlightTypes } from "@configs/enums"
import { selectUserId } from "@selectors/activeTeeup"
import SlimScroll from "@ui/slimScroll"
import TabBar from "@ui/tabBar"

import PinnedMessagesScreen from "./PinnedMessagesScreen"
import { SuggestionsScreen } from "./SuggestionsScreen"
import UnreadBar from "./UnreadBar"
import UserMentionsScreen from "./UserMentionsScreen"
import UserNudgesScreen from "./UserNudgesScreen"
import { DiscussionStyles, CardStyles } from "./discussionStyles"

const tabs = [
  {
    id: highlightTypes.conversation,
    title: "All",
  },
  {
    id: highlightTypes.pinboard,
    title: "Pinned",
  },
  {
    id: highlightTypes.mentions,
    title: "Mentions",
  },
  {
    id: highlightTypes.nudges,
    title: "Nudges",
  },
  {
    id: highlightTypes.suggestions,
    title: "Suggestions",
  },
]

const renderSkeleton = () => {
  return (
    <View style={CardStyles.skeletonContainer}>
      <ContentLoader
        backgroundColor="#E1E9EE"
        foregroundColor="#F2F8FC"
        width="400"
        height="350"
        viewBox="0 0 400 350">
        <rect x="0" y="200" rx="20" ry="20" width="90%" height="100" />
        <rect x="100" y="100" width="120" height="20" />
        <rect x="100" y="150" width="120" height="20" />
      </ContentLoader>
    </View>
  )
}

const renderView = ({ style, ...props }) => {
  return <div id="messages-list" style={{ ...style }} {...props} />
}

interface Props {
  index: number
  messages: any[]
  expanded?: boolean
  renderItem: ({ item, index }) => ReactNode
  unreadCount?: number
  unreadBannerEnabled: boolean
  onUnreadClose: () => void
  onUnreadPress: () => void
  testID?: string
  toggleExpansion: () => void
  handleCloseKeyboardOnTouchOutside?: () => void
}

const DiscussionCardView = ({
  index,
  messages,
  expanded,
  renderItem,
  unreadCount,
  unreadBannerEnabled,
  onUnreadClose,
  onUnreadPress,
  testID,
  toggleExpansion,
  handleCloseKeyboardOnTouchOutside,
}: Props) => {
  const scrollContainerRef = useRef<any>(null)
  const isMessagesExist = messages.length > 0
  const userId = useSelector(selectUserId)

  useEffect(() => {
    if (
      !messages?.length ||
      !scrollContainerRef?.current?.scrollToBottom ||
      !scrollContainerRef?.current?.view
    )
      return

    const { view, scrollToBottom } = scrollContainerRef.current

    const lastMessage = messages.at(-1)
    if (
      lastMessage.conversationTypeId === 1 &&
      lastMessage.messageTypeId === 1 &&
      lastMessage.userId === userId
    ) {
      view.style.overflow = "hidden"
      scrollToBottom({
        behavior: "smooth",
        block: "center",
      })
      view.style.overflow = "scroll"
    }
  }, [messages, index])

  return (
    <View
      style={CardStyles.cardContent}
      pointerEvents="box-none"
      testID={testID}>
      <TabBar tabs={tabs} />

      <View style={DiscussionStyles.splitter} />

      {index === highlightTypes.conversation && (
        <View
          style={[
            DiscussionStyles.chatContainer,
            messages.length === 0 && { transform: "scaleY(-1)" },
          ]}>
          {unreadBannerEnabled && (
            <UnreadBar
              count={unreadCount}
              onClose={expanded && onUnreadClose}
              onPress={onUnreadPress}
            />
          )}

          <TouchableOpacity
            style={DiscussionStyles.chatContainerPlaceholder}
            onPress={
              expanded ? handleCloseKeyboardOnTouchOutside : toggleExpansion
            }
          />

          {isMessagesExist && (
            <div
              className="discussion-items__container scroll"
              style={{ height: "100%" }}>
              {/*@ts-ignore */}
              <SlimScroll ref={scrollContainerRef} renderView={renderView}>
                {messages.map((item, index) => (
                  <Fragment
                    key={`message-${
                      item.id || item.timestamp || item.daybreak
                    }`}>
                    {renderItem({ item, index })}
                  </Fragment>
                ))}
              </SlimScroll>
            </div>
          )}

          {!isMessagesExist && renderSkeleton()}
        </View>
      )}

      {index === highlightTypes.pinboard && (
        <PinnedMessagesScreen
          // @ts-ignore
          disableBind={true}
          insideTabContainer={true}
          renderChatItem={renderItem}
        />
      )}

      {index === 2 && (
        // @ts-ignore
        <UserMentionsScreen handleClose={() => {}} />
      )}

      {index === 3 && <UserNudgesScreen />}
      {index === 4 && <SuggestionsScreen renderChatItem={renderItem} />}
    </View>
  )
}

export default DiscussionCardView
