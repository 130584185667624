import { useCallback } from "react"

import { CALENDAR_TYPES, EVENT_TYPES } from "@utils/calendarUtils"

export const useEventsColors = () => {
  return useCallback((eventType, calendarType) => {
    if (eventType === EVENT_TYPES.calendar) {
      return calendarType === CALENDAR_TYPES.google
        ? {
            secondary: "var(--transparentGreen)",
            main: "var(--green2)",
          }
        : {
            secondary: "var(--transparentBlue)",
            main: "var(--blue)",
          }
    } else {
      return {
        secondary: "var(--transparentRadicalRed)",
        main: "var(--radicalRed)",
      }
    }
  }, [])
}
