import { useEffect } from "react"

import { SIDEBAR_MENU } from "@constants/sidebar"
import { checkIsPath } from "@utils/checkIsPath"

import { logScreen, SCREENS } from "../../../analytics-tracking"

export const useFetchData = ({
  isInitialCall,
  teeups,
  userId,
  teeupsPaginationParams,
  token,
  fetchTeeups,
  getContacts,
}) => {
  const fetchData = () => {
    const { INBOX, ARCHIVE, TRASH } = SIDEBAR_MENU

    if (teeups.length === 0 && userId && checkIsPath(INBOX.PATHNAME)) {
      fetchTeeups(teeupsPaginationParams)
    }

    if (checkIsPath(ARCHIVE.PATHNAME) || checkIsPath(TRASH.PATHNAME)) {
      fetchTeeups()
    }

    getContacts()

    logScreen(SCREENS.TEEUP_LIST)
  }

  useEffect(() => {
    if (isInitialCall) {
      fetchData()
    }
  }, [teeups.length, token, userId])

  return { fetchData }
}
