import React, { useState } from "react"

import parse from "html-react-parser"
import ReactTooltip from "react-tooltip"

import "./index.scss"

const InformationTooltip = () => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <ReactTooltip
      id="information-tooltip"
      class="extraClassInfo"
      backgroundColor="#222222"
      place="top"
      effect="solid"
      offset={{ top: 5 }}
      afterHide={() => setIsOpen(true)}
      delayHide={500}
      // delayShow={ 100 }
      getContent={title => {
        if (!title || !isOpen) {
          return false
        }
        return <span className="tooltip_info_title">{parse(title)}</span>
      }}
    />
  )
}

export default InformationTooltip
