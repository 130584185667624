import dayjs from "utils/dayjs"

export const getCalendarRequestParams = (date, linkedGuestCalendar) => {
  const currentDate = dayjs(date) || dayjs()
  const startDate = currentDate.startOf("date").toISOString()
  const endDate = currentDate.endOf("date").toISOString()

  return {
    calendarCredentials: linkedGuestCalendar,
    startDate,
    endDate,
  }
}
