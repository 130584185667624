import { useDispatch, useSelector } from "react-redux"

import actionTypes from "@actions/actionTypes"
import {
  selectActiveMeetingApp,
  selectActiveButtonId,
  selectInitComment,
} from "@selectors/suggest"

const useOnlineTabState = () => {
  const dispatch = useDispatch()
  const activeApp = useSelector(selectActiveMeetingApp)
  const activeButtonId = useSelector(selectActiveButtonId)
  const initComment = useSelector(selectInitComment)

  const setActiveApp = payload => {
    dispatch({
      type: actionTypes.SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP,
      payload,
    })
  }

  const setInitComment = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_INIT_COMMENT, payload })
  }

  const setActiveButtonId = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID, payload })
  }

  const addSuggestAppName = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_APP_NAME, payload })
  }

  const addSuggestDetails = payload => {
    dispatch({ type: actionTypes.SET_SUGGEST_ONLINE_DETAILS, payload })
  }

  return {
    activeApp,
    initComment,
    activeButtonId,
    setActiveApp,
    setActiveButtonId,
    addSuggestAppName,
    addSuggestDetails,
    setInitComment,
  }
}

export default useOnlineTabState
