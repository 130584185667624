import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { getContacts } from "@actions/createTeeupActions"
import {
  postGuestData,
  resetAllGuestData,
  setGuestRegistrationData,
  postSharedLinkGuestData,
} from "@actions/guestActions"
import { fetchTeeups } from "@actions/teeupActions"
import { teeupUserStatusKeys } from "@config/enums"
import { useDeviceTimezone } from "@hooks"
import {
  selectGuestReactions,
  selectGuestRegistrationData,
  selectGuestSuggestions,
} from "@selectors/guest"
import {
  selectActiveTeeups,
  selectTeeupsPaginationParams,
} from "@selectors/teeups"
import { selectUserInfo } from "@selectors/user"
// TODO: move these hooks to global hooks
import { selectToken, selectUserId } from "@selectors/user"

import {
  usePostingReactions,
  usePostingSuggestions,
} from "../../components/TeeupPreviewContent/hooks"
import { useFetchData } from "../../pages/TeeUpsPage/hooks"

export const useRegisteredGuest = () => {
  const [isCalled, setIsCalled] = useState(false)
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const guestRegistrationData = useSelector(selectGuestRegistrationData)
  const reactions = useSelector(selectGuestReactions)
  const suggestions = useSelector(selectGuestSuggestions)
  const postingReactions = usePostingReactions(reactions)
  const postingSuggestions = usePostingSuggestions(
    suggestions,
    guestRegistrationData?.whenGameplanOptionId
  )
  const teeups = useSelector(selectActiveTeeups)
  const userId = useSelector(selectUserId)
  const teeupsPaginationParams = useSelector(selectTeeupsPaginationParams)
  const token = useSelector(selectToken)

  const { fetchData } = useFetchData({
    isInitialCall: false,
    teeups,
    userId,
    teeupsPaginationParams,
    token,
    fetchTeeups: params => dispatch(fetchTeeups(params)),
    getContacts: params => dispatch(getContacts(params)),
  })
  const { currentDeviceTimezone } = useDeviceTimezone({})

  const callDataPost = async () => {
    if (isCalled) return

    if (guestRegistrationData && userInfo.userId) {
      setIsCalled(true)

      const { slug, status, isSharedLink, teeupId } = guestRegistrationData

      if (isSharedLink) {
        const bodyObj = {
          teeupId,
          status: teeupUserStatusKeys.going,
          data: {},
        }
        if (postingSuggestions.length > 0) {
          bodyObj.data.suggestions = postingSuggestions
        }
        if (postingReactions.length > 0) {
          bodyObj.data.reactions = postingReactions
        }

        await postSharedLinkGuestData(bodyObj)
        fetchData()
        dispatch(setGuestRegistrationData(null))
      } else {
        const bodyObj = {
          slug,
          status,
          data: {
            username: userInfo.nickname,
            timezone: currentDeviceTimezone,
          },
        }
        if (postingSuggestions.length > 0) {
          bodyObj.data.suggestions = postingSuggestions
        }
        if (postingReactions.length > 0) {
          bodyObj.data.reactions = postingReactions
        }

        dispatch(setGuestRegistrationData(null))
        await postGuestData(bodyObj)
      }

      dispatch(resetAllGuestData())
    }
  }

  useEffect(() => {
    callDataPost().then()
  }, [isCalled, guestRegistrationData, userInfo])
}
