import React from "react"

import { Title, Description } from "./atoms"
import { InfoBlock, DownloadBlock } from "./molecules"
import { Wrapper } from "./whiteCooweOverviewStyles"

export const WhiteCooweOverview = () => (
  <Wrapper>
    <Title />

    <Description />

    <InfoBlock />

    <DownloadBlock />
  </Wrapper>
)
