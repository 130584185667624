import styled from "styled-components"

import { FontWeight, Subtitle } from "@assets/styles/typographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;
`

export const BackIcon = styled(SvgIcon)`
  transform: rotate(90deg);
`

export const ModalTitle = styled(Subtitle)`
  font-weight: ${FontWeight.bold};
`

export const CloseIcon = styled(SvgIcon)`
  width: 32px;
  height: 32px;
`
