import { LAST_MINUTES_STEP } from "constants/global"

import { MINUTES_STEP } from "../constants"

export const getHours = period => {
  return period.map(currentDay => {
    const result = []

    for (let h = 0; h < 24; h++) {
      currentDay.seconds(0)
      currentDay.hours(h)
      for (let m = 0; m <= LAST_MINUTES_STEP; m += MINUTES_STEP) {
        result.push(currentDay.minutes(m).format())
      }
    }

    return result
  })
}
