import { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"

import { getContacts } from "@actions/createTeeupActions"
import { selectAllContacts } from "@selectors/contacts"

export const useContacts = () => {
  const dispatch = useDispatch()
  const cooweContacts = useSelector(selectAllContacts)

  useEffect(() => {
    dispatch(getContacts())
  }, [])

  return {
    cooweContacts,
  }
}
