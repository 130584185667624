import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

// similar styles as in ../commentedSuggestionStyles
export default StyleSheet.create({
  container: {
    alignItems: "flex-start",
  },
  myContainer: {
    alignItems: "flex-end",
  },
  senderText: {
    ...AppFonts.smallishMedium,
    marginBottom: Units.responsiveValue(4),
    color: AppColors.brand.grey157,
  },
  myText: {
    color: AppColors.brand.lightBlue7,
  },
})
