import React, { useCallback } from "react"

import GoogleIcon from "@assets/images/google.svg"
import OutlookIcon from "@assets/images/outlook.svg"
import { Subtitle } from "@assets/styles/typographyStyles"
import { CALENDAR_TYPES } from "@utils/calendarUtils"

import {
  List,
  ListItem,
  CalendarColor,
  CalendarIcon,
} from "./calendarsListStyles"

const CALENDARS_COLORS = {
  [CALENDAR_TYPES.google]: "var(--green2)",
  [CALENDAR_TYPES.outlook]: "var(--blue)",
}

export const CalendarsList = ({ calendars }) => {
  const getCalendarIcon = useCallback(calendarType => {
    if (calendarType === CALENDAR_TYPES.google) {
      return GoogleIcon
    }

    return OutlookIcon
  }, [])

  return (
    <List>
      {calendars.map(calendar => (
        <ListItem key={calendar.calendarEmailId}>
          <CalendarIcon
            src={getCalendarIcon(calendar.calendarType)}
            alt={calendar.calendarType}
          />

          <Subtitle>{calendar.summary}</Subtitle>

          <CalendarColor $color={CALENDARS_COLORS[calendar.calendarType]} />
        </ListItem>
      ))}
    </List>
  )
}
