import styled from "styled-components"

import { SvgIcon } from "@components/common"

export const IconWrapper = styled.div`
  // background-color: var(--lightBlue4);
  border-radius: 50%;
  display: flex;
  padding: 5px;
`

export const Icon = styled(SvgIcon)`
  width: 11px;
  height: 11px;
`
