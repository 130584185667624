import React from "react"

import PropTypes from "prop-types"

import {
  SuggestModalFooter,
  SuggestModalContent,
  SuggestModalSubHeader,
} from "../../components"

const TimeSlotsTab = ({ onSave }) => {
  return (
    <>
      <SuggestModalSubHeader></SuggestModalSubHeader>
      <SuggestModalContent type="when">TimeSlotsTab</SuggestModalContent>
      <SuggestModalFooter></SuggestModalFooter>
    </>
  )
}
TimeSlotsTab.propTypes = {
  onSave: PropTypes.func,
}
export default TimeSlotsTab
