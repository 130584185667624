import React from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  connectToSlack,
  setSlackIntegrationInfo,
} from "@actions/slackIntegrationAction"
import Logo from "@assets/images/newDesign/ndLoginLogo.png"
import { selectSlackIntegrationInfo } from "@selectors/slackIntegration"

import "./index.scss"

const SlackConnection = () => {
  const dispatch = useDispatch()
  const slackIntegrationInfo = useSelector(selectSlackIntegrationInfo)

  const handleConnectToSlack = () => {
    connectToSlack(slackIntegrationInfo)
    dispatch(setSlackIntegrationInfo(null))
  }

  const handleCancelConnect = () => {
    dispatch(setSlackIntegrationInfo(null))
  }

  return (
    <div className="slack-connection">
      <img src={Logo} alt="cooWe logo" className="slack-connection__icon" />
      <h3 className="slack-connection__title">
        Do you want to connect your CooWe account to Slack?
      </h3>
      <div className="slack-connection__buttons">
        <button
          type="button"
          className="slack-connection__button"
          onClick={() => handleCancelConnect()}>
          No
        </button>
        <button
          type="button"
          className="slack-connection__button connect"
          onClick={() => handleConnectToSlack()}>
          Connect
        </button>
      </div>
    </div>
  )
}

export default SlackConnection
