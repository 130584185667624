import React from "react"

import { Wrapper, Title, Description } from "./emptyListStyles"

export const EmptyList = () => (
  <Wrapper>
    <Title>Empty</Title>

    <Description>No suggestions has been made</Description>
  </Wrapper>
)
