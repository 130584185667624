import React from "react"

import PropTypes from "prop-types"

import { Message } from "./errorMessageStyles"

export const ErrorMessage = ({ errorMessage }) => (
  <Message dangerouslySetInnerHTML={{ __html: errorMessage }} />
)

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
}
