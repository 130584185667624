import React from "react"

import PropTypes from "prop-types"
import { View } from "react-native"

import { DatePicker } from "@ui/scrollablePicker"
// import Collapsible from 'react-native-collapsible'

const TimePicker = ({
  startDate,
  startTime,
  isStartDateToday,
  showTimePicker,
  onStartTimeSelected,
  timezone,
}) => {
  return (
    <View key={"time"} collapsed={!showTimePicker}>
      <DatePicker
        timezone={timezone}
        initDate={new Date(startTime)}
        mode={"time"}
        minuteInterval={15} // note: for some reason it uses 5-min intervals anyway
        minimumDate={isStartDateToday ? new Date() : undefined}
        onDateSelected={onStartTimeSelected}
        hideDate={true}
        startDate={new Date(startDate)}
      />
    </View>
  )
}

TimePicker.propTypes = {
  startTimeVal: PropTypes.string,
  endTimeVal: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isStartDateToday: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  showEndTimePicker: PropTypes.bool,
  onStartTimeSelected: PropTypes.func,
  onEndTimeSelected: PropTypes.func,
  onToggleEndEnable: PropTypes.bool,
  isEndEnabled: PropTypes.bool,
  isTimeInterval: PropTypes.bool,
  onPickerHeaderPress: PropTypes.func,
  timezone: PropTypes.string,
}

export default TimePicker
