import React from "react"

import PropTypes from "prop-types"

import { NewModal } from "@components"
import { MODAL_STATUSES } from "@constants/modal"

export const SomethingWentWrongModal = ({ text, setErrorMessage }) => (
  <NewModal
    isOpen
    isBlackBtn
    isHeader={false}
    title=""
    description={text}
    confirmText="Close"
    onClose={() => setErrorMessage("")}
    status={MODAL_STATUSES.ERROR}
    onConfirm={() => setErrorMessage("")}
  />
)

SomethingWentWrongModal.propTypes = {
  text: PropTypes.string,
  setErrorMessage: PropTypes.func,
}
