import styled from "styled-components"

import { FontWeight, SubtitleS } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  background-color: var(--white);
  border: 1px solid var(--lightGray5);
  border-radius: 24px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 11px 14px;
`

export const Number = styled(SubtitleS)`
  background-color: var(--darkBlue);
  border-radius: 50%;
  color: var(--white);
  font-weight: ${FontWeight.medium};
  padding: 2px 7px;
`
