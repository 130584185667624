import React, { Fragment } from "react"

import { useMediaQuery } from "@mui/material"
import { connect } from "react-redux"

import { SvgIcon, NewTooltip } from "@components/common"
import {
  selectActiveTeeup,
  selectMessagesWithoutNugdes,
} from "@selectors/activeTeeup"
import { selectUserId } from "@selectors/user"
import { MEDIA_SIZES } from "assets/styles/mediaSizes"

import { useActions } from "./hooks"
import { ActionsList, ActionItem, ActionTitle } from "./teeupQuickActionsStyles"

const TeeupQuickActions = ({
  activeTeeupSettings,
  userId,
  activeTeeup,
  messages,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${MEDIA_SIZES.MOBILE})`)
  const actions = useActions({
    activeTeeupSettings,
    activeTeeup,
    userId,
  })

  const getIsDisabled = action => {
    return messages.length === 0 || action.isDisabled
  }

  const actionClickHandler = action => {
    if (getIsDisabled(action)) return

    action.onClick()
  }
  if (isMobile) return false

  return (
    <ActionsList>
      {actions.map(action => (
        <Fragment key={action.id}>
          {getIsDisabled(action) && action.tooltip && (
            <NewTooltip key={`tip_${action.id}`} />
          )}

          <ActionItem
            data-tip={action.tooltip}
            data-testid={action.testId}
            $isDisabled={getIsDisabled(action)}
            onClick={() => actionClickHandler(action)}
            key={action.id}>
            <SvgIcon name={action.iconName} color="var(--gray5)" />

            <ActionTitle>{action.title}</ActionTitle>
          </ActionItem>
        </Fragment>
      ))}
    </ActionsList>
  )
}

const mapStateToProps = state => {
  const activeTeeup = selectActiveTeeup(state)

  return {
    activeTeeup,
    activeTeeupSettings: activeTeeup?.settings,
    userId: selectUserId(state),
    messages: selectMessagesWithoutNugdes(state),
  }
}

export default connect(mapStateToProps)(TeeupQuickActions)
