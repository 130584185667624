import React from "react"

import Modal from "react-modal"
import { ReactSVG } from "react-svg"

import closeIcon from "../../../../assets/images/close.svg"

const ImagePreviewModal = ({ isOpen, closeModal, image }) => {
  const modalStyles = {
    content: {
      border: "none",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: "1000",
    },
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      ariaHideApp={false}>
      <ReactSVG
        src={closeIcon}
        className="close-img-preview "
        onClick={closeModal}
      />
      <img className="img-preview-modal" src={image} />
    </Modal>
  )
}

export default ImagePreviewModal
