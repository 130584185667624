import React from "react"

import ReactDom from "react-dom"
import "./modal.scss"

const Modal = ({ children }) => {
  return ReactDom.createPortal(
    <div className="modal-wrapper">
      {/* <div className="modal-backdrop"> */}
      <div className="modal">{children}</div>
      {/* </div> */}
    </div>,
    document.querySelector("#modal-root")
  )
}

export default Modal
