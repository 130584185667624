import React from "react"

import _ from "lodash"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { View, Text, TouchableOpacity, Image, SectionList } from "react-native"
import { connect } from "react-redux"
// import LinearGradient from 'react-native-linear-gradient'
import ReactTooltip from "react-tooltip"

import { reactionTypes } from "@configs/mappings"
import { selectTeeupReactions } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { stringAlphabeticalComparator } from "@utils/dataUtils"
import {
  getCalendarFormat,
  isDateBeforeNow,
  compareTimeStrings,
} from "@utils/dateUtils"
import {
  isNewSuggestion,
  isRemovedSuggestion,
  GAMEPLAN_OPTIONS,
  locationTypeIds,
  isPastSuggestion,
} from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import { isMultizoneTeeup } from "@utils/teeupUtils"
// import {
//     getDistanceByCoordinates,
//     maxDistance,
//     getCurrentLocation,
// } from '@utils/location'
// import { showActionsheet } from '@nav'

import threeDotsIcon from "../../assets/images/three-dots.svg"

import SortSuggestionsTooltip from "./SortSuggestionsTooltip"
import DraggableSuggestionRow from "./draggableSuggestionRow"
import { GameplansRowStyles } from "./gameplansStyles"

const hasSelectedSuggestion = suggestions => {
  let selectedSuggestionIndex = false
  suggestions.map(({ selected }, index) => {
    if (selected) {
      selectedSuggestionIndex = index
    }
  })
  return selectedSuggestionIndex
}

const SORTBY_SOONEST = "soonest"
const SORTBY_DATE = "date"
const SORTBY_LIKES = "likes"
const SORTBY_CLOSEST = "closest"

const sortTitles = {
  date: "Most Recent",
  likes: "Most Likes",
  soonest: "Soonest",
  closest: "Closest to current location",
}

const getValueForSortByClosest = (
  { locationType, googlePlaceId, latitude, longitude },
  currentLocation
) => {
  if (
    locationType !== locationTypeIds.realWorld ||
    !googlePlaceId ||
    !latitude ||
    !longitude
  ) {
    return 0 - 10
  }
  // const distance = getDistanceByCoordinates(
  //     { latitude, longitude },
  //     currentLocation
  // )
  const distance = 10
  // return negative distance for descending sort
  return 0 - distance
}

function isActiveSuggestion(suggestion) {
  const isPast = isPastSuggestion(suggestion, "day")
  if (isPast) {
    return false
  }

  if (suggestion.isWithdrawn) {
    return false
  }

  return true
}

function getSections(suggestions, reactions, sortBy) {
  const activeSuggestions = suggestions.filter(isActiveSuggestion)
  const pastSuggestions = suggestions.filter(isPastSuggestion)
  const withdrawnSuggestions = suggestions.filter(
    suggestion => suggestion.isWithdrawn
  )

  const activeData = getSortedSuggestions(activeSuggestions, reactions, sortBy)
  const pastData = getSortedSuggestions(pastSuggestions, reactions, sortBy)
  const withdrawnData = getSortedSuggestions(
    withdrawnSuggestions,
    reactions,
    sortBy
  )

  const title = _.isEmpty(pastData)
    ? "Withdrawn Suggestions"
    : _.isEmpty(withdrawnSuggestions)
    ? "Past Suggestions"
    : "Withdrawn and Past Suggestions"

  return [
    {
      key: "active",
      data: activeData,
    },
    {
      key: "past",
      data: [...withdrawnData, ...pastData],
      title,
    },
  ]
}

function getSortedSuggestions(suggestions, reactions, sortBy) {
  let sortedSuggestions = suggestions

  switch (sortBy) {
    case SORTBY_DATE: {
      // return negative id for descending sort
      sortedSuggestions = _.sortBy(suggestions, ({ id }) => -id)

      break
    }
    case SORTBY_LIKES: {
      sortedSuggestions = _.sortBy(suggestions, ({ id }) => {
        const suggestionReactions = reactions[id] || []
        const likes = suggestionReactions.find(
          ({ reactionId }) => reactionId === reactionTypes.upvote
        )
        const dislikes = suggestionReactions.find(
          ({ reactionId }) => reactionId === reactionTypes.downvote
        )
        const numLikes = likes ? likes.reactedBy.length : 0
        const numDislikes = dislikes ? dislikes.reactedBy.length : 0
        // return negative likes for descending sort
        return numDislikes - numLikes
      })

      break
    }
    case SORTBY_SOONEST: {
      sortedSuggestions = sortedSuggestions.sort((a, b) => {
        if (!a.startDate || !b.startDate) {
          return 0
        }
        if (isDateBeforeNow(a.startDate)) {
          return -1
        }
        return compareTimeStrings(a.startDate, b.startDate) ? -1 : 1
      })

      break
    }
    case SORTBY_CLOSEST: {
      // const currentLocation = getCurrentLocation()
      const currentLocation = "type"

      sortedSuggestions = sortedSuggestions.sort((suggestion1, suggestion2) => {
        const distance1 = getValueForSortByClosest(suggestion1, currentLocation)
        const distance2 = getValueForSortByClosest(suggestion2, currentLocation)
        if (distance1 === distance2) {
          // sort alphabetically
          const compareValues = stringAlphabeticalComparator(
            suggestion1.value,
            suggestion2.value
          )
          return compareValues === 0
            ? stringAlphabeticalComparator(
                suggestion1.details,
                suggestion2.details
              )
            : compareValues
        }
        return distance1 < distance2 ? 1 : -1
      })

      break
    }
    // No default
  }
  return sortedSuggestions
}

class SuggestionSectionContent extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { suggestions, reactions, newSuggestions } = nextProps
    const updates = {}
    if (
      suggestions !== prevState.suggestions ||
      reactions !== prevState.reactions ||
      newSuggestions !== prevState.newSuggestions
    ) {
      updates.suggestions = suggestions
      updates.newSuggestions = newSuggestions
      updates.reactions = reactions
      updates.sections = getSections(suggestions, reactions, prevState.sortBy)
    }

    return updates
  }

  constructor(props) {
    super(props)

    const { suggestions, reactions } = props
    this.state = {
      suggestions,
      reactions,
      sortBy: SORTBY_DATE,
      sections: getSections(suggestions, reactions, SORTBY_DATE),
      sortTooltip: false,
      threeDots: false,
    }
    this.sortRef = React.createRef()
    this.handleChangeSortBy = this.handleChangeSortBy.bind(this)
  }

  handleClickSortBy = () => {
    const { sortBy, suggestions } = this.state
    const additionalSortOptions = []
    const showSoonest =
      suggestions.length && suggestions[0].type === GAMEPLAN_OPTIONS.when
    if (showSoonest) {
      additionalSortOptions.push({
        title: sortTitles[SORTBY_SOONEST],
        isSelected: sortBy === SORTBY_SOONEST,
        callback: () => this.handleChangeSortBy(SORTBY_SOONEST),
      })
    }
    const showClosest =
      suggestions.length && suggestions[0].type === GAMEPLAN_OPTIONS.where
    if (showClosest) {
      additionalSortOptions.push({
        title: sortTitles[SORTBY_CLOSEST],
        isSelected: sortBy === SORTBY_CLOSEST,
        callback: () => this.handleChangeSortBy(SORTBY_CLOSEST),
      })
    }
    // showActionsheet([
    //     ...additionalSortOptions,
    //     {
    //         title: sortTitles[SORTBY_DATE],
    //         isSelected: sortBy === SORTBY_DATE,
    //         callback: () => this.handleChangeSortBy(SORTBY_DATE),
    //     },
    //     {
    //         title: sortTitles[SORTBY_LIKES],
    //         isSelected: sortBy === SORTBY_LIKES,
    //         callback: () => this.handleChangeSortBy(SORTBY_LIKES),
    //     },
    // ])
  }

  handleChangeSortBy(sortBy) {
    if (sortBy === this.state.sortBy) return

    const { suggestions, reactions } = this.props
    this.setState({
      sortBy,
      sections: getSections(suggestions, reactions, sortBy),
    })
  }

  renderSectionHeader = ({ section }) => {
    const { sortBy, sections } = this.state
    const { key, data, title } = section
    if (key === "active" && data.length === 0) {
      return null
    }

    if (key === "past" && data.length === 0) {
      return null
    }
    const isListTitleShown =
      (key === "active" && data.length) ||
      (key === "past" && !sections[0]?.data?.length)

    return (
      <View
        style={[
          GameplansRowStyles.suggestionsHeader,
          { marginTop: "8px", marginBottom: "8px" },
        ]}>
        {isListTitleShown && data.some(isActiveSuggestion) && (
          <Text style={GameplansRowStyles.headerTitle}>
            {`${data.length} active suggestions`}
          </Text>
        )}
        {key === "active" ? (
          <>
            {ReactDOM.createPortal(
              <>
                <SortSuggestionsTooltip
                  handleChangeSortByProp={this.handleChangeSortBy}
                  text={"Working"}
                  SORTBY_SOONEST={SORTBY_SOONEST}
                  SORTBY_DATE={SORTBY_DATE}
                  SORTBY_LIKES={SORTBY_LIKES}
                  selectedSort={sortTitles[sortBy]}
                />
              </>,
              document.querySelector("#modal-root")
            )}
            <TouchableOpacity
              style={[
                GameplansRowStyles.sortButton,
                { backgroundColor: "#e1e1e1" },
              ]}
              onMouseOver={e => {
                e.stopPropagation()
                this.setState({ threeDots: true })
              }}
              onMouseLeave={e => {
                e.stopPropagation()
                ReactTooltip.hide(this.sortRef.current)
                this.setState({ threeDots: false })
              }}>
              {this.state.threeDots && this.props.userId && (
                <img
                  src={threeDotsIcon}
                  ref={this.sortRef}
                  data-tip={this.props.userId}
                  data-for="sortSuggestions-tooltip"
                  className="reactions-view"
                  onClick={e => {
                    e.stopPropagation()
                    ReactTooltip.show(this.sortRef.current)
                    this.setState({ sortTooltip: true })
                  }}
                  alt="dots"
                />
              )}
              <Image
                style={GameplansRowStyles.sortButtonImage}
                source={images.teeupsSort}
              />
              <Text style={GameplansRowStyles.sortButtonText}>
                {sortTitles[sortBy]}
              </Text>
              <View style={GameplansRowStyles.arrowUp}></View>
            </TouchableOpacity>
          </>
        ) : (
          <Text style={GameplansRowStyles.headerTitle}>{title}</Text>
        )}
      </View>
    )
  }

  renderSuggestionRow = ({ item: suggestion, index }) => {
    const {
      suggestions,
      people,
      type,
      suggestionMessages,
      newSuggestions,
      removedSuggestions,
      permissions,
      recommendations,
      handleOnLongPress = () => {},
      testID,
      isTeeupCancelled,
      isRowExpanded,
      draggingSuggestionId,
      sectionIndex,
      isActionSheetActive,
      onAddSuggestion,
    } = this.props

    const selectedSuggestionIndex = hasSelectedSuggestion(suggestions)

    let isNew = isNewSuggestion(newSuggestions, suggestion)
    let isRemoved = isRemovedSuggestion(removedSuggestions, suggestion)

    let name = ""
    if (suggestion.createdBy && people[suggestion.createdBy]) {
      const user = people[suggestion.createdBy]
      name = user?.username || user?.name
    }

    let commentsCount
    if (
      suggestionMessages &&
      suggestion.id &&
      suggestionMessages[suggestion.id]
    ) {
      commentsCount = suggestionMessages[suggestion.id].length
    }
    const recommendation =
      recommendations && recommendations[suggestion.recommendationId]

    const isMultizone = isMultizoneTeeup(people)

    return (
      <DraggableSuggestionRow
        type={type}
        key={"" + index}
        name={name}
        timestamp={getCalendarFormat(suggestion.timestamp)}
        commentsCount={commentsCount}
        suggestion={suggestion}
        isNew={isNew}
        isRemoved={isRemoved}
        initiateStatusUpdate
        canSelectGameplan={permissions.allowSelectGameplan}
        canDecideGameplan={permissions.allowDecideGameplan}
        canChangeGameplanOptions={permissions.allowChangeGameplanOptions}
        recommendation={recommendation}
        handleOnLongPress={handleOnLongPress}
        isTimeZoneEnabled={isMultizone}
        testID={`${testID}-suggestion-${index}`}
        isTeeupCancelled={isTeeupCancelled}
        suggestionIndex={index}
        selectedSuggestionIndex={selectedSuggestionIndex}
        isRowExpanded={isRowExpanded}
        isDragging={draggingSuggestionId === suggestion.id}
        sectionIndex={sectionIndex}
        customStyle={GameplansRowStyles.customSuggestionStyle}
        customContentStyle={GameplansRowStyles.customSuggestionStyle}
        isActionSheetActive={isActionSheetActive}
        suggestionFromList={suggestion}
        onAddSuggestion={onAddSuggestion}
      />
    )
  }

  render() {
    const { draggingSuggestionId, isSuggestionOpen } = this.props
    const { sections } = this.state

    return (
      <SectionList
        style={[
          GameplansRowStyles.whiteGreyContainer,
          isSuggestionOpen && { height: "calc(90vh - 247px)" },
        ]}
        sections={sections}
        extraData={draggingSuggestionId}
        keyExtractor={(item, index) => "" + index}
        renderItem={this.renderSuggestionRow}
        renderSectionHeader={this.renderSectionHeader}
      />
    )
  }
}

SuggestionSectionContent.propTypes = {
  suggestions: PropTypes.array.isRequired,
  people: PropTypes.object.isRequired,
  // type: PropTypes.string.isRequired,
  suggestionMessages: PropTypes.object.isRequired,
  newSuggestions: PropTypes.object.isRequired,
  removedSuggestions: PropTypes.array,
  isDisabled: PropTypes.bool.isRequired,
  handleOnLongPress: PropTypes.func,
  recommendations: PropTypes.object,
  isTeeupCancelled: PropTypes.bool,
  permissions: PropTypes.object,
  testID: PropTypes.string,
  isRowExpanded: PropTypes.bool,
  draggingSuggestionId: PropTypes.number,
  sectionIndex: PropTypes.number,
  // reactions: PropTypes.object,
  userId: PropTypes.number,
  isActionSheetActive: PropTypes.bool,
}

const mapStateToProps = state => {
  const userId = selectUserId(state)
  const reactions = selectTeeupReactions(state)
  const isSuggestionOpen = state.common.isSuggestionOpen

  return {
    userId,
    reactions,
    isSuggestionOpen,
  }
}

export default connect(mapStateToProps)(SuggestionSectionContent)
