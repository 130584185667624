import styled, { css } from "styled-components"

import {
  Subtitle,
  SubtitleS,
  FontWeight,
} from "@assets/styles/typographyStyles"

export const OutsideWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 5px;
  margin-top: 10px;
  width: 100%;
`

export const Wrapper = styled.div`
  background-color: #fbfaf8;
  border: 1px solid var(--lightGray5);
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  row-gap: 7px;
  column-gap: 7px;
  padding: 8px 12px;
  position: relative;
  color: var(--black2);

  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      width: 100%;
      min-height: 95px;
      height: 95px;
      border-radius: 8px;
      justify-content: space-between;
      align-items: center;
    `};
  ${({ $isCollapsed, $isPreview }) =>
    !$isCollapsed &&
    !$isPreview &&
    css`
      width: 331px;
      border-radius: 4px;
    `};
  ${({ $isWithdrawn }) =>
    $isWithdrawn &&
    css`
      background-color: var(--lightGray);
      border-color: var(--lightGray5);
      color: var(--gray4);
    `};
  ${({ $decidedInPopup }) =>
    $decidedInPopup &&
    css`
      background-color: var(--white);
    `};
  ${({ $isMap }) =>
    $isMap &&
    css`
      height: unset;
      padding: 8px 12px 8px 12px;
    `};

  ${({ $isDecidedInPreview }) =>
    $isDecidedInPreview &&
    css`
      background-color: var(--lightMint);
      color: var(--green2);
    `};

  ${({ $isPreview }) =>
    $isPreview &&
    css`
      background-color: var(--lightGray6);
    `};
`

export const LabelNewWrapper = styled.div`
  ${({ $isRight }) => css`
    background-color: var(--white);
    border: 1px solid var(--lightGray5);
    border-radius: 25px;
    padding: 2px 8px;
    position: absolute;
    bottom: 90%;
    ${$isRight ? "right" : "left"}: 10px;
  `};
`

export const LabelNewText = styled(SubtitleS)`
  ${({ $color }) => css`
    color: ${$color || "var(--gray4)"};
    font-weight: ${FontWeight.medium};
  `};
`

export const MainInfo = styled.div`
  ${({ $isCollapsed }) => css`
    width: ${$isCollapsed ? "92%" : "55%"};
    display: flex;
    align-items: center;
    column-gap: 7px;
  `};
`

export const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: auto;
`

export const ValuesWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

export const Value = styled(({ isCollapsed, ...props }) =>
  isCollapsed ? <SubtitleS {...props} /> : <Subtitle {...props} />
)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: ${FontWeight.bold};
    `};
  ${({ $color }) => css`
    color: ${$color};
  `};
`

export const Value2 = styled(SubtitleS)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: ${FontWeight.bold};
    `};
`

export const CreatorName = styled(SubtitleS)`
  color: var(--gray4);
  font-weight: ${FontWeight.bold};
  position: absolute;
  top: -20px;
`
