import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: var(--white);
  border-radius: 50%;
  overflow: hidden;

  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      width: 32px;
      min-width: 32px;
      height: 32px;
    `};
`

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
