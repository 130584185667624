import { teeupStatusKeys } from "@config/enums"
import { Stage, TEEUP_STATUS } from "types/teeup"

export const getStageOptions = (): Stage[] => [
  {
    label: teeupStatusKeys.planning,
    position: 0,
    isSelected: true,
    color: "#F9861A",
    backgroundColor: "#FEF3E8",
    labelToDisplay: "Planning",
    statusId: TEEUP_STATUS.PLANNING,
  },
  {
    label: teeupStatusKeys.allset,
    position: 1,
    isSelected: false,
    color: "#00C5AE",
    backgroundColor: "#E6F9F7",
    labelToDisplay: "All Set",
    statusId: TEEUP_STATUS.ALL_SET,
  },
  {
    label: teeupStatusKeys.happeningSoon,
    position: 2,
    isSelected: false,
    color: "#7B61FF",
    backgroundColor: "#F2EFFF",
    labelToDisplay: "Happening soon",
    isDisabled: true,
    statusId: TEEUP_STATUS.HAPPENING_SOON,
  },
  {
    label: teeupStatusKeys.happening,
    position: 3,
    isSelected: false,
    color: "#0AC6FF",
    backgroundColor: "#E7F9FF",
    labelToDisplay: "Happening now",
    statusId: TEEUP_STATUS.HAPPENING,
  },
  {
    label: teeupStatusKeys.ended,
    position: 4,
    isSelected: false,
    color: "#303030",
    backgroundColor: "#F5F5F5",
    labelToDisplay: "Ended",
    statusId: TEEUP_STATUS.ENDED,
  },
  {
    label: teeupStatusKeys.cancelled,
    position: 5,
    isSelected: false,
    color: "#303030",
    backgroundColor: "#F5F5F5",
    labelToDisplay: "Ended",
    isHidden: true,
    statusId: TEEUP_STATUS.CANCELLED,
  },
]

export const getStageOptionsByStatusId = () => {
  return {
    [TEEUP_STATUS.PLANNING]: {
      label: "Planning",
      className: "text-[#F9861A] border-[#FDD9B8] bg-[#FEF3E8]",
    },
    [TEEUP_STATUS.ALL_SET]: {
      label: "All Set",
      className: "text-[#00C5AE] border-[#B0EDE6] bg-[#E6F9F7]",
    },
    [TEEUP_STATUS.HAPPENING_SOON]: {
      label: "Happening Soon",
      className: "text-[#7B61FF] border-[#D6CEFF] bg-[#F2EFFF]",
    },
    [TEEUP_STATUS.HAPPENING]: {
      label: "Happening now",
      className: "text-[#0AC6FF] border-[#B3EDFF] bg-[#E7F9FF]",
    },
    [TEEUP_STATUS.ENDED]: {
      label: "Ended",
      className: "text-[#303030] border-[#E5E5E5] bg-[#FFFFFF]",
    },
    [TEEUP_STATUS.CANCELLED]: {
      label: "Cancelled",
      className: "text-[#EF4444] border-[#FAC5C5] bg-[#FDECEC]",
    },
  }
}

export const removedStageOption = {
  label: "You have been removed",
  className: "text-red-500 bg-rose-50 border-red-200",
}
