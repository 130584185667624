import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  list-style: none;
`

export const ListItem = styled.li`
  background-color: var(--white);
  border: 1px solid var(--gray3);
  border-radius: 4px;

  display: flex;
  align-items: center;
  column-gap: 4px;

  padding: 8px 12px;
`

export const Label = styled(NSubtitleS)`
  color: var(--black2);
  font-weight: ${NFontWeight.medium};
`

export const Remove = styled(SvgIcon)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  &:active {
    opacity: 0.4;
  }
`
