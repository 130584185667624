import React from "react"

import Button from "@mui/material/Button"
import PropTypes from "prop-types"

import { ButtonEl } from "@components/common"
import { BUTTON_SIZES, BUTTON_VARIANTS } from "@constants/button"

import { Icon } from "./goBackStyles"

export const GoBack = ({ text, onClick }) => (
  <Button
    size={BUTTON_SIZES.MEDIUM}
    variant="text"
    onClick={onClick}
    sx={{
      color: "black",
      textTransform: "none",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 600,
    }}>
    <Icon name="arrow-2" color="var(--black)" />
    {text}
  </Button>
)

GoBack.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
}
