import { create } from "zustand"

import { Prediction } from "types/suggestions"

interface RecentMapSearchStore {
  add: (value: Prediction) => void
  remove: (placeId: string) => void
  recentAddresses: Prediction[]
}

const RECENT_SEARCH_LIMIT = 3

export const useRecentMapSearchStore = create<RecentMapSearchStore>()(set => ({
  add: value => {
    const recentAddresses = JSON.parse(
      localStorage.getItem("recentAddress") || "[]"
    )
    if (
      recentAddresses.some(
        (item: Prediction) => item.place_id === value.place_id
      )
    )
      return

    if (recentAddresses.length >= RECENT_SEARCH_LIMIT) {
      recentAddresses.shift()
    }

    const resentPrediction = { ...value, iconName: "refresh", isRecent: true }
    recentAddresses.push(resentPrediction)
    localStorage.setItem("recentAddress", JSON.stringify(recentAddresses))
    set({ recentAddresses })
  },
  remove: placeId => {
    const recentAddresses = JSON.parse(
      localStorage.getItem("recentAddress") || "[]"
    ) as Prediction[]

    const filteredRecent = recentAddresses.filter(
      item => item.place_id !== placeId
    )
    localStorage.setItem("recentAddress", JSON.stringify(filteredRecent))
    set({ recentAddresses: filteredRecent })
  },
  get recentAddresses() {
    return JSON.parse(localStorage.getItem("recentAddress") || "[]")
  },
}))
