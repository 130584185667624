import React, { useContext } from "react"

import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"

import { ButtonEl, OrBlock } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { GAMEPLAN_TYPES } from "@constants/gameplan"
import { selectGuestSuggestions } from "@selectors/guest"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { DecidedSuggestion } from "../NewTeeupGameplan/molecules"
import { RSVPSection, PreviewCollapsedSuggestions } from "components"

import { Wrapper, BottomContent, DecidedWrapper } from "./invitesSuggestStyles"
import { DescriptionInfo } from "./molecules"

const InvitesSuggest = ({ isFullWidth, isOnlySuggest, guestSuggestions }) => {
  const { gameplanInfo, stepsManager } = useContext(TeeupPreviewContext)
  const decidedSuggestion = gameplanInfo?.when.decidedSuggestion
  const suggestionsLength = guestSuggestions.length
  const btnText = decidedSuggestion
    ? "Suggest alternative"
    : suggestionsLength
    ? "Share meeting preferences"
    : "Make a Suggestion"

  const renderSuggestionsContent = () => {
    if (decidedSuggestion) {
      return (
        <DecidedWrapper>
          <DecidedSuggestion
            isPreview
            isBorderedPreview
            decidedSuggestion={decidedSuggestion}
          />
        </DecidedWrapper>
      )
    }

    return suggestionsLength ? (
      <PreviewCollapsedSuggestions type={GAMEPLAN_TYPES.when} isInvitees />
    ) : (
      <DescriptionInfo />
    )
  }

  const suggestionsActionHandle = () => {
    if (decidedSuggestion || !suggestionsLength) {
      stepsManager.jumpToStep(1)
      stepsManager.setIsMakeSuggestion(true)
    } else {
      stepsManager.goNextStep()
    }
  }

  return (
    <Wrapper
      $isFullWidth={isFullWidth}
      $isOnlySuggest={isOnlySuggest}
      $isSuggestions={suggestionsLength}>
      {renderSuggestionsContent()}

      <BottomContent $isDecidedSuggestion={!!decidedSuggestion}>
        {gameplanInfo ? (
          <ButtonEl
            fullWidth
            isBlack
            variant={BUTTON_VARIANTS.ROUNDED}
            text={btnText}
            iconName="arrow-right-2"
            onClick={suggestionsActionHandle}>
            {!gameplanInfo && <Skeleton width={200} borderRadius={20} />}
          </ButtonEl>
        ) : (
          <Skeleton width={"100%"} borderRadius={20} />
        )}

        {!isOnlySuggest && (
          <>
            <OrBlock />

            <RSVPSection isDecided={!!decidedSuggestion} />
          </>
        )}
      </BottomContent>
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  guestSuggestions: selectGuestSuggestions(state),
})

export default connect(mapStateToProps)(InvitesSuggest)

InvitesSuggest.propTypes = {
  isFullWidth: PropTypes.bool,
  isOnlySuggest: PropTypes.bool,
}
