import React from "react"

import PropTypes from "prop-types"
import { View } from "react-native"

import { isToday, getDateWithTimezone, isDateBeforeNow } from "@utils/dateUtils"

import DayOnMonthCalendarView from "./DayOnMonthCalendarView"
import { MonthCalendarTimetableStyles as styles } from "./styles"

const MonthCalendarTimetableView = ({
  selectedDate,
  hasNewSuggestionProps,
  daysOfTheMonth,
  eventsList,
  conflictsDetected,
  selectDay,
  monthCellWidth,
}) => {
  const selectedDateObj = getDateWithTimezone(selectedDate)
  console.log("eventsList", eventsList)
  return (
    <View style={styles.container}>
      {daysOfTheMonth.map((week, index) => (
        <View
          key={index}
          style={[
            styles.rowContainer,
            index === daysOfTheMonth.length - 1 && styles.lastBlockBorder,
          ]}>
          {week.map((day, i) => {
            const date = day.format("YYYY-MM-DD")
            const isThisMonth = day.isSame(selectedDateObj, "month")
            const today = isToday(day)
            const isSelectedTimeForSuggestWhen = day.isSame(
              selectedDateObj,
              "day"
            )
            return (
              <DayOnMonthCalendarView
                key={date}
                date={day}
                monthCellWidth={monthCellWidth}
                eventsList={eventsList[date]}
                conflictsDetected={conflictsDetected[date]}
                isLastInRow={i === week.length - 1}
                isDateDisabled={isDateBeforeNow(date) && !today}
                isThisMonth={isThisMonth}
                isToday={today}
                hasNewSuggestionProps={hasNewSuggestionProps}
                isSelectedTimeForSuggestWhen={isSelectedTimeForSuggestWhen}
                onPress={selectDay(date)}
              />
            )
          })}
        </View>
      ))}
    </View>
  )
}

MonthCalendarTimetableView.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  eventsList: PropTypes.object,
  conflictsDetected: PropTypes.object,
  totalWidth: PropTypes.number,
  daysOfTheMonth: PropTypes.array,
  selectDay: PropTypes.func,
  hasNewSuggestionProps: PropTypes.bool,
}

export default MonthCalendarTimetableView
