import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const SuggestionWrapper = styled.div`
  background-color: var(--white);
  padding: 20px 20% 45px 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    width: 100% !important;
    max-width: 400px !important;
  }
`
