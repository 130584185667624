import styled from "styled-components"

import {
  NFontWeight,
  NCaption,
  NTitle6,
} from "@assets/styles/newTypographyStyles"

export const Title = styled(NTitle6)`
  font-weight: ${NFontWeight.bold};
`

export const Description = styled(NCaption)`
  color: var(--gray4);
  font-weight: ${NFontWeight.medium};
  margin-top: 3px;
`
