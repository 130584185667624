import { useRef } from "react"
import ArchiveUndo from "./ArchiveUndo"
import Filters from "./Filters"
import Tabs from "./Tabs"
import TeeUpList from "./TeeUpList"
import { useFetchTeeUps } from "./hooks/useFetchTeeUps"
import useFetchUsers from "./hooks/useFetchUsers"
import useFetchCalendarEvents from "./hooks/useFetchCalendarEvents"
import UnderlineModal from "./TeeUpList/Modals/UnderlineModal"

// TODO: remove TeeUps folder in the future, rename TeeUpsNd to TeeUps
const TeeUps = () => {
  const { fetchPaginatedTeeups } = useFetchTeeUps()
  useFetchUsers()
  useFetchCalendarEvents()

  return (
    <div
      className="relative !overflow-x-hidden max-h-[100vh] pb-4"
      id="infinite-scroll-target">
      <Tabs />
      <Filters />
      <TeeUpList fetchTeeUps={fetchPaginatedTeeups} />

      <ArchiveUndo />
      <UnderlineModal />
    </div>
  )
}

export default TeeUps
