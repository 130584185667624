import React, { createContext, useEffect, useMemo, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { fetchBookContacts } from "@actions/contactActions"
import { fetchRequests } from "@actions/requestActions"
import { contactsTabsList } from "@constants/contacts"
import { selectAllContacts, selectBookContacts } from "@selectors/contacts"
import { getContactsByCategories } from "@utils/getContactsByCategories"
import { getContactsWithTags, getContacts } from "actions/createTeeupActions"

import { useFilteredContacts } from "./hooks"

export const InvitePeopleContext = createContext({})

export const InvitePeopleState = ({ children }) => {
  const dispatch = useDispatch()
  const bookContacts = useSelector(selectBookContacts)
  const cooweContacts = useSelector(selectAllContacts)
  const [notFoundEmails, setNotFoundEmails] = useState([])
  const [activeTab, setActiveTab] = useState(contactsTabsList[0])
  const [searchValue, setSearchValue] = useState("")
  const { filteredBookContacts, filteredCooweContacts } = useFilteredContacts(
    bookContacts,
    cooweContacts
  )

  useEffect(() => {
    dispatch(getContacts())
    dispatch(fetchRequests())
  }, [])

  const contactsCategory = useMemo(() => {
    return getContactsByCategories({
      activeTab,
      bookContacts: filteredBookContacts,
      cooweContacts: filteredCooweContacts,
    })
  }, [activeTab, bookContacts, cooweContacts])

  return (
    <InvitePeopleContext.Provider
      value={{
        activeTab,
        setActiveTab,
        searchValue,
        setSearchValue,
        filteredContacts: [...filteredBookContacts, ...filteredCooweContacts],
        contactsCategory,
        notFoundEmails,
        setNotFoundEmails,
      }}>
      {children}
    </InvitePeopleContext.Provider>
  )
}
