import { Fragment } from "react/jsx-runtime"

import { SvgIcon } from "@components/common"
import { Button } from "@components/ui/ButtonNd"
import {
  GeneralDateSuggestion,
  OnlineSuggestion,
  Prediction,
  TimeWheelSuggestion,
} from "types/suggestions"

import "./index.scss"

type Suggestion =
  | OnlineSuggestion
  | GeneralDateSuggestion
  | TimeWheelSuggestion
  | { name: string; address: Prediction | null }

interface Props {
  hideDecideButton?: boolean
  isShown: boolean
  onAddAnotherClick: () => void
  renderPill: (suggestion: Suggestion, index: number) => JSX.Element
  onDecideClick: () => void
  onSuggestClick: () => void
  defaultPill: JSX.Element
  suggestions: Suggestion[]
  className?: string
}

const YourSuggestions = ({
  hideDecideButton,
  isShown,
  onAddAnotherClick,
  renderPill,
  onDecideClick,
  onSuggestClick,
  defaultPill,
  suggestions,
  className,
}: Props) => {
  const isSuggestionsExist = suggestions.length > 0
  const isDecidedHidden = suggestions.length > 1

  if (!isShown) return false

  return (
    <div className="suggestion-where-your-suggestions">
      <div>
        <h4>Your Suggestions</h4>
        <Button variant="text" onClick={onAddAnotherClick}>
          +Add another
        </Button>
      </div>

      <ul>
        {!isSuggestionsExist && defaultPill}

        {isSuggestionsExist &&
          suggestions.map((suggestion, index) => (
            // @ts-ignore
            <Fragment key={suggestion?.name || suggestion?.label}>
              {renderPill(suggestion, index)}
            </Fragment>
          ))}
      </ul>

      <div>
        {!hideDecideButton && !isDecidedHidden && (
          <Button
            variant="text"
            onClick={onDecideClick}
            disabled={isDecidedHidden}>
            Decide on this
          </Button>
        )}
        <Button variant="contained" onClick={onSuggestClick}>
          {isDecidedHidden
            ? `Post ${suggestions.length} suggestions`
            : "Suggest"}{" "}
          <SvgIcon name="arrow" color="#fff" />
        </Button>
      </div>
    </div>
  )
}

export default YourSuggestions
