import styled, { css } from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.section`
  width: 100%;
  background-color: var(--white);
  border-radius: 12px 12px 0 0;
  padding-top: 65px;
`

export const InputBlock = styled.section`
  background-color: var(--white);
  position: relative;
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const TitleLabel = styled.label`
  font-weight: ${NFontWeight.bold};
  margin-left: 15px;
`

export const TitleInput = styled.input`
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: ${NFontWeight.bold};
  padding: 15px 70px 15px 15px;
  outline: none;
`

export const PencilIcon = styled(SvgIcon)`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(calc(-50% + 10px));
`
