import React from "react"

import { View } from "react-native"

import { TimeWheelContent } from "../../TimeWheelContent"
import { GameplanStyles } from "../createTeeupStyles"

export const TimeDateTab = () => (
  <View style={GameplanStyles.flex}>
    <TimeWheelContent />
  </View>
)
