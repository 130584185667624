import React from "react"

import { useHistory } from "react-router-dom"

import { ForgotPasswordText } from "./molecules"

import "./forgotPasswordLink.scss"

export const ForgotPasswordLink = () => {
  const history = useHistory()

  const redirectToForgot = () => {
    history.push("/forgot-password")
  }

  return (
    <ForgotPasswordText onClick={redirectToForgot}>
      Forgot Password?
    </ForgotPasswordText>
  )
}
