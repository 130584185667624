import React from "react"

import CooWeLogo from "../../../assets/images/CooWeWhiteLogo.svg"
import { Subtitle } from "../../../assets/styles/typographyStyles"
import { FooterSocialNetworks } from "../../common"
import { FooterEl, FooterLogo } from "../makesMeetingHappenStyles"

export const Footer = () => (
  <FooterEl>
    <FooterLogo src={CooWeLogo} alt="CooWe" />

    <Subtitle style={{ color: "#fff" }}>
      © 2023 CooWe. All rights reserved.
    </Subtitle>

    <FooterSocialNetworks />
  </FooterEl>
)
