import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme/"

export const BaseDialogButtonStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  centerAligned: {
    justifyContent: "center",
    alignItems: "center",
  },
  widthAligned: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonText: {
    ...AppFonts.smallExtraBold,
    textTransform: "uppercase",
    letterSpacing: Units.responsiveValue(0.49),
    color: AppColors.brand.darkGrey2,
  },
  doneButtonText: {
    paddingRight: Units.responsiveValue(16),
  },
  buttonIconColor: {
    tintColor: AppColors.brand.warmGrey2,
  },
  activeButtonText: {
    color: AppColors.brand.white,
  },
  primaryIconContainer: {
    width: Units.responsiveValue(16),
    height: Units.responsiveValue(16),
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.responsiveValue(4),
  },
  primaryIcon: {
    width: Units.responsiveValue(12),
    height: Units.responsiveValue(12),
  },
  secondaryIcon: {
    height: Units.responsiveValue(40),
    width: Units.responsiveValue(20),
    marginLeft: Units.responsiveValue(8),
    marginRight: Units.responsiveValue(16),
  },
  inactivaIcon: {},
  activeIconColor: {
    tintColor: AppColors.brand.white,
  },
  separator: {
    width: Units.responsiveValue(0.9),
    height: Units.responsiveValue(30),
    marginLeft: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.warmGrey2_50,
  },
  activeSeparator: {
    backgroundColor: AppColors.brand.white50,
  },
  amountButtonContainer: {
    paddingHorizontal: Units.responsiveValue(6),
  },
  amountTextContainer: {
    marginHorizontal: Units.responsiveValue(4),
  },
  amountText: {
    ...AppFonts.xlDemibold,
    color: AppColors.brand.black2,
    textAlign: "center",
  },
  amountChangingButton: {
    width: Units.responsiveValue(40),
    height: Units.responsiveValue(40),
  },
  amountChangingIcon: {
    width: Units.responsiveValue(40),
    height: Units.responsiveValue(40),
  },
  disabledButton: {
    backgroundColor: AppColors.brand.grey247,
    cursor: "not-allowed",
  },
})

export const CloseButtonStyles = StyleSheet.create({
  closeButtonPosition: {
    position: "absolute",
    top: Units.responsiveValue(0),
    right: Units.responsiveValue(0),
  },
  closeButton: {
    width: Units.responsiveValue(42),
    height: Units.responsiveValue(42),
    paddingTop: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(8),
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  closeIcon: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(20),
  },
})

export const dialogButtonContainer = includesAlreadyResponded => ({
  minWidth: includesAlreadyResponded ? Units.responsiveValue(200) : "auto",
  width: includesAlreadyResponded ? "auto" : Units.responsiveValue(184),
  height: Units.responsiveValue(40),
  borderRadius: Units.responsiveValue(22),
  paddingLeft: Units.responsiveValue(16),
  backgroundColor: AppColors.brand.white,
  border: "1px solid var(--gray3)",
  textAlign: "center",
})

export const borderOnHover = (borderColor, isItemHovered) => ({})
