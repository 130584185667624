import styled, { css } from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  border-bottom: 1px solid var(--lightGray5);
  display: flex;

  ${({ $isSideBorder }) =>
    $isSideBorder &&
    css`
      border-right: 1px solid var(--lightGray5);
      border-left: 1px solid var(--lightGray5);
    `};
`

export const Tab = styled.div`
  ${({ $isActive }) => css`
    border-bottom: 2px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    padding: 15px 10px;
    transition: transform 0.2s ease-in-out;

    ${$isActive &&
    css`
      border-color: var(--black);
    `}
  `};
`

export const TabText = styled(NSubtitleS)`
  ${({ $isActive }) => css`
    color: var(--gray);
    transition: transform 0.2s ease-in-out;

    ${$isActive &&
    css`
      color: var(--gray6);
      font-weight: ${NFontWeight.bold};
    `};
  `};
`
