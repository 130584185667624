const environments = {
  dev: {
    endpoint: "dev-api",
    envName: "DEV",
  },
  stg: {
    endpoint: "stg-api",
    envName: "STG",
  },
  prod: {
    endpoint: "prod-api",
    envName: "PROD",
  },
}

export const checkEnv = () => {
  const envValues = Object.values(environments)
  const currentEnv = envValues.find(el =>
    process.env.REACT_APP_API_ENDPOINT.includes(el.endpoint)
  )

  return currentEnv ? currentEnv : environments.prod
}

export const getCurrentEnvName = () => checkEnv()?.envName

export const isProdEnv = () => getCurrentEnvName() === environments.prod.envName
