import React, { useContext } from "react"

import { Auth0ErrorContext } from "../../contexts/Auth0ErrorContext"
import { AuthLayout } from "../../hoc"
import { AUTH0_ERRORS } from "../../pages/Auth0ContentPage/constants"

import { ErrorMessage } from "./atoms"
import { SubmitButtons } from "./molecules"

export const Auth0Errors = () => {
  const {
    title,
    message,
    navBtnText,
    auth0Error,
    secondBtnText,
    secondBtnAction,
  } = useContext(Auth0ErrorContext)

  return (
    <section className="auth0-errors--wraper">
      {auth0Error?.code !== AUTH0_ERRORS.TRY_EMAIL && (
        <>
          <AuthLayout title={title}>
            <ErrorMessage errorMessage={message} />
          </AuthLayout>

          <SubmitButtons
            navBtnText={navBtnText}
            secondBtnText={secondBtnText}
            secondBtnAction={secondBtnAction}
            auth0Error={auth0Error}
          />
        </>
      )}
    </section>
  )
}
