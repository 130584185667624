import { useEffect, useState } from "react"

import cn from "classnames"

import { SvgIcon } from "@components/common"
import { useSuggestionTimeWheelStore } from "stores/suggestions/suggestionTimeWheelStore"
import dayjs from "utils/dayjs"

import "./index.scss"
import { useSelector } from "react-redux"

import { selectUserTimezone } from "@selectors/user"

const Dates = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null)
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null)
  const [datesRange, setDatesRange] = useState<dayjs.Dayjs[]>([])
  const currentDate = useSuggestionTimeWheelStore(state => state.currentDate)
  const setCurrentDate = useSuggestionTimeWheelStore(
    state => state.setCurrentDate
  )
  const triggerDatesRangeChange = useSuggestionTimeWheelStore(
    state => state.triggerDatesRangeChange
  )
  const isLastWeekDisabled = dayjs().isSameOrAfter(
    currentDate?.subtract(1, "week")
  )
  const updateGenerateDatesTrigger = useSuggestionTimeWheelStore(
    state => state.updateGenerateDatesTrigger
  )
  const userTimezone = useSelector(selectUserTimezone)

  useEffect(() => {
    handleDateChange()
  }, [])

  useEffect(() => {
    if (!triggerDatesRangeChange) return

    handleDateChange(currentDate, true)
  }, [triggerDatesRangeChange])

  const handleDateChange = (
    date?: dayjs.Dayjs | null,
    isTriggered?: boolean
  ) => {
    const currentDate = date
      ? dayjs(date).tz(userTimezone)
      : dayjs().tz(userTimezone)
    const startDate = currentDate.subtract(3, "days")
    const endDate = currentDate.add(3, "days")

    const diff = endDate.diff(startDate, "days")
    if (!isTriggered) {
      setCurrentDate(currentDate)
      updateGenerateDatesTrigger()
    }
    setDatesRange(
      Array.from({ length: diff + 1 }, (_, i) => startDate.add(i, "days"))
    )
    setEndDate(endDate)
    setStartDate(startDate)
  }

  return (
    <div className="dates-container">
      <div className="title">
        <SvgIcon
          className={cn({ disabled: isLastWeekDisabled })}
          name="arrow-2"
          onClick={() =>
            !isLastWeekDisabled &&
            handleDateChange(currentDate?.subtract(1, "week"))
          }
        />
        <span>
          {startDate?.format("MMM, Do")}
          {" - "}
          {endDate?.format("MMM, Do")}
        </span>
        <SvgIcon
          name="arrow-2"
          onClick={() => handleDateChange(currentDate?.add(1, "week"))}
        />
      </div>

      <div className="range">
        {datesRange.map((date, index) => {
          const isDisabled = date.isBefore(dayjs(), "day")
          return (
            <div
              key={index}
              onClick={() => !isDisabled && handleDateChange(date)}
              className={cn("range-item", {
                "range-item__selected": date.isSame(currentDate, "day"),
                "range-item__disabled": isDisabled,
              })}>
              <div>{date?.format("dd")}</div>
              <div>{date.format("D")}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Dates
