import React from "react"

import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import { EVENT_TYPES } from "@utils/calendarUtils"
import { getDateWithTimezone } from "@utils/dateUtils"

import { Units } from "../../../theme/"
import CalendarEventBlock from "../eventBlocks/calendarEventBlock"
import TeeupEventBlock from "../eventBlocks/teeupBlock"

const ROW_HEIGHT = Units.responsiveValue(50)

const DayCalendarEvents = ({
  eventsList,
  onCreateTeeup,
  allowCreateTeeup,
  columnsAmount,
  totalWidth,
  showIcons = true,
  compacted = false,
}) => {
  const history = useHistory()

  const getEventPositioning = event => {
    const columnWidth = totalWidth / columnsAmount
    const startPosition =
      getDateWithTimezone(event.startDate).hour() +
      getDateWithTimezone(event.startDate).minute() / 60
    const endPosition =
      getDateWithTimezone(event.endDate).hour() +
      getDateWithTimezone(event.endDate).minute() / 60
    return {
      height: (endPosition - startPosition) * ROW_HEIGHT,
      width: event.width * columnWidth,
      top: startPosition * ROW_HEIGHT,
      left: (event.startColumn - 1) * columnWidth,
    }
  }
  return (
    <>
      {eventsList.map(event => {
        const props = {
          title: event.name,
          positionStyles: getEventPositioning(event),
          hasConflist: event.hasConflict,
          coverLastColumn:
            event.startColumn + event.width - 1 === columnsAmount,
          compacted,
          isTemporaryEvent: event.isTemporaryEvent,
        }
        if (event.type === EVENT_TYPES.calendar) {
          return (
            <CalendarEventBlock
              key={event.id}
              {...props}
              showActionButton={allowCreateTeeup && showIcons}
              onClick={onCreateTeeup(event)}
            />
          )
        }
        return (
          <TeeupEventBlock
            onClick={() => history.push(`/teeUps/${event.id}`)}
            key={`${event.id}`}
            {...props}
            showIcons={showIcons}
          />
        )
      })}
    </>
  )
}

DayCalendarEvents.propTypes = {
  eventsList: PropTypes.array,
  onCreateTeeup: PropTypes.func,
  allowCreateTeeup: PropTypes.bool,
  columnsAmount: PropTypes.number,
  totalWidth: PropTypes.number,
  showIcons: PropTypes.bool,
  compacted: PropTypes.bool,
}

export default DayCalendarEvents
