import React, { useRef, useState } from "react"

import PropTypes from "prop-types"
import { TouchableOpacity, View, Image, Text } from "react-native"
import ReactTooltip from "react-tooltip"

import { Units } from "@theme"
import MessageTimestamp from "@ui/chat/messageTimestamp/messageTimestamp"
import { isWithIndent } from "@utils/chatUtils"
import { images } from "@utils/imageUtils"

import threeDotsIcon from "../../../assets/images/three-dots.svg"
import ThreeDotsIcon from "../../../assets/images/transparent-three-dots.svg"
import CommentedSuggestion from "../chat/commentedSuggestion/commentedSuggestion"
import Message from "../chat/message/message"
import MessageAvatar from "../chat/messageAvatar/messageAvatar"
import MessageSender from "../chat/messageSender/messageSender"

import styles, { thumbtackWrapper, pinnedStyle } from "./chatMessageStyles"

export const ChatMessage = React.forwardRef(
  (
    {
      expanded,
      toggleExpansion,
      isTeeupCancelled,
      isSenderAvatarVisible,
      useAvatarBorder,
      isSenderUsernameVisible,
      isSuggestion,
      suggestionStatus,
      avatar,
      initials,
      status,
      isInitialMessage,
      isMyMessage,
      isStatusMessage,
      isGamePlanSelect,
      messageType,
      isComment,
      suggestionName,
      suggestionType,
      suggestionCreatorName,
      goToSuggestion,
      name,
      children,
      timestamp,
      timestampDate,
      isTimestampsVisible,
      onUnpinMessage,
      isPinned,
      isImage,
      isFile,
      isReply,
      pinnedUserName,
      onContextMenu,
      openPreview,
      handleCloseAddFilesMenu = () => {},
      onMessageAvatarPress,
      isKeyboardActive,
      handleKeyboardClose,
      onSuggestionCommentScreen,
      id,
      messages,
      onPinboardScreen,
      showOnMentionsScreen,
      newDesign,
    },
    ref
  ) => {
    const showSender =
      (isSenderUsernameVisible || onUnpinMessage) &&
      !isReply &&
      !isComment &&
      !isSuggestion &&
      !isInitialMessage &&
      !isMyMessage &&
      !isGamePlanSelect

    const [threeDots, setThreeDots] = useState(false)
    const messageRef = useRef(null)
    const shouldShowMessageAction =
      !isInitialMessage &&
      !isGamePlanSelect &&
      !isStatusMessage &&
      !isSuggestion
    return (
      <div
        onMouseOver={() => {
          if (!shouldShowMessageAction) {
            return
          }
          setThreeDots(true)
        }}
        onMouseLeave={() => {
          if (!shouldShowMessageAction) {
            return
          }
          ReactTooltip.hide(messageRef.current)
          setThreeDots(false)
        }}
        style={{ paddingBottom: "10px" }}>
        <TouchableOpacity
          style={[
            styles.chatMessageContainer,
            isMyMessage && styles.myChatMessageContainer,
            isStatusMessage && styles.statusMessage,
            pinnedStyle(
              !onUnpinMessage && isPinned,
              isTeeupCancelled,
              onPinboardScreen,
              showOnMentionsScreen,
              newDesign
            ),
            isWithIndent(messageType) && styles.indentWrapper,
            styles.messagePadding,
          ]}
          onPress={() => {
            handleCloseAddFilesMenu()
            !expanded && toggleExpansion && toggleExpansion()
            isKeyboardActive && handleKeyboardClose && handleKeyboardClose()
          }}
          activeOpacity={1}>
          <View
            testID="chatMessage"
            style={[
              styles.chatMessage,
              isMyMessage && styles.myChatMessage,
              isStatusMessage && !isInitialMessage && styles.statusMessage,
            ]}>
            <View
              style={[
                styles.row,
                isMyMessage &&
                  !isInitialMessage && { flexDirection: "row-reverse" },
              ]}>
              {!isTimestampsVisible &&
                ((isInitialMessage && !isMyMessage) ||
                  (!isMyMessage && !isStatusMessage)) && (
                  <View style={styles.selfFlexEnd}>
                    <MessageAvatar
                      avatar={avatar}
                      initials={initials}
                      isAvatarVisible={isSenderAvatarVisible}
                      useAvatarBorder={useAvatarBorder}
                      status={status}
                      disabled={isTeeupCancelled}
                      onPress={onMessageAvatarPress}
                    />
                  </View>
                )}
              <View
                style={[
                  isMyMessage && !isInitialMessage && styles.chatMessage,
                  isStatusMessage &&
                    !isInitialMessage &&
                    !isInitialMessage &&
                    styles.statusMessage,
                  (showSender || isInitialMessage) && styles.senderSpace,
                  messages && { borderRadius: "20px" },
                ]}>
                <Message
                  ref={ref}
                  isMyMessage={isMyMessage}
                  timestamp={timestamp}
                  messageType={messageType}
                  isStatusMessage={isStatusMessage}
                  isDisabled={
                    isTeeupCancelled ||
                    isGamePlanSelect ||
                    (!expanded && !onContextMenu)
                  }
                  id={id}
                  isTeeupCancelled={isTeeupCancelled}
                  isSuggestion={isSuggestion}
                  suggestionStatus={suggestionStatus}
                  onLongPress={onContextMenu}
                  openPreview={openPreview}
                  isImage={isImage}
                  isFile={isFile}
                  isReply={isReply}
                  onSuggestionCommentScreen={onSuggestionCommentScreen}
                  messages={messages}
                  newDesign={newDesign}
                  isTimestampsVisible={isTimestampsVisible}
                  isInitialMessage={isInitialMessage}>
                  {(showSender || messages) && (
                    <MessageSender
                      name={name}
                      isAttachment={isImage || isFile}
                    />
                  )}
                  {isComment && (
                    <CommentedSuggestion
                      isMyMessage={isMyMessage}
                      name={name}
                      suggestionName={suggestionName}
                      suggestionType={suggestionType}
                      suggestionCreatorName={suggestionCreatorName}
                      goToSuggestion={goToSuggestion}
                      isDisabled={isTeeupCancelled}
                      onSuggestionCommentScreen={onSuggestionCommentScreen}
                      newDesign={newDesign}
                    />
                  )}
                  {children}
                </Message>
              </View>
              {isTimestampsVisible && (
                <MessageTimestamp
                  isInitialMessage={isInitialMessage}
                  isTimestampsVisible={isTimestampsVisible}
                  timestamp={timestamp}
                  timestampDate={isInitialMessage ? timestampDate : null}
                />
              )}
              {shouldShowMessageAction &&
                threeDots &&
                (newDesign ? (
                  <div
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: showSender || isInitialMessage ? "12px" : 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <img
                      src={ThreeDotsIcon}
                      ref={messageRef}
                      data-tip={id}
                      data-for="message-tooltip"
                      style={{
                        height: "14px",
                        width: "5px",
                      }}
                      onClick={() => ReactTooltip.show(messageRef.current)}
                      alt="send icon"
                    />
                  </div>
                ) : (
                  <img
                    src={threeDotsIcon}
                    ref={messageRef}
                    data-tip={id}
                    data-for="message-tooltip"
                    className="three-dots"
                    style={isMyMessage ? { left: "-5px" } : { right: "-5px" }}
                    onClick={() => ReactTooltip.show(messageRef.current)}
                    alt="three-dots"
                  />
                ))}
            </View>
            {isPinned && !onUnpinMessage && (
              <View style={styles.pinWrapper}>
                <View style={styles.rowWithPinIcon}>
                  <Text style={styles.pinnedUser}>
                    Pinned by {pinnedUserName}
                  </Text>
                  <Image
                    style={styles.pinIcon}
                    source={images.borderedThumbstack}
                  />
                </View>
              </View>
            )}
          </View>
          {onUnpinMessage && !newDesign ? (
            <TouchableOpacity
              style={thumbtackWrapper(isMyMessage, isReply, isSuggestion)}
              hitSlop={Units.hitSlop()}
              onPress={onUnpinMessage}>
              <Image style={styles.thumbtackIcon} source={images.thumbtack} />
            </TouchableOpacity>
          ) : null}
        </TouchableOpacity>
      </div>
    )
  }
)

ChatMessage.displayName = "ChatMessage"

ChatMessage.propTypes = {
  expanded: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isTeeupCancelled: PropTypes.bool.isRequired,
  avatar: PropTypes.string,
  isSenderAvatarVisible: PropTypes.bool,
  isSenderUsernameVisible: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  suggestionStatus: PropTypes.string,
  status: PropTypes.string,
  initials: PropTypes.string,
  isInitialMessage: PropTypes.bool.isRequired,
  isMyMessage: PropTypes.bool.isRequired,
  isStatusMessage: PropTypes.bool,
  isComment: PropTypes.bool.isRequired,
  suggestionName: PropTypes.string,
  suggestionType: PropTypes.string,
  suggestionCreatorName: PropTypes.string,
  messageType: PropTypes.string.isRequired,
  name: PropTypes.string,
  timestamp: PropTypes.string,
  timestampDate: PropTypes.string,
  pinnedUserName: PropTypes.string,
  isPinned: PropTypes.bool,
  isImage: PropTypes.bool,
  isFile: PropTypes.bool,
  isReply: PropTypes.bool,
  toggleExpansion: PropTypes.func.isRequired,
  goToSuggestion: PropTypes.func,
  onContextMenu: PropTypes.func.isRequired,
  openPreview: PropTypes.func,
  onUnpinMessage: PropTypes.func,
  handleCloseAddFilesMenu: PropTypes.func,
  onMessageAvatarPress: PropTypes.func,
  isTimestampsVisible: PropTypes.bool,
  leftSwipeButtons: PropTypes.array.isRequired,
  rightSwipeButtons: PropTypes.array.isRequired,
  onLeftSwipe: PropTypes.func,
  onSwipe: PropTypes.func,
  handleKeyboardClose: PropTypes.func,
  isKeyboardActive: PropTypes.bool,
  isSwipeoutClosed: PropTypes.bool,
  isGamePlanSelect: PropTypes.bool,
  onSuggestionCommentScreen: PropTypes.bool,
  useAvatarBorder: PropTypes.bool,
}
