import React, { useContext } from "react"

import { SelectImage } from "@components"
import { TEEUP_SETTINGS_TYPES } from "@constants/teeups"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"

import { updateTeeup } from "../../middlewares/backendMiddleware"

import {
  Header,
  MainSettingsScreen,
  ParticipantsList,
  PermissionsScreen,
} from "./molecules"
import { Wrapper, SelectImageWrapper } from "./newTeeupSettingsStyles"

export const NewTeeupSettings = () => {
  const { teeup, settingsType, setSettingsType } =
    useContext(NewTeeupPageContext)

  const handleSetImage = val => {
    updateTeeup({
      teeUpId: teeup.id,
      teeup: { photo: val },
    })
    setSettingsType(null)
  }

  const renderContent = () => {
    switch (settingsType) {
      case TEEUP_SETTINGS_TYPES.SELECT_IMAGE:
        return (
          <SelectImageWrapper>
            <SelectImage setImage={handleSetImage} />
          </SelectImageWrapper>
        )
      case TEEUP_SETTINGS_TYPES.PERMISSIONS:
        return <PermissionsScreen />
      case TEEUP_SETTINGS_TYPES.PARTICIPANTS:
        return <ParticipantsList />
      default:
        return <MainSettingsScreen />
    }
  }

  return (
    <Wrapper $isBottomPadding={!settingsType}>
      <Header />

      {renderContent()}
    </Wrapper>
  )
}
