import React, { useMemo } from "react"

import Modal from "react-modal"
import OutsideClickHandler from "react-outside-click-handler"

import ErrorIcon from "@assets/images/shadow-error.svg"
import SuccessIcon from "@assets/images/success.svg"
import WarningIcon from "@assets/images/warning-shadow.svg"
import { MODAL_STATUSES } from "@constants/modal"

import {
  mobileStyles,
  modalBottomStyles,
  modalStyles,
  tabletStyles,
} from "./constants"
import { ModalHeader, NewModalHeader, ModalFooter } from "./molecules"
import {
  ContentWrapper,
  Description,
  Title,
  StatusIcon,
  CustomIcon,
  CloseBtn,
  CloseIcon,
} from "./newModalStyles"
import { useScreenWidth } from "@hooks"

// TODO: we had too much different modals in design, so we need make it in one style, then here will be less params
export const NewModal = ({
  isBlackBtn,
  isNoPadding,
  status,
  iconName,
  description,
  isOpen,
  isNewHeader,
  isCloseBtn,
  isDisabledConfirm,
  isLoadingConfirm,
  title,
  closeText,
  cancelText,
  confirmText,
  onClose,
  onCancel,
  onConfirm,
  children,
  contentWrapperClassName,
  isHeader = true,
  isBottom = false,
  isHorizontalBtns = false,
}) => {
  const { isMobile, isTablet, isTabletL } = useScreenWidth()
  const mobileBottomStyle = isBottom ? modalBottomStyles : {}
  const tabletStyle = !isBottom && (isTablet || isTabletL) ? tabletStyles : {}
  const mobileStyle = isMobile ? mobileStyles : {}
  const updatedModalStyles = {
    ...modalStyles,
    content: {
      ...modalStyles.content,
      ...mobileStyle,
      ...mobileBottomStyle,
      ...tabletStyle,
    },
  }

  const statusIcon = useMemo(() => {
    switch (status) {
      case MODAL_STATUSES.WARNING:
        return WarningIcon
      case MODAL_STATUSES.ERROR:
        return ErrorIcon
      case MODAL_STATUSES.SUCCESS:
        return SuccessIcon
      default:
        return null
    }
  }, [status])

  return (
    <Modal isOpen={isOpen} style={updatedModalStyles}>
      <OutsideClickHandler onOutsideClick={onClose}>
        <ContentWrapper
          $isNoPadding={isNoPadding}
          $isHeader={isHeader}
          $isNewHeader={isNewHeader}
          className={contentWrapperClassName}>
          {/*TODO: remove old header when design will change*/}
          {isHeader && !isNewHeader && (
            <ModalHeader title={title} close={onClose} />
          )}

          {isCloseBtn && (
            <CloseBtn onClick={onClose}>
              <CloseIcon name="close-2" />
            </CloseBtn>
          )}

          {isNewHeader && !isHeader && (
            <NewModalHeader
              title={title}
              description={description}
              close={onClose}
            />
          )}

          {statusIcon && <StatusIcon src={statusIcon} alt="status" />}

          {iconName && <CustomIcon name={iconName} $isOnly={!!title} />}

          {!isHeader && !isNewHeader && <Title>{title}</Title>}

          {children && children}

          {!isHeader && !isNewHeader && description && (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {(closeText || cancelText || confirmText) && (
            <ModalFooter
              isBlackBtn={isBlackBtn}
              isHeader={isHeader}
              isHorizontalBtns={isHorizontalBtns}
              isDisabledConfirm={isDisabledConfirm}
              isLoadingConfirm={isLoadingConfirm}
              closeText={closeText}
              cancelText={cancelText}
              confirmText={confirmText}
              onClose={onClose}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </ContentWrapper>
      </OutsideClickHandler>
    </Modal>
  )
}
