import moment from "moment-timezone"

export const getTileClassName = (
  { date },
  { startDate, endDate, endOfWeek, startOfWeek, isOnlyWeek, activeStartDate }
) => {
  const dateMoment = moment(date)
  const isDisabledDate = dateMoment.diff(moment(), "days") < 0
  const isPrevWeek = dateMoment.diff(endOfWeek, "week") < 0
  const isNextWeek = dateMoment.diff(startOfWeek, "week") > 0
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)

  if (startDate && !startDateMoment.isSame(endDateMoment, "date")) {
    if (startDateMoment.isSame(dateMoment, "date")) {
      return "today-start"
    }

    const isSameEndDate = endDateMoment.isSame(dateMoment, "date")
    const isSameWeekWithStartDate = endDateMoment.isSame(
      startDateMoment,
      "week"
    )

    if (
      (isSameEndDate && !isOnlyWeek) ||
      (isSameEndDate && isSameWeekWithStartDate && isOnlyWeek)
    ) {
      return "today-end"
    }
  }

  const endOfMonth = moment(activeStartDate).endOf("month")
  const startOfMonth = moment(activeStartDate).startOf("month")
  const isBeforeMonth = dateMoment.isBefore(startOfMonth)
  const isAfterMonth = dateMoment.isAfter(endOfMonth)
  const isSameMonth = dateMoment.isSame(startDateMoment, "month")
  const isBeforeStartDateMonth = dateMoment.isBefore(startDateMoment, "month")

  if (isOnlyWeek) {
    let className = ""

    if (isBeforeStartDateMonth) {
      className += " custom-neighbor-month"
    }

    if (isAfterMonth) {
      if (isPrevWeek || isNextWeek) return "hidden-picker-day"
      if (!isSameMonth) className += " custom-neighbor-month"
    }

    if (isDisabledDate) {
      className += " custom-non-click-day"
    }

    if (isPrevWeek || isNextWeek) className += " hidden-picker-day"

    return className
  } else {
    if (isDisabledDate) return "custom-non-click-day"
    if (!isSameMonth && (isBeforeMonth || isAfterMonth))
      return "custom-neighbor-month"
    if (isNextWeek) return
  }
}
