import { useEffect } from "react"

import moment from "moment-timezone"

export const useTodayCalendarEvents = ({
  isChecked,
  calendarEvents,
  checkIfSameDate,
  setIsChecked,
  fetchCalendarEvents,
}) => {
  useEffect(() => {
    const isSameDate =
      calendarEvents[0] &&
      checkIfSameDate(moment(calendarEvents[0].start), moment())

    if (!isChecked && (calendarEvents.length === 0 || !isSameDate)) {
      setIsChecked(true)
      fetchCalendarEvents(moment())
    }
  }, [calendarEvents])
}
