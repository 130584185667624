import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import {
  NFontWeight,
  NSubtitleS,
  NSubtitleXs,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  background-color: var(--lightGray4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`

export const DaysList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-left: 40px;
  list-style: none;
  padding: 10px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding: 10px 0;
    margin-left: 0;
  }
`

export const DayItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;

  ${({ $isPast }) =>
    $isPast &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    `};
`

export const WeekDay = styled(NSubtitleS)`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  font-weight: ${NFontWeight.medium};
  text-align: center;
  line-height: 35px;

  ${({ $isToday }) =>
    $isToday &&
    css`
      background-color: var(--lightGray5);
    `};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: var(--black);
      color: var(--white);
    `};
`

export const MonthDay = styled(NSubtitleXs)`
  color: var(--gray);
`
