import React, { useState } from "react"

import { useRouteMatch } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { recoveryPassword } from "@actions/loginActions"
import {
  ActionWrapper,
  ModalDescription,
  PasswordRequirementsContainer,
  PasswordRequirementWrapper,
  PasswordRequirement,
} from "@components/ChangePasswordModal/molecules"
import { InputEl, ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import { CHANGE_PASSWORD_REQUIREMENTS } from "@constants/password"
import { usePasswordValidation } from "@hooks"

import { useRuleIcon } from "../hooks"
import { PasswordRequirementsWrapper, PasswordResetWrapper } from "../molecules"

const deviceId = uuidv4()

export const ResetPasswordStep = ({ setStep }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const getRuleIcon = useRuleIcon()

  const data = useRouteMatch()
  const { params } = data

  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    failedRequirements,
    isValid,
    passwordErrors,
  } = usePasswordValidation()

  const handleInputNewPassword = value => {
    if (errorMessage) {
      setErrorMessage(null)
    }

    setPassword(value)
  }

  const handleSubmit = () => {
    recoveryPassword({
      password,
      token: params.token,
      deviceId,
    })
      .then(() => {
        setStep(4)
      })
      .catch(({ data }) => {
        if (data?.message) {
          setErrorMessage(data.message)
        }
      })
  }

  const errorConfirmPassword =
    passwordErrors?.confirmPassword === "Passwords do not match" &&
    confirmPassword.length >= password.length
  const isErrorPassword =
    confirmPassword.length >= password.length && passwordErrors?.password
  const isErrorConfirmPassword =
    confirmPassword.length >= password.length && passwordErrors?.confirmPassword

  return (
    <PasswordResetWrapper>
      <ActionWrapper>
        <InputEl
          isError={isErrorPassword}
          type="password"
          label="Create New Password"
          name="password"
          testId="CreateNewPassword"
          value={password}
          errorMessage={errorMessage}
          onChange={handleInputNewPassword}
        />

        <InputEl
          isError={isErrorConfirmPassword}
          errorMessage={errorConfirmPassword && passwordErrors?.confirmPassword}
          type="password"
          label="Confirm New Password"
          name="password"
          testId="ConfirmNewPassword"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
      </ActionWrapper>

      <ModalDescription>Your new password must:</ModalDescription>

      <PasswordRequirementsWrapper>
        <PasswordRequirementsContainer>
          {CHANGE_PASSWORD_REQUIREMENTS.map(req => {
            const failedRule =
              password === "" || failedRequirements.includes(req.description)

            return (
              <PasswordRequirementWrapper>
                {getRuleIcon(failedRule, confirmPassword)}

                <PasswordRequirement>{req.text}</PasswordRequirement>
              </PasswordRequirementWrapper>
            )
          })}
        </PasswordRequirementsContainer>
      </PasswordRequirementsWrapper>

      <ButtonEl
        className="forgot_password-button"
        disabled={!isValid || errorMessage}
        variant={BUTTON_VARIANTS.ROUNDED}
        text="Submit"
        fullWidth
        onClick={handleSubmit}
      />
    </PasswordResetWrapper>
  )
}
