import styled from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const Wrapper = styled.div`
  background-color: var(--white);
  border-bottom: 1px solid var(--gray3);
  border-radius: 8px 8px 0 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 24px 16px;
`

export const ArrowIcon = styled(SvgIcon)`
  transform: rotate(90deg);
`

export const Text = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
`
