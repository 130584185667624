import { useEffect } from "react"

import debounce from "lodash.debounce"

import dayjs from "utils/dayjs"

import { usePrevDateValue } from "./usePrevDateValue"

export const useLazyDatesLoading = ({
  startDate,
  reinitDates,
  isScrolling,
}) => {
  const prevStartDate = usePrevDateValue(startDate)

  useEffect(() => {
    const debouncedEffect = debounce(() => {
      // @ts-ignore
      if (prevStartDate?.includes("00:00:00")) return
      const beginStartDate = dayjs(startDate).hour(0).minute(0)
      const beginPrevStartDate = dayjs(prevStartDate).hour(0).minute(0)
      const daysDifference = dayjs(beginStartDate).diff(
        dayjs(beginPrevStartDate),
        "days"
      )
      if (!startDate || !isScrolling) return

      if (daysDifference) {
        reinitDates(startDate)
      }
    }, 300)

    debouncedEffect()

    return () => {
      debouncedEffect.cancel()
    }
  }, [startDate])
}
