import { create } from "zustand"

import { DETAILS_MODAL_CONTENT } from "@utils/peopleUtils"
import { Participant } from "types/participant"

interface TeeupPeopleStore {
  isCollapsed: boolean
  setIsCollapsed: (flag: boolean) => void

  isAskPeopleModalOpen: boolean
  setIsAskPeopleModalOpen: (flag: boolean) => void

  isOnGoingSelected: boolean
  setIsOnGoingSelected: (flag: boolean) => void

  isNudgeModalOpen: boolean
  setIsNudgeModalOpen: (flag: boolean) => void

  currentParticipant: Participant | null
  setCurrentParticipant: (participant: Participant | null) => void

  isParticipantDetailsModalOpen: boolean
  setIsParticipantDetailsModalOpen: (flag: boolean) => void

  detailsModalContent: string
  setDetailsModalContent: (content: string) => void
}

export const useTeeupPeopleStore = create<TeeupPeopleStore>()(set => ({
  isCollapsed: false,
  setIsCollapsed: flag => set({ isCollapsed: flag }),

  isAskPeopleModalOpen: false,
  setIsAskPeopleModalOpen: flag => set({ isAskPeopleModalOpen: flag }),

  isOnGoingSelected: false,
  setIsOnGoingSelected: flag => set({ isOnGoingSelected: flag }),

  isNudgeModalOpen: false,
  setIsNudgeModalOpen: flag => set({ isNudgeModalOpen: flag }),

  currentParticipant: null,
  setCurrentParticipant: participant =>
    set({ currentParticipant: participant }),

  isParticipantDetailsModalOpen: false,
  setIsParticipantDetailsModalOpen: flag =>
    set({ isParticipantDetailsModalOpen: flag }),

  detailsModalContent: DETAILS_MODAL_CONTENT.OPTIONS,
  setDetailsModalContent: content => set({ detailsModalContent: content }),

  clear: () =>
    set({
      isCollapsed: false,
      isAskPeopleModalOpen: false,
      isOnGoingSelected: false,
      isNudgeModalOpen: false,
      currentParticipant: null,
      isParticipantDetailsModalOpen: false,
      detailsModalContent: DETAILS_MODAL_CONTENT.OPTIONS,
    }),
}))
