import React from "react"

import { useAuth0 } from "@auth0/auth0-react"
import ReactDOM from "react-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"

import "./index.scss"
import { resetModalInfo } from "@actions/commonActions"
import { logOut } from "@actions/loginActions"
import { modalHandlerTypes } from "@config/enums"
import { selectModalInfo } from "@selectors/common"
import { images } from "@utils/imageUtils"
import { reloadPage } from "@utils/reloadPage"

const ModalInfo = ({ children, logOut }) => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  const { show, canCloseModal, title, message, handler, handlerByReference } =
    useSelector(selectModalInfo)

  const customHandler = () => {
    switch (handler) {
      case modalHandlerTypes.logout:
        logOut(logout)
        handleCloseModal()
        break
      case modalHandlerTypes.reload:
        reloadPage()
        break
      default:
        handlerByReference()
        handleCloseModal()
        break
    }
  }

  const handleCloseModal = () => dispatch(resetModalInfo())

  return (
    show &&
    ReactDOM.createPortal(
      <div
        className="modal-info-wrapper"
        onClick={canCloseModal ? handleCloseModal : null}>
        <div className="modal-info" onClick={e => e.stopPropagation()}>
          {canCloseModal && (
            <div className="modal-btn-close" onClick={handleCloseModal}>
              <img src={images.closeSettings} alt="close icon" />
            </div>
          )}
          <div className="modal-info__inner">
            {title && <h2>{title}</h2>}
            {message && <p>{message}</p>}
          </div>
          {children ?? (
            <button
              onClick={handler ? customHandler : handleCloseModal}
              className="modal-btn-secondary">
              Ok
            </button>
          )}
        </div>
      </div>,
      document.querySelector("#modal-root")
    )
  )
}

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch),
})
export default connect(null, mapDispatchToProps)(ModalInfo)
