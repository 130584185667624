import React, { useContext, useEffect } from "react"

import { AiOutlineLeft } from "react-icons/ai"
import { useHistory } from "react-router-dom"

import { Caption } from "@assets/styles/typographyStyles"
import { BUTTON_VARIANTS } from "@constants/button"
import { SignUpEmailContext } from "contexts/SignUpEmailContext"

import { AuthLayout } from "../../hoc"
import { BackWrapper } from "../NewSignInForm/molecules"
import { ButtonEl, ErrorMessage } from "../common"

import { REGISTER_STEPS } from "./constants"
import { PrivateInfoForm, PublicInfoForm } from "./molecules"
import {
  SignUpWrapper,
  SignUpForm,
  SignUpButtons,
  BackTextButton,
} from "./newSignUpEmailStyles"
import { getValidationSchema } from "./validationSchema"

export const NewSignUpEmail = () => {
  const history = useHistory()
  const {
    step,
    setEmail,
    setTouchedFields,
    isRegisterEmailLoading,
    isCodeVerifying,
    isContinueDisabled,
    isRegisterNamesLoading,
    continueHandle,
    apiErrors,
  } = useContext(SignUpEmailContext)

  const { search } = history.location
  const queryEmail = new URLSearchParams(search).get("email")

  const isLoading =
    isRegisterEmailLoading || isCodeVerifying || isRegisterNamesLoading

  useEffect(() => {
    if (!queryEmail?.length) return
    checkQueryEmail(queryEmail)
  }, [queryEmail])

  const checkQueryEmail = async queryEmail => {
    try {
      setEmail(queryEmail)
      await getValidationSchema(REGISTER_STEPS.EMAIL).validate({
        email: queryEmail,
      })
      setTouchedFields(prev => ({
        ...prev,
        email: true,
      }))
    } catch (error) {
      console.log("error", error)
    }
  }

  const getTitle = () => {
    return step === REGISTER_STEPS.NAMES ||
      step === REGISTER_STEPS.INVITE_SENDER
      ? "How should others see you?"
      : "Sign Up"
  }

  const renderForm = () => {
    switch (step) {
      case REGISTER_STEPS.NAMES:
      case REGISTER_STEPS.INVITE_SENDER:
        return <PublicInfoForm />
      default:
        return <PrivateInfoForm />
    }
  }

  const navigateToSignUp = () => {
    history.push("/sign-up")
  }

  return (
    <SignUpWrapper>
      <SignUpForm onSubmit={continueHandle}>
        <AuthLayout title={getTitle()}>
          {renderForm()}
          {apiErrors.global && <ErrorMessage errorMessage={apiErrors.global} />}

          <SignUpButtons>
            <ButtonEl
              fullWidth
              testID="continueButton"
              variant={BUTTON_VARIANTS.ROUNDED}
              type="submit"
              text="Continue"
              isLoading={isLoading}
              disabled={isContinueDisabled}
            />
            {apiErrors.email && (
              <BackTextButton onClick={navigateToSignUp}>
                Go Back
              </BackTextButton>
            )}
          </SignUpButtons>
        </AuthLayout>
        <BackWrapper onClick={navigateToSignUp}>
          <AiOutlineLeft />
          <Caption>All Signup Options</Caption>
        </BackWrapper>
      </SignUpForm>
    </SignUpWrapper>
  )
}
