export const mergeArrays = (a, b, predicate = (a, b) => a === b) => {
  const setA = new Set(a)

  b.forEach(bItem => {
    if (![...setA].some(aItem => predicate(bItem, aItem))) {
      setA.add(bItem)
    }
  })

  return [...setA]
}
