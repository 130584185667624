import ButtonMui, { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import { styled, SystemStyleObject } from "@mui/system"

interface Props extends Omit<MuiButtonProps, "variant"> {
  variant?: MuiButtonProps["variant"]
  sx?: SystemStyleObject
}

const StyledButton = styled(ButtonMui)(({ theme, variant }) => ({
  color: variant === "text" ? "black" : "white",
  backgroundColor: variant === "contained" ? "black" : "inherit",
  textTransform: "none",
  padding: "12px 20px",
  borderRadius: "200px",
  "&:hover": {
    backgroundColor: variant === "contained" ? "black" : "inherit",
  },
}))

export const Button = ({ children, ...rest }: Props) => {
  return <StyledButton {...rest}>{children}</StyledButton>
}
