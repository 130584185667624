import styled, { css } from "styled-components"

import { NSubtitle } from "@assets/styles/newTypographyStyles"

export const AvatarImg = styled.img`
  ${({ $size }) => css`
    width: ${$size || 40}px;
    height: ${$size || 40}px;
    border-radius: 50%;
    object-fit: cover;
  `};
`

export const NameAvatar = styled.div`
  ${({ $size }) => css`
    width: ${$size || 40}px;
    height: ${$size || 40}px;
    background-color: var(--primary-50);
    border-radius: 50%;
    text-align: center;
    line-height: ${$size || 40}px;
  `}
`

export const Text = styled(NSubtitle)`
  color: var(--radicalRed);
  text-transform: uppercase;
`
