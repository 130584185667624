import { useEffect, useRef } from "react"

import { FixedSizeGrid } from "react-window"

import useTimeWheelDates from "@hooks/useTimeWheelDates/useTimeWheelDates"
import {
  BOX_COUNT,
  BOX_SIZE,
  ROW_HEIGHT,
  TIME_WHEEL_WIDTH,
} from "@utils/suggestionUtils"

import HourBox from "./HourBox"
import TileLine from "./TileLine"

import "./index.scss"
import useTimeWheelMouseMove from "@hooks/useTimeWheelMouseMove"

interface Props {
  isStatic?: boolean
  isFromCreateTeeUp?: boolean
}

const TimeWheel = ({ isStatic, isFromCreateTeeUp }: Props) => {
  const scrollRef = useRef<FixedSizeGrid>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const { boxesInfoByTimezone, onScroll } = useTimeWheelDates(
    scrollRef,
    isFromCreateTeeUp
  )

  useTimeWheelMouseMove(scrollRef, innerRef)

  return (
    <div className="time-picker-container">
      <TileLine isStatic={isStatic} />

      <FixedSizeGrid
        // innerRef={innerRef}
        ref={scrollRef}
        innerRef={innerRef}
        height={boxesInfoByTimezone?.length * ROW_HEIGHT}
        width={TIME_WHEEL_WIDTH}
        columnCount={BOX_COUNT}
        rowCount={boxesInfoByTimezone?.length}
        rowHeight={ROW_HEIGHT}
        columnWidth={BOX_SIZE}
        overscanColumnCount={20}
        className="time-picker-grid"
        style={{ overflow: isStatic ? "hidden" : "auto" }}
        onScroll={onScroll}>
        {({ rowIndex, columnIndex, style, isScrolling }) => (
          <HourBox
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            styles={style}
            data={boxesInfoByTimezone?.[rowIndex]?.[columnIndex]}
          />
        )}
      </FixedSizeGrid>
    </div>
  )
}

export default TimeWheel
