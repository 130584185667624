import { useMemo, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { updateUserStatus } from "@actions/requestActions"
import { teeupUserStatusPriorities } from "@config/enums"
import { selectActiveTeeupId } from "@selectors/activeTeeup"
import { selectUserStatus } from "@selectors/teeups"
import { getAttendanceItems } from "@utils/peopleUtils"

export const useUserStatusChange = user => {
  const [userStatus, setUserStatus] = useState(user?.status)
  const dispatch = useDispatch()
  const teeUpId = useSelector(selectActiveTeeupId)

  const handleUserStatusChange = statusText => {
    const statusId = teeupUserStatusPriorities[statusText]
    dispatch(updateUserStatus(teeUpId, statusId))
  }

  const statusExtended = useMemo(() => {
    const extendedStatus = getAttendanceItems().find(
      item => user?.status === item?.status
    )
    setUserStatus(user?.status)
    return extendedStatus
  }, [user?.status])

  return {
    handleUserStatusChange,
    userStatus,
    statusExtended,
  }
}
