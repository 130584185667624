import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes } from "@theme/"

const HEADER_HEIGHT = Units.responsiveValue(42)
const AVATAR_SIZE = Units.responsiveValue(80)
const AVATAR_PADDING_TOP = Units.responsiveValue(17)
const STATUS_BAR_HEIGHT = AppSizes.statusBarHeight

const ViewProps = {
  navButtonColor: AppColors.brand.pink,
  avatarSize: AVATAR_SIZE,
}

const ViewStyles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  headerImage: {
    width: "100%",
    height: Units.responsiveValue(112),
    position: "absolute",
    top: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tabbar: {
    backgroundColor: AppColors.brand.white,
    height: Units.responsiveValue(40),
  },
  tabbarLabel: {
    ...AppFonts.smallExtraBold,
    color: AppColors.brand.grey157,
  },
  contentContainer: {
    // paddingTop:
    //     HEADER_HEIGHT +
    //     AVATAR_PADDING_TOP +
    //     STATUS_BAR_HEIGHT +
    //     AVATAR_SIZE / 2 -
    //     Units.responsiveValue(8),
    paddingTop: 112,
    flex: 1,
  },
  contentWrapper: {
    //backgroundColor: AppColors.brand.lightGrey,
    flex: 1,
  },
  header: {
    top: AppSizes.statusBarHeight,
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 300,
  },
  headerText: {
    ...AppFonts.headerSmallDemibold,
    color: AppColors.brand.white,
    letterSpacing: -Units.responsiveValue(0.76),
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Units.responsiveValue(4),
  },
  headerButton: {
    width: Units.responsiveValue(42),
    height: Units.responsiveValue(42),
    zIndex: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  headerButtonIcon: {
    color: AppColors.brand.white,
  },
  avatarStatusWrapper: {
    position: "absolute",
    flexDirection: "row",
    top: 45,
    width: "100%",
    paddingHorizontal: Units.responsiveValue(16),
    zIndex: 2,
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: Units.responsiveValue(80),
  },
  userContainer: {
    flexDirection: "row",
    paddingHorizontal: Units.rem(1),
    paddingTop: 14,
    paddingBottom: 14,
    backgroundColor: AppColors.brand.white,
    zIndex: 1,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  userContainerInformation: {
    flexDirection: "column",
    flex: 1,
  },
  nameText: {
    ...AppFonts.headerSmallBold,
    color: AppColors.brand.blackTwo,
    letterSpacing: -Units.responsiveValue(0.5),
    paddingBottom: Units.responsiveValue(4),
  },
  additionalText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(18),
  },
  avatarAccesory: {
    bottom: 0,
    right: Units.responsiveValue(8),
  },
  editBlockHeader: {
    // height: Units.responsiveValue(39),
    marginTop: 24,
    //backgroundColor: AppColors.brand.lightGrey,
    justifyContent: "flex-end",
    // paddingLeft: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(8),
  },
  editBlockHeaderText: {
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 17,
    lineHeight: "21px",
    letterSpacing: "-0.34px",
    color: "#222222",
  },
  editBlockRow: {
    paddingVertical: Units.responsiveValue(10),
  },
  editBlockWrapper: {
    paddingHorizontal: 12,
    backgroundColor: "white",
    borderRadius: 4,
    marginBottom: 24,
  },
  editContactRow: {
    height: Units.responsiveValue(56),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    paddingLeft: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(12),
    borderBottomWidth: Units.responsiveValue(1),
    //borderColor: AppColors.brand.lightGrey,
  },
  editContactRowWithPlusButton: {
    height: Units.responsiveValue(56),
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: AppColors.brand.white,
    paddingLeft: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(12),
    borderBottomWidth: Units.responsiveValue(1),
    //borderColor: AppColors.brand.lightGrey,
  },
  editContactRowLabel: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.4),
    color: AppColors.brand.grey157,
    paddingHorizontal: Units.responsiveValue(8),
  },
  editContactRowValue: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.4),
    color: AppColors.brand.black2,
    paddingHorizontal: Units.responsiveValue(8),
  },
  trashIcon: {
    tintColor: AppColors.brand.black,
    height: Units.responsiveValue(18),
    width: Units.responsiveValue(15),
  },
  plusIcon: {
    tintColor: AppColors.brand.pink,
    width: Units.responsiveValue(21),
    height: Units.responsiveValue(21),
  },
  contactIcon: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
  },
  iconWrapper: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
    justifyContent: "center",
    alignItems: "center",
  },
  contactValueWithIcon: {
    flexDirection: "row",
    alignItems: "center",
  },
  additionalInformationWrapper: {
    // minHeight: Units.responsiveValue(62),
    paddingTop: Units.responsiveValue(8),
    //backgroundColor: AppColors.brand.lightGrey,
    paddingBottom: Units.responsiveValue(8),
  },
  additionalInformation: {
    fontFamily: "greycliffcf-medium-salt",
    fontSize: 15,
    lineHeight: "22px",
    letterSpacing: "-0.2px",
    color: "var(--gray2)",
  },

  headerContainer: {
    alignItems: "center",
  },
  headerTitle: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
  },
  headerSubtitle: {
    ...AppFonts.headerSmallBold,
    color: "black",
  },
  stubNavigationButton: {
    paddingRight: Units.rem(1),
  },
  navigationHeaderContainer: {
    flexDirection: "row",
    height: AppSizes.navbarHeight,
    alignItems: "center",
  },
  chooseContactContainer: {
    flex: 1,
    backgroundColor: AppColors.brand.grey243,
    paddingTop: Units.responsiveValue(3),
  },
  chooseContactRow: {
    height: Units.responsiveValue(56),
    backgroundColor: AppColors.brand.white,
    paddingTop: Units.responsiveValue(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Units.responsiveValue(16),
  },
  chooseContactRowImage: {
    height: Units.responsiveValue(32),
    width: Units.responsiveValue(32),
    alignItems: "center",
    justifyContent: "center",
  },
  chooseContactRowText: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(22),
    color: AppColors.brand.black2,
    letterSpacing: -Units.responsiveValue(0.4),
    paddingLeft: Units.responsiveValue(8),
  },
  chooseContactImageWithText: {
    flexDirection: "row",
    alignItems: "center",
  },
  outerSelector: {
    borderColor: AppColors.brand.grey217,
    borderWidth: 1,
    height: Units.responsiveValue(24),
    width: Units.responsiveValue(24),
    borderRadius: Units.responsiveValue(12),
    alignItems: "center",
    justifyContent: "center",
  },
  innerSelector: {
    backgroundColor: AppColors.brand.black2,
    height: Units.responsiveValue(12),
    width: Units.responsiveValue(12),
    borderRadius: Units.responsiveValue(6),
  },
  saveButtonText: {
    ...AppFonts.biggerDemibold,
    lineHeight: Units.responsiveValue(20),
    color: AppColors.brand.pink,
    letterSpacing: -Units.responsiveValue(0.34),
  },
  backgroundEditIcon: {
    position: "absolute",
    right: 16,
    top: 69,
    zIndex: 1000,
    height: 32,
    width: 32,
  },
  avatarEditIcon: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: 32,
    width: 32,
  },
})

export { ViewStyles, ViewProps }
