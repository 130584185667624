import React, { useEffect } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { fetchTeeups } from "@actions/teeupActions"
import {
  selectLatestTeeupEventDate,
  selectTeeupEventsByDay,
  selectTeeupEvents,
} from "@selectors/calendar"
import {
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
  selectActiveTeeups,
  selectArchivedTeeups,
} from "@selectors/teeups"
import { selectTeeupsStateTeeups } from "@selectors/teeupsState"
import { selectUserInfo } from "@selectors/user"

import "./index.scss"

import CalendarCardContainer from "@components/calendarCard/"

const ComingUpPage = ({
  fetchTeeups,
  activeTeeUp,
  userId,
  token,
  isTeeupListVisible,
  isNudgePeopleOpen,
  teeups,
  fetchRequests,
}) => {
  useEffect(() => {
    fetchTeeups()
  }, [])

  return (
    <>
      <div className="ComingUpTab">
        <CalendarCardContainer />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const latestEventDate = selectLatestTeeupEventDate(state)

  const teeupEvents = selectTeeupEvents(state)
  const teeupEventsByDay = selectTeeupEventsByDay(state)

  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const teeupsState = selectTeeupsStateTeeups(state)
  const userInfo = selectUserInfo(state)
  const teeups = selectActiveTeeups(state)
  const archivedTeeups = selectArchivedTeeups(state)

  return {
    teeupParticipants,
    teeupPeople,
    teeupGameplans,
    teeupsState,
    userInfo,
    teeups: [...teeups, ...archivedTeeups],
    latestEventDate,
    teeupEvents,
    teeupEventsByDay,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchTeeups: bindActionCreators(fetchTeeups, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComingUpPage)
