import React, { useState, useEffect } from "react"

import cn from "classnames"

import { SignUpButtons } from "@components"
import { validateEmail } from "@utils/dataUtils"

import { ButtonThreeDots } from "../../../components/ButtonThreeDots"

const CreateAccount = ({
  error,
  isEmailSetting,
  handleAddEmail,
  defaultEmail,
  handleClickVerify,
  setCurrentEmail,
  isPreview,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [email, setEmail] = useState(defaultEmail)
  const [isSignUpWithEmail, setIsSignUpWithEmail] = useState(false)

  useEffect(() => {
    setIsButtonDisabled(!validateEmail(email) || isEmailSetting)
  }, [email, isEmailSetting])

  return (
    <>
      {/* <img style={{ marginBottom: "9px" }} src={images.cooeModal} />
      <span className="forgot__title">Create an Account</span> */}
      {/* <p className="forgot__subtitle" style={{ marginTop: "32px" }}>
        Please select a sign up method to begin creating your CooWe account.
      </p> */}
      {!isSignUpWithEmail && !isPreview ? (
        <SignUpButtons />
      ) : (
        <>
          <form
            onSubmit={e => {
              e.preventDefault()
              handleAddEmail(email)
              handleClickVerify(email)
            }}>
            <label htmlFor="email" className="forgot__label">
              Email
            </label>
            <div style={{ position: "relative" }}>
              <input
                data-testid="emailInputField"
                id="email"
                placeholder="Enter email address"
                value={email}
                onChange={({ target }) => {
                  setEmail(target.value)
                  setCurrentEmail(target.value)
                }}
                className={cn("login__input forgot__input", {
                  "forgot__input--error": error,
                })}
                type="text"
              />
            </div>
            <span className="forgot__error">{error}</span>
          </form>
          <button
            data-testid="continueButton"
            disabled={isButtonDisabled}
            style={{ marginTop: 40 }}
            className={cn("forgot__button", {
              "forgot__button--disabled": isButtonDisabled,
            })}
            onClick={() => {
              handleAddEmail(email)
              handleClickVerify(email)
            }}>
            {isEmailSetting ? <ButtonThreeDots /> : "Continue"}
          </button>
        </>
      )}
    </>
  )
}

export default CreateAccount
