import React, { Component } from "react"

import isEqual from "lodash/isEqual"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import * as teeupActions from "@actions/teeupActions"
import * as userActions from "@actions/userActions"
import { fetchProfile } from "@actions/userActions"
import strings from "@i18n"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectAllContacts } from "@selectors/contacts"
import { selectTeeupInvitationsRequests } from "@selectors/requests"
import {
  selectTeeupUserById,
  selectTeeupsByUserId,
  selectTeeupsParticipants,
  selectTeeupsPeople,
  selectTeeupsGameplans,
} from "@selectors/teeups"
import {
  selectUserId,
  selectUserInfo,
  selectPublicContactMechanismsValues,
} from "@selectors/user"
import { userContactTypes } from "@utils/contactUtils"
import { getRandomBase64Image } from "@utils/imageUtils"
import { isTeeupCancelled } from "@utils/teeupUtils"

import EditUserProfileContainer from "../EditUserProfile"

import UserProfileContactInfo from "./userProfileContactInfo"
import UserProfileSharedTeeups from "./userProfileSharedTeeupsView"
import UserProfileView from "./userProfileView"

const routes = [
  { key: "contactInfoView", title: strings.profileOverview.contactInfo },
  { key: "sharedTeeupsView", title: strings.profileOverview.sharedTeeups },
]

class UserProfileContainer extends Component {
  static options() {
    return {}
  }

  constructor(props) {
    super(props)
    this.state = {
      backgroundImage: props.userInfo.backgroundImageUrl,
      userProfile: props.userProfile,
      isEditOpened: false,
      content: null,
    }
    this.divRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const {
      userId,
      isMyProfile,
      userInfo: { backgroundImageUrl },
      userProfile,
    } = this.props
    if (userId !== prevProps.userId) {
      this.fetchUserInfoWithContactMechs()
      this.setBackgroundImage()
    }
    if (isMyProfile && !isEqual(prevProps.userProfile, userProfile)) {
      this.fetchUserInfoWithContactMechs()
      this.setState({
        userProfile: userProfile,
      })
    }

    if (
      isMyProfile &&
      prevProps.userInfo.backgroundImageUrl !== backgroundImageUrl
    ) {
      this.setState({
        backgroundImage: backgroundImageUrl,
      })
    }
  }

  componentDidMount() {
    const { isMyProfile, myContacts } = this.props
    this.setBackgroundImage()

    if (isMyProfile) {
      this.fetchUserInfo()
    }
    this.fetchUserInfoWithContactMechs()
    !myContacts && this.handleSettings()
  }

  setBackgroundImage = async () => {
    if (!this.state.backgroundImage) {
      let backgroundImage = localStorage.getItem("profileBackgroundImage")
      if (!backgroundImage) {
        backgroundImage = await getRandomBase64Image("dark")
        localStorage.setItem("profileBackgroundImage", backgroundImage)
      }

      this.setState({
        backgroundImage,
      })
    }
  }

  getContactMechanismValue = (contactMechanisms = [], types) => {
    return contactMechanisms.find(
      contactMech => types.includes(contactMech.typeid) && contactMech.isPublic
    )?.value
  }

  fetchUserInfoWithContactMechs = async () => {
    const { userId, isFromContactScreen } = this.props
    const data = await fetchProfile(userId)

    if (isFromContactScreen && data?.profile?.backgroundImageUrl) {
      this.setState({
        backgroundImage: data?.profile.backgroundImageUrl,
      })
    }
    const bio = data?.profile?.about
    const avatar = data?.profile?.avatar
    const backgroundImage = data?.profile?.backgroundImageUrl
    const hometown = data?.profile?.primarylocation
    const email = this.getContactMechanismValue(data?.contactMechanisms, [
      userContactTypes.EMAIL,
      userContactTypes.PRIMARY_EMAIL,
    ])
    const phone = this.getContactMechanismValue(data?.contactMechanisms, [
      userContactTypes.PHONE,
      userContactTypes.PRIMARY_PHONE,
    ])
    const location = this.getContactMechanismValue(data?.contactMechanisms, [
      userContactTypes.LOCATION,
    ])
    const url = this.getContactMechanismValue(data?.contactMechanisms, [
      userContactTypes.URL,
    ])

    this.setState(
      {
        userProfile: {
          bio,
          hometown,
          email,
          phone,
          location,
          url,
          avatar,
        },
        backgroundImage,
      },
      () => {
        if (!backgroundImage) this.setBackgroundImage()
      }
    )
  }

  fetchUserInfo = async () => {
    userActions.fetchUserInfo()
  }

  closeEdit = () => {
    this.setState({ isEditOpened: false })
  }

  handleSettings = () => {
    this.setState({
      isEditOpened: true,
      content: <EditUserProfileContainer closeEdit={this.closeEdit} />,
    })
  }

  onTeeupPress = teeup => {
    const { setTeeupActive, teeup: activeTeeup } = this.props
    const isAnotherTeeupOpened = !!activeTeeup?.name

    // if (isAnotherTeeupOpened) {
    //     Alert.alert(
    //         I18n.t('profileOverview.teeupAlert'),
    //         '',
    //         [
    //             {
    //                 text: I18n.t('systemButtons.cancel'),
    //                 onPress: () => {},
    //             },
    //             {
    //                 text: I18n.t('profileOverview.openTeeup'),
    //                 onPress: () => {
    //                     setTeeupActive(teeup)
    //                     routeHome()
    //                     routeTeeup()
    //                 },
    //             },
    //         ],
    //         { cancelable: false }
    //     )
    // } else {
    //     setTeeupActive(teeup)
    //     routeHome()
    //     routeTeeup()
    // }
  }

  renderContactInfoView = jumpTo => {
    const { userProfile } = this.state
    const { isMyProfile } = this.props
    return (
      <UserProfileContactInfo
        isMyProfile={isMyProfile}
        userProfile={userProfile}
        jumpTo={jumpTo}
      />
    )
  }

  renderScene = (route, jumpTo) => {
    switch (route.key) {
      case "contactInfoView":
        return this.renderContactInfoView(jumpTo)
      case "sharedTeeupsView":
        return this.renderSharedTeeupsView(jumpTo)
    }
  }

  renderSharedTeeupsView = jumpTo => {
    const {
      sharedTeeups,
      teeupParticipants,
      teeupPeople,
      teeupGameplans,
      teeupInvitations,
      userId,
    } = this.props
    return (
      <UserProfileSharedTeeups
        teeups={sharedTeeups}
        teeupParticipants={teeupParticipants}
        teeupPeople={teeupPeople}
        teeupGameplans={teeupGameplans}
        teeupInvitations={teeupInvitations}
        userId={userId}
        onTeeupPress={this.onTeeupPress}
        jumpTo={jumpTo}
        divHeight={this.divRef?.current?.offsetHeight}
      />
    )
  }

  render() {
    const { backgroundImage, userProfile, isEditOpened, content } = this.state
    const { teeup, userId, userInfo, isMyProfile, isContact, closeOption } =
      this.props

    const sceneMapping = {
      contactInfoView: this.renderContactInfoView,
      sharedTeeupsView: this.renderSharedTeeupsView,
    }

    return (
      <>
        {isEditOpened ? (
          <div
            className="settings__modal settings__modal-content"
            style={{
              animationName: "none",
              width: "100%",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
            onClick={event => event.stopPropagation()}>
            {content}
          </div>
        ) : (
          <UserProfileView
            userId={userId}
            userInfo={userInfo}
            userProfile={userProfile}
            routes={routes}
            sceneMapping={sceneMapping}
            renderScene={this.renderScene}
            isTeeupDisabled={isTeeupCancelled(teeup)}
            teeup={teeup}
            handleBack={closeOption}
            handleSettings={this.handleSettings}
            isMyProfile={isMyProfile}
            backgroundImage={backgroundImage}
            isContact={isContact}
            divRef={this.divRef}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const teeup = selectActiveTeeup(state)
  const currentUserId = selectUserId(state)
  const teeupParticipants = selectTeeupsParticipants(state)
  const teeupPeople = selectTeeupsPeople(state)
  const teeupGameplans = selectTeeupsGameplans(state)
  const teeupInvitations = selectTeeupInvitationsRequests(state)
  let userInfo = selectTeeupUserById(props.userId)(state)
  const myUserInfo = selectUserInfo(state)
  const sharedTeeups = selectTeeupsByUserId(props.userId)(state)
  const isMyProfile = props.userId === currentUserId
  const contacts = selectAllContacts(state)
  const isContact =
    !isMyProfile && !!contacts.find(item => item.id === props.userId)

  let userProfile = {
    bio: "",
    hometown: "",
    email: "",
    phone: "",
    location: "",
    url: "",
  }

  if (isMyProfile) {
    const { email, phone, location, url } =
      selectPublicContactMechanismsValues(state)

    userInfo = myUserInfo
    userProfile.bio = userInfo?.about
    userProfile.hometown = userInfo?.primaryLocation
    userProfile.email = email?.value
    userProfile.phone = phone?.value
    userProfile.location = location?.value
    userProfile.url = url?.value
  }

  return {
    userId: props.userId || null,
    teeup,
    teeupParticipants,
    teeupPeople,
    teeupGameplans,
    teeupInvitations,
    currentUserId,
    isMyProfile,
    userInfo,
    sharedTeeups,
    userProfile,
    isContact,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...teeupActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer)

UserProfileContainer.propTypes = {
  userId: PropTypes.number.isRequired,
  userInfo: PropTypes.object,
  // componentId: PropTypes.string.isRequired,
  teeupLastActions: PropTypes.object,
  currentUserId: PropTypes.number.isRequired,
  isMyProfile: PropTypes.bool,
  teeup: PropTypes.object,
  // teeupParticipants: PropTypes.array,
  // teeupPeople: PropTypes.array,
  // teeupGameplans: PropTypes.array,
  // teeupInvitations: PropTypes.array,
  sharedTeeups: PropTypes.array,
  setTeeupActive: PropTypes.func,
  isFromMeScreen: PropTypes.bool,
  userProfile: PropTypes.object,
  isContact: PropTypes.bool,
  isFromContactScreen: PropTypes.bool,
}
