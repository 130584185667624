import React, { useContext } from "react"

import PropTypes from "prop-types"

import { SideBarContext } from "@utils/sideBarContext"

import { StyledLink, StyledDirectLink } from "./navItemWrapperStyles"

export const NavItemWrapper = ({
  disabled,
  staticElement,
  option,
  pathname,
  children,
}) => {
  const { isSideBarOpen } = useContext(SideBarContext)
  const isDirectUrl = pathname?.includes("http")

  return (
    <>
      {isDirectUrl ? (
        <StyledDirectLink
          $disabled={disabled}
          href={pathname}
          target="_blank"
          rel="noreferrer">
          {children}
        </StyledDirectLink>
      ) : (
        <>
          {pathname ? (
            <StyledLink
              $disabled={disabled}
              $isWrapped={staticElement ? false : !isSideBarOpen}
              to={`${pathname}${option ? `?option=${option}` : ""}`}>
              {children}
            </StyledLink>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  )
}

NavItemWrapper.propTypes = {
  pathname: PropTypes.string,
}
