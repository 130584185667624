import React from "react"

import PropTypes from "prop-types"

import { Title, Description } from "./emptySuggestionsStyles"

export const EmptySuggestions = ({ title, description }) => (
  <div>
    <Title>{title}</Title>

    <Description>{description}</Description>
  </div>
)

EmptySuggestions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
