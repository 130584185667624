/**
 * App Theme - Sizes
 *
 * React Native Starter App
 * https://github.com/mcnamee/react-native-starter-app
 */

import { responsiveValue } from "./units"

const absolute = {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
}

const shadow = {
  shadowColor: "black",
  shadowOffset: { height: 2 },
  shadowOpacity: 0.2,
  shadowRadius: 1,
}

const style = {
  transparent: "transparent",
}

const cardGrabber = {
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  height: responsiveValue(32),
  backgroundColor: "white",
  opacity: 0,
}

const absoluteWithoutGrabber = {
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: responsiveValue(48),
}

export default {
  absolute,
  shadow,
  style,
  cardGrabber,
  absoluteWithoutGrabber,
}
