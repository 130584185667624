import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units, AppSizes } from "@theme/"

const SectionHeaderStyles = StyleSheet.create({
  tooltipContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: Units.responsiveValue(
      80 + AppSizes.navbarHeight + AppSizes.statusBarHeight + 7 //7 is container's top padding
    ),
  },
  container: {
    minHeight: Units.responsiveValue(100),
    // paddingTop: Units.responsiveValue(7),
    paddingBottom: Units.responsiveValue(12),
    borderBottomWidth: 1,
    borderBottomColor: AppColors.brand.grey2_50,
    justifyContent: "center",
    zIndex: 5,
    position: "sticky",
    top: 0,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
  },
  divider: {
    height: 1,
    borderRadius: 8,
    backgroundColor: "rgb(220,220,220)",
    width: "100%",
    marginVertical: "10px",
  },
  suggestionPlaceholderText: {
    color: "rgb(157,157,157)",
    fontSize: 10,
    lineHeight: 13,
    letterSpacing: 0.45,
    fontFamily: "greycliffcf-extrabold-salt",
    textTransform: "uppercase",
    marginBottom: "10px",
  },
  placeholderEmptyAreaContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  placeholderEmptyArea: {
    height: 15,
    backgroundColor: "rgb(220,220,220)",
    borderRadius: 4,
  },
  placeholderEmptyAreaFirst: {
    width: "35%",
    marginBottom: "5px",
  },
  placeholderEmptyAreaSecond: {
    width: "50%",
  },
  activeSuggestionSubtitleContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: Units.rem(0.375),
    zIndex: 1,
  },
  activeSuggestionSubtitle: {
    ...AppFonts.middleDemibold,
    lineHeight: Units.rem(1.1875),
    color: AppColors.brand.lightGrey6,
  },
  emptySuggestionHeader: {
    zIndex: 1,
    maxHeight: 115,
    height: 115,
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "rgb(240,240,240)",
    borderRadius: Units.responsiveValue(8),
    marginHorizontal: Units.responsiveValue(16),
    padding: 12,
    boxShadow: "rgb(0 0 0 / 16%) 0px 1pt 4pt 0px inset",
  },
  emptySuggestionHeaderText: {
    ...AppFonts.middleDemibold,
    color: AppColors.brand.lightGrey6,
    textAlign: "center",
  },
  placeholderTitleText: {
    ...AppFonts.smallestExtrabold,
    lineHeight: Units.responsiveValue(16),
    letterSpacing: -Units.responsiveValue(0.5),
    textTransform: "uppercase",
    color: AppColors.brand.grey204,
  },
  emptyUndecidedIcon: {
    marginHorizontal: Units.responsiveValue(16),
    height: Units.responsiveValue(20),
    width: Units.responsiveValue(20),
    tintColor: AppColors.brand.grey204,
  },
  placeholderTextWrapper: width => ({
    width: Units.responsiveValue(width),
    height: Units.responsiveValue(15),
    borderRadius: Units.responsiveValue(4),
    marginTop: Units.responsiveValue(6),
    backgroundColor: AppColors.brand.grey217,
  }),
  noplanWrapper: {
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: AppColors.brand.grey217,
    paddingHorizontal: Units.responsiveValue(17),
    paddingVertical: Units.responsiveValue(5),
    borderTopRightRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),
  },
  noplanText: {
    ...AppFonts.smallBold,
    color: AppColors.brand.white,
    lineHeight: Units.responsiveValue(13),
    letterSpacing: -Units.responsiveValue(0.2),
  },
  activeSuggestionContainer: {
    zIndex: 5,
  },
  activeSuggestionCustomStyle: {
    minHeight: Units.rem(5),
  },
  lockIcon: {
    width: Units.responsiveValue(28),
    height: Units.responsiveValue(52),
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.responsiveValue(8.5),
  },
  draggingOverlayWrapper: isSuggestionInPast => ({
    position: "absolute",
    maxHeight: Units.responsiveValue(80),
    height: Units.responsiveValue(80),
    left: Units.rem(1),
    right: Units.rem(1),
    borderWidth: 2,
    borderColor: isSuggestionInPast
      ? AppColors.brand.darkGrey2
      : AppColors.brand.pink,
    borderRadius: Units.rem(0.5),
    zIndex: 10,
  }),
  draggingOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: Units.rem(0.5),
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Units.rem(0.5),
  },
  draggingOverlayText: {
    ...AppFonts.middleDemibold,
    color: AppColors.brand.pink,
    textAlign: "center",
  },
  draggingOverlayActive: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: Units.rem(0.5),
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: Units.rem(0.5),
  },
  draggingOverlayActiveText: {
    ...AppFonts.middleDemibold,
    color: AppColors.brand.pink,
    textAlign: "center",
  },
  rippleOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.lightGrey2,
  },
})

const SectionFooterStyles = StyleSheet.create({
  suggestionButtonWrapper: {
    // paddingTop: Units.rem(0.5),
    paddingVertical: 8,
    zIndex: 4,
    backgroundColor: AppColors.brand.lightGrey,
    padding: 0,
    display: "flex",
    alignItems: "center",
    // marginTop: '70px',
    /* margin-bottom: 30px; */
    // paddingBottom: '20px',
    position: "sticky",
    bottom: 0,
  },
  suggestionButtonBackground: {
    // padding: Units.rem(1),
    // paddingBottom: Units.rem(1) + AppSizes.additionalBottomSpace,
    // borderTopWidth: 1,
    borderRadius: "24px",
    width: "345px",
    borderTopColor: AppColors.brand.grey2_50,
    backgroundColor: AppColors.brand.white,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
  },
  suggestionButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: Units.rem(3),
    borderRadius: Units.rem(0.5),
    overflow: "hidden",
  },
  suggestionButtonEnabled: {
    // backgroundColor: AppColors.brand.pink,
  },
  suggestionButtonDisabled: {
    borderWidth: 2,
    borderColor: AppColors.brand.warmGrey2,
  },
  suggestionImageEnabled: {
    tintColor: AppColors.brand.white,
  },
  suggestionImageDisabled: {
    tintColor: AppColors.brand.warmGrey2,
  },
  suggestionButtonText: {
    ...AppFonts.biggerDemibold,
    // color: AppColors.brand.white,
    // marginLeft: Units.rem(0.5),
    fontSize: "17px",
    fontWeight: "600",
    color: AppColors.brand.pink,
  },
  suggestionButtonTextEnabled: {
    // color: AppColors.brand.white,
    color: AppColors.brand.pink,
  },
  suggestionButtonTextDisabled: {
    color: AppColors.brand.warmGrey2,
  },
  shadowTop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: Units.rem(0.5),
  },
})

const SectionListStyles = StyleSheet.create({
  sectionList: {
    minHeight: Units.rem(9),
    backgroundColor: AppColors.brand.lightGrey,
    zIndex: 2,
  },
  activeSuggestionDropZoneStubContainer: {
    position: "absolute",
    top: Units.rem(9.375),
    left: 0,
    right: 0,
    bottom: Units.rem(0.625),
    zIndex: 1,
  },
  activeSuggestionDropZoneDraggingOverlay: dropTargetPermissionText => ({
    top: Units.responsiveValue(165),
    bottom: Units.rem(0.625) + AppSizes.additionalBottomSpace,
    position: "absolute",
    left: Units.responsiveValue(16),
    right: Units.responsiveValue(16),
    borderWidth: 2,
    borderColor: dropTargetPermissionText
      ? AppColors.brand.darkGrey2
      : AppColors.brand.pink,
    borderRadius: Units.rem(0.5),
    zIndex: 10,
  }),
  overlay: {
    position: "absolute",
    zIndex: 5,
    top: -Units.rem(18.75),
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.black40,
  },
  titleContainer: {
    alignItems: "center",
  },
  titleText1: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.warmGrey2,
    letterSpacing: -Units.rem(0.018_75),
    lineHeight: Units.rem(1.1875),
  },
  titleText2: {
    ...AppFonts.headerSmallDemibold,
    lineHeight: Units.rem(1.5625),
    color: AppColors.brand.black2,
    letterSpacing: -Units.rem(0.025),
  },
  cancelBtnStyle: {
    padding: Units.rem(0.875),
  },
  cancelBtnImage: {
    // tintColor: AppColors.brand.pink,
    height: Units.responsiveValue(20),
    width: Units.responsiveValue(20),
    marginLeft: 10,
    tintColor: "#b2b2b2",
  },
  stubRightButton: {
    height: Units.rem(2.75),
    width: Units.rem(2.75),
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 14,
    paddingLeft: 16,
    paddingBottom: 12,
  },
  type: {
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: 17,
    lineHeight: 20,
    letterSpacing: "-0.34px",
    color: "#222222",
  },
  icon: {
    width: 12,
    height: 17,
    tintColor: "#f42862",
  },
  suggest: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 13,
    lineHeight: 22,
    letterSpacing: "-0.26px",
    color: "#f42862",
    marginLeft: 6,
  },
  suggestContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 20,
  },
})

export { SectionHeaderStyles, SectionFooterStyles, SectionListStyles }
