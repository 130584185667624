import React from "react"

import { View, Text, Image, StyleSheet } from "react-native"

import { AppColors, Units } from "@theme"
import { images } from "@utils/imageUtils"

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Units.rem(1),
    paddingVertical: Units.rem(0.75),
    backgroundColor: AppColors.brand.white,
  },
  titleContainer: {
    flex: 1,
  },
  titleText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.blackTwo,
  },
  subTitleText: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.darkGrey2,
  },
  iconContainer: {
    height: Units.rem(2.5),
    width: Units.rem(2.5),
    borderRadius: Units.rem(2.5) / 2,
    marginRight: Units.rem(1),
    justifyContent: "center",
    alignItems: "center",
  },
  addContactIcon: {
    height: Units.rem(2.5),
    width: Units.rem(2.5),
    marginRight: Units.rem(1),
  },
  teeupIcon: {
    height: Units.rem(1.25),
    width: Units.rem(1.375),
  },
  teeupIconBackground: {
    backgroundColor: AppColors.brand.blue2,
  },
  chevron: {
    tintColor: AppColors.brand.warmGrey2,
    height: Units.rem(0.75),
  },
})

const LinkButton = ({ isAddContact, title, subTitle, onPress }) => (
  <View style={styles.container}>
    {isAddContact ? (
      <Image
        source={require("../../../assets/images/addContact.png")}
        style={styles.addContactIcon}
      />
    ) : (
      <View style={[styles.iconContainer, styles.teeupIconBackground]}>
        <Image
          source={require("../../../assets/images/teeupIcon.png")}
          style={styles.teeupIcon}
        />
      </View>
    )}
    <View style={styles.titleContainer}>
      {title}
      <Text style={styles.subTitleText}>{subTitle}</Text>
    </View>
    <Image source={images.chevron} style={styles.chevron} />
  </View>
)

// LinkButton.propTypes = {
//     isAddContact: PropTypes.bool,
//     title: PropTypes.string.isRequired,
//     subTitle: PropTypes.string.isRequired,
//     onPress: PropTypes.func.isRequired,
// }

export default LinkButton
