import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react"

import { ReactSVG } from "react-svg"

import { images } from "@utils/imageUtils"

import useOnlineTabState from "../../Hooks/useOnlineTabState"

import MeetingLink from "./MeetingLink"

import "./index.scss"

const MeetingDetails = forwardRef((props, ref) => {
  const {
    activeApp,
    activeButtonId,
    setActiveButtonId,
    addSuggestAppName,
    addSuggestDetails,
  } = useOnlineTabState()

  const [appName, setAppName] = useState("")
  const [comment, setComment] = useState("")
  const [pasteText, setPasteText] = useState(null)
  const [isValidateError, setIsValidateError] = useState(false)
  const [showError, setShowError] = useState(false)

  useImperativeHandle(ref, () => ({
    isLinkValid(valid) {
      setShowError(!valid)
      if (!valid) {
        setPasteText(null)
        setActiveButtonId(1)
      }
    },
  }))

  const handleClose = () => {
    setShowError(false)
  }

  useEffect(() => {
    if (activeApp.title === "Other") {
      appName.length > 0 && appName.length !== 30
        ? setActiveButtonId(1)
        : setActiveButtonId(0)
    }
  }, [appName])

  useEffect(() => {
    activeButtonId !== 2 && setPasteText(null)
    setShowError(false)
  }, [activeApp.id])

  useEffect(() => {
    addSuggestAppName(appName)
    appName.length === 30 ? setIsValidateError(true) : setIsValidateError(false)
  }, [appName])

  const handlePasteLink = () => {
    navigator.clipboard.readText().then(text => {
      text && setPasteText(text)
      setActiveButtonId(2)
      addSuggestDetails(text)
      setShowError(false)
    })
  }

  return (
    <div className="suggest-online-details">
      {activeApp.title === "Other" && (
        <div className="suggest-online-details__app">
          <h3 className="suggest-online__title">Add name</h3>
          <div className="suggest-online-details__app--name">
            {appName.length === 0 && (
              <p className="suggest-online-details__app--required">*</p>
            )}
            <input
              type="text"
              className="suggest-online-details__app--input"
              value={appName}
              onChange={e => setAppName(e.target.value)}
              placeholder="What is the app name?"
              maxLength="30"
            />
            {appName.length > 0 && (
              <div
                className="suggest-online-details--clear"
                onClick={() => setAppName("")}></div>
            )}
          </div>
          {isValidateError && (
            <div className="suggest-online-details__error">
              The app name should be less than 30 characters
            </div>
          )}
        </div>
      )}

      <div className="suggest-online-details__top">
        <h3 className="suggest-online__title">
          {activeButtonId === 2 ? "Meeting details" : "Add meeting details"}
        </h3>

        <div className="suggest-online-details__meaning">
          <img
            src={images.ndSuggestDetails}
            alt="meaning"
            style={{ marginRight: 4 }}
          />
          What does this mean?
        </div>
      </div>

      {showError && (
        <div className="suggest-online-details__link-error">
          <div
            className="suggest-online-details__link-error-close"
            onClick={() => handleClose()}>
            <ReactSVG src={images.close} />
          </div>
          <div className="suggest-online-details__link-error-reason">
            <ReactSVG src={images.ndWarning} />
            <div className="suggest-online-details__link-error-message">
              No meeting link or message has been copied to the clipboard. Copy
              meeting link or message to the clipboard.
            </div>
          </div>
          <div className="suggest-online-details__link-error-subtitle">
            CLIPBOARD CONTENT
          </div>
          <div className="suggest-online-details__link-error-details">
            {activeApp.details}
          </div>
        </div>
      )}

      {pasteText && (
        <MeetingLink
          pasteText={pasteText}
          comment={comment}
          setPasteText={setPasteText}
          setComment={setComment}
        />
      )}

      {activeButtonId !== 2 && !pasteText && (
        <div className="suggest-online-details__buttons">
          {/* {activeApp.title === "Zoom" && (
            <div className="suggest-online-details__button">
              <img src={images.ndSuggestCreateMeeting} alt="create meeting" style={{ marginRight: 4 }} />
              Create a Zoom meeting
            </div>
          )} */}
          <div
            className="suggest-online-details__button"
            onClick={() => handlePasteLink()}>
            <img
              src={images.ndSuggestPasteLink}
              alt="paste link"
              style={{ marginRight: 4 }}
            />
            Paste meeting link or message
          </div>
        </div>
      )}

      {activeButtonId === 2 && (
        <div className="suggest-comment">
          <h3 className="suggest-online__title">Comment</h3>
          <textarea
            data-testid="meetingsDetailsCommentTextArea"
            className="suggest-comment__textarea"
            value={comment}
            onChange={e => setComment(e.target.value)}
            placeholder="Add a comment..."
          />
        </div>
      )}
    </div>
  )
})

export default MeetingDetails
