import actionTypes from "@actions/actionTypes"

const initialState = {
  activeMeetingApp: null,
  activeButtonId: 0,
  mapCenter: {
    lat: 49.282_729_1,
    lng: -123.120_737_5,
  },
  activePlace: null,
  isSearchNearby: false,
  nearbyPlaces: [],
  initComment: "",
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SUGGEST_ONLINE_ACTIVE_MEETING_APP:
      return {
        ...state,
        activeMeetingApp: payload,
      }
    case actionTypes.SET_SUGGEST_ONLINE_ACTIVE_BUTTON_ID:
      return {
        ...state,
        activeButtonId: payload,
      }
    case actionTypes.SET_SUGGEST_ONLINE_APP_NAME:
      return {
        ...state,
        activeMeetingApp: {
          ...state.activeMeetingApp,
          appName: payload,
        },
      }
    case actionTypes.SET_SUGGEST_INIT_COMMENT:
      return {
        ...state,
        initComment: payload,
      }
    case actionTypes.SET_SUGGEST_ONLINE_DETAILS:
      return {
        ...state,
        activeMeetingApp: {
          ...state.activeMeetingApp,
          details: payload,
        },
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_MAP_CENTER:
      return {
        ...state,
        mapCenter: payload,
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_ACTIVE_PLACE:
      return {
        ...state,
        activePlace: payload,
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_IS_SEARCH_NEARBY:
      return {
        ...state,
        isSearchNearby: payload,
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_NEARBY_PLACES:
      return {
        ...state,
        nearbyPlaces: [...state.nearbyPlaces, payload],
      }
    case actionTypes.SET_SUGGEST_IN_PERSON_NEARBY_CLEAR_PLACES:
      return {
        ...state,
        nearbyPlaces: [],
      }
    default:
      return state
  }
}
