import { StyleSheet } from "react-native"

import { AppColors } from "@theme"

const Styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: AppColors.card1Grey,
  },
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  bottomCover: bottomHeight => ({
    position: "absolute",
    backgroundColor: AppColors.card1Grey,
    width: "100%",
    height: bottomHeight,
    bottom: -bottomHeight,
  }),
  overlay: (dimAmount = 0.9, animatedValue, bottom) => ({
    ...StyleSheet.absoluteFill,
    backgroundColor: `rgba(0,0,0,${dimAmount})`,
    opacity: animatedValue,
    bottom,
  }),
})

export default Styles
