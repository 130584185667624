/**
 * Created by gintaras on 6/19/17.
 */
import React from "react"

import { View, Text, TouchableOpacity, StyleSheet, Image } from "react-native"
import { connect } from "react-redux"

import { AppColors, AppFonts, Units, AppSizes } from "@theme"

// components
import { images } from "@utils/imageUtils"

import Icon from "../ui/icon"

const ViewWithHeader = ({
  title,
  titleView,
  isTemporalCustom,
  leftButton,
  rightButton,
  headerStyle,
  style,
  containerStyle,
  titleContainerStyle,
  headerContainerStyle,
  leftBtnStyle,
  rightBtnStyle,
  onBack,
  onClose,
  onCancel,
  onDone,
  onCloseRight,
  onDoneDisabled,
  onDoneTitle,
  children,
  lightTheme,
  noStatusBarSpacing,

  isWhen,
  onLayout = () => {},
  showFooter,
  SuggestionListProp,
  isFromCreateTeeup,
}) => {
  if (onClose) {
    leftButton = (
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        style={[styles.extraPadding, leftBtnStyle]}
        onPress={onClose}>
        <Text style={styles.button}>Cancel</Text>
      </TouchableOpacity>
    )
  } else if (onBack) {
    leftButton = (
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        style={[styles.extraPadding, leftBtnStyle]}
        onPress={onBack}>
        <Icon
          type="material-icons"
          name="arrow-back"
          size={Units.rem(1.5)}
          style={[styles.backIcon, lightTheme && styles.backIconLight]}
        />
      </TouchableOpacity>
    )
  } else if (onCancel) {
    leftButton = (
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        style={[styles.extraPadding, leftBtnStyle]}
        onPress={onCancel}>
        <Image source={images.back} style={styles.cancelBtnImage} />
      </TouchableOpacity>
    )
  }

  if (onDone) {
    rightButton = (
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        disabled={onDoneDisabled}
        style={[styles.extraPadding, rightBtnStyle]}
        onPress={onDone}
        testID={"onDoneButton"}>
        <Text style={[styles.button, onDoneDisabled && styles.buttonDisabled]}>
          {onDoneTitle ? onDoneTitle : "Done"}
        </Text>
      </TouchableOpacity>
    )
  } else if (onCloseRight) {
    rightButton = (
      <TouchableOpacity
        hitSlop={Units.hitSlop()}
        style={[styles.smallPadding, rightBtnStyle]}
        onPress={onCloseRight}>
        <Icon
          type="ant-design"
          name="closecircleo"
          size={Units.responsiveValue(20)}
          style={styles.rightCloseIcon}
        />
      </TouchableOpacity>
    )
  }

  const widthStyle = () => {
    if (isWhen & isTemporalCustom) return { width: "100%" }
    else if (isWhen & !isTemporalCustom) return { width: "380px" }
    else {
    }
  }

  return (
    <View
      style={[
        containerStyle ? containerStyle : styles.flex,
        { height: isWhen && "100%" },
      ]}
      onLayout={onLayout}>
      {!isTemporalCustom && (
        <View style={headerStyle}>
          <View style={styles.shadowContainer} />

          {!noStatusBarSpacing && <View style={styles.statusBarSpacing} />}
          <View
            style={
              headerContainerStyle
                ? headerContainerStyle
                : styles.headerContainer
            }>
            {showFooter && leftButton}
            {showFooter ? (
              <View
                style={
                  titleContainerStyle
                    ? titleContainerStyle
                    : styles.titleContainer
                }>
                {titleView ? (
                  titleView
                ) : (
                  <Text style={styles.title}>{title}</Text>
                )}
              </View>
            ) : (
              <div
                style={{
                  width: isWhen ? "380px" : "100%",
                  display: "flex",
                  height: !SuggestionListProp && "48px",
                  alignItems: !SuggestionListProp && "center",
                }}>
                {titleView ? (
                  titleView
                ) : (
                  <Text
                    style={[
                      styles.title,
                      {
                        fontFamily: "greycliffcf-extrabold-salt",
                        fontSize: "17px",
                        fontWeight: "800",
                        marginLeft: "16px",
                      },
                    ]}>
                    {title}
                  </Text>
                )}
              </div>
            )}
            {rightButton || <div></div>}
          </View>
        </View>
      )}

      <View style={[style ? style : styles.flex, widthStyle()]}>
        {children}
      </View>
    </View>
  )
}

export const styles = StyleSheet.create({
  flex: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
  },
  headerContainer: {
    flexDirection: "row",
    // width: AppSizes.screen.width,
    height: AppSizes.navbarHeight,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  shadowContainer: {
    shadowColor: AppColors.brand.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 6,
    zIndex: 1,
    elevation: 3,
  },
  titleContainer: {
    // flex: 1,
    backgroundColor: AppColors.brand.white,
    alignItems: "center",
    // justifyContent: 'center',
  },
  title: {
    color: "black",
    ...AppFonts.navbar,
  },
  extraPadding: {
    padding: Units.rem(1),
  },
  smallPadding: {
    padding: Units.responsiveValue(9),
  },
  cancelBtnImage: {
    tintColor: AppColors.brand.pink,
    height: Units.responsiveValue(16),
    width: Units.responsiveValue(16),
  },
  statusBarSpacing: {
    height: AppSizes.statusBarHeight,
  },
  backIcon: {
    color: AppColors.brand.black20,
  },
  backIconLight: {
    color: AppColors.brand.pink,
  },
  button: {
    color: AppColors.brand.pink,
    ...AppFonts.biggerDemibold,
  },
  buttonDisabled: {
    color: "grey",
  },
  rightCloseIcon: {
    color: AppColors.brand.grey151,
  },
})

// ViewWithHeader.propTypes = {
//     title: PropTypes.string,
//     titleView: PropTypes.object,
//     leftButton: PropTypes.oneOfType([
//         PropTypes.shape({}),
//         PropTypes.arrayOf(PropTypes.shape({})),
//     ]),
//     rightButton: PropTypes.oneOfType([
//         PropTypes.shape({}),
//         PropTypes.arrayOf(PropTypes.shape({})),
//     ]),
//     headerStyle: PropTypes.object,
//     style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//     containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//     titleContainerStyle: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.array,
//     ]),
//     headerContainerStyle: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.array,
//     ]),
//     leftBtnStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//     rightBtnStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//     onBack: PropTypes.func,
//     onClose: PropTypes.func,
//     onCancel: PropTypes.func,
//     onDone: PropTypes.func,
//     onCloseRight: PropTypes.func,
//     onDoneDisabled: PropTypes.bool,
//     lightTheme: PropTypes.bool,
//     onDoneTitle: PropTypes.string,
//     onLayout: PropTypes.func,
//     children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
//     noStatusBarSpacing: PropTypes.bool,
// }

const mapStateToProps = state => {
  return {
    showFooter: state.common.showFooter,
  }
}

export default connect(mapStateToProps, null)(ViewWithHeader)
