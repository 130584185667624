import React from "react"

import Skeleton from "react-loading-skeleton"

import { TitleWrapper } from "../teeupPreviewContentStyles"

import { InviterTitle, TeeupTitle } from "./inviteTitleStyles"

export const InviteTitle = ({ isStepper, previewInfo }) => {
  const renderInviterInfo = () => {
    const name = previewInfo?.inviter?.name || previewInfo?.organizer?.name
    const text = `<b>${name}</b> wants to meet.`

    return (
      <InviterTitle
        $isStepper={isStepper}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  return (
    <TitleWrapper $isStepper={isStepper}>
      {previewInfo ? (
        renderInviterInfo()
      ) : (
        <Skeleton width={200} borderRadius={20} />
      )}

      {previewInfo ? (
        <TeeupTitle $isStepper={isStepper}>{previewInfo.title}</TeeupTitle>
      ) : (
        <Skeleton width={220} height={30} borderRadius={20} />
      )}
    </TitleWrapper>
  )
}
