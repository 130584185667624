import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  sectionListContainer: { flex: 1 },
  sectionHeader: {
    paddingHorizontal: Units.rem(1),
    paddingVertical: Units.rem(0.5),
    //backgroundColor: AppColors.brand.whiteGrey,
  },
  sectionTitle: {
    fontFamily: "greycliffcf-demibold-salt",
    color: AppColors.brand.blackTwo,
  },
  notFound: {
    flex: 1,
    backgroundColor: AppColors.brand.white,
    justifyContent: "center",
    alignItems: "center",
  },
  notFoundText: {
    ...AppFonts.dynamic("headerSmall", "demibold"),
    color: AppColors.brand.warmGrey2,
  },
})
