export const AI_USERS_IDS = {
  development: [
    58_206, 3173, 51_649, 60_423, 3125, 68_966, 4537, 3088, 62_926, 23, 65_660,
  ],
  staging: [
    10_277, 10_285, 10_284, 10_275, 9963, 8958, 2619, 1438, 1330, 1282, 53,
    10_288,
  ],
  production: [12, 4117, 4185, 2860, 4385, 3322, 2822, 3738, 2892, 2188],
}
