import React, { Component } from "react"

import _ from "lodash"
import assign from "lodash/assign"
import isEmpty from "lodash/isEmpty"
import PropTypes from "prop-types"
import { View, Text, Animated, Platform } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import * as commonActions from "@actions/commonActions"
import * as teeupActions from "@actions/teeupActions"
import * as teeupCardActions from "@actions/teeupCardActions"
import { teeupUserStatusKeys, titles } from "@configs/enums"
import { gameplanOptionStatusIds, conversationTypeIds } from "@configs/mappings"
import I18n from "@i18n"
import { selectActiveTeeup, selectMessages } from "@selectors/activeTeeup"
import {
  //     selectNewSuggestions,
  selectRemovedSuggestions,
} from "@selectors/localData"
import {
  selectPermissions,
  // selectSuggestionMessages,
  selectTeeupGameplans,
  selectTeeupPeople,
  selectTeeupRecommendationsIds,
  selectTeeupRecommendations,
  selectDecidedGameplans,
  selectTeeupsParticipants,
  // selectTeeupMessages,
} from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import { AppColors } from "@theme"
import Highlighter from "@ui/highlighter"
import Toast from "@ui/toast"
import {
  GAMEPLAN_OPTIONS,
  getNotAllowedTextColor,
  getNotAllowedIconTintColor,
} from "@utils/gamePlanUtils"

// import {
//     showNudgeParticipantsList,
//     routeSetupGameplan,
//     routeGameplanOptions,
//     showActionsheet,
//     showTimezonesSummary,
//     routeBack,
//     routeBackToTeeup,
// } from '@nav'
// import navigationConfig from '@nav/NavigationConfig'
import {
  promoteToGameplan,
  getDropTargetText,
  permissionsText,
  isPastSuggestion,
} from "@utils/gamePlanUtils"
import { images } from "@utils/imageUtils"
import {
  isMultizoneTeeup,
  isTeeupDisabled,
  isTeeupCancelled,
  isOrganiser,
} from "@utils/teeupUtils"
// import {
//     selectDraggingSuggestion,
//     selectDraggingSuggestionId,
// } from '@selectors/teeupCards'
// import ReactTooltip from '@ui/tooltip'

import GameplanContainer from "../../CreateTeeUp/gameplanContainer"
import RecommendationList from "../RecommendationList"
import StartsWhenCarousel from "../StartsWhenCarousel"
import { RecommendationStyles } from "../gameplansStyles"
import suggestionDragGlobal from "../suggestionDragGlobal"
import SuggestionSectionContent from "../suggestionSectionContent"
import SuggestionWhenWorksSectionContent from "../whenWorksSectionContent"

import SuggestionListFooter from "./suggestionListFooter"
import SuggestionSectionHeader from "./suggestionListHeader"
import { SectionHeaderStyles } from "./suggestionListStyles"
import SuggestionListView from "./suggestionListView"

const isIOS = Platform.OS === "ios"

const formatSections = (
  gameplans,
  recommendationsItems,
  recommendationsIds,
  activeIndex
) => {
  let sections = gameplans.map((gameplan, index) => ({
    ...gameplan,
    data: [index],
    index,
  }))
  let recommendations = recommendationsIds
    ? recommendationsIds.map(
        recommendationId => recommendationsItems[recommendationId]
      )
    : []

  if (recommendations.length > 0) {
    sections.push({
      index: gameplans.length,
      title: "Possibilities for your TeeUp",
      data: [gameplans.length],
      recommendations,
    })
  }

  return sections.filter(section => section.index === activeIndex)
}

const headerTemplateKey = "header-template-key"

class SuggestionListContainer extends Component {
  static options() {
    return {
      statusBar: {
        // ...navigationConfig.lightStatusBar,
      },
      popGesture: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let updates = {}

    const { draggingSuggestion } = nextProps
    if (nextProps.gameplans !== state.sections) {
      updates.sections = formatSections(
        nextProps.gameplans,
        nextProps.recommendations,
        nextProps.recommendationsIds,
        state.activeSectionIndex
      )
    }

    if (state.draggingSuggestion !== draggingSuggestion) {
      if (!draggingSuggestion) {
        updates.draggingSuggestion = draggingSuggestion
      } else if (draggingSuggestion.rect) {
        if (!state.draggingSuggestion) {
          const { rect } = draggingSuggestion
          state.draggingRowPos.setValue({
            x: rect.x,
            y: rect.y,
          })
        }

        updates.draggingSuggestion = draggingSuggestion
      }
    }

    return isEmpty(updates) ? null : updates
  }

  constructor(props) {
    super(props)

    const {
      gameplans,
      recommendations,
      recommendationsIds,
      gameplanIndex,
      optionId,
    } = props
    const activeIndex =
      gameplanIndex || gameplanIndex === 0
        ? gameplanIndex
        : gameplans.findIndex(gameplan => gameplan.optionId === optionId)

    this.state = {
      sections: formatSections(
        gameplans,
        recommendations,
        recommendationsIds,
        activeIndex
      ),
      activeSectionIndex: activeIndex,
      draggingSuggestion: null,
      isTooltipVisible: false,
      draggingRowPos: new Animated.ValueXY({ x: 0, y: 0 }),
      isActionSheetActive: false,
      isSuggestionsVisible: true,
      gameplanContent: null,
      sessionSuggestions: {},
      listWithFooter: true,
    }

    this._sectionHeaderRef = null
    this._suggestionDraggingRef = null
    this._suggestionHoverRef = null
    this.highlighterRef = React.createRef(null)
  }

  componentDidMount() {
    const { setTitle } = this.props
    const { sections } = this.state
    const title =
      sections[0].type === "when" ? titles.suggestWhen : titles.suggestWhere

    setTitle(title)
    suggestionDragGlobal.onTouchStart = this.handleSuggestionTouchStart
    suggestionDragGlobal.onTouchMove = this.handleSuggestionTouchMove
    suggestionDragGlobal.onTouchEnd = this.handleSuggestionTouchEnd
  }

  componentWillUnmount() {
    const { activeTeeup, setTitle } = this.props
    suggestionDragGlobal.onTouchStart = null
    suggestionDragGlobal.onTouchMove = null
    suggestionDragGlobal.onTouchEnd = null
    setTitle(activeTeeup.name)
    // this.props.stopDraggingSuggestion()
  }

  componentDidUpdate(prevProps) {
    const { draggingSuggestionId, previewOn, startTouch, messages } = this.props
    const hidden = previewOn || startTouch
    const prevHidden = prevProps.previewOn || prevProps.startTouch
    const { messages: prevMessages } = prevProps
    if (messages.length > 0 && messages.length > prevMessages.length) {
      const newMessage = messages.find(m => !prevMessages.includes(m))
      if (newMessage && newMessage.isSuggestion) {
        this.setState(prevState => ({
          ...prevState,
          sessionSuggestions: {
            ...prevState.sessionSuggestions,
            [newMessage.id]: newMessage,
          },
        }))
        setTimeout(
          () =>
            this.setState(prevState => ({
              ...prevState,
              sessionSuggestions: {},
            })),
          5000
        )
      }
    }
    if (!draggingSuggestionId && hidden && !prevHidden) {
      this._sectionList.scrollToLocation({
        animated: false,
        itemIndex: 0,
        sectionIndex: 0,
        viewOffset: 0,
        viewPosition: 0,
      })
    }

    if (
      draggingSuggestionId &&
      draggingSuggestionId !== prevProps.draggingSuggestionId
    ) {
      const isActiveSuggestionDragging = this.checkActiveGameplanSuggestion()
      suggestionDragGlobal.dropRef = isActiveSuggestionDragging
        ? this._sectionRef
        : this._sectionHeaderRef
    }
  }

  handleSuggestionTouchStart = (
    e,
    gestureState,
    props,
    rowRef,
    rowContentRef
  ) => {
    const extraMoveY = 30
    if (!rowRef || !rowContentRef || !rowContentRef.measureInWindow) {
      return
    }

    rowContentRef.measureInWindow((x, y, width, height) => {
      this._suggestionDraggingRef = rowRef
      if (this._suggestionDraggingRef) {
        this._suggestionDraggingRef.showRipple()
      }
      this.props.startDraggingSuggestion({
        props,
        touch: {
          x: gestureState.x0,
          y: gestureState.y0 + extraMoveY,
        },
        rect: { x: 0, y: gestureState.y0 - height },
      })
      this.props.updateDraggingSuggestion({
        rect: { x, y, width, height },
      })
    })
  }

  handleSuggestionTouchMove = (e, gestureState) => {
    const { draggingSuggestion } = this.state
    if (!draggingSuggestion) {
      return
    }

    const { moveX, moveY } = gestureState
    const extraMoveY = isIOS ? 60 : 30
    this.state.draggingRowPos.setValue({
      x: moveX - draggingSuggestion.touch.x + draggingSuggestion.rect.x,
      y:
        moveY -
        draggingSuggestion.touch.y +
        draggingSuggestion.rect.y -
        extraMoveY,
    })
    if (suggestionDragGlobal.dropRef) {
      suggestionDragGlobal.dropRef.measureInWindow((x, y, width, height) => {
        if (moveY > y && moveY < y + height) {
          suggestionDragGlobal.dropRef.animateDropActive(true)
          this._suggestionHoverRef.setOnDropPoint(true)
        } else {
          suggestionDragGlobal.dropRef.animateDropActive(false)
          this._suggestionHoverRef.setOnDropPoint(false)
        }
      })
    }
  }

  getPermissionDropText = () => {
    const { draggingSuggestion } = this.state
    if (draggingSuggestion && draggingSuggestion.props.suggestion) {
      const {
        props: { suggestion },
      } = draggingSuggestion
      const { permissions, decidedGameplans } = this.props
      const { type } = suggestion
      const decidedGameplanId = decidedGameplans[type]
        ? decidedGameplans[type].id
        : -1
      return getDropTargetText({ permissions, decidedGameplanId })
    } else {
      return ""
    }
  }

  handleSuggestionTouchEnd = (e, gestureState) => {
    if (this._suggestionDraggingRef) {
      this._suggestionDraggingRef.showRipple(false)
    }

    const { draggingSuggestion } = this.state

    if (!draggingSuggestion) {
      this.props.stopDraggingSuggestion()
      return
    }

    let isSuggestionInPast = this.checkIsSuggestionInPast()
    const isActiveSuggestionDragging = this.checkActiveGameplanSuggestion()

    if (isActiveSuggestionDragging) {
      isSuggestionInPast = false
    }

    const dropTargetPermissionText = this.getPermissionDropText()

    const { moveY } = gestureState
    if (suggestionDragGlobal.dropRef) {
      suggestionDragGlobal.dropRef.measureInWindow((x, y, width, height) => {
        if (
          moveY > y - 10 &&
          moveY < y + height &&
          !isSuggestionInPast &&
          !dropTargetPermissionText &&
          dropTargetPermissionText !== permissionsText.decidedPermission
        ) {
          this.animateSuccessDrag()
        } else {
          this.animateReleaseDrag()
        }
      })
    }
  }

  // dragged but not onto correct gameplan
  animateReleaseDrag() {
    const { draggingSuggestion } = this.state
    const extraMoveY = isIOS
      ? draggingSuggestion.rect.height
      : draggingSuggestion.rect.height / 2
    Animated.timing(this.state.draggingRowPos, {
      toValue: {
        x: draggingSuggestion.rect.x,
        y: draggingSuggestion.rect.y - extraMoveY,
      },
      duration: 300,
      useNativeDriver: true,
    }).start()

    if (this._suggestionDraggingRef) {
      this._suggestionHoverRef.showRipple(false)
    }

    setTimeout(this.props.stopDraggingSuggestion, 300)
  }

  // dragged onto correct gameplan
  animateSuccessDrag() {
    const { draggingSuggestion } = this.state

    if (draggingSuggestion && draggingSuggestion.props) {
      const suggestion = draggingSuggestion.props.suggestion

      setTimeout(() => {
        this.promoteToGameplan(suggestion)
      }, 300)
    }

    this._suggestionDraggingRef.animateSelectSuggestion()
    this._suggestionHoverRef.animateDismiss()
    suggestionDragGlobal.dropRef.animateChangeGameplan()

    setTimeout(this.props.stopDraggingSuggestion, 1300)
  }

  promoteToGameplan(suggestion, isSelectedSuggestion) {
    const { permissions, decidedGameplans, activeTeeup } = this.props
    const activeTeeupId = activeTeeup.id
    const { type } = suggestion
    const decidedGameplanId = decidedGameplans[type]
      ? decidedGameplans[type].id
      : -1
    const isActiveSuggestionDragging = this.checkActiveGameplanSuggestion()

    const isSelected = isSelectedSuggestion
      ? isSelectedSuggestion
      : isActiveSuggestionDragging

    promoteToGameplan({
      suggestion,
      decidedGameplanId,
      permissions,
      activeTeeupId,
      isRemove: isSelected,
    })
  }

  markAsDecided(suggestion, isDecided) {
    const { permissions } = this.props
    const { id: suggestionId } = suggestion
    if (permissions.allowDecideGameplan) {
      // globalDispatch({
      //     type: actionTypes.MARK_AS_DECIDED,
      //     payload: {
      //         suggestionId,
      //         isDecided,
      //     },
      // })
    } else {
      Toast.show(I18n.t("teeup.toastMsg.forbiddenDecideGP"), {
        bottomOffset: 8,
        textAlign: "center",
      })
    }
  }

  handleToggleWithdraw(suggestion, isWithdrawn) {
    const { id: suggestionId } = suggestion
    // globalDispatch({
    //     type: actionTypes.TOGGLE_WITHDRAW_SUGGESTION,
    //     payload: {
    //         suggestionId,
    //         isWithdrawn,
    //     },
    // })
  }

  handleOnLongPress = props => {
    const {
      permissions: { allowDecideGameplan, allowSelectGameplan },
      decidedGameplans,
      messages,
      currentUser,
      expandConversationCard,
    } = this.props
    const suggestionParams = props
    const { suggestion } = suggestionParams
    const { id, selected, decided, isWithdrawn, type, googlePlaceId } =
      suggestion

    const decidedGameplanId = decidedGameplans[type]
      ? decidedGameplans[type].id
      : -1

    if (!id) {
      return
    }

    const isSuggestionInPast = this.checkIsSuggestionInPast({
      props: { suggestion },
    })
    const inactive = isSuggestionInPast || isWithdrawn
    let suggestionMessage = messages.find(msg => msg.id === suggestion.id)

    if (!suggestionMessage) {
      const initialMessage = messages.find(
        message => message.conversationTypeId === conversationTypeIds.initial
      )
      suggestionMessage = initialMessage?.gameplans?.find(
        gameplan => gameplan.id === suggestion.id
      )
    }

    const allowComment = currentUser.status !== teeupUserStatusKeys.invited
    const canViewOnMap = !!googlePlaceId

    const commonButtons = [
      canViewOnMap && {
        title: I18n.t("gameplans.viewOnMap"),
        image: images.directions,
        iconStyle: { tintColor: AppColors.brand.darkGrey2 },
        callback: () => {
          // teeupActions
          //     .getSuggestionPlace(suggestion.googlePlaceId)
          //     .then((res) => openSuggestionOnMap(res))
        },
      },
      !inactive && {
        title: I18n.t("gameplans.nudgeAboutSuggestion"),
        iconStyle: { tintColor: AppColors.brand.darkGrey2 },
        image: images.nudgePeople,
        callback: () => {
          // showNudgeParticipantsList({
          //     suggestionId: suggestion?.id,
          //     subTypeId: teeupListNudgeTypes.suggestion,
          // })
        },
      },
      {
        title: I18n.t("gameplans.commentOnSuggestion"),
        image: images.reply,
        iconStyle: getNotAllowedIconTintColor(allowComment),
        textStyle: getNotAllowedTextColor(allowComment),
        callback: () => {
          if (!allowComment) return

          expandConversationCard(true, () => {
            this.props.setActiveReplyMessage(suggestionMessage)
          })

          // routeBackToTeeup()
        },
      },
    ]

    const isDecideGameplanAllowed =
      decidedGameplanId > 0
        ? allowSelectGameplan && allowDecideGameplan
        : allowSelectGameplan

    let buttonsForUnselected = inactive
      ? []
      : [
          {
            title: I18n.t("gameplans.moveToGameplan"),
            iconStyle: getNotAllowedIconTintColor(isDecideGameplanAllowed),
            textStyle: getNotAllowedTextColor(isDecideGameplanAllowed),
            image: images.makeGameplan,
            callback: () => {
              if (!isDecideGameplanAllowed) {
                Toast.show(permissionsText.orgPermission, {
                  bottomOffset: 8,
                })
                return null
              }

              if (isSuggestionInPast) {
                Toast.show(I18n.t("gameplans.pastSuggestion"), {
                  bottomOffset: 8,
                })
              } else {
                this.promoteToGameplan(suggestion)
              }
            },
          },
        ]

    if (!isSuggestionInPast) {
      buttonsForUnselected = [
        ...buttonsForUnselected,
        {
          title: isWithdrawn
            ? I18n.t("gameplans.restoreSuggestion")
            : I18n.t("gameplans.withdrawSuggestion"),
          iconStyle: { tintColor: AppColors.brand.darkGrey2 },
          image: images.trashcanOutline,
          callback: () => this.handleToggleWithdraw(suggestion, !isWithdrawn),
        },
      ]
    }

    const buttonsForSelected = [
      {
        title: decided
          ? I18n.t("gameplans.markNotDecided")
          : I18n.t("gameplans.markDecided"),
        iconStyle: getNotAllowedIconTintColor(allowDecideGameplan),
        textStyle: getNotAllowedTextColor(allowDecideGameplan),
        image: images.lockOutline,
        callback: () =>
          allowDecideGameplan && this.markAsDecided(suggestion, !decided),
      },
      {
        title: I18n.t("gameplans.removeFromGameplan"),
        iconStyle: getNotAllowedIconTintColor(isDecideGameplanAllowed),
        textStyle: getNotAllowedTextColor(isDecideGameplanAllowed),
        image: images.makeGameplan,
        callback: () => {
          if (!isDecideGameplanAllowed) {
            Toast.show(permissionsText.orgPermission, {
              bottomOffset: 8,
            })
            return null
          }
          this.promoteToGameplan(suggestion, true)
        },
      },
    ]

    this.setState({ isActionSheetActive: true })

    // showActionsheet(
    //     [
    //         ...commonButtons,
    //         ...(selected ? buttonsForSelected : buttonsForUnselected),
    //     ],
    //     {},
    //     () => this.setState({ isActionSheetActive: false })
    // )
  }

  onAddSuggestion = gameplan => {
    const { allowSelectGameplan } = this.props.permissions

    this.setState({
      isSuggestionsVisible: false,
      gameplanContent: (
        <GameplanContainer
          {...gameplan}
          suggestions={[]}
          closeCreate={() => {
            this.setState({ isSuggestionsVisible: true })
            this.props.FromModal && this.props.closeSuggestions()
          }}
          allowPutOnGameplan={
            allowSelectGameplan && !gameplan?.suggestions?.length
          }
        />
      ),
    })

    // routeSetupGameplan({
    //     ...gameplan,
    //     suggestions: [],
    //     allowPutOnGameplan:
    //         allowSelectGameplan && !gameplan?.suggestions?.length,
    // })
  }

  suggestRecommendation = recommendedItem => {
    const { userId, activeTeeup } = this.props
    this.props.teeupActions.updateGameplan({
      teeupId: activeTeeup.id,
      title: "where",
      userId,
      value: recommendedItem.header,
      value2: undefined,
      recommendationId: recommendedItem.id,
    })
  }

  onSelectRecommendation = item => {
    const buttons = [
      {
        title: "Suggest this place",
        image: images.suggestOutline,
        callback: () => this.suggestRecommendation(item),
        iconStyle: RecommendationStyles.overlayButtonImage,
      },
      {
        title: "Add to new Game Plan row",
        image: images.plus,
        callback: () => {},
        iconStyle: RecommendationStyles.overlayButtonImage,
      },
    ]

    // showActionsheet(buttons)
  }

  showTimezoneView = suggestion => {
    const { participants, people, activeTeeup } = this.props
    const teeupParticipants = participants[activeTeeup.id]
    // showTimezonesSummary({
    //     participants:
    //         Object.keys(teeupParticipants).length > 0
    //             ? Object.values(teeupParticipants)
    //             : [],
    //     people,
    //     suggestion,
    //     suggestionValues: getSuggestionValue(
    //         suggestion,
    //         GAMEPLAN_OPTIONS.whenWorks
    //     ),
    // })
  }

  checkIsSuggestionInPast = currentSuggestion => {
    const { draggingSuggestion } = this.state
    const suggestion = currentSuggestion || draggingSuggestion
    return isPastSuggestion(_.get(suggestion, "props.suggestion"), "day")
  }

  handleShowTooltip = () => {
    this.highlighterRef.current.setVisible(headerTemplateKey)
    this.setState({ isTooltipVisible: true })
  }

  handleHideTooltip = () => {
    this.highlighterRef.current.setVisible()
    this.setState({ isTooltipVisible: false })
  }

  renderHeader = () => {
    const {
      people,
      permissions,
      activeTeeup,
      userId,
      draggingSuggestionId,
      currentUser,
      recommendations,
      suggestionMessages,
      closeSidebar,
    } = this.props
    const { sections, isActionSheetActive, sessionSuggestions } = this.state
    const section = sections[0]
    if (!section) return null

    if (section?.recommendations) {
      if (section.recommendations.length > 0) {
        return (
          <View style={RecommendationStyles.mainHeaderContainer}>
            <Text style={RecommendationStyles.mainHeaderText}>
              {section.title}
            </Text>
          </View>
        )
      }

      return null
    }

    let isDragging = !!section?.suggestions?.find(
      ({ id }) => id === draggingSuggestionId
    )

    let suggestion

    if (section.type === GAMEPLAN_OPTIONS.startsWhen) {
      suggestion = assign({}, section?.suggestions[0])
      if (suggestion) {
        suggestion.userAvailability = section.availability.find(
          availability => availability.userId === userId
        )
      } else {
        suggestion = {
          optionId: section.optionId,
          peopleCount: section.peopleCount,
          startDate: section.startDate,
          type: GAMEPLAN_OPTIONS.startsWhen,
        }
      }
      suggestion.currentPeopleCount = section.currentPeopleCount
    } else {
      suggestion =
        section?.suggestions?.find(suggestion => suggestion.selected) || {}
    }

    let isMultizone = isMultizoneTeeup(people)
    const activeSuggestionIndex = section?.suggestions?.findIndex(
      item => suggestion.id === item.id
    )

    const isActiveSuggestionDragging = this.checkActiveGameplanSuggestion()
    const isSuggestionInPast = this.checkIsSuggestionInPast()
    const dropTargetPermissionText = this.getPermissionDropText()

    return (
      <SuggestionSectionHeader
        suggestions={section?.suggestions}
        recommendations={recommendations}
        pollStatus={section.statusId}
        suggestion={suggestion}
        type={section.type}
        permissions={permissions}
        isMultizone={isMultizone}
        isTeeupCancelled={isTeeupCancelled(activeTeeup)}
        ref={ref => (this._sectionHeaderRef = ref)}
        isDragging={!isActiveSuggestionDragging && isDragging}
        currentUser={currentUser}
        sectionIndex={section.index}
        draggingSuggestionId={draggingSuggestionId}
        testID={`teeupGameplans-${activeSuggestionIndex}`}
        userId={userId}
        newSuggestions={sessionSuggestions}
        suggestionMessages={suggestionMessages}
        index={activeSuggestionIndex}
        handleOnLongPress={this.handleOnLongPress}
        isSuggestionInPast={isSuggestionInPast}
        dropTargetPermissionText={dropTargetPermissionText}
        highlighterRef={this.highlighterRef}
        headerTemplateKey={headerTemplateKey}
        onTooltipShow={this.handleShowTooltip}
        isActionSheetActive={isActionSheetActive}
        closeSidebar={closeSidebar}
      />
    )
  }

  renderFooter = () => {
    const {
      activeTeeup,
      handleOnSuggestPress,
      handleOpenSuggestion,
      blockFunction,
    } = this.props
    const { sections } = this.state
    const gameplan = sections[0]
    return (
      <SuggestionListFooter
        FromModal={this.props.FromModal}
        isDisabled={isTeeupDisabled(activeTeeup)}
        onAddSuggestion={() => {
          handleOpenSuggestion()
          !blockFunction && handleOnSuggestPress(gameplan)
        }}
        gameplan={gameplan}
      />
    )
  }

  renderItem = ({ item, section, index }) => {
    const gameplan = section
    let {
      suggestionMessages,
      people,
      removedSuggestions,
      permissions,
      recommendations,
      activeTeeup,
      draggingSuggestionId,
    } = this.props
    const { isActionSheetActive, sessionSuggestions } = this.state
    const { handleOpenSuggestion, handleOnSuggestPress, blockFunction } =
      this.props

    if (section?.recommendations) {
      if (section.recommendations.length > 0) {
        const numberOfPeople = Object.keys(people).length
        return (
          <RecommendationList
            recommendations={section.recommendations}
            onSelectRecommendation={this.onSelectRecommendation}
            numberOfPeople={numberOfPeople}
          />
        )
      }
      return null
    }

    if (gameplan.type === GAMEPLAN_OPTIONS.startsWhen) {
      const { suggestions } = gameplan
      let availability, startDate
      if (suggestions[0]) {
        availability = suggestions[0].availability
        startDate = suggestions[0].startDate
      } else {
        availability = gameplan.startDate
        startDate = gameplan.availability
      }

      return (
        <View key={section.title + index}>
          {availability && (
            <StartsWhenCarousel
              startDate={startDate}
              availability={availability}
            />
          )}
        </View>
      )
    }

    const suggestionsWithoutSelected = gameplan?.suggestions?.filter(
      item => item.selected === false
    )

    const showCloseButton =
      section.statusId === gameplanOptionStatusIds.POLLING || !section.statusId

    return (
      <View key={`${section.index} + ${index}`}>
        {gameplan.type === GAMEPLAN_OPTIONS.whenWorks ? (
          <SuggestionWhenWorksSectionContent
            gameplan={gameplan}
            newSuggestions={sessionSuggestions}
            people={people}
            type={gameplan.type}
            onAddSuggestion={() => this.onAddSuggestion(gameplan)}
            isDisabled={isTeeupDisabled(activeTeeup)}
            suggestionMessages={suggestionMessages}
            item={item}
            permissions={permissions}
            recommendations={recommendations}
            handleOnLongPress={this.handleOnLongPress}
            testID={`teeupGameplans-${index}`}
            isTeeupCancelled={isTeeupCancelled(activeTeeup)}
            isRowExpanded
            draggingSuggestionId={draggingSuggestionId}
            sectionIndex={section.index}
            isShowClosePollButton={showCloseButton}
          />
        ) : (
          <SuggestionSectionContent
            suggestions={suggestionsWithoutSelected}
            newSuggestions={sessionSuggestions}
            removedSuggestions={removedSuggestions}
            people={people}
            type={gameplan.type}
            // onAddSuggestion={() => this.onAddSuggestion(gameplan)}
            onAddSuggestion={() => {
              handleOpenSuggestion()
              !blockFunction && handleOnSuggestPress(gameplan)
            }}
            isDisabled={isTeeupDisabled(activeTeeup)}
            suggestionMessages={suggestionMessages}
            item={item}
            permissions={permissions}
            recommendations={recommendations}
            handleOnLongPress={this.handleOnLongPress}
            testID={`teeupGameplans-${index}`}
            isTeeupCancelled={isTeeupCancelled(activeTeeup)}
            isRowExpanded
            draggingSuggestionId={draggingSuggestionId}
            sectionIndex={section.index}
            isActionSheetActive={isActionSheetActive}
          />
        )}
      </View>
    )
  }

  handleClickMore = () => {
    const buttons = [
      {
        title: "Game Plan Options",
        image: images.fix,
        callback: this.showGameplanOptions,
      },
    ]
    // showActionsheet(buttons)
  }

  showGameplanOptions = () => {
    const { gameplans } = this.props
    // routeGameplanOptions({
    //     gameplans,
    // })
  }

  checkActiveGameplanSuggestion = () => {
    const { draggingSuggestion } = this.state

    if (draggingSuggestion) {
      const { props } = draggingSuggestion

      return props.suggestion.selected || props.suggestion.decided
    } else {
      return false
    }
  }

  onCancel = () => {
    // routeBack(this.props.componentId)
  }

  getActiveGameplanSuggestion = (gameplanOnScreen = {}) => {
    const { suggestions } = gameplanOnScreen

    return suggestions?.length > 0
      ? suggestions.find(suggestion => suggestion.selected)
      : null
  }

  render() {
    const {
      newSuggestions,
      activeTeeup,
      userId,
      draggingSuggestionId,
      gameplans,
      fromSuggestionModal,
      closeSidebar,
    } = this.props
    const {
      sections,
      draggingSuggestion,
      draggingRowPos,
      activeSectionIndex,
      isTooltipVisible,
      isSuggestionsVisible,
      gameplanContent,
    } = this.state
    const { organisers = [], name } = activeTeeup
    const sectionTitle = sections.length > 0 ? sections[0].title : ""
    const isTeeupOrganiser = isOrganiser(userId, organisers)
    const isActiveSuggestionDragging = this.checkActiveGameplanSuggestion()
    const dropTargetPermissionText = this.getPermissionDropText()
    const gameplanOnScreen = gameplans[activeSectionIndex] || {}
    const activeGameplanSuggestion =
      this.getActiveGameplanSuggestion(gameplanOnScreen)
    const section = sections[0]
    return (
      <>
        {isSuggestionsVisible ? (
          <>
            <Highlighter ref={this.highlighterRef} dimAmount={0.7} useStaticTop>
              <SuggestionListView
                gameplanOnScreen={gameplanOnScreen}
                sections={sections}
                extraData={newSuggestions}
                renderHeader={this.renderHeader}
                renderItem={this.renderItem}
                onClickGameplanOptions={
                  isTeeupOrganiser ? this.showGameplanOptions : null
                }
                onClickMore={this.handleClickMore}
                onDragSuggestion={this.handleDragSuggestion}
                isTeeupDisabled={isTeeupDisabled(activeTeeup)}
                testID={"teeupGameplans"}
                draggingSuggestion={draggingSuggestion}
                draggingSuggestionId={draggingSuggestionId}
                sectionListRef={ref => (this._sectionList = ref)}
                draggingSuggestionRowRef={ref =>
                  (this._suggestionHoverRef = ref)
                }
                activeSuggestionDropRef={ref => (this._sectionRef = ref)}
                draggingRowPos={draggingRowPos}
                renderFooter={this.renderFooter}
                isActiveSuggestionDragging={isActiveSuggestionDragging}
                teeupName={name}
                sectionTitle={sectionTitle}
                onCancel={this.props.closeSuggestions}
                dropTargetPermissionText={dropTargetPermissionText}
                activeGameplanSuggestion={activeGameplanSuggestion}
                onAddSuggestion={() => this.onAddSuggestion(section)}
                fromSuggestionModal={fromSuggestionModal}
                closeSidebar={closeSidebar}
              />
            </Highlighter>
            {isTooltipVisible && (
              <View style={SectionHeaderStyles.tooltipContainer}>
                {/* <ReactTooltip
                                buttonTitle={I18n.t('emptyGameplan.tooltipButton')}
                                text={I18n.t('emptyGameplan.tooltipText')}
                                action={this.handleHideTooltip}
                            /> */}
              </View>
            )}
          </>
        ) : (
          gameplanContent
        )}
      </>
    )
  }
}

SuggestionListContainer.defaultProps = {
  gameplans: [],
  people: {},
  suggestionMessages: {},
  suggestions: {},
  newSuggestions: {},
}

const mapStateToProps = state => {
  const gameplans = selectTeeupGameplans(state)
  const people = selectTeeupPeople(state)
  const permissions = selectPermissions(state)
  // const suggestionMessages = selectSuggestionMessages(state)
  const suggestionMessages = {}
  // const newSuggestions = selectNewSuggestions(state)
  const newSuggestions = {}
  const removedSuggestions = selectRemovedSuggestions(state)
  // const removedSuggestions = []

  const recommendationsIds = selectTeeupRecommendationsIds(state)
  const recommendations = selectTeeupRecommendations(state)
  const userId = selectUserId(state)
  const activeTeeup = selectActiveTeeup(state)
  // const draggingSuggestion = selectDraggingSuggestion(state)
  const draggingSuggestion = {}

  const participants = selectTeeupsParticipants(state)
  const messages = selectMessages(state)

  // const draggingSuggestionId = selectDraggingSuggestionId(state)
  const draggingSuggestionId = -1

  const currentUser = people[userId] || {}

  return {
    gameplans,
    participants,
    people,
    permissions,
    suggestionMessages,
    newSuggestions,
    removedSuggestions,
    recommendationsIds,
    recommendations,
    userId,
    activeTeeup,
    decidedGameplans: selectDecidedGameplans(state),
    draggingSuggestion,
    draggingSuggestionId,
    currentUser,
    messages,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...teeupActions,
      ...teeupCardActions,
      ...commonActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionListContainer)

SuggestionListContainer.propTypes = {
  gameplans: PropTypes.array,
  participants: PropTypes.object,
  suggestionMessages: PropTypes.object,
  item: PropTypes.object,
  people: PropTypes.object,
  newSuggestions: PropTypes.object.isRequired,
  removedSuggestions: PropTypes.array,
  permissions: PropTypes.object,
  recommendations: PropTypes.object,
  recommendationsIds: PropTypes.array,
  userId: PropTypes.number.isRequired,
  teeupActions: PropTypes.shape({
    updateGameplan: PropTypes.func.isRequired,
  }),
  activeTeeup: PropTypes.object,
  teeup: PropTypes.object,
  previewOn: PropTypes.bool,
  startTouch: PropTypes.object,
  draggingSuggestion: PropTypes.any,
  draggingSuggestionId: PropTypes.any,
  decidedGameplans: PropTypes.object,
  teeupCardActions: PropTypes.shape({
    updateDraggingSuggestion: PropTypes.func.isRequired,
  }),
  currentUser: PropTypes.object.isRequired,
  gameplanIndex: PropTypes.number,
  optionId: PropTypes.number,
  startDraggingSuggestion: PropTypes.func,
  updateDraggingSuggestion: PropTypes.func,
  stopDraggingSuggestion: PropTypes.func,
  componentId: PropTypes.string,
  // messages: PropTypes.array,
  setActiveReplyMessage: PropTypes.func,
  expandConversationCard: PropTypes.func,
}
