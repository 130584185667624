import React, { useContext } from "react"

import ReactDOM from "react-dom"

import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"

import { TeeupPageContext } from "../../contexts/TeeupPageContext"
import TeeupSettings from "../../pages/TeeUpPage/TeeUpPageLeftSection/TeeupSettings"
import DropDown from "../TeeUpsOverview/DropDown"

import { GoBack } from "./molecules"
import {
  Wrapper,
  Line,
  RightSide,
  DropdownWrapper,
  SettingsWrapper,
  SettignsIcon,
} from "./teeupOverviewHeaderStyles"

export const TeeupOverviewHeader = () => {
  const { teeup, isTeeupLoading, isSettings, setIsSettings } =
    useContext(NewTeeupPageContext)
  const { isTeeUpSettingsOpen, setIsTeeUpSettingsOpen } =
    useContext(TeeupPageContext)

  return (
    <>
      <Wrapper $photo={teeup.photo}>
        <Line>
          <GoBack name={teeup.name} />

          <RightSide>
            <DropdownWrapper>
              <DropDown
                isHigh
                isRight
                teeUp={teeup}
                title={teeup.name}
                imgUrl={teeup.photo}
              />
            </DropdownWrapper>

            <SettingsWrapper
              $isDisabled={isTeeupLoading}
              onClick={() => setIsSettings(!isSettings)}>
              <SettignsIcon name="settings-3" color="var(--white)" />
            </SettingsWrapper>
          </RightSide>
        </Line>
      </Wrapper>

      {isTeeUpSettingsOpen &&
        ReactDOM.createPortal(
          <TeeupSettings close={() => setIsTeeUpSettingsOpen(false)} />,
          document.querySelector("#modal-root")
        )}
    </>
  )
}
