import Separator from "@components/ui/DefaultModal/Separator"

import "./index.scss"
import { useSuggestionDurationStore } from "stores/suggestions/suggestionDurationStore"
import { SvgIcon } from "@components/common"

const Duration = () => {
  const duration = useSuggestionDurationStore(state => state.duration)
  const setIsDurationOpen = useSuggestionDurationStore(state => state.setIsOpen)

  return (
    <div className="general-duration">
      <Separator />
      <h4>Duration</h4>
      <div className="duration-box" onClick={() => setIsDurationOpen(true)}>
        <p>{duration.value}</p>

        <SvgIcon name="arrow-2" />
      </div>
    </div>
  )
}

export default Duration
