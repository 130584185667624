import React, { useEffect } from "react"

import { useCalendar } from "@hooks"

import useGeneralTimeTab from "../GeneralTimeContext/useGeneralTimeTab"

const CalendarSelect = () => {
  const { dates, Calendar } = useCalendar()
  const { setDaysSelected } = useGeneralTimeTab()

  useEffect(() => {
    setDaysSelected(dates)
  }, [dates, setDaysSelected])

  return <Calendar />
}

export default CalendarSelect
