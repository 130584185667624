import { SvgIcon } from "@components/common"

interface Props {
  title: string
  subtitle?: string
  iconName: string
}

const Info = ({ title, subtitle, iconName }: Props) => {
  return (
    <div className="flex items-center">
      <SvgIcon name={iconName} className="mr-2 !size-4" />
      <div className="text-sm font-semibold text-neutral-700">
        {title || "---"}
      </div>
      {subtitle && (
        <div className="ml-1 text-sm font-medium text-neutral-500">
          · {subtitle}
        </div>
      )}
    </div>
  )
}

export default Info
