import {
  HALF_OF_TIME_WHEEL,
  HOUR_ITEM_WIDTH,
  MINUTES_DAY_PARTS,
} from "../constants"

export const getScrollLeftOfCurrentDate = (currentDateIndex, pickerWidth) => {
  return (
    (currentDateIndex * HOUR_ITEM_WIDTH) / MINUTES_DAY_PARTS +
    pickerWidth / 2 -
    HALF_OF_TIME_WHEEL
  )
}
