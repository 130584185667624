import React, { useContext, useEffect, useState } from "react"

import { Button } from "@mui/material"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
  postGuestData,
  publishGuestCreatedSuggestions,
} from "@actions/guestActions"
import { InputEl, ButtonEl } from "@components/common"
import { teeupUserStatusKeys } from "@config/enums"
import { BUTTON_VARIANTS } from "@constants/button"
import { SOMETHING_WENT_WRONG } from "@constants/global"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { selectGuestReactions, selectGuestSuggestions } from "@selectors/guest"
import { TeeupPreviewContext } from "contexts/TeeupPreviewContext"

import { usePostingReactions, usePostingSuggestions } from "../hooks"
import { getSubmitChoicesValidationSchema } from "../submitChoicesValidationSchema"
import { GuestDoneModal, SomethingWentWrongModal } from "@components"

import { Wrapper } from "./confirmationScreenButtonsStyles"
import { Form, Fields, Text } from "./submitChoicesFormStyles"
import { useFormValidation, useDeviceTimezone } from "@hooks"

const SubmitChoicesForm = ({
  guestSuggestions,
  guestReactions,
  publishGuestCreatedSuggestions,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isDoneModal, setIsDoneModal] = useState(false)
  const {
    isSharedLink,
    isSharedLinkAddress,
    isPhoneInvite,
    previewInfo,
    stepsManager: { jumpToStep, goPrevStep, setIsMakeSuggestion },
    setIsResponseInfoModal,
    statusModalTrigger,
  } = useContext(TeeupPreviewContext)
  const isEmailField =
    (isSharedLink && !previewInfo?.invitee?.isSharedLink) || isPhoneInvite
  const { slug } = useParams()
  const postingReactions = usePostingReactions(guestReactions)
  const postingSuggestions = usePostingSuggestions(guestSuggestions)
  const { currentDeviceTimezone } = useDeviceTimezone({})
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const { validateForm, validationErrors } = useFormValidation(
    getSubmitChoicesValidationSchema(isEmailField),
    {
      username,
      email,
    },
    true
  )
  const isSubmitDisabled = !!validationErrors || isLoading || isDoneModal

  const prefillFields = () => {
    if (!previewInfo) return

    const { name, value, email } = previewInfo?.invitee
    if (name && !isPhoneInvite) {
      setUsername(name)
    }
    if (isEmailField && email && value && !isPhoneInvite) {
      setEmail(value)
    }
  }

  useEffect(() => {
    prefillFields()
  }, [previewInfo])

  const goToBeginning = () => {
    setIsDoneModal(false)
    jumpToStep(0)
    setIsMakeSuggestion(false)
  }

  const submitHandle = async e => {
    e.preventDefault()
    setIsLoading(true)

    const errors = await validateForm()

    if (errors) {
      return setIsLoading(false)
    }

    const bodyObj = {
      isSharedLink: isSharedLinkAddress,
      slug,
      data: {
        username,
      },
    }
    if (postingSuggestions.length > 0) {
      bodyObj.data.suggestions = postingSuggestions
    }
    if (postingReactions.length > 0) {
      bodyObj.data.reactions = postingReactions
    }
    if (isEmailField) {
      bodyObj.data.email = email
    } else if (!isSharedLinkAddress) {
      bodyObj.data.timezone = currentDeviceTimezone
    }
    if (isSharedLinkAddress) {
      bodyObj.status = statusModalTrigger || teeupUserStatusKeys.invited
    } else if (isPhoneInvite && statusModalTrigger) {
      bodyObj.status = statusModalTrigger
    }
    // COMMENTED BECAUSE OF BACKEND DON'T NEED THIS FOR NOW
    // if (linkedGuestCalendar) {
    //   bodyObj.data.auth0signature = linkedGuestCalendar?.accessToken
    // }

    postGuestData(bodyObj)
      .then(() => {
        publishGuestCreatedSuggestions(bodyObj.data.username)

        setIsLoading(false)

        if (isSharedLinkAddress || isPhoneInvite) {
          setIsResponseInfoModal(true)
          goToBeginning()
        } else {
          setIsDoneModal(true)
        }
      })
      .catch(error => {
        setErrorMessage(error.data?.message || SOMETHING_WENT_WRONG)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Form onSubmit={submitHandle}>
        <Fields>
          <InputEl
            isRounded
            label="Full name"
            name="username"
            placeholder="ie. Jane Doe"
            value={username}
            errorMessage={validationErrors?.username}
            onChange={setUsername}
          />

          {isEmailField && (
            <InputEl
              isRounded
              label="Email"
              name="email"
              placeholder="info@email.com"
              value={email}
              errorMessage={validationErrors?.email}
              onChange={setEmail}
            />
          )}

          <Text>
            {`Your name${
              isEmailField ? ` and email` : ""
            } will be visible to participants.`}
          </Text>
        </Fields>

        {/* <OrBlock /> */}

        {/* <RegistrationButtonsWrapper>
          <Text>Quick Sign-Up to get more out of CooWe</Text>

          <RegistrationButtons />
        </RegistrationButtonsWrapper> */}

        <Wrapper $isShown={username && (email || !isEmailField)}>
          <ButtonEl
            isBlack
            fullWidth
            type="submit"
            variant={BUTTON_VARIANTS.ROUNDED}
            text="Submit"
            isLoading={isLoading}
            disabled={isSubmitDisabled}
          />

          <Button
            variant="text"
            fullWidth
            onClick={goPrevStep}
            sx={{
              color: "var(--gray4)",
              fontSize: 16,
              fontWeight: 400,
              lineHeight: "24px",
              textTransform: "none",
            }}>
            Back
          </Button>
        </Wrapper>
      </Form>

      {errorMessage && (
        <SomethingWentWrongModal
          text={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      {!isSharedLinkAddress && isDoneModal && (
        <GuestDoneModal onClose={goToBeginning} />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  linkedGuestCalendar: selectGuestLinkedCalendar(state),
  guestSuggestions: selectGuestSuggestions(state),
  guestReactions: selectGuestReactions(state),
})

const mapDispatchToProps = dispatch => ({
  publishGuestCreatedSuggestions: bindActionCreators(
    publishGuestCreatedSuggestions,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitChoicesForm)
