import styled from "styled-components"

export const Wrapper = styled.section`
  width: 100%;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding: 16px 12px 16px;

  position: sticky;
  bottom: 0;
  left: 0;
`
