import React, { createContext, useState } from "react"

import { SYNC_CONTACTS_MODAL } from "./constants"
import {
  useModalData,
  useAccessToken,
  useSyncGoogle,
  useSyncApple,
} from "./hooks"

export const SyncContactsContext = createContext({})

export const SyncContactsState = ({ children }) => {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [isAppleLoading, setIsAppleLoading] = useState(false)
  const [connectorType, setConnectorType] = useState(null)
  const [connectorEmail, setConnectorEmail] = useState(null)
  const [modalType, setModalType] = useState(null)
  const [connectorId, setConnectorId] = useState(null)
  const [isDeletingConnector, setIsDeletingConnector] = useState(false)

  const getAccessToken = useAccessToken({
    setIsGoogleLoading,
    setIsAppleLoading,
    setModalType,
  })

  const showSyncingError = errorCode => {
    if (errorCode === 12) {
      setModalType(SYNC_CONTACTS_MODAL.ADDED_BY_DIFFERENT_ACCOUNT)
    } else {
      setModalType(SYNC_CONTACTS_MODAL.SOMETHING_WEND_WRONG)
    }
  }

  const handleSyncGoogleContacts = useSyncGoogle({
    setIsGoogleLoading,
    getAccessToken,
    setConnectorEmail,
    setModalType,
    setConnectorType,
    showSyncingError,
  })

  const handleSyncAppleContacts = useSyncApple({
    setIsAppleLoading,
    getAccessToken,
  })

  const closeModals = () => {
    setModalType(null)
    setConnectorType(null)
    setConnectorEmail(null)
    setConnectorId(null)
  }

  const contactsModalData = useModalData({
    modalType,
    connectorType,
    connectorEmail,
    connectorId,
    setIsDeletingConnector,
    setModalType,
    closeModals,
  })

  return (
    <SyncContactsContext.Provider
      value={{
        contactsModalData,
        modalType,
        connectorEmail,
        connectorId,
        isGoogleLoading,
        isAppleLoading,
        isDeletingConnector,
        setModalType,
        setConnectorType,
        setConnectorEmail,
        setConnectorId,
        handleSyncGoogleContacts,
        handleSyncAppleContacts,
      }}>
      {children}
    </SyncContactsContext.Provider>
  )
}
