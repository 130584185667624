import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { SvgIcon } from "@components/common"
import { selectUserInfo } from "@selectors/user"

import { Wrapper, Text } from "./chatSuggestionLabelStyles"
import { useChatSuggestionLabel } from "./hooks"

export const ChatSuggestionLabel = ({ suggestion }) => {
  const userInfo = useSelector(selectUserInfo)
  const isDecided = suggestion?.selected && suggestion?.decided
  const isPast =
    dayjs().isAfter(dayjs(suggestion.startDate)) &&
    !suggestion.isWithdrawn &&
    !isDecided
  const isNew =
    userInfo?.id !== suggestion?.creator?.id && !suggestion?.seenInfo?.isSeen
  const label = useChatSuggestionLabel(suggestion, isPast)

  const iconColor = isDecided
    ? "var(--green3)"
    : isPast
    ? "#000"
    : "var(--darkBlue)"

  const newText =
    isNew && !isDecided && !suggestion.isWithdrawn ? " · New suggestion" : ""
  const decidedText = isDecided ? " · Decided" : ""

  return (
    <Wrapper $isDecided={isDecided} $isPast={isPast}>
      <SvgIcon name={label.iconName} color={iconColor} />

      <Text $isDecided={isDecided} $isPast={isPast}>
        {label.text} {newText} {decidedText}
      </Text>
    </Wrapper>
  )
}

ChatSuggestionLabel.propTypes = {
  suggestion: PropTypes.object,
}
