import { useEffect } from "react"

export const useTriggerModal = ({
  statusModalTrigger,
  isGameplanReady,
  setStatus,
  setStatusModalTrigger,
}) => {
  const catchStatus = () => {
    if (isGameplanReady && statusModalTrigger) {
      setStatus(statusModalTrigger)
      setStatusModalTrigger(null)
    }
  }

  useEffect(() => {
    catchStatus()
  }, [statusModalTrigger, isGameplanReady])
}
