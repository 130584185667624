import cn from "classnames"

import { SvgIcon } from "@components/common"

import "./index.scss"
import { useMediaQuery } from "@mui/material"

import { MEDIA_SIZES } from "assets/styles/mediaSizes"

const Arrow = ({ isCollapsed }) => {
  return (
    <button className="collapse-button">
      <SvgIcon
        name="arrow-2"
        color="var(--gray4)"
        className={cn({ "collapse-icon-rotate": !isCollapsed })}
      />
    </button>
  )
}

const Collapse = ({
  children,
  title,
  titleContent,
  subTitleContent,
  isCollapsed,
  setIsCollapsed,
  containerClassName,
  contentClassName,
  isParent,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${MEDIA_SIZES.MOBILE})`)
  const isMobileCollapsed = isParent && isCollapsed
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div
      className={cn("collapse-container", containerClassName, {
        "collapse-container-parent": isMobileCollapsed,
      })}>
      <div
        className={cn("collapse-header", {
          "collapse-header-border": !isCollapsed,
        })}>
        <div className="collapse-header-top" onClick={handleToggle}>
          {isMobileCollapsed && isMobile && <Arrow isCollapsed={isCollapsed} />}

          <div className="collapse-title">
            <h4>{title}</h4>

            {titleContent}
          </div>

          {(!isMobileCollapsed || !isMobile) && (
            <Arrow isCollapsed={isCollapsed} />
          )}
        </div>

        {subTitleContent}
      </div>

      <div
        className={cn("collapse-content", contentClassName, {
          "collapse-content-open": isCollapsed,
        })}>
        {children}
      </div>
    </div>
  )
}

export default Collapse
