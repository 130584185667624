import React from "react"

import AppStoreIcon from "../../../assets/images/white-app-store.svg"
import GooglePlayIcon from "../../../assets/images/white-google-play.svg"
import { Title5 } from "../../../assets/styles/typographyStyles"
import {
  GOOGLE_PLAY_APP_LINK,
  APP_STORE_APP_LINK,
} from "../../../constants/appLinks"
import { AppStores, StoresLinks } from "../whiteCooweOverviewStyles"

export const DownloadBlock = () => (
  <AppStores>
    <Title5 style={{ color: "#fff" }}>Download The App</Title5>

    <StoresLinks>
      <a href={GOOGLE_PLAY_APP_LINK}>
        <img src={GooglePlayIcon} alt="google-play" />
      </a>

      <a href={APP_STORE_APP_LINK}>
        <img src={AppStoreIcon} alt="app-store" />
      </a>
    </StoresLinks>
  </AppStores>
)
