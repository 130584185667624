import { StyleSheet } from "react-native"

import { AppColors, Units } from "@theme/"

export default StyleSheet.create({
  unreadBarContainer: {
    position: "absolute",
    bottom: Units.responsiveValue(8),
    left: 0,
    right: 0,
    // transform: [{ scaleY: -1 }],
    alignItems: "center",
    zIndex: 100,
  },
  unreadBar: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: AppColors.card1Grey,
    borderWidth: 1,
    borderRadius: Units.responsiveValue(16),
    paddingVertical: Units.responsiveValue(8),
    paddingHorizontal: Units.responsiveValue(48),
    backgroundColor: "rgba(255,255,255,0.8)",
    shadowColor: AppColors.brand.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.35,
    elevation: 3,
  },
  unreadBarText: {
    fontSize: Units.responsiveValue(13),
    fontWeight: "bold",
    textTransform: "uppercase",
    color: AppColors.navbarButton,
  },
  unreadBarContent: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: "auto",
  },
  unreadBarClose: {
    position: "absolute",
    right: Units.responsiveValue(16),
  },
})
