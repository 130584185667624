import React, { useEffect, useState, useMemo } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
} from "react-native"

import strings from "@i18n"
import { Units } from "@theme/"
import Avatar from "@ui/avatar"
import { getNameOrUsername } from "@utils/contactUtils"
import { images } from "@utils/imageUtils"
import { removeSpaces } from "@utils/removeSpaces"

import {
  Title6,
  Subtitle,
  CaptionS,
} from "../../../assets/styles/typographyStyles"

import { ViewProps, ViewStyles } from "./editUserProfileStyles"
import checkValid from "./utils"

import "./index.scss"

const EditContactRow = ({ value, label, leftIcon, onAdd, onRemove }) => {
  return value ? (
    <View style={ViewStyles.editContactRow}>
      <View style={ViewStyles.contactValueWithIcon}>
        <View style={ViewStyles.iconWrapper}>
          <Image source={leftIcon} style={ViewStyles.contactIcon} />
        </View>
        <Text style={ViewStyles.editContactRowValue}>{value}</Text>
      </View>
      <TouchableOpacity onPress={onRemove} style={ViewStyles.iconWrapper}>
        <Image source={images.trashWhiteIcon} style={ViewStyles.trashIcon} />
      </TouchableOpacity>
    </View>
  ) : (
    <TouchableOpacity
      onPress={onAdd}
      style={ViewStyles.editContactRowWithPlusButton}>
      <View style={ViewStyles.iconWrapper}>
        <Image
          source={images.plusWithRoundBorder}
          style={ViewStyles.plusIcon}
        />
      </View>
      <Text style={ViewStyles.editContactRowLabel}>{label}</Text>
    </TouchableOpacity>
  )
}

const ClickableRow = ({
  title,
  value,
  onClick,
  phoneOrEmail,
  notSet,
  dataTestId,
  maxLength,
  handleRemove,
}) => {
  const [changeMode, setChangeMode] = useState(false)
  const [fieldValue, setFieldValue] = useState(value || "")
  const [error, setError] = useState(null)

  const isPublicInformation = phoneOrEmail || title === "Website"

  const inputValue = useMemo(() => {
    if (fieldValue || changeMode) {
      return fieldValue || ""
    }
    if (!changeMode && notSet) {
      return notSet
    }

    return value || ""
  }, [fieldValue, changeMode, notSet, value])

  const handleDone = async fieldValue => {
    const name = title.toLowerCase()

    if (name === "website" && !checkValid.isValidURL(fieldValue)) {
      setError("invalid value")

      return
    }

    if (name === "full name" && fieldValue.length < 3) {
      setError("Must be at least 3 characters")

      return
    }

    if (
      name === "display name" &&
      (!checkValid.isValidDisplayName(fieldValue) || fieldValue.length < 3)
    ) {
      const errorMessage =
        fieldValue.length < 3
          ? "Must be at least 3 characters"
          : "invalid value"
      setError(errorMessage)
      return
    }

    if (title === "Display Name") setFieldValue("@" + fieldValue)
    await onClick(fieldValue)
    setChangeMode(false)
    setError(null)
  }

  const handleChangeMode = () => {
    if (title === "Display Name") setFieldValue(value.slice(1))
    setChangeMode(true)
  }

  const handleChangeInput = event => {
    const value = event.target.value

    if (maxLength && value.length > maxLength) {
      return
    }

    if (title === "Display Name") {
      return setFieldValue(removeSpaces(value))
    }

    setFieldValue(value)
  }

  return (
    <div
      className={classNames("clickable-row__container", {
        "clickable-row-edit-mode": changeMode,
      })}>
      <div data-testid={title + "Field"} style={{ overflow: "hidden" }}>
        <Subtitle className="title">{title}</Subtitle>
        {!changeMode && (
          <Subtitle
            style={{ overflowWrap: "break-word" }}
            className="clickable-value">
            {value ? value : "Not Set"}
          </Subtitle>
        )}
      </div>
      <div>
        {changeMode ? (
          <div className="change-mode-wrapper">
            <div>
              {title === "Bio" ? (
                <textarea
                  value={inputValue}
                  onChange={event => handleChangeInput(event)}
                />
              ) : (
                <input
                  data-testid={dataTestId}
                  className="input-field"
                  value={inputValue}
                  disabled={changeMode ? false : true}
                  onChange={event => handleChangeInput(event)}
                />
              )}
              {error && (
                <CaptionS className="error public_profile">{error}</CaptionS>
              )}
              {maxLength && (
                <CaptionS className="caption">
                  {fieldValue.length}/{maxLength}
                </CaptionS>
              )}
            </div>
            <div className="buttons-wrapper">
              <button
                className="cancel-button"
                onClick={() => {
                  setChangeMode(false)
                  setFieldValue(value)
                }}>
                Cancel
              </button>
              <button
                className="save-button"
                onClick={() => handleDone(fieldValue)}>
                Save
              </button>
            </div>
          </div>
        ) : (
          <div>
            <Subtitle
              className="subtitle"
              onClick={() =>
                phoneOrEmail ? onClick(!!value) : handleChangeMode()
              }>
              {isPublicInformation && !value ? "Add" : "Change"}
            </Subtitle>
            {isPublicInformation && value && (
              <Subtitle
                className="subtitle remove-button"
                onClick={() => handleRemove(value)}>
                Remove
              </Subtitle>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

EditContactRow.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  // leftIcon: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

const modalStyles = {
  content: {
    width: 320,
    height: 300,
    top: "50%",
    right: "20%",
    left: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "transparent",
  },
  overlay: {
    backgroundColor: "transparent",
  },
}

const EditUserProfileView = ({
  userInfo = {},
  backgroundImage,
  handleFullNameChange,
  handleNicknameChange,
  handleBioChange,
  handleHometownChange,
  handleUrlChange,
  handlePhoneChange,
  handleEmailChange,
  handleAvatarChange,
  handleBackgroundChange,
  handleUrlRemove,
  handleRemovePhone,
  handleRemoveEmail,
  modalContent,
  contactMechanisms,
}) => {
  const [loading, setLoading] = useState(true)

  const { username, name, primaryLocation, primarylocation, nickname } =
    userInfo
  const { email, phone, url } = contactMechanisms

  const publicPhone = phone.find(item => item.isPublic)?.value
  const publicEmail = email.find(item => item.isPublic)?.value

  useEffect(() => {
    userInfo?.name || userInfo?.nickname ? setLoading(false) : setLoading(true)
  }, [userInfo])

  return (
    <View style={ViewStyles.container}>
      {!loading && (
        <>
          {/* <Modal
                        isOpen={isModalOpened}
                        onRequestClose={closeModal}
                        style={modalStyles}
                        ariaHideApp={false}
                    >
                        {modalContent}
                    </Modal> */}
          <ImageBackground
            source={backgroundImage ? { uri: backgroundImage } : null}
            style={ViewStyles.headerImage}></ImageBackground>
          <TouchableOpacity style={ViewStyles.backgroundEditIcon}>
            <Image
              source={require("@assets/images/editProfileImage.png")}
              style={{ height: 32 }}
              alt="edit profile background icon"
            />
            <input
              className="upload-input"
              style={{ width: 32, right: 0 }}
              type="file"
              onChange={handleBackgroundChange}
            />
          </TouchableOpacity>
          {/* <View style={ViewStyles.header}>
                        <View style={ViewStyles.headerActions}>
                            <TouchableOpacity
                                onPress={handleBack}
                                hitSlop={Units.hitSlop()}
                                style={ViewStyles.headerButton}
                            >
                                <Image
                                    source={images.back}
                                    style={[ViewStyles.headerButtonIcon, {height: 24, width: 24}]}
                                
                                />
                            </TouchableOpacity>
                            <Text style={ViewStyles.headerText}>
                                {strings.editProfile.editProfile}
                            </Text>
                            <View style={ViewStyles.headerButton} />
                        </View>
                    </View> */}
          <View style={ViewStyles.contentContainer}>
            <View style={ViewStyles.avatarStatusWrapper}>
              <TouchableOpacity
                hitSlop={Units.hitSlop()}
                onPress={() => {}}
                activeOpacity={1}
                disabled={true}>
                <Avatar
                  imageUrl={userInfo?.avatar || userInfo.user?.avatar}
                  size={ViewProps.avatarSize}
                  accesory={
                    <TouchableOpacity style={ViewStyles.avatarEditIcon}>
                      <img
                        src={require("@assets/images/editProfileImage.png")}
                        style={{ height: 32 }}
                        alt="edit profile avatar icon"
                      />
                      <input
                        className="upload-input"
                        style={{ width: 32, right: 0 }}
                        type="file"
                        onChange={handleAvatarChange}
                      />
                    </TouchableOpacity>
                  }
                />
              </TouchableOpacity>
            </View>
            <View style={ViewStyles.contentWrapper}>
              <View style={ViewStyles.userContainer}>
                <View style={ViewStyles.userContainerInformation}>
                  <Text style={ViewStyles.nameText}>
                    {getNameOrUsername(userInfo)}
                  </Text>
                  {(!!username || !!nickname) && (
                    <Text
                      style={(ViewStyles.additionalText, { color: "#767676" })}>
                      @{username || nickname}
                    </Text>
                  )}
                </View>
              </View>
              <View>
                <div className="public__wrapper">
                  <div className="header__container">
                    <Title6>Public Profile</Title6>
                    <div>
                      <Subtitle>
                        {strings.editProfile.publicProfileInfo}
                      </Subtitle>
                    </div>
                  </div>
                  <View style={ViewStyles.editBlockWrapper}>
                    <ClickableRow
                      dataTestId="profileNameField"
                      title={strings.editProfile.name}
                      value={name}
                      onClick={handleFullNameChange}
                      maxLength={40}
                    />
                    <ClickableRow
                      dataTestId="profileDisplayNameField"
                      title={strings.editProfile.displayName}
                      value={
                        username
                          ? `@${username}`
                          : nickname
                          ? `@${nickname}`
                          : "Not Set"
                      }
                      onClick={handleNicknameChange}
                      maxLength={12}
                    />
                    <ClickableRow
                      title={strings.editProfile.bio}
                      notSet={!userInfo?.about && "Not Set"}
                      value={userInfo?.about}
                      // value={userInfo?.about ? userInfo?.about : 'Not Set'}
                      onClick={handleBioChange}
                      maxLength={200}
                    />
                    <ClickableRow
                      title={strings.editProfile.hometown}
                      notSet={
                        !(Object.keys(userInfo).includes("primarylocation")
                          ? primarylocation
                          : primaryLocation) && "Not Set"
                      }
                      value={
                        Object.keys(userInfo).includes("primarylocation")
                          ? primarylocation
                          : primaryLocation
                      }
                      onClick={handleHometownChange}
                    />
                  </View>
                </div>
                <div className="public__wrapper">
                  <div className="header__container">
                    <Title6>Public information</Title6>
                    <div>
                      <Subtitle>{strings.editProfile.emailPhoneInfo}</Subtitle>
                    </div>
                  </div>
                  <View
                    style={[ViewStyles.editBlockWrapper, { marginBottom: 0 }]}>
                    <ClickableRow
                      title="Website"
                      notSet={!url.at(-1)?.value && "Not Set"}
                      value={url.at(-1)?.value}
                      onClick={handleUrlChange}
                      handleRemove={() => handleUrlRemove(url.at(-1)?.id)}
                    />
                    <ClickableRow
                      title="Mobile number"
                      notSet={!publicPhone && "Not Set"}
                      value={publicPhone}
                      onClick={handlePhoneChange}
                      phoneOrEmail
                      handleRemove={handleRemovePhone}
                    />
                    <ClickableRow
                      title="Email"
                      notSet={!publicEmail && "Not Set"}
                      value={publicEmail}
                      onClick={handleEmailChange}
                      handleRemove={handleRemoveEmail}
                      phoneOrEmail
                    />
                  </View>
                </div>
              </View>
            </View>
          </View>
        </>
      )}
      {modalContent}
    </View>
  )
}

export default EditUserProfileView

EditUserProfileView.propTypes = {
  userInfo: PropTypes.object,
  handleBack: PropTypes.func,
  backgroundImage: PropTypes.string,
  handleFullNameChange: PropTypes.func,
  handleNicknameChange: PropTypes.func,
  handleBioChange: PropTypes.func,
  handleHometownChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  handleUrlChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  handleEmailChange: PropTypes.func,
  handleAvatarChange: PropTypes.func,
  handleBackgroundChange: PropTypes.func,
  handleUrlRemove: PropTypes.func,
  handleLocationRemove: PropTypes.func,
  handleRemovePhone: PropTypes.func,
  handleRemoveEmail: PropTypes.func,
  location: PropTypes.object,
  url: PropTypes.object,
  email: PropTypes.object,
  phone: PropTypes.object,
}
