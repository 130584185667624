import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: var(--lightGray4);
  border: 1px solid var(--lightGray5);
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;
  padding: 16px;
  position: relative;
`
