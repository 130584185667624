import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"

import { ReactComponent as CopyIcon } from "@assets/images/copyNew.svg"
import { selectMessageById } from "@selectors/activeTeeup"
import { selectUserInfo } from "@selectors/user"
import {
  getSuggestionOnlineValuesFromLink,
  suggestionOnlineTypeOfLinks,
} from "@utils/gamePlanUtils"
import { socialIcons } from "@utils/imageUtils"
import { ReactComponent as OpenAppIcon } from "assets/images/openAppIcon.svg"

import { store } from "../../../../index"
import GoogleMap from "../../../CreateTeeUp/GoogleMap"

// TODO: almost all content of this component is copied from SuggestionPopup component - refactor this component.
// TODO: from here - src/components/gamePlansCard/SuggestionPopup/index.js

// TODO: also think about Context for SuggestionPopup component for less amount of props

// qwe
const SuggestionContent = ({
  currentSuggestion,
  people,
  initialSuggestions,
  userInfo,
}) => {
  const completeCurrentSuggestion =
    selectMessageById(currentSuggestion.id)(store.getState()) ||
    initialSuggestions?.find(
      suggestion => suggestion.id === currentSuggestion.id
    )

  const isCustomSuggestion =
    completeCurrentSuggestion?.locationType === 2 ||
    completeCurrentSuggestion?.isCustomTime ||
    completeCurrentSuggestion?.isCustomDate

  const address =
    currentSuggestion.type === "where" &&
    (completeCurrentSuggestion?.fullDetails ??
      `${
        completeCurrentSuggestion?.details ?? completeCurrentSuggestion?.value
      }`)

  const link = getSuggestionOnlineValuesFromLink(
    completeCurrentSuggestion?.details
  )?.link

  return (
    <>
      {isCustomSuggestion ? (
        completeCurrentSuggestion.value === suggestionOnlineTypeOfLinks.zoom &&
        getSuggestionOnlineValuesFromLink(completeCurrentSuggestion?.details)
          ?.type === suggestionOnlineTypeOfLinks.zoom ? (
          <div className="suggestion__popup__left__side--gameplan__container">
            <div className="suggestion__popup__left__side gameplan__customApp">
              <div className="suggestion__popup__left__side gameplan__customApp--title">
                [
                <img
                  src={
                    socialIcons.find(
                      icon => icon.name === suggestionOnlineTypeOfLinks.zoom
                    ).enabled.default
                  }
                  alt="zoom"
                  width="12px"
                  height="12px"
                />
                ]
                <span className="suggestion__popup__left__side gameplan__customApp--text">
                  Zoom
                </span>
              </div>
              <section className="suggestion__popup__left__side buttons_container">
                <div
                  className="suggestion__popup__left__side customButton__secondary"
                  onClick={() => navigator.clipboard.writeText(link)}>
                  <CopyIcon className="customButton__copySvg" />
                  <span className=" customButton__text">Copy Link</span>
                </div>
                <div
                  className="suggestion__popup__left__side customButton__primary"
                  onClick={() => window.open(link, "_blank")}>
                  <OpenAppIcon className="customButton__copySvg" />
                  <span className=" customButton__text">Open App</span>
                </div>
              </section>
            </div>
          </div>
        ) : null // TODO: make custom component for custom app display
      ) : (
        <div className="gameplan__content">
          {currentSuggestion.type === "where" &&
            (completeCurrentSuggestion?.locationType === 1 ? (
              <GoogleMap fromSuggestionPopup address={address} />
            ) : null)}
        </div>
      )}
    </>
  )
}

SuggestionContent.propTypes = {
  currentSuggestion: PropTypes.object,
  people: PropTypes.object,
}

const mapStateToProps = state => ({
  initialSuggestions: state.activeTeeup.messages.find(
    message => message.suggestionIds
  )?.gameplans,
  userInfo: selectUserInfo(state),
})

export default connect(mapStateToProps)(SuggestionContent)
