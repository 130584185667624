import { useState } from "react"

import { register } from "@actions/loginActions"
import { fetchUserInfo } from "@actions/userActions"

import api from "../../../api"
import { API_ERRORS_MESSAGES, REGISTER_STEPS } from "../constants"

export const useRegisterNames = ({
  setUserInfo,
  deviceId,
  accessToken,
  setUserInfoAction,
  addApiError,
  setStep,
}) => {
  const [isRegisterNamesLoading, setIsRegisterNamesLoading] = useState(false)

  const registerNames = ({
    userInfo,
    fullName,
    displayName,
    avatar,
    captcha,
    isInvite,
  }) => {
    setIsRegisterNamesLoading(true)
    const updatedUserInfo = {
      ...userInfo,
      avatar,
      name: fullName,
      nickname: displayName.replaceAll("@", ""),
      deviceId,
      captcha,
    }

    setUserInfo(updatedUserInfo)
    register(updatedUserInfo, accessToken)
      .then(response => {
        const localNewUserInfo = {
          ...updatedUserInfo,
          userId: response.data.id,
          ...response.data,
          loginType: "email",
        }
        api.createSession(localNewUserInfo)
        setUserInfo(localNewUserInfo)

        if (isInvite) {
          setStep(REGISTER_STEPS.INVITE_SENDER)
        } else {
          setUserInfoAction(localNewUserInfo)
          fetchUserInfo(true)
        }
        setIsRegisterNamesLoading(false)
      })
      .catch(error => {
        if (error.status === 409) {
          addApiError("displayName", API_ERRORS_MESSAGES.EMAIL_IS_USING)
        } else {
          if (error.status === 422) {
            addApiError("displayName", API_ERRORS_MESSAGES.INVALID_DISPLAY_NAME)
          } else if (error.status === 429) {
            addApiError("global", API_ERRORS_MESSAGES.TOO_MANY_REQUESTS)
          } else {
            addApiError("displayName", API_ERRORS_MESSAGES.EMAIL_IS_USING)
          }
        }
        setIsRegisterNamesLoading(false)
      })
  }

  return { isRegisterNamesLoading, setIsRegisterNamesLoading, registerNames }
}
