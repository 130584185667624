import React from "react"

import _ from "lodash"
import PropTypes from "prop-types"
import {
  View,
  Text,
  PanResponder,
  Animated,
  TouchableOpacity,
} from "react-native"
// import LinearGradient from 'react-native-linear-gradient'

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { dispatchSections } from "@actions/calendarActions"
import CalendarContainer from "@components/calendar"
import { Units, AppSizes } from "@theme"
import EmptyView from "@ui/emptyView"
import { preventBounceParams } from "@utils/animateUtils"
import { getMonthDay, getWeekdayName, isToday } from "@utils/dateUtils"
import { images } from "@utils/imageUtils"

import { CalendarStyles } from "./calendarCardStyles"
import "./index.scss"
import CalendarSectionList from "./CalendarSectionList"

const { height: SCREEN_HEIGHT, width: SCREEN_WIDTH } = AppSizes.screen

const styles = {
  contentContainer: height => ({
    position: "absolute",
    // top: Units.responsiveValue(16),
    right: 0,
    left: 0,
    height: height - Units.responsiveValue(32) - AppSizes.statusBarHeight,
    flex: 1,
    // "::-webkit-scrollbar": {
    "::WebkitScrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  }),
}

class CalendarCardView extends React.Component {
  constructor(props) {
    super(props)
    this.position = new Animated.ValueXY(this.props.currentPosition)
    this.divRef = React.createRef()
    this.state = {
      comingUpWidth: 0,
    }

    this._panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        const { dx, dy } = gestureState
        return Math.abs(dx) > 2 || Math.abs(dy) > 2
      },
      onPanResponderMove: this._handlePanResponderMove,
      onPanResponderRelease: this._handlePanResponderRelease,
    })
  }
  _handlePanResponderMove = (e, gesture) => {
    if (this._swipeInBounds(gesture)) {
      this.position.setValue({
        y: this.props.currentPosition.y + gesture.dy,
      })
    } else {
      this.position.setValue({
        y: this.props.upPosition.y - this._calculateEase(gesture),
      })
    }
  }

  _calculateEase(gesture) {
    return Math.min(Math.sqrt(gesture.dy * -1), Math.sqrt(SCREEN_HEIGHT))
  }

  _handlePanResponderRelease = (e, gesture) => {
    const {
      newSuggestionProps,
      toggleThreshold,
      currentPosition,
      upPosition,
      middlePosition,
      bottomPosition,
    } = this.props
    if (gesture.dy > toggleThreshold) {
      if (currentPosition === upPosition) {
        this._transitionTo(newSuggestionProps ? bottomPosition : middlePosition)
      } else if (currentPosition === middlePosition) {
        this._transitionTo(newSuggestionProps ? bottomPosition : middlePosition)
      }
    } else if (gesture.dy < -toggleThreshold) {
      if (currentPosition === middlePosition) {
        this._transitionTo(upPosition)
      } else if (currentPosition === upPosition) {
        this._transitionTo(upPosition)
      }
    } else {
      this._resetPosition()
    }
  }

  _transitionTo = position => {
    Animated.spring(this.position, {
      useNativeDriver: false,
      toValue: position,
      ...preventBounceParams,
    }).start()
    this.props.setCurrentPosition(position)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sections.length !== this.props.sections.length)
      this.props.dispatchSections(this.props.sections)
    if (prevProps.currentPosition !== this.props.currentPosition) {
      this._transitionTo(this.props.currentPosition)
    }
  }

  componentDidMount() {
    this.props.dispatchSections(this.props.sections)
  }

  changePosition = ({ stopInTopPosition }) => {
    const { upPosition, togglePosition, currentPosition } = this.props
    if (stopInTopPosition && currentPosition === upPosition) {
      return
    }
    const position = togglePosition()
    this._transitionTo(position)
  }

  _resetPosition() {
    Animated.spring(this.position, {
      useNativeDriver: false,
      toValue: this.props.currentPosition,
    }).start()
  }

  _swipeInBounds(gesture) {
    const { currentPosition, upPosition } = this.props
    return currentPosition.y + gesture.dy > upPosition.y
  }

  formatHeader = () => {
    const { headerDays, sections, scrollToDay } = this.props
    return (
      <View style={CalendarStyles.daysContainer}>
        {headerDays.map((day, index) => (
          <TouchableOpacity
            onPress={() => scrollToDay(day)}
            key={index}
            style={CalendarStyles.daysRow}>
            <Text style={CalendarStyles.dayText}>{getWeekdayName(day)}</Text>
            <View
              style={[
                CalendarStyles.monthContainer,
                isToday(day) && CalendarStyles.todayMonthContainer,
                // day === activeDayHeader && CalendarStyles.activeMonthContainer,
              ]}>
              <Text
                style={[
                  CalendarStyles.dayText,
                  // day === activeDayHeader && CalendarStyles.activeMonth,
                ]}>
                {getMonthDay(day)}
              </Text>
            </View>
            <View style={CalendarStyles.indicatorContainer}>
              {sections[index].id === day &&
                sections[index].status.planning && (
                  <View
                    style={[
                      CalendarStyles.indicator,
                      CalendarStyles.planningIndicator,
                    ]}
                  />
                )}
              {sections[index].id === day && sections[index].status.allset && (
                <View
                  style={[
                    CalendarStyles.indicator,
                    CalendarStyles.allsetIndicator,
                  ]}
                />
              )}
              {sections[index].id === day &&
                sections[index].status.happening && (
                  <View
                    style={[
                      CalendarStyles.indicator,
                      CalendarStyles.happeningIndicator,
                    ]}
                  />
                )}
            </View>
          </TouchableOpacity>
        ))}
      </View>
    )
  }

  getCompingUpContainer = () => {
    const {
      sections,
      renderItem,
      renderSectionHeader,
      onViewableItemsChanged,
      // comingUpHeaderRef,
      setHeaderDays,
    } = this.props

    if (!sections.find(section => !_.isEmpty(section.data))) {
      return (
        <View style={CalendarStyles.flex}>
          {/* <View style={CalendarStyles.comingUpHeader}>
                        {this.formatHeader()}
                    </View> */}
          <EmptyView
            customContainerStyle={CalendarStyles.comingUpListEmptyContainer}
            title={"No Scheduled TeeUps"}
            subtitle={
              'Only TeeUps with an exact "When" in the\nGame-plan will be displayed in your CooWe\nCalendar'
            }
            imageSrc={images.noScheduledTeeups}
            btn={false}
            scrollEnabled={false}
          />
        </View>
      )
    }

    return (
      <View style={CalendarStyles.flex}>
        {/* <View style={CalendarStyles.comingUpHeader}>
                    {this.formatHeader()}
                </View> */}
        <CalendarSectionList
          renderSectionHeader={renderSectionHeader}
          renderItem={renderItem}
          onViewableItemsChanged={onViewableItemsChanged}
          setHeaderDays={setHeaderDays}
          // ref={comingUpHeaderRef}
        />
      </View>
    )
  }

  render() {
    const { comingUpWidth } = this.state
    const {
      currentPosition,
      upPosition,
      contentHeight,
      newSuggestionProps,
      onCloseCalendarCard,
      activeScreen,
      bottomMargin,
      middlePosition,
      isFromSugggestionDetails,
      togglePosition,
      toggleUp,
    } = this.props
    const left = 0

    return (
      <>
        <div className="calendarCard">
          <View
            ref={this.divRef}
            style={styles.contentContainer(contentHeight)}
            pointerEvents={"box-none"}>
            <CalendarContainer
              allowGoToPast={!newSuggestionProps}
              newSuggestionProps={newSuggestionProps}
              useComingUpScreen={!newSuggestionProps}
              showSettingsIcon={!newSuggestionProps}
              comingUpComponent={this.getCompingUpContainer}
              changePosition={this.changePosition}
              calendarCardStyles={CalendarStyles}
              onCloseCalendarCard={onCloseCalendarCard}
              activeScreen={activeScreen}
              currentPosition={currentPosition}
              middlePosition={middlePosition}
              isFromSugggestionDetails={isFromSugggestionDetails}
              toggleUp={toggleUp}
              openSettings={this.props.openSettings}
              comingUpWidth={
                comingUpWidth > 0
                  ? comingUpWidth
                  : this.divRef.current && this.divRef.current.clientWidth
              }
            />
          </View>
        </div>
      </>
    )
  }
}

CalendarCardView.propTypes = {
  toggleThreshold: PropTypes.number.isRequired,
  // currentPosition: PropTypes.object.isRequired,
  upPosition: PropTypes.object.isRequired,
  // middlePosition: PropTypes.object.isRequired,
  bottomPosition: PropTypes.object.isRequired,
  bottomMargin: PropTypes.number,
  headerDays: PropTypes.array.isRequired,
  setHeaderDays: PropTypes.func,
  setCurrentPosition: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderSectionHeader: PropTypes.func.isRequired,
  onViewableItemsChanged: PropTypes.func.isRequired,
  // activeDayHeader: PropTypes.string.isRequired,
  activeScreen: PropTypes.string,
  // contentHeight: PropTypes.number.isRequired,
  // comingUpHeaderRef: PropTypes.object,
  scrollToDay: PropTypes.func,
  togglePosition: PropTypes.func,
  onCloseCalendarCard: PropTypes.func,
  newSuggestionProps: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      duration: PropTypes.number,
      isFromCreateTeeup: PropTypes.bool,
      passedEvent: PropTypes.object,
      selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      onDatesSelected: PropTypes.func.isRequired,
    }).isRequired,
  ]),
  isFromSugggestionDetails: PropTypes.bool,
}

const mapDispatchToProps = dispatch => ({
  dispatchSections: bindActionCreators(dispatchSections, dispatch),
})

export default connect(null, mapDispatchToProps)(CalendarCardView)
