import "./index.scss"
import cn from "classnames"

interface Props {
  onClick: () => void
  children: React.ReactNode
  styles?: React.CSSProperties
  className?: string
  variant?: "primary"
  isSelected?: boolean
  isDisabled?: boolean
}

const ActionButton = ({
  onClick,
  children,
  styles,
  className,
  variant,
  isSelected,
  isDisabled,
}: Props) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={cn("people-action-button", className, {
        "people-action-button-primary": variant === "primary",
        "people-action-button-selected": isSelected,
        "people-action-button-disabled": isDisabled,
      })}
      style={styles}>
      {children}
    </button>
  )
}

export default ActionButton
