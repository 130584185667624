import React from "react"

import EditIcon from "@assets/images/edit-user-avatar.svg"

import { InputAvatar, InputAvatarEditIcon } from "../newSignUpEmailStyles"

export const UserAvatar = ({ picture, onChange }) => (
  <>
    <InputAvatar picture={picture} />
    <InputAvatarEditIcon
      type="file"
      accept=".png,.jpg,.jpeg,.webp,.gif"
      onChange={onChange}
      picture={EditIcon}
    />
  </>
)
