import dayjs from "dayjs"

import { roundTimeToFifteenMinutes } from "@utils/timeUtils"

export const roundMinutesToFifteen = dateString => {
  const date = dayjs(dateString)
  const finalMinutes = roundTimeToFifteenMinutes(date)

  return date.minute(finalMinutes).format()
}
