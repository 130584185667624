import React, { useState, useEffect } from "react"

import { useDispatch } from "react-redux"
import { useRouteMatch, useHistory } from "react-router-dom"

import { setShowSideBar } from "@actions/commonActions"
import { logOut } from "@actions/loginActions"
import { deleteUser, cancelDeleteUser } from "@actions/userActions"
import { ConfirmDeleteAccoutPopup } from "@components/ConfirmDeleteAccoutPopup"
import { STORAGE_KEYS } from "@constants/storageKeys"
import { getItem } from "@utils/localStorage"

const useDeleteAccount = () => {
  const data = useRouteMatch()
  const { params, path } = data
  const [isDeleteCalled, setIsDeleteCalled] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const isCancel = path === "/users/email/delete/cancel/:token"

  const [modal, setModal] = useState(null)

  const cancelStep = () => {
    cancelDeleteUser(params.token).finally(() => {
      history.push({
        pathname: "/settings",
        search: "?option=accountManagement",
        state: { isCancel: true },
      })
    })
  }

  const handleDeleteAccount = () => {
    deleteUser(params.token)
      .then(() => {
        sessionStorage.setItem(STORAGE_KEYS.IS_CLEAN_PAUSE, "1")
        setModal(
          <ConfirmDeleteAccoutPopup
            title="You deleted your CooWe account!"
            description="Your request to delete your CooWe account is complete."
          />
        )

        setTimeout(() => {
          sessionStorage.removeItem(STORAGE_KEYS.IS_CLEAN_PAUSE)
          setModal(null)
          logOut()(() => {})
          history.push("/sign-in")
        }, 3000)
      })
      .catch(() => {
        dispatch(setShowSideBar(true))
        cancelStep()
      })
  }

  useEffect(() => {
    const userInfo = getItem("userInfo")

    if (isDeleteCalled) return
    setIsDeleteCalled(true)

    if (isCancel) {
      cancelStep()
      return
    }

    if (params.token && userInfo) {
      handleDeleteAccount()
    }
  }, [data, isDeleteCalled])

  return modal
}

export default useDeleteAccount
