import React from "react"

import Linkify from "linkify-react"

import { Value, ValuesWrapper } from "../suggestionItemStyles"

export const useValue = ({
  isPopup,
  isDecided,
  detailsValue,
  isCollapsed,
  value,
  isDecidedInPopup,
  isMap,
}) => {
  const renderLinkValue = () => {
    return isPopup || isDecided ? (
      <Linkify
        options={{
          className: "message-link",
        }}>
        {detailsValue}
      </Linkify>
    ) : (
      detailsValue
    )
  }
  const renderValues = () => {
    if (isCollapsed && isMap) {
      return (
        <Value isCollapsed={isCollapsed}>
          <b>{detailsValue} &#8226;</b> {value}
        </Value>
      )
    } else if (isCollapsed) {
      return (
        <Value isCollapsed={isCollapsed}>
          <b>{value} &#8226;</b> {detailsValue}
        </Value>
      )
    } else {
      return (
        <ValuesWrapper>
          <Value
            $isBold
            isCollapsed={isCollapsed}
            $color={isDecidedInPopup && "var(--black)"}>
            {value}
          </Value>
          <Value
            isCollapsed={isCollapsed}
            $color={isDecidedInPopup && "var(--gray4)"}>
            {renderLinkValue()}
          </Value>
        </ValuesWrapper>
      )
    }
  }

  return { renderLinkValue, renderValues }
}
