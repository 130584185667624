import { useContext, useState } from "react"

import { useSelector } from "react-redux"

import { ContactAvatar, IconButtonEl, SvgIcon } from "@components/common"
import { NewTeeupPageContext } from "@contexts/NewTeeupPageContext"
import { selectTeeupPeople } from "@selectors/teeups"
import { Snackbar } from "@ui/Snackbar"

import { ParticipantAvatarWrapper } from "../../ParticipantAvatarWrapper"
import { ParticipantListActions } from "../../ParticipantListActions"

import {
  List,
  ListItem,
  LeftSide,
  Name,
  InvitedBy,
  TextWrapper,
} from "./participantsListStyles"

export const ParticipantsList = () => {
  const { teeup, disableOrganiserActions } = useContext(NewTeeupPageContext)
  const people = useSelector(selectTeeupPeople)
  const peopleArr = Object.values(people)
  const peopleCount = peopleArr.length
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const getInviteInfo = personId => {
    //
    const inviteInfo = teeup.invitationHistory.find(invite =>
      invite.invitees.includes(`${personId}`)
    )

    return peopleArr.find(({ id }) => id === inviteInfo?.inviter)
  }

  return (
    <List>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setIsSnackbarOpen(false)}
        message="The action you are trying to perform cannot be done. You are required to have at least one participant in the TeeUp"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className="snackbar"
        sx={{
          "&.MuiSnackbar-root": {
            backgroundColor: "#fff !important",
            color: "#000 !important",
          },
        }}
      />
      {peopleArr.map(person => {
        const invitedBy = getInviteInfo(person.id)

        return (
          <ListItem key={person.id}>
            <LeftSide>
              <ParticipantAvatarWrapper
                isOrganiser={person.isOrganiser}
                status={person.status}>
                <ContactAvatar
                  src={person.avatar}
                  text={person.name}
                  avatar={person.avatar}
                  size={48}
                />
              </ParticipantAvatarWrapper>

              <TextWrapper>
                <Name>{person.name}</Name>
                {invitedBy && (
                  <InvitedBy>Invited by {invitedBy.name}</InvitedBy>
                )}
              </TextWrapper>
            </LeftSide>

            {!disableOrganiserActions(person) && (
              <SvgIcon
                // isSvg
                name="three-dots-2"
                size={20}
                disabled={false}
                onClick={e => {
                  e.stopPropagation()
                  setActiveDropdown(person.id)
                }}
              />
            )}
            {activeDropdown === person.id && (
              <ParticipantListActions
                person={person}
                onClose={() => setActiveDropdown(null)}
                peopleCount={peopleCount}
                setIsSnackbarOpen={setIsSnackbarOpen}
              />
            )}
          </ListItem>
        )
      })}
    </List>
  )
}
