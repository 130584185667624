import React, { createContext, useEffect, useMemo, useState } from "react"

import _ from "lodash"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
  manageGuestGoogleEvents,
  manageGuestOutlookEvents,
} from "@actions/calendarActions"
import {
  setGuestSuggestions,
  setNewGuestSuggestion,
  setMyGuestTeeupReactions,
  resetAllGuestData,
} from "@actions/guestActions"
import { setTeeupInviteInfo } from "@actions/loginActions"
import { getUserContactMechanisms } from "@actions/userActions"
import { loginProviders } from "@config/enums"
import { selectActiveTeeup } from "@selectors/activeTeeup"
import { selectGuestLinkedCalendar } from "@selectors/calendar"
import { selectNewGuestSuggestion } from "@selectors/guest"
import {
  selectContactMechanisms,
  selectToken,
  selectUserInfo,
} from "@selectors/user"
import { scrollToTop } from "@utils/scrollToTop"
import { usePreviewScreenStore } from "stores/previewScreen"

import { TEEUP_PREVIEW_ERRORS_INFO } from "./constants"
import {
  useContactWarning,
  useGamePlan,
  useGuestStatus,
  useLocalSuggestions,
  usePreviewInfo,
  useTeeupRedirect,
  useSuggestSteps,
} from "./hooks"

export const TeeupPreviewContext = createContext({})

const TeeupPreviewState = ({
  isInbox,
  isSharedLink,
  children,
  isAuthenticated,
  userInfo,
  teeup,
  contactMechanisms,
  setTeeupInviteInfo,
  linkedGuestCalendar,
  manageGuestGoogleEvents,
  manageGuestOutlookEvents,
  setGuestSuggestions,
  newGuestSuggestion,
  setNewGuestSuggestion,
  setMyGuestTeeupReactions,
  resetAllGuestData,
}) => {
  const [isStatusModal, setIsStatusModal] = useState(false)
  const [isResponseInfoModal, setIsResponseInfoModal] = useState(false)
  const [confirmingStatus, setConfirmingStatus] = useState("")
  const [isStatusConfirmedModal, setIsStatusConfirmedModal] = useState(false)
  const [confirmingBadgeVal, setConfirmingBadgeVal] = useState("")
  const [isLoginTab, setIsLoginTab] = useState(false)
  const [isSignUpBlink, setIsSignUpBlink] = useState(false)
  const [isRedirectChecking, setIsRedirectChecking] = useState(false)
  const [statusModalTrigger, setStatusModalTrigger] = useState(null)
  const currentSelectedDay = usePreviewScreenStore(
    state => state.currentSelectedDay
  )
  const history = useHistory()

  useEffect(() => {
    return () => {
      resetAllGuestData()
      console.log("unmount")
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      getUserContactMechanisms()
    }
  }, [isAuthenticated])

  const closePreviewError = () => {
    setPreviewError(null)
    history.push("/")
  }

  const {
    isPreviewLoading,
    previewInfo,
    previewError,
    setPreviewError,
    updatePreviewInfo,
  } = usePreviewInfo({
    isInbox,
    isSharedLink,
    teeupSlug: teeup.slug,
    closePreviewError,
  })
  const gameplanInfo = useGamePlan(previewInfo)
  const suggestionsManager = useLocalSuggestions({
    newGuestSuggestion,
    gameplanInfo,
    previewInfo,
    setGuestSuggestions,
    setNewGuestSuggestion,
    setMyGuestTeeupReactions,
    currentSelectedDay,
  })

  useEffect(() => {
    if (!!gameplanInfo && statusModalTrigger) {
      // setIsStatusConfirmedModal(true)
      stepsManager.jumpToStep(3)
    }
  }, [statusModalTrigger, gameplanInfo])

  useTeeupRedirect({
    isPreviewLoading,
    previewInfo,
    setIsRedirectChecking,
  })
  const stepsManager = useSuggestSteps()

  const previewErrorInfo = useMemo(() => {
    return previewError && TEEUP_PREVIEW_ERRORS_INFO[previewError]
  }, [previewError])

  const contactWarning = useContactWarning({
    isAuthenticated,
    contactValue: previewInfo?.invitee?.value,
    contactMechanisms,
  })

  const goToSignUp = () => {
    setIsLoginTab(false)
    setIsSignUpBlink(true)

    if (window.screen.width < 961) {
      scrollToTop()
    }

    setTimeout(() => {
      setIsSignUpBlink(false)
    }, 1000)
  }

  const saveInviteInfo = () => {
    if (isSharedLink) return

    const contactVal = previewInfo?.invitee.value
    const isEmail = previewInfo?.invitee.email

    setTeeupInviteInfo({
      email: isEmail ? contactVal : null,
      phone: previewInfo?.invitee.phone ? contactVal : null,
      inviterName: previewInfo?.inviter?.name || "",
      inviterAvatar: previewInfo?.inviter?.avatar || "",
      type: isEmail ? "email" : "SMS",
      message: isEmail ? "inbox" : "text messages",
    })
  }

  const {
    isRSVPStatusBadge,
    guestStatusMessage,
    RSVPWarningBadgeVal,
    setGuestStatus,
  } = useGuestStatus({
    isRegisteredUser: previewInfo?.invitee.isCooe,
    teeupTitle: previewInfo?.title,
    isSharedLink,
    previewInfo,
    setStatusModalTrigger,
    updatePreviewInfo,
    setIsStatusConfirmedModal,
    setConfirmingStatus,
  })

  const isPhoneInvite = previewInfo?.invitee?.phone
  const isInviteeSharedLink = previewInfo?.invitee?.isSharedLink

  const fetchGuestCalendarsEvents = params => {
    if (linkedGuestCalendar.provider === loginProviders.google) {
      manageGuestGoogleEvents(params)
    } else {
      manageGuestOutlookEvents(params)
    }
  }

  return (
    <TeeupPreviewContext.Provider
      value={{
        isSharedLinkAddress: isSharedLink,
        isSharedLink: isSharedLink || isInviteeSharedLink,
        isPhoneInvite,
        isRedirectChecking,
        isLoginTab,
        setIsLoginTab,
        isSignUpBlink,
        setIsSignUpBlink,
        isInbox,
        previewInfo,
        previewErrorInfo,
        gameplanInfo,
        isAuthenticated,
        userInfo,
        contactWarning,
        isRSVPStatusBadge,
        RSVPWarningBadgeVal,
        guestStatusMessage,
        goToSignUp,
        closePreviewError,
        saveInviteInfo,
        statusModalTrigger,
        setStatusModalTrigger,
        setGuestStatus,
        isStatusModal,
        setIsStatusModal,
        isResponseInfoModal,
        setIsResponseInfoModal,
        confirmingStatus,
        setConfirmingStatus,
        isStatusConfirmedModal,
        setIsStatusConfirmedModal,
        confirmingBadgeVal,
        setConfirmingBadgeVal,
        suggestionsManager,
        stepsManager,
        fetchGuestCalendarsEvents,
      }}>
      {children}
    </TeeupPreviewContext.Provider>
  )
}

const mapStateToProps = state => {
  const contacts = selectContactMechanisms(state)

  return {
    isAuthenticated: selectToken(state),
    userInfo: selectUserInfo(state),
    teeup: selectActiveTeeup(state),
    contactMechanisms: [contacts["email"], contacts["phone"]].flat(),
    linkedGuestCalendar: selectGuestLinkedCalendar(state),
    newGuestSuggestion: selectNewGuestSuggestion(state),
  }
}

const mapDispatchToProps = dispatch => ({
  setTeeupInviteInfo: payload => dispatch(setTeeupInviteInfo(payload)),
  manageGuestGoogleEvents: params => dispatch(manageGuestGoogleEvents(params)),
  manageGuestOutlookEvents: params =>
    dispatch(manageGuestOutlookEvents(params)),
  setGuestSuggestions: bindActionCreators(setGuestSuggestions, dispatch),
  setNewGuestSuggestion: bindActionCreators(setNewGuestSuggestion, dispatch),
  setMyGuestTeeupReactions: bindActionCreators(
    setMyGuestTeeupReactions,
    dispatch
  ),
  resetAllGuestData: () => dispatch(resetAllGuestData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeeupPreviewState)
