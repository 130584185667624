import React, { useMemo } from "react"

import googlePlay from "../../../assets/images/newDesign/ndLoginGooglePlay.png"

import appStoreWebp from "@assets/images/newDesign/ndLoginAppStore.webp"
import Logo from "@assets/images/newDesign/ndLoginLogo.png"

import "./index.scss"
import classNames from "classnames"

import { MakesMeetingHappen, WhiteCooweOverview } from "@components"
import { FooterBlock, PreviewHeader } from "@components/TeeupPreview/molecules"
import { GetAppBadge } from "@components/common/GetAppBadge"
import { useScreenWidth, useAppLink } from "@hooks"
import { getCurrentEnvName } from "@utils/checkEnv"

import appStore from "../../../assets/images/newDesign/ndLoginAppStore.png"
import googlePlayWebp from "../../../assets/images/newDesign/ndLoginGooglePlay.webp"

// TODO: temporarily hid this statusbar. in the future we'll back it with working logic
// const SLIDE_SECONDS = 2
// const SLIDE_TOTAL = 5

/**
 * This template is for 3 pages: Sign in with email, Sign in with social medias and Sign Up
 * @param type {string} represents the 3 pages: 'signin-email', 'signin-social' and 'signup'
 *
 */

const CustomTemplateSign = ({ type, children }) => {
  // TODO: temporarily hid this statusbar. in the future we'll back it with working logic
  // const [actualSlide, setActualSlide] = useState(0)

  // TODO: temporarily hid this statusbar. in the future we'll back it with working logic
  // useEffect(() => {
  //   const interval = setInterval(
  //     () => setActualSlide((actualSlide + 1) % SLIDE_TOTAL),
  //     SLIDE_SECONDS * 1000
  //   )
  //   return () => clearInterval(interval)
  // }, [actualSlide])

  const { isMobile } = useScreenWidth()
  const { appLink } = useAppLink()

  const renderFooter = () => (
    <div className="ctsign__left__body__bottom">
      <a
        href="https://play.google.com/store/apps/details?id=com.app.cooe"
        target="_blank"
        rel="noopener noreferrer">
        <picture>
          <source srcSet={googlePlayWebp} type="image/webp" />
          <img
            src={googlePlay}
            className="ctsign__left__body__bottom-img"
            alt="googlePlay img"
          />
        </picture>
      </a>
      <a
        href="https://apps.apple.com/app/coo-e-coordinator/id1507948580"
        target="_blank"
        rel="noopener noreferrer">
        <picture>
          <source srcSet={appStoreWebp} type="image/webp" />
          <img
            src={appStore}
            className="ctsign__left__body__bottom-img"
            alt="appStore img"
          />
        </picture>
      </a>
    </div>
  )

  const renderLogo = () => {
    if (isMobile) {
      return <PreviewHeader />
    }

    return (
      <div className="ctsign__left__header">
        <a href={landingUrl} target="_blank" rel="noreferrer">
          <img
            src={Logo}
            onClick={landingUrl}
            alt="logo cooWe"
            className="ctsign__logo"
          />
        </a>
      </div>
    )
  }

  const landingUrl = useMemo(() => {
    let url = process.env[`REACT_APP_PROD_COOE_URL`]
    const currentEnvName = getCurrentEnvName()
    const index = url.indexOf("coowe")

    if (currentEnvName === "DEV") {
      url = url.slice(0, index) + "dev." + url.slice(index)
    }
    if (currentEnvName === "STG") {
      url = url.slice(0, index) + "stg." + url.slice(index)
    }

    return url.replace("www.", "")
  }, [])

  return (
    <div className="ctsign">
      <div
        className={classNames("ctsign__left", {
          ctsign__left__mobile: isMobile,
        })}>
        {renderLogo()}

        {isMobile && <GetAppBadge appLink={appLink} />}

        <div className="ctsign__left__body">{children}</div>
      </div>

      {isMobile ? (
        <>
          <WhiteCooweOverview />
          <FooterBlock />
        </>
      ) : (
        <MakesMeetingHappen />
      )}
    </div>
  )
}

export default CustomTemplateSign
