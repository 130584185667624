import React, { useState } from "react"

import cn from "classnames"

import { images } from "@utils/imageUtils"

import ContactListNd from "./ContactList/ContactListNd"

const AddInviteesViewNd = ({
  invitees = [],
  searchText,
  foundContacts = [],
  onOpenMenu,
  expandedContactItemId,
  onToggleExpand,
  onToggleContact,
  onChangeContactType,
  sectionOpenItemCount,
  onViewMore,
  onOpenPreviousTeeupParticipants,
  userTags,
  searchRef,
  sortingType,
  onContactsScrolled,
  isSearching,
  openEmail,
  openPhone,

  // My Contacts screen props
  renderMyContactItem,
  fromCreateTeeup,
  selectedTag,
  rememberSelectedTag,
  handleSearch,
  contacts,
  currentTag,
  fromContactsBook,
  openInviteToTeeupModal,
  handleOnInviteesDone,
  handleSearchByCustomTags,
  selectedTags,
  setSelectedTags,
  handleSearchByText,
  setSearchText,
  tagUser,
  openTagPopUp,
  activePopUpOpen,
}) => {
  const [isSortingModalOpen, setIsSortingModalOpen] = useState(false)

  const [activeSortFilter, setActiveSortFilter] = useState(0)
  const [isContactFilterOpened, setIsContactFilterOpened] = useState(false)

  const handleTagTouch = (selectedTag, sortByName) => () => {
    handleSearch(null, selectedTag, sortByName)
  }

  const sortByCommonTags = tag => {
    const { value, id, onPress } = tag
    const isTagSelected = selectedTag ? id === selectedTag.id : false
    const onPressEvent = onPress ? onPress : handleTagTouch

    const classes = isTagSelected ? "svg-wrapper tag-selected" : "svg-wrapper"
    return (
      <div
        key={`${value} + ${id}`}
        onClick={() => {
          onPressEvent(tag)()
          rememberSelectedTag(tag)

          if (tag.value === "Frequent") {
            setActiveSortFilter(1)
          } else {
            setActiveSortFilter(2)
          }
        }}>
        {["Frequent", "Recent"].includes(tag.value) && (
          <div className="sortNd-dropdown__item">{value}</div>
        )}
      </div>
    )
  }

  const renderTagsRow = (userTags, onTagRender) => {
    let tagsRow = []
    if (userTags) {
      tagsRow = userTags.map(onTagRender)
    }
    return tagsRow
  }

  const selectDefaultFilter = () => {
    handleTagTouch(null, true)()
    rememberSelectedTag({})
  }

  return (
    <>
      <div
        className="inviteNd-contacts"
        style={{
          width: fromContactsBook && "640px",
        }}>
        <div className="inviteNd-contacts__header">
          <div className="inviteNd-contacts__input--wrapper">
            <img
              src={images.ndSearch}
              className="inviteNd-contacts__input--search"
              alt="search"
            />
            <input
              type="text"
              className="inviteNd-contacts__input"
              ref={searchRef}
              placeholder="Search"
              onChange={e => {
                setSearchText(e.target.value)
              }}
              value={searchText}
            />
          </div>
          <div
            className={cn("inviteNd-contacts__tags", {
              "inviteNd-contacts__tags--active": isContactFilterOpened,
            })}
            onClick={() => {
              setIsContactFilterOpened(!isContactFilterOpened)
            }}>
            <img src={images.ndTags} alt="tags" />
            <div
              className={cn("inviteNd-contacts__count", {
                "inviteNd-contacts__count--active": isContactFilterOpened,
              })}>
              {selectedTags ? selectedTags.length : "0"}
            </div>
          </div>
          {/* <div className="inviteNd-contacts__earth">
          <img src={images.ndEarth} />
        </div> */}
          <div
            className="inviteNd-contacts__sort"
            onClick={() => {
              setIsSortingModalOpen(!isSortingModalOpen)
            }}>
            <div className="inviteNd-contacts__sort--wrapper">
              <img src={images.ndSort} alt="sort" style={{ marginRight: 6 }} />
              {activeSortFilter === 0 && "Name"}
              {activeSortFilter === 1 && "Frequent"}
              {activeSortFilter === 2 && "Recent"}
            </div>
            {isSortingModalOpen && (
              <div className="sortNd-dropdown">
                <div
                  className="sortNd-dropdown__item"
                  onClick={() => {
                    selectDefaultFilter()
                    setActiveSortFilter(0)
                  }}>
                  Name
                </div>
                {renderTagsRow(userTags, sortByCommonTags)}
              </div>
            )}
          </div>
        </div>
        <div
          className="inviteNd-contacts__container"
          style={{
            marginTop: isContactFilterOpened && 0,
            height: fromContactsBook && "100%",
          }}>
          <ContactListNd
            searchText={searchText}
            onContactsScrolled={onContactsScrolled}
            allContacts={foundContacts}
            invitees={invitees}
            isSearching={isSearching}
            sectionOpenItemCount={sectionOpenItemCount}
            expandedContactItemId={expandedContactItemId}
            onViewMore={onViewMore}
            onOpenMenu={onOpenMenu}
            openPreviousTeeupParticipants={onOpenPreviousTeeupParticipants}
            onToggleExpand={onToggleExpand}
            onToggleContact={onToggleContact}
            onChangeContactType={onChangeContactType}
            sortingType={sortingType}
            renderContactItem={renderMyContactItem}
            openPhone={openPhone}
            openEmail={openEmail}
            fromCreateTeeup={fromCreateTeeup}
            isContactFilterOpened={isContactFilterOpened}
            selectedTag={selectedTag}
            tags={userTags}
            handleSearch={handleSearch}
            contacts={contacts}
            rememberSelectedTag={rememberSelectedTag}
            currentTag={currentTag}
            closeContactsFilterModal={() => setIsContactFilterOpened(false)}
            fromContactsBook={fromContactsBook}
            openInviteToTeeupModal={openInviteToTeeupModal}
            handleOnInviteesDone={handleOnInviteesDone}
            handleSearchByCustomTags={handleSearchByCustomTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            handleSearchByText={handleSearchByText}
            tagUser={tagUser}
            openTagPopUp={openTagPopUp}
            activePopUpOpen={activePopUpOpen}
          />
        </div>
      </div>
    </>
  )
}

export default AddInviteesViewNd
