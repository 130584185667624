import { useState } from "react"

import _ from "lodash"
import PropTypes from "prop-types"
import { View, Text, Image } from "react-native"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { NewModal } from "@components"
import { ButtonEl } from "@components/common"
import SuggestionNudge from "@components/nudges/suggestionNudge"
import SugestionOrStatusNudgeResponse from "@components/teeupUserResponseDialogs/suggestionOrStatusNudgeResponse"
import { teeupUserStatus, teeupListNudgeTypes } from "@configs/enums"
import { userStatusTypes } from "@configs/mappings"
import { BUTTON_VARIANTS } from "@constants/button"
import I18n from "@i18n"
import { selectTeeupNudgesWithResponses } from "@selectors/activeTeeup"
import { selectTeeupPeople, selectTeeupSuggestions } from "@selectors/teeups"
import { selectUserId } from "@selectors/user"
import UserStatusAccesory from "@ui/avatar/userStatusAccesory"
import { getUsernameOrName } from "@utils/contactUtils"
import { capitalizeFirstLetter, getContactInitials } from "@utils/dataUtils"
import { getTimeFormat } from "@utils/dateUtils"
import { formatAskedUsers } from "@utils/teeupUtils"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import {
  NudgeAnswerPlainText,
  NudgeAnswerAvatarAndStatus,
} from "./NudgeAnswers"
import UserNudgesScreenComponent from "./UserNudgesScreenComponent"
import {
  styles,
  nudgeItemContentWrapper,
  nudgeTitle,
  nudgeType,
  nudgeTimestamp,
  nudgeAnswer,
} from "./UserNudgesScreenStyles"

const UserNudgesScreenContainer = props => {
  const [nudgeResponseDialogOpen, setNudgeResponseDialogOpen] = useState(false)
  const [nudgeInfo, setNudgeInfo] = useState({})
  const [subTypeId, setSubtypeId] = useState(-1)
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )

  const handleNudgeItemRender = ({ item }) => {
    const { userId, people, teeupSuggestions } = props

    const { targetId, mentionIds, text, message, nudgeAnswers, createdAt } =
      item
    let nudgesSuggestion = null
    if (targetId) {
      nudgesSuggestion = teeupSuggestions[Number(targetId)]
    }

    const nudgeSenderId = Number(item.userId || item.senderId)
    const isCreatedByCurrentUser = nudgeSenderId === userId
    const nudgeCreator = people[nudgeSenderId] || {}
    const teeupUserIdsToMentions = _.map(Object.keys(people), Number).filter(
      i => i !== nudgeCreator.id
    )
    const isAllUsersNudged = _.isEqual(
      _.sortBy(mentionIds),
      _.sortBy(teeupUserIdsToMentions)
    )
    const mentionedUsers = _.map(mentionIds, mentionId =>
      mentionId === userId ? "me" : getUsernameOrName(people[mentionId])
    ).filter(Boolean)
    const askedUsers = formatAskedUsers(mentionedUsers)
    // const askedUsersText =
    //   isAllUsersNudged && askedUsers.length !== 1 ? "everybody" : askedUsers

    const creator = isCreatedByCurrentUser
      ? "I"
      : getUsernameOrName(nudgeCreator)

    return (
      <View style={styles.nudgeItemWrapper}>
        {!isCreatedByCurrentUser && (
          <View style={styles.avatar}>
            {nudgeCreator.avatar ? (
              <Image
                style={styles.avatarImageStyle}
                source={nudgeCreator.avatar}
              />
            ) : (
              <Text>{getContactInitials(nudgeCreator)}</Text>
            )}
            <UserStatusAccesory
              userStatus={nudgeCreator.status}
              accesoryStyle={styles.userStatusIcon}
            />
          </View>
        )}
        <View style={nudgeItemContentWrapper(isCreatedByCurrentUser)}>
          <View style={styles.row}>
            <Text
              style={[
                styles.flex,
                styles.row,
                nudgeTitle(isCreatedByCurrentUser),
                [
                  {
                    color: "var(--black3)",
                    fontFamily: "Inter",
                    fontSize: 13,
                    fontWeight: 700,
                  },
                ],
              ]}>
              @{creator} Asked
            </Text>
          </View>
          <Text
            style={{
              ...nudgeType(isCreatedByCurrentUser),
              color: "var(--gray5)",
              fontFamily: "Inter",
              fontSize: 13,
              fontWeight: 500,
            }}>
            {capitalizeFirstLetter(text || message)}
          </Text>

          {!!nudgesSuggestion && (
            <View style={styles.suggestionNudge}>
              <SuggestionNudge
                onNudgesScreen={true}
                suggestion={nudgesSuggestion}
              />
            </View>
          )}
          {handleRenderNudgesAnswers(
            nudgeAnswers,
            isCreatedByCurrentUser,
            item
          )}
          <Text style={nudgeTimestamp(isCreatedByCurrentUser)}>
            Nudged {getTimeFormat(createdAt, "MMMM D, hh:mm A")}
          </Text>
        </View>
      </View>
    )
  }

  const handleRenderNudgesAnswers = (
    answers,
    isCreatedByCurrentUser,
    nudgeInfo
  ) => {
    const { userId, people } = props
    let children = null
    if (!isCreatedByCurrentUser) {
      const userAnswer = answers[userId]

      if (userAnswer) {
        let text
        text =
          nudgeInfo.subTypeId === teeupListNudgeTypes.suggestion
            ? userAnswer.text
            : teeupUserStatus[userStatusTypes[userAnswer.typeId]]
        children = (
          <NudgeAnswerPlainText
            respondTitle={I18n.iResponded}
            text={text}
            date={userAnswer.createdAt}
          />
        )
      } else {
        return (
          <div style={{ marginTop: 20 }}>
            <ButtonEl
              isBlack
              fullWidth
              variant={BUTTON_VARIANTS.ROUNDED}
              text={I18n.systemButtons.respond}
              onClick={openRespondDialog(nudgeInfo)}
              testID="respondButton"
            />
          </div>
        )
      }
    } else if (!_.isEmpty(answers)) {
      children = Object.values(answers).map((answer, index, self) => {
        const user = people[answer.userId]
        const username = getUsernameOrName(user)
        const userStatusKey = userStatusTypes[answer.typeId]
        return nudgeInfo.subTypeId === teeupListNudgeTypes.suggestion ? (
          <NudgeAnswerPlainText
            key={`${answer.timestamp}-${index}`}
            respondTitle={username}
            text={answer.text}
            date={answer.timestamp}
          />
        ) : (
          <NudgeAnswerAvatarAndStatus
            key={`${answer.timestamp}-${index}`}
            user={user}
            userStatusKey={userStatusKey}
            date={answer.timestamp}
            index={index}
            totalAmount={self}
          />
        )
      })
    }

    if (!children) return false

    return <View style={nudgeAnswer(isCreatedByCurrentUser)}>{children}</View>
  }

  const openRespondDialog = nudgeInfo => () => {
    if (nudgeInfo.subTypeId === teeupListNudgeTypes.wouldYouSuggestWhen) {
      setIsWhenModalOpen(true)
      return
    }
    if (nudgeInfo.subTypeId === teeupListNudgeTypes.wouldYouSuggestWhere) {
      setIsWhereModalOpen(true)

      return
    }
    setNudgeResponseDialogOpen(true)
    setSubtypeId(nudgeInfo.subTypeId)
    setNudgeInfo(nudgeInfo)
  }

  return (
    <>
      <UserNudgesScreenComponent
        nudgesWithResponses={props.nudgesWithResponses}
        onNudgeRender={handleNudgeItemRender}
      />
      {nudgeResponseDialogOpen && (
        <NewModal
          isOpen
          isNoPadding
          isHeader={false}
          onClose={() => setNudgeResponseDialogOpen(false)}>
          <SugestionOrStatusNudgeResponse
            nudgeInfo={nudgeInfo}
            subTypeId={subTypeId}
            close={() => setNudgeResponseDialogOpen(false)}
          />
        </NewModal>
      )}
    </>
  )
}

UserNudgesScreenContainer.propTypes = {
  nudgesWithResponses: PropTypes.arrayOf(PropTypes.object),
  userId: PropTypes.number.isRequired,
  people: PropTypes.object.isRequired,
  teeupSuggestions: PropTypes.object.isRequired,
}

const modalStyles = {
  content: {
    backgroundColor: "#F5F8FA",
    // backgroundColor: '#F5F5F5',
    width: 320,
    height: 391,
    border: "1px solid #F3F3F3",
    top: "50%",
    left: "50%",
    // left: 'auto',
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}

const mapStateToProps = state => {
  const teeupSuggestions = selectTeeupSuggestions(state)
  const userId = selectUserId(state)
  const people = selectTeeupPeople(state)
  const nudgesWithResponses = selectTeeupNudgesWithResponses(state)
  return { nudgesWithResponses, userId, people, teeupSuggestions }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNudgesScreenContainer)
