import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Message = styled.div`
  color: red;
  font-family: greycliffcf-medium-salt;
  font-size: 15px;
  margin: 5px 0;
  font-size: 15px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    font-size: 13px;
  }
`
