import styled from "styled-components"

import { NFontWeight, NSubtitle } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  height: 100%;
  border-top: 10px solid var(--lightGray);
  background-color: var(--white);
  padding-bottom: 50px;
`

export const List = styled.ul`
  padding: 20px;
  list-style: none;
`

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--lightGray5);
  padding: 20px 0;
`

export const LeftSide = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
`

export const Info = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-left: 10px;
`

export const Name = styled(NSubtitle)`
  font-weight: ${NFontWeight.bold};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
