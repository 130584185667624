import { useEffect, useMemo } from "react"

import { findEarliestAndLatestEvents } from "@utils/timeUtils"

import { getEndOfDate, getStartOfDate } from "../helpers"

export const useFullCalendarEvents = ({
  guestSuggestions,
  linkedGuestCalendar,
  fetchGuestCalendarsEvents,
}) => {
  const eventsRange = useMemo(() => {
    const { earliest, latest } = findEarliestAndLatestEvents(guestSuggestions)

    if (!earliest?.startDate || !latest?.endDate) return null

    return {
      startDate: getStartOfDate(earliest.startDate),
      endDate: getEndOfDate(latest.endDate),
    }
  }, [guestSuggestions])

  useEffect(() => {
    if (!linkedGuestCalendar) return

    fetchGuestCalendarsEvents({
      calendarCredentials: linkedGuestCalendar,
      ...eventsRange,
    })
  }, [linkedGuestCalendar])
}
