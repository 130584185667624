import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "../../../theme/"

export const CalendarEventsStyles = StyleSheet.create({
  eventBlockContainer: {
    position: "absolute",
  },
  extraPadding: {
    paddingRight: Units.responsiveValue(4),
  },
  smallExtraPadding: {
    paddingRight: Units.responsiveValue(2),
  },
  calendarEventContainer: {
    flex: 1,
    paddingLeft: Units.responsiveValue(4),
    paddingTop: Units.responsiveValue(4),
    overflow: "hidden",
  },
  eventContainerPadding: {
    paddingLeft: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(8),
  },
  calendarEventText: {
    ...AppFonts.smallDemibold,
    letterSpacing: -Units.responsiveValue(0.2),
  },
  calendarEventActionButtonContainer: {
    position: "absolute",
    bottom: Units.responsiveValue(8),
    right: Units.responsiveValue(8),
  },
  basicCalendarEventContainer: {
    backgroundColor: AppColors.brand.lightBlue6,
  },
  basicCalendarEventText: {
    color: AppColors.brand.blue3,
  },
  basicCalendarEventIcon: {
    tintColor: AppColors.brand.blue3,
  },
  conflictedCalendarEventContainer: {
    backgroundColor: AppColors.brand.lightRed,
  },
  conflictedCalendarEventText: {
    color: AppColors.brand.red3,
  },
  conflictedCalendarEventIcon: {
    tintColor: AppColors.brand.red3,
  },
  magicWandIcon: {
    width: 15,
    height: 15,
  },
})

export const TeeupsStyles = StyleSheet.create({
  eventBlockContainer: {
    position: "absolute",
  },
  extraPadding: {
    paddingRight: Units.responsiveValue(4),
  },
  smallExtraPadding: {
    paddingRight: Units.responsiveValue(2),
  },
  teeupEventIcon: {
    marginRight: Units.responsiveValue(4),
    width: Units.responsiveValue(10),
    height: Units.responsiveValue(10),
  },
  teeupEventIconPink: {
    tintColor: AppColors.brand.pink,
  },
  teeupEventContainer: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: Units.responsiveValue(4),
    paddingTop: Units.responsiveValue(4),
    paddingBottom: Units.responsiveValue(4),
    backgroundColor: AppColors.brand.white,
    overflow: "hidden",
  },
  eventContainerPadding: {
    paddingLeft: Units.responsiveValue(6),
    paddingRight: Units.responsiveValue(6),
  },
  teeupEventText: {
    ...AppFonts.smallBold,
    letterSpacing: -Units.responsiveValue(0.1),
    color: AppColors.brand.black,
  },
  teeupEventTextTemporary: {
    color: AppColors.brand.darkGrey2,
  },
  conflictedTeeupEventContainer: {
    borderWidth: 1,
    borderColor: AppColors.brand.red,
  },
  calendarIconContainer: {
    position: "absolute",
    bottom: Units.responsiveValue(6),
    right: Units.responsiveValue(6),
  },
})
