import { LOGIN_ERRORS } from "./constants"

export const getLoginErrorsMessages = (errorCode, auth0Type) => {
  switch (errorCode) {
    case LOGIN_ERRORS.ACCOUNT_DOES_NOT_EXIST:
      return "There is no CooWe account for this email address."
    case LOGIN_ERRORS.INCORRECT_PASSWORD:
      return "The password you have entered is incorrect."
    case LOGIN_ERRORS.CANNOT_LOGIN_PASSWORD:
      return `
        <p>
          This email address is linked to a CooWe account using ${auth0Type} log in.
        </p>
        <p>
          You must log in using ${auth0Type} to access this CooWe account.
        </p>
      `
    default:
      return ""
  }
}
