import React, { useContext } from "react"

import { SubtitleL } from "@assets/styles/typographyStyles"
import { ButtonEl } from "@components/common"
import { BUTTON_VARIANTS } from "@constants/button"
import {
  SYNC_CONTACTS_MODAL,
  SyncContactsContext,
} from "@contexts/SyncContactsContext"

import { useButtons } from "../hooks"

import {
  BodyIcon,
  BodyTextWrapper,
  BodyButtonWrapper,
} from "./modalsBodyStyles"

export const ModalsBody = () => {
  const {
    modalType,
    contactsModalData,
    isGoogleLoading,
    isAppleLoading,
    handleSyncGoogleContacts,
    handleSyncAppleContacts,
  } = useContext(SyncContactsContext)
  const isSyncButtons = modalType === SYNC_CONTACTS_MODAL.ADD_ANOTHER

  const buttonsParams = useButtons({
    isGoogleLoading,
    isAppleLoading,
    handleSyncGoogleContacts,
    handleSyncAppleContacts,
  })

  return (
    <div>
      <BodyTextWrapper isSyncButtons={isSyncButtons}>
        {!!contactsModalData?.icon && (
          <BodyIcon src={contactsModalData?.icon} alt="type" />
        )}

        <SubtitleL
          style={{ color: "var(--gray2)" }}
          dangerouslySetInnerHTML={{ __html: contactsModalData.description }}
        />
      </BodyTextWrapper>

      {isSyncButtons &&
        buttonsParams.map((button, buttonIndex) => (
          <BodyButtonWrapper key={buttonIndex}>
            <ButtonEl
              fullWidth
              isIconFirst
              backgroundColor="var(--lightGray4)"
              border="1px solid var(--gray3)"
              textColor="var(--black3)"
              loadingColor="var(--lightGray3)"
              variant={BUTTON_VARIANTS.ROUNDED}
              text={button.text}
              iconName={button.iconName}
              isLoading={button.isLoading}
              disabled={button.isDisabled}
              onClick={button.action}
            />
          </BodyButtonWrapper>
        ))}
    </div>
  )
}
