import React, { useState, useRef, useEffect, memo } from "react"

import ReactDOM from "react-dom"
import ReactTooltip from "react-tooltip"

import { reactionTypes } from "@configs/mappings"
import { isTeeupDisabled } from "@utils/teeupUtils"

import "./reactionButtonView.scss"

import threeDotsIcon from "../../assets/images/three-dots.svg"

import ReactionsTooltip from "./ReactionsTooltip/"

import { SvgIcon } from "@components/common"
import { SubtitleS } from "@assets/styles/typographyStyles"

const getIcon = iconID => {
  switch (iconID) {
    case reactionTypes.upvote:
      return "like"
    case reactionTypes.downvote:
      return "dislike"
    default:
      return null
  }
}

export const ReactionButtonView = ({
  loadingReaction,
  disableReaction,
  teeup,
  previewMode = false,
  isMyMessage = false,
  inactive = false,
  isInNudge,
  sections = [],
  suggestionId,
  id,
  userReaction,
  isPressedButton,
  reactedCustomId,
  onReaction,
  number,
}) => {
  const intId = Number(id)
  let buttonClasses = `${
    isMyMessage ? "my-reaction-button" : "reaction-button"
  }`

  if (!reactedCustomId && intId === userReaction) {
    buttonClasses += " active"
  }

  if (inactive) {
    buttonClasses += " inactive"
  }

  const [reactions, setReactions] = useState([])
  const disabled = disableReaction || isTeeupDisabled(teeup)

  const [reactionsTooltip, setReactionsTooltip] = useState(false)
  const [threeDotsReaction, setThreeDotsReaction] = useState(false)

  const reactionsRef = useRef()

  useEffect(() => {
    setReactions(sections)
  }, [sections])

  return (
    <>
      {reactionsTooltip &&
        threeDotsReaction &&
        ReactDOM.createPortal(
          <>
            <ReactionsTooltip sections={reactions} reactionId={intId} />
          </>,
          document.querySelector("#modal-root")
        )}

      <div className="button-wrapper">
        {loadingReaction && isPressedButton === intId ? (
          <div className="loader-wrapper">
            <span className="loader"></span>
          </div>
        ) : (
          <button
            className={buttonClasses}
            disabled={disabled || previewMode || inactive || loadingReaction}
            onClick={e => {
              e.stopPropagation()
              onReaction(id)
            }}
            onMouseOver={e => {
              setThreeDotsReaction(true)
            }}
            onMouseLeave={e => {
              e.stopPropagation()
              ReactTooltip.hide(reactionsRef.current)
              setThreeDotsReaction(false)
            }}>
            {threeDotsReaction && suggestionId && (
              <img
                src={threeDotsIcon}
                ref={reactionsRef}
                data-tip={id}
                data-for="reactions-tooltip"
                className="reactions-view"
                onMouseOver={e => {
                  e.stopPropagation()
                  ReactTooltip.show(reactionsRef.current)
                  setReactionsTooltip(true)
                }}
                alt="reaction"
              />
            )}
            <>
              <SvgIcon name={getIcon(id)} color="var(--gray6)" />

              <SubtitleS className="reaction-number">
                {previewMode && id === reactionTypes.upvote
                  ? 1
                  : number[id] || 0}
              </SubtitleS>
            </>
          </button>
        )}
      </div>
    </>
  )
}

export const ReactionButtonViewMemoized = memo(ReactionButtonView)
