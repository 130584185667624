import React from "react"

import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"

import { ButtonEl } from "@components/common"
import { BUTTON_SIZES, BUTTON_VARIANTS } from "@constants/button"
import { GAMEPLAN_TYPES, GAMEPLAN_TYPES_LABELS } from "@constants/gameplan"
import { useScreenWidth } from "@hooks"
import {
  selectActiveTeeupSettings,
  selectMessagesWithoutNugdes,
} from "@selectors/activeTeeup"
import { useSuggestionInPersonStore } from "stores/suggestions/suggestionInPersonStore"

import { SuggestionMark } from "../../SuggestionItem/atoms"

import {
  Wrapper,
  Left,
  Label,
  Description,
  SuggestionsAmountLabel,
} from "./noDecidedStyles"

export const GameplanHeader = ({
  isDecidedSuggestion,
  type,
  suggestionsAmount,
  messages,
}) => {
  const settings = useSelector(selectActiveTeeupSettings)
  const typeLabel = GAMEPLAN_TYPES_LABELS[type]
  const setIsWhenModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhenModalOpen
  )
  const setIsWhereModalOpen = useSuggestionInPersonStore(
    state => state.setIsWhereModalOpen
  )

  // const { openSuggestWhenModal, openSuggestWhereModal } = useTeeUpPage()
  const { isSmallSize } = useScreenWidth()

  const suggestHandle = () => {
    type === GAMEPLAN_TYPES.WHEN
      ? setIsWhenModalOpen(true)
      : setIsWhereModalOpen(true)
  }

  const isDesktopDetails = !isSmallSize || (isSmallSize && !suggestionsAmount)

  return (
    <Wrapper>
      <Left>
        <Label>{typeLabel}</Label>

        {!suggestionsAmount && !isDecidedSuggestion && isDesktopDetails && (
          <Description>Suggest {typeLabel}</Description>
        )}
      </Left>

      {isDecidedSuggestion && <SuggestionMark isDecided />}

      {suggestionsAmount > 0 && !isDecidedSuggestion && (
        <SuggestionsAmountLabel>
          {suggestionsAmount > 1
            ? `${suggestionsAmount} Suggestions`
            : `${suggestionsAmount} Suggestion`}
        </SuggestionsAmountLabel>
      )}

      {!suggestionsAmount && !isDecidedSuggestion && isDesktopDetails && (
        <ButtonEl
          autoWidth
          text="Suggest"
          disabled={
            messages.length === 0 || !settings?.allowChangeGameplanOptions
          }
          variant={BUTTON_VARIANTS.TRANSPARENT}
          size={BUTTON_SIZES.SMALL}
          onClick={suggestHandle}
        />
      )}
    </Wrapper>
  )
}

GameplanHeader.propTypes = {
  isDecidedSuggestion: PropTypes.bool,
  type: PropTypes.string,
  suggestionsAmount: PropTypes.number,
}

const mapStateToProps = state => ({
  messages: selectMessagesWithoutNugdes(state),
})

export default connect(mapStateToProps)(GameplanHeader)
