import styled, { css } from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--transparentBlack);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`

export const Wrapper = styled.div`
  ${({ $isMain }) => css`
    width: 615px;
    height: 95vh;
    background-color: ${$isMain ? "var(--radicalRed)" : "var(--white)"};
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    padding: 15px 25px 0 25px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    // overflow: hidden;

    @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
      width: 97%;
      height: 97%;
    }

    @media (max-width: ${MEDIA_SIZES.MOBILE}) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 0;
      top: 0;
    }
    @media (min-height: 900px) {
      height: 855px;
    }
  `};
`
