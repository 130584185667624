import { useEffect } from "react"

import moment from "moment-timezone"

import { EVENT_TYPES } from "@utils/calendarUtils"

export const useCheckExistingEvents = ({
  eventsArr,
  newGuestSuggestion,
  setNewGuestSuggestion,
}) => {
  useEffect(() => {
    if (!newGuestSuggestion || eventsArr.length === 0) return

    const teeupSuggestionsEvents = eventsArr.filter(
      ({ eventType, isCreated, key }) =>
        eventType === EVENT_TYPES.teeup || (isCreated && !!key)
    )
    teeupSuggestionsEvents.forEach(eve => {
      const { start, end } = eve
      const { start: newStart, end: newEnd, isConflict } = newGuestSuggestion
      const isSameStart = moment(start).isSame(newStart)
      const isSameEnd = moment(end).isSame(newEnd)
      const isSameTime = isSameStart && isSameEnd

      if (isSameTime && !isConflict) {
        setNewGuestSuggestion({
          isConflict: isSameTime,
        })
      }
    })
  }, [newGuestSuggestion, eventsArr])
}
