import styled, { css } from "styled-components"

import { NFontWeight } from "@assets/styles/newTypographyStyles"
import { SubtitleS } from "@assets/styles/typographyStyles"

export const Wrapper = styled.div`
  background-color: var(--lightMint);
  border: 1px solid var(--lightMint2);
  border-radius: 4px;
  color: var(--green3);
  padding: 2px 7px;
  ${({ $isDecided }) =>
    !$isDecided &&
    css`
      background-color: var(--lightGray5);
      color: var(--gray4);
      position: absolute;
      top: 10px;
      right: 10px;
    `}
`

export const Text = styled(SubtitleS)`
  font-weight: ${NFontWeight.semiBold};
`
