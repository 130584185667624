import { EVENT_TYPES } from "@utils/calendarUtils"
import { roundTimeToFifteenMinutes } from "@utils/timeUtils"
import dayjs from "utils/dayjs"

export const getNotAvailableZone = start => {
  const minutes = roundTimeToFifteenMinutes(dayjs()) - 15

  const isDateBefore = dayjs(start).isBefore(dayjs().startOf("day"), "day")

  const startDate = isDateBefore ? dayjs(start) : dayjs()
  const endDate = isDateBefore
    ? startDate.endOf("day").toDate()
    : dayjs().minute(minutes).second(0).toDate()

  return {
    eventType: EVENT_TYPES.not_avaliable,
    title: "",
    start: startDate.startOf("date").toDate(),
    end: endDate,
  }
}
