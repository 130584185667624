import React, { memo } from "react"

import cn from "classnames"

const SelectTimeOptionButton = ({
  name,
  option,
  timeRange,
  selected,
  handleTimeSelect,
  currentHour,
}) => {
  const isDisable = currentHour > option.end && option.end !== 0
  return (
    <div
      className={cn("select-time__option-button", {
        selected: selected,
        "select-time__option-button--disabled": isDisable,
      })}
      onClick={isDisable ? null : () => handleTimeSelect(option)}>
      <span>{name}</span>
      <span>{timeRange}</span>
    </div>
  )
}

export default memo(SelectTimeOptionButton)
