import styled from "styled-components"

import { NSubtitleS } from "@assets/styles/newTypographyStyles"
import { SvgIcon } from "@components/common"

export const InviteButton = styled.button`
  width: 100%;
  background-color: var(--lightGray4);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &:active {
    opacity: 0.4;
  }
`

export const Text = styled(NSubtitleS)`
  color: var(--black2);
`

export const PlusIcon = styled(SvgIcon)`
  width: 40px;
  height: 40px;
`
