import { useEffect, useState, useCallback, useMemo } from "react"

import debounce from "lodash/debounce"

import { getCoords } from "@utils/suggestionUtils"
import { Prediction } from "types/suggestions"

const useAddressPredictions = (input, detailsRef) => {
  const [predictions, setPredictions] = useState<Prediction[]>([])
  const [placePhotos, setPlacePhotos] = useState([])
  // @ts-ignore
  const location = useMemo(() => new window.google.maps.LatLng(), [])
  const autocomplete = useMemo(
    () => new window.google.maps.places.AutocompleteService(),
    []
  )
  const geocoder = useMemo(() => new google.maps.Geocoder(), [])

  const getPlacePredictions = async input => {
    try {
      const { predictions } = await autocomplete.getPlacePredictions({
        input,
        location,
        radius: 20_000,
      })
      if (!predictions) return
      for (const prediction of predictions) {
        const { lat, lng } =
          (await getCoords(geocoder, prediction.place_id)) || {}
        if (!lat || !lng) return
        prediction.lat = lat
        prediction.lng = lng
      }

      setPredictions(
        predictions.map(prediction => ({
          iconName: "location-marker",
          label: prediction.description,
          ...prediction,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  )

  useEffect(() => {
    if (!input || input === "Current Location") return
    debouncedGetPlacePredictions(input)
  }, [input])

  // useEffect(() => {
  //   if (!predictions.length) return

  //   const service = new window.google.maps.places.PlacesService(
  //     detailsRef.current
  //   )

  //   predictions.map(prediction => {
  //     const placeId = prediction.place_id

  //     service.getDetails(
  //       {
  //         placeId,
  //         fields: ["photos"],
  //       },
  //       place => {
  //         setPlacePhotos(state => [
  //           ...state,
  //           {
  //             photo: place && place.photos && place.photos[0].getUrl(),
  //             placeId,
  //           },
  //         ])
  //       }
  //     )
  //   })
  // }, [predictions])

  return {
    predictions,
    placePhotos,
  }
}

export default useAddressPredictions
