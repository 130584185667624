import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import { NCaptionS } from "@assets/styles/newTypographyStyles"

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 100px;
  max-height: 250px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: var(--white);
  padding: 16px;
  margin-top: 8px;
  border-top: 1px solid var(--gray3);
  border-bottom: 1px solid var(--gray3);

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
    padding-bottom: 250px;
  }
`

export const Text = styled(NCaptionS)`
  color: var(--gray4);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`

export const RegistrationButtonsWrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  margin: 0 auto;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
  }
`

export const SubmitWrapper = styled.div`
  padding: 0 5%;
  margin-top: 70px;
`
