import { useMemo } from "react"

import getWhenSuggestionDetails from "@utils/gameplan/getWhenSuggestionDetails"
import { apps } from "constants/apps"
import { GAMEPLAN_TYPES } from "constants/gameplan"

export const useSuggestionData = suggestion => {
  return useMemo(() => {
    // if (suggestion?.type === GAMEPLAN_TYPES.when) {
    //   return getWhenSuggestionInfo(suggestion, true)
    // }
    if (suggestion?.type === GAMEPLAN_TYPES.when) {
      const { title, subtitle } = getWhenSuggestionDetails(suggestion, true)
      return {
        img: apps.find(app => app.title === "when")?.image,
        value: title,
        details: subtitle,
      }
    }

    return {
      img: apps.find(app => app.title === suggestion.value)?.image,
      value: suggestion.value,
      details: suggestion.details,
    }
  }, [suggestion])
}
