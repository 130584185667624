import {
  memo,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useContext,
} from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { useIsAuthenticated } from "@azure/msal-react"
import { gapi } from "gapi-script"
import moment from "moment-timezone"
import ApiCalendar from "react-google-calendar-api"
import { Helmet } from "react-helmet"
import { useOneSignalSetup } from "react-onesignal"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"

import {
  manageDisplayedCalendars,
  setIsCalendarSynchronized,
} from "actions/calendarActions"
import { SideBarContext } from "@utils/sideBarContext"
import { getCurrentWindowParams, setTeeupFilter } from "actions/commonActions"
import {
  verifyUserAndSave,
  setAuth0Error,
  setAuth0Type,
  setIsRegisterSsoNames,
  setNewRegisteredUser,
  setNewRegisteredUserError,
} from "actions/loginActions"
import { loadNotifications } from "actions/notificationActions"
import { fetchTeeups } from "actions/teeupActions"
import {
  setUserIsLoading,
  setInviteSSORegisterToken,
} from "actions/userActions"
import api from "api"
import { ErrorOauthModal, NewSidebar } from "components"
import { STORAGE_KEYS } from "constants/storageKeys"
import { Auth0ErrorState } from "contexts/Auth0ErrorContext"
import { setDefaultTimeZone } from "contexts/TimeWheelContext/helpers"
import { useTopLevelContext } from "contexts/TopLevelContext"
import { setupTeeupListSocket } from "middlewares/backendMiddleware"
import LoadingPage from "pages/LoadingPage"
import {
  selectCalendarSyncInProgress,
  selectDisplayCalendar,
  selectDisplayCalendarHash,
  selectIsCalendarSynchronized,
} from "selectors/calendar"
import { selectSlackIntegrationInfo } from "selectors/slackIntegration"
import {
  selectActiveTeeups,
  selectAllTeeups,
  selectUnseenTeeups,
} from "selectors/teeups"
import { fetchCalendarEvents } from "utils/calendarSyncUtils"
import { catchIsUserValid } from "utils/catchIsUserValid"
import { throttle } from "utils/commonUtils"

import {
  useCleanNewRegisteredUser,
  useGetIdToken,
  useHandleAuth0Login,
  useHandleAuth0Registration,
  useHandleAuthError,
  useHandleGetTokenId,
  useRegisteredGuest,
  useResetLoginLoader,
  useScreenWidth,
  useTeeupRedirect,
} from "./hooks"
import "./index.scss"
import notificationService from "./notificationsService"
import { AUTH0_ACTION_TYPES } from "./pages/Auth0ContentPage/constants"
import SlackConnection from "./pages/SlackConnection"
import useRoutes from "./routes"
import {
  selectIsTeeupOverviewSettings,
  selectShowSideBar,
  selectTitle,
} from "./selectors/common"

import GlobalSnackbar from "components/GlobalSnackbar"
import {
  selectCalendarSyncSettings,
  selectToken,
  selectUserId,
  selectUserInfo,
} from "selectors/user"
import { reloadPage } from "utils/reloadPage"
import ErrorBoundary from "components/ErrorBoundary"

import CreateTeeUp from "./components/CreateTeeUp"
import { history } from "./configureStore"
import { getCreateTeeupState } from "./selectors/createTeeUp"
import "./types/global.d.ts"

function App({
  token,
  userId,
  isOpen,
  verifyUserAndSave,
  showSiderBar,
  setUserIsLoading,
  title,
  activeTeeups,
  userInfo,
  setTeeupFilter,
  notifications,
  getCurrentWindowParams,
  auth0Error,
  isRegisterSsoNames,
  newRegisteredUser,
  newRegisteredUserError,
  allTeeups,
  inviteSSORegisterToken,
  setAuth0Type,
  setAuth0Error,
  setIsRegisterSsoNames,
  setNewRegisteredUserError,
  calSyncInProgress,
  setInviteSSORegisterToken,
  getTeeups,
  isCalendarSynchronized,
  setIsCalendarSyncInProgress,
  calendarSyncSettings,
  calendarDisplay,
  calendarDisplayHash,
  manageDisplayedCalendars,
}) {
  const touchStartX = useRef(0)
  const touchEndX = useRef(0)
  const isTeeupOverviewSettings = useSelector(selectIsTeeupOverviewSettings)
  const { isSmallSize } = useScreenWidth()
  const isSidebarHidden = isSmallSize && isTeeupOverviewSettings
  const {
    isLoading,
    isAuthenticated,
    user,
    getIdTokenClaims,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()
  const { handleToggleSidebar } = useContext(SideBarContext)
  const [isIdentityVerified, setIsIdentityVerified] = useState(false)
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  // const [isOpenErrorOauthModal, setisOpenErrorOauthModal] = useState(false)

  const [signedForGoogleCalendar, setSignedForGoogleCalendar] = useState(false)

  const {
    isRegistrationInProgress,
    isLoginInProgress,
    socialRegistrationType,
    isRegisterCheckIdentity,
    setIsRegisterCheckIdentity,
    setIsRegistrationInProgress,
    setIsLoginInProgress,
  } = useTopLevelContext()

  const routes = useRoutes()
  const isAuthenticatedUser = useSelector(selectToken)
  const slackIntegrationInfo = useSelector(selectSlackIntegrationInfo)
  const isMSAuth = useIsAuthenticated()

  if (isOpen) {
    document.body.style.overflow = "hidden"
  }

  const loading = useMemo(() => {
    if (!isLoading && !isRegistrationInProgress && !isLoginInProgress) {
      return false
    }

    return true
  }, [isLoading, isRegistrationInProgress, isLoginInProgress])

  // Authorization via social networks

  // Reset login loader if user closed auth0 modal
  useResetLoginLoader({
    isAuthenticated,
    isLoading,
    isLoginInProgress,
    isRegistrationInProgress,
    setIsLoginInProgress,
    setIsRegistrationInProgress,
  })

  const { handleAuthError } = useHandleAuthError({
    user,
    setAuth0Error,
    setNewRegisteredUser,
    setIsIdentityVerified,
    setIsLoginInProgress,
    setIsRegistrationInProgress,
    setNewRegisteredUserError,
    logout,
  })

  const handleAuth0Login = useHandleAuth0Login({
    isLoading,
    user,
    isLoginInProgress,
    isAuthenticated,
    setIsLoginInProgress,
    setIsIdentityVerified,
    handleAuthError,
  })

  useEffect(() => {
    handleAuth0Login()
  }, [handleAuth0Login])

  const handleAuth0Registration = useHandleAuth0Registration({
    user,
    isLoginInProgress,
    isRegistrationInProgress,
    socialRegistrationType,
    isRegisterCheckIdentity,
    setUserIsLoading,
    setIsRegisterSsoNames,
    setIsRegisterCheckIdentity,
    setIsRegistrationInProgress,
    loginWithRedirect,
    handleAuthError,
    setIsIdentityVerified,
  })

  useEffect(() => {
    if (newRegisteredUserError) {
      handleAuthError(newRegisteredUserError, AUTH0_ACTION_TYPES.SIGN_UP)
    }
  }, [newRegisteredUserError])

  useCleanNewRegisteredUser({
    user,
    isLoginInProgress,
    isRegistrationInProgress,
    logout,
  })

  useEffect(() => {
    if (isRegisterSsoNames) {
      history.push("/sign-up-with")
    }
  }, [isRegisterSsoNames])

  useEffect(() => {
    handleAuth0Registration()
  }, [handleAuth0Registration])

  const isUserValid = useMemo(() => {
    return catchIsUserValid(user)
  }, [user])

  const isRegisteredUserValid = useMemo(() => {
    return (
      !!newRegisteredUser &&
      !!newRegisteredUser["https://coo-e.com/userId"] &&
      !!newRegisteredUser["https://coo-e.com/userType"]
    )
  }, [newRegisteredUser])

  useRegisteredGuest()

  useEffect(() => {
    if (newRegisteredUser) {
      setIsIdentityVerified(true)
    }
  }, [newRegisteredUser])

  const { getIdToken } = useGetIdToken({
    setIsLocalLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    verifyUserAndSave,
    user,
    inviteSSORegisterToken,
    newRegisteredUser,
    setAuth0Type,
    setAuth0Error,
    setInviteSSORegisterToken,
  })

  // authorize and get token if auth0 authorization was successful
  const handleGetTokenId = useHandleGetTokenId({
    isAuthenticated,
    isUserValid,
    isRegisteredUserValid,
    isIdentityVerified,
    setUserIsLoading,
    getIdToken,
    setIsIdentityVerified,
    user,
    newRegisteredUser,
  })

  useEffect(() => {
    handleGetTokenId()
  }, [handleGetTokenId])

  useTeeupRedirect(userInfo, history)

  // initialization
  useEffect(() => {
    api.init()
    notificationService.init()
  }, [user])

  // useEffect(() => {
  //   if (isAuthenticatedUser) loadNotifications()
  // }, [isAuthenticatedUser, user])

  useOneSignalSetup(() => {
    if (isAuthenticatedUser) {
      notificationService.setEmail(userInfo.email || userInfo?.user.email)
      notificationService.setTags({
        email: userInfo.email || userInfo?.user.email,
        phone: userInfo.phone || undefined,
        ...userInfo,
      })
      notificationService.askForNotificationsPermsions()
    }
  })

  useEffect(() => {
    if (token && userId) {
      setupTeeupListSocket(token, userId)
    }
  }, [token, userId])

  useEffect(() => {
    sessionStorage.removeItem(STORAGE_KEYS.IS_CLEAN_PAUSE)
    let timePicker
    window.addEventListener("blur", function () {
      timePicker = setTimeout(() => {
        reloadPage() // reload page when user not active for 30min
      }, 1_800_000)
    })
    window.addEventListener("focus", function () {
      clearInterval(timePicker)
    })
  }, [])

  const fetchCalendar = useCallback(async () => {
    const calendars = userInfo?.calendars || []

    const googleCalendar = calendars.find(c => c.provider === "google-oauth2")
    const outlookCalendar = calendars.find(c => c.provider === "windowslive")

    if (!googleCalendar && !outlookCalendar) return

    if (googleCalendar) {
      const { accessToken } = googleCalendar

      setSignedForGoogleCalendar(true)
      ApiCalendar.updateSigninStatus(true)
      gapi.client.setToken({ access_token: accessToken })

      await fetchCalendarEvents({})
    }

    if (outlookCalendar) {
      const { accessToken, username } = outlookCalendar

      await fetchCalendarEvents({
        newOutlookCalendar: { accessToken, username },
      })
    }
  }, [isCalendarSynchronized, getTeeups])

  useEffect(() => {
    const { selectedCalendars } = calendarSyncSettings
    if (selectedCalendars && Object.keys(calendarDisplay).length > 0) {
      manageDisplayedCalendars(selectedCalendars)
    }
  }, [
    calendarSyncSettings,
    Object.keys(calendarDisplay).length,
    calendarDisplayHash,
  ])

  useEffect(() => {
    if (!isAuthenticatedUser) return

    fetchCalendar()
  }, [fetchCalendar, isAuthenticatedUser])

  useEffect(() => {
    if (!isAuthenticatedUser) return

    // TODO: remove it later (Calendar removing)
    // syncCalendar()
  }, [activeTeeups, isAuthenticatedUser])

  useLayoutEffect(() => {
    const throttleWindowParams = throttle(
      () =>
        getCurrentWindowParams({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      500
    )
    window.addEventListener("resize", throttleWindowParams)
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.timezone) {
      setDefaultTimeZone(moment, userInfo.timezone)
    }
  }, [userInfo])

  if (isAuthenticatedUser) {
    if (calendarSyncSettings.hasOwnProperty("customTimezone")) {
      if (calendarSyncSettings?.customTimezone) {
        setDefaultTimeZone(moment, calendarSyncSettings?.customTimezone)
      }
    } else {
      return <LoadingPage />
    }
  }

  if (
    (loading || isLocalLoading) &&
    !auth0Error &&
    !(isLoginInProgress || isRegistrationInProgress)
  ) {
    return <LoadingPage />
  }

  const handleSwipeStart = e => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleSwipeEnd = e => {
    touchEndX.current = e.changedTouches[0].clientX

    if (touchStartX.current < touchEndX.current - 50) {
      handleToggleSidebar(true)
    } else if (touchStartX.current > touchEndX.current + 50) {
      handleToggleSidebar(false)
    }
  }

  // if (!!auth0Error) {
  //   !isOpenErrorOauthModal && setisOpenErrorOauthModal(true)
  // }

  return (
    <ErrorBoundary fallback={<p>something went wrong</p>}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        className="app"
        onTouchStart={handleSwipeStart}
        onTouchEnd={handleSwipeEnd}>
        {!isSidebarHidden && isAuthenticatedUser && showSiderBar && (
          <NewSidebar />
        )}
        {/* <LocationDetector close={() => setIsLocationDetectorOpen(false)} /> */}
        {routes}
        {!!auth0Error && (
          <Auth0ErrorState>
            <ErrorOauthModal />
          </Auth0ErrorState>
        )}
        {isOpen && <CreateTeeUp />}

        <GlobalSnackbar />
        {isAuthenticatedUser && slackIntegrationInfo && <SlackConnection />}
      </div>
    </ErrorBoundary>
  )
}

const mapStateToProps = state => ({
  token: selectToken(state),
  userInfo: selectUserInfo(state),
  userId: selectUserId(state),
  isOpen: getCreateTeeupState(state),
  activeTeeups: selectActiveTeeups(state),
  showSiderBar: selectShowSideBar(state),
  title: selectTitle(state),
  notifications: selectUnseenTeeups(state),
  auth0Error: state.user.auth0Error,
  isRegisterSsoNames: state.user.isRegisterSsoNames,
  newRegisteredUser: state.user.newRegisteredUser,
  newRegisteredUserError: state.user.newRegisteredUserError,
  allTeeups: selectAllTeeups(state),
  calSyncInProgress: selectCalendarSyncInProgress(state),
  inviteSSORegisterToken: state.user.inviteSSORegisterToken,
  isCalendarSynchronized: selectIsCalendarSynchronized(state),
  calendarSyncSettings: selectCalendarSyncSettings(state),
  calendarDisplay: selectDisplayCalendar(state),
  calendarDisplayHash: selectDisplayCalendarHash(state),
})

const mapDispatchToProps = dispatch => ({
  verifyUserAndSave: bindActionCreators(verifyUserAndSave, dispatch),
  setUserIsLoading: bindActionCreators(setUserIsLoading, dispatch),
  setTeeupFilter: bindActionCreators(setTeeupFilter, dispatch),
  getCurrentWindowParams: bindActionCreators(getCurrentWindowParams, dispatch),
  setAuth0Type: bindActionCreators(setAuth0Type, dispatch),
  setAuth0Error: bindActionCreators(setAuth0Error, dispatch),
  setIsRegisterSsoNames: bindActionCreators(setIsRegisterSsoNames, dispatch),
  setNewRegisteredUser: bindActionCreators(setNewRegisteredUser, dispatch),
  setNewRegisteredUserError: bindActionCreators(
    setNewRegisteredUserError,
    dispatch
  ),
  setInviteSSORegisterToken: bindActionCreators(
    setInviteSSORegisterToken,
    dispatch
  ),
  getTeeups: bindActionCreators(fetchTeeups, dispatch),
  setIsCalendarSynchronized: bindActionCreators(
    setIsCalendarSynchronized,
    dispatch
  ),
  manageDisplayedCalendars: bindActionCreators(
    manageDisplayedCalendars,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(App))
