import styled, { css } from "styled-components"

export const ContactsWrapper = styled.div`
  width: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  margin-top: 20px;
`

export const ContactsConnectorItem = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--lightGray2);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px;
  position: relative;
  &:first-child {
    padding-top: 0;
  }
  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 20px;
    `};
`

export const ConnectorProvider = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

export const ConnectorIcon = styled.img`
  margin-right: 7px;
`

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`

export const RemoveBtn = styled.button`
  background-color: transparent;
  border: none;
  color: var(--darkGray);
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  ${({ isFirst }) =>
    isFirst &&
    css`
      top: 40%;
      transform: translateY(-60%);
    `};
`
