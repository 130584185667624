import { CalendarStyles } from "./calendarCardStyles"

import React, { useEffect, useRef } from "react"
import "./index.scss"

import { SectionList } from "react-native"
import { connect } from "react-redux"

import useIsInViewport from "@hooks/useIsInViewport"
import {
  selectCalendarListSections,
  selectUpdatedValue,
} from "@selectors/calendar"

const CalendarSectionList = ({
  renderSectionHeader,
  renderItem,
  onViewableItemsChanged,
  setHeaderDays,
  sectionsList,
  updatedValue,
}) => {
  const ref = useRef(null)
  const isInViewport = useIsInViewport(ref)

  useEffect(() => {
    if (isInViewport) setHeaderDays()
  }, [isInViewport, updatedValue])

  return (
    <div className="sectionListDivHelperComingUp">
      <SectionList
        sections={sectionsList}
        renderSectionHeader={renderSectionHeader}
        renderItem={renderItem}
        keyExtractor={item => `${item?.id}`}
        stickySectionHeadersEnabled={false}
        contentContainerStyle={CalendarStyles.comingUpList}
        onViewableItemsChanged={onViewableItemsChanged}
      />
      <div ref={ref} className="calendarCard--lazyDiv" />
    </div>
  )
}

const mapStateToProps = state => ({
  sectionsList: selectCalendarListSections(state),
  updatedValue: selectUpdatedValue(state),
})

export default connect(mapStateToProps, null)(CalendarSectionList)
