import { useCallback } from "react"

import { checkLoginIdentity } from "../../actions/loginActions"
import { AUTH0_ACTION_TYPES } from "../../pages/Auth0ContentPage/constants"

export const useHandleAuth0Login = ({
  isLoading,
  user,
  isLoginInProgress,
  isAuthenticated,
  setIsLoginInProgress,
  setIsIdentityVerified,
  handleAuthError,
}) => {
  return useCallback(() => {
    if (!isLoading && isLoginInProgress && isAuthenticated) {
      const provider = user.sub.split("|")[0]
      const uid = user.sub.split("|")[1]

      checkLoginIdentity({
        email: user.email,
        provider,
        uid,
        isLogin: true,
      })
        .then(() => {
          setIsLoginInProgress(false)
          setIsIdentityVerified(true)
        })
        .catch(error => {
          handleAuthError(error, AUTH0_ACTION_TYPES.SIGN_IN)
        })
    }
  }, [isLoading, isAuthenticated, user, isLoginInProgress])
}
