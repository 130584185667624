import React from "react"

import PropTypes from "prop-types"

import { Button, CloseIcon } from "./closeButtonStyles"

export const CloseButton = ({ size, onClick }) => (
  <Button $size={size} onClick={onClick}>
    <CloseIcon $size={size} name="close" color="var(--gray4)" />
  </Button>
)

CloseButton.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
}
