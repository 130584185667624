import { StyleSheet } from "react-native"

import { AppColors, AppFonts, AppSizes, Units } from "@theme/"

const radius = Units.responsiveValue(8)

const draggableContainerInner = ({
  bordered,
  decided,
  isDraggable,
  isTeeupCancelled,
  isNew,
  isRemoved,
  selected,
  forChat,
  fullContainer,
}) => {
  const borderColor = isRemoved
    ? AppColors.brand.orange
    : isNew
    ? AppColors.brand.pink
    : isTeeupCancelled
    ? AppColors.brand.warmGrey2
    : decided
    ? AppColors.brand.green
    : selected
    ? AppColors.brand.blue2
    : AppColors.brand.message
  const borderWidth =
    selected || isNew || bordered || decided || isRemoved ? 1 : 0
  const margin = fullContainer ? 0 : undefined
  return {
    margin,
    flex: 1,
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),
    borderBottomRightRadius: Units.responsiveValue(8),
    overflow: "unset",
    paddingVertical: 30,
    borderWidth,
    borderColor: "transparent",
    borderBottomWidth: Units.responsiveValue(borderWidth),
  }
}

const messageTypesStyles = {
  head: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: radius,
    borderTopLeftRadius: radius,
    borderTopRightRadius: radius,
  },
  headMy: {
    borderBottomLeftRadius: radius,
    borderBottomRightRadius: 0,
  },
  default: {
    borderRadius: radius,
  },
  tail: {
    borderBottomLeftRadius: radius,
    borderBottomRightRadius: radius,
    borderTopLeftRadius: 0,
    borderTopRightRadius: radius,
  },
  tailMy: {
    borderBottomLeftRadius: radius,
    borderBottomRightRadius: radius,
    borderTopLeftRadius: radius,
    borderTopRightRadius: 0,
  },
  middle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: radius,
    borderTopLeftRadius: 0,
    borderTopRightRadius: radius,
  },
  middleMy: {
    borderBottomLeftRadius: radius,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: radius,
    borderTopRightRadius: 0,
  },
}

const GameplansRowStyles = StyleSheet.create({
  startsWhenIcon: {
    justifyContent: "center",
    alignItems: "center",
    width: Units.responsiveValue(40),
    height: Units.responsiveValue(40),
    borderRadius: Units.responsiveValue(20),
    backgroundColor: AppColors.brand.lightBlue3,
  },
  viewReactions: {
    position: "absolute",
    width: "100%",
    height: "100%",
    right: 0,
    top: 0,
    backgroundColor: "#222222",
    zIndex: 999,
  },
  newContainer: {
    borderWidth: "1pt",
    borderColor: "rgb(244,40,98)",
  },
  isRemovedContainer: {
    borderColor: "rgb(253,123,56)",
  },
  selectedContainer: {
    borderColor: "rgb(23,201,180)",
  },
  suggestContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Units.responsiveValue(0),
    marginBottom: Units.responsiveValue(0),
  },
  suggestIcon: {
    marginRight: Units.responsiveValue(6),
  },
  customSuggestionStyle: {
    minHeight: Units.rem(5),
  },
  background: {
    flexDirection: "row",
  },
  backgroundWithPaddingTop: {
    flexDirection: "row",
    paddingTop: Units.rem(0.5),
  },
  whiteGreyContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    paddingBottom: Units.responsiveValue(2),
  },
  whiteBg: {
    backgroundColor: AppColors.brand.white,

    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  draggableContainer: {
    flex: 1,
    borderRadius: Units.rem(0.5),
    // marginHorizontal: Units.rem(1),
    marginHorizontal: "12px",
    backgroundColor: AppColors.brand.white,
    borderColor: "transparent",
    flexDirection: "row",
    // borderBottomRightRadius: "0px",
    alignItems: "center",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderBottomRightRadius: 6,
    transition: "0.6s all",
    minWidth: "320px",
  },
  draggableContainerGrey: {
    backgroundColor: AppColors.brand.lightGrey,
    border: "none",
  },
  draggableContainerJumping: {
    shadowColor: AppColors.brand.black,
    shadowOffset: { width: 0, height: Units.responsiveValue(4) },
    shadowRadius: Units.rem(0.375),
    shadowOpacity: 0.5,
    elevation: 4,
  },
  draggableContainerShadow: {
    shadowColor: AppColors.brand.black,
    shadowOffset: { width: 0, height: Units.responsiveValue(2) },
    shadowRadius: Units.rem(0.1875),
    shadowOpacity: 0.25,
    elevation: 2,
  },
  draggableContentContainer: {
    backgroundColor: "var(--lightGray2)",
    border: "1px solid var(--lightGray5)",
    paddingVertical: 10,
    paddingHorizontal: 15,
    position: "relative",
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 5,
    flex: 1,
  },
  decidedContentContainer: {
    backgroundColor: "var(--lightMint3)",
    borderColor: "var(--lightGray5)",
  },
  withdrawnContentContainer: {
    backgroundColor: "var(--lightGray)",
    borderColor: "var(--lightGray5)",
  },
  rippleOverlay: {
    position: "absolute",
    width: "200%",
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  draggingOverlay: ({ selected }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: selected
      ? AppColors.brand.warmGrey4
      : AppColors.brand.lightGrey,
    borderRadius: Units.rem(0.5),
  }),
  draggedIndicator: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: Units.responsiveValue(3),
    overflow: "hidden",
  },
  draggedIndicatorInner: {
    width: Units.responsiveValue(40),
    flex: 1,
    backgroundColor: AppColors.brand.pink,
    borderRadius: Units.rem(0.5),
  },
  fullContainer: {
    marginRight: 0,
    marginVertical: 0,
    marginTop: 0,
    marginLeft: 0,
    borderRadius: radius,
  },
  forChatContainer: {
    backgroundColor: AppColors.brand.lightGrey,
    borderColor: "rgb(0,188,246)",
    borderWidth: "1pt",
    marginRight: 0,
    marginVertical: 0,
    marginTop: 0,
    marginLeft: 0,
  },
  forChatContainerMy: {
    backgroundColor: AppColors.brand.lightBlue4,
    borderColor: "transparent",
  },
  selected: {
    borderWidth: 2,
    borderColor: AppColors.brand.blue2,
  },
  decided: {
    borderWidth: 2,
    borderColor: AppColors.brand.green,
  },
  usual: {
    borderWidth: 2,
    borderColor: AppColors.brand.message,
  },
  rowReorder: {
    // minWidth: Units.responsiveValue(isDraggable ? 26 : 12),
    elevation: 1,
    alignSelf: "stretch",
    overflow: "hidden",
    justifyContent: "center",
  },
  topContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  contentContainerAlignTop: {
    flexDirection: "row",
    // marginTop: '4pt'
    position: "relative",
  },
  titleContainer: {
    flex: 1,
    color: "red",
  },
  suggestionText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.warmGrey2,
  },
  suggestionTextActiveMy: {
    color: AppColors.brand.darkBlue,
  },
  suggestionTextActive: {
    color: AppColors.brand.blackTwo,
  },
  suggestionLine1: {
    paddingVertical: Units.responsiveValue(4),
  },
  suggestionOverlapWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  suggestionPlaceholder: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.warmGrey2,
    paddingTop: Units.responsiveValue(4),
  },
  title: {
    ...AppFonts.biggerDemibold,
    paddingLeft: Units.rem(0.75),
  },
  placeholder: {
    ...AppFonts.bigDemibold,
    paddingLeft: Units.rem(0.75),
    color: AppColors.brand.warmGrey2,
  },
  content: {
    color: AppColors.brand.black2,
  },
  username: {
    color: "#9d9d9d",
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 10,
    lineHeight: 12,
    // lineHeight: 16,
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
  },
  myUsername: {
    color: "rgb(116,169,198)",
    fontFamily: "greycliffcf-bold-salt",
    fontSize: 10,
    lineHeight: 12,
    // lineHeight: 16,
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
  },
  number: {
    ...AppFonts.mediumDemibold,
    marginLeft: Units.rem(0.25),
    color: AppColors.brand.warmGrey,
  },
  headline: {
    ...AppFonts.smallDemibold,
    color: AppColors.brand.warmGrey,
  },
  timestamp: {
    ...AppFonts.smallRegular,
    alignSelf: "flex-end",
    color: AppColors.brand.warmGrey,
  },
  avatar: {
    tintColor: AppColors.brand.warmGrey,
  },
  reaction: {
    fontSize: Units.rem(0.75),
  },
  shadowTop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 8,
  },
  shadowBottom: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 8,
  },
  suggestionButtonWrapper: {
    padding: Units.rem(1),
  },
  suggestionButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: Units.rem(3),
    borderRadius: Units.rem(0.5),
    overflow: "hidden",
  },
  suggestionButtonEnabled: {
    backgroundColor: AppColors.brand.pink,
  },
  suggestionButtonDisabled: {
    borderWidth: 2,
    borderColor: AppColors.brand.warmGrey2,
  },
  suggestionImageEnabled: {
    tintColor: AppColors.brand.white,
  },
  suggestionImageDisabled: {
    tintColor: AppColors.brand.warmGrey2,
  },
  suggestionButtonText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
    marginLeft: Units.rem(0.5),
  },
  suggestionButtonTextEnabled: {
    color: AppColors.brand.white,
  },
  suggestionButtonTextDisabled: {
    color: AppColors.brand.warmGrey2,
  },
  suggestionsHeader: {
    flexDirection: "row",
    height: Units.rem(2.125),
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: Units.rem(1),
    position: "relative",
    marginVertical: "10px",
  },
  headerTitle: {
    textTransform: "uppercase",
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: 12,
    lineHeight: 20,
    letterSpacing: "-0.24px",
    color: "#767676",
  },
  sortButton: {
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    paddingHorizontal: "10px",
    paddingVertical: "6px",
    borderRadius: "24px",
  },
  arrowUp: {
    width: 0,
    height: 0,
    borderLeftWidth: "5px",
    borderLeftColor: "transparent",
    borderRightWidth: "5px",
    borderRightColor: "transparent",
    borderBottomWidth: "5px",
    borderBottomColor: "#767676",
  },
  sortButtonImage: {
    // tintColor: AppColors.brand.darkGrey2,
    width: "8px",
    height: "10px",
    marginTop: "2px",
    marginRight: "3px",
  },
  sortButtonText: {
    fontFamily: "greycliff-cf-medium",
    letterSpacing: "-0.26px",
    fontSize: "13px",
    color: "#767676",
    marginHorizontal: Units.responsiveValue(4),
    lineHeight: "17px",
  },
  sortButtonCaret: {
    tintColor: AppColors.brand.darkGrey2,
    width: Units.responsiveValue(8),
  },
  markContainer: {
    position: "relative",
    marginLeft: "auto",
    top: 0,
    right: 0,
    // paddingHorizontal: Units.responsiveValue(9),
    // paddingTop: Units.responsiveValue(2.5),
    // paddingBottom: Units.responsiveValue(4.5),
    // borderBottomLeftRadius: Units.rem(0.5),
    // backgroundColor: AppColors.brand.warmGrey2,
    marginHorizontal: "4px",
    color: "rgb 0 188 246",
    // minWidth: Units.responsiveValue(99),
    alignItems: "center",
  },
  reactionsContainerInSuggestionHeader: {
    paddingVertical: 6,
    paddingHorizontal: 11,
  },
  decidedMarkContainer: {
    backgroundColor: AppColors.brand.green,
    flexDirection: "row",
    alignItems: "center",
  },
  selectedMarkContainer: {
    // backgroundColor: AppColors.brand.blue2,
  },
  isNewMarkContainer: {
    // backgroundColor: AppColors.brand.pink,
  },
  isRemovedMarkContainer: {
    // backgroundColor: AppColors.brand.orange,
  },
  decidedMark: {
    ...AppFonts.smallBold,
    color: AppColors.brand.white,
    paddingRight: Units.responsiveValue(8),
  },
  selectedMark: {
    // ...AppFonts.smallBold,
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "10px",
    lineHeight: "16pt",
    letterSpacing: "0.45pt",
    // color: AppColors.brand.white,
    color: "rgb(23,201,180)",
    textTransform: "uppercase",
  },
  selectedMarkGameplan: {
    // ...AppFonts.smallBold,
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "10px",
    // lineHeight: '16pt',
    letterSpacing: "0.45pt",
    // color: AppColors.brand.white,
    color: "rgb(0,188,246)",
    textTransform: "uppercase",
  },
  newMarkGameplan: {
    color: "rgb(244,40,98)",
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "10px",
    letterSpacing: "0.45pt",
    textTransform: "uppercase",
  },
  newMarkGameplanList: {
    position: "absolute",
    right: "5px",
    top: "-5px",
  },
  removedMark: {
    // ...AppFonts.smallBold,
    // color: AppColors.brand.white,
    color: "rgb(253,123,56)",
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: "10px",
    letterSpacing: "0.45pt",
    textTransform: "uppercase",
  },
  multizoneIconContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  multizoneIcon: {
    paddingHorizontal: Units.responsiveValue(8),
  },
  inActiveIcon: {
    paddingHorizontal: Units.responsiveValue(8),
  },
  timezoneText: {
    marginLeft: Units.responsiveValue(4),
  },
  iconSmall: {
    height: Units.responsiveValue(14),
    width: Units.responsiveValue(14),
  },
  iconGray: {
    tintColor: AppColors.brand.warmGrey,
  },
  iconInactive: {
    tintColor: AppColors.brand.warmGrey2,
  },
  icon: {
    height: Units.rem(1),
    width: Units.rem(1),
    tintColor: AppColors.brand.warmGrey,
  },
  commentsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: Units.rem(0.5),
  },
  comments: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.warmGrey,
    paddingLeft: Units.rem(0.25),
  },
  reactionsContainer: {
    columnGap: 15,
    flexDirection: "row",
    paddingVertical: 6,
    paddingHorizontal: 11,
  },

  inactiveReactionContainer: {
    backgroundColor: AppColors.brand.grey227,
    borderColor: AppColors.brand.grey227,
  },
  activeReactionContainerMy: {
    backgroundColor: AppColors.brand.lightBlue5,
    borderColor: AppColors.brand.lightBlue5,
  },
  activeReactionContainer: {
    backgroundColor: AppColors.brand.lightGrey2,
    borderColor: AppColors.brand.lightGrey2,
  },
  activeReactionNudgeContainer: {
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  reactionCountText: {
    opacity: 0.6,
  },
  activeReactionIcon: {
    marginTop: Units.responsiveValue(4),
    color: AppColors.brand.thumbYellow,
  },
  activeReactionCountText: {
    marginTop: Units.responsiveValue(2),
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.warmGrey2,
  },
  inActiveReactionCountText: {
    marginTop: Units.responsiveValue(2),
    ...AppFonts.biggerDemibold,
    color: "rgb(157,157,157)",
  },
  activeReactionCountTexActiveMy: {
    color: AppColors.brand.darkBlue,
  },
  activeReactionCountTexActive: {
    color: AppColors.brand.blackTwo,
  },
  iconContainer: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: AppColors.brand.lightBlue3,
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainerMask: {
    position: "absolute",
    top: -8,
    left: -8,
    height: 56,
    width: 56,
  },
  noSuggestionsIconContainer: {
    backgroundColor: AppColors.brand.whiteGrey,
  },
  chatIconContainer: {
    position: "absolute",
    top: 7,
    left: 7,
  },
  suggestionRecommendationsContainer: {
    paddingTop: Units.responsiveValue(8),
    paddingHorizontal: Units.responsiveValue(14),
  },
  suggestionRecommendationsHeader: {
    color: AppColors.brand.green,
    fontSize: Units.responsiveValue(11),
    fontFamily: AppFonts.family.bold,
    textAlign: "center",
  },
  startsWhenInactive: {
    color: AppColors.brand.darkGrey2,
  },
  toggleSuggestionExpandButton: {
    borderTopWidth: Units.responsiveValue(1),
    borderTopColor: AppColors.brand.lightGrey,
    paddingVertical: Units.responsiveValue(10),
    backgroundColor: AppColors.brand.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  expandText: {
    ...AppFonts.middleBold,
    lineHeight: Units.responsiveValue(22),
    letterSpacing: -Units.responsiveValue(0.33),
    color: AppColors.brand.pink,
  },
  actionSheetButton: {
    alignItems: "center",
    justifyContent: "center",
    width: Units.responsiveValue(24),
    height: Units.responsiveValue(42),
  },
  actionSheetButtonTransparent: {
    alignItems: "center",
    justifyContent: "center",
    width: Units.responsiveValue(24),
    height: Units.responsiveValue(42),
    opacity: 0,
  },
  mapButton: {
    marginRight: 12,
    marginBottom: 8,
    alignSelf: "stretch",
    width: 17,
    justifyContent: "flex-end",
  },
  gamePlanText: {
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: 15,
    lineHeight: 12,
    letterSpacing: "-0.3px",
    color: "#787f88",
    marginLeft: 2,
    textTransform: "uppercase",
  },
  gamePlanIcon: {
    width: 9,
    heigth: 12,
  },
  gamePlanWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 10,
  },
})

const Props = {
  flagColor: AppColors.brand.darkGrey2,
  flagColorGrey: AppColors.brand.warmGrey2,
}

const GameplansStyles = StyleSheet.create({
  contentContainer: {
    paddingLeft: Units.rem(1),
  },
  sectionList: {
    minHeight: 144,
    borderRadius: 10,
  },
  blocker: {
    marginTop: Units.rem(0.5),
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.white,
    backgroundColor: AppColors.brand.white,
    height: 120,
    width: "100%",
  },
  rowContainer: {},
  separator: {
    width: Units.rem(0.5),
  },
  titleContainer: {
    marginTop: 0,
    // borderBottomWidth: 1,
    // borderColor: AppColors.brand.whiteGrey,
    // paddingHorizontal: Units.responsiveValue(16),
  },
  titleRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  titlePadding: {
    paddingBottom: Units.responsiveValue(12),
    paddingLeft: "7px",
    paddingTop: "2px",
  },
  flex: {
    flex: 1,
  },
  title: {
    fontFamily: "greycliffcf-bold-salt",
    paddingRight: Units.rem(0.5),
    lineHeight: 22,
    fontSize: 17,
    color: AppColors.brand.black,
  },
  number: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.warmGrey,
  },
  scrollViewContainer: {
    borderTopWidth: 1,
    borderColor: AppColors.brand.whiteGrey,
    backgroundColor: AppColors.brand.white,
  },
  titleIconContainer: {
    flexDirection: "row",
  },
  buttonIcon: {
    paddingRight: Units.responsiveValue(16),
  },
  hidden: {
    opacity: 0,
  },
})

const ReactionPopoverStyles = StyleSheet.create({
  content: {
    paddingHorizontal: Units.rem(0.75),
    backgroundColor: AppColors.brand.black,
    borderRadius: Units.rem(0.5),
  },
  arrow: {
    borderTopColor: AppColors.brand.black,
  },
  background: {
    backgroundColor: AppColors.brand.white100,
  },
  button: {
    paddingHorizontal: Units.rem(0.75),
  },
  buttonContent: {
    fontSize: Units.rem(1.5),
    color: AppColors.brand.black,
  },
  container: {
    flexDirection: "row",
  },
})

const SectionHeaderStyles = StyleSheet.create({
  container: {
    // maxHeight: Units.responsiveValue(135),
    // minHeight: Units.responsiveValue(108),
    // height: 135,
    paddingHorizontal: Units.responsiveValue(12),
    flexDirection: "row",
    // alignItems: 'center',
    borderRadius: 8,
    marginBottom: 8,
    // borderBottomWidth: 1,
    // borderBottomColor: 'rgba(225, 225, 225, 0.5)',
    backgroundColor: "#F5F8FA",
    // backgroundColor: '#f5f5f5',
  },
  lockIcon: {
    width: Units.responsiveValue(20),
    height: Units.responsiveValue(52),
    justifyContent: "center",
    alignItems: "center",
    marginRight: Units.responsiveValue(12),
    marginLeft: Units.responsiveValue(16),
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
  },
  content: {
    flex: 1,
    marginRight: Units.responsiveValue(4),
  },
  contentHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: Units.responsiveValue(8),
    marginTop: 10,
  },
  contentHeaderText: {
    marginHorizontal: 4,
    fontFamily: "greycliffcf-extrabold-salt",
    fontSize: 15,
    lineHeigth: "12px",
    letterSpacing: "-0.3px",
    color: "#787f88",
  },
  contentHeaderCaret: {
    width: Units.responsiveValue(7),
  },
  contentText: {
    fontFamily: "greycliffcf-demibold-salt",
    fontSize: 17,
    lineHeigth: "22px",
    letterSpacing: "-0.34px",
    color: "#222222",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "230px",
  },
  contentBox: {
    borderRadius: 8,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    backgroundColor: "#ffffff",
    // height: 56,
    boxSizing: "border-box",
    paddingHorizontal: 8,
    paddingVertical: 8,
    width: "100%",
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  moreSuggestions: {
    fontFamily: "greycliffcf-medium-salt",
    fontSize: 12,
    lineHeight: "20px",
    letterSpacing: "-0.24px",
    color: "#b2b2b2",
    paddingTop: 6,
    paddingBottom: 8,
    textAlign: "center",
  },
  draggingOverlayWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderWidth: 2,
    borderColor: AppColors.brand.pink,
  },
  draggingOverlay: {
    position: "absolute",
    minHeight: Units.responsiveValue(80),
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    justifyContent: "center",
    alignItems: "center",
  },
  draggingOverlayText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.pink,
  },
  draggingOverlayActive: {
    position: "absolute",
    minHeight: Units.responsiveValue(76),
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.pink,
    justifyContent: "center",
    alignItems: "center",
  },
  draggingOverlayActiveText: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.white,
  },
  rippleOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: AppColors.brand.lightGrey2,
  },
  chevronIcon: {
    paddingHorizontal: 6,
  },
  whenWorksHeader: {
    flexDirection: "row",
    paddingLeft: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(8),
  },
  contactLine: {
    backgroundColor: AppColors.brand.blue2,
    width: Units.responsiveValue(2),
    height: Units.responsiveValue(13),
    position: "absolute",
    marginLeft: Units.responsiveValue(24),
    marginTop: Units.responsiveValue(40),
  },
  timeBlock: {
    flexDirection: "row",
    alignItems: "center",
  },
  whenWorksImage: {
    marginRight: Units.responsiveValue(12),
    marginLeft: Units.responsiveValue(4),
    height: Units.responsiveValue(40),
    width: Units.responsiveValue(40),
  },
  whenWorksBorder: {
    borderRadius: Units.responsiveValue(20),
    borderWidth: Units.responsiveValue(2),
    borderColor: AppColors.brand.blue2,
  },
  whenWorksTitle: {
    ...AppFonts.biggerDemibold,
    color: AppColors.brand.darkGrey2,
  },
  timeIcon: {
    marginLeft: Units.responsiveValue(8),
    zIndex: 10_000,
  },
  triangleUp: {
    position: "absolute",
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderBottomWidth: 6,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: AppColors.brand.darkGrey2,
    marginLeft: Units.responsiveValue(43),
    marginTop: Units.responsiveValue(35),
  },
  triangleDown: {
    position: "absolute",
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderTopWidth: 8,
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: AppColors.brand.darkGrey2,
    marginLeft: Units.responsiveValue(35),
    marginTop: Units.responsiveValue(32),
  },
  pollStatusBlock: {
    alignSelf: "center",
    flexDirection: "row",
    right: 0,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  statusLabel: {
    height: Units.responsiveValue(22),
    width: Units.responsiveValue(92),
    borderRadius: Units.responsiveValue(12),
    ...AppFonts.mediumMedium,
    color: AppColors.brand.white,
    backgroundColor: "green",
    textAlign: "center",
    paddingTop: Units.responsiveValue(2),
  },
  participantsReactions: {
    flexDirection: "row",
    marginTop: Units.responsiveValue(8),
  },
  polling: {
    backgroundColor: AppColors.brand.orange,
  },
  closedPoll: {
    backgroundColor: AppColors.brand.warmGrey2,
  },
  scheduled: {
    backgroundColor: AppColors.brand.blueEmerald,
  },
  completedPoll: {
    backgroundColor: AppColors.brand.blue2,
  },
  reactionCountView: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: Units.responsiveValue(2),
    marginRight: Units.responsiveValue(6),
    paddingLeft: Units.responsiveValue(6),
    paddingRight: Units.responsiveValue(4),
    // paddingTop: Units.responsiveValue(1),
    // paddingBottom: Units.responsiveValue(1),
  },
  reactionsTotal: {
    color: AppColors.brand.warmGrey2,
    ...AppFonts.biggerDemibold,
    marginTop: Units.responsiveValue(4),
  },
  totalImage: {
    width: Units.responsiveValue(14),
    height: Units.responsiveValue(19),
  },
  suggestText: {
    fontFamily: "greycliffcf-medium-salt",
    fontSize: 12,
    lineHeigth: "20px",
    letterSpacing: "-0.24px",
    color: "#b2b2b2",
  },
  suggestIcon: {
    width: 12,
    height: 17,
    tintColor: "#b2b2b2",
    marginRight: 5,
  },
  suggestWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
})

const ReactionPageStyles = StyleSheet.create({
  container: {
    backgroundColor: AppColors.brand.lightGrey,
  },
  scrollContainer: {
    paddingBottom: Units.rem(1.5),
    borderTopWidth: 1,
    borderColor: AppColors.brand.warmGrey2,
  },
  titleContainer: {
    marginBottom: Units.rem(0.25),
    paddingTop: Units.rem(0.5),
    paddingBottom: Units.rem(0.5),
    paddingLeft: Units.rem(1),
    backgroundColor: AppColors.brand.lightGrey,
  },
  title: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
  },
  usersScrollContainer: {
    paddingBottom: Units.rem(1),
    flexDirection: "row",
  },
  userRow: {
    paddingBottom: Units.rem(2),
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: Units.rem(0.3),
    paddingRight: Units.rem(0.3),
  },
  userInfoContainer: columns => ({
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1 / columns,
    overFlow: "hidden",
  }),
  userName: {
    ...AppFonts.smallMedium,
    color: AppColors.brand.darkGrey2,
    paddingTop: Units.rem(0.25),
    letterSpacing: -Units.responsiveValue(0.2),
    textAlign: "center",
    width: "100%",
  },
  userAvatar: {
    height: Units.rem(3.5),
    width: Units.rem(3.5),
  },
  avatarAccesory: {
    top: Units.responsiveValue(36),
  },
  placeholderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.darkGrey2,
    textAlign: "center",
  },
})

const RecommendationStyles = StyleSheet.create({
  flatlist: {
    paddingTop: Units.responsiveValue(16),
    paddingBottom: Units.responsiveValue(16),
    flex: 1,
  },
  mainHeaderContainer: {
    paddingTop: Units.responsiveValue(16),
    paddingHorizontal: Units.responsiveValue(16),
    borderTopWidth: 2,
    borderColor: AppColors.brand.lightGrey,
  },
  mainHeaderText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
  },
  container: {
    borderRadius: Units.responsiveValue(8),
    flexDirection: "row",
    // marginHorizontal: Units.responsiveValue(16),
    shadowColor: AppColors.brand.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6,

    elevation: 4,
    backgroundColor: "white",
    marginVertical: Units.responsiveValue(8),
  },
  imageContainer: {
    overflow: "hidden",
    height: Units.responsiveValue(110),
    width: Units.responsiveValue(64),
    borderTopLeftRadius: Units.responsiveValue(8),
    borderBottomLeftRadius: Units.responsiveValue(8),
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  contentContainer: {
    paddingLeft: Units.responsiveValue(8),
    paddingRight: Units.responsiveValue(12),
    flex: 1,
    justifyContent: "space-between",
  },
  bottomContainer: {
    flexDirection: "row",
    paddingBottom: Units.responsiveValue(8),
    justifyContent: "space-between",
  },
  peopleText: {
    color: AppColors.brand.orange,
    ...AppFonts.smallDemibold,
    paddingRight: Units.responsiveValue(2),
  },
  peopleTextActive: {
    color: AppColors.brand.blue2,
  },
  locationText: {
    ...AppFonts.smallMedium,
    color: AppColors.brand.warmGrey2,
    paddingLeft: Units.responsiveValue(2),
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.blackTwo,
    paddingTop: Units.responsiveValue(8),
  },
  contentText: {
    color: AppColors.brand.darkGrey2,
    ...AppFonts.smallish,
    paddingTop: Units.responsiveValue(3),
  },
  overlayButtonImage: {
    tintColor: AppColors.brand.darkGrey2,
  },
})
const ChatTabStyles = StyleSheet.create({
  contentWrapper: {
    backgroundColor: AppColors.brand.lightGrey,
    overflow: "hidden",
    paddingTop: Units.rem(5),
  },
  chatWrapper: {
    // transform: [{ scaleY: -1 }],
    flex: 1,
    backgroundColor: AppColors.brand.lightGrey,
  },
  chatMessagesList: {
    marginBottom: Units.responsiveValue(22),
  },
  shadowTop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: Units.rem(0.5),
  },
  emptyCommentsWrapper: {
    flex: 1,
    paddingTop: Units.responsiveValue(12),
    alignItems: "center",
    backgroundColor: AppColors.brand.lightGrey,
  },
  noCommentsText: {
    ...AppFonts.xlMedium,
    letterSpacing: -Units.responsiveValue(1.17),
    color: AppColors.brand.darkGrey3,
    lineHeight: Units.responsiveValue(29),
  },
  footerWrapper: {
    paddingTop: Units.rem(0.5),
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: AppColors.brand.lightGrey,
  },
  footerButtonBackground: {
    padding: Units.rem(0.75),
    paddingBottom: Units.rem(0.75) + AppSizes.additionalBottomSpace,
    borderTopWidth: 1,
    borderTopColor: AppColors.brand.grey2_50,
    backgroundColor: AppColors.brand.white,
  },
  footerButtonWrapper: {
    width: "100%",
    height: Units.rem(3),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: AppColors.brand.pink,
    borderRadius: Units.rem(0.5),
  },
  footerButtonText: {
    ...AppFonts.biggerBold,
    letterSpacing: -Units.responsiveValue(0.3),
    lineHeight: Units.responsiveValue(20),
    color: AppColors.brand.white,
  },
  inputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: Units.responsiveValue(40),
    paddingHorizontal: Units.responsiveValue(12),
    marginVertical: Units.responsiveValue(8),
  },
  inputField: {
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: Units.responsiveValue(12),
    paddingLeft: Units.responsiveValue(16),
    borderWidth: 2,
    borderRadius: Units.rem(1.5),
    borderColor: AppColors.brand.whiteGrey,
    flexGrow: 1,
    marginRight: Units.responsiveValue(16),
  },
  input: {
    flex: 1,
    justifyContent: "center",
    color: AppColors.brand.black,
    padding: 0,
    margin: 0,
    borderWidth: 0,
  },
  sendIcon: {
    tintColor: AppColors.brand.pink,
  },
  activeSendIcon: {
    tintColor: AppColors.brand.darkGrey2,
  },
  inactiveSendIcon: {
    tintColor: AppColors.brand.pink,
  },
})

const IconsProps = {
  lockIcon: {
    type: "font-awesome5",
    name: "lock",
    solid: true,
    size: Units.responsiveValue(10),
    color: AppColors.brand.white,
  },
  caretDown: {
    type: "font-awesome5",
    name: "caret-down",
    size: Units.responsiveValue(14),
    color: AppColors.brand.pink,
    style: { marginLeft: Units.responsiveValue(6) },
  },
  caretUp: {
    type: "font-awesome5",
    name: "caret-up",
    size: Units.responsiveValue(14),
    color: AppColors.brand.pink,
    style: { marginLeft: Units.responsiveValue(6) },
  },
}

const rowReorderImage = bordered => ({
  ...StyleSheet.absoluteFill,
  left: Units.responsiveValue(12 - (bordered ? 2 : 0)),
  overflow: "hidden",
})

const reactionContainer = (isMyMessage, inSuggestion, inactive) => {
  let background = isMyMessage
    ? { backgroudColor: AppColors.brand.lightBlue4 }
    : {}
  return {
    // width: Units.responsiveValue(28),
    width: "22pt",
    // height: Units.responsiveValue(42),
    height: "34pt",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    marginHorizontal: Units.rem(0.25),
    borderWidth: 1,
    boxShadow: "0 1pt 4pt 0 rgb(0 0 0 / 16%)",
    border: "none",
    // borderColor: inactive
    //     ? inSuggestion
    //         ? 'transparent'
    //         : AppColors.brand.grey214
    //     : isMyMessage
    //     ? AppColors.brand.lightBlue5
    //     : AppColors.brand.lightGrey2,
    borderRadius: Units.responsiveValue(10),
    ...background,
  }
}

const activeReactionContainer = (isMyMessage, inactive, newDesign = false) => {
  if (newDesign) {
    return {
      backgroundColor: inactive ? "transparent" : "#f0f0f0",
      ...(!inactive && {
        boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.16)",
        backgroundBlendMode: "multiply",
        backgroundImage: "linear-gradient(to bottom, #f0f0f0, #f0f0f0)",
      }),
    }
  }

  return {
    backgroundColor: inactive
      ? AppColors.brand.grey227
      : isMyMessage
      ? AppColors.brand.lightBlue5
      : AppColors.brand.lightGrey2,
    boxShadow: "none",
  }
}

export {
  GameplansStyles,
  GameplansRowStyles,
  SectionHeaderStyles,
  ReactionPopoverStyles,
  Props,
  ReactionPageStyles,
  RecommendationStyles,
  ChatTabStyles,
  IconsProps,
  draggableContainerInner,
  messageTypesStyles,
  rowReorderImage,
  reactionContainer,
  activeReactionContainer,
}
