import React, { useCallback } from "react"

import PropTypes from "prop-types"
import { View, Text } from "react-native"
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field"

import styles from "./CodeInputNeoStyles"

const CodeInputNeo = props => {
  const {
    customContainerStyle,
    label,
    errorMessage,
    codeLength,
    onChange,
    value,
  } = props

  const handleChangeText = useCallback(text => {
    if (!/^\d*$/g.test(text)) return
    onChange(text)
  })

  const ref = useBlurOnFulfill({ value, cellCount: codeLength })
  const [cellProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: handleChangeText,
  })

  return (
    <View style={[styles.container, customContainerStyle]}>
      <CodeField
        ref={ref}
        {...cellProps}
        value={value}
        onChangeText={handleChangeText}
        cellCount={codeLength}
        rootStyle={styles.inputContainer}
        // keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => (
          <View
            key={index}
            style={[
              styles.inputBox,
              index === codeLength - 1 && styles.lastInputBox,
              errorMessage && styles.errorInput,
            ]}
            onLayout={getCellOnLayoutHandler(index)}>
            <Text style={styles.inputText}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          </View>
        )}
      />
    </View>
  )
}

CodeInputNeo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  codeLength: PropTypes.number.isRequired,
  customContainerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
}

CodeInputNeo.defaultProps = {
  codeLength: 6,
  label: "Verification Code:",
  customContainerStyle: {},
}

export default CodeInputNeo
