import { useCallback } from "react"

import moment from "moment-timezone"
import { v4 as uuidv4 } from "uuid"

import { CUSTOM_ACTION_KEY } from "@constants/global"

import { roundMinutesToFifteen } from "../../../contexts/TimeWheelContext/helpers"

export const useTodayDate = setNewGuestSuggestion => {
  const checkIsToday = useCallback(date => {
    return date.isSame(moment(), "date")
  }, [])
  const start = moment(roundMinutesToFifteen(moment())).set({ seconds: 0 })

  const setTodayDate = () => {
    setNewGuestSuggestion({
      id: uuidv4(),
      key: CUSTOM_ACTION_KEY,
      start,
      end: moment(start).add(1, "hour"),
      title: "",
    })
  }

  return { checkIsToday, setTodayDate }
}
