import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  overflow-y: hidden;

  & > div {
    position: absolute;
    right: 0;
    overflow-y: scroll;
    height: 100%;
  }
`
