import { useMemo } from "react"

import { sortButtons } from "@utils/inbox"
import { useInboxStore } from "stores/inboxStore"

import Option from "./Option"

const SortingOptions = () => {
  const currentSortBy = useInboxStore(state => state.currentSortBy)
  const setCurrentSortBy = useInboxStore(state => state.setCurrentSortBy)
  const sortButtonsArr = useMemo(
    () => Object.values(sortButtons),
    [sortButtons]
  )

  return (
    <div>
      {sortButtonsArr.map(sortButton => (
        <Option
          option={sortButton}
          key={sortButton.id}
          isChecked={currentSortBy.id === sortButton.id}
          onClick={() => setCurrentSortBy(sortButton)}
        />
      ))}
    </div>
  )
}

export default SortingOptions
