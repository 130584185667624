import styled from "styled-components"

import { MEDIA_SIZES } from "@assets/styles/mediaSizes"
import {
  NCaptionS,
  NFontWeight,
  NTitle5,
} from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  min-height: 100%;
  background-color: var(--white);
  position: relative;

  @media (max-width: ${MEDIA_SIZES.TABLET_L}) {
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled(NTitle5)`
  width: 75%;
  font-weight: ${NFontWeight.bold};
  text-align: center;
  margin: 15px auto 0 auto;
`

export const Description = styled(NCaptionS)`
  width: 75%;
  color: var(--gray4);
  text-align: center;
  margin: 5px auto 0 auto;
`

export const ContentWrapper = styled.div`
  height: 100%;
  background-color: var(--white);
  padding: 24px;
  margin-top: 20px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    padding-top: 0;
    margin-top: 10px;
  }
`

export const MakeSuggestionButton = styled.button`
  width: 52%;
  margin: 24px auto;
  height: 52px;
  border-radius: 400px;
  background-color: var(--white);
  color: var(--gray5);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-top: 24px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--lightGray5);
  gap: 8px;

  @media (max-width: ${MEDIA_SIZES.MOBILE}) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
`
