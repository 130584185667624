import React, { useCallback, useMemo } from "react"

const Pinboard = ({ gamePlans: { day = null, quickSelect = null, time } }) => {
  const sliceDate = date => (date ? String(date).slice(0, 10) : "")

  const getDatesDuration = useCallback(dates => {
    return dates.length === 2
      ? `${sliceDate(dates[0])} - ${sliceDate(dates[1])}`
      : sliceDate(dates)
  }, [])

  const selected = useMemo(() => {
    return day
      ? sliceDate(day)
      : quickSelect?.date
      ? getDatesDuration(quickSelect?.date)
      : ""
  }, [day, quickSelect])

  return (
    <>
      {selected && <p>{selected}</p>}
      <span>{time?.timeRange}</span>
    </>
  )
}

export default Pinboard
