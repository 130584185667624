import {
  teeupStageFilterKeys,
  teeupFilterButtonTitles,
  teeupUserStatusKeys,
  teeupUserGenericStatus,
  teeupTypes,
  teeupTypesTitles,
} from "../config/enums"

export const PARTICIPANTS = {
  LAST_ACTIVITY: "last_activity",
  STATUS: "status",
  NAME: "name",
  STATUS_CHANGE: "status_change",
}

export const buttonsCreator = titles => statusKey => ({
  id: statusKey,
  filter: statusKey,
  title: titles[statusKey],
})

export const teeupStatusFilterButtons = Object.values(teeupStageFilterKeys).map(
  buttonsCreator(teeupFilterButtonTitles)
)

export const userStatusFilterButtons = Object.values(teeupUserStatusKeys).map(
  buttonsCreator(teeupUserGenericStatus)
)

export const teeupTypeFilterButtons = Object.values(teeupTypes).map(
  buttonsCreator(teeupTypesTitles)
)

export const currentUserFilter = {
  id: "userId",
  filter: { isCurrentUserCreator: true },
  title: "Created by me",
}

export const noExactWhenFilter = {
  id: "noExactWhen",
  filter: { isNoExactWhen: true },
  title: "No exact When",
}

export const blankGameplanFilter = {
  id: "blankGameplan",
  filter: { isBlankGamePlan: true },
  title: "Blank Game Plan",
}

export const isStaleFilter = {
  id: "stale",
  filter: { isStale: true },
  title: "Stale",
}

export const unreadTeeupsFilter = {
  id: "unread",
  filter: { isUnseen: true },
  title: "Unread",
}

export const OtherFilterButtons = [
  currentUserFilter,
  noExactWhenFilter,
  blankGameplanFilter,
  isStaleFilter,
  unreadTeeupsFilter,
]
