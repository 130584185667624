import { useEffect } from "react"

import { setNewRegisteredUser } from "../../actions/loginActions"

export const useCleanNewRegisteredUser = ({
  user,
  isLoginInProgress,
  isRegistrationInProgress,
  logout,
}) => {
  useEffect(() => {
    const pathname = window.location.pathname
    if (
      (pathname === "/sign-in" || pathname === "/sign-up") &&
      user &&
      !isLoginInProgress &&
      !isRegistrationInProgress
    ) {
      setNewRegisteredUser(null)
      logout({ localOnly: true })
    }
  }, [user])
}
