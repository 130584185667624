export const BUTTON_VARIANTS = {
  BORDERED: "BORDERED",
  TRANSPARENT: "TRANSPARENT",
  ROUNDED: "ROUNDED",
  UNDERLINE: "UNDERLINE",
}

export const BUTTON_SIZES = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
}
