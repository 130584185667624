import styled from "styled-components"

import { NFontWeight, NSubtitleS } from "@assets/styles/newTypographyStyles"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 40px;
`

export const EventWrapper = styled.div`
  width: 400px;
  background-color: var(--primary-50);
  border: 1px solid var(--pink2);
  border-radius: 4px;
  color: var(--red3);
  box-sizing: border-box;
  padding: 20px 0;
`
