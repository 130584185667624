export enum USER_STATUS {
  INVITED = 1,
  GOING = 2,
  MIGHT_GO = 3,
  NOT_GOING = 4,
  JOINED = 5,
  ON_MY_WAY = 6,
  ARRIVED = 7,
  ATTENDED = 8,
  DROPPED_OUT = 9,
  SKIPPED = 10,
  APPROVAL_WAITING = 11,
  APPROVAL_DECLINED = 12,
  COOL_IDEA = 13,
  RUNNING_LATE = 17,
  BACKED_OUT = 18,
  INTERESTED = 20,
  NOT_INTERESTED = 21,
}
export interface Participant {
  id: number
  userId?: number
  username: string
  avatar: string
  additionalCount: number
  isVip: boolean
  timezone: string
  countryCode: string
  name: string
  status: string
  lastTeeupActivity?: string
  lastTeeupActivityCreatedAt: string
  statusChangedAt: string
  backgroundImageUrl: string | null
  primaryLocation: string | null
  joinedAt: string
  isOrganiser: boolean
  prevStatus: string | null
  contactList: {
    value: string
    contactType: number
    isPublic: boolean
  }[]
  invitedBy: number
  invitedByUsername: string
}

export interface ActionButton {
  isShown: boolean
  text: string
  action: () => void
}

export type NudgeAction =
  | "areYouGoing"
  | "whereAreYou"
  | "wouldYouSuggestWhen"
  | "wouldYouSuggestWhere"
export interface OptionsItem {
  title: string
  icon: string
  action: () => void
}

export interface StatusIcon {
  iconName: string
  color: string
}

export default interface TeeupInvitee {
  about: string
  avatar: string
  firstName?: string
  lastName?: string
  backgroundImageUrl: string
  contactsSyncedAt: string
  email: string
  icon?: string
  label?: string
  type?: string
  status: string
  invitedAt?: string
  value?: string
  lastTeeupActivity?: string
  lastTeeupActivityCreatedAt?: string
  invitedBy: number
  username: string
  contactType?: string
  isOrganiser?: boolean
  additionalCount?: number
  invitationStage: number
  hasPassword: boolean
  firstContactMech: any
  id: number
  contactId?: number
  selectedContactMethodIds?: number[]
  contactLabel?: string
  contactList: { label: string; value: string; contactType: string }[]
  emails: { email: string; userId?: number; value?: string }[]
  identities: [
    {
      id: number
      provider: string
      title: string
      type: string
      userId: number
    }
  ]
  isOwner: boolean
  loginType: string
  name: string
  nickname: string
  timezone: string
  usertype?: string
  userType: string
  primaryLocation: string
  phoneNumbers?: {
    value: string
    number: string
    userId?: number
  }[]
  tags?: any[]
}
