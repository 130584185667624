import { StyleSheet } from "react-native"

import { AppColors, AppFonts, Units } from "@theme"

export const styles = StyleSheet.create({
  sortButtonLine: {
    flexDirection: "row",
    alignItems: "center",
    height: Units.responsiveValue(48),
    paddingHorizontal: Units.responsiveValue(16),
    backgroundColor: AppColors.brand.white,
    marginBottom: Units.responsiveValue(1),
  },
  checkIcon: {
    tintColor: AppColors.brand.pink,
    marginLeft: Units.responsiveValue(7),
    height: Units.responsiveValue(11),
    width: Units.responsiveValue(14),
  },
  buttonTitle: {
    flexGrow: 1,
    ...AppFonts.mediumDemibold,
    color: AppColors.brand.black,
    textAlign: "left",
    lineHeight: Units.responsiveValue(20),
    letterSpacing: -Units.responsiveValue(0.3),
  },
})
