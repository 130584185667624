import React, { useMemo } from "react"

import { connect } from "react-redux"

import { MINUTES_DAY_PARTS } from "@contexts/TimeWheelContext"
import { selectCalendarForDay, selectSyncEmail } from "@selectors/calendar"
import { EVENT_TYPES, getEventsLayoutForDay } from "@utils/calendarUtils"

import { EventsList } from "../molecules"

const TimeWheelDate = ({
  dateTitleInfo,
  currentHour,
  todayEvents,
  syncEmail,
}) => {
  const eventsRows = useMemo(() => {
    const filteredTodayEvents = todayEvents.filter(event => {
      const teeupEvent = event.type === EVENT_TYPES.teeup

      if (!!event.name && (event.calendarId || teeupEvent)) {
        return event
      }
    })

    return getEventsLayoutForDay(filteredTodayEvents)
  }, [todayEvents, syncEmail])

  return (
    <div className="hour-el hour-el--day-line">
      {eventsRows.mappedEventsList.length > 0 && (
        <EventsList eventsRows={eventsRows} currentHour={currentHour} />
      )}

      {dateTitleInfo && (
        <>
          <span className="hour-el__date">{dateTitleInfo[0]}</span>
          <span className="hour-el__month">{dateTitleInfo[1]}</span>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { parsedCurrentPeriod, index } = ownProps
  const todayDate = parsedCurrentPeriod[index * MINUTES_DAY_PARTS]

  return {
    todayEvents: selectCalendarForDay(todayDate)(state),
    syncEmail: selectSyncEmail(state),
  }
}

export default connect(mapStateToProps)(TimeWheelDate)
