import React from "react"

import PropTypes from "prop-types"

import { ButtonThreeDots } from "../../ButtonThreeDots"

import { Button, ButtonIcon } from "./buttonElStyles"

export const ButtonEl = ({
  isReverse,
  isNoPadding,
  backgroundColor,
  border,
  isIconFirst,
  textColor,
  fontSize,
  loadingColor = null,
  fullWidth,
  // TODO: isBlack is temporary prop because a new design - when we'll get all buttons styles - we'll decide what to do
  isBlack,
  autoWidth,
  text = "",
  disabled = false,
  type = "button",
  isLoading,
  size,
  padding,
  variant,
  icon,
  iconName,
  iconColor,
  onClick,
  testID = text,
  children,
  className,
}) => {
  const renderIcon = isRender => {
    return !!iconName && isRender ? (
      <ButtonIcon
        $isIconFirst={isIconFirst}
        name={iconName}
        color={iconColor}
      />
    ) : null
  }

  return (
    <>
      <Button
        $backgroundColor={backgroundColor}
        border={border}
        $textColor={textColor}
        $fontSize={fontSize}
        data-testid={testID}
        type={type}
        variant={variant}
        disabled={disabled}
        isFullWidth={fullWidth}
        $isBlack={isBlack}
        $isNoPadding={isNoPadding}
        $isAutoWidth={autoWidth}
        $isReverse={isReverse}
        isLoading={isLoading}
        $size={size}
        $padding={padding}
        className={className}
        onClick={onClick}>
        {isLoading ? (
          <ButtonThreeDots color={loadingColor} />
        ) : (
          <>
            {renderIcon(isIconFirst)}

            {text}

            {renderIcon(!isIconFirst)}

            {children}
          </>
        )}
      </Button>
    </>
  )
}

ButtonEl.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string,
  loadingColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  isIconFirst: PropTypes.bool,
  isReverse: PropTypes.bool,
  isBlack: PropTypes.bool,
  autoWidth: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.any,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  testID: PropTypes.string,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  padding: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  isNoPadding: PropTypes.bool,
}
