import React, { createContext, useContext, useReducer } from "react"

import { CreateTeeUpReducer } from "./CreateTeeUpReducer"

const CreateTeeUpStateContext = createContext({})
const CreateTeeUpDispatchContext = createContext(undefined)

const CreateTeeUpProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CreateTeeUpReducer, {
    image: null,
    name: "",
    message: "",
    invitees: [],
    isInviteesOpen: false,
  })

  return (
    <CreateTeeUpStateContext.Provider value={state}>
      <CreateTeeUpDispatchContext.Provider value={dispatch}>
        {children}
      </CreateTeeUpDispatchContext.Provider>
    </CreateTeeUpStateContext.Provider>
  )
}

const useCreateTeeUpState = () => {
  try {
    const context = useContext(CreateTeeUpStateContext)

    if (context === undefined) {
      throw new Error(
        "useCreateTeeUpState must be used within a CreateTeeUpProvider"
      )
    }

    return context
  } catch (error) {
    console.log(error)
  }
}

const useCreateTeeUpDispatch = () => {
  try {
    const context = useContext(CreateTeeUpDispatchContext)

    if (context === undefined) {
      throw new Error(
        "useCreateTeeUpDispatch must be used within a CreateTeeUpProvider"
      )
    }

    return context
  } catch (error) {
    console.log(error)
  }
}

export { CreateTeeUpProvider, useCreateTeeUpState, useCreateTeeUpDispatch }
