import { useRef } from "react"

import { DateRange } from "@mui/lab"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
// import { DateCalendar as MuiDateCalendar } from "@mui/x-date-pickers/StaticDateRangePicker"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker"
import { Dayjs } from "dayjs"
import styled from "styled-components"

import { useSuggestionGeneralDateStore } from "stores/suggestions/suggestionGeneralDateStore"

import DayItem from "./DayItem"

const Container = styled.div`
  width: 100%;
  position: relative;
`

interface Props {
  dateRange?: DateRange<Dayjs>
  onChange?: (dateRange: Dayjs) => void
}

export default function DateCalendar({ dateRange, onChange }: Props) {
  const calendarRef = useRef<HTMLDivElement>(null)
  const currentDate = useSuggestionGeneralDateStore(state => state.currentDate)
  const setCurrentDate = useSuggestionGeneralDateStore(
    state => state.setCurrentDate
  )
  const addSuggestion = useSuggestionGeneralDateStore(
    state => state.addSuggestion
  )
  const setCurrentLabel = useSuggestionGeneralDateStore(
    state => state.setCurrentLabel
  )
  const suggestions = useSuggestionGeneralDateStore(state => state.suggestions)
  const currentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.currentActiveSuggestionIndex
  )
  const setCurrentActiveSuggestionIndex = useSuggestionGeneralDateStore(
    state => state.setCurrentActiveSuggestionIndex
  )
  const updateSuggestion = useSuggestionGeneralDateStore(
    state => state.updateSuggestion
  )
  const removeSuggestion = useSuggestionGeneralDateStore(
    state => state.removeSuggestion
  )
  const isCustomDateRangeOpen = useSuggestionGeneralDateStore(
    state => state.isCustomDateRangeOpen
  )
  // const currentLabel = useSuggestionGeneralDateStore(
  //   state => state.currentLabel
  // )

  const handleDateChange = (date: Dayjs) => {
    setCurrentDate([date, date])
    setCurrentLabel("")
    if (suggestions.length > 0 && currentActiveSuggestionIndex === -1) {
      addSuggestion({
        date: [date, date],
        label: "",
        duration: 60,
      })
      setCurrentActiveSuggestionIndex(suggestions.length)
    } else if (currentActiveSuggestionIndex !== -1) {
      updateSuggestion(currentActiveSuggestionIndex, {
        date: [date, date],
        label: "",
      })
    }
  }

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          ref={calendarRef}
          value={dateRange?.[0] || currentDate?.[0]}
          // @ts-ignore
          onChange={onChange || handleDateChange}
          showDaysOutsideCurrentMonth
          // disableDragEditing
          disablePast
          slots={{
            day: props => (
              <DayItem
                {...props}
                selectedDate={dateRange || currentDate}
                isCustomDateRangeOpen={isCustomDateRangeOpen}
              />
            ),
          }}
          sx={{
            "& .MuiDialogActions-root": {
              display: "none",
            },
            "& .MuiDateRangePickerToolbar-root": {
              display: "none",
            },
            "& .css-e47596-MuiDateRangeCalendar-root > div:first-child": {
              display: "none",
            },
            "& .MuiPickersLayout-contentWrapper": {
              alignItems: "center",
            },
            "& .MuiPickersArrowSwitcher-root": {
              position: "absolute",
              top: 10,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "space-between",
            },
            "& MuiPickersCalendarHeader-switchViewButton": {
              display: "none",
            },
            "& .MuiPickersArrowSwitcher-spacer": {
              display: "none",
            },
            "& .MuiTouchRipple-root": {
              mr: 0,
            },
            "& .MuiPickersCalendarHeader-labelContainer": {
              justifyContent: "center",
              width: "100%",
              userSelect: "none",
              pointerEvents: "none",
            },
            "& .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton":
              {
                display: "none",
              },
            "& .css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root": {
              border: "1px solid var(--lightGray5)",
              backgroundColor: "var(--lightGray6)",
            },
            "& .css-n5yb6p-MuiButtonBase-root-MuiPickersDay-root": {
              border: "1px solid var(--lightGray5)",
              backgroundColor: "var(--lightGray6)",
            },
            "& .MuiPickersDay-today": {
              color: "var(--red2)",
              border: "1px solid var(--lightGray5)",
              backgroundColor: "var(--lightGray6)",
            },
            "& .MuiPickersToolbar-content": {
              display: "none",
            },
            "& .MuiTypography-overline": {
              display: "none",
            },
            "& .MuiDayCalendar-weekContainer": {
              gap: isCustomDateRangeOpen ? "0" : "2px",
            },
          }}
        />
      </LocalizationProvider>
    </Container>
  )
}
