import React from "react"

import PropTypes from "prop-types"

import CloseIcon from "@assets/images/close-grey-2.svg"

import { Title6 } from "../../../assets/styles/typographyStyles"
import { IconButtonEl } from "../../common"
import { Header } from "../newModalStyles"

export const ModalHeader = ({ title, close }) => (
  <Header>
    <Title6>{title}</Title6>

    <IconButtonEl icon={CloseIcon} onClick={close} alt="close" />
  </Header>
)

ModalHeader.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
}
