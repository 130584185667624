import { useEffect, useState } from "react"

import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

import { getAttendanceItems } from "@utils/peopleUtils"
import { useUserStatusChange } from "hooks"

import Block from "../Block"
import StatusIcon from "../Participants/ParticipantItem/StatusIcon"

import "./index.scss"
import AttendanceModal from "@components/TeeupPeople/Modals/Attendance"

const YourStatus = ({ user }) => {
  // const [attendanceStatuses, setAttendanceStatuses] = useState([])

  // const { handleUserStatusChange, statusExtended } = useUserStatusChange(user)

  // useEffect(() => {
  //   const attendanceStatuses = getAttendanceItems()
  //   setAttendanceStatuses(attendanceStatuses)
  // }, [])

  // const handleAttendanceChange = item => {
  //   const statusText = item.target.value

  //   handleUserStatusChange(statusText)
  // }

  return (
    <Block>
      <div className="people-your-status">
        <h4>Your Status</h4>

        <AttendanceModal user={user} />
        {/* <Select
          value={statusExtended?.status || "rsvp"}
          className="people-status-select"
          onChange={handleAttendanceChange}>
          <MenuItem value="rsvp" className="menu-item-invisible">
            <div className="people-status-menu-item">
              <span>RSVP</span>
            </div>
          </MenuItem>
          {attendanceStatuses.map(item => (
            <MenuItem key={item.status} value={item.status}>
              <div className="people-status-menu-item">
                <StatusIcon status={item} isBlock disabled isLarge />
                <span>{item.text}</span>
              </div>
            </MenuItem>
          ))}
        </Select> */}
      </div>
    </Block>
  )
}

export default YourStatus
